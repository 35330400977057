import React, { useState } from 'react';
import { useLinkTo, useNavigation, useRoute } from '@react-navigation/native';
import { Text, useTheme } from '@ui-kitten/components';
import { RouteProp } from '@react-navigation/core/lib/typescript/src/types';

import { Platform } from 'react-native';
import MaxWidthContainer from '../../components/MaxWidthContainer';

import TeleconsultationCard from '../../components/TeleconsultationCard';
import InfoCard from '../../screenComponents/InfoCard';
import CompButton from '../../components/Button';
import DetailsScreensHeaderCard from '../../screenComponents/DetailsScreensHeaderCard';
import AnimalCard from '../../screenComponents/AnimalCard';

import { TabElevageParamList } from '../../types';

import { useDeleteLotMutation, useGetLot } from '../../src/API/Lots';
import ActivityIndicator from '../../components/ActivityIndicator';
import { useConsultationList, useUpdateConsultationMutation } from '../../src/API/Consultation';
import ElevageScreenBL from '../../screenBusinessLogic/ElevageScreen/ElevageScreenBL';
import CustomModal from '../../components/Modals/CustomModal';
import { useUpdateAnimalMutation } from '../../src/API/Animals';

const DetailLotScreen = () => {
  const linkTo = useLinkTo();
  const navigation = useNavigation();
  const theme = useTheme();
  // const { breeding } = useBreedingList();
  const route = useRoute<RouteProp<TabElevageParamList, 'detail-lot'>>();
  // console.log('DetailLotScreen route: ', route);
  // console.log('DetailLotScreen data: ', breeding);

  const { lot, loading, refetch } = useGetLot(route.params.id);
  // console.log('lot detail lot:', lot);
  // console.log('lot detail route.params.id:', route.params.id);

  const affectedAnimalsToLotQuatity = lot?.animals?.items?.length;
  // console.log(lot);

  // for modal
  const [openDelete, setOpenDelete] = useState<{ title : string }>();

  const { updateAnimal } = useUpdateAnimalMutation();
  const { modifyConsultation } = useUpdateConsultationMutation();
  const deleteLotMutation = useDeleteLotMutation();
  const deleteLot = async () => {
    if (lot) {
      /** DELETE ANIMALS FROM LOTS (lotsId in animals) */
      if (lot.animals?.items && lot.animals.items.length > 0) {
        lot.animals.items.reduce(async (promise, animal) => {
          await promise;
          if (animal) {
            await updateAnimal({
              variables: {
                input: {
                  id: animal.id,
                  // eslint-disable-next-line no-underscore-dangle
                  _version: animal._version,
                  lotsId: null,
                },
              },
            });
          }
        }, Promise.resolve());
      }
      /** DELETE CONSULTATIONS FROM LOTS (lotsId in consultations) */
      if (lot.consultations?.items && lot.consultations.items.length > 0) {
        lot.consultations.items.reduce(async (promise, consultation) => {
          await promise;
          if (consultation) {
            await modifyConsultation({
              variables: {
                input: {
                  id: consultation.id,
                  // eslint-disable-next-line no-underscore-dangle
                  _version: consultation._version,
                  lotId: null,
                },
              },
            });
          }
        }, Promise.resolve());
      }
      /** DELETE lots after unaffecting from everything */
      await deleteLotMutation({
        variables: {
          input: {
            id: route.params.id,
            // eslint-disable-next-line no-underscore-dangle
            _version: lot._version,
          },
        },
      });
    }
    if (refetch) {
      await refetch();
    }
    linkTo('/elevage');
    setOpenDelete(undefined);
  };

  return (
    <>
      <MaxWidthContainer
        outerViewProps={{
          style: {
            padding: 24,
          },
        }}
      >
        {lot
    && (
    <>
      <DetailsScreensHeaderCard
        title1="Numéro de lot"
        titleInfo1={lot?.numberLot}
        title2="Nom du lot"
        titleInfo2={lot?.name}
        title3="Effectif"
        titleInfo3={affectedAnimalsToLotQuatity} // quatité des animaux affecté à ce lot
        title4="Bâtiment(s)"
        titleInfo4={lot?.building?.nameBuilding || 'Pas de bâtiment affecté à ce lot'}
      />

      <CompButton
        leftIcon="edit-outline"
        leftIconFill={theme['color-primary-500']}
        appearance="outline"
        onPress={() => { linkTo(`/elevage/detail-lot/${lot?.id}/modify-lot`); }}
      >
        Modifier les informations du lot
      </CompButton>
    </>
    )}

        <Text category="p1" appearance="hint" style={{ marginTop: 20, marginBottom: 10 }}>
          Animaux affectés à ce lot
        </Text>
        <InfoCard
          title="alertInfo"
          iconShow="alert-circle-outline"
          description="Appuyez sur le bouton ci-dessous pour accéder à votre espace de gestion des animaux affectés à ce lot. Vous pouvez ajouter ou supprimer les animaux de votre choix."
          alertInfo="alertInfo"
          infoStyle={{ backgroundColor: theme['color-primary-100'] }}
        />

        <CompButton
          leftIcon="edit-outline"
          leftIconFill={theme['color-primary-500']}
          appearance="outline"
          style={{ marginBottom: 10 }}
          onPress={() => {
            navigation.navigate('manage-affected-animals', {
              listTitle: 'Animaux', manage: 'les animaux', whichPage: 'lot', id: route.params.id,
            });
          }}
        >
          Gérer les animaux affectés à ce lot
        </CompButton>

        {loading
          ? (<ActivityIndicator center margin={10} />)
          : (
            lot && lot.animals && lot.animals.items
            && lot.animals.items.map(
              (animalInLot) => (
                <AnimalCard
                  key={animalInLot?.id}
                  name={animalInLot?.name}
                  numberLot={lot.numberLot}
                  numberId={animalInLot?.numberID}
                  onPress={() => { ElevageScreenBL.allerDetailScreen({ state: 'animaux', linkTo, id: animalInLot?.id }); }}
                />
              ),
            )
          )}

        <Text category="p1" appearance="hint" style={{ marginTop: 20 }}>Derniers auto-examens</Text>

        {
          lot?.consultations?.items && lot?.consultations?.items.length > 0
            ? (lot?.consultations?.items.map(
              (item) => <TeleconsultationCard key={item?.id} consultationData={item} back={`elevage/detail-lot/${lot?.id}`} />,
            ))
            : <Text category="h4">Vous n'avez pas de consultations</Text>
        }

        <CompButton onPress={() => { setOpenDelete('delete Lot'); }} style={{ marginTop: 40, backgroundColor: 'white' }} status="danger" appearance="outline">Supprimer le lot</CompButton>

      </MaxWidthContainer>
      <CustomModal
        title={'Vous êtes sur le point de supprimer le lot.'
      + '\n'
      + 'Êtes-vous sur de vouloir continuer ?'}
        before={<></>}
        noSafeArea
        visible={openDelete !== undefined}
        scrollable={false}
        onClose={() => setOpenDelete(undefined)}
        insideModalStyle={{ textAlign: 'center', padding: 20 }}
        buttonText="Confirmer"
        secondButtonText="Annuler"
        heightPercentage={Platform.OS === 'web' ? 0.36 : 0.6}
        buttonPress={() => { deleteLot(); }}
      />
    </>
  );
};

export default DetailLotScreen;
