import React from 'react';

import { Text } from '@ui-kitten/components';
// import Shimmer from 'react-native-shimmer';
import { useNavigation } from '@react-navigation/native';
import moment from 'moment';
import { View, StyleSheet } from 'react-native';
import MaxWidthContainer from '../../components/MaxWidthContainer';
import { useNotificationsList } from '../../src/API/Notification';
import { ModelSortDirection } from '../../src/API';
import { useUser } from '../../src/API/UserContext';
import ActivityIndicator from '../../components/ActivityIndicator';
import NotificationCard from '../../components/CustomCards/NotificationCard';

export default function NotificationScreen() {
  const navigation = useNavigation();
  const { user, updateUser } = useUser();

  React.useEffect(() => navigation.addListener('focus', () => {
    // refetch();
    if (updateUser) { /**
      updateUser({
        notificationLastSeenAt: new Date().toISOString(),
      });
    */ }
  }), [navigation]);

  /** NOTIFICATIONS */
  const { loading: loadingNotif, notifications } = useNotificationsList({
    userId: user?.id,
    sortDirection: ModelSortDirection.DESC,
    createdAt: {
      ge: moment().add(-30, 'days').format('YYYY-MM-DDT00:00:00'),
    },
  });

  return (
    <MaxWidthContainer
      withScrollView="simple"
      outerViewProps={{
        style: {
          padding: 25,
        },
      }}
    >
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        {/**
         <Button
         appearance="ghost"
         style={{ padding: 0 }}
         onPress={() => {
            // console.log('press');
            if (user && user.userGroup === 'veto') {
              linkTo('/veto-notifications/veto-notifications-params');
            } else {
              linkTo('/notifications/notifications-params');
            }
          }}
         accessoryLeft={(props) => (
            <IconUIKitten
              {...props}
              name="settings-2-outline"
              fill="black"
              style={{ width: 24, height: 24 }}
            />
          )}
         />
        */}

      </View>

      {loadingNotif
        ? <ActivityIndicator center margin={10} />
        : (notifications
            && notifications?.length > 0
          ? (notifications?.map(
            (notification) => (notification && (
            <NotificationCard
              key={notification.id}
              notification={notification}
            />
            )),
          )) : (<Text category="h6">Vous n’avez pas de nouvelles notifications</Text>)

        )}
    </MaxWidthContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: 24,
    marginTop: 34,
  },
  faq: {
    marginTop: 12,
    marginBottom: 41,
  },
});
