import React, { useState } from 'react';
import { useLinkTo, useRoute } from '@react-navigation/native';
import { Text, useTheme } from '@ui-kitten/components';

import { useDimensions } from '@react-native-community/hooks';
import MaxWidthContainer from '../../components/MaxWidthContainer';
import RealiserAutoExamScreenHeaderCard
  from '../../screenComponents/ConsultationScreenComps/RealiserAutoExamScreenHeaderCard';
import ElevageScreenBL from '../../screenBusinessLogic/ElevageScreen/ElevageScreenBL';
import CompButton from '../../components/Button';
import { category } from '../../src/mockData/selectData';
import FSelect from '../../components/Form/Select';
import InfoCard from '../../screenComponents/InfoCard';
import { useUser } from '../../src/API/UserContext';
import { useUpdateUser } from '../../src/API/User';
import { useBreedingList } from '../../src/API/Breeding';

const RealiserAutoExamScreen = () => {
  const [state, setState] = useState<string>();
  const theme = useTheme();
  const route = useRoute();

  const { user } = useUser();
  const { breeding } = useBreedingList(user?.userGroup);

  const { window } = useDimensions();
  const isGreat = window.width > 780;

  return (
    <MaxWidthContainer outerViewProps={{ style: { padding: 24 } }}>

      <InfoCard
        title="alertInfo"
        iconShow="alert-circle-outline"
            // iconCloseShow="close-outline"
        infoStyle={{ backgroundColor: theme['color-primary-100'] }}
        description={route.name === 'faire-demande-teleconsultation'
          ? `Attention, si vous souhaitez réaliser un demande de téléconsultation sur un animal ou un lot d’animaux non enregistré, vous devez d’abord le créer à partir de l’espace « Elevage »${!isGreat ? ' : dans la barre du bas section « Elevage »' : ''}`
          : `Attention, si vous souhaitez réaliser un auto-examen sur un animal ou un lot d’animaux non enregistré, vous devez d’abord le créer à partir de l’espace « Elevage »${!isGreat ? ' : dans la barre du bas section « Elevage »' : ''}`}
        alertInfo="alertInfo"
      />

      {
            route.name === 'faire-demande-teleconsultation' && breeding.vetOffice
              ? (
                <InfoCard
                  title="alertInfo"
                  iconShow="alert-circle-outline"
                    // iconCloseShow="close-outline"
                  infoStyle={{ backgroundColor: theme['color-primary-100'] }}
                  description={`Le montant maximal pouvant vous être prélevé par votre vétérinaire pour une téléconsultation est de ${breeding.vetOffice.maxPriceTeleConsultation} €.`}
                  alertInfo="alertInfo"
                />
              )
              : null
        }
      {/* <CompButton
        appearance="outline"
        leftIcon="plus-outline"
        leftIconFill={theme['color-primary-600']}
        onPress={() => linkTo('/consultation/ajout-animal')}
        style={{ marginTop: 20 }}
      >
        {ElevageScreenBL.getAddButtonTitle('animaux')}
      </CompButton>
      <CompButton
        appearance="outline"
        leftIcon="plus-outline"
        leftIconFill={theme['color-primary-700']}
        onPress={() => linkTo('/consultation/ajout-lot')}
        style={{ marginTop: 20 }}
      >
        {ElevageScreenBL.getAddButtonTitle('lots')}
      </CompButton> */}

      <Text category="h2" style={{ marginTop: 40 }}>Rechercher un animal ou un lot</Text>

      <Text category="h5" style={{ marginTop: 22 }}>
        Souhaitez-vous réaliser
        {' '}
        {route.name === 'faire-demande-teleconsultation' ? 'la demande de téléconsultation' : 'l\'auto-examen'}
        {' '}
        sur un animal ou un lot?
      </Text>
      <FSelect
        data={category}
        onChangeValue={(v) => { setState(v); }}
        name="category"
        placeholder="Sélectionner"
      />

      <RealiserAutoExamScreenHeaderCard state={state} />

    </MaxWidthContainer>
  );
};

export default RealiserAutoExamScreen;
