import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLinkTo, useRoute } from '@react-navigation/native';
import { Auth } from 'aws-amplify';
import { Text } from '@ui-kitten/components';
import MaxWidthContainer from '../../components/MaxWidthContainer';
import Form from '../../components/Form/Form';
import { AvailableValidationRules } from '../../components/Form/validation';
import TextInputComp from '../../components/Form/TextInput';
import Button from '../../components/Button';
import { useUser } from '../../src/API/UserContext';

type ModifyPasswordForm = {
  password: string;
  oldPassword: string;
};

const ModifyPasswordScreen = () => {
  const linkTo = useLinkTo();
  const [loading, setLoading] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const { updateUser, user, cognitoUser } = useUser();
  const route = useRoute();

  const onPress = async (data :ModifyPasswordForm) => {
    if (user && updateUser && cognitoUser) {
      const { password, oldPassword } = data;
      setPasswordError(false);

      if (oldPassword && password) {
        try {
          const passwordChanged = await Auth.changePassword(cognitoUser, oldPassword, password);
          // console.log('password change', passwordChanged);
          if (passwordChanged !== 'SUCCESS') {
            setPasswordError(true);
            return;
          }
        } catch (e) {
          setPasswordError(true);
          return;
        }
      }
      if (route && route.params && route.params.id) {
        // console.log('route modify password: ', route.params.id);
        // console.log('id user modify password: ', user.id);
        linkTo('/veto-mon-cabinet/veto-mon-compte/veto-modifier-mon-compte');
      } else {
        linkTo('/mon-compte/modifier-compte');
      }
    }
  };

  const modifyPasswordForm = useForm<ModifyPasswordForm>();
  return (
    <MaxWidthContainer
      withScrollView="keyboardAware"
      outerViewProps={{
        showsVerticalScrollIndicator: false,
        style: { padding: 20 },
      }}
    >
      <Form<ModifyPasswordForm> {...modifyPasswordForm}>
        <>
          <TextInputComp
            name="oldPassword"
              // label="Mot de passe"
            title="Votre mot de passe actuel"
            placeholder="Écrivez le mot de passe actuel"
            secureTextEntry
            withEyeToggle
            validators={[
              {
                rule: AvailableValidationRules.requiredIfNotEmpty,
                errorMessage: 'Vous devez renseigner votre mot de passe actuel pour changer de mot de passe',
                ifNotEmpty: ['password'],
              },
              AvailableValidationRules.required,
              AvailableValidationRules.password,
            ]}
          />

          <TextInputComp
            name="password"
            title="Définissez un nouveau mot de passe"
            placeholder="Définissez le mot de passe"
            secureTextEntry
            withEyeToggle
            validators={[
              AvailableValidationRules.required,
              AvailableValidationRules.password,
            ]}
          />

          <Button
            loading={loading}
            loadingText="Chargement"
            onPress={modifyPasswordForm.handleSubmit(
              (data) => {
                setLoading(true);
                onPress(data);
              },
            )}
            style={{ marginTop: 40 }}
          >
            Valider
          </Button>
          {passwordError && <Text status="danger">Une erreur à eu lieu lors du changement de mot de passe merci de vérifier votre mot de passe.</Text>}
        </>
      </Form>

    </MaxWidthContainer>
  );
};

export default ModifyPasswordScreen;
