import React from 'react';
import { TouchableOpacity, View, ViewStyle } from 'react-native';
import { Icon as IconUIKitten } from '@ui-kitten/components/ui/icon/icon.component';
import { useBtnInnerStyle, useInnerIconStyle } from '../useStyles';
import { AdditionalInfosState } from '../AdditionalInfos';

type MoreButtonsProps = { style?: ViewStyle, setState: (state?: AdditionalInfosState) => void };

function MoreButtons(props: MoreButtonsProps) {
  const btnInnerStyle = useBtnInnerStyle();
  const iconStyle = useInnerIconStyle();
  const { style, setState } = props;

  return (
    <View style={{
      ...{
        flexDirection: 'row', justifyContent: 'space-between', backgroundColor: 'white', borderRadius: 10, width: 225,
      },
      ...style,
    }}
    >
      <TouchableOpacity
        style={btnInnerStyle}
        onPress={() => { setState(AdditionalInfosState.infos); }}
      >
        <IconUIKitten name="info-outline" style={iconStyle} fill="black" />
      </TouchableOpacity>
      <TouchableOpacity
        style={btnInnerStyle}
        onPress={() => { setState(AdditionalInfosState.chat); }}
      >
        <IconUIKitten name="message-square-outline" style={iconStyle} fill="black" />
      </TouchableOpacity>
      <TouchableOpacity
        style={btnInnerStyle}
        onPress={() => { setState(AdditionalInfosState.documents); }}
      >
        <IconUIKitten name="file-add-outline" style={iconStyle} fill="black" />
      </TouchableOpacity>
    </View>
  );
}

export default MoreButtons;
