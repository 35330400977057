/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateUserInput = {
  id?: string | null,
  lastname?: string | null,
  firstname?: string | null,
  avatarUri?: string | null,
  userGroup?: string | null,
  optIn?: boolean | null,
  inscriptionNumberOrdreVeto?: string | null,
  breedingId?: string | null,
  vetOfficeId?: string | null,
  email?: string | null,
  expoToken?: Array< string > | null,
  notificationParams?: NotificationParamsInput | null,
  notificationLastSeenAt?: string | null,
  _version?: number | null,
};

export type NotificationParamsInput = {
  echeanceFacture?: NotificationParamInput | null,
  echeanceBSE?: NotificationParamInput | null,
  finBSE?: NotificationParamInput | null,
  debitConsultation?: NotificationParamInput | null,
  finPeriodeEssai?: NotificationParamInput | null,
  autre?: NotificationParamInput | null,
};

export type NotificationParamInput = {
  push?: boolean | null,
  email?: boolean | null,
};

export type ModelUserConditionInput = {
  lastname?: ModelStringInput | null,
  firstname?: ModelStringInput | null,
  avatarUri?: ModelStringInput | null,
  userGroup?: ModelStringInput | null,
  optIn?: ModelBooleanInput | null,
  inscriptionNumberOrdreVeto?: ModelStringInput | null,
  breedingId?: ModelIDInput | null,
  vetOfficeId?: ModelIDInput | null,
  email?: ModelStringInput | null,
  expoToken?: ModelStringInput | null,
  notificationLastSeenAt?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type User = {
  __typename: "User",
  id: string,
  lastname?: string | null,
  firstname?: string | null,
  avatarUri?: string | null,
  userGroup?: string | null,
  optIn?: boolean | null,
  inscriptionNumberOrdreVeto?: string | null,
  breedingId?: string | null,
  breeding?: Breeding | null,
  vetOfficeId?: string | null,
  vetOffice?: VetOffice | null,
  email?: string | null,
  expoToken?: Array< string > | null,
  notificationParams?: NotificationParams | null,
  notificationLastSeenAt?: string | null,
  messages?: ModelMessageConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Breeding = {
  __typename: "Breeding",
  id: string,
  locked: LockedStatus,
  nBreeding: string,
  nSIRET: string,
  phone: string,
  emailEleveur: string,
  address?: Address | null,
  companyName: string,
  dairyFarm: boolean,
  sucklerCowHusbandry: boolean,
  fatteningFarm: boolean,
  nbCow: number,
  nbHeifer: number,
  nbMale: number,
  nbYoung: number,
  idVeto?: string | null,
  emailVeto?: string | null,
  admins: Array< string >,
  vetOffice?: VetOffice | null,
  users?: ModelUserConnection | null,
  documents?: ModelDocumentConnection | null,
  animals?: ModelAnimalsConnection | null,
  lots?: ModelLotsConnection | null,
  buildings?: ModelBuildingsConnection | null,
  bse?: ModelBSEConnection | null,
  pendingInvitations?: ModelPendingInvitationConnection | null,
  consultations?: ModelConsultationConnection | null,
  billingHistories?: ModelBillingHistoryConnection | null,
  mpUserId?: string | null,
  mpWalletId?: string | null,
  mpBankId?: string | null,
  mpMandateId?: string | null,
  mpMandateConfirmed?: boolean | null,
  createdAt: string,
  dateFirstCons?: string | null,
  nextSubscriptionDate?: string | null,
  subscriptionAmount?: number | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum LockedStatus {
  initialControlNeed = "initialControlNeed",
  unlocked = "unlocked",
  unpaid = "unpaid",
  otherLockReason = "otherLockReason",
}


export type Address = {
  __typename: "Address",
  address: string,
  additionalAddress?: string | null,
  postalCode: string,
  city: string,
};

export type VetOffice = {
  __typename: "VetOffice",
  id: string,
  locked: LockedStatus,
  companyName: string,
  emailOffice: string,
  admins: Array< string >,
  personnel?: Array< string > | null,
  phoneVetOffice?: string | null,
  address?: Address | null,
  nameVetSanitaire?: string | null,
  nOrdreCabinet: string,
  nSIRET: string,
  maxPriceTeleConsultation: number,
  maxPriceConsultation: number,
  users?: ModelUserConnection | null,
  breedings?: ModelBreedingConnection | null,
  billingHistories?: ModelBillingHistoryConnection | null,
  mpUserId?: string | null,
  isFree?: boolean | null,
  mpWalletId?: string | null,
  mpBankId?: string | null,
  mpMandateId?: string | null,
  mpMandateConfirmed?: boolean | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelBreedingConnection = {
  __typename: "ModelBreedingConnection",
  items:  Array<Breeding | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelBillingHistoryConnection = {
  __typename: "ModelBillingHistoryConnection",
  items:  Array<BillingHistory | null >,
  nextToken?: string | null,
};

export type BillingHistory = {
  __typename: "BillingHistory",
  id: string,
  vetOfficeId?: string | null,
  vetOffice?: VetOffice | null,
  breedingId?: string | null,
  breeding?: Breeding | null,
  createdAt: string,
  type: string,
  invoiceNumber?: number | null,
  amount: number,
  label: string,
  paid?: boolean | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelDocumentConnection = {
  __typename: "ModelDocumentConnection",
  items:  Array<Document | null >,
  nextToken?: string | null,
};

export type Document = {
  __typename: "Document",
  id: string,
  breedingId?: string | null,
  breeding?: Breeding | null,
  consultationId?: string | null,
  consultation?: Consultation | null,
  name: string,
  key?: string | null,
  s3file: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Consultation = {
  __typename: "Consultation",
  id: string,
  breedingId: string,
  breeding?: Breeding | null,
  animalId?: string | null,
  animal?: Animals | null,
  lotId?: string | null,
  lot?: Lots | null,
  diseaseId?: string | null,
  disease?: disease | null,
  shared?: boolean | null,
  sharedAt?: string | null,
  autoExam?: boolean | null,
  autoExamRes?: AutoExamRes | null,
  teleconsultation?: boolean | null,
  priceConsultation?: number | null,
  diseaseIsVerified?: Array< string | null > | null,
  isDone?: boolean | null,
  factureId?: string | null,
  facture?: Document | null,
  documents?: ModelDocumentConnection | null,
  messages?: ModelMessageConnection | null,
  sharedFacture?: boolean | null,
  sharedFactureAt?: string | null,
  teleconsAskAt?: string | null,
  dateTeleCons?: string | null,
  questionResponse?: string | null,
  note?: number | null,
  questionResponseOrder?: Array< string | null > | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Animals = {
  __typename: "Animals",
  id: string,
  numberID: string,
  name?: string | null,
  gender?: GenderType | null,
  birthDate?: string | null,
  breedingId: string,
  breeding?: Breeding | null,
  lotsId?: string | null,
  lots?: Lots | null,
  consultations?: ModelConsultationConnection | null,
  zoneId?: string | null,
  zone?: Zones | null,
  buildingId?: string | null,
  building?: Buildings | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum GenderType {
  Man = "Man",
  Woman = "Woman",
  Castrated = "Castrated",
}


export type Lots = {
  __typename: "Lots",
  id: string,
  numberLot: string,
  name?: string | null,
  buildingId?: string | null,
  building?: Buildings | null,
  breedingId: string,
  breeding?: Breeding | null,
  animals?: ModelAnimalsConnection | null,
  consultations?: ModelConsultationConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Buildings = {
  __typename: "Buildings",
  id: string,
  nameBuilding: string,
  address?: Address | null,
  breedingId: string,
  breeding?: Breeding | null,
  zone?: ModelZonesConnection | null,
  animals?: ModelAnimalsConnection | null,
  lots?: ModelLotsConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelZonesConnection = {
  __typename: "ModelZonesConnection",
  items:  Array<Zones | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Zones = {
  __typename: "Zones",
  id: string,
  name?: string | null,
  numberZone?: string | null,
  animals?: ModelAnimalsConnection | null,
  buildingId?: string | null,
  building?: Buildings | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelAnimalsConnection = {
  __typename: "ModelAnimalsConnection",
  items:  Array<Animals | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelLotsConnection = {
  __typename: "ModelLotsConnection",
  items:  Array<Lots | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelConsultationConnection = {
  __typename: "ModelConsultationConnection",
  items:  Array<Consultation | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type disease = {
  __typename: "disease",
  id: string,
  codeIdentification?: string | null,
  name: string,
  forme?: string | null,
  description: string,
  synonymes?: string | null,
  enjeux?: string | null,
  causes?: string | null,
  circonstancesApparition?: string | null,
  diagnosticDiff?: string | null,
  diagnosticLab?: string | null,
  traitementCuratif?: string | null,
  pronostic?: string | null,
  prevention?: string | null,
  url?: string | null,
  consultations?: ModelConsultationConnection | null,
  image?: Array< string | null > | null,
  ponderationDisease?: string | null,
  ponderationCount?: number | null,
  familleMaladie?: string | null,
  familleMaladieId?: number | null,
  MRLC?: boolean | null,
  maladieUrgente?: boolean | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type AutoExamRes = {
  __typename: "AutoExamRes",
  reponse1?: string | null,
};

export type ModelMessageConnection = {
  __typename: "ModelMessageConnection",
  items:  Array<Message | null >,
  nextToken?: string | null,
};

export type Message = {
  __typename: "Message",
  id: string,
  consultationId: string,
  consultation?: Consultation | null,
  message: string,
  userId: string,
  user?: User | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelBuildingsConnection = {
  __typename: "ModelBuildingsConnection",
  items:  Array<Buildings | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelBSEConnection = {
  __typename: "ModelBSEConnection",
  items:  Array<BSE | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type BSE = {
  __typename: "BSE",
  id: string,
  breedingId: string,
  StatusBse?: StatusBse | null,
  dateBSE?: string | null,
  crS3Key?: string | null,
  breeding?: Breeding | null,
  questionResponse?: string | null,
  lasIdQuestion?: string | null,
  idEleveurUser?: string | null,
  lastQuestionVeto?: string | null,
  questionResponseOrder?: Array< string | null > | null,
  maladieRecurente?: Array< string | null > | null,
  protocolesDeSoins?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum StatusBse {
  preReviewStarted = "preReviewStarted",
  preReviewFinished = "preReviewFinished",
  BSEAsk = "BSEAsk",
  BSEAskStart = "BSEAskStart",
  BSEStarted = "BSEStarted",
  BSEProtocolSoins = "BSEProtocolSoins",
  BSEFinish = "BSEFinish",
}


export type ModelPendingInvitationConnection = {
  __typename: "ModelPendingInvitationConnection",
  items:  Array<PendingInvitation | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type PendingInvitation = {
  __typename: "PendingInvitation",
  id: string,
  breedingId: string,
  breeding?: Breeding | null,
  emailCurrentUser: string,
  email: string,
  createdAt: string,
  initial?: boolean | null,
  refusedAt?: string | null,
  acceptedAt?: string | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type NotificationParams = {
  __typename: "NotificationParams",
  echeanceFacture?: NotificationParam | null,
  echeanceBSE?: NotificationParam | null,
  finBSE?: NotificationParam | null,
  debitConsultation?: NotificationParam | null,
  finPeriodeEssai?: NotificationParam | null,
  autre?: NotificationParam | null,
};

export type NotificationParam = {
  __typename: "NotificationParam",
  push?: boolean | null,
  email?: boolean | null,
};

export type UpdateUserInput = {
  id: string,
  lastname?: string | null,
  firstname?: string | null,
  avatarUri?: string | null,
  userGroup?: string | null,
  optIn?: boolean | null,
  inscriptionNumberOrdreVeto?: string | null,
  breedingId?: string | null,
  vetOfficeId?: string | null,
  email?: string | null,
  expoToken?: Array< string > | null,
  notificationParams?: NotificationParamsInput | null,
  notificationLastSeenAt?: string | null,
  _version?: number | null,
};

export type DeleteUserInput = {
  id: string,
  _version?: number | null,
};

export type CreateVetOfficeInput = {
  id?: string | null,
  locked: LockedStatus,
  companyName: string,
  emailOffice: string,
  admins: Array< string >,
  personnel?: Array< string > | null,
  phoneVetOffice?: string | null,
  address?: AddressInput | null,
  nameVetSanitaire?: string | null,
  nOrdreCabinet: string,
  nSIRET: string,
  maxPriceTeleConsultation: number,
  maxPriceConsultation: number,
  mpUserId?: string | null,
  isFree?: boolean | null,
  mpWalletId?: string | null,
  mpBankId?: string | null,
  mpMandateId?: string | null,
  mpMandateConfirmed?: boolean | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type AddressInput = {
  address: string,
  additionalAddress?: string | null,
  postalCode: string,
  city: string,
};

export type ModelVetOfficeConditionInput = {
  locked?: ModelLockedStatusInput | null,
  companyName?: ModelStringInput | null,
  emailOffice?: ModelStringInput | null,
  admins?: ModelStringInput | null,
  personnel?: ModelStringInput | null,
  phoneVetOffice?: ModelStringInput | null,
  nameVetSanitaire?: ModelStringInput | null,
  nOrdreCabinet?: ModelStringInput | null,
  nSIRET?: ModelStringInput | null,
  maxPriceTeleConsultation?: ModelFloatInput | null,
  maxPriceConsultation?: ModelFloatInput | null,
  mpUserId?: ModelStringInput | null,
  isFree?: ModelBooleanInput | null,
  mpWalletId?: ModelStringInput | null,
  mpBankId?: ModelStringInput | null,
  mpMandateId?: ModelStringInput | null,
  mpMandateConfirmed?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelVetOfficeConditionInput | null > | null,
  or?: Array< ModelVetOfficeConditionInput | null > | null,
  not?: ModelVetOfficeConditionInput | null,
};

export type ModelLockedStatusInput = {
  eq?: LockedStatus | null,
  ne?: LockedStatus | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateVetOfficeInput = {
  id: string,
  locked?: LockedStatus | null,
  companyName?: string | null,
  emailOffice?: string | null,
  admins?: Array< string > | null,
  personnel?: Array< string > | null,
  phoneVetOffice?: string | null,
  address?: AddressInput | null,
  nameVetSanitaire?: string | null,
  nOrdreCabinet?: string | null,
  nSIRET?: string | null,
  maxPriceTeleConsultation?: number | null,
  maxPriceConsultation?: number | null,
  mpUserId?: string | null,
  isFree?: boolean | null,
  mpWalletId?: string | null,
  mpBankId?: string | null,
  mpMandateId?: string | null,
  mpMandateConfirmed?: boolean | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type DeleteVetOfficeInput = {
  id: string,
  _version?: number | null,
};

export type CreateBreedingInput = {
  id?: string | null,
  locked: LockedStatus,
  nBreeding: string,
  nSIRET: string,
  phone: string,
  emailEleveur: string,
  address?: AddressInput | null,
  companyName: string,
  dairyFarm: boolean,
  sucklerCowHusbandry: boolean,
  fatteningFarm: boolean,
  nbCow: number,
  nbHeifer: number,
  nbMale: number,
  nbYoung: number,
  idVeto?: string | null,
  emailVeto?: string | null,
  admins: Array< string >,
  mpUserId?: string | null,
  mpWalletId?: string | null,
  mpBankId?: string | null,
  mpMandateId?: string | null,
  mpMandateConfirmed?: boolean | null,
  createdAt?: string | null,
  dateFirstCons?: string | null,
  nextSubscriptionDate?: string | null,
  subscriptionAmount?: number | null,
  _version?: number | null,
};

export type ModelBreedingConditionInput = {
  locked?: ModelLockedStatusInput | null,
  nBreeding?: ModelStringInput | null,
  nSIRET?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  emailEleveur?: ModelStringInput | null,
  companyName?: ModelStringInput | null,
  dairyFarm?: ModelBooleanInput | null,
  sucklerCowHusbandry?: ModelBooleanInput | null,
  fatteningFarm?: ModelBooleanInput | null,
  nbCow?: ModelFloatInput | null,
  nbHeifer?: ModelFloatInput | null,
  nbMale?: ModelFloatInput | null,
  nbYoung?: ModelFloatInput | null,
  idVeto?: ModelIDInput | null,
  emailVeto?: ModelStringInput | null,
  admins?: ModelStringInput | null,
  mpUserId?: ModelStringInput | null,
  mpWalletId?: ModelStringInput | null,
  mpBankId?: ModelStringInput | null,
  mpMandateId?: ModelStringInput | null,
  mpMandateConfirmed?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  dateFirstCons?: ModelStringInput | null,
  nextSubscriptionDate?: ModelStringInput | null,
  subscriptionAmount?: ModelFloatInput | null,
  and?: Array< ModelBreedingConditionInput | null > | null,
  or?: Array< ModelBreedingConditionInput | null > | null,
  not?: ModelBreedingConditionInput | null,
};

export type UpdateBreedingInput = {
  id: string,
  locked?: LockedStatus | null,
  nBreeding?: string | null,
  nSIRET?: string | null,
  phone?: string | null,
  emailEleveur?: string | null,
  address?: AddressInput | null,
  companyName?: string | null,
  dairyFarm?: boolean | null,
  sucklerCowHusbandry?: boolean | null,
  fatteningFarm?: boolean | null,
  nbCow?: number | null,
  nbHeifer?: number | null,
  nbMale?: number | null,
  nbYoung?: number | null,
  idVeto?: string | null,
  emailVeto?: string | null,
  admins?: Array< string > | null,
  mpUserId?: string | null,
  mpWalletId?: string | null,
  mpBankId?: string | null,
  mpMandateId?: string | null,
  mpMandateConfirmed?: boolean | null,
  createdAt?: string | null,
  dateFirstCons?: string | null,
  nextSubscriptionDate?: string | null,
  subscriptionAmount?: number | null,
  _version?: number | null,
};

export type DeleteBreedingInput = {
  id: string,
  _version?: number | null,
};

export type CreateBillingHistoryInput = {
  id?: string | null,
  vetOfficeId?: string | null,
  breedingId?: string | null,
  createdAt?: string | null,
  type: string,
  invoiceNumber?: number | null,
  amount: number,
  label: string,
  paid?: boolean | null,
  _version?: number | null,
};

export type ModelBillingHistoryConditionInput = {
  vetOfficeId?: ModelIDInput | null,
  breedingId?: ModelIDInput | null,
  type?: ModelStringInput | null,
  invoiceNumber?: ModelIntInput | null,
  amount?: ModelFloatInput | null,
  label?: ModelStringInput | null,
  paid?: ModelBooleanInput | null,
  and?: Array< ModelBillingHistoryConditionInput | null > | null,
  or?: Array< ModelBillingHistoryConditionInput | null > | null,
  not?: ModelBillingHistoryConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type CreateAnimalsInput = {
  id?: string | null,
  numberID: string,
  name?: string | null,
  gender?: GenderType | null,
  birthDate?: string | null,
  breedingId: string,
  lotsId?: string | null,
  zoneId?: string | null,
  buildingId?: string | null,
  _version?: number | null,
};

export type ModelAnimalsConditionInput = {
  numberID?: ModelStringInput | null,
  name?: ModelStringInput | null,
  gender?: ModelGenderTypeInput | null,
  birthDate?: ModelStringInput | null,
  breedingId?: ModelIDInput | null,
  lotsId?: ModelIDInput | null,
  zoneId?: ModelIDInput | null,
  buildingId?: ModelIDInput | null,
  and?: Array< ModelAnimalsConditionInput | null > | null,
  or?: Array< ModelAnimalsConditionInput | null > | null,
  not?: ModelAnimalsConditionInput | null,
};

export type ModelGenderTypeInput = {
  eq?: GenderType | null,
  ne?: GenderType | null,
};

export type UpdateAnimalsInput = {
  id: string,
  numberID?: string | null,
  name?: string | null,
  gender?: GenderType | null,
  birthDate?: string | null,
  breedingId?: string | null,
  lotsId?: string | null,
  zoneId?: string | null,
  buildingId?: string | null,
  _version?: number | null,
};

export type DeleteAnimalsInput = {
  id: string,
  _version?: number | null,
};

export type CreateLotsInput = {
  id?: string | null,
  numberLot: string,
  name?: string | null,
  buildingId?: string | null,
  breedingId: string,
  _version?: number | null,
};

export type ModelLotsConditionInput = {
  numberLot?: ModelStringInput | null,
  name?: ModelStringInput | null,
  buildingId?: ModelIDInput | null,
  breedingId?: ModelIDInput | null,
  and?: Array< ModelLotsConditionInput | null > | null,
  or?: Array< ModelLotsConditionInput | null > | null,
  not?: ModelLotsConditionInput | null,
};

export type UpdateLotsInput = {
  id: string,
  numberLot?: string | null,
  name?: string | null,
  buildingId?: string | null,
  breedingId?: string | null,
  _version?: number | null,
};

export type DeleteLotsInput = {
  id: string,
  _version?: number | null,
};

export type CreateBuildingsInput = {
  id?: string | null,
  nameBuilding: string,
  address?: AddressInput | null,
  breedingId: string,
  _version?: number | null,
};

export type ModelBuildingsConditionInput = {
  nameBuilding?: ModelStringInput | null,
  breedingId?: ModelIDInput | null,
  and?: Array< ModelBuildingsConditionInput | null > | null,
  or?: Array< ModelBuildingsConditionInput | null > | null,
  not?: ModelBuildingsConditionInput | null,
};

export type UpdateBuildingsInput = {
  id: string,
  nameBuilding?: string | null,
  address?: AddressInput | null,
  breedingId?: string | null,
  _version?: number | null,
};

export type DeleteBuildingsInput = {
  id: string,
  _version?: number | null,
};

export type CreateZonesInput = {
  id?: string | null,
  name?: string | null,
  numberZone?: string | null,
  buildingId?: string | null,
  _version?: number | null,
};

export type ModelZonesConditionInput = {
  name?: ModelStringInput | null,
  numberZone?: ModelStringInput | null,
  buildingId?: ModelIDInput | null,
  and?: Array< ModelZonesConditionInput | null > | null,
  or?: Array< ModelZonesConditionInput | null > | null,
  not?: ModelZonesConditionInput | null,
};

export type UpdateZonesInput = {
  id: string,
  name?: string | null,
  numberZone?: string | null,
  buildingId?: string | null,
  _version?: number | null,
};

export type DeleteZonesInput = {
  id: string,
  _version?: number | null,
};

export type CreateBSEInput = {
  id?: string | null,
  breedingId: string,
  StatusBse?: StatusBse | null,
  dateBSE?: string | null,
  crS3Key?: string | null,
  questionResponse?: string | null,
  lasIdQuestion?: string | null,
  idEleveurUser?: string | null,
  lastQuestionVeto?: string | null,
  questionResponseOrder?: Array< string | null > | null,
  maladieRecurente?: Array< string | null > | null,
  protocolesDeSoins?: string | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type ModelBSEConditionInput = {
  breedingId?: ModelIDInput | null,
  StatusBse?: ModelStatusBseInput | null,
  dateBSE?: ModelStringInput | null,
  crS3Key?: ModelStringInput | null,
  questionResponse?: ModelStringInput | null,
  lasIdQuestion?: ModelStringInput | null,
  idEleveurUser?: ModelStringInput | null,
  lastQuestionVeto?: ModelStringInput | null,
  questionResponseOrder?: ModelStringInput | null,
  maladieRecurente?: ModelStringInput | null,
  protocolesDeSoins?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelBSEConditionInput | null > | null,
  or?: Array< ModelBSEConditionInput | null > | null,
  not?: ModelBSEConditionInput | null,
};

export type ModelStatusBseInput = {
  eq?: StatusBse | null,
  ne?: StatusBse | null,
};

export type UpdateBSEInput = {
  id: string,
  breedingId?: string | null,
  StatusBse?: StatusBse | null,
  dateBSE?: string | null,
  crS3Key?: string | null,
  questionResponse?: string | null,
  lasIdQuestion?: string | null,
  idEleveurUser?: string | null,
  lastQuestionVeto?: string | null,
  questionResponseOrder?: Array< string | null > | null,
  maladieRecurente?: Array< string | null > | null,
  protocolesDeSoins?: string | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type DeleteBSEInput = {
  id: string,
  _version?: number | null,
};

export type CreateDocumentInput = {
  id?: string | null,
  breedingId?: string | null,
  consultationId?: string | null,
  name: string,
  key?: string | null,
  s3file: string,
  createdAt?: string | null,
  _version?: number | null,
};

export type ModelDocumentConditionInput = {
  breedingId?: ModelIDInput | null,
  consultationId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  key?: ModelStringInput | null,
  s3file?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelDocumentConditionInput | null > | null,
  or?: Array< ModelDocumentConditionInput | null > | null,
  not?: ModelDocumentConditionInput | null,
};

export type UpdateDocumentInput = {
  id: string,
  breedingId?: string | null,
  consultationId?: string | null,
  name?: string | null,
  key?: string | null,
  s3file?: string | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type DeleteDocumentInput = {
  id: string,
  _version?: number | null,
};

export type CreateMessageInput = {
  id?: string | null,
  consultationId: string,
  message: string,
  userId: string,
  createdAt?: string | null,
  _version?: number | null,
};

export type ModelMessageConditionInput = {
  consultationId?: ModelIDInput | null,
  message?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelMessageConditionInput | null > | null,
  or?: Array< ModelMessageConditionInput | null > | null,
  not?: ModelMessageConditionInput | null,
};

export type CreatePendingInvitationInput = {
  id?: string | null,
  breedingId: string,
  emailCurrentUser: string,
  email: string,
  createdAt?: string | null,
  initial?: boolean | null,
  refusedAt?: string | null,
  acceptedAt?: string | null,
  _version?: number | null,
};

export type ModelPendingInvitationConditionInput = {
  breedingId?: ModelIDInput | null,
  emailCurrentUser?: ModelStringInput | null,
  email?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  initial?: ModelBooleanInput | null,
  refusedAt?: ModelStringInput | null,
  acceptedAt?: ModelStringInput | null,
  and?: Array< ModelPendingInvitationConditionInput | null > | null,
  or?: Array< ModelPendingInvitationConditionInput | null > | null,
  not?: ModelPendingInvitationConditionInput | null,
};

export type UpdatePendingInvitationInput = {
  id: string,
  breedingId?: string | null,
  emailCurrentUser?: string | null,
  email?: string | null,
  createdAt?: string | null,
  initial?: boolean | null,
  refusedAt?: string | null,
  acceptedAt?: string | null,
  _version?: number | null,
};

export type DeletePendingInvitationInput = {
  id: string,
  _version?: number | null,
};

export type CreateConsultationInput = {
  id?: string | null,
  breedingId: string,
  animalId?: string | null,
  lotId?: string | null,
  diseaseId?: string | null,
  shared?: boolean | null,
  sharedAt?: string | null,
  autoExam?: boolean | null,
  autoExamRes?: AutoExamResInput | null,
  teleconsultation?: boolean | null,
  priceConsultation?: number | null,
  diseaseIsVerified?: Array< string | null > | null,
  isDone?: boolean | null,
  factureId?: string | null,
  sharedFacture?: boolean | null,
  sharedFactureAt?: string | null,
  teleconsAskAt?: string | null,
  dateTeleCons?: string | null,
  questionResponse?: string | null,
  note?: number | null,
  questionResponseOrder?: Array< string | null > | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type AutoExamResInput = {
  reponse1?: string | null,
};

export type ModelConsultationConditionInput = {
  breedingId?: ModelIDInput | null,
  animalId?: ModelIDInput | null,
  lotId?: ModelIDInput | null,
  diseaseId?: ModelIDInput | null,
  shared?: ModelBooleanInput | null,
  sharedAt?: ModelStringInput | null,
  autoExam?: ModelBooleanInput | null,
  teleconsultation?: ModelBooleanInput | null,
  priceConsultation?: ModelFloatInput | null,
  diseaseIsVerified?: ModelStringInput | null,
  isDone?: ModelBooleanInput | null,
  factureId?: ModelIDInput | null,
  sharedFacture?: ModelBooleanInput | null,
  sharedFactureAt?: ModelStringInput | null,
  teleconsAskAt?: ModelStringInput | null,
  dateTeleCons?: ModelStringInput | null,
  questionResponse?: ModelStringInput | null,
  note?: ModelIntInput | null,
  questionResponseOrder?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelConsultationConditionInput | null > | null,
  or?: Array< ModelConsultationConditionInput | null > | null,
  not?: ModelConsultationConditionInput | null,
};

export type UpdateConsultationInput = {
  id: string,
  breedingId?: string | null,
  animalId?: string | null,
  lotId?: string | null,
  diseaseId?: string | null,
  shared?: boolean | null,
  sharedAt?: string | null,
  autoExam?: boolean | null,
  autoExamRes?: AutoExamResInput | null,
  teleconsultation?: boolean | null,
  priceConsultation?: number | null,
  diseaseIsVerified?: Array< string | null > | null,
  isDone?: boolean | null,
  factureId?: string | null,
  sharedFacture?: boolean | null,
  sharedFactureAt?: string | null,
  teleconsAskAt?: string | null,
  dateTeleCons?: string | null,
  questionResponse?: string | null,
  note?: number | null,
  questionResponseOrder?: Array< string | null > | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type DeleteConsultationInput = {
  id: string,
  _version?: number | null,
};

export type CreateDiseaseInput = {
  id?: string | null,
  codeIdentification?: string | null,
  name: string,
  forme?: string | null,
  description: string,
  synonymes?: string | null,
  enjeux?: string | null,
  causes?: string | null,
  circonstancesApparition?: string | null,
  diagnosticDiff?: string | null,
  diagnosticLab?: string | null,
  traitementCuratif?: string | null,
  pronostic?: string | null,
  prevention?: string | null,
  url?: string | null,
  image?: Array< string | null > | null,
  ponderationDisease?: string | null,
  ponderationCount?: number | null,
  familleMaladie?: string | null,
  familleMaladieId?: number | null,
  MRLC?: boolean | null,
  maladieUrgente?: boolean | null,
  _version?: number | null,
};

export type ModelDiseaseConditionInput = {
  codeIdentification?: ModelStringInput | null,
  name?: ModelStringInput | null,
  forme?: ModelStringInput | null,
  description?: ModelStringInput | null,
  synonymes?: ModelStringInput | null,
  enjeux?: ModelStringInput | null,
  causes?: ModelStringInput | null,
  circonstancesApparition?: ModelStringInput | null,
  diagnosticDiff?: ModelStringInput | null,
  diagnosticLab?: ModelStringInput | null,
  traitementCuratif?: ModelStringInput | null,
  pronostic?: ModelStringInput | null,
  prevention?: ModelStringInput | null,
  url?: ModelStringInput | null,
  image?: ModelStringInput | null,
  ponderationDisease?: ModelStringInput | null,
  ponderationCount?: ModelIntInput | null,
  familleMaladie?: ModelStringInput | null,
  familleMaladieId?: ModelIntInput | null,
  MRLC?: ModelBooleanInput | null,
  maladieUrgente?: ModelBooleanInput | null,
  and?: Array< ModelDiseaseConditionInput | null > | null,
  or?: Array< ModelDiseaseConditionInput | null > | null,
  not?: ModelDiseaseConditionInput | null,
};

export type UpdateDiseaseInput = {
  id: string,
  codeIdentification?: string | null,
  name?: string | null,
  forme?: string | null,
  description?: string | null,
  synonymes?: string | null,
  enjeux?: string | null,
  causes?: string | null,
  circonstancesApparition?: string | null,
  diagnosticDiff?: string | null,
  diagnosticLab?: string | null,
  traitementCuratif?: string | null,
  pronostic?: string | null,
  prevention?: string | null,
  url?: string | null,
  image?: Array< string | null > | null,
  ponderationDisease?: string | null,
  ponderationCount?: number | null,
  familleMaladie?: string | null,
  familleMaladieId?: number | null,
  MRLC?: boolean | null,
  maladieUrgente?: boolean | null,
  _version?: number | null,
};

export type DeleteDiseaseInput = {
  id: string,
  _version?: number | null,
};

export type CreateProtocolSoinInput = {
  id?: string | null,
  idDiseaseFamily?: string | null,
  name?: string | null,
  Protocol?: string | null,
  _version?: number | null,
};

export type ModelProtocolSoinConditionInput = {
  idDiseaseFamily?: ModelStringInput | null,
  name?: ModelStringInput | null,
  Protocol?: ModelStringInput | null,
  and?: Array< ModelProtocolSoinConditionInput | null > | null,
  or?: Array< ModelProtocolSoinConditionInput | null > | null,
  not?: ModelProtocolSoinConditionInput | null,
};

export type ProtocolSoin = {
  __typename: "ProtocolSoin",
  id: string,
  idDiseaseFamily?: string | null,
  name?: string | null,
  Protocol?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateProtocolSoinInput = {
  id: string,
  idDiseaseFamily?: string | null,
  name?: string | null,
  Protocol?: string | null,
  _version?: number | null,
};

export type DeleteProtocolSoinInput = {
  id: string,
  _version?: number | null,
};

export type CreateProtocolSoinSaveInput = {
  id?: string | null,
  breedingId?: string | null,
  bseId?: string | null,
  idDiseaseFamily?: string | null,
  new?: boolean | null,
  name?: string | null,
  Protocol?: string | null,
  _version?: number | null,
};

export type ModelProtocolSoinSaveConditionInput = {
  breedingId?: ModelStringInput | null,
  bseId?: ModelStringInput | null,
  idDiseaseFamily?: ModelStringInput | null,
  new?: ModelBooleanInput | null,
  name?: ModelStringInput | null,
  Protocol?: ModelStringInput | null,
  and?: Array< ModelProtocolSoinSaveConditionInput | null > | null,
  or?: Array< ModelProtocolSoinSaveConditionInput | null > | null,
  not?: ModelProtocolSoinSaveConditionInput | null,
};

export type ProtocolSoinSave = {
  __typename: "ProtocolSoinSave",
  id: string,
  breedingId?: string | null,
  bseId?: string | null,
  idDiseaseFamily?: string | null,
  new?: boolean | null,
  name?: string | null,
  Protocol?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateProtocolSoinSaveInput = {
  id: string,
  breedingId?: string | null,
  bseId?: string | null,
  idDiseaseFamily?: string | null,
  new?: boolean | null,
  name?: string | null,
  Protocol?: string | null,
  _version?: number | null,
};

export type DeleteProtocolSoinSaveInput = {
  id: string,
  _version?: number | null,
};

export type CreateNotificationInput = {
  id?: string | null,
  userId: string,
  type: string,
  title: string,
  body: string,
  data?: string | null,
  clicked?: boolean | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type ModelNotificationConditionInput = {
  userId?: ModelIDInput | null,
  type?: ModelStringInput | null,
  title?: ModelStringInput | null,
  body?: ModelStringInput | null,
  data?: ModelStringInput | null,
  clicked?: ModelBooleanInput | null,
  and?: Array< ModelNotificationConditionInput | null > | null,
  or?: Array< ModelNotificationConditionInput | null > | null,
  not?: ModelNotificationConditionInput | null,
};

export type Notification = {
  __typename: "Notification",
  id: string,
  userId: string,
  user?: User | null,
  type: string,
  title: string,
  body: string,
  data?: string | null,
  clicked?: boolean | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateNotificationInput = {
  id: string,
  userId?: string | null,
  type?: string | null,
  title?: string | null,
  body?: string | null,
  data?: string | null,
  clicked?: boolean | null,
  createdAt: string,
  _version?: number | null,
};

export type DeleteNotificationInput = {
  id: string,
  createdAt: string,
  _version?: number | null,
};

export type CreateNotificationTicketsInput = {
  id?: string | null,
  expoTokens?: Array< UserTokenInput > | null,
  ticketIds?: Array< string > | null,
  _version?: number | null,
};

export type UserTokenInput = {
  userId: string,
  token: string,
};

export type ModelNotificationTicketsConditionInput = {
  ticketIds?: ModelStringInput | null,
  and?: Array< ModelNotificationTicketsConditionInput | null > | null,
  or?: Array< ModelNotificationTicketsConditionInput | null > | null,
  not?: ModelNotificationTicketsConditionInput | null,
};

export type NotificationTickets = {
  __typename: "NotificationTickets",
  id: string,
  expoTokens?:  Array<UserToken > | null,
  ticketIds?: Array< string > | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UserToken = {
  __typename: "UserToken",
  userId: string,
  token: string,
};

export type UpdateNotificationTicketsInput = {
  id: string,
  expoTokens?: Array< UserTokenInput > | null,
  ticketIds?: Array< string > | null,
  _version?: number | null,
};

export type DeleteNotificationTicketsInput = {
  id: string,
  _version?: number | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  lastname?: ModelStringInput | null,
  firstname?: ModelStringInput | null,
  avatarUri?: ModelStringInput | null,
  userGroup?: ModelStringInput | null,
  optIn?: ModelBooleanInput | null,
  inscriptionNumberOrdreVeto?: ModelStringInput | null,
  breedingId?: ModelIDInput | null,
  vetOfficeId?: ModelIDInput | null,
  email?: ModelStringInput | null,
  expoToken?: ModelStringInput | null,
  notificationLastSeenAt?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelVetOfficeFilterInput = {
  id?: ModelIDInput | null,
  locked?: ModelLockedStatusInput | null,
  companyName?: ModelStringInput | null,
  emailOffice?: ModelStringInput | null,
  admins?: ModelStringInput | null,
  personnel?: ModelStringInput | null,
  phoneVetOffice?: ModelStringInput | null,
  nameVetSanitaire?: ModelStringInput | null,
  nOrdreCabinet?: ModelStringInput | null,
  nSIRET?: ModelStringInput | null,
  maxPriceTeleConsultation?: ModelFloatInput | null,
  maxPriceConsultation?: ModelFloatInput | null,
  mpUserId?: ModelStringInput | null,
  isFree?: ModelBooleanInput | null,
  mpWalletId?: ModelStringInput | null,
  mpBankId?: ModelStringInput | null,
  mpMandateId?: ModelStringInput | null,
  mpMandateConfirmed?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelVetOfficeFilterInput | null > | null,
  or?: Array< ModelVetOfficeFilterInput | null > | null,
  not?: ModelVetOfficeFilterInput | null,
};

export type ModelVetOfficeConnection = {
  __typename: "ModelVetOfficeConnection",
  items:  Array<VetOffice | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelBreedingFilterInput = {
  id?: ModelIDInput | null,
  locked?: ModelLockedStatusInput | null,
  nBreeding?: ModelStringInput | null,
  nSIRET?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  emailEleveur?: ModelStringInput | null,
  companyName?: ModelStringInput | null,
  dairyFarm?: ModelBooleanInput | null,
  sucklerCowHusbandry?: ModelBooleanInput | null,
  fatteningFarm?: ModelBooleanInput | null,
  nbCow?: ModelFloatInput | null,
  nbHeifer?: ModelFloatInput | null,
  nbMale?: ModelFloatInput | null,
  nbYoung?: ModelFloatInput | null,
  idVeto?: ModelIDInput | null,
  emailVeto?: ModelStringInput | null,
  admins?: ModelStringInput | null,
  mpUserId?: ModelStringInput | null,
  mpWalletId?: ModelStringInput | null,
  mpBankId?: ModelStringInput | null,
  mpMandateId?: ModelStringInput | null,
  mpMandateConfirmed?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  dateFirstCons?: ModelStringInput | null,
  nextSubscriptionDate?: ModelStringInput | null,
  subscriptionAmount?: ModelFloatInput | null,
  and?: Array< ModelBreedingFilterInput | null > | null,
  or?: Array< ModelBreedingFilterInput | null > | null,
  not?: ModelBreedingFilterInput | null,
};

export type ModelBillingHistoryFilterInput = {
  id?: ModelIDInput | null,
  vetOfficeId?: ModelIDInput | null,
  breedingId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  type?: ModelStringInput | null,
  invoiceNumber?: ModelIntInput | null,
  amount?: ModelFloatInput | null,
  label?: ModelStringInput | null,
  paid?: ModelBooleanInput | null,
  and?: Array< ModelBillingHistoryFilterInput | null > | null,
  or?: Array< ModelBillingHistoryFilterInput | null > | null,
  not?: ModelBillingHistoryFilterInput | null,
};

export type ModelAnimalsFilterInput = {
  id?: ModelIDInput | null,
  numberID?: ModelStringInput | null,
  name?: ModelStringInput | null,
  gender?: ModelGenderTypeInput | null,
  birthDate?: ModelStringInput | null,
  breedingId?: ModelIDInput | null,
  lotsId?: ModelIDInput | null,
  zoneId?: ModelIDInput | null,
  buildingId?: ModelIDInput | null,
  and?: Array< ModelAnimalsFilterInput | null > | null,
  or?: Array< ModelAnimalsFilterInput | null > | null,
  not?: ModelAnimalsFilterInput | null,
};

export type ModelLotsFilterInput = {
  id?: ModelIDInput | null,
  numberLot?: ModelStringInput | null,
  name?: ModelStringInput | null,
  buildingId?: ModelIDInput | null,
  breedingId?: ModelIDInput | null,
  and?: Array< ModelLotsFilterInput | null > | null,
  or?: Array< ModelLotsFilterInput | null > | null,
  not?: ModelLotsFilterInput | null,
};

export type ModelBuildingsFilterInput = {
  id?: ModelIDInput | null,
  nameBuilding?: ModelStringInput | null,
  breedingId?: ModelIDInput | null,
  and?: Array< ModelBuildingsFilterInput | null > | null,
  or?: Array< ModelBuildingsFilterInput | null > | null,
  not?: ModelBuildingsFilterInput | null,
};

export type ModelZonesFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  numberZone?: ModelStringInput | null,
  buildingId?: ModelIDInput | null,
  and?: Array< ModelZonesFilterInput | null > | null,
  or?: Array< ModelZonesFilterInput | null > | null,
  not?: ModelZonesFilterInput | null,
};

export type ModelBSEFilterInput = {
  id?: ModelIDInput | null,
  breedingId?: ModelIDInput | null,
  StatusBse?: ModelStatusBseInput | null,
  dateBSE?: ModelStringInput | null,
  crS3Key?: ModelStringInput | null,
  questionResponse?: ModelStringInput | null,
  lasIdQuestion?: ModelStringInput | null,
  idEleveurUser?: ModelStringInput | null,
  lastQuestionVeto?: ModelStringInput | null,
  questionResponseOrder?: ModelStringInput | null,
  maladieRecurente?: ModelStringInput | null,
  protocolesDeSoins?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelBSEFilterInput | null > | null,
  or?: Array< ModelBSEFilterInput | null > | null,
  not?: ModelBSEFilterInput | null,
};

export type ModelDocumentFilterInput = {
  id?: ModelIDInput | null,
  breedingId?: ModelIDInput | null,
  consultationId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  key?: ModelStringInput | null,
  s3file?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelDocumentFilterInput | null > | null,
  or?: Array< ModelDocumentFilterInput | null > | null,
  not?: ModelDocumentFilterInput | null,
};

export type ModelMessageFilterInput = {
  id?: ModelIDInput | null,
  consultationId?: ModelIDInput | null,
  message?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelMessageFilterInput | null > | null,
  or?: Array< ModelMessageFilterInput | null > | null,
  not?: ModelMessageFilterInput | null,
};

export type ModelPendingInvitationFilterInput = {
  id?: ModelIDInput | null,
  breedingId?: ModelIDInput | null,
  emailCurrentUser?: ModelStringInput | null,
  email?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  initial?: ModelBooleanInput | null,
  refusedAt?: ModelStringInput | null,
  acceptedAt?: ModelStringInput | null,
  and?: Array< ModelPendingInvitationFilterInput | null > | null,
  or?: Array< ModelPendingInvitationFilterInput | null > | null,
  not?: ModelPendingInvitationFilterInput | null,
};

export type ModelConsultationFilterInput = {
  id?: ModelIDInput | null,
  breedingId?: ModelIDInput | null,
  animalId?: ModelIDInput | null,
  lotId?: ModelIDInput | null,
  diseaseId?: ModelIDInput | null,
  shared?: ModelBooleanInput | null,
  sharedAt?: ModelStringInput | null,
  autoExam?: ModelBooleanInput | null,
  teleconsultation?: ModelBooleanInput | null,
  priceConsultation?: ModelFloatInput | null,
  diseaseIsVerified?: ModelStringInput | null,
  isDone?: ModelBooleanInput | null,
  factureId?: ModelIDInput | null,
  sharedFacture?: ModelBooleanInput | null,
  sharedFactureAt?: ModelStringInput | null,
  teleconsAskAt?: ModelStringInput | null,
  dateTeleCons?: ModelStringInput | null,
  questionResponse?: ModelStringInput | null,
  note?: ModelIntInput | null,
  questionResponseOrder?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelConsultationFilterInput | null > | null,
  or?: Array< ModelConsultationFilterInput | null > | null,
  not?: ModelConsultationFilterInput | null,
};

export type ModelDiseaseFilterInput = {
  id?: ModelIDInput | null,
  codeIdentification?: ModelStringInput | null,
  name?: ModelStringInput | null,
  forme?: ModelStringInput | null,
  description?: ModelStringInput | null,
  synonymes?: ModelStringInput | null,
  enjeux?: ModelStringInput | null,
  causes?: ModelStringInput | null,
  circonstancesApparition?: ModelStringInput | null,
  diagnosticDiff?: ModelStringInput | null,
  diagnosticLab?: ModelStringInput | null,
  traitementCuratif?: ModelStringInput | null,
  pronostic?: ModelStringInput | null,
  prevention?: ModelStringInput | null,
  url?: ModelStringInput | null,
  image?: ModelStringInput | null,
  ponderationDisease?: ModelStringInput | null,
  ponderationCount?: ModelIntInput | null,
  familleMaladie?: ModelStringInput | null,
  familleMaladieId?: ModelIntInput | null,
  MRLC?: ModelBooleanInput | null,
  maladieUrgente?: ModelBooleanInput | null,
  and?: Array< ModelDiseaseFilterInput | null > | null,
  or?: Array< ModelDiseaseFilterInput | null > | null,
  not?: ModelDiseaseFilterInput | null,
};

export type ModelDiseaseConnection = {
  __typename: "ModelDiseaseConnection",
  items:  Array<disease | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModeldiseaseFilterInput = {
  id?: ModelIDInput | null,
  codeIdentification?: ModelStringInput | null,
  name?: ModelStringInput | null,
  forme?: ModelStringInput | null,
  description?: ModelStringInput | null,
  synonymes?: ModelStringInput | null,
  enjeux?: ModelStringInput | null,
  causes?: ModelStringInput | null,
  circonstancesApparition?: ModelStringInput | null,
  diagnosticDiff?: ModelStringInput | null,
  diagnosticLab?: ModelStringInput | null,
  traitementCuratif?: ModelStringInput | null,
  pronostic?: ModelStringInput | null,
  prevention?: ModelStringInput | null,
  url?: ModelStringInput | null,
  image?: ModelStringInput | null,
  ponderationDisease?: ModelStringInput | null,
  ponderationCount?: ModelIntInput | null,
  familleMaladie?: ModelStringInput | null,
  familleMaladieId?: ModelIntInput | null,
  MRLC?: ModelBooleanInput | null,
  maladieUrgente?: ModelBooleanInput | null,
  and?: Array< ModeldiseaseFilterInput | null > | null,
  or?: Array< ModeldiseaseFilterInput | null > | null,
  not?: ModeldiseaseFilterInput | null,
};

export type ModeldiseaseConnection = {
  __typename: "ModeldiseaseConnection",
  items:  Array<disease | null >,
  nextToken?: string | null,
};

export type ModelProtocolSoinFilterInput = {
  id?: ModelIDInput | null,
  idDiseaseFamily?: ModelStringInput | null,
  name?: ModelStringInput | null,
  Protocol?: ModelStringInput | null,
  and?: Array< ModelProtocolSoinFilterInput | null > | null,
  or?: Array< ModelProtocolSoinFilterInput | null > | null,
  not?: ModelProtocolSoinFilterInput | null,
};

export type ModelProtocolSoinConnection = {
  __typename: "ModelProtocolSoinConnection",
  items:  Array<ProtocolSoin | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelProtocolSoinSaveFilterInput = {
  id?: ModelIDInput | null,
  breedingId?: ModelStringInput | null,
  bseId?: ModelStringInput | null,
  idDiseaseFamily?: ModelStringInput | null,
  new?: ModelBooleanInput | null,
  name?: ModelStringInput | null,
  Protocol?: ModelStringInput | null,
  and?: Array< ModelProtocolSoinSaveFilterInput | null > | null,
  or?: Array< ModelProtocolSoinSaveFilterInput | null > | null,
  not?: ModelProtocolSoinSaveFilterInput | null,
};

export type ModelProtocolSoinSaveConnection = {
  __typename: "ModelProtocolSoinSaveConnection",
  items:  Array<ProtocolSoinSave | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelNotificationFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  type?: ModelStringInput | null,
  title?: ModelStringInput | null,
  body?: ModelStringInput | null,
  data?: ModelStringInput | null,
  clicked?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelNotificationFilterInput | null > | null,
  or?: Array< ModelNotificationFilterInput | null > | null,
  not?: ModelNotificationFilterInput | null,
};

export type ModelNotificationConnection = {
  __typename: "ModelNotificationConnection",
  items:  Array<Notification | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelNotificationTicketsFilterInput = {
  id?: ModelIDInput | null,
  ticketIds?: ModelStringInput | null,
  and?: Array< ModelNotificationTicketsFilterInput | null > | null,
  or?: Array< ModelNotificationTicketsFilterInput | null > | null,
  not?: ModelNotificationTicketsFilterInput | null,
};

export type ModelNotificationTicketsConnection = {
  __typename: "ModelNotificationTicketsConnection",
  items:  Array<NotificationTickets | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelSubscriptionUserFilterInput = {
  lastname?: ModelSubscriptionStringInput | null,
  firstname?: ModelSubscriptionStringInput | null,
  avatarUri?: ModelSubscriptionStringInput | null,
  userGroup?: ModelSubscriptionStringInput | null,
  optIn?: ModelSubscriptionBooleanInput | null,
  inscriptionNumberOrdreVeto?: ModelSubscriptionStringInput | null,
  breedingId?: ModelSubscriptionIDInput | null,
  vetOfficeId?: ModelSubscriptionIDInput | null,
  email?: ModelSubscriptionStringInput | null,
  expoToken?: ModelSubscriptionStringInput | null,
  notificationLastSeenAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionVetOfficeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  locked?: ModelSubscriptionStringInput | null,
  companyName?: ModelSubscriptionStringInput | null,
  emailOffice?: ModelSubscriptionStringInput | null,
  phoneVetOffice?: ModelSubscriptionStringInput | null,
  nameVetSanitaire?: ModelSubscriptionStringInput | null,
  nOrdreCabinet?: ModelSubscriptionStringInput | null,
  nSIRET?: ModelSubscriptionStringInput | null,
  maxPriceTeleConsultation?: ModelSubscriptionFloatInput | null,
  maxPriceConsultation?: ModelSubscriptionFloatInput | null,
  mpUserId?: ModelSubscriptionStringInput | null,
  isFree?: ModelSubscriptionBooleanInput | null,
  mpWalletId?: ModelSubscriptionStringInput | null,
  mpBankId?: ModelSubscriptionStringInput | null,
  mpMandateId?: ModelSubscriptionStringInput | null,
  mpMandateConfirmed?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionVetOfficeFilterInput | null > | null,
  or?: Array< ModelSubscriptionVetOfficeFilterInput | null > | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionBreedingFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  locked?: ModelSubscriptionStringInput | null,
  nBreeding?: ModelSubscriptionStringInput | null,
  nSIRET?: ModelSubscriptionStringInput | null,
  phone?: ModelSubscriptionStringInput | null,
  emailEleveur?: ModelSubscriptionStringInput | null,
  companyName?: ModelSubscriptionStringInput | null,
  dairyFarm?: ModelSubscriptionBooleanInput | null,
  sucklerCowHusbandry?: ModelSubscriptionBooleanInput | null,
  fatteningFarm?: ModelSubscriptionBooleanInput | null,
  nbCow?: ModelSubscriptionFloatInput | null,
  nbHeifer?: ModelSubscriptionFloatInput | null,
  nbMale?: ModelSubscriptionFloatInput | null,
  nbYoung?: ModelSubscriptionFloatInput | null,
  idVeto?: ModelSubscriptionIDInput | null,
  emailVeto?: ModelSubscriptionStringInput | null,
  mpUserId?: ModelSubscriptionStringInput | null,
  mpWalletId?: ModelSubscriptionStringInput | null,
  mpBankId?: ModelSubscriptionStringInput | null,
  mpMandateId?: ModelSubscriptionStringInput | null,
  mpMandateConfirmed?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  dateFirstCons?: ModelSubscriptionStringInput | null,
  nextSubscriptionDate?: ModelSubscriptionStringInput | null,
  subscriptionAmount?: ModelSubscriptionFloatInput | null,
  and?: Array< ModelSubscriptionBreedingFilterInput | null > | null,
  or?: Array< ModelSubscriptionBreedingFilterInput | null > | null,
};

export type ModelSubscriptionAnimalsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  numberID?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  gender?: ModelSubscriptionStringInput | null,
  birthDate?: ModelSubscriptionStringInput | null,
  breedingId?: ModelSubscriptionIDInput | null,
  lotsId?: ModelSubscriptionIDInput | null,
  zoneId?: ModelSubscriptionIDInput | null,
  buildingId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionAnimalsFilterInput | null > | null,
  or?: Array< ModelSubscriptionAnimalsFilterInput | null > | null,
};

export type ModelSubscriptionLotsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  numberLot?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  buildingId?: ModelSubscriptionIDInput | null,
  breedingId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionLotsFilterInput | null > | null,
  or?: Array< ModelSubscriptionLotsFilterInput | null > | null,
};

export type ModelSubscriptionBuildingsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  nameBuilding?: ModelSubscriptionStringInput | null,
  breedingId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionBuildingsFilterInput | null > | null,
  or?: Array< ModelSubscriptionBuildingsFilterInput | null > | null,
};

export type ModelSubscriptionZonesFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  numberZone?: ModelSubscriptionStringInput | null,
  buildingId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionZonesFilterInput | null > | null,
  or?: Array< ModelSubscriptionZonesFilterInput | null > | null,
};

export type ModelSubscriptionBSEFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  breedingId?: ModelSubscriptionIDInput | null,
  StatusBse?: ModelSubscriptionStringInput | null,
  dateBSE?: ModelSubscriptionStringInput | null,
  crS3Key?: ModelSubscriptionStringInput | null,
  questionResponse?: ModelSubscriptionStringInput | null,
  lasIdQuestion?: ModelSubscriptionStringInput | null,
  idEleveurUser?: ModelSubscriptionStringInput | null,
  lastQuestionVeto?: ModelSubscriptionStringInput | null,
  questionResponseOrder?: ModelSubscriptionStringInput | null,
  maladieRecurente?: ModelSubscriptionStringInput | null,
  protocolesDeSoins?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBSEFilterInput | null > | null,
  or?: Array< ModelSubscriptionBSEFilterInput | null > | null,
};

export type ModelSubscriptionPendingInvitationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  breedingId?: ModelSubscriptionIDInput | null,
  emailCurrentUser?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  initial?: ModelSubscriptionBooleanInput | null,
  refusedAt?: ModelSubscriptionStringInput | null,
  acceptedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPendingInvitationFilterInput | null > | null,
  or?: Array< ModelSubscriptionPendingInvitationFilterInput | null > | null,
};

export type ModelSubscriptionConsultationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  breedingId?: ModelSubscriptionIDInput | null,
  animalId?: ModelSubscriptionIDInput | null,
  lotId?: ModelSubscriptionIDInput | null,
  diseaseId?: ModelSubscriptionIDInput | null,
  shared?: ModelSubscriptionBooleanInput | null,
  sharedAt?: ModelSubscriptionStringInput | null,
  autoExam?: ModelSubscriptionBooleanInput | null,
  teleconsultation?: ModelSubscriptionBooleanInput | null,
  priceConsultation?: ModelSubscriptionFloatInput | null,
  diseaseIsVerified?: ModelSubscriptionStringInput | null,
  isDone?: ModelSubscriptionBooleanInput | null,
  factureId?: ModelSubscriptionIDInput | null,
  sharedFacture?: ModelSubscriptionBooleanInput | null,
  sharedFactureAt?: ModelSubscriptionStringInput | null,
  teleconsAskAt?: ModelSubscriptionStringInput | null,
  dateTeleCons?: ModelSubscriptionStringInput | null,
  questionResponse?: ModelSubscriptionStringInput | null,
  note?: ModelSubscriptionIntInput | null,
  questionResponseOrder?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionConsultationFilterInput | null > | null,
  or?: Array< ModelSubscriptionConsultationFilterInput | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionDiseaseFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  codeIdentification?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  forme?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  synonymes?: ModelSubscriptionStringInput | null,
  enjeux?: ModelSubscriptionStringInput | null,
  causes?: ModelSubscriptionStringInput | null,
  circonstancesApparition?: ModelSubscriptionStringInput | null,
  diagnosticDiff?: ModelSubscriptionStringInput | null,
  diagnosticLab?: ModelSubscriptionStringInput | null,
  traitementCuratif?: ModelSubscriptionStringInput | null,
  pronostic?: ModelSubscriptionStringInput | null,
  prevention?: ModelSubscriptionStringInput | null,
  url?: ModelSubscriptionStringInput | null,
  image?: ModelSubscriptionStringInput | null,
  ponderationDisease?: ModelSubscriptionStringInput | null,
  ponderationCount?: ModelSubscriptionIntInput | null,
  familleMaladie?: ModelSubscriptionStringInput | null,
  familleMaladieId?: ModelSubscriptionIntInput | null,
  MRLC?: ModelSubscriptionBooleanInput | null,
  maladieUrgente?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionDiseaseFilterInput | null > | null,
  or?: Array< ModelSubscriptionDiseaseFilterInput | null > | null,
};

export type ModelSubscriptionProtocolSoinFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  idDiseaseFamily?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  Protocol?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProtocolSoinFilterInput | null > | null,
  or?: Array< ModelSubscriptionProtocolSoinFilterInput | null > | null,
};

export type ModelSubscriptionProtocolSoinSaveFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  breedingId?: ModelSubscriptionStringInput | null,
  bseId?: ModelSubscriptionStringInput | null,
  idDiseaseFamily?: ModelSubscriptionStringInput | null,
  new?: ModelSubscriptionBooleanInput | null,
  name?: ModelSubscriptionStringInput | null,
  Protocol?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProtocolSoinSaveFilterInput | null > | null,
  or?: Array< ModelSubscriptionProtocolSoinSaveFilterInput | null > | null,
};

export type ModelSubscriptionNotificationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  body?: ModelSubscriptionStringInput | null,
  data?: ModelSubscriptionStringInput | null,
  clicked?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionNotificationFilterInput | null > | null,
  or?: Array< ModelSubscriptionNotificationFilterInput | null > | null,
};

export type ModelSubscriptionNotificationTicketsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  ticketIds?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionNotificationTicketsFilterInput | null > | null,
  or?: Array< ModelSubscriptionNotificationTicketsFilterInput | null > | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    lastname?: string | null,
    firstname?: string | null,
    avatarUri?: string | null,
    userGroup?: string | null,
    optIn?: boolean | null,
    inscriptionNumberOrdreVeto?: string | null,
    breedingId?: string | null,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    vetOfficeId?: string | null,
    vetOffice?:  {
      __typename: "VetOffice",
      id: string,
      locked: LockedStatus,
      companyName: string,
      emailOffice: string,
      admins: Array< string >,
      personnel?: Array< string > | null,
      phoneVetOffice?: string | null,
      nameVetSanitaire?: string | null,
      nOrdreCabinet: string,
      nSIRET: string,
      maxPriceTeleConsultation: number,
      maxPriceConsultation: number,
      mpUserId?: string | null,
      isFree?: boolean | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    email?: string | null,
    expoToken?: Array< string > | null,
    notificationLastSeenAt?: string | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    lastname?: string | null,
    firstname?: string | null,
    avatarUri?: string | null,
    userGroup?: string | null,
    optIn?: boolean | null,
    inscriptionNumberOrdreVeto?: string | null,
    breedingId?: string | null,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    vetOfficeId?: string | null,
    vetOffice?:  {
      __typename: "VetOffice",
      id: string,
      locked: LockedStatus,
      companyName: string,
      emailOffice: string,
      admins: Array< string >,
      personnel?: Array< string > | null,
      phoneVetOffice?: string | null,
      nameVetSanitaire?: string | null,
      nOrdreCabinet: string,
      nSIRET: string,
      maxPriceTeleConsultation: number,
      maxPriceConsultation: number,
      mpUserId?: string | null,
      isFree?: boolean | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    email?: string | null,
    expoToken?: Array< string > | null,
    notificationLastSeenAt?: string | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    lastname?: string | null,
    firstname?: string | null,
    avatarUri?: string | null,
    userGroup?: string | null,
    optIn?: boolean | null,
    inscriptionNumberOrdreVeto?: string | null,
    breedingId?: string | null,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    vetOfficeId?: string | null,
    vetOffice?:  {
      __typename: "VetOffice",
      id: string,
      locked: LockedStatus,
      companyName: string,
      emailOffice: string,
      admins: Array< string >,
      personnel?: Array< string > | null,
      phoneVetOffice?: string | null,
      nameVetSanitaire?: string | null,
      nOrdreCabinet: string,
      nSIRET: string,
      maxPriceTeleConsultation: number,
      maxPriceConsultation: number,
      mpUserId?: string | null,
      isFree?: boolean | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    email?: string | null,
    expoToken?: Array< string > | null,
    notificationLastSeenAt?: string | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateVetOfficeMutationVariables = {
  input: CreateVetOfficeInput,
  condition?: ModelVetOfficeConditionInput | null,
};

export type CreateVetOfficeMutation = {
  createVetOffice?:  {
    __typename: "VetOffice",
    id: string,
    locked: LockedStatus,
    companyName: string,
    emailOffice: string,
    admins: Array< string >,
    personnel?: Array< string > | null,
    phoneVetOffice?: string | null,
    address?:  {
      __typename: "Address",
      address: string,
      additionalAddress?: string | null,
      postalCode: string,
      city: string,
    } | null,
    nameVetSanitaire?: string | null,
    nOrdreCabinet: string,
    nSIRET: string,
    maxPriceTeleConsultation: number,
    maxPriceConsultation: number,
    users?:  {
      __typename: "ModelUserConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    breedings?:  {
      __typename: "ModelBreedingConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    billingHistories?:  {
      __typename: "ModelBillingHistoryConnection",
      nextToken?: string | null,
    } | null,
    mpUserId?: string | null,
    isFree?: boolean | null,
    mpWalletId?: string | null,
    mpBankId?: string | null,
    mpMandateId?: string | null,
    mpMandateConfirmed?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateVetOfficeMutationVariables = {
  input: UpdateVetOfficeInput,
  condition?: ModelVetOfficeConditionInput | null,
};

export type UpdateVetOfficeMutation = {
  updateVetOffice?:  {
    __typename: "VetOffice",
    id: string,
    locked: LockedStatus,
    companyName: string,
    emailOffice: string,
    admins: Array< string >,
    personnel?: Array< string > | null,
    phoneVetOffice?: string | null,
    address?:  {
      __typename: "Address",
      address: string,
      additionalAddress?: string | null,
      postalCode: string,
      city: string,
    } | null,
    nameVetSanitaire?: string | null,
    nOrdreCabinet: string,
    nSIRET: string,
    maxPriceTeleConsultation: number,
    maxPriceConsultation: number,
    users?:  {
      __typename: "ModelUserConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    breedings?:  {
      __typename: "ModelBreedingConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    billingHistories?:  {
      __typename: "ModelBillingHistoryConnection",
      nextToken?: string | null,
    } | null,
    mpUserId?: string | null,
    isFree?: boolean | null,
    mpWalletId?: string | null,
    mpBankId?: string | null,
    mpMandateId?: string | null,
    mpMandateConfirmed?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteVetOfficeMutationVariables = {
  input: DeleteVetOfficeInput,
  condition?: ModelVetOfficeConditionInput | null,
};

export type DeleteVetOfficeMutation = {
  deleteVetOffice?:  {
    __typename: "VetOffice",
    id: string,
    locked: LockedStatus,
    companyName: string,
    emailOffice: string,
    admins: Array< string >,
    personnel?: Array< string > | null,
    phoneVetOffice?: string | null,
    address?:  {
      __typename: "Address",
      address: string,
      additionalAddress?: string | null,
      postalCode: string,
      city: string,
    } | null,
    nameVetSanitaire?: string | null,
    nOrdreCabinet: string,
    nSIRET: string,
    maxPriceTeleConsultation: number,
    maxPriceConsultation: number,
    users?:  {
      __typename: "ModelUserConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    breedings?:  {
      __typename: "ModelBreedingConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    billingHistories?:  {
      __typename: "ModelBillingHistoryConnection",
      nextToken?: string | null,
    } | null,
    mpUserId?: string | null,
    isFree?: boolean | null,
    mpWalletId?: string | null,
    mpBankId?: string | null,
    mpMandateId?: string | null,
    mpMandateConfirmed?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateBreedingMutationVariables = {
  input: CreateBreedingInput,
  condition?: ModelBreedingConditionInput | null,
};

export type CreateBreedingMutation = {
  createBreeding?:  {
    __typename: "Breeding",
    id: string,
    locked: LockedStatus,
    nBreeding: string,
    nSIRET: string,
    phone: string,
    emailEleveur: string,
    address?:  {
      __typename: "Address",
      address: string,
      additionalAddress?: string | null,
      postalCode: string,
      city: string,
    } | null,
    companyName: string,
    dairyFarm: boolean,
    sucklerCowHusbandry: boolean,
    fatteningFarm: boolean,
    nbCow: number,
    nbHeifer: number,
    nbMale: number,
    nbYoung: number,
    idVeto?: string | null,
    emailVeto?: string | null,
    admins: Array< string >,
    vetOffice?:  {
      __typename: "VetOffice",
      id: string,
      locked: LockedStatus,
      companyName: string,
      emailOffice: string,
      admins: Array< string >,
      personnel?: Array< string > | null,
      phoneVetOffice?: string | null,
      nameVetSanitaire?: string | null,
      nOrdreCabinet: string,
      nSIRET: string,
      maxPriceTeleConsultation: number,
      maxPriceConsultation: number,
      mpUserId?: string | null,
      isFree?: boolean | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    users?:  {
      __typename: "ModelUserConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      nextToken?: string | null,
    } | null,
    animals?:  {
      __typename: "ModelAnimalsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    lots?:  {
      __typename: "ModelLotsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    buildings?:  {
      __typename: "ModelBuildingsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    bse?:  {
      __typename: "ModelBSEConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    pendingInvitations?:  {
      __typename: "ModelPendingInvitationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    consultations?:  {
      __typename: "ModelConsultationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    billingHistories?:  {
      __typename: "ModelBillingHistoryConnection",
      nextToken?: string | null,
    } | null,
    mpUserId?: string | null,
    mpWalletId?: string | null,
    mpBankId?: string | null,
    mpMandateId?: string | null,
    mpMandateConfirmed?: boolean | null,
    createdAt: string,
    dateFirstCons?: string | null,
    nextSubscriptionDate?: string | null,
    subscriptionAmount?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateBreedingMutationVariables = {
  input: UpdateBreedingInput,
  condition?: ModelBreedingConditionInput | null,
};

export type UpdateBreedingMutation = {
  updateBreeding?:  {
    __typename: "Breeding",
    id: string,
    locked: LockedStatus,
    nBreeding: string,
    nSIRET: string,
    phone: string,
    emailEleveur: string,
    address?:  {
      __typename: "Address",
      address: string,
      additionalAddress?: string | null,
      postalCode: string,
      city: string,
    } | null,
    companyName: string,
    dairyFarm: boolean,
    sucklerCowHusbandry: boolean,
    fatteningFarm: boolean,
    nbCow: number,
    nbHeifer: number,
    nbMale: number,
    nbYoung: number,
    idVeto?: string | null,
    emailVeto?: string | null,
    admins: Array< string >,
    vetOffice?:  {
      __typename: "VetOffice",
      id: string,
      locked: LockedStatus,
      companyName: string,
      emailOffice: string,
      admins: Array< string >,
      personnel?: Array< string > | null,
      phoneVetOffice?: string | null,
      nameVetSanitaire?: string | null,
      nOrdreCabinet: string,
      nSIRET: string,
      maxPriceTeleConsultation: number,
      maxPriceConsultation: number,
      mpUserId?: string | null,
      isFree?: boolean | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    users?:  {
      __typename: "ModelUserConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      nextToken?: string | null,
    } | null,
    animals?:  {
      __typename: "ModelAnimalsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    lots?:  {
      __typename: "ModelLotsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    buildings?:  {
      __typename: "ModelBuildingsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    bse?:  {
      __typename: "ModelBSEConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    pendingInvitations?:  {
      __typename: "ModelPendingInvitationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    consultations?:  {
      __typename: "ModelConsultationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    billingHistories?:  {
      __typename: "ModelBillingHistoryConnection",
      nextToken?: string | null,
    } | null,
    mpUserId?: string | null,
    mpWalletId?: string | null,
    mpBankId?: string | null,
    mpMandateId?: string | null,
    mpMandateConfirmed?: boolean | null,
    createdAt: string,
    dateFirstCons?: string | null,
    nextSubscriptionDate?: string | null,
    subscriptionAmount?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteBreedingMutationVariables = {
  input: DeleteBreedingInput,
  condition?: ModelBreedingConditionInput | null,
};

export type DeleteBreedingMutation = {
  deleteBreeding?:  {
    __typename: "Breeding",
    id: string,
    locked: LockedStatus,
    nBreeding: string,
    nSIRET: string,
    phone: string,
    emailEleveur: string,
    address?:  {
      __typename: "Address",
      address: string,
      additionalAddress?: string | null,
      postalCode: string,
      city: string,
    } | null,
    companyName: string,
    dairyFarm: boolean,
    sucklerCowHusbandry: boolean,
    fatteningFarm: boolean,
    nbCow: number,
    nbHeifer: number,
    nbMale: number,
    nbYoung: number,
    idVeto?: string | null,
    emailVeto?: string | null,
    admins: Array< string >,
    vetOffice?:  {
      __typename: "VetOffice",
      id: string,
      locked: LockedStatus,
      companyName: string,
      emailOffice: string,
      admins: Array< string >,
      personnel?: Array< string > | null,
      phoneVetOffice?: string | null,
      nameVetSanitaire?: string | null,
      nOrdreCabinet: string,
      nSIRET: string,
      maxPriceTeleConsultation: number,
      maxPriceConsultation: number,
      mpUserId?: string | null,
      isFree?: boolean | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    users?:  {
      __typename: "ModelUserConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      nextToken?: string | null,
    } | null,
    animals?:  {
      __typename: "ModelAnimalsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    lots?:  {
      __typename: "ModelLotsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    buildings?:  {
      __typename: "ModelBuildingsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    bse?:  {
      __typename: "ModelBSEConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    pendingInvitations?:  {
      __typename: "ModelPendingInvitationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    consultations?:  {
      __typename: "ModelConsultationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    billingHistories?:  {
      __typename: "ModelBillingHistoryConnection",
      nextToken?: string | null,
    } | null,
    mpUserId?: string | null,
    mpWalletId?: string | null,
    mpBankId?: string | null,
    mpMandateId?: string | null,
    mpMandateConfirmed?: boolean | null,
    createdAt: string,
    dateFirstCons?: string | null,
    nextSubscriptionDate?: string | null,
    subscriptionAmount?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateBillingHistoryMutationVariables = {
  input: CreateBillingHistoryInput,
  condition?: ModelBillingHistoryConditionInput | null,
};

export type CreateBillingHistoryMutation = {
  createBillingHistory?:  {
    __typename: "BillingHistory",
    id: string,
    vetOfficeId?: string | null,
    vetOffice?:  {
      __typename: "VetOffice",
      id: string,
      locked: LockedStatus,
      companyName: string,
      emailOffice: string,
      admins: Array< string >,
      personnel?: Array< string > | null,
      phoneVetOffice?: string | null,
      nameVetSanitaire?: string | null,
      nOrdreCabinet: string,
      nSIRET: string,
      maxPriceTeleConsultation: number,
      maxPriceConsultation: number,
      mpUserId?: string | null,
      isFree?: boolean | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    breedingId?: string | null,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    type: string,
    invoiceNumber?: number | null,
    amount: number,
    label: string,
    paid?: boolean | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAnimalsMutationVariables = {
  input: CreateAnimalsInput,
  condition?: ModelAnimalsConditionInput | null,
};

export type CreateAnimalsMutation = {
  createAnimals?:  {
    __typename: "Animals",
    id: string,
    numberID: string,
    name?: string | null,
    gender?: GenderType | null,
    birthDate?: string | null,
    breedingId: string,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    lotsId?: string | null,
    lots?:  {
      __typename: "Lots",
      id: string,
      numberLot: string,
      name?: string | null,
      buildingId?: string | null,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    consultations?:  {
      __typename: "ModelConsultationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    zoneId?: string | null,
    zone?:  {
      __typename: "Zones",
      id: string,
      name?: string | null,
      numberZone?: string | null,
      buildingId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Buildings",
      id: string,
      nameBuilding: string,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAnimalsMutationVariables = {
  input: UpdateAnimalsInput,
  condition?: ModelAnimalsConditionInput | null,
};

export type UpdateAnimalsMutation = {
  updateAnimals?:  {
    __typename: "Animals",
    id: string,
    numberID: string,
    name?: string | null,
    gender?: GenderType | null,
    birthDate?: string | null,
    breedingId: string,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    lotsId?: string | null,
    lots?:  {
      __typename: "Lots",
      id: string,
      numberLot: string,
      name?: string | null,
      buildingId?: string | null,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    consultations?:  {
      __typename: "ModelConsultationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    zoneId?: string | null,
    zone?:  {
      __typename: "Zones",
      id: string,
      name?: string | null,
      numberZone?: string | null,
      buildingId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Buildings",
      id: string,
      nameBuilding: string,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAnimalsMutationVariables = {
  input: DeleteAnimalsInput,
  condition?: ModelAnimalsConditionInput | null,
};

export type DeleteAnimalsMutation = {
  deleteAnimals?:  {
    __typename: "Animals",
    id: string,
    numberID: string,
    name?: string | null,
    gender?: GenderType | null,
    birthDate?: string | null,
    breedingId: string,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    lotsId?: string | null,
    lots?:  {
      __typename: "Lots",
      id: string,
      numberLot: string,
      name?: string | null,
      buildingId?: string | null,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    consultations?:  {
      __typename: "ModelConsultationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    zoneId?: string | null,
    zone?:  {
      __typename: "Zones",
      id: string,
      name?: string | null,
      numberZone?: string | null,
      buildingId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Buildings",
      id: string,
      nameBuilding: string,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateLotsMutationVariables = {
  input: CreateLotsInput,
  condition?: ModelLotsConditionInput | null,
};

export type CreateLotsMutation = {
  createLots?:  {
    __typename: "Lots",
    id: string,
    numberLot: string,
    name?: string | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Buildings",
      id: string,
      nameBuilding: string,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    breedingId: string,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    animals?:  {
      __typename: "ModelAnimalsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    consultations?:  {
      __typename: "ModelConsultationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateLotsMutationVariables = {
  input: UpdateLotsInput,
  condition?: ModelLotsConditionInput | null,
};

export type UpdateLotsMutation = {
  updateLots?:  {
    __typename: "Lots",
    id: string,
    numberLot: string,
    name?: string | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Buildings",
      id: string,
      nameBuilding: string,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    breedingId: string,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    animals?:  {
      __typename: "ModelAnimalsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    consultations?:  {
      __typename: "ModelConsultationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteLotsMutationVariables = {
  input: DeleteLotsInput,
  condition?: ModelLotsConditionInput | null,
};

export type DeleteLotsMutation = {
  deleteLots?:  {
    __typename: "Lots",
    id: string,
    numberLot: string,
    name?: string | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Buildings",
      id: string,
      nameBuilding: string,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    breedingId: string,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    animals?:  {
      __typename: "ModelAnimalsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    consultations?:  {
      __typename: "ModelConsultationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateBuildingsMutationVariables = {
  input: CreateBuildingsInput,
  condition?: ModelBuildingsConditionInput | null,
};

export type CreateBuildingsMutation = {
  createBuildings?:  {
    __typename: "Buildings",
    id: string,
    nameBuilding: string,
    address?:  {
      __typename: "Address",
      address: string,
      additionalAddress?: string | null,
      postalCode: string,
      city: string,
    } | null,
    breedingId: string,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    zone?:  {
      __typename: "ModelZonesConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    animals?:  {
      __typename: "ModelAnimalsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    lots?:  {
      __typename: "ModelLotsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateBuildingsMutationVariables = {
  input: UpdateBuildingsInput,
  condition?: ModelBuildingsConditionInput | null,
};

export type UpdateBuildingsMutation = {
  updateBuildings?:  {
    __typename: "Buildings",
    id: string,
    nameBuilding: string,
    address?:  {
      __typename: "Address",
      address: string,
      additionalAddress?: string | null,
      postalCode: string,
      city: string,
    } | null,
    breedingId: string,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    zone?:  {
      __typename: "ModelZonesConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    animals?:  {
      __typename: "ModelAnimalsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    lots?:  {
      __typename: "ModelLotsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteBuildingsMutationVariables = {
  input: DeleteBuildingsInput,
  condition?: ModelBuildingsConditionInput | null,
};

export type DeleteBuildingsMutation = {
  deleteBuildings?:  {
    __typename: "Buildings",
    id: string,
    nameBuilding: string,
    address?:  {
      __typename: "Address",
      address: string,
      additionalAddress?: string | null,
      postalCode: string,
      city: string,
    } | null,
    breedingId: string,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    zone?:  {
      __typename: "ModelZonesConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    animals?:  {
      __typename: "ModelAnimalsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    lots?:  {
      __typename: "ModelLotsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateZonesMutationVariables = {
  input: CreateZonesInput,
  condition?: ModelZonesConditionInput | null,
};

export type CreateZonesMutation = {
  createZones?:  {
    __typename: "Zones",
    id: string,
    name?: string | null,
    numberZone?: string | null,
    animals?:  {
      __typename: "ModelAnimalsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Buildings",
      id: string,
      nameBuilding: string,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateZonesMutationVariables = {
  input: UpdateZonesInput,
  condition?: ModelZonesConditionInput | null,
};

export type UpdateZonesMutation = {
  updateZones?:  {
    __typename: "Zones",
    id: string,
    name?: string | null,
    numberZone?: string | null,
    animals?:  {
      __typename: "ModelAnimalsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Buildings",
      id: string,
      nameBuilding: string,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteZonesMutationVariables = {
  input: DeleteZonesInput,
  condition?: ModelZonesConditionInput | null,
};

export type DeleteZonesMutation = {
  deleteZones?:  {
    __typename: "Zones",
    id: string,
    name?: string | null,
    numberZone?: string | null,
    animals?:  {
      __typename: "ModelAnimalsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Buildings",
      id: string,
      nameBuilding: string,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateBSEMutationVariables = {
  input: CreateBSEInput,
  condition?: ModelBSEConditionInput | null,
};

export type CreateBSEMutation = {
  createBSE?:  {
    __typename: "BSE",
    id: string,
    breedingId: string,
    StatusBse?: StatusBse | null,
    dateBSE?: string | null,
    crS3Key?: string | null,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questionResponse?: string | null,
    lasIdQuestion?: string | null,
    idEleveurUser?: string | null,
    lastQuestionVeto?: string | null,
    questionResponseOrder?: Array< string | null > | null,
    maladieRecurente?: Array< string | null > | null,
    protocolesDeSoins?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateBSEMutationVariables = {
  input: UpdateBSEInput,
  condition?: ModelBSEConditionInput | null,
};

export type UpdateBSEMutation = {
  updateBSE?:  {
    __typename: "BSE",
    id: string,
    breedingId: string,
    StatusBse?: StatusBse | null,
    dateBSE?: string | null,
    crS3Key?: string | null,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questionResponse?: string | null,
    lasIdQuestion?: string | null,
    idEleveurUser?: string | null,
    lastQuestionVeto?: string | null,
    questionResponseOrder?: Array< string | null > | null,
    maladieRecurente?: Array< string | null > | null,
    protocolesDeSoins?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteBSEMutationVariables = {
  input: DeleteBSEInput,
  condition?: ModelBSEConditionInput | null,
};

export type DeleteBSEMutation = {
  deleteBSE?:  {
    __typename: "BSE",
    id: string,
    breedingId: string,
    StatusBse?: StatusBse | null,
    dateBSE?: string | null,
    crS3Key?: string | null,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questionResponse?: string | null,
    lasIdQuestion?: string | null,
    idEleveurUser?: string | null,
    lastQuestionVeto?: string | null,
    questionResponseOrder?: Array< string | null > | null,
    maladieRecurente?: Array< string | null > | null,
    protocolesDeSoins?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDocumentMutationVariables = {
  input: CreateDocumentInput,
  condition?: ModelDocumentConditionInput | null,
};

export type CreateDocumentMutation = {
  createDocument?:  {
    __typename: "Document",
    id: string,
    breedingId?: string | null,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    consultationId?: string | null,
    consultation?:  {
      __typename: "Consultation",
      id: string,
      breedingId: string,
      animalId?: string | null,
      lotId?: string | null,
      diseaseId?: string | null,
      shared?: boolean | null,
      sharedAt?: string | null,
      autoExam?: boolean | null,
      teleconsultation?: boolean | null,
      priceConsultation?: number | null,
      diseaseIsVerified?: Array< string | null > | null,
      isDone?: boolean | null,
      factureId?: string | null,
      sharedFacture?: boolean | null,
      sharedFactureAt?: string | null,
      teleconsAskAt?: string | null,
      dateTeleCons?: string | null,
      questionResponse?: string | null,
      note?: number | null,
      questionResponseOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    name: string,
    key?: string | null,
    s3file: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateDocumentMutationVariables = {
  input: UpdateDocumentInput,
  condition?: ModelDocumentConditionInput | null,
};

export type UpdateDocumentMutation = {
  updateDocument?:  {
    __typename: "Document",
    id: string,
    breedingId?: string | null,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    consultationId?: string | null,
    consultation?:  {
      __typename: "Consultation",
      id: string,
      breedingId: string,
      animalId?: string | null,
      lotId?: string | null,
      diseaseId?: string | null,
      shared?: boolean | null,
      sharedAt?: string | null,
      autoExam?: boolean | null,
      teleconsultation?: boolean | null,
      priceConsultation?: number | null,
      diseaseIsVerified?: Array< string | null > | null,
      isDone?: boolean | null,
      factureId?: string | null,
      sharedFacture?: boolean | null,
      sharedFactureAt?: string | null,
      teleconsAskAt?: string | null,
      dateTeleCons?: string | null,
      questionResponse?: string | null,
      note?: number | null,
      questionResponseOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    name: string,
    key?: string | null,
    s3file: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteDocumentMutationVariables = {
  input: DeleteDocumentInput,
  condition?: ModelDocumentConditionInput | null,
};

export type DeleteDocumentMutation = {
  DeleteDocument?:  {
    __typename: "Document",
    id: string,
    breedingId?: string | null,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    consultationId?: string | null,
    consultation?:  {
      __typename: "Consultation",
      id: string,
      breedingId: string,
      animalId?: string | null,
      lotId?: string | null,
      diseaseId?: string | null,
      shared?: boolean | null,
      sharedAt?: string | null,
      autoExam?: boolean | null,
      teleconsultation?: boolean | null,
      priceConsultation?: number | null,
      diseaseIsVerified?: Array< string | null > | null,
      isDone?: boolean | null,
      factureId?: string | null,
      sharedFacture?: boolean | null,
      sharedFactureAt?: string | null,
      teleconsAskAt?: string | null,
      dateTeleCons?: string | null,
      questionResponse?: string | null,
      note?: number | null,
      questionResponseOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    name: string,
    key?: string | null,
    s3file: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateMessageMutationVariables = {
  input: CreateMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type CreateMessageMutation = {
  createMessage?:  {
    __typename: "Message",
    id: string,
    consultationId: string,
    consultation?:  {
      __typename: "Consultation",
      id: string,
      breedingId: string,
      animalId?: string | null,
      lotId?: string | null,
      diseaseId?: string | null,
      shared?: boolean | null,
      sharedAt?: string | null,
      autoExam?: boolean | null,
      teleconsultation?: boolean | null,
      priceConsultation?: number | null,
      diseaseIsVerified?: Array< string | null > | null,
      isDone?: boolean | null,
      factureId?: string | null,
      sharedFacture?: boolean | null,
      sharedFactureAt?: string | null,
      teleconsAskAt?: string | null,
      dateTeleCons?: string | null,
      questionResponse?: string | null,
      note?: number | null,
      questionResponseOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    message: string,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      lastname?: string | null,
      firstname?: string | null,
      avatarUri?: string | null,
      userGroup?: string | null,
      optIn?: boolean | null,
      inscriptionNumberOrdreVeto?: string | null,
      breedingId?: string | null,
      vetOfficeId?: string | null,
      email?: string | null,
      expoToken?: Array< string > | null,
      notificationLastSeenAt?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreatePendingInvitationMutationVariables = {
  input: CreatePendingInvitationInput,
  condition?: ModelPendingInvitationConditionInput | null,
};

export type CreatePendingInvitationMutation = {
  createPendingInvitation?:  {
    __typename: "PendingInvitation",
    id: string,
    breedingId: string,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emailCurrentUser: string,
    email: string,
    createdAt: string,
    initial?: boolean | null,
    refusedAt?: string | null,
    acceptedAt?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdatePendingInvitationMutationVariables = {
  input: UpdatePendingInvitationInput,
  condition?: ModelPendingInvitationConditionInput | null,
};

export type UpdatePendingInvitationMutation = {
  updatePendingInvitation?:  {
    __typename: "PendingInvitation",
    id: string,
    breedingId: string,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emailCurrentUser: string,
    email: string,
    createdAt: string,
    initial?: boolean | null,
    refusedAt?: string | null,
    acceptedAt?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeletePendingInvitationMutationVariables = {
  input: DeletePendingInvitationInput,
  condition?: ModelPendingInvitationConditionInput | null,
};

export type DeletePendingInvitationMutation = {
  deletePendingInvitation?:  {
    __typename: "PendingInvitation",
    id: string,
    breedingId: string,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emailCurrentUser: string,
    email: string,
    createdAt: string,
    initial?: boolean | null,
    refusedAt?: string | null,
    acceptedAt?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateConsultationMutationVariables = {
  input: CreateConsultationInput,
  condition?: ModelConsultationConditionInput | null,
};

export type CreateConsultationMutation = {
  createConsultation?:  {
    __typename: "Consultation",
    id: string,
    breedingId: string,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    animalId?: string | null,
    animal?:  {
      __typename: "Animals",
      id: string,
      numberID: string,
      name?: string | null,
      gender?: GenderType | null,
      birthDate?: string | null,
      breedingId: string,
      lotsId?: string | null,
      zoneId?: string | null,
      buildingId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    lotId?: string | null,
    lot?:  {
      __typename: "Lots",
      id: string,
      numberLot: string,
      name?: string | null,
      buildingId?: string | null,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    diseaseId?: string | null,
    disease?:  {
      __typename: "disease",
      id: string,
      codeIdentification?: string | null,
      name: string,
      forme?: string | null,
      description: string,
      synonymes?: string | null,
      enjeux?: string | null,
      causes?: string | null,
      circonstancesApparition?: string | null,
      diagnosticDiff?: string | null,
      diagnosticLab?: string | null,
      traitementCuratif?: string | null,
      pronostic?: string | null,
      prevention?: string | null,
      url?: string | null,
      image?: Array< string | null > | null,
      ponderationDisease?: string | null,
      ponderationCount?: number | null,
      familleMaladie?: string | null,
      familleMaladieId?: number | null,
      MRLC?: boolean | null,
      maladieUrgente?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    shared?: boolean | null,
    sharedAt?: string | null,
    autoExam?: boolean | null,
    autoExamRes?:  {
      __typename: "AutoExamRes",
      reponse1?: string | null,
    } | null,
    teleconsultation?: boolean | null,
    priceConsultation?: number | null,
    diseaseIsVerified?: Array< string | null > | null,
    isDone?: boolean | null,
    factureId?: string | null,
    facture?:  {
      __typename: "Document",
      id: string,
      breedingId?: string | null,
      consultationId?: string | null,
      name: string,
      key?: string | null,
      s3file: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      nextToken?: string | null,
    } | null,
    sharedFacture?: boolean | null,
    sharedFactureAt?: string | null,
    teleconsAskAt?: string | null,
    dateTeleCons?: string | null,
    questionResponse?: string | null,
    note?: number | null,
    questionResponseOrder?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateConsultationMutationVariables = {
  input: UpdateConsultationInput,
  condition?: ModelConsultationConditionInput | null,
};

export type UpdateConsultationMutation = {
  updateConsultation?:  {
    __typename: "Consultation",
    id: string,
    breedingId: string,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    animalId?: string | null,
    animal?:  {
      __typename: "Animals",
      id: string,
      numberID: string,
      name?: string | null,
      gender?: GenderType | null,
      birthDate?: string | null,
      breedingId: string,
      lotsId?: string | null,
      zoneId?: string | null,
      buildingId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    lotId?: string | null,
    lot?:  {
      __typename: "Lots",
      id: string,
      numberLot: string,
      name?: string | null,
      buildingId?: string | null,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    diseaseId?: string | null,
    disease?:  {
      __typename: "disease",
      id: string,
      codeIdentification?: string | null,
      name: string,
      forme?: string | null,
      description: string,
      synonymes?: string | null,
      enjeux?: string | null,
      causes?: string | null,
      circonstancesApparition?: string | null,
      diagnosticDiff?: string | null,
      diagnosticLab?: string | null,
      traitementCuratif?: string | null,
      pronostic?: string | null,
      prevention?: string | null,
      url?: string | null,
      image?: Array< string | null > | null,
      ponderationDisease?: string | null,
      ponderationCount?: number | null,
      familleMaladie?: string | null,
      familleMaladieId?: number | null,
      MRLC?: boolean | null,
      maladieUrgente?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    shared?: boolean | null,
    sharedAt?: string | null,
    autoExam?: boolean | null,
    autoExamRes?:  {
      __typename: "AutoExamRes",
      reponse1?: string | null,
    } | null,
    teleconsultation?: boolean | null,
    priceConsultation?: number | null,
    diseaseIsVerified?: Array< string | null > | null,
    isDone?: boolean | null,
    factureId?: string | null,
    facture?:  {
      __typename: "Document",
      id: string,
      breedingId?: string | null,
      consultationId?: string | null,
      name: string,
      key?: string | null,
      s3file: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      nextToken?: string | null,
    } | null,
    sharedFacture?: boolean | null,
    sharedFactureAt?: string | null,
    teleconsAskAt?: string | null,
    dateTeleCons?: string | null,
    questionResponse?: string | null,
    note?: number | null,
    questionResponseOrder?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteConsultationMutationVariables = {
  input: DeleteConsultationInput,
  condition?: ModelConsultationConditionInput | null,
};

export type DeleteConsultationMutation = {
  deleteConsultation?:  {
    __typename: "Consultation",
    id: string,
    breedingId: string,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    animalId?: string | null,
    animal?:  {
      __typename: "Animals",
      id: string,
      numberID: string,
      name?: string | null,
      gender?: GenderType | null,
      birthDate?: string | null,
      breedingId: string,
      lotsId?: string | null,
      zoneId?: string | null,
      buildingId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    lotId?: string | null,
    lot?:  {
      __typename: "Lots",
      id: string,
      numberLot: string,
      name?: string | null,
      buildingId?: string | null,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    diseaseId?: string | null,
    disease?:  {
      __typename: "disease",
      id: string,
      codeIdentification?: string | null,
      name: string,
      forme?: string | null,
      description: string,
      synonymes?: string | null,
      enjeux?: string | null,
      causes?: string | null,
      circonstancesApparition?: string | null,
      diagnosticDiff?: string | null,
      diagnosticLab?: string | null,
      traitementCuratif?: string | null,
      pronostic?: string | null,
      prevention?: string | null,
      url?: string | null,
      image?: Array< string | null > | null,
      ponderationDisease?: string | null,
      ponderationCount?: number | null,
      familleMaladie?: string | null,
      familleMaladieId?: number | null,
      MRLC?: boolean | null,
      maladieUrgente?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    shared?: boolean | null,
    sharedAt?: string | null,
    autoExam?: boolean | null,
    autoExamRes?:  {
      __typename: "AutoExamRes",
      reponse1?: string | null,
    } | null,
    teleconsultation?: boolean | null,
    priceConsultation?: number | null,
    diseaseIsVerified?: Array< string | null > | null,
    isDone?: boolean | null,
    factureId?: string | null,
    facture?:  {
      __typename: "Document",
      id: string,
      breedingId?: string | null,
      consultationId?: string | null,
      name: string,
      key?: string | null,
      s3file: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      nextToken?: string | null,
    } | null,
    sharedFacture?: boolean | null,
    sharedFactureAt?: string | null,
    teleconsAskAt?: string | null,
    dateTeleCons?: string | null,
    questionResponse?: string | null,
    note?: number | null,
    questionResponseOrder?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDiseaseMutationVariables = {
  input: CreateDiseaseInput,
  condition?: ModelDiseaseConditionInput | null,
};

export type CreateDiseaseMutation = {
  createDisease?:  {
    __typename: "disease",
    id: string,
    codeIdentification?: string | null,
    name: string,
    forme?: string | null,
    description: string,
    synonymes?: string | null,
    enjeux?: string | null,
    causes?: string | null,
    circonstancesApparition?: string | null,
    diagnosticDiff?: string | null,
    diagnosticLab?: string | null,
    traitementCuratif?: string | null,
    pronostic?: string | null,
    prevention?: string | null,
    url?: string | null,
    consultations?:  {
      __typename: "ModelConsultationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    image?: Array< string | null > | null,
    ponderationDisease?: string | null,
    ponderationCount?: number | null,
    familleMaladie?: string | null,
    familleMaladieId?: number | null,
    MRLC?: boolean | null,
    maladieUrgente?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateDiseaseMutationVariables = {
  input: UpdateDiseaseInput,
  condition?: ModelDiseaseConditionInput | null,
};

export type UpdateDiseaseMutation = {
  updateDisease?:  {
    __typename: "disease",
    id: string,
    codeIdentification?: string | null,
    name: string,
    forme?: string | null,
    description: string,
    synonymes?: string | null,
    enjeux?: string | null,
    causes?: string | null,
    circonstancesApparition?: string | null,
    diagnosticDiff?: string | null,
    diagnosticLab?: string | null,
    traitementCuratif?: string | null,
    pronostic?: string | null,
    prevention?: string | null,
    url?: string | null,
    consultations?:  {
      __typename: "ModelConsultationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    image?: Array< string | null > | null,
    ponderationDisease?: string | null,
    ponderationCount?: number | null,
    familleMaladie?: string | null,
    familleMaladieId?: number | null,
    MRLC?: boolean | null,
    maladieUrgente?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteDiseaseMutationVariables = {
  input: DeleteDiseaseInput,
  condition?: ModelDiseaseConditionInput | null,
};

export type DeleteDiseaseMutation = {
  deleteDisease?:  {
    __typename: "disease",
    id: string,
    codeIdentification?: string | null,
    name: string,
    forme?: string | null,
    description: string,
    synonymes?: string | null,
    enjeux?: string | null,
    causes?: string | null,
    circonstancesApparition?: string | null,
    diagnosticDiff?: string | null,
    diagnosticLab?: string | null,
    traitementCuratif?: string | null,
    pronostic?: string | null,
    prevention?: string | null,
    url?: string | null,
    consultations?:  {
      __typename: "ModelConsultationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    image?: Array< string | null > | null,
    ponderationDisease?: string | null,
    ponderationCount?: number | null,
    familleMaladie?: string | null,
    familleMaladieId?: number | null,
    MRLC?: boolean | null,
    maladieUrgente?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateProtocolSoinMutationVariables = {
  input: CreateProtocolSoinInput,
  condition?: ModelProtocolSoinConditionInput | null,
};

export type CreateProtocolSoinMutation = {
  createProtocolSoin?:  {
    __typename: "ProtocolSoin",
    id: string,
    idDiseaseFamily?: string | null,
    name?: string | null,
    Protocol?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateProtocolSoinMutationVariables = {
  input: UpdateProtocolSoinInput,
  condition?: ModelProtocolSoinConditionInput | null,
};

export type UpdateProtocolSoinMutation = {
  updateProtocolSoin?:  {
    __typename: "ProtocolSoin",
    id: string,
    idDiseaseFamily?: string | null,
    name?: string | null,
    Protocol?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteProtocolSoinMutationVariables = {
  input: DeleteProtocolSoinInput,
  condition?: ModelProtocolSoinConditionInput | null,
};

export type DeleteProtocolSoinMutation = {
  deleteProtocolSoin?:  {
    __typename: "ProtocolSoin",
    id: string,
    idDiseaseFamily?: string | null,
    name?: string | null,
    Protocol?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateProtocolSoinSaveMutationVariables = {
  input: CreateProtocolSoinSaveInput,
  condition?: ModelProtocolSoinSaveConditionInput | null,
};

export type CreateProtocolSoinSaveMutation = {
  createProtocolSoinSave?:  {
    __typename: "ProtocolSoinSave",
    id: string,
    breedingId?: string | null,
    bseId?: string | null,
    idDiseaseFamily?: string | null,
    new?: boolean | null,
    name?: string | null,
    Protocol?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateProtocolSoinSaveMutationVariables = {
  input: UpdateProtocolSoinSaveInput,
  condition?: ModelProtocolSoinSaveConditionInput | null,
};

export type UpdateProtocolSoinSaveMutation = {
  updateProtocolSoinSave?:  {
    __typename: "ProtocolSoinSave",
    id: string,
    breedingId?: string | null,
    bseId?: string | null,
    idDiseaseFamily?: string | null,
    new?: boolean | null,
    name?: string | null,
    Protocol?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteProtocolSoinSaveMutationVariables = {
  input: DeleteProtocolSoinSaveInput,
  condition?: ModelProtocolSoinSaveConditionInput | null,
};

export type DeleteProtocolSoinSaveMutation = {
  deleteProtocolSoinSave?:  {
    __typename: "ProtocolSoinSave",
    id: string,
    breedingId?: string | null,
    bseId?: string | null,
    idDiseaseFamily?: string | null,
    new?: boolean | null,
    name?: string | null,
    Protocol?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateNotificationMutationVariables = {
  input: CreateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type CreateNotificationMutation = {
  createNotification?:  {
    __typename: "Notification",
    id: string,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      lastname?: string | null,
      firstname?: string | null,
      avatarUri?: string | null,
      userGroup?: string | null,
      optIn?: boolean | null,
      inscriptionNumberOrdreVeto?: string | null,
      breedingId?: string | null,
      vetOfficeId?: string | null,
      email?: string | null,
      expoToken?: Array< string > | null,
      notificationLastSeenAt?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    type: string,
    title: string,
    body: string,
    data?: string | null,
    clicked?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateNotificationMutationVariables = {
  input: UpdateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type UpdateNotificationMutation = {
  updateNotification?:  {
    __typename: "Notification",
    id: string,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      lastname?: string | null,
      firstname?: string | null,
      avatarUri?: string | null,
      userGroup?: string | null,
      optIn?: boolean | null,
      inscriptionNumberOrdreVeto?: string | null,
      breedingId?: string | null,
      vetOfficeId?: string | null,
      email?: string | null,
      expoToken?: Array< string > | null,
      notificationLastSeenAt?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    type: string,
    title: string,
    body: string,
    data?: string | null,
    clicked?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteNotificationMutationVariables = {
  input: DeleteNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type DeleteNotificationMutation = {
  deleteNotification?:  {
    __typename: "Notification",
    id: string,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      lastname?: string | null,
      firstname?: string | null,
      avatarUri?: string | null,
      userGroup?: string | null,
      optIn?: boolean | null,
      inscriptionNumberOrdreVeto?: string | null,
      breedingId?: string | null,
      vetOfficeId?: string | null,
      email?: string | null,
      expoToken?: Array< string > | null,
      notificationLastSeenAt?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    type: string,
    title: string,
    body: string,
    data?: string | null,
    clicked?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateNotificationTicketsMutationVariables = {
  input: CreateNotificationTicketsInput,
  condition?: ModelNotificationTicketsConditionInput | null,
};

export type CreateNotificationTicketsMutation = {
  createNotificationTickets?:  {
    __typename: "NotificationTickets",
    id: string,
    expoTokens?:  Array< {
      __typename: "UserToken",
      userId: string,
      token: string,
    } > | null,
    ticketIds?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateNotificationTicketsMutationVariables = {
  input: UpdateNotificationTicketsInput,
  condition?: ModelNotificationTicketsConditionInput | null,
};

export type UpdateNotificationTicketsMutation = {
  updateNotificationTickets?:  {
    __typename: "NotificationTickets",
    id: string,
    expoTokens?:  Array< {
      __typename: "UserToken",
      userId: string,
      token: string,
    } > | null,
    ticketIds?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteNotificationTicketsMutationVariables = {
  input: DeleteNotificationTicketsInput,
  condition?: ModelNotificationTicketsConditionInput | null,
};

export type DeleteNotificationTicketsMutation = {
  deleteNotificationTickets?:  {
    __typename: "NotificationTickets",
    id: string,
    expoTokens?:  Array< {
      __typename: "UserToken",
      userId: string,
      token: string,
    } > | null,
    ticketIds?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    lastname?: string | null,
    firstname?: string | null,
    avatarUri?: string | null,
    userGroup?: string | null,
    optIn?: boolean | null,
    inscriptionNumberOrdreVeto?: string | null,
    breedingId?: string | null,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    vetOfficeId?: string | null,
    vetOffice?:  {
      __typename: "VetOffice",
      id: string,
      locked: LockedStatus,
      companyName: string,
      emailOffice: string,
      admins: Array< string >,
      personnel?: Array< string > | null,
      phoneVetOffice?: string | null,
      nameVetSanitaire?: string | null,
      nOrdreCabinet: string,
      nSIRET: string,
      maxPriceTeleConsultation: number,
      maxPriceConsultation: number,
      mpUserId?: string | null,
      isFree?: boolean | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    email?: string | null,
    expoToken?: Array< string > | null,
    notificationLastSeenAt?: string | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      lastname?: string | null,
      firstname?: string | null,
      avatarUri?: string | null,
      userGroup?: string | null,
      optIn?: boolean | null,
      inscriptionNumberOrdreVeto?: string | null,
      breedingId?: string | null,
      vetOfficeId?: string | null,
      email?: string | null,
      expoToken?: Array< string > | null,
      notificationLastSeenAt?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUsersQuery = {
  syncUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      lastname?: string | null,
      firstname?: string | null,
      avatarUri?: string | null,
      userGroup?: string | null,
      optIn?: boolean | null,
      inscriptionNumberOrdreVeto?: string | null,
      breedingId?: string | null,
      vetOfficeId?: string | null,
      email?: string | null,
      expoToken?: Array< string > | null,
      notificationLastSeenAt?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type UserByEmailQueryVariables = {
  email: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserByEmailQuery = {
  userByEmail?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      lastname?: string | null,
      firstname?: string | null,
      avatarUri?: string | null,
      userGroup?: string | null,
      optIn?: boolean | null,
      inscriptionNumberOrdreVeto?: string | null,
      breedingId?: string | null,
      vetOfficeId?: string | null,
      email?: string | null,
      expoToken?: Array< string > | null,
      notificationLastSeenAt?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetVetOfficeQueryVariables = {
  id: string,
};

export type GetVetOfficeQuery = {
  getVetOffice?:  {
    __typename: "VetOffice",
    id: string,
    locked: LockedStatus,
    companyName: string,
    emailOffice: string,
    admins: Array< string >,
    personnel?: Array< string > | null,
    phoneVetOffice?: string | null,
    address?:  {
      __typename: "Address",
      address: string,
      additionalAddress?: string | null,
      postalCode: string,
      city: string,
    } | null,
    nameVetSanitaire?: string | null,
    nOrdreCabinet: string,
    nSIRET: string,
    maxPriceTeleConsultation: number,
    maxPriceConsultation: number,
    users?:  {
      __typename: "ModelUserConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    breedings?:  {
      __typename: "ModelBreedingConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    billingHistories?:  {
      __typename: "ModelBillingHistoryConnection",
      nextToken?: string | null,
    } | null,
    mpUserId?: string | null,
    isFree?: boolean | null,
    mpWalletId?: string | null,
    mpBankId?: string | null,
    mpMandateId?: string | null,
    mpMandateConfirmed?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListVetOfficesQueryVariables = {
  filter?: ModelVetOfficeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVetOfficesQuery = {
  listVetOffices?:  {
    __typename: "ModelVetOfficeConnection",
    items:  Array< {
      __typename: "VetOffice",
      id: string,
      locked: LockedStatus,
      companyName: string,
      emailOffice: string,
      admins: Array< string >,
      personnel?: Array< string > | null,
      phoneVetOffice?: string | null,
      nameVetSanitaire?: string | null,
      nOrdreCabinet: string,
      nSIRET: string,
      maxPriceTeleConsultation: number,
      maxPriceConsultation: number,
      mpUserId?: string | null,
      isFree?: boolean | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncVetOfficesQueryVariables = {
  filter?: ModelVetOfficeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncVetOfficesQuery = {
  syncVetOffices?:  {
    __typename: "ModelVetOfficeConnection",
    items:  Array< {
      __typename: "VetOffice",
      id: string,
      locked: LockedStatus,
      companyName: string,
      emailOffice: string,
      admins: Array< string >,
      personnel?: Array< string > | null,
      phoneVetOffice?: string | null,
      nameVetSanitaire?: string | null,
      nOrdreCabinet: string,
      nSIRET: string,
      maxPriceTeleConsultation: number,
      maxPriceConsultation: number,
      mpUserId?: string | null,
      isFree?: boolean | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListVetOfficeByLockedByCreationDateQueryVariables = {
  locked: LockedStatus,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVetOfficeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVetOfficeByLockedByCreationDateQuery = {
  listVetOfficeByLockedByCreationDate?:  {
    __typename: "ModelVetOfficeConnection",
    items:  Array< {
      __typename: "VetOffice",
      id: string,
      locked: LockedStatus,
      companyName: string,
      emailOffice: string,
      admins: Array< string >,
      personnel?: Array< string > | null,
      phoneVetOffice?: string | null,
      nameVetSanitaire?: string | null,
      nOrdreCabinet: string,
      nSIRET: string,
      maxPriceTeleConsultation: number,
      maxPriceConsultation: number,
      mpUserId?: string | null,
      isFree?: boolean | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type VetOfficeByEmailOfficeQueryVariables = {
  emailOffice: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVetOfficeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VetOfficeByEmailOfficeQuery = {
  vetOfficeByEmailOffice?:  {
    __typename: "ModelVetOfficeConnection",
    items:  Array< {
      __typename: "VetOffice",
      id: string,
      locked: LockedStatus,
      companyName: string,
      emailOffice: string,
      admins: Array< string >,
      personnel?: Array< string > | null,
      phoneVetOffice?: string | null,
      nameVetSanitaire?: string | null,
      nOrdreCabinet: string,
      nSIRET: string,
      maxPriceTeleConsultation: number,
      maxPriceConsultation: number,
      mpUserId?: string | null,
      isFree?: boolean | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListVetOfficeMandateIdQueryVariables = {
  mpMandateId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVetOfficeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVetOfficeMandateIdQuery = {
  listVetOfficeMandateId?:  {
    __typename: "ModelVetOfficeConnection",
    items:  Array< {
      __typename: "VetOffice",
      id: string,
      locked: LockedStatus,
      companyName: string,
      emailOffice: string,
      admins: Array< string >,
      personnel?: Array< string > | null,
      phoneVetOffice?: string | null,
      nameVetSanitaire?: string | null,
      nOrdreCabinet: string,
      nSIRET: string,
      maxPriceTeleConsultation: number,
      maxPriceConsultation: number,
      mpUserId?: string | null,
      isFree?: boolean | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetBreedingQueryVariables = {
  id: string,
};

export type GetBreedingQuery = {
  getBreeding?:  {
    __typename: "Breeding",
    id: string,
    locked: LockedStatus,
    nBreeding: string,
    nSIRET: string,
    phone: string,
    emailEleveur: string,
    address?:  {
      __typename: "Address",
      address: string,
      additionalAddress?: string | null,
      postalCode: string,
      city: string,
    } | null,
    companyName: string,
    dairyFarm: boolean,
    sucklerCowHusbandry: boolean,
    fatteningFarm: boolean,
    nbCow: number,
    nbHeifer: number,
    nbMale: number,
    nbYoung: number,
    idVeto?: string | null,
    emailVeto?: string | null,
    admins: Array< string >,
    vetOffice?:  {
      __typename: "VetOffice",
      id: string,
      locked: LockedStatus,
      companyName: string,
      emailOffice: string,
      admins: Array< string >,
      personnel?: Array< string > | null,
      phoneVetOffice?: string | null,
      nameVetSanitaire?: string | null,
      nOrdreCabinet: string,
      nSIRET: string,
      maxPriceTeleConsultation: number,
      maxPriceConsultation: number,
      mpUserId?: string | null,
      isFree?: boolean | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    users?:  {
      __typename: "ModelUserConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      nextToken?: string | null,
    } | null,
    animals?:  {
      __typename: "ModelAnimalsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    lots?:  {
      __typename: "ModelLotsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    buildings?:  {
      __typename: "ModelBuildingsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    bse?:  {
      __typename: "ModelBSEConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    pendingInvitations?:  {
      __typename: "ModelPendingInvitationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    consultations?:  {
      __typename: "ModelConsultationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    billingHistories?:  {
      __typename: "ModelBillingHistoryConnection",
      nextToken?: string | null,
    } | null,
    mpUserId?: string | null,
    mpWalletId?: string | null,
    mpBankId?: string | null,
    mpMandateId?: string | null,
    mpMandateConfirmed?: boolean | null,
    createdAt: string,
    dateFirstCons?: string | null,
    nextSubscriptionDate?: string | null,
    subscriptionAmount?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListBreedingsQueryVariables = {
  filter?: ModelBreedingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBreedingsQuery = {
  listBreedings?:  {
    __typename: "ModelBreedingConnection",
    items:  Array< {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncBreedingsQueryVariables = {
  filter?: ModelBreedingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncBreedingsQuery = {
  syncBreedings?:  {
    __typename: "ModelBreedingConnection",
    items:  Array< {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListBreedingByLockedQueryVariables = {
  locked: LockedStatus,
  nextSubscriptionDate?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBreedingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBreedingByLockedQuery = {
  listBreedingByLocked?:  {
    __typename: "ModelBreedingConnection",
    items:  Array< {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListBreedingByLockedByCreationDateQueryVariables = {
  locked: LockedStatus,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBreedingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBreedingByLockedByCreationDateQuery = {
  listBreedingByLockedByCreationDate?:  {
    __typename: "ModelBreedingConnection",
    items:  Array< {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListBreedingMandateIdQueryVariables = {
  mpMandateId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBreedingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBreedingMandateIdQuery = {
  listBreedingMandateId?:  {
    __typename: "ModelBreedingConnection",
    items:  Array< {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListBillingHistoriesByVetOfficeQueryVariables = {
  vetOfficeId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBillingHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBillingHistoriesByVetOfficeQuery = {
  listBillingHistoriesByVetOffice?:  {
    __typename: "ModelBillingHistoryConnection",
    items:  Array< {
      __typename: "BillingHistory",
      id: string,
      vetOfficeId?: string | null,
      breedingId?: string | null,
      createdAt: string,
      type: string,
      invoiceNumber?: number | null,
      amount: number,
      label: string,
      paid?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListBillingHistoriesByBreedingQueryVariables = {
  breedingId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBillingHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBillingHistoriesByBreedingQuery = {
  listBillingHistoriesByBreeding?:  {
    __typename: "ModelBillingHistoryConnection",
    items:  Array< {
      __typename: "BillingHistory",
      id: string,
      vetOfficeId?: string | null,
      breedingId?: string | null,
      createdAt: string,
      type: string,
      invoiceNumber?: number | null,
      amount: number,
      label: string,
      paid?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListBillingHistoriesByTypeQueryVariables = {
  type: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBillingHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBillingHistoriesByTypeQuery = {
  listBillingHistoriesByType?:  {
    __typename: "ModelBillingHistoryConnection",
    items:  Array< {
      __typename: "BillingHistory",
      id: string,
      vetOfficeId?: string | null,
      breedingId?: string | null,
      createdAt: string,
      type: string,
      invoiceNumber?: number | null,
      amount: number,
      label: string,
      paid?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAnimalsQueryVariables = {
  id: string,
};

export type GetAnimalsQuery = {
  getAnimals?:  {
    __typename: "Animals",
    id: string,
    numberID: string,
    name?: string | null,
    gender?: GenderType | null,
    birthDate?: string | null,
    breedingId: string,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    lotsId?: string | null,
    lots?:  {
      __typename: "Lots",
      id: string,
      numberLot: string,
      name?: string | null,
      buildingId?: string | null,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    consultations?:  {
      __typename: "ModelConsultationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    zoneId?: string | null,
    zone?:  {
      __typename: "Zones",
      id: string,
      name?: string | null,
      numberZone?: string | null,
      buildingId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Buildings",
      id: string,
      nameBuilding: string,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAnimalsQueryVariables = {
  filter?: ModelAnimalsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAnimalsQuery = {
  listAnimals?:  {
    __typename: "ModelAnimalsConnection",
    items:  Array< {
      __typename: "Animals",
      id: string,
      numberID: string,
      name?: string | null,
      gender?: GenderType | null,
      birthDate?: string | null,
      breedingId: string,
      lotsId?: string | null,
      zoneId?: string | null,
      buildingId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAnimalsQueryVariables = {
  filter?: ModelAnimalsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAnimalsQuery = {
  syncAnimals?:  {
    __typename: "ModelAnimalsConnection",
    items:  Array< {
      __typename: "Animals",
      id: string,
      numberID: string,
      name?: string | null,
      gender?: GenderType | null,
      birthDate?: string | null,
      breedingId: string,
      lotsId?: string | null,
      zoneId?: string | null,
      buildingId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type AnimalsByNumberIdQueryVariables = {
  numberID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAnimalsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AnimalsByNumberIdQuery = {
  animalsByNumberId?:  {
    __typename: "ModelAnimalsConnection",
    items:  Array< {
      __typename: "Animals",
      id: string,
      numberID: string,
      name?: string | null,
      gender?: GenderType | null,
      birthDate?: string | null,
      breedingId: string,
      lotsId?: string | null,
      zoneId?: string | null,
      buildingId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type AnimalsByNameQueryVariables = {
  name: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAnimalsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AnimalsByNameQuery = {
  animalsByName?:  {
    __typename: "ModelAnimalsConnection",
    items:  Array< {
      __typename: "Animals",
      id: string,
      numberID: string,
      name?: string | null,
      gender?: GenderType | null,
      birthDate?: string | null,
      breedingId: string,
      lotsId?: string | null,
      zoneId?: string | null,
      buildingId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListAnimalsByBreedingQueryVariables = {
  breedingId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAnimalsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAnimalsByBreedingQuery = {
  listAnimalsByBreeding?:  {
    __typename: "ModelAnimalsConnection",
    items:  Array< {
      __typename: "Animals",
      id: string,
      numberID: string,
      name?: string | null,
      gender?: GenderType | null,
      birthDate?: string | null,
      breedingId: string,
      lotsId?: string | null,
      zoneId?: string | null,
      buildingId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetLotsQueryVariables = {
  id: string,
};

export type GetLotsQuery = {
  getLots?:  {
    __typename: "Lots",
    id: string,
    numberLot: string,
    name?: string | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Buildings",
      id: string,
      nameBuilding: string,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    breedingId: string,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    animals?:  {
      __typename: "ModelAnimalsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    consultations?:  {
      __typename: "ModelConsultationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListLotsQueryVariables = {
  filter?: ModelLotsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLotsQuery = {
  listLots?:  {
    __typename: "ModelLotsConnection",
    items:  Array< {
      __typename: "Lots",
      id: string,
      numberLot: string,
      name?: string | null,
      buildingId?: string | null,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncLotsQueryVariables = {
  filter?: ModelLotsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncLotsQuery = {
  syncLots?:  {
    __typename: "ModelLotsConnection",
    items:  Array< {
      __typename: "Lots",
      id: string,
      numberLot: string,
      name?: string | null,
      buildingId?: string | null,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type LotsBynumberLotQueryVariables = {
  numberLot: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLotsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LotsBynumberLotQuery = {
  lotsBynumberLot?:  {
    __typename: "ModelLotsConnection",
    items:  Array< {
      __typename: "Lots",
      id: string,
      numberLot: string,
      name?: string | null,
      buildingId?: string | null,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListLotsByBreedingQueryVariables = {
  breedingId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLotsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLotsByBreedingQuery = {
  listLotsByBreeding?:  {
    __typename: "ModelLotsConnection",
    items:  Array< {
      __typename: "Lots",
      id: string,
      numberLot: string,
      name?: string | null,
      buildingId?: string | null,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetBuildingsQueryVariables = {
  id: string,
};

export type GetBuildingsQuery = {
  getBuildings?:  {
    __typename: "Buildings",
    id: string,
    nameBuilding: string,
    address?:  {
      __typename: "Address",
      address: string,
      additionalAddress?: string | null,
      postalCode: string,
      city: string,
    } | null,
    breedingId: string,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    zone?:  {
      __typename: "ModelZonesConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    animals?:  {
      __typename: "ModelAnimalsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    lots?:  {
      __typename: "ModelLotsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListBuildingsQueryVariables = {
  filter?: ModelBuildingsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBuildingsQuery = {
  listBuildings?:  {
    __typename: "ModelBuildingsConnection",
    items:  Array< {
      __typename: "Buildings",
      id: string,
      nameBuilding: string,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncBuildingsQueryVariables = {
  filter?: ModelBuildingsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncBuildingsQuery = {
  syncBuildings?:  {
    __typename: "ModelBuildingsConnection",
    items:  Array< {
      __typename: "Buildings",
      id: string,
      nameBuilding: string,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type BuildingsByNameBuildingQueryVariables = {
  nameBuilding: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBuildingsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BuildingsByNameBuildingQuery = {
  buildingsByNameBuilding?:  {
    __typename: "ModelBuildingsConnection",
    items:  Array< {
      __typename: "Buildings",
      id: string,
      nameBuilding: string,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListBuildingsByBreedingQueryVariables = {
  breedingId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBuildingsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBuildingsByBreedingQuery = {
  listBuildingsByBreeding?:  {
    __typename: "ModelBuildingsConnection",
    items:  Array< {
      __typename: "Buildings",
      id: string,
      nameBuilding: string,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetZonesQueryVariables = {
  id: string,
};

export type GetZonesQuery = {
  getZones?:  {
    __typename: "Zones",
    id: string,
    name?: string | null,
    numberZone?: string | null,
    animals?:  {
      __typename: "ModelAnimalsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Buildings",
      id: string,
      nameBuilding: string,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListZonesQueryVariables = {
  filter?: ModelZonesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListZonesQuery = {
  listZones?:  {
    __typename: "ModelZonesConnection",
    items:  Array< {
      __typename: "Zones",
      id: string,
      name?: string | null,
      numberZone?: string | null,
      buildingId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncZonesQueryVariables = {
  filter?: ModelZonesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncZonesQuery = {
  syncZones?:  {
    __typename: "ModelZonesConnection",
    items:  Array< {
      __typename: "Zones",
      id: string,
      name?: string | null,
      numberZone?: string | null,
      buildingId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ZonesByBuildingQueryVariables = {
  buildingId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelZonesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ZonesByBuildingQuery = {
  zonesByBuilding?:  {
    __typename: "ModelZonesConnection",
    items:  Array< {
      __typename: "Zones",
      id: string,
      name?: string | null,
      numberZone?: string | null,
      buildingId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetBSEQueryVariables = {
  id: string,
};

export type GetBSEQuery = {
  getBSE?:  {
    __typename: "BSE",
    id: string,
    breedingId: string,
    StatusBse?: StatusBse | null,
    dateBSE?: string | null,
    crS3Key?: string | null,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questionResponse?: string | null,
    lasIdQuestion?: string | null,
    idEleveurUser?: string | null,
    lastQuestionVeto?: string | null,
    questionResponseOrder?: Array< string | null > | null,
    maladieRecurente?: Array< string | null > | null,
    protocolesDeSoins?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListBSESQueryVariables = {
  filter?: ModelBSEFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBSESQuery = {
  listBSES?:  {
    __typename: "ModelBSEConnection",
    items:  Array< {
      __typename: "BSE",
      id: string,
      breedingId: string,
      StatusBse?: StatusBse | null,
      dateBSE?: string | null,
      crS3Key?: string | null,
      questionResponse?: string | null,
      lasIdQuestion?: string | null,
      idEleveurUser?: string | null,
      lastQuestionVeto?: string | null,
      questionResponseOrder?: Array< string | null > | null,
      maladieRecurente?: Array< string | null > | null,
      protocolesDeSoins?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncBSESQueryVariables = {
  filter?: ModelBSEFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncBSESQuery = {
  syncBSES?:  {
    __typename: "ModelBSEConnection",
    items:  Array< {
      __typename: "BSE",
      id: string,
      breedingId: string,
      StatusBse?: StatusBse | null,
      dateBSE?: string | null,
      crS3Key?: string | null,
      questionResponse?: string | null,
      lasIdQuestion?: string | null,
      idEleveurUser?: string | null,
      lastQuestionVeto?: string | null,
      questionResponseOrder?: Array< string | null > | null,
      maladieRecurente?: Array< string | null > | null,
      protocolesDeSoins?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListBseByBreedingQueryVariables = {
  breedingId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBSEFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBseByBreedingQuery = {
  listBseByBreeding?:  {
    __typename: "ModelBSEConnection",
    items:  Array< {
      __typename: "BSE",
      id: string,
      breedingId: string,
      StatusBse?: StatusBse | null,
      dateBSE?: string | null,
      crS3Key?: string | null,
      questionResponse?: string | null,
      lasIdQuestion?: string | null,
      idEleveurUser?: string | null,
      lastQuestionVeto?: string | null,
      questionResponseOrder?: Array< string | null > | null,
      maladieRecurente?: Array< string | null > | null,
      protocolesDeSoins?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type DocumentsByConsultationQueryVariables = {
  consultationId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDocumentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DocumentsByConsultationQuery = {
  documentsByConsultation?:  {
    __typename: "ModelDocumentConnection",
    items:  Array< {
      __typename: "Document",
      id: string,
      breedingId?: string | null,
      consultationId?: string | null,
      name: string,
      key?: string | null,
      s3file: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DocumentsByKeyQueryVariables = {
  key: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDocumentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DocumentsByKeyQuery = {
  documentsByKey?:  {
    __typename: "ModelDocumentConnection",
    items:  Array< {
      __typename: "Document",
      id: string,
      breedingId?: string | null,
      consultationId?: string | null,
      name: string,
      key?: string | null,
      s3file: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MessagesByConsultationQueryVariables = {
  consultationId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MessagesByConsultationQuery = {
  messagesByConsultation?:  {
    __typename: "ModelMessageConnection",
    items:  Array< {
      __typename: "Message",
      id: string,
      consultationId: string,
      message: string,
      userId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MessagesByUserQueryVariables = {
  userId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MessagesByUserQuery = {
  messagesByUser?:  {
    __typename: "ModelMessageConnection",
    items:  Array< {
      __typename: "Message",
      id: string,
      consultationId: string,
      message: string,
      userId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPendingInvitationQueryVariables = {
  id: string,
};

export type GetPendingInvitationQuery = {
  getPendingInvitation?:  {
    __typename: "PendingInvitation",
    id: string,
    breedingId: string,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emailCurrentUser: string,
    email: string,
    createdAt: string,
    initial?: boolean | null,
    refusedAt?: string | null,
    acceptedAt?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListPendingInvitationsQueryVariables = {
  filter?: ModelPendingInvitationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPendingInvitationsQuery = {
  listPendingInvitations?:  {
    __typename: "ModelPendingInvitationConnection",
    items:  Array< {
      __typename: "PendingInvitation",
      id: string,
      breedingId: string,
      emailCurrentUser: string,
      email: string,
      createdAt: string,
      initial?: boolean | null,
      refusedAt?: string | null,
      acceptedAt?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPendingInvitationsQueryVariables = {
  filter?: ModelPendingInvitationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPendingInvitationsQuery = {
  syncPendingInvitations?:  {
    __typename: "ModelPendingInvitationConnection",
    items:  Array< {
      __typename: "PendingInvitation",
      id: string,
      breedingId: string,
      emailCurrentUser: string,
      email: string,
      createdAt: string,
      initial?: boolean | null,
      refusedAt?: string | null,
      acceptedAt?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type PendingInvitationsByEmailQueryVariables = {
  email: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPendingInvitationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PendingInvitationsByEmailQuery = {
  pendingInvitationsByEmail?:  {
    __typename: "ModelPendingInvitationConnection",
    items:  Array< {
      __typename: "PendingInvitation",
      id: string,
      breedingId: string,
      emailCurrentUser: string,
      email: string,
      createdAt: string,
      initial?: boolean | null,
      refusedAt?: string | null,
      acceptedAt?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetConsultationQueryVariables = {
  id: string,
};

export type GetConsultationQuery = {
  getConsultation?:  {
    __typename: "Consultation",
    id: string,
    breedingId: string,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    animalId?: string | null,
    animal?:  {
      __typename: "Animals",
      id: string,
      numberID: string,
      name?: string | null,
      gender?: GenderType | null,
      birthDate?: string | null,
      breedingId: string,
      lotsId?: string | null,
      zoneId?: string | null,
      buildingId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    lotId?: string | null,
    lot?:  {
      __typename: "Lots",
      id: string,
      numberLot: string,
      name?: string | null,
      buildingId?: string | null,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    diseaseId?: string | null,
    disease?:  {
      __typename: "disease",
      id: string,
      codeIdentification?: string | null,
      name: string,
      forme?: string | null,
      description: string,
      synonymes?: string | null,
      enjeux?: string | null,
      causes?: string | null,
      circonstancesApparition?: string | null,
      diagnosticDiff?: string | null,
      diagnosticLab?: string | null,
      traitementCuratif?: string | null,
      pronostic?: string | null,
      prevention?: string | null,
      url?: string | null,
      image?: Array< string | null > | null,
      ponderationDisease?: string | null,
      ponderationCount?: number | null,
      familleMaladie?: string | null,
      familleMaladieId?: number | null,
      MRLC?: boolean | null,
      maladieUrgente?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    shared?: boolean | null,
    sharedAt?: string | null,
    autoExam?: boolean | null,
    autoExamRes?:  {
      __typename: "AutoExamRes",
      reponse1?: string | null,
    } | null,
    teleconsultation?: boolean | null,
    priceConsultation?: number | null,
    diseaseIsVerified?: Array< string | null > | null,
    isDone?: boolean | null,
    factureId?: string | null,
    facture?:  {
      __typename: "Document",
      id: string,
      breedingId?: string | null,
      consultationId?: string | null,
      name: string,
      key?: string | null,
      s3file: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      nextToken?: string | null,
    } | null,
    sharedFacture?: boolean | null,
    sharedFactureAt?: string | null,
    teleconsAskAt?: string | null,
    dateTeleCons?: string | null,
    questionResponse?: string | null,
    note?: number | null,
    questionResponseOrder?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListConsultationsQueryVariables = {
  filter?: ModelConsultationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListConsultationsQuery = {
  listConsultations?:  {
    __typename: "ModelConsultationConnection",
    items:  Array< {
      __typename: "Consultation",
      id: string,
      breedingId: string,
      animalId?: string | null,
      lotId?: string | null,
      diseaseId?: string | null,
      shared?: boolean | null,
      sharedAt?: string | null,
      autoExam?: boolean | null,
      teleconsultation?: boolean | null,
      priceConsultation?: number | null,
      diseaseIsVerified?: Array< string | null > | null,
      isDone?: boolean | null,
      factureId?: string | null,
      sharedFacture?: boolean | null,
      sharedFactureAt?: string | null,
      teleconsAskAt?: string | null,
      dateTeleCons?: string | null,
      questionResponse?: string | null,
      note?: number | null,
      questionResponseOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncConsultationsQueryVariables = {
  filter?: ModelConsultationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncConsultationsQuery = {
  syncConsultations?:  {
    __typename: "ModelConsultationConnection",
    items:  Array< {
      __typename: "Consultation",
      id: string,
      breedingId: string,
      animalId?: string | null,
      lotId?: string | null,
      diseaseId?: string | null,
      shared?: boolean | null,
      sharedAt?: string | null,
      autoExam?: boolean | null,
      teleconsultation?: boolean | null,
      priceConsultation?: number | null,
      diseaseIsVerified?: Array< string | null > | null,
      isDone?: boolean | null,
      factureId?: string | null,
      sharedFacture?: boolean | null,
      sharedFactureAt?: string | null,
      teleconsAskAt?: string | null,
      dateTeleCons?: string | null,
      questionResponse?: string | null,
      note?: number | null,
      questionResponseOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListConsultationsByBreedingQueryVariables = {
  breedingId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelConsultationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListConsultationsByBreedingQuery = {
  listConsultationsByBreeding?:  {
    __typename: "ModelConsultationConnection",
    items:  Array< {
      __typename: "Consultation",
      id: string,
      breedingId: string,
      animalId?: string | null,
      lotId?: string | null,
      diseaseId?: string | null,
      shared?: boolean | null,
      sharedAt?: string | null,
      autoExam?: boolean | null,
      teleconsultation?: boolean | null,
      priceConsultation?: number | null,
      diseaseIsVerified?: Array< string | null > | null,
      isDone?: boolean | null,
      factureId?: string | null,
      sharedFacture?: boolean | null,
      sharedFactureAt?: string | null,
      teleconsAskAt?: string | null,
      dateTeleCons?: string | null,
      questionResponse?: string | null,
      note?: number | null,
      questionResponseOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetDiseaseQueryVariables = {
  id: string,
};

export type GetDiseaseQuery = {
  getDisease?:  {
    __typename: "disease",
    id: string,
    codeIdentification?: string | null,
    name: string,
    forme?: string | null,
    description: string,
    synonymes?: string | null,
    enjeux?: string | null,
    causes?: string | null,
    circonstancesApparition?: string | null,
    diagnosticDiff?: string | null,
    diagnosticLab?: string | null,
    traitementCuratif?: string | null,
    pronostic?: string | null,
    prevention?: string | null,
    url?: string | null,
    consultations?:  {
      __typename: "ModelConsultationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    image?: Array< string | null > | null,
    ponderationDisease?: string | null,
    ponderationCount?: number | null,
    familleMaladie?: string | null,
    familleMaladieId?: number | null,
    MRLC?: boolean | null,
    maladieUrgente?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListDiseasesQueryVariables = {
  filter?: ModelDiseaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDiseasesQuery = {
  listDiseases?:  {
    __typename: "ModelDiseaseConnection",
    items:  Array< {
      __typename: "disease",
      id: string,
      codeIdentification?: string | null,
      name: string,
      forme?: string | null,
      description: string,
      synonymes?: string | null,
      enjeux?: string | null,
      causes?: string | null,
      circonstancesApparition?: string | null,
      diagnosticDiff?: string | null,
      diagnosticLab?: string | null,
      traitementCuratif?: string | null,
      pronostic?: string | null,
      prevention?: string | null,
      url?: string | null,
      image?: Array< string | null > | null,
      ponderationDisease?: string | null,
      ponderationCount?: number | null,
      familleMaladie?: string | null,
      familleMaladieId?: number | null,
      MRLC?: boolean | null,
      maladieUrgente?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDiseasesQueryVariables = {
  filter?: ModelDiseaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDiseasesQuery = {
  syncDiseases?:  {
    __typename: "ModelDiseaseConnection",
    items:  Array< {
      __typename: "disease",
      id: string,
      codeIdentification?: string | null,
      name: string,
      forme?: string | null,
      description: string,
      synonymes?: string | null,
      enjeux?: string | null,
      causes?: string | null,
      circonstancesApparition?: string | null,
      diagnosticDiff?: string | null,
      diagnosticLab?: string | null,
      traitementCuratif?: string | null,
      pronostic?: string | null,
      prevention?: string | null,
      url?: string | null,
      image?: Array< string | null > | null,
      ponderationDisease?: string | null,
      ponderationCount?: number | null,
      familleMaladie?: string | null,
      familleMaladieId?: number | null,
      MRLC?: boolean | null,
      maladieUrgente?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type DiseaseByCodeIdentificationQueryVariables = {
  codeIdentification: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModeldiseaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DiseaseByCodeIdentificationQuery = {
  diseaseByCodeIdentification?:  {
    __typename: "ModeldiseaseConnection",
    items:  Array< {
      __typename: "disease",
      id: string,
      codeIdentification?: string | null,
      name: string,
      forme?: string | null,
      description: string,
      synonymes?: string | null,
      enjeux?: string | null,
      causes?: string | null,
      circonstancesApparition?: string | null,
      diagnosticDiff?: string | null,
      diagnosticLab?: string | null,
      traitementCuratif?: string | null,
      pronostic?: string | null,
      prevention?: string | null,
      url?: string | null,
      image?: Array< string | null > | null,
      ponderationDisease?: string | null,
      ponderationCount?: number | null,
      familleMaladie?: string | null,
      familleMaladieId?: number | null,
      MRLC?: boolean | null,
      maladieUrgente?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProtocolSoinQueryVariables = {
  id: string,
};

export type GetProtocolSoinQuery = {
  getProtocolSoin?:  {
    __typename: "ProtocolSoin",
    id: string,
    idDiseaseFamily?: string | null,
    name?: string | null,
    Protocol?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListProtocolSoinsQueryVariables = {
  filter?: ModelProtocolSoinFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProtocolSoinsQuery = {
  listProtocolSoins?:  {
    __typename: "ModelProtocolSoinConnection",
    items:  Array< {
      __typename: "ProtocolSoin",
      id: string,
      idDiseaseFamily?: string | null,
      name?: string | null,
      Protocol?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncProtocolSoinsQueryVariables = {
  filter?: ModelProtocolSoinFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncProtocolSoinsQuery = {
  syncProtocolSoins?:  {
    __typename: "ModelProtocolSoinConnection",
    items:  Array< {
      __typename: "ProtocolSoin",
      id: string,
      idDiseaseFamily?: string | null,
      name?: string | null,
      Protocol?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ProtocolSoinByIdDiseaseFamilyQueryVariables = {
  idDiseaseFamily: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProtocolSoinFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProtocolSoinByIdDiseaseFamilyQuery = {
  protocolSoinByIdDiseaseFamily?:  {
    __typename: "ModelProtocolSoinConnection",
    items:  Array< {
      __typename: "ProtocolSoin",
      id: string,
      idDiseaseFamily?: string | null,
      name?: string | null,
      Protocol?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetProtocolSoinSaveQueryVariables = {
  id: string,
};

export type GetProtocolSoinSaveQuery = {
  getProtocolSoinSave?:  {
    __typename: "ProtocolSoinSave",
    id: string,
    breedingId?: string | null,
    bseId?: string | null,
    idDiseaseFamily?: string | null,
    new?: boolean | null,
    name?: string | null,
    Protocol?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListProtocolSoinSavesQueryVariables = {
  filter?: ModelProtocolSoinSaveFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProtocolSoinSavesQuery = {
  listProtocolSoinSaves?:  {
    __typename: "ModelProtocolSoinSaveConnection",
    items:  Array< {
      __typename: "ProtocolSoinSave",
      id: string,
      breedingId?: string | null,
      bseId?: string | null,
      idDiseaseFamily?: string | null,
      new?: boolean | null,
      name?: string | null,
      Protocol?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncProtocolSoinSavesQueryVariables = {
  filter?: ModelProtocolSoinSaveFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncProtocolSoinSavesQuery = {
  syncProtocolSoinSaves?:  {
    __typename: "ModelProtocolSoinSaveConnection",
    items:  Array< {
      __typename: "ProtocolSoinSave",
      id: string,
      breedingId?: string | null,
      bseId?: string | null,
      idDiseaseFamily?: string | null,
      new?: boolean | null,
      name?: string | null,
      Protocol?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ProtocolSoinSaveByIdBreedingIdQueryVariables = {
  breedingId: string,
  idDiseaseFamily?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProtocolSoinSaveFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProtocolSoinSaveByIdBreedingIdQuery = {
  protocolSoinSaveByIdBreedingId?:  {
    __typename: "ModelProtocolSoinSaveConnection",
    items:  Array< {
      __typename: "ProtocolSoinSave",
      id: string,
      breedingId?: string | null,
      bseId?: string | null,
      idDiseaseFamily?: string | null,
      new?: boolean | null,
      name?: string | null,
      Protocol?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ProtocolSoinSaveByBseIdQueryVariables = {
  bseId: string,
  idDiseaseFamily?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProtocolSoinSaveFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProtocolSoinSaveByBseIdQuery = {
  protocolSoinSaveByBseId?:  {
    __typename: "ModelProtocolSoinSaveConnection",
    items:  Array< {
      __typename: "ProtocolSoinSave",
      id: string,
      breedingId?: string | null,
      bseId?: string | null,
      idDiseaseFamily?: string | null,
      new?: boolean | null,
      name?: string | null,
      Protocol?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ProtocolSoinSaveByIdDiseaseFamilyQueryVariables = {
  idDiseaseFamily: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProtocolSoinSaveFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProtocolSoinSaveByIdDiseaseFamilyQuery = {
  protocolSoinSaveByIdDiseaseFamily?:  {
    __typename: "ModelProtocolSoinSaveConnection",
    items:  Array< {
      __typename: "ProtocolSoinSave",
      id: string,
      breedingId?: string | null,
      bseId?: string | null,
      idDiseaseFamily?: string | null,
      new?: boolean | null,
      name?: string | null,
      Protocol?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetNotificationQueryVariables = {
  id: string,
  createdAt: string,
};

export type GetNotificationQuery = {
  getNotification?:  {
    __typename: "Notification",
    id: string,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      lastname?: string | null,
      firstname?: string | null,
      avatarUri?: string | null,
      userGroup?: string | null,
      optIn?: boolean | null,
      inscriptionNumberOrdreVeto?: string | null,
      breedingId?: string | null,
      vetOfficeId?: string | null,
      email?: string | null,
      expoToken?: Array< string > | null,
      notificationLastSeenAt?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    type: string,
    title: string,
    body: string,
    data?: string | null,
    clicked?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListNotificationsQueryVariables = {
  id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListNotificationsQuery = {
  listNotifications?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      userId: string,
      type: string,
      title: string,
      body: string,
      data?: string | null,
      clicked?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncNotificationsQueryVariables = {
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncNotificationsQuery = {
  syncNotifications?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      userId: string,
      type: string,
      title: string,
      body: string,
      data?: string | null,
      clicked?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetNotificationByIdQueryVariables = {
  id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetNotificationByIdQuery = {
  getNotificationById?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      userId: string,
      type: string,
      title: string,
      body: string,
      data?: string | null,
      clicked?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListNotificationsByUserQueryVariables = {
  userId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationsByUserQuery = {
  listNotificationsByUser?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      userId: string,
      type: string,
      title: string,
      body: string,
      data?: string | null,
      clicked?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetNotificationTicketsQueryVariables = {
  id: string,
};

export type GetNotificationTicketsQuery = {
  getNotificationTickets?:  {
    __typename: "NotificationTickets",
    id: string,
    expoTokens?:  Array< {
      __typename: "UserToken",
      userId: string,
      token: string,
    } > | null,
    ticketIds?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListNotificationTicketsQueryVariables = {
  filter?: ModelNotificationTicketsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationTicketsQuery = {
  listNotificationTickets?:  {
    __typename: "ModelNotificationTicketsConnection",
    items:  Array< {
      __typename: "NotificationTickets",
      id: string,
      ticketIds?: Array< string > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncNotificationTicketsQueryVariables = {
  filter?: ModelNotificationTicketsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncNotificationTicketsQuery = {
  syncNotificationTickets?:  {
    __typename: "ModelNotificationTicketsConnection",
    items:  Array< {
      __typename: "NotificationTickets",
      id: string,
      ticketIds?: Array< string > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OnCreateMessageByConsultationIdSubscriptionVariables = {
  consultationId: string,
};

export type OnCreateMessageByConsultationIdSubscription = {
  onCreateMessageByConsultationId?:  {
    __typename: "Message",
    id: string,
    consultationId: string,
    consultation?:  {
      __typename: "Consultation",
      id: string,
      breedingId: string,
      animalId?: string | null,
      lotId?: string | null,
      diseaseId?: string | null,
      shared?: boolean | null,
      sharedAt?: string | null,
      autoExam?: boolean | null,
      teleconsultation?: boolean | null,
      priceConsultation?: number | null,
      diseaseIsVerified?: Array< string | null > | null,
      isDone?: boolean | null,
      factureId?: string | null,
      sharedFacture?: boolean | null,
      sharedFactureAt?: string | null,
      teleconsAskAt?: string | null,
      dateTeleCons?: string | null,
      questionResponse?: string | null,
      note?: number | null,
      questionResponseOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    message: string,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      lastname?: string | null,
      firstname?: string | null,
      avatarUri?: string | null,
      userGroup?: string | null,
      optIn?: boolean | null,
      inscriptionNumberOrdreVeto?: string | null,
      breedingId?: string | null,
      vetOfficeId?: string | null,
      email?: string | null,
      expoToken?: Array< string > | null,
      notificationLastSeenAt?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateDocumentByConsultationIdSubscriptionVariables = {
  consultationId: string,
};

export type OnCreateDocumentByConsultationIdSubscription = {
  onCreateDocumentByConsultationId?:  {
    __typename: "Document",
    id: string,
    breedingId?: string | null,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    consultationId?: string | null,
    consultation?:  {
      __typename: "Consultation",
      id: string,
      breedingId: string,
      animalId?: string | null,
      lotId?: string | null,
      diseaseId?: string | null,
      shared?: boolean | null,
      sharedAt?: string | null,
      autoExam?: boolean | null,
      teleconsultation?: boolean | null,
      priceConsultation?: number | null,
      diseaseIsVerified?: Array< string | null > | null,
      isDone?: boolean | null,
      factureId?: string | null,
      sharedFacture?: boolean | null,
      sharedFactureAt?: string | null,
      teleconsAskAt?: string | null,
      dateTeleCons?: string | null,
      questionResponse?: string | null,
      note?: number | null,
      questionResponseOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    name: string,
    key?: string | null,
    s3file: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteDocumentByConsultationIdSubscriptionVariables = {
  consultationId: string,
};

export type OnDeleteDocumentByConsultationIdSubscription = {
  onDeleteDocumentByConsultationId?:  {
    __typename: "Document",
    id: string,
    breedingId?: string | null,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    consultationId?: string | null,
    consultation?:  {
      __typename: "Consultation",
      id: string,
      breedingId: string,
      animalId?: string | null,
      lotId?: string | null,
      diseaseId?: string | null,
      shared?: boolean | null,
      sharedAt?: string | null,
      autoExam?: boolean | null,
      teleconsultation?: boolean | null,
      priceConsultation?: number | null,
      diseaseIsVerified?: Array< string | null > | null,
      isDone?: boolean | null,
      factureId?: string | null,
      sharedFacture?: boolean | null,
      sharedFactureAt?: string | null,
      teleconsAskAt?: string | null,
      dateTeleCons?: string | null,
      questionResponse?: string | null,
      note?: number | null,
      questionResponseOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    name: string,
    key?: string | null,
    s3file: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnAskBSEStartSubscriptionVariables = {
  id: string,
};

export type OnAskBSEStartSubscription = {
  onAskBSEStart?:  {
    __typename: "BSE",
    id: string,
    breedingId: string,
    StatusBse?: StatusBse | null,
    dateBSE?: string | null,
    crS3Key?: string | null,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questionResponse?: string | null,
    lasIdQuestion?: string | null,
    idEleveurUser?: string | null,
    lastQuestionVeto?: string | null,
    questionResponseOrder?: Array< string | null > | null,
    maladieRecurente?: Array< string | null > | null,
    protocolesDeSoins?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnConfirmBreedingMandateSubscriptionVariables = {
  id: string,
};

export type OnConfirmBreedingMandateSubscription = {
  onConfirmBreedingMandate?:  {
    __typename: "Breeding",
    id: string,
    locked: LockedStatus,
    nBreeding: string,
    nSIRET: string,
    phone: string,
    emailEleveur: string,
    address?:  {
      __typename: "Address",
      address: string,
      additionalAddress?: string | null,
      postalCode: string,
      city: string,
    } | null,
    companyName: string,
    dairyFarm: boolean,
    sucklerCowHusbandry: boolean,
    fatteningFarm: boolean,
    nbCow: number,
    nbHeifer: number,
    nbMale: number,
    nbYoung: number,
    idVeto?: string | null,
    emailVeto?: string | null,
    admins: Array< string >,
    vetOffice?:  {
      __typename: "VetOffice",
      id: string,
      locked: LockedStatus,
      companyName: string,
      emailOffice: string,
      admins: Array< string >,
      personnel?: Array< string > | null,
      phoneVetOffice?: string | null,
      nameVetSanitaire?: string | null,
      nOrdreCabinet: string,
      nSIRET: string,
      maxPriceTeleConsultation: number,
      maxPriceConsultation: number,
      mpUserId?: string | null,
      isFree?: boolean | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    users?:  {
      __typename: "ModelUserConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      nextToken?: string | null,
    } | null,
    animals?:  {
      __typename: "ModelAnimalsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    lots?:  {
      __typename: "ModelLotsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    buildings?:  {
      __typename: "ModelBuildingsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    bse?:  {
      __typename: "ModelBSEConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    pendingInvitations?:  {
      __typename: "ModelPendingInvitationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    consultations?:  {
      __typename: "ModelConsultationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    billingHistories?:  {
      __typename: "ModelBillingHistoryConnection",
      nextToken?: string | null,
    } | null,
    mpUserId?: string | null,
    mpWalletId?: string | null,
    mpBankId?: string | null,
    mpMandateId?: string | null,
    mpMandateConfirmed?: boolean | null,
    createdAt: string,
    dateFirstCons?: string | null,
    nextSubscriptionDate?: string | null,
    subscriptionAmount?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnConfirmVetOfficeMandateSubscriptionVariables = {
  id: string,
};

export type OnConfirmVetOfficeMandateSubscription = {
  onConfirmVetOfficeMandate?:  {
    __typename: "VetOffice",
    id: string,
    locked: LockedStatus,
    companyName: string,
    emailOffice: string,
    admins: Array< string >,
    personnel?: Array< string > | null,
    phoneVetOffice?: string | null,
    address?:  {
      __typename: "Address",
      address: string,
      additionalAddress?: string | null,
      postalCode: string,
      city: string,
    } | null,
    nameVetSanitaire?: string | null,
    nOrdreCabinet: string,
    nSIRET: string,
    maxPriceTeleConsultation: number,
    maxPriceConsultation: number,
    users?:  {
      __typename: "ModelUserConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    breedings?:  {
      __typename: "ModelBreedingConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    billingHistories?:  {
      __typename: "ModelBillingHistoryConnection",
      nextToken?: string | null,
    } | null,
    mpUserId?: string | null,
    isFree?: boolean | null,
    mpWalletId?: string | null,
    mpBankId?: string | null,
    mpMandateId?: string | null,
    mpMandateConfirmed?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  id?: string | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    lastname?: string | null,
    firstname?: string | null,
    avatarUri?: string | null,
    userGroup?: string | null,
    optIn?: boolean | null,
    inscriptionNumberOrdreVeto?: string | null,
    breedingId?: string | null,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    vetOfficeId?: string | null,
    vetOffice?:  {
      __typename: "VetOffice",
      id: string,
      locked: LockedStatus,
      companyName: string,
      emailOffice: string,
      admins: Array< string >,
      personnel?: Array< string > | null,
      phoneVetOffice?: string | null,
      nameVetSanitaire?: string | null,
      nOrdreCabinet: string,
      nSIRET: string,
      maxPriceTeleConsultation: number,
      maxPriceConsultation: number,
      mpUserId?: string | null,
      isFree?: boolean | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    email?: string | null,
    expoToken?: Array< string > | null,
    notificationLastSeenAt?: string | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  id?: string | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    lastname?: string | null,
    firstname?: string | null,
    avatarUri?: string | null,
    userGroup?: string | null,
    optIn?: boolean | null,
    inscriptionNumberOrdreVeto?: string | null,
    breedingId?: string | null,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    vetOfficeId?: string | null,
    vetOffice?:  {
      __typename: "VetOffice",
      id: string,
      locked: LockedStatus,
      companyName: string,
      emailOffice: string,
      admins: Array< string >,
      personnel?: Array< string > | null,
      phoneVetOffice?: string | null,
      nameVetSanitaire?: string | null,
      nOrdreCabinet: string,
      nSIRET: string,
      maxPriceTeleConsultation: number,
      maxPriceConsultation: number,
      mpUserId?: string | null,
      isFree?: boolean | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    email?: string | null,
    expoToken?: Array< string > | null,
    notificationLastSeenAt?: string | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  id?: string | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    lastname?: string | null,
    firstname?: string | null,
    avatarUri?: string | null,
    userGroup?: string | null,
    optIn?: boolean | null,
    inscriptionNumberOrdreVeto?: string | null,
    breedingId?: string | null,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    vetOfficeId?: string | null,
    vetOffice?:  {
      __typename: "VetOffice",
      id: string,
      locked: LockedStatus,
      companyName: string,
      emailOffice: string,
      admins: Array< string >,
      personnel?: Array< string > | null,
      phoneVetOffice?: string | null,
      nameVetSanitaire?: string | null,
      nOrdreCabinet: string,
      nSIRET: string,
      maxPriceTeleConsultation: number,
      maxPriceConsultation: number,
      mpUserId?: string | null,
      isFree?: boolean | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    email?: string | null,
    expoToken?: Array< string > | null,
    notificationLastSeenAt?: string | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateVetOfficeSubscriptionVariables = {
  filter?: ModelSubscriptionVetOfficeFilterInput | null,
};

export type OnCreateVetOfficeSubscription = {
  onCreateVetOffice?:  {
    __typename: "VetOffice",
    id: string,
    locked: LockedStatus,
    companyName: string,
    emailOffice: string,
    admins: Array< string >,
    personnel?: Array< string > | null,
    phoneVetOffice?: string | null,
    address?:  {
      __typename: "Address",
      address: string,
      additionalAddress?: string | null,
      postalCode: string,
      city: string,
    } | null,
    nameVetSanitaire?: string | null,
    nOrdreCabinet: string,
    nSIRET: string,
    maxPriceTeleConsultation: number,
    maxPriceConsultation: number,
    users?:  {
      __typename: "ModelUserConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    breedings?:  {
      __typename: "ModelBreedingConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    billingHistories?:  {
      __typename: "ModelBillingHistoryConnection",
      nextToken?: string | null,
    } | null,
    mpUserId?: string | null,
    isFree?: boolean | null,
    mpWalletId?: string | null,
    mpBankId?: string | null,
    mpMandateId?: string | null,
    mpMandateConfirmed?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateVetOfficeSubscriptionVariables = {
  filter?: ModelSubscriptionVetOfficeFilterInput | null,
};

export type OnUpdateVetOfficeSubscription = {
  onUpdateVetOffice?:  {
    __typename: "VetOffice",
    id: string,
    locked: LockedStatus,
    companyName: string,
    emailOffice: string,
    admins: Array< string >,
    personnel?: Array< string > | null,
    phoneVetOffice?: string | null,
    address?:  {
      __typename: "Address",
      address: string,
      additionalAddress?: string | null,
      postalCode: string,
      city: string,
    } | null,
    nameVetSanitaire?: string | null,
    nOrdreCabinet: string,
    nSIRET: string,
    maxPriceTeleConsultation: number,
    maxPriceConsultation: number,
    users?:  {
      __typename: "ModelUserConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    breedings?:  {
      __typename: "ModelBreedingConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    billingHistories?:  {
      __typename: "ModelBillingHistoryConnection",
      nextToken?: string | null,
    } | null,
    mpUserId?: string | null,
    isFree?: boolean | null,
    mpWalletId?: string | null,
    mpBankId?: string | null,
    mpMandateId?: string | null,
    mpMandateConfirmed?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteVetOfficeSubscriptionVariables = {
  filter?: ModelSubscriptionVetOfficeFilterInput | null,
};

export type OnDeleteVetOfficeSubscription = {
  onDeleteVetOffice?:  {
    __typename: "VetOffice",
    id: string,
    locked: LockedStatus,
    companyName: string,
    emailOffice: string,
    admins: Array< string >,
    personnel?: Array< string > | null,
    phoneVetOffice?: string | null,
    address?:  {
      __typename: "Address",
      address: string,
      additionalAddress?: string | null,
      postalCode: string,
      city: string,
    } | null,
    nameVetSanitaire?: string | null,
    nOrdreCabinet: string,
    nSIRET: string,
    maxPriceTeleConsultation: number,
    maxPriceConsultation: number,
    users?:  {
      __typename: "ModelUserConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    breedings?:  {
      __typename: "ModelBreedingConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    billingHistories?:  {
      __typename: "ModelBillingHistoryConnection",
      nextToken?: string | null,
    } | null,
    mpUserId?: string | null,
    isFree?: boolean | null,
    mpWalletId?: string | null,
    mpBankId?: string | null,
    mpMandateId?: string | null,
    mpMandateConfirmed?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateBreedingSubscriptionVariables = {
  filter?: ModelSubscriptionBreedingFilterInput | null,
};

export type OnCreateBreedingSubscription = {
  onCreateBreeding?:  {
    __typename: "Breeding",
    id: string,
    locked: LockedStatus,
    nBreeding: string,
    nSIRET: string,
    phone: string,
    emailEleveur: string,
    address?:  {
      __typename: "Address",
      address: string,
      additionalAddress?: string | null,
      postalCode: string,
      city: string,
    } | null,
    companyName: string,
    dairyFarm: boolean,
    sucklerCowHusbandry: boolean,
    fatteningFarm: boolean,
    nbCow: number,
    nbHeifer: number,
    nbMale: number,
    nbYoung: number,
    idVeto?: string | null,
    emailVeto?: string | null,
    admins: Array< string >,
    vetOffice?:  {
      __typename: "VetOffice",
      id: string,
      locked: LockedStatus,
      companyName: string,
      emailOffice: string,
      admins: Array< string >,
      personnel?: Array< string > | null,
      phoneVetOffice?: string | null,
      nameVetSanitaire?: string | null,
      nOrdreCabinet: string,
      nSIRET: string,
      maxPriceTeleConsultation: number,
      maxPriceConsultation: number,
      mpUserId?: string | null,
      isFree?: boolean | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    users?:  {
      __typename: "ModelUserConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      nextToken?: string | null,
    } | null,
    animals?:  {
      __typename: "ModelAnimalsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    lots?:  {
      __typename: "ModelLotsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    buildings?:  {
      __typename: "ModelBuildingsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    bse?:  {
      __typename: "ModelBSEConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    pendingInvitations?:  {
      __typename: "ModelPendingInvitationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    consultations?:  {
      __typename: "ModelConsultationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    billingHistories?:  {
      __typename: "ModelBillingHistoryConnection",
      nextToken?: string | null,
    } | null,
    mpUserId?: string | null,
    mpWalletId?: string | null,
    mpBankId?: string | null,
    mpMandateId?: string | null,
    mpMandateConfirmed?: boolean | null,
    createdAt: string,
    dateFirstCons?: string | null,
    nextSubscriptionDate?: string | null,
    subscriptionAmount?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateBreedingSubscriptionVariables = {
  filter?: ModelSubscriptionBreedingFilterInput | null,
};

export type OnUpdateBreedingSubscription = {
  onUpdateBreeding?:  {
    __typename: "Breeding",
    id: string,
    locked: LockedStatus,
    nBreeding: string,
    nSIRET: string,
    phone: string,
    emailEleveur: string,
    address?:  {
      __typename: "Address",
      address: string,
      additionalAddress?: string | null,
      postalCode: string,
      city: string,
    } | null,
    companyName: string,
    dairyFarm: boolean,
    sucklerCowHusbandry: boolean,
    fatteningFarm: boolean,
    nbCow: number,
    nbHeifer: number,
    nbMale: number,
    nbYoung: number,
    idVeto?: string | null,
    emailVeto?: string | null,
    admins: Array< string >,
    vetOffice?:  {
      __typename: "VetOffice",
      id: string,
      locked: LockedStatus,
      companyName: string,
      emailOffice: string,
      admins: Array< string >,
      personnel?: Array< string > | null,
      phoneVetOffice?: string | null,
      nameVetSanitaire?: string | null,
      nOrdreCabinet: string,
      nSIRET: string,
      maxPriceTeleConsultation: number,
      maxPriceConsultation: number,
      mpUserId?: string | null,
      isFree?: boolean | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    users?:  {
      __typename: "ModelUserConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      nextToken?: string | null,
    } | null,
    animals?:  {
      __typename: "ModelAnimalsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    lots?:  {
      __typename: "ModelLotsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    buildings?:  {
      __typename: "ModelBuildingsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    bse?:  {
      __typename: "ModelBSEConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    pendingInvitations?:  {
      __typename: "ModelPendingInvitationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    consultations?:  {
      __typename: "ModelConsultationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    billingHistories?:  {
      __typename: "ModelBillingHistoryConnection",
      nextToken?: string | null,
    } | null,
    mpUserId?: string | null,
    mpWalletId?: string | null,
    mpBankId?: string | null,
    mpMandateId?: string | null,
    mpMandateConfirmed?: boolean | null,
    createdAt: string,
    dateFirstCons?: string | null,
    nextSubscriptionDate?: string | null,
    subscriptionAmount?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteBreedingSubscriptionVariables = {
  filter?: ModelSubscriptionBreedingFilterInput | null,
};

export type OnDeleteBreedingSubscription = {
  onDeleteBreeding?:  {
    __typename: "Breeding",
    id: string,
    locked: LockedStatus,
    nBreeding: string,
    nSIRET: string,
    phone: string,
    emailEleveur: string,
    address?:  {
      __typename: "Address",
      address: string,
      additionalAddress?: string | null,
      postalCode: string,
      city: string,
    } | null,
    companyName: string,
    dairyFarm: boolean,
    sucklerCowHusbandry: boolean,
    fatteningFarm: boolean,
    nbCow: number,
    nbHeifer: number,
    nbMale: number,
    nbYoung: number,
    idVeto?: string | null,
    emailVeto?: string | null,
    admins: Array< string >,
    vetOffice?:  {
      __typename: "VetOffice",
      id: string,
      locked: LockedStatus,
      companyName: string,
      emailOffice: string,
      admins: Array< string >,
      personnel?: Array< string > | null,
      phoneVetOffice?: string | null,
      nameVetSanitaire?: string | null,
      nOrdreCabinet: string,
      nSIRET: string,
      maxPriceTeleConsultation: number,
      maxPriceConsultation: number,
      mpUserId?: string | null,
      isFree?: boolean | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    users?:  {
      __typename: "ModelUserConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      nextToken?: string | null,
    } | null,
    animals?:  {
      __typename: "ModelAnimalsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    lots?:  {
      __typename: "ModelLotsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    buildings?:  {
      __typename: "ModelBuildingsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    bse?:  {
      __typename: "ModelBSEConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    pendingInvitations?:  {
      __typename: "ModelPendingInvitationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    consultations?:  {
      __typename: "ModelConsultationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    billingHistories?:  {
      __typename: "ModelBillingHistoryConnection",
      nextToken?: string | null,
    } | null,
    mpUserId?: string | null,
    mpWalletId?: string | null,
    mpBankId?: string | null,
    mpMandateId?: string | null,
    mpMandateConfirmed?: boolean | null,
    createdAt: string,
    dateFirstCons?: string | null,
    nextSubscriptionDate?: string | null,
    subscriptionAmount?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateAnimalsSubscriptionVariables = {
  filter?: ModelSubscriptionAnimalsFilterInput | null,
};

export type OnCreateAnimalsSubscription = {
  onCreateAnimals?:  {
    __typename: "Animals",
    id: string,
    numberID: string,
    name?: string | null,
    gender?: GenderType | null,
    birthDate?: string | null,
    breedingId: string,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    lotsId?: string | null,
    lots?:  {
      __typename: "Lots",
      id: string,
      numberLot: string,
      name?: string | null,
      buildingId?: string | null,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    consultations?:  {
      __typename: "ModelConsultationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    zoneId?: string | null,
    zone?:  {
      __typename: "Zones",
      id: string,
      name?: string | null,
      numberZone?: string | null,
      buildingId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Buildings",
      id: string,
      nameBuilding: string,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateAnimalsSubscriptionVariables = {
  filter?: ModelSubscriptionAnimalsFilterInput | null,
};

export type OnUpdateAnimalsSubscription = {
  onUpdateAnimals?:  {
    __typename: "Animals",
    id: string,
    numberID: string,
    name?: string | null,
    gender?: GenderType | null,
    birthDate?: string | null,
    breedingId: string,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    lotsId?: string | null,
    lots?:  {
      __typename: "Lots",
      id: string,
      numberLot: string,
      name?: string | null,
      buildingId?: string | null,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    consultations?:  {
      __typename: "ModelConsultationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    zoneId?: string | null,
    zone?:  {
      __typename: "Zones",
      id: string,
      name?: string | null,
      numberZone?: string | null,
      buildingId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Buildings",
      id: string,
      nameBuilding: string,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteAnimalsSubscriptionVariables = {
  filter?: ModelSubscriptionAnimalsFilterInput | null,
};

export type OnDeleteAnimalsSubscription = {
  onDeleteAnimals?:  {
    __typename: "Animals",
    id: string,
    numberID: string,
    name?: string | null,
    gender?: GenderType | null,
    birthDate?: string | null,
    breedingId: string,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    lotsId?: string | null,
    lots?:  {
      __typename: "Lots",
      id: string,
      numberLot: string,
      name?: string | null,
      buildingId?: string | null,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    consultations?:  {
      __typename: "ModelConsultationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    zoneId?: string | null,
    zone?:  {
      __typename: "Zones",
      id: string,
      name?: string | null,
      numberZone?: string | null,
      buildingId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Buildings",
      id: string,
      nameBuilding: string,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateLotsSubscriptionVariables = {
  filter?: ModelSubscriptionLotsFilterInput | null,
};

export type OnCreateLotsSubscription = {
  onCreateLots?:  {
    __typename: "Lots",
    id: string,
    numberLot: string,
    name?: string | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Buildings",
      id: string,
      nameBuilding: string,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    breedingId: string,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    animals?:  {
      __typename: "ModelAnimalsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    consultations?:  {
      __typename: "ModelConsultationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateLotsSubscriptionVariables = {
  filter?: ModelSubscriptionLotsFilterInput | null,
};

export type OnUpdateLotsSubscription = {
  onUpdateLots?:  {
    __typename: "Lots",
    id: string,
    numberLot: string,
    name?: string | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Buildings",
      id: string,
      nameBuilding: string,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    breedingId: string,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    animals?:  {
      __typename: "ModelAnimalsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    consultations?:  {
      __typename: "ModelConsultationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteLotsSubscriptionVariables = {
  filter?: ModelSubscriptionLotsFilterInput | null,
};

export type OnDeleteLotsSubscription = {
  onDeleteLots?:  {
    __typename: "Lots",
    id: string,
    numberLot: string,
    name?: string | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Buildings",
      id: string,
      nameBuilding: string,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    breedingId: string,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    animals?:  {
      __typename: "ModelAnimalsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    consultations?:  {
      __typename: "ModelConsultationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateBuildingsSubscriptionVariables = {
  filter?: ModelSubscriptionBuildingsFilterInput | null,
};

export type OnCreateBuildingsSubscription = {
  onCreateBuildings?:  {
    __typename: "Buildings",
    id: string,
    nameBuilding: string,
    address?:  {
      __typename: "Address",
      address: string,
      additionalAddress?: string | null,
      postalCode: string,
      city: string,
    } | null,
    breedingId: string,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    zone?:  {
      __typename: "ModelZonesConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    animals?:  {
      __typename: "ModelAnimalsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    lots?:  {
      __typename: "ModelLotsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateBuildingsSubscriptionVariables = {
  filter?: ModelSubscriptionBuildingsFilterInput | null,
};

export type OnUpdateBuildingsSubscription = {
  onUpdateBuildings?:  {
    __typename: "Buildings",
    id: string,
    nameBuilding: string,
    address?:  {
      __typename: "Address",
      address: string,
      additionalAddress?: string | null,
      postalCode: string,
      city: string,
    } | null,
    breedingId: string,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    zone?:  {
      __typename: "ModelZonesConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    animals?:  {
      __typename: "ModelAnimalsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    lots?:  {
      __typename: "ModelLotsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteBuildingsSubscriptionVariables = {
  filter?: ModelSubscriptionBuildingsFilterInput | null,
};

export type OnDeleteBuildingsSubscription = {
  onDeleteBuildings?:  {
    __typename: "Buildings",
    id: string,
    nameBuilding: string,
    address?:  {
      __typename: "Address",
      address: string,
      additionalAddress?: string | null,
      postalCode: string,
      city: string,
    } | null,
    breedingId: string,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    zone?:  {
      __typename: "ModelZonesConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    animals?:  {
      __typename: "ModelAnimalsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    lots?:  {
      __typename: "ModelLotsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateZonesSubscriptionVariables = {
  filter?: ModelSubscriptionZonesFilterInput | null,
};

export type OnCreateZonesSubscription = {
  onCreateZones?:  {
    __typename: "Zones",
    id: string,
    name?: string | null,
    numberZone?: string | null,
    animals?:  {
      __typename: "ModelAnimalsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Buildings",
      id: string,
      nameBuilding: string,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateZonesSubscriptionVariables = {
  filter?: ModelSubscriptionZonesFilterInput | null,
};

export type OnUpdateZonesSubscription = {
  onUpdateZones?:  {
    __typename: "Zones",
    id: string,
    name?: string | null,
    numberZone?: string | null,
    animals?:  {
      __typename: "ModelAnimalsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Buildings",
      id: string,
      nameBuilding: string,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteZonesSubscriptionVariables = {
  filter?: ModelSubscriptionZonesFilterInput | null,
};

export type OnDeleteZonesSubscription = {
  onDeleteZones?:  {
    __typename: "Zones",
    id: string,
    name?: string | null,
    numberZone?: string | null,
    animals?:  {
      __typename: "ModelAnimalsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Buildings",
      id: string,
      nameBuilding: string,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateBSESubscriptionVariables = {
  filter?: ModelSubscriptionBSEFilterInput | null,
};

export type OnCreateBSESubscription = {
  onCreateBSE?:  {
    __typename: "BSE",
    id: string,
    breedingId: string,
    StatusBse?: StatusBse | null,
    dateBSE?: string | null,
    crS3Key?: string | null,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questionResponse?: string | null,
    lasIdQuestion?: string | null,
    idEleveurUser?: string | null,
    lastQuestionVeto?: string | null,
    questionResponseOrder?: Array< string | null > | null,
    maladieRecurente?: Array< string | null > | null,
    protocolesDeSoins?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateBSESubscriptionVariables = {
  filter?: ModelSubscriptionBSEFilterInput | null,
};

export type OnUpdateBSESubscription = {
  onUpdateBSE?:  {
    __typename: "BSE",
    id: string,
    breedingId: string,
    StatusBse?: StatusBse | null,
    dateBSE?: string | null,
    crS3Key?: string | null,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questionResponse?: string | null,
    lasIdQuestion?: string | null,
    idEleveurUser?: string | null,
    lastQuestionVeto?: string | null,
    questionResponseOrder?: Array< string | null > | null,
    maladieRecurente?: Array< string | null > | null,
    protocolesDeSoins?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteBSESubscriptionVariables = {
  filter?: ModelSubscriptionBSEFilterInput | null,
};

export type OnDeleteBSESubscription = {
  onDeleteBSE?:  {
    __typename: "BSE",
    id: string,
    breedingId: string,
    StatusBse?: StatusBse | null,
    dateBSE?: string | null,
    crS3Key?: string | null,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questionResponse?: string | null,
    lasIdQuestion?: string | null,
    idEleveurUser?: string | null,
    lastQuestionVeto?: string | null,
    questionResponseOrder?: Array< string | null > | null,
    maladieRecurente?: Array< string | null > | null,
    protocolesDeSoins?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreatePendingInvitationSubscriptionVariables = {
  filter?: ModelSubscriptionPendingInvitationFilterInput | null,
};

export type OnCreatePendingInvitationSubscription = {
  onCreatePendingInvitation?:  {
    __typename: "PendingInvitation",
    id: string,
    breedingId: string,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emailCurrentUser: string,
    email: string,
    createdAt: string,
    initial?: boolean | null,
    refusedAt?: string | null,
    acceptedAt?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdatePendingInvitationSubscriptionVariables = {
  filter?: ModelSubscriptionPendingInvitationFilterInput | null,
};

export type OnUpdatePendingInvitationSubscription = {
  onUpdatePendingInvitation?:  {
    __typename: "PendingInvitation",
    id: string,
    breedingId: string,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emailCurrentUser: string,
    email: string,
    createdAt: string,
    initial?: boolean | null,
    refusedAt?: string | null,
    acceptedAt?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeletePendingInvitationSubscriptionVariables = {
  filter?: ModelSubscriptionPendingInvitationFilterInput | null,
};

export type OnDeletePendingInvitationSubscription = {
  onDeletePendingInvitation?:  {
    __typename: "PendingInvitation",
    id: string,
    breedingId: string,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emailCurrentUser: string,
    email: string,
    createdAt: string,
    initial?: boolean | null,
    refusedAt?: string | null,
    acceptedAt?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateConsultationSubscriptionVariables = {
  filter?: ModelSubscriptionConsultationFilterInput | null,
};

export type OnCreateConsultationSubscription = {
  onCreateConsultation?:  {
    __typename: "Consultation",
    id: string,
    breedingId: string,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    animalId?: string | null,
    animal?:  {
      __typename: "Animals",
      id: string,
      numberID: string,
      name?: string | null,
      gender?: GenderType | null,
      birthDate?: string | null,
      breedingId: string,
      lotsId?: string | null,
      zoneId?: string | null,
      buildingId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    lotId?: string | null,
    lot?:  {
      __typename: "Lots",
      id: string,
      numberLot: string,
      name?: string | null,
      buildingId?: string | null,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    diseaseId?: string | null,
    disease?:  {
      __typename: "disease",
      id: string,
      codeIdentification?: string | null,
      name: string,
      forme?: string | null,
      description: string,
      synonymes?: string | null,
      enjeux?: string | null,
      causes?: string | null,
      circonstancesApparition?: string | null,
      diagnosticDiff?: string | null,
      diagnosticLab?: string | null,
      traitementCuratif?: string | null,
      pronostic?: string | null,
      prevention?: string | null,
      url?: string | null,
      image?: Array< string | null > | null,
      ponderationDisease?: string | null,
      ponderationCount?: number | null,
      familleMaladie?: string | null,
      familleMaladieId?: number | null,
      MRLC?: boolean | null,
      maladieUrgente?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    shared?: boolean | null,
    sharedAt?: string | null,
    autoExam?: boolean | null,
    autoExamRes?:  {
      __typename: "AutoExamRes",
      reponse1?: string | null,
    } | null,
    teleconsultation?: boolean | null,
    priceConsultation?: number | null,
    diseaseIsVerified?: Array< string | null > | null,
    isDone?: boolean | null,
    factureId?: string | null,
    facture?:  {
      __typename: "Document",
      id: string,
      breedingId?: string | null,
      consultationId?: string | null,
      name: string,
      key?: string | null,
      s3file: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      nextToken?: string | null,
    } | null,
    sharedFacture?: boolean | null,
    sharedFactureAt?: string | null,
    teleconsAskAt?: string | null,
    dateTeleCons?: string | null,
    questionResponse?: string | null,
    note?: number | null,
    questionResponseOrder?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateConsultationSubscriptionVariables = {
  filter?: ModelSubscriptionConsultationFilterInput | null,
};

export type OnUpdateConsultationSubscription = {
  onUpdateConsultation?:  {
    __typename: "Consultation",
    id: string,
    breedingId: string,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    animalId?: string | null,
    animal?:  {
      __typename: "Animals",
      id: string,
      numberID: string,
      name?: string | null,
      gender?: GenderType | null,
      birthDate?: string | null,
      breedingId: string,
      lotsId?: string | null,
      zoneId?: string | null,
      buildingId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    lotId?: string | null,
    lot?:  {
      __typename: "Lots",
      id: string,
      numberLot: string,
      name?: string | null,
      buildingId?: string | null,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    diseaseId?: string | null,
    disease?:  {
      __typename: "disease",
      id: string,
      codeIdentification?: string | null,
      name: string,
      forme?: string | null,
      description: string,
      synonymes?: string | null,
      enjeux?: string | null,
      causes?: string | null,
      circonstancesApparition?: string | null,
      diagnosticDiff?: string | null,
      diagnosticLab?: string | null,
      traitementCuratif?: string | null,
      pronostic?: string | null,
      prevention?: string | null,
      url?: string | null,
      image?: Array< string | null > | null,
      ponderationDisease?: string | null,
      ponderationCount?: number | null,
      familleMaladie?: string | null,
      familleMaladieId?: number | null,
      MRLC?: boolean | null,
      maladieUrgente?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    shared?: boolean | null,
    sharedAt?: string | null,
    autoExam?: boolean | null,
    autoExamRes?:  {
      __typename: "AutoExamRes",
      reponse1?: string | null,
    } | null,
    teleconsultation?: boolean | null,
    priceConsultation?: number | null,
    diseaseIsVerified?: Array< string | null > | null,
    isDone?: boolean | null,
    factureId?: string | null,
    facture?:  {
      __typename: "Document",
      id: string,
      breedingId?: string | null,
      consultationId?: string | null,
      name: string,
      key?: string | null,
      s3file: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      nextToken?: string | null,
    } | null,
    sharedFacture?: boolean | null,
    sharedFactureAt?: string | null,
    teleconsAskAt?: string | null,
    dateTeleCons?: string | null,
    questionResponse?: string | null,
    note?: number | null,
    questionResponseOrder?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteConsultationSubscriptionVariables = {
  filter?: ModelSubscriptionConsultationFilterInput | null,
};

export type OnDeleteConsultationSubscription = {
  onDeleteConsultation?:  {
    __typename: "Consultation",
    id: string,
    breedingId: string,
    breeding?:  {
      __typename: "Breeding",
      id: string,
      locked: LockedStatus,
      nBreeding: string,
      nSIRET: string,
      phone: string,
      emailEleveur: string,
      companyName: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      idVeto?: string | null,
      emailVeto?: string | null,
      admins: Array< string >,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      createdAt: string,
      dateFirstCons?: string | null,
      nextSubscriptionDate?: string | null,
      subscriptionAmount?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    animalId?: string | null,
    animal?:  {
      __typename: "Animals",
      id: string,
      numberID: string,
      name?: string | null,
      gender?: GenderType | null,
      birthDate?: string | null,
      breedingId: string,
      lotsId?: string | null,
      zoneId?: string | null,
      buildingId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    lotId?: string | null,
    lot?:  {
      __typename: "Lots",
      id: string,
      numberLot: string,
      name?: string | null,
      buildingId?: string | null,
      breedingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    diseaseId?: string | null,
    disease?:  {
      __typename: "disease",
      id: string,
      codeIdentification?: string | null,
      name: string,
      forme?: string | null,
      description: string,
      synonymes?: string | null,
      enjeux?: string | null,
      causes?: string | null,
      circonstancesApparition?: string | null,
      diagnosticDiff?: string | null,
      diagnosticLab?: string | null,
      traitementCuratif?: string | null,
      pronostic?: string | null,
      prevention?: string | null,
      url?: string | null,
      image?: Array< string | null > | null,
      ponderationDisease?: string | null,
      ponderationCount?: number | null,
      familleMaladie?: string | null,
      familleMaladieId?: number | null,
      MRLC?: boolean | null,
      maladieUrgente?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    shared?: boolean | null,
    sharedAt?: string | null,
    autoExam?: boolean | null,
    autoExamRes?:  {
      __typename: "AutoExamRes",
      reponse1?: string | null,
    } | null,
    teleconsultation?: boolean | null,
    priceConsultation?: number | null,
    diseaseIsVerified?: Array< string | null > | null,
    isDone?: boolean | null,
    factureId?: string | null,
    facture?:  {
      __typename: "Document",
      id: string,
      breedingId?: string | null,
      consultationId?: string | null,
      name: string,
      key?: string | null,
      s3file: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      nextToken?: string | null,
    } | null,
    sharedFacture?: boolean | null,
    sharedFactureAt?: string | null,
    teleconsAskAt?: string | null,
    dateTeleCons?: string | null,
    questionResponse?: string | null,
    note?: number | null,
    questionResponseOrder?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateDiseaseSubscriptionVariables = {
  filter?: ModelSubscriptionDiseaseFilterInput | null,
};

export type OnCreateDiseaseSubscription = {
  onCreateDisease?:  {
    __typename: "disease",
    id: string,
    codeIdentification?: string | null,
    name: string,
    forme?: string | null,
    description: string,
    synonymes?: string | null,
    enjeux?: string | null,
    causes?: string | null,
    circonstancesApparition?: string | null,
    diagnosticDiff?: string | null,
    diagnosticLab?: string | null,
    traitementCuratif?: string | null,
    pronostic?: string | null,
    prevention?: string | null,
    url?: string | null,
    consultations?:  {
      __typename: "ModelConsultationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    image?: Array< string | null > | null,
    ponderationDisease?: string | null,
    ponderationCount?: number | null,
    familleMaladie?: string | null,
    familleMaladieId?: number | null,
    MRLC?: boolean | null,
    maladieUrgente?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateDiseaseSubscriptionVariables = {
  filter?: ModelSubscriptionDiseaseFilterInput | null,
};

export type OnUpdateDiseaseSubscription = {
  onUpdateDisease?:  {
    __typename: "disease",
    id: string,
    codeIdentification?: string | null,
    name: string,
    forme?: string | null,
    description: string,
    synonymes?: string | null,
    enjeux?: string | null,
    causes?: string | null,
    circonstancesApparition?: string | null,
    diagnosticDiff?: string | null,
    diagnosticLab?: string | null,
    traitementCuratif?: string | null,
    pronostic?: string | null,
    prevention?: string | null,
    url?: string | null,
    consultations?:  {
      __typename: "ModelConsultationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    image?: Array< string | null > | null,
    ponderationDisease?: string | null,
    ponderationCount?: number | null,
    familleMaladie?: string | null,
    familleMaladieId?: number | null,
    MRLC?: boolean | null,
    maladieUrgente?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteDiseaseSubscriptionVariables = {
  filter?: ModelSubscriptionDiseaseFilterInput | null,
};

export type OnDeleteDiseaseSubscription = {
  onDeleteDisease?:  {
    __typename: "disease",
    id: string,
    codeIdentification?: string | null,
    name: string,
    forme?: string | null,
    description: string,
    synonymes?: string | null,
    enjeux?: string | null,
    causes?: string | null,
    circonstancesApparition?: string | null,
    diagnosticDiff?: string | null,
    diagnosticLab?: string | null,
    traitementCuratif?: string | null,
    pronostic?: string | null,
    prevention?: string | null,
    url?: string | null,
    consultations?:  {
      __typename: "ModelConsultationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    image?: Array< string | null > | null,
    ponderationDisease?: string | null,
    ponderationCount?: number | null,
    familleMaladie?: string | null,
    familleMaladieId?: number | null,
    MRLC?: boolean | null,
    maladieUrgente?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateProtocolSoinSubscriptionVariables = {
  filter?: ModelSubscriptionProtocolSoinFilterInput | null,
};

export type OnCreateProtocolSoinSubscription = {
  onCreateProtocolSoin?:  {
    __typename: "ProtocolSoin",
    id: string,
    idDiseaseFamily?: string | null,
    name?: string | null,
    Protocol?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateProtocolSoinSubscriptionVariables = {
  filter?: ModelSubscriptionProtocolSoinFilterInput | null,
};

export type OnUpdateProtocolSoinSubscription = {
  onUpdateProtocolSoin?:  {
    __typename: "ProtocolSoin",
    id: string,
    idDiseaseFamily?: string | null,
    name?: string | null,
    Protocol?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteProtocolSoinSubscriptionVariables = {
  filter?: ModelSubscriptionProtocolSoinFilterInput | null,
};

export type OnDeleteProtocolSoinSubscription = {
  onDeleteProtocolSoin?:  {
    __typename: "ProtocolSoin",
    id: string,
    idDiseaseFamily?: string | null,
    name?: string | null,
    Protocol?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateProtocolSoinSaveSubscriptionVariables = {
  filter?: ModelSubscriptionProtocolSoinSaveFilterInput | null,
};

export type OnCreateProtocolSoinSaveSubscription = {
  onCreateProtocolSoinSave?:  {
    __typename: "ProtocolSoinSave",
    id: string,
    breedingId?: string | null,
    bseId?: string | null,
    idDiseaseFamily?: string | null,
    new?: boolean | null,
    name?: string | null,
    Protocol?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateProtocolSoinSaveSubscriptionVariables = {
  filter?: ModelSubscriptionProtocolSoinSaveFilterInput | null,
};

export type OnUpdateProtocolSoinSaveSubscription = {
  onUpdateProtocolSoinSave?:  {
    __typename: "ProtocolSoinSave",
    id: string,
    breedingId?: string | null,
    bseId?: string | null,
    idDiseaseFamily?: string | null,
    new?: boolean | null,
    name?: string | null,
    Protocol?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteProtocolSoinSaveSubscriptionVariables = {
  filter?: ModelSubscriptionProtocolSoinSaveFilterInput | null,
};

export type OnDeleteProtocolSoinSaveSubscription = {
  onDeleteProtocolSoinSave?:  {
    __typename: "ProtocolSoinSave",
    id: string,
    breedingId?: string | null,
    bseId?: string | null,
    idDiseaseFamily?: string | null,
    new?: boolean | null,
    name?: string | null,
    Protocol?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationFilterInput | null,
  userId?: string | null,
};

export type OnCreateNotificationSubscription = {
  onCreateNotification?:  {
    __typename: "Notification",
    id: string,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      lastname?: string | null,
      firstname?: string | null,
      avatarUri?: string | null,
      userGroup?: string | null,
      optIn?: boolean | null,
      inscriptionNumberOrdreVeto?: string | null,
      breedingId?: string | null,
      vetOfficeId?: string | null,
      email?: string | null,
      expoToken?: Array< string > | null,
      notificationLastSeenAt?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    type: string,
    title: string,
    body: string,
    data?: string | null,
    clicked?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationFilterInput | null,
  userId?: string | null,
};

export type OnUpdateNotificationSubscription = {
  onUpdateNotification?:  {
    __typename: "Notification",
    id: string,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      lastname?: string | null,
      firstname?: string | null,
      avatarUri?: string | null,
      userGroup?: string | null,
      optIn?: boolean | null,
      inscriptionNumberOrdreVeto?: string | null,
      breedingId?: string | null,
      vetOfficeId?: string | null,
      email?: string | null,
      expoToken?: Array< string > | null,
      notificationLastSeenAt?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    type: string,
    title: string,
    body: string,
    data?: string | null,
    clicked?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationFilterInput | null,
  userId?: string | null,
};

export type OnDeleteNotificationSubscription = {
  onDeleteNotification?:  {
    __typename: "Notification",
    id: string,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      lastname?: string | null,
      firstname?: string | null,
      avatarUri?: string | null,
      userGroup?: string | null,
      optIn?: boolean | null,
      inscriptionNumberOrdreVeto?: string | null,
      breedingId?: string | null,
      vetOfficeId?: string | null,
      email?: string | null,
      expoToken?: Array< string > | null,
      notificationLastSeenAt?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    type: string,
    title: string,
    body: string,
    data?: string | null,
    clicked?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateNotificationTicketsSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationTicketsFilterInput | null,
};

export type OnCreateNotificationTicketsSubscription = {
  onCreateNotificationTickets?:  {
    __typename: "NotificationTickets",
    id: string,
    expoTokens?:  Array< {
      __typename: "UserToken",
      userId: string,
      token: string,
    } > | null,
    ticketIds?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateNotificationTicketsSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationTicketsFilterInput | null,
};

export type OnUpdateNotificationTicketsSubscription = {
  onUpdateNotificationTickets?:  {
    __typename: "NotificationTickets",
    id: string,
    expoTokens?:  Array< {
      __typename: "UserToken",
      userId: string,
      token: string,
    } > | null,
    ticketIds?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteNotificationTicketsSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationTicketsFilterInput | null,
};

export type OnDeleteNotificationTicketsSubscription = {
  onDeleteNotificationTickets?:  {
    __typename: "NotificationTickets",
    id: string,
    expoTokens?:  Array< {
      __typename: "UserToken",
      userId: string,
      token: string,
    } > | null,
    ticketIds?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};
