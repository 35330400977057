import { Text, useTheme } from '@ui-kitten/components';
import { TouchableOpacity, View } from 'react-native';
import { Icon as IconUIKitten } from '@ui-kitten/components/ui/icon/icon.component';
import React, { useState } from 'react';
import { useLinkTo } from '@react-navigation/native';
import PaperPenIcon from '../assets/svg_icones_ScanFlock/BLACK/blackPaperPenIcon.svg';
import FCard from './Card';
import BseScreenBL from '../screenBusinessLogic/BseScreenBL/BseScreenBL';
import { useGetBse } from '../src/API/BSE';
import { StatusBse } from '../src/API';
import CustomRatingBar from './Modals/CustomRatingBar';
import CustomModal from './Modals/CustomModal';
import DateUtils from '../utils/DateUtils';

type BseCardProps = {
  BSEId : string,
  goTo?: () => void,
};

const BseCard = ({ BSEId, goTo } : BseCardProps) => {
  const theme = useTheme();
  const { bse } = useGetBse(BSEId);
  const linkTo = useLinkTo();
  let preReviewFinish = false;
  let bseStatus = false;
  const [showModal, setShowModal] = useState(false);
  if (bse && bse.questionResponse && (bse.StatusBse === StatusBse.preReviewFinished
      || bse.StatusBse === StatusBse.BSEAsk
      || bse.StatusBse === StatusBse.BSEAskStart
      || bse.StatusBse === StatusBse.BSEProtocolSoins
      || bse.StatusBse === StatusBse.BSEStarted)) {
    preReviewFinish = true;
  }
  if (bse && bse.questionResponse && bse.StatusBse === StatusBse.BSEFinish) {
    preReviewFinish = true;
    bseStatus = true;
  }
  const goBSEInfo = () => {
    if (goTo) {
      goTo();
    } else if (bse) {
      linkTo(`/bilan-sanitaire/demande-bse/${BSEId}`);
    } else {
      setShowModal(true);
    }
  };

  if (!bse) {
    return <></>;
  }

  let statutBSE : string = '';

  switch (bse?.StatusBse) {
    case StatusBse.BSEAsk:
      if (bse.dateBSE) {
        statutBSE = 'En attente réalisation';
      } else {
        statutBSE = 'En attente de confirmation du véto';
      }
      break;
    case StatusBse.BSEFinish:
      statutBSE = 'Réalisé';
      break;
    case StatusBse.BSEAskStart:
    case StatusBse.BSEProtocolSoins:
    case StatusBse.BSEStarted:
      statutBSE = 'En cours de réalisation';
      break;
    case StatusBse.preReviewFinished:
      statutBSE = 'En attente de demande au véto';
      break;
    case StatusBse.preReviewStarted:
  }
  //
  return (
    <FCard
      onPress={() => goBSEInfo()}
      style={{
        padding: 0, marginTop: 10, paddingHorizontal: -5, marginHorizontal: 5,
      }}
    >
      <View style={{
        flexDirection: 'row', alignItems: 'center', paddingHorizontal: 15, paddingTop: 15,
      }}
      >
        <PaperPenIcon height={24} width={24} fill="black" />
        <View style={{ flex: 1, marginLeft: 10 }}>
          <Text category="h4">Bilan Sanitaire d'Elevage</Text>
          <Text category="h6" appearance="hint">{DateUtils.convertDate(bse.dateBSE)}</Text>
        </View>
        <IconUIKitten
          name="arrow-ios-forward-outline"
          fill="#000000"
          style={{
            height: 24, width: 24,
          }}
        />
      </View>

      {/** Temporary TouchableOpacity instead of View; Put back View after using */}
      <View
        style={{
          flex: 1,
          borderTopWidth: 0.5,
          borderTopColor: '#b5b5b5',
          // paddingTop: 10,
          marginTop: 10,
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >

        <>
          {!bseStatus ? (
            <View style={{
              flex: 1, marginVertical: 10, flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
            }}
            >
              <IconUIKitten
                name="alert-circle"
                fill="orange"
                style={{ height: 20, width: 20, marginRight: 5 }}
              />
              <Text category="h6" status="primary">{statutBSE}</Text>
            </View>

          )
            : (
              <View style={{
                flex: 1, marginVertical: 10, flexDirection: 'row', justifyContent: 'center',
              }}
              >
                <IconUIKitten
                  name="checkmark-circle-2"
                  fill={theme['realised-color']}
                  style={{ height: 20, width: 20, marginRight: 5 }}
                />
                <Text category="h6" status="primary">Réalisé</Text>
              </View>
            )}
        </>
        {!preReviewFinish
              && (
              <View style={{
                flex: 1, marginVertical: 10, flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
              }}
              >
                <IconUIKitten
                  name="alert-circle"
                  fill="orange"
                  style={{ height: 20, width: 20, marginRight: 5 }}
                />
                <Text category="h6" status="primary">Pré - bilan non complété</Text>
              </View>
              )}

      </View>
      <CustomModal
        title={'Vous devez avoir réalisé votre pré-bilan avant d\'accéder à la section BSE\n'}
        before={<></>}
        noSafeArea
        visible={showModal}
        scrollable={false}
        onClose={() => { setShowModal(false); }}
        insideModalStyle={{ padding: 20 }}
        buttonText="Ok"
        buttonPress={() => { setShowModal(false); }}
      />
    </FCard>
  );
};

export default BseCard;
