import { Dispatch, SetStateAction } from 'react';
import { LinkProps } from '../../types';
import { Breeding, LockedStatus } from '../../src/API';
import { useListAnimalsByBreedinG } from '../../src/API/Animals';

const allerDetailScreen = ({ state, linkTo, id }: LinkProps) => {
  switch (state) {
    case 'animaux':
      linkTo(`/elevage/detail-animal/${id}`);
      break;
    case 'lots':
      linkTo(`/elevage/detail-lot/${id}`);
      break;
    case 'details-elevage':
      linkTo(`/veto-elevage/veto-details-elevage/${id}`);
      break;
    case 'batiments':
      linkTo(`/elevage/detail-batiment/${id}`);
      break;
    default:
      break;
  }
};

const allerVetoDetailScreen = ({ state, linkTo, id }: LinkProps) => {
  switch (state) {
    case 'animaux':
      linkTo(`/veto-elevage/veto-detail-animal/${id}`);
      break;
    case 'lots':
      linkTo(`/veto-elevage/veto-detail-lot/${id}`);
      break;

    case 'batiments':
      linkTo(`/veto-elevage/veto-detail-batiment/${id}`);
      break;
    default:
      break;
  }
};

const goVoirPlusScreenSwitch = ({ state, linkTo }: LinkProps) => {
  switch (state) {
    case 'animaux':
      linkTo('/elevage/voir-plus-animal');
      break;
    case 'lots':
      linkTo('/elevage/voir-plus-lot');
      break;
    case 'batiments':
      linkTo('/elevage/voir-plus-batiment');
      break;
    default:
      break;
  }
};

const goVetoVoirPlusScreenSwitch = ({ state, linkTo }: LinkProps) => {
  switch (state) {
    case 'animaux':
      linkTo('/veto-elevage/veto-voir-plus-animal');
      break;
    case 'lots':
      linkTo('/veto-elevage/veto-voir-plus-lot');
      break;
    case 'batiments':
      linkTo('/veto-elevage/veto-voir-plus-batiment');
      break;
    default:
      break;
  }
};

const goAjoutScreen = ({ state, linkTo }: LinkProps) => {
  switch (state) {
    case 'animaux':
      linkTo('/elevage/ajout-animal');
      break;
    case 'lots':
      linkTo('/elevage/ajout-lot');
      break;
    case 'batiments':
      linkTo('/elevage/ajout-batiment');
      break;
    default:
      break;
  }
};

const getAddButtonTitle = (state: string) => {
  switch (state) {
    case 'animaux':
      return 'Ajouter un nouvel animal';
    case 'lots':
      return 'Ajouter un nouveau lot';
    case 'batiments':
      return 'Ajouter un nouveau bâtiment';
    default:
      break;
  }
  return '';
};

const placeholderTitle = (state: string) => {
  switch (state) {
    case 'animaux':
      return 'Rechercher un animal';
    case 'lots':
      return 'Rechercher un lot';
    case 'batiments':
      return 'Rechercher un bâtiment';
    case 'consultations':
      return "Nom ou n° d'identification";
    default:
      break;
  }
  return '';
};

const getTypeProductionAsString = (breeding?: Breeding | null) => {
  const typeProduction = [];
  // console.log('getTypeProductionAsString', breeding);
  if (breeding) {
    if (breeding?.dairyFarm) {
      typeProduction.push('Laitier');
    }
    if (breeding?.fatteningFarm) {
      typeProduction.push('Engraisseur');
    }
    if (breeding?.sucklerCowHusbandry) {
      typeProduction.push('Allaitant');
    }

    return typeProduction.join(', ');
  }
  return '';
};

type ChangeElevageListDataProps = {
  breeding: Breeding | undefined | null,

  setElevageList: Dispatch<SetStateAction<undefined>>,
  state: string,
};

const useListAnimalByBreedingBL = (id: string) => {
  const {
    animalS, fetchMoreAnimalS, nextToken, // refetch: referchAnimalS,
  } = useListAnimalsByBreedinG(id, 1000, 'cache-and-network');
  return {
    animalS, fetchMoreAnimalS, nextToken,
  };
};

const changeElevageListData = ({
  breeding, setElevageList, state,
}: ChangeElevageListDataProps) => {
  // console.log(state);
  // ];
  // setElevageList(selectElevage);
  if (state === 'animaux' || state === 'animal') {
    const animals = breeding?.animals?.items?.map((animal) => ({
      id: animal?.id,
      name: animal?.name,
      numberID: animal?.numberID,
      // eslint-disable-next-line no-underscore-dangle
      _deleted: animal?._deleted,
      lots: {
        id: animal?.lots?.id,
        numberLot: animal?.lots?.numberLot,
      },
      // eslint-disable-next-line no-underscore-dangle
    })).filter((item) => !item._deleted);
    setElevageList(animals);
  }
  if (state === 'batiments') {
    const buildings = breeding?.buildings?.items?.map(
      (building) => (
        {
          id: building?.id,
          nameBuilding: building?.nameBuilding,
          // eslint-disable-next-line no-underscore-dangle
          _deleted: building?._deleted,
        }
      ),
      // eslint-disable-next-line no-underscore-dangle
    ).filter((item) => !item._deleted);

    setElevageList(buildings);
  }
  if (state === 'lots' || state === 'lot') {
    const lots = breeding?.lots?.items?.map(
      (lot) => ({
        id: lot?.id,
        numberLot: lot?.numberLot,
        name: lot?.name,
        // eslint-disable-next-line no-underscore-dangle
        _deleted: lot?._deleted,
      }),
      // eslint-disable-next-line no-underscore-dangle
    ).filter((item) => !item._deleted);
    // console.log('passage');
    setElevageList(lots);
  }
};

export default {
  allerDetailScreen,
  allerVetoDetailScreen,

  getAddButtonTitle,
  goAjoutScreen,
  placeholderTitle,
  getTypeProductionAsString,

  useListAnimalByBreedingBL,
  changeElevageListData,

  goVoirPlusScreenSwitch,
  goVetoVoirPlusScreenSwitch,
};
