/**
 *
 *
 * @author: David-Julian Buch
 */

import * as React from 'react';
import {
  ImageStyle,
} from 'react-native';

import { RequireSome } from '../utils/typeHelpers';
import S3Image from './S3Image';
import Image from './Image';

import ManAvatar from '../assets/Avatars/manAvatar.svg';
import WomanAvatar from '../assets/Avatars/womanAvatar.svg';
import WaitUser from '../assets/Avatars/waitUser.svg';

import ActivityIndicator from './ActivityIndicator';

export type AutoAvatarProps = {
  avatarInfo?: string
  style: RequireSome<ImageStyle, 'height' | 'width'>;
};

export default function AutoAvatar(props: AutoAvatarProps): JSX.Element {
  const {
    avatarInfo,
    style,
  } = props;
  if (avatarInfo === undefined) {
    return <ActivityIndicator center margin={10} />;
  }
  if (avatarInfo.indexOf('default::') > -1) {
    let SelectedAvatar = ManAvatar;
    switch (avatarInfo) {
      case 'default::ManAvatar':
        SelectedAvatar = ManAvatar;
        break;
      case 'default::WomanAvatar':
        SelectedAvatar = WomanAvatar;
        break;
      case 'default::WaitUser':
        SelectedAvatar = WaitUser;
        break;
      default:
        break;
    }
    return (
      <>
        <SelectedAvatar height={style.height} width={style.height} />
      </>
    );
  }

  if (avatarInfo.indexOf('://') > -1 || avatarInfo.indexOf('data:image') > -1) {
    return (
      <Image style={style} uri={avatarInfo} />
    );
  }

  return (
    <S3Image style={style} s3key={avatarInfo} />
  );
}
