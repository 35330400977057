import React, { useState } from 'react';
import { Platform, View } from 'react-native';
import { Icon as IconUIKitten } from '@ui-kitten/components/ui/icon/icon.component';
import { Text, useTheme } from '@ui-kitten/components';
import { useLinkTo } from '@react-navigation/native';
import { useDimensions } from '@react-native-community/hooks';
import FCard from './Card';
import RejoindreButton from './RejoindreButton';

import ScreenIcon from '../assets/svg_icones_ScanFlock/BLACK/blackScreenIcon.svg';
import ArrowIcon from '../assets/svg_icones_ScanFlock/BLACK/blackArrowRightIcon.svg';
import RobotIcon from '../assets/svg_icones_ScanFlock/BLACK/blackRobotIcon.svg';

import AnimalCard from '../screenComponents/AnimalCard';
import ConsultationScreenBL from '../screenBusinessLogic/ConsultationScreen/ConsultationScreenBL';
import DateUtils from '../utils/DateUtils';
import { useUser } from '../src/API/UserContext';
import { useBreedingList, useGetBreeding } from '../src/API/Breeding';
import {
  Breeding, BSE, GenderType, LockedStatus, StatusBse,
} from '../src/API';

import VetoDetailsConsultationScreenBL
  from '../screenBusinessLogic/vetoScreensBL/VetoConsultationScreenBL/VetoDetailsConsultationScreenBL';
import VetoBseScreenBL from '../screenBusinessLogic/vetoScreensBL/VetoBseScreenBL/VetoBseScreenBL';
import CustomModal from './Modals/CustomModal';

const TeleIcon = () => (
  <ScreenIcon height={20} width={20} style={{ marginRight: 5 }} />
);

const ShareIcon = () => (
  <ArrowIcon height={20} width={20} style={{ marginRight: 5 }} />
);

const AutoExamIcon = () => (
  <RobotIcon height={20} width={20} style={{ marginRight: 5 }} />
);

export type CardButtonProps = {
  veto?: boolean;
  consultation?: string;
  back?: string;
  sharedExam?: string;
  detailConsultationPage?: boolean;
  consultationData?: {
    __typename: 'Consultation',
    id: string,
    breedingId: string,
    animalId?: string | null,
    animal?: {
      __typename: 'Animals',
      id: string,
      numberID: string,
      name?: string | null,
      gender?: GenderType | null,
      birthDate?: string | null,
      breedingId: string,
      lotsId?: string | null,
      zoneId?: string | null,
      buildingId?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    isDone?: boolean,
    lot?: {
      __typename: 'Lots',
      id: string,
      numberLot: string,
      name?: string | null,
      buildingId?: string | null,
      breedingId: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,

    lotId?: string | null,
    shared?: boolean | null,
    autoExam?: boolean | null,
    teleconsultation?: boolean | null,
    dateTeleCons?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,

  } | null,
  bseData?: BSE | null,
};

const TeleconsultationCard = (props: CardButtonProps) => {
  const {
    veto, consultationData, detailConsultationPage = false, bseData, back,
  } = props;
  let { consultation = 'consultationCreated' } = props;
  const linkTo = useLinkTo();
  const theme = useTheme();
  const { window } = useDimensions();
  const { user } = useUser();
  let breeding: Breeding;
  let breedingLocked : LockedStatus | undefined;
  if (user && user.userGroup === 'veto' && (consultationData || bseData)) {
    const { oneBreeding } = useGetBreeding(consultationData
      ? consultationData.breedingId
      : bseData.breedingId);
    breeding = oneBreeding;
    breedingLocked = LockedStatus.unlocked;
  } else if (user.userGroup === 'eleveur') {
    const { breeding: thisBreeding } = useBreedingList(user.userGroup);
    breedingLocked = thisBreeding?.locked;
  }
  const [openModalUnsubscrib, setOpenModalUnsubscrib] = useState(false);

  let dataConsultation;
  let dataConsulLot;
  if (consultationData) {
    if (consultationData?.animalId) {
      // const { animal } = useGetAnimal(consultationData?.animalId);
      dataConsultation = consultationData.animal;
    }
    // console.log('dataConsultation TeleconsultationCard', dataConsultation);
    if (consultationData && consultationData.lotId) {
      // const { lot } = useGetLot(consultationData?.lotId);
      dataConsulLot = consultationData.lot;
      // console.log('dataConsulLot TeleconsultationCard', dataConsulLot);
    }

    if (!consultationData.autoExam && consultationData.teleconsultation && DateUtils.verificationDateTeleconsSimple('nonPasse', consultationData)) {
      consultation = 'RDV';
    }
  }
  // console.log('dataConsultation TeleconsultationCard', consultationData);

  const allerDetailConsultationScreen = () => {
    // user.group === 'éleveur'
    if (user && user.userGroup === 'veto') {
      if (consultationData && consultationData.teleconsultation && !consultationData.autoExam) {
        VetoDetailsConsultationScreenBL.linkToSwitch({
          state: 'details-teleconsultation', linkTo, id: consultationData?.id, back,
        });
      } else if (bseData) {
        if (bseData.StatusBse === StatusBse.BSEStarted) {
          linkTo(`/veto-bse/bse-questionnaire/${bseData.id}`);
        } else if (bseData.StatusBse === StatusBse.BSEProtocolSoins) {
          linkTo(`/veto-bse/veto-protocol-soins/${bseData.id}`);
        } else {
          VetoBseScreenBL.linkToSwitch({
            state: 'details-bse', linkTo, id: bseData.id, back,
          });
        }
      } else {
        VetoDetailsConsultationScreenBL.linkToSwitch({
          state: 'details-consultation', linkTo, id: consultationData?.id, back,
        });
      }
    } else if (consultation !== 'live') {
      ConsultationScreenBL.linkToSwitch({
        state: 'details-consultation', linkTo, id: consultationData?.id, back,
      });
    } else {
      ConsultationScreenBL.linkToSwitch({
        state: 'details-consultation', linkTo, id: consultationData?.id, back,
      });
    }
  };
  const renderDate = () => {
    if (consultation === 'consultationCreated') {
      return DateUtils.convertDate(consultationData?.createdAt);
    }
    if (consultationData?.dateTeleCons || bseData?.dateBSE) {
      // console.log('conultation', consultation);
      return DateUtils.convertDate(consultationData
        ? consultationData?.dateTeleCons : bseData?.dateBSE);
    }
    return 'En attente';
  };

  const renderTime = () => {
    // console.log('consultationData?.dateTeleCons', consultationData?.dateTeleCons);
    if (consultationData?.dateTeleCons || bseData?.dateBSE) {
      return DateUtils.convertTime(consultationData
        ? consultationData?.dateTeleCons : bseData?.dateBSE);
    }
    return 'En attente';
  };
  // consultationData?.dateTeleCons && DateUtils.convertTime(consultationData?.dateTeleCons)}

  const renderCardStatus = ({
    statusLeftIcon, statusTitle, isOver, statusRightIcon,
  }) => (
    <View style={{
    // flex: 1,
      marginBottom: 10,
      flexDirection: 'row',
      justifyContent: 'center',
      alignSelf: 'center',
      borderColor: isOver ? theme['color-primary-300'] : theme['color-danger-400'],
      borderWidth: 1,
      borderRadius: 10,
      backgroundColor: isOver ? theme['color-primary-100'] : theme['color-danger-200'],
      width: 180,
      paddingVertical: 5,
      marginRight: Platform.OS === 'web' ? 10 : 0,
      paddingLeft: consultationData?.autoExam && consultationData?.teleconsultation ? 5 : 0,
    }}
    >
      {statusLeftIcon}
      <Text category="s2" style={{ textAlign: 'center' }}>{statusTitle}</Text>
      {isOver ? (
        <IconUIKitten
          name="checkmark-circle-2"
          fill="#349B2E"
          style={{
            height: 20, width: 20,
          }}
        />
      ) : (
        <IconUIKitten
          name={statusRightIcon}
          fill="#ee4646"
          style={{
            height: 20, width: 20,
          }}
        />
      )}

    </View>
  );

  return (
    <FCard
      onPress={() => {
        if (breedingLocked === LockedStatus.unlocked) {
          allerDetailConsultationScreen();
        } else {
          setOpenModalUnsubscrib(true);
        }
      }}
      style={[{
        marginVertical: 10,
        padding: 0,

      }, (!detailConsultationPage || false) && {
        paddingBottom: 10,
      }]}
    >
      <View style={[{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 5,
        paddingVertical: 10,
        backgroundColor: (consultation === 'RDV') ? '#182E6B' : '',
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,

      }, ((detailConsultationPage || false)) && {
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
      }]}
      >
        <View style={{ flexDirection: 'column' }}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <IconUIKitten
              name="calendar-outline"
              fill={consultation === 'RDV' ? '#fff' : '#000000'}
              style={{
                height: 20, width: 20, marginRight: 5,
              }}
            />
            <Text
              category="h4"
              style={{ color: (consultation === 'RDV') ? 'white' : 'black' }}
            >
              {renderDate()}
            </Text>
          </View>
          {(consultationData?.teleconsultation || bseData) && consultation === 'RDV' && (consultationData?.dateTeleCons || bseData?.dateBSE) ? (
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <IconUIKitten
                name="clock-outline"
                fill={consultation === 'RDV' ? '#fff' : '#000000'}
                style={{
                  height: 20, width: 20, marginRight: 5,
                }}
              />
              <Text
                category="h4"
                style={{ color: (consultation === 'RDV') ? 'white' : 'black' }}
              >
                {renderTime()}
              </Text>
            </View>
          ) : null}

        </View>
        {(detailConsultationPage || false)
          ? (<></>)
          : (
            <>
              {consultationData && !DateUtils.verificationDateTeleconsSimple('enCours', consultationData) && (
              <View style={{ flexDirection: 'row' }}>
                <Text category="h4" style={{ color: (consultation === 'RDV') ? 'white' : theme['color-primary-700'] }}>
                  DÉTAILS
                </Text>
                <IconUIKitten
                  name="arrow-ios-forward-outline"
                  fill={consultation === 'RDV' ? '#fff' : theme['color-primary-800']}
                  style={{
                    height: 20, width: 20, marginRight: 5,
                  }}
                />
              </View>
              )}
              {consultationData
                  && DateUtils.verificationDateTeleconsSimple('enCours', consultationData)
              && (
              <RejoindreButton consultation={consultationData} veto={veto} style={{ marginRight: 10 }} />
              )}
            </>
          )}

      </View>

      {!detailConsultationPage && (
      <>
        <View style={[
          {
            flexDirection: 'row',
          },
          dataConsultation && {
            paddingTop: 5,
            borderTopColor: '#b5b5b5',
            borderTopWidth: 1,
          },
          (consultation === 'consultationCreated') && {
            paddingTop: 5,
            borderTopColor: '#b5b5b5',
            borderTopWidth: 1,
          },
          consultationData && {
            paddingTop: 5,
            borderTopColor: '#b5b5b5',
            borderTopWidth: 1,
          },
        ]}
        >

          {breeding && (
          <View style={{
            flex: 1, flexDirection: 'column', alignItems: 'center', borderRightColor: '#b5b5b5', borderRightWidth: 1,
          }}
          >
            <Text category="c1" appearance="hint">Elevage</Text>
            <Text category="h4">{breeding?.companyName || ''}</Text>
            <Text category="h6">{breeding?.nBreeding || ''}</Text>
          </View>
          )}
          {dataConsultation && (
          <AnimalCard name={dataConsultation?.name} numberId={dataConsultation?.numberID} numberLot={dataConsultation?.numberLot} teleCard="telecard" style={{ marginLeft: breeding ? 20 : 0 }} />
          )}

          {(dataConsultation?.lots || dataConsulLot) && (
          <View style={[{
            flex: 1, flexDirection: 'column', alignItems: 'center',
          }, dataConsultation && { borderLeftColor: '#b5b5b5', borderLeftWidth: 1 }]}
          >
            <Text category="c1" appearance="hint">Lot</Text>
            <Text category="h4">{dataConsultation?.lots?.name || dataConsulLot?.name || ''}</Text>
            <Text category="h6">{dataConsultation?.lots?.numberLot || dataConsulLot?.numberLot || ''}</Text>
          </View>
          )}

        </View>

        <View style={[{
          flex: 1,
          flexDirection: (Platform.OS !== 'web' || window.width < 640) ? 'column' : 'row',
          justifyContent: 'center',
        }, consultationData && {
          paddingBottom: 10,
          marginTop: 10,
          paddingTop: 15,
          borderTopColor: '#b5b5b5',
          borderTopWidth: 1,
        }]}
        >
          {consultationData?.teleconsultation && (renderCardStatus({
            statusLeftIcon: <TeleIcon />, statusTitle: 'Téléconsultation', isOver: consultationData.dateTeleCons, statusRightIcon: 'calendar',
          }))}
          {consultationData?.autoExam && (renderCardStatus({
            statusLeftIcon: <AutoExamIcon />, statusTitle: 'Auto-examen', isOver: consultationData.isDone, statusRightIcon: 'close-circle',
          }))}
          {consultationData?.shared && (renderCardStatus({
            statusLeftIcon: <ShareIcon />, statusTitle: 'Partagé', isOver: consultationData.shared, statusRightIcon: 'close-circle',
          }))}
        </View>
      </>
      )}
      <CustomModal
        title="Votre abonnement est terminé. Abonnez vous pour accéder à cette fonctionalité"
        before={<></>}
        noSafeArea
        visible={openModalUnsubscrib}
        scrollable={false}
        onClose={() => setOpenModalUnsubscrib(false)}
        insideModalStyle={{ textAlign: 'center', padding: 20 }}
        buttonText="Continuer"
        heightPercentage={0.46}
        buttonPress={() => { setOpenModalUnsubscrib(false); }}
      />

    </FCard>

  );
};

export default TeleconsultationCard;
