import { useLinkTo, useRoute } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { Text, useTheme } from '@ui-kitten/components';
import { RouteProp } from '@react-navigation/core/lib/typescript/src/types';
import { ListRenderItem } from 'react-native';
import InfoCard from '../../../screenComponents/InfoCard';
import MaxWidthContainer from '../../../components/MaxWidthContainer';
import ButtonGroup from '../../../components/ButtonGroup';
import SearchComponent from '../../../screenComponents/SearchComponent';
import ElevageScreenBL from '../../../screenBusinessLogic/ElevageScreen/ElevageScreenBL';
import AnimalCard from '../../../screenComponents/AnimalCard';
import GoCustomCard from '../../../components/GoCustomCard';
import { VetoTabElevageParamList } from '../../../types';
import { useGetBreeding } from '../../../src/API/Breeding';
import { useUser } from '../../../src/API/UserContext';

const VetoEffectifElevage = () => {
  const linkTo = useLinkTo();
  const theme = useTheme();
  const user = useUser();

  /** GET BREEDING BY ID */
  const route = useRoute<RouteProp<VetoTabElevageParamList, 'veto-effectif-elevage'>>();

  const { oneBreeding, refetch } = useGetBreeding(route.params.id);
  // console.log('iiiiiiid: ', route.params.id);
  // console.log('oneBreeding: ', oneBreeding);

  /** Switching between lots, animals and buildings sections   */
  const [state, setState] = useState('animaux');
  const [elevageList, setElevageList] = useState<
  Array<[
    [{
      id: string | undefined,
      name: string | undefined,
      numberID: string | undefined,
      _deleted: boolean,
      lots: {
        id: string,
        numberLot: string | undefined,
      } | undefined,
    }] | undefined,

  ]>

  | Array<[
    [{
      id: string,
      nameBuilding: string | undefined,
      _deleted: boolean
    }] | undefined,
  ]>

  | Array<[
    [{
      id: string | undefined,
      numberLot: string | undefined,
      name?: string | undefined | null,
      _deleted: boolean
    }] | undefined,
  ]>
  | undefined>();

  useEffect(() => {
    ElevageScreenBL.changeElevageListData({
      breeding: oneBreeding, setElevageList, state,
    });
  }, [state, oneBreeding]);

  const goToVetoDetailsScreen = (idItem: string) => {
    // console.log('user outside', user.user.userGroup);
    // console.log('idItem', idItem);

    if (user && user.user && user.user.userGroup === 'veto') {
      // console.log('user', user);
      ElevageScreenBL.allerVetoDetailScreen(
        { state, linkTo, id: idItem },
      );
    } else if (user && user.user && user.user.userGroup === 'eleveur') {
      ElevageScreenBL.allerDetailScreen(
        { state, linkTo, id: idItem },
      );
    }
  };

  const renderSearchComponentItem = (item) => {
    // console.log('item search component:', item);
    switch (state) {
      case 'animaux':
        // eslint-disable-next-line no-underscore-dangle
        if (item.item) {
          return (
            <AnimalCard
              name={item.item.name}
              numberId={`${item?.item?.numberID}`}
              numberLot={item && item.item && item.item.lots
                ? (item.item.lots.numberLot)
                : ('')}
              key={item.item.id}
              onPress={() => {
                goToVetoDetailsScreen(item.item.id);
              }}
            />
          );
        }
        break;
      case 'lots':
        // console.log('item lots:', item.item);
        // eslint-disable-next-line no-underscore-dangle
        if (item.item) {
          return (
            <GoCustomCard
              title1={item.item.name}
              title2={`Numéro de lot: ${item?.item?.numberLot}`}
              key={item.item.id}
              linkToCustom={() => {
                goToVetoDetailsScreen(item.item.id);
              }}
              customStyle={{ marginHorizontal: 5 }}
              minusSquareOutline="arrow-ios-forward"
            />
          );
        }
        break;
      case 'batiments':
        // console.log('item buildings:', item.item);
        // eslint-disable-next-line no-underscore-dangle
        if (item.item) {
          return (
            <GoCustomCard
              key={item.item.id}
              title1={`${item.item.nameBuilding}`}
              linkToCustom={() => { goToVetoDetailsScreen(item.item.id); }}
              customStyle={{ marginHorizontal: 5 }}
            />
          );
        }
        break;

      default:
        break;
    }
    return '';
  };

  return (
    <MaxWidthContainer
      withScrollView="keyboardAware"
      outerViewProps={{
        style: {
          padding: 24,
        },
      }}
    >
      <InfoCard
        title="alertInfo"
        iconShow="alert-circle-outline"
            // iconCloseShow="close-outline"
        description={"Accédez aux animaux et lots d'animaux enregistrés par l'éleveur et consultez les auto-examens partagés avec votre cabinet."}
        alertInfo="alertInfo"
      />

      <ButtonGroup
        onChange={(selectedItem) => setState(selectedItem.key)}
        items={[
          { key: 'animaux', name: 'Animaux' },
          { key: 'lots', name: 'Lots' },
          { key: 'batiments', name: 'Bâtiments' },
        ]}
        initialSelectedItem="animaux"
      />

      <Text category="h2" appearance="hint" style={{ marginTop: 20, marginBottom: 10 }}>{`Liste des ${state !== 'batiments' ? state : 'bâtiments'}`}</Text>
      {elevageList && elevageList.length > 0 ? (
        <>
          <SearchComponent
            state={state}
            data={elevageList}
            opened
            keyExtractor={(item) => item.id}
            placeholder={ElevageScreenBL.placeholderTitle(state)}
            renderItem={(item) => renderSearchComponentItem(item)}
          />
        </>
      ) : (
        <InfoCard
          title="alertInfo"
          iconShow="alert-circle-outline"
                // iconCloseShow="close-outline"
          infoStyle={{ marginTop: 10, backgroundColor: theme['color-primary-100'] }}
          description={`Il n'y a aucun ${state === 'animaux' ? 'animal' : state === 'batiments' ? 'bâtiment' : `${state.slice(0, -1)}`} dans cet élevage.`}
          alertInfo="alertInfo"
        />
      )}
    </MaxWidthContainer>
  );
};

export default VetoEffectifElevage;
