import React, { useState } from 'react';

import { useLinkTo, useRoute } from '@react-navigation/native';
import { Text, useTheme } from '@ui-kitten/components';
import { RouteProp } from '@react-navigation/core/lib/typescript/src/types';
import { Platform } from 'react-native';
import MaxWidthContainer from '../../components/MaxWidthContainer';

import TeleconsultationCard from '../../components/TeleconsultationCard';
import CompButton from '../../components/Button';
import DetailsScreensHeaderCard from '../../screenComponents/DetailsScreensHeaderCard';
import { TabElevageParamList } from '../../types';
import { useDeleteAnimalMutation, useGetAnimal } from '../../src/API/Animals';
import DateUtils from '../../utils/DateUtils';
import {
  useUpdateConsultationMutation,
} from '../../src/API/Consultation';
import CustomModal from '../../components/Modals/CustomModal';
import { typeGenderCustom } from '../../src/selectFormData/ajoutAnimalData';

const DetailAnimalScreen = () => {
  const linkTo = useLinkTo();
  const theme = useTheme();

  const route = useRoute<RouteProp<TabElevageParamList, 'detail-animal'>>();
  // console.log('DetailAnimalScreen route: ', route);
  const { animal, refetch } = useGetAnimal(route.params.id);

  // for modal
  const [openDelete, setOpenDelete] = useState<{ title : string }>();
  const { modifyConsultation } = useUpdateConsultationMutation();
  const supprimerAnimal = useDeleteAnimalMutation();

  const deleteAnimal = async () => {
    if (animal) {
      /** DELETE CONSULTATIONS FROM ANIMALS (animalsId in consultations) */
      if (animal.consultations?.items && animal.consultations?.items.length > 0) {
        animal.consultations?.items?.reduce(async (promise, consultation) => {
          await promise;
          if (consultation) {
            await modifyConsultation({
              variables: {
                input: {
                  id: consultation.id,
                  // eslint-disable-next-line no-underscore-dangle
                  _version: consultation._version,
                  animalId: null,
                },
              },
            });
          }
        }, Promise.resolve());
      }

      /** DELETE ANIMALS after unaffecting from everything */
      await supprimerAnimal({
        variables: {
          input: {
            id: route.params.id,
            // eslint-disable-next-line no-underscore-dangle
            _version: animal._version,
          },
        },
      });
    }
    if (refetch) {
      await refetch();
    }
    linkTo('/elevage');
    setOpenDelete(undefined);
  };

  return (
    <>
      <MaxWidthContainer
        outerViewProps={{
          style: {
            padding: 24,
          },
        }}
      >
        {animal
        && (
        <>
          <DetailsScreensHeaderCard
            title1={"N° d'identification"}
            titleInfo1={animal?.numberID}
            title2="Nom"
            titleInfo2={animal?.name}
            title3="Sexe"
            titleInfo3={animal?.gender ? typeGenderCustom[animal?.gender].label : null}
            title4="Age"
            titleInfo4={DateUtils.getAge(animal?.birthDate)}
            title5="Numéro de lot"
            titleInfo5={animal?.lots?.numberLot ? animal?.lots?.numberLot : "L'animal n'est affecté à aucun lot"}
            title6="Bâtiment"
            titleInfo6={animal?.building?.nameBuilding ? animal?.building?.nameBuilding : "L'animal n'est affecté à aucun bâtiment"}
            title7="Zone"
            titleInfo7={animal?.zone?.name ? animal?.zone?.name : "L'animal n'est affecté à aucune zone"}
            animals={animal}
          />
          <CompButton
            leftIcon="edit-outline"
            leftIconFill={theme['color-primary-500']}
            appearance="outline"
            onPress={() => { linkTo(`/elevage/detail-animal/${animal?.id}/modify-animal`); }}
          >
            Modifier les informations de l'animal
          </CompButton>
        </>
        )}

        <Text
          category="p1"
          appearance="hint"
          style={{ marginTop: 20 }}
        >
          Dernières consultations créées
        </Text>
        {
          animal?.consultations?.items && animal?.consultations?.items?.length > 0
            ? (animal?.consultations?.items.map(
              (item) => <TeleconsultationCard key={item?.id} consultationData={item} back={`elevage/detail-animal/${animal?.id}`} />,
            ))
            : <Text category="h4">Vous n'avez pas de consultations</Text>
        }

        <CompButton onPress={() => { setOpenDelete('delete animal'); }} style={{ marginTop: 40, backgroundColor: '#fff' }} status="danger" appearance="outline">Supprimer l'animal</CompButton>

      </MaxWidthContainer>
      <CustomModal
        title={"Vous êtes sur le point de supprimer l'animal."
          + '\n'
          + 'Êtes-vous sur de vouloir continuer ?'}
        before={<></>}
        noSafeArea
        visible={openDelete !== undefined}
        scrollable={false}
        onClose={() => setOpenDelete(undefined)}
        insideModalStyle={{ textAlign: 'center', padding: 20 }}
        buttonText="Confirmer"
        secondButtonText="Annuler"
        heightPercentage={Platform.OS === 'web' ? 0.36 : 0.6}
        buttonPress={() => { deleteAnimal(); }}
      />
    </>
  );
};

export default DetailAnimalScreen;
