/**
 *
 * @author: David-Julian Buch
 */

import * as React from 'react';
import {
  StyleSheet, ViewStyle,
} from 'react-native';
import { Button as UIButton, ButtonProps as UIButtonProps, Spinner } from '@ui-kitten/components';
import { Icon as IconUIKitten } from '@ui-kitten/components/ui/icon/icon.component';
import { ReactChild } from 'react';

// import { useAnimation } from './Modals/AnimationBusinessLogic';

export type ButtonProps = UIButtonProps & {
  loading?: boolean;
  status?: string;
  size?: string;
  loadingText?: string;
  title?: string;
  svgIcon?: string;
  topIcon?: string;
  leftIcon?: string;
  customLeftIcon?: ReactChild;
  leftIconFill?: string;
  leftIconStyle?: ViewStyle;
  rightIcon?: string;
  rightIconFill?: string;
  rightIconStyle?: ViewStyle;
  onPress?: () => void;
};

function CompButton(props: ButtonProps): JSX.Element {
  const {
    status = "primary",
    loading,
    loadingText,
    style,
    size = "medium",
    title,
    svgIcon,
    topIcon,
    rightIcon,
    leftIcon,
    customLeftIcon,
    onPress,
    leftIconFill = '#000000',
    leftIconStyle = {
      height: 20, width: 20, paddingRight: 5,
    },
    rightIconFill = '#000000',
    rightIconStyle = {
      height: 20, width: 20, paddingLeft: 5,
    },
    ...buttonProps
  } = props;

  let { disabled, children } = props;

  let shadow = {};
  if ((status === 'primary' || status === undefined || status === 'control')
      && (buttonProps.appearance === undefined || buttonProps.appearance === 'default')
      && (!loading)
  ) {
    shadow = {
      shadowColor: 'rgba(190, 190, 190, 0.5)',
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowRadius: 2,
      shadowOpacity: 1,
      elevation: 2,
    };
  }

  const LoadingIndicator = () => (
    <Spinner status="basic" size="small" />
  );

  if (loading) {
    disabled = true;
    if (loadingText) {
      children = loadingText;
    }
  }

  let { accessoryRight, accessoryLeft } = buttonProps;
  if (rightIcon && accessoryRight === undefined) {
    accessoryRight = () => (
      <IconUIKitten
        name={rightIcon}
        fill={rightIconFill}
        style={rightIconStyle}
      />
    );
  }
  if (leftIcon && accessoryLeft === undefined) {
    accessoryLeft = () => (
      <IconUIKitten
        name={leftIcon}
        fill={leftIconFill}
        style={leftIconStyle}
      />
    );
  }
  if (customLeftIcon && accessoryLeft === undefined) {
    accessoryLeft = () => (
      customLeftIcon
    );
  }
  /**
  if (svgIcon && topIcon === undefined) {
    topIcon = () => (
      <SvgIcon name={svgIcon} height={20} width={20} />
    );
    return (
      <View>
        <UIButton
          style={StyleSheet.flatten([shadow, style])}
          {...buttonProps}
          onPress={onPress}
        >
          {children}
        </UIButton>
      </View>
    );
  }
  */

  // const slideToMiddle = useAnimation();

  return (
    <UIButton
      status={status}
      style={StyleSheet.flatten([
        shadow,
        style,
        // slideToMiddle,
      ])}
      {...buttonProps}
      disabled={disabled}
      accessoryLeft={accessoryLeft}
      accessoryRight={loading ? LoadingIndicator : accessoryRight}
      onPress={onPress}
      size={size}
    >
      {children}
    </UIButton>
  );
}

CompButton.displayName = 'CompButton';

export default CompButton;
