import React, { useContext, createContext } from 'react';
import UidContext from './UidContext';
import { UidInterface } from './RtcContext';

export const LocalContext = createContext<UidInterface>({} as UidInterface);
export const LocalProvider = LocalContext.Provider;
export const LocalConsumer = LocalContext.Consumer;

interface LocalUserContextInterface {
  children: React.ReactNode;
}

const LocalUserContext: React.FC<LocalUserContextInterface> = (props) => {
  const uids = useContext(UidContext);
  const localUser: UidInterface = uids.filter(({ uid }) => uid === 'local').pop();
  console.log('uids', uids);
  console.log('local', localUser);
  return (
    <LocalContext.Provider value={localUser ?? { uid: 'local', hasAudio: false, hasVideo: false }}>
      {props.children}
    </LocalContext.Provider>
  );
};

export default LocalUserContext;
