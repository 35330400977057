import { useLinkTo, useRoute } from '@react-navigation/native';
import { Text } from '@ui-kitten/components';

import React from 'react';

import { RouteProp } from '@react-navigation/core/lib/typescript/src/types';
import ConsultationScreenBL from '../../../screenBusinessLogic/ConsultationScreen/ConsultationScreenBL';
import GoCustomCard from '../../../components/GoCustomCard';
import InfoCard from '../../InfoCard';
import { TabConsultationParamList } from '../../../types';
import Tutorial from '../../../components/Tutorial';

const MesEchangesAvecVetCards = () => {
  const linkTo = useLinkTo();
  const route = useRoute<RouteProp<TabConsultationParamList, 'detail-consultation'>>();

  return (
    <>
      <Text category="h2" appearance="hint" style={{ marginTop: 40 }}>Mes échanges avec mon vétérinaire</Text>
      <Tutorial
        videoId="QOB_FtbmsD8"
      />
      <InfoCard
        title="alertInfo"
        iconShow="alert-circle-outline"
        // iconCloseShow="close-outline"
        description={'La messagerie instantanée vous permet de communiquer avec votre véto lors d’un auto-examen partagé.\n'
        + 'Vous pouvez échanger des documents ou photos relatifs à cet auto-examen à partir de l’espace « Documents ».\n'
        + 'Dans l’espace « Document », vous pouvez télécharger le document de votre souhait destiné à être envoyé via la messagerie instantanée lors de vos échanges. Par précaution, une confirmation de l’envoi vous sera demandée.'}
        alertInfo="alertInfo"
        infoStyle={{ marginTop: 10 }}
      />

      <GoCustomCard title1="Messagerie" linkToCustom={() => { ConsultationScreenBL.linkToSwitch({ state: 'messagerie', linkTo, id: route.params.id }); }} minusSquareOutline="arrow-ios-forward" />

      <GoCustomCard title1="Documents" linkToCustom={() => { ConsultationScreenBL.linkToSwitch({ state: 'documents', linkTo, id: route.params.id }); }} minusSquareOutline="arrow-ios-forward" />

      {/* <InfoCard
        title="alertInfo"
        iconShow="alert-circle-outline"
        // iconCloseShow="close-outline"
        description={"Vous pouvez retrouver les documents partagés dans votre messagerie dans l'espace « Documents »."}
        alertInfo="alertInfo"
        infoStyle={{ marginTop: 20 }}
      /> */}

    </>
  );
};

export default MesEchangesAvecVetCards;
