import React, { useState } from 'react';

export const GlobalStateContext = React.createContext({ state: false, setState: (v:boolean) => {} });

export const GlobalStateProvider = ({ children }) => {
  const [state, setState] = useState(false);

  return (
    <GlobalStateContext.Provider value={{ state, setState }}>
      { children }
    </GlobalStateContext.Provider>
  );
};
