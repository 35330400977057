import React, { useEffect, useState } from 'react';
import {
  Platform, StyleSheet, View,
} from 'react-native';
import {
  Radio, Text,
} from '@ui-kitten/components';
import { useNavigation, useRoute } from '@react-navigation/native';
import { RouteProp } from '@react-navigation/core/lib/typescript/src/types';
import { useForm } from 'react-hook-form';

import { useDimensions } from '@react-native-community/hooks';
import * as DocumentPicker from 'expo-document-picker';
import TextInput from '../../components/Form/TextInput';
import MaxWidthContainer from '../../components/MaxWidthContainer';
import { InscriptionEtape3Form, TabElevageParamList, TabMonCompteParamList } from '../../types';
import Form from '../../components/Form/Form';

import { AvailableValidationRules } from '../../components/Form/validation';
import { useUser } from '../../src/API/UserContext';
import Button from '../../components/Button';

import { useBreedingList, useUpdateBreedingMutation } from '../../src/API/Breeding';
import { useCreatePendingInvitationMutation } from '../../src/API/PendingInvitation';
import {
  DocumentItem,
  useCreateDocumentMutation,
} from '../../src/API/Document';
import { Upload } from '../../utils/S3FileStorage';
import ItemTitle from '../../screenComponents/ItemTitle';
import { AuthStyles } from '../../components/Auth/styles';

const InscriptionEtape3Screen = () => {
  const navigation = useNavigation();
  const route = useRoute<
  RouteProp<TabMonCompteParamList, 'inscription-etape-3'>
  |
  RouteProp<TabElevageParamList, 'modify-elevage'>
  >();
  // console.log('route InscriptionEtape3Screen: ', route);
  const { user } = useUser();
  const { breeding: oneBreeding } = useBreedingList('eleveur');
  // console.log(oneBreeding);
  const { modifyBreeding } = useUpdateBreedingMutation();
  const [loading, setLoading] = useState(false);

  const [dairyFarm, setDairyFarm] = useState(false);
  const [sucklerCowHusbandry, setSucklerCowHusbandry] = useState(false);
  const [fatteningFarm, setFatteningFarm] = useState(false);

  let boolSignUp: boolean = true;
  if (route && route.params && route.params?.signUp === 'false') {
    boolSignUp = false;
  }

  const { window } = useDimensions();

  const { createPendingInvitationVeto } = useCreatePendingInvitationMutation();
  const sendInvitationEmail = async (emailVeto: string) => {
    if (user && user.email && oneBreeding) {
      await createPendingInvitationVeto({
        variables: {
          input: {
            breedingId: oneBreeding.id,
            emailCurrentUser: user.email,
            initial: true,
            email: emailVeto.toLowerCase(),
          },
        },
      });
    }
  };

  const inscriptionEtape3Form = useForm<InscriptionEtape3Form>();
  const onPress = async (data: InscriptionEtape3Form) => {
    /**  Send invitation Email */
    if (data.emailVeto) {
      sendInvitationEmail(data.emailVeto);
    }

    /**  modify(create breeding)   */
    if (boolSignUp) {
      if (user) {
        await modifyBreeding({
          variables: {
            input: {
              id: oneBreeding.id,
              ...data,
              dairyFarm,
              sucklerCowHusbandry,
              fatteningFarm,
              // eslint-disable-next-line no-underscore-dangle
              _version: oneBreeding._version,
            },
          },
        });

        navigation.navigate('inscription-etape-4', {
          signUp: true,
        });
      }
    } else {
      // eslint-disable-next-line no-console
      // console.log('Modify elevage from Inscription Etap 3 Screen tsx');
    }
  };

  useEffect(() => {
    if (oneBreeding && oneBreeding.documents) {
      // eslint-disable-next-line no-underscore-dangle
      oneBreeding.documents?.items?.forEach((item) => { if (item && !item._deleted && item.key && item.key.includes('EDE')) { setEDE(item); } });
    }
    if (oneBreeding) {
      setDairyFarm(oneBreeding.dairyFarm);
      setFatteningFarm(oneBreeding.fatteningFarm);
      setSucklerCowHusbandry(oneBreeding.sucklerCowHusbandry);
    }
  }, [oneBreeding?.id]);

  return (
    <>
      <MaxWidthContainer
        withScrollView="keyboardAware"
        innerViewProps={{
          style: {
            paddingHorizontal: 24,
            paddingVertical: 34,
          },
        }}
      >
        <Form<InscriptionEtape3Form>
          {...inscriptionEtape3Form}
          defaultValues={oneBreeding}
        >
          <>
            <View style={{
              alignItems: 'center',
            }}
            >
              <Text category="h2" style={styles.title}>{boolSignUp ? "Je m'inscris en tant qu'éleveur" : "Je m'inscris en tant qu'éleveur"}</Text>
              <Text category="p1" appearance="hint" style={{ marginVertical: 20 }}>{boolSignUp ? 'Etape 3/4' : 'Etape 3/4'}</Text>
            </View>

            <Text category="h5" style={{ marginTop: 30 }}>Type de production*</Text>

            <View style={{ flexDirection: 'row', marginVertical: 20 }}>
              <Radio
                checked={dairyFarm}
                onChange={(newChecked) => {
                  setDairyFarm(newChecked);
                  setSucklerCowHusbandry(false);
                  setFatteningFarm(false);
                }}
              >
                Laitier
              </Radio>
              <Radio
                checked={sucklerCowHusbandry}
                onChange={(newChecked) => {
                  setSucklerCowHusbandry(newChecked);
                  setDairyFarm(false);
                  setFatteningFarm(false);
                }}
              >
                Allaitant
              </Radio>
              <Radio
                checked={fatteningFarm}
                onChange={(newChecked) => {
                  setFatteningFarm(newChecked);
                  setDairyFarm(false);
                  setSucklerCowHusbandry(false);
                }}
              >
                Engraisseur
              </Radio>
            </View>

            <View style={styles.buttonRight}>
              <Button loading={loading} loadingText="Chargement" onPress={inscriptionEtape3Form.handleSubmit((data) => { setLoading(true); onPress(data); })} size="large">
                Suivant
              </Button>
            </View>
            <Text category="c1" appearance="hint">
              * champs obligatoires
            </Text>
          </>
        </Form>

      </MaxWidthContainer>

    </>

  );
};

const styles = StyleSheet.create({
  buttonRight: { alignItems: 'flex-end', marginTop: 34 },
  title: {
    marginTop: 29,
    textAlign: 'center',
  },
});

export default InscriptionEtape3Screen;
