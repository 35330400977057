/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      lastname
      firstname
      avatarUri
      userGroup
      optIn
      inscriptionNumberOrdreVeto
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      vetOfficeId
      vetOffice {
        id
        locked
        companyName
        emailOffice
        admins
        personnel
        phoneVetOffice
        nameVetSanitaire
        nOrdreCabinet
        nSIRET
        maxPriceTeleConsultation
        maxPriceConsultation
        mpUserId
        isFree
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      email
      expoToken
      notificationLastSeenAt
      messages {
        nextToken
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      lastname
      firstname
      avatarUri
      userGroup
      optIn
      inscriptionNumberOrdreVeto
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      vetOfficeId
      vetOffice {
        id
        locked
        companyName
        emailOffice
        admins
        personnel
        phoneVetOffice
        nameVetSanitaire
        nOrdreCabinet
        nSIRET
        maxPriceTeleConsultation
        maxPriceConsultation
        mpUserId
        isFree
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      email
      expoToken
      notificationLastSeenAt
      messages {
        nextToken
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      lastname
      firstname
      avatarUri
      userGroup
      optIn
      inscriptionNumberOrdreVeto
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      vetOfficeId
      vetOffice {
        id
        locked
        companyName
        emailOffice
        admins
        personnel
        phoneVetOffice
        nameVetSanitaire
        nOrdreCabinet
        nSIRET
        maxPriceTeleConsultation
        maxPriceConsultation
        mpUserId
        isFree
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      email
      expoToken
      notificationLastSeenAt
      messages {
        nextToken
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createVetOffice = /* GraphQL */ `
  mutation CreateVetOffice(
    $input: CreateVetOfficeInput!
    $condition: ModelVetOfficeConditionInput
  ) {
    createVetOffice(input: $input, condition: $condition) {
      id
      locked
      companyName
      emailOffice
      admins
      personnel
      phoneVetOffice
      address {
        address
        additionalAddress
        postalCode
        city
      }
      nameVetSanitaire
      nOrdreCabinet
      nSIRET
      maxPriceTeleConsultation
      maxPriceConsultation
      users {
        nextToken
        startedAt
      }
      breedings {
        nextToken
        startedAt
      }
      billingHistories {
        nextToken
      }
      mpUserId
      isFree
      mpWalletId
      mpBankId
      mpMandateId
      mpMandateConfirmed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateVetOffice = /* GraphQL */ `
  mutation UpdateVetOffice(
    $input: UpdateVetOfficeInput!
    $condition: ModelVetOfficeConditionInput
  ) {
    updateVetOffice(input: $input, condition: $condition) {
      id
      locked
      companyName
      emailOffice
      admins
      personnel
      phoneVetOffice
      address {
        address
        additionalAddress
        postalCode
        city
      }
      nameVetSanitaire
      nOrdreCabinet
      nSIRET
      maxPriceTeleConsultation
      maxPriceConsultation
      users {
        nextToken
        startedAt
      }
      breedings {
        nextToken
        startedAt
      }
      billingHistories {
        nextToken
      }
      mpUserId
      isFree
      mpWalletId
      mpBankId
      mpMandateId
      mpMandateConfirmed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteVetOffice = /* GraphQL */ `
  mutation DeleteVetOffice(
    $input: DeleteVetOfficeInput!
    $condition: ModelVetOfficeConditionInput
  ) {
    deleteVetOffice(input: $input, condition: $condition) {
      id
      locked
      companyName
      emailOffice
      admins
      personnel
      phoneVetOffice
      address {
        address
        additionalAddress
        postalCode
        city
      }
      nameVetSanitaire
      nOrdreCabinet
      nSIRET
      maxPriceTeleConsultation
      maxPriceConsultation
      users {
        nextToken
        startedAt
      }
      breedings {
        nextToken
        startedAt
      }
      billingHistories {
        nextToken
      }
      mpUserId
      isFree
      mpWalletId
      mpBankId
      mpMandateId
      mpMandateConfirmed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createBreeding = /* GraphQL */ `
  mutation CreateBreeding(
    $input: CreateBreedingInput!
    $condition: ModelBreedingConditionInput
  ) {
    createBreeding(input: $input, condition: $condition) {
      id
      locked
      nBreeding
      nSIRET
      phone
      emailEleveur
      address {
        address
        additionalAddress
        postalCode
        city
      }
      companyName
      dairyFarm
      sucklerCowHusbandry
      fatteningFarm
      nbCow
      nbHeifer
      nbMale
      nbYoung
      idVeto
      emailVeto
      admins
      vetOffice {
        id
        locked
        companyName
        emailOffice
        admins
        personnel
        phoneVetOffice
        nameVetSanitaire
        nOrdreCabinet
        nSIRET
        maxPriceTeleConsultation
        maxPriceConsultation
        mpUserId
        isFree
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      users {
        nextToken
        startedAt
      }
      documents {
        nextToken
      }
      animals {
        nextToken
        startedAt
      }
      lots {
        nextToken
        startedAt
      }
      buildings {
        nextToken
        startedAt
      }
      bse {
        nextToken
        startedAt
      }
      pendingInvitations {
        nextToken
        startedAt
      }
      consultations {
        nextToken
        startedAt
      }
      billingHistories {
        nextToken
      }
      mpUserId
      mpWalletId
      mpBankId
      mpMandateId
      mpMandateConfirmed
      createdAt
      dateFirstCons
      nextSubscriptionDate
      subscriptionAmount
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateBreeding = /* GraphQL */ `
  mutation UpdateBreeding(
    $input: UpdateBreedingInput!
    $condition: ModelBreedingConditionInput
  ) {
    updateBreeding(input: $input, condition: $condition) {
      id
      locked
      nBreeding
      nSIRET
      phone
      emailEleveur
      address {
        address
        additionalAddress
        postalCode
        city
      }
      companyName
      dairyFarm
      sucklerCowHusbandry
      fatteningFarm
      nbCow
      nbHeifer
      nbMale
      nbYoung
      idVeto
      emailVeto
      admins
      vetOffice {
        id
        locked
        companyName
        emailOffice
        admins
        personnel
        phoneVetOffice
        nameVetSanitaire
        nOrdreCabinet
        nSIRET
        maxPriceTeleConsultation
        maxPriceConsultation
        mpUserId
        isFree
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      users {
        nextToken
        startedAt
      }
      documents {
        nextToken
      }
      animals {
        nextToken
        startedAt
      }
      lots {
        nextToken
        startedAt
      }
      buildings {
        nextToken
        startedAt
      }
      bse {
        nextToken
        startedAt
      }
      pendingInvitations {
        nextToken
        startedAt
      }
      consultations {
        nextToken
        startedAt
      }
      billingHistories {
        nextToken
      }
      mpUserId
      mpWalletId
      mpBankId
      mpMandateId
      mpMandateConfirmed
      createdAt
      dateFirstCons
      nextSubscriptionDate
      subscriptionAmount
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteBreeding = /* GraphQL */ `
  mutation DeleteBreeding(
    $input: DeleteBreedingInput!
    $condition: ModelBreedingConditionInput
  ) {
    deleteBreeding(input: $input, condition: $condition) {
      id
      locked
      nBreeding
      nSIRET
      phone
      emailEleveur
      address {
        address
        additionalAddress
        postalCode
        city
      }
      companyName
      dairyFarm
      sucklerCowHusbandry
      fatteningFarm
      nbCow
      nbHeifer
      nbMale
      nbYoung
      idVeto
      emailVeto
      admins
      vetOffice {
        id
        locked
        companyName
        emailOffice
        admins
        personnel
        phoneVetOffice
        nameVetSanitaire
        nOrdreCabinet
        nSIRET
        maxPriceTeleConsultation
        maxPriceConsultation
        mpUserId
        isFree
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      users {
        nextToken
        startedAt
      }
      documents {
        nextToken
      }
      animals {
        nextToken
        startedAt
      }
      lots {
        nextToken
        startedAt
      }
      buildings {
        nextToken
        startedAt
      }
      bse {
        nextToken
        startedAt
      }
      pendingInvitations {
        nextToken
        startedAt
      }
      consultations {
        nextToken
        startedAt
      }
      billingHistories {
        nextToken
      }
      mpUserId
      mpWalletId
      mpBankId
      mpMandateId
      mpMandateConfirmed
      createdAt
      dateFirstCons
      nextSubscriptionDate
      subscriptionAmount
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createBillingHistory = /* GraphQL */ `
  mutation CreateBillingHistory(
    $input: CreateBillingHistoryInput!
    $condition: ModelBillingHistoryConditionInput
  ) {
    createBillingHistory(input: $input, condition: $condition) {
      id
      vetOfficeId
      vetOffice {
        id
        locked
        companyName
        emailOffice
        admins
        personnel
        phoneVetOffice
        nameVetSanitaire
        nOrdreCabinet
        nSIRET
        maxPriceTeleConsultation
        maxPriceConsultation
        mpUserId
        isFree
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      type
      invoiceNumber
      amount
      label
      paid
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAnimals = /* GraphQL */ `
  mutation CreateAnimals(
    $input: CreateAnimalsInput!
    $condition: ModelAnimalsConditionInput
  ) {
    createAnimals(input: $input, condition: $condition) {
      id
      numberID
      name
      gender
      birthDate
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      lotsId
      lots {
        id
        numberLot
        name
        buildingId
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      consultations {
        nextToken
        startedAt
      }
      zoneId
      zone {
        id
        name
        numberZone
        buildingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      buildingId
      building {
        id
        nameBuilding
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAnimals = /* GraphQL */ `
  mutation UpdateAnimals(
    $input: UpdateAnimalsInput!
    $condition: ModelAnimalsConditionInput
  ) {
    updateAnimals(input: $input, condition: $condition) {
      id
      numberID
      name
      gender
      birthDate
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      lotsId
      lots {
        id
        numberLot
        name
        buildingId
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      consultations {
        nextToken
        startedAt
      }
      zoneId
      zone {
        id
        name
        numberZone
        buildingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      buildingId
      building {
        id
        nameBuilding
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAnimals = /* GraphQL */ `
  mutation DeleteAnimals(
    $input: DeleteAnimalsInput!
    $condition: ModelAnimalsConditionInput
  ) {
    deleteAnimals(input: $input, condition: $condition) {
      id
      numberID
      name
      gender
      birthDate
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      lotsId
      lots {
        id
        numberLot
        name
        buildingId
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      consultations {
        nextToken
        startedAt
      }
      zoneId
      zone {
        id
        name
        numberZone
        buildingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      buildingId
      building {
        id
        nameBuilding
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createLots = /* GraphQL */ `
  mutation CreateLots(
    $input: CreateLotsInput!
    $condition: ModelLotsConditionInput
  ) {
    createLots(input: $input, condition: $condition) {
      id
      numberLot
      name
      buildingId
      building {
        id
        nameBuilding
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      animals {
        nextToken
        startedAt
      }
      consultations {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateLots = /* GraphQL */ `
  mutation UpdateLots(
    $input: UpdateLotsInput!
    $condition: ModelLotsConditionInput
  ) {
    updateLots(input: $input, condition: $condition) {
      id
      numberLot
      name
      buildingId
      building {
        id
        nameBuilding
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      animals {
        nextToken
        startedAt
      }
      consultations {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteLots = /* GraphQL */ `
  mutation DeleteLots(
    $input: DeleteLotsInput!
    $condition: ModelLotsConditionInput
  ) {
    deleteLots(input: $input, condition: $condition) {
      id
      numberLot
      name
      buildingId
      building {
        id
        nameBuilding
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      animals {
        nextToken
        startedAt
      }
      consultations {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createBuildings = /* GraphQL */ `
  mutation CreateBuildings(
    $input: CreateBuildingsInput!
    $condition: ModelBuildingsConditionInput
  ) {
    createBuildings(input: $input, condition: $condition) {
      id
      nameBuilding
      address {
        address
        additionalAddress
        postalCode
        city
      }
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      zone {
        nextToken
        startedAt
      }
      animals {
        nextToken
        startedAt
      }
      lots {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateBuildings = /* GraphQL */ `
  mutation UpdateBuildings(
    $input: UpdateBuildingsInput!
    $condition: ModelBuildingsConditionInput
  ) {
    updateBuildings(input: $input, condition: $condition) {
      id
      nameBuilding
      address {
        address
        additionalAddress
        postalCode
        city
      }
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      zone {
        nextToken
        startedAt
      }
      animals {
        nextToken
        startedAt
      }
      lots {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteBuildings = /* GraphQL */ `
  mutation DeleteBuildings(
    $input: DeleteBuildingsInput!
    $condition: ModelBuildingsConditionInput
  ) {
    deleteBuildings(input: $input, condition: $condition) {
      id
      nameBuilding
      address {
        address
        additionalAddress
        postalCode
        city
      }
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      zone {
        nextToken
        startedAt
      }
      animals {
        nextToken
        startedAt
      }
      lots {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createZones = /* GraphQL */ `
  mutation CreateZones(
    $input: CreateZonesInput!
    $condition: ModelZonesConditionInput
  ) {
    createZones(input: $input, condition: $condition) {
      id
      name
      numberZone
      animals {
        nextToken
        startedAt
      }
      buildingId
      building {
        id
        nameBuilding
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateZones = /* GraphQL */ `
  mutation UpdateZones(
    $input: UpdateZonesInput!
    $condition: ModelZonesConditionInput
  ) {
    updateZones(input: $input, condition: $condition) {
      id
      name
      numberZone
      animals {
        nextToken
        startedAt
      }
      buildingId
      building {
        id
        nameBuilding
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteZones = /* GraphQL */ `
  mutation DeleteZones(
    $input: DeleteZonesInput!
    $condition: ModelZonesConditionInput
  ) {
    deleteZones(input: $input, condition: $condition) {
      id
      name
      numberZone
      animals {
        nextToken
        startedAt
      }
      buildingId
      building {
        id
        nameBuilding
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createBSE = /* GraphQL */ `
  mutation CreateBSE(
    $input: CreateBSEInput!
    $condition: ModelBSEConditionInput
  ) {
    createBSE(input: $input, condition: $condition) {
      id
      breedingId
      StatusBse
      dateBSE
      crS3Key
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      questionResponse
      lasIdQuestion
      idEleveurUser
      lastQuestionVeto
      questionResponseOrder
      maladieRecurente
      protocolesDeSoins
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateBSE = /* GraphQL */ `
  mutation UpdateBSE(
    $input: UpdateBSEInput!
    $condition: ModelBSEConditionInput
  ) {
    updateBSE(input: $input, condition: $condition) {
      id
      breedingId
      StatusBse
      dateBSE
      crS3Key
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      questionResponse
      lasIdQuestion
      idEleveurUser
      lastQuestionVeto
      questionResponseOrder
      maladieRecurente
      protocolesDeSoins
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteBSE = /* GraphQL */ `
  mutation DeleteBSE(
    $input: DeleteBSEInput!
    $condition: ModelBSEConditionInput
  ) {
    deleteBSE(input: $input, condition: $condition) {
      id
      breedingId
      StatusBse
      dateBSE
      crS3Key
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      questionResponse
      lasIdQuestion
      idEleveurUser
      lastQuestionVeto
      questionResponseOrder
      maladieRecurente
      protocolesDeSoins
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDocument = /* GraphQL */ `
  mutation CreateDocument(
    $input: CreateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    createDocument(input: $input, condition: $condition) {
      id
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      consultationId
      consultation {
        id
        breedingId
        animalId
        lotId
        diseaseId
        shared
        sharedAt
        autoExam
        teleconsultation
        priceConsultation
        diseaseIsVerified
        isDone
        factureId
        sharedFacture
        sharedFactureAt
        teleconsAskAt
        dateTeleCons
        questionResponse
        note
        questionResponseOrder
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      name
      key
      s3file
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDocument = /* GraphQL */ `
  mutation UpdateDocument(
    $input: UpdateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    updateDocument(input: $input, condition: $condition) {
      id
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      consultationId
      consultation {
        id
        breedingId
        animalId
        lotId
        diseaseId
        shared
        sharedAt
        autoExam
        teleconsultation
        priceConsultation
        diseaseIsVerified
        isDone
        factureId
        sharedFacture
        sharedFactureAt
        teleconsAskAt
        dateTeleCons
        questionResponse
        note
        questionResponseOrder
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      name
      key
      s3file
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteDocument = /* GraphQL */ `
  mutation DeleteDocument(
    $input: DeleteDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    DeleteDocument(input: $input, condition: $condition) {
      id
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      consultationId
      consultation {
        id
        breedingId
        animalId
        lotId
        diseaseId
        shared
        sharedAt
        autoExam
        teleconsultation
        priceConsultation
        diseaseIsVerified
        isDone
        factureId
        sharedFacture
        sharedFactureAt
        teleconsAskAt
        dateTeleCons
        questionResponse
        note
        questionResponseOrder
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      name
      key
      s3file
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      consultationId
      consultation {
        id
        breedingId
        animalId
        lotId
        diseaseId
        shared
        sharedAt
        autoExam
        teleconsultation
        priceConsultation
        diseaseIsVerified
        isDone
        factureId
        sharedFacture
        sharedFactureAt
        teleconsAskAt
        dateTeleCons
        questionResponse
        note
        questionResponseOrder
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      message
      userId
      user {
        id
        lastname
        firstname
        avatarUri
        userGroup
        optIn
        inscriptionNumberOrdreVeto
        breedingId
        vetOfficeId
        email
        expoToken
        notificationLastSeenAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createPendingInvitation = /* GraphQL */ `
  mutation CreatePendingInvitation(
    $input: CreatePendingInvitationInput!
    $condition: ModelPendingInvitationConditionInput
  ) {
    createPendingInvitation(input: $input, condition: $condition) {
      id
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      emailCurrentUser
      email
      createdAt
      initial
      refusedAt
      acceptedAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updatePendingInvitation = /* GraphQL */ `
  mutation UpdatePendingInvitation(
    $input: UpdatePendingInvitationInput!
    $condition: ModelPendingInvitationConditionInput
  ) {
    updatePendingInvitation(input: $input, condition: $condition) {
      id
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      emailCurrentUser
      email
      createdAt
      initial
      refusedAt
      acceptedAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deletePendingInvitation = /* GraphQL */ `
  mutation DeletePendingInvitation(
    $input: DeletePendingInvitationInput!
    $condition: ModelPendingInvitationConditionInput
  ) {
    deletePendingInvitation(input: $input, condition: $condition) {
      id
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      emailCurrentUser
      email
      createdAt
      initial
      refusedAt
      acceptedAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createConsultation = /* GraphQL */ `
  mutation CreateConsultation(
    $input: CreateConsultationInput!
    $condition: ModelConsultationConditionInput
  ) {
    createConsultation(input: $input, condition: $condition) {
      id
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      animalId
      animal {
        id
        numberID
        name
        gender
        birthDate
        breedingId
        lotsId
        zoneId
        buildingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      lotId
      lot {
        id
        numberLot
        name
        buildingId
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      diseaseId
      disease {
        id
        codeIdentification
        name
        forme
        description
        synonymes
        enjeux
        causes
        circonstancesApparition
        diagnosticDiff
        diagnosticLab
        traitementCuratif
        pronostic
        prevention
        url
        image
        ponderationDisease
        ponderationCount
        familleMaladie
        familleMaladieId
        MRLC
        maladieUrgente
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      shared
      sharedAt
      autoExam
      autoExamRes {
        reponse1
      }
      teleconsultation
      priceConsultation
      diseaseIsVerified
      isDone
      factureId
      facture {
        id
        breedingId
        consultationId
        name
        key
        s3file
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      documents {
        nextToken
      }
      messages {
        nextToken
      }
      sharedFacture
      sharedFactureAt
      teleconsAskAt
      dateTeleCons
      questionResponse
      note
      questionResponseOrder
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateConsultation = /* GraphQL */ `
  mutation UpdateConsultation(
    $input: UpdateConsultationInput!
    $condition: ModelConsultationConditionInput
  ) {
    updateConsultation(input: $input, condition: $condition) {
      id
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      animalId
      animal {
        id
        numberID
        name
        gender
        birthDate
        breedingId
        lotsId
        zoneId
        buildingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      lotId
      lot {
        id
        numberLot
        name
        buildingId
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      diseaseId
      disease {
        id
        codeIdentification
        name
        forme
        description
        synonymes
        enjeux
        causes
        circonstancesApparition
        diagnosticDiff
        diagnosticLab
        traitementCuratif
        pronostic
        prevention
        url
        image
        ponderationDisease
        ponderationCount
        familleMaladie
        familleMaladieId
        MRLC
        maladieUrgente
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      shared
      sharedAt
      autoExam
      autoExamRes {
        reponse1
      }
      teleconsultation
      priceConsultation
      diseaseIsVerified
      isDone
      factureId
      facture {
        id
        breedingId
        consultationId
        name
        key
        s3file
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      documents {
        nextToken
      }
      messages {
        nextToken
      }
      sharedFacture
      sharedFactureAt
      teleconsAskAt
      dateTeleCons
      questionResponse
      note
      questionResponseOrder
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteConsultation = /* GraphQL */ `
  mutation DeleteConsultation(
    $input: DeleteConsultationInput!
    $condition: ModelConsultationConditionInput
  ) {
    deleteConsultation(input: $input, condition: $condition) {
      id
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      animalId
      animal {
        id
        numberID
        name
        gender
        birthDate
        breedingId
        lotsId
        zoneId
        buildingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      lotId
      lot {
        id
        numberLot
        name
        buildingId
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      diseaseId
      disease {
        id
        codeIdentification
        name
        forme
        description
        synonymes
        enjeux
        causes
        circonstancesApparition
        diagnosticDiff
        diagnosticLab
        traitementCuratif
        pronostic
        prevention
        url
        image
        ponderationDisease
        ponderationCount
        familleMaladie
        familleMaladieId
        MRLC
        maladieUrgente
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      shared
      sharedAt
      autoExam
      autoExamRes {
        reponse1
      }
      teleconsultation
      priceConsultation
      diseaseIsVerified
      isDone
      factureId
      facture {
        id
        breedingId
        consultationId
        name
        key
        s3file
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      documents {
        nextToken
      }
      messages {
        nextToken
      }
      sharedFacture
      sharedFactureAt
      teleconsAskAt
      dateTeleCons
      questionResponse
      note
      questionResponseOrder
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDisease = /* GraphQL */ `
  mutation CreateDisease(
    $input: CreateDiseaseInput!
    $condition: ModelDiseaseConditionInput
  ) {
    createDisease(input: $input, condition: $condition) {
      id
      codeIdentification
      name
      forme
      description
      synonymes
      enjeux
      causes
      circonstancesApparition
      diagnosticDiff
      diagnosticLab
      traitementCuratif
      pronostic
      prevention
      url
      consultations {
        nextToken
        startedAt
      }
      image
      ponderationDisease
      ponderationCount
      familleMaladie
      familleMaladieId
      MRLC
      maladieUrgente
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDisease = /* GraphQL */ `
  mutation UpdateDisease(
    $input: UpdateDiseaseInput!
    $condition: ModelDiseaseConditionInput
  ) {
    updateDisease(input: $input, condition: $condition) {
      id
      codeIdentification
      name
      forme
      description
      synonymes
      enjeux
      causes
      circonstancesApparition
      diagnosticDiff
      diagnosticLab
      traitementCuratif
      pronostic
      prevention
      url
      consultations {
        nextToken
        startedAt
      }
      image
      ponderationDisease
      ponderationCount
      familleMaladie
      familleMaladieId
      MRLC
      maladieUrgente
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteDisease = /* GraphQL */ `
  mutation DeleteDisease(
    $input: DeleteDiseaseInput!
    $condition: ModelDiseaseConditionInput
  ) {
    deleteDisease(input: $input, condition: $condition) {
      id
      codeIdentification
      name
      forme
      description
      synonymes
      enjeux
      causes
      circonstancesApparition
      diagnosticDiff
      diagnosticLab
      traitementCuratif
      pronostic
      prevention
      url
      consultations {
        nextToken
        startedAt
      }
      image
      ponderationDisease
      ponderationCount
      familleMaladie
      familleMaladieId
      MRLC
      maladieUrgente
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createProtocolSoin = /* GraphQL */ `
  mutation CreateProtocolSoin(
    $input: CreateProtocolSoinInput!
    $condition: ModelProtocolSoinConditionInput
  ) {
    createProtocolSoin(input: $input, condition: $condition) {
      id
      idDiseaseFamily
      name
      Protocol
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateProtocolSoin = /* GraphQL */ `
  mutation UpdateProtocolSoin(
    $input: UpdateProtocolSoinInput!
    $condition: ModelProtocolSoinConditionInput
  ) {
    updateProtocolSoin(input: $input, condition: $condition) {
      id
      idDiseaseFamily
      name
      Protocol
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteProtocolSoin = /* GraphQL */ `
  mutation DeleteProtocolSoin(
    $input: DeleteProtocolSoinInput!
    $condition: ModelProtocolSoinConditionInput
  ) {
    deleteProtocolSoin(input: $input, condition: $condition) {
      id
      idDiseaseFamily
      name
      Protocol
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createProtocolSoinSave = /* GraphQL */ `
  mutation CreateProtocolSoinSave(
    $input: CreateProtocolSoinSaveInput!
    $condition: ModelProtocolSoinSaveConditionInput
  ) {
    createProtocolSoinSave(input: $input, condition: $condition) {
      id
      breedingId
      bseId
      idDiseaseFamily
      new
      name
      Protocol
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateProtocolSoinSave = /* GraphQL */ `
  mutation UpdateProtocolSoinSave(
    $input: UpdateProtocolSoinSaveInput!
    $condition: ModelProtocolSoinSaveConditionInput
  ) {
    updateProtocolSoinSave(input: $input, condition: $condition) {
      id
      breedingId
      bseId
      idDiseaseFamily
      new
      name
      Protocol
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteProtocolSoinSave = /* GraphQL */ `
  mutation DeleteProtocolSoinSave(
    $input: DeleteProtocolSoinSaveInput!
    $condition: ModelProtocolSoinSaveConditionInput
  ) {
    deleteProtocolSoinSave(input: $input, condition: $condition) {
      id
      breedingId
      bseId
      idDiseaseFamily
      new
      name
      Protocol
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        lastname
        firstname
        avatarUri
        userGroup
        optIn
        inscriptionNumberOrdreVeto
        breedingId
        vetOfficeId
        email
        expoToken
        notificationLastSeenAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      type
      title
      body
      data
      clicked
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        lastname
        firstname
        avatarUri
        userGroup
        optIn
        inscriptionNumberOrdreVeto
        breedingId
        vetOfficeId
        email
        expoToken
        notificationLastSeenAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      type
      title
      body
      data
      clicked
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        lastname
        firstname
        avatarUri
        userGroup
        optIn
        inscriptionNumberOrdreVeto
        breedingId
        vetOfficeId
        email
        expoToken
        notificationLastSeenAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      type
      title
      body
      data
      clicked
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createNotificationTickets = /* GraphQL */ `
  mutation CreateNotificationTickets(
    $input: CreateNotificationTicketsInput!
    $condition: ModelNotificationTicketsConditionInput
  ) {
    createNotificationTickets(input: $input, condition: $condition) {
      id
      expoTokens {
        userId
        token
      }
      ticketIds
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateNotificationTickets = /* GraphQL */ `
  mutation UpdateNotificationTickets(
    $input: UpdateNotificationTicketsInput!
    $condition: ModelNotificationTicketsConditionInput
  ) {
    updateNotificationTickets(input: $input, condition: $condition) {
      id
      expoTokens {
        userId
        token
      }
      ticketIds
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteNotificationTickets = /* GraphQL */ `
  mutation DeleteNotificationTickets(
    $input: DeleteNotificationTicketsInput!
    $condition: ModelNotificationTicketsConditionInput
  ) {
    deleteNotificationTickets(input: $input, condition: $condition) {
      id
      expoTokens {
        userId
        token
      }
      ticketIds
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
