import {
  ImageSourcePropType, Platform, View, ViewStyle,
} from 'react-native';
import { Icon as IconUIKitten } from '@ui-kitten/components/ui/icon/icon.component';
import React from 'react';
import { Text, useTheme } from '@ui-kitten/components';
import CompCard from '../components/Card';
import CompButton from '../components/Button';
import RotatingIcon from '../components/Icon/RotatingIcon';
import InfoCardImage from '../components/InfoCardImage';
import { useIsTablet } from '../utils/CustomHooks';

export type InfoCardProps = {
  title?: string;
  onPressCard?: () => void;

  imageUri?: ImageSourcePropType;
  imagePosition?: 'left' | 'right';
  description?: string;
  description2?: string;
  buttonText?: string;

  buttonOnPress?: () => void;
  secondButtonOnPress?: () => void;

  invertButtonStyle?:boolean;

  infoStyle?: ViewStyle;
  // appearance?: string;
  // status?: string;

  alertInfo?: string;
  secondButtonText?: string;

  opened?: boolean;
  iconShow?: string;
  iconCloseShow?: string;
  // disabled?: boolean;
};

const InfoCard = (props: InfoCardProps) => {
  const theme = useTheme();
  const isTablet = useIsTablet();

  const {
    title,
    imageUri,
    onPressCard,
    imagePosition,
    description,
    description2,
    buttonOnPress,
    buttonText,
    infoStyle,
    alertInfo,
    secondButtonText,
    secondButtonOnPress,
    iconShow,
    iconCloseShow,
    opened,
    invertButtonStyle = false,
  } = props;

  let secondaryButtonStyle = {
    flex: 1, justifyContent: 'center', borderColor: theme['color-primary-100'], paddingHorizontal: 5, maxHeight: 44, marginBottom: 15, backgroundColor: theme['color-primary-100'],
  };

  let primaryButtonStyle = {
    flex: 1, justifyContent: 'center', borderColor: theme['color-primary-500'], maxHeight: 44, paddingHorizontal: 5, backgroundColor: theme['color-primary-500'],
  };

  if (invertButtonStyle) {
    const bckp = { ...secondaryButtonStyle };
    secondaryButtonStyle = { ...primaryButtonStyle, marginBottom: 15 };
    primaryButtonStyle = { ...bckp, maxHeight: 44 };
  }
  // console.log('infor card :', opened);
  return (
    <CompCard
      style={[{
        paddingVertical: 20,
        paddingHorizontal: 20,
        marginBottom: 20,
        zIndex: 1500,
      },
      iconShow === 'alert-circle-outline'
        ? { backgroundColor: theme['color-primary-100'], borderColor: theme['color-primary-300'], borderWidth: 0.5 }
        : (iconShow === 'alert-triangle-outline'
          ? { backgroundColor: theme['color-danger-100'], borderColor: theme['color-danger-300'], borderWidth: 0.5 }
          : { backgroundColor: '#FFFFFF' }),
      infoStyle,
      Platform.OS === 'android'
        && { shadowColor: 'black' },

      ]}

      onPress={onPressCard}
    >
      {title !== 'alertInfo' && (
        <Text category="h3" style={{ marginLeft: 25, marginBottom: 10 }}>
          {title}
        </Text>
      )}
      <View style={{
        flexDirection: 'column', alignItems: 'flex-start',
      }}
      >
        {!isTablet && description2 ? (
          <Text
            category="s2"
          >
            {description}
          </Text>
        ) : null}
        <View style={{
          flex: 1, flexDirection: 'row', alignItems: imageUri ? 'flex-end' : 'flex-start', width: '100%', justifyContent: 'space-between',
        }}
        >
          {iconShow && (
            <View style={{ flexDirection: 'column', alignItems: 'center' }}>
              <IconUIKitten
                name={iconShow}
                fill="#000000"
                style={{
                  height: 20, width: 20, marginRight: 5,
                }}
              />
            </View>
          )}
          {(imagePosition === 'left' && imageUri) ? (
            <InfoCardImage source={imageUri} imageStyle={{ marginRight: 15, marginTop: 15 }} />
          ) : null}

          <View style={[
            {
              flex: 1, flexDirection: 'column', justifyContent: 'flex-start', height: '100%', maxWidth: imageUri ? 400 : null,
            },

          ]}
          >
            <Text
              category="s2"
              style={[
                { flex: 1 },
                iconShow !== 'alert-circle-outline'
                && iconShow !== 'alert-triangle-outline'
                && iconShow !== 'arrow-ios-downward-outline'
                && ({ marginBottom: 20 }),
              ]}
            >
              {isTablet || !(!isTablet && description2) ? (description ?? '') + (description2 ?? '') : description2}
            </Text>

            {isTablet && secondButtonText ? (
              <CompButton
                size="medium"
                status={!invertButtonStyle ? 'basic' : 'primary'}
                style={secondaryButtonStyle}
                onPress={secondButtonOnPress}
              >
                {secondButtonText}
              </CompButton>
            ) : null}

            {buttonText && (isTablet && alertInfo !== 'alertInfo') ? (
              <CompButton
                size="medium"
                status={invertButtonStyle ? 'basic' : 'primary'}
                style={primaryButtonStyle}
                onPress={buttonOnPress}
              >
                {buttonText}
              </CompButton>
            ) : null}
          </View>
          {iconShow === 'arrow-ios-downward-outline' && (
          <>
            <RotatingIcon
              name="arrow-ios-downward-outline"
              uikitten
              state={opened}
              width={20}
              height={20}
              fill="#000000"
            />
            {/**
                 <IconUIKitten
                 name={iconShow}
                 fill="#000000"
                 style={{
              height: 20, width: 20, marginLeft: 5,
            }}
           />
           */}
          </>
          )}
          {(imagePosition === 'right' && imageUri) ? (
            <InfoCardImage source={imageUri} imageStyle={{ marginLeft: 15, marginTop: 15 }} />
          ) : null}

        </View>
        {iconCloseShow === 'close-outline' && (
          <View style={{ flexDirection: 'column' }}>
            <IconUIKitten
              name={iconCloseShow}
              fill="#000000"
              style={{
                height: 20, width: 20, marginLeft: 5,
              }}
            />
          </View>
        )}
      </View>

      {!isTablet && secondButtonText ? (
        <CompButton
          size="medium"
          status={!invertButtonStyle ? 'basic' : 'primary'}
          style={secondaryButtonStyle}
          onPress={secondButtonOnPress}
        >
          {secondButtonText}
        </CompButton>
      ) : null}

      {buttonText && (!isTablet && alertInfo !== 'alertInfo') ? (
        <CompButton
          size="medium"
          status={invertButtonStyle ? 'basic' : 'primary'}
          style={primaryButtonStyle}
          onPress={buttonOnPress}
        >
          {buttonText}
        </CompButton>
      ) : null}

    </CompCard>
  );
};

export default InfoCard;
