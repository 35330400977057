/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import * as Linking from 'expo-linking';

export default {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      Root: {
        screens: {
          'veto-bottom-tab-nav': {
            path: '',
            screens: {
              'veto-tableau-de-bord': {
                initialRouteName: 'veto-tableau-de-bord',
                path: 'veto-tableau-de-bord',
                screens: {
                  'veto-tableau-de-bord': '/',

                },
              },
              'veto-elevage-nav': {
                path: 'veto-elevage',
                screens: {
                  'veto-elevage': '/',
                  'veto-effectif-elevage': '/veto-effectif-elevage/:id',

                  'veto-detail-animal': '/veto-detail-animal/:id',
                  'veto-detail-lot': '/veto-detail-lot/:id',
                  'veto-detail-batiment': '/veto-detail-batiment/:id',

                  'veto-voir-plus-animal': '/veto-voir-plus-animal',
                  'veto-voir-plus-lot': '/veto-voir-plus-lot',
                  'veto-voir-plus-batiment': '/veto-voir-plus-batiment',

                  'veto-ajout-elevage': '/veto-ajout-elevage',
                  'veto-details-elevage': '/veto-details-elevage/:id',
                  'veto-historique-bse': '/veto-historique-bse/:id',
                  'veto-details-bse': '/veto-details-bse',
                },
              },
              'veto-examen-nav': {
                path: 'veto-examen',
                screens: {
                  'veto-examen': '/',
                  'veto-details-consultation': '/veto-details-consultation/:id',
                  'veto-details-teleconsultation': '/veto-details-consultation/veto-details-teleconsultation/:id',
                  'veto-consultation-document': '/veto-details-consultation/:id/document',
                  'veto-consultation-messagerie': '/veto-details-consultation/:id/messagerie',
                  'veto-consultation-questionnaire': '/veto-details-consultation/:id/questionnaire',
                },
              },
              'veto-teleconsultation-nav': {
                path: 'veto-teleconsultation',
                screens: {
                  'veto-teleconsultation': '/',
                },
              },
              'veto-bse-nav': {
                path: 'veto-bse',
                screens: {
                  'veto-bse': '/',
                  'veto-realiser-bse': '/veto-realiser-bse',
                  'veto-ajout-elevage': '/veto-realiser-bse/veto-ajout-elevage', ///
                  'veto-details-bse': '/veto-details-bse/:id',
                  'bse-questionnaire': '/bse-questionnaire/:id',
                  'veto-protocol-soins': '/veto-protocol-soins/:id',
                  'veto-prebilan': '/veto-details-bse/veto-prebilan',
                },
              },
            },
          },
          'veto-mon-cabinet-nav': {
            initialRouteName: 'veto-mon-cabinet',
            path: 'veto-mon-cabinet',
            screens: {
              'veto-mon-cabinet': '/',
              'veto-mon-compte': '/veto-mon-compte',
              'veto-modify-info-perso': '/veto-modify-info-perso/:signUp',
              'veto-modifier-mon-compte': '/veto-mon-compte/veto-modifier-mon-compte',
              'veto-modify-password': '/veto-mon-compte/veto-modifier-mon-compte/veto-modify-password/:id',

              'veto-utilisateurs': '/veto-utilisateurs',
              'veto-details-utilisateur': '/veto-utilisateurs/veto-details-utilisateur',
              'veto-ajout-utilisateurs': '/veto-utilisateurs/veto-ajout-utilisateurs',
              'veto-modify-utilisateurs': '/veto-utilisateurs/veto-modify-utilisateurs/:id',

              'veto-tarif-consultation': '/veto-tarif-consultation',
              'veto-facture': '/veto-facture',

              'infos-bancaires': '/infos-bancaires',

            },
          },
          'veto-notifications-nav': {
            initialRouteName: 'veto-notifications',
            path: 'veto-notifications',
            screens: {
              'veto-notifications': '/',
              'veto-notifications-params': '/veto-notifications-params',
            },
          },
          faq: 'faq',
        },
      },
      NotFound: '*',
    },
  },
};
