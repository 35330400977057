import * as React from 'react';
import { useContext, useState } from 'react';
import { View } from 'react-native';
import { Text } from '@ui-kitten/components';
import UidContext from './UidContext';
import { UidInterface } from './RtcContext';
import { useIsTablet } from '../../utils/CustomHooks';

export default function MuteWaring() {
  const uids = useContext(UidContext);
  const [viewWidth, setWidth] = useState(0);

  const isTabletFormat = useIsTablet();

  const localUser: UidInterface | undefined = uids.filter(({ uid }) => uid === 'local').pop();
  if (localUser && (!localUser.hasVideo || !localUser.hasAudio)) {
    let message = '';
    if (!localUser.hasVideo && !localUser.hasAudio) {
      message = 'Vous avez coupé votre microphone et votre caméra.';
    } else if (!localUser.hasVideo) {
      message = 'Vous avez coupé votre caméra.';
    } else {
      message = 'Vous avez coupé votre microphone.';
    }
    return (
      <View
        style={{
          position: 'absolute',
          backgroundColor: 'orange',
          padding: 5,
          borderRadius: 5,
          top: isTabletFormat ? 140 : 80,
          left: '50%',
          transform: [
            { translateX: -viewWidth / 2 },
          ],
        }}
        onLayout={(event) => {
          const { width } = event.nativeEvent.layout;
          setWidth(width);
        }}
      >
        <Text category="h6">{message}</Text>
      </View>
    );
  }
  return (
    <>
    </>
  );
}
