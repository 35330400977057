import { Text } from '@ui-kitten/components';
import { Platform, TouchableOpacity, View } from 'react-native';
import { Icon as IconUIKitten } from '@ui-kitten/components/ui/icon/icon.component';
import React, { useState } from 'react';
import Button from '../components/Button';
import InfoModal from '../components/InfoModal';

type ItemTitleProps = {
  titleType: '1' | '2' | '3' | '4' | '5',
  title: string,
  description?: string,
  status?: 'primary' | 'default'
};

const ItemTitle = (props: ItemTitleProps) => {
  const {
    titleType, title, description, status = 'primary',
  } = props;

  let style = {};
  switch (titleType) {
    case '1':
      style = {
        alignItems: 'center', paddingBottom: 20, paddingTop: 35, flexDirection: 'row',
      };
      break;
    case '2':
      style = {
        paddingLeft: 15, paddingBottom: 20, paddingTop: 35, flexDirection: 'row',
      };
      break;
    case '3':
      style = {
        paddingLeft: 25, paddingBottom: 20, paddingTop: 35, flexDirection: 'row',
      };
      break;
    case '4':
      style = {
        paddingLeft: 45, paddingBottom: 20, paddingTop: 35, flexDirection: 'row',
      };
      break;
    case '5':
      style = {
        paddingBottom: 20, paddingTop: 20, flexDirection: 'row',
      };
      break;
    default:
      style = {};
  }

  return (
    <View style={style}>
      <Text category={`h${titleType}`} status={status}>{title}</Text>
      {description ? (
        <InfoModal infoText={description} />
      ) : null}

    </View>
  );
};

export default ItemTitle;
