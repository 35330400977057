import React from 'react';
import { Text } from '@ui-kitten/components';
import { useLinkTo } from '@react-navigation/native';

import MaxWidthContainer from '../../components/MaxWidthContainer';

import GoCustomCard from '../../components/GoCustomCard';
import Button from '../../components/Button';

const ModifierInfoScreen = () => {
  const linkTo = useLinkTo();
  return (
    <MaxWidthContainer
      outerViewProps={{
        style: {
          padding: 20,
          marginBottom: 20,
        },
      }}
    >
      <Text category="h3">Informations générales</Text>
      <GoCustomCard
        title1="Modifier mes informations générales"
        linkToCustom={() => {
          linkTo('/mon-compte/modify-info-perso');
        }}
        minusSquareOutline="arrow-ios-forward"
      />

      <Text category="h3">Confidentialité et sécurité</Text>
      <GoCustomCard
        title1="Modifier mon mot de passe"
        linkToCustom={() => {
          linkTo('/mon-compte/modify-password');
        }}
        minusSquareOutline="arrow-ios-forward"
      />

      <GoCustomCard
        title1="Modifier mon adresse e-mail"
        linkToCustom={() => {
          linkTo('/mon-compte/modify-email');
        }}
        minusSquareOutline="arrow-ios-forward"
      />

      <Text category="h3" style={{ marginTop: 40 }}>Suppression du compte</Text>

      <Button status="danger" appearance="outline" style={{ backgroundColor: 'white', marginTop: 20 }}>Supprimer mon compte</Button>
    </MaxWidthContainer>
  );
};

export default ModifierInfoScreen;
