export const userGroup = [
  {
    label: 'Vétérinaire',
    key: 'veterinaire',
  },
  {
    label: 'Personnel',
    key: 'personnel',
  },
];
