import {
  Input,
  Radio, Spinner, Text, useTheme,
} from '@ui-kitten/components';
import React, {
  useCallback,
  useContext, useEffect, useRef, useState,
} from 'react';

import {
  useIsFocused, useLinkTo, useNavigation, useRoute,
} from '@react-navigation/native';
import {
  BackHandler,
  FlatList, InteractionManager, TouchableOpacity, View,
} from 'react-native';
import { Icon as IconUIKitten } from '@ui-kitten/components/ui/icon/icon.component';
import _ from 'lodash';
import * as math from 'mathjs';

import BseScreenBL from '../../screenBusinessLogic/BseScreenBL/BseScreenBL';

import Card from '../../components/Card';
import Button from '../../components/Button';
import { GlobalStateContext } from '../../utils/GlobalStateContext';
import CrudBL from '../../screenBusinessLogic/ConsultationScreen/CrudBL';
import questionConditionDataInit from '../../src/mockData/bseQuestionConditionData';
import CustomModal from '../../components/Modals/CustomModal';
import { useFunction } from '../../utils/CustomHooks';
import ListAutoExamenItem from '../ListAutoExamenItem';
import { QuestionnaireData } from '../ListAutoExam';
import { useUser } from '../../src/API/UserContext';
import { useGetBse } from '../../src/API/BSE';
import { StatusBse } from '../../src/API';

export type QuestionaireDataItem = {
  id: string;
  titre1? :string;
  titre2? :string;
  titre3? :string;
  titre4? :string;
  autopass?: boolean;
  // quand on a valider la réponse de cette question
  validate?: boolean;
  // les questions a afficher dans la flatlist
  showQuestion?: boolean;
  lastChecked?: number;
  name: string;
  description: string;
  interdire: boolean;
  veto: boolean;
  imposer: boolean;
  readonly?: boolean;
  poserMaintenant: boolean,
  hideQuestion: boolean;
  multipleAnswer: boolean;
  function?: string
  answers: {
    [key: string]: {
      name: string;
      description: string;
      image: string;
      interdire: boolean;
      veto: boolean;
      imposer: boolean;
      checked: boolean;
      showNextQuestion: boolean,
    }
  }
};

const ListPrebilanBilan = () => {
  const linkTo = useLinkTo();
  const route = useRoute();
  const scrollViewRef = useRef(null);
  const theme = useTheme();
  /**  screen height logic */
  const navigation = useNavigation();
  const { isVeto } = useUser();
  const { bse } = useGetBse(route?.params?.id);
  console.log(bse);
  const [flatListLayout, setFlatListLayout] = useState({
    layoutHeight: 0,
    contentHeight: 0,
    contentOffsetY: 0,
  });
  const [hasInit, setHasInit] = useState(false);

  const isCloseToBottom = () => {
    const paddingToBottom = 20;
    const {
      layoutHeight,
      contentHeight,
      contentOffsetY,
    } = flatListLayout;
    return layoutHeight + contentOffsetY
        >= contentHeight - paddingToBottom;
  };

  useEffect(() => {
    if (isCloseToBottom()) {
      setIsBottom(false);
    } else {
      setIsBottom(true);
    }
  }, [flatListLayout.layoutHeight, flatListLayout.contentHeight, flatListLayout.contentOffsetY]);

  const [isBottom, setIsBottom] = useState(false);
  const isAllCheck = !!route.params.allCheck;
  const [showIgnore, setShowIgnore] = useState(true);
  const { state, setState } = useContext(GlobalStateContext);
  const [currentQuestionnaireData, setCurrentQuestionnaireData] = useState({});
  const [questionConditionData, setQuestionConditionData] = useState({ ...questionConditionDataInit });
  const [currentQuestion, setCurrentQuestion] = useState<string>();

  const [showNext, setShowNext] = useState(false);
  const [ignoreQuestion, setIgnoreQuestion] = useState(false);

  const [finQuestionaire, setFinQuestionaire] = useState<string | undefined>(undefined);

  const [inputState, setInputState] = useState<'NI' | 'TI' | false>(false);
  // console.log('consultation:', consultation);
  useEffect(() => {
    const {
      showNext: showNextTmp,
      currentQuestion: currentQuestionTmp,
      currentQuestionnaireData: currentQuestionnaireDataTmp,
      questionConditionData: questionConditionDataTmp,
      finQuestionnaire: finQuestionnaireTmp,
      showIgnore: showIgnoreTmp,
      valueInputState: valueInputStateTmp,
      showInputState,
      diseaseSave: diseaseSaveTmp,
    } = BseScreenBL.initQuestionnaire(questionConditionData, bse, isVeto, isAllCheck);

    setShowNext(showNextTmp || false);
    setCurrentQuestion(currentQuestionTmp);
    setCurrentQuestionnaireData(currentQuestionnaireDataTmp);
    setQuestionConditionData(questionConditionDataTmp);
    setFinQuestionaire(finQuestionnaireTmp);
    setShowIgnore(showIgnoreTmp || true);
    setValueInputState(valueInputStateTmp);
    setInputState(showInputState || false);
    setDiseaseSave(diseaseSaveTmp);
    setHasInit(true);
    return () => {
    };
  }, [bse?.id]);
  // console.log(currentQuestionnaireData);
  const [answerChecked, setAnswerChecked] = useState<string[]>([]);

  const [valueInputState, setValueInputState] = useState<string | undefined>(undefined);

  const [diseaseSave, setDiseaseSave] = useState<string[]>([]);

  useEffect(() => {
    if (Object.values(currentQuestionnaireData).filter((item) => item.showQuestion).length > 0) {
      const data = Object.values(currentQuestionnaireData).filter((item) => item.showQuestion);
      let indexToGo = data.length - 1;
      // console.log(data);
      // on verifie si juste avant on a pas un autopass
      while (indexToGo > 0 && data[indexToGo - 1].autopass) {
        indexToGo -= 1;
      }
      scrollToBottom(indexToGo, 0, 0);
      if (data[indexToGo].answers && Object.values(data[indexToGo].answers)[0].answerType === 'F') {
        setShowNext(true);
      }
    }
  }, [currentQuestion]);

  useEffect(() => {
    const backAction = () => {
      setState(true);
      return true;
    };

    const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction);

    return () => backHandler.remove();
  }, []);

  const { editBse } = CrudBL.useCrudBSE();

  const scrollHandler = useCallback(_.throttle((event) => {
    if (event.nativeEvent) {
      setFlatListLayout({
        layoutHeight: event.nativeEvent.layoutMeasurement.height,
        contentHeight: event.nativeEvent.contentSize.height,
        contentOffsetY: event.nativeEvent.contentOffset.y,
      });
    }
  }, 200));
  const layoutHandler = useCallback(_.throttle((event) => {
    if (event.nativeEvent) {
      setFlatListLayout((currentState) => ({
        ...currentState,
        layoutHeight: event.nativeEvent.layout.height,
      }));
    }
  }, 200));

  const finaliserQuestionnaire = async (nextState: any, isDone: boolean) => {
    const questionReponse : { [key: string]: string[] } = {};
    const questionResponseOrder : string[] = [];
    const listKey = Object.keys(nextState);
    const questionResponseTemp = JSON.parse(bse?.questionResponse);
    let lastQuestionVeto = '';
    listKey.forEach((key) => {
      if (nextState[key].validate) {
        const answers: string[] = [];
        Object.keys(nextState[key].answers).forEach((answerKey) => {
          if (nextState[key].answers[answerKey].answerText && ['F', 'TI', 'NI'].indexOf(nextState[key].answers[answerKey].answerType) > -1) {
            answers.push(nextState[key].answers[answerKey].answerText);
          } else if (nextState[key].answers[answerKey].checked) {
            answers.push(answerKey);
          }
        });
        lastQuestionVeto = key;
        questionReponse[key] = answers;
        questionResponseOrder.push(key);
      } else
      // dans le cas du veto non fini on rajoute les réponses non encore vue de l'éleveur
      if (!isDone && isVeto && questionResponseTemp[key]) {
        questionReponse[key] = questionResponseTemp[key];
        questionResponseOrder.push(key);
      }
    });
    let currentStatusBse: StatusBse = StatusBse.preReviewStarted;
    if (isDone && !isVeto) {
      currentStatusBse = StatusBse.preReviewFinished;
    } else if (isDone && isVeto) {
      currentStatusBse = StatusBse.BSEProtocolSoins;
    } else if (!isDone && isVeto) {
      currentStatusBse = StatusBse.BSEStarted;
    }
    await editBse({
      id: route?.params?.id,
      breedingId: bse.breedingId,
      questionResponse: JSON.stringify(questionReponse),
      StatusBse: currentStatusBse,
      lastQuestionVeto: isVeto ? lastQuestionVeto : null,
      questionResponseOrder,
      crS3Key: bse.crS3Key,
      maladieRecurente: _.uniq(diseaseSave),
      _version: bse._version,
    });
    setState(false);
    setFinQuestionaire(undefined);
    if (isVeto && isDone) {
      linkTo(`/veto-bse/veto-protocol-soins/${route?.params?.id}`);
    } else if (isVeto) {
      navigation.goBack();
    } else {
      navigation.reset({
        index: 0,
        routes: [{ name: 'bilan-sanitaire' }],
      });
    }
  };

  const validTextInput = async () => {
    if (ignoreQuestion) {
      setValueInputState(undefined);
    }
    const keys = Object.keys(currentQuestionnaireData);
    const newListKey = keys.slice(keys.indexOf(currentQuestion));
    const nextState = _.cloneDeep(currentQuestionnaireData);
    nextState[currentQuestion].answers[currentQuestion].answerText = valueInputState;
    nextState[currentQuestion].answers[currentQuestion].checked = true;
    nextState[currentQuestion].lastChecked = Date.now();
    const showNow = false;
    nextState[currentQuestion].validate = true;

    if (ignoreQuestion) {
      const questionConditionDataTemp = questionConditionData;
      // cas ou on ignore, alors on vérifie s'il n'y a pas des liens interactif dans le cas ignoré
      // (on cherche alors par l'ID de la question et plus par l'ID de la réponse)
      const nbRepeat = '';
      const ids = [currentQuestion];
      ids.forEach((id) => {
        if (id && questionConditionDataTemp[id]) {
          questionConditionDataTemp[id].map((item) => {
            if (item.cible.substr(-1) === '/') {
              const regex = /([A-Za-z0-9).]+?)([0-9]+\/)/gm;
              let questionId = '';
              let reponseId = '';
              let m;
              while ((m = regex.exec(item.cible)) !== null) {
                // This is necessary to avoid infinite loops with zero-width matches
                if (m.index === regex.lastIndex) {
                  regex.lastIndex++;
                }

                // The result can be accessed through the `m`-variable.
                m.forEach((match, groupIndex) => {
                  if (groupIndex === 1) {
                    questionId = match;
                  }
                  if (groupIndex === 2) {
                    reponseId = match;
                  }
                });
              }
              if (questionId.startsWith('D)16)') && reponseId === '4/') {
                const regex2 = /([A-Za-z0-9).]+?)([0-9]+\.)/gm;
                let famillediseasId = '';
                let n;
                while ((n = regex2.exec(item.cible)) !== null) {
                  // This is necessary to avoid infinite loops with zero-width matches
                  if (n.index === regex2.lastIndex) {
                    regex2.lastIndex++;
                  }

                  // The result can be accessed through the `m`-variable.
                  n.forEach((match, groupIndex) => {
                    if (groupIndex === 2) {
                      famillediseasId = match.slice(0, -1);
                    }
                  });
                }
                if (famillediseasId) {
                  setDiseaseSave((diseaseSaveTmp) => {
                    const currentState = _.clone(diseaseSaveTmp);
                    currentState.push(famillediseasId);
                    return currentState;
                  });
                }
              } else if (nextState[questionId]) {
                if (item.interdire) {
                  nextState[questionId].answers[item.cible].interdire = true;
                  nextState[questionId].answers[item.cible].imposer = false;
                  nextState[questionId].answers[item.cible].checked = false;
                }
                if (item.imposer) {
                  nextState[questionId].answers[item.cible].imposer = true;
                  nextState[questionId].answers[item.cible].interdire = false;
                  nextState[questionId].answers[item.cible].checked = true;
                  if (nextState[questionId] && !nextState[questionId].multipleAnswer) {
                    nextState[questionId].validate = true;
                    nextState[questionId].interdire = true;
                  }
                }
              }
            } else {
              if (item.veto) {
                keys.filter((key) => {
                  const finalKey = nextState[nbRepeat + key] ? nbRepeat + key : key;
                  if (key.startsWith(item.cible)) {
                    nextState[finalKey].veto = true;
                    nextState[finalKey].interdire = true;
                    nextState[finalKey].imposer = false;
                  }
                  return () => {
                  };
                });
              }
              if (item.poser) {
                keys.filter((key) => {
                  const finalKey = nextState[nbRepeat + key] ? nbRepeat + key : key;
                  if (key.startsWith(item.cible)) {
                    nextState[finalKey].poserMaintenant = true;
                  }
                  return () => {
                  };
                });
              }
              if (item.interdire) {
                keys.filter((key) => {
                  const finalKey = nextState[nbRepeat + key] ? nbRepeat + key : key;
                  if (key.startsWith(item.cible) && !nextState[finalKey].imposer) {
                    nextState[finalKey].interdire = true;
                  }
                  return () => {
                  };
                });
              }
              if (item.imposer) {
                keys.filter((key) => {
                  const finalKey = nextState[nbRepeat + key] ? nbRepeat + key : key;
                  if (key.startsWith(item.cible) && !nextState[finalKey].veto) {
                    nextState[finalKey].imposer = true;
                    nextState[finalKey].interdire = false;
                  }
                  return () => {
                  };
                });
              }
              if (item.resetInterdire) {
                keys.filter((key) => {
                  const finalKey = nextState[nbRepeat + key] ? nbRepeat + key : key;
                  if (key.startsWith(item.cible)) {
                    nextState[finalKey].interdire = item.interdire;
                  }
                  return () => {
                  };
                });
              }
            }

            return () => {
            };
          });
        }
      });
    }
    const {
      showNext: showNextTmp,
      currentQuestion: currentQuestionTmp,
      currentQuestionnaireData: currentQuestionnaireDataTmp,
      finQuestionnaire: finQuestionnaireTmp,
      showIgnore: showIgnoreTmp,
      valueInputState: valueInputStateTmp,
      showInputState,
    } = BseScreenBL.goNextQuestion(
      currentQuestionnaireData,
      nextState,
      showNow,
      newListKey,
      currentQuestion,
      false,
      false,
      isVeto,
      isAllCheck,
    );

    if (showNextTmp !== undefined) {
      setShowNext(showNextTmp);
    }
    if (currentQuestionTmp !== undefined) {
      setCurrentQuestion(currentQuestionTmp);
    }
    if (currentQuestionnaireDataTmp !== undefined) {
      setCurrentQuestionnaireData(currentQuestionnaireDataTmp);
    }
    if (finQuestionnaireTmp !== undefined) {
      setFinQuestionaire(finQuestionnaireTmp);
    }
    if (showIgnoreTmp !== undefined) {
      setShowIgnore(showIgnoreTmp);
    }
    if (valueInputStateTmp !== undefined) {
      setValueInputState(valueInputStateTmp);
    }
    if (showInputState !== undefined) {
      setInputState(showInputState);
    }
  };

  const scrollToBottom = (index, viewPosition? = 0, viewOffset? = 0) => {
    try {
      const dataLength = Object.values(currentQuestionnaireData).filter((item) => item.showQuestion).length;
      if (dataLength > 0) {
        setTimeout(() => scrollViewRef?.current?.scrollToIndex({
          index, animated: true, viewOffset, viewPosition,
        }), 300);
      }
    } catch (e) {
      // console.log('pas grave on devrait scroll à la prochaine');
    }
  };

  const modifyQuestionnaire = useFunction((idQuestion: string, answerId?: string, currentChecked?: boolean) => {
    const keys = Object.keys(currentQuestionnaireData);
    const keyUpdate = keys.slice(keys.indexOf(idQuestion) + 1);
    const keyBefore = keys.slice(0, keys.indexOf(idQuestion) + 1);
    let initialKeys = Object.keys(currentQuestionnaireData);
    const diseaseSaveTemp = [];
    initialKeys = initialKeys.slice(initialKeys.indexOf(idQuestion) + 1);
    const orderedKeyUpdate = [];
    for (let i = 0; i < initialKeys.length; i++) {
      if (keyUpdate.indexOf(initialKeys[i]) > -1) {
        orderedKeyUpdate.push(initialKeys[i]);
      }
    }

    const newQuestionnaireData = _.cloneDeep(currentQuestionnaireData);
    for (let j = 0; j < orderedKeyUpdate.length; j++) {
      newQuestionnaireData[orderedKeyUpdate[j]] = { ...currentQuestionnaireData[orderedKeyUpdate[j]] };
      newQuestionnaireData[orderedKeyUpdate[j]].validate = false;
      newQuestionnaireData[orderedKeyUpdate[j]].showQuestion = false;
      newQuestionnaireData[orderedKeyUpdate[j]].imposer = false;
      newQuestionnaireData[orderedKeyUpdate[j]].interdire = !!orderedKeyUpdate[j].includes('_') || (!!orderedKeyUpdate[j].includes('#suite') && !orderedKeyUpdate[j].includes('#suite2'));
      newQuestionnaireData[orderedKeyUpdate[j]].poserMaintenant = false;
      if (newQuestionnaireData[orderedKeyUpdate[j]].answers) {
        const answersKeys = Object.keys(newQuestionnaireData[orderedKeyUpdate[j]].answers);
        for (let k = 0; k < answersKeys.length; k++) {
          newQuestionnaireData[orderedKeyUpdate[j]].answers[answersKeys[k]].interdire = false;
          newQuestionnaireData[orderedKeyUpdate[j]].answers[answersKeys[k]].imposer = false;
        }
      }
    }

    for (let l = 0; l < keys.indexOf(idQuestion); l++) {
      if (newQuestionnaireData[keys[l]].answers && newQuestionnaireData[keys[l]].validate) {
        Object.keys(newQuestionnaireData[keys[l]].answers).map((answerKey) => {
          let nbRepeat = '';
          if (answerKey.includes('_')) {
            const answerSplit = answerKey.split('_');
            nbRepeat = `${answerSplit[0]}_`;
            answerKey = answerSplit[1];
          }
          const answer = newQuestionnaireData[keys[l]].answers[answerKey];
          // on contrôle la question si c'est la partie 16
          if (answer && answer.answerType && answer.answerType === 'F' && answer.answerText) {
            const diseaseId = getBgColor(keys[l], answerKey, answer.answerText, true);
            if (diseaseId !== false) {
              diseaseSaveTemp.push(diseaseId);
            }
          }

          if (answer && answer.checked && questionConditionData[answerKey]) {
            questionConditionData[answerKey].map((item) => {
              if (item.cible.substr(-1) === '/') {
                const regex = /([A-Za-z0-9).]+?)([0-9]+\/)/gm;
                let questionId = '';
                let reponseId = '';
                let m;
                while ((m = regex.exec(item.cible)) !== null) {
                  // This is necessary to avoid infinite loops with zero-width matches
                  if (m.index === regex.lastIndex) {
                    regex.lastIndex++;
                  }

                  // The result can be accessed through the `m`-variable.
                  m.forEach((match, groupIndex) => {
                    if (groupIndex === 1) {
                      questionId = match;
                    }
                    if (groupIndex === 2) {
                      reponseId = match;
                    }
                  });
                }
                if (questionId.startsWith('D)16)') && reponseId === '4/') {
                  const regex2 = /([A-Za-z0-9).]+?)([0-9]+\.)/gm;
                  let famillediseasId = '';
                  let n;
                  while ((n = regex2.exec(item.cible)) !== null) {
                    // This is necessary to avoid infinite loops with zero-width matches
                    if (n.index === regex2.lastIndex) {
                      regex2.lastIndex++;
                    }

                    // The result can be accessed through the `m`-variable.
                    n.forEach((match, groupIndex) => {
                      if (groupIndex === 2) {
                        famillediseasId = match.slice(0, -1);
                      }
                    });
                  } if (famillediseasId) {
                    diseaseSaveTemp.push(famillediseasId);
                  }
                } else if (newQuestionnaireData[questionId]) {
                  if (item.interdire) {
                    newQuestionnaireData[questionId].answers[item.cible].interdire = true;
                    newQuestionnaireData[questionId].answers[item.cible].imposer = false;
                    newQuestionnaireData[questionId].answers[item.cible].checked = false;
                  }
                  if (item.imposer) {
                    newQuestionnaireData[questionId].answers[item.cible].imposer = true;
                    newQuestionnaireData[questionId].answers[item.cible].interdire = false;
                    newQuestionnaireData[questionId].answers[item.cible].checked = true;
                  }
                }
              } else {
                if (item.veto) {
                  keys.filter((key) => {
                    if (key.startsWith(item.cible)) {
                      newQuestionnaireData[nbRepeat + key].veto = true;
                      newQuestionnaireData[nbRepeat + key].interdire = true;
                      newQuestionnaireData[nbRepeat + key].imposer = false;
                    }
                    return () => {
                    };
                  });
                }
                if (item.poser) {
                  keys.filter((key) => {
                    if (key.startsWith(item.cible)) {
                      newQuestionnaireData[nbRepeat + key].poserMaintenant = true;
                    }
                    return () => {
                    };
                  });
                }
                if (item.interdire) {
                  keys.filter((key) => {
                    if (key.startsWith(item.cible) && !newQuestionnaireData[nbRepeat + key].imposer) {
                      newQuestionnaireData[nbRepeat + key].interdire = true;
                    }
                    return () => {
                    };
                  });
                }
                if (item.imposer) {
                  keys.filter((key) => {
                    if (key.startsWith(item.cible) && !newQuestionnaireData[nbRepeat + key].veto) {
                      newQuestionnaireData[nbRepeat + key].imposer = true;
                      newQuestionnaireData[nbRepeat + key].interdire = false;
                    }
                    return () => {
                    };
                  });
                }
                if (item.resetInterdire) {
                  keys.filter((key) => {
                    if (key.startsWith(item.cible)) {
                      newQuestionnaireData[nbRepeat + key].interdire = item.interdire;
                    }
                    return () => {
                    };
                  });
                }
              }

              return () => {
              };
            });
          }
        });
      }
    }
    let showIgnoreTemp = true;
    setDiseaseSave(diseaseSaveTemp);
    newQuestionnaireData[idQuestion].validate = false;
    if (answerId && !(newQuestionnaireData[idQuestion].answers[idQuestion] && newQuestionnaireData[idQuestion].answers[idQuestion].answerType)) {
      onChecked(idQuestion, answerId, currentChecked, true, newQuestionnaireData);
      if (newQuestionnaireData[idQuestion].answers[answerId].mandatory) {
        showIgnoreTemp = false;
      }
      setInputState(false);
    } else {
      if (newQuestionnaireData[idQuestion].answers[idQuestion] && (newQuestionnaireData[idQuestion].answers[idQuestion].answerType === 'NI' || newQuestionnaireData[idQuestion].answers[idQuestion].answerType === 'TI')) {
        setInputState(newQuestionnaireData[idQuestion].answers[idQuestion].answerType);
        setShowNext(false);
        if (newQuestionnaireData[idQuestion].answers[idQuestion].answerText) {
          setValueInputState(newQuestionnaireData[idQuestion].answers[idQuestion].answerText);
        } else {
          setValueInputState('');
        }
      }
      setCurrentQuestionnaireData(newQuestionnaireData);
    }
    setShowIgnore(showIgnoreTemp);
    setCurrentQuestion(idQuestion);
  });

  const onPressSaveAndQuit = () => {
    finaliserQuestionnaire(currentQuestionnaireData, false);
  };

  const onChecked = useFunction((
    idQuestion: string, checkedItem: string, currentChecked: boolean, trueCheck = true, baseState?: QuestionnaireData,
    // showNextQuestion, showingNextQuestion,
  ) => {
    // next lines of code permit to stock or remove checked answer
    // by comparing
    // current item from CheckedAnswersState  with checkedItem that was just checked

    const nextState = baseState ?? _.cloneDeep(currentQuestionnaireData);
    const newCurrentQuestion = nextState[idQuestion];
    const newChecked = newCurrentQuestion.multipleAnswer ? answerChecked.filter((current) => current !== checkedItem) : [];
    if (currentChecked) { newChecked.push(checkedItem); }

    /**  switch between 2 different questions that have same name
     * (e.g. 'A)1)c)2.') thanks to hideQuestion attribute */

    if (newCurrentQuestion.multipleAnswer) {
      newCurrentQuestion.answers[checkedItem].checked = currentChecked;
    } else {
      Object.keys(newCurrentQuestion.answers).map((item) => (newCurrentQuestion.answers[item].checked = false));
      newCurrentQuestion.answers[checkedItem].checked = currentChecked;
    }
    setAnswerChecked(newChecked);
    if (trueCheck) {
      newCurrentQuestion.lastChecked = Date.now();

      setCurrentQuestionnaireData(nextState);
      if (newChecked.length > 0) {
        setIgnoreQuestion(false);
        setShowNext(true);
      } else {
        setShowNext(false);
      }
    }
  });

  const replaceAll = (t: string) => {
    const regex = /\${([A-Za-z0-9.)]+)}/gm;
    // /\${([A-Za-z0-9.)]+)}/gm
    return t.replace(regex, (a, other) => {
      if (currentQuestionnaireData && currentQuestionnaireData[other] && currentQuestionnaireData[other].answers[other].answerText) {
        return currentQuestionnaireData[other].answers[other].answerText;
      }
      return '0';
    });
  };

  const calculateResult = (
    functionData: string,

  ) => math.evaluate(replaceAll(functionData));

  const setFunctionResult = (
    result: string, idQuestion:string,

  ) => {
    const nextState = _.cloneDeep(currentQuestionnaireData);
    nextState[idQuestion].answers[idQuestion].answerText = result;
    nextState[idQuestion].lastChecked = Date.now();
    setCurrentQuestionnaireData(nextState);
  };

  const setDiseaseSaveFromTaux = (idDisease:string) => {
    setDiseaseSave((diseaseSaveTmp) => {
      const currentState = _.clone(diseaseSaveTmp);
      currentState.push(idDisease);
      return currentState;
    });
  };

  const getIdFamilleDease = (idAnswer: string) => {
    const regex2 = /([A-Za-z0-9).]+?)([0-9]+\.)/gm;
    let famillediseasId = '';
    let n;
    while ((n = regex2.exec(idAnswer)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (n.index === regex2.lastIndex) {
        regex2.lastIndex++;
      }

      // The result can be accessed through the `m`-variable.
      n.forEach((match, groupIndex) => {
        if (groupIndex === 2) {
          famillediseasId = match.slice(0, -1);
        }
      });
    }
    return famillediseasId;
  };

  const getBgColor = (questionId:string, answerId:string, value: string, returnDiseaseId = false) => {
    let thisAnswer = currentQuestionnaireData[questionId]?.answers[answerId];
    if (thisAnswer.minVert.indexOf('${') > -1) {
      const possibleAnwsers = Object.values(currentQuestionnaireData[thisAnswer.minVert.replace('${', '').replace('}', '')].answers);
      thisAnswer = possibleAnwsers.find(({ checked }) => checked);
    }
    if (!thisAnswer) {
      return false;
    }

    const minVert = parseFloat(thisAnswer.minVert ?? '0');
    const maxVert = parseFloat(thisAnswer.maxVert ?? '0');
    const minOrange = parseFloat(thisAnswer.minOrange ?? '0');
    const maxOrange = parseFloat(thisAnswer.maxOrange ?? '0');
    const minRouge = parseFloat(thisAnswer.minRouge ?? '0');
    const maxRouge = parseFloat(thisAnswer.maxRouge ?? '0');

    const answerValue = parseFloat(value ?? '0');

    if (!questionId.startsWith('D)16)') && returnDiseaseId) {
      return false;
    }
    if (minVert <= answerValue
        && answerValue <= maxVert) {
      if (returnDiseaseId) {
        return false;
      }
      return (theme['color-success-400']);
    }
    if (minOrange <= answerValue
        && answerValue <= maxOrange) {
      if (questionId.startsWith('D)16)')) {
        if (returnDiseaseId) {
          return getIdFamilleDease(answerId);
        }
        setDiseaseSaveFromTaux(getIdFamilleDease(answerId));
      }
      return (theme['color-warning-500']);
    }
    if (minRouge <= answerValue && answerValue <= maxRouge) {
      if (questionId.startsWith('D)16)')) {
        if (returnDiseaseId) {
          return getIdFamilleDease(answerId);
        }
        setDiseaseSaveFromTaux(getIdFamilleDease(answerId));
      }
      return (theme['color-danger-600']);
    }
    return false;
  };
  // console.log('inputState', inputState, 'showNext', showNext, 'ignoreQuestion', ignoreQuestion);

  if (!hasInit) {
    return (
      <View style={{
        flex: 1,
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'center',
        padding: 5,
      }}
      >
        <Spinner />
      </View>
    );
  }

  const renderItem = ({ item }) => (
    <ListAutoExamenItem item={item} modifyQuestionnaire={modifyQuestionnaire} onChecked={onChecked} setFunctionResult={setFunctionResult} calculateResult={calculateResult} getBgColor={getBgColor} />
  );
  const keyExtractor = (item) => item.id;

  const onScrollToIndexFailed = (info) => {
    if (info.averageItemLength > 0) {
      const offset = info.averageItemLength * info.index;
      scrollViewRef?.current?.scrollToOffset({ offset, animated: true });
      setTimeout(() => {
        try {
          scrollViewRef?.current?.scrollToIndex({
            index: info.index, animated: true, viewOffset: 0, viewPosition: 0,
          });
        } catch (e) {
          try {
            scrollViewRef?.current?.scrollToIndex({
              index: info.index-1, animated: true, viewOffset: 0, viewPosition: 0,
            });
          } catch (e) {
            try {
              scrollViewRef?.current?.scrollToIndex({
                index: info.index-2, animated: true, viewOffset: 0, viewPosition: 0,
              });
            } catch (e) {
              // console.log('pas grave on devrait scroll à la prochaine');
            }
          }
        }
      }, 100);
    }
  };

  const onScroll = (event) => {
    if (event.persist) {
      event.persist();
    }
    scrollHandler(event);
  };

  const onLayout = (event) => {
    if (event.persist) {
      event.persist();
    }
    layoutHandler(event);
  };

  const onContentSizeChange = _.throttle((w, h) => {
    setFlatListLayout((currentState) => ({
      ...currentState,
      contentHeight: h,
    }));
  }, 200);

  const data = Object.values(currentQuestionnaireData).filter((item) => item.showQuestion);

  return (
    <>
      <FlatList<QuestionaireDataItem>
        style={{ backgroundColor: '#EFF8F8', paddingHorizontal: 10, flexGrow: 1 }}
        data={data}
        ref={scrollViewRef}
        onScrollToIndexFailed={onScrollToIndexFailed}
        onScroll={onScroll}
        onLayout={onLayout}
        onContentSizeChange={onContentSizeChange}
        renderItem={renderItem}
        initialNumToRender={1000}
        ListHeaderComponent={(isVeto ? (
          <View style={{ padding: 10 }}>
            <Text>
              L’importance estimée des différents problèmes abordés est symbolisée par un code couleur :
            </Text>
            <Text>
              <Text style={{ color: theme['color-success-400'] }}>« Vert »</Text>
              {' '}
              : RAS,
            </Text>
            <Text>
              <Text style={{ color: theme['color-warning-500'] }}>« Orange »</Text>
              {' '}
              : Risque potentiel,
            </Text>
            <Text>
              <Text style={{ color: theme['color-danger-600'] }}>« Rouge »</Text>
              {' '}
              : Alerte.
            </Text>
          </View>
        ) : null)}
        ListFooterComponent={(
          <>
            <View style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>

              <View style={{
                display: 'flex', flex: 3, alignSelf: 'flex-end', flexDirection: 'column',
              }}
              >
                <>
                  {route?.params?.update === 'false' ? (
                    <Button
                      rightIcon="arrow-ios-forward"
                      rightIconFill="#fff"
                      style={{ alignSelf: 'flex-end', width: 210, marginBottom: 10 }}
                      onPress={() => { linkTo(`/consultation/detail-consultation/${route?.params?.id}`); }}
                    >
                      Revenir à l'auto-examen
                    </Button>
                  ) : (
                    !isAllCheck && showIgnore && (
                    <>
                      <Text category="h4" style={{ marginBottom: 10, textAlign: 'right' }}>Vous ne savez pas ou vous n'êtes pas sûr ?</Text>
                      <Card
                        onPress={() => {
                          setIgnoreQuestion(!ignoreQuestion);
                          if (!ignoreQuestion) {
                            const nextState = _.cloneDeep(currentQuestionnaireData);
                            const newCurrentQuestion = nextState[currentQuestion];
                            if (newCurrentQuestion) {
                              Object.keys(newCurrentQuestion.answers).map((item) => {
                                if (!newCurrentQuestion.answers[item].imposer) {
                                  newCurrentQuestion.answers[item].checked = false;
                                }
                              });
                              newCurrentQuestion.lastChecked = Date.now();

                              setAnswerChecked([]);
                              setCurrentQuestionnaireData(nextState);
                            }
                          }
                        }}
                        style={{
                          flexDirection: 'row', alignSelf: 'flex-end', marginLeft: 55, marginBottom: 10,
                        }}
                      >
                        <Radio
                          onChange={(nextChecked) => {
                            setIgnoreQuestion(nextChecked);
                            if (!ignoreQuestion) {
                              const nextState = _.cloneDeep(currentQuestionnaireData);
                              const newCurrentQuestion = nextState[currentQuestion];

                              if (newCurrentQuestion) {
                                Object.keys(newCurrentQuestion.answers).map((item) => {
                                  if (!newCurrentQuestion.answers[item].imposer) {
                                    newCurrentQuestion.answers[item].checked = false;
                                  }
                                });
                                newCurrentQuestion.lastChecked = Date.now();

                                setAnswerChecked([]);
                                setCurrentQuestionnaireData(nextState);
                              }
                            }
                          }}
                          checked={ignoreQuestion}
                          style={{ marginRight: 5 }}
                        />
                        <Text category="h4">Ignorer cette question</Text>
                      </Card>
                      <View style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }} />
                    </>
                    )

                  )}

                  <View ref={scrollViewRef} />
                </>
              </View>
            </View>
          </>
            )}
        keyExtractor={keyExtractor}
      />
      {isBottom && (
      <TouchableOpacity
        onPress={() => scrollToBottom(Object.values(currentQuestionnaireData).filter((item) => item.showQuestion).length - 1, 1, -100)}
        style={{
          position: 'absolute', bottom: 100, marginHorizontal: 50, alignSelf: 'center', width: 300, backgroundColor: theme['color-primary-300'], borderRadius: 20, flexDirection: 'row', justifyContent: 'center',
        }}
      >
        <IconUIKitten
          name="arrow-downward-outline"
          style={{
            height: 20, width: 20, paddingRight: 5, paddingTop: 2,
          }}
          fill="#000000"
        />
        <Text>
          Avez-vous vu toutes les réponses ?
        </Text>
      </TouchableOpacity>
      )}

      {inputState !== false && !showNext && !ignoreQuestion && (
      <View style={{
        backgroundColor: 'white', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', paddingVertical: 10, paddingHorizontal: 4,
      }}
      >
        <Input
          style={{ borderRadius: 10, flex: 1, marginRight: 10 }}
          status="primary"
          keyboardType={inputState === 'NI' ? 'numeric' : undefined}
          value={valueInputState}
          placeholder="Saisir votre réponse ici"
          onChangeText={(nextValue) => setValueInputState(nextValue)}
        />
        <Button
          rightIcon="arrow-ios-forward"
          rightIconFill="#fff"
          style={{ width: 100, height: '100%' }}
          onPress={() => {
            validTextInput();
          }}
        >
          Envoyer
        </Button>
      </View>
      )}
      {!isAllCheck && (!inputState || ignoreQuestion) && (
      <View style={{
        backgroundColor: 'white', justifyContent: 'flex-end', flexDirection: 'row', alignItems: 'center', paddingVertical: 10, paddingHorizontal: 4, minHeight: 66,
      }}
      >
        {(showNext || ignoreQuestion)
            && (
            <Button
              rightIcon="arrow-ios-forward"
              rightIconFill="#fff"
              style={{ width: 100, height: '100%' }}
              onPress={() => {
                if (inputState) {
                  validTextInput();
                } else {
                  const {
                    showNext: showNextTmp,
                    currentQuestion: currentQuestionTmp,
                    currentQuestionnaireData: currentQuestionnaireDataTmp,
                    finQuestionnaire: finQuestionnaireTmp,
                    showIgnore: showIgnoreTmp,
                    valueInputState: valueInputStateTmp,
                    showInputState,
                    diseaseSave: diseaseSaveTmp,
                  } = BseScreenBL.validAnswer(
                    ignoreQuestion ? [] : answerChecked,
                    questionConditionData,
                    currentQuestionnaireData,
                    currentQuestion,
                    diseaseSave,
                    false,
                    undefined,
                    false,
                    false,
                    isVeto,
                    isAllCheck,
                  );

                  if (showNextTmp !== undefined) {
                    setShowNext(showNextTmp);
                  }
                  if (currentQuestionTmp !== undefined) {
                    setCurrentQuestion(currentQuestionTmp);
                  }
                  if (currentQuestionnaireDataTmp !== undefined) {
                    setCurrentQuestionnaireData(currentQuestionnaireDataTmp);
                  }
                  if (finQuestionnaireTmp !== undefined) {
                    setFinQuestionaire(finQuestionnaireTmp);
                  }
                  if (showIgnoreTmp !== undefined) {
                    setShowIgnore(showIgnoreTmp);
                  }
                  if (valueInputStateTmp !== undefined) {
                    setValueInputState(valueInputStateTmp);
                  }
                  if (showInputState !== undefined) {
                    setInputState(showInputState);
                  }
                  if (diseaseSaveTmp !== undefined) {
                    setDiseaseSave(diseaseSaveTmp);
                  }
                }
                setIgnoreQuestion(false);
                setAnswerChecked([]);
              }}
            >
              Suivant
            </Button>
            )}
      </View>
      )}

      <CustomModal
        title={'Vous êtes sur le point de valider votre questionnaire.\n'
          + 'Etes-vous sûr de vouloir continuer ?'}
        before={<></>}
        noSafeArea
        visible={finQuestionaire !== undefined}
        scrollable={false}
        onClose={() => { setFinQuestionaire(undefined); }}
        insideModalStyle={{ padding: 20 }}
        buttonText="Valider le questionnaire"
        buttonPress={async () => {
          await finaliserQuestionnaire(finQuestionaire, true);
        }}
        buttonLoadingText="Analyse du questionnaire en cours"
        secondButtonText="Modifier le questionnaire"
        secondButtonPress={() => {
          setState(undefined);
        }}
      />
      <CustomModal
        title={`Vous êtes sur le point de quitter le ${isVeto ? 'BSE' : 'Pré-bilan'}.`}
        before={<></>}
        noSafeArea
        visible={state}
        scrollable={false}
        onClose={() => { setState(false); }}
        insideModalStyle={{ padding: 20 }}
        heightPercentage={0.5}
      >
        <View>
          <Button
            size="large"
            appearance="filled"
            onPress={onPressSaveAndQuit}
            style={{ marginTop: 20, justifyContent: 'center' }}
          >
            Sauvegarder le
            {' '}
            {isVeto ? 'BSE' : 'Pré-bilan'}
            {' '}
            et quitter
          </Button>
          <Button
            size="large"
            appearance="outline"
            status="danger"
            onPress={() => {
              if (isVeto) {
                navigation.goBack();
              } else {
                navigation.reset({
                  index: 0,
                  routes: [{ name: 'bilan-sanitaire' }],
                });
              }
              setState(false);
            }}
            style={{ marginTop: 20, justifyContent: 'center' }}
          >
            <Text style={{ textAlign: 'center' }}>
              Quitter sans sauvegarder le
              {' '}
              {isVeto ? 'BSE' : 'Pré-bilan'}
            </Text>
          </Button>
          <Button size="large" appearance="ghost" onPress={() => setState(false)} style={{ marginTop: 20, justifyContent: 'center' }}>
            Annuler
          </Button>
        </View>
      </CustomModal>
    </>
  );
};

export default ListPrebilanBilan;
