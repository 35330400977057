import { useMutation, useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { WatchQueryFetchPolicy } from 'apollo-client/core/watchQueryOptions';
import { DocumentNode } from 'apollo-link';
import { createVetOffice, updateVetOffice } from '../graphql/mutations';
import {
  Address,
  CreateVetOfficeMutation,
  CreateVetOfficeMutationVariables, GetVetOfficeQueryVariables,
  ListVetOfficeByLockedByCreationDateQuery,
  ListVetOfficeByLockedByCreationDateQueryVariables,
  ListVetOfficesQueryVariables,
  LockedStatus,
  ModelSortDirection,
  UpdateVetOfficeMutation,
  UpdateVetOfficeMutationVariables,
} from '../API';
import { useUser } from './UserContext';
import {
  listVetOfficeByLockedByCreationDate,
} from '../graphql/queries';

export function useCreateVetOfficeMutation() {
  const [createNewVetOffice] = useMutation<
  CreateVetOfficeMutation,
  CreateVetOfficeMutationVariables
  >(gql(createVetOffice));
  return { createNewVetOffice };
}

type ListVetOfficesQuery = {
  listVetOffices?: {
    __typename: 'ModelVetOfficeConnection',
    items?: Array< {
      __typename: 'VetOffice',
      id: string,
      companyName: string,
      emailOffice: string,
      admins: Array< string >,
      personnel?: Array< string > | null,
      phoneVetOffice?: number | null,
      address?: {
        __typename: 'Address',
        address: string,
        additionalAddress?: string | null,
        postalCode: string,
        city: string,
      } | null,
      nameVetSanitaire?: string | null,
      nOrdreCabinet: string,
      nSIRET: string,
      maxPriceTeleConsultation: number,
      maxPriceConsultation: number,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

const listVetOfficeQuery = <DocumentNode>gql(`
  query ListVetOffices(
    $filter: ModelVetOfficeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVetOffices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyName
        emailOffice
        admins
        personnel
        phoneVetOffice
        nameVetSanitaire
        nOrdreCabinet
        nSIRET
        address {
          address
          additionalAddress
          postalCode
          city
        }
        maxPriceTeleConsultation
        maxPriceConsultation
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        mpUserId
        mpWalletId
        mpBankId
      }
      nextToken
      startedAt
    }
  }
`);
export function useVetOfficeList(fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network') {
  const { loading, data } = useQuery<ListVetOfficesQuery, ListVetOfficesQueryVariables>(
    listVetOfficeQuery, { fetchPolicy },
  );

  // console.log('data useVetOfficeList', data);
  if (data && data.listVetOffices && data.listVetOffices.items) {
    return { loading, vetOffice: data.listVetOffices.items[0] };
  }
  return { loading, vetOffice: null };
}

type VetOfficeByEmailOfficeQuery = {
  listBreedings?: {
    __typename: 'ModelBreedingConnection',
    items?: Array< {
      __typename: 'Breeding',
      vetOffice: {
        __typename: 'VetOffice',
        id: string,
        companyName: string,
        emailOffice: string,
        admins: Array<string>,
        personnel?: Array<string> | null,
        phoneVetOffice?: number | null,
        address?: {
          __typename: 'Address',
          address: string,
          additionalAddress?: string | null,
          postalCode: string,
          city: string,
        } | null,
        nameVetSanitaire?: string | null,
        nOrdreCabinet: string,
        nSIRET: string,
        maxPriceTeleConsultation: number,
        maxPriceConsultation: number,
        mpUserId?: string | null,
        mpWalletId?: string | null,
        mpBankId?: string | null,

        users?: {
          __typename: 'ModelUserConnection',

          items?: Array<{
            __typename: 'User',
            id: string,
            lastname?: string | null,
            firstname?: string | null,
            avatarUri?: string | null,
            userGroup?: string | null,
            optIn?: boolean | null,
            inscriptionNumberOrdreVeto?: string | null,
            breedingId?: string | null,
            vetOfficeId?: string | null,
            email?: string | null,
            expoToken?: Array<string> | null,
            notificationLastSeenAt?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null> | null,

          nextToken?: string | null,
          startedAt?: number | null,
        } | null,

        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

type VetOfficeByEmailOfficeVetoQuery = {
  listVetOffices?: {
    __typename: 'ModelVetOfficeConnection',
    items?: Array< {
      __typename: 'VetOffice',
      id: string,
      companyName: string,
      emailOffice: string,
      admins: Array< string >,
      personnel?: Array< string > | null,
      phoneVetOffice?: number | null,
      address?: {
        __typename: 'Address',
        address: string,
        additionalAddress?: string | null,
        postalCode: string,
        city: string,
      } | null,
      nameVetSanitaire?: string | null,
      nOrdreCabinet: string,
      nSIRET: string,
      maxPriceTeleConsultation: number,
      maxPriceConsultation: number,
      mpBankId?: string | null,
      mpWalletId?: string | null,
      mpUserId?: string | null,

      users?: {
        __typename: 'ModelUserConnection',

        items?: Array< {
          __typename: 'User',
          id: string,
          lastname?: string | null,
          firstname?: string | null,
          avatarUri?: string | null,
          userGroup?: string | null,
          optIn?: boolean | null,
          inscriptionNumberOrdreVeto?: string | null,
          breedingId?: string | null,
          vetOfficeId?: string | null,
          email?: string | null,
          expoToken?: Array< string > | null,
          notificationLastSeenAt?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,

        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,

      billingHistories?: {
        __typename: 'ModelBillingHistoryConnection',
        items: Array< {
          __typename: 'BillingHistory',
          id: string,
          vetOfficeId?: string | null,
          vetOffice?: {
            companyName: string,
            address?: Address | null,
          },
          breedingId?: string | null,
          createdAt: string,
          invoiceNumber?: number | null,
          amount: number,
          label: string,
          paid?: boolean | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};
export function useGetVetOffice(fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network') {
  const { isVeto } = useUser();
  if (isVeto) {
    const getVetOfficeByEmailOffice = <DocumentNode>gql(`
      query listVetOffice {
        listVetOffices {
          items {
            id
            companyName
            emailOffice
            admins
            personnel
            phoneVetOffice
            nameVetSanitaire
            nOrdreCabinet
            address {
              address
              additionalAddress
              postalCode
              city
            }
            nSIRET
            maxPriceTeleConsultation
            maxPriceConsultation
            mpBankId
            mpWalletId
            mpUserId
            
            users {
              items {
                email
                avatarUri
                expoToken
                firstname
                id
                lastname
                userGroup
                updatedAt
                createdAt
              }
              nextToken
              startedAt
            }
            billingHistories (sortDirection: DESC, limit: 1000) {
              items {
                id
                vetOfficeId
                vetOffice {
                  companyName
                  address {
                    address
                    additionalAddress
                    postalCode
                    city
                  }
                }
                breedingId
                createdAt
                invoiceNumber
                amount
                label
                paid
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    `);
    // console.log('emailOffice', emailOffice);

    const {
      loading,
      data,
      refetch,
    } = useQuery<VetOfficeByEmailOfficeVetoQuery>(getVetOfficeByEmailOffice, {
      fetchPolicy,
    });

    // console.log('data getVetOfficeByEmailOffice', data);
    if (data && data.listVetOffices && data.listVetOffices.items) {
      return {
        loading,
        vetOfficeData: data?.listVetOffices?.items[0],
        refetchVetOffice: refetch,
      };
    }
  } else {
    const getVetOfficeByEmailOffice = <DocumentNode>gql(`
      query listVetOffice {
        listBreedings {
          items {
            vetOffice {
              id
              companyName
              emailOffice
              admins
              personnel
              phoneVetOffice
              nameVetSanitaire
              nOrdreCabinet
              address {
                address
                additionalAddress
                postalCode
                city
              }
              nSIRET
              maxPriceTeleConsultation
              maxPriceConsultation
              
              users {
                items {
                  email
                  avatarUri
                  expoToken
                  firstname
                  id
                  lastname
                  userGroup
                  updatedAt
                  createdAt
                }
                nextToken
                startedAt
              }
              
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
    `);
    // console.log('emailOffice', emailOffice);

    const {
      loading,
      data,
      refetch,
    } = useQuery<VetOfficeByEmailOfficeQuery>(getVetOfficeByEmailOffice, {
      fetchPolicy,
    });

    // console.log('data getVetOfficeByEmailOffice', data);
    if (data && data.listBreedings && data.listBreedings.items) {
      return {
        loading,
        vetOfficeData: data?.listBreedings?.items[0]?.vetOffice,
        refetchVetOffice: refetch,
      };
    }
  }

  return { loading: false, vetOfficeData: null };
}

type GetVetOfficeAdminQuery = {
  getVetOffice?: {
    __typename: 'VetOffice',
    id: string,
    locked: LockedStatus,
    companyName: string,
    emailOffice: string,
    admins: Array< string >,
    personnel?: Array< string > | null,
    phoneVetOffice?: string | null,
    isFree?: boolean | null,
    address?: {
      __typename: 'Address',
      address: string,
      additionalAddress?: string | null,
      postalCode: string,
      city: string,
    } | null,
    nameVetSanitaire?: string | null,
    nOrdreCabinet: string,
    nSIRET: string,
    maxPriceTeleConsultation: number,
    maxPriceConsultation: number,
    users?: {
      __typename: 'ModelUserConnection',
      items?: Array< {
        __typename: 'User',
        id: string,
        lastname?: string | null,
        firstname?: string | null,
        avatarUri?: string | null,
        userGroup?: string | null,
        optIn?: boolean | null,
        inscriptionNumberOrdreVeto?: string | null,
        breedingId?: string | null,
        email?: string | null,
        expoToken?: Array< string > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,

    mpUserId?: string | null,
    mpWalletId?: string | null,
    mpBankId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export function useGetVetOfficeAdmin(id: string, fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network') {
  const getVetOffice = <DocumentNode>gql(`
      query GetVetOffice($id: ID!) {
        getVetOffice(id: $id) {
            id
            companyName
            emailOffice
            admins
            locked
            isFree
            personnel
            phoneVetOffice
            nameVetSanitaire
            nOrdreCabinet
            address {
              address
              additionalAddress
              postalCode
              city
            }
            nSIRET
            maxPriceTeleConsultation
            maxPriceConsultation
            mpBankId
            mpWalletId
            mpUserId
            
            users {
              items {
                email
                avatarUri
                expoToken
                firstname
                inscriptionNumberOrdreVeto
                id
                lastname
                userGroup
                updatedAt
                createdAt
              }
              nextToken
              startedAt
            }
            
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
        }
      }
    `);

  const {
    loading,
    data,
    refetch,
  } = useQuery<GetVetOfficeAdminQuery, GetVetOfficeQueryVariables>(getVetOffice, {
    fetchPolicy,
    variables: {
      id,
    },
  });

  // console.log('data getVetOfficeByEmailOffice', data);
  if (data && data.getVetOffice) {
    return {
      loading,
      vetOffice: data?.getVetOffice,
      refetch,
    };
  }

  return { loading: false, vetOffice: null, refetch: null };
}

export function useUpdateVetOfficeMutation() {
  const [updateVeterinaryOffice] = useMutation<
  UpdateVetOfficeMutation, UpdateVetOfficeMutationVariables
  >(gql(updateVetOffice));
  return { updateVeterinaryOffice };
}

export function useVetOfficeListAdmin(
  locked: LockedStatus,
  filter?: ListVetOfficeByLockedByCreationDateQueryVariables['filter'],
  fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network',
) {
  const { loading, data, fetchMore } = useQuery<
  ListVetOfficeByLockedByCreationDateQuery,
  ListVetOfficeByLockedByCreationDateQueryVariables
  >(gql(listVetOfficeByLockedByCreationDate), {
    fetchPolicy,
    variables: {
      locked,
      sortDirection: ModelSortDirection.DESC,
      filter,
      limit: 100,
    },
  });
  if (data && data.listVetOfficeByLockedByCreationDate?.items) {
    return {
      loading,
      vetOffices: data.listVetOfficeByLockedByCreationDate?.items,
      nextToken: data.listVetOfficeByLockedByCreationDate.nextToken,
      fetchMore,
    };
  }

  return {
    loading,
    vetOffices: null,
    nextToken: null,
    fetchMore,
  };
}

export const onConfirmVetOfficeMandate = /* GraphQL */ `
  subscription OnConfirmVetOfficeMandate($id: ID!) {
    onConfirmVetOfficeMandate(id: $id) {
      id
      mpMandateConfirmed
    }
  }
`;

export type OnConfirmVetOfficeMandateSubscription = {
  onConfirmVetOfficeMandate?: {
    __typename: 'Breeding',
    id: string,
    mpMandateConfirmed?: boolean | null,
  } | null,
};
