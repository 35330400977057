import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';
import { AdminElevageParamList } from '../../types';
import AdminElevageScreen from '../../screens/AdminScreens/ElevagesScreen/AdminElevageScreen';
import AdminDetailsElevageScreen
  from '../../screens/AdminScreens/ElevagesScreen/AdminDetailsElevageScreen';

const Stack = createNativeStackNavigator<AdminElevageParamList>();

export default () => (
  <Stack.Navigator
    initialRouteName="admin-elevages"
    screenOptions={{
      headerShown: false,
    }}
  >
    <Stack.Screen
      name="admin-elevages"
      component={AdminElevageScreen}
    />
    <Stack.Screen
      name="admin-details-elevage"
      component={AdminDetailsElevageScreen}
    />
  </Stack.Navigator>
);
