import React, { useState } from 'react';

import { useLinkTo } from '@react-navigation/native';
import { FlatList, View } from 'react-native';
import { Text } from '@ui-kitten/components';
import MaxWidthContainer from '../../../components/MaxWidthContainer';

import Button from '../../../components/Button';
import FCard from '../../../components/Card';
import FTextInput from '../../../components/Form/TextInput';
import { useListDiseases } from '../../../src/API/Disease';
import ActivityIndicator from '../../../components/ActivityIndicator';

export default function AdminDiseasesScreen() {
  const linkTo = useLinkTo();

  const [searchedText, setSearchedText] = useState<undefined | string>('');
  const {
    diseases, nextToken, fetchMore, loading,
  } = useListDiseases(
    'cache-and-network',
    {
      filter: searchedText ? {
        or: [
          { familleMaladie: { contains: searchedText } },
          { name: { contains: searchedText } },
        ],
      } : undefined,
      limit: 100,
    },
  );

  return (
    <MaxWidthContainer
      withScrollView="keyboardAware"
      outerViewProps={{
        style: {
          padding: 15,
        },
      }}
    >
      <Button onPress={() => { linkTo('/admin-diseases/details/new'); }}>Ajouter une maladie</Button>
      {diseases ? (
        <FlatList
          data={diseases.filter(({ _deleted }) => !_deleted)}
          keyExtractor={(item) => item?.id || 'z'}
          renderItem={
          ({ item }) => (item
            ? (
              <FCard
                onPress={() => { linkTo(`/admin-diseases/details/${item.id}`); }}
                style={{
                  marginVertical: 5, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginHorizontal: 5,
                }}
              >
                <View style={{ flexDirection: 'column', justifyContent: 'flex-start' }}>
                  <Text category="h4">{item.name}</Text>
                  <Text category="h6" appearance="hint">{item.familleMaladie}</Text>
                </View>
              </FCard>
            ) : null
          )
        }
          ListHeaderComponent={(
            <>
              <FTextInput
                name="search"
                placeholder="Rechercher une monographie"
                value={searchedText}
                style={{ marginVertical: 15, marginHorizontal: 5, marginBottom: 10 }}
                    // eslint-disable-next-line no-underscore-dangle
                onChangeValue={(enteredValue) => {
                  setSearchedText(enteredValue as string);
                }}
                icon="search-outline"
              />
            </>
        )}
          ListFooterComponent={nextToken ? (
            <Button
              disabled={loading}
              loading={loading}
              onPress={() => {
                fetchMore({
                  variables: { nextToken },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;
                    return {
                      ...prev,
                      listDiseases: {
                        ...prev.listDiseases,
                        ...fetchMoreResult.listDiseases,
                        items: [
                          ...prev.listDiseases?.items,
                          ...fetchMoreResult.listDiseases?.items,
                        ],
                      },
                    };
                  },
                });
              }}
            >
              Charger plus de monographies
            </Button>
          ) : null}
        />
      ) : <ActivityIndicator />}
    </MaxWidthContainer>
  );
}
