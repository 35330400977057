/**
 * Drawer navigation
 *
 * @author: Shynggys UMBETOV
 */

import React, { useContext } from 'react';
import { createDrawerNavigator } from '@react-navigation/drawer';

import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useNavigationState } from '@react-navigation/native';

import { useTheme } from '@ui-kitten/components';
// import HeaderLogo from '../components/Header/HeaderLogo';
import HeaderBurger from '../components/Header/HeaderBurger';
import HeaderBack from '../components/Header/HeaderBack';

import { getStackInfos, getTitleFromName } from './Utils';

import FaqScreen from '../screens/FaqScreen/FaqScreen';

import VetCustomDrawer from './VetCustomDrawer';
import VetoMonCabinetStackNavigator from './VetNavigators/VetoMonCabinetStackNavigator';
import VetoBottomTabNavigator from './VetBottomTabNavigator';
import VetoNotificationStackNavigator from './VetNavigators/VetoNotificationStackNavigator';
import { GlobalStateContext } from '../utils/GlobalStateContext';

const Drawer = createDrawerNavigator();

const VetMainDrawerNavigator = () => {
  const insets = useSafeAreaInsets();
  const theme = useTheme();
  const navigationState = useNavigationState((state) => state);

  const DrawerPress = (showBack: boolean, currentRouteName : string, focusedRoute?) => {
    const { setState } = useContext(GlobalStateContext);

    let onPress;
    if ((currentRouteName === 'bse-questionnaire' && focusedRoute.params.allCheck !== 'true')) {
      onPress = () => {
        setState(true);
      };
    }

    return showBack ? <HeaderBack onPress={onPress} /> : <HeaderBurger />;
  };

  return (
    <Drawer.Navigator
      drawerContent={(props) => <VetCustomDrawer {...props} />}
      initialRouteName="TableauDeBordDrawer"
      screenOptions={() => {
        const { showBack, currentRouteName, focusedRoute } = getStackInfos(navigationState);
        let title = getTitleFromName(currentRouteName);
        if (currentRouteName === 'bse-questionnaire') {
          title = 'Quitter le BSE';
        }
        return {
          title,
          headerLeft: () => DrawerPress(showBack, currentRouteName, focusedRoute),
          /**
          headerRight: () => (
            <HeaderLogo />
          ),
          */
          headerStyle: {
            height: 70 + insets.top,
            backgroundColor: theme['color-primary-500'],
          },
          headerShown: true,
          headerTintColor: '#fff',
          headerTitleStyle: {
            fontFamily: 'sourceSansPro_Bold',
          },
        };
      }}
    >
      <Drawer.Screen
        name="veto-bottom-tab-nav"
        component={VetoBottomTabNavigator}
      />
      <Drawer.Screen
        name="veto-mon-cabinet-nav"
        component={VetoMonCabinetStackNavigator}
      />
      <Drawer.Screen
        name="veto-notifications-nav"
        component={VetoNotificationStackNavigator}
      />
      <Drawer.Screen
        name="faq"
        component={FaqScreen}
      />

    </Drawer.Navigator>
  );
};

export default VetMainDrawerNavigator;

// const styles = StyleSheet.create({});
