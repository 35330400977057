import {
  StackActions, useLinkTo, useNavigation, useRoute,
} from '@react-navigation/native';
import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { Text, useTheme } from '@ui-kitten/components';
import { Platform, TouchableOpacity, View } from 'react-native';
import { Icon as IconUIKitten } from '@ui-kitten/components/ui/icon/icon.component';
import { RouteProp } from '@react-navigation/core/lib/typescript/src/types';
import MaxWidthContainer from '../../components/MaxWidthContainer';

import Form from '../../components/Form/Form';
import CompButton from '../../components/Button';
import { useUser } from '../../src/API/UserContext';
import { useGetBuilding } from '../../src/API/Buildings';
import FTextInput from '../../components/Form/TextInput';

import { AjoutBatimentForm, TabElevageParamList } from '../../types';
import { Buildings } from '../../src/API';
import CrudBL from '../../screenBusinessLogic/ElevageScreen/CrudBL';
import { AvailableValidationRules } from '../../components/Form/validation';
import CustomModal from '../../components/Modals/CustomModal';
import InfoCard from '../../screenComponents/InfoCard';

const AjoutBatimentScreen = () => {
  const { user } = useUser();
  const linkTo = useLinkTo();
  const theme = useTheme();
  const navigation = useNavigation();

  const [nbZones, setNbZones] = useState<Array<{ id: string | null | undefined, nom: string | null | undefined, num: string | null | undefined }>>([{ id: 'new1', nom: '', num: '' }]);
  const [nbNewZones, setNbNewZones] = useState(1);
  const [processing, setProcessing] = useState(false);
  const [zonesToDelete, setZonesToDelete] = useState<{ id: string }[]>([]);
  // console.log('nbZones', nbZones);

  const onDecreaseZone = (index : string) => {
    const newZones = nbZones.filter((item) => item.id !== index);
    if (index.indexOf('new') <= -1) {
      setZonesToDelete((prevState) => [...prevState, { id: index }]);
    }
    // console.log(newZones);
    // console.log('onDecreaseZone', newZones);
    setNbZones([...newZones]);
  };

  // console.log('nombre de zone : ', nbZones);
  // const existingZone : Array<{ id: string, nom: string, num: string }>;
  const renderZoneTextInputs = () => nbZones.map((item, i) => {
    const { id } = item;
    return (
      <>
        <View
          key={id}
          style={{
            flexDirection: 'row', justifyContent: 'space-between', marginTop: 40, marginBottom: 20,
          }}
        >
          <Text category="h4">{`Zone n° ${i + 1}`}</Text>
          <TouchableOpacity onPress={() => onDecreaseZone(id)}>
            <IconUIKitten name="trash-outline" fill="#000000" style={{ height: 20, width: 20 }} />
          </TouchableOpacity>
        </View>
        <FTextInput
          title="Nom de la zone"
          name={`zone.items.${id}.name`}
          placeholder="Nom de la zone"
          onChangeValue={(text) => setCurrentBuilding((prevState) => ({
            ...prevState,
            zone: {
              items: {
                ...(prevState?.zone?.items || {}),
                [id]: {
                  ...(prevState?.zone?.items[id] || {}),
                  name: text,
                },
              },
            },
          }))}
        />
        <FTextInput
          title="Numéro d'identification"
          name={`zone.items.${id}.numberZone`}
          placeholder="Numéro de la zone"
          onChangeValue={(text) => setCurrentBuilding((prevState) => ({
            ...prevState,
            zone: {
              items: {
                ...(prevState?.zone?.items || {}),
                [id]: {
                  ...(prevState?.zone?.items[id] || {}),
                  numberZone: text,
                },
              },
            },
          }))}
          containerStyle={{
            marginTop: 10, paddingBottom: 20, borderBottomWidth: 1, borderBottomColor: '#b5b5b5',
          }}
        />
      </>
    );
  });
  // console.log('render', nbZones.length);

  /**           Modify Batiment           */
  const route = useRoute<RouteProp<TabElevageParamList, 'modify-batiment'>>();
  // console.log('route modify batiment', route);

  /**           Create building            */
  const { addBuilding, updateBatiment } = CrudBL.useAjoutBatiment();

  const [currentBuilding, setCurrentBuilding] = useState<Buildings | undefined>(undefined);
  if (route.params) {
    const { building } = useGetBuilding(route?.params?.id);
    /**         Zone management     */
    useEffect(() => {
      if (building && building.zone && building.zone.items) {
        const zoneOfThisBuildingListLocal = building.zone.items.filter((item) => item !== null && !item._deleted).map(
          (zon) => ({ id: zon?.id, nom: zon?.name, num: zon?.numberZone }),
        );
        // console.log('zoneListLocal', zoneOfThisBuildingListLocal);
        setNbZones(zoneOfThisBuildingListLocal);
        setNbNewZones(0);

        const newCurrentBuilding = building;
        newCurrentBuilding.zone.items = Object.fromEntries(building?.zone?.items?.filter((item) => item !== null && !item._deleted)
          .map((item) => [item?.id as string, item]) || []);
        setCurrentBuilding(newCurrentBuilding);
      }

      return () => {};
    }, [building]);
  }

  // for modal
  const [openDelete, setOpenDelete] = useState<{ title : string, id: string, data }>();
  /**    Create update Building from Business Logic */
  const ajoutBatimentForm = useForm<AjoutBatimentForm>();

  const onAjoutBatiment = (data: AjoutBatimentForm) => {
    setProcessing(true);
    if (route.params) {
      updateBatiment(route, { ...data, zonesToDelete }, currentBuilding).then(() => {
        setProcessing(false);
        linkTo(`/elevage/detail-batiment/${currentBuilding?.id}`);
      });
    } else if (user) {
      addBuilding(data).then((newCreatedBuilding) => {
        setProcessing(false);
        setOpenDelete({ id: newCreatedBuilding?.createBuildings?.id });
      });
    }
  };

  // console.log('openDelete onAjoutLot:', openDelete);
  const confirmButtonThenAffectToAnimal = () => {
    const id = openDelete?.id;
    setOpenDelete(undefined);
    navigation.navigate('manage-affected-animals', {
      listTitle: 'Animaux', manage: 'les animaux', whichPage: 'batiment', id,
    });
  };

  const confirmButtonThenAffectToLot = () => {
    const id = openDelete?.id;
    setOpenDelete(undefined);
    navigation.navigate('manage-affected-animals', {
      listTitle: 'Lots', manage: 'les lots', whichPage: 'batiment', id,
    });
  };

  const confirmGoDetail = () => {
    const id = openDelete?.id;
    setOpenDelete(undefined);
    linkTo(`/elevage/detail-batiment/${id}`);
  };

  return (
    <>
      <MaxWidthContainer
        withScrollView="keyboardAware"
        outerViewProps={{
          style: {
            padding: 24,
            paddingBottom: 40,
          },
        }}
      >
        <Form<AjoutBatimentForm>
          {...ajoutBatimentForm}
          defaultValues={currentBuilding}
        >
          <>
            <FTextInput
              title="Nom de bâtiment"
              name="nameBuilding"
              placeholder="Saisissez le nom du bâtiment"
              containerStyle={{ marginTop: 10 }}
              validators={[AvailableValidationRules.required]}
            />
            <FTextInput
              title="Adresse"
              name="address.address"
              placeholder="Saisissez l'adresse"
              containerStyle={{ marginTop: 10 }}
              validators={[AvailableValidationRules.required]}
            />
            <FTextInput
              title={"Complément d'adresse"}
              name="address.additionalAddress"
              placeholder="Saisissez le complément d'adresse"
              containerStyle={{ marginTop: 10 }}
            />
            <FTextInput
              title="Code postal"
              name="address.postalCode"
              maxLength={5}
              keyboardType="numeric"
              placeholder="Saisissez le code postal"
              containerStyle={{ marginTop: 10 }}
              validators={[AvailableValidationRules.required]}
            />
            <FTextInput
              title="Ville"
              name="address.city"
              placeholder="Saisissez la ville"
              containerStyle={{ marginTop: 10 }}
              validators={[AvailableValidationRules.required]}
            />

            <Text category="h5" style={{ marginTop: 20 }}>Zone(s)</Text>

            {renderZoneTextInputs()}
            <CompButton
              leftIcon="plus-outline"
              leftIconFill="#298A91"
              size="large"
              appearance="ghost"
              style={{ marginTop: 30 }}
              onPress={() => {
                const newNbNewZones = nbNewZones + 1;
                const newZones = { id: `new${newNbNewZones}` };
                setNbNewZones(newNbNewZones);
                setNbZones((prevState) => [...prevState, newZones]);
              }}
            >
              Ajouter une autre zone
            </CompButton>

            <CompButton
              style={{ marginTop: 40 }}
              loading={processing}
              onPress={ajoutBatimentForm.handleSubmit((data) => onAjoutBatiment(data))}
            >
              Enregistrer le bâtiment
            </CompButton>
            <Text category="c1" appearance="hint">
              * champs obligatoires
            </Text>
          </>
        </Form>

        <InfoCard
          title="alertInfo"
          iconShow="alert-circle-outline"
            // iconCloseShow="close-outline"
          infoStyle={{ backgroundColor: theme['color-primary-100'] }}
          description="En appuyant sur le bouton ci-dessus, le bâtiment sera créé, ainsi que les zones, s’il en contient. Vous pourrez gérer les animaux ou les lots appartenant à ce bâtiment depuis la prochaine étape et à tout moment par la suite."
          alertInfo="alertInfo"
        />

      </MaxWidthContainer>
      <CustomModal
        title={'Vous venez de créer un bâtiment.'
      + '\n'
      + 'Vous pouvez désormais affecter à ce bâtiment des animaux ou des lots enregistrés dans votre espace Élevage.'}
        before={<></>}
        noSafeArea
        visible={openDelete !== undefined}
        scrollable={false}
        onClose={() => confirmGoDetail()}
        insideModalStyle={{ textAlign: 'center', padding: 20 }}
        buttonText="Affecter des animaux à ce bâtiment"
        secondButtonText={"Je n'ai pas d'animal ou de lot à affecter pour l'instant"}
        thirdButtonText="Affecter des lots à ce bâtiment"
        heightPercentage={Platform.OS === 'web' ? 0.50 : 0.6}
        buttonPress={() => confirmButtonThenAffectToAnimal(openDelete)}
        thirdButtonPress={() => { confirmButtonThenAffectToLot(openDelete); }}
      />
    </>
  );
};

export default AjoutBatimentScreen;
