/**
 * Drawer navigation
 *
 * @author: Shynggys UMBETOV
 */

import React from 'react';
import { createDrawerNavigator } from '@react-navigation/drawer';

import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useNavigationState } from '@react-navigation/native';

import { useTheme } from '@ui-kitten/components';
import HeaderBurger from '../components/Header/HeaderBurger';
import HeaderBack from '../components/Header/HeaderBack';

import { getStackInfos, getTitleFromName } from './Utils';

import AdminCustomDrawer from './AdminCustomDrawer';
import AdminTableauDeBordScreen
  from '../screens/AdminScreens/TableauDeBordScreen/AdminTableauDeBordScreen';
import AdminFacturesScreen from '../screens/AdminScreens/AdminFacturesScreen';
import AdminElevageStackNavigator from './AdminNavigators/AdminElevageStackNavigator';
import AdminVetoStackNavigator from './AdminNavigators/AdminVetoStackNavigator';
import AdminDiseaseStackNavigator from './AdminNavigators/AdminDiseaseStackNavigator';
import AdminProtocolSoinsStackNavigator from './AdminNavigators/AdminProtocolSoinsStackNavigator';

const Drawer = createDrawerNavigator();

const AdminMainDrawerNavigator = () => {
  const insets = useSafeAreaInsets();
  const theme = useTheme();
  const navigationState = useNavigationState((state) => state);

  const DrawerPress = (showBack: boolean) => (showBack ? <HeaderBack /> : <HeaderBurger />);

  return (
    <Drawer.Navigator
      drawerContent={(props) => <AdminCustomDrawer {...props} />}
      initialRouteName="TableauDeBordDrawer"
      screenOptions={() => {
        const { showBack, currentRouteName } = getStackInfos(navigationState);
        const title = getTitleFromName(currentRouteName);
        return {
          title,
          headerLeft: () => DrawerPress(showBack),
          headerStyle: {
            height: 70 + insets.top,
            backgroundColor: theme['color-primary-500'],
          },
          headerShown: true,
          headerTintColor: '#fff',
          headerTitleStyle: {
            fontFamily: 'sourceSansPro_Bold',
          },
        };
      }}
    >
      <Drawer.Screen
        name="admin-tableau-de-bord"
        component={AdminTableauDeBordScreen}
      />
      <Drawer.Screen
        name="admin-elevages-nav"
        component={AdminElevageStackNavigator}
      />
      <Drawer.Screen
        name="admin-vetos-nav"
        component={AdminVetoStackNavigator}
      />
      <Drawer.Screen
        name="admin-diseases-nav"
        component={AdminDiseaseStackNavigator}
      />
      <Drawer.Screen
        name="admin-ps-nav"
        component={AdminProtocolSoinsStackNavigator}
      />
      <Drawer.Screen
        name="admin-factures"
        component={AdminFacturesScreen}
      />

    </Drawer.Navigator>
  );
};

export default AdminMainDrawerNavigator;
