import { useMutation, useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { DocumentNode } from 'apollo-link';
import { WatchQueryFetchPolicy } from 'apollo-client/core/watchQueryOptions';
import * as mutations from '../graphql/mutations';
import { deleteConsultation, updateConsultation } from '../graphql/mutations';
import {
  Breeding,
  Buildings,
  Consultation,
  CreateConsultationMutation,
  CreateConsultationMutationVariables,
  DeleteConsultationMutation,
  DeleteConsultationMutationVariables,
  Document,
  GenderType,
  GetBreedingQueryVariables,
  GetConsultationQuery,
  GetConsultationQueryVariables,
  ListConsultationsByBreedingQueryVariables,
  ListConsultationsQueryVariables,
  Lots,
  ModelConsultationConnection,
  UpdateConsultationMutation,
  UpdateConsultationMutationVariables,
  Zones,
} from '../API';

export function useCreateConsultationMutation() {
  const [createConsultation] = useMutation<
  CreateConsultationMutation, CreateConsultationMutationVariables
  >(gql(mutations.createConsultation));
  return { createConsultation };
}

export function useGetConsultation(id: string, fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network') {
  const consultationQuery = <DocumentNode>gql(`
  query GetConsultation($id: ID!) {
    getConsultation(id: $id) {
      id
      breedingId
      animalId
      diseaseId
      isDone
      diseaseIsVerified
      lotId
      diseaseId
      shared
      sharedAt
      autoExam
     
      teleconsultation
      priceConsultation
      
      teleconsAskAt
      dateTeleCons
      questionResponse
      questionResponseOrder
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      breeding {
        id
        nBreeding
        nSIRET
        address {
          address
          additionalAddress
          postalCode
          city
        }
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        emailVeto
        admins
        vetOffice {
          id
          maxPriceConsultation
          maxPriceTeleConsultation
        }
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      animal {
        id
        numberID
        name
        gender
        birthDate
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        
        lots {
          id
          numberLot
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        building {
          id
          nameBuilding
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        zone {
          id
          name
          numberZone
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      lot {
        id
        numberLot
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        building {
          id
          nameBuilding
          breedingId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      
      sharedFacture
      sharedFactureAt
      factureId
      facture {
        id
        breedingId
        name
        key
        s3file
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      
      disease {
        id
        codeIdentification
        name
        familleMaladie
        familleMaladieId
        MRLC
        maladieUrgente
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`);

  if (!id) {
    return { loading: false, consultation: undefined };
  }
  const {
    loading, data, refetch, error,
  } = useQuery<
  GetConsultationQuery, GetConsultationQueryVariables
  >(consultationQuery, {
    fetchPolicy,
    variables: {
      id,
    },
  });
  console.log(data, error);
  return {
    loading, consultation: <Consultation>data?.getConsultation, refetch,
  };
}

/** useConsultationList by BREEDING that is filtered.  Can see in consultationQuery  */
export function useConsultationList(fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network') {
  const consultationQuery = <DocumentNode>gql(`
  query ListConsultations {
    listBreedings {
      items {
        consultations(sortDirection: DESC, limit: 1000) {
          items {
            id
            breedingId
            animalId
            lotId
            shared
            autoExam
            diseaseIsVerified
            teleconsultation
            priceConsultation
            factureId
            
            dateTeleCons
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            
            lot {
              id
              numberLot
              name
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            
            animal {
              id
              numberID
              name
              gender
              birthDate
              breedingId
              lotsId
              lots {
                id
                numberLot
                name
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
              }
              zoneId
              buildingId
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            
            priceConsultation
            sharedFacture
            sharedFactureAt
            factureId
            facture {
              id
              breedingId
              name
              key
              s3file
              createdAt
              _version
              _deleted
              _lastChangedAt
              updatedAt
            }
            
          }
        }
      }
      nextToken
      startedAt
    }
  }
`);

  type ListConsultationsQuery = {
    listBreedings?: {
      __typename: 'ModelBreedingConnection',
      items: Array< {
        consultations?: {
          __typename: 'ModelConsultationConnection',
          items?: Array< {
            __typename: 'Consultation',
            id: string,
            breedingId: string,
            breeding?: Breeding | null,
            animalId?: string | null,
            lotId?: string | null,
            lot?: {
              __typename: 'Lots',
              id: string,
              numberLot: string,
              name?: string | null,
              _version: number,
              _deleted?: boolean | null,
              _lastChangedAt: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            shared?: boolean | null,
            autoExam?: boolean | null,
            teleconsultation?: boolean | null,
            dateTeleCons?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
            animal?: {
              __typename: 'ModelAnimalsConnection',
              items?: Array<{
                __typename: 'Animals',
                id: string,
                numberID: string,
                name?: string | null,
                gender?: GenderType | null,
                birthDate?: string | null,
                breedingId: string,
                lotsId?: string | null,
                zoneId?: string | null,
                buildingId?: string | null,
                _version: number,
                _deleted?: boolean | null,
                _lastChangedAt: number,
                createdAt: string,
                updatedAt: string,
                breeding?: Breeding | null,
                lots?: Lots | null,
                building?: Buildings | null,
                zone?: Zones | null,
                consultations?: ModelConsultationConnection | null,
              } | null > | null,
              nextToken?: string | null,
              startedAt?: number | null,
            } | null,

            priceConsultation?: number | null,
            factureId?: string | null,
            facture?: Document | null,
            sharedFacture?: boolean | null,
            sharedFactureAt?: string | null,
            teleconsAskAt?: string | null,

          } | null > | null,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
      }>,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  };

  const {
    loading, data, refetch,
  } = useQuery<
  ListConsultationsQuery, ListConsultationsQueryVariables
  >(consultationQuery, { fetchPolicy });

  // console.log('useConsultationList dataaaaaaaaaaaaa:', data);
  if (data && data.listBreedings && data.listBreedings.items && data.listBreedings.items[0]) {
    let consultationS = [];
    for (let i = 0; i < data.listBreedings.items.length; i++) {
      if (data?.listBreedings?.items[i].consultations && data?.listBreedings?.items[i].consultations?.items) {
        consultationS = consultationS.concat(data?.listBreedings?.items[0].consultations?.items);
      }
    }

    consultationS.sort((a, b) => {
      if (a.createdAt && b.createdAt) {
        return -a.createdAt.localeCompare(b.createdAt);
      }
      if (b.createdAt) {
        return 1;
      }
      if (a.createdAt) {
        return -1;
      }
      return 0;
    });

    // console.log('data:', data);
    return {
      loadingConsultation: loading,
      consultationS,
      refetch,
    };
  }
  return { loadingConsultation: loading, consultationS: null };
}

/** useConsultationList by BREEDING that is filtered.  Can see in consultationQuery  */
export function useConsultationListVeto(fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network') {
  const consultationQuery = <DocumentNode>gql(`
  query ListConsultations {
    listVetOffices {  
      items {
        breedings (limit: 1000) {
          items {
            consultations(sortDirection: DESC, limit: 1000) {
              items {
                id
                breedingId
                animalId
                lotId
                shared
                autoExam
                diseaseIsVerified
                teleconsultation
                priceConsultation
                factureId
               
                dateTeleCons
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                
                lot {
                  id
                  numberLot
                  name
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                
                animal {
                  id
                  numberID
                  name
                  gender
                  birthDate
                  breedingId
                  lotsId
                  lots {
                    id
                    numberLot
                    name
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                  }
                  zoneId
                  buildingId
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                
                priceConsultation
                sharedFacture
                sharedFactureAt
                factureId
                facture {
                  id
                  breedingId
                  name
                  key
                  s3file
                  createdAt
                  _version
                  _deleted
                  _lastChangedAt
                  updatedAt
                }
                
              }
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`);

  type ListConsultationsQuery = {
    listVetOffices?: {
      __typename: 'ModelVetOfficeConnection',
      items: Array< {
        __typename: 'VetOffice',
        breedings?: {
          __typename: 'ModelBreedingConnection',
          items: Array< {
            consultations?: {
              __typename: 'ModelConsultationConnection',
              items?: Array< {
                __typename: 'Consultation',
                id: string,
                breedingId: string,
                breeding?: Breeding | null,
                animalId?: string | null,
                lotId?: string | null,
                lot?: {
                  __typename: 'Lots',
                  id: string,
                  numberLot: string,
                  name?: string | null,
                  _version: number,
                  _deleted?: boolean | null,
                  _lastChangedAt: number,
                  createdAt: string,
                  updatedAt: string,
                } | null,
                shared?: boolean | null,
                autoExam?: boolean | null,
                teleconsultation?: boolean | null,
                dateTeleCons?: string | null,
                _version: number,
                _deleted?: boolean | null,
                _lastChangedAt: number,
                createdAt: string,
                updatedAt: string,
                animal?: {
                  __typename: 'ModelAnimalsConnection',
                  items?: Array<{
                    __typename: 'Animals',
                    id: string,
                    numberID: string,
                    name?: string | null,
                    gender?: GenderType | null,
                    birthDate?: string | null,
                    breedingId: string,
                    lotsId?: string | null,
                    zoneId?: string | null,
                    buildingId?: string | null,
                    _version: number,
                    _deleted?: boolean | null,
                    _lastChangedAt: number,
                    createdAt: string,
                    updatedAt: string,
                    breeding?: Breeding | null,
                    lots?: Lots | null,
                    building?: Buildings | null,
                    zone?: Zones | null,
                    consultations?: ModelConsultationConnection | null,
                  } | null > | null,
                  nextToken?: string | null,
                  startedAt?: number | null,
                } | null,

                priceConsultation?: number | null,
                factureId?: string | null,
                facture?: Document | null,
                sharedFacture?: boolean | null,
                sharedFactureAt?: string | null,
                teleconsAskAt?: string | null,

              } | null > | null,
              nextToken?: string | null,
              startedAt?: number | null,
            } | null,
          }>,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,

      }>,
      nextToken?: string | null,
    } | null,
  };

  const {
    loading, data, refetch,
  } = useQuery<
  ListConsultationsQuery, ListConsultationsQueryVariables
  >(consultationQuery, { fetchPolicy });

  // console.log('useConsultationList dataaaaaaaaaaaaa:', data);
  if (data && data.listVetOffices && data.listVetOffices.items && data.listVetOffices.items[0]
      && data.listVetOffices.items[0].breedings && data.listVetOffices.items[0].breedings?.items) {
    let consultationS = [];
    for (let i = 0; i < data.listVetOffices.items[0].breedings?.items.length; i++) {
      if (data.listVetOffices.items[0].breedings?.items[i].consultations && data.listVetOffices.items[0].breedings?.items[i].consultations?.items) {
        consultationS = consultationS.concat(data.listVetOffices.items[0].breedings?.items[i].consultations?.items);
      }
    }

    consultationS.sort((a, b) => {
      if (a.createdAt && b.createdAt) {
        return -a.createdAt.localeCompare(b.createdAt);
      }
      if (b.createdAt) {
        return 1;
      }
      if (a.createdAt) {
        return -1;
      }
      return 0;
    });

    // console.log('data:', data);
    return {
      loadingConsultation: loading,
      consultationS,
      refetch,
    };
  }
  return { loadingConsultation: loading, consultationS: null };
}

export function useConsultationListByBreedingId(fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network', id: string) {
  type ListConsultationByBreedingIdQuery = {
    getBreeding?: {
      __typename: 'Breeding',
      id: string,
      consultations?: {
        __typename: 'ModelConsultationConnection',
        items?: Array< {
          __typename: 'Consultation',
          id: string,
          breedingId: string,
          breeding?: Breeding | null,
          animalId?: string | null,
          animal?: {
            __typename: 'ModelAnimalsConnection',
            items?: Array<{
              __typename: 'Animals',
              id: string,
              numberID: string,
              name?: string | null,
              gender?: GenderType | null,
              birthDate?: string | null,
              breedingId: string,
              lotsId?: string | null,
              zoneId?: string | null,
              buildingId?: string | null,
              _version: number,
              _deleted?: boolean | null,
              _lastChangedAt: number,
              createdAt: string,
              updatedAt: string,
              breeding?: Breeding | null,
              lots?: Lots | null,
              building?: Buildings | null,
              zone?: Zones | null,
              consultations?: ModelConsultationConnection | null,
            } | null > | null,
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          lotId?: string | null,
          lot?: {
            __typename: 'Lots',
            id: string,
            numberLot: string,
            name?: string | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          shared?: boolean | null,
          autoExam?: boolean | null,
          teleconsultation?: boolean | null,
          dateTeleCons?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  };

  const listConsultationByBreedingIdQuery = <DocumentNode>gql(`
   query GetBreedingConsutation($id: ID!) {
    getBreeding(id: $id) {
      id
      consultations(sortDirection: DESC, limit: 1000) {
        items {
          id
          breedingId
          breeding {
            id
            companyName
            nBreeding
          }
          animalId
          animal {
              id
              numberID
              name
              gender
              birthDate
              breedingId
              
              lotsId
              lots {
                id
                numberLot
                name
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
              }

              zoneId
              buildingId
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
          }
          lotId
          lot {
            id
            numberLot
            name
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          shared
          autoExam
          teleconsultation
          priceConsultation
          factureId
          
          dateTeleCons
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`);

  const { loading, data } = useQuery<
  ListConsultationByBreedingIdQuery, GetBreedingQueryVariables
  >(listConsultationByBreedingIdQuery, {
    variables: {
      id,
    },
    fetchPolicy,
  });

  // console.log('useConsultationList dataaaaaaaaaaaaa:', data);
  if (data) {
    // console.log('data:', data);
    return {
      loadingConsultation: loading,
      consultationS: data?.getBreeding?.consultations?.items,
    };
  }
  return { loadingConsultation: loading, consultationS: null };
}

export function useUpdateConsultationMutation() {
  const [modifyConsultation, { loading: mutationConsultationLoading }] = useMutation<
  UpdateConsultationMutation, UpdateConsultationMutationVariables
  >(gql(updateConsultation));
  return { modifyConsultation, mutationConsultationLoading };
}

export function useDeleteConsultationMutation() {
  const [supprimerConsultation] = useMutation<
  DeleteConsultationMutation, DeleteConsultationMutationVariables
  >(gql(deleteConsultation));
  return { supprimerConsultation };
}

export function useListConsultationsByBreedinG(
  limit: number,
  filter: { sharedFacture: { eq: boolean } } | null,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network',
  breeding?: Breeding,
  breedingId?: string,
) {
  if ((breeding && breeding.id) || breedingId) {
    if (breeding && breeding.id) {
      breedingId = breeding.id;
    }
    type ListConsultationsByBreedingQuery = {
      listConsultationsByBreeding?: {
        __typename: 'ModelConsultationConnection',
        items?: Array< {
          __typename: 'Consultation',
          id: string,
          breedingId: string,
          animalId?: string | null,
          lotId?: string | null,
          diseaseId?: string | null,
          shared?: boolean | null,
          sharedAt?: string | null,
          autoExam?: boolean | null,
          isDone?: boolean | null,
          teleconsultation?: boolean | null,
          priceConsultation?: number | null,
          factureId?: string | null,

          facture?: Document | null,

          sharedFacture?: boolean | null,
          sharedFactureAt?: string | null,
          teleconsAskAt?: string | null,
          dateTeleCons?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    };

    const listConsultationsByBreedingQuery = <DocumentNode>gql(`
  query ListConsultationsByBreeding(
    $breedingId: ID!
    $filter: ModelConsultationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConsultationsByBreeding(
      breedingId: $breedingId
      sortDirection: DESC
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        breedingId
        animalId
        lotId
        diseaseId
        shared
        isDone
        sharedAt
        autoExam
        teleconsultation
        priceConsultation
        factureId
        
        facture {
          id
          breedingId
          name
          key
          s3file
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        
        lot {
          id
          numberLot
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        
        animal {
          id
          numberID
          name
          gender
          birthDate
          breedingId
          lotsId
          lots {
            id
            numberLot
            name
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          zoneId
          buildingId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        
        sharedFacture
        sharedFactureAt
        teleconsAskAt
        dateTeleCons
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`);
    const {
      loading, data, refetch, fetchMore,
    } = useQuery<
    ListConsultationsByBreedingQuery, ListConsultationsByBreedingQueryVariables
    >(listConsultationsByBreedingQuery, {
      variables: {
        filter,
        limit,
        // sortDirection: ModelSortDirection.DESC,
        breedingId,
      },
      fetchPolicy,
    });
    if (data && data.listConsultationsByBreeding && data.listConsultationsByBreeding.items) {
      return {
        loadingConsultations: loading,
        consultationS: data.listConsultationsByBreeding?.items,
        nextToken: data?.listConsultationsByBreeding?.nextToken,
        startedAt: data?.listConsultationsByBreeding?.startedAt,
        fetchMoreConsultations: () => (fetchMore({
          variables: {
            nextToken: data?.listConsultationsByBreeding?.nextToken,
          },
          updateQuery: (previousQueryResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return previousQueryResult;
            return {
              ...previousQueryResult,
              listConsultationsByBreeding: {
                ...previousQueryResult.listConsultationsByBreeding,
                items: [
                  ...previousQueryResult.listConsultationsByBreeding?.items,
                  ...fetchMoreResult.listConsultationsByBreeding?.items,
                ],
                nextToken: fetchMoreResult.listConsultationsByBreeding?.nextToken,
                startedAt: fetchMoreResult.listConsultationsByBreeding?.startedAt,
              },
            };
          },
        })),

        refetch,
      };
    }
    return { loadingConsultations: loading, consultationS: null };
  }
  return { loadingConsultations: null, consultationS: null };
}
