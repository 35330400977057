import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { TabPhotoParamList } from '../../types';

import PhotoScreen from '../../screens/PhotoScreen/PhotoScreen';

/** Notification Section */
const Stack = createStackNavigator<TabPhotoParamList>();

export default () => (
  <Stack.Navigator
    initialRouteName="photo"
    screenOptions={{
      headerShown: false,
    }}
  >
    <Stack.Screen
      name="photo"
      component={PhotoScreen}
    />
  </Stack.Navigator>
);
