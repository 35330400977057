import * as React from 'react';
import moment from 'moment';
import {
  StyleSheet, TouchableOpacity, KeyboardAvoidingView, View, ViewStyle, Modal,
} from 'react-native';

import { useEffect, useState } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { AnimatePresence, MotiView } from 'moti';
import { useDimensions } from '@react-native-community/hooks';
import { Text } from '@ui-kitten/components';

import Button from '../Button';
import DatepickerComp from '../Form/DatePicker';
import FTextInput from '../Form/TextInput';
import { useUpdateConsultationMutation } from '../../src/API/Consultation';
import { useUpdateBseMutation } from '../../src/API/BSE';
import { AvailableValidationRules } from '../Form/validation';

/**
function DatePickerPlaceholder({ text = 'Sélectionner la date' }: { text?: string }) {
  const theme = useTheme();
  const style = { color: theme['text-hint-color'], marginHorizontal: 8 } as TextStyle;

  return (
    <Text style={style} category="p1">
      {text}
    </Text>
  );
}
*/

export type CustomModalProps = {
  title: string,
  data: { id: string, _version: number | undefined, date: string | undefined, type: 'bse' | 'teleconsultation' }
  subtitle?: string,
  buttonText?: string,
  insideModalStyle?: ViewStyle,
  children?: React.ReactNode,
  before: React.ReactNode,
  noSafeArea: boolean,
  visible: boolean,
  scrollable: boolean,
  onClose?: () => void
  buttonPress?: () => void,
  heightPercentage?: number,
  rightAction?: {
    label: string,
    onPress: () => any
  }
};

const CustomModal = (props: CustomModalProps) => {
  const {
    buttonText,
    heightPercentage = 0.7,
    title,
    data,
    // before,
    visible, onClose, insideModalStyle,
  } = props;
  const { window } = useDimensions();
  const [visibleState, setVisible] = useState(false);
  const [visibleModalState, setVisibleModal] = useState(false);

  useEffect(() => {
    if (visible !== visibleState) {
      if (visible) {
        setVisible(visible);
        setVisibleModal(visible);
      } else {
        setVisible(visible);
      }
    }
  }, [visible]);
  const insets = useSafeAreaInsets();

  const onRequestClose = () => {
    if (onClose) {
      onClose();
    } else {
      setVisible(false);
    }
  };

  const computedStyles = styles.modal;
  const computedContentStyle = {};

  const [date, setDate] = useState(data.date ? moment(data.date).format('YYYY-MM-DD') : undefined);
  const [time, setTime] = useState(data.date ? moment(data.date).format('HH:mm') : undefined);
  const { modifyConsultation } = useUpdateConsultationMutation();
  const { updateBse } = useUpdateBseMutation();

  const offset = new Date().getTimezoneOffset() / -60;
  // console.log('UTC', offset);

  const validerTelecons = async () => {
    const dateSelect = `${date}T${time}:00+0${offset}:00`;
    // console.log(time);
    // console.log(dateSelect);
    switch (data.type) {
      case 'teleconsultation':
        await modifyConsultation({
          variables: {
            input: {
              id: data.id,
              dateTeleCons: dateSelect,
              // eslint-disable-next-line no-underscore-dangle
              _version: data._version,
            },
          },
        });
        break;
      case 'bse':
        await updateBse({
          variables: {
            input: {
              id: data.id,
              dateBSE: dateSelect,
              // eslint-disable-next-line no-underscore-dangle
              _version: data._version,
            },
          },
        });
        break;
      default:
        break;
    }
    if (onClose) {
      onClose();
    }
  };
  return (
    <Modal
      onRequestClose={() => onRequestClose()}
      animationType="none"
      transparent
      statusBarTranslucent
      visible={visibleModalState}
      style={{
        margin: 0,
        ...StyleSheet.absoluteFillObject,
        height: window.height,
        paddingTop: insets.top,
      }}
    >
      {/**
             before
             */}
      <AnimatePresence onExitComplete={() => setVisibleModal(false)}>
        {visibleState && (
        <View style={computedStyles}>
          <MotiView
            style={{
              ...StyleSheet.absoluteFillObject,
              backgroundColor: '#000000',
              flex: 1,
            }}
            from={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            transition={{
              type: 'timing',
              duration,
            }}
          >
            <TouchableOpacity style={styles.exit} onPress={() => onRequestClose()} />
          </MotiView>
          <MotiView
            style={{
              paddingBottom: insets.bottom,
              maxWidth: 780,
              width: '100%',
              flex: 1,
              maxHeight: window.height * heightPercentage,
              height: window.height * heightPercentage,
            }}
            from={{ opacity: 0, translateY: window.height }}
            animate={{ opacity: 1, translateY: 0 }}
            exit={{ opacity: 0, translateY: window.height }}
            transition={{
              type: 'timing',
              duration,
            }}
          >
            <KeyboardAvoidingView behavior="height" style={{ flex: 1 }}>
              <View style={[styles.content, computedContentStyle, insideModalStyle]}>
                <Text category="h1" style={{ textAlign: 'center', marginTop: 20 }}>{title}</Text>

                <View style={{ flexDirection: 'row', marginTop: 20 }}>
                  <View style={{ flexDirection: 'column', flex: 1, marginRight: 20 }}>
                    <Text category="h4" appearance="hint">Choisissez la date</Text>
                    <View style={{ height: 60 }}>
                      <DatepickerComp
                        name="date"
                        placeholder="Sélectionner la date"
                        onChangeValue={(d) => setDate(d)}
                        validators={[
                          AvailableValidationRules.required,
                        ]}
                        defaultValue={data.date ? moment(data.date).format('YYYY-MM-DD') : undefined}
                      />
                    </View>
                    <Text category="p2">Example : 01/01/2021</Text>
                  </View>
                  <View style={{ flexDirection: 'column', flex: 1 }}>
                    <Text category="h4" appearance="hint" style={{ marginBottom: 8 }}>Choisissez l'heure</Text>
                    <View style={{ height: 50 }}>
                      <FTextInput
                        category="h4"
                        name="time"
                        maskOptions={{
                          type: 'datetime',
                          options: {
                            format: 'HH:mm',
                          },
                        }}
                        maxLength={5}
                        placeholder="00:00"
                        defaultValue={data.date ? moment(data.date).format('HH:mm') : undefined}
                        onChangeValue={(t:string) => { setTime(t); }}
                        validators={[
                          AvailableValidationRules.required,
                        ]}
                      />
                    </View>

                    <Text category="p2">Example : 10:30</Text>
                  </View>

                </View>

                {/** still to finish buttonPress */}
                <Button size="large" onPress={() => validerTelecons()} style={[{ marginTop: 20 }]} disabled={!date || !time}>
                  {buttonText}
                </Button>
                {!date || !time ? (<Text category="s2" status="warning">Vous devez renseigner une date et une heure.</Text>) : null}

                <Button size="large" appearance="ghost" onPress={() => onRequestClose()} style={{ justifyContent: 'center' }}>
                  Annuler
                </Button>
                <Text category="p2" style={{ textAlign: 'center' }}>
                  {/* eslint-disable-next-line max-len */}
                  L'éleveur sera notifié de votre proposition de date pour la téléconsultation. Vous serez notifié de sa confirmation ou de sa demande de report du rendez-vous.
                  Vous pouvez également échanger avec lui sur la messagerie de l'auto-examen.
                </Text>
              </View>
            </KeyboardAvoidingView>
          </MotiView>
        </View>
        )}
      </AnimatePresence>
    </Modal>
  );
};

export default CustomModal;

const duration = 350;
const styles = StyleSheet.create({
  modal: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 30,
  },
  exit: {
    flex: 1,
  },
  content: {
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderRadius: 15,
    flex: 1,
  },

});
