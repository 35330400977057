import React, { useContext, useState } from 'react';
import { Icon as IconUIKitten } from '@ui-kitten/components/ui/icon/icon.component';
import { TouchableOpacity } from 'react-native';
import { Text } from '@ui-kitten/components';
import RtcContext, { DispatchType } from '../RtcContext';

function FlashLight() {
  const { dispatch, isTorchAvailable } = useContext(RtcContext);
  const [isOn, setIsOn] = useState(false);
  if (!isTorchAvailable) {
    return null;
  }
  return (
    <TouchableOpacity
      style={{
        flexDirection: 'row',
        alignItems: 'center',
      }}
      onPress={() => {
        (dispatch as DispatchType<'FlashLight'>)({
          type: 'FlashLight',
          value: [!isOn],
        });
        setIsOn(!isOn);
      }}
    >
      <Text category="h6" style={{ color: 'white' }}>{isOn ? 'Eteindre la lampe' : 'Allumer la lampe'}</Text>
      <IconUIKitten name={isOn ? 'flash' : 'flash-outline'} style={{ height: 30, width: 30, margin: 20 }} fill="white" />
    </TouchableOpacity>
  );
}

export default FlashLight;
