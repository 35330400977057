import { useForm } from 'react-hook-form';
import React, { useState } from 'react';
import { Platform, View } from 'react-native';
import { useDimensions } from '@react-native-community/hooks';
import { Modal, Radio, Text } from '@ui-kitten/components';
import { useNavigation, useRoute } from '@react-navigation/native';
import { RouteProp } from '@react-navigation/core/lib/typescript/src/types';

import Form from '../../components/Form/Form';
import TextInput from '../../components/Form/TextInput';
import MaxWidthContainer from '../../components/MaxWidthContainer';

import { useUser } from '../../src/API/UserContext';
import { AvailableValidationRules } from '../../components/Form/validation';
import Button from '../../components/Button';
import WebView from '../../components/WebView';
import { TabElevageParamList } from '../../types';
import { useGetBreeding, useUpdateBreedingMutation } from '../../src/API/Breeding';

import { DocumentItem } from '../../src/API/Document';
import DocumentBL from '../../components/Document/DocumentBL';
import DocumentComponent from '../../components/Document/DocumentComponent';
import InfoModal from '../../components/InfoModal';
import ItemTitle from '../../screenComponents/ItemTitle';

type ModifyElevageForm = {
  companyName: string
  address?: {
    address: string;
    additionalAddress?: string | null;
    city: string;
    postalCode: string;
  } | null;
  // id: string,
  nBreeding: string,
  nSIRET: string,
  dairyFarm: boolean,
  sucklerCowHusbandry: boolean,
  fatteningFarm: boolean,
  nbCow: number,
  nbHeifer: number,
  nbMale: number,
  nbYoung: number,
  emailVeto: string,
};

const ModifyElevageScreen = () => {
  const { window } = useDimensions();
  const { user } = useUser();
  const [loading, setLoading] = useState(false);

  const route = useRoute<
  RouteProp<TabElevageParamList, 'modify-elevage'>
  >();
  // console.log('route Modif Elev:', route);
  const { oneBreeding, refetch } = useGetBreeding(route.params.id);
  // console.log('oneBreeding:', oneBreeding);

  const [dairyFarm, setDairyFarm] = useState(oneBreeding?.dairyFarm || false);
  const [sucklerCowHusbandry, setSucklerCowHusbandry] = useState(
    oneBreeding?.sucklerCowHusbandry || false,
  );
  const [fatteningFarm, setFatteningFarm] = useState(oneBreeding?.fatteningFarm || false);

  /** DOCUMENT */
  const navigation = useNavigation();
  const { modifyBreeding } = useUpdateBreedingMutation();
  const saveElevageModification = async (data: ModifyElevageForm) => {
    // console.log('id modif elevage:', user?.id);
    // console.log('data modif elevage:', data);

    if (user) {
      await modifyBreeding({
        variables: {
          input: {
            id: route.params.id,
            ...data,
            dairyFarm,
            sucklerCowHusbandry,
            fatteningFarm,
            // eslint-disable-next-line no-underscore-dangle
            _version: oneBreeding._version,
          },
        },
      });

      navigation.navigate('elevage');
    }
  };

  let EDE:DocumentItem;
  if (oneBreeding && oneBreeding.documents) {
    // eslint-disable-next-line no-underscore-dangle
    oneBreeding.documents?.items?.map((item) => { if (item && !item._deleted && item.key && item.key.includes('EDE')) { EDE = item; } return {}; });
  }

  const { uploadCreateDocument } = DocumentBL.UseDocument();

  const modifyElevageForm = useForm<ModifyElevageForm>();
  return (
    <>
      <MaxWidthContainer
        withScrollView="keyboardAware"
        outerViewProps={{
          style: {
            // flex: 1,
            padding: 24,
          },
        }}
      >
        <Form<ModifyElevageForm> {...modifyElevageForm} defaultValues={oneBreeding}>
          <>

            <View style={Platform.OS === 'web' && window.width > 560 && ({ flexDirection: 'row' })}>
              <TextInput
                title="La raison sociale"
                name="companyName"
                placeholder="Saisissez la raison sociale"
                style={[Platform.OS === 'web' && window.width > 560 && ({ marginRight: 20 }), { marginBottom: 20 }]}
                validators={[
                  AvailableValidationRules.required,
                ]}
              />
              <TextInput
                title="Adresse"
                name="address.address"
                placeholder="Saisissez l'adresse de l'élevage"
                style={{ marginBottom: 20 }}
                validators={[
                  AvailableValidationRules.required,
                ]}
              />
            </View>

            <View style={Platform.OS === 'web' && window.width > 560 && ({ flexDirection: 'row' })}>
              <TextInput
                title={"Saisissez le complément d'adresse"}
                name="address.additionalAddress"
                placeholder="Saisissez le complément d'adresse"
                style={[Platform.OS === 'web' && window.width > 560 && ({ marginRight: 20 }), { marginBottom: 20 }]}
              />
              <TextInput
                title="Code Postal"
                name="address.postalCode"
                placeholder="Saisissez le code postal"
                style={{ marginBottom: 20 }}
                maxLength={5}
                validators={[
                  AvailableValidationRules.required,
                ]}
              />
            </View>
            <View style={Platform.OS === 'web' && window.width > 560 && ({ flexDirection: 'row' })}>
              <TextInput
                title="Ville"
                name="address.city"
                placeholder="Saisissez votre ville"
                style={[{ marginBottom: 20 }]}
                validators={[
                  AvailableValidationRules.required,
                ]}
              />
            </View>

            <View style={Platform.OS === 'web' && ({ flexDirection: 'row' })}>
              <View style={{ flex: 1 }}>
                <TextInput
                  title={"N° d'élevage"}
                  name="nBreeding"
                  placeholder="Saisissez votre numéro d'élevage"
                  validators={[
                    AvailableValidationRules.required,
                  ]}
                />
              </View>

              <View style={[{ flex: 1 },
                Platform.OS === 'web' && ({ marginLeft: 20 }),
                Platform.OS !== 'web' && ({ marginTop: 20 }),
              ]}
              >
                <TextInput
                  title={"Numéro SIREN de l'exploitation"}
                  name="nSIRET"
                  placeholder="Saisissez le numéro SIREN"
                  validators={[
                    AvailableValidationRules.required,
                  ]}
                />
              </View>
            </View>
            <Text category="h5" style={{ marginTop: 30 }}>Type de production*</Text>

            <View style={{ flexDirection: 'row', marginVertical: 20 }}>
              <Radio
                checked={dairyFarm}
                onChange={(newChecked) => {
                  setDairyFarm(newChecked);
                  setSucklerCowHusbandry(false);
                  setFatteningFarm(false);
                }}
              >
                Laitier
              </Radio>
              <Radio
                checked={sucklerCowHusbandry}
                onChange={(newChecked) => {
                  setSucklerCowHusbandry(newChecked);
                  setDairyFarm(false);
                  setFatteningFarm(false);
                }}
              >
                Allaitant
              </Radio>
              <Radio
                checked={fatteningFarm}
                onChange={(newChecked) => {
                  setFatteningFarm(newChecked);
                  setDairyFarm(false);
                  setSucklerCowHusbandry(false);
                }}
              >
                Engraisseur
              </Radio>
            </View>

            <Text category="h5" style={{ marginLeft: 10, marginTop: 20 }}>Effectif du cheptel*</Text>
            <Text category="h5" appearance="hint" style={{ marginLeft: 10, marginVertical: 10 }}>Saisissez le nombre de bovins</Text>
            <View style={Platform.OS === 'web' && window.width > 780 && ({ flexDirection: 'row' })}>

              <TextInput
                name="nbCow"
                placeholder="Vaches"
                keyboardType="numeric"
                maxLength={5}
                title="Vaches"
                appendTitle={(<InfoModal infoText="femelles ayant mis bas au moins une fois" infoTitle="Vaches" />)}
              />
              {/** Vache qui donne naissence */}
              <TextInput
                name="nbHeifer"
                placeholder="Génisses"
                keyboardType="numeric"
                maxLength={5}
                title="Génisses"
                titleStyle={[Platform.OS === 'web' && window.width > 780 ? ({ marginLeft: 30 }) : ({ marginTop: 10 })]}
                style={Platform.OS === 'web' && window.width > 780 && ({ marginLeft: 20 })}
                appendTitle={(<InfoModal infoText="femelles de plus de 12 mois n'ayant pas encore mis bas" infoTitle="Génisses" />)}
              />
              <TextInput
                name="nbMale"
                placeholder="Mâles"
                title="Mâles"
                titleStyle={[Platform.OS === 'web' && window.width > 780 ? ({ marginLeft: 30 }) : ({ marginTop: 10 })]}
                style={Platform.OS === 'web' && window.width > 780 && ({ marginLeft: 20 })}
              />
              <TextInput
                name="nbYoung"
                placeholder="Moins de 12 mois"
                title="Moins de 12 mois"
                titleStyle={[Platform.OS === 'web' && window.width > 780 ? ({ marginLeft: 30 }) : ({ marginTop: 10 })]}
                style={Platform.OS === 'web' && window.width > 780 && ({ marginLeft: 20, minWidth: 200 })}
              />
            </View>

            <TextInput
              name="emailVeto"
              placeholder="Sasissez l'adresse e-mail"
              title={"E-mail du vétérinaire sanitaire de l'élevage*"}
            />

            <ItemTitle
              titleType="5"
              status="default"
              title="Document validation d'effectif du cheptel adulte *"
              description={"Dernière facture de cotisation GDS, ou dernière facture de prophylaxie, ou tout autre document officiel attestant sans ambiguïté l'effectif annoncé pour le troupeau adulte. Attention toute fausse déclaration d'effectif entraînera la suspension de votre adhésion et d'éventuelles poursuites (voir CGV)"}
            />

            { EDE
              ? (
                <>
                  { oneBreeding && oneBreeding.documents?.items?.map(
                    // eslint-disable-next-line no-underscore-dangle
                    (item) => (item && !item._deleted && (
                    <DocumentComponent
                      key={item.id}
                      document={item}
                      // data={item}
                      refetch={refetch}
                    />
                    )),
                  ) }
                </>
              )
              : (
                <Button
                  onPress={
                    () => uploadCreateDocument('EDE', refetch, oneBreeding)
                  }
                  leftIcon="plus-outline"
                  appearance="ghost"
                  style={{ alignSelf: 'flex-start' }}
                >
                  Ajouter un document
                </Button>
              )}

            <View style={{ marginTop: 34 }}>
              <Button loading={loading} loadingText="Chargement" onPress={modifyElevageForm.handleSubmit((data) => { setLoading(true); saveElevageModification(data); })} size="large">
                Enregistrer
              </Button>
            </View>
            <Text category="c1" appearance="hint">
              * champs obligatoires
            </Text>
          </>
        </Form>

      </MaxWidthContainer>
    </>
  );
};

export default ModifyElevageScreen;
