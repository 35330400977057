import * as React from 'react';
import {
  View, StyleSheet,
} from 'react-native';
import { useEffect, useState } from 'react';
import {
  Datepicker, I18nConfig, Icon, IconProps, NativeDateService, Text,
} from '@ui-kitten/components';

import { DatePickerFormProps } from './types';
import DateUtils from '../../utils/DateUtils';
import { AvailableValidationRules } from './validation';

const i18n : I18nConfig = {
  dayNames: {
    short: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
    long: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
  },
  monthNames: {
    short: ['Jan.', 'Fév.', 'Mars', 'Avr.', 'Mai', 'Juin', 'Juil.', 'Août', 'Sep.', 'Oct.', 'Nov.', 'Déc.'],
    long: [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Août',
      'Septembre',
      'October',
      'Novembre',
      'Décembre',
    ],
  },
};
const localeDateService = new NativeDateService('ru', { i18n, startDayOfWeek: 1 });

const DatepickerComp = React.forwardRef<
Datepicker, DatePickerFormProps
>(
  (props: DatePickerFormProps, ref): React.ReactElement => {
    const {
      label,
      labelBefore,
      title,
      icon,
      error,
      onChangeValue,
      placeholder,
      labelStyle,
      containerStyle,
      defaultValue,
      style,
      validators,
      clearStateFunc,
      ...DatePickerProps
    } = props;

    const [inputValue, setInputValue] = useState<Date>();
    // const [date, setDate] = useState(moment(defaultValue));
    // const [show, setShow] = useState(false);

    if (clearStateFunc) {
      clearStateFunc(() => {
        setInputValue(undefined);
      });
    }

    useEffect(() => {
      if (inputValue === undefined && defaultValue) {
        setInputValue(DateUtils.parseToDateObj(defaultValue));
      }
    }, [inputValue, defaultValue]);

    const renderIcon = (iconProps: IconProps) => (
      <Icon {...iconProps} name={icon} fill="#000" />
    );

    const isRequired = validators && validators.find((item) => {
      if (typeof item === 'object') {
        return item.rule === AvailableValidationRules.required;
      }
      return item === AvailableValidationRules.required;
    });

    const required = (isRequired ? ' *' : '');

    // const [date, setDate] = useState(new Date());

    return (
      <View style={[styles.container, containerStyle, labelBefore ? { flexDirection: 'column', alignItems: 'flex-start' } : {}]}>
        {labelBefore && (
        <Text category="h5" style={{ flex: 1, marginBottom: 5, marginRight: labelBefore ? 10 : 0 }}>{label}</Text>
        )}
        {/* Platform.OS === 'web' && ( */}
        <View>
          {title && (<Text category="h5" style={{ marginBottom: 5 }}>{title}</Text>)}
          <Datepicker
            label={labelBefore ? undefined : label}
            ref={ref}
            accessoryRight={icon ? renderIcon : undefined}
            style={[styles.input, style]}
            caption={error && error.message}
            status={error && error.message ? 'danger' : 'default'}
            dateService={localeDateService}
            placeholder={(placeholder || '') + required}
            controlStyle={{
              width: '100%',
              shadowColor: 'rgba(190, 190, 190, 0.5)',
              shadowOffset: {
                width: 0,
                height: 1,
              },
              shadowRadius: 2,
              shadowOpacity: 1,
              elevation: 2,
            }}
            min={new Date(1900, 0, 1)}
            size="medium"
            {...DatePickerProps}
            placement="bottom"
            onSelect={(date) => {
              setInputValue(date);
              if (onChangeValue) {
                const newDate = date;
                newDate.setHours(12);
                onChangeValue(newDate.toISOString().substr(0, 10));
              }
            }}
            date={inputValue}
          />

          {/**
          <IconUIKitten
            name="calendar-outline"
            fill="#000000"
            style={{
              height: 16, width: 16,
            }}
          /> */}
        </View>

        {/** Platform.OS === 'whenReady' && (
        <View>
          {title && (<Text category="h5" style={{ marginBottom: 5 }}>{title}</Text>)}

          <TouchableHighlight
            underlayColor="white"
            activeOpacity={0}
            style={{
              flex: 1, borderTopColor: '#e9e9e9', borderTopWidth: 1,
            }}
          >
            <View style={{ backgroundColor: 'white', height: 40, overflow: 'hidden' }}>
              <DateTimePicker
                timeZoneOffsetInMinutes={0}
                value={inputValue || new Date(date)}
                mode="date"
                minimumDate={new Date(1900, 0, 1)}
              />
            </View>
          </TouchableHighlight>

        </View>
        ) */}

      </View>
    );
  },
);

DatepickerComp.displayName = 'Datepicker';

export default DatepickerComp;

const styles = StyleSheet.create({
  container: {
    marginVertical: 8,
    flexDirection: 'column',
    marginBottom: 20,
    // justifyContent: 'space-between',
  },
  input: {
    flex: 1,
    margin: 0,
  },
});
