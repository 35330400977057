import { Icon, Text, useTheme } from '@ui-kitten/components';
import { View } from 'react-native';
import React from 'react';

export default function InnerSend() {
  const theme = useTheme();
  return (
    <View style={{
      backgroundColor: theme['color-primary-500'],
      height: 36,
      flexDirection: 'row',
      alignItems: 'center',
      padding: 5,
      margin: 5,
      borderRadius: 18,
      paddingLeft: 10,
    }}
    >
      <Text category="h6" style={{ color: 'white' }}>Envoyer</Text>
      <Icon name="arrow-ios-forward" fill="white" style={{ width: 20, height: 20 }} />
    </View>
  );
}
