import React, { useCallback, useEffect } from 'react';
import 'dayjs/locale/fr';
import {
  Avatar,
  Bubble, Day, GiftedChat, IMessage, LoadEarlier, Send, utils,
} from 'react-native-gifted-chat';
import {
  Spinner, Text, useTheme,
} from '@ui-kitten/components';
import { View } from 'react-native';
import gql from 'graphql-tag';
import InnerSend from './InnerSend';
import {
  MessagesByConsultationQuery, OnCreateMessageByConsultationIdSubscription,
  onCreateMessageByConsultationIdSubscription, useCreateMessageMutation,
  useGetMessagesByConsultation,
} from '../../src/API/Message';
import DateUtils from '../../utils/DateUtils';
import { useUser } from '../../src/API/UserContext';
import { OnCreateDocumentByConsultationIdSubscriptionVariables } from '../../src/API';

const renderSend = (props: Send['props']) => (
  <Send
    {...props}
    containerStyle={{
      height: null,
      justifyContent: 'center',
    }}
  >
    <InnerSend />
  </Send>
);

export type ChatProps = {
  channel?: string,
  backgroundColor?: string,
};

const convertMessages = (messagesArray: MessagesByConsultationQuery['messagesByConsultation']): IMessage[] | undefined => {
  if (messagesArray) {
    return messagesArray.items
      .filter((item) => item !== null)
      .map((item) => ({
        _id: item.id,
        text: item.message,
        createdAt: DateUtils.parseToDateObj(item.createdAt),
        user: {
          _id: item.userId,
          name: `${item.user.firstname} ${item.user.lastname}`,
        },
      }));
  }
  return undefined;
};

export default function Chat(props: ChatProps) {
  const { channel, backgroundColor = 'white' } = props;
  const {
    messages, loading, subscribeToMore, fetchMore,
  } = useGetMessagesByConsultation(channel);
  const createMessage = useCreateMessageMutation();
  const { user } = useUser();
  const theme = useTheme();

  useEffect(() => {
    let unsubscribe = () => {};
    if (subscribeToMore) {
      unsubscribe = subscribeToMore<
      OnCreateMessageByConsultationIdSubscription,
      OnCreateDocumentByConsultationIdSubscriptionVariables
      >({
        document: gql(onCreateMessageByConsultationIdSubscription),
        variables: { consultationId: channel },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          const newMessage = subscriptionData.data.onCreateMessageByConsultationId;

          if (newMessage) {
            if (prev.messagesByConsultation) {
              if (prev.messagesByConsultation.items
                  && prev.messagesByConsultation.items[0]
                  && prev.messagesByConsultation.items[0].id === newMessage.id) {
                return prev;
              }
              return {
                messagesByConsultation: {
                  ...prev.messagesByConsultation,
                  items: [
                    newMessage,
                    ...prev.messagesByConsultation.items,
                  ],
                },
              };
            }
            return {
              messagesByConsultation: {
                __typename: 'ModelMessageConnection',
                items: [
                  newMessage,
                ],
              },
            };
          }
          return prev;
        },
      });
    }
    return () => {
      unsubscribe();
    };
  }, [subscribeToMore, channel]);

  const onSend = useCallback((sentMessages: IMessage[] = []) => {
    if (user) {
      Promise.all(sentMessages.map(async (item) => {
        await createMessage({
          variables: {
            input: {
              message: item.text,
              userId: user.id,
              consultationId: channel,

            },
          },
        });
      }));
    }
  }, [createMessage, user]);
    /*
        loadEarlier={this.state.loadEarlier}
                                    renderLoadEarlier={(props) => (this.state.hasOlder ?
                                        <LoadEarlier
                                            {...props}
                                            label={"Charger les messages précédents"}
                                            textStyle={{
                                                color: StyleGuide.palette.littleDarkGray
                                            }}
                                            wrapperStyle={{
                                                backgroundColor: "transparent"
                                            }}
                                        /> : null)}
                                    isLoadingEarlier={this.state.isLoadingEarlier}
                                    onLoadEarlier={this.loadEarlier}
                                    */
  if (!user || !channel) {
    return (<View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}><Spinner /></View>);
  }

  return (
    <GiftedChat
      loadEarlier
      renderLoadEarlier={(props) => (messages?.nextToken
        ? (
          <LoadEarlier
            {...props}
            containerStyle={[
              props.containerStyle,
              { backgroundColor: 'transparent' },
            ]}
            label="Charger les messages précédents"
            activityIndicatorColor={theme['color-primary-500']}
            textStyle={{
              fontFamily: 'sourceSansPro_SemiBold',
              fontSize: 12,
              color: theme['light-grey'],
            }}
            wrapperStyle={{
              backgroundColor: 'transparent',
            }}
          />
        ) : null)}
      isLoadingEarlier={loading}
      onLoadEarlier={(props) => {
        if (fetchMore) {
          fetchMore({
            variables: {
              nextToken: messages?.nextToken,
            },
            updateQuery: (previousQueryResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return previousQueryResult;
              return {
                ...previousQueryResult,
                messagesByConsultation: {
                  ...previousQueryResult.messagesByConsultation,
                  items: [
                    ...previousQueryResult.messagesByConsultation?.items,
                    ...fetchMoreResult.messagesByConsultation?.items,
                  ],
                  nextToken: fetchMoreResult.messagesByConsultation?.nextToken,
                  startedAt: fetchMoreResult.messagesByConsultation?.startedAt,
                },
              };
            },
          });
        }
      }}
      messages={convertMessages(messages)}
      onSend={(messages) => onSend(messages)}
      user={{
        _id: user.id,
      }}
      locale="fr"
      renderLoading={() => <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}><Spinner /></View>}
      onLongPress={() => {}}
      renderDay={(props) => {
        const { currentMessage, previousMessage } = props;
        return (
          <View>
            <Day
              {...props}
              textStyle={{
                fontFamily: 'sourceSansPro_SemiBold',
                fontSize: 12,
                color: theme['light-grey'],
              }}
            />
            {
            !utils.isSameUser(currentMessage, previousMessage) && currentMessage.user._id !== user.id
            && (
            <View>
              <Text
                category="p2"
                style={{
                  fontSize: 10, color: theme['light-grey'], marginLeft: 68, marginBottom: 5,
                }}
              >
                {currentMessage.user.name}
              </Text>
            </View>
            )
          }
          </View>
        );
      }}
      renderAvatar={(props) => (
        <Avatar
          {...props}
          textStyle={{
            fontFamily: 'sourceSansPro_SemiBold',
            fontSize: 22,
          }}
          imageStyle={{
            left: {
              width: 44,
              height: 44,
              borderRadius: 22,
              backgroundColor: theme['color-primary-500'],
            },
            right: {
              width: 44,
              height: 44,
              borderRadius: 22,
              backgroundColor: theme['color-primary-500'],
            },
          }}
        />
      )}
      imageStyle={{
        left: {
          width: 52,
        },
        right: {
          width: 52,
        },
      }}
      renderSend={renderSend}
      placeholder="Votre message"
      renderBubble={(props) => (
        <Bubble
          {...props}
          wrapperStyle={{
            right: {
              backgroundColor: theme['color-primary-500'],
              padding: 16,
            },
            left: {
              backgroundColor: 'white',
              padding: 16,
            },
          }}
          textStyle={{
            right: {
              color: 'white',
            },
            left: {
              color: 'black',
            },
          }}
          timeTextStyle={{
            right: {
              fontFamily: 'sourceSansPro_Regular',
              fontSize: 14,
              color: 'white',
              margin: -10,
              marginTop: 10,
            },
            left: {
              fontFamily: 'sourceSansPro_Regular',
              fontSize: 14,
              color: theme['light-grey'],
              margin: -10,
              marginTop: 10,
            },
          }}
          customTextStyle={{
            fontFamily: 'sourceSansPro_Regular',
            fontSize: 16,
            margin: 0,
          }}
        />
      )}
      listViewProps={{
        keyboardDismissMode: 'on-drag',
        style: {
          maxWidth: 780,
          flex: 1,
          width: '100%',
        },
      }}
      style={{
        width: '100%',
      }}
      messagesContainerStyle={{
        alignItems: 'center',
      }}
      containerStyle={{
        borderTopWidth: 0,
        alignItems: 'center',
        paddingHorizontal: 7,
        backgroundColor,
      }}
      minInputToolbarHeight={102}
      minComposerHeight={46}
      primaryStyle={{
        maxWidth: 780,
        width: '100%',
        borderWidth: 2,
        borderColor: theme['xtralight-grey'],
        borderRadius: 24,
        backgroundColor: 'white',
        marginTop: 16,
        marginBottom: 36,
      }}
      textInputStyle={{
        fontFamily: 'sourceSansPro_Regular',
        fontSize: 16,
        marginHorizontal: 0,
        marginTop: 0,
        marginBottom: 0,
        padding: 15,
      }}
    />
  );
}
