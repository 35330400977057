import { useMutation, useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { DocumentNode } from 'apollo-link';

import { WatchQueryFetchPolicy } from 'apollo-client/core/watchQueryOptions';
import {
  Address,
  Animals,
  Breeding,
  CreateBreedingMutation,
  CreateBreedingMutationVariables,
  GenderType,
  GetBreedingQueryVariables,
  ListBreedingByLockedByCreationDateQuery,
  ListBreedingByLockedByCreationDateQueryVariables,
  ListBreedingsQueryVariables,
  LockedStatus,
  ModelAnimalsConnection,
  ModelBSEConnection,
  ModelDocumentConnection,
  ModelPendingInvitationConnection,
  ModelSortDirection,
  ModelUserConnection,
  ModelZonesConnection,
  StatusBse,
  UpdateBreedingMutation,
  UpdateBreedingMutationVariables,
} from '../API';
import * as mutations from '../graphql/mutations';
import { updateBreeding } from '../graphql/mutations';
import { listBreedingByLockedByCreationDate } from '../graphql/queries';

type ListBreedingsQuery = {
  listBreedings?: {
    __typename: 'ModelBreedingConnection',
    items?: Array< {
      __typename: 'Breeding',
      id: string,
      nBreeding: string,
      nSIRET: string,
      phone: number,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      vetOffice: {
        maxPriceConsultation: number,
        maxPriceTeleConsultation: number,
      },
      emailVeto?: string | null,
      dateFirstCons?: string | null,
      locked: LockedStatus,
      admins: Array< string >,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      animals?: {
        __typename: 'ModelAnimalsConnection',
        items?: Array<Animals | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      lots?: {
        __typename: 'ModelLotsConnection',
        items?: Array<{
          __typename: 'Lots',
          id: string,
          numberLot: number,
          name?: string | null,
          breedingId: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          breeding?: Breeding | null,
          animals?: ModelAnimalsConnection | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?: {
        __typename: 'ModelBuildingsConnection',
        items?: Array<{
          __typename: 'Buildings',
          id: string,
          nameBuilding: string,
          address?: Address | null,
          breedingId: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          breeding?: Breeding | null,
          zone?: ModelZonesConnection | null,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bse?: ModelBSEConnection | null,
      documents?: ModelDocumentConnection | null,
      pendingInvitations?: ModelPendingInvitationConnection | null,
      // exams?: ModelExamConnection | null,
      consultations?: {
        __typename: 'ModelConsultationConnection',
        items?: Array< {
          __typename: 'Consultation',
          id: string,
          breedingId: string,
          breeding?: {
            id: string,
            nBreeding: string,
            nSIRET: string,
            phone: number,
            emailEleveur: string,
            companyName: string,
          } | null,
          animalId?: string | null,
          animal?: ModelAnimalsConnection | null,
          lotId?: string | null,
          shared?: boolean | null,
          autoExam?: boolean | null,
          teleconsultation?: boolean | null,
          dateTeleCons?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      mpUserId?: string | null,
      mpWalletId?: string | null,
      mpBankId?: string | null,
      mpMandateId?: string | null,
      mpMandateConfirmed?: boolean | null,
      billingHistories?: {
        __typename: 'ModelBillingHistoryConnection',
        items: Array< {
          __typename: 'BillingHistory',
          id: string,
          vetOfficeId?: string | null,
          breedingId?: string | null,
          breeding?: {
            companyName: string,
            address?: Address | null,
          },
          createdAt: string,
          invoiceNumber?: number | null,
          amount: number,
          label: string,
          paid?: boolean | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

const listBreedingQuery = <DocumentNode>gql(`
  query ListBreedings(
    $filter: ModelBreedingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBreedings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        nBreeding
        nSIRET
        locked
        address {
          address
          additionalAddress
          postalCode
          city
        }
        phone
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        emailVeto
        dateFirstCons
        admins
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        vetOffice {
          maxPriceConsultation
          maxPriceTeleConsultation
        }
        animals (limit: 1000) {
          items {
            id
            numberID
            name
            gender
            birthDate
            breedingId
            lotsId
            zoneId
            buildingId
            _version
            _deleted
            _lastChangedAt
            
            
            lots {
              id
              numberLot
              name
              breedingId
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            
            
          }
          nextToken
          startedAt
        }
        
        lots (limit: 1000) {
          items {
            id
            numberLot
            name
            buildingId
            _deleted
            _version
          }
          nextToken
          startedAt
        }
        
        
        buildings (limit: 1000) {
          items {
            id
            nameBuilding
            _deleted
            address {
              address
              additionalAddress
              postalCode
            }
            breedingId
            zone (limit: 1000) {
              items {
                id
                name
                numberZone
                buildingId
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
              }
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }     
        
       consultations(sortDirection: DESC, limit: 1000) {
          items {
            id
            breedingId
            breeding {
              id
              nBreeding
              nSIRET
              phone
              emailEleveur
              companyName
            }
            
            priceConsultation
            sharedFacture
            sharedFactureAt
            factureId
            facture {
              id
              breedingId
              name
              key
              s3file
              createdAt
              _version
              _deleted
              _lastChangedAt
              updatedAt
            }
            
            animalId
            animal {
              id
              numberID
              name
              gender
              birthDate
              breedingId
              
              lotsId
              lots {
                id
                numberLot
                name
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
              }

              zoneId
              buildingId
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            
            lotId
            lot {
                id
                numberLot
                name
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
              }
            
            shared
            autoExam
            teleconsultation
            dateTeleCons
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        
        bse (limit: 1000) {
          items {
            id
            breedingId
            dateBSE
            StatusBse
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      
        documents (limit: 1000) {
          items {
            id
            breedingId
            name
            key
            s3file
            createdAt
            _version
            _deleted
            _lastChangedAt
            updatedAt
          }
          nextToken
        }
        
        billingHistories (sortDirection: DESC, limit: 1000) {
          items {
            id
            vetOfficeId
            breedingId
            breeding {
              companyName
              address {
                address
                additionalAddress
                postalCode
                city
              }
            }
            createdAt
            invoiceNumber
            amount
            label
            paid
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
        }
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
      }
      nextToken
      startedAt
    }
  }
`);

type ListBreedingsVetoQuery = {
  listVetOffices?: {
    __typename: 'ModelVetOfficeConnection',
    items?: Array< {
      __typename: 'VetOffice',
      breedings?: {
        __typename: 'ModelBreedingConnection',
        items?: Array<{
          __typename: 'Breeding',
          id: string,
          nBreeding: string,
          nSIRET: string,
          phone: number,
          dairyFarm: boolean,
          sucklerCowHusbandry: boolean,
          fatteningFarm: boolean,
          nbCow: number,
          nbHeifer: number,
          nbMale: number,
          nbYoung: number,
          vetOffice: {
            maxPriceConsultation: number,
            maxPriceTeleConsultation: number,
          },
          emailVeto?: string | null,
          dateFirstCons?: string | null,
          locked: LockedStatus,
          admins: Array<string>,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          animals?: {
            __typename: 'ModelAnimalsConnection',
            items?: Array<Animals | null> | null,
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          lots?: {
            __typename: 'ModelLotsConnection',
            items?: Array<{
              __typename: 'Lots',
              id: string,
              numberLot: number,
              name?: string | null,
              breedingId: string,
              _version: number,
              _deleted?: boolean | null,
              _lastChangedAt: number,
              createdAt: string,
              updatedAt: string,
              breeding?: Breeding | null,
              animals?: ModelAnimalsConnection | null,
            } | null> | null,
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          buildings?: {
            __typename: 'ModelBuildingsConnection',
            items?: Array<{
              __typename: 'Buildings',
              id: string,
              nameBuilding: string,
              address?: Address | null,
              breedingId: string,
              _version: number,
              _deleted?: boolean | null,
              _lastChangedAt: number,
              createdAt: string,
              updatedAt: string,
              breeding?: Breeding | null,
              zone?: ModelZonesConnection | null,
            } | null> | null,
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          bse?: ModelBSEConnection | null,
          documents?: ModelDocumentConnection | null,
          pendingInvitations?: ModelPendingInvitationConnection | null,
          // exams?: ModelExamConnection | null,
          consultations?: {
            __typename: 'ModelConsultationConnection',
            items?: Array<{
              __typename: 'Consultation',
              id: string,
              breedingId: string,
              breeding?: {
                id: string,
                nBreeding: string,
                nSIRET: string,
                phone: number,
                emailEleveur: string,
                companyName: string,
              } | null,
              animalId?: string | null,
              animal?: ModelAnimalsConnection | null,
              lotId?: string | null,
              shared?: boolean | null,
              autoExam?: boolean | null,
              teleconsultation?: boolean | null,
              dateTeleCons?: string | null,
              _version: number,
              _deleted?: boolean | null,
              _lastChangedAt: number,
              createdAt: string,
              updatedAt: string,
            } | null> | null,
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,

          mpUserId?: string | null,
          mpWalletId?: string | null,
          mpBankId?: string | null,
          mpMandateId?: string | null,
          mpMandateConfirmed?: string | null,
          nextSubscriptionDate?: string | null,
        } | null> | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null> | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

const listBreedingVetoQuery = <DocumentNode>gql(`
  query ListBreedings(
    $filter: ModelVetOfficeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVetOffices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        breedings (limit: 1000) {
          items {
            id
            nBreeding
            nSIRET
            locked
            address {
              address
              additionalAddress
              postalCode
              city
            }
            phone
            companyName
            dairyFarm
            sucklerCowHusbandry
            fatteningFarm
            nbCow
            nbHeifer
            nbMale
            nbYoung
            emailVeto
            dateFirstCons
            admins
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vetOffice {
              maxPriceConsultation
              maxPriceTeleConsultation
            }
            animals (limit: 1000) {
              items {
                id
                numberID
                name
                gender
                birthDate
                breedingId
                lotsId
                zoneId
                buildingId
                _version
                _deleted
                _lastChangedAt
                
                
                lots {
                  id
                  numberLot
                  name
                  breedingId
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                
                
              }
              nextToken
              startedAt
            }
            
            lots (limit: 1000) {
              items {
                id
                numberLot
                name
                buildingId
                _deleted
              }
              nextToken
              startedAt
            }
            
            
            buildings (limit: 1000) {
              items {
                id
                nameBuilding
                _deleted
                address {
                  address
                  additionalAddress
                  postalCode
                }
                breedingId
                zone (limit: 1000) {
                  items {
                    id
                    name
                    numberZone
                    buildingId
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                  }
                  nextToken
                  startedAt
                }
              }
              nextToken
              startedAt
            }     
            
           consultations(sortDirection: DESC, limit: 1000) {
              items {
                id
                breedingId
                breeding {
                  id
                  nBreeding
                  nSIRET
                  phone
                  emailEleveur
                  companyName
                }
                
                priceConsultation
                sharedFacture
                sharedFactureAt
                factureId
                facture {
                  id
                  breedingId
                  name
                  key
                  s3file
                  createdAt
                  _version
                  _deleted
                  _lastChangedAt
                  updatedAt
                }
                
                animalId
                animal {
                  id
                  numberID
                  name
                  gender
                  birthDate
                  breedingId
                  
                  lotsId
                  lots {
                    id
                    numberLot
                    name
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                  }
    
                  zoneId
                  buildingId
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                
                lotId
                lot {
                    id
                    numberLot
                    name
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                  }
                
                shared
                autoExam
                isDone
                teleconsultation
                dateTeleCons
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
              }
              nextToken
              startedAt
            }
            
            bse (limit: 1000) {
              items {
                id
                breedingId
                dateBSE
                StatusBse
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
              }
              nextToken
              startedAt
            }
          
            documents (limit: 1000) {
              items {
                id
                breedingId
                name
                key
                s3file
                createdAt
                _version
                _deleted
                _lastChangedAt
                updatedAt
              }
              nextToken
            }
            mpUserId
            mpWalletId
            mpBankId
            mpMandateId
            mpMandateConfirmed
            nextSubscriptionDate
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`);

export function useBreedingList(userGroup: string | undefined | null, fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network') {
  /**   Veto peut avoir plusieurs elevages(breedingS)  */
  if (userGroup === 'veto') {
    const { loading, data, refetch } = useQuery<ListBreedingsVetoQuery, ListBreedingsQueryVariables>(
      listBreedingVetoQuery, { fetchPolicy },
    );
    // console.log(data);
    // console.log('useBreedingList breeding.ts:', data);
    // eslint-disable-next-line no-underscore-dangle
    if (data && data.listVetOffices && data.listVetOffices.items) {
      // console.log('data veto : ', data);
      return {
        loading,
        breedings: data.listVetOffices.items[0]?.breedings?.items as Breeding[],
        refetch,
      };
    }
  } else {
    const { loading, data, refetch } = useQuery<ListBreedingsQuery, ListBreedingsQueryVariables>(
      listBreedingQuery, { fetchPolicy },
    );
    // console.log(data);
    // console.log('useBreedingList breeding.ts:', data);
    // eslint-disable-next-line no-underscore-dangle
    if (data && data.listBreedings && data.listBreedings.items) {
      /**   Eleveur peut avoir qu'un SEUL elevage(breeding)  */

      return { loading, breeding: data.listBreedings.items[0] as Breeding, refetch };
    }
  }
  return { loading: true, breeding: undefined, refetch: () => {} };
}

export default function useCreateBreedingMutation() {
  const [createBreeding] = useMutation<
  CreateBreedingMutation,
  CreateBreedingMutationVariables>(gql(mutations.createBreeding));
  return { createBreeding };
}

type GetBreedingQuery = {
  getBreeding?: {
    __typename: 'Breeding',
    id: string,
    nBreeding: string,
    nSIRET: string,
    phone: string,
    locked: LockedStatus,
    emailEleveur: string,
    address?: {
      __typename: 'Address',
      address: string,
      additionalAddress?: string | null,
      postalCode: string,
      city: string,
    } | null,
    companyName: string,
    dairyFarm: boolean,
    sucklerCowHusbandry: boolean,
    fatteningFarm: boolean,
    nbCow: number,
    nbHeifer: number,
    nbMale: number,
    nbYoung: number,
    idVeto?: string | null,
    emailVeto?: string | null,
    admins: Array< string >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    nextSubscriptionDate?: string | null,
    subscriptionAmount?: number | null,
    users?: {
      __typename: 'ModelUserConnection',
      items?: Array< {
        __typename: 'User',
        id: string,
        lastname?: string | null,
        firstname?: string | null,
        avatarUri?: string | null,
        userGroup?: string | null,
        optIn?: boolean | null,
        inscriptionNumberOrdreVeto?: string | null,
        breedingId?: string | null,
        email?: string | null,
        expoToken?: Array< string > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    animals?: {
      __typename: 'ModelAnimalsConnection',
      items?: Array< {
        __typename: 'Animals',
        id: string,
        numberID: string,
        name?: string | null,
        gender?: GenderType | null,
        birthDate?: string | null,
        breedingId: string,

        lotsId?: string | null,
        lots?: {
          id: string,
          numberID: string,
          name?: string | null,
        } | null,

        zoneId?: string | null,
        buildingId?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    lots?: {
      __typename: 'ModelLotsConnection',
      items?: Array< {
        __typename: 'Lots',
        id: string,
        numberLot: string,
        name?: string | null,
        buildingId?: string | null,
        breedingId: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    buildings?: {
      __typename: 'ModelBuildingsConnection',
      items?: Array< {
        __typename: 'Buildings',
        id: string,
        nameBuilding: string,
        breedingId: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    bse?: {
      __typename: 'ModelBSEConnection',
      items?: Array< {
        __typename: 'BSE',
        id: string,
        breedingId: string,
        StatusBse?: StatusBse | null,
        dateBSE?: string | null,
        breeding?: Breeding | null,
        questionResponse?: string | null,
        questionResponseOrder?: Array< string | null > | null,
        maladieRecurente?: Array< string | null > | null,
        protocolesDeSoins?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    documents?: {
      __typename: 'ModelDocumentConnection',
      items?: Array< {
        __typename: 'Document',
        id: string,
        breedingId?: string | null,
        name: string,
        key?: string | null,
        s3file: string,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    pendingInvitations?: {
      __typename: 'ModelPendingInvitationConnection',
      items?: Array< {
        __typename: 'PendingInvitation',
        id: string,
        breedingId: string,
        emailCurrentUser: string,
        email: string,
        initial?: boolean | null,
        refusedAt?: string | null,
        acceptedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    consultations?: {
      __typename: 'ModelConsultationConnection',
      items?: Array< {
        __typename: 'Consultation',
        id: string,
        breedingId: string,
        animalId?: string | null,
        lotId?: string | null,
        diseaseId?: string | null,
        shared?: boolean | null,
        sharedAt?: string | null,
        autoExam?: boolean | null,
        teleconsultation?: boolean | null,
        priceConsultation?: number | null,
        factureId?: string | null,
        sharedFacture?: boolean | null,
        teleconsAskAt?: string | null,
        dateTeleCons?: string | null,
        questionData?: string | null,
        questionOrder?: Array< string | null > | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

const getBreedingQuery = gql(`
  query GetBreeding($id: ID!) {
    getBreeding(id: $id) {
      id
      nBreeding
      nSIRET
      phone
      locked
      emailEleveur
      address {
        address
        additionalAddress
        postalCode
        city
      }
      companyName
      dairyFarm
      sucklerCowHusbandry
      fatteningFarm
      nbCow
      nbHeifer
      nbMale
      nbYoung
      idVeto
      emailVeto
      admins
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      nextSubscriptionDate
      subscriptionAmount
      users (limit: 1000) {
        items {
          id
          lastname
          firstname
          avatarUri
          userGroup
          optIn
          inscriptionNumberOrdreVeto
          breedingId
          email
          expoToken
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      animals (limit: 1000) {
        items {
          id
          numberID
          name
          gender
          birthDate
          breedingId
          lotsId
          lots {
            id
            numberLot
            name
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          zoneId
          buildingId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      lots (limit: 1000) {
        items {
          id
          numberLot
          name
          buildingId
          breedingId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      buildings (limit: 1000) {
        items {
          id
          nameBuilding
          breedingId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      bse (limit: 1000, sortDirection: DESC) {
        items {
          id
          breedingId
          StatusBse
          dateBSE
          questionResponse
          questionResponseOrder
          maladieRecurente
          protocolesDeSoins
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      documents (limit: 1000) {
        items {
          id
          breedingId
          name
          key
          s3file
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
      }
      pendingInvitations (limit: 1000, sortDirection: DESC) {
        items {
          id
          breedingId
          emailCurrentUser
          email
          _version
          _deleted
          _lastChangedAt
          createdAt
          initial
          refusedAt
          acceptedAt
          updatedAt
        }
        nextToken
        startedAt
      }
      consultations(limit: 1000, sortDirection: DESC) {
        items {
          id
          breedingId
          animalId
          lotId
          diseaseId
          shared
          sharedAt
          autoExam
          teleconsultation
          priceConsultation
          factureId
          sharedFacture
          teleconsAskAt
          dateTeleCons
          questionResponse
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`);

export function useGetBreeding(id: string, fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network') {
  const {
    loading, data, fetchMore, refetch,
  } = useQuery<GetBreedingQuery, GetBreedingQueryVariables>(
    getBreedingQuery, {
      variables: {
        id,
      },
      fetchPolicy,
    },
  );
  // console.log('le breeding la :', data);
  return {
    loading, oneBreeding: data?.getBreeding, fetchMore, refetch,
  };
}

export function useUpdateBreedingMutation() {
  const [modifyBreeding, { loading: mutationBreedingLoading }] = useMutation<
  UpdateBreedingMutation, UpdateBreedingMutationVariables
  >(gql(updateBreeding));
  return { modifyBreeding, mutationBreedingLoading };
}

const listUserByBreedingQuery = <DocumentNode>gql(`
  query ListBreedings(
    $filter: ModelBreedingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBreedings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        nBreeding
        admins
        locked
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        users {
          items {
            email
            avatarUri
            expoToken
            firstname
            id
            lastname
            userGroup
            updatedAt
            createdAt
            _deleted
          }
          startedAt
          nextToken
      }
        

      }
      nextToken
      startedAt
    }
  }
`);
type ListUserByBreedingQuery = {
  listBreedings?: {
    __typename: 'ModelBreedingConnection',
    items?: Array< {
      __typename: 'Breeding',
      id: string,
      nBreeding: string,
      admins: Array< string >,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      users?: {
        __typename: 'ModelUserConnection',
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export function useListUserByBreeding(fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network') {
  const { loading, data, refetch } = useQuery<ListUserByBreedingQuery, ListBreedingsQueryVariables>(
    listUserByBreedingQuery, { fetchPolicy },
  );
  if (data && data.listBreedings && data.listBreedings.items && data.listBreedings.items[0]) {
    return {
      loading, usersInBreeding: <ModelUserConnection>data.listBreedings.items[0].users, refetch,
    };
  }
  return {
    loading, usersInBreeding: null, refetch,
  };
}

export function useBreedingListAdmin(
  locked: LockedStatus,
  filter?: ListBreedingByLockedByCreationDateQueryVariables['filter'],
  fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network',
) {
  const { loading, data, fetchMore } = useQuery<
  ListBreedingByLockedByCreationDateQuery,
  ListBreedingByLockedByCreationDateQueryVariables
  >(gql(listBreedingByLockedByCreationDate), {
    fetchPolicy,
    variables: {
      locked,
      filter,
      sortDirection: ModelSortDirection.DESC,
      limit: 10,
    },
  });
  if (data && data.listBreedingByLockedByCreationDate?.items) {
    return {
      loading,
      breedings: data.listBreedingByLockedByCreationDate?.items,
      nextToken: data.listBreedingByLockedByCreationDate.nextToken,
      fetchMore,
    };
  }

  return {
    loading,
    breedings: null,
    nextToken: null,
    fetchMore,
  };
}

export const onConfirmBreedingMandate = /* GraphQL */ `
  subscription OnConfirmBreedingMandate($id: ID!) {
    onConfirmBreedingMandate(id: $id) {
      id
      mpMandateConfirmed
    }
  }
`;

export type OnConfirmBreedingMandateSubscription = {
  onConfirmBreedingMandate?: {
    __typename: 'Breeding',
    id: string,
    mpMandateConfirmed?: boolean | null,
  } | null,
};
