import React, { useEffect, useState } from 'react';

import { API } from 'aws-amplify';
import { Text, useTheme } from '@ui-kitten/components';
import { useForm } from 'react-hook-form';
import { Alert, Platform, View } from 'react-native';
import { useDimensions } from '@react-native-community/hooks';
import gql from 'graphql-tag';
import { useSubscription } from 'react-apollo';
import MaxWidthContainer from '../../components/MaxWidthContainer';

import Button from '../../components/Button';
import DetailsScreensHeaderCard from '../../screenComponents/DetailsScreensHeaderCard';
import { useUser } from '../../src/API/UserContext';
import {
  onConfirmBreedingMandate,
  OnConfirmBreedingMandateSubscription,
  useBreedingList, useUpdateBreedingMutation,
} from '../../src/API/Breeding';
import Form from '../../components/Form/Form';
import FTextInput from '../../components/Form/TextInput';
import { AvailableValidationRules } from '../../components/Form/validation';
import ActionSheet from '../../components/ActionSheet/ActionSheet';
import WebView from '../../components/WebView';
import ActivityIndicator from '../../components/ActivityIndicator';
import BaseModal from '../../components/Modals/BaseModal';
import CustomModal from '../../components/Modals/CustomModal';
import Select from '../../components/Form/Select';
import { ALL_countries, EU_countries } from '../../src/mockData/selectData';
import DatepickerComp from '../../components/Form/DatePicker';
import { OnConfirmBreedingMandateSubscriptionVariables } from '../../src/API';
import { BicCodes } from '../../src/mockData/bicCodes';
import * as StringHelper from '../../utils/StringHelper';
import { IBANData } from '../../types/mangopay';
import Radio from '../../components/Form/Radio';
import DateUtils from '../../utils/DateUtils';
import { DocumentItem } from '../../src/API/Document';
import ItemTitle from '../../screenComponents/ItemTitle';
import DocumentComponent from '../../components/Document/DocumentComponent';
import DocumentBL from '../../components/Document/DocumentBL';
import TextInput from "../../components/Form/TextInput";
import {useCreatePendingInvitationMutation} from "../../src/API/PendingInvitation";

type EleveurIBAN = {
  iban: string,
  bic: string,
  nationality: string,
  birthday: string,
  nom:string,
  prenom:string,
  email:string,
  address1: string,
  address2: string,
  postalCode: string,
  city: string,
  emailVeto: string
};

const INJECTEDJAVASCRIPT = 'const meta = document.createElement(\'meta\'); meta.setAttribute(\'content\', \'width=device-width, initial-scale=1, maximum-scale=0.99, user-scalable=0\'); meta.setAttribute(\'name\', \'viewport\'); document.getElementsByTagName(\'head\')[0].appendChild(meta); ';

const ModePaiementScreen = () => {
  const { user } = useUser();
  const theme = useTheme();
  const { breeding, refetch } = useBreedingList(user?.userGroup);
  const eleveurIBAN = useForm<EleveurIBAN>();
  const [newMandateLink, setNewMandateLink] = useState<string | undefined>();
  const [addingMandate, setAddingMandate] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [modalUrl, setModalUrl] = useState<string | false>(false);
  const { window: rnWindow } = useDimensions();
  const eleveurIBANChange = useForm<EleveurIBAN>();
  const [bankInfos, setBankInfos] = useState<IBANData | false>(false);
  const [changingIban, setChangingIban] = useState(false);
  const { modifyBreeding } = useUpdateBreedingMutation();

  useEffect(() => {
    let isLoaded = true;

    if (breeding?.mpBankId) {
      API.get('scanflockrest', '/mangopay/get-bank-infos', {}).then((res) => {
        if (isLoaded) {
          if (res.success) {
            setBankInfos(res.bankInfos);
          } else {
            setBankInfos(false);
          }
        }
      });
    }
    return () => { isLoaded = false; };
  }, [breeding?.mpBankId]);

  useSubscription<
  OnConfirmBreedingMandateSubscription,
  OnConfirmBreedingMandateSubscriptionVariables
  >(gql(onConfirmBreedingMandate),
    {
      variables: { id: breeding?.id || 'z' },
      onSubscriptionData: (options) => {
        if (options.subscriptionData.data?.onConfirmBreedingMandate?.mpMandateConfirmed) {
          if (refetch) {
            refetch();
            setAddingMandate(false);
            setNewMandateLink(undefined);
            setChangingIban(false);
          }
        }
      },
    });

  const openUrl = (url: string) => {
    if (Platform.OS === 'web') {
      window.open(url);
    } else {
      setModalUrl(url);
    }
  };

  let EDE:DocumentItem;
  if (breeding && breeding.documents) {
    // eslint-disable-next-line no-underscore-dangle
    breeding.documents?.items?.map((item) => { if (item && !item._deleted && item.key && item.key.includes('EDE')) { EDE = item; } return {}; });
  }
  const { uploadCreateDocument } = DocumentBL.UseDocument();

  const { createPendingInvitationVeto } = useCreatePendingInvitationMutation();
  const sendInvitationEmail = async (emailVeto: string) => {
    if (user && user.email && breeding) {
      await createPendingInvitationVeto({
        variables: {
          input: {
            breedingId: breeding.id,
            emailCurrentUser: user.email,
            initial: true,
            email: emailVeto.toLowerCase(),
          },
        },
      });
    }
  };

  return (
    <MaxWidthContainer
      withScrollView="keyboardAware"
      outerViewProps={{
        style: {
          padding: 20,
          marginBottom: 20,
        },
      }}
    >
      {breeding && breeding.mpMandateId && (breeding.mpMandateConfirmed || changingIban) ? (
        <>
          <Text category="h2" appearance="hint" style={{ marginTop: 30 }}>Compte bancaire enregisté</Text>
          {bankInfos ? (
            <DetailsScreensHeaderCard
              title1="Nom du détenteur"
              titleInfo1={bankInfos.OwnerName}
              title2="Banque"
              titleInfo2={BicCodes[bankInfos.BIC] || 'Banque inconnue'}
              title3="IBAN"
              titleInfo3={StringHelper.formatIban(bankInfos.IBAN)}
            />
          ) : null}

          <Text style={{ marginTop: 30 }}>
            Vous souhaitez modifier vos informations bancaires ?
          </Text>
          {changingIban ? (

            <Form<EleveurIBAN>
              {...eleveurIBANChange}
            >
              <>
                <View>
                  <Text category="h6" appearance="hint">IBAN *</Text>
                  <FTextInput
                    name="iban"
                    placeholder="Saisissez votre IBAN"
                    maskOptions={{
                      type: 'custom',
                      options: { mask: 'FR99 9999 9999 99SS SSSS SSSS S99' },
                    }}
                    validators={[
                      AvailableValidationRules.iban,
                      AvailableValidationRules.required,
                    ]}
                  />
                  <Text category="h6" appearance="hint">BIC *</Text>
                  <FTextInput
                    name="bic"
                    placeholder="Saisissez votre BIC"
                    validators={[
                      AvailableValidationRules.required,
                    ]}
                  />
                </View>
              </>
            </Form>
          ) : null}
          <Button
            loading={processing}
            onPress={async () => {
              if (changingIban) {
                await eleveurIBANChange.handleSubmit(async (data) => {
                  setProcessing(true);
                  let w = null;
                  if (Platform.OS === 'web') {
                    w = window.open('');
                    if (w) {
                      w.document.write("<html><head></head><body>Merci d'attendre, nous chargeons votre mandat électronique de prélèvement.</body></html>");
                    }
                  }
                  const resp = await API.post('scanflockrest', '/mangopay/update-bank', { body: data });
                  if (resp.success) {
                    if (w) {
                      w.location.href = resp.returnUrl;
                    }
                    setNewMandateLink(resp.returnUrl);
                  }
                  setProcessing(false);
                }, (a) => {
                  console.log('error');
                  console.log(a);
                })();
              } else {
                setChangingIban(true);
              }
            }}
            leftIcon="edit-outline"
            leftIconFill={theme['color-primary-500']}
            appearance="outline"
            style={{ marginTop: 10 }}
          >
            Modifier les informations bancaires
          </Button>
          {changingIban ? (
            <Text style={{ flex: 1 }}>
              <Text>Les mandats de prélèvements sont gérés grâce à nos partenaires Mangopay et Gocardless. Vous pouvez consulter les politiques de confidentialité de Gocardless </Text>
              <Text onPress={() => openUrl('https://gocardless.com/fr-fr/legal/politique-de-confidentialite/')} style={{ textDecorationLine: 'underline' }}>ici</Text>
              <Text>.</Text>
            </Text>
          ) : null}
        </>
      ) : (
        <>
          <View style={{ marginBottom: 15 }}>
            <ItemTitle
              titleType="5"
              status="default"
              title="Document validation d'effectif du cheptel adulte *"
              description={"Dernière facture de cotisation GDS, ou dernière facture de prophylaxie, ou tout autre document officiel attestant sans ambiguïté l'effectif annoncé pour le troupeau adulte. Attention toute fausse déclaration d'effectif entraînera la suspension de votre adhésion et d'éventuelles poursuites (voir CGV)"}
            />
            {!EDE ? (
              <Button
                onPress={
                            () => uploadCreateDocument('EDE', refetch, breeding)
                          }
                leftIcon="plus-outline"
                appearance="ghost"
                style={{ alignSelf: 'flex-start' }}
              >
                Ajouter un document
              </Button>
            )
              : <Text>Merci d'avoir transmis votre document.</Text>}
          </View>
          <Form<EleveurIBAN>
            {...eleveurIBAN}
            defaultValues={{ }}
          >
            <>
              <View>
                <Text category="h6" appearance="hint">IBAN *</Text>
                <FTextInput
                  name="iban"
                  placeholder="Saisissez votre IBAN"
                  maskOptions={{
                    type: 'custom',
                    options: { mask: 'FR99 9999 9999 99SS SSSS SSSS S99' },
                  }}
                  validators={[
                    AvailableValidationRules.iban,
                    AvailableValidationRules.required,
                  ]}
                />
                <Text category="h6" appearance="hint">BIC *</Text>
                <FTextInput
                  name="bic"
                  placeholder="Saisissez votre BIC"
                  validators={[
                    AvailableValidationRules.required,
                  ]}
                />
                <Text category="h6" appearance="hint">Informations sur le représentant légal de l'élevage</Text>
                <Text category="h6" appearance="hint">Nom *</Text>
                <FTextInput
                  name="nom"
                  placeholder="Saisissez le nom du représentant légal"
                  validators={[
                    AvailableValidationRules.required,
                  ]}
                />
                <Text category="h6" appearance="hint">Prénom *</Text>
                <FTextInput
                  name="prenom"
                  placeholder="Saisissez le prénom du représentant légal"
                  validators={[
                    AvailableValidationRules.required,
                  ]}
                />
                <Text category="h6" appearance="hint">E-mail *</Text>
                <FTextInput
                  name="email"
                  placeholder="Saisissez l'e-mail du représentant légal"
                  validators={[
                    AvailableValidationRules.required,
                    AvailableValidationRules.email,
                  ]}
                />
                <Text category="h6" appearance="hint">Adresse *</Text>
                <FTextInput
                  name="address1"
                  placeholder="Saisissez l'adresse du représentant légal"
                  validators={[
                    AvailableValidationRules.required,
                  ]}
                />
                <Text category="h6" appearance="hint">Adresse (complément)</Text>
                <FTextInput
                  name="address2"
                  placeholder="Saisissez le complément d'adresse"
                />
                <Text category="h6" appearance="hint">Code postal *</Text>
                <FTextInput
                  name="postalCode"
                  placeholder="Saisissez le code postal"
                  validators={[
                    AvailableValidationRules.required,
                  ]}
                />
                <Text category="h6" appearance="hint">Ville *</Text>
                <FTextInput
                  name="city"
                  placeholder="Saisissez la ville"
                  validators={[
                    AvailableValidationRules.required,
                  ]}
                />
                <Text category="h6" appearance="hint">Nationalité *</Text>
                <Select
                  name="nationality"
                  placeholder="Choisissez la nationalité"
                  validators={[
                    AvailableValidationRules.required,
                  ]}
                  data={EU_countries}
                />
                <Text category="h6" appearance="hint">Date de naissance *</Text>
                <DatepickerComp
                  name="birthday"
                  placeholder="Saisissez la date de naissance"
                  validators={[
                    AvailableValidationRules.required,
                  ]}
                />
                <Text category="h6" appearance="hint">Lieu de naissance *</Text>
                <FTextInput
                  name="lieuNaissance"
                  placeholder="Saisissez le lieu de naissance"
                  validators={[
                    AvailableValidationRules.required,
                  ]}
                />
                <Text category="h6" appearance="hint">Pays de naissance *</Text>
                <Select
                  name="paysNaissance"
                  placeholder="Choisissez le pays de naissance"
                  validators={[
                    AvailableValidationRules.required,
                  ]}
                  data={ALL_countries}
                />
                <Text category="h6" appearance="hint">E-mail du vétérinaire sanitaire de l'élevage *</Text>
                <FTextInput
                  name="emailVeto"
                  placeholder="Saisissez l'adresse e-mail"
                  validators={[
                    AvailableValidationRules.required,
                    AvailableValidationRules.email,
                  ]}
                />
                <Text category="h6" appearance="hint">N° d'élevage *</Text>
                    <FTextInput
                      name="nBreeding"
                      placeholder="Saisissez votre numéro d'élevage"
                      validators={[
                        AvailableValidationRules.required,
                      ]}
                    />
                <Text category="h6" appearance="hint">Numéro SIREN de l'exploitation *</Text>
                    <FTextInput
                      name="nSIRET"
                      placeholder="Saisissez le numéro SIREN"
                      validators={[
                        AvailableValidationRules.required,
                      ]}
                    />
                <Text category="h5" style={{ marginTop: 20 }}>Effectif du cheptel *</Text>
                <Text category="h5" appearance="hint" style={{ marginVertical: 10 }}>Saisissez le nombre de bovins</Text>
                <View style={Platform.OS === 'web' && window.width > 780 && ({ flexDirection: 'row' })}>

                  <TextInput
                    name="nbCow"
                    placeholder="Vaches"
                    title="Vaches"
                    validators={[
                      AvailableValidationRules.required,
                    ]}
                  />
                  {/** Vache qui donne naissance */}
                  <TextInput
                    name="nbHeifer"
                    placeholder="Génisses"
                    keyboardType="numeric"
                    maxLength={5}
                    title="Génisses"
                    titleStyle={[Platform.OS === 'web' && window.width > 780 ? ({ marginLeft: 20 }) : ({ marginTop: 10 })]}
                    style={Platform.OS === 'web' && window.width > 780 && ({ marginLeft: 20 })}
                  />
                  <TextInput
                    name="nbMale"
                    placeholder="Mâles"
                    title="Mâles"
                    titleStyle={[Platform.OS === 'web' && window.width > 780 ? ({ marginLeft: 20 }) : ({ marginTop: 10 })]}
                    style={Platform.OS === 'web' && window.width > 780 && ({ marginLeft: 20 })}
                  />
                  <TextInput
                    name="nbYoung"
                    placeholder="Moins de 12 mois"
                    title="Moins de 12 mois"
                    titleStyle={[Platform.OS === 'web' && window.width > 780 ? ({ marginLeft: 20 }) : ({ marginTop: 10 })]}
                    style={Platform.OS === 'web' && window.width > 780 && ({ marginLeft: 20 })}
                  />
                </View>
              </View>
              <Radio
                name="acceptCGVMangoPay"
                label={(props) => (
                  <Text style={{ flex: 1 }}>
                    <Text {...props}>J'accepte </Text>
                    <Text {...props} onPress={() => openUrl('https://scanflock.com/conditions-generales-dutilisation/?simple=1')} style={{ textDecorationLine: 'underline' }}>les CGU</Text>
                    <Text {...props}> de notre partenaire de paiement Mangopay</Text>
                  </Text>
                )}
                labelPosition="after"
                validators={[
                  {
                    rule: AvailableValidationRules.required,
                    errorMessage: 'Vous devez accepter les CGU de notre partenaire de paiement Mangopay',
                  },
                ]}
              />
              <Button
                style={{ marginTop: 40 }}
                loading={processing}
                loadingText={"En cours d'enregistrement"}
                onPress={
                eleveurIBAN.handleSubmit(async (data) => {
                  setProcessing(true);
                  const birthday = DateUtils.parseToDateObj(data.birthday);
                  birthday.setHours(12);
                  const realData = {
                    ...data,
                    birthday: Math.round(birthday.getTime() / 1000),
                  };
                  let w = null;
                  if (Platform.OS === 'web') {
                    w = window.open('');
                    if (w) {
                      w.document.write("<html><head></head><body>Merci d'attendre, nous chargeons votre mandat électronique de prélèvement.</body></html>");
                    }
                  }

                  await modifyBreeding({
                    variables: {
                      input: {
                        id: breeding.id,
                        nbCow: data.nbCow,
                        nbMale: data.nbMale,
                        nbYoung: data.nbYoung,
                        nbHeifer: data.nbHeifer,
                        nSIRET: data.nSIRET,
                        nBreeding: data.nBreeding,
                        emailVeto: data.emailVeto,
                        // eslint-disable-next-line no-underscore-dangle
                        _version: breeding._version,
                      },
                    },
                  });

                  if (data.emailVeto) {
                    await sendInvitationEmail(data.emailVeto);
                  }

                  const resp = await API.post('scanflockrest', '/mangopay/create-user', { body: realData });
                  if (resp.success && resp.returnUrl) {
                    if (w) {
                      w.location.href = resp.returnUrl;
                    }
                    setNewMandateLink(resp.returnUrl);
                  }
                  setProcessing(false);
                })
              }
              >
                Ajouter mes informations de paiement
              </Button>
              <Text style={{ flex: 1 }}>
                <Text>Les mandats de prélèvements sont gérés grâce à nos partenaires Mangopay et Gocardless. Vous pouvez consulter les politiques de confidentialité de Gocardless </Text>
                <Text onPress={() => openUrl('https://gocardless.com/fr-fr/legal/politique-de-confidentialite/')} style={{ textDecorationLine: 'underline' }}>ici</Text>
                <Text>.</Text>
              </Text>
            </>
          </Form>
        </>
      )}
      {Platform.OS === 'web' ? (
        <CustomModal
          title="Signature du mandat électronique en cours. Merci de ne pas fermer cette fenêtre."
          before={<></>}
          noSafeArea
          visible={newMandateLink !== undefined || addingMandate}
          scrollable={false}
          onClose={() => {}}
          heightPercentage={Platform.OS === 'web' ? 0.36 : 0.6}
        />
      ) : (
        <>
          <ActionSheet
            title="Confirmation du mandat"
            before={<></>}
            noSafeArea
            scrollable={false}
            visible={newMandateLink !== undefined || addingMandate}
            onClose={() => {
              Alert.alert('Mandat de prélèvement',
                'Etes-vous sur de vouloir quitter sans confirmer votre mandat de prélèvement ?.',
                [{
                  text: 'Annuler',
                  style: 'cancel',
                },
                {
                  text: 'Valider',
                  onPress: async () => {
                    setNewMandateLink(undefined);
                    setAddingMandate(false);
                  },
                }]);
            }}
          >
            {newMandateLink && (
              <WebView
                src={newMandateLink}
                injectedJavaScript={INJECTEDJAVASCRIPT}
              />
            )}
            {addingMandate && (
              <View style={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
              }}
              >
                <ActivityIndicator />
              </View>
            )}
          </ActionSheet>
          <BaseModal
            visible={modalUrl !== false}
            onClose={() => setModalUrl(false)}
          >
            <View
              style={{
                backgroundColor: 'white',
                borderRadius: 10,
                height: rnWindow.height * 0.9,
                maxHeight: rnWindow.height * 0.9,
                width: rnWindow.width * 0.9,
                flex: 1,
                justifyContent: 'space-between',
                overflow: 'hidden',
                padding: 7,
              }}
            >
              <WebView src={modalUrl || ''} />
              <Button onPress={() => setModalUrl(false)}>Fermer</Button>
            </View>
          </BaseModal>
        </>
      )}
    </MaxWidthContainer>
  );
};

export default ModePaiementScreen;
