import { View } from 'react-native';
import React from 'react';
import { Text } from '@ui-kitten/components';
import Card from '../../components/Card';
import AutoAvatar from '../../components/AutoAvatar';
import { useUser } from '../../src/API/UserContext';
import { useGetUser } from '../../src/API/User';

const MonCompteHeaderCard = () => {
  const { user } = useUser();
  const { user: currentUser, loading } = useGetUser(user.id);
  // console.log('avatarUri Mon CompteHeader', currentUser);

  return (
    <Card>
      <View style={{ alignItems: 'center', marginTop: 20, marginBottom: 20 }}>
        <AutoAvatar
          // source={require('../../assets/test.png')}
          style={{
            height: 94,
            width: 94,
            marginRight: 12,
            marginLeft: 8,

            borderRadius: 50,
          }}
          turnOff={false}
          avatarInfo={(currentUser && currentUser.avatarUri) || 'default::ManAvatar'}
        />

      </View>

      <View style={{ paddingVertical: 11, borderTopWidth: 1, borderTopColor: '#b5b5b5' }}>
        <Text category="h6" appearance="hint">Nom</Text>
        <Text category="h4">{`${currentUser?.firstname || undefined} ${currentUser?.lastname || undefined}`}</Text>
      </View>
      <View style={{ paddingTop: 11, borderTopWidth: 1, borderTopColor: '#b5b5b5' }}>
        <Text category="h6" appearance="hint">E-mail</Text>
        <Text category="h4">{user?.email || undefined}</Text>
      </View>
    </Card>
  );
};

export default MonCompteHeaderCard;
