/**
 *
 *
 * @author: David-Julian Buch
 */

import * as React from 'react';
import {
  View, StyleSheet, Modal, Platform,
} from 'react-native';

import { Text, useTheme } from '@ui-kitten/components';
import { useEffect, useState } from 'react';
import { useDimensions } from '@react-native-community/hooks';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { StatusBar } from 'expo-status-bar';
import RtcConfigure from './RTCConfigure';
import MaxWidthContainer from '../MaxWidthContainer';
import EndCall from './Controls/EndCall';
import LocalAudioMute from './Controls/LocalAudioMute';
import LocalVideoMute from './Controls/LocalVideoMute';
import LocalUserContext from './LocalUserContext';
import { VetoView } from './VetoView';
import { EleveurView } from './EleveurView';
import { VideoMode } from './RtcContext';
import SwitchCamera from './Controls/SwitchCamera';
import ShowMoreButton from './Controls/ShowMoreButton';
import MoreButtons from './Controls/MoreButtons';
import MuteWaring from './MuteWarning';
import { AdditionalInfos, AdditionalInfosState } from './AdditionalInfos';
import FlashLight from './Controls/FlashLight';
import { useIsTablet } from '../../utils/CustomHooks';
import SafeAreaView from '../SafeAreaView';

export type VideoConfProps = {
  channel: string
  visible?: boolean
  mode?: VideoMode,
  onClose?: () => void
};

export default function VideoConf(props: VideoConfProps): JSX.Element {
  const {
    channel,
    visible = false,
    mode = VideoMode.ELEVEUR,
    onClose,
  } = props;

  const [visibleModalState, setVisibleModal] = useState(false);
  const [showAdditional, setShowAdditional] = useState<AdditionalInfosState | undefined>(undefined);
  const theme = useTheme();

  useEffect(() => {
    setVisibleModal(visible);
    return () => {
      setVisibleModal(false);
    };
  }, [visible]);

  const { window } = useDimensions();
  const insets = useSafeAreaInsets();

  const isTabletFormat = useIsTablet();
  return (
    <Modal
      visible={visibleModalState}
      style={{
        margin: 0,
        ...StyleSheet.absoluteFillObject,
        height: window.height,
        paddingTop: insets.top,
      }}
    >
      <>
        <SafeAreaView style={{ backgroundColor: '#192328', flex: 1 }} top>
          {visible ? (
            <>
              <StatusBar
                hidden
                translucent
              />
              <RtcConfigure channel={channel} appId="b8481b4b314a455697b5c9a30acd1052" enableAudio={false}>
                <View style={{ flex: 1, paddingHorizontal: isTabletFormat ? 15 : 0 }}>
                  <View style={{
                    flex: 1, flexGrow: 2, flexDirection: 'row', justifyContent: 'center', height: '100%',
                  }}
                  >
                    <View
                      style={{
                        flex: 1, flexGrow: 2, maxWidth: 1027,
                      }}
                    >
                      <View style={{ height: 70, alignItems: 'flex-end', flexDirection: 'row-reverse' }}>
                        {Platform.OS !== 'web' && (
                          <>
                            <SwitchCamera />
                            <FlashLight />
                          </>
                        )}
                      </View>
                      <View style={{
                        flex: 1,
                        flexGrow: 2,
                      }}
                      >
                        <View style={{
                          flex: 1,
                          backgroundColor: '#FFFFFF',
                          borderRadius: isTabletFormat ? 15 : 0,
                          alignItems: 'center',
                          justifyContent: 'center',
                          overflow: 'hidden',
                        }}
                        >
                          <EleveurView mode={mode} />
                        </View>
                        <View style={{
                          width: isTabletFormat ? 195 : 141,
                          height: isTabletFormat ? 146 : 106,
                          backgroundColor: 'white',
                          position: 'absolute',
                          top: isTabletFormat ? 31 : null,
                          bottom: !isTabletFormat ? 38 : null,
                          right: isTabletFormat ? 31 : 23,
                          alignItems: 'center',
                          justifyContent: 'center',
                          zIndex: 10,
                        }}
                        >
                          <VetoView mode={mode} />
                        </View>
                      </View>
                    </View>
                    {showAdditional && isTabletFormat && (
                      <View style={{
                        backgroundColor: theme['background-color'],
                        flex: 1,
                        maxWidth: 367,
                        marginTop: 70,
                        marginLeft: 15,
                        borderRadius: 15,
                      }}
                      >
                        <AdditionalInfos
                          channel={channel}
                          state={showAdditional}
                          onClose={() => setShowAdditional(undefined)}
                        />
                      </View>
                    )}
                  </View>
                  <MaxWidthContainer
                    maxWidth={1027}
                    withScrollView={false}
                    outerViewProps={{ style: { flex: undefined } }}
                    innerViewProps={{ style: { paddingBottom: 0, minHeight: undefined } }}
                  >
                    <LocalUserContext>
                      <View style={{
                        flexDirection: 'row',
                        justifyContent: isTabletFormat ? 'space-between' : 'center',
                        alignItems: 'flex-start',
                        alignContent: 'center',
                        height: isTabletFormat ? 50 * 2 + 80 : 120,
                        paddingTop: isTabletFormat ? 50 : 20,
                      }}
                      >
                        <MuteWaring />
                        {isTabletFormat && (<View style={{ height: 80, alignItems: 'center', flexDirection: 'row' }}><Text category="h4" style={{ color: 'white', width: 225 }}>Téléconsultation</Text></View>)}
                        <View style={{
                          flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center',
                        }}
                        >
                          <LocalAudioMute />
                          <LocalVideoMute />
                          <EndCall onPress={() => {
                            setVisibleModal(false);
                            if (onClose) {
                              onClose();
                            }
                          }}
                          />
                          {!isTabletFormat && <ShowMoreButton setState={(state) => setShowAdditional(state)} />}
                        </View>

                        {isTabletFormat && (
                          <MoreButtons setState={(state) => setShowAdditional(state)} />
                        )}
                      </View>
                    </LocalUserContext>
                  </MaxWidthContainer>
                </View>
              </RtcConfigure>
            </>
          ) : null}
        </SafeAreaView>
        {showAdditional && !isTabletFormat && (
          <View style={{
            backgroundColor: 'rgba(0,0,0,0.5)',
            flex: 1,
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
          >
            <View style={{
              backgroundColor: theme['background-color'],
              flex: 1,
              marginTop: 100,
              borderTopRightRadius: 15,
              borderTopLeftRadius: 15,
            }}
            >
              <AdditionalInfos
                channel={channel}
                state={showAdditional}
                onClose={() => setShowAdditional(undefined)}
              />
            </View>
          </View>
        )}
      </>
    </Modal>
  );
}
