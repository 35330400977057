import React from 'react';

import { Text } from '@ui-kitten/components';
import { useLinkTo } from '@react-navigation/native';
import MaxWidthContainer from '../../components/MaxWidthContainer';

import MonCompteHeaderCard from '../../screenComponents/MonCompteScreenComps/MonCompteHeaderCard';

import Button from '../../components/Button';
import GoCustomCard from '../../components/GoCustomCard';

const AccMonCompteScreen = () => {
  const linkTo = useLinkTo();
  return (
    <MaxWidthContainer
      withScrollView="keyboardAware"
      outerViewProps={{
        style: {
          padding: 20,
          // marginBottom: 20,
        },
      }}
    >
      <MonCompteHeaderCard />

      <Button
        onPress={() => { linkTo('/mon-compte/modifier-compte'); }}
        leftIcon="edit-outline"
        leftIconFill="#298A91"
        appearance="outline"
        style={{ marginTop: 15 }}
      >
        Modifier mes informations
      </Button>

      <Text category="s2" style={{ marginTop: 30 }}>Ajoutez les utilisateurs et donnez leur un accès à votre élevage</Text>

      <GoCustomCard
        title3="Utilisateurs de l'élevage"
        linkToCustom={() => {
          linkTo('/mon-compte/utilisateurs');
        }}
        minusSquareOutline="arrow-ios-forward"
      />

      <Text category="s2">Ajoutez ou modifiez votre mode de paiement</Text>

      <GoCustomCard
        title3="Mode de paiement"
        linkToCustom={() => {
          linkTo('/mon-compte/mode-paiement');
        }}
        minusSquareOutline="arrow-ios-forward"
      />

      <Text category="s2">Consultez et téléchargez vos factures en sélectionnant la date et l'échéance</Text>
      <GoCustomCard
        title3="Factures"
        linkToCustom={() => {
          linkTo('/mon-compte/factures');
        }}
        minusSquareOutline="arrow-ios-forward"
      />

    </MaxWidthContainer>
  );
};

export default AccMonCompteScreen;
