import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';
import { VetoTabElevageParamList } from '../../types';

import VetoElevageScreen from '../../screens/VetScreens/ElevagesScreen/VetoElevageScreen';
import VetoDetailsElevageScreen from '../../screens/VetScreens/VetoDetailsElevageScreen';
import VetoAjoutElevageScreen from '../../screens/VetScreens/VetoAjoutElevageScreen';
import VetoHistoriqueBseScreen from '../../screens/VetScreens/VetoHistoriqueBseScreen';
import VetoDetailsBseScreen from '../../screens/VetScreens/VetoDetailsBseScreen';
import VetoEffectifElevage from '../../screens/VetScreens/ElevagesScreen/VetoEffectifElevage';
import VoirPlusScreen from '../../screens/ElevageScreen/VoirPlusScreen';
import VetoDetailAnimalScreen from '../../screens/VetScreens/ElevagesScreen/VetoDetailAnimalScreen';
import VetoDetailLotScreen from '../../screens/VetScreens/ElevagesScreen/VetoDetailLotScreen';
import VetoDetailBatimentScreen from '../../screens/VetScreens/ElevagesScreen/VetoDetailBatimentScreen';

const Stack = createNativeStackNavigator<VetoTabElevageParamList>();

export default () => (
  <Stack.Navigator
    initialRouteName="veto-elevage"
    screenOptions={{
      headerShown: false,
    }}
  >
    <Stack.Screen
      name="veto-elevage"
      component={VetoElevageScreen}
    />
    <Stack.Screen
      name="veto-effectif-elevage"
      component={VetoEffectifElevage}
    />
    <Stack.Screen
      name="veto-details-elevage"
      component={VetoDetailsElevageScreen}
    />
    <Stack.Screen
      name="veto-detail-animal"
      component={VetoDetailAnimalScreen}
    />
    <Stack.Screen
      name="veto-detail-lot"
      component={VetoDetailLotScreen}
    />
    <Stack.Screen
      name="veto-detail-batiment"
      component={VetoDetailBatimentScreen}
    />
    <Stack.Screen
      name="veto-details-bse"
      component={VetoDetailsBseScreen}
    />

    <Stack.Screen
      name="veto-voir-plus-animal"
      component={VoirPlusScreen}
    />
    <Stack.Screen
      name="veto-voir-plus-lot"
      component={VoirPlusScreen}
    />
    <Stack.Screen
      name="veto-voir-plus-batiment"
      component={VoirPlusScreen}
    />

    <Stack.Screen
      name="veto-ajout-elevage"
      component={VetoAjoutElevageScreen}
    />
    <Stack.Screen
      name="veto-historique-bse"
      component={VetoHistoriqueBseScreen}
    />

  </Stack.Navigator>
);
