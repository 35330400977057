import {
  Alert, Platform, TouchableOpacity, View,
} from 'react-native';
import {
  CheckBox, Radio, Text, useTheme,
} from '@ui-kitten/components';
import React, {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import { useRoute } from '@react-navigation/native';
import { Icon as IconUIKitten } from '@ui-kitten/components/ui/icon/icon.component';
import * as Updates from 'expo-updates';
import * as math from 'mathjs';
import _ from 'lodash';
import Card from '../Card';
import DiseaseDetailsChildPage
  from '../../screenComponents/ConsultationScreenComps/DiseaseDetailsChildPage';
import ActionSheet from '../ActionSheet/ActionSheet';
import WebView from '../WebView';
import Button from '../Button';
import CustomModal from '../Modals/CustomModal';
import { useUser } from '../../src/API/UserContext';
import InfoModal from '../InfoModal';
import { useCustomState } from '../../utils/CustomHooks';

type AnswerItemProps = {
  name: string;
  description: string;
  image: string;
  interdire: boolean;
  veto: boolean;
  imposer: boolean;
  checked: boolean;
  showNextQuestion: boolean;
  answerType?: string;
  answerText?: string;
  formule?:string;
  minVert?: string;
  maxVert?: string;
  minOrange?: string;
  maxOrange?: string;
  minRouge?: string;
  maxRouge?: string;
};

export type AnswerProps = { answer:[AnswerItemProps];
  onChecked?: (
    questionId: string, item: string, checked: boolean, trueCheck: boolean
  ) => void;
  onModify?: (questionId:string, answerId:string, currentChecked?: boolean) => void;
  setFunctionResult?: (result: string, idQuestion: string) => void;
  calculateResult?: (functionData: string) => any;
  getBgColor?: (questionId:string, answerId:string, value: string) => string | false;
  multipleAnswer: boolean;
  locked?: boolean;
  readonly?: boolean;
  lastChecked?: number;
  idQuestion: string;
};

const AnswerCard = (props: AnswerProps) => {
  const {
    idQuestion, answer, getBgColor, onChecked, setFunctionResult, calculateResult, locked, onModify, readonly = false,
  } = props;
  const idAnswer = Object.keys(answer);
  const route = useRoute();
  const theme = useTheme();
  const [bgColor, setBgColor] = useState<string | false>(false);
  const { isVeto } = useUser();
  useEffect(() => {
    idAnswer.map((answerId) => {
      if (answer[answerId].answerType === 'F' && setFunctionResult && calculateResult) {
        const result = calculateResult(answer[answerId].formule);
        answer[answerId].answerText = result;
        setFunctionResult(answer[answerId].answerText, answerId);
        setColorsBG(answerId, answer[answerId].answerText);
      }
      if (answer[answerId].checked && onChecked) {
        onChecked(
          idQuestion, answerId, answer[answerId].checked, false,
        );
      }
      return 0;
    });

    return () => {

    };
  }, []);
  const setColorsBG = (answerId: string, answerText : string) => {
    if (getBgColor) {
      const color = getBgColor(idQuestion, answerId, answerText);
      setBgColor(color);
    }
  };
  const onpress = (answerId: string, isOpen: boolean = false, nextChecked: boolean = false) => {
    if (answer[answerId].answerType !== 'F' && !readonly) {
      if (locked && !isOpen) {
        setOpenModal(answerId);
      } else if (onChecked && !answer[answerId].answerType) {
        onChecked(
          idQuestion, answerId, nextChecked, true,
        );
      }
    }
  };
  const [openModal, setOpenModal] = useCustomState<string>();
  return (
    <>
      {(
    idAnswer.map((answerItem) => (
      answerItem && !answer[answerItem].interdire && !(answer[answerItem].bseOnly && !isVeto) && (answer[answerItem].name || locked || answer[answerItem].answerType === 'F') && (
        <View key={answerItem}>
          {/* <TouchableOpacity> */}
          <Card
            style={{
              flexDirection: 'row', marginVertical: 5, marginLeft: 55, backgroundColor: (answer[answerItem].checked && (answer[answerItem].name || answer[answerItem].answerText)) || answer[answerItem].answerType === 'F' ? (bgColor || theme['xlight-grey']) : 'white',
            }}
            onPress={() => { if (!(answer[answerItem].interdire || answer[answerItem].imposer || route.params.update === 'false')) { onpress(answerItem, false, !answer[answerItem].checked); } }}
            withOpacity
          >
            {!answer[answerItem].answerType && (
              props.multipleAnswer ? (

                <CheckBox
                  style={{ marginRight: 10 }}
                  status="basic"
                  checked={answer[answerItem].checked}
                  disabled={answer[answerItem].imposer || route.params.update === 'false'}
                  onChange={(nextChecked) => {
                    if (!(answer[answerItem].interdire || route.params.update === 'false')) {
                      onpress(answerItem, false, nextChecked);
                    }
                  }}
                />
              ) : (
                <Radio
                  style={{ marginRight: 10 }}
                  checked={answer[answerItem].checked}
                  disabled={answer[answerItem].imposer || route.params.update === 'false'}
                  onChange={(nextChecked) => {
                    if (!(answer[answerItem].interdire || route.params.update === 'false')) {
                      onpress(answerItem, false, nextChecked);
                    }
                  }}
                />
              )
            )}

            <View style={{ flexDirection: 'row', justifyContent: 'space-between', flex: 1 }}>
              <Text category="h3"
                appearance={(answer[answerItem].checked && (answer[answerItem].name || answer[answerItem].answerText)) || answer[answerItem].answerType === 'F' ? 'default' : 'hint'}
                style={{flex:1}}
              >
                {answer[answerItem].answerType === 'F' ? parseFloat(answer[answerItem].answerText).toFixed(answer[answerItem].round ?? 0).replace('.', ',') + (answer[answerItem].suffix ?? '') : (answer[answerItem].name ?? (answer[answerItem].answerText || 'Question ignorée, cliquez ici pour y revenir.'))}
              </Text>
              {answer[answerItem].description ? (
                <InfoModal infoText={answer[answerItem].description} infoTitle="Informations sur la réponse" />
              ) : null}
            </View>
          </Card>

        </View>
      )
    )))}
      <CustomModal
        title="Vous êtes sur le point de modifier le questionnaire et de recommencer a cette question"
        before={<></>}
        noSafeArea
        visible={openModal !== undefined}
        scrollable={false}
        onClose={() => { setOpenModal(undefined); }}
        insideModalStyle={{ padding: 20 }}
        buttonText="Valider la modification"
        secondButtonText="Annuler la modification"
        heightPercentage={0.36}
        buttonPress={() => {
          if (onModify && openModal) {
            const currentOpenModal = openModal;
            setOpenModal(undefined, () => {
              onModify(idQuestion, currentOpenModal, !answer[currentOpenModal].checked);
            });
          }
        }}
        secondButtonPress={() => { setOpenModal(undefined); }}
      />
    </>
  );
};
const areEqual = (prevProps: AnswerProps, nextProps: AnswerProps) => {
  if (prevProps.locked === nextProps.locked
      && prevProps.lastChecked === nextProps.lastChecked
  ) {
    return true; // donot re-render
  }
  return false; // will re-render
};

export default React.memo(AnswerCard, areEqual);
