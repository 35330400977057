import { Text, useTheme } from '@ui-kitten/components';
import React, { useEffect, useState } from 'react';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import { API } from 'aws-amplify';
import CompButton from '../../../components/Button';
import Card from '../../../components/Card';
import VetoMonCabinetScreenBL
  from '../../../screenBusinessLogic/vetoScreensBL/MonCabinetScreenBL/VetoMonCabinetScreenBL';
import { useGetVetOffice } from '../../../src/API/VetOffice';
import { useUser } from '../../../src/API/UserContext';
import Formatter from '../../../utils/Formatter';
import InfoCard from '../../InfoCard';

const VetoPaiementCard = () => {
  const theme = useTheme();
  const { vetOfficeData } = useGetVetOffice();
  const { jwt } = useUser();
  const [walletAmount, setWalletAmount] = useState<number | false>(false);

  useEffect(() => {
    let isLoaded = true;

    if (vetOfficeData?.mpWalletId) {
      API.get('scanflockrest', '/mangopay/get-wallet-amount', {}).then((res) => {
        if (isLoaded) {
          if (res.success) {
            setWalletAmount(res.amount);
          } else {
            setWalletAmount(false);
          }
        }
      });
    }
    return () => { isLoaded = false; };
  }, [vetOfficeData?.mpWalletId]);

  const linkTo = useLinkTo();
  return (
    <Card style={{ alignItems: 'center' }}>
      {vetOfficeData?.mpWalletId ? (
        <>
          <Text
            category="s2"
            style={{
              marginBottom: 20, borderBottomColor: '#e4e4e4', borderBottomWidth: 1, paddingBottom: 20,
            }}
          >
            Retrouvez ici le total des paiements suite aux consultations réalisés au sein
            de l'application. Vous pouvez transférer le solde sur votre compte bancaire.
          </Text>

          {walletAmount !== false ? (
            <>
              <Text>
                Montant disponible
              </Text>
              <Text category="h1" style={{ marginTop: 10 }}>{Formatter.currencyFormatter.format(walletAmount / 100)}</Text>
            </>
          ) : null}

          <CompButton
            size="medium"
            status="primary"
        // appearance="outline"
            onPress={() => { VetoMonCabinetScreenBL.allerMonCompteScreen({ state: 'compte-bancaire', linkTo }); }}
            style={{ marginTop: 30, backgroundColor: theme['color-primary-700'] }}
          >
            Transférer vers votre compte bancaire
          </CompButton>
          <InfoCard
            title="alertInfo"
            iconShow="alert-circle-outline"
              // iconCloseShow="close-outline"
            description="Vous pouvez modifier vos informations bancaires depuis votre espace « Mon Cabinet »"
            alertInfo="alertInfo"
            infoStyle={{ marginTop: 10 }}
          />
        </>
      ) : (
        <>
          <Text
            category="s2"
          >
            Pour recevoir des paiements à travers l'application, vous devez d'abord renseigner vos coordonnées bancaires.
          </Text>
          <CompButton
            size="medium"
            status="primary"
                // appearance="outline"
            onPress={() => { VetoMonCabinetScreenBL.allerMonCompteScreen({ state: 'compte-bancaire', linkTo }); }}
            style={{ marginTop: 30, backgroundColor: theme['color-primary-700'] }}
          >
            Renseigner vos coordonnées bancaires
          </CompButton>
        </>
      )}
    </Card>
  );
};

export default VetoPaiementCard;
