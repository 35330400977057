import React, { useContext } from 'react';
import { Icon as IconUIKitten } from '@ui-kitten/components/ui/icon/icon.component';
import { TouchableOpacity } from 'react-native';
import { Text } from '@ui-kitten/components';
import RtcContext, { DispatchType } from '../RtcContext';

function SwitchCamera() {
  const { dispatch } = useContext(RtcContext);
  return (
    <TouchableOpacity
      style={{
        flexDirection: 'row',
        alignItems: 'center',
      }}
      onPress={() => {
        (dispatch as DispatchType<'SwitchCamera'>)({
          type: 'SwitchCamera',
          value: [],
        });
      }}
    >
      <Text category="h6" style={{ color: 'white' }}>Changer de camera</Text>
      <IconUIKitten name="sync-outline" style={{ height: 30, width: 30, margin: 20 }} fill="white" />
    </TouchableOpacity>
  );
}

export default SwitchCamera;
