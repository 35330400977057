import React from 'react';
import MaxWidthContainer from '../../components/MaxWidthContainer';
import ListPrebilanBilan from '../../screenComponents/BilanSanitaireScreenComps/ListPrebilanBilan';

const PrebilanQuestionnaireScreen = () => (
  <MaxWidthContainer
    withScrollView={false}
    innerViewProps={{
      style: {
        paddingBottom: 0,
        flexGrow: 1,
      },
    }}
    outerViewProps={{
      style: {
        flexGrow: 1,
      },
    }}
  >
    <ListPrebilanBilan />
  </MaxWidthContainer>
);

export default PrebilanQuestionnaireScreen;
