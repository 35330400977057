import { Image, ImageSourcePropType, ImageStyle } from 'react-native';
import React, { useEffect, useState } from 'react';
import { useDimensions } from '@react-native-community/hooks';
import { useIsTablet } from '../utils/CustomHooks';

export type InfoCardImageProps = {
  source: ImageSourcePropType | string,
  maxWidth?: number,
  maxHeight?: number,
  maxWidthTablet?: number,
  maxHeightTablet?: number,
  imageStyle?: ImageStyle
};

const InfoCardImage = (props: InfoCardImageProps) => {
  const {
    source, maxHeight = 200, maxHeightTablet = 200, maxWidth = 150, maxWidthTablet = 180, imageStyle,
  } = props;
  const isTabletFormat = useIsTablet();
  const { window } = useDimensions();

  const [dimension, setDimension] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    let canSetState = true;
    if (typeof source === 'string') {
      Image.getSize(source, (width, height) => {
        if (canSetState) {
          setDimension({
            width, height,
          });
        }
      });
    } else {
      const res = Image.resolveAssetSource(source);
      if (canSetState) {
        setDimension({
          width: res.width, height: res.height,
        });
      }
    }
    return () => {
      canSetState = false;
    };
  }, [source]);

  const width = Math.min(isTabletFormat ? maxWidthTablet : maxWidth, window.width * 0.30);
  const height = isTabletFormat ? maxHeightTablet : maxHeight;

  const ratioX = dimension.width / width;
  const ratioY = dimension.height / height;
  const ratio = Math.max(ratioX, ratioY);

  const newWidth = ratio > 0 ? dimension.width / ratio : dimension.width;
  const newHeight = ratio > 0 ? dimension.height / ratio : dimension.height;

  return (<Image resizeMode="contain" style={{ height: newHeight, width: newWidth, ...imageStyle }} source={source} />);
};

export default InfoCardImage;
