import {
  Icon, Text,
} from '@ui-kitten/components';
import React, { useEffect, useState } from 'react';
import * as Sharing from 'expo-sharing';
import {
  Platform, View,
} from 'react-native';
import * as FileSystem from 'expo-file-system';
import * as MediaLibrary from 'expo-media-library';
import moment from 'moment';
import Card from './Card';
import ActivityIndicator from './ActivityIndicator';
import { BillingHistory } from '../src/API';
import DateUtils from '../utils/DateUtils';
import Formatter from '../utils/Formatter';
import pdfGenerator from '../utils/pdfGenerator/pdfGenerator';
import { templateFacture } from './templateFacture';

type FactureProps = {
  billingHistory?: BillingHistory,
};

const FactureComponent = (props: FactureProps) => {
  const {
    billingHistory,
  } = props;

  const [sharing, setSharing] = useState(false);

  async function getMediaLibraryPermissions() {
    await MediaLibrary.requestPermissionsAsync();
  }

  useEffect(() => {
    getMediaLibraryPermissions();
  });

  const shareDoc = async () => {
    if (document) {
      setSharing(true);
      let win;
      if (Platform.OS === 'web') {
        win = window.open('');
      }
      try {
        const totalHT = Math.round(billingHistory.amount / 1.2 * 100) / 100;
        const totalTTC = billingHistory.amount;
        const totalTaxes = totalTTC - totalHT;

        const html = templateFacture
          .replace(/\{\{societe\}\}/g, billingHistory?.breeding?.companyName || billingHistory?.vetOffice?.companyName)
          .replace(/\{\{address\}\}/g, billingHistory?.breeding?.address.address || billingHistory?.vetOffice?.address.address)
          .replace(/\{\{address2\}\}/g, billingHistory?.breeding?.address.additionalAddress || billingHistory?.vetOffice?.address.additionalAddress || '')
          .replace(/\{\{postalCode\}\}/g, billingHistory?.breeding?.address.postalCode || billingHistory?.vetOffice?.address.postalCode)
          .replace(/\{\{city\}\}/g, billingHistory?.breeding?.address.city || billingHistory?.vetOffice?.address.city)
          .replace(/\{\{nFacture\}\}/g, billingHistory.invoiceNumber)
          .replace(/\{\{date\}\}/g, DateUtils.convertDate(billingHistory.createdAt))
          .replace(/\{\{label\}\}/g, billingHistory.label)
          .replace(/\{\{totalHT\}\}/g, Formatter.currencyFormatter.format(totalHT))
          .replace(/\{\{totalTTC\}\}/g, Formatter.currencyFormatter.format(totalTTC))
          .replace(/\{\{totalTaxes\}\}/g, Formatter.currencyFormatter.format(totalTaxes));
        const result = await pdfGenerator(html);
        // console.log('result', result);
        if (result !== false) {
          const name = `Facture_${billingHistory.invoiceNumber}_${moment(DateUtils.parseToDateObj(billingHistory.createdAt)).format('YYYY-MM-DD')}.pdf`;
          if (Platform.OS === 'web') {
            win.document.location.href = result.uri;
          } else {
            const fileUri = FileSystem.documentDirectory + name;
            await FileSystem.writeAsStringAsync(fileUri, result.base64, { encoding: FileSystem.EncodingType.Base64 });
            await Sharing.shareAsync(fileUri);
          }
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Document Component error : ', e);
      } finally {
        setSharing(false);
      }
    }
  };

  return (billingHistory ? (
    <Card
      onPress={() => { shareDoc(); }}
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 15,
      }}
    >
      <View style={[{ flex: 1 }]}>
        <Text category="h3">
          Facture n°
          {billingHistory.invoiceNumber}
          {' '}
          -
          {' '}
          {DateUtils.convertDate(billingHistory.createdAt)}
        </Text>
        <Text category="h6" appearance="hint">{Formatter.currencyFormatter.format(billingHistory.amount)}</Text>
      </View>

      {sharing ? <ActivityIndicator style={{ height: 24, width: 24 }} />
        : <Icon name="download-outline" fill="#000" style={{ height: 24, width: 24 }} />}

    </Card>
  ) : (<></>)
  );
};

export default FactureComponent;
