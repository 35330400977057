import React, { useState } from 'react';
import { Text, useTheme } from '@ui-kitten/components';
import { Image, useWindowDimensions } from 'react-native';

import { RenderHTML } from 'react-native-render-html';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import * as WebBrowser from 'expo-web-browser';
import MaxWidthContainer from '../../components/MaxWidthContainer';
import Button from '../../components/Button';
import InfoCard from '../InfoCard';
import CustomRatingBar from '../../components/Modals/CustomRatingBar';
import CustomModal from '../../components/Modals/CustomModal';
import { useGetDisease, useGetDiseaseByCodeIdentification } from '../../src/API/Disease';
import { useUpdateConsultationMutation } from '../../src/API/Consultation';
import ActionSheet from '../../components/ActionSheet/ActionSheet';
import { useBseListByBreedingId } from '../../src/API/BSE';
import { useGetProtocolSoinSaveByIdDiseaseFamilyAndBseId } from '../../src/API/ProtocolSoin';
import { Consultation } from '../../src/API';

type DiseaseDetailsProps = {
  linkTo: (path:string) => void,
  consultationId? : string
  diseaseId?: string
  consultation?: Consultation
  onSaved?: () => void,
  isStack?: boolean,
  canSave?: boolean,
};

const DiseaseDetailsChildPage = (props: DiseaseDetailsProps) => {
  const {
    onSaved, linkTo, consultationId, diseaseId, consultation, isStack, canSave = true,
  } = props;
  const theme = useTheme();
  let disease;
  if (isStack) {
    disease = useGetDiseaseByCodeIdentification(diseaseId).disease;
  } else {
    disease = useGetDisease(diseaseId).disease;
  }

  const { modifyConsultation } = useUpdateConsultationMutation();

  const [rating, setRating] = useState(4);

  const [openDiseaseModal, setOpenDiseaseModal] = useState<string>();
  // console.log('list de diseases : ', disease);
  const [state, setState] = useState();

  const { bse } = useBseListByBreedingId(consultation?.breedingId, false, true);

  const { psSave } = useGetProtocolSoinSaveByIdDiseaseFamilyAndBseId(disease?.familleMaladieId?.toString(), bse?.id);

  let maladieReccurente = false;
  let typeMaladie = 'Pathologie non récurrente';
  if (bse && disease?.familleMaladieId && bse?.maladieRecurente?.indexOf(disease.familleMaladieId.toString()) > -1) {
    typeMaladie = 'Pathologie récurrente';
    maladieReccurente = true;
  }
  if (psSave) {
    typeMaladie = 'Pathologie du Protocole de Soins';
    maladieReccurente = true;
  }

  const [opened, setOpened] = useState<string[]>([]);

  const { width } = useWindowDimensions();
  const insets = useSafeAreaInsets();
  // console.log('opened:', opened);
  const saveDisease = async () => {
    if (disease) {
      await modifyConsultation({
        variables: {
          input: {
            id: consultationId,
            diseaseId: disease?.id,
            note: rating,
            // eslint-disable-next-line no-underscore-dangle
            _version: consultation?._version,
          },
        },
      });
    }
  };

  const allerSaisirDiseaseResultScreen = () => {
    if (onSaved) {
      onSaved();
    }
    linkTo(`/consultation/questionnaire/list-disease/saisir-disease-result/${consultationId}/${disease.codeIdentification}`);
  };

  const toggle = (description: string) => {
    // console.log('toggle descrip:', description);
    const x = opened;
    if (x.includes(description)) {
      setOpened(opened.filter((each) => each !== description));
      // console.log('x :', x);
    } else {
      // console.log('ddd:', description);
      x.push(description);
      // console.log('x 2: ', x);
      setOpened(x);
      setOpened([...opened]);
      // console.log('o:', opened);
    }
  };

  const tagsStyles = {
    p: {
      marginLeft: 10,
    },
    ul: {
      marginLeft: 10,
    },
  };

  const onPress = (event, href: string) => {
    const codeID = href.split('///');
    if (codeID[1]) {
      setOpenDiseaseModal(codeID[1]);
    } else {
      WebBrowser.openBrowserAsync(codeID[0]);
    }
  };

  const renderersProps = {
    a: {
      onPress,
    },
  };

  return (
    <>
      <MaxWidthContainer
        withScrollView="keyboardAware"
        outerViewProps={{
          style: {
            padding: 20,
          },
        }}
        innerViewProps={{
          style: {
            paddingTop: 30,
            marginBottom: insets.bottom + 40,
          },
        }}
      >
        <Text category="h1">{disease && disease.name}</Text>
        <Text category="h2"> 1. La maladie en bref : définition – synonymes – enjeux – causes </Text>

        {typeMaladie ? (
          <Button
            status="basic"
            leftIcon="clock-outline"
            style={{ backgroundColor: theme['color-primary-300'], marginTop: 15 }}
            onPress={() => {
              if (psSave) {
                toggle('PS');
              }
            }}
          >
            {typeMaladie}
          </Button>
        ) : null}
        {psSave && opened.includes('PS') ? (
        <Text>{psSave.Protocol}</Text>
        ) : null}
        {disease ? (
        <>
          {disease.description ? (
          <>
            <InfoCard
              title="alertInfo"
              infoStyle={{ marginTop: 20, marginBottom: 0 }}
              iconShow="arrow-ios-downward-outline"
              description="1.1. Définition"
              alertInfo="alertInfo"
              opened={opened.includes('1.1. Définition')}
              onPressCard={() => toggle('1.1. Définition')}
            />
            {opened.includes('1.1. Définition') ? (
            <RenderHTML
              source={{ html: `${replaceAll(disease.description)}` }}
              tagsStyles={tagsStyles}
              contentWidth={width}
              renderersProps={renderersProps}
            />
            ) : null}
          </>
          ) : null}

          {disease.synonymes ? (
          <>
            <InfoCard
              title="alertInfo"
              infoStyle={{ marginTop: 20, marginBottom: 0 }}
              iconShow="arrow-ios-downward-outline"
              description="1.2. Synonymes"
              alertInfo="alertInfo"
              opened={opened.includes('1.2. Synonymes')}
              onPressCard={() => toggle('1.2. Synonymes')}
            />
            {opened.includes('1.2. Synonymes') ? (
            <RenderHTML
              source={{ html: `${replaceAll(disease.synonymes)}` }}
              tagsStyles={tagsStyles}
              contentWidth={width}
              renderersProps={renderersProps}
            />
            ) : null}
          </>
          ) : null}
          {disease.enjeux ? (
          <>
            <InfoCard
              title="alertInfo"
              infoStyle={{ marginTop: 20, marginBottom: 0 }}
              iconShow="arrow-ios-downward-outline"
              description="1.3. Enjeux"
              alertInfo="alertInfo"
              opened={opened.includes('1.3. Enjeux')}
              onPressCard={() => toggle('1.3. Enjeux')}
            />
            {opened.includes('1.3. Enjeux') ? (
            <RenderHTML
              source={{ html: `${replaceAll(disease.enjeux)}` }}
              tagsStyles={tagsStyles}
              contentWidth={width}
              renderersProps={renderersProps}
            />
            ) : null}
          </>
          ) : null}
          {disease.causes ? (
          <>
            <InfoCard
              title="alertInfo"
              infoStyle={{ marginTop: 20, marginBottom: 0 }}
              iconShow="arrow-ios-downward-outline"
              description="1.4. Causes"
              alertInfo="alertInfo"
              opened={opened.includes('1.4. Causes')}
              onPressCard={() => toggle('1.4. Causes')}
            />
            {opened.includes('1.4. Causes') ? (
            <RenderHTML
              source={{ html: `${replaceAll(disease.causes)}` }}
              tagsStyles={tagsStyles}
              contentWidth={width}
              renderersProps={renderersProps}
            />
            ) : null}
          </>
          ) : null}

          <Text category="h2" style={{ marginTop: 20 }}>2. Diagnostic</Text>

          {disease.circonstancesApparition ? (
          <>
            <InfoCard
              title="alertInfo"
              infoStyle={{ marginTop: 20, marginBottom: 0 }}
              iconShow="arrow-ios-downward-outline"
              description="2.1. Circonstances d’apparition et symptômes"
              alertInfo="alertInfo"
              opened={opened.includes('2.1. Circonstances d’apparition et symptômes')}
              onPressCard={() => toggle('2.1. Circonstances d’apparition et symptômes')}
            />
            {opened.includes('2.1. Circonstances d’apparition et symptômes') ? (
            <RenderHTML
              source={{ html: `${replaceAll(disease.circonstancesApparition)}` }}
              tagsStyles={tagsStyles}
              contentWidth={width}
              renderersProps={renderersProps}
            />
            ) : null}
          </>
          ) : null}

          {disease.diagnosticDiff ? (
          <>
            <InfoCard
              title="alertInfo"
              infoStyle={{ marginTop: 20, marginBottom: 0 }}
              iconShow="arrow-ios-downward-outline"
              description="2.2. Différentiel"
              alertInfo="alertInfo"
              opened={opened.includes('2.2. Différentiel')}
              onPressCard={() => toggle('2.2. Différentiel')}
            />
            {opened.includes('2.2. Différentiel') ? (

            <RenderHTML
              source={{ html: `${replaceAll(disease.diagnosticDiff)}` }}
              tagsStyles={tagsStyles}
              contentWidth={width}
              renderersProps={renderersProps}
            />
            ) : null}
          </>
          ) : null}

          {disease.diagnosticLab ? (
          <>
            <InfoCard
              title="alertInfo"
              infoStyle={{ marginTop: 20, marginBottom: 0 }}
              iconShow="arrow-ios-downward-outline"
              description="2.3. Examens complémentaires"
              alertInfo="alertInfo"
              opened={opened.includes('2.3. Examens complémentaires')}
              onPressCard={() => toggle('2.3. Examens complémentaires')}
            />
            {opened.includes('2.3. Examens complémentaires') ? (
            <>
              <RenderHTML
                source={{ html: `${replaceAll(disease.diagnosticLab)}` }}
                tagsStyles={tagsStyles}
                contentWidth={width}
                renderersProps={renderersProps}
              />
              <Button appearance="outline" style={{ marginBottom: 20 }} onPress={() => { allerSaisirDiseaseResultScreen(); }}>Saisir les résultats</Button>
            </>
            ) : null}
          </>
          ) : null}

          {maladieReccurente ? (
            <>
              <Text category="h2" style={{ marginTop: 20 }}>3. Maîtrise</Text>

              {disease.traitementCuratif ? (
              <>
                <InfoCard
                  title="alertInfo"
                  infoStyle={{ marginTop: 20, marginBottom: 0 }}
                  iconShow="arrow-ios-downward-outline"
                  description="3.1 Traitement curatif"
                  alertInfo="alertInfo"
                  opened={opened.includes('3.1 Traitement curatif')}
                  onPressCard={() => toggle('3.1 Traitement curatif')}
                />
                {opened.includes('3.1 Traitement curatif') ? (
                <RenderHTML
                  source={{ html: `${replaceAll(disease.traitementCuratif)}` }}
                  tagsStyles={tagsStyles}
                  contentWidth={width}
                  renderersProps={renderersProps}
                />
                ) : null}
              </>
              ) : null}

              {disease.pronostic ? (
              <>
                <InfoCard
                  title="alertInfo"
                  infoStyle={{ marginTop: 20, marginBottom: 0 }}
                  iconShow="arrow-ios-downward-outline"
                  description="3.2 Pronostic"
                  alertInfo="alertInfo"
                  opened={opened.includes('3.2 Pronostic')}
                  onPressCard={() => toggle('3.2 Pronostic')}
                />
                {opened.includes('3.2 Pronostic') && disease.pronostic ? (
                <RenderHTML
                  source={{ html: `${replaceAll(disease.pronostic)}` }}
                  tagsStyles={tagsStyles}
                  contentWidth={width}
                  renderersProps={renderersProps}
                />
                ) : null}
              </>
              ) : null}

              {disease.prevention ? (
              <>
                <InfoCard
                  title="alertInfo"
                  infoStyle={{ marginTop: 20, marginBottom: 0 }}
                  iconShow="arrow-ios-downward-outline"
                  description="3.3 Prévention"
                  alertInfo="alertInfo"
                  opened={opened.includes('3.3 Prévention')}
                  onPressCard={() => toggle('3.3 Prévention')}
                />
                {opened.includes('3.3 Prévention') ? (
                <RenderHTML
                  source={{ html: `${replaceAll(disease.prevention)}` }}
                  tagsStyles={tagsStyles}
                  contentWidth={width}
                  renderersProps={renderersProps}
                />
                ) : null}
              </>
              ) : null}
            </>
          ) : null}

        </>
        ) : null}

      </MaxWidthContainer>
      {canSave
        ? (
          <Button
            style={{
              position: 'absolute', bottom: 10 + insets.bottom, marginHorizontal: 20, alignSelf: 'center',
            }}
            onPress={() => setState('open custom modal')}
          >
            Sélectionner cette maladie
          </Button>
        ) : null}
      <CustomModal
        title={'Vous êtes sur le point de confirmer votre sélection.\n'
            + 'Etes-vous sûr de vouloir continuer ?'}
        before={<></>}
        noSafeArea
        visible={state !== undefined}
        scrollable={false}
        onClose={() => { setState(undefined); }}
        insideModalStyle={{ padding: 20 }}
        buttonText="Confirmer ma sélection"
        secondButtonText="Annuler"
        buttonPress={() => {
          // update desease and consultation for affect them
          saveDisease();
          setState(undefined);
          onSaved();
          linkTo(`/consultation/detail-consultation/${consultationId}`);
        }}
      >
        <CustomRatingBar onRate={(v) => setRating(v)} />
      </CustomModal>
      <ActionSheet
        before={<></>}
        noSafeArea
        scrollable={false}
        heightPercentage={0.85}
        visible={openDiseaseModal !== undefined}
        onClose={() => setOpenDiseaseModal(undefined)}
      >
        {openDiseaseModal !== undefined && (
        <DiseaseDetailsChildPage
          linkTo={linkTo}
          onSaved={() => { setOpenDiseaseModal(undefined); }}
          consultationId={consultationId}
          diseaseId={openDiseaseModal}
          consultation={consultation}
          isStack
        />
        )}
      </ActionSheet>
    </>
  );
};

const replaceAll = (t: string) => {
  const regex = /{#(.+?)\(#([a-zA-Z0-9-_ ]+)#\)#}/gm;
  const regex2 = /((«) | (»))/gm;

  return t.replace(regex, (s, g1, g2) => `<a href="${g2}">${g1}</a>`).replace(regex2, (s, g1, g2, g3) => `${g2 || ''}&nbsp;${g3 || ''}`);
};

export default DiseaseDetailsChildPage;
