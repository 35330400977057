import React, { useEffect, useState } from 'react';
import { useLinkTo } from '@react-navigation/native';
import { TextInput, TouchableOpacity, View } from 'react-native';
import {
  Button, Text, Icon, Spinner,
} from '@ui-kitten/components';
import { BSE, ProtocolSoin } from '../../../src/API';
import {
  useCreateProtocolSoinSaveMutation,
  useDeleteProtocolSoinSave,
  useGetProtocolSoinByIdDiseaseFamily,
  useGetProtocolSoinSaveByIdDiseaseFamilyAndBseId,
  useUpdateProtocolSoinSave,
} from '../../../src/API/ProtocolSoin';
import Card from '../../../components/Card';
import CustomModal from '../../../components/Modals/CustomModal';
import RotatingIcon from '../../../components/Icon/RotatingIcon';

type PSProps = {
  idMaladiePS: string
  bse?: BSE
  notEdit?: boolean
};
const PSCard = (props: PSProps) => {
  const { idMaladiePS, bse, notEdit } = props;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalAnnuler, setOpenModalAnnuler] = useState<boolean>(false);
  const [editPS, setEditPS] = useState<string>();
  const [showPS, setShowPS] = useState<boolean>(false);
  const [height, setHeight] = useState(42);
  const [newPSInfo, setNewPSInfo] = useState<{ id?: string, _version?: number }>({ id: undefined, _version: undefined });

  let ps: ProtocolSoin = {};
  const { psDefault } = useGetProtocolSoinByIdDiseaseFamily(idMaladiePS);
  const { psSave, refetch } = useGetProtocolSoinSaveByIdDiseaseFamilyAndBseId(idMaladiePS, bse.id);
  const { createProtocolSoinSave } = useCreateProtocolSoinSaveMutation();
  const { modifyProtocolSoinSave } = useUpdateProtocolSoinSave();
  const { deleteProtocolSoinSave } = useDeleteProtocolSoinSave();

  useEffect(() => {
    if (psSave) {
      setNewPSInfo({ id: psSave.id, _version: psSave._version });
    }
    return () => {
    };
  }, [psSave]);

  useEffect(() => {
    if (psSave) {
      ps = psSave;
      setPsIsValidate(true);
    } else {
      ps = psDefault;
    }
    if (!ps) {
      ps = {
        id: '',
        idDiseaseFamily: idMaladiePS,
        name: 'Maladie à définir',
        new: true,
      };
    }
    setTextInput(ps ? ps.Protocol : '');
    setNewTextInput(ps ? ps.Protocol : '');
    setNameTextInput(ps ? ps.name : '');
    setNewNameTextInput(ps ? ps.name : '');
    return () => {
    };
  }, [psDefault, psSave]);

  const [psScanflock] = useState(psDefault ? psDefault.Protocol : '');
  if (psSave) {
    ps = psSave;
  } else {
    ps = psDefault;
  }
  if (!ps) {
    ps = {
      id: '',
      idDiseaseFamily: idMaladiePS,
      name: 'Maladie à définir',
      new: true,
    };
  }
  // console.log(ps);
  const [textInput, setTextInput] = useState(ps ? ps.Protocol : '');
  const [newTextInput, setNewTextInput] = useState(ps ? ps.Protocol : '');
  const [nameTextInput, setNameTextInput] = useState(ps ? ps.name : '');
  const [newNameTextInput, setNewNameTextInput] = useState(ps ? ps.name : '');
  const [psIsValidate, setPsIsValidate] = useState(false);

  const edit = () => (
    <Icon name="edit-outline" fill="#2b4f9e" style={{ height: 25, width: 25 }} />
  );
  const annuler = () => (
    <Icon name="close-outline" fill="#2b4f9e" style={{ height: 25, width: 25 }} />
  );
  const recharger = () => (
    <Icon name="refresh" fill="#2b4f9e" style={{ height: 25, width: 25 }} />
  );
  const validerPS = async () => {
    setTextInput(newTextInput);
    setNameTextInput(newNameTextInput);
    setEditPS(undefined);
    setPsIsValidate(true);
    if (newPSInfo.id) {
      await modifyProtocolSoinSave({
        variables: {
          input: {
            id: newPSInfo.id,
            name: ps.id === '' || ps.new ? newNameTextInput : ps.name,
            _version: newPSInfo._version,
            Protocol: newTextInput,
          },
        },
      }).then((thisPs) => setNewPSInfo({ id: thisPs.data?.updateProtocolSoinSave?.id, _version: thisPs.data?.updateProtocolSoinSave?._version }));
    } else {
      await createProtocolSoinSave({
        variables: {
          input: {
            name: ps.id === '' || ps.new ? newNameTextInput : ps.name,
            idDiseaseFamily: ps.idDiseaseFamily,
            breedingId: bse.breedingId,
            bseId: bse.id,
            Protocol: newTextInput,
            new: ps.new,
          },
        },
      }).then((thisPs) => setNewPSInfo({ id: thisPs.data?.createProtocolSoinSave?.id, _version: thisPs.data?.createProtocolSoinSave?._version }));
    }
  };

  const annulerPS = async () => {
    setPsIsValidate(false);
    await deleteProtocolSoinSave({
      variables: {
        input: {
          id: newPSInfo.id,
          _version: newPSInfo._version,
        },
      },
    });
    refetch();
    setNewPSInfo({ id: undefined, _version: undefined });
  };

  const reinitialiserPS = async () => {
    setNewTextInput(psScanflock);
    if (psSave || psIsValidate) {
      await modifyProtocolSoinSave({
        variables: {
          input: {
            id: newPSInfo.id,
            _version: newPSInfo._version,
            Protocol: psScanflock,
          },
        },
      }).then((thisPs) => setNewPSInfo({ id: thisPs.data?.updateProtocolSoinSave?.id, _version: thisPs.data?.updateProtocolSoinSave?._version }));
    }
  };

  return (
    <>
      {ps ? (
        <>
          <Card
            style={{
              padding: 10, marginBottom: 20, backgroundColor: psIsValidate ? '#F2F2F2' : 'white', borderWidth: psIsValidate ? 1 : 0, borderColor: '#4C76BA', borderStyle: 'solid',
            }}
          >
            <TouchableOpacity
              onPress={() => {
                setShowPS(!showPS);
              }}
              style={{ flexDirection: 'row' }}
            >
              <View style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: 10,
                flexWrap: 'wrap',
                flex: 1,
              }}
              >
                <View>
                  <Text category="h4">{nameTextInput || ''}</Text>
                </View>
                <View style={{ flexDirection: 'row' }}>
                  {!notEdit ? (psIsValidate ? (
                    <Text category="h4">PROTOCOLE DE SOIN VALIDÉ</Text>
                  ) : (
                    <Text category="h4">PROTOCOLE DE SOIN À VALIDER</Text>
                  )) : null}
                  <RotatingIcon
                    name="arrow-ios-downward-outline"
                    uikitten
                    state={showPS}
                    width={20}
                    height={20}
                    fill="#000000"
                  />
                </View>

              </View>
            </TouchableOpacity>
            {showPS && (
              editPS ? (
                <View style={{ padding: 20 }}>
                  {ps.id === '' || ps.new
                    ? (
                      <TextInput
                        style={{ height, backgroundColor: !psIsValidate ? '#F2F2F2' : 'white', marginBottom: 10 }}
                        value={newNameTextInput}
                        onChangeText={(text) => setNewNameTextInput(text)}
                      />
                    )
                    : null}
                  <TextInput
                    style={{ height, backgroundColor: !psIsValidate ? '#F2F2F2' : 'white' }}
                    multiline
                    onContentSizeChange={(e) => setHeight(e.nativeEvent.contentSize.height)}
                    value={newTextInput}
                    onChangeText={(text) => setNewTextInput(text)}
                  />
                  <View style={{
                    flexDirection: 'row', justifyContent: 'space-between', flex: 1, flexWrap: 'wrap', marginTop: 10,
                  }}
                  >
                    <Button appearance="filled" onPress={() => { setOpenModal(true); }}>Valider le protocole de soin</Button>
                    <Button accessoryLeft={annuler} appearance="ghost" onPress={() => { setNewTextInput(textInput); setNewNameTextInput(nameTextInput); setEditPS(undefined); }}>Annuler</Button>
                    <Button accessoryLeft={recharger} appearance="ghost" onPress={() => reinitialiserPS()}>Réinitialiser le protocole de soin</Button>
                  </View>
                </View>
              ) : (
                <View style={{ padding: 20 }}>
                  <Text>
                    {textInput || ''}
                  </Text>
                  {!notEdit && (
                  <View style={{
                    flexDirection: 'row', justifyContent: psIsValidate ? 'space-between' : 'center', flex: 1, flexWrap: 'wrap',
                  }}
                  >
                    {psIsValidate && (
                    <Button status="danger" onPress={() => setOpenModalAnnuler(true)}>Annuler le protocole de soins</Button>
                    )}
                    <Button accessoryLeft={edit} appearance="outline" onPress={() => setEditPS(ps.idDiseaseFamily)}>Editer le protocole de soins</Button>

                  </View>
                  )}
                </View>
              )

            ) }
          </Card>
          <CustomModal
            title="Valider le Protocole de soin et choisir cette famille de maladie? "
            before={<></>}
            noSafeArea
            visible={openModal}
            scrollable={false}
            onClose={() => { setOpenModal(false); }}
            insideModalStyle={{ padding: 20 }}
            buttonText="Valider le choix"
            secondButtonText="Annuler"
            heightPercentage={0.36}
            buttonPress={() => {
              validerPS();
              setOpenModal(false);
              setShowPS(false);
            }}
            secondButtonPress={() => { setOpenModal(false); }}
          />

          <CustomModal
            title="Annuler le protocole de soins"
            subtitle={"Le fait d'annuler le protocole de soin ne réinitialise pas ce dernier mais vos modifications ne seront pas enregistré"}
            noSafeArea
            visible={openModalAnnuler}
            scrollable={false}
            onClose={() => { setOpenModalAnnuler(false); }}
            insideModalStyle={{ padding: 20 }}
            buttonText="Valider le choix"
            secondButtonText="Annuler"
            heightPercentage={0.36}
            buttonPress={() => {
              annulerPS();
              setOpenModalAnnuler(false);
              setShowPS(false);
            }}
            secondButtonPress={() => { setOpenModalAnnuler(false); }}
          />
        </>
      ) : (
        <View style={{
          flex: 1,
          flexDirection: 'row',
          alignContent: 'center',
          justifyContent: 'center',
          padding: 5,
        }}
        >
          <Spinner />
          <Text style={{ marginLeft: 10 }}>Chargement du protocole de soin en cours</Text>
        </View>
      )}

    </>
  );
};

export default PSCard;
