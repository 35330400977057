import React, { useEffect, useState } from 'react';
import { useLinkTo, useRoute } from '@react-navigation/native';
import { View } from 'react-native';
import { Text } from '@ui-kitten/components';
import { Icon as IconUIKitten } from '@ui-kitten/components/ui/icon/icon.component';
import Card from '../../components/Card';
import ActionSheet from '../../components/ActionSheet/ActionSheet';
import DiseaseDetailsChildPage from './DiseaseDetailsChildPage';
import { disease, Consultation } from '../../src/API';
import { verifyDiseas } from '../../screenBusinessLogic/ConsultationScreen/ConsulationFicheMaladie';
import { useBseListByBreedingId } from '../../src/API/BSE';
import {
  useGetProtocolSoinSaveByIdDiseaseFamilyAndBseId,
} from '../../src/API/ProtocolSoin';

type DiseaseProps = {
  currentDisease: disease
  consultation?: Consultation,
  canSave?:boolean
};
const DiseaseCard = (props: DiseaseProps) => {
  const { currentDisease, consultation, canSave } = props;

  const linkTo = useLinkTo();
  const [openDiseaseModal, setOpenDiseaseModal] = useState<string>();
  const route = useRoute();
  const [prob, setProb] = useState(undefined);
  const { bse } = useBseListByBreedingId(consultation?.breedingId, false, true);

  const { psSave } = useGetProtocolSoinSaveByIdDiseaseFamilyAndBseId(currentDisease?.familleMaladieId?.toString(), bse?.id);

  let typeMaladie = 'Pathologie non récurrente';
  if (bse && currentDisease.familleMaladieId && bse?.maladieRecurente.indexOf(currentDisease.familleMaladieId.toString()) > -1) {
    typeMaladie = 'Pathologie récurrente';
  }
  if (psSave) {
    typeMaladie = 'Pathologie du Protocole de Soins';
  }

  useEffect(() => {
    if (consultation && currentDisease.codeIdentification && consultation.diseaseIsVerified && consultation.diseaseIsVerified.includes(currentDisease.codeIdentification)) {
      const parsedQuesAnsCons = JSON.parse(consultation?.questionResponse);
      setProb(verifyDiseas(parsedQuesAnsCons, currentDisease));
    }
    return () => {

    };
  }, [currentDisease]);

  const OpenDetailsDisease = () => {
    setOpenDiseaseModal(true);
  };

  return (
    <>
      <Card
        onPress={() => { OpenDetailsDisease(); }}
        style={{ padding: 0, marginBottom: 20 }}
      >
        <View style={{
          flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: 10,
        }}
        >
          <View>
            <Text category="h4">{currentDisease.name || ''}</Text>
            {currentDisease.familleMaladie && (
              <Text category="h6" appearance="hint" style={{ marginTop: 7 }}>{currentDisease.familleMaladie}</Text>
            )}
            <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 7 }}>
              <IconUIKitten
                name="clock-outline"
                fill="black"
                style={{
                  // backgroundColor: 'orange',
                  height: 24,
                  width: 24,
                  borderRadius: 16,
                  marginRight: 5,
                }}
              />

              <Text category="c1">{typeMaladie}</Text>
            </View>

          </View>
          <IconUIKitten
            name="plus-outline"
            fill="black"
            style={{
              // backgroundColor: 'orange',
              height: 24,
              width: 24,
              borderRadius: 16,
              marginRight: 5,
            }}
          />
        </View>
        {prob && (
          <View style={{
            flexDirection: 'row', padding: 10, borderTopColor: '#e4e4e4', borderTopWidth: 1,
          }}
          >
            <Text category="c1" style={{ marginRight: 10 }}>Probabilité</Text>
            <Text category="c1" status="danger">{prob}</Text>
          </View>
        )}
        {(currentDisease.MRLC || currentDisease.maladieUrgente) && (
          <View style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            borderTopWidth: 1,
            borderTopColor: '#b5b5b5',
            padding: 10,
          }}
          >
            <IconUIKitten
              name="alert-triangle-outline"
              fill="#e30101"
              style={{
                height: 24,
                width: 24,
                borderRadius: 16,
                marginRight: 5,
              }}
            />
            {currentDisease.maladieUrgente && (
            <Text category="c1">Maladie urgente</Text>
            )}

            {currentDisease.MRLC && (
            <Text category="c1" style={{ textAlign: 'center', marginBottom: 10 }}>MRLC (Maladie réputée contagieuse)</Text>

            )}
          </View>
        )}

      </Card>

      <ActionSheet
        before={<></>}
        noSafeArea
        scrollable={false}
        heightPercentage={0.92}
        visible={openDiseaseModal !== undefined}
        onClose={() => setOpenDiseaseModal(undefined)}
      >
        {openDiseaseModal !== undefined && (
        <DiseaseDetailsChildPage
          linkTo={linkTo}
          onSaved={() => { setOpenDiseaseModal(undefined); }}
          consultationId={route?.params?.id}
          diseaseId={currentDisease.id}
          consultation={consultation}
          canSave={canSave}
        />
        )}
      </ActionSheet>
    </>
  );
};

export default DiseaseCard;
