import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';

import { VetoTabMonCabinetParamList } from '../../types';
import VetoMonCabinetScreen from '../../screens/VetScreens/MonCabinetScreen/VetoMonCabinetScreen';
import VetoMonCompteScreen from '../../screens/VetScreens/MonCabinetScreen/VetoMonCompteScreen';
import VetoModifierMonCompteScreen from '../../screens/VetScreens/MonCabinetScreen/VetoModifierMonCompteScreen';
import VetoUtilisateursScreen from '../../screens/VetScreens/MonCabinetScreen/VetoUtilisateursScreen';
import VetoAjoutUtilisateursScreen from '../../screens/VetScreens/MonCabinetScreen/VetoAjoutUtilisateurScreen';
import VetoTarifConsultationScreen from '../../screens/VetScreens/MonCabinetScreen/VetoTarifConsultationsScreen';
import VetoFacturesScreen from '../../screens/VetScreens/VetoFacturesScreen';
import ModifyPasswordScreen from '../../screens/AccountScreens/ModifyPasswordScreen';
import InscriptionEtape2Screen from '../../screens/AccountScreens/InscriptionEtape2Screen';
import VetoCompteBancaire from '../../screens/VetScreens/MonCabinetScreen/VetoCompteBancaire';

const Stack = createNativeStackNavigator<VetoTabMonCabinetParamList>();

const VetoMonCabinetStackNavigator = () => (
  <Stack.Navigator
    initialRouteName="veto-mon-cabinet"
    screenOptions={{ headerShown: false }}
  >
    <Stack.Screen
      name="veto-mon-cabinet"
      component={VetoMonCabinetScreen}
    />
    <Stack.Screen
      name="veto-mon-compte"
      component={VetoMonCompteScreen}
    />
    <Stack.Screen
      name="veto-modifier-mon-compte"
      component={VetoModifierMonCompteScreen}
    />
    <Stack.Screen
      name="veto-modify-password"
      component={ModifyPasswordScreen}
    />
    <Stack.Screen
      name="veto-modify-info-perso"
      component={InscriptionEtape2Screen}
    />

    <Stack.Screen
      name="veto-utilisateurs"
      component={VetoUtilisateursScreen}
    />
    <Stack.Screen
      name="veto-details-utilisateur"
      component={VetoMonCompteScreen}
    />
    <Stack.Screen
      name="veto-ajout-utilisateurs"
      component={VetoAjoutUtilisateursScreen}
    />

    <Stack.Screen
      name="veto-modify-utilisateurs"
      component={VetoAjoutUtilisateursScreen}
    />
    <Stack.Screen
      name="veto-tarif-consultation"
      component={VetoTarifConsultationScreen}
    />
    <Stack.Screen
      name="veto-facture"
      component={VetoFacturesScreen}
    />
    <Stack.Screen
      name="infos-bancaires"
      component={VetoCompteBancaire}
    />

  </Stack.Navigator>
);

export default VetoMonCabinetStackNavigator;
