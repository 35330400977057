import React, { useEffect, useState } from 'react';
import { useFocusEffect, useLinkTo, useNavigation } from '@react-navigation/native';
import { Text, useTheme } from '@ui-kitten/components';

import MaxWidthContainer from '../../components/MaxWidthContainer';

import SearchComponent from '../../screenComponents/SearchComponent';
import ButtonGroup from '../../components/ButtonGroup';
import InfoCard from '../../screenComponents/InfoCard';
import CompButton from '../../components/Button';
import AnimalCard from '../../screenComponents/AnimalCard';
import GoCustomCard from '../../components/GoCustomCard';
import DetailsScreensHeaderCard from '../../screenComponents/DetailsScreensHeaderCard';

import ElevageScreenBL from '../../screenBusinessLogic/ElevageScreen/ElevageScreenBL';

import { useUser } from '../../src/API/UserContext';
import { useBreedingList } from '../../src/API/Breeding';
import Tutorial from '../../components/Tutorial';

export default function ElevageScreen() {
  const linkTo = useLinkTo();
  const navigation = useNavigation();
  const theme = useTheme();
  const { user } = useUser();

  /** Switching between lots, animals and buildings sections   */
  const [state, setState] = useState('animaux');

  const { breeding, refetch } = useBreedingList(user?.userGroup);

  const ede = breeding?.documents?.items?.find(
    // eslint-disable-next-line no-underscore-dangle
    (item) => (item && !item._deleted && item.key && item.key.includes('Document_EDE')),
  );

  const [elevageList, setElevageList] = useState();

  useEffect(() => {
    ElevageScreenBL.changeElevageListData({
      breeding, setElevageList, state,
    });
  }, [state, breeding]);

  useFocusEffect(
    React.useCallback(() => {
      if (refetch) {
        refetch();
      }
    }, [refetch]),
  );

  return (
    <MaxWidthContainer
      // withScrollView={false}
      outerViewProps={{
        style: {
          // flex: 1,
          padding: 24,
        },
      }}
    >

      <Tutorial
        videoId="Tc59C2nmzxA"
      />

      <InfoCard
        title="alertInfo"
        iconShow="alert-circle-outline"
        // iconCloseShow="close-outline"
        description="Attention, avant de créer un animal, si vous souhaitez l’affecter à un bâtiment et (ou) à un lot, vous devez d’abord créer dans l’ordre, le bâtiment, puis le lot."
        alertInfo="alertInfo"
      />

      <ButtonGroup
        onChange={(selectedItem) => setState(selectedItem.key)}
        items={[
          { key: 'animaux', name: 'Animaux' },
          { key: 'lots', name: 'Lots' },
          { key: 'batiments', name: 'Bâtiments' },
        ]}
        initialSelectedItem={state}
      />

      <CompButton
        style={{ marginTop: 10, paddingHorizontal: 5 }}
        leftIcon="plus-outline"
        leftIconFill="white"
        status="primary"
        onPress={() => ElevageScreenBL.goAjoutScreen({ state, linkTo })}
      >
        {ElevageScreenBL.getAddButtonTitle(state)}
      </CompButton>

      <Text category="h2" appearance="hint" style={{ marginTop: 20, marginBottom: 10 }}>{`Mes ${state}`}</Text>
      {elevageList && elevageList.length > 0 ? (
        <>
          <SearchComponent
            state={state}
            data={elevageList}
            opened
            keyExtractor={(item) => item.id}
            placeholder={ElevageScreenBL.placeholderTitle(state)}
            renderItem={(item) => {
              // console.log('item search component:', item.item);
              switch (state) {
                case 'animaux':
                  // eslint-disable-next-line no-underscore-dangle
                  if (item.item) {
                    return (
                      <AnimalCard
                        name={item.item.name}
                        numberId={`${item?.item?.numberID}`}
                        numberLot={item?.item?.lots ? (item?.item?.lots.numberLot) : ('')}
                        key={item.item.id}
                        onPress={() => {
                          ElevageScreenBL.allerDetailScreen({ state, linkTo, id: item.item.id });
                        }}
                      />
                    );
                  }
                  /**
                  return (
                    <InfoCard
                      title="alertInfo"
                      iconShow="alert-circle-outline"
                                    // iconCloseShow="close-outline"
                      infoStyle={{ marginTop: 10, backgroundColor: theme['color-primary-100'] }}
                      description="Vous avez aucun animal dans votre élevage."
                      alertInfo="alertInfo"
                    />
                  );
                    */

                  break;
                case 'lots':
                  // console.log('item lots:', item.item);
                  // eslint-disable-next-line no-underscore-dangle
                  if (item.item) {
                    return (
                      <GoCustomCard
                        title1={item.item.name}
                        title2={`Numéro de lot: ${item?.item?.numberLot}`}
                        key={item.item.id}
                        linkToCustom={() => ElevageScreenBL.allerDetailScreen(
                          { state, linkTo, id: item.item.id },
                        )}
                        customStyle={{ marginHorizontal: 5 }}
                        minusSquareOutline="arrow-ios-forward"
                      />
                    );
                  }
                  /**
                  return (
                    <InfoCard
                      title="alertInfo"
                      iconShow="alert-circle-outline"
                                    // iconCloseShow="close-outline"
                      infoStyle={{ marginTop: 10, backgroundColor: theme['color-primary-100'] }}
                      description="Vous avez aucun lot dans votre élevage."
                      alertInfo="alertInfo"
                    />
                  );
                   */
                  break;
                case 'batiments':
                  // console.log('item buildings:', item.item);
                  // eslint-disable-next-line no-underscore-dangle
                  if (item.item) {
                    return (
                      <GoCustomCard
                        key={item.item.id}
                        title1={`${item.item.nameBuilding}`}
                        linkToCustom={() => ElevageScreenBL.allerDetailScreen(
                          { state, linkTo, id: item.item.id },
                        )}
                        customStyle={{ marginHorizontal: 5 }}
                        minusSquareOutline="arrow-ios-forward"
                      />
                    );
                  }
                  /**
                  return (
                    <InfoCard
                      title="alertInfo"
                      iconShow="alert-circle-outline"
                                    // iconCloseShow="close-outline"
                      infoStyle={{ marginTop: 10, backgroundColor: theme['color-primary-100'] }}
                      description="Vous avez aucun bâtiment dans votre élevage."
                      alertInfo="alertInfo"
                    />
                  );
                  */
                  break;

                default:
                  break;
              }
              return '';
            }}
          />
        </>
      ) : (
        <InfoCard
          title="alertInfo"
          iconShow="alert-circle-outline"
            // iconCloseShow="close-outline"
          infoStyle={{ marginTop: 10, backgroundColor: theme['color-primary-100'] }}
          description={`Vous avez aucun ${state === 'animaux' ? 'animal' : `${state.slice(0, -1)}`} dans votre élevage.`}
          alertInfo="alertInfo"
        />
      )}

      <Text category="h2" appearance="hint" style={{ marginTop: 20, marginBottom: -10 }}>Informations de l'élevage</Text>
      <DetailsScreensHeaderCard
        title1="Raison sociale"
        titleInfo1={breeding?.companyName}
        title2="Type de production"
        titleInfo2={ElevageScreenBL.getTypeProductionAsString(breeding)}
        title3="Document EDE"
        titleInfo3={ede || 'Aucun document EDE ajouté'}
      />
      <Text category="h2" appearance="hint" style={{ marginTop: 20, marginBottom: -10 }}>Effectif de l'élevage</Text>
      <DetailsScreensHeaderCard
        title1="Vaches"
        titleInfo1={breeding?.nbCow}
        title2="Génisses"
        titleInfo2={breeding?.nbHeifer}
        title3="Mâles"
        titleInfo3={breeding?.nbMale}
        title4="Moins de 12 mois"
        titleInfo4={breeding?.nbYoung}
      />
      <CompButton
        status="primary"
        appearance="outline"
        leftIcon="edit-outline"
        leftIconFill={theme['color-primary-500']}
        onPress={() => {
          navigation.navigate('modify-elevage', {
            signUp: false,
            id: breeding?.id,
            // userGroup: authUser,
          });
          // linkTo(`/modify-elevage/${breeding?.id}`);
        }}
      >
        Modifier l'élevage
      </CompButton>

      <Text category="h2" style={{ marginTop: 40, marginBottom: 7 }}>Le Bilan Sanitaire d'Elevage</Text>
      <InfoCard
        title="alertInfo"
        description="La réalisation du BSE et du Protocole de Soins par votre véto à l’aide de l’application ScanFlock améliore sensiblement la qualité des résultats de vos auto-examens."
        imagePosition="right"
        imageUri=""
        buttonText="Accéder à votre espace BSE"
        buttonOnPress={() => { linkTo('/bilan-sanitaire'); }}
      />
    </MaxWidthContainer>
  );
}
