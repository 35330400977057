import { useMutation, useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { DocumentNode } from 'apollo-link';
import { WatchQueryFetchPolicy } from 'apollo-client/core/watchQueryOptions';
import * as mutations from '../graphql/mutations';
import {
  Animals as Animal,
  CreateAnimalsMutation,
  CreateAnimalsMutationVariables, DeleteAnimalsMutation, DeleteAnimalsMutationVariables,
  GetAnimalsQuery,
  GetAnimalsQueryVariables, ListAnimalsByBreedingQuery, ListAnimalsByBreedingQueryVariables,
  UpdateAnimalsMutation, UpdateAnimalsMutationVariables,
} from '../API';
import { deleteAnimals } from '../graphql/mutations';

export function useCreateAnimalMutation() {
  const [createAnimal] = useMutation<
  CreateAnimalsMutation, CreateAnimalsMutationVariables
  >(gql(mutations.createAnimals));
  return { createAnimal };
}

export const getAnimals = /* GraphQL */ `
  query GetAnimals($id: ID!) {
    getAnimals(id: $id) {
      id
      numberID
      name
      gender
      birthDate
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        createdAt
        dateFirstCons
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      lotsId
      lots {
        id
        numberLot
        name
        buildingId
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      consultations(sortDirection: DESC, limit: 10) {
        items {
          id
          breedingId
          animalId
          lotId
          shared
          autoExam
          diseaseIsVerified
          teleconsultation
          priceConsultation
          factureId
          isDone
          dateTeleCons
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          
          lot {
            id
            numberLot
            name
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          
          animal {
            id
            numberID
            name
            gender
            birthDate
            breedingId
            lotsId
            lots {
              id
              numberLot
              name
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            zoneId
            buildingId
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          
          priceConsultation
          sharedFacture
          sharedFactureAt
          factureId
          facture {
            id
            breedingId
            name
            key
            s3file
            createdAt
            _version
            _deleted
            _lastChangedAt
            updatedAt
          }
        }
      }
      zoneId
      zone {
        id
        name
        numberZone
        buildingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      buildingId
      building {
        id
        nameBuilding
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export function useGetAnimal(id: string) {
  const animalQuery = <DocumentNode>gql(getAnimals);

  if (!id) {
    return { loading: false, animal: undefined };
  }
  const { loading, data, refetch } = useQuery<
  GetAnimalsQuery, GetAnimalsQueryVariables
  >(animalQuery, {
    variables: {
      id,
    },
    fetchPolicy: 'cache-and-network',
  });
  return {
    loading, animal: <Animal>data?.getAnimals, refetch,
  };
}

export function useUpdateAnimalMutation() {
  const [updateAnimal, { loading: mutationAnimalLoading }] = useMutation<
  UpdateAnimalsMutation, UpdateAnimalsMutationVariables
  >(gql(mutations.updateAnimals));
  return { updateAnimal, mutationAnimalLoading };
}

export function useDeleteAnimalMutation() {
  const [supprimerAnimal] = useMutation<
  DeleteAnimalsMutation, DeleteAnimalsMutationVariables
  >(gql(deleteAnimals));
  return supprimerAnimal;
}

// listAnimalsByBreeding
// ListAnimalsByBreedingQuery
export function useListAnimalsByBreedinG(
  id: string, limit: number, fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network',
) {
  // const listAnimalsByBreedingQuery = <DocumentNode>gql(listAnimalsByBreeding);
  const listAnimalsByBreedingQuery = <DocumentNode>gql(`
  query ListAnimalsByBreeding(
    $breedingId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAnimalsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnimalsByBreeding(
      breedingId: $breedingId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        numberID
        name
        gender
        birthDate
        breedingId
        lotsId
        
        lots {
          id
          numberLot
          name
          buildingId
          breedingId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        
        zoneId
        buildingId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`);

  const {
    loading, data, fetchMore, refetch,
  } = useQuery<
  ListAnimalsByBreedingQuery, ListAnimalsByBreedingQueryVariables
  >(listAnimalsByBreedingQuery, {
    variables: {
      limit,
      breedingId: id,
    },
    fetchPolicy,
  });

  if (data && data.listAnimalsByBreeding && data.listAnimalsByBreeding.items) {
    // console.log('data animalS:', data.listAnimalsByBreeding.items);
    return {
      loadingAnimals: loading,
      animalS: data.listAnimalsByBreeding.items,
      nextToken: data.listAnimalsByBreeding.nextToken,
      startedAt: data.listAnimalsByBreeding.startedAt,

      fetchMoreAnimalS: () => (fetchMore({
        variables: {
          nextToken: data.listAnimalsByBreeding?.nextToken,
        },
        updateQuery: (previousQueryResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) return previousQueryResult;
          return {
            ...previousQueryResult,
            listAnimalsByBreeding: {
              ...previousQueryResult.listAnimalsByBreeding,
              items: [
                ...previousQueryResult.listAnimalsByBreeding?.items,
                ...fetchMoreResult.listAnimalsByBreeding?.items,
              ],
              nextToken: fetchMoreResult.listAnimalsByBreeding?.nextToken,
              startedAt: fetchMoreResult.listAnimalsByBreeding?.startedAt,
            },
          };
        },
      })),

      refetch,
    };
  }
  return { loadingAnimals: loading, animalS: null };
}
