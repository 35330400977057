import React, { useState } from 'react';
import { Text, useTheme } from '@ui-kitten/components';
import {
  Alert, Platform, View, ViewStyle,
} from 'react-native';
import { useForm } from 'react-hook-form';
import { API } from 'aws-amplify';
import CompButton from './Button';
import { GenderType } from '../src/API';
import VideoConf from './VideoConf/VideoConf';
import { VideoMode } from './VideoConf/RtcContext';
import CustomModal from './Modals/CustomModal';
import Form from './Form/Form';
import TextInput from './Form/TextInput';
import { AvailableValidationRules } from './Form/validation';
import Button from './Button';
import { useVetOfficeList } from '../src/API/VetOffice';
import { useUpdateConsultationMutation } from '../src/API/Consultation';

type RejoinProps = {
  veto?: boolean;
  consultation: {
    __typename: 'Consultation',
    id: string,
    breedingId: string,
    animalId?: string | null,
    animal?: {
      __typename: 'Animals',
      id: string,
      numberID: string,
      name?: string | null,
      gender?: GenderType | null,
      birthDate?: string | null,
      breedingId: string,
      lotsId?: string | null,
      zoneId?: string | null,
      buildingId?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,

    lot?: {
      __typename: 'Lots',
      id: string,
      numberLot: string,
      name?: string | null,
      buildingId?: string | null,
      breedingId: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,

    lotId?: string | null,
    shared?: boolean | null,
    autoExam?: boolean | null,
    teleconsultation?: boolean | null,
    dateTeleCons?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,

  } | null,
  style?: ViewStyle
};

type ModifyPriceConsultation = {
  priceConsultation: string
};

const RejoindreButton = ({ veto, consultation, style }: RejoinProps) => {
  const [showTelecons, setShowTelecons] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openFacture, setOpenFacture] = useState(false);
  const theme = useTheme();

  console.log(showTelecons);
  console.log('consultation', consultation);

  return (
    <>
      <CompButton
        status="primary"
        size="medium"
        appearance="outline"
        rightIcon="arrow-right-outline"
        rightIconFill={theme['color-primary-700']}
        style={[{
          // flex: 1,
          width: 150,
          borderRadius: 4,
          // alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'white',
          paddingVertical: 0,
        }, style]}
        onPress={() => {
          setOpenModal(true);
        }}
      >
        {veto ? 'DÉBUTER' : 'REJOINDRE'}
      </CompButton>
      <CustomModal
        title="Vous êtes sur le point de rejoindre une téléconsultation. Si votre interlocuteur n'est pas encore présent, merci d'attendre. Etes-vous sûr de vouloir continuer ?"
        before={<></>}
        noSafeArea
        visible={openModal}
        scrollable={false}
        onClose={() => { setOpenModal(false); }}
        insideModalStyle={{ padding: 20 }}
        buttonText="Oui"
        secondButtonText="Annuler"
        heightPercentage={0.36}
        buttonPress={() => {
          setOpenModal(false);
          setTimeout(() => {
            setShowTelecons(true);
          }, 400);
        }}
        secondButtonPress={() => { setOpenModal(false); }}
      />
      {veto ? (
        <FacurationModal
          openFacture={openFacture}
          consultation={consultation}
          onClose={() => {
            setOpenFacture(false);
            setShowTelecons(false);
          }}
        />
      ) : null}
      {consultation ? (
        <VideoConf
          channel={consultation.id}
          visible={showTelecons}
          mode={veto ? VideoMode.VETERINAIRE : VideoMode.ELEVEUR}
          onClose={() => {
            if (veto) {
              setOpenFacture(true);
            } else {
              setShowTelecons(false);
            }
          }}
        />
      ) : null}
    </>
  );
};

export default RejoindreButton;

const FacurationModal = ({ openFacture, onClose, consultation }: { openFacture: boolean, onClose: () => void, consultation: RejoinProps['consultation'] }) => {
  const { vetOffice } = useVetOfficeList();
  const [showMessage, setShowMessage] = useState<string | boolean>(false);

  const modifyPriceConsultation = useForm<ModifyPriceConsultation>();
  const { modifyConsultation } = useUpdateConsultationMutation();

  const invoiceConsultation = async (data: ModifyPriceConsultation) => {
    const parsed = parseFloat(data?.priceConsultation);

    if (consultation
        && vetOffice && vetOffice.maxPriceTeleConsultation) {
      if (parsed > vetOffice?.maxPriceTeleConsultation || parsed < 0) {
        setShowMessage(true);
      } else {
        setShowMessage(false);

        const resp = await API.post('scanflockrest', '/mangopay/do-payment', {
          body: {
            amount: parsed,
            type: 'teleconsultation',
            breedingId: consultation.breedingId,
          },
        });
        if (resp.success) {
          await modifyConsultation({
            variables: {
              input: {
                id: consultation.id,
                priceConsultation: parsed,
                // eslint-disable-next-line no-underscore-dangle
                _version: consultation._version,
              },
            },
          });
          setShowMessage(false);
          onClose();
        } else {
          setShowMessage(resp.error);
        }
      }
    }
  };

  return (
    <CustomModal
      title={"Vous pouvez désormais fixer le montant de la téléconsultation. Nous vous conseillons d'informer l'éleveur avant de confirmer la facturation. Que vous facturiez ou pas la consultation, des frais de 3.8 € HT vous seront prélevés."}
      before={<></>}
      noSafeArea
      visible={openFacture}
      scrollable={false}
      heightPercentage={Platform.OS === 'web' ? (showMessage ? 0.325 : 0.28) : (showMessage ? 0.62 : 0.45)}
      insideModalStyle={{ padding: 20 }}
    >
      <Form<ModifyPriceConsultation> {...modifyPriceConsultation}>
        <View style={{ justifyContent: 'flex-start' }}>

          <View style={{ marginTop: 20, height: 55 }}>
            <TextInput
              keyboardType="numeric"
              placeholder="Saisissez le montant"
              name="priceConsultation"
              moneySign
              validators={[
                AvailableValidationRules.required,
              ]}
            />
          </View>
          {vetOffice && showMessage ? (
            <Text status="danger" category="s2" style={{ textAlign: 'center', marginTop: 5 }}>
              {showMessage === true
                ? `Le prix que vous avez renseigné est supérieur au 
          montant maximum de ${vetOffice.maxPriceTeleConsultation} €.
          Vous pouvez modifier ce montant dans les paramètres de votre cabinet.` : showMessage}
            </Text>
          ) : null}
          <Button
            onPress={modifyPriceConsultation.handleSubmit(
              (data) => {
                invoiceConsultation(data);
              },
            )}
          >
            Confirmer le montant
          </Button>
          <Button
            appearance="outline"
            onPress={
              () => {
                invoiceConsultation({ priceConsultation: 0 });
              }
            }
          >
            Ne pas facturer, je serais prélevé de 3 € HT
          </Button>
        </View>
      </Form>
    </CustomModal>
  );
};
