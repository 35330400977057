import React from 'react';

import { Text } from '@ui-kitten/components';

import { useLinkTo } from '@react-navigation/native';
import MaxWidthContainer from '../../../components/MaxWidthContainer';

import Button from '../../../components/Button';
import ActivityIndicator from '../../../components/ActivityIndicator';
import SearchComponent from '../../../screenComponents/SearchComponent';

import VetoBseScreenBL from '../../../screenBusinessLogic/vetoScreensBL/VetoBseScreenBL/VetoBseScreenBL';
import ElevageCard from '../../../components/ElevageCard';
import VetoElevageScreenBL from '../../../screenBusinessLogic/vetoScreensBL/VetoElevageScreens/VetoElevageScreenBL';

export default function VetoRealiserBseScreen() {
  const linkTo = useLinkTo();
  return (
    <MaxWidthContainer
      withScrollView="keyboardAware"
      outerViewProps={{
        style: {
          padding: 24,
        },
      }}
    >
      {/* <Text category="h6">L'élevage sur lequel vous souhaitez réaliser le BSE n'est pas abonné à ScanFlock ?</Text>
      <Button leftIcon="plus-outline" leftIconFill="white" onPress={() => { VetoBseScreenBL.linkToSwitch({ state: 'ajout-elevage', linkTo }); }}>
        Ajouter un nouvel élevage
      </Button> */}

      <Text category="h2" appearance="hint" style={{ marginTop: 40 }}>Choisir l'élevage</Text>
      <Text category="h6">Parmi la liste des élevages dont le BSE n'a pas encore été réalisé, sélectionnez celui pour lequel vous souhaitez le planifier.</Text>
      <SearchComponent
        state="elevage"
        onChangeSearchText={() => {}}
        data={[{ Margo: 'Margo' }, { Moumou: 'Moumou' }]}
        placeholder="Rechercher un élevage"
        renderItem={(item) => (
          <ElevageCard
            key={item.index}
            breeding={item}
            linkTo={() => { VetoElevageScreenBL.goAjoutScreen({ state: 'details-elevage', linkTo }); }}
          />
        )}
      />
      <ActivityIndicator margin={10} center />

    </MaxWidthContainer>
  );
}
