import { View } from 'react-native';
import React from 'react';

const ThinSeparator = () => (
  <View style={{
    borderTopWidth: 1, borderTopColor: '#b5b5b5', marginTop: 20, paddingVertical: 10,
  }}
  />
);

export default ThinSeparator;
