import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';
import { AdminProtocolSoinsParamList } from '../../types';
import AdminProtocolSoinsScreen
  from '../../screens/AdminScreens/ProtocolSoinsScreen/AdminProtocolSoinsScreen';
import AdminDetailsProtocolSoinScreen
  from '../../screens/AdminScreens/ProtocolSoinsScreen/AdminDetailsProtocolSoinScreen';

const Stack = createNativeStackNavigator<AdminProtocolSoinsParamList>();

export default () => (
  <Stack.Navigator
    initialRouteName="admin-ps"
    screenOptions={{
      headerShown: false,
    }}
  >
    <Stack.Screen
      name="admin-ps"
      component={AdminProtocolSoinsScreen}
    />
    <Stack.Screen
      name="admin-details-ps"
      component={AdminDetailsProtocolSoinScreen}
    />
  </Stack.Navigator>
);
