import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { useNavigationState } from '@react-navigation/native';
import { TabConsultationParamList } from '../../types';
import ConsultationScreen from '../../screens/ConsultationScreen/ConsultationScreen';
import RealiserAutoExamScreen from '../../screens/ConsultationScreen/RealiserAutoExamScreen';
import DetailConsultationScreen from '../../screens/ConsultationScreen/DetailConsultationScreen';
import DocumentsConsultationsScreen from '../../screens/ConsultationScreen/DocumentsConsultationsScreen';
import QuestionnaireScreen from '../../screens/ConsultationScreen/QuestionnaireScreen';
// import SearchConsultationScreen from '../../screens/ConsultationScreen/SearchConsultationScreen';
import ListDiseaseScreen from '../../screens/ConsultationScreen/ListDiseaseScreen';
import SaisirDiseaseResultScreen from '../../screenComponents/ConsultationScreenComps/SaisirDiseaseResultScreen';
import MessagerieScreen from '../../screens/ConsultationScreen/MessagerieScreen';
import { getStackInfos } from '../Utils';
import Button from '../../components/Button';
import AjoutAnimalScreen from '../../screens/ElevageScreen/AjoutAnimalScreen';
import AjoutLotScreen from '../../screens/ElevageScreen/AjoutLotScreen';

const Stack = createStackNavigator<TabConsultationParamList>();

export default () => (
  <Stack.Navigator
    initialRouteName="consultation"
    screenOptions={{
      headerShown: false,
      gestureEnabled: false,
    }}
  >
    <Stack.Screen
      name="consultation"
      component={ConsultationScreen}
    />
    <Stack.Screen
      name="ajout-animal"
      component={AjoutAnimalScreen}
    />
    <Stack.Screen
      name="ajout-lot"
      component={AjoutLotScreen}
    />
    <Stack.Screen
      name="realiser-auto-exam"
      component={RealiserAutoExamScreen}
    />
    <Stack.Screen
      name="detail-consultation"
      component={DetailConsultationScreen}
    />
    <Stack.Screen
      name="document"
      component={DocumentsConsultationsScreen}
    />
    <Stack.Screen
      name="questionnaire"
      component={QuestionnaireScreen}
    />
    {/**
         <Stack.Screen
         name="search-consultation"
         component={SearchConsultationScreen}
         />
         */}
    <Stack.Screen
      name="list-disease"
      component={ListDiseaseScreen}
    />
    <Stack.Screen
      name="saisir-disease-result"
      component={SaisirDiseaseResultScreen}
    />
    <Stack.Screen
      name="messagerie"
      component={MessagerieScreen}
    />

  </Stack.Navigator>
);
