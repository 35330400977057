import React, { useContext } from 'react';
import { TouchableOpacity } from 'react-native';
import { Icon as IconUIKitten } from '@ui-kitten/components/ui/icon/icon.component';
import RtcContext, { DispatchType } from '../RtcContext';
import { LocalContext } from '../LocalUserContext';
import { useBtnStyle, useIconStyle } from '../useStyles';

function LocalVideoMute() {
  const { dispatch } = useContext(RtcContext);
  const local = useContext(LocalContext);
  const btnStyle = useBtnStyle();
  const iconStyle = useIconStyle();

  return (
    <TouchableOpacity
      style={{ ...btnStyle, ...{ backgroundColor: local.hasVideo ? 'white' : 'orange' } }}
      onPress={() => {
        (dispatch as DispatchType<'LocalMuteVideo'>)({
          type: 'LocalMuteVideo',
          value: [local.hasVideo],
        });
      }}
    >
      <IconUIKitten
        name={local.hasVideo ? 'video-outline' : 'video-off-outline'}
        style={iconStyle}
        fill="black"
      />
    </TouchableOpacity>
  );
}

export default LocalVideoMute;
