import React from 'react';

import { useFocusEffect, useLinkTo } from '@react-navigation/native';
import { useTheme } from '@ui-kitten/components';
import MaxWidthContainer from '../../components/MaxWidthContainer';

import Button from '../../components/Button';
import GoCustomCard from '../../components/GoCustomCard';
import { useBreedingList, useListUserByBreeding } from '../../src/API/Breeding';
import { useUser } from '../../src/API/UserContext';

const UtilisateursScreen = () => {
  const linkTo = useLinkTo();
  const theme = useTheme();
  const { user } = useUser();

  const { usersInBreeding, refetch } = useListUserByBreeding();

  useFocusEffect(
    React.useCallback(() => {
      refetch();
      return () => {};
    }, [refetch]),
  );

  // console.log(usersInBreeding);
  let eleveurList;
  if (usersInBreeding && user && usersInBreeding.items) {
    eleveurList = usersInBreeding.items.filter((item) => item.id !== user.id && !item._deleted);
  }
  return (
    <MaxWidthContainer
      outerViewProps={{
        style: {
          padding: 20,
          marginBottom: 20,
        },
      }}
    >
      {eleveurList && eleveurList.map((eleveur) => (
        <GoCustomCard
          userCard="userCard"
          title1="Nom"
          title2={`${eleveur.firstname} ${eleveur.lastname}`}
          titleEmail={eleveur.email}
          linkToCustom={() => { linkTo(`/mon-compte/utilisateurs/modify-utilisateur/${eleveur.id}`); }}
        />
      ))}

      <Button
        onPress={() => { linkTo('/mon-compte/utilisateurs/ajout-utilisateur'); }}
        leftIcon="plus-outline"
        leftIconFill="#000"
        status="basic"
        style={{ marginTop: 15, backgroundColor: theme['color-primary-300'] }}
      >
        Ajouter un nouvel utilisateur
      </Button>
    </MaxWidthContainer>
  );
};

export default UtilisateursScreen;
