import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Text } from '@ui-kitten/components';
import { StyleSheet, View } from 'react-native';
import { TabMonCompteParamList } from '../types';

import InscriptionEtape2Screen from '../screens/AccountScreens/InscriptionEtape2Screen';
import InscriptionEtape3Screen from '../screens/AccountScreens/InscriptionEtape3Screen';
import VetoAjoutUtilisateurScreen
  from '../screens/VetScreens/MonCabinetScreen/VetoAjoutUtilisateurScreen';
import Button from '../components/Button';
import AjoutUtilisateurScreen from '../screens/MonCompteScreen/AjoutUtilisateurScreen';
import { useUser } from '../src/API/UserContext';
import { useBreedingList } from '../src/API/Breeding';
import { useGetVetOffice } from '../src/API/VetOffice';

const Stack = createStackNavigator<TabMonCompteParamList>();

export default function FinalSignUpStackNavigator() {
  const { updateUser } = useUser();
  const { breeding: oneBreeding } = useBreedingList('eleveur');
  const { vetOfficeData: vetOffice } = useGetVetOffice();

  return (
    <Stack.Navigator
      initialRouteName="inscription-etape-2"
      screenOptions={{
        cardStyle: {
          flex: 1,
          overflow: 'hidden',
        },
        headerShown: false,
        gestureEnabled: false,
      }}
    >
      <Stack.Screen
        name="inscription-etape-2"
        component={InscriptionEtape2Screen}
        initialParams={{
          signUp: true,
        }}
      />
      <Stack.Screen
        name="veto-inscription-etape-3"
        children={() => (
          <VetoAjoutUtilisateurScreen
            header={(
              <View style={{
                alignItems: 'center',
              }}
              >
                <Text category="h2" style={styles.title}>Je m'inscris en tant que vétérinaire</Text>
                <Text category="p1" appearance="hint" style={{ marginVertical: 20 }}>Etape 3/3</Text>
                <Button
                  style={{ marginTop: 20 }}
                  onPress={
                      async () => {
                        if (updateUser && vetOffice) {
                          await updateUser({
                            vetOfficeId: vetOffice?.id,
                            userGroup: 'veto',
                          });
                        }
                      }
                  }
                >
                  Confirmer votre inscription
                </Button>

                <Text category="p1" appearance="hint" style={{ marginVertical: 20 }}>ou</Text>

                <Text category="h4" style={{ marginVertical: 20 }}>Ajoutez des utilisateurs (optionnel, cette étape peut être répétée autant de fois que nécessaire)</Text>
              </View>
            )}
          />
        )}
        initialParams={{
          signUp: true,
        }}
      />
      <Stack.Screen
        name="inscription-etape-3"
        component={InscriptionEtape3Screen}
        initialParams={{
          signUp: true,
        }}
      />
      <Stack.Screen
        name="inscription-etape-4"
        children={() => (
          <AjoutUtilisateurScreen
            header={(
              <View style={{
                alignItems: 'center',
              }}
              >
                <Text category="h2" style={styles.title}>Je m'inscris en tant qu'éleveur</Text>
                <Text category="p1" appearance="hint" style={{ marginVertical: 20 }}>Etape 4/4</Text>
                <Button
                  style={{ marginTop: 20 }}
                  onPress={
                                async () => {
                                  if (updateUser) {
                                    await updateUser({ breedingId: oneBreeding?.id, userGroup: 'eleveur' });
                                  }
                                }
                            }
                >
                  Confirmer votre inscription
                </Button>

                <Text category="p1" appearance="hint" style={{ marginVertical: 20 }}>ou</Text>

                <Text category="h4" style={{ marginVertical: 20 }}>Ajoutez des utilisateurs (optionnel, cette étape peut être répétée autant de fois que nécessaire)</Text>
              </View>
                )}
          />
        )}
        initialParams={{
          signUp: true,
        }}
      />

    </Stack.Navigator>
  );
}

const styles = StyleSheet.create({
  buttonRight: { alignItems: 'flex-end', marginTop: 34 },
  title: {
    marginTop: 43,
    textAlign: 'center',
  },
});
