import { LinkProps } from '../../../types';

const goAjoutScreen = ({ state, linkTo, id }: LinkProps) => {
  switch (state) {
    case 'ajout-elevage':
      linkTo('/veto-elevage/veto-ajout-elevage');
      break;
    case 'details-elevage':
      linkTo(`/veto-elevage/veto-details-elevage/${id}`);
      break;
    case 'historique-bse':
      linkTo(`/veto-elevage/veto-historique-bse/${id}`);
      break;
    case 'details-bse':
      linkTo('/veto-elevage/veto-details-bse');
      break;
    default:
      break;
  }
};

const switchTo = ({ state, linkTo, id }: LinkProps) => {
  switch (state) {
    case 'effectif elevage':
      linkTo(`/veto-elevage/veto-effectif-elevage/${id}`);
      break;

    default:
      break;
  }
};

export default {
  goAjoutScreen,
  switchTo,
};
