import {
  CommonActions,
  getActionFromState,
  getStateFromPath,
  InitialState,
  NavigationState, StackActions, useLinkTo, useNavigation,
} from '@react-navigation/native';
import LinkingConfiguration from './LinkingConfiguration';
import VetoLinkingConfiguration from './VetoLinkingConfiguration';

function findFocusedState(state: InitialState): InitialState {
  let current: InitialState | undefined = state;
  let currentType: string | undefined = state.type;

  while (current?.routes[current.index ?? 0].state != null) {
    current = current.routes[current.index ?? 0].state;
    if (current?.type) {
      currentType = current.type;
    }
  }

  return <InitialState>{ ...current, type: currentType };
}

export const getDeepestParams = (array) => {
  if (array.params) {
    return getDeepestParams(array.params);
  }
  return array;
};

export const useResetLinkTo = (linkingConfig) => {
  const navigation = useNavigation();
  const linkTo = useLinkTo();

  return (path) => {
    const state = getStateFromPath(path, linkingConfig);

    // Verify state and then extract action
    if (state) {
      navigation.dispatch(
        CommonActions.reset({ index: 0, ...state }),
      );
    } else {
      linkTo(path);
    }
  };
};

export const getStackInfos = (state: NavigationState) => {
  const focusedEndState = findFocusedState(state);
  const focusedRoute = focusedEndState?.routes[focusedEndState?.index ?? 0];
  let currentRouteName = focusedRoute?.name;
  const params = focusedRoute?.params ? getDeepestParams(focusedRoute?.params) : undefined;
  const showBack = (focusedEndState?.type === 'stack' && focusedEndState.routes.length > 1)
      || (typeof params?.back === 'string')
      || false;

  if (focusedRoute?.params?.initial === true) {
    currentRouteName = focusedRoute?.params?.screen;
  }
  return {
    focusedRoute,
    currentRouteName,
    showBack,
    params,
  };
};

export const getTitleFromName = (name?: string) => {
  switch (name) {
    case 'tableau-de-bord':
    case 'admin-tableau-de-bord':
      return 'Tableau de bord';

    case 'admin-vetos':
      return 'Vétérinaires';
    case 'admin-elevages':
      return 'Elevages';
    case 'admin-details-veto':
      return 'Détail du vétérinaire';
    case 'admin-details-elevage':
      return 'Détail de l\'élevage';

    case 'mon-compte-nav':
      return 'Mon compte';
    case 'mon-compte':
      return 'Mon compte';
    case 'modifier-compte-info':
      return 'Modifier mes informations';
    case 'utilisateurs':
      return 'Utilisateurs';
    case 'detail-utilisateur':
      return "Détails de l'utilisateur";
    case 'mode-paiement':
      return 'Mode de paiement';
    case 'factures':
    case 'admin-factures':
      return 'Factures';
    case 'ajout-utilisateur':
      return 'Ajouter un utilisateur';
    case 'modify-utilisateur':
      return 'Modifier un utilisateur';

    case 'elevage-nav':
      return 'Elevage';
    case 'elevage':
      return 'Elevage';
    case 'modify-elevage':
      return 'Modifier mon élevage';

    case 'consultation-nav':
      return 'Consultations';
    case 'consultation':
      return 'Consultations';

    case 'faire-demande-teleconsultation':
      return 'Demande de téléconsultation';

    case 'photo':
      return 'Photos';
    case 'bilan-sanitaire-nav':
      return "Bilan Sanitaire d'Élevage";
    case 'notifications-nav':
      return 'Notifications';
    case 'faq':
      return 'Foire aux questions';

    case 'bilan-sanitaire':
      return "Bilan Sanitaire d'Élevage";
    case 'prebilan':
      return 'Pré-bilan';
    case 'prebilan-questionnaire':
      return 'Pré-bilan Questionnaire';
    case 'demande-bse':
      return "Demande de Bilan Sanitaire d'Élevage";
    case 'bse-historique':
      return "Historique des Bilans Sanitaires d'Élevage";

    case 'detail-animal':
      return "Détails d'un animal";
    case 'detail-lot':
      return "Détails d'un lot";
    case 'detail-batiment':
      return "Détails d'un bâtiment";
    case 'ajout-animal':
      return 'Ajouter un animal';
    case 'ajout-lot':
      return 'Ajouter un lot';
    case 'ajout-batiment':
      return 'Ajouter un bâtiment';

    case 'manage-affected-animals':
      return 'Gérer les animaux';

    case 'modify-animal':
      return "Modifier l'animal";
    case 'modify-lot':
      return 'Modifier le lot';
    case 'modify-batiment':
      return 'Modifier le bâtiment';

    case 'realiser-auto-exam':
      return 'Réaliser un auto-examen';
    case 'questionnaire':
      return 'Questionnaire';
    case 'list-disease':
      return 'Maladies détectées';
    case 'saisir-disease-result':
      return "Finaliser l'auto-examen";
    case 'detail-consultation':
      return 'Détails de la consultation';
    case 'document':
      return 'Documents';
    case 'veto-tableau-de-bord':
      return 'Tableau de bord';

    case 'veto-mon-cabinet-nav':
      return 'Mon cabinet';
    case 'veto-mon-cabinet':
      return 'Mon cabinet';
    case 'veto-mon-compte':
      return 'Mon compte';
    case 'veto-modifier-mon-compte':
      return 'Modifier mes informations';
    case 'veto-modify-password':
      return 'Modifier le mot de passe';
    case 'veto-modify-info-perso':
      return 'Modifier mes informations';

    case 'veto-utilisateurs':
      return 'Utilisateurs';
    case 'veto-details-utilisateur':
      return "Détails de l'utilisateur";
    case 'veto-modify-utilisateurs':
      return 'Modifier un utilisateur';
    case 'veto-ajout-utilisateurs':
      return 'Ajouter un utilisateur';

    case 'veto-tarif-consultation':
      return 'Tarif des téléconsultations';
    case 'veto-facture':
      return 'Factures';

    case 'veto-examen-nav':
      return 'Consultations';
    case 'veto-examen':
      return 'Consultations';
    case 'veto-details-consultation':
      return 'Détails de la consultation';
    case 'veto-details-teleconsultation':
      return 'Détails de la téléconsultation';

    case 'veto-details-elevage':
      return "Détails de l'élevage";
    case 'veto-detail-animal':
      return "Détails de l'animal";
    case 'veto-detail-lot':
      return 'Détails du lot';
    case 'veto-detail-batiment':
      return 'Détails du bâtiment';

    case 'veto-consultation-document':
      return 'Documents';
    case 'veto-consultation-messagerie':
      return 'Messagerie';

    case 'veto-teleconsultation':
      return 'Téléconsultations';

    case 'veto-bse':
      return "Bilan sanitaire d'élevage";
    case 'veto-realiser-bse':
      return "Bilan sanitaire d'élevage";
    case 'veto-ajout-elevage':
      return 'Ajouter un nouvel élevage';

    case 'veto-details-bse':
      return "Détails du Bilan Sanitaire d'Élevage";

    case 'veto-elevage-nav':
      return 'Élevages';
    case 'veto-elevage':
      return 'Élevages';
    case 'veto-effectif-elevage':
      return "Effectif de l'élevage";
    case 'veto-historique-bse':
      return "Historique des Bilans Sanitaire d'Élevage";
    case 'veto-protocolSoins':
      return "Finaliser le Bilan Sanitaire d'Élevage";

    case 'veto-notifications-nav':
      return 'Notifications';

    case 'Root':
      return 'Tableau de bord';

    default:
      return '';
  }
};
