import React, { useEffect, useState } from 'react';

import { Text } from '@ui-kitten/components';
import { useLinkTo, useNavigation, useRoute } from '@react-navigation/native';

import MaxWidthContainer from '../../components/MaxWidthContainer';

import InfoCard from '../../screenComponents/InfoCard';
import BseCard from '../../components/BseCard';
import BseScreenBL from '../../screenBusinessLogic/BseScreenBL/BseScreenBL';
import GoCustomCard from '../../components/GoCustomCard';
import PrebilanCard from '../../components/CustomCards/PrebilanCard';
import CustomModal from '../../components/Modals/CustomModal';

import { useUser } from '../../src/API/UserContext';
import { useBreedingList } from '../../src/API/Breeding';
import { useBseListEleveur } from '../../src/API/BSE';
import { LockedStatus, StatusBse } from '../../src/API';
import Tutorial from '../../components/Tutorial';

export default function BilanSanitaireScreen() {
  const linkTo = useLinkTo();
  const route = useRoute();
  const { user } = useUser();
  const { bse } = useBseListEleveur();
  const { breeding } = useBreedingList(user.userGroup);
  let preReviewFinish = StatusBse.preReviewStarted;
  let BSEId: string;
  if (bse && bse[0] && bse[0].questionResponse && (
    bse[0].StatusBse === StatusBse.preReviewFinished
      || bse[0].StatusBse === StatusBse.BSEAsk
      || bse[0].StatusBse === StatusBse.BSEAskStart
      || bse[0].StatusBse === StatusBse.BSEProtocolSoins
      || bse[0].StatusBse === StatusBse.BSEFinish
      || bse[0].StatusBse === StatusBse.BSEStarted)) {
    preReviewFinish = StatusBse.preReviewFinished;
    BSEId = bse[0].id;
  } else BSEId = '';
  const [openModalUnsubscrib, setOpenModalUnsubscrib] = useState(false);
  useEffect(() => {
    // console.log('fzefez');
    if (breeding && breeding.locked !== LockedStatus.unlocked) {
      setOpenModalUnsubscrib(true);
    }
    return () => {

    };
  }, []);

  return (
    <>
      <MaxWidthContainer
        withScrollView="keyboardAware"
        outerViewProps={{
          style: {
            padding: 24,
          },
        }}
      >
        <Text category="h1" style={{ marginBottom: 10 }}>
          Réalisation du Pré-Bilan
        </Text>
        <Tutorial
          videoId="TXLkBKh2WGk"
        />
        <InfoCard
          title="alertInfo"
          iconShow="alert-circle-outline"
          description="Vous devez répondre aux questions du
          pré-bilan avant de pouvoir faire une demande de BSE."
          alertInfo="alertInfo"
        />

        <PrebilanCard
          prebilanStatus={preReviewFinish}
          linkTo={() => {
            BseScreenBL.linkToSwitch({
              state: 'prebilan', linkTo,
            });
          }}
        />

        <Text category="h1" style={{ marginTop: 40, marginBottom: 10 }}>
          Réalisation du Bilan Sanitaire d'Elevage
        </Text>
        <InfoCard
          title="alertInfo"
          iconShow="alert-circle-outline"
          description="Effectuez une demande et consultez la confirmation du
        rendez-vous par votre vétérinaire."
          alertInfo="alertInfo"
        />
        {BSEId ? (
          <BseCard BSEId={BSEId} />
        ) : (
          <></>
        )}

        <Text category="h2" appearance="hint" style={{ marginTop: 40, marginBottom: 10 }}>Précédents Bilans Sanitaires d'Élevage</Text>
        <Text category="s2" appearance="hint" style={{ marginBottom: 8 }}>Consultez les précédents BSE réalisés avec l'application.</Text>

        <GoCustomCard title1="Historique" linkToCustom={() => { BseScreenBL.linkToSwitch({ state: 'historique-bse', linkTo }); }} minusSquareOutline="arrow-ios-forward" />

        <Text category="h1" style={{ marginTop: 40, marginBottom: 7 }}>Une question sur le Pré-bilan ou le BSE ?</Text>
        <InfoCard
          description={"Consultez notre FAQ afin d'obtenir une réponse sur les questions les plus "
            + "fréquemment posées à propos de l'application."}
            // imagePosition="right"
          imageUri=""
          buttonText="Accéder à la FAQ"
          buttonOnPress={() => { linkTo('/faq'); }}
        />

      </MaxWidthContainer>
      <CustomModal
        title="Votre abonnement est terminé. Abonnez vous pour accéder à cette fonctionalité"
        before={<></>}
        noSafeArea
        visible={openModalUnsubscrib}
        scrollable={false}
        onClose={() => setOpenModalUnsubscrib(false)}
        insideModalStyle={{ textAlign: 'center', padding: 20 }}
        buttonText="Continuer"
        heightPercentage={0.46}
        buttonPress={() => {
          setOpenModalUnsubscrib(false);
          linkTo('/mon-compte/mode-paiement');
        }}
      />
    </>
  );
}
