import { useRoute } from '@react-navigation/native';
import moment from 'moment';
import {
  useCreateConsultationMutation,
  useDeleteConsultationMutation,
  useUpdateConsultationMutation,
} from '../../src/API/Consultation';

import { useBreedingList } from '../../src/API/Breeding';
import { useUser } from '../../src/API/UserContext';
import { useUpdateBseMutation } from '../../src/API/BSE';
import { BSE } from '../../src/API';

export const useAjoutConsultation = () => {
  const { user } = useUser();
  const { breeding } = useBreedingList(user?.userGroup);
  const createConsultation = useCreateConsultationMutation();
  const modifyConsultation = useUpdateConsultationMutation();
  const route = useRoute();
  const now = moment(new Date()).format('YYYY-MM-DD');

  const addConsultationByAnimal = async (animal?: string) => {
    if (breeding && animal) {
      const { data: consultationData } = await createConsultation.createConsultation({
        variables: {
          input: {
            breedingId: breeding.id,
            animalId: animal,
            shared: route?.name === 'faire-demande-teleconsultation',
            sharedAt: now,
            autoExam: route?.name !== 'faire-demande-teleconsultation',
            teleconsultation: route?.name === 'faire-demande-teleconsultation',
            teleconsAskAt: now,
          },
        },
      });
      return consultationData;
    }
    return () => {};
  };

  const addConsultationByLot = async (lot?: string) => {
    if (breeding && lot) {
      const { data: consultationByLotData } = await createConsultation.createConsultation({
        variables: {
          input: {
            breedingId: breeding.id,
            lotId: lot,
            shared: route?.name === 'faire-demande-teleconsultation',
            sharedAt: now,
            autoExam: route?.name !== 'faire-demande-teleconsultation',
            teleconsultation: route?.name === 'faire-demande-teleconsultation',
            teleconsAskAt: now,
          },
        },
      });
      return consultationByLotData;
    }
    return () => {};
  };

  const shareConsultation = async (data) => {
    if (user && breeding) {
      const { data: updatedConsultationData } = await modifyConsultation.modifyConsultation({
        variables: {
          input: {
            id: data.id,
            breedingId: breeding.id,
            shared: true,
            sharedAt: now,
            // eslint-disable-next-line no-underscore-dangle
            _version: data._version,
          },
        },
      });
      return updatedConsultationData;
    }
    return () => {};
  };
  return { addConsultationByAnimal, addConsultationByLot, shareConsultation };
};

const useCrudConsultation = () => {
  const modifyConsultation = useUpdateConsultationMutation();
  const { supprimerConsultation } = useDeleteConsultationMutation();

  const editConsultation = async (data) => {
    const {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      id, questionData, questionOrder, questionResponse, _version, isDone, questionResponseOrder,
    } = data;
    const { data: updatedConsultationData } = await modifyConsultation.modifyConsultation({
      variables: {
        input: {
          id,
          questionData,
          questionResponseOrder,
          questionOrder,
          questionResponse,
          isDone,
          _version,
        },
      },
    });
    return updatedConsultationData;
  };

  const deleteConsultation = async (data) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { id, _version } = data;
    await supprimerConsultation({
      variables: {
        input: {
          id,
          _version,
        },
      },
    });
  };
  return { editConsultation, deleteConsultation };
};

const useCrudBSE = () => {
  const modifyBse = useUpdateBseMutation();

  const editBse = async (data: BSE) => {
    const {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      id, breedingId, StatusBse, maladieRecurente, questionResponse, _version, questionResponseOrder, lastQuestionVeto, crS3Key,
    } = data;
    const { data: updatedConsultationData } = await modifyBse.updateBse({
      variables: {
        input: {
          id,
          breedingId,
          StatusBse,
          questionResponse,
          questionResponseOrder,
          crS3Key,
          maladieRecurente,
          lastQuestionVeto,
          _version,
        },
      },
    });
    return updatedConsultationData;
  };

  return { editBse };
};

export default {
  useAjoutConsultation, useCrudConsultation, useCrudBSE,
};
