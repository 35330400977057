export const BicCodes = {
  BDUPFR2S: 'BANQUE DUPUY, DE PARSEVAL',
  BKCHFRPP: 'BANK OF CHINA, PARIS BRANCH',
  BKCHFRPP013: 'BANK OF CHINA, PARIS BRANCH',
  BOFAFRPP: 'BANK OF AMERICA MERRILL LYNCH INTERNATIONAL DESIGNATED ACTIVITY COMPANY, PARIS BRANCH',
  BOFAFRPPFIC: 'BANK OF AMERICA MERRILL LYNCH INTERNATIONAL DESIGNATED ACTIVITY COMPANY, PARIS BRANCH',
  BOTKFRCC: 'MUFG BANK, LTD. (ACCES TARGET 2)',
  BOTKFRPX: 'MUFG BANK, LTD. PARIS BRANCH',
  CAIXFRPP: 'CAIXABANK S.A., SUCCURSALE EN FRANCE',
  CHASFRPB: 'JPMORGAN CHASE BANK, N.A.',
  CHASFRPP: 'JPMORGAN CHASE BANK, N.A.',
  CHASFRPPENQ: 'JPMORGAN CHASE BANK, N.A.',
  CHASFRPPGMO: 'JPMORGAN CHASE BANK, N.A.',
  CHASFRPPIPB: 'JPMORGAN CHASE BANK, N.A.',
  CHASFRPPPCG: 'JPMORGAN CHASE BANK, N.A.',
  CNEPFR22: '3E CONCEPT S.A.S.',
  EIBCFRPP: 'THE EXPORT-IMPORT BANK OF CHINA, PARIS BRANCH',
  ICBKFRPP: 'INDUSTRIAL AND COMMERCIAL BANK OF CHINA (EUROPE) S.A., PARIS BRANCH',
  MHCBFRPP: 'MIZUHO BANK, LTD. PARIS BRANCH',
  MHCBFRPPTGT: 'MIZUHO BANK, LTD. PARIS BRANCH',
  MIRAFRPP: 'MIRABAUD ET CIE (EUROPE), SUCCURSALE EN FRANCE',
  PARBFRPP: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPP030: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPP060: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPP074: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPP116: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPP127: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPP144: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPP145: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPP221: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPP240: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPP242: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPP249: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPP277: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPP322: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPP344: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPP352: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPP3DB: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPP3PM: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPP546: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPP579: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPP755: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPP756: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPP757: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPP758: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPP759: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPP760: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPAGL: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPALA: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPAXA: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPBEC: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPBEX: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPBOA: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPCDC: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPCHE: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPCRA: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPCTS: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPDNL: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPEPG: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPESY: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPFDA: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPFDI: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPFHO: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPFOH: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPFSF: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPFTT: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPGCM: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPGCS: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPGIN: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPGOS: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPINV: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPIPB: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPISI: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPJBF: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPLOF: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPMOO: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPMPX: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPNAT: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPNBB: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPNLA: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPNLC: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPNLX: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPNMC: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPNMJ: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPNMP: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPOPT: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPPFS: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPPIC: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPPKG: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPPTC: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPRBS: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPSFI: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPSHX: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPSLA: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPSLB: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPTPA: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPTRS: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPTSU: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PARBFRPPUFO: 'BNP PARIBAS SECURITIES SERVICES, FRANCE',
  PCBCFRPP: 'CHINA CONSTRUCTION BANK (EUROPE) S.A., PARIS BRANCH',
  ROYCFRPB: 'ROYAL BANK OF CANADA, PARIS BRANCH',
  ROYCFRPBTGT: 'ROYAL BANK OF CANADA, PARIS BRANCH',
  UNESFRPP: 'UNESCO (UNITED NATIONS EDUCATIONAL, SCIENTIFIC AND CULTURAL ORGANIZATION',
  MAMEFRPP: 'A3M (ASSOCIATION DE MOYENS MALAKOFF MEDERIC)',
  ABCOFRPP: 'ABC INTERNATIONAL BANK PARIS BRANCH',
  EBAPFRPP: 'ABE CLEARING SAS/EBA CLEARING',
  EBAPFRPPCOL: 'ABE CLEARING SAS/EBA CLEARING',
  EBAPFRPPINT: 'ABE CLEARING SAS/EBA CLEARING',
  EBAPFRPPPSA: 'ABE CLEARING SAS/EBA CLEARING',
  EBAPFRPS: 'ABE CLEARING SAS/EBA CLEARING (SYSTEM OPERATOR)',
  GRACFRP2: 'ACCOR',
  ADECFR22: 'ADECCO GROUPE FRANCE',
  ADSSFR22: 'ADISSEO FRANCE',
  AFPMFR22: 'AFONE PAIEMENT',
  AGAPFR22: 'AGAPES',
  AGFDFRPA: 'AGENCE FRANCAISE DE DEVELOPPEMENT',
  AFTRFRPP: 'AGENCE FRANCE TRESOR (AFT)',
  AFTRFRPPCDP: 'AGENCE FRANCE TRESOR (AFT)',
  AGFIFR22: 'AGRIAL FINANCES',
  AIRFFRPP: 'AIR FRANCE - KLM SA',
  RLIQFRPP: 'AIR LIQUIDE SA',
  AIRBFR22: 'AIRBUS SAS',
  AKKAFRPP: 'AKKA TECHNOLOGIES',
  AKUOFRPP: 'AKUO ENERGY SAS',
  LICOFRPP: 'AL KHALIJI FRANCE S.A.',
  AAGPFR2A: 'ALAIN AFFLELOU FRANCHISEUR',
  ABVAFRPP: 'ALBEA SERVICES S.A.S.',
  ALSNFRPP: 'ALCATEL SUBMARINE NETWORKS',
  ATELFRPP: 'ALCATEL-LUCENT',
  ALDEFR2V: 'ALDES AERAULIQUE',
  AALEFR22: 'ALE INTERNATIONAL',
  ALAFFRPP: 'ALLIANCE AUTOMOTIVE FRANCE S.A.S.',
  AGFBFRCC: 'ALLIANZ BANQUE',
  AGFBFRPP: 'ALLIANZ BANQUE',
  ALLZFRPP: 'ALLIANZ IARD',
  ALHOFRPP: 'ALSTOM HOLDINGS',
  ALSYFRPP: 'ALSYON TECHNOLOGIES',
  FALTFRPP: 'ALTAVIA',
  ALTNFRPP: 'ALTEN SA',
  ALRAFR22: 'ALTRAD INVESTMENT AUTHORITY',
  GMTSFRPP: 'ALTRAN TECHNOLOGIES',
  ALVTFRPP: 'ALVEST',
  AGRIFRPI: 'AMUNDI',
  APIGFR22: 'APICIL GESTION',
  APIGFR22TPS: 'APICIL GESTION',
  APIGFR22GBQ: 'APICIL GESTION',
  APIGFR22SKL: 'APICIL GESTION',
  APIGFR22GSA: 'APICIL GESTION',
  APIGFR22INT: 'APICIL GESTION',
  APIGFR22MIE: 'APICIL GESTION',
  APIGFR22TPN: 'APICIL GESTION',
  APTGFR22: 'APTARGROUP HOLDING SAS',
  USINFRPP: 'ARCELORMITTAL TREASURY SNC',
  AREVFRPP: 'AREVA SA',
  ARNNFR22: 'ARIANESPACE',
  ARKEFR22: 'ARKEMA',
  GRSRFR22: 'ART ET TECHNIQUES DU PROGRES',
  ARVVFRP2: 'ARVELLA INVESTMENTS',
  AMACFRPP: 'ASSOCIATION DE MOYENS ASSURANCES',
  ASSYFRPP: 'ASSYSTEM',
  ATLNFRPP: 'ATALIAN',
  ATLAFR22: 'ATLANTIC SOCIETE FRANCAISE DE DEVELOPPEMENT THERMIQUE',
  AFOMFRPP: 'ATLASFORMEN SAS',
  ATOSFRPP: 'ATOS SE',
  TRYBFR22: 'ATRYA',
  BCMAFRPP: 'ATTIJARIWAFA BANK EUROPE-PARIS',
  AULSFRP2: 'AUREL BGC',
  ANZBFRPP: 'AUSTRALIA AND NEW ZEALAND BANKING GROUP LIMITED',
  AVTGFR22: 'AVIONS DE TRANSPORT REGIONAL GIE',
  AXFIFRPP: 'AXA BANQUE FINANCEMENT',
  AXABFRPPTG2: 'AXA BANQUE SA',
  AXABFRPP: 'AXA BANQUE SA',
  AXAFFRPA: 'AXA FRANCE VIE',
  AHLFFR22: 'AXA HOME LOAN SFH',
  AXRLFR22: 'AXEREAL FINANCES',
  LACTFRPP: 'B.S.A.',
  BAAHFRPP: 'BA AND SH S.A.S.',
  BBOUFRPP: 'BABILOU GROUP SAS',
  BAIIFR22: 'BAI SA',
  BBVAFRPPBDF: 'BANCO BILBAO VIZCAYA ARGENTARIA',
  BBVAFRPP: 'BANCO BILBAO VIZCAYA ARGENTARIA',
  BSABFRPPBCE: 'BANCO DE SABADELL',
  BSABFRPP: 'BANCO DE SABADELL',
  BRASFRPP: 'BANCO DO BRASIL AG SUCCURSALE FRANCE',
  BSCHFRPPBF1: 'BANCO SANTANDER S.A.',
  BSCHFRPP: 'BANCO SANTANDER S.A.',
  AUDIFRPP: 'BANK AUDI FRANCE',
  COMMFRPP: 'BANK OF COMMUNICATIONS (LUXEMBOURG) S.A. PARIS BRANCH',
  BKIDFRPP: 'BANK OF INDIA',
  BPSMFRPP: 'BANQUE BCP',
  BIARFRPP: 'BANQUE BIA',
  BACPFRPPAMS: 'BANQUE CENTRALE DE COMPENSATION - LCH.CLEARNET SA',
  BACPFRPPDVP: 'BANQUE CENTRALE DE COMPENSATION - LCH.CLEARNET SA',
  BACPFRPPTRE: 'BANQUE CENTRALE DE COMPENSATION - LCH.CLEARNET SA',
  BACPFRPPBRU: 'BANQUE CENTRALE DE COMPENSATION - LCH.CLEARNET SA',
  BACPFRPPT01: 'BANQUE CENTRALE DE COMPENSATION - LCH.CLEARNET SA',
  BACPFRPPT02: 'BANQUE CENTRALE DE COMPENSATION - LCH.CLEARNET SA',
  BACPFRPPT03: 'BANQUE CENTRALE DE COMPENSATION - LCH.CLEARNET SA',
  BACPFRPPRCP: 'BANQUE CENTRALE DE COMPENSATION - LCH.CLEARNET SA',
  BACPFRPPTRI: 'BANQUE CENTRALE DE COMPENSATION - LCH.CLEARNET SA',
  BACPFRPPSSD: 'BANQUE CENTRALE DE COMPENSATION - LCH.CLEARNET SA',
  BACPFRPPVP1: 'BANQUE CENTRALE DE COMPENSATION - LCH.CLEARNET SA',
  BACPFRPPCBP: 'BANQUE CENTRALE DE COMPENSATION - LCH.CLEARNET SA',
  BACPFRPPOTC: 'BANQUE CENTRALE DE COMPENSATION - LCH.CLEARNET SA',
  BACPFRPPEQ2: 'BANQUE CENTRALE DE COMPENSATION - LCH.CLEARNET SA',
  BACPFRPPEQ1: 'BANQUE CENTRALE DE COMPENSATION - LCH.CLEARNET SA',
  BACPFRPPMMO: 'BANQUE CENTRALE DE COMPENSATION - LCH.CLEARNET SA',
  BACPFRPPSBL: 'BANQUE CENTRALE DE COMPENSATION - LCH.CLEARNET SA',
  BACPFRPPASI: 'BANQUE CENTRALE DE COMPENSATION - LCH.CLEARNET SA',
  BACPFRPPTG2: 'BANQUE CENTRALE DE COMPENSATION - LCH.CLEARNET SA',
  BACPFRPPUSC: 'BANQUE CENTRALE DE COMPENSATION - LCH.CLEARNET SA',
  BACPFRPPDEF: 'BANQUE CENTRALE DE COMPENSATION - LCH.CLEARNET SA',
  BACPFRPPCLI: 'BANQUE CENTRALE DE COMPENSATION - LCH.CLEARNET SA',
  BACPFRPP: 'BANQUE CENTRALE DE COMPENSATION - LCH.CLEARNET SA',
  BCDMFRPP: 'BANQUE CHAABI DU MAROC',
  COURFR2T: 'BANQUE COURTOIS S.A.',
  BDFEFR2T: 'BANQUE DE FRANCE',
  BDFEFRPP: 'BANQUE DE FRANCE',
  BDFEFR2TTCI: 'BANQUE DE FRANCE',
  BDFEFR2TTSB: 'BANQUE DE FRANCE',
  BDFEFR2TNCA: 'BANQUE DE FRANCE',
  BDFEFR2TTAH: 'BANQUE DE FRANCE',
  BDFEFR2TWAL: 'BANQUE DE FRANCE',
  BDFEFR2TFPT: 'BANQUE DE FRANCE',
  BDFEFR2T026: 'BANQUE DE FRANCE',
  BDFEFR2T033: 'BANQUE DE FRANCE',
  BDFEFR2T034: 'BANQUE DE FRANCE',
  BDFEFR2T043: 'BANQUE DE FRANCE',
  BDFEFR2T100: 'BANQUE DE FRANCE',
  BDFEFR2T101: 'BANQUE DE FRANCE',
  BDFEFR2T120: 'BANQUE DE FRANCE',
  BDFEFR2T121: 'BANQUE DE FRANCE',
  BDFEFR2T131: 'BANQUE DE FRANCE',
  BDFEFR2T141: 'BANQUE DE FRANCE',
  BDFEFR2T151: 'BANQUE DE FRANCE',
  BDFEFR2T171: 'BANQUE DE FRANCE',
  BDFEFR2T177: 'BANQUE DE FRANCE',
  BDFEFR2T211: 'BANQUE DE FRANCE',
  BDFEFR2T224: 'BANQUE DE FRANCE',
  BDFEFR2T282: 'BANQUE DE FRANCE',
  BDFEFR2T292: 'BANQUE DE FRANCE',
  BDFEFR2TC30: 'BANQUE DE FRANCE',
  BDFEFR2T3GX: 'BANQUE DE FRANCE',
  BDFEFR2T228: 'BANQUE DE FRANCE',
  BDFEFR2TAU2: 'BANQUE DE FRANCE',
  BDFEFR2TAUT: 'BANQUE DE FRANCE',
  BDFEFR2TALT: 'BANQUE DE FRANCE',
  BDFEFR2TFID: 'BANQUE DE FRANCE',
  BDFEFR2TCBT: 'BANQUE DE FRANCE',
  BDFEFR2TCP1: 'BANQUE DE FRANCE',
  BDFEFR2TCC1: 'BANQUE DE FRANCE',
  BDFEFR2T4CB: 'BANQUE DE FRANCE',
  BDFEFR2TFAC: 'BANQUE DE FRANCE',
  BDFEFR2TPIV: 'BANQUE DE FRANCE',
  BDFEFR2T200: 'BANQUE DE FRANCE',
  BDFEFR2TBDF: 'BANQUE DE FRANCE',
  BDFEFR2T188: 'BANQUE DE FRANCE',
  BDFEFR2T179: 'BANQUE DE FRANCE',
  BDFEFR2TE15: 'BANQUE DE FRANCE',
  BDFEFR2TESB: 'BANQUE DE FRANCE',
  BDFEFR2TIAM: 'BANQUE DE FRANCE',
  BDFEFR2T143: 'BANQUE DE FRANCE',
  BDFEFR2TE31: 'BANQUE DE FRANCE',
  BDFEFR2TCAI: 'BANQUE DE FRANCE',
  BDFEFR2TCBL: 'BANQUE DE FRANCE',
  BDFEFR2TC31: 'BANQUE DE FRANCE',
  BDFEFR2TC51: 'BANQUE DE FRANCE',
  BDFEFR2TC10: 'BANQUE DE FRANCE',
  BDFEFR2TC50: 'BANQUE DE FRANCE',
  BDFEFR2TSEE: 'BANQUE DE FRANCE',
  BDFEFR2TSCC: 'BANQUE DE FRANCE',
  BDFEFR2TTIT: 'BANQUE DE FRANCE',
  BDFEFR2TTRY: 'BANQUE DE FRANCE',
  BDFEFR2TCGN: 'BANQUE DE FRANCE',
  BDFEFR2TTCH: 'BANQUE DE FRANCE',
  BDFEFR2TFIC: 'BANQUE DE FRANCE',
  BDFEFR2TPOL: 'BANQUE DE FRANCE',
  BDFEFRPPCCT: 'BANQUE DE FRANCE',
  BDFEFR2TE30: 'BANQUE DE FRANCE',
  BDFEFR2TE10: 'BANQUE DE FRANCE',
  BDFEFR2TE14: 'BANQUE DE FRANCE',
  BDFEFR2TE11: 'BANQUE DE FRANCE',
  BDFEFR2TE12: 'BANQUE DE FRANCE',
  BDFEFR2TECR: 'BANQUE DE FRANCE',
  BDFEFR2TEBE: 'BANQUE DE FRANCE',
  BDFEFR2TENL: 'BANQUE DE FRANCE',
  BDFEFR2T721: 'BANQUE DE FRANCE',
  BDFEFR2T724: 'BANQUE DE FRANCE',
  BDFEFR2T782: 'BANQUE DE FRANCE',
  BDFEFR2T743: 'BANQUE DE FRANCE',
  BDFEFR2T733: 'BANQUE DE FRANCE',
  BDFEFR2T700: 'BANQUE DE FRANCE',
  BDFEFR2T720: 'BANQUE DE FRANCE',
  BDFEFR2T820: 'BANQUE DE FRANCE',
  BDFEFR2TTRA: 'BANQUE DE FRANCE',
  BDFEFR2TDSE: 'BANQUE DE FRANCE',
  BDFEFR2TSFP: 'BANQUE DE FRANCE',
  BDFEFR2TFPR: 'BANQUE DE FRANCE',
  BDFEFRPPSRD: 'BANQUE DE FRANCE',
  BDFEFR2TGCO: 'BANQUE DE FRANCE',
  BDFEFR2TGDP: 'BANQUE DE FRANCE',
  BDFEFR2T243: 'BANQUE DE FRANCE',
  BDFEFR2TIDD: 'BANQUE DE FRANCE',
  BDFEFR2TIND: 'BANQUE DE FRANCE',
  BDFEFRPPTEC: 'BANQUE DE FRANCE',
  BDFEFRPPMAP: 'BANQUE DE FRANCE',
  BDFEFR2TFI1: 'BANQUE DE FRANCE',
  BDFEFR2TFI2: 'BANQUE DE FRANCE',
  BDFEFR2TOPC: 'BANQUE DE FRANCE',
  BDFEFR2TERM: 'BANQUE DE FRANCE',
  BDFEFR2TNUM: 'BANQUE DE FRANCE',
  BDFEFR2TCCB: 'BANQUE DE FRANCE',
  BDFEFR2LCCB: 'BANQUE DE FRANCE',
  BDFEFR2TPOM: 'BANQUE DE FRANCE',
  BDFEFR2TICM: 'BANQUE DE FRANCE',
  BDFEFR2T104: 'BANQUE DE FRANCE',
  BDFEFR2TTEM: 'BANQUE DE FRANCE',
  BDFEFR2TCDT: 'BANQUE DE FRANCE',
  BDFEFR2TSRD: 'BANQUE DE FRANCE',
  BDFEFR2TSPM: 'BANQUE DE FRANCE',
  BDFEFR2TDMC: 'BANQUE DE FRANCE',
  BDFEFR2TSAI: 'BANQUE DE FRANCE',
  BDFEFR2TSPE: 'BANQUE DE FRANCE',
  BDFEFRPPTIT: 'BANQUE DE FRANCE',
  BDFEFRPPCMI: 'BANQUE DE FRANCE',
  BDFEFR2TETC: 'BANQUE DE FRANCE',
  BDFEFR2TSIP: 'BANQUE DE FRANCE',
  BDFEFR2TPUB: 'BANQUE DE FRANCE',
  BDFEFR2T102: 'BANQUE DE FRANCE',
  BDFEFR2TFTC: 'BANQUE DE FRANCE',
  BSAVFR2C: 'BANQUE DE SAVOIE S.A.',
  DELUFR22LCH: 'BANQUE DELUBAC ET CIE',
  DELUFR22: 'BANQUE DELUBAC ET CIE',
  EDELFR22: 'BANQUE EDEL SNC',
  CCBPFRPPINS: 'BANQUE FEDERALE DES BANQUES POPULAIRES (BANQUES POPULAIRES REGIONALES)',
  BDEIFRPP: 'BANQUE FIDUCIAL (FORMERLY BANQUE THEMIS)',
  BFCOFRPP: "BANQUE FRANCAISE COMMERCIALE DE L'OCEAN INDIEN",
  BICFFRPP: 'BANQUE INTERNATIONALE DE COMMERCE-BRED',
  LAYDFR2W: 'BANQUE LAYDERNIER',
  BMMMFRCP: 'BANQUE MARTIN-MAUREL',
  BAMYFR22BDX: 'BANQUE MICHEL INCHAUSPE - BAMI',
  BAMYFR22: 'BANQUE MICHEL INCHAUSPE - BAMI',
  BMISFRPP: 'BANQUE MISR - SUCCURSALE DE PARIS',
  NSMBFRPP: 'BANQUE NEUFLIZE OBC',
  NOMAFRPP: 'BANQUE NOMURA FRANCE S.A.',
  BSPFFRPP: 'BANQUE PALATINE',
  CCBPFRPPMTZ: 'BANQUE POPULAIRE ALSACE LORRAINE CHAMPAGNE',
  CCBPFRPPSTR: 'BANQUE POPULAIRE ALSACE LORRAINE CHAMPAGNE (FORMER BANQUE POPULAIRE ALSACE)',
  CCBPFRPPBDX: 'BANQUE POPULAIRE AQUITAINE CENTRE ATLANTIQUE',
  CCBPFRPP169: 'BANQUE POPULAIRE AQUITAINE CENTRE ATLANTIQUE',
  CCBPFRPPCFD: 'BANQUE POPULAIRE AUVERGNE RHONE ALPES',
  CCBPFRPPLYO: 'BANQUE POPULAIRE AUVERGNE RHONE ALPES',
  CCBPFRPPGRE: 'BANQUE POPULAIRE AUVERGNE RHONE ALPES',
  CCBPFRPPDJN: 'BANQUE POPULAIRE BOURGOGNE FRANCHE COMTE',
  CCBPFRPPLIL: 'BANQUE POPULAIRE DU NORD',
  CCBPFRPPPPG: 'BANQUE POPULAIRE DU SUD',
  CCBPFRPP149: 'BANQUE POPULAIRE GRAND OUEST',
  CCBPFRPPREN: 'BANQUE POPULAIRE GRAND OUEST',
  CCBPFRPPNAN: 'BANQUE POPULAIRE GRAND OUEST',
  CCBPFRPP219: 'BANQUE POPULAIRE GRAND-OUEST',
  CCBPFRPPNCE: 'BANQUE POPULAIRE MEDITERRANEE',
  CCBPFRPPCHX: 'BANQUE POPULAIRE MEDITERRANEE',
  CCBPFRPPMAR: 'BANQUE POPULAIRE MEDITERRANEE',
  CCBPFRPPTLS: 'BANQUE POPULAIRE OCCITANE',
  CCBPFRPPMTG: 'BANQUE POPULAIRE RIVES DE PARIS',
  CCBPFRPPVER: 'BANQUE POPULAIRE VAL DE FRANCE',
  PSABFRPP: 'BANQUE PSA FINANCE SA',
  REVIFRPP: 'BANQUE REVILLON',
  RALPFR2G: 'BANQUE RHONE ALPES',
  KBLXFRPP: 'BANQUE RICHELIEU FRANCE',
  SBAAFRPPTGT: 'BANQUE SBA',
  SBAAFRPP: 'BANQUE SBA',
  TARNFR2L: 'BANQUE TARNEAUD',
  TRAVFRPP: 'BANQUE TRAVELEX SA',
  ESCBFRPPBDF: "BANQUE WORMSER FRERES / BANQUE D'ESCOMPTE",
  ESCBFRPP: "BANQUE WORMSER FRERES / BANQUE D'ESCOMPTE",
  CCBPFRPP: 'BANQUES POPULAIRES-GROUPE BPCE',
  BARCFRPC: 'BARCLAYS BANK IRELAND PLC PARIS',
  BARCFRPP: 'BARCLAYS BANK IRELAND PLC PARIS',
  FBELFRPP: 'BEL',
  BEEVFRPP: 'BELIEVE S.A.S.',
  BEMOFRPP: 'BEMO EUROPE BANQUE PRIVEE',
  BNTOFR2S: 'BENETEAU',
  BGFIFRPPFGX: 'BGFIBANK EUROPE',
  BGFIFRPPRSV: 'BGFIBANK EUROPE',
  BGFIFRPP: 'BGFIBANK EUROPE',
  BIGMFRPP: 'BIG MAMMA FOOD SAS',
  BIOMFR22: 'BIOMERIEUX',
  BLOMFRPPBDF: 'BLOM BANK FRANCE',
  BLOMFRPP: 'BLOM BANK FRANCE',
  BMSOFR23: 'BM SOFTWARE',
  BMCEFRPP: 'BMCE BANK INTERNATIONAL PLC SUCCURSALE EN FRANCE',
  BNPAFRPH: 'BNP PARIBAS (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNABFRPPAGY: 'BNP PARIBAS ARBITRAGE',
  BNABFRPPMOR: 'BNP PARIBAS ARBITRAGE',
  BNABFRPPOTC: 'BNP PARIBAS ARBITRAGE',
  BNABFRPPPRE: 'BNP PARIBAS ARBITRAGE',
  BNABFRPPOST: 'BNP PARIBAS ARBITRAGE',
  BNABFRPPLON: 'BNP PARIBAS ARBITRAGE',
  BNABFRPPSVC: 'BNP PARIBAS ARBITRAGE',
  BNABFRPPFND: 'BNP PARIBAS ARBITRAGE',
  BNABFRPPTRE: 'BNP PARIBAS ARBITRAGE',
  BNABFRPPUSA: 'BNP PARIBAS ARBITRAGE',
  BNABFRPP: 'BNP PARIBAS ARBITRAGE',
  PARBFRPM: 'BNP PARIBAS ASSET MANAGEMENT',
  FAMSFRPP: 'BNP PARIBAS FINAMS',
  BNPAFRPL: 'BNP PARIBAS HOME LOAN SFH',
  BNPAFRPS: 'BNP PARIBAS PUBLIC SECTOR SCF',
  BNPAFRPPBRO: 'BNP PARIBAS SA (FORMELY BANQUE NATIONALE DE PARIS S.A.)',
  BPASFRPP: 'BNP PARIBAS SECURITIES SERVICES',
  PARBFRPH: 'BNP PARIBAS SECURITIES SERVICES',
  BPASFRPPLUX: 'BNP PARIBAS SECURITIES SERVICES',
  PARBFRKA: 'BNP PARIBAS SECURITIES SERVICES',
  PARBFRKB: 'BNP PARIBAS SECURITIES SERVICES',
  PARBFRKC: 'BNP PARIBAS SECURITIES SERVICES',
  PARBFRKD: 'BNP PARIBAS SECURITIES SERVICES',
  PARBFRKE: 'BNP PARIBAS SECURITIES SERVICES',
  PARBFRKF: 'BNP PARIBAS SECURITIES SERVICES',
  PARBFRCB: 'BNP PARIBAS SECURITIES SERVICES',
  PARBFRCP: 'BNP PARIBAS SECURITIES SERVICES',
  BNPAFRPPAGN: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPAIP: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPALB: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPAMS: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPAGS: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPANC: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPARR: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPAUX: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPAVI: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPBAY: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPBVS: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPBSC: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPBOR: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPBOT: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPSAE: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPBRE: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPBRV: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPCAE: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPCAN: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPCER: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPCHL: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPCHS: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPCLF: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPCRE: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPCRT: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPBGE: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPDIJ: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPDIN: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPDUN: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPENG: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPEPL: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPEVY: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPGAP: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPGNV: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPAAE: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPGRE: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPIVR: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPROC: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPHAV: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPMAN: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPNFE: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPLIL: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPLMV: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPLOR: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPVBE: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPLPD: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPTAS: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPMNT: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPMED: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPMEE: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPMAR: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPMAS: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPMEL: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPMTZ: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPMPL: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPMUL: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPCNA: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPNCY: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPVLE: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPNAN: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPNEU: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPNIC: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPNIM: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPORE: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPORL: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPCCS: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPCTG: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPDEF: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPGAI: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPPBP: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPPPP: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPANI: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPPAA: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPPBQ: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPCHQ: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPAFE: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPAEA: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPBFI: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPEMC: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPPBY: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPEQD: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPP032: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPP038: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPP039: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPP201: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPPBU: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPICE: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPIFS: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPPAE: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPCPI: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPIPI: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPCFD: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPPCE: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPCDE: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPCWB: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPCIT: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPSCE: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPPLZ: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPMPE: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPACH: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPCFI: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPCSP: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPFND: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPPGN: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPPAK: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPICP: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPPCP: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPP: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPIFE: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPIFN: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPIFO: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPIBF: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPINT: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPPTX: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPLAE: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPMDE: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPPXV: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPFXC: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPPOP: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPPAC: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPPVD: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPPEE: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPPGA: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPPRG: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPPSU: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPPKL: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPTTN: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPPTE: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPPGB: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPTSZ: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPPAU: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPPPG: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPPOI: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPCRM: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPRMS: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPCRN: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPREN: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPROA: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPROI: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPRBX: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPCRO: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPROU: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPBGT: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPETI: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPLAY: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPMDT: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPQUE: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPSQY: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPCST: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPSTR: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPTLN: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPCTO: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPTLS: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPTOU: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPTRO: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPBBT: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPVAL: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPVCS: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  BNPAFRPPVRS: 'BNP-PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)',
  AFRIFRPP: 'BOA-FRANCE',
  BCDDFR22: 'BOCCARD S.A.',
  BOFAFRP3: 'BOFA SECURITIES EUROPE S.A.',
  BOFAFRP4GPB: 'BOFA SECURITIES EUROPE S.A.',
  BOFAFRP4: 'BOFA SECURITIES EUROPE S.A.',
  BOFAFRP3PLX: 'BOFA SECURITIES EUROPE S.A.',
  BOFAFRP4ESF: 'BOFA SECURITIES EUROPE S.A.',
  BOFAFRP3SEG: 'BOFA SECURITIES EUROPE S.A.',
  BOFAFRP4ECL: 'BOFA SECURITIES EUROPE S.A.',
  BOFAFRP4EQD: 'BOFA SECURITIES EUROPE S.A.',
  BOFAFRP3FIC: 'BOFA SECURITIES EUROPE S.A.',
  BOFAFRP4FUT: 'BOFA SECURITIES EUROPE S.A.',
  BOFAFRP4EFG: 'BOFA SECURITIES EUROPE S.A.',
  BOFAFRP3TSY: 'BOFA SECURITIES EUROPE S.A.',
  BOFAFRP4UCT: 'BOFA SECURITIES EUROPE S.A.',
  BRONFR22: 'BOIRON',
  SCHNFR22: 'BOISSIERE FINANCE',
  SNEIFR22: 'BOISSIERE FINANCE',
  BOLLFR22: 'BOLLORE SA',
  BNDUFR22: 'BONDUELLE S.A.',
  BONAFRPP: 'BONNA SABLA SA',
  BDIRFRPP: 'BOURSE DIRECT SA',
  BOUSFRPPBDF: 'BOURSORAMA',
  BOUSFRPP: 'BOURSORAMA',
  BYCNFR22: 'BOUYGUES CONSTRUCTION',
  BYIMFR22: 'BOUYGUES IMMOBILIER',
  BPCEFRPPSFH: 'BPCE',
  BPCEFRPP: 'BPCE',
  SDBFFRPP: 'BPCE DEFI',
  BPAXFR22: 'BPIFRANCE ASSURANCE EXPORT',
  CPMEFRPPCRI: 'BPIFRANCE FINANCEMENT',
  CPMEFRPP: 'BPIFRANCE FINANCEMENT',
  BREDFRPP637: 'BRED BANQUE POPULAIRE',
  BREDFRPP638: 'BRED BANQUE POPULAIRE',
  BREDFRPPKUS: 'BRED BANQUE POPULAIRE',
  BREDFRPPAXL: 'BRED BANQUE POPULAIRE',
  BREDFRPPKYR: 'BRED BANQUE POPULAIRE',
  BREDFRPPBON: 'BRED BANQUE POPULAIRE',
  BREDFRPPKAS: 'BRED BANQUE POPULAIRE',
  BREDFRPP: 'BRED BANQUE POPULAIRE',
  BREDFRPPFDF: 'BRED BANQUE POPULAIRE',
  BREDFRPPTRE: 'BRED BANQUE POPULAIRE',
  BRGEFRPP: 'BRED GESTION',
  BFRFFRPP: "BRINK'S FRANCE FINANCE",
  BMSFFRPP: 'BRISTOL-MYERS SQUIBB',
  BUSAFRPP: 'BULL',
  KIAIFR22: 'BUNSHA INTERNATIONAL',
  BUVEFRPP: 'BUREAU VERITAS SA',
  BUTCFRPP: 'BUTLER CORUM S.A.S.',
  BYBBFRPP: 'BYBLOS BANK EUROPE S.A. (PARIS BRANCH)',
  CCVBFR22: 'C.C.V. BEAUMANOIR',
  SSLYFRPP: 'C.F.E.B SISLEY',
  CRRHFRPA: "C.R.H. - CAISSE DE REFINANCEMENT DE L'HABITAT S.A.",
  AGRLFR22: 'CA CONSUMER FINANCE',
  SIBLFRPP: 'CA INDOSUEZ WEALTH (FRANCE)',
  ISAEFRPPEXT: 'CACEIS BANK',
  ISAEFRPPETC: 'CACEIS BANK',
  ISAEFRPPTCC: 'CACEIS BANK',
  ISAEFRPPEXA: 'CACEIS BANK',
  ISAEFRPPMCA: 'CACEIS BANK',
  ISAEFRPPCMA: 'CACEIS BANK',
  ISAEFRPPAIF: 'CACEIS BANK',
  ISAEFRPPFDS: 'CACEIS BANK',
  ISAEFRPP4SF: 'CACEIS BANK',
  ISAEFRPPINS: 'CACEIS BANK',
  ISAEFRPPUCI: 'CACEIS BANK',
  ISAEFRPPPER: 'CACEIS BANK',
  ISAEFRPPPTF: 'CACEIS BANK',
  ISAEFRPPCAI: 'CACEIS BANK',
  ISAEFRPPITM: 'CACEIS BANK',
  ISAEFRPPCBL: 'CACEIS BANK',
  ISAEFRPPISC: 'CACEIS BANK',
  ISAEFRPPISO: 'CACEIS BANK',
  ISAEFRPPIMC: 'CACEIS BANK',
  ISAEFRPPIMO: 'CACEIS BANK',
  ISAEFRPPMFS: 'CACEIS BANK',
  ISAEFRPPSOL: 'CACEIS BANK',
  ISAEFRPPS3C: 'CACEIS BANK',
  ISAEFRPPINC: 'CACEIS BANK',
  ISAEFRPPINO: 'CACEIS BANK',
  ISAEFRPPKEC: 'CACEIS BANK',
  ISAEFRPPKEO: 'CACEIS BANK',
  ISAEFRPPNBC: 'CACEIS BANK',
  ISAEFRPPNBO: 'CACEIS BANK',
  ISAEFRPPOPX: 'CACEIS BANK',
  ISAEFRPPOPO: 'CACEIS BANK',
  ISAEFRPPTUL: 'CACEIS BANK',
  ISAEFRPPPFS: 'CACEIS BANK',
  ISAEFRPPTBM: 'CACEIS BANK',
  ISAEFRPPCAL: 'CACEIS BANK',
  ISAEFRPPAGT: 'CACEIS BANK',
  ISAEFRPPBAA: 'CACEIS BANK',
  ISAEFRPPCAC: 'CACEIS BANK',
  ISAEFRPPCBD: 'CACEIS BANK',
  ISAEFRPPCEI: 'CACEIS BANK',
  ISAEFRPPEQI: 'CACEIS BANK',
  ISAEFRPPERF: 'CACEIS BANK',
  ISAEFRPPNAT: 'CACEIS BANK',
  ISAEFRPPOWN: 'CACEIS BANK',
  ISAEFRPPUCB: 'CACEIS BANK',
  ISAEFRPPUCM: 'CACEIS BANK',
  ISAEFRPPWST: 'CACEIS BANK',
  ISAEFRPPDMA: 'CACEIS BANK',
  ISAEFRPPSA1: 'CACEIS BANK',
  ISAEFRPPSA2: 'CACEIS BANK',
  ISAEFRPPMID: 'CACEIS BANK',
  ISAEFRPP: 'CACEIS BANK',
  FNETFRPP: 'CACEIS FUND ADMINISTRATION',
  AGRJFRPP: 'CACI - CREDIT AGRICOLE CREDITOR INSURANCE SA',
  CCIFFRPP: 'CAISSE CENTRALE DU CREDIT IMMOBILIER DE FRANCE',
  CEPAFRPP802: 'CAISSE D EPARGNE ET DE PREVOYANCE HAUTS DE FRANCE',
  CEPAFRPP627: 'CAISSE D EPARGNE ET DE PREVOYANCE HAUTS DE FRANCE',
  CEPAFRPPBRU: 'CAISSE D EPARGNE ET DE PREVOYANCE HAUTS DE FRANCE',
  CEPAFRPP333: "CAISSE D'EPARGNE AQUITAINE POITOU CHARENTES",
  CEPAFRPP871: "CAISSE D'EPARGNE AUVERGNE LIMOUSIN",
  CEPAFRPP444: "CAISSE D'EPARGNE BRETAGNE PAYS DE LOIRE",
  CEPAFRPP131: "CAISSE D'EPARGNE CEPAC",
  CEPAFRPP831: "CAISSE D'EPARGNE COTE D'AZUR",
  CEPAFRPP213: "CAISSE D'EPARGNE DE BOURGOGNE FRANCHE-COMTE",
  CEPAFRPP426: "CAISSE D'EPARGNE DE LOIRE DROME ARDECHE",
  CEPAFRPP313: "CAISSE D'EPARGNE DE MIDI PYRENEES",
  CEPAFRPP348: "CAISSE D'EPARGNE DU LANGUEDOC ROUSSILLON",
  CEPAFRPP513: "CAISSE D'EPARGNE GRAND EST EUROPE",
  CEPAFRPP670: "CAISSE D'EPARGNE GRAND EST EUROPE",
  CEPAFRPP751: "CAISSE D'EPARGNE ILE DE FRANCE",
  CEPAFRPP450: "CAISSE D'EPARGNE LOIRE CENTRE",
  CEPAFRPP142: "CAISSE D'EPARGNE NORMANDIE",
  CEPAFRPP382: "CAISSE D'EPARGNE RHONE ALPES",
  CDCGFRPPCAC: 'CAISSE DES DEPOTS ET CONSIGNATIONS',
  CDCGFRPPFRR: 'CAISSE DES DEPOTS ET CONSIGNATIONS',
  CDCGFRPPOAC: 'CAISSE DES DEPOTS ET CONSIGNATIONS',
  CDCGFRPPPAC: 'CAISSE DES DEPOTS ET CONSIGNATIONS',
  CDCGFRPP: 'CAISSE DES DEPOTS ET CONSIGNATIONS',
  DXMAFRPP: 'CAISSE FRANCAISE DE FINANCEMENT LOCAL',
  CEPAFRPP: "CAISSES D'EPARGNE-GROUPE BPCE",
  CEPAFRPP950: "CAISSES D'EPARGNE-GROUPE BPCE",
  CGDIFRPP: 'CAIXA GERAL DE DEPOSITOS',
  IMERFR22: 'CALDERYS FRANCE SAS',
  CMAIFR22: 'CAMAIEU',
  DXAMFRPP: 'CANDRIAM FRANCE SA',
  CAPGFRPP: 'CAP GEMINI SERVICE SAS',
  CAQDFRPP: 'CARDIF - ASSURANCES RISQUES DIVERS',
  CAQDFRPPAEP: 'CARDIF - ASSURANCES RISQUES DIVERS',
  SOAPFR22: 'CARREFOUR BANQUE',
  CCBPFRPPCAS: 'CASDEN-BANQUE POPULAIRE',
  CAYGFRP2: 'CASHBEE',
  TCATFRPP: "CAT COMPAGNIE D'AFFRETEMENT ET DE TRANSPORT",
  CBSEFR22: 'CBASE',
  CBSPFR22: 'CBP GROUP',
  FCCIFRPP: 'CCI FINANCE',
  CEUSFR22: 'CEETRUS S.A.',
  CGDGFR22: 'CEGID GROUP',
  CFAFFR22: 'CFAO',
  CHNLFR22: 'CHANEL',
  CTLLFRPP: 'CHANTELLE SA',
  CHGGFRPP: 'CHARGEURS S.A.',
  CHKCFRP2: 'CHECKOUT',
  CHDUFRPP: 'CHOLET DUPONT SA',
  DCOUFRPP: 'CHRISTIAN DIOR COUTURE',
  CLOUFRPP: 'CHRISTIAN LOUBOUTIN',
  CITIFRPPSEC: 'CITIBANK EUROPE PLC FRANCE BRANCH',
  CITIFRPP: 'CITIBANK EUROPE PLC FRANCE BRANCH',
  CLNSFRPP: 'CLARINS',
  CLSQFR22: 'CLASQUIN SA',
  CMEDFRPP: 'CLUB MEDITERRANEE',
  CMCIFRPPCOR: 'CM - CIC BANQUES',
  CMCIFRPP: 'CM - CIC BANQUES',
  CMAGFR22: 'CMA CGM',
  CNPAFRPP: 'CNP ASSURANCES',
  CNPAFRPPUCS: 'CNP ASSURANCES',
  CNPAFRPPCDP: 'CNP ASSURANCES',
  CNPAFRPPNRT: 'CNP ASSURANCES',
  CNPAFRPPQIS: 'CNP ASSURANCES',
  COFAFR22: 'COFACE',
  CLLAFRPP: 'COLAS S.A.',
  CLNEFRPP: 'COLISEE INTERNATIONAL S.A.',
  COLLFR22: 'COLLECTE LOCALISATION SATELLITES',
  CLPNFRP2: 'COLVILLE CAPITAL PARTNERS',
  CPSPFRPP: 'COMITE PROFESSIONNEL DES STOCKS STRATEGIQUES PETROLIERS (CPSSP)',
  COBAFRPXTPA: 'COMMERZBANK AG',
  COBAFRPX: 'COMMERZBANK AG',
  CHOMFRPP: 'COMPAGNIE CHOMARAT',
  GIEOFRPP: "COMPAGNIE D'INVESTISSEMENTS INDUSTRIELS ET COMMERCIAUX - CIIC",
  DAHRFR2M: 'COMPAGNIE DAHER S.A.',
  CFFOFRPPSSS: 'COMPAGNIE DE FINANCEMENT FONCIER',
  CFFOFRPP: 'COMPAGNIE DE FINANCEMENT FONCIER',
  SGOBFRPP: 'COMPAGNIE DE SAINT-GOBAIN',
  CDALFRPP: 'COMPAGNIE DES ALPES',
  CPNOFR2M: 'COMPAGNIE DU PONANT SAS',
  ROULFR22: 'COMPAGNIE FINANCIERE ET DE PARTICIPATIONS ROULLIER',
  CGCPFRPL: 'COMPAGNIE GENERALE DE CREDIT AUX PARTICULIERS - CREDIPAR',
  CGLEFR2M: "COMPAGNIE GENERALE DE LOCATION D'EQUIPEMENTS",
  POMNFR22: 'COMPAGNIE PLASTIC OMNIUM',
  CNPGFRP2: 'CONSEIL PLUS GESTION',
  CGALFRPP: 'CONTOURGLOBAL MANAGEMENT FRANCE SAS',
  CEFPFRPP: 'COUNCIL OF EUROPE DEVELOPMENT BANK',
  FDREFR22: 'COVIVIO',
  CPBIFRPP: 'CP OR DEVISES',
  CPRMFRPP: 'CPR ASSET MANAGEMENT',
  CPRPFR22: 'CPRPSNCF',
  AGRIFRPPABV: 'CREDIT AGRICOLE',
  AGRIFRPPAGD: 'CREDIT AGRICOLE',
  AGRIFRPPAGN: 'CREDIT AGRICOLE',
  AGRIFRPPAIR: 'CREDIT AGRICOLE',
  AGRIFRPPAIX: 'CREDIT AGRICOLE',
  AGRIFRPPAIB: 'CREDIT AGRICOLE',
  AGRIFRPP820: 'CREDIT AGRICOLE',
  AGRIFRPPALB: 'CREDIT AGRICOLE',
  AGRIFRPP812: 'CREDIT AGRICOLE',
  AGRIFRPPALN: 'CREDIT AGRICOLE',
  AGRIFRPPALO: 'CREDIT AGRICOLE',
  AGRIFRPPALE: 'CREDIT AGRICOLE',
  AGRIFRPPAMB: 'CREDIT AGRICOLE',
  AGRIFRPP887: 'CREDIT AGRICOLE',
  AGRIFRPPANC: 'CREDIT AGRICOLE',
  AGRIFRPPAGS: 'CREDIT AGRICOLE',
  AGRIFRPPANG: 'CREDIT AGRICOLE',
  AGRIFRPP881: 'CREDIT AGRICOLE',
  AGRIFRPPANM: 'CREDIT AGRICOLE',
  AGRIFRPPANN: 'CREDIT AGRICOLE',
  AGRIFRPPANT: 'CREDIT AGRICOLE',
  AGRIFRPPAPT: 'CREDIT AGRICOLE',
  AGRIFRPPARC: 'CREDIT AGRICOLE',
  AGRIFRPPARU: 'CREDIT AGRICOLE',
  AGRIFRPPART: 'CREDIT AGRICOLE',
  AGRIFRPPARG: 'CREDIT AGRICOLE',
  AGRIFRPP813: 'CREDIT AGRICOLE',
  AGRIFRPPARM: 'CREDIT AGRICOLE',
  AGRIFRPPARR: 'CREDIT AGRICOLE',
  AGRIFRPPASN: 'CREDIT AGRICOLE',
  AGRIFRPPABG: 'CREDIT AGRICOLE',
  AGRIFRPPAUE: 'CREDIT AGRICOLE',
  AGRIFRPPAUB: 'CREDIT AGRICOLE',
  AGRIFRPPAUI: 'CREDIT AGRICOLE',
  AGRIFRPPAUC: 'CREDIT AGRICOLE',
  AGRIFRPPAUR: 'CREDIT AGRICOLE',
  AGRIFRPPAUT: 'CREDIT AGRICOLE',
  AGRIFRPPAUN: 'CREDIT AGRICOLE',
  AGRIFRPPAUX: 'CREDIT AGRICOLE',
  AGRIFRPPAVI: 'CREDIT AGRICOLE',
  AGRIFRPPAVO: 'CREDIT AGRICOLE',
  AGRIFRPPAVR: 'CREDIT AGRICOLE',
  AGRIFRPPBAC: 'CREDIT AGRICOLE',
  AGRIFRPPBLN: 'CREDIT AGRICOLE',
  AGRIFRPPBAG: 'CREDIT AGRICOLE',
  AGRIFRPPBAN: 'CREDIT AGRICOLE',
  AGRIFRPPBAR: 'CREDIT AGRICOLE',
  AGRIFRPPBAS: 'CREDIT AGRICOLE',
  AGRIFRPPBAX: 'CREDIT AGRICOLE',
  AGRIFRPPBAY: 'CREDIT AGRICOLE',
  AGRIFRPPBAZ: 'CREDIT AGRICOLE',
  AGRIFRPPBEJ: 'CREDIT AGRICOLE',
  AGRIFRPPBDV: 'CREDIT AGRICOLE',
  AGRIFRPPBSO: 'CREDIT AGRICOLE',
  AGRIFRPPBEA: 'CREDIT AGRICOLE',
  AGRIFRPPBEV: 'CREDIT AGRICOLE',
  AGRIFRPPBED: 'CREDIT AGRICOLE',
  AGRIFRPPBLT: 'CREDIT AGRICOLE',
  AGRIFRPPBEL: 'CREDIT AGRICOLE',
  AGRIFRPPBER: 'CREDIT AGRICOLE',
  AGRIFRPPBES: 'CREDIT AGRICOLE',
  AGRIFRPPBLG: 'CREDIT AGRICOLE',
  AGRIFRPP825: 'CREDIT AGRICOLE',
  AGRIFRPPBET: 'CREDIT AGRICOLE',
  AGRIFRPPBZR: 'CREDIT AGRICOLE',
  AGRIFRPPBIA: 'CREDIT AGRICOLE',
  AGRIFRPPBCE: 'CREDIT AGRICOLE',
  AGRIFRPPBLA: 'CREDIT AGRICOLE',
  AGRIFRPPBAE: 'CREDIT AGRICOLE',
  AGRIFRPPBLO: 'CREDIT AGRICOLE',
  AGRIFRPPBOB: 'CREDIT AGRICOLE',
  AGRIFRPPBOE: 'CREDIT AGRICOLE',
  AGRIFRPPBOL: 'CREDIT AGRICOLE',
  AGRIFRPPBOD: 'CREDIT AGRICOLE',
  AGRIFRPPBON: 'CREDIT AGRICOLE',
  AGRIFRPP833: 'CREDIT AGRICOLE',
  AGRIFRPPBBT: 'CREDIT AGRICOLE',
  AGRIFRPPBSM: 'CREDIT AGRICOLE',
  AGRIFRPPBDO: 'CREDIT AGRICOLE',
  AGRIFRPPBEB: 'CREDIT AGRICOLE',
  AGRIFRPPBLR: 'CREDIT AGRICOLE',
  AGRIFRPPBGI: 'CREDIT AGRICOLE',
  AGRIFRPP848: 'CREDIT AGRICOLE',
  AGRIFRPPBJA: 'CREDIT AGRICOLE',
  AGRIFRPPBOJ: 'CREDIT AGRICOLE',
  AGRIFRPPBZV: 'CREDIT AGRICOLE',
  AGRIFRPPBRE: 'CREDIT AGRICOLE',
  AGRIFRPPBST: 'CREDIT AGRICOLE',
  AGRIFRPPBRN: 'CREDIT AGRICOLE',
  AGRIFRPPBRT: 'CREDIT AGRICOLE',
  AGRIFRPPBRS: 'CREDIT AGRICOLE',
  AGRIFRPPBRG: 'CREDIT AGRICOLE',
  AGRIFRPPBRI: 'CREDIT AGRICOLE',
  AGRIFRPPBRV: 'CREDIT AGRICOLE',
  AGRIFRPPBRU: 'CREDIT AGRICOLE',
  AGRIFRPPCAB: 'CREDIT AGRICOLE',
  AGRIFRPPCAD: 'CREDIT AGRICOLE',
  AGRIFRPP866: 'CREDIT AGRICOLE',
  AGRIFRPPCAG: 'CREDIT AGRICOLE',
  AGRIFRPPCAH: 'CREDIT AGRICOLE',
  AGRIFRPPCAL: 'CREDIT AGRICOLE',
  AGRIFRPPCAM: 'CREDIT AGRICOLE',
  AGRIFRPPCAN: 'CREDIT AGRICOLE',
  AGRIFRPPCAR: 'CREDIT AGRICOLE',
  AGRIFRPPCAT: 'CREDIT AGRICOLE',
  AGRIFRPPCAQ: 'CREDIT AGRICOLE',
  AGRIFRPPCSI: 'CREDIT AGRICOLE',
  AGRIFRPPCAC: 'CREDIT AGRICOLE',
  AGRIFRPPCNY: 'CREDIT AGRICOLE',
  AGRIFRPPCAS: 'CREDIT AGRICOLE',
  AGRIFRPPCAU: 'CREDIT AGRICOLE',
  AGRIFRPPCAV: 'CREDIT AGRICOLE',
  AGRIFRPPCVL: 'CREDIT AGRICOLE',
  AGRIFRPPCET: 'CREDIT AGRICOLE',
  AGRIFRPPCER: 'CREDIT AGRICOLE',
  AGRIFRPPCES: 'CREDIT AGRICOLE',
  AGRIFRPPCIS: 'CREDIT AGRICOLE',
  AGRIFRPPCLL: 'CREDIT AGRICOLE',
  AGRIFRPPCSS: 'CREDIT AGRICOLE',
  AGRIFRPPCEC: 'CREDIT AGRICOLE',
  AGRIFRPPCHA: 'CREDIT AGRICOLE',
  AGRIFRPPCHY: 'CREDIT AGRICOLE',
  AGRIFRPPCHX: 'CREDIT AGRICOLE',
  AGRIFRPPCMO: 'CREDIT AGRICOLE',
  AGRIFRPPCHI: 'CREDIT AGRICOLE',
  AGRIFRPPCTY: 'CREDIT AGRICOLE',
  AGRIFRPPCHM: 'CREDIT AGRICOLE',
  AGRIFRPPSRH: 'CREDIT AGRICOLE',
  AGRIFRPPCHL: 'CREDIT AGRICOLE',
  AGRIFRPP844: 'CREDIT AGRICOLE',
  AGRIFRPPCDP: 'CREDIT AGRICOLE',
  AGRIFRPPLHS: 'CREDIT AGRICOLE',
  AGRIFRPPCEU: 'CREDIT AGRICOLE',
  AGRIFRPPCSA: 'CREDIT AGRICOLE',
  AGRIFRPPCHT: 'CREDIT AGRICOLE',
  AGRIFRPPCHN: 'CREDIT AGRICOLE',
  AGRIFRPPCHD: 'CREDIT AGRICOLE',
  AGRIFRPPCHR: 'CREDIT AGRICOLE',
  AGRIFRPPCHU: 'CREDIT AGRICOLE',
  AGRIFRPPCTL: 'CREDIT AGRICOLE',
  AGRIFRPPCOU: 'CREDIT AGRICOLE',
  AGRIFRPPCMT: 'CREDIT AGRICOLE',
  AGRIFRPPCEE: 'CREDIT AGRICOLE',
  AGRIFRPPCHE: 'CREDIT AGRICOLE',
  AGRIFRPPCHO: 'CREDIT AGRICOLE',
  AGRIFRPPCLT: 'CREDIT AGRICOLE',
  AGRIFRPPCLM: 'CREDIT AGRICOLE',
  AGRIFRPP868: 'CREDIT AGRICOLE',
  AGRIFRPPCLY: 'CREDIT AGRICOLE',
  AGRIFRPPCLI: 'CREDIT AGRICOLE',
  AGRIFRPPCLU: 'CREDIT AGRICOLE',
  AGRIFRPPCOG: 'CREDIT AGRICOLE',
  AGRIFRPPCOL: 'CREDIT AGRICOLE',
  AGRIFRPPCMP: 'CREDIT AGRICOLE',
  AGRIFRPPCNU: 'CREDIT AGRICOLE',
  AGRIFRPPCOD: 'CREDIT AGRICOLE',
  AGRIFRPPCSH: 'CREDIT AGRICOLE',
  AGRIFRPPCRB: 'CREDIT AGRICOLE',
  AGRIFRPPCOS: 'CREDIT AGRICOLE',
  AGRIFRPPCMM: 'CREDIT AGRICOLE',
  AGRIFRPPCCR: 'CREDIT AGRICOLE',
  AGRIFRPPCOV: 'CREDIT AGRICOLE',
  AGRIFRPPCTH: 'CREDIT AGRICOLE',
  AGRIFRPPCOT: 'CREDIT AGRICOLE',
  AGRIFRPPCRE: 'CREDIT AGRICOLE',
  AGRIFRPPCRU: 'CREDIT AGRICOLE',
  AGRIFRPPCRM: 'CREDIT AGRICOLE',
  AGRIFRPPCRO: 'CREDIT AGRICOLE',
  AGRIFRPPCEV: 'CREDIT AGRICOLE',
  AGRIFRPPCRT: 'CREDIT AGRICOLE',
  AGRIFRPPDAM: 'CREDIT AGRICOLE',
  AGRIFRPPDAX: 'CREDIT AGRICOLE',
  AGRIFRPPDEC: 'CREDIT AGRICOLE',
  AGRIFRPPDIC: 'CREDIT AGRICOLE',
  AGRIFRPPDEI: 'CREDIT AGRICOLE',
  AGRIFRPPDIE: 'CREDIT AGRICOLE',
  AGRIFRPPDIG: 'CREDIT AGRICOLE',
  AGRIFRPPDIJ: 'CREDIT AGRICOLE',
  AGRIFRPPDIN: 'CREDIT AGRICOLE',
  AGRIFRPPDOM: 'CREDIT AGRICOLE',
  AGRIFRPPDOU: 'CREDIT AGRICOLE',
  AGRIFRPP891: 'CREDIT AGRICOLE',
  AGRIFRPPDRX: 'CREDIT AGRICOLE',
  AGRIFRPPDUN: 'CREDIT AGRICOLE',
  AGRIFRPPECL: 'CREDIT AGRICOLE',
  AGRIFRPPELB: 'CREDIT AGRICOLE',
  AGRIFRPPENG: 'CREDIT AGRICOLE',
  AGRIFRPPEPY: 'CREDIT AGRICOLE',
  AGRIFRPPEPL: 'CREDIT AGRICOLE',
  AGRIFRPPERS: 'CREDIT AGRICOLE',
  AGRIFRPPESP: 'CREDIT AGRICOLE',
  AGRIFRPPETA: 'CREDIT AGRICOLE',
  AGRIFRPPEVX: 'CREDIT AGRICOLE',
  AGRIFRPPEVR: 'CREDIT AGRICOLE',
  AGRIFRPPFAL: 'CREDIT AGRICOLE',
  AGRIFRPPFAY: 'CREDIT AGRICOLE',
  AGRIFRPPFEC: 'CREDIT AGRICOLE',
  AGRIFRPPFEV: 'CREDIT AGRICOLE',
  AGRIFRPPFEY: 'CREDIT AGRICOLE',
  AGRIFRPPFIG: 'CREDIT AGRICOLE',
  AGRIFRPPFLA: 'CREDIT AGRICOLE',
  AGRIFRPPFLE: 'CREDIT AGRICOLE',
  AGRIFRPPFLI: 'CREDIT AGRICOLE',
  AGRIFRPPFOX: 'CREDIT AGRICOLE',
  AGRIFRPPFON: 'CREDIT AGRICOLE',
  AGRIFRPPFOT: 'CREDIT AGRICOLE',
  AGRIFRPPFLC: 'CREDIT AGRICOLE',
  AGRIFRPPFSB: 'CREDIT AGRICOLE',
  AGRIFRPPFRB: 'CREDIT AGRICOLE',
  AGRIFRPPFOG: 'CREDIT AGRICOLE',
  AGRIFRPPFRE: 'CREDIT AGRICOLE',
  AGRIFRPPFRI: 'CREDIT AGRICOLE',
  AGRIFRPPGAP: 'CREDIT AGRICOLE',
  AGRIFRPPGAR: 'CREDIT AGRICOLE',
  AGRIFRPPGNS: 'CREDIT AGRICOLE',
  AGRIFRPPGEN: 'CREDIT AGRICOLE',
  AGRIFRPPGNV: 'CREDIT AGRICOLE',
  AGRIFRPPGEX: 'CREDIT AGRICOLE',
  AGRIFRPPGIE: 'CREDIT AGRICOLE',
  AGRIFRPPGIO: 'CREDIT AGRICOLE',
  AGRIFRPPGIS: 'CREDIT AGRICOLE',
  AGRIFRPPGOR: 'CREDIT AGRICOLE',
  AGRIFRPPGOU: 'CREDIT AGRICOLE',
  AGRIFRPPGRA: 'CREDIT AGRICOLE',
  AGRIFRPPGRS: 'CREDIT AGRICOLE',
  AGRIFRPPGRL: 'CREDIT AGRICOLE',
  AGRIFRPP839: 'CREDIT AGRICOLE',
  AGRIFRPPGBW: 'CREDIT AGRICOLE',
  AGRIFRPPGUE: 'CREDIT AGRICOLE',
  AGRIFRPPGIL: 'CREDIT AGRICOLE',
  AGRIFRPPGUI: 'CREDIT AGRICOLE',
  AGRIFRPPMTA: 'CREDIT AGRICOLE',
  AGRIFRPPHGN: 'CREDIT AGRICOLE',
  AGRIFRPPHAY: 'CREDIT AGRICOLE',
  AGRIFRPPHAZ: 'CREDIT AGRICOLE',
  AGRIFRPPHEN: 'CREDIT AGRICOLE',
  AGRIFRPPHET: 'CREDIT AGRICOLE',
  AGRIFRPPHYX: 'CREDIT AGRICOLE',
  AGRIFRPPHEY: 'CREDIT AGRICOLE',
  AGRIFRPPHOU: 'CREDIT AGRICOLE',
  AGRIFRPPHYE: 'CREDIT AGRICOLE',
  AGRIFRPPIAB: 'CREDIT AGRICOLE',
  AGRIFRPPILS: 'CREDIT AGRICOLE',
  AGRIFRPPISS: 'CREDIT AGRICOLE',
  AGRIFRPPILM: 'CREDIT AGRICOLE',
  AGRIFRPPIST: 'CREDIT AGRICOLE',
  AGRIFRPPIVR: 'CREDIT AGRICOLE',
  AGRIFRPPJSO: 'CREDIT AGRICOLE',
  AGRIFRPPLAI: 'CREDIT AGRICOLE',
  AGRIFRPPLCI: 'CREDIT AGRICOLE',
  AGRIFRPPLAD: 'CREDIT AGRICOLE',
  AGRIFRPPLRE: 'CREDIT AGRICOLE',
  AGRIFRPPROF: 'CREDIT AGRICOLE',
  AGRIFRPPLRY: 'CREDIT AGRICOLE',
  AGRIFRPPROC: 'CREDIT AGRICOLE',
  AGRIFRPP817: 'CREDIT AGRICOLE',
  AGRIFRPPLSM: 'CREDIT AGRICOLE',
  AGRIFRPPLTP: 'CREDIT AGRICOLE',
  AGRIFRPPLTR: 'CREDIT AGRICOLE',
  AGRIFRPPLVV: 'CREDIT AGRICOLE',
  AGRIFRPPLVE: 'CREDIT AGRICOLE',
  AGRIFRPPASR: 'CREDIT AGRICOLE',
  AGRIFRPPLAG: 'CREDIT AGRICOLE',
  AGRIFRPPLAM: 'CREDIT AGRICOLE',
  AGRIFRPPLAB: 'CREDIT AGRICOLE',
  AGRIFRPPLBC: 'CREDIT AGRICOLE',
  AGRIFRPPLGN: 'CREDIT AGRICOLE',
  AGRIFRPPLAN: 'CREDIT AGRICOLE',
  AGRIFRPPLNN: 'CREDIT AGRICOLE',
  AGRIFRPPLAO: 'CREDIT AGRICOLE',
  AGRIFRPPLMO: 'CREDIT AGRICOLE',
  AGRIFRPP835: 'CREDIT AGRICOLE',
  AGRIFRPPLAV: 'CREDIT AGRICOLE',
  AGRIFRPPLVT: 'CREDIT AGRICOLE',
  AGRIFRPPBLM: 'CREDIT AGRICOLE',
  AGRIFRPPBGT: 'CREDIT AGRICOLE',
  AGRIFRPPCEY: 'CREDIT AGRICOLE',
  AGRIFRPPLEU: 'CREDIT AGRICOLE',
  AGRIFRPPHAV: 'CREDIT AGRICOLE',
  AGRIFRPPLLA: 'CREDIT AGRICOLE',
  AGRIFRPP879: 'CREDIT AGRICOLE',
  AGRIFRPPLPZ: 'CREDIT AGRICOLE',
  AGRIFRPPLEY: 'CREDIT AGRICOLE',
  AGRIFRPPPUY: 'CREDIT AGRICOLE',
  AGRIFRPPPSR: 'CREDIT AGRICOLE',
  AGRIFRPPRAI: 'CREDIT AGRICOLE',
  AGRIFRPPTHO: 'CREDIT AGRICOLE',
  AGRIFRPPLEN: 'CREDIT AGRICOLE',
  AGRIFRPPLEG: 'CREDIT AGRICOLE',
  AGRIFRPPLEH: 'CREDIT AGRICOLE',
  AGRIFRPPMES: 'CREDIT AGRICOLE',
  AGRIFRPPLMX: 'CREDIT AGRICOLE',
  AGRIFRPPLSA: 'CREDIT AGRICOLE',
  AGRIFRPPLES: 'CREDIT AGRICOLE',
  AGRIFRPPLEP: 'CREDIT AGRICOLE',
  AGRIFRPPLIB: 'CREDIT AGRICOLE',
  AGRIFRPP867: 'CREDIT AGRICOLE',
  AGRIFRPP895: 'CREDIT AGRICOLE',
  AGRIFRPPLIS: 'CREDIT AGRICOLE',
  AGRIFRPPLMV: 'CREDIT AGRICOLE',
  AGRIFRPPLOM: 'CREDIT AGRICOLE',
  AGRIFRPPLCJ: 'CREDIT AGRICOLE',
  AGRIFRPPLOW: 'CREDIT AGRICOLE',
  AGRIFRPPLON: 'CREDIT AGRICOLE',
  AGRIFRPPLOR: 'CREDIT AGRICOLE',
  AGRIFRPPLOD: 'CREDIT AGRICOLE',
  AGRIFRPPLOA: 'CREDIT AGRICOLE',
  AGRIFRPPLOU: 'CREDIT AGRICOLE',
  AGRIFRPPLUC: 'CREDIT AGRICOLE',
  AGRIFRPPLUN: 'CREDIT AGRICOLE',
  AGRIFRPP878: 'CREDIT AGRICOLE',
  AGRIFRPPMAC: 'CREDIT AGRICOLE',
  AGRIFRPPMAI: 'CREDIT AGRICOLE',
  AGRIFRPPMAL: 'CREDIT AGRICOLE',
  AGRIFRPPMOF: 'CREDIT AGRICOLE',
  AGRIFRPPMAT: 'CREDIT AGRICOLE',
  AGRIFRPPMAN: 'CREDIT AGRICOLE',
  AGRIFRPPMLJ: 'CREDIT AGRICOLE',
  AGRIFRPPMAG: 'CREDIT AGRICOLE',
  AGRIFRPPMGN: 'CREDIT AGRICOLE',
  AGRIFRPPMAD: 'CREDIT AGRICOLE',
  AGRIFRPPMVA: 'CREDIT AGRICOLE',
  AGRIFRPPMAR: 'CREDIT AGRICOLE',
  AGRIFRPPMTS: 'CREDIT AGRICOLE',
  AGRIFRPPMAS: 'CREDIT AGRICOLE',
  AGRIFRPPMAU: 'CREDIT AGRICOLE',
  AGRIFRPPMAB: 'CREDIT AGRICOLE',
  AGRIFRPPMAY: 'CREDIT AGRICOLE',
  AGRIFRPPMAZ: 'CREDIT AGRICOLE',
  AGRIFRPPMEA: 'CREDIT AGRICOLE',
  AGRIFRPPMEG: 'CREDIT AGRICOLE',
  AGRIFRPPMEL: 'CREDIT AGRICOLE',
  AGRIFRPPMED: 'CREDIT AGRICOLE',
  AGRIFRPPMEN: 'CREDIT AGRICOLE',
  AGRIFRPP907: 'CREDIT AGRICOLE',
  AGRIFRPPMEI: 'CREDIT AGRICOLE',
  AGRIFRPPMER: 'CREDIT AGRICOLE',
  AGRIFRPPMUE: 'CREDIT AGRICOLE',
  AGRIFRPP861: 'CREDIT AGRICOLE',
  AGRIFRPPMEU: 'CREDIT AGRICOLE',
  AGRIFRPPMEY: 'CREDIT AGRICOLE',
  AGRIFRPPMZU: 'CREDIT AGRICOLE',
  AGRIFRPPMEZ: 'CREDIT AGRICOLE',
  AGRIFRPPMIL: 'CREDIT AGRICOLE',
  AGRIFRPPMIS: 'CREDIT AGRICOLE',
  AGRIFRPPMIR: 'CREDIT AGRICOLE',
  AGRIFRPPMYM: 'CREDIT AGRICOLE',
  AGRIFRPPMOD: 'CREDIT AGRICOLE',
  AGRIFRPPMOL: 'CREDIT AGRICOLE',
  AGRIFRPPMOI: 'CREDIT AGRICOLE',
  AGRIFRPPMDM: 'CREDIT AGRICOLE',
  AGRIFRPPMOA: 'CREDIT AGRICOLE',
  AGRIFRPPMTG: 'CREDIT AGRICOLE',
  AGRIFRPPTTB: 'CREDIT AGRICOLE',
  AGRIFRPPMOT: 'CREDIT AGRICOLE',
  AGRIFRPPMOR: 'CREDIT AGRICOLE',
  AGRIFRPPMTE: 'CREDIT AGRICOLE',
  AGRIFRPPMTX: 'CREDIT AGRICOLE',
  AGRIFRPPMTF: 'CREDIT AGRICOLE',
  AGRIFRPPMLU: 'CREDIT AGRICOLE',
  AGRIFRPPMOU: 'CREDIT AGRICOLE',
  AGRIFRPPMPL: 'CREDIT AGRICOLE',
  AGRIFRPPMON: 'CREDIT AGRICOLE',
  AGRIFRPPMTR: 'CREDIT AGRICOLE',
  AGRIFRPPMOM: 'CREDIT AGRICOLE',
  AGRIFRPPMRG: 'CREDIT AGRICOLE',
  AGRIFRPPMOX: 'CREDIT AGRICOLE',
  AGRIFRPPMOE: 'CREDIT AGRICOLE',
  AGRIFRPPMLS: 'CREDIT AGRICOLE',
  AGRIFRPPMUL: 'CREDIT AGRICOLE',
  AGRIFRPPMET: 'CREDIT AGRICOLE',
  AGRIFRPPNAN: 'CREDIT AGRICOLE',
  AGRIFRPPNAI: 'CREDIT AGRICOLE',
  AGRIFRPPNTE: 'CREDIT AGRICOLE',
  AGRIFRPP847: 'CREDIT AGRICOLE',
  AGRIFRPPNAR: 'CREDIT AGRICOLE',
  AGRIFRPPNEM: 'CREDIT AGRICOLE',
  AGRIFRPPNEB: 'CREDIT AGRICOLE',
  AGRIFRPPNEU: 'CREDIT AGRICOLE',
  AGRIFRPPNEV: 'CREDIT AGRICOLE',
  AGRIFRPPNIC: 'CREDIT AGRICOLE',
  AGRIFRPPNIM: 'CREDIT AGRICOLE',
  AGRIFRPPNOG: 'CREDIT AGRICOLE',
  AGRIFRPPNOI: 'CREDIT AGRICOLE',
  AGRIFRPPNLG: 'CREDIT AGRICOLE',
  AGRIFRPPNOY: 'CREDIT AGRICOLE',
  AGRIFRPPNUI: 'CREDIT AGRICOLE',
  AGRIFRPPOBN: 'CREDIT AGRICOLE',
  AGRIFRPPORA: 'CREDIT AGRICOLE',
  AGRIFRPPOUL: 'CREDIT AGRICOLE',
  AGRIFRPPOYX: 'CREDIT AGRICOLE',
  AGRIFRPPPAI: 'CREDIT AGRICOLE',
  AGRIFRPPPAN: 'CREDIT AGRICOLE',
  AGRIFRPPPLM: 'CREDIT AGRICOLE',
  AGRIFRPPCLS: 'CREDIT AGRICOLE',
  AGRIFRPP882: 'CREDIT AGRICOLE',
  AGRIFRPPPAU: 'CREDIT AGRICOLE',
  AGRIFRPPRIN: 'CREDIT AGRICOLE',
  AGRIFRPPPER: 'CREDIT AGRICOLE',
  AGRIFRPP871: 'CREDIT AGRICOLE',
  AGRIFRPPPTS: 'CREDIT AGRICOLE',
  AGRIFRPPPEZ: 'CREDIT AGRICOLE',
  AGRIFRPPPIT: 'CREDIT AGRICOLE',
  AGRIFRPPPDU: 'CREDIT AGRICOLE',
  AGRIFRPPPLO: 'CREDIT AGRICOLE',
  AGRIFRPPPFA: 'CREDIT AGRICOLE',
  AGRIFRPPPOI: 'CREDIT AGRICOLE',
  AGRIFRPP894: 'CREDIT AGRICOLE',
  AGRIFRPPPAM: 'CREDIT AGRICOLE',
  AGRIFRPPPBV: 'CREDIT AGRICOLE',
  AGRIFRPPPCU: 'CREDIT AGRICOLE',
  AGRIFRPPPCY: 'CREDIT AGRICOLE',
  AGRIFRPPPDC: 'CREDIT AGRICOLE',
  AGRIFRPPPON: 'CREDIT AGRICOLE',
  AGRIFRPPPSO: 'CREDIT AGRICOLE',
  AGRIFRPPPOU: 'CREDIT AGRICOLE',
  AGRIFRPPPRA: 'CREDIT AGRICOLE',
  AGRIFRPPPRI: 'CREDIT AGRICOLE',
  AGRIFRPPPRO: 'CREDIT AGRICOLE',
  AGRIFRPPPUG: 'CREDIT AGRICOLE',
  AGRIFRPPPUJ: 'CREDIT AGRICOLE',
  AGRIFRPPPTX: 'CREDIT AGRICOLE',
  AGRIFRPP829: 'CREDIT AGRICOLE',
  AGRIFRPPRED: 'CREDIT AGRICOLE',
  AGRIFRPP802: 'CREDIT AGRICOLE',
  AGRIFRPP836: 'CREDIT AGRICOLE',
  AGRIFRPPREL: 'CREDIT AGRICOLE',
  AGRIFRPPRIO: 'CREDIT AGRICOLE',
  AGRIFRPPRIV: 'CREDIT AGRICOLE',
  AGRIFRPPROA: 'CREDIT AGRICOLE',
  AGRIFRPPROD: 'CREDIT AGRICOLE',
  AGRIFRPPROE: 'CREDIT AGRICOLE',
  AGRIFRPPROI: 'CREDIT AGRICOLE',
  AGRIFRPPRSI: 'CREDIT AGRICOLE',
  AGRIFRPPRSB: 'CREDIT AGRICOLE',
  AGRIFRPPROU: 'CREDIT AGRICOLE',
  AGRIFRPP883: 'CREDIT AGRICOLE',
  AGRIFRPPRUM: 'CREDIT AGRICOLE',
  AGRIFRPPRUN: 'CREDIT AGRICOLE',
  AGRIFRPPSAB: 'CREDIT AGRICOLE',
  AGRIFRPPSAF: 'CREDIT AGRICOLE',
  AGRIFRPPSAZ: 'CREDIT AGRICOLE',
  AGRIFRPPSTA: 'CREDIT AGRICOLE',
  AGRIFRPPSBC: 'CREDIT AGRICOLE',
  AGRIFRPP822: 'CREDIT AGRICOLE',
  AGRIFRPPSAC: 'CREDIT AGRICOLE',
  AGRIFRPPSCB: 'CREDIT AGRICOLE',
  AGRIFRPPSDN: 'CREDIT AGRICOLE',
  AGRIFRPPSTD: 'CREDIT AGRICOLE',
  AGRIFRPPSDZ: 'CREDIT AGRICOLE',
  AGRIFRPPSEG: 'CREDIT AGRICOLE',
  AGRIFRPPSEL: 'CREDIT AGRICOLE',
  AGRIFRPP845: 'CREDIT AGRICOLE',
  AGRIFRPPSGS: 'CREDIT AGRICOLE',
  AGRIFRPPSGL: 'CREDIT AGRICOLE',
  AGRIFRPPSGV: 'CREDIT AGRICOLE',
  AGRIFRPPCGR: 'CREDIT AGRICOLE',
  AGRIFRPPSHH: 'CREDIT AGRICOLE',
  AGRIFRPPLUZ: 'CREDIT AGRICOLE',
  AGRIFRPPSJM: 'CREDIT AGRICOLE',
  AGRIFRPPSJR: 'CREDIT AGRICOLE',
  AGRIFRPPSJP: 'CREDIT AGRICOLE',
  AGRIFRPPSLV: 'CREDIT AGRICOLE',
  AGRIFRPPSLF: 'CREDIT AGRICOLE',
  AGRIFRPPSAL: 'CREDIT AGRICOLE',
  AGRIFRPPSMR: 'CREDIT AGRICOLE',
  AGRIFRPPSMT: 'CREDIT AGRICOLE',
  AGRIFRPPSMN: 'CREDIT AGRICOLE',
  AGRIFRPPSMH: 'CREDIT AGRICOLE',
  AGRIFRPPSMC: 'CREDIT AGRICOLE',
  AGRIFRPPPSM: 'CREDIT AGRICOLE',
  AGRIFRPPSMI: 'CREDIT AGRICOLE',
  AGRIFRPPNAZ: 'CREDIT AGRICOLE',
  AGRIFRPPSTO: 'CREDIT AGRICOLE',
  AGRIFRPPSAO: 'CREDIT AGRICOLE',
  AGRIFRPPSAY: 'CREDIT AGRICOLE',
  AGRIFRPPPOL: 'CREDIT AGRICOLE',
  AGRIFRPPSPT: 'CREDIT AGRICOLE',
  AGRIFRPPSTQ: 'CREDIT AGRICOLE',
  AGRIFRPPSQY: 'CREDIT AGRICOLE',
  AGRIFRPPSQF: 'CREDIT AGRICOLE',
  AGRIFRPPSRA: 'CREDIT AGRICOLE',
  AGRIFRPPSTR: 'CREDIT AGRICOLE',
  AGRIFRPPSRP: 'CREDIT AGRICOLE',
  AGRIFRPPSTP: 'CREDIT AGRICOLE',
  AGRIFRPPSVV: 'CREDIT AGRICOLE',
  AGRIFRPPSFG: 'CREDIT AGRICOLE',
  AGRIFRPPSTM: 'CREDIT AGRICOLE',
  AGRIFRPPSDP: 'CREDIT AGRICOLE',
  AGRIFRPPSNY: 'CREDIT AGRICOLE',
  AGRIFRPPSAE: 'CREDIT AGRICOLE',
  AGRIFRPPSAN: 'CREDIT AGRICOLE',
  AGRIFRPPSAA: 'CREDIT AGRICOLE',
  AGRIFRPPSUN: 'CREDIT AGRICOLE',
  AGRIFRPPSRB: 'CREDIT AGRICOLE',
  AGRIFRPPSAR: 'CREDIT AGRICOLE',
  AGRIFRPPSAT: 'CREDIT AGRICOLE',
  AGRIFRPPSAU: 'CREDIT AGRICOLE',
  AGRIFRPPSGE: 'CREDIT AGRICOLE',
  AGRIFRPPSAV: 'CREDIT AGRICOLE',
  AGRIFRPPSCE: 'CREDIT AGRICOLE',
  AGRIFRPPSEC: 'CREDIT AGRICOLE',
  AGRIFRPPSED: 'CREDIT AGRICOLE',
  AGRIFRPPSLT: 'CREDIT AGRICOLE',
  AGRIFRPPSLS: 'CREDIT AGRICOLE',
  AGRIFRPPSEN: 'CREDIT AGRICOLE',
  AGRIFRPPSET: 'CREDIT AGRICOLE',
  AGRIFRPPSEV: 'CREDIT AGRICOLE',
  AGRIFRPPSEP: 'CREDIT AGRICOLE',
  AGRIFRPPSIX: 'CREDIT AGRICOLE',
  AGRIFRPPSOS: 'CREDIT AGRICOLE',
  AGRIFRPPSOR: 'CREDIT AGRICOLE',
  AGRIFRPPSOU: 'CREDIT AGRICOLE',
  AGRIFRPP824: 'CREDIT AGRICOLE',
  AGRIFRPP872: 'CREDIT AGRICOLE',
  AGRIFRPPSUR: 'CREDIT AGRICOLE',
  AGRIFRPPTAH: 'CREDIT AGRICOLE',
  AGRIFRPPTAE: 'CREDIT AGRICOLE',
  AGRIFRPP869: 'CREDIT AGRICOLE',
  AGRIFRPPTAR: 'CREDIT AGRICOLE',
  AGRIFRPPTAS: 'CREDIT AGRICOLE',
  AGRIFRPPTHN: 'CREDIT AGRICOLE',
  AGRIFRPPTHI: 'CREDIT AGRICOLE',
  AGRIFRPPTHV: 'CREDIT AGRICOLE',
  AGRIFRPPTLB: 'CREDIT AGRICOLE',
  AGRIFRPPTIG: 'CREDIT AGRICOLE',
  AGRIFRPPMLV: 'CREDIT AGRICOLE',
  AGRIFRPPTOL: 'CREDIT AGRICOLE',
  AGRIFRPPTLN: 'CREDIT AGRICOLE',
  AGRIFRPP831: 'CREDIT AGRICOLE',
  AGRIFRPPTRG: 'CREDIT AGRICOLE',
  AGRIFRPPTNN: 'CREDIT AGRICOLE',
  AGRIFRPPTNS: 'CREDIT AGRICOLE',
  AGRIFRPPTOU: 'CREDIT AGRICOLE',
  AGRIFRPPTRT: 'CREDIT AGRICOLE',
  AGRIFRPPTRM: 'CREDIT AGRICOLE',
  AGRIFRPP810: 'CREDIT AGRICOLE',
  AGRIFRPPTUL: 'CREDIT AGRICOLE',
  AGRIFRPPUSS: 'CREDIT AGRICOLE',
  AGRIFRPPUZE: 'CREDIT AGRICOLE',
  AGRIFRPPVLR: 'CREDIT AGRICOLE',
  AGRIFRPPVAI: 'CREDIT AGRICOLE',
  AGRIFRPPVTH: 'CREDIT AGRICOLE',
  AGRIFRPPVAL: 'CREDIT AGRICOLE',
  AGRIFRPPVCS: 'CREDIT AGRICOLE',
  AGRIFRPPVAA: 'CREDIT AGRICOLE',
  AGRIFRPPVGE: 'CREDIT AGRICOLE',
  AGRIFRPPVAS: 'CREDIT AGRICOLE',
  AGRIFRPP860: 'CREDIT AGRICOLE',
  AGRIFRPPVAU: 'CREDIT AGRICOLE',
  AGRIFRPPVLZ: 'CREDIT AGRICOLE',
  AGRIFRPPVRN: 'CREDIT AGRICOLE',
  AGRIFRPPVER: 'CREDIT AGRICOLE',
  AGRIFRPPVRS: 'CREDIT AGRICOLE',
  AGRIFRPPVES: 'CREDIT AGRICOLE',
  AGRIFRPPVEY: 'CREDIT AGRICOLE',
  AGRIFRPPVIC: 'CREDIT AGRICOLE',
  AGRIFRPPVIE: 'CREDIT AGRICOLE',
  AGRIFRPPVIZ: 'CREDIT AGRICOLE',
  AGRIFRPPVIL: 'CREDIT AGRICOLE',
  AGRIFRPPVIF: 'CREDIT AGRICOLE',
  AGRIFRPPVIR: 'CREDIT AGRICOLE',
  AGRIFRPPVSS: 'CREDIT AGRICOLE',
  AGRIFRPPVVO: 'CREDIT AGRICOLE',
  AGRIFRPPVDA: 'CREDIT AGRICOLE',
  AGRIFRPPVIA: 'CREDIT AGRICOLE',
  AGRIFRPPVSL: 'CREDIT AGRICOLE',
  AGRIFRPPVIP: 'CREDIT AGRICOLE',
  AGRIFRPPVLN: 'CREDIT AGRICOLE',
  AGRIFRPPVIU: 'CREDIT AGRICOLE',
  AGRIFRPPVBE: 'CREDIT AGRICOLE',
  AGRIFRPPVIN: 'CREDIT AGRICOLE',
  AGRIFRPPVIX: 'CREDIT AGRICOLE',
  AGRIFRPPVIT: 'CREDIT AGRICOLE',
  AGRIFRPPVTL: 'CREDIT AGRICOLE',
  AGRIFRPPVLF: 'CREDIT AGRICOLE',
  AGRIFRPPVOI: 'CREDIT AGRICOLE',
  AGRIFRPPVOR: 'CREDIT AGRICOLE',
  AGRIFRPPWSB: 'CREDIT AGRICOLE',
  AGRIFRPPYVE: 'CREDIT AGRICOLE',
  BSUIFRPPLIL: 'CREDIT AGRICOLE CIB',
  BSUIFRPPLYO: 'CREDIT AGRICOLE CIB',
  BSUIFRPPMAR: 'CREDIT AGRICOLE CIB',
  BSUIFRPP: 'CREDIT AGRICOLE CIB',
  BSUIFRPPNAN: 'CREDIT AGRICOLE CIB',
  BSUIFRPPCAP: 'CREDIT AGRICOLE CIB',
  BSUIFRPPDMA: 'CREDIT AGRICOLE CIB',
  BSUIFRPPPRI: 'CREDIT AGRICOLE CIB',
  AGRIFRPPACY: 'CREDIT AGRICOLE SA',
  AGRIFRPPAUQ: 'CREDIT AGRICOLE SA',
  AGRIFRPPBEG: 'CREDIT AGRICOLE SA',
  AGRIFRPPBGU: 'CREDIT AGRICOLE SA',
  AGRIFRPPBRY: 'CREDIT AGRICOLE SA',
  AGRIFRPP184: 'CREDIT AGRICOLE SA',
  AGRIFRPP23A: 'CREDIT AGRICOLE SA',
  AGRIFRPP27A: 'CREDIT AGRICOLE SA',
  AGRIFRPP: 'CREDIT AGRICOLE SA',
  AGRIFRPPNIO: 'CREDIT AGRICOLE SA',
  AGRIFRPPORL: 'CREDIT AGRICOLE SA',
  AGRIFRPPPAO: 'CREDIT AGRICOLE SA',
  CCBPFRPP555: 'CREDIT COMMERCIAL DU SUD-OUEST',
  CCOPFRPPFXD: 'CREDIT COOPERATIF',
  CCOPFRCP: 'CREDIT COOPERATIF',
  CCOPFRPP: 'CREDIT COOPERATIF',
  NORDFRPPASV: 'CREDIT DU NORD',
  NORDFRPPGSM: 'CREDIT DU NORD',
  NORDFRPP: 'CREDIT DU NORD',
  CFFRFRPPSSS: 'CREDIT FONCIER DE FRANCE',
  CFFRFRPPTG2: 'CREDIT FONCIER DE FRANCE',
  CFFRFRPP: 'CREDIT FONCIER DE FRANCE',
  CMCIFR2ACMO: 'CREDIT MUTUEL',
  CMCIFR2ALAV: 'CREDIT MUTUEL',
  CMCIFR2ABCM: 'CREDIT MUTUEL',
  CMCIFR2ACMN: 'CREDIT MUTUEL',
  CMCIFR2ACEE: 'CREDIT MUTUEL',
  CMCIFR2A: 'CREDIT MUTUEL',
  CMCIFRPAGAR: 'CREDIT MUTUEL - CIC BANQUES',
  CMCIFRPA: 'CREDIT MUTUEL - CIC BANQUES',
  CMCIFRPARFT: 'CREDIT MUTUEL - CIC BANQUES',
  CMCIFRPACCM: 'CREDIT MUTUEL - CIC BANQUES',
  CMCIFRPADGE: 'CREDIT MUTUEL - CIC BANQUES',
  CMCIFRPAPOR: 'CREDIT MUTUEL - CIC BANQUES',
  CMCIFRPAGMT: 'CREDIT MUTUEL - CIC BANQUES',
  CMCIFRPADOC: 'CREDIT MUTUEL - CIC BANQUES',
  CMCIFRPAPEN: 'CREDIT MUTUEL - CIC BANQUES',
  CMCIFRPACHQ: 'CREDIT MUTUEL - CIC BANQUES',
  CMBRFR2BPBS: 'CREDIT MUTUEL ARKEA',
  CMBRFR2BCME: 'CREDIT MUTUEL ARKEA',
  CMBRFR2BEFI: 'CREDIT MUTUEL ARKEA',
  CMBRFR2BCAL: 'CREDIT MUTUEL ARKEA',
  CMBRFR2BARK: 'CREDIT MUTUEL ARKEA',
  CMBRFR2BTIT: 'CREDIT MUTUEL ARKEA',
  CMBRFR2BFIR: 'CREDIT MUTUEL ARKEA',
  CMBRFR2B: 'CREDIT MUTUEL ARKEA',
  CMBRFR2BSFH: 'CREDIT MUTUEL ARKEA',
  CMBRFR2BSCF: 'CREDIT MUTUEL ARKEA',
  CMBRFR2BDOC: 'CREDIT MUTUEL ARKEA',
  CMBRFR2BAIS: 'CREDIT MUTUEL ARKEA',
  CRESFRPP: 'CREDIT SUISSE (LUXEMBOURG) S.A.',
  CTIEFRPP: 'CRITEO SA',
  CRNHFR22: 'CRONITE HOLDING S.A.S.',
  CRZAFR2V: 'CROUZET AUTOMATISMES SAS',
  CYVBFR22: 'CYRILLUS VERTBAUDET GROUP',
  DALPFRPP: 'DALENYS PAYMENT S.A.S.',
  DAMXFR22: 'DAMARTEX S.A.',
  DAFIFRPP: 'DANONE S.A.',
  DAAVFRPA: 'DASSAULT AVIATION SA',
  DSSAFR22: 'DASSAULT SYSTEMES',
  DBAGFRPP: 'DBAPPAREL S.A.S',
  DBVTFR2M: 'DBV TECHNOLOGIES S.A.',
  OXYLFR22: 'DECATHLON SA',
  DEPIFRPP: 'DELPHARM INDUSTRIE S.A.S.',
  DERHFRPP: 'DERICHEBOURG SA',
  DETDFRPP: 'DESCARTES TRADING',
  DEUTFRPPSSS: 'DEUTSCHE BANK AG',
  DEUTFRPPSEC: 'DEUTSCHE BANK AG',
  DEUTFRPP: 'DEUTSCHE BANK AG',
  CLFRFRCC: 'DEXIA CREDIT LOCAL',
  CLFRFRPP: 'DEXIA CREDIT LOCAL',
  PIMKFR22: 'DIRAMODE',
  ECOCFRPP: 'EBI SA',
  ECOCFRPPBDF: 'EBI SA',
  EDERFR22: 'EDENRED SA',
  EDFNFRPP: 'EDF RENOUVELABLES',
  COFIFRPPRAM: 'EDMOND DE ROTHSCHILD (FRANCE)',
  COFIFRPPRIM: 'EDMOND DE ROTHSCHILD (FRANCE)',
  COFIFRCP: 'EDMOND DE ROTHSCHILD (FRANCE)',
  COFIFRPP: 'EDMOND DE ROTHSCHILD (FRANCE)',
  EGISFR22: 'EGIS S.A.',
  EIFGFRPP: 'EIFFAGE S.A.',
  EIGSFRP2: 'EIFFEL INVESTMENT GROUP SAS',
  EDFGFRPP001: 'ELECTRICITE DE FRANCE',
  EDFGFRPPBBF: 'ELECTRICITE DE FRANCE',
  EDFGFRPPBPC: 'ELECTRICITE DE FRANCE',
  EDFGFRPPSCA: 'ELECTRICITE DE FRANCE',
  EDFGFRPP: 'ELECTRICITE DE FRANCE',
  EDFGFRPPADX: 'ELECTRICITE DE FRANCE',
  EDFGFRP2: 'ELECTRICITE DE FRANCE',
  ELBAFRPP: 'ELECTRO FINANCE',
  ELTRFRPP: 'ELIOR TRESORERIE',
  ELISFR22: 'ELIS PANTIN',
  ELRSFRPP: 'ELLIPSE PROJECTS SAS',
  ENEDFRPP: 'ENEDIS S.A.',
  GSZGFRPP: 'ENGIE',
  ENGIFRPP: 'ENGIE',
  ENCTFR22: 'ENTREPOSE CONTRACTING',
  ERAMFRPA: 'ERAMET',
  ERAOFRPP: 'ERANOVE S.A.',
  ERMHFRPP: 'ERMEWA HOLDING S.A.S.',
  ESITFR2P: 'ESSILOR INTERNATIONAL',
  LAGAFR22: 'ETABLISSEMENTS FOURE LAGADEC ET CIE',
  ETMPFRP2: 'ETABLISSEMENTS MAUREL ET PROM S.A.',
  SOUFFR22: 'ETS J SOUFFLET',
  EHSCFR2P: 'EULER HERMES SFAC CREDIT',
  SICVFRPP: 'EUROCLEAR FRANCE',
  SICVFRPPAF1: 'EUROCLEAR FRANCE',
  SICVFRPPAF2: 'EUROCLEAR FRANCE',
  SICVFRPPEF1: 'EUROCLEAR FRANCE',
  SICVFRPP015: 'EUROCLEAR FRANCE',
  SICVFRPPREG: 'EUROCLEAR FRANCE',
  SICVFRPPOAM: 'EUROCLEAR FRANCE',
  SICVFRPPEII: 'EUROCLEAR FRANCE',
  SICVFRPPAF4: 'EUROCLEAR FRANCE',
  SICVFRPPAF5: 'EUROCLEAR FRANCE',
  ESESFRPPLE1: 'EUROCLEAR FRANCE SA',
  ESESFRPPLE2: 'EUROCLEAR FRANCE SA',
  ESESFRPPT2X: 'EUROCLEAR FRANCE SA',
  ESESFRPP: 'EUROCLEAR FRANCE SA',
  ESESFRPPFTT: 'EUROCLEAR FRANCE SA',
  ESESFRPPVOT: 'EUROCLEAR FRANCE SA',
  ESESFRPPFDB: 'EUROCLEAR FRANCE SA',
  ESESFRPPBRN: 'EUROCLEAR FRANCE SA',
  ESESFRPPBIT: 'EUROCLEAR FRANCE SA',
  ESESFRPPIBR: 'EUROCLEAR FRANCE SA',
  ESESFRPPCBF: 'EUROCLEAR FRANCE SA',
  ESESFRPPMTI: 'EUROCLEAR FRANCE SA',
  ESESFRPPNBB: 'EUROCLEAR FRANCE SA',
  ESESFRPPNOH: 'EUROCLEAR FRANCE SA',
  ESESFRPPREG: 'EUROCLEAR FRANCE SA',
  ESESFRPPNTX: 'EUROCLEAR FRANCE SA',
  ESESFRPPBIL: 'EUROCLEAR FRANCE SA',
  ESESFRPPPEN: 'EUROCLEAR FRANCE SA',
  ESESFRPPINT: 'EUROCLEAR FRANCE SA',
  ESESFRPPPFE: 'EUROCLEAR FRANCE SA',
  ESESFRPPMIG: 'EUROCLEAR FRANCE SA',
  ESESFRPPCSD: 'EUROCLEAR FRANCE SA',
  ESESFRPPEII: 'EUROCLEAR FRANCE SA',
  EUPPFRP2: 'EUROCORPORATE AM',
  XPARFRPP022: 'EURONEXT PARIS S.A.',
  XPARFRPPLIS: 'EURONEXT PARIS S.A.',
  XPARFRPP: 'EURONEXT PARIS S.A.',
  XPARFRPPINT: 'EURONEXT PARIS S.A.',
  XPARFRPPTRS: 'EURONEXT PARIS S.A.',
  EUAHFRPP: 'EUROP ASSISTANCE HOLDING S.A.S.',
  ECARFR22: 'EUROPCAR HOLDING',
  ARABFRPP: 'EUROPE ARAB BANK SA',
  EFALFRPP: 'EUROPEAN FUND ADMINISTRATION S.A. - FRANCE',
  EFALFRPPACC: 'EUROPEAN FUND ADMINISTRATION S.A. - FRANCE',
  EFALFRPPEQY: 'EUROPEAN FUND ADMINISTRATION S.A. - FRANCE',
  ESMAFRP2: 'EUROPEAN SECURITIES AND MARKETS AUTHORITY',
  ESAZFRPP: 'EUROPEAN SPACE AGENCY',
  ETLSFRPP: 'EUTELSAT COMMUNICATIONS S.A',
  EXADFRPP: 'EXANE DERIVATIVES SNC',
  EXANFRPPSAM: 'EXANE S.A.',
  EXANFRPPSTO: 'EXANE S.A.',
  EXANFRPP: 'EXANE S.A.',
  EXWOFRPP: 'EXCLUSIVE NETWORKS SAS',
  EXPGFRPP: 'EXPERIMENTAL GROUP SAS',
  FACFFRPP: 'FACTOFRANCE',
  COFEFR2P: 'FACTOFRANCE (FORMERLY COFACREDIT)',
  LEYTFR22: 'FAIVELEY TRANSPORT SA',
  FAURFRPP: 'FAURECIA',
  FBDIFR22: 'FBD INTERNATIONAL S.A.S.',
  FDCAFRPP: 'FIDUCIAL',
  FICVFRP2: 'FINANCIERE ARBEVEL',
  FGBBFR2M: 'FINANCIERE BOURBON',
  SNOPFR22: 'FINANCIERE SNOP DUNOIS',
  RGFIFRPP: 'FINVAL',
  NBADFRPPBDF: 'FIRST ABU DHABI BANK PJSC (FORMERLY NATIONAL BANK OF ABU DHABI) PARIS',
  NBADFRPPTFO: 'FIRST ABU DHABI BANK PJSC (FORMERLY NATIONAL BANK OF ABU DHABI) PARIS',
  NBADFRPP: 'FIRST ABU DHABI BANK PJSC (FORMERLY NATIONAL BANK OF ABU DHABI) PARIS',
  FLNGFR22: 'FLEX-N-GATE FRANCE S.A.S.',
  FDVFFR22: 'FLORIMOND DESPREZ VEUVE ET FILS',
  FLWBFRPP: 'FLOWBIRD S.A.S.',
  FCOFFRPP: 'FRANCOIS-CHARLES OBERTHUR SAS',
  FRAFFRPP: 'FRANSABANK FRANCE S.A.',
  FTOPFRPP: 'FUNECAP TOPCO',
  GECFFRPP: 'GE SCF',
  GFCOFRPP: 'GEFCO',
  GMTOFR22: 'GEMALTO TREASURY SERVICES',
  GENNFR22: 'GENEBANQUE SA',
  GPEEFRPP: "GENERALE POUR L'ENFANT S.A.S.",
  GEFCFRPA: 'GENERALI FRANCE',
  GEFOFR22: 'GERFLOR S.A.S.',
  AXAFFRPP: 'GIE AXA FRANCE',
  AVIVFRPP: 'GIE GROUPE AVIVA FRANCE',
  GLEVFR22: 'GL EVENTS',
  GOLDFRPP: 'GOLDMAN SACHS PARIS INC. ET CIE',
  GVSAFRPP: 'GRANDVISION FRANCE SAS',
  GPAMFRPP: 'GROUPAMA ASSET MANAGEMENT',
  GPAMFRPPIZN: 'GROUPAMA ASSET MANAGEMENT',
  GPSIFRPP: "GROUPAMA SYSTEMES D'INFORMATION",
  GRDDFR22: 'GROUPE ADEO',
  GPANFRPP: 'GROUPE ARNAULT',
  GPANFRPPCDI: 'GROUPE ARNAULT',
  GPANFRPPEWB: 'GROUPE ARNAULT',
  GPANFRPPFAG: 'GROUPE ARNAULT',
  GPANFRPPFGO: 'GROUPE ARNAULT',
  GPANFRPPSEM: 'GROUPE ARNAULT',
  AUCHFR22: 'GROUPE AUCHAN',
  CNLPFR22: 'GROUPE CANAL+',
  GGUIFR22: 'GROUPE GUILLIN SA',
  SIPFFR22: 'GROUPE PETIT FORESTIER',
  ROYEFR22: 'GROUPE ROYER',
  SEGUFR2N: 'GROUPE SEGULA TECHNOLOGIES',
  GCBAFRPP: 'GROUPEMENT DES CARTES BANCAIRES GIE',
  GRBTFRPP: 'GUERBET',
  ECORFR22: 'GUY DAUPHIN ENVIRONNEMENT',
  HANGFRPP: 'HANA GROUP S.A.S',
  HENNFRPP: 'HENNER SAS',
  HRMSFRPP: 'HERMES INTERNATIONAL',
  HIQCFRPP: 'HIQ CONSULTING SAS',
  HMGFFRPP: 'HMG FINANCE SA',
  HOLCFRPP: 'HOLDCO-CROSS S.A.S.',
  HOLDFR22: 'HOLDHAM',
  HDUFFR2R: "HOLDING LE DUFF 'HLD'",
  HORFFRPP: 'HORUS FINANCE S.A.',
  MIDLFRPX111: 'HSBC BANK PLC',
  MIDLFRPX222: 'HSBC BANK PLC',
  MIDLFRPX: 'HSBC BANK PLC',
  CCFRFRPP330: 'HSBC FRANCE (FORMERLY HSBC CCF)',
  CCFRFRPP120: 'HSBC FRANCE (FORMERLY HSBC CCF)',
  CCFRFRPP222: 'HSBC FRANCE (FORMERLY HSBC CCF)',
  CCFRFRPP320: 'HSBC FRANCE (FORMERLY HSBC CCF)',
  CCFRFRPP224: 'HSBC FRANCE (FORMERLY HSBC CCF)',
  CCFRFRPP280: 'HSBC FRANCE (FORMERLY HSBC CCF)',
  CCFRFRPP140: 'HSBC FRANCE (FORMERLY HSBC CCF)',
  CCFRFRPP150: 'HSBC FRANCE (FORMERLY HSBC CCF)',
  CCFRFRPP170: 'HSBC FRANCE (FORMERLY HSBC CCF)',
  CCFRFRPP209: 'HSBC FRANCE (FORMERLY HSBC CCF)',
  CCFRFRPP210: 'HSBC FRANCE (FORMERLY HSBC CCF)',
  CCFRFRPP310: 'HSBC FRANCE (FORMERLY HSBC CCF)',
  CCFRFRPP220: 'HSBC FRANCE (FORMERLY HSBC CCF)',
  CCFRFRPP030: 'HSBC FRANCE (FORMERLY HSBC CCF)',
  CCFRFRPP024: 'HSBC FRANCE (FORMERLY HSBC CCF)',
  CCFRFRPPNTX: 'HSBC FRANCE (FORMERLY HSBC CCF)',
  CCFRFRPPEQC: 'HSBC FRANCE (FORMERLY HSBC CCF)',
  CCFRFRPPSWT: 'HSBC FRANCE (FORMERLY HSBC CCF)',
  CCFRFRPPSDD: 'HSBC FRANCE (FORMERLY HSBC CCF)',
  CCFRFRPP040: 'HSBC FRANCE (FORMERLY HSBC CCF)',
  CCFRFRPP050: 'HSBC FRANCE (FORMERLY HSBC CCF)',
  CCFRFRPP: 'HSBC FRANCE (FORMERLY HSBC CCF)',
  CCFRFRPP151: 'HSBC FRANCE (FORMERLY HSBC CCF)',
  CCFRFRPP089: 'HSBC FRANCE (FORMERLY HSBC CCF)',
  CCFRFRPP260: 'HSBC FRANCE (FORMERLY HSBC CCF)',
  CCFRFRPP152: 'HSBC FRANCE (FORMERLY HSBC CCF)',
  HAMFFRPB: 'HSBC GLOBAL ASSET MANAGEMENT FRANCE',
  HAMFFRPP: 'HSBC GLOBAL ASSET MANAGEMENT FRANCE (FORMERLY HSBC ASSET MANAGEMENT (EUROPE) SA',
  SAMFFRPP: 'HSBC GLOBAL ASSET MANAGEMENT FRANCE (FORMERLY SINOPIA ASSET MANAGEMENT)',
  HSFHFRPP: 'HSBC SFH (FRANCE)',
  FXBBFRPP: 'IBANFIRST',
  ICADFRPP: 'ICADE',
  RSETFRPP: 'ID2S S.A.',
  RSETFRPPSEC: 'ID2S S.A.',
  OTSAFRPP: 'IDEMIA FRANCE',
  GLPSFRP2: 'IM GLOBAL PARTNER SAS',
  IMERFRPP: 'IMERYS SA',
  VPKSFR22: 'INDIGO PARK',
  ISTAFR22: 'INFOVISTA HOLDING',
  INGBFRPP: 'ING BANK FRANCE COMMERCIAL BANKING',
  IGENFR22: 'INGENICO',
  IPARFRPP: 'INTER PARFUMS S.A',
  ICPOFR22: 'INTERPOL SERVICE FINANCIER',
  BCITFRPP: 'INTESA SANPAOLO SPA',
  INVZFRPP: 'INVESCO GESTION',
  INVZFRPPIHK: 'INVESCO GESTION',
  INVZFRPPTRI: 'INVESCO GESTION',
  INVZFRPPIPE: 'INVESCO GESTION',
  INVZFRPPMDO: 'INVESCO GESTION',
  ORUNFRPP: 'IPAGOO LLP FRENCH BRANCH',
  IPSNFRPP: 'IPSEN',
  IPSOFRPP: 'IPSOS',
  TTCEFRPP: 'IT-CE',
  ITMEFRPP: 'ITM ENTREPRISES S.A.S.',
  IVAIFR22: 'IVALIS S.A.',
  JCDXFR22: 'JC DECAUX SA',
  JCGRFRPP: 'JEAN CASSEGRAIN',
  KREDFRPP: 'KBC BANK NV PARIS',
  KREDFRPP416: 'KBC BANK NV PARIS',
  KOEXFRPPFRA: 'KEB HANA BANK',
  KOEXFRPP: 'KEB HANA BANK',
  JBBRFRPP01X: 'KEPLER CHEUVREUX',
  JBBRFRPP02X: 'KEPLER CHEUVREUX',
  JBBRFRPP: 'KEPLER CHEUVREUX',
  PPRFFRPP: 'KERING FINANCE (FORMERLY PPR FINANCE)',
  KGSAFRPP: 'KERING S.A.',
  KLPIFRPP: 'KLEPIERRE MANAGEMENT',
  BKROFRPP: 'KRONENBOURG',
  KYRIFRPP: 'KYRIBA SAS',
  PSSTFRPPAJA: 'LA BANQUE POSTALE',
  PSSTFRPPBOR: 'LA BANQUE POSTALE',
  PSSTFRPPCHA: 'LA BANQUE POSTALE',
  PSSTFRPPCLE: 'LA BANQUE POSTALE',
  PSSTFRPPDIJ: 'LA BANQUE POSTALE',
  PSSTFRPPGRE: 'LA BANQUE POSTALE',
  PSSTFRPPLIL: 'LA BANQUE POSTALE',
  PSSTFRPPLIM: 'LA BANQUE POSTALE',
  PSSTFRPPCVM: 'LA BANQUE POSTALE',
  PSSTFRPPLYO: 'LA BANQUE POSTALE',
  PSSTFRPPMAR: 'LA BANQUE POSTALE',
  PSSTFRPPMON: 'LA BANQUE POSTALE',
  PSSTFRPPNCY: 'LA BANQUE POSTALE',
  PSSTFRPPNTE: 'LA BANQUE POSTALE',
  PSSTFRPPSCE: 'LA BANQUE POSTALE',
  PSSTFRPPCNE: 'LA BANQUE POSTALE',
  PSSTFRPPBTE: 'LA BANQUE POSTALE',
  PSSTFRPPCAY: 'LA BANQUE POSTALE',
  PSSTFRPPFDF: 'LA BANQUE POSTALE',
  PSSTFRPPPAR: 'LA BANQUE POSTALE',
  PSSTFRPPSDR: 'LA BANQUE POSTALE',
  PSSTFRPPTRE: 'LA BANQUE POSTALE',
  PSSTFRPP: 'LA BANQUE POSTALE',
  PSSTFRPPCCB: 'LA BANQUE POSTALE',
  PSSTFRPPREN: 'LA BANQUE POSTALE',
  PSSTFRPPROU: 'LA BANQUE POSTALE',
  PSSTFRPPSTR: 'LA BANQUE POSTALE',
  PSSTFRPPTOU: 'LA BANQUE POSTALE',
  LBGAFRPP: 'LA BANQUE POSTALE ASSET MANAGEMENT',
  HLBPFRPP: 'LA BANQUE POSTALE HOME LOAN SFH',
  CWELFR22: 'LA COOPERATIVE WELCOOP',
  WBNVFRPP: 'LA POSTE',
  LABOFR22: 'LABORATOIRES ANIOS SAS',
  LATHFR22: 'LABORATOIRES THEA',
  LAFAFRPP: 'LAFARGE SA',
  LGSPFRPP: 'LAGARDERE SPORTS AND ENTERTAINMENT',
  LASXFRPP: 'LASALLE INVESTMENT MANAGEMENT SAS',
  LAZPFRPPMOK: 'LAZARD FRERES BANQUE',
  LAZPFRCC: 'LAZARD FRERES BANQUE',
  LAZPFRPP: 'LAZARD FRERES BANQUE',
  LAFGFRPP: 'LAZARD FRERES GESTION',
  CRLYFRPP: 'LCL (LE CREDIT LYONNAIS)',
  CRLYFRPPRMA: 'LCL (LE CREDIT LYONNAIS)',
  CRLYFRPP049: 'LCL (LE CREDIT LYONNAIS)',
  CRLYFRPPDCM: 'LCL (LE CREDIT LYONNAIS)',
  CRLYFRPPDOC: 'LCL (LE CREDIT LYONNAIS)',
  CRLYFRPPIGA: 'LCL (LE CREDIT LYONNAIS)',
  CRLYFRPP726: 'LCL (LE CREDIT LYONNAIS)',
  LEGDFR2L: 'LEGRAND FRANCE SA',
  LGRIFR22: 'LEGRIS INDUSTRIES DEVELOPPEMENT S.A.',
  LEWAFRPP: 'LEMON WAY',
  LECPFRPP: 'LESAFFRE ET COMPAGNIE',
  LISIFR22: 'LISI',
  LOCYFRPPNDP: 'LOMBARD ODIER (EUROPE) SA SUCCURSALE EN FRANCE',
  LOCYFRPP: 'LOMBARD ODIER (EUROPE) SA SUCCURSALE EN FRANCE',
  LDARFR22: 'LOUIS DREYFUS ARMATEURS',
  LOUVFRPP: 'LOUVRE HOTELS GROUP',
  LOXAFR2X: 'LOXAM',
  LUDDFR22: 'LUDENDO ENTREPRISES',
  LVMHFRPP: 'LVMH - MOET HENNESSY LOUIS VUITTON',
  LYREFR22: 'LYRECO SAS',
  LAMXFRPP: 'LYXOR ASSET MANAGEMENT',
  LIMXFRPP: 'LYXOR INTERNATIONAL ASSET MANAGEMENT',
  LIMXFRPB: 'LYXOR INTERNATIONAL ASSET MANAGEMENT',
  MNINFR22: 'MANUTAN INTERNATIONAL S.A.',
  MAUFFRP2: 'MARKET SECURITIES (FRANCE) SA',
  VIZAFRPP: 'MARLINK HOLDING SAS',
  MAUBFRPP: 'MAUBOUSSIN',
  MBDAFRPP: 'MBDA FRANCE',
  MBWSFRPP: 'MBWS S.A.',
  MCBRFRPP: 'MCBRIDE SAS',
  ICBCFRPP: 'MEGA INTERNATIONAL COMMERCIAL BANK CO. LTD.',
  MEMOFRP2: 'MEMO BANK',
  METGFRP2: 'METROPOLE GESTION',
  AXLTFRPP: 'MFEX FRANCE',
  MGGOFR22: 'MIGOM BANK',
  PRIVFRPP: 'MILLEIS BANQUE',
  MOISFR22: 'MOBILIS',
  MOBQFR22: 'MOBILIS BANQUE',
  MOXEFR22: 'MONEXT',
  MONTFRPP: 'MONTE PASCHI BANQUE S.A.',
  MSFRFRCP: 'MORGAN STANLEY (FRANCE) S.A.',
  MTLSFR2A: 'MOTEURS LEROY SOMER',
  MPOGFR22: 'MPO INTERNATIONAL',
  FMTSFRPP: 'MTS FRANCE SAS',
  MOEYFRPPMON: 'MY MONEY BANK',
  MOEYFRPP: 'MY MONEY BANK',
  BESVFRPP: 'MY PARTNER BANK',
  BESVFRPPYBF: 'MY PARTNER BANK',
  NBOKFRPP: 'NATIONAL BANK OF KUWAIT FRANCE SA',
  NBPAFRPP: 'NATIONAL BANK OF PAKISTAN',
  NATXFRPPDBO: 'NATIXIS',
  NATXFRPPDFG: 'NATIXIS',
  NATXFRPPMAR: 'NATIXIS',
  NATXFRPPTIT: 'NATIXIS',
  NATXFRPPFMP: 'NATIXIS',
  NATXFRPPPAI: 'NATIXIS',
  NATXFRPPBPS: 'NATIXIS',
  NATXFRPPTCE: 'NATIXIS',
  NATXFRPPTCC: 'NATIXIS',
  NATXFRPP: 'NATIXIS',
  IXIRFRPP: 'NATIXIS (BROKERAGE DIVISION)',
  NATTFRPP: 'NATIXIS INTERTITRES',
  NGAAFRPP: 'NATIXIS INVESTMENT MANAGERS',
  NTRXFR2A: 'NATUREX',
  DCNSFRPP: 'NAVAL GROUP',
  NEONFRPP: 'NEOEN SA',
  NEOSFRPP: 'NEOPOST SA',
  EGIIFRPP: 'NEPTUNE ENERGY INTERNATIONAL',
  NWNPFRPP: 'NEW NP S.A.S.',
  NVCAFR22: 'NOVACAP S.A.S',
  ODDOFRPPNES: 'ODDO BHF SCA',
  ODDOFRPPBKO: 'ODDO BHF SCA',
  ODDOFRPPFEC: 'ODDO BHF SCA',
  ODDOFRPPOAM: 'ODDO BHF SCA',
  ODDOFRPPOCO: 'ODDO BHF SCA',
  ODDOFRPPOOP: 'ODDO BHF SCA',
  ODDOFRPP: 'ODDO BHF SCA',
  ODDOFRPA: 'ODDO ET CIE',
  ODDOFRCP: "ODDO ET CIE ENTREPRISE D'INVESTISSEMENT",
  OFIFFRPP: 'OFI ASSET MANAGEMENT',
  QNTOFRPA: 'OLINDA',
  BACCFR22OLI: 'ONEY BANK',
  BACCFR22: 'ONEY BANK',
  BACCFR23: 'ONEY BANK',
  GMACFR22: 'OPEL BANK',
  GPBAFRPP: 'ORANGE BANK',
  FTELFRPP: 'ORANGE SA',
  ORNOFRPP: 'ORANO S.A.',
  OTRAFR22: 'ORCHESTRA PREMAMAN S.A.',
  OREAFRPP: 'ORPEA S.A.',
  IXIAFRPP: 'OSTRUM ASSET MANAGEMENT',
  IXIAFRPP001: 'OSTRUM ASSET MANAGEMENT',
  OTVSFR22: 'OTV SA',
  OVHGFR22: 'OVH GROUPE SAS',
  PAREFRPPAFF: 'PAREL',
  PAREFRPPCCL: 'PAREL',
  PAREFRPPDES: 'PAREL',
  PAREFRPPEQR: 'PAREL',
  PAREFRPPGBS: 'PAREL',
  PAREFRPPGDU: 'PAREL',
  PAREFRPPCIB: 'PAREL',
  PAREFRPPLCH: 'PAREL',
  PAREFRPPHVT: 'PAREL',
  PAREFRPPINV: 'PAREL',
  PAREFRPPKCH: 'PAREL',
  PAREFRPPNDG: 'PAREL',
  PAREFRPPTRA: 'PAREL',
  PAREFRPPBZC: 'PAREL',
  PAREFRPPGPP: 'PAREL',
  PAREFRPPAGY: 'PAREL',
  PAREFRPPVBC: 'PAREL',
  PAREFRPP: 'PAREL',
  PAHEFRPP: 'PATHE S.A.S.',
  PRGRFRPP: 'PERNOD RICARD',
  PSAGFRPP: 'PEUGEOT SA',
  PCSIFRP2: 'PFS CARD SERVICES IRELAND - FRANCE',
  PICTFRPPROB: 'PICTET AND CIE (EUROPE) S.A. SUCCURSALE DE PARIS',
  PICTFRPP: 'PICTET AND CIE (EUROPE) S.A. SUCCURSALE DE PARIS',
  PFSBFRPP: 'PIERRE FABRE SA',
  POCLFR22: 'POCLAIN HYDRAULICS SA',
  POLEFR22: 'POLE EMPLOI',
  POUJFR22: 'POUJOULAT S.A.',
  PSSSFR22: 'PPS EU',
  PROCFRPPBAV: 'PROCAPITAL SA',
  PROCFRPPBPE: 'PROCAPITAL SA',
  PROCFRPPPCB: 'PROCAPITAL SA',
  PROCFRPP: 'PROCAPITAL SA',
  PROCFRPPEAB: 'PROCAPITAL SA',
  PROCFRPPLBG: 'PROCAPITAL SA',
  PROCFRPPLGV: 'PROCAPITAL SA',
  PROCFRPPMGP: 'PROCAPITAL SA',
  PROMFR22: 'PROMOD SAS',
  PUBGFRPP: 'PUBLICIS FINANCE SERVICES SA',
  QNBAFRPPECB: 'QATAR NATIONAL BANK',
  QNBAFRPP: 'QATAR NATIONAL BANK',
  RABOFRPP: 'RABOBANK PARIS',
  RAPDFRPP: 'RATP DEVELOPPEMENT',
  ROYCFRPP: 'RBC EUROPE LIMITED PARIS BRANCH',
  DISFFRPPMOD: 'RBC INVESTOR SERVICES BANK FRANCE S.A.',
  DISFFRPPINV: 'RBC INVESTOR SERVICES BANK FRANCE S.A.',
  DISFFRPP: 'RBC INVESTOR SERVICES BANK FRANCE S.A.',
  RCINFRPP: 'RCIBANQUE S.A.',
  RENOFRPP: 'RENAULT SA',
  RTEFFRPP: "RESEAU DE TRANSPORT D'ELECTRICITE",
  REXSFRPP: 'REXEL SA',
  KBFGFRPP: 'RICHELIEU GESTION',
  ROBTFR22: 'ROBERTET SA',
  RFSAFRPP: 'ROQUETTE FRERES',
  RCBPFRPP: 'ROTHSCHILD MARTIN MAUREL',
  RCBPFRPR: 'ROTHSCHILD MARTIN MAUREL',
  BMMMFR2A: 'ROTHSCHILD MARTIN MAUREL',
  SALAFR2M: 'SAARLB FRANCE',
  SFRAFRPP: 'SAFRAN',
  SFRAFRP2: 'SAFRAN',
  SGEOFRPP: 'SAGEMCOM S.A.S.',
  SAMSFR22: 'SAMSIC',
  SNOFFR2L: 'SANOFI PASTEUR MSD S.N.C.',
  SAAVFRPP: 'SANOFI SA',
  SANXFRPP: 'SANOFI SA',
  SATEFR22: 'SAS LE CREUSET',
  SBMDFR22: 'SBM DEVELOPPEMENT',
  SCGIFRPP: 'SCOR INVESTMENT PARTNERS',
  SCTYFRPP: 'SCOR SE',
  SOPUFR22: 'SCORPIUS S.A.S.',
  SKSAFR22: 'SEB S.A.',
  SEENFR22: 'SECHE ENVIRONNEMENT',
  SDVRFRPP: 'SEDIVER S.A.S.',
  SENOFR2P: 'SENOBLE',
  SFILFRPP: 'SFIL',
  SGOEFRPP: 'SG OPTION EUROPE S.A.',
  SGDSFR22: 'SGD SA',
  SSHOFRPP: 'SIACI SAINT HONORE SAS',
  SIUAFR22: 'SICAME GROUP S.A.S',
  SITXFR22: 'SINTEX NP',
  SMCSFRPP: 'SMCP SAS',
  SMPAFR22: 'SMILE AND PAY',
  REYDFR22: 'SMRC AUTOMOTIVE MODULES FRANCE SAS',
  SNINFRPP: 'SNCF INTERSERVICES',
  SNCFFRPA: 'SNCF MOBILITES',
  RFFRFRPP: 'SNCF RESEAU',
  SAGKFRPP: 'SOCIETE ANONYME DE GESTION DE STOCKS DE SECURITE - SAGESS',
  SOBCFR2P: 'SOCIETE BIC',
  CCBPFRPPMRT: 'SOCIETE CENTRALE DE CREDIT MARITIME MUTUEL',
  SOGEFRPPAGE: 'SOCIETE GENERALE',
  SOGEFRPPAXP: 'SOCIETE GENERALE',
  SOGEFRPPAJA: 'SOCIETE GENERALE',
  SOGEFRPPALB: 'SOCIETE GENERALE',
  SOGEFRPPALN: 'SOCIETE GENERALE',
  SOGEFRPPALE: 'SOCIETE GENERALE',
  SOGEFRPPAMI: 'SOCIETE GENERALE',
  SOGEFRPPAGS: 'SOCIETE GENERALE',
  SOGEFRPPANG: 'SOCIETE GENERALE',
  SOGEFRPPANY: 'SOCIETE GENERALE',
  SOGEFRPPANS: 'SOCIETE GENERALE',
  SOGEFRPPANT: 'SOCIETE GENERALE',
  SOGEFRPPARC: 'SOCIETE GENERALE',
  SOGEFRPPAGT: 'SOCIETE GENERALE',
  SOGEFRPPARL: 'SOCIETE GENERALE',
  SOGEFRPPARM: 'SOCIETE GENERALE',
  SOGEFRPPARR: 'SOCIETE GENERALE',
  SOGEFRPPASN: 'SOCIETE GENERALE',
  SOGEFRPPAUB: 'SOCIETE GENERALE',
  SOGEFRPPAUR: 'SOCIETE GENERALE',
  SOGEFRPPAUX: 'SOCIETE GENERALE',
  SOGEFRPPAVI: 'SOCIETE GENERALE',
  SOGEFRPPBLD: 'SOCIETE GENERALE',
  SOGEFRPPBAS: 'SOCIETE GENERALE',
  SOGEFRPPBAY: 'SOCIETE GENERALE',
  SOGEFRPPBSO: 'SOCIETE GENERALE',
  SOGEFRPPBEA: 'SOCIETE GENERALE',
  SOGEFRPPBEV: 'SOCIETE GENERALE',
  SOGEFRPPBEF: 'SOCIETE GENERALE',
  SOGEFRPPBES: 'SOCIETE GENERALE',
  SOGEFRPPBET: 'SOCIETE GENERALE',
  SOGEFRPPBEZ: 'SOCIETE GENERALE',
  SOGEFRPPBLO: 'SOCIETE GENERALE',
  SOGEFRPPBOB: 'SOCIETE GENERALE',
  SOGEFRPPBON: 'SOCIETE GENERALE',
  SOGEFRPPBOI: 'SOCIETE GENERALE',
  SOGEFRPPBOP: 'SOCIETE GENERALE',
  SOGEFRPPOUE: 'SOCIETE GENERALE',
  SOGEFRPPBSS: 'SOCIETE GENERALE',
  SOGEFRPPBIL: 'SOCIETE GENERALE',
  SOGEFRPPBSM: 'SOCIETE GENERALE',
  SOGEFRPPBEB: 'SOCIETE GENERALE',
  SOGEFRPPBLR: 'SOCIETE GENERALE',
  SOGEFRPPBOU: 'SOCIETE GENERALE',
  SOGEFRPPBOJ: 'SOCIETE GENERALE',
  SOGEFRPPBRE: 'SOCIETE GENERALE',
  SOGEFRPPBRI: 'SOCIETE GENERALE',
  SOGEFRPPBRU: 'SOCIETE GENERALE',
  SOGEFRPPCAE: 'SOCIETE GENERALE',
  SOGEFRPPCAL: 'SOCIETE GENERALE',
  SOGEFRPPCAM: 'SOCIETE GENERALE',
  SOGEFRPPCAN: 'SOCIETE GENERALE',
  SOGEFRPPCAR: 'SOCIETE GENERALE',
  SOGEFRPPCAT: 'SOCIETE GENERALE',
  SOGEFRPPCAU: 'SOCIETE GENERALE',
  SOGEFRPPCAV: 'SOCIETE GENERALE',
  SOGEFRPPCGY: 'SOCIETE GENERALE',
  SOGEFRPPCSS: 'SOCIETE GENERALE',
  SOGEFRPPCSM: 'SOCIETE GENERALE',
  SOGEFRPPCHY: 'SOCIETE GENERALE',
  SOGEFRPPCHM: 'SOCIETE GENERALE',
  SOGEFRPPCHS: 'SOCIETE GENERALE',
  SOGEFRPPCHT: 'SOCIETE GENERALE',
  SOGEFRPPCHX: 'SOCIETE GENERALE',
  SOGEFRPPCHA: 'SOCIETE GENERALE',
  SOGEFRPPCHE: 'SOCIETE GENERALE',
  SOGEFRPPCLF: 'SOCIETE GENERALE',
  SOGEFRPPCLI: 'SOCIETE GENERALE',
  SOGEFRPPCOG: 'SOCIETE GENERALE',
  SOGEFRPPCOM: 'SOCIETE GENERALE',
  SOGEFRPPCSH: 'SOCIETE GENERALE',
  SOGEFRPPCIG: 'SOCIETE GENERALE',
  SOGEFRPPDSE: 'SOCIETE GENERALE',
  SOGEFRPPCRE: 'SOCIETE GENERALE',
  SOGEFRPPCEV: 'SOCIETE GENERALE',
  SOGEFRPPCRT: 'SOCIETE GENERALE',
  SOGEFRPPDAX: 'SOCIETE GENERALE',
  SOGEFRPPDIE: 'SOCIETE GENERALE',
  SOGEFRPPDIG: 'SOCIETE GENERALE',
  SOGEFRPPDIJ: 'SOCIETE GENERALE',
  SOGEFRPPDOU: 'SOCIETE GENERALE',
  SOGEFRPPDRA: 'SOCIETE GENERALE',
  SOGEFRPPDRX: 'SOCIETE GENERALE',
  SOGEFRPPDUN: 'SOCIETE GENERALE',
  SOGEFRPPELB: 'SOCIETE GENERALE',
  SOGEFRPPEPY: 'SOCIETE GENERALE',
  SOGEFRPPEPI: 'SOCIETE GENERALE',
  SOGEFRPPETA: 'SOCIETE GENERALE',
  SOGEFRPPEVX: 'SOCIETE GENERALE',
  SOGEFRPPEVR: 'SOCIETE GENERALE',
  SOGEFRPPFEV: 'SOCIETE GENERALE',
  SOGEFRPPFOX: 'SOCIETE GENERALE',
  SOGEFRPPFON: 'SOCIETE GENERALE',
  SOGEFRPPGAR: 'SOCIETE GENERALE',
  SOGEFRPPFBM: 'SOCIETE GENERALE',
  SOGEFRPPHRS: 'SOCIETE GENERALE',
  SOGEFRPPST2: 'SOCIETE GENERALE',
  SOGEFRPPGRA: 'SOCIETE GENERALE',
  SOGEFRPPGRS: 'SOCIETE GENERALE',
  SOGEFRPPGRL: 'SOCIETE GENERALE',
  SOGEFRPPGRM: 'SOCIETE GENERALE',
  SOGEFRPPGVH: 'SOCIETE GENERALE',
  SOGEFRPPHOU: 'SOCIETE GENERALE',
  SOGEFRPPHYE: 'SOCIETE GENERALE',
  SOGEFRPPILM: 'SOCIETE GENERALE',
  SOGEFRPPIVS: 'SOCIETE GENERALE',
  SOGEFRPPJSO: 'SOCIETE GENERALE',
  SOGEFRPPLRE: 'SOCIETE GENERALE',
  SOGEFRPPLRY: 'SOCIETE GENERALE',
  SOGEFRPPLRO: 'SOCIETE GENERALE',
  SOGEFRPPLGN: 'SOCIETE GENERALE',
  SOGEFRPPLAO: 'SOCIETE GENERALE',
  SOGEFRPPLAV: 'SOCIETE GENERALE',
  SOGEFRPPLHA: 'SOCIETE GENERALE',
  SOGEFRPPLEM: 'SOCIETE GENERALE',
  SOGEFRPPRAI: 'SOCIETE GENERALE',
  SOGEFRPPLEN: 'SOCIETE GENERALE',
  SOGEFRPPLMX: 'SOCIETE GENERALE',
  SOGEFRPPLEP: 'SOCIETE GENERALE',
  SOGEFRPPLIB: 'SOCIETE GENERALE',
  SOGEFRPPLLM: 'SOCIETE GENERALE',
  SOGEFRPPLIN: 'SOCIETE GENERALE',
  SOGEFRPPLIM: 'SOCIETE GENERALE',
  SOGEFRPPLIS: 'SOCIETE GENERALE',
  SOGEFRPPLGJ: 'SOCIETE GENERALE',
  SOGEFRPPLOG: 'SOCIETE GENERALE',
  SOGEFRPPLLS: 'SOCIETE GENERALE',
  SOGEFRPPLOR: 'SOCIETE GENERALE',
  SOGEFRPPLDS: 'SOCIETE GENERALE',
  SOGEFRPPLPA: 'SOCIETE GENERALE',
  SOGEFRPPLYE: 'SOCIETE GENERALE',
  SOGEFRPPLPD: 'SOCIETE GENERALE',
  SOGEFRPPLYO: 'SOCIETE GENERALE',
  SOGEFRPPLYP: 'SOCIETE GENERALE',
  SOGEFRPPMAN: 'SOCIETE GENERALE',
  SOGEFRPPMAL: 'SOCIETE GENERALE',
  SOGEFRPPMON: 'SOCIETE GENERALE',
  SOGEFRPPMLJ: 'SOCIETE GENERALE',
  SOGEFRPPMRG: 'SOCIETE GENERALE',
  SOGEFRPPMAR: 'SOCIETE GENERALE',
  SOGEFRPPMAC: 'SOCIETE GENERALE',
  SOGEFRPPMAP: 'SOCIETE GENERALE',
  SOGEFRPPMPL: 'SOCIETE GENERALE',
  SOGEFRPPMAU: 'SOCIETE GENERALE',
  SOGEFRPPMAZ: 'SOCIETE GENERALE',
  SOGEFRPPMEX: 'SOCIETE GENERALE',
  SOGEFRPPMEL: 'SOCIETE GENERALE',
  SOGEFRPPMTZ: 'SOCIETE GENERALE',
  SOGEFRPPMIL: 'SOCIETE GENERALE',
  SOGEFRPPMDM: 'SOCIETE GENERALE',
  SOGEFRPPMTG: 'SOCIETE GENERALE',
  SOGEFRPPMTB: 'SOCIETE GENERALE',
  SOGEFRPPMOT: 'SOCIETE GENERALE',
  SOGEFRPPMOR: 'SOCIETE GENERALE',
  SOGEFRPPMOC: 'SOCIETE GENERALE',
  SOGEFRPPMOP: 'SOCIETE GENERALE',
  SOGEFRPPMSA: 'SOCIETE GENERALE',
  SOGEFRPPMUL: 'SOCIETE GENERALE',
  SOGEFRPPNAY: 'SOCIETE GENERALE',
  SOGEFRPPNAN: 'SOCIETE GENERALE',
  SOGEFRPPEDE: 'SOCIETE GENERALE',
  SOGEFRPPOPC: 'SOCIETE GENERALE',
  SOGEFRPPISE: 'SOCIETE GENERALE',
  SOGEFRPPBEN: 'SOCIETE GENERALE',
  SOGEFRPPTIT: 'SOCIETE GENERALE',
  SOGEFRPPNSL: 'SOCIETE GENERALE',
  SOGEFRPPNSS: 'SOCIETE GENERALE',
  SOGEFRPPNVS: 'SOCIETE GENERALE',
  SOGEFRPPNIO: 'SOCIETE GENERALE',
  SOGEFRPPNJM: 'SOCIETE GENERALE',
  SOGEFRPPNMS: 'SOCIETE GENERALE',
  SOGEFRPPNIT: 'SOCIETE GENERALE',
  SOGEFRPPNOY: 'SOCIETE GENERALE',
  SOGEFRPPNUI: 'SOCIETE GENERALE',
  SOGEFRPPORL: 'SOCIETE GENERALE',
  SOGEFRPPPAN: 'SOCIETE GENERALE',
  SOGEFRPPAMS: 'SOCIETE GENERALE',
  SOGEFRPPBTI: 'SOCIETE GENERALE',
  SOGEFRPPBTZ: 'SOCIETE GENERALE',
  SOGEFRPPBXL: 'SOCIETE GENERALE',
  SOGEFRPPEFK: 'SOCIETE GENERALE',
  SOGEFRPPFKT: 'SOCIETE GENERALE',
  SOGEFRPPHCM: 'SOCIETE GENERALE',
  SOGEFRPPMAD: 'SOCIETE GENERALE',
  SOGEFRPPMLA: 'SOCIETE GENERALE',
  SOGEFRPPMTL: 'SOCIETE GENERALE',
  SOGEFRPPPSZ: 'SOCIETE GENERALE',
  SOGEFRPPTOK: 'SOCIETE GENERALE',
  SOGEFRPPACH: 'SOCIETE GENERALE',
  SOGEFRPPCDE: 'SOCIETE GENERALE',
  SOGEFRPPFVS: 'SOCIETE GENERALE',
  SOGEFRP2: 'SOCIETE GENERALE',
  SOGEFRPPINT: 'SOCIETE GENERALE',
  SOGEFRPPEXO: 'SOCIETE GENERALE',
  SOGEFRPPCEN: 'SOCIETE GENERALE',
  SOGEFRPPCOP: 'SOCIETE GENERALE',
  SOGEFRPPADB: 'SOCIETE GENERALE',
  SOGEFRPPAFI: 'SOCIETE GENERALE',
  SOGEFRPPAGI: 'SOCIETE GENERALE',
  SOGEFRPPAG2: 'SOCIETE GENERALE',
  SOGEFRPPBCK: 'SOCIETE GENERALE',
  SOGEFRPPBMP: 'SOCIETE GENERALE',
  SOGEFRPPINV: 'SOCIETE GENERALE',
  SOGEFRPPSGS: 'SOCIETE GENERALE',
  SOGEFRPPAFX: 'SOCIETE GENERALE',
  SOGEFRPPEOM: 'SOCIETE GENERALE',
  SOGEFRPPCLS: 'SOCIETE GENERALE',
  SOGEFRPPADI: 'SOCIETE GENERALE',
  SOGEFRPPEXT: 'SOCIETE GENERALE',
  SOGEFRPPPEE: 'SOCIETE GENERALE',
  SOGEFRPPFIN: 'SOCIETE GENERALE',
  SOGEFRPPONE: 'SOCIETE GENERALE',
  SOGEFRPPGSS: 'SOCIETE GENERALE',
  SOGEFRPPLUX: 'SOCIETE GENERALE',
  SOGEFRPPLDE: 'SOCIETE GENERALE',
  SOGEFRPPIBL: 'SOCIETE GENERALE',
  SOGEFRPPLMC: 'SOCIETE GENERALE',
  SOGEFRPPLMD: 'SOCIETE GENERALE',
  SOGEFRPPLMB: 'SOCIETE GENERALE',
  SOGEFRPPLMA: 'SOCIETE GENERALE',
  SOGEFRPPCBS: 'SOCIETE GENERALE',
  SOGEFRPPAGY: 'SOCIETE GENERALE',
  SOGEFRPPAOA: 'SOCIETE GENERALE',
  SOGEFRPPOBA: 'SOCIETE GENERALE',
  SOGEFRPPANB: 'SOCIETE GENERALE',
  SOGEFRPPRRC: 'SOCIETE GENERALE',
  SOGEFRPPABO: 'SOCIETE GENERALE',
  SOGEFRPPBML: 'SOCIETE GENERALE',
  SOGEFRPPAJC: 'SOCIETE GENERALE',
  SOGEFRPPCTE: 'SOCIETE GENERALE',
  SOGEFRPPBXC: 'SOCIETE GENERALE',
  SOGEFRPPUCR: 'SOCIETE GENERALE',
  SOGEFRPPBFD: 'SOCIETE GENERALE',
  SOGEFRPPAED: 'SOCIETE GENERALE',
  SOGEFRPPWLF: 'SOCIETE GENERALE',
  SOGEFRPPAZF: 'SOCIETE GENERALE',
  SOGEFRPPAUG: 'SOCIETE GENERALE',
  SOGEFRPPGSG: 'SOCIETE GENERALE',
  SOGEFRPPALG: 'SOCIETE GENERALE',
  SOGEFRPPAGK: 'SOCIETE GENERALE',
  SOGEFRPPBMA: 'SOCIETE GENERALE',
  SOGEFRPPAKT: 'SOCIETE GENERALE',
  SOGEFRPPFMA: 'SOCIETE GENERALE',
  SOGEFRPPAMM: 'SOCIETE GENERALE',
  SOGEFRPPLPC: 'SOCIETE GENERALE',
  SOGEFRPPHPO: 'SOCIETE GENERALE',
  SOGEFRPPKPA: 'SOCIETE GENERALE',
  SOGEFRPPJLH: 'SOCIETE GENERALE',
  SOGEFRPPRAS: 'SOCIETE GENERALE',
  SOGEFRPPBKR: 'SOCIETE GENERALE',
  SOGEFRPPRIG: 'SOCIETE GENERALE',
  SOGEFRPPGSM: 'SOCIETE GENERALE',
  SOGEFRPPAFS: 'SOCIETE GENERALE',
  SOGEFRPPAPS: 'SOCIETE GENERALE',
  SOGEFRPPAIS: 'SOCIETE GENERALE',
  SOGEFRPPAXT: 'SOCIETE GENERALE',
  SOGEFRPPPVL: 'SOCIETE GENERALE',
  SOGEFRPPATV: 'SOCIETE GENERALE',
  SOGEFRPPTVI: 'SOCIETE GENERALE',
  SOGEFRPPBLG: 'SOCIETE GENERALE',
  SOGEFRPPAAA: 'SOCIETE GENERALE',
  SOGEFRPPACF: 'SOCIETE GENERALE',
  SOGEFRPPSFX: 'SOCIETE GENERALE',
  SOGEFRPPSGA: 'SOCIETE GENERALE',
  SOGEFRPPCBV: 'SOCIETE GENERALE',
  SOGEFRPPSGI: 'SOCIETE GENERALE',
  SOGEFRPPBTC: 'SOCIETE GENERALE',
  SOGEFRPPBTL: 'SOCIETE GENERALE',
  SOGEFRPPFIP: 'SOCIETE GENERALE',
  SOGEFRPPSGO: 'SOCIETE GENERALE',
  SOGEFRPPLMT: 'SOCIETE GENERALE',
  SOGEFRPPTCP: 'SOCIETE GENERALE',
  SOGEFRPP: 'SOCIETE GENERALE',
  SOGEFRPPPAU: 'SOCIETE GENERALE',
  SOGEFRPPPRX: 'SOCIETE GENERALE',
  SOGEFRPPPPG: 'SOCIETE GENERALE',
  SOGEFRPPPOI: 'SOCIETE GENERALE',
  SOGEFRPPPOT: 'SOCIETE GENERALE',
  SOGEFRPPSRP: 'SOCIETE GENERALE',
  SOGEFRPPPUB: 'SOCIETE GENERALE',
  SOGEFRPPHVT: 'SOCIETE GENERALE',
  SOGEFRPPQMP: 'SOCIETE GENERALE',
  SOGEFRPPRIM: 'SOCIETE GENERALE',
  SOGEFRPPREN: 'SOCIETE GENERALE',
  SOGEFRPPRON: 'SOCIETE GENERALE',
  SOGEFRPPRDZ: 'SOCIETE GENERALE',
  SOGEFRPPROX: 'SOCIETE GENERALE',
  SOGEFRPPROE: 'SOCIETE GENERALE',
  SOGEFRPPRUM: 'SOCIETE GENERALE',
  SOGEFRPPRUH: 'SOCIETE GENERALE',
  SOGEFRPPPSM: 'SOCIETE GENERALE',
  SOGEFRPPSTB: 'SOCIETE GENERALE',
  SOGEFRPPSAD: 'SOCIETE GENERALE',
  SOGEFRPPSTD: 'SOCIETE GENERALE',
  SOGEFRPPSDZ: 'SOCIETE GENERALE',
  SOGEFRPPSAE: 'SOCIETE GENERALE',
  SOGEFRPPSTE: 'SOCIETE GENERALE',
  SOGEFRPPSGL: 'SOCIETE GENERALE',
  SOGEFRPPSLF: 'SOCIETE GENERALE',
  SOGEFRPPSTL: 'SOCIETE GENERALE',
  SOGEFRPPSMR: 'SOCIETE GENERALE',
  SOGEFRPPSTN: 'SOCIETE GENERALE',
  SOGEFRPPSTO: 'SOCIETE GENERALE',
  SOGEFRPPSAO: 'SOCIETE GENERALE',
  SOGEFRPPSTQ: 'SOCIETE GENERALE',
  SOGEFRPPSQY: 'SOCIETE GENERALE',
  SOGEFRPPSTR: 'SOCIETE GENERALE',
  SOGEFRPPSTM: 'SOCIETE GENERALE',
  SOGEFRPPSDP: 'SOCIETE GENERALE',
  SOGEFRPPSAN: 'SOCIETE GENERALE',
  SOGEFRPPSAT: 'SOCIETE GENERALE',
  SOGEFRPPSCE: 'SOCIETE GENERALE',
  SOGEFRPPSEN: 'SOCIETE GENERALE',
  SOGEFRPPSET: 'SOCIETE GENERALE',
  SOGEFRPPSEV: 'SOCIETE GENERALE',
  SOGEFRPPSOS: 'SOCIETE GENERALE',
  SOGEFRPPBRG: 'SOCIETE GENERALE',
  SOGEFRPPSTG: 'SOCIETE GENERALE',
  SOGEFRPPSUR: 'SOCIETE GENERALE',
  SOGEFRPPTBS: 'SOCIETE GENERALE',
  SOGEFRPPTLB: 'SOCIETE GENERALE',
  SOGEFRPPMLV: 'SOCIETE GENERALE',
  SOGEFRPPTLN: 'SOCIETE GENERALE',
  SOGEFRPPLAF: 'SOCIETE GENERALE',
  SOGEFRPPTLS: 'SOCIETE GENERALE',
  SOGEFRPPTRG: 'SOCIETE GENERALE',
  SOGEFRPPTOR: 'SOCIETE GENERALE',
  SOGEFRPPTOY: 'SOCIETE GENERALE',
  SOGEFRPPVSR: 'SOCIETE GENERALE',
  SOGEFRPPVAC: 'SOCIETE GENERALE',
  SOGEFRPPVAN: 'SOCIETE GENERALE',
  SOGEFRPPVER: 'SOCIETE GENERALE',
  SOGEFRPPVES: 'SOCIETE GENERALE',
  SOGEFRPPVIC: 'SOCIETE GENERALE',
  SOGEFRPPVIZ: 'SOCIETE GENERALE',
  SOGEFRPPVSS: 'SOCIETE GENERALE',
  SOGEFRPPVSL: 'SOCIETE GENERALE',
  SOGEFRPPVPR: 'SOCIETE GENERALE',
  SOGEFRPPLYV: 'SOCIETE GENERALE',
  SOGEFRPPVIN: 'SOCIETE GENERALE',
  SOGEFRPPVTL: 'SOCIETE GENERALE',
  SOGEFRPPVOI: 'SOCIETE GENERALE',
  SGFFFRPP: 'SOCIETE GENERALE FACTORING S.A.',
  SOGEFRPI: 'SOCIETE GENERALE INSTANT PAYMENT',
  GSCFFR22: 'SOCIETE GENERALE SCF',
  GSCFFR22HA2: 'SOCIETE GENERALE SCF',
  GSCFFR22HA1: 'SOCIETE GENERALE SCF',
  GSFHFRPP: 'SOCIETE GENERALE SFH',
  GSFHFRPPHA3: 'SOCIETE GENERALE SFH',
  GSFHFRPPHA1: 'SOCIETE GENERALE SFH',
  GSFHFRPPHA2: 'SOCIETE GENERALE SFH',
  SMCTFR2AAIX: 'SOCIETE MARSEILLAISE DE CREDIT',
  SMCTFR2AAVI: 'SOCIETE MARSEILLAISE DE CREDIT',
  SMCTFR2ABZR: 'SOCIETE MARSEILLAISE DE CREDIT',
  SMCTFR2AGRA: 'SOCIETE MARSEILLAISE DE CREDIT',
  SMCTFR2AMAR: 'SOCIETE MARSEILLAISE DE CREDIT',
  SMCTFR2A: 'SOCIETE MARSEILLAISE DE CREDIT',
  SMCTFR2AMPL: 'SOCIETE MARSEILLAISE DE CREDIT',
  SMCTFR2ANIC: 'SOCIETE MARSEILLAISE DE CREDIT',
  SMCTFR2ANIM: 'SOCIETE MARSEILLAISE DE CREDIT',
  SMCTFR2AORA: 'SOCIETE MARSEILLAISE DE CREDIT',
  SMCTFR2APER: 'SOCIETE MARSEILLAISE DE CREDIT',
  SMCTFR2ASTP: 'SOCIETE MARSEILLAISE DE CREDIT',
  SMCTFR2ATLN: 'SOCIETE MARSEILLAISE DE CREDIT',
  SMCTFR2AVAL: 'SOCIETE MARSEILLAISE DE CREDIT',
  SGETFR22: 'SOCOTEC GESTION SAS',
  SORMFR2N: 'SOCRAM BANQUE',
  SDALFRPP: 'SODIAAL INTERNATIONAL',
  SFAXFRPP: 'SOFAX BANQUE',
  SFAXFRPPBDF: 'SOFAX BANQUE',
  SOFRFRPP: 'SOFIPROTEOL',
  AFDOFR22: 'SOGEA-SATOM',
  SGAIFR22: 'SOGECLAIR S.A.',
  SOECFR22: 'SOITEC SA',
  SOLFFR22: 'SOLETANCHE FREYSSINET',
  PAJAFR2S: 'SOLOCAL GROUP',
  SOMYFR22: 'SOMFY S.A.',
  SONRFRPP: 'SONEPAR SA',
  STERFR22: 'SOPRA STERIA GROUP',
  SRSAFR2M: 'SOPROFEN S.A.S.',
  SHHRFRPP: 'SPHERE S.A.',
  STDPFRPP: 'ST DUPONT',
  SBOSFRP2CTA: 'STATE STREET BANK INTERNATIONAL GMBH - PARIS BRANCH',
  SBOSFRP2: 'STATE STREET BANK INTERNATIONAL GMBH - PARIS BRANCH',
  SCSSFR22: 'STEELCASE SAS',
  TFEXFRPP: 'STEF TFE',
  STTSFRP2: 'STET SAS',
  STTSFRPP: 'STET SAS',
  SUEZFRPP: 'SUEZ S.A.',
  SUFAFR22: 'SULO FRANCE S.A.S.',
  SMBCFRPPGEB: 'SUMITOMO MITSUI BANKING CORPORATION EUROPE LIMITED',
  SMBCFRPP: 'SUMITOMO MITSUI BANKING CORPORATION EUROPE LIMITED',
  SUSGFRPP: 'SUSHI SHOP GROUP',
  HANDFRPP: 'SVENSKA HANDELSBANKEN AB',
  SWNBFR22: 'SWAN',
  SLAMFRPP: 'SWISS LIFE ASSET MANAGEMENT (FRANCE)',
  SLAMFRPPAMB: 'SWISS LIFE ASSET MANAGEMENT (FRANCE)',
  SWILFRPPOPE: 'SWISSLIFE BANQUE PRIVEE',
  SWILFRPPBDF: 'SWISSLIFE BANQUE PRIVEE',
  SWILFRPP114: 'SWISSLIFE BANQUE PRIVEE',
  SWILFRPP: 'SWISSLIFE BANQUE PRIVEE',
  LAGEFRPP: 'SYNLAB GESTION',
  TARKFRPP: 'TARKETT',
  TCHIFRPP: 'TECHNICOLOR',
  TPSAFRPP: 'TELEPERFORMANCE',
  TEREFR22: 'TEREOS',
  TCSFFRPP: 'THALES SA',
  BNYMFRPP: 'THE BANK OF NEW YORK MELLON SA/NV',
  THOEFRPP: 'THOM EUROPE S.A.S.',
  THUAFR22: 'THUASNE',
  TOKHFR22: 'TOKHEIM GROUP SERVICES',
  DIREFRPP: 'TOTAL DIRECT ENERGIE',
  TTREFRPP: 'TOTAL TREASURY',
  TOUAFRPP: 'TOUAX SCA',
  TREFFRP2: 'TRADITION SECURITIES AND FUTURES',
  TSERFR22: 'TRANSALLIANCE SERVICE',
  TSLFFRPP: 'TRANSATEL SA',
  TDEVFRPP: 'TRANSDEV',
  BOXXFRPP: 'TREASURYXPRESS',
  TREGFRPP: 'TREVES SAS',
  TSCGFRPP: 'TREVES TEXTILES AND SEAT COMPONENTS',
  UTUBFRPPLYO: 'TUNISIAN FOREIGN BANK',
  UTUBFRPPMAR: 'TUNISIAN FOREIGN BANK',
  UTUBFRPPBDF: 'TUNISIAN FOREIGN BANK',
  UTUBFRPPPYR: 'TUNISIAN FOREIGN BANK',
  UTUBFRPPREP: 'TUNISIAN FOREIGN BANK',
  UTUBFRPPBEL: 'TUNISIAN FOREIGN BANK',
  UTUBFRPP: 'TUNISIAN FOREIGN BANK',
  UBOFFR22: 'UBISOFT ENTERTAINMENT S.A.',
  UBSWFRPPBDF: 'UBS (FRANCE) SA',
  UBSWFRPP: 'UBS (FRANCE) SA',
  UJAAFRPP: 'UJA',
  UNBDFRPP: 'UNIBAIL-RODAMCO',
  UNCRFRPP: 'UNICREDITO ITALIANO SPA - SUCCURSALE DE PARIS',
  UBAFFRPP: 'UNION DE BANQUES ARABES ET FRANCAISES',
  BREUFR22: 'UNIONE DI BANCHE ITALIANE S.P.A.',
  UNBUFR22: 'UNITED BISCUITS',
  UMGTFRPP: 'UNIVERSAL MUSIC GROUP TREASURY SAS',
  UPGEFRPP: 'UP',
  UPASFRPP: 'UPSA S.A.S.',
  VAEOFRPP: 'VALEO SA',
  VLRCFR22: 'VALLOUREC TUBES',
  VEOEFRPP: 'VEOLIA ENVIRONNEMENT SA',
  VERAFRPP: 'VERALLIA FRANCE',
  SGDPFRPP: 'VERESCENCE FRANCE',
  VETOFR2L: 'VETOQUINOL SA',
  VIGIFRP2: 'VIGIFINANCE',
  VCOMFRPP: 'VILMORIN ET CIE',
  VINCFRPP: 'VINCI',
  VCFRFRPP: 'VINCI CONSTRUCTION FRANCE',
  VCGPFR22: 'VINCI CONSTRUCTION GRANDS PROJETS',
  VESAFR22: 'VINCI ENERGIES S.A.',
  VIRBFR22: 'VIRBAC S.A',
  VPAYFRP2: 'VIVA PAYMENT SERVICES S.A. FRENCH BRANCH',
  VIVAFR22: 'VIVA SANTE',
  FIFLFRPP: 'VIVARTE',
  VIVEFRPP: 'VIVENDI',
  VVCAFR22: 'VIVESCIA SERVICES',
  VIVCFR22: 'VIVUSCARD',
  VLTAFRPP: 'VOLTALIA S.A.',
  WTERFR22: 'WATERS S.A.S',
  WAVSFRPP: 'WAVESTONE S.A.',
  FDFRFR22: 'WEBHELP PAYMENT SERVICES FRANCE SAS',
  WEBHFRPP: 'WEBHELP SAS',
  WFSEFRPP: 'WELLS FARGO SECURITIES EUROPE S.A.',
  WFSEFRPPBCP: 'WELLS FARGO SECURITIES EUROPE S.A.',
  WENDFRPP: 'WENDEL SE',
  WIAGFRP2: 'WIDE ASSET MANAGEMENT',
  WWWAFR22: 'WINOA',
  WFCSFR2P: 'WORLD FREIGHT COMPANY',
  WOLNFR22: 'WORLDLINE S.A.',
  YMGIFRPP: 'YMAGIS S.A.',
  YOPPFRPP: 'YOPLAIT SAS',
  YPIIFR2N: 'YPI',
  YROCFRPP: 'YVES ROCHER',
  ZAVOFRPP: 'Z AND V SAS',
  ZODCFR22: 'ZODIAC AEROSPACE',
};
