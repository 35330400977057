import React from 'react';
import { useLinkTo, useRoute } from '@react-navigation/native';
import { RouteProp } from '@react-navigation/core/lib/typescript/src/types';
import MaxWidthContainer from '../../components/MaxWidthContainer';

import BseCard from '../../components/BseCard';
import VetoBseScreenBL from '../../screenBusinessLogic/vetoScreensBL/VetoBseScreenBL/VetoBseScreenBL';
import { useBseListByBreedingId } from '../../src/API/BSE';
import { VetoTabElevageParamList } from '../../types';
import ActivityIndicator from '../../components/ActivityIndicator';
import { StatusBse } from '../../src/API';

const VetoHistoriqueBseScreen = () => {
  const linkTo = useLinkTo();
  const route = useRoute<RouteProp<VetoTabElevageParamList, 'veto-historique-bse'>>();

  const { loading, bses } = useBseListByBreedingId(route.params.id);
  return (
    <MaxWidthContainer
      withScrollView="keyboardAware"
      outerViewProps={{
        style: {
          padding: 20,
        },
      }}
    >
      {loading ? (<ActivityIndicator center margin={10} />) : (
        bses && bses.map((item) => {
          if (item.StatusBse === StatusBse.BSEFinish) {
            return (
              <BseCard
                key={item.id}
                BSEId={item.id}
                goTo={() => {
                  VetoBseScreenBL.linkToSwitch({
                    state: 'details-bse',
                    linkTo,
                    id: item.id,
                    back: `veto-elevage/veto-historique-bse/${route.params.id}`,
                  });
                }}
              />
            );
          }
        })
      )}

    </MaxWidthContainer>
  );
};

export default VetoHistoriqueBseScreen;
