import { useLinkTo } from '@react-navigation/native';
import { Text } from '@ui-kitten/components';
import React from 'react';

import InfoCard from '../InfoCard';
import TeleconsultationCard from '../../components/TeleconsultationCard';
import CompButton from '../../components/Button';
import { BSE, Consultation } from '../../src/API';
import DateUtils from '../../utils/DateUtils';
import Tutorial from '../../components/Tutorial';

type ListTeleProps = {
  teleconsultationData?: Consultation[] | null,
  bseData?: BSE[] | null
  tdb?: boolean
};

const VetoListTeleconsultationCards = ({
  tdb,
  teleconsultationData, bseData,
}: ListTeleProps) => {
  const linkTo = useLinkTo();

  const teleconsEnCours = DateUtils.verificationDateTelecons('enCours', teleconsultationData);
  const teleconsAVenirs = DateUtils.verificationDateTelecons('aVenirs', teleconsultationData);
  const teleconsAPlanifiers = DateUtils.verificationDateTelecons('aPlanifiers', teleconsultationData);

  const bseAVenirs = DateUtils.verificationDateBSE('aVenirs', bseData);
  const bseAPlanifiers = DateUtils.verificationDateBSE('aPlanifiers', bseData);

  return (
    <>
      <Text category="h2" appearance="hint" style={{ marginTop: 20 }}>{`Téléconsultation en cours (${teleconsEnCours?.length})`}</Text>
      {teleconsEnCours && teleconsEnCours.length > 0
        ? (
          teleconsEnCours.map((teleconsEnCour) => <TeleconsultationCard veto key={teleconsEnCour.id} detailConsultationPage={false} consultation="RDV" consultationData={teleconsEnCour} />)
        ) : (
          <InfoCard
            title="alertInfo"
            iconShow="alert-circle-outline"
            description={"Vous n'avez pas de téléconsultation en cours"}
            alertInfo="alertInfo"
          />
        )}

      <Text category="h2" appearance="hint" style={{ marginTop: 20 }}>{`Téléconsultation à venir (${teleconsAVenirs?.length})`}</Text>
      {teleconsAVenirs && teleconsAVenirs.length > 0
        ? (
          teleconsAVenirs.map((teleconsAVenir) => <TeleconsultationCard veto key={teleconsAVenir.id} detailConsultationPage={false} consultation="RDV" consultationData={teleconsAVenir} />)
        ) : (
          <InfoCard
            title="alertInfo"
            iconShow="alert-circle-outline"
            description={"Vous n'avez pas de téléconsultation à venir"}
            alertInfo="alertInfo"
          />
        )}

      <Text category="h2" appearance="hint" style={{ marginTop: 20 }}>{`Téléconsultation à planifier (${teleconsAPlanifiers?.length})`}</Text>
      {teleconsAPlanifiers && teleconsAPlanifiers.length > 0
        ? (
          teleconsAPlanifiers.map((teleconsAPlanifier) => <TeleconsultationCard veto key={teleconsAPlanifier.id} detailConsultationPage={false} consultation="RDV" consultationData={teleconsAPlanifier} />)
        ) : (
          <InfoCard
            title="alertInfo"
            iconShow="alert-circle-outline"
            description={"Vous n'avez pas de téléconsultation à planifier"}
            alertInfo="alertInfo"
          />
        )}
      {tdb && (
        <>
          <Text category="h1" style={{ marginTop: 30 }}>Bilan Sanitaire d'Élevage</Text>
          <Tutorial
            videoId="H2L-7YOALUM"
          />
          <InfoCard
            title="alertInfo"
            description="Cette fonctionnalité vous permet de réaliser un BSE et son protocole de soins chez un éleveur adhérent. L’éleveur vous aura fait parvenir au préalable un pré-bilan, questionnaire collectant un ensemble d’informations sanitaires sur le troupeau indispensables à la réalisation du BSE, mais chronophage."
            imagePosition="left"
            imageUri={require('../../assets/veto_eleveur.png')}
            buttonText="Accéder à l'espace BSE"
            buttonOnPress={() => {
              linkTo('/veto-bse');
            }}
            infoStyle={{ marginTop: 5, padding: 20 }}
            invertButtonStyle
          />

          <Text category="h2" appearance="hint" style={{ marginTop: 20 }}>
            Bilans Sanitaires d'Élevage à venir (
            {bseAVenirs.length}
            )
          </Text>
          {bseAVenirs && bseAVenirs.length > 0 ? (
            bseAVenirs.map((bseAVenir) => <TeleconsultationCard veto key={bseAVenir.id} veto detailConsultationPage={false} consultation="RDV" bseData={bseAVenir} />)
          ) : (
            <InfoCard
              title="alertInfo"
              iconShow="alert-circle-outline"
              // iconCloseShow="close-outline"
              description={"Vous n'avez pas de BSE à venir"}
              alertInfo="alertInfo"
            />
          )}

          <Text category="h2" appearance="hint" style={{ marginTop: 20 }}>Bilans Sanitaires d'Élevage à planifier</Text>
          {bseAPlanifiers && bseAPlanifiers.length > 0 && bseAPlanifiers.length < 11 ? (
            bseAPlanifiers.map((bseAPlanifier) => <TeleconsultationCard veto key={bseAPlanifier.id} detailConsultationPage={false} consultation="RDV" bseData={bseAPlanifier} />)
          ) : (
            <InfoCard
              title="alertInfo"
              iconShow="alert-circle-outline"
              // iconCloseShow="close-outline"
              description={"Vous n'avez pas de BSE à planifier"}
              alertInfo="alertInfo"
            />
          )}
          {bseAPlanifiers && bseAPlanifiers.length > 10 && (
          <CompButton
            size="medium"
            status="primary"
            appearance="outline"
            onPress={() => {}}
          >
            Voir tous les prochaines BSE à effectuer
          </CompButton>
          )}

        </>
      )}

    </>

  );
};

export default VetoListTeleconsultationCards;
