const vetoScanflockTheme = {
  'color-primary-100': '#DDEDFB',
  'color-primary-200': '#BDDAF8',
  'color-primary-300': '#97BDEA',
  'color-primary-400': '#769ED5',
  'color-primary-500': '#4C76BA',
  'color-primary-600': '#375B9F',
  'color-primary-700': '#264385',
  'color-primary-800': '#182E6B',
  'color-primary-900': '#0E1F59',
  'color-primary-transparent-100': 'rgba(76, 118, 186, 0.08)',
  'color-primary-transparent-200': 'rgba(76, 118, 186, 0.16)',
  'color-primary-transparent-300': 'rgba(76, 118, 186, 0.24)',
  'color-primary-transparent-400': 'rgba(76, 118, 186, 0.32)',
  'color-primary-transparent-500': 'rgba(76, 118, 186, 0.4)',
  'color-primary-transparent-600': 'rgba(76, 118, 186, 0.48)',
  'color-success-100': '#ECFBD6',
  'color-success-200': '#D5F8AE',
  'color-success-300': '#B2EA81',
  'color-success-400': '#8ED55E',
  'color-success-500': '#5EBA30',
  'color-success-600': '#449F23',
  'color-success-700': '#2E8518',
  'color-success-800': '#1C6B0F',
  'color-success-900': '#0F5909',
  'color-success-transparent-100': 'rgba(71, 186, 48, 0.08)',
  'color-success-transparent-200': 'rgba(71, 186, 48, 0.16)',
  'color-success-transparent-300': 'rgba(71, 186, 48, 0.24)',
  'color-success-transparent-400': 'rgba(71, 186, 48, 0.32)',
  'color-success-transparent-500': 'rgba(71, 186, 48, 0.4)',
  'color-success-transparent-600': 'rgba(71, 186, 48, 0.48)',
  'color-info-100': '#DCEDFE',
  'color-info-200': '#BAD9FE',
  'color-info-300': '#97C2FD',
  'color-info-400': '#7CAEFB',
  'color-info-500': '#3B6CD6', // '#528DF9',
  'color-info-600': '#3B6CD6',
  'color-info-700': '#2950B3',
  'color-info-800': '#1A3790',
  'color-info-900': '#0F2577',
  'color-info-transparent-100': 'rgba(82, 141, 249, 0.08)',
  'color-info-transparent-200': 'rgba(82, 141, 249, 0.16)',
  'color-info-transparent-300': 'rgba(82, 141, 249, 0.24)',
  'color-info-transparent-400': 'rgba(82, 141, 249, 0.32)',
  'color-info-transparent-500': 'rgba(82, 141, 249, 0.4)',
  'color-info-transparent-600': 'rgba(82, 141, 249, 0.48)',
  'color-warning-100': '#FFF3CC',
  'color-warning-200': '#FFE399',
  'color-warning-300': '#FFD066',
  'color-warning-400': '#FFBC3F',
  'color-warning-500': '#FF9D00',
  'color-warning-600': '#DB7E00',
  'color-warning-700': '#B76200',
  'color-warning-800': '#934900',
  'color-warning-900': '#7A3800',
  'color-warning-transparent-100': 'rgba(255, 157, 0, 0.08)',
  'color-warning-transparent-200': 'rgba(255, 157, 0, 0.16)',
  'color-warning-transparent-300': 'rgba(255, 157, 0, 0.24)',
  'color-warning-transparent-400': 'rgba(255, 157, 0, 0.32)',
  'color-warning-transparent-500': 'rgba(255, 157, 0, 0.4)',
  'color-warning-transparent-600': 'rgba(255, 157, 0, 0.48)',
  'color-danger-100': '#FFECDF',
  'color-danger-200': '#FFD4BF',
  'color-danger-300': '#FFB79F',
  'color-danger-400': '#FF9B87',
  'color-danger-500': '#FF6E60',
  'color-danger-600': '#DB4746',
  'color-danger-700': '#B7303A',
  'color-danger-800': '#931E31',
  'color-danger-900': '#7A122B',
  'color-danger-transparent-100': 'rgba(255, 110, 96, 0.08)',
  'color-danger-transparent-200': 'rgba(255, 110, 96, 0.16)',
  'color-danger-transparent-300': 'rgba(255, 110, 96, 0.24)',
  'color-danger-transparent-400': 'rgba(255, 110, 96, 0.32)',
  'color-danger-transparent-500': 'rgba(255, 110, 96, 0.4)',
  'color-danger-transparent-600': 'rgba(255, 110, 96, 0.48)',

  'light-grey': '#6E6E6E',
  'xlight-grey': '#d4d4d4',
  'xtralight-grey': '#E6E6E6',
  'background-color': '#FAFCFD',

  'text-primary-focused-color': 'color-primary-500',
  'alert-custom-color': '#eaeef5',
  'realised-color': '#349B2E',
};

export default vetoScanflockTheme;
