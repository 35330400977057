import React, { useEffect, useState } from 'react';
import { SignUp as AmplifySignUp } from 'aws-amplify-react-native';

import {
  Layout, Text,
} from '@ui-kitten/components';
import { useForm } from 'react-hook-form';
// @ts-ignore
import TEST_ID from 'aws-amplify-react-native/dist/AmplifyTestIDs';

import {
  Platform, View,
} from 'react-native';
import { useDimensions } from '@react-native-community/hooks';
import { AuthStyles } from './styles';
import { ErrorMessage } from './components/ErrorMessage';
import TextInputComp from '../Form/TextInput';
import { AvailableValidationRules } from '../Form/validation';
import Radio from '../Form/Radio';
import Form from '../Form/Form';

import MaxWidthContainer from '../MaxWidthContainer';
import WebView from '../WebView';
import Header from './Header';
import Button from '../Button';
import BaseModal from '../Modals/BaseModal';
import Tutorial from '../Tutorial';

type SignUpForm = {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  acceptCGV: boolean;
  optIn: boolean;
};

const MySignUp = ({
  error, signUp, goBack, goConfirmCode,
}: SignUpProps) => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [modalUrl, setModalUrl] = useState<string | false>(false);
  const { window } = useDimensions();
  const [loading, setLoading] = useState(false);

  const [userGroup, setUserGroup] = useState<string>('');
  // let userGroup: string;

  // const theme = useTheme();

  const signUpForm = useForm<SignUpForm>();

  useEffect(() => {
    setErrorMessage(error);
  }, [error]);

  const submit = (data: SignUpForm) => {
    // console.log(data);
    signUp({
      password: data.password,
      email: data.email,
      family_name: data.lastname.substr(0, 1).toUpperCase() + data.lastname.substr(1),
      given_name: data.firstname.substr(0, 1).toUpperCase() + data.firstname.substr(1),
      'custom:optIn': data.optIn ? 'true' : 'false',
      'custom:groupe': userGroup,
    });

    setLoading(false);
  };

  return (
    <>
      <Header onPress={() => {
        if (userGroup !== '') {
          setUserGroup('');
        } else {
          goBack();
        }
      }}
      />
      <MaxWidthContainer
        withScrollView="keyboardAware"
        outerViewProps={{
          showsVerticalScrollIndicator: false,
        }}
        innerViewProps={{ style: { flex: 1, marginBottom: 20 } }}
        // maxWidth={1000}
      >
        { userGroup === '' && (
        <Layout style={{
          marginHorizontal: 25,
          backgroundColor: 'transparent',
        }}
        >
          <View style={{
            alignItems: 'center',
          }}
          >
            <Text category="h2" style={[AuthStyles.header, { textAlign: 'center' }]}>Inscrivez-vous à l'application ScanFlock</Text>
            <Text category="p1" appearance="hint" style={{ marginTop: 20, textAlign: 'center' }}>Pour commencer, sélectionnez votre profession.</Text>
          </View>
          <View style={[{ marginVertical: 30 }, Platform.OS === 'web' ? { flexDirection: 'row' } : { flexDirection: 'column' }]}>
            <Button style={{ backgroundColor: '#1C6B79', flex: 1 }} onPress={() => { setUserGroup('eleveur'); }}>Je m'inscris en tant qu'éleveur</Button>
            <Button style={[{ backgroundColor: '#264385', flex: 1 }, Platform.OS === 'web' ? { marginLeft: 20 } : { marginTop: 20 }]} onPress={() => { setUserGroup('veto'); }}>Je m'inscris en tant que vétérinaire</Button>
          </View>

          <Button appearance="ghost" onPress={goBack}>Vous avez déjà un compte ? Se connecter</Button>
        </Layout>
        )}
        {userGroup !== '' && (
        <Form<SignUpForm> {...signUpForm}>

          <Layout style={{
            marginHorizontal: 25,
            backgroundColor: 'transparent',

          }}
          >
            <View style={{
              alignItems: 'center',
            }}
            >
              <Tutorial
                videoId={userGroup === 'eleveur' ? '2yk1AvhFWRA' : 'UWeJUlGEzOo'}
                style={[AuthStyles.header]}
              />
              <Text category="h2" style={AuthStyles.header}>{`Je m'inscris en tant ${userGroup === 'eleveur' ? "qu'éleveur" : 'que vétérinaire'}`}</Text>
              <Text category="p1" appearance="hint">{userGroup === 'eleveur' ? 'Etape 1/4' : 'Etape 1/3'}</Text>
            </View>

            {userGroup === 'eleveur' ? <>
              <Text category="s2"  style={{ marginBottom: 10, marginTop: 20 }}>Le ScanFlock est réservé aux éleveurs de bovins.
Pour tester l'application, seul le nom de l'exploitation, le n° de téléphone et le type de production sont obligatoires.
Les autres informations demandées peuvent être renseignées plus tard si, convaincu, vous souhaitez adhérer.</Text>
              <Text category="s2"  style={{ marginBottom: 10, marginTop: 10 }}>La réalisation d'un auto-examen est totalement confidentielle tout comme les informations demandées au cours de celui-ci.
Vous seul pouvez choisir de partager ces informations avec un professionnel de santé, mais ce partage n'est jamais obligatoire.</Text>
            </>: null}

            <Text category="s2" style={{ color: '#cecece', marginBottom: 20, marginTop: 10 }}>Tous les champs sont obligatoires</Text>

            <ErrorMessage message={errorMessage} />

            <View style={Platform.OS === 'web' && ({ flexDirection: 'row' })}>
              <TextInputComp
                name="firstname"
                label="Prénom"
                placeholder="Saisissez votre prénom"
                style={[Platform.OS === 'web' && ({ marginRight: 20, marginBottom: 20 })]}
                validators={[
                  AvailableValidationRules.required,
                ]}
              />
              <TextInputComp
                name="lastname"
                label="Nom"
                placeholder="Saisissez votre nom"
                validators={[
                  AvailableValidationRules.required,
                ]}
              />
            </View>
            <View style={Platform.OS === 'web' && ({ flexDirection: 'row' })}>
              <TextInputComp
                name="email"
                label="E-mail"
                style={[Platform.OS === 'web' && ({ marginRight: 20, marginBottom: 20 })]}
                placeholder="Saisissez l'e-mail"
                validators={[
                  AvailableValidationRules.required,
                  AvailableValidationRules.email,
                ]}
              />
              <TextInputComp
                name="password"
                label="Mot de passe"
                placeholder="Définissez le mot de passe"
                secureTextEntry
                withEyeToggle
                validators={[
                  AvailableValidationRules.required,
                  AvailableValidationRules.password,
                ]}
              />
            </View>
            {/** user.group === 'veto' && ()
                 <TextInputComp
                 name="nOrdreCabinet"
                 label="N° d'inscription ordre des vétérinaires"
                 placeholder="Saisissez le numéro"
                 secureTextEntry
                 withEyeToggle
                 validators={[
                AvailableValidationRules.required,
                AvailableValidationRules.password,
              ]}
                 />
                 */}

            <Radio
              name="optIn"
              label="Souhaitez-vous rester informé de nos actualités ? "
              labelPosition="before"
            />
            <Radio
              name="acceptCGV"
              label={(props) => (
                <Text style={{ flex: 1 }}>
                  <Text {...props}>J'accepte </Text>
                  <Text {...props} onPress={() => setModalUrl('https://scanflock.com/conditions-generales-dutilisation/?simple=1')} style={{ textDecorationLine: 'underline' }}>les CGU</Text>
                  <Text {...props}> et </Text>
                  <Text {...props} onPress={() => setModalUrl('https://scanflock.com/conditions-generales-de-vente/?simple=1')} style={{ textDecorationLine: 'underline' }}>les CGV</Text>
                  <Text {...props}> du ScanFlock et </Text>
                  <Text
                    {...props}
                    onPress={() => setModalUrl('https://scanflock.com/politique-de-confidentialite/?simple=1')}
                    style={{ textDecorationLine: 'underline' }}
                  >
                    la politique de confidentialité
                  </Text>
                  <Text {...props}> de VetelSud</Text>
                </Text>
              )}
              labelPosition="before"
              validators={[
                {
                  rule: AvailableValidationRules.required,
                  errorMessage: 'Vous devez accepter les CGU et les CGV du ScanFlock et la politique de confidentialité de VetelSud',
                },
              ]}
            />
            <BaseModal
              visible={modalUrl !== false}
              onClose={() => setModalUrl(false)}
            >
              <View
                style={{
                  backgroundColor: 'white',
                  borderRadius: 10,
                  height: window.height * 0.9,
                  maxHeight: window.height * 0.9,
                  width: window.width * 0.9,
                  flex: 1,
                  justifyContent: 'space-between',
                  overflow: 'hidden',
                  padding: 7,
                }}
              >
                <WebView src={modalUrl || ''} />
                <Button onPress={() => setModalUrl(false)}>Fermer</Button>
              </View>
            </BaseModal>
            {/**
                 <Text style={{ flex: 1, marginVertical: 10 }}>
                 <Text>
                 ScanFlock collecte vos informations personnelles afin d'exécuter le contrat qui
                 vous lie et de satisfaire vos demandes (services, fonctionnement de compte, etc. ).
                 Pour connaitre les droits dont vous disposez concernant vos données personnelles
                 et toutes les informations sur leur traitement par Scanflock, vous pouvez
                 consulter notre Politique de protection des données
                 </Text>
                 <Text> </Text>
                 <Text onPress={() => setModalUrl('https://omedom.com/politique-de-confidentialite?simple=1')} style={{ textDecorationLine: 'underline' }}>ici</Text>
                 <Text>.</Text>
                 </Text>
                 */}

            <View style={[Platform.OS === 'web' && window.width > 560 && ({
              flexDirection: 'row', justifyContent: 'center',
            }), { flexDirection: 'column', alignItems: 'center' }]}
            >
              {/**
                <Button appearance="ghost" onPress={goConfirmCode}>Confirmez avec un code</Button>
              */}

              <Button
                size="medium"
                disabled={loading}
                style={{
                  width: 140,
                }}
                onPress={
                  signUpForm.handleSubmit(async (data) => {
                    setLoading(true);
                    submit(data);
                  })
                }
              >
                Suivant
              </Button>

              <Button
                appearance="ghost"
                onPress={goConfirmCode}
                style={{ flexDirection: 'row' }}
              >
                Vous avez déjà reçu un code et souhaitez confirmer votre inscription, cliquez ici.
              </Button>
              {userGroup === 'eleveur' ? (
                <View style={{
                  flex: 1, alignItems: 'flex-start',
                }}
                >
                  <Text
                    category="p2"
                    style={{ fontSize: 11, marginTop: 15 }}
                  >
                    La société VETELSUD SAS collecte vos données personnelles afin de vous permettre ou de permettre
                    à votre exploitation agricole de souscrire aux services de Scanflock et de les utiliser.
                  </Text>
                  <Text
                    category="p2"
                    style={{ fontSize: 11 }}
                  >
                    Les informations requises et marquées d’un astérisque sont obligatoires pour confirmer votre
                    inscription.
                  </Text>
                  <Text
                    category="p2"
                    style={{ fontSize: 11 }}
                  >
                    <Text
                      category="p2"
                      style={{ fontSize: 11 }}
                    >
                      Pour en savoir plus sur l’utilisation de vos données personnelles par VETELSUD et pour exercer vos
                      droits notamment vos droits d’accès, rectification et suppression, veuillez consulter la
                    </Text>
                    <Text
                      category="p2"
                      style={{ fontSize: 11 }}
                    >
                      {' '}
                    </Text>
                    <Text
                      category="p2"
                      style={{ fontSize: 11, textDecorationLine: 'underline' }}
                      onPress={() => setModalUrl('https://scanflock.com/politique-de-confidentialite/?simple=1')}
                    >
                      Politique de confidentialité
                    </Text>
                    <Text
                      category="p2"
                      style={{ fontSize: 11 }}
                    >
                      .
                    </Text>
                  </Text>
                </View>
              ) : (
                <View style={{
                  flex: 1, alignItems: 'flex-start',
                }}
                >
                  <Text
                    category="p2"
                    style={{ fontSize: 11, marginTop: 15 }}
                  >
                    La société VETELSUD SAS collecte vos données personnelles afin de vous permettre ou de permettre
                    à votre cabinet ou clinique de souscrire aux services de Scanflock et de les utiliser.
                  </Text>
                  <Text
                    category="p2"
                    style={{ fontSize: 11 }}
                  >
                    Les informations requises et marquées d’un astérisque sont obligatoires pour confirmer votre
                    inscription.
                  </Text>
                  <Text
                    category="p2"
                    style={{ fontSize: 11 }}
                  >
                    <Text
                      category="p2"
                      style={{ fontSize: 11 }}
                    >
                      Pour en savoir plus sur l’utilisation de vos données personnelles par VETELSUD et pour exercer vos
                      droits notamment vos droits d’accès, rectification et suppression, veuillez consulter la
                    </Text>
                    <Text
                      category="p2"
                      style={{ fontSize: 11 }}
                    >
                      {' '}
                    </Text>
                    <Text
                      category="p2"
                      style={{ fontSize: 11, textDecorationLine: 'underline' }}
                      onPress={() => setModalUrl('https://scanflock.com/politique-de-confidentialite/?simple=1')}
                    >
                      Politique de confidentialité
                    </Text>
                    <Text
                      category="p2"
                      style={{ fontSize: 11 }}
                    >
                      .
                    </Text>
                  </Text>
                </View>
              )}

            </View>

          </Layout>

        </Form>
        )}

        <Layout style={{ flexDirection: 'row' }} />
      </MaxWidthContainer>
    </>
  );
};

interface SignUpProps {
  error?: string
  signUp: (userInfo: {
    email: string,
    password: string,
    given_name: string,
    family_name: string,
    'custom:optIn': string,
    'custom:groupe':string

  }) => void
  goBack: () => void
  goConfirmCode: () => void
}

export default class SignUp extends AmplifySignUp {
  showComponent() {
    // @ts-expect-error : Cannot change AWS prop types
    const { setTmpPasswd } = this.props;
    // @ts-expect-error : AWS does not expose Types
    this.signUpFields = [
      {
        label: 'Firstname',
        key: 'given_name',
        required: true,
        displayOrder: 1,
        testID: TEST_ID.AUTH.PHONE_INPUT,
      },
      {
        label: 'Lastname',
        key: 'family_name',
        required: true,
        displayOrder: 2,
        testID: TEST_ID.AUTH.PHONE_INPUT,
      },
      {
        label: 'Email',
        key: 'email',
        required: true,
        type: 'email',
        displayOrder: 3,
        testID: TEST_ID.AUTH.EMAIL_INPUT,
      },
      {
        label: 'Password',
        key: 'password',
        required: true,
        type: 'password',
        displayOrder: 4,
        testID: TEST_ID.AUTH.PASSWORD_INPUT,
      },
      {
        label: 'Opt In',
        key: 'custom:optIn',
        displayOrder: 6,
      },
      {
        label: 'User Group',
        key: 'custom:groupe',
        displayOrder: 7,
      },
    ];
    return (
      <MySignUp
        error={this.state.error}
        signUp={(userInfo) => {
          // @ts-expect-error : AWS does not expose Types
          this.setState({ ...userInfo, error: null }, this.signUp);
          setTmpPasswd(userInfo.password);
        }}
        // @ts-expect-error : AWS does not expose Types
        goBack={() => this.changeState('signIn')}
        // @ts-expect-error : AWS does not expose Types
        goConfirmCode={() => this.changeState('confirmSignUp')}
      />
    );
  }
}
