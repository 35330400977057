import React, { useEffect, useState } from 'react';
import { Icon, Text, useTheme } from '@ui-kitten/components';
import { Platform, TouchableOpacity, View } from 'react-native';
import { API } from 'aws-amplify';
import { kycDocument } from 'mangopay2-nodejs-sdk/typings/models/kycDocument';
import * as DocumentPicker from 'expo-document-picker';
import { Icon as IconUIKitten } from '@ui-kitten/components/ui/icon/icon.component';
import { uboDeclaration } from 'mangopay2-nodejs-sdk/typings/models/uboDeclaration';
import { useForm } from 'react-hook-form';
import InfoCard from '../InfoCard';
import ActivityIndicator from '../../components/ActivityIndicator';
import Button from '../../components/Button';
import FileHelper from '../../utils/FileHelper';
import CustomModal from '../../components/Modals/CustomModal';
import FTextInput from '../../components/Form/TextInput';
import { AvailableValidationRules } from '../../components/Form/validation';
import Select from '../../components/Form/Select';
import { ALL_countries, EU_countries } from '../../src/mockData/selectData';
import DatepickerComp from '../../components/Form/DatePicker';
import Radio from '../../components/Form/Radio';
import DateUtils from '../../utils/DateUtils';
import Form from '../../components/Form/Form';

export const KYCInfos = () => {
  const [documentsInfos, setDocumentsInfos] = useState<[kycDocument.KycDocumentData] | false>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  let identityProof:BaseKycDocumentData = { Type: 'IDENTITY_PROOF' };
  let articlesOfAssociation:BaseKycDocumentData = { Type: 'ARTICLES_OF_ASSOCIATION' };
  let registrationProof:BaseKycDocumentData = { Type: 'REGISTRATION_PROOF' };

  useEffect(() => {
    let isLoaded = true;
    API.get('scanflockrest', '/mangopay/get-kyc-documents', {}).then((res) => {
      if (isLoaded) {
        if (res.success) {
          setDocumentsInfos(res.documentsInfo.reverse());
        } else {
          setDocumentsInfos(false);
        }
        setIsLoading(false);
      }
    });
    return () => { isLoaded = false; };
  }, []);

  console.log(documentsInfos);
  if (documentsInfos) {
    const tempIdentityProof = documentsInfos.find((item) => item.Type === 'IDENTITY_PROOF');
    if (tempIdentityProof) {
      identityProof = tempIdentityProof;
    }
    const tempArticlesOfAssociation = documentsInfos.find((item) => item.Type === 'ARTICLES_OF_ASSOCIATION');
    if (tempArticlesOfAssociation) {
      articlesOfAssociation = tempArticlesOfAssociation;
    }
    const tempRegistrationProof = documentsInfos.find((item) => item.Type === 'REGISTRATION_PROOF');
    if (tempRegistrationProof) {
      registrationProof = tempRegistrationProof;
    }
  }
  return (
    <View>
      <InfoCard
        title="alertInfo"
        iconShow="alert-circle-outline"
        description={`Dans le cadre de ses obligations pour la lutte contre le blanchiment et le financement du terrorisme, notre prestataire de paiement collecte un certain nombre d'informations pour être en conformité avec ses obligations légales. Ces informations ne sont pas stockées par Scanflock.
Vous devez fournir les informations suivantes afin de pouvoir transférer vos fonds sur votre compte bancaire.`}
        alertInfo="alertInfo"
        infoStyle={{ marginVertical: 10 }}
      />
      {isLoading ? <ActivityIndicator /> : (
        <>
          <KYCDocument
            title={"Document d'identité du responsable légal (CNI, permis ou passeport)"}
            document={identityProof}
          />
          <KYCDocument
            title="Statuts du cabinet"
            document={articlesOfAssociation}
          />
          <KYCDocument
            title="Extrait KBIS de moins de 3 mois"
            document={registrationProof}
          />
          <UBODeclaration />
        </>
      )}
    </View>
  );
};

interface BaseKycDocumentData extends Partial<kycDocument.KycDocumentData> {
  Type: kycDocument.KycDocumentType;
}

type KYCDocumentProps = {
  title: string;
  document: BaseKycDocumentData;
};

type VetoUBO = {
  firstname:string,
  lastname:string,
  address: string,
  address2: string,
  postcode: string,
  city: string,
  nationality: string,
  birthday: number,
  birthPlace: string,
  birthCountry: string
};

const KYCDocument = (props: KYCDocumentProps) => {
  const { title, document } = props;

  const theme = useTheme();
  const [docsList, setDocsList] = useState<{ [key:string]: { name:string, status:boolean } } | false>(false);
  const [error, setError] = useState<string | false>(false);
  const [finalDocument, setFinalDocument] = useState<kycDocument.KycDocumentData | BaseKycDocumentData>(document);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    let isLoaded = true;

    if (document && document.Id) {
      setFinalDocument(document);
    } else {
      API.get('scanflockrest', `/mangopay/create-kyc-document?type=${document.Type}`, {}).then((res) => {
        if (isLoaded) {
          if (res.success) {
            setFinalDocument(res.documentInfo);
          } else {
            setFinalDocument(document);
          }
        }
      });
    }
    return () => { isLoaded = false; };
  }, [document]);

  // console.log(consultInfos);
  console.log(finalDocument);

  if (!finalDocument || !finalDocument.Id || !finalDocument.Status) {
    return (
      <></>
    );
  }

  const renderStatus = (document: kycDocument.KycDocumentData) => {
    switch (document.Status) {
      case 'VALIDATION_ASKED':
        return <Text>En attente de validation</Text>;
      case 'VALIDATED':
        return <Text>Document accepté</Text>;
      case 'REFUSED':
        return (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text>
              Document refusé :
              {' '}
              {getRefusedMessage(document)}
            </Text>
            <Button
              onPress={() => {
                API.get('scanflockrest', `/mangopay/create-kyc-document?type=${document.Type}`, {}).then((res) => {
                  if (res.success) {
                    setFinalDocument(res.documentInfo);
                  } else {
                    setFinalDocument(document);
                  }
                });
              }}
              size="small"
              status="warning"
              style={{ marginLeft: 10 }}
            >
              Soumettre un nouveau document
            </Button>
          </View>
        );
    }
  };

  const renderFileAdd = () => (
    <View>
      <View>
        {docsList && Object.values(docsList).map((item, k) => (
          <Text key={item.name}>
            {item.name}
            {' '}
            {item.status ? <Icon name="checkmark-circle-outline" fill={theme['color-success-500']} style={{ height: 20, width: 20 }} /> : <ActivityIndicator size="small" /> }
          </Text>
        ))}
      </View>
      <Button
        onPress={async () => {
          setError(false);
          const pickedDocument = await DocumentPicker.getDocumentAsync({
            copyToCacheDirectory: Platform.OS !== 'android',
            type: ['image/jpeg', 'image/png', 'application/pdf'],
          });
          const newDocsList = { ...docsList };
          if (pickedDocument.type === 'success') {
            newDocsList[pickedDocument.name] = { name: pickedDocument.name, status: false };
            setDocsList(newDocsList);

            const response = await fetch(pickedDocument.uri);
            const blob = await response.blob();
            if (blob.size > 4 * 1024 * 1024) {
              setError('Votre fichier doit faire moins de 4Mo.');
              const newDocsList2 = { ...docsList };
              delete newDocsList2[pickedDocument.name];
              setDocsList(newDocsList2);
            } else {
              const base64 = await FileHelper.blobToBase64(blob);

              API.post('scanflockrest', '/mangopay/add-kyc-document-page', {
                body: {
                  documentId: finalDocument.Id,
                  file: base64,
                },
              }).then((res) => {
                if (res.success) {
                  const newDocsList2 = { ...docsList };
                  newDocsList2[pickedDocument.name] = { name: pickedDocument.name, status: true };
                  setDocsList(newDocsList2);
                } else {
                  const newDocsList2 = { ...docsList };
                  delete newDocsList2[pickedDocument.name];
                  setDocsList(newDocsList2);
                }
              });
            }
          }
        }}
        size="small"
        appearance="outline"
        style={{ width: '100%', marginBottom: 25 }}
        loading={docsList && Object.values(docsList).find(({ status }) => !status) !== undefined}
      >
        + Ajouter un document
      </Button>
      {error ? <Text status="warning" category="p1">{error}</Text> : null}
      {docsList && Object.values(docsList).find(({ status }) => status) !== undefined
        ? (
          <Button
            onPress={() => {
              setModal(true);
            }}
            style={{ width: '100%', marginBottom: 25, height: '10%' }}
          >
            Valider
          </Button>
        ) : null}
    </View>
  );

  return (
    <View style={{ marginVertical: 10 }}>
      <Text category="h3">{title}</Text>
      {finalDocument.Status === 'CREATED' ? renderFileAdd() : renderStatus(finalDocument)}
      <CustomModal
        title="Avez-vous bien transmis toutes les pages de votre document ?"
        before={<></>}
        noSafeArea
        visible={modal}
        scrollable={false}
        onClose={() => { setModal(false); }}
        insideModalStyle={{ padding: 20 }}
        buttonText="Oui, je demande la vérification de ce document"
        buttonPress={async () => {
          API.post('scanflockrest', '/mangopay/submit-kyc-document', {
            body: {
              documentId: finalDocument.Id,
            },
          }).then((res) => {
            if (res.success) {
              setFinalDocument(res.documentsInfo);
            }
            setModal(false);
          });
        }}
        secondButtonText="Annuler"
        secondButtonPress={() => {
          setModal(false);
        }}
      />
    </View>
  );
};

const getRefusedMessage = (document: kycDocument.KycDocumentData) => {
  switch (document.RefusedReasonType) {
    case 'DOCUMENT_DO_NOT_MATCH_ACCOUNT_DATA':
      return 'Ce document ne correspond pas aux données du compte.';
    case 'DOCUMENT_FALSIFIED':
      return 'Ce document semble falsifié.';
    case 'DOCUMENT_INCOMPLETE':
      return 'Ce document est incomplèt.';
    case 'DOCUMENT_MISSING':
      return 'Le document est manquant.';
    case 'DOCUMENT_UNREADABLE':
      return 'Ce document est illisible.';
    case 'DOCUMENT_HAS_EXPIRED':
      return 'Ce document a expiré.';
    case 'DOCUMENT_NOT_ACCEPTED':
      return 'Ce type de document n\'est pas accepté.';
    case 'DOCUMENT_DO_NOT_MATCH_USER_DATA':
      return 'Ce document ne correspond pas aux données de l\'utilisateur.';
    case 'UNDERAGE_PERSON':
      return 'La personne est mineure.';
    case 'SPECIFIC_CASE':
      return `Cas spécifique : ${document.RefusedReasonMessage}`;
  }
};

const getRefusedMessageUBO = (declaration: uboDeclaration.UboDeclarationData) => {
  switch (declaration.Reason) {
    case 'DOCUMENTS_NEEDED':
      return 'Des documents supplémentaires sont nécessaires, merci de vous rapprocher de VetelSud.';
    case 'UBO_IDENTITY_NEEDED':
      return 'Bénéficiaire effectif manquant.';
    case 'MISSING_UBO':
      return 'Bénéficiaire effectif manquant.';
    case 'WRONG_UBO_INFORMATION':
      return 'Informations de bénéficaires effectifs erronées.';
    case 'DECLARATION_DO_NOT_MATCH_UBO_INFORMATION':
      return 'Informations de bénéficaires effectifs erronées.';
    case 'SHAREHOLDERS_DECLARATION_NEEDED':
      return 'Une déclaration des associés de votre structure est nécessaire, merci de vous rapprocher de VetelSud';
    case 'ORGANIZATION_CHART_NEEDED':
      return 'Un organigramme de votre structure est nécessaire, merci de vous rapprocher de VetelSud.';
    case 'SPECIFIC_CASE':
      return 'Cas spécifique.';
  }
};

const UBODeclaration = () => {
  const [uboDeclarationInfos, setUboDeclarationInfos] = useState<uboDeclaration.UboDeclarationData[] | false>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [processing, setProcessing] = useState(false);
  const [modal, setModal] = useState(false);
  const vetoUBO = useForm<VetoUBO>();
  const theme = useTheme();

  let resetter;

  useEffect(() => {
    let isLoaded = true;
    API.get('scanflockrest', '/mangopay/get-ubo-declarations', {}).then((res) => {
      if (isLoaded) {
        if (res.success) {
          if (res.uboDeclarationsInfos.length <= 0) {
            API.get('scanflockrest', '/mangopay/create-ubo-declaration', {}).then((res) => {
              if (isLoaded) {
                if (res.success) {
                  setUboDeclarationInfos([res.uboDeclarationInfos]);
                } else {
                  setUboDeclarationInfos(false);
                }
                setIsLoading(false);
              }
            });
          } else {
            setUboDeclarationInfos(res.uboDeclarationsInfos.reverse());
            setIsLoading(false);
          }
        } else {
          setUboDeclarationInfos(false);
          setIsLoading(false);
        }
      }
    });
    return () => { isLoaded = false; };
  }, []);

  console.log(uboDeclarationInfos);

  const ubos: uboDeclaration.UboData[] | false = uboDeclarationInfos && (uboDeclarationInfos[0].Ubos.filter((item: uboDeclaration.UboData) => item.IsActive) as uboDeclaration.UboData[]);

  const renderStatus = (declaration: uboDeclaration.UboDeclarationData) => {
    switch (declaration.Status) {
      case 'VALIDATION_ASKED':
        return <Text>En attente de validation</Text>;
      case 'VALIDATED':
        return <Text>Document accepté</Text>;
      case 'REFUSED':
      case 'INCOMPLETE':
        return (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text>
              Décalaration refusée / incomplète :
              {' '}
              {getRefusedMessageUBO(declaration)}
            </Text>
            <Button
              onPress={() => {
                API.get('scanflockrest', '/mangopay/create-ubo-declaration', {}).then((res) => {
                  if (res.success) {
                    const newUboDeclarations = [...uboDeclarationInfos];
                    newUboDeclarations[0] = res.uboDeclarationInfos;
                    setUboDeclarationInfos(newUboDeclarations);
                  }
                });
              }}
              size="small"
              status="warning"
              style={{ marginLeft: 10 }}
            >
              Soumettre une nouvelle déclaration
            </Button>
          </View>
        );
    }
  };

  const renderAddUbo = () => (
    <View>
      {ubos && ubos.map((item) => (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Text>
            {item.FirstName}
            {' '}
            {item.LastName}
          </Text>
          <TouchableOpacity
            onPress={() => {
              API.post('scanflockrest', '/mangopay/update-ubo', {
                body: {
                  uboDeclarationId: uboDeclarationInfos[0].Id,
                  uboId: item.Id,
                },
              }).then((res) => {
                if (res.success) {
                  const newUboDeclarations = [...uboDeclarationInfos];
                  newUboDeclarations[0].Ubos = newUboDeclarations[0].Ubos
                    .map((mItem) => ({
                      ...mItem,
                      IsActive: mItem.Id === item.Id ? false : mItem.IsActive,
                    }));
                  setUboDeclarationInfos(newUboDeclarations);
                }
              });
            }}
          >
            <Icon name="trash-2-outline" fill={theme['color-primary-500']} style={{ height: 20, width: 20 }} />
          </TouchableOpacity>
        </View>
      ))}
      {!ubos || ubos.length < 4 ? (
        <Form<VetoUBO>
          {...vetoUBO}
          resetFunc={(func) => {
            resetter = func;
          }}
        >
          <>
            <View>
              <Text category="h6" appearance="hint">Ajouter un bénéficiaire effectif final</Text>
              <Text category="h6" appearance="hint">Nom *</Text>
              <FTextInput
                name="lastname"
                placeholder="Saisissez le nom"
                validators={[
                  AvailableValidationRules.required,
                ]}
              />
              <Text category="h6" appearance="hint">Prénom *</Text>
              <FTextInput
                name="firstname"
                placeholder="Saisissez le prénom"
                validators={[
                  AvailableValidationRules.required,
                ]}
              />
              <Text category="h6" appearance="hint">Adresse *</Text>
              <FTextInput
                name="address"
                placeholder="Saisissez l'adresse"
                validators={[
                  AvailableValidationRules.required,
                ]}
              />
              <Text category="h6" appearance="hint">Adresse (complément)</Text>
              <FTextInput
                name="address2"
                placeholder="Saisissez le complément d'adresse"
              />
              <Text category="h6" appearance="hint">Code postal *</Text>
              <FTextInput
                name="postcode"
                placeholder="Saisissez le code postal"
                validators={[
                  AvailableValidationRules.required,
                ]}
              />
              <Text category="h6" appearance="hint">Ville *</Text>
              <FTextInput
                name="city"
                placeholder="Saisissez la ville"
                validators={[
                  AvailableValidationRules.required,
                ]}
              />
              <Text category="h6" appearance="hint">Nationalité *</Text>
              <Select
                name="nationality"
                placeholder="Choisissez la nationalité"
                validators={[
                  AvailableValidationRules.required,
                ]}
                data={EU_countries}
              />
              <Text category="h6" appearance="hint">Date de naissance *</Text>
              <DatepickerComp
                name="birthday"
                placeholder="Saisissez la date de naissance"
                validators={[
                  AvailableValidationRules.required,
                ]}
              />
              <Text category="h6" appearance="hint">Lieu de naissance *</Text>
              <FTextInput
                name="birthPlace"
                placeholder="Saisissez le lieu de naissance"
                validators={[
                  AvailableValidationRules.required,
                ]}
              />
              <Text category="h6" appearance="hint">Pays de naissance *</Text>
              <Select
                name="birthCountry"
                placeholder="Choisissez le pays de naissance"
                validators={[
                  AvailableValidationRules.required,
                ]}
                data={ALL_countries}
              />
            </View>
            <Button
              style={{ marginTop: 10 }}
              loading={processing}
              appearance="outline"
              size="small"
              loadingText={"En cours d'enregistrement"}
              onPress={
                  vetoUBO.handleSubmit(async (data) => {
                    setProcessing(true);
                    const birthday = DateUtils.parseToDateObj(data.birthday);
                    birthday.setHours(12);
                    const realData = {
                      ...data,
                      uboDeclarationId: uboDeclarationInfos[0].Id,
                      birthday: Math.round(birthday.getTime() / 1000),
                    };
                    const resp = await API.post('scanflockrest', '/mangopay/add-ubo', { body: realData });
                    if (resp.success) {
                      const newUboDeclarations = [...uboDeclarationInfos];
                      newUboDeclarations[0] = {
                        ...newUboDeclarations[0],
                        Ubos: [...newUboDeclarations[0].Ubos, resp.uboInfos],
                      };
                      setUboDeclarationInfos(newUboDeclarations);
                    }
                    setProcessing(false);
                    if (resetter) {
                      resetter();
                    }
                  })
                }
            >
              Ajouter le bénéficiaire
            </Button>
          </>
        </Form>
      ) : null}
      {ubos && ubos.length > 0 ? (
        <>
          <Button
            onPress={() => {
              setModal(true);
            }}
            style={{ width: '100%', marginVertical: 25 }}
          >
            Valider votre liste de bénéficaires effectifs
          </Button>
          <CustomModal
            title="Avez-vous bien déclarés tous vos bénéficiaires effectifs ?"
            before={<></>}
            noSafeArea
            visible={modal}
            scrollable={false}
            onClose={() => { setModal(false); }}
            insideModalStyle={{ padding: 20 }}
            buttonText="Oui, je demande la vérification de cette déclaration"
            buttonPress={async () => {
              API.post('scanflockrest', '/mangopay/submit-ubo-declaration', {
                body: {
                  uboDeclarationId: uboDeclarationInfos[0].Id,
                },
              }).then((res) => {
                if (res.success) {
                  const newUboDeclarations = [...uboDeclarationInfos];
                  newUboDeclarations[0] = res.uboDeclarationInfos;
                  setUboDeclarationInfos(newUboDeclarations);
                }
                setModal(false);
              });
            }}
            secondButtonText="Annuler"
            secondButtonPress={() => {
              setModal(false);
            }}
          />
        </>
      ) : null}
    </View>
  );

  return (
    <View style={{ marginVertical: 10 }}>
      <Text category="h3">Bénéficiaires effectifs finaux (détenants plus de 25% du cabinet)</Text>
      {isLoading || !uboDeclarationInfos ? <ActivityIndicator /> : (
        uboDeclarationInfos[0].Status === 'CREATED' ? renderAddUbo() : renderStatus(uboDeclarationInfos[0])
      )}
    </View>
  );
};
