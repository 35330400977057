import React, { useState } from 'react';

import { Text } from '@ui-kitten/components';
import {
  StackActions,
  useLinkTo,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { Platform, View } from 'react-native';
import { useForm } from 'react-hook-form';
import { API, Auth } from 'aws-amplify';
import MaxWidthContainer from '../../../components/MaxWidthContainer';
import {
  User,
} from '../../../src/API';

import Button from '../../../components/Button';
import Select from '../../../components/Form/Select';
import { userGroup } from '../../../src/selectFormData/vetoSelectData';
import FTextInput from '../../../components/Form/TextInput';
import InfoCard from '../../../screenComponents/InfoCard';
import VetoMonCabinetScreenBL
  from '../../../screenBusinessLogic/vetoScreensBL/MonCabinetScreenBL/VetoMonCabinetScreenBL';
import {
  useCreateUser, useDeleteUser, useGetUser, useUpdateUser,
} from '../../../src/API/User';
import { useGetVetOffice, useUpdateVetOfficeMutation } from '../../../src/API/VetOffice';
import Form from '../../../components/Form/Form';
import { AvailableValidationRules } from '../../../components/Form/validation';
import CustomModal from '../../../components/Modals/CustomModal';
import { Delete } from '../../../utils/S3FileStorage';
import { useUser } from '../../../src/API/UserContext';

type VetoAjoutUtilisateur = {
  id: string,
  function: string,
  lastname: string,
  firstname: string,
  email: string,
  password: string,
  inscriptionNumberOrdreVeto: string,
};

const VetoAjoutUtilisateursScreen = (props: { header?: JSX.Element }) => {
  const linkTo = useLinkTo();
  const vetoAjoutUtilisateur = useForm<VetoAjoutUtilisateur>();
  const createUser = useCreateUser();
  const { updateVeterinaryOffice } = useUpdateVetOfficeMutation();
  const { refetchVetOffice } = useGetVetOffice();
  const route = useRoute();
  const { jwt } = useUser();
  const { header } = props;
  const navigation = useNavigation();

  let boolSignUp: boolean = false;
  if (route && route.params && (route.params?.signUp === 'true' || route.params?.signUp)) {
    boolSignUp = true;
  }

  const {
    vetOfficeData,
  } = useGetVetOffice();

  /** MODIFY */
  let currentUtilisateurs: User & { function?: string } | undefined;

  const { user } = useGetUser(route?.params?.id);
  if (user) {
    let selectFunction;
    if (vetOfficeData?.admins.includes(user.id)) {
      selectFunction = 'veterinaire';
    } else if (vetOfficeData?.personnel?.includes(user.id)) {
      selectFunction = 'personnel';
    }

    currentUtilisateurs = {
      ...user,
      function: selectFunction,
    };
  }

  // console.log('currentUtilisateurs', currentUtilisateurs);
  const { modifyUser } = useUpdateUser();

  /** CREATE */
  const confirmInscription = async (data: VetoAjoutUtilisateur) => {
    // console.log('data', data);

    let userID : string;
    let admins = vetOfficeData?.admins || [];
    let personnel = vetOfficeData?.personnel || [];
    // console.log('data:', data);

    /** MODIFY USER  */
    if (route.params && route.params.id) {
      userID = route.params.id;
      if (modifyUser && currentUtilisateurs) {
        const modifCognito = await API.post('scanflockrest', '/user-admin/update-user', {
          body: {
            'custom-jwt': jwt,
            userId: userID,
            email: data.email,
            lastname: data.lastname,
            firstname: data.firstname,
          },
        });

        // console.log(modifCognito);
        if (modifCognito.success) {
          await modifyUser({
            variables: {
              input: {
                id: userID,
                lastname: data.lastname,
                firstname: data.firstname,
                email: data.email,
                inscriptionNumberOrdreVeto: data.inscriptionNumberOrdreVeto,
                // eslint-disable-next-line no-underscore-dangle
                _version: currentUtilisateurs._version,
              },
            },
          });
          admins = admins.filter((admin) => admin !== userID) || [];
          personnel = personnel.filter((perso) => perso !== userID) || [];
        }
      }
    } else {
      /** CREATE USER */
      try {
        const { userSub: newlyCreatedUserID } = await Auth.signUp({
          username: data.email,
          password: data.password,
          attributes: {
            email: data.email, // optional
            'custom:groupe': 'veto',
            'custom:autoConfirm': '1',
            'custom:enable': boolSignUp ? '1' : '0',
            given_name: data.firstname,
            family_name: data.lastname,
            // other custom attributes
          },
        });
        if (newlyCreatedUserID) {
          userID = newlyCreatedUserID;

          await createUser.createNewUser({
            variables: {
              input: {
                id: userID,
                lastname: data.lastname,
                firstname: data.firstname,
                vetOfficeId: vetOfficeData?.id,
                inscriptionNumberOrdreVeto: data.inscriptionNumberOrdreVeto,
                userGroup: 'veto',
                optIn: false,
                email: data.email,
              },
            },
          });
        }
      } catch (exception) {
        if (exception.code === 'UsernameExistsException') {
          vetoAjoutUtilisateur.setError('email', { message: 'Un utilisateur avec cet email est déjà enregistré' }, { shouldFocus: true });
          return;
        }
      }
    }

    /** COMMON CREATE AND UPDATE
     * IMPORTANT! Must be after Cognito & Dynamo DB cruds
    */
    if (userID) {
      if (data.function === 'veterinaire') admins.push(userID);
      else if (data.function === 'personnel') personnel.push(userID);
    }
    if (vetOfficeData) {
      await updateVeterinaryOffice({
        variables: {
          input: {
            id: vetOfficeData.id,
            // eslint-disable-next-line no-underscore-dangle
            _version: vetOfficeData._version,
            admins,
            personnel,
          },
        },
      });
    }

    // console.log('newUser:', newUser);
    if (refetchVetOffice) {
      await refetchVetOffice();
    }
    vetoAjoutUtilisateur.reset();
    if (!boolSignUp) {
      VetoMonCabinetScreenBL.allerMonCompteScreen({ state: 'utilisateurs', linkTo });
    } else {
      navigation.dispatch({
        ...StackActions.push('veto-inscription-etape-3'),
        source: route.key,
      });
    }
  };

  /** DELETE USER */
  const supprimerUser = useDeleteUser();
  const [openDelete, setOpenDelete] = useState<string>();

  const deleteUtilisateurs = async () => {
    let admins;
    let personnel;
    if (currentUtilisateurs && route.params) {
      admins = vetOfficeData?.admins.filter((admin) => admin !== route.params?.id) || [];
      personnel = vetOfficeData?.personnel?.filter(
        (perso) => perso !== route.params?.id,
      ) || [];

      const deleteCognito = await API.post('scanflockrest', '/user-admin/delete-user', {
        body: {
          'custom-jwt': jwt,
          userId: route.params?.id,
        },
      });

      if (deleteCognito.success) {
        if (vetOfficeData) {
          await updateVeterinaryOffice({
            variables: {
              input: {
                id: vetOfficeData.id,
                // eslint-disable-next-line no-underscore-dangle
                _version: vetOfficeData._version,
                admins,
                personnel,
              },
            },
          });

          await supprimerUser({
            variables: {
              input: {
                id: route.params.id,
                // eslint-disable-next-line no-underscore-dangle
                _version: currentUtilisateurs._version,
              },
            },
          });
        }
      }
    }
    vetoAjoutUtilisateur.reset();
    VetoMonCabinetScreenBL.allerMonCompteScreen({ state: 'utilisateurs', linkTo });
  };

  return (
    <>
      <MaxWidthContainer
        withScrollView="keyboardAware"
        outerViewProps={{
          style: {
            padding: 20,
            marginBottom: 20,
          },
        }}
      >
        <Form<VetoAjoutUtilisateur>
          {...vetoAjoutUtilisateur}
          defaultValues={currentUtilisateurs}
        >
          <>
            {header}
            <View style={Platform.OS === 'web' && ({ flexDirection: 'row' })}>
              <View style={{ flex: 1, paddingBottom: 10 }}>
                <Text category="h6" appearance="hint">Fonction *</Text>
                <Select
                  containerStyle={{ marginTop: 0 }}
                  data={userGroup}
                  placeholder="Fonction"
                  name="function"
                  validators={[
                    AvailableValidationRules.required,
                  ]}
                />
              </View>
              <View style={[
                { flex: 1 },
                Platform.OS !== 'web' && ({ marginTop: 10 }),
                Platform.OS === 'web' && ({ marginLeft: 10 }),
              ]}
              >
                <Text category="h6" appearance="hint">Prénom *</Text>
                <FTextInput
                  name="firstname"
                  placeholder="Saisissez le prénom"
                  validators={[
                    AvailableValidationRules.required,
                  ]}
                />
              </View>
            </View>

            <View style={[
              Platform.OS === 'web' && ({ flexDirection: 'row' }),
              Platform.OS !== 'web' && ({ marginTop: 20 }),
            ]}
            >
              <View style={{ flex: 1, paddingBottom: 10 }}>
                <Text category="h6" appearance="hint">Nom *</Text>
                <FTextInput
                  name="lastname"
                  placeholder="Saisissez le nom"
                  validators={[
                    AvailableValidationRules.required,
                  ]}
                />
              </View>
              <View style={[
                { flex: 1, paddingBottom: 11 },
                Platform.OS !== 'web' && ({ marginTop: 20 }),
                Platform.OS === 'web' && ({ marginLeft: 10 }),
              ]}
              >
                <Text category="h6" appearance="hint">E-mail *</Text>
                <FTextInput
                  name="email"
                  placeholder="Saisissez l'e-mail"
                  validators={[
                    AvailableValidationRules.required,
                    AvailableValidationRules.email,
                  ]}
                />
              </View>
            </View>
            {
              vetoAjoutUtilisateur.getValues('function') === 'veterinaire' && (
                <View style={[
                  Platform.OS === 'web' && ({ flexDirection: 'row' }),
                  Platform.OS !== 'web' && ({ marginTop: 20 }),
                ]}
                >
                  <View style={[
                    { flex: 1, paddingBottom: 11 },
                    Platform.OS !== 'web' && ({ marginTop: 20 }),
                  ]}
                  >
                    <Text category="h6" appearance="hint">N° d'inscription à l'ordre des vétérinaires *</Text>
                    <FTextInput
                      name="inscriptionNumberOrdreVeto"
                      placeholder="Saisissez le numéro"
                      validators={[
                        AvailableValidationRules.required,
                      ]}
                    />
                  </View>
                </View>
              )
            }

            {(!route.params || boolSignUp)
              && (
                <View style={[
                  { flex: 1, paddingBottom: 11 },
                  Platform.OS !== 'web' && ({ marginTop: 20 }),
                ]}
                >
                  <Text category="h6" appearance="hint">Mot de passe *</Text>
                  <FTextInput
                    name="password"
                    placeholder="Définissez le mot de passe du nouvel utilisateur"
                    validators={[
                      AvailableValidationRules.password,
                      AvailableValidationRules.required,
                    ]}
                  />
                </View>
              )}

            {(!route.params || !boolSignUp) && (
            <InfoCard
              title="alertInfo"
              iconShow="alert-circle-outline"
            // iconCloseShow="close-outline"
              description="Le nouvel utilisateur recevra une invitation à télécharger l'application ScanFlock et à se connecter sur son espace lorsque son compte aura été activé par nos équipes."
              alertInfo="alertInfo"
            />
            )}

            <Button
              style={{ marginTop: 40 }}
              onPress={
                  vetoAjoutUtilisateur.handleSubmit((data) => {
                    // setLoading(true);
                    // console.log('handleSubmit data:', data);
                    confirmInscription(data);
                  })
            }
            >
              Enregistrer
            </Button>
            {route.params && !boolSignUp && (
            <Button status="danger" appearance="outline" style={{ marginTop: 40, backgroundColor: '#FFF' }} onPress={() => setOpenDelete('delete utilisateurs')}>Supprimer l'utilisateur</Button>
            )}
          </>
        </Form>
      </MaxWidthContainer>
      <CustomModal
        title={"Vous êtes sur le point de supprimer l'utilisateur."
      + '\n'
      + 'Êtes-vous sur de vouloir continuer ?'}
        before={<></>}
        noSafeArea
        visible={openDelete !== undefined}
        scrollable={false}
        onClose={() => setOpenDelete(undefined)}
        insideModalStyle={{ textAlign: 'center', padding: 20 }}
        buttonText="Supprimer"
        secondButtonText="Annuler"
        heightPercentage={Platform.OS === 'web' ? 0.36 : 0.6}
        buttonPress={() => { deleteUtilisateurs(); }}
      />
    </>
  );
};

export default VetoAjoutUtilisateursScreen;
