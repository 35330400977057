import { LinkProps } from '../../../types';

const allerUtilisateursScreen = ({ state, linkTo, id }: LinkProps) => {
  switch (state) {
    case 'details-utilisateur-personnel':
      linkTo('/veto-mon-cabinet/veto-utilisateurs/veto-details-utilisateur');
      break;
    case 'ajout-utilisateurs':
      linkTo('/veto-mon-cabinet/veto-utilisateurs/veto-ajout-utilisateurs');
      break;
    case 'modify-utilisateurs':
      linkTo(`/veto-mon-cabinet/veto-utilisateurs/veto-modify-utilisateurs/${id}`);
      break;
    default:
      break;
  }
};

export default {
  allerUtilisateursScreen,
};
