import React, { useEffect, useState } from 'react';
import { useLinkTo, useRoute } from '@react-navigation/native';

import { Text } from '@ui-kitten/components';
import MaxWidthContainer from '../../components/MaxWidthContainer';
import SearchComponent from '../../screenComponents/SearchComponent';
import { useBreedingList } from '../../src/API/Breeding';
import ElevageScreenBL from '../../screenBusinessLogic/ElevageScreen/ElevageScreenBL';
import { useUser } from '../../src/API/UserContext';
import AnimalCard from '../../screenComponents/AnimalCard';
import GoCustomCard from '../../components/GoCustomCard';
import CompButton from '../../components/Button';
import { Animals } from '../../src/API';
import { useListAnimalsByBreedinG } from '../../src/API/Animals';

const VoirPlusScreen = () => {
  const route = useRoute();
  const linkTo = useLinkTo();
  const { user } = useUser();

  // console.log('VoirPlusScreen', route.name);

  /** Switching between lots, animals and buildings sections   */
  const [state, setState] = useState();

  useEffect(() => {
    if (route.name.includes('animal')) {
      setState('animaux');
    } else if (route.name.includes('lot')) {
      setState('lots');
    } else if (route.name.includes('batiment')) {
      setState('batiments');
    }

    return () => {};
  }, [route.name]);

  const { breeding } = useBreedingList(user?.userGroup);
  // console.log('breeding: ', breeding);

  let animalsElevage: Animals[] | null;
  let fetchMoreAnimals;
  let nextTokenAnimals;
  if (breeding && breeding.id) {
    const {
      animalS, fetchMoreAnimalS,
      // refetch,
      nextToken,
    } = useListAnimalsByBreedinG(breeding.id, 3, 'cache-and-network');
    animalsElevage = animalS;
    fetchMoreAnimals = fetchMoreAnimalS;
    nextTokenAnimals = nextToken;
  }

  const [elevageList, setElevageList] = useState<
  Array<[
    [{
      id: string | undefined,
      name: string | undefined,
      numberID: string | undefined,
      lots: {
        id: string,
        numberLot: string | undefined,
      } | undefined,
    }] | undefined,

  ]>

  | Array<[
    [{
      id: string,
      nameBuilding: string | undefined,
    }] | undefined,
  ]>

  | Array<[
    [{
      id: string | undefined,
      numberLot: string | undefined,
      name?: string | undefined | null,
    }] | undefined,
  ]>
  | undefined>();

  let animals: Animals[];
  let buildings;
  let lots;
  useEffect(() => {
    ElevageScreenBL.changeElevageListData({
      breeding, animals, lots, buildings, setElevageList, state, animalsElevage,
    });

    let interval;
    // console.log('nextTokenAnimals:', nextTokenAnimals);
    if (nextTokenAnimals) {
      // console.log('next:', nextTokenAnimals);
      interval = setInterval(() => {
        // console.log('This will run every 2 second!');

        if (fetchMoreAnimals) {
          fetchMoreAnimals();
        }
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [state, animalsElevage]);
  // console.log('elevageList', elevageList);
  return (
    <MaxWidthContainer outerViewProps={{
      style: {
        // flex: 1,
        padding: 24,
      },
    }}
    >

      <CompButton
        style={{ marginTop: 10, paddingHorizontal: 5 }}
        leftIcon="plus-outline"
        leftIconFill="white"
        status="primary"
        onPress={() => ElevageScreenBL.goAjoutScreen({ state, linkTo })}
      >
        {ElevageScreenBL.getAddButtonTitle(state)}
      </CompButton>

      <Text category="h2" appearance="hint" style={{ marginTop: 20, marginBottom: 10 }}>{`Mes ${state}`}</Text>
      <SearchComponent
        data={elevageList}
        opened
        searchButton // hide button and automatic charging of data
        state={state}
        // fetchMore={fetchMoreAnimals}
        currentNextToken={nextTokenAnimals}
        keyExtractor={(item) => item.id}
        placeholder={ElevageScreenBL.placeholderTitle(state)}
        renderItem={(item) => {
          // console.log('item search component:', item.item);
          switch (state) {
            case 'animaux':
              // console.log('itemanimals', item.item);
              return (
                <AnimalCard
                  name={item.item.name}
                  numberId={`${item?.item?.numberID}`}
                  numberLot={item?.item?.lots ? (item?.item?.lots.numberLot) : ('')}
                  key={item.item.id}
                  onPress={() => {
                    ElevageScreenBL.allerDetailScreen({ state, linkTo, id: item.item.id });
                  }}
                />
              );
            case 'lots':
              // console.log('item lots:', item.item);
              return (
                <GoCustomCard
                  title1={item.item.name}
                  title2={`Numéro de lot: ${item?.item?.numberLot}`}
                  key={item.item.id}
                  linkToCustom={() => ElevageScreenBL.allerDetailScreen(
                    { state, linkTo, id: item.item.id },
                  )}
                  customStyle={{ marginHorizontal: 5 }}
                  minusSquareOutline="arrow-ios-forward"
                />
              );
            case 'batiments':
              // console.log('item buildings:', item.item);
              return (
                <GoCustomCard
                  key={item.item.id}
                  title1={`${item.item.nameBuilding}`}
                  linkToCustom={() => ElevageScreenBL.allerDetailScreen(
                    { state, linkTo, id: item.item.id },
                  )}
                  customStyle={{ marginHorizontal: 5 }}
                  minusSquareOutline="arrow-ios-forward"
                />
              );

            default:
              break;
          }
          return '';
        }}
      />
    </MaxWidthContainer>
  );
};

export default VoirPlusScreen;
