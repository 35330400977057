import React, { useEffect, useState } from 'react';

import { Text, useTheme } from '@ui-kitten/components';
import { useLinkTo } from '@react-navigation/native';
import { Platform, View } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import { ImagePickerResult } from 'expo-image-picker';
import { useForm } from 'react-hook-form';
import API from '@aws-amplify/api';
import MaxWidthContainer from '../../../components/MaxWidthContainer';

import Button from '../../../components/Button';
import Select from '../../../components/Form/Select';
import { userGroup } from '../../../src/selectFormData/vetoSelectData';
import FTextInput from '../../../components/Form/TextInput';
import VetoMonCabinetScreenBL
  from '../../../screenBusinessLogic/vetoScreensBL/MonCabinetScreenBL/VetoMonCabinetScreenBL';
import { useUser } from '../../../src/API/UserContext';
import AutoAvatar from '../../../components/AutoAvatar';
import { CameraOutput } from '../../../components/Camera/Camera';
import Form from '../../../components/Form/Form';
import { Delete, Upload } from '../../../utils/S3FileStorage';
import { useGetVetOffice, useUpdateVetOfficeMutation } from '../../../src/API/VetOffice';
import { AvailableValidationRules } from '../../../components/Form/validation';

type ModifyInfoPersoForm = {
  avatarUri: string | undefined | null
  firstname: string | undefined | null
  lastname: string | undefined | null
  email: string | undefined | null
  inscriptionNumberOrdreVeto: string | undefined | null
  fonction: string

};

const VetoMonCompteScreen = () => {
  const linkTo = useLinkTo();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  const { user, updateUser } = useUser();
  /** AVATAR PHOTO MANAGEMENT   */
  // console.log('user:', currentUser);

  const [selectedNewImage, setSelectedNewImage] = useState<
  ImagePickerResult |
  CameraOutput |
  undefined
  >();

  const [image, setImage] = useState('default::ManAvatar');

  const pickImage = async () => {
    try {
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
      });
      if (!result.cancelled) {
        setImage(result.uri);
        setSelectedNewImage(result);
      }
    } catch (e) {
      // console.log('pickImage error: ', e);
    }
  };

  // console.log('image modify info perso:', image);
  useEffect(() => {
    setImage(user?.avatarUri || 'default::ManAvatar');
  }, [user]);

  /** UPDATE USER */
  const { updateVeterinaryOffice } = useUpdateVetOfficeMutation();
  const { vetOfficeData } = useGetVetOffice('cache-and-network');
  // console.log('la nouvelle image : ', selectedNewImage);
  const onPress = async (data: ModifyInfoPersoForm) => {
    if (user && updateUser) {
      // console.log('data ModifyInfoPersoForm:', data);
      let iconUri = image;
      if (image !== user.avatarUri) {
        // console.log('passage 1');
        const toDelete = user.avatarUri && user.avatarUri.indexOf('default::') > -1
          ? undefined
          : user.avatarUri;
        // console.log('passage : ', toDelete);
        if (toDelete) {
          // console.log('passage 2');

          await Delete(toDelete);
        }
        if (selectedNewImage) {
          const upload = await Upload(selectedNewImage, `avatarUser/${user.id}/`);
          if (upload !== false) {
            iconUri = upload.key;
          }
        }
      }

      await updateUser({
        id: user.id,
        avatarUri: iconUri,
        // eslint-disable-next-line no-underscore-dangle
        _version: user._version,
        firstname: data.firstname,
        lastname: data.lastname,
        email: data.email,
        inscriptionNumberOrdreVeto: data.inscriptionNumberOrdreVeto,
      });

      if (vetOfficeData) {
        const admins = vetOfficeData.admins || [];
        admins.filter((item) => item !== user.id);
        const personnel = vetOfficeData.personnel || [];
        personnel.filter((item) => item !== user.id);

        if (data.fonction === 'veterinaire') {
          // console.log('data fonction veterinar:', data.fonction);
          admins.push(user.id);
        } else if (data.fonction === 'personnel') {
          // console.log('data fonction personnel:', data.fonction);
          personnel.push(user.id);
        }
        await updateVeterinaryOffice({
          variables: {
            input: {
              id: vetOfficeData.id,
              personnel,
              admins,
              // eslint-disable-next-line no-underscore-dangle
              _version: vetOfficeData._version,

            },
          },
        });
      }

      // navigation.navigate('mon-compte');
      setLoading(false);
      VetoMonCabinetScreenBL.allerMonCompteScreen({ state: 'mon compte', linkTo });
    }
  };
  const modifyInfoPersoForm = useForm<ModifyInfoPersoForm>();

  let fonction: string = 'veterinaire';
  if (vetOfficeData && vetOfficeData.admins.includes(user?.id)) {
    fonction = 'veterinaire';
  }
  if (vetOfficeData && vetOfficeData.personnel && vetOfficeData.personnel.includes(user?.id)) {
    fonction = 'personnel';
  }
  const defaultForms: ModifyInfoPersoForm = {
    avatarUri: user?.avatarUri,
    email: user?.email,
    firstname: user?.firstname,
    fonction,
    inscriptionNumberOrdreVeto: user?.inscriptionNumberOrdreVeto,
    lastname: user?.lastname,
  };

  return (
    <>
      <MaxWidthContainer
        withScrollView="keyboardAware"
        outerViewProps={{
          style: {
            padding: 20,
            marginBottom: 20,
          },
        }}
      >
        <Form<ModifyInfoPersoForm> {...modifyInfoPersoForm} defaultValues={defaultForms}>
          <>

            <View style={{ alignItems: 'center', flexDirection: 'row' }}>
              <AutoAvatar
                avatarInfo={image}
                style={{
                  height: 94,
                  width: 94,
                  marginBottom: 10,
                  borderRadius: 50,
                  overflow: 'hidden',
                  marginLeft: 6.5,

                }}
              />
              <View>
                <Button
                  status="primary"
                  appearance="ghost"
                  leftIcon="edit-outline"
                  leftIconFill={theme['color-primary-500']}
                  onPress={() => { pickImage(); }}
                >
                  Modifier la photo
                </Button>
                {image.indexOf('default::') <= -1 && (
                <Button
                  status="primary"
                  leftIcon="trash-2-outline"
                  leftIconFill={theme['color-primary-500']}
                  appearance="ghost"
                  onPress={() => {
                    setImage('default::ManAvatar');
                    // setSelectedNewImage(undefined);
                  }}
                >
                  Supprimer la photo
                </Button>
                )}
              </View>

            </View>

            <View style={Platform.OS === 'web' && ({ flexDirection: 'row' })}>
              <View style={{ flex: 1, paddingBottom: 10 }}>
                <Text category="h6" appearance="hint">Fonction *</Text>
                <Select
                  data={userGroup}
                  placeholder="Fonction"
                  name="fonction"
                  disabled
                  validators={[
                    AvailableValidationRules.required,
                  ]}
                />
              </View>
              <View style={[
                { flex: 1 },
                Platform.OS !== 'web' && ({ marginTop: 10 }),
                Platform.OS === 'web' && ({ marginLeft: 10 }),
              ]}
              >
                <Text category="h6" appearance="hint" style={{ paddingBottom: 7 }}>Prénom *</Text>
                <FTextInput
                  name="firstname"
                  placeholder="Saisissez le prénom"
                  validators={[
                    AvailableValidationRules.required,
                  ]}
                />
              </View>
            </View>

            <View style={[
              Platform.OS === 'web' && ({ flexDirection: 'row' }),
              Platform.OS !== 'web' && ({ marginTop: 20 }),
            ]}
            >
              <View style={{ flex: 1, paddingBottom: 10 }}>
                <Text category="h6" appearance="hint">Nom *</Text>
                <FTextInput
                  name="lastname"
                  placeholder="Saisissez le nom"
                  validators={[
                    AvailableValidationRules.required,
                  ]}
                />
              </View>
              <View style={[
                { flex: 1, paddingBottom: 11 },
                Platform.OS !== 'web' && ({ marginTop: 20 }),
                Platform.OS === 'web' && ({ marginLeft: 10 }),
              ]}
              >
                <Text category="h6" appearance="hint">E-mail *</Text>
                <FTextInput
                  name="email"
                  placeholder="Saisissez l'e-mail'"
                  validators={[
                    AvailableValidationRules.required,
                    AvailableValidationRules.email,
                  ]}
                />
              </View>
            </View>
            {modifyInfoPersoForm.getValues('fonction') === 'veterinaire'
              ? (
                <View style={[
                  { flex: 1, paddingVertical: 11 },
                  Platform.OS !== 'web' && ({ marginTop: 20 }),
                ]}
                >
                  <Text category="h6" appearance="hint">Numéro d'inscription à l'ordre des vétérinaires *</Text>
                  <FTextInput
                    name="inscriptionNumberOrdreVeto"
                    placeholder={"Saisissez numéro d'inscription"}
                    validators={[
                      AvailableValidationRules.required,
                    ]}
                  />
                </View>
              ) : null}

            <Text category="c1" appearance="hint" style={{ marginTop: 40 }}>* champs obligatoire</Text>
            {/** Add update graphql API */}
            <Button
              loading={loading}
              loadingText="Chargement"
              onPress={modifyInfoPersoForm.handleSubmit(
                (data) => {
                  setLoading(true);
                  onPress(data);
                },
              )}
              disabled={user === null}
              style={{ marginTop: 40 }}
            >
              Enregistrer
            </Button>

            <Button
              leftIcon="edit-outline"
              leftIconFill={theme['color-info-500']}
              appearance="outline"
              style={{ marginTop: 20 }}
              onPress={() => linkTo(`/veto-mon-cabinet/veto-mon-compte/veto-modifier-mon-compte/veto-modify-password/${user?.id}`)}
            >
              Modifier le mot de passe
            </Button>
          </>
        </Form>
      </MaxWidthContainer>

    </>
  );
};

export default VetoMonCompteScreen;
