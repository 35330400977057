import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { DocumentNode } from 'apollo-link';
import { WatchQueryFetchPolicy } from 'apollo-client/core/watchQueryOptions';
import {
  Address,
  BillingHistory,
  ListBillingHistoriesByTypeQuery,
  ListBillingHistoriesByTypeQueryVariables,
  ListInvoicesQueryVariables,
  ModelSortDirection,
} from '../API';

export type ListInvoicesQuery = {
  listBillingHistoriesByType?: {
    __typename: 'ModelBillingHistoryConnection',
    items: Array< BillingHistory | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export function useListInvoices(fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network') {
  const listInvoicesQuery = <DocumentNode>gql(`
  query ListBillingHistoriesByType(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBillingHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBillingHistoriesByType(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        vetOfficeId
        vetOffice {
          companyName
          address {
            address
            additionalAddress
            postalCode
            city
          }
        }
        breedingId
        breeding {
          companyName
          address {
            address
            additionalAddress
            postalCode
            city
          }
        }
        createdAt
        invoiceNumber
        amount
        label
        paid
        type
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
    }
  }
`);

  const {
    loading, data, refetch, subscribeToMore, fetchMore,
  } = useQuery<
  ListInvoicesQuery, ListBillingHistoriesByTypeQueryVariables
  >(listInvoicesQuery, {
    fetchPolicy,
    variables: {
      type: 'invoice',
      sortDirection: ModelSortDirection.DESC,
      limit: 50,
    },
  });
  return {
    loading, invoices: data?.listBillingHistoriesByType?.items, refetch, subscribeToMore, fetchMore, nextToken: data?.listBillingHistoriesByType?.nextToken,
  };
}
