/**
 * Liste des CustomHook
 *
 * @author: David Buch
 */
import {
  ComponentProps,
  DependencyList, EffectCallback, ForwardedRef, useCallback, useEffect, useRef, useState,
} from 'react';
import { useDimensions } from '@react-native-community/hooks';
import {
  Platform, ScaledSize, View,
} from 'react-native';
import NetInfo from '@react-native-community/netinfo';
import * as ScreenOrientation from 'expo-screen-orientation';
import { Orientation } from 'expo-screen-orientation/src/ScreenOrientation.types';

export const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useHasChanged = <T extends unknown>(value: T): boolean => {
  const prevVal = usePrevious<T>(value);
  return prevVal !== value;
};

export const useUpdateEffect = (effect: EffectCallback, dependencies: DependencyList = []): void => {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      effect();
    }
  }, dependencies);
};

export const useIsTablet = (breakpoint = 800) => {
  const { window } = useDimensions();
  return window.width > breakpoint;
};

export const useIsIphoneX = () => {
  const { window } = useDimensions();

  const iPhoneXHeight = 812;
  const iPhoneXrHeight = 896;

  const checkMaxSize = (dim: ScaledSize, size: number) => {
    const toCheck = dim.height > dim.width ? dim.height : dim.width;
    return toCheck == size;
  };

  return (
    Platform.OS === 'ios' && (checkMaxSize(window, iPhoneXHeight) || checkMaxSize(window, iPhoneXrHeight))
  );
};

export const useNetworkInfo = () => {
  const [network, setNetwork] = useState<boolean | undefined | null>();

  useEffect(() => NetInfo.addEventListener((state) => {
    setNetwork(state.isInternetReachable);
  }), []);

  return network;
};

export const useForwardedRef = <T>(ref: ForwardedRef<T>) => {
  const innerRef = useRef<T>(null);
  useEffect(() => {
    if (!ref) return;
    if (typeof ref === 'function') {
      ref(innerRef.current);
    } else {
      ref.current = innerRef.current;
    }
  });

  return innerRef;
};

export function useLayout() {
  const [layout, setLayout] = useState({
    height: 0,
  });
  const onLayout: ComponentProps<typeof View>['onLayout'] = ({
    nativeEvent,
  }) => {
    setLayout(nativeEvent.layout);
  };

  return [layout, onLayout] as const;
}

export function useFunction(callback) {
  const ref = useRef();
  ref.current = callback;

  return useCallback(function () {
    const currentCallback = ref.current;
    if (typeof currentCallback === 'function') {
      return currentCallback.apply(this, arguments);
    }
  }, []);
}

export function useScreenOrientation() {
  const [orientation, setOrientation] = useState<Orientation>(Orientation.UNKNOWN);

  useEffect(() => {
    ScreenOrientation.getOrientationAsync().then((d) => setOrientation(d));
    const subscription = ScreenOrientation.addOrientationChangeListener((d) => {
      setOrientation(d.orientationInfo.orientation);
    });
    return () => {
      ScreenOrientation.removeOrientationChangeListener(subscription);
    };
  }, []);

  return orientation;
}

type OnUpdateCallback<T> = (s: T) => void;
type SetStateUpdaterCallback<T> = (s: T) => T;
type SetStateAction<T> =
    (newState: T | SetStateUpdaterCallback<T>, callback?: OnUpdateCallback<T>) => void;

export function useCustomState<T>(init: T): [T, SetStateAction<T>];
export function useCustomState<T = undefined>(init?: T):
[T | undefined, SetStateAction<T | undefined>];
export function useCustomState<T>(init: T): [T, SetStateAction<T>] {
  const [state, setState] = useState<T>(init);
  const cbRef = useRef<OnUpdateCallback<T>>();

  const setCustomState: SetStateAction<T> = (newState, callback?): void => {
    cbRef.current = callback;
    setState(newState);
  };

  useEffect(() => {
    if (cbRef.current) {
      cbRef.current(state);
    }
    cbRef.current = undefined;
  }, [state]);

  return [state, setCustomState];
}
