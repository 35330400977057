import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import * as React from 'react';

import { SafeAreaView } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useDimensions } from '@react-native-community/hooks';
import { BottomNavigationTab, useTheme } from '@ui-kitten/components';
import { VetoBottomTabParamList } from '../types';

import { BottomNavigation } from '../components/UIKittenRewrite/BottomNavigation';
import { getStackInfos, useResetLinkTo } from './Utils';

import AcceuilIcon from '../assets/svg_icones_ScanFlock/rectan_square.svg';
import Cow from '../assets/svg_icones_ScanFlock/cow.svg';
import Consultation from '../assets/svg_icones_ScanFlock/doctor.svg';
import TeleConsultation from '../assets/svg_icones_ScanFlock/screenIcon.svg';
import Bse from '../assets/svg_icones_ScanFlock/paper_pen.svg';

import VetoTableauDeBordScreen from '../screens/VetScreens/TableauDeBordScreen/VetoTableauDeBord';
import VetoBilanSanitaireStackNavigator from './VetNavigators/VetoBilanSanitaireStackNavigator';
import VetoTeleconsultationStackNavigator from './VetNavigators/VetoTeleconsultationStackNavigator';
import VetoExamenStackNavigator from './VetNavigators/VetoExamenStackNavigator';
import VetoElevageStackNavigator from './VetNavigators/VetoElevageStackNavigator';
import VetoLinkingConfiguration from './VetoLinkingConfiguration';

const HomeIcon = (props) => {
  const { style } = props;
  return <AcceuilIcon height={20} width={20} fill={style.tintColor} />;
};

const CowIcon = (props) => {
  const { style } = props;
  return <Cow height={20} width={20} fill={style.tintColor} />;
};

const ConsultationIcon = (props) => {
  const { style } = props;
  return <Consultation height={20} width={20} fill={style.tintColor} />;
};

const TeleConsultationIcon = (props) => {
  const { style } = props;
  return <TeleConsultation height={20} width={20} fill={style.tintColor} />;
};

const BseIcon = (props) => {
  const { style } = props;
  return <Bse height={20} width={20} fill={style.tintColor} />;
};

// create type Props for props. Do Not leave any props

const BottomTabBar = ({ navigation, state, visible }: any) => {
  const theme = useTheme();
  const linkTo = useResetLinkTo(VetoLinkingConfiguration.config);

  return (
    <SafeAreaView style={{ backgroundColor: theme['color-basic-100'] }}>
      <BottomNavigation
        style={{ marginHorizontal: 2 }}
        selectedIndex={state.index}
        visible={visible}
        onSelect={(index) => {
          switch (index) {
            case 0:
              linkTo('/veto-tableau-de-bord');
              break;
            case 1:
              linkTo('/veto-elevage');
              break;
            case 2:
              linkTo('/veto-examen');
              break;
            case 3:
              linkTo('/veto-teleconsultation');
              break;
            case 4:
              linkTo('/veto-bse');
              break;
          }
        }}
      >
        <BottomNavigationTab
          title="Accueil"
          icon={HomeIcon}
        />
        <BottomNavigationTab
          title="Elevages"
          icon={CowIcon}
        />
        <BottomNavigationTab
          title="Consultations"
          icon={ConsultationIcon}
        />
        <BottomNavigationTab
          title="Téléconsult."
          icon={TeleConsultationIcon}
        />
        <BottomNavigationTab
          title="BSE"
          icon={BseIcon}
        />
      </BottomNavigation>
    </SafeAreaView>
  );
};

const BottomTab = createBottomTabNavigator<VetoBottomTabParamList>();

export default function VetoBottomTabNavigator() {
  const navigation = useNavigation();
  const { window } = useDimensions();
  return (
    <BottomTab.Navigator
      initialRouteName="veto-tableau-de-bord"
      tabBar={
        (props) => {
          const { showBack } = getStackInfos(navigation.getState());
          return (
            <BottomTabBar
              {...props}
              visible={!(showBack || window.width > 780)}
              style={{ margin: 100 }}
            />
          );
        }
      }
      screenOptions={{ headerShown: false }}
    >
      <BottomTab.Screen
        name="veto-tableau-de-bord"
        component={VetoTableauDeBordScreen}
      />
      <BottomTab.Screen
        name="veto-elevage-nav"
        component={VetoElevageStackNavigator}
      />
      <BottomTab.Screen
        name="veto-examen-nav"
        component={VetoExamenStackNavigator}
      />
      <BottomTab.Screen
        name="veto-teleconsultation-nav"
        component={VetoTeleconsultationStackNavigator}
      />
      <BottomTab.Screen
        name="veto-bse-nav"
        component={VetoBilanSanitaireStackNavigator}
      />
    </BottomTab.Navigator>
  );
}
