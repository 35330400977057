import { useLinkTo, useRoute } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { useTheme, Text, Spinner } from '@ui-kitten/components';
import { Platform, View } from 'react-native';
import * as Sharing from 'expo-sharing';
import * as FileSystem from 'expo-file-system';
import { Storage } from 'aws-amplify';
import moment from 'moment';
import DetailsScreensHeaderCard from '../../screenComponents/DetailsScreensHeaderCard';
import MaxWidthContainer from '../../components/MaxWidthContainer';
import InfoCard from '../../screenComponents/InfoCard';
import Button from '../../components/Button';
import VetoDetailsConsultationScreenBL
  from '../../screenBusinessLogic/vetoScreensBL/VetoConsultationScreenBL/VetoDetailsConsultationScreenBL';
import { useGetBse, useUpdateBseMutation } from '../../src/API/BSE';
import DateUtils from '../../utils/DateUtils';
import PlanifierTeleconsultationModal from '../../components/Modals/PlanifierTeleconsultationModal';
import CustomModal from '../../components/Modals/CustomModal';
import { StatusBse } from '../../src/API';
import GoCustomCard from '../../components/GoCustomCard';
import PSCard from '../../screenComponents/VetScreens/BSEComps/PSCard';
import { useGetProtocolSoinSaveByBseId } from '../../src/API/ProtocolSoin';
import { waitingDirectory } from '../../utils/S3FileStorage';

import DownloadFile from '../../utils/DownloadFile';

const VetoDetailsBseScreen = () => {
  const linkTo = useLinkTo();
  const theme = useTheme();
  const route = useRoute();
  const { bse } = useGetBse(route.params.id);
  const { psSave, loading } = useGetProtocolSoinSaveByBseId(route.params.id || undefined);
  // console.log('bse : ', bse);

  const [bseModal, setBseModal] = useState(false);
  const [startBSEModal, setStartBSEModal] = useState(false);
  const { updateBse } = useUpdateBseMutation();

  useEffect(() => {
    if (bse && bse.StatusBse === StatusBse.BSEStarted) {
      setStartBSEModal(false);
      linkTo(`/veto-bse/bse-questionnaire/${bse.id}`);
    }
    if (bse && bse.StatusBse === StatusBse.BSEProtocolSoins) {
      setStartBSEModal(false);
      linkTo(`/veto-bse/veto-protocol-soins/${bse.id}`);
    }
    return () => {

    };
  }, [bse]);

  const startBSE = async () => {
    if (bse.StatusBse === StatusBse.BSEStarted) {
      linkTo(`/veto-bse/bse-questionnaire/${bse.id}`);
    } else if (bse.StatusBse === StatusBse.BSEProtocolSoins) {
      linkTo(`/veto-bse/veto-protocol-soins/${bse.id}`);
    } else {
      setStartBSEModal(true);
      await updateBse({
        variables: {
          input: {
            id: bse.id,
            StatusBse: StatusBse.BSEAskStart,
            // eslint-disable-next-line no-underscore-dangle
            _version: bse._version,
          },
        },
      });
    }
  };

  return (
    <MaxWidthContainer
      withScrollView="keyboardAware"
      outerViewProps={{
        style: {
          padding: 20,
        },
      }}
    >
      {bse && (

      <>
        <DetailsScreensHeaderCard
          title1="Date"
          titleInfo1={bse.dateBSE ? DateUtils.convertDate(bse.dateBSE) : 'A Définir'}
          title2="Heure"
          titleInfo2={bse.dateBSE ? DateUtils.convertTime(bse.dateBSE) : 'A Définir'}
          title3={"N° d'identification d'élevage"}
          titleInfo3={bse.breeding.nBreeding}
          title4="Nom de l'élevage"
          titleInfo4={bse.breeding.companyName}
          title5="Statut du BSE"
          titleInfo5={bse.StatusBse === StatusBse.BSEFinish ? 'BSE Réalisé' : 'En attente'}
          buttonText="Accéder à l'élevage"
          customLinkTo={() => {
            VetoDetailsConsultationScreenBL.linkToSwitch({ state: 'acceder-a-elevage', linkTo, id: bse.breeding.id });
          }}
        />

        {bse.StatusBse !== StatusBse.BSEFinish
          ? (
            <>
              <InfoCard
                title="alertInfo"
                iconShow="alert-circle-outline"
        // iconCloseShow="close-outline"
                description={"L'éleveur vous a envoyé une demande de réalisation du BSE."
                + ' Vous pouvez confirmer la demande et fixer la date du rendez-vous.'}
                alertInfo="alertInfo"
              />
              {/** Open modal Planifier BSE */}
              {bse && bse.dateBSE ? (
                <>
                  <Button style={{ marginTop: 10 }} onPress={() => startBSE()}>
                    {bse.StatusBse === StatusBse.BSEStarted || bse.StatusBse === StatusBse.BSEProtocolSoins ? 'Continuer' : 'Commencer'}
                    {' '}
                    le Bilan Sanitaire d'Élevage
                  </Button>
                  <Button status="info" appearance="outline" leftIcon="edit-outline" leftIconFill={theme['color-info-500']} onPress={() => setBseModal(true)} style={{ marginTop: 10 }}>Modifier le rendez-vous BSE</Button>

                </>
              ) : (
                <Button
                  leftIcon="calendar-outline"
                  leftIconFill="white"
                  onPress={() => setBseModal(true)}
                >
                  Planifier le Bilan Sanitaire d'Élevage
                </Button>
              )}
              <PlanifierTeleconsultationModal
                title="Planifier le BSE"
                before={<></>}
                data={{
                  id: route.params?.id,
                  _version: bse?._version,
                  date: bse?.dateBSE,
                  type: 'bse',
                }}
                noSafeArea
                visible={bseModal}
                scrollable={false}
                onClose={() => { setBseModal(false); }}
                insideModalStyle={{ padding: 20 }}
                buttonText="Confirmer"
              />

              {/* <Text category="h2" appearance="hint" style={{ marginTop: 40 }}>Prébilan</Text>
        <Text category="h6">Consultez les réponses données par l'élevage lors du questionnaire de prébilan.</Text>

        <GoCustomCard title1="Prébilan" linkToCustom={() => { VetoBseScreenBL.linkToSwitch({ state: 'prebilan', linkTo }); }} /> */}

              <CustomModal
                title={'Vous venez de demander le démarrage du BSE.\n'
            + 'Ne fermez pas et veuillez attendre que l\'éleveur confirme le démarrage.'}
                before={<></>}
                noSafeArea
                visible={startBSEModal}
                scrollable={false}
                onClose={async () => {
                  setStartBSEModal(false);
                  await updateBse({
                    variables: {
                      input: {
                        id: bse.id,
                        StatusBse: StatusBse.BSEAsk,
                        // eslint-disable-next-line no-underscore-dangle
                        _version: bse._version,
                      },
                    },
                  });
                }}
                insideModalStyle={{ padding: 20 }}
                buttonText="Annuler le BSE"
                buttonPress={async () => {
                  setStartBSEModal(false);
                  await updateBse({
                    variables: {
                      input: {
                        id: bse.id,
                        StatusBse: StatusBse.BSEAsk,
                        // eslint-disable-next-line no-underscore-dangle
                        _version: bse._version,
                      },
                    },
                  });
                }}
              />
            </>
          ) : (
            <>
              {bse.crS3Key ? (
                <>
                  <Text category="h2">Compte rendu du BSE</Text>
                  <GoCustomCard
                    title1="Compte rendu du BSE"
                    title2={new Date(bse?.dateBSE).getFullYear() || 'Année du BSE'}
                    linkToCustom={async () => {
                      if (bse.crS3Key) {
                        try {
                          const url = await Storage.get(bse.crS3Key);
                          if (Platform.OS === 'web') {
                            window.open(url as string);
                          } else {
                            const directory = `${FileSystem.cacheDirectory + waitingDirectory}temp/`;

                            try {
                              await FileSystem.makeDirectoryAsync(
                                directory,
                                { intermediates: true },
                              );
                            } catch (e) {
                              console.log('Document Component error : ', e);
                            }

                            const name = `Compte_rendu_BSE_${moment(bse.dateBSE).format('YYYY-MM-DD')}.pdf`;

                            const res = await FileSystem.downloadAsync(
                              url as string,
                              FileSystem.documentDirectory + encodeURIComponent(name.replace(/\//g, '_')),
                            );
                            console.log(res);

                            if (Platform.OS === 'ios') {
                              await Sharing.shareAsync(res.uri);
                              await FileSystem.deleteAsync(res.uri);
                            } else {
                              const folder = 'scanflock';
                              const test = await DownloadFile.downloadFilesAsync([{ url: res.uri, name: name.replace(/\//g, '_'), mimeType: res.headers['Content-Type'] }], folder, () => {});
                            }
                          }
                        } catch (e) {
                          console.log('Document Component error : ', e);
                        }
                      }
                    }}
                    minusSquareOutline="download-outline"
                  />
                </>
              ) : null}

              <Text category="h2" style={{ marginTop: 40 }}>Protocoles de soins</Text>
              <Text category="h4">
                Consultez les protocoles de soin que vous avez édités lors de ce Bilan Sanitaire d'Élevage.
              </Text>
              {loading ? (
                <View style={{
                  flex: 1,
                  flexDirection: 'row',
                  alignContent: 'center',
                  justifyContent: 'center',
                  padding: 5,
                }}
                >
                  <Spinner />
                  <Text style={{ marginLeft: 10 }}>Chargement des protocoles de soins en cours</Text>
                </View>
              )
                : psSave?.map((ps) => (
                  <PSCard key={ps.idDiseaseFamily} bse={bse} idMaladiePS={ps.idDiseaseFamily} notEdit />
                ))}
            </>
          )}
      </>
      )}
    </MaxWidthContainer>
  );
};

export default VetoDetailsBseScreen;
