import { Text } from '@ui-kitten/components';
import { Icon as IconUIKitten } from '@ui-kitten/components/ui/icon/icon.component';
import React from 'react';
import {
  StyleProp, View, ViewStyle,
} from 'react-native';
import FCard from './Card';
import AutoAvatar from './AutoAvatar';

export type GoCustomCardProps = {
  userCard?: string,
  userAvatar?: string | undefined | null,
  title1: string | undefined | null,
  title2?: string,
  title3?: string,
  titleEmail?: string | undefined | null,
  titleFonction?: string,
  iconForward?: string,
  linkToCustom: () => void
  customStyle?: StyleProp<ViewStyle>
  minusSquareOutline?: string
};

const GoCustomCard = ({
  userCard,
  title1, title2,
  title3, // iconForward,
  titleEmail, titleFonction,
  customStyle,
  linkToCustom,
  minusSquareOutline,
  userAvatar,
}: GoCustomCardProps) => (
  <FCard
    onPress={linkToCustom}
    style={[{
      marginVertical: 10, paddingVertical: 20, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between',
    }, customStyle]}
  >
    {userCard === 'userCard' && (
    <AutoAvatar
          // source={require('../assets/test.png')}
      style={{
        height: 40,
        width: 40,
        borderRadius: 50,
        marginRight: 5,
        marginLeft: 8,
        // overflow: 'hidden',
      }}
      avatarInfo={userAvatar || 'default::ManAvatar'}
    />
    )}
    <View style={{
      flex: 2, flexDirection: 'column', overflow: 'hidden', marginLeft: userCard === 'userCard' ? 10 : 0,
    }}
    >
      {title1 && (
        <Text
          status={minusSquareOutline === 'arrow-ios-forward' ? 'basic' : ''}
          category={userCard === 'userCard' ? ('h6') : ('h3')}
          appearance={userCard === 'userCard' ? ('hint') : ('default')}
        >
          {title1}
        </Text>
      )}

      {title2 && (
      <Text category="h6" appearance={userCard === 'userCard' ? ('default') : ('hint')}>{title2}</Text>
      )}
      {title3 && (
      <Text status={minusSquareOutline === 'arrow-ios-forward' ? 'primary' : ''} category="h6">{title3}</Text>
      )}
    </View>
    {userCard === 'userCard' && (
    <View style={{ flex: 2, flexDirection: 'row' }}>
      <View style={{
        flex: 3, flexDirection: 'column', overflow: 'hidden', borderLeftWidth: 1, borderLeftColor: '#b5b5b5', paddingHorizontal: 5,
      }}
      >
        <Text category="h6" appearance="hint">E-mail</Text>
        <Text category="h6">{titleEmail}</Text>
      </View>
      {titleFonction ? (
        <View style={{
          flex: 1, flexDirection: 'column', overflow: 'hidden', borderLeftWidth: 1, borderLeftColor: '#b5b5b5', paddingLeft: 5,
        }}
        >
          <Text category="h6" appearance="hint">Fonction</Text>
          <Text category="h6">{titleFonction}</Text>
        </View>
      ) : null}
    </View>
    )}
    {minusSquareOutline === 'trash-2-outline' && (
    <IconUIKitten
      name={minusSquareOutline === 'trash-2-outline' ? 'trash-2-outline' : 'arrow-ios-forward'}
      fill="#000"
      style={{
        height: 24, width: 24,
      }}
    />
    )}
    {minusSquareOutline === 'plus-square-outline' && (
    <IconUIKitten
      name={minusSquareOutline === 'plus-square-outline' ? 'plus-square-outline' : 'arrow-ios-forward'}
      fill="#000"
      style={{
        height: 24, width: 24,
      }}
    />
    )}
    {minusSquareOutline === 'arrow-ios-forward' && (
    <IconUIKitten
      name="arrow-ios-forward"
      fill="#000"
      style={{
        height: 24, width: 24,
      }}
    />
    )}
    {minusSquareOutline === 'download-outline' && (
      <IconUIKitten
        name="download-outline"
        fill="#000"
        style={{
          height: 24, width: 24,
        }}
      />
    )}
    {minusSquareOutline === 'arrow-right-outline' && (
      <IconUIKitten
        name="arrow-right-outline"
        fill="#000"
        style={{
          height: 24, width: 24,
        }}
      />
    )}

  </FCard>
);

export default GoCustomCard;
