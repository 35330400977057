import { Icon as IconUIKitten } from '@ui-kitten/components/ui/icon/icon.component';
import React, { useEffect, useState } from 'react';
import { Text, useTheme } from '@ui-kitten/components';

import { View } from 'moti';
import {
  TouchableOpacity, TouchableWithoutFeedback, Animated, Easing,
} from 'react-native';
import { useAnimation } from './AnimationBusinessLogic';

export type CustomRatingBarProps = {
  onRate?: (value: number) => void;
  initialRating?: number
};

const CustomRatingBar = (props: CustomRatingBarProps) => {
  const { onRate, initialRating = 4 } = props;
  const theme = useTheme();

  const [defaultRating, setDefaultRating] = useState(initialRating);
  const [maxRating] = useState([1, 2, 3, 4, 5]);

  useEffect(() => {
    setDefaultRating(initialRating);
    if (onRate) {
      onRate(initialRating);
    }
    return () => {
    };
  }, [initialRating]);

  const [starColor] = useState(theme['color-primary-300']);

  return (
    <View
      style={{
        marginVertical: 10,
        padding: 20,
        alignItems: 'center',
        overflow: 'hidden',
        flex: 1,
        maxHeight: 150,
      }}
      // animate={{ scale: 1 translateY: 5 }}
    >
      <Text category="h3" style={{ flex: 1, textAlign: 'center' }}>Donnez-nous votre avis sur cet auto-examen</Text>
      <View style={{ flex: 1, marginTop: 10, flexDirection: 'row' }}>
        {maxRating.map((item) => (
          <TouchableOpacity
            key={item}
            activeOpacity={0.7}
            onPress={() => {
              setDefaultRating(item);
              if (onRate) {
                onRate(item);
              }
            }}
          >
            <IconUIKitten
              name={item <= defaultRating ? 'star' : 'star-outline'}
              fill={starColor}
              style={{
                height: 45, width: 45, marginRight: 5,
              }}
            />
          </TouchableOpacity>
        ))}
      </View>
    </View>

  );
};

export default CustomRatingBar;
