/**
 * Drawer navigation
 *
 * @author: Shynggys UMBETOV
 */

import React, { useContext } from 'react';
import { createDrawerNavigator } from '@react-navigation/drawer';

import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useNavigationState } from '@react-navigation/native';
import { useTheme } from '@ui-kitten/components';

import BottomTabNavigator from './BottomTabNavigator';
import CustomDrawer from './CustomDrawer';

import HeaderBurger from '../components/Header/HeaderBurger';
import HeaderBack from '../components/Header/HeaderBack';

import { getStackInfos, getTitleFromName } from './Utils';
import MonCompteStackNavigator from './Navigators/MonCompteStackNavigator';
import FaqScreen from '../screens/FaqScreen/FaqScreen';
import NotificationsStackNavigator from './Navigators/NotificationsStackNavigator';
import BilanSanitaireStackNavigator from './Navigators/BilanSanitaireStackNavigator';
import { GlobalStateContext } from '../utils/GlobalStateContext';

const Drawer = createDrawerNavigator();

const MainDrawerNavigator = () => {
  const insets = useSafeAreaInsets();
  const theme = useTheme();

  const navigationState = useNavigationState((state) => state);

  const DrawerPress = (showBack: boolean, currentRouteName : string, focusedRoute?) => {
    const { setState } = useContext(GlobalStateContext);

    let onPress;
    if ((currentRouteName === 'questionnaire' && focusedRoute.params.update !== 'false')
        || (currentRouteName === 'prebilan-questionnaire' && focusedRoute.params.allCheck !== 'true')) {
      onPress = () => {
        setState(true);
      };
    }

    return showBack ? <HeaderBack onPress={onPress} /> : <HeaderBurger />;
  };

  return (

    <Drawer.Navigator
      drawerContent={(props) => <CustomDrawer {...props} />}
      initialRouteName="TableauDeBordDrawer"
      screenOptions={() => {
        const { showBack, currentRouteName, focusedRoute } = getStackInfos(navigationState);
        let title = getTitleFromName(currentRouteName);
        if (currentRouteName === 'questionnaire') {
          title = 'Quitter le questionnaire';
        }
        if (currentRouteName === 'prebilan-questionnaire') {
          title = 'Quitter le Pré-bilan';
        }
        // console.log('currentRouteName', currentRouteName);
        return {
          title,
          headerLeft: () => DrawerPress(showBack, currentRouteName, focusedRoute),
          /**
          headerRight: () => (
            <HeaderLogo />
          ),
           */
          headerStyle: {
            height: 70 + insets.top,
            backgroundColor: theme['color-primary-500'],
          },
          headerShown: true,
          headerTintColor: '#fff',
          headerTitleStyle: { fontFamily: 'sourceSansPro_Bold' },
          gestureEnabled: false,
          swipeEdgeWidth: 0,
        };
      }}

    >
      <Drawer.Screen
        name="bottom-tab-nav"
        component={BottomTabNavigator}
      />
      <Drawer.Screen
        name="mon-compte-nav"
        component={MonCompteStackNavigator}
      />
      <Drawer.Screen
        name="bilan-sanitaire-nav"
        component={BilanSanitaireStackNavigator}
      />
      <Drawer.Screen
        name="notifications-nav"
        component={NotificationsStackNavigator}
      />
      <Drawer.Screen
        name="faq"
        component={FaqScreen}
      />

    </Drawer.Navigator>
  );
};

export default MainDrawerNavigator;
