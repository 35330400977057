export default {
  'D)1)1.1/': [{
    poser: false, imposer: false, interdire: true, cible: 'D)1)6.8/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)1)10.b)3)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)1)4/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)1)5/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)1)10.b)9)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)2)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)c)8/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)e)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)f)10/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)i)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)4)a)7/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)4)c)8/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)4)c)9/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)4)c)10/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)7)c)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)5)a)7.1/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)5)a)7.2/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)5)a)7.3/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)5)b)5.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)5)b)7.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)9)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)10)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)11)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)13)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)14)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)16)a)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)16)a)1.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)16)a)2.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)16)a)3.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)16)g)29.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)16)g)32.',
  },
    {
    poser: false, imposer: false, interdire: true, cible: 'D)16)c)10.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)d)11.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)d)12.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)d)13.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)d)14.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)d)15.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)d)16.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)d)17.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)d)19.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)d)52.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)e)20.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)f)21.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)f)22.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)f)23.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)f)26.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)f)28.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)g)31.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)g)53.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)g)33.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)g)34.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)h)35.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)h)36.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)h)37.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)h)38.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)h)60.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)j)40.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)j)54.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)j)41.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)j)42.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)k)43.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)m)45.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)m)46.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)m)47.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)m)48.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)n)49.2)',
},
  ],
  'D)1)1.2/': [{
    poser: false, imposer: false, interdire: true, cible: 'D)3)i)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)2)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)5)b)5.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)5)b)7.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)7)b)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)12)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)13)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)15)',
  },
    {
    poser: false, imposer: false, interdire: true, cible: 'D)16)c)10.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)d)11.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)d)12.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)d)13.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)d)14.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)d)15.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)d)16.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)d)17.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)d)19.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)d)52.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)e)20.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)f)21.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)f)22.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)f)23.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)f)26.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)f)28.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)g)31.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)g)53.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)g)33.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)g)34.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)h)35.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)h)36.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)h)37.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)h)38.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)h)60.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)j)40.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)j)54.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)j)41.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)j)42.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)k)43.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)m)45.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)m)46.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)m)47.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)m)48.2)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)n)49.2)',
},
  ],
  'D)1)1.3/': [{
    poser: false, imposer: false, interdire: true, cible: 'D)1)2.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)1)4/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)1)5/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)1)3.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)1)4.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)1)5.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)1)6.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)1)7.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)1)8.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)1)9.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)1)10.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)c)8/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)f)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)e)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)g)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)4)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)5)a)1.4/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)5)a)1.5/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)5)a)1.6/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)5)a)1.7/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)5)a)1.8/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)5)a)3.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)5)a)4.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)5)a)5.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)5)a)6.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)5)a)7.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)5)a)8.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)5)a)9.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)5)b)3.1/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)7)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)8)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)9)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)10)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)11)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)12)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)15)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)16)a)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)16)a)1.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)16)a)2.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)16)a)3.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)16)b)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)16)b)4.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)16)b)5.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)16)b)6.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)16)b)7.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)16)b)8.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)16)b)9.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)16)f)24.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)16)f)25.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)16)f)27.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)16)g)29.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)16)g)30.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)16)g)32.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)e)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)7)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)8)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)9)',
  },
    {
    poser: false, imposer: false, interdire: true, cible: 'D)16)c)10.1)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)d)11.1)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)d)12.1)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)d)13.1)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)d)14.1)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)d)15.1)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)d)16.1)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)d)17.1)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)d)19.1)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)d)52.1)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)e)20.1)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)f)21.1)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)f)22.1)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)f)23.1)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)f)26.1)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)f)28.1)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)g)31.1)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)g)53.1)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)g)33.1)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)g)34.1)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)h)35.1)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)h)36.1)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)h)37.1)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)h)38.1)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)h)60.1)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)j)40.1)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)j)54.1)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)j)41.1)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)j)42.1)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)k)43.1)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)m)45.1)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)m)46.1)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)m)47.1)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)m)48.1)',
},{
poser: false, imposer: false, interdire: true, cible: 'D)16)n)49.1)',
},
  ],
  'D)1)4.1/': [{
    poser: false, imposer: false, interdire: true, cible: 'D)5)a)1.4/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)5)a)1.5/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)5)a)1.6/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)5)a)1.7/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)5)a)1.8/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)5)a)4.',
  },
  ],
  'D)1)4.2/': [{
    poser: false, imposer: false, interdire: true, cible: 'D)5)a)1.5/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)5)a)3.',
  },
  ],
  'D)1)4.3/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)5)a)8.b)',
  },
  ],
  'D)1)4.4/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)5)a)8.a)',
  },
  ],
  'D)1)4.5/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)5)a)9.d)',
  },
  ],
  'D)1)4.7/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)5)b)2.',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)5)b)3.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)4)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)5)a)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)7)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)8)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)9)',
  },
  ],
  'D)1)5.1/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)5)a)8.c)1/',
  },
  ],
  'D)1)5.2/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)5)a)8.c)1/',
  },
  ],
  'D)1)6.1/': [{
    poser: false, imposer: false, interdire: true, cible: 'D)3)c)7/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)c)8/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)e)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)h)1/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)h)2/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)h)3/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)h)4/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)h)5/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)h)6/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)h)7/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)h)9/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)h)14/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)h)15/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)h)16/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)h)17/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)h)18/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)h)19/',
  },
  ],
  'D)1)6.3/': [{
    poser: false, imposer: false, interdire: true, cible: 'D)3)c)1/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)c)2/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)c)6/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)c)7/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)c)8/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)c)9/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)e)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)f)4/',
  },
  ],
  'D)1)6.5/': [{
    poser: false, imposer: false, interdire: true, cible: 'D)3)e)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)h)2/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)h)3/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)h)4/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)h)5/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)h)6/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)h)9/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)h)14/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)h)15/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)h)16/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)h)17/',
  },
  ],
  'D)1)6.6/': [{
    poser: false, imposer: false, interdire: true, cible: 'D)3)e)',
  },
  ],
  'D)1)7.1/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)3)f)',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)5)a)9.d)',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)7)e)',
  },
  ],
  'D)1)7.2/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)3)f)',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)5)a)9.d)',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)9)e)',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)16)b)9.4/',
  },
  ],
  'D)10)c)1.1/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)b)9.4/',
  },
  ],
  'D)10)c)2.1/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)m)47.4/',
  },
  ],
  'D)10)c)3.1/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)b)9.4/',
  },
  ],
  'D)2)4.1/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)6)d)1/',
  },
  ],
  'D)2)4.2/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)6)d)2/',
  },
  ],
  'D)2)4.3/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)6)d)3/',
  },
  ],
  'D)2)4.4/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)6)d)4/',
  },
  ],
  'D)3)a)3/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)l)44.4/',
  },
  ],
  'D)3)a)4/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)l)44.4/',
  },
  ],
  'D)3)a)5/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)l)44.4/',
  },
  ],
  'D)3)c)8/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)a)2.4/',
  },
  ],
  'D)3)c)9/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)a)2.4/',
  },
  ],
  'D)3)e)4/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)a)2.4/',
  },
  ],
  'D)3)e)5/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)a)2.4/',
  },
  ],
  'D)3)e)6/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)j)40.4/',
  },
  ],
  'D)3)e)7/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)j)40.4/',
  },
  ],
  'D)3)e)8/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)j)40.4/',
  },
  ],
  'D)3)h)11/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)h)35.4/',
  },
  ],
  'D)3)h)12/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)h)35.4/',
  },
  ],
  'D)3)h)8/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)h)35.4/',
  },
  ],
  'D)4)a)1/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)g)31.4/',
  },
  ],
  'D)4)a)11/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)l)44.4/',
  },
  ],
  'D)4)a)12/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)l)44.4/',
  },
  ],
  'D)4)a)14/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)l)44.4/',
  },
  ],
  'D)4)a)15/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)l)44.4/',
  },
  ],
  'D)4)a)5/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)g)30.4/',
  },
  ],
  'D)4)a)6/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)g)30.4/',
  },
  ],
  'D)4)a)7/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)g)30.4/',
  },
  ],
  'D)4)c)11/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)l)44.4/',
  },
  ],
  'D)4)c)12/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)l)44.4/',
  },
  ],
  'D)4)c)15/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)l)44.4/',
  },
  ],
  'D)4)c)16/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)l)44.4/',
  },
  ],
  'D)4)c)2/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)l)44.4/',
  },
  ],
  'D)4)c)3/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)l)44.4/',
  },
  ],
  'D)4)c)4/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)l)44.4/',
  },
  ],
  'D)4)c)5/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)l)44.4/',
  },
  ],
  'D)4)c)7/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)l)44.4/',
  },
  ],
  'D)4)c)8/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)l)44.4/',
  },
  ],
  'D)4)c)9/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)l)44.4/',
  },
  ],
  'D)4)d)1/': [{
    poser: false, imposer: false, interdire: true, cible: 'D)4)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)8)b)1/',
  },
  ],
  'D)4)d)3/': [{
    poser: false, imposer: false, interdire: true, cible: 'D)5)b)4.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)8)b)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)8)c)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)9)',
  },
  ],
  'D)4)d)5/': [{
    poser: false, imposer: false, interdire: true, cible: 'D)5)a)3.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)5)a)4.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)5)b)4.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)8)b)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)8)c)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)9)',
  },
  ],
  'D)5)a)2.11/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)g)33.4/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)16)g)34.4/',
  },
  ],
  'D)5)a)2.6/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)g)31.4/',
  },
  ],
  'D)5)a)2.9/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)g)32.4/',
  },
  ],
  'D)5)a)3.1/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)g)31.4/',
  },
  ],
  'D)5)a)3.2/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)g)31.4/',
  },
  ],
  'D)5)a)3.3/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)g)31.4/',
  },
  ],
  'D)5)a)3.4/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)g)31.4/',
  },
  ],
  'D)5)a)5.a)1/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)g)33.4/',
  },
  ],
  'D)5)a)5.b)1/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)g)33.4/',
  },
  ],
  'D)5)a)5.c)1/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)g)33.4/',
  },
  ],
  'D)5)a)5.d)1/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)g)34.4/',
  },
  ],
  'D)5)a)6.2/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)g)31.4/',
  },
  ],
  'D)5)a)7.1/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)g)32.4/',
  },
  ],
  'D)5)a)7.4/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)g)32.4/',
  },
  ],
  'D)5)a)8.a)1/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)g)31.4/',
  },
  ],
  'D)5)a)8.a)3/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)g)31.4/',
  },
  ],
  'D)5)a)8.a)4/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)g)31.4/',
  },
  ],
  'D)5)a)8.b)3/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)g)31.4/',
  },
  ],
  'D)5)a)8.c)1/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)g)31.4/',
  },
  ],
  'D)5)a)8.c)2/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)g)31.4/',
  },
  ],
  'D)5)a)8.c)3/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)g)31.4/',
  },
  ],
  'D)5)a)8.c)4/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)g)31.4/',
  },
  ],
  'D)5)a)8.c)6/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)g)31.4/',
  },
  ],
  'D)5)a)8.c)8/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)g)31.4/',
  },
  ],
  'D)5)b)1.1/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)f)27.4/',
  },
  ],
  'D)5)b)1.2/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)f)27.4/',
  },
  ],
  'D)5)b)1.3/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)f)27.4/',
  },
  ],
  'D)5)b)1.4/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)f)27.4/',
  },
  ],
  'D)5)b)1.6/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)f)27.4/',
  },
  ],
  'D)6)d)1/': [{
    poser: false, imposer: false, interdire: true, cible: 'D)6)a)1.b)1/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)a)1.b)2/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)a)1.b)3/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)a)1.b)4/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)b)1.b)1/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)1.b)2/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)1.b)3/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)1.b)4/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)b)2.b)1/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)2.b)2/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)2.b)3/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)2.b)4/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)b)3.b)1/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)3.b)2/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)3.b)3/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)3.b)4/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)b)4.b)1/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)4.b)2/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)4.b)3/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)4.b)4/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)b)5.b)1/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)5.b)2/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)5.b)3/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)5.b)4/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)b)6.b)1/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)6.b)2/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)6.b)3/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)6.b)4/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)b)7.b)1/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)7.b)2/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)7.b)3/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)7.b)4/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)b)8.b)1/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)8.b)2/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)8.b)3/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)8.b)4/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)1.b)4/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)c)2.b)1/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)2.b)2/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)2.b)3/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)2.b)4/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)c)3.b)1/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)3.b)2/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)3.b)3/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)3.b)4/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)c)4.b)1/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)4.b)2/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)4.b)3/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)4.b)4/',
  },
  ],
  'D)6)d)2/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)6)a)1.b)1/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)a)1.b)2/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)a)1.b)3/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)a)1.b)4/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)1.b)1/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)b)1.b)2/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)1.b)3/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)1.b)4/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)2.b)1/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)b)2.b)2/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)2.b)3/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)2.b)4/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)3.b)1/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)b)3.b)2/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)3.b)3/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)3.b)4/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)4.b)1/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)b)4.b)2/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)4.b)3/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)4.b)4/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)5.b)1/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)b)5.b)2/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)5.b)3/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)5.b)4/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)6.b)1/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)b)6.b)2/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)6.b)3/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)6.b)4/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)7.b)1/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)b)7.b)2/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)7.b)3/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)7.b)4/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)8.b)1/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)b)8.b)2/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)8.b)3/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)8.b)4/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)1.b)1/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)c)1.b)2/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)1.b)3/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)1.b)4/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)2.b)1/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)c)2.b)2/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)2.b)3/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)2.b)4/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)3.b)1/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)c)3.b)2/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)3.b)3/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)3.b)4/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)4.b)1/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)c)4.b)2/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)4.b)3/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)4.b)4/',
  },
  ],
  'D)6)d)3/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)6)a)1.b)1/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)a)1.b)2/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)a)1.b)3/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)a)1.b)4/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)1.b)1/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)1.b)2/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)b)1.b)3/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)1.b)4/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)2.b)1/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)2.b)2/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)b)2.b)3/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)2.b)4/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)3.b)1/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)3.b)2/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)b)3.b)3/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)3.b)4/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)4.b)1/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)4.b)2/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)b)4.b)3/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)4.b)4/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)5.b)1/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)5.b)2/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)b)5.b)3/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)5.b)4/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)6.b)1/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)6.b)2/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)b)6.b)3/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)6.b)4/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)7.b)1/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)7.b)2/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)b)7.b)3/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)7.b)4/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)8.b)1/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)8.b)2/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)b)8.b)3/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)8.b)4/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)1.b)1/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)1.b)2/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)c)1.b)3/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)1.b)4/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)2.b)1/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)2.b)2/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)c)2.b)3/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)2.b)4/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)3.b)1/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)3.b)2/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)c)3.b)3/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)3.b)4/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)4.b)1/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)4.b)2/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)c)4.b)3/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)4.b)4/',
  },
  ],
  'D)6)d)4/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)6)a)1.b)1/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)a)1.b)2/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)a)1.b)3/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)a)1.b)4/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)1.b)1/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)1.b)2/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)1.b)3/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)b)1.b)4/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)2.b)1/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)2.b)2/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)2.b)3/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)b)2.b)4/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)3.b)1/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)3.b)2/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)3.b)3/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)b)3.b)4/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)4.b)1/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)4.b)2/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)4.b)3/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)b)4.b)4/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)5.b)1/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)5.b)2/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)5.b)3/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)b)5.b)4/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)6.b)1/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)6.b)2/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)6.b)3/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)b)6.b)4/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)7.b)1/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)7.b)2/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)7.b)3/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)b)7.b)4/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)8.b)1/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)8.b)2/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)b)8.b)3/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)b)8.b)4/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)1.b)1/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)1.b)2/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)1.b)3/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)c)1.b)4/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)2.b)1/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)2.b)2/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)2.b)3/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)c)2.b)4/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)3.b)1/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)3.b)2/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)3.b)3/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)c)3.b)4/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)4.b)1/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)4.b)2/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)6)c)4.b)3/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)6)c)4.b)4/',
  },
  ],
  'D)7)f)2/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)b)5.4/',
  },
  ],
  'D)7)f)3/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)b)5.4/',
  },
  ],
  'D)7)f)4/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)b)5.4/',
  },
  ],
  'D)7)f)5/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)b)5.4/',
  },
  ],
  'D)8)f)2/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)i)39.4/',
  },
  ],
  'D)9)a)1/': [{
    poser: false, imposer: false, interdire: true, cible: 'D)9)c)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)9)e)1/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)9)e)2/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)9)f)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)9)g)2/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)9)g)3/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)9)g)4/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)9)g)5/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)9)g)6/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)9)g)8/',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)9)h)',
  },
  ],
  'D)9)b)3/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)a)2.4/',
  },
  ],
  'D)9)b)5/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)a)2.4/',
  },
  ],
  'D)9)b)6/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)a)2.4/',
  },
  ],
  'D)9)b)7/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)a)2.4/',
  },
  ],
  'D)9)c)3/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)a)2.4/',
  },
  ],
  'D)9)d)2/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)a)2.4/',
  },
  ],
  'D)9)e)3/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)a)2.4/',
  },
  ],
  'D)9)e)4/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)a)2.4/',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)16)a)2.4/',
  },
  ],
  'D)9)e)6/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)a)2.4/',
  },
  ],
  'D)9)f)2/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)a)2.4/',
  },
  ],
  'D)9)i)2/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)a)2.4/',
  },
  ],
  'D)9)j)1/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)a)3.4/',
  },
  ],
  'D)9)j)2/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)a)3.4/',
  },
  ],
  'D)9)j)4/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)a)2.4/',
  },
  ],
  'D)9)j)6/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)16)a)2.4/',
  }],
  'D)3)d)1)1/': [{
    poser: false, imposer: true, interdire: false, cible: 'D)3)d)8)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)9)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)10)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)11)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)12)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)13)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)7)',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)3)d)3)',
  },
  ],
  'D)3)d)1)2/': [{
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)8)',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)3)d)9)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)10)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)11)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)12)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)13)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)7)',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)3)d)3)',
  },
  ],
  'D)3)d)1)3/': [{
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)8)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)9)',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)3)d)10)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)11)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)12)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)13)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)7)',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)3)d)3)',
  },
  ],
  'D)3)d)1)4/': [{
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)8)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)9)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)10)',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)3)d)11)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)12)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)13)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)7)',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)3)d)3)',
  },
  ],
  'D)3)d)1)5/': [{
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)8)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)9)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)10)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)11)',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)3)d)12)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)13)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)7)',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)3)d)3)',
  },
  ],
  'D)3)d)1)6/': [{
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)8)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)9)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)10)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)11)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)12)',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)3)d)13)',
  },
  {
    poser: false, imposer: true, interdire: false, cible: 'D)3)d)7)',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)3)d)3)',
  }],
  'D)11)a)1.': [{
    poser: false, imposer: false, interdire: true, cible: 'D)11)a)2.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)11)a)3.',
  }],
  'D)11)b)1.': [{
    poser: false, imposer: false, interdire: true, cible: 'D)11)b)2.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)11)b)3.',
  }],
  'D)11)c)1.': [{
    poser: false, imposer: false, interdire: true, cible: 'D)11)c)2.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)11)c)3.',
  }],
  'D)11)d)1.': [{
    poser: false, imposer: false, interdire: true, cible: 'D)11)d)2.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)11)d)3.',
  }],
  'D)12)a)1.': [{
    poser: false, imposer: false, interdire: true, cible: 'D)12)a)2.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)12)a)3.',
  }],
  'D)12)b)1.': [{
    poser: false, imposer: false, interdire: true, cible: 'D)12)b)2.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)12)b)3.',
  }],
  'D)12)c)1.': [{
    poser: false, imposer: false, interdire: true, cible: 'D)12)c)2.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)12)c)3.',
  }],
  'D)12)d)1.': [{
    poser: false, imposer: false, interdire: true, cible: 'D)12)d)2.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)12)d)3.',
  }],
  'D)13)1.a)1.': [{
    poser: false, imposer: false, interdire: true, cible: 'D)13)1.a)2.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)13)1.a)3.',
  }],
  'D)13)1.b)1.': [{
    poser: false, imposer: false, interdire: true, cible: 'D)13)1.b)2.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)13)1.b)3.',
  }],
  'D)13)1.c)1.': [{
    poser: false, imposer: false, interdire: true, cible: 'D)13)1.c)2.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)13)1.c)3.',
  }],
  'D)13)1.d)1.': [{
    poser: false, imposer: false, interdire: true, cible: 'D)13)1.d)2.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)13)1.d)3.',
  }],
  'D)13)2.a)1.': [{
    poser: false, imposer: false, interdire: true, cible: 'D)13)2.a)2.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)13)2.a)3.',
  }],
  'D)13)2.b)1.': [{
    poser: false, imposer: false, interdire: true, cible: 'D)13)2.b)2.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)13)2.b)3.',
  }],
  'D)13)2.c)1.': [{
    poser: false, imposer: false, interdire: true, cible: 'D)13)2.c)2.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)13)2.c)3.',
  }],
  'D)13)2.d)1.': [{
    poser: false, imposer: false, interdire: true, cible: 'D)13)2.d)2.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)13)2.d)3.',
  }],
  'D)14)a)1.': [{
    poser: false, imposer: false, interdire: true, cible: 'D)14)a)2.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)14)a)3.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)14)a)4.',
  }],
  'D)14)b)1.': [{
    poser: false, imposer: false, interdire: true, cible: 'D)14)b)2.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)14)b)3.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)14)b)4.',
  }],
  'D)14)c)1.': [{
    poser: false, imposer: false, interdire: true, cible: 'D)14)c)2.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)14)c)3.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)14)c)4.',
  }],
  'D)15)a)1.': [{
    poser: false, imposer: false, interdire: true, cible: 'D)15)a)2.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)15)a)3.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)15)a)4.',
  }],
  'D)15)b)1.': [{
    poser: false, imposer: false, interdire: true, cible: 'D)15)b)2.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)15)b)3.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)15)b)4.',
  }],
  'D)15)c)1.': [{
    poser: false, imposer: false, interdire: true, cible: 'D)15)c)2.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)15)c)3.',
  },
  {
    poser: false, imposer: false, interdire: true, cible: 'D)15)c)4.',
  }],
};
