export const category = [
  {
    label: 'Animal',
    key: 'animal',
  },
  {
    label: 'Lot',
    key: 'lot',
  },
];

export const modeLecture = [
  {
    label: 'Utilisateur principal ',
    key: 'admin',
  },
  {
    label: 'Utilisateur secondaire',
    key: 'visitor',
  },
];

export const frequence = [
  {
    label: 'Mensuelle',
    key: 'monthly',
  },
  {
    label: 'Semestrielle',
    key: 'biannually',
  },
  {
    label: 'Trimestrielle',
    key: 'quarterly',
  },
  {
    label: 'Annuelle',
    key: 'annual',
  },
];

// mockData
export const mockLots = [
  {
    label: 'Aucun',
    key: 'nothing',
  },
  {
    label: 'Nom du lot n7879454',
    key: 'first',
  },
];

export const mockBatiments = [
  {
    label: 'Batiment 1',
    key: 'building1',
  },
  {
    label: 'Batiment 2',
    key: 'building2',
  },
];

export const mockZones = [
  {
    label: 'Zone 1',
    key: 'zone1',
  },
  {
    label: 'Zone 2',
    key: 'zone2',
  },
];

export const EU_countries = [
  { label: 'France', key: 'FR' },
  { label: 'Allemagne', key: 'DE' },
  { label: 'Autriche', key: 'AT' },
  { label: 'Belgique', key: 'BE' },
  { label: 'Bulgarie', key: 'BG' },
  { label: 'Chypre', key: 'CY' },
  { label: 'Croatie', key: 'HR' },
  { label: 'Danemark', key: 'DK' },
  { label: 'Espagne', key: 'ES' },
  { label: 'Estonie', key: 'EE' },
  { label: 'Finlande', key: 'FI' },
  { label: 'Grèce', key: 'EL' },
  { label: 'Hongrie', key: 'HU' },
  { label: 'Irlande', key: 'IE' },
  { label: 'Italie', key: 'IT' },
  { label: 'Lettonie', key: 'LV' },
  { label: 'Lituanie', key: 'LT' },
  { label: 'Luxembourg', key: 'LU' },
  { label: 'Malte', key: 'MT' },
  { label: 'Pays-Bas', key: 'NL' },
  { label: 'Pologne', key: 'PL' },
  { label: 'Portugal', key: 'PT' },
  { label: 'Roumanie', key: 'RO' },
  { label: 'Slovaquie', key: 'SK' },
  { label: 'Slovénie', key: 'SI' },
  { label: 'Suède', key: 'SE' },
  { label: 'Tchéquie', key: 'CZ' },
];

export const ALL_countries = [
  { label: 'France', key: 'FR' },
  { label: 'Afghanistan', key: 'AF' },
  { label: 'Albania', key: 'AL' },
  { label: 'Algeria', key: 'DZ' },
  { label: 'Andorra', key: 'AD' },
  { label: 'Angola', key: 'AO' },
  { label: 'Antigua and Barbuda', key: 'AG' },
  { label: 'Argentina', key: 'AR' },
  { label: 'Armenia', key: 'AM' },
  { label: 'Australia', key: 'AU' },
  { label: 'Austria', key: 'AT' },
  { label: 'Azerbaijan', key: 'AZ' },
  { label: 'Bahamas', key: 'BS' },
  { label: 'Bahrain', key: 'BH' },
  { label: 'Bangladesh', key: 'BD' },
  { label: 'Barbados', key: 'BB' },
  { label: 'Belarus', key: 'BY' },
  { label: 'Belgium', key: 'BE' },
  { label: 'Belize', key: 'BZ' },
  { label: 'Benin', key: 'BJ' },
  { label: 'Bhutan', key: 'BT' },
  { label: 'Bolivia', key: 'BO' },
  { label: 'Bosnia and Herzegovina', key: 'BA' },
  { label: 'Botswana', key: 'BW' },
  { label: 'Brazil', key: 'BR' },
  { label: 'Brunei Darussalam', key: 'BN' },
  { label: 'Bulgaria', key: 'BG' },
  { label: 'Burkina Faso', key: 'BF' },
  { label: 'Burundi', key: 'BI' },
  { label: 'Cabo Verde', key: 'CV' },
  { label: 'Cambodia', key: 'KH' },
  { label: 'Cameroon', key: 'CM' },
  { label: 'Canada', key: 'CA' },
  { label: 'Central African Republic', key: 'CF' },
  { label: 'Chad', key: 'TD' },
  { label: 'Chile', key: 'CL' },
  { label: 'China', key: 'CN' },
  { label: 'Colombia', key: 'CO' },
  { label: 'Comoros', key: 'KM' },
  { label: 'Congo (the Democratic Republic of the)', key: 'CD' },
  { label: 'Congo', key: 'CG' },
  { label: 'Costa Rica', key: 'CR' },
  { label: "Côte d'Ivoire", key: 'CI' },
  { label: 'Croatia', key: 'HR' },
  { label: 'Cuba', key: 'CU' },
  { label: 'Cyprus', key: 'CY' },
  { label: 'Czechia', key: 'CZ' },
  { label: 'Denmark', key: 'DK' },
  { label: 'Djibouti', key: 'DJ' },
  { label: 'Dominica', key: 'DM' },
  { label: 'Dominican Republic', key: 'DO' },
  { label: 'Ecuador', key: 'EC' },
  { label: 'Egypt', key: 'EG' },
  { label: 'El Salvador', key: 'SV' },
  { label: 'Equatorial Guinea', key: 'GQ' },
  { label: 'Eritrea', key: 'ER' },
  { label: 'Estonia', key: 'EE' },
  { label: 'Eswatini', key: 'SZ' },
  { label: 'Ethiopia', key: 'ET' },
  { label: 'Fiji', key: 'FJ' },
  { label: 'Finland', key: 'FI' },
  { label: 'Gabon', key: 'GA' },
  { label: 'Gambia', key: 'GM' },
  { label: 'Georgia', key: 'GE' },
  { label: 'Germany', key: 'DE' },
  { label: 'Ghana', key: 'GH' },
  { label: 'Greece', key: 'GR' },
  { label: 'Grenada', key: 'GD' },
  { label: 'Guatemala', key: 'GT' },
  { label: 'Guinea', key: 'GN' },
  { label: 'Guinea-Bissau', key: 'GW' },
  { label: 'Guyana', key: 'GY' },
  { label: 'Haiti', key: 'HT' },
  { label: 'Honduras', key: 'HN' },
  { label: 'Hungary', key: 'HU' },
  { label: 'Iceland', key: 'IS' },
  { label: 'India', key: 'IN' },
  { label: 'Indonesia', key: 'ID' },
  { label: 'Iran', key: 'IR' },
  { label: 'Iraq', key: 'IQ' },
  { label: 'Ireland', key: 'IE' },
  { label: 'Israel', key: 'IL' },
  { label: 'Italy', key: 'IT' },
  { label: 'Jamaica', key: 'JM' },
  { label: 'Japan', key: 'JP' },
  { label: 'Jordan', key: 'JO' },
  { label: 'Kazakhstan', key: 'KZ' },
  { label: 'Kenya', key: 'KE' },
  { label: 'Kiribati', key: 'KI' },
  { label: 'North Korea', key: 'KP' },
  { label: 'South Korea', key: 'KR' },
  { label: 'Kuwait', key: 'KW' },
  { label: 'Kyrgyzstan', key: 'KG' },
  { label: 'Laos', key: 'LA' },
  { label: 'Latvia', key: 'LV' },
  { label: 'Lebanon', key: 'LB' },
  { label: 'Lesotho', key: 'LS' },
  { label: 'Liberia', key: 'LR' },
  { label: 'Libya', key: 'LY' },
  { label: 'Liechtenstein', key: 'LI' },
  { label: 'Lithuania', key: 'LT' },
  { label: 'Luxembourg', key: 'LU' },
  { label: 'North Macedonia', key: 'MK' },
  { label: 'Madagascar', key: 'MG' },
  { label: 'Malawi', key: 'MW' },
  { label: 'Malaysia', key: 'MY' },
  { label: 'Maldives', key: 'MV' },
  { label: 'Mali', key: 'ML' },
  { label: 'Malta', key: 'MT' },
  { label: 'Marshall Islands', key: 'MH' },
  { label: 'Mauritania', key: 'MR' },
  { label: 'Mauritius', key: 'MU' },
  { label: 'Mexico', key: 'MX' },
  { label: 'Micronesia', key: 'FM' },
  { label: 'Moldova', key: 'MD' },
  { label: 'Monaco', key: 'MC' },
  { label: 'Mongolia', key: 'MN' },
  { label: 'Montenegro', key: 'ME' },
  { label: 'Morocco', key: 'MA' },
  { label: 'Mozambique', key: 'MZ' },
  { label: 'Myanmar', key: 'MM' },
  { label: 'Namibia', key: 'NA' },
  { label: 'Nauru', key: 'NR' },
  { label: 'Nepal', key: 'NP' },
  { label: 'Netherlands', key: 'NL' },
  { label: 'New Zealand', key: 'NZ' },
  { label: 'Nicaragua', key: 'NI' },
  { label: 'Niger', key: 'NE' },
  { label: 'Nigeria', key: 'NG' },
  { label: 'Norway', key: 'NO' },
  { label: 'Oman', key: 'OM' },
  { label: 'Pakistan', key: 'PK' },
  { label: 'Palau', key: 'PW' },
  { label: 'Palestine', key: 'PS' },
  { label: 'Panama', key: 'PA' },
  { label: 'Papua New Guinea', key: 'PG' },
  { label: 'Paraguay', key: 'PY' },
  { label: 'Peru', key: 'PE' },
  { label: 'Philippines', key: 'PH' },
  { label: 'Poland', key: 'PL' },
  { label: 'Portugal', key: 'PT' },
  { label: 'Qatar', key: 'QA' },
  { label: 'Romania', key: 'RO' },
  { label: 'Russian Federation', key: 'RU' },
  { label: 'Rwanda', key: 'RW' },
  { label: 'Saint Kitts and Nevis', key: 'KN' },
  { label: 'Saint Lucia', key: 'LC' },
  { label: 'Saint Vincent and the Grenadines', key: 'VC' },
  { label: 'Samoa', key: 'WS' },
  { label: 'San Marino', key: 'SM' },
  { label: 'Sao Tome and Principe', key: 'ST' },
  { label: 'Saudi Arabia', key: 'SA' },
  { label: 'Senegal', key: 'SN' },
  { label: 'Serbia', key: 'RS' },
  { label: 'Seychelles', key: 'SC' },
  { label: 'Sierra Leone', key: 'SL' },
  { label: 'Singapore', key: 'SG' },
  { label: 'Slovakia', key: 'SK' },
  { label: 'Slovenia', key: 'SI' },
  { label: 'Solomon Islands', key: 'SB' },
  { label: 'Somalia', key: 'SO' },
  { label: 'South Africa', key: 'ZA' },
  { label: 'South Sudan', key: 'SS' },
  { label: 'Spain', key: 'ES' },
  { label: 'Sri Lanka', key: 'LK' },
  { label: 'Sudan', key: 'SD' },
  { label: 'Suriname', key: 'SR' },
  { label: 'Sweden', key: 'SE' },
  { label: 'Switzerland', key: 'CH' },
  { label: 'Syrian Arab Republic', key: 'SY' },
  { label: 'Tajikistan', key: 'TJ' },
  { label: 'Tanzania', key: 'TZ' },
  { label: 'Thailand', key: 'TH' },
  { label: 'Timor-Leste', key: 'TL' },
  { label: 'Togo', key: 'TG' },
  { label: 'Tonga', key: 'TO' },
  { label: 'Trinidad and Tobago', key: 'TT' },
  { label: 'Tunisia', key: 'TN' },
  { label: 'Türkiye', key: 'TR' },
  { label: 'Turkmenistan', key: 'TM' },
  { label: 'Tuvalu', key: 'TV' },
  { label: 'Uganda', key: 'UG' },
  { label: 'Ukraine', key: 'UA' },
  { label: 'United Arab Emirates', key: 'AE' },
  { label: 'United Kingdom', key: 'GB' },
  { label: 'United States of America', key: 'US' },
  { label: 'Uruguay', key: 'UY' },
  { label: 'Uzbekistan', key: 'UZ' },
  { label: 'Vanuatu', key: 'VU' },
  { label: 'Venezuela', key: 'VE' },
  { label: 'Viet Nam', key: 'VN' },
  { label: 'Yemen', key: 'YE' },
  { label: 'Zambia', key: 'ZM' },
  { label: 'Zimbabwe', key: 'ZW' },
];
