import React, { useEffect, useState } from 'react';

import { Text, useTheme } from '@ui-kitten/components';

import { Platform, View } from 'react-native';
import { Icon as IconUIKitten } from '@ui-kitten/components/ui/icon/icon.component';
import { useLinkTo, useRoute } from '@react-navigation/native';
import moment from 'moment';
import { RouteProp } from '@react-navigation/core/lib/typescript/src/types';
import { useForm } from 'react-hook-form';
import { API } from 'aws-amplify';
import MaxWidthContainer from '../../../components/MaxWidthContainer';
import InfoCard from '../../../screenComponents/InfoCard';
import GoCustomCard from '../../../components/GoCustomCard';
import TeleconsultationCard from '../../../components/TeleconsultationCard';
import Button from '../../../components/Button';
import DetailsScreensHeaderCard from '../../../screenComponents/DetailsScreensHeaderCard';
import FCard from '../../../components/Card';
import VetoDetailsConsultationScreenBL
  from '../../../screenBusinessLogic/vetoScreensBL/VetoConsultationScreenBL/VetoDetailsConsultationScreenBL';

import { useGetConsultation, useUpdateConsultationMutation } from '../../../src/API/Consultation';
import ActivityIndicator from '../../../components/ActivityIndicator';
import DocumentComponent from '../../../components/Document/DocumentComponent';
import { VetoTabExamenParamList } from '../../../types';
import { Consultation } from '../../../src/API';
import CustomModal from '../../../components/Modals/CustomModal';
import TextInput from '../../../components/Form/TextInput';
import { useVetOfficeList } from '../../../src/API/VetOffice';

import Form from '../../../components/Form/Form';
import { AvailableValidationRules } from '../../../components/Form/validation';

import ArrowIcon from '../../../assets/svg_icones_ScanFlock/WHITE/arrowRight.svg';
import DocumentBL from '../../../components/Document/DocumentBL';
import PlanifierTeleconsultationModal
  from '../../../components/Modals/PlanifierTeleconsultationModal';
import DiseaseCard from '../../../screenComponents/ConsultationScreenComps/DiseaseCard';
import Tutorial from '../../../components/Tutorial';

type ModifyPriceConsultation = {
  priceConsultation: string
};

const VetoDetailsConsultationScreen = () => {
  const linkTo = useLinkTo();

  const route = useRoute<RouteProp<VetoTabExamenParamList, 'veto-details-consultation'> >();
  const theme = useTheme();
  const [openDelete, setOpenDelete] = useState();
  let refetchConsultation;
  let oneConsultation: Consultation | undefined;

  const [telecons, setTelecons] = useState();

  // let oneConsultation
  if (route && route.params && route.params.id) {
    // console.log('route', route.params.id);
    const { consultation, refetch } = useGetConsultation(route.params.id);
    // console.log('consultation:', consultation);

    oneConsultation = consultation;
    refetchConsultation = refetch;
  }

  const { vetOffice } = useVetOfficeList();

  /** Modal facturer la consultation */
  const [state, setState] = useState();
  const [showMessage, setShowMessage] = useState<string | boolean>(false);
  const [loading, setLoading] = useState(false);
  const { modifyConsultation } = useUpdateConsultationMutation();
  const modifyPriceConsultation = useForm<ModifyPriceConsultation>();
  /** Document Bl */
  const { uploadCreateDocument } = DocumentBL.UseDocument();

  const invoiceConsultation = async (data: ModifyPriceConsultation) => {
    const parsed = parseFloat(data?.priceConsultation);

    if (oneConsultation && route.params.id
        && vetOffice && vetOffice.maxPriceConsultation) {
      if (parsed > vetOffice?.maxPriceConsultation) {
        setShowMessage(true);
      } else if (oneConsultation && route
        && route.params.id
          && (parsed <= vetOffice?.maxPriceConsultation)) {
        setShowMessage(false);

        const resp = await API.post('scanflockrest', '/mangopay/do-payment', {
          body: {
            amount: parsed,
            type: 'conseil',
            breedingId: oneConsultation.breedingId,
          },
        });
        if (resp.success) {
          await modifyConsultation({
            variables: {
              input: {
                id: route.params.id,
                priceConsultation: parsed,
                // eslint-disable-next-line no-underscore-dangle
                _version: oneConsultation._version,
              },
            },
          });
          setShowMessage(false);
        } else {
          setShowMessage(resp.error);
        }

        setLoading(false);
        setState(undefined);
        if (refetchConsultation) {
          await refetchConsultation();
        }
      }
    }
  };

  /** FACTURATION DOCUMENT  */
  const now = moment(new Date()).format('YYYY-MM-DD');
  const shareFactureToConsultation = async () => {
    if (oneConsultation) {
      // console.log('share facture', oneConsultation.id);

      await modifyConsultation({
        variables: {
          input: {
            id: oneConsultation.id,
            // eslint-disable-next-line no-underscore-dangle
            _version: oneConsultation._version,
            sharedFacture: true,
            sharedFactureAt: now,
          },
        },
      });
      setOpenDelete(undefined);
    }

    if (refetchConsultation) {
      await refetchConsultation();
    }
  };

  const importerFacture = () => {
    uploadCreateDocument('facture', refetchConsultation, oneConsultation);
    // setLoading(true);  // potential bug: if importer button clicked and loading will be true,
    // and user will not choose file but close file chooser.
    // so loading=true will not permit to recall file chooser.
  };
  // console.log('oneConsultation.breeding.breedingId:', oneConsultation);

  return (
    <>
      <MaxWidthContainer
        withScrollView="keyboardAware"
        outerViewProps={{
          style: {
            padding: 20,
          },
        }}
      >
        {oneConsultation
          ? (
            <DetailsScreensHeaderCard
              title1="Date de la consultation"
              titleInfo1={moment(oneConsultation?.dateTeleCons ? oneConsultation?.dateTeleCons : oneConsultation?.createdAt).format('L')}
              title2="Partagé avec votre cabinet le"
              titleInfo2={moment(oneConsultation?.sharedAt).format('L') || '-'}
              title3={`${oneConsultation && oneConsultation.breeding && oneConsultation.breeding.companyName ? "Nom de l'élevage" : ''}`}
              titleInfo3={`${oneConsultation && oneConsultation.breeding && oneConsultation.breeding.companyName}`}
              title4={"N° d'identification de l'élevage"}
              titleInfo4={`${oneConsultation && oneConsultation.breeding && oneConsultation.breeding.nBreeding}`}
              title5={oneConsultation && oneConsultation.animal && oneConsultation.animal.name ? "Nom de l'animal" : null}
              titleInfo5={`${oneConsultation && oneConsultation.animal && oneConsultation.animal.name}`}
              title6={`${oneConsultation && oneConsultation.animal && oneConsultation.animal.numberID ? "N° d'identification de l'animal" : null}`}
              titleInfo6={`${oneConsultation && oneConsultation.animal && (oneConsultation.animal.numberID || 'Non')}`}
              title7={`${oneConsultation
                && (
                  (
                    oneConsultation.animal && oneConsultation.animal.lots
                    && oneConsultation.animal.lots.numberLot ? "N° d'identification du lot" : "N° d'identification du lot"
                  )
                    || (oneConsultation.lot && oneConsultation.lot.numberLot
                      ? "N° d'identification du lot" : "N° d'identification du lot")
                )}`}
              titleInfo7={`${oneConsultation
                && (

                  (// eslint-disable-next-line no-nested-ternary
                    oneConsultation.animal ? (oneConsultation.animal.lots
                        && oneConsultation.animal.lots.numberLot
                      ? oneConsultation.animal.lots.numberLot : "Cet animal n'est affecté à aucun lot") : null
                  )
                    || (
                      oneConsultation.lot && oneConsultation.lot.numberLot
                        ? oneConsultation.lot.numberLot : "Ce lot n'est affecté à aucun animal"
                    )
                )}`}
              title8="Facturation"
              titleInfo8={oneConsultation && oneConsultation.sharedFacture ? 'Oui' : 'Non'}
              title9="Montant"
              titleInfo9={oneConsultation && oneConsultation.priceConsultation ? oneConsultation.priceConsultation : '--'}
              buttonText="Accéder à l'élevage"
              customLinkTo={() => {
                VetoDetailsConsultationScreenBL.linkToSwitch({ state: 'acceder-a-elevage', linkTo, id: oneConsultation.breedingId });
              }}
            />
          )
          : (<ActivityIndicator center margin={10} />)}

        <InfoCard
          title="alertInfo"
          iconShow="alert-circle-outline"
        // iconCloseShow="close-outline"
          description={'L\'éleveur vous a partagé cet auto-examen. Vous pouvez désormais échanger avec lui sur la messagerie et consulter et partager des documents.'}
          alertInfo="alertInfo"
        />

        {/**       FACTURATION DE CONSULTATION */}
        <Text category="h2" appearance="hint" style={{ marginTop: 40 }}>Facturation</Text>
        {oneConsultation && oneConsultation.priceConsultation
          ? (
            <>
              <InfoCard
                title="alertInfo"
                iconShow="alert-circle-outline"
                  // iconCloseShow="close-outline"
                description="Vous avez facturé cette consultation. Vous pouvez désormais importer la facture"
                alertInfo="alertInfo"
              />
              { oneConsultation.facture ? (
                <>
                  <DocumentComponent
                    key={oneConsultation.facture.id}
                    document={oneConsultation.facture}
                    data={oneConsultation}
                    refetch={refetchConsultation}
                    modifyConsultation={modifyConsultation}
                    shareFactureToConsultation={shareFactureToConsultation}
                    // deleteDoc={() => { setOpenDelete('delete document'); }}
                  />
                  {oneConsultation.sharedFacture
                    ? (
                      <InfoCard
                        title="alertInfo"
                        iconShow="alert-circle-outline"
                          // iconCloseShow="close-outline"
                        description={`Vous avez envoyé une facture à l'éleveur le ${moment(oneConsultation.sharedFactureAt).format('L')}`}
                        alertInfo="alertInfo"
                      />
                    )
                    : (
                      <Button
                        onPress={() => { setOpenDelete('share facture'); }}
                        customLeftIcon={<ArrowIcon height={20} width={20} fill="white" style={{ marginRight: 5 }} />}
                        leftIconFill="white"
                      >
                        Partager la facture
                      </Button>
                    )}

                </>
              ) : (
                <Button
                  loading={loading}
                  onPress={() => importerFacture()}
                  leftIcon="file-add-outline"
                  leftIconFill={theme['color-primary-600']}
                  appearance="outline"
                  // style={{ alignSelf: 'flex-start' }}
                >
                  Importer une facture
                </Button>
              )}
            </>
          )
          : (
            oneConsultation && oneConsultation.breeding
                && oneConsultation.breeding.mpMandateId
                && oneConsultation.breeding.mpMandateConfirmed
                && vetOffice && vetOffice.mpBankId ? (
                  <>
                    <InfoCard
                      title="alertInfo"
                      iconShow="alert-circle-outline"
                  // iconCloseShow="close-outline"
                      description={"Vous pouvez consulter les réponses données par l'éleveur dans le questionnaire. Vous ne pouvez pas modifier le questionnaire."}
                      alertInfo="alertInfo"
                    />

                    <Button
                      leftIcon="credit-card-outline"
                      leftIconFill="#FFF"
                      onPress={() => { setState('Open facturation form'); }}
                    >
                      Facturer la consultation
                    </Button>
                    <Text category="h4" style={{ textAlign: 'center' }}>
                      {`Vous avez fixé un montant maximum de ${vetOffice?.maxPriceConsultation} € pour les consultations. Vous pouvez modifier ce montant dans les paramètres de votre cabinet. Des frais de services de 1,50 € HT seront facturés.`}
                    </Text>
                  </>
              ) : (
                vetOffice && vetOffice.mpBankId ? (
                  <InfoCard
                    title="alertInfo"
                    iconShow="alert-triangle-outline"
                          // iconCloseShow="close-outline"
                    description={"L'éleveur n'a pas encore fournis ses coordonnées bancaires, vous ne pouvez pas facturer par l'application pour l'instant."}
                    alertInfo="alertInfo"
                  />
                ) : (
                  <>
                    <InfoCard
                      title="alertInfo"
                      iconShow="alert-triangle-outline"
                          // iconCloseShow="close-outline"
                      description={"Vous n'avez pas encore fournis vos coordonnées bancaires pour recevoir vos paiements, vous ne pouvez pas facturer par l'application pour l'instant."}
                      alertInfo="alertInfo"
                    />
                    <Button
                      leftIconFill="#FFF"
                      onPress={() => { linkTo('/veto-mon-compte/infos-bancaires'); }}
                    >
                      Renseigner ses coordonnées bancaires
                    </Button>
                  </>
                )
              )
          )}

        {/**        ****    */}
        <Text category="h2" appearance="hint" style={{ marginTop: 40 }}>Accéder aux réponses de l'auto-examen</Text>
        <GoCustomCard
          title1="Questionnaire"
          minusSquareOutline="arrow-ios-forward"
          linkToCustom={() => {
            VetoDetailsConsultationScreenBL.linkToSwitch({ state: 'consultation-questionnaire', linkTo, id: oneConsultation.id });
          }}
        />
        <InfoCard
          title="alertInfo"
          iconShow="alert-circle-outline"
        // iconCloseShow="close-outline"
          description={"Vous pouvez consulter les réponses données par l'éleveur dans le questionnaire. Vous ne pouvez pas modifier le questionnaire."}
          alertInfo="alertInfo"
        />

        {/**        ****    */}
        {oneConsultation && oneConsultation.disease && (
        <>
          <Text category="h2" appearance="hint" style={{ marginTop: 40 }}>Maladie sélectionnée par l'éleveur</Text>
          <DiseaseCard
            key={oneConsultation.disease.id}
            currentDisease={oneConsultation.disease}
            consultation={oneConsultation}
            canSave={false}
          />
        </>
        )}

        {/**        ****    */}
        <Text category="h2" appearance="hint" style={{ marginTop: 40 }}>Téléconsultation</Text>
        <Tutorial
          videoId="arQnAd6eWGI"
        />
        {oneConsultation?.teleconsultation
          ? (
            <>
              <InfoCard
                title="alertInfo"
                iconShow="alert-circle-outline"
                // iconCloseShow="close-outline"
                description={"Une téléconsultation a été demandée par l'éleveur, vous pouvez planifier le rendez-vous. Planifiez le rendez-vous dans les détails de la téléconsultation."}
                alertInfo="alertInfo"
              />
              <TeleconsultationCard consultationData={oneConsultation} detailConsultationPage consultation="RDV" />
              {oneConsultation && !oneConsultation.facture
                ? (
                  <Button
                    onPress={() => setTelecons(oneConsultation?.id)}
                  >
                    Planifier la téléconsultation
                  </Button>
                ) : null}
            </>
          )
          : (
            <>
              <InfoCard
                title="alertInfo"
                iconShow="alert-circle-outline"
                // iconCloseShow="close-outline"
                description={"Aucune téléconsultation de prévue pour cet auto-examen. Vous pouvez planifier une nouvelle téléconsultation. Vous pouvez accéder à l'historique des auto-examens et téléconsultations sur le détail des élevages."}
                alertInfo="alertInfo"
              />
              <Button
                onPress={() => setTelecons(oneConsultation?.id)}
              >
                Créer une nouvelle téléconsultation
              </Button>
            </>
          )}

        <PlanifierTeleconsultationModal
          title="Planifier la téléconsultation"
          before={<></>}
            /* eslint-disable-next-line no-underscore-dangle */
          data={{
            id: route.params?.id,
            _version: oneConsultation?._version,
            date: oneConsultation?.dateTeleCons,
            type: 'teleconsultation',
          }}
          noSafeArea
          visible={telecons !== undefined}
          scrollable={false}
          onClose={() => { setTelecons(undefined); }}
          insideModalStyle={{ padding: 20 }}
          buttonText="Confirmer"
        />

        {/**        ****    */}
        <Text category="h2" appearance="hint" style={{ marginTop: 40 }}>Mes échanges avec l'éleveur</Text>

        <Tutorial
          videoId="QOB_FtbmsD8"
        />
        <InfoCard
          title="alertInfo"
          iconShow="alert-circle-outline"
            // iconCloseShow="close-outline"
          description={'La messagerie instantanée vous permet de communiquer avec votre éleveur lors d’un auto-examen partagé.\n'
            + 'Vous pouvez échanger des documents ou photos relatifs à cet auto-examen à partir de l’espace « Documents ».\n'
            + 'Dans l\'espace « Document », on vous pourrez télécharger le document après confirmation de votre souhait.'}
          alertInfo="alertInfo"
        />
        <GoCustomCard
          title1="Messagerie"
          minusSquareOutline="arrow-ios-forward"
          linkToCustom={() => {
            VetoDetailsConsultationScreenBL.linkToSwitch({ state: 'consultation-messagerie', linkTo, id: oneConsultation.id });
          }}
        />
        <GoCustomCard
          title1="Documents"
          minusSquareOutline="arrow-ios-forward"
          linkToCustom={() => {
            VetoDetailsConsultationScreenBL.linkToSwitch({ state: 'consultation-document', linkTo, id: route.params.id });
          }}
        />

      </MaxWidthContainer>
      <CustomModal
        title={"Vous pouvez désormais fixer le montant de la consultation. Nous vous conseillons d'informer l'éleveur avant de confirmer la facturation."}
        before={<></>}
        noSafeArea
        visible={state !== undefined}
        scrollable={false}
        heightPercentage={Platform.OS === 'web' ? (showMessage ? 0.325 : 0.28) : (showMessage ? 0.62 : 0.45)}
        onClose={() => { setState(undefined); setShowMessage(false); }}
        insideModalStyle={{ padding: 20 }}
      >
        <Form<ModifyPriceConsultation> {...modifyPriceConsultation} defaultValues={vetOffice}>
          <View style={{ justifyContent: 'flex-start' }}>

            <View style={{ marginTop: 20, height: 55 }}>
              <TextInput
                keyboardType="numeric"
                placeholder="Saisissez le montant"
                name="priceConsultation"
                moneySign
                validators={[
                  AvailableValidationRules.required,
                ]}
              />
            </View>
            {vetOffice && showMessage && (
              <Text status="danger" category="s2" style={{ textAlign: 'center', marginTop: 5 }}>
                {showMessage === true ? `Le prix que vous avez renseigné est supérieur au montant maximum de ${vetOffice.maxPriceConsultation} €. Vous pouvez modifier ce montant dans les paramètres de votre cabinet.` : showMessage}
              </Text>
            )}
            <Button
              onPress={modifyPriceConsultation.handleSubmit(
                (data) => {
                  setLoading(true);
                  invoiceConsultation(data);
                },
              )}
            >
              Confirmer le montant
            </Button>
          </View>
        </Form>
      </CustomModal>
      <CustomModal
        title={`Vous êtes sur le point de ${openDelete !== 'share facture' ? 'supprimer la facture de consultation.' : 'partager une facture à cet élevage.'} `
          + '\n'
          + 'Souhaitez vous continuer ?'}
        before={<></>}
        noSafeArea
        visible={openDelete !== undefined}
        scrollable={false}
        onClose={() => { setOpenDelete(undefined); }}
        insideModalStyle={{ padding: 20 }}
        buttonText={openDelete !== 'share facture' ? 'Supprimer' : 'Partager'}
        heightPercentage={0.36}
        buttonPress={() => { openDelete === 'share facture' && shareFactureToConsultation(); }}
      />
    </>
  );
};

export default VetoDetailsConsultationScreen;
