import { LockedStatus } from '../src/API';

export const LockedStatusLabels = [
  {
    key: LockedStatus.unpaid,
    label: 'Bloqué pour impayé',
  },
  {
    key: LockedStatus.unlocked,
    label: 'Actif',
  },
  {
    key: LockedStatus.initialControlNeed,
    label: 'Contrôle initial nécessaire',
  },
  {
    key: LockedStatus.otherLockReason,
    label: 'Bloqué pour une autre raison',
  },
];

export const getLockedStatusLabel = (locked: LockedStatus) => {
  const currentStatus = LockedStatusLabels.find(({ key }) => key === locked);
  if (currentStatus) {
    return currentStatus.label;
  }
  return undefined;
};
