import React, { useEffect } from 'react';

import { useLinkTo } from '@react-navigation/native';

import { Text } from '@ui-kitten/components';

import { Platform } from 'react-native';
import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import MaxWidthContainer from '../../../components/MaxWidthContainer';
import TableauDeBordHeaderCard from '../../../screenComponents/TableauDeBordScreenComps/TableauDeBordHeaderCard';

import InfoCard from '../../../screenComponents/InfoCard';

import VetoListTeleconsultationCards from '../../../screenComponents/VetScreens/VetoListTeleconsultationCards';
import GoCustomCard from '../../../components/GoCustomCard';
import VetoMonCabinetScreenBL
  from '../../../screenBusinessLogic/vetoScreensBL/MonCabinetScreenBL/VetoMonCabinetScreenBL';
import TeleconsultationCard from '../../../components/TeleconsultationCard';
import CompButton from '../../../components/Button';
import VetoPaiementCard from '../../../screenComponents/VetScreens/TableauDeBordScreenComps/VetoPaiementCard';
import { useBreedingList } from '../../../src/API/Breeding';
import { useUser } from '../../../src/API/UserContext';
import { BSE, Consultation, StatusBse } from '../../../src/API';
import { useUpdateUser } from '../../../src/API/User';
import Tutorial from '../../../components/Tutorial';

export default function VetoTableauDeBordScreen() {
  const linkTo = useLinkTo();
  const { user } = useUser();
  const { modifyUser } = useUpdateUser();
  const { breedings } = useBreedingList(user?.userGroup);
  const consultations:[Consultation][] = [];
  const teleCons:Consultation[] = [];
  const bse:BSE[] = [];

  if (breedings) {
    breedings.map(
      (item) => item.consultations && item.consultations.items && item.consultations.items
        .map((item2) => {
          if (item2 && item2.shared) consultations.push(item2);
          // console.log('Veto TDB consultations:', item2);
          return () => {};
        }),
    );

    breedings.map(
      (item) => item.consultations && item.consultations.items && item.consultations.items
        .map((item2) => {
          if (item2 && item2.teleconsultation) {
            teleCons.push(item2);
          }
          // console.log('2 Veto TDB consultations:', item2);
          return () => {};
        }),
    );

    breedings.map(
      (item) => item.bse && item.bse.items && item.bse.items
        .map((item2) => {
          if (item2 && item2.StatusBse === StatusBse.BSEAsk) {
            bse.push(item2);
          // console.log('3 Veto TDB consultations:', item2);
          }
          return () => {};
        }),
    );
  }

  /** NOTIFICATIONS */
  useEffect(() => {
    (async () => {
      if (Platform.OS !== 'web' && Constants.isDevice && user) {
        const { status: existingStatus } = await Notifications.getPermissionsAsync();
        let finalStatus = existingStatus;
        if (existingStatus !== 'granted') {
          const { status } = await Notifications.requestPermissionsAsync();
          finalStatus = status;
        }
        if (finalStatus === 'granted') {
          const token = (await Notifications.getExpoPushTokenAsync({ experienceId: '@djbuch/scanflock' })).data;
          // console.log('token', token);
          const newTokens = user?.expoToken || [];
          // console.log('newTokens', newTokens);
          if (newTokens.indexOf(token) <= -1) {
            newTokens.push(token);
            // const justUpdatedUser =
            await modifyUser({
              variables: {
                input: {
                  id: user.id,
                  // eslint-disable-next-line no-underscore-dangle
                  _version: user._version,
                  expoToken: newTokens,
                },
              },
            });
          }
        }
        if (finalStatus !== 'granted') {
          // console.log('Failed to get push token for push notification!');
        }
      }
    })();

    return () => {};
  }, [user]);

  return (
    <MaxWidthContainer
      withScrollView="keyboardAware"
      outerViewProps={{
        style: {
          paddingHorizontal: 20,
        },
      }}
    >
      <TableauDeBordHeaderCard veto linkTo={() => {}} />

      <InfoCard
        title="Bienvenue sur votre tableau de bord"
        iconShow="alert-circle-outline"
        // iconCloseShow="close-outline"
        description={`Visualisez les derniers auto-examens partagés avec votre cabinet, les rendez-vous de type téléconsultation et/ou bilan sanitaire d'élevage à venir ou demandés par les éleveurs (vous devrez alors confirmer et planifier le rendez-vous).
        \n
Vous serez informé des rendez-vous à venir et nous vous adresserons des rappels la veille et le jour même.  Lorsque un rendez-vous est en cours, vous pouvez le démarrer directement depuis le tableau de bord.
        \n
Accédez à votre solde de paiements des prestations de conseil et/ou des téléconsultations, que vous pouvez transférer à tout moment sur votre compte bancaire.`}
        alertInfo="alertInfo"
      />

      <Text category="h1" style={{ marginTop: 30 }}>Consultations</Text>
      <Text category="h2" appearance="hint" style={{ marginBottom: 5 }}>Dernières consultations partagées avec le cabinet</Text>

      { breedings && consultations && consultations.length > 0 ? (
        consultations.map((item) => <TeleconsultationCard veto key={item?.id} consultationData={item} consultation="consultationCreated" back="veto-tableau-de-bord" />)
      ) : (
        <InfoCard
          title="alertInfo"
          iconShow="alert-circle-outline"
          // iconCloseShow="close-outline"
          description={'Aucune consultation n\'a été partagée avec votre cabinet'}
          alertInfo="alertInfo"
        />
      )}

      <CompButton
        size="medium"
        status="primary"
        appearance="outline"
        onPress={() => { linkTo('/veto-examen'); }}
      >
        Voir plus de consultations
      </CompButton>
      <Text category="h1" style={{ marginTop: 30 }}>Téléconsultation</Text>
      <Tutorial
        videoId="arQnAd6eWGI"
      />
      <InfoCard
        title="alertInfo"
        description="Cette fonctionnalité vous permet de réaliser une téléconsultation à la demande d’un éleveur adhérent, ou à votre initiative suite à un auto-examen partagé en lui proposant de vous adresser une demande de téléconsultation."
        imagePosition="right"
        imageUri={require('../../../assets/veto_ordi_r.png')}
        infoStyle={{ marginTop: 5, padding: 20 }}
      />

      <VetoListTeleconsultationCards tdb veto="veto" teleconsultationData={teleCons} bseData={bse} />

      <Text category="h1" style={{ marginTop: 20 }}>Paiements</Text>
      <VetoPaiementCard />

      <GoCustomCard title1="Factures" linkToCustom={() => { VetoMonCabinetScreenBL.allerMonCompteScreen({ state: 'facture', linkTo }); }} minusSquareOutline="arrow-ios-forward" />

      <Text category="h1" style={{ marginTop: 20 }}>FAQ</Text>
      <InfoCard
        title="alertInfo"
        description={"Consultez notre FAQ afin d'obtenir une réponse sur "
            + "les questions les plus fréquemment posées à propos de l'application."}
        imagePosition="right"
        imageUri={require('../../../assets/FAQ_veterinaire.png')}
        buttonText="Consulter la foire aux questions"
        buttonOnPress={() => {
          linkTo('/faq');
        }}
        infoStyle={{ padding: 20 }}
      />
      <Text category="p1">
        v
        {Constants.manifest.version}
      </Text>
    </MaxWidthContainer>
  );
}
