import * as React from 'react';
import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import {
  StyleSheet, View,
} from 'react-native';
import { Text } from '@ui-kitten/components';
import ReactQuill from 'react-quill';
import { Input } from '../UIKittenRewrite/Input';
import { RichTextInputFormProps } from './types';
import { AvailableValidationRules } from './validation';
import 'react-quill/dist/quill.snow.css';

const FRichTextInput = React.forwardRef<Input, RichTextInputFormProps>(
  (props: RichTextInputFormProps, ref): React.ReactElement => {
    const {
      title,
      titleStyle,
      label,
      icon,
      labelStyle,
      error,
      onChangeValue,
      onChangeText,
      style,
      defaultValue,
      containerStyle,
      keyboardType,
      validators,
      placeholder,
      showAsterix = true,
      appendTitle = (<></>),
      ...inputProps
    } = props;

    const realDefaultValue: string | undefined = defaultValue || inputProps.value;

    const reactQuillRef: any = useRef(null);

    const [inputValue, setInputValue] = useState<string | undefined>(realDefaultValue);

    useEffect(() => {
      setInputValue(realDefaultValue);
    }, [defaultValue, inputProps.value]);

    const richTextHandle = (descriptionText: string) => {
      if (descriptionText) {
        setInputValue(descriptionText);
        if (onChangeValue) {
          onChangeValue(descriptionText);
        }
      } else {
        setInputValue('');
        if (onChangeValue) {
          onChangeValue('');
        }
      }
    };

    const required = ((showAsterix && validators && validators.indexOf(AvailableValidationRules.required) > -1) ? ' *' : '');

    const imageHandler = () => {
      const quill = reactQuillRef?.current?.getEditor();
      const { tooltip } = quill.theme;
      const originalSave = tooltip.save;
      const originalHide = tooltip.hide;
      tooltip.save = function () {
        const range = this.quill.getSelection(true);
        const { value } = this.textbox;
        if (value) {
          this.quill.insertEmbed(range.index, 'image', value, 'user');
        }
      };
      // Called on hide and save.
      tooltip.hide = function (this: any) {
        tooltip.save = originalSave;
        tooltip.hide = originalHide;
        tooltip.hide();
      };
      tooltip.edit('image');
      tooltip.textbox.placeholder = 'URL de l\'image';
    };

    const modules = useMemo(() => ({
      clipboard: {
        matchVisual: false,
      },
      toolbar: {
        container: [[{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'link'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['image'],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }), []);

    return (
      <View style={[styles.container, containerStyle, title ? ({ flexDirection: 'column' }) : ({ flexDirection: 'row' })]}>
        {title && (
        <View style={{ flexDirection: 'row' }}>
          <Text category="h5" style={[{ marginBottom: 5 }, titleStyle]}>{title + required}</Text>
          {appendTitle}
        </View>
        )}
        <View style={{ flex: 1, flexDirection: 'row' }}>
          <View style={styles.container}>
            <View style={styles.richTextContainer}>
              <ReactQuill
                theme="snow"
                ref={reactQuillRef}
                value={inputValue}
                onChange={richTextHandle}
                style={{
                  flex: 1,
                  flexShrink: 1,
                  flexBasis: 'auto',
                  flexGrow: 0,
                  flexDirection: 'column',
                  display: 'flex',
                }}
                modules={modules}
              />
            </View>
          </View>
        </View>
      </View>
    );
  },
);

FRichTextInput.displayName = 'RichTextInput';

export default FRichTextInput;

const styles = StyleSheet.create({
  container: {
    marginBottom: 15,
    flex: 1,
    flexShrink: 1,
    flexBasis: 'auto',
    flexGrow: 0,
    width: '100%',
  },
  richTextContainer: {
    width: '100%',
    backgroundColor: 'white',
  },
});
