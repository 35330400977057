import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';
import { AdminDiseaseParamList } from '../../types';
import AdminDiseasesScreen from '../../screens/AdminScreens/DiseasesScreen/AdminDiseasesScreen';
import AdminDetailsDiseaseScreen
  from '../../screens/AdminScreens/DiseasesScreen/AdminDetailsDiseaseScreen';

const Stack = createNativeStackNavigator<AdminDiseaseParamList>();

export default () => (
  <Stack.Navigator
    initialRouteName="admin-diseases"
    screenOptions={{
      headerShown: false,
    }}
  >
    <Stack.Screen
      name="admin-diseases"
      component={AdminDiseasesScreen}
    />
    <Stack.Screen
      name="admin-details-disease"
      component={AdminDetailsDiseaseScreen}
    />
  </Stack.Navigator>
);
