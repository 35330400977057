import React, { useEffect, useState } from 'react';
import { Text, useTheme } from '@ui-kitten/components';
import { StackActions, useLinkTo } from '@react-navigation/native';
import { Icon as IconUIKitten } from '@ui-kitten/components/ui/icon/icon.component';
import { TouchableOpacity, View } from 'react-native';
import moment from 'moment';
import TeleconsultationCard from '../components/TeleconsultationCard';
import CompButton from '../components/Button';

import InfoCard from './InfoCard';
import ElevageScreenBL from '../screenBusinessLogic/ElevageScreen/ElevageScreenBL';

import SearchComponent from './SearchComponent';
import RotatingIcon from '../components/Icon/RotatingIcon';
import DateUtils from '../utils/DateUtils';

import {
  useCreateConsultationMutation,
  useListConsultationsByBreedinG,
} from '../src/API/Consultation';
import { useBreedingList } from '../src/API/Breeding';
import { useUser } from '../src/API/UserContext';
import { Breeding, LockedStatus } from '../src/API';
import CustomModal from '../components/Modals/CustomModal';
import Tutorial from '../components/Tutorial';

type TdbProps = {
  hide?: string;
  limit: number;
  isTDB: boolean;
  breeding?: Breeding | null;
  // currentNextToken?: string | null;
  // consultationS?: Consultation | null,
  // fetchMore?: () => Promise<ApolloQueryResult<ListConsultationsByBreedingQuery>> | null
};

const ListTeleconsultationCards = ({
  hide, limit = 10000, isTDB = false, breeding,
  // consultationS: consultScreenConsultationS,
}: TdbProps) => {
  const linkTo = useLinkTo();
  const [opened, setOpened] = useState(false);
  const theme = useTheme();

  // console.log('breeding2', breeding);

  let consultationS;

  const [openModalUnsubscrib, setOpenModalUnsubscrib] = useState(false);
  const createConsultation = useCreateConsultationMutation();

  const {
    consultationS: consultationSvar, nextToken, fetchMoreConsultations,
    /** à finir fetchmore */
  } = useListConsultationsByBreedinG(limit, null, 'network-only', breeding);
  // console.log('consultationSvar', consultationSvar);

  const currentNextToken = nextToken;
  /**    Afficher seulement quand c'est en cours         */
  // console.log(today, ' versus ', minusCinq);
  let teleconsEnCours;
  let teleconsDemande;
  let teleconsultationProchaines;
  consultationS = consultationSvar?.filter((item) => !item._deleted);
  if (isTDB && consultationS) {
    consultationS = consultationS.slice(0, 3);
    // console.log(consultationS);
  }
  useEffect(() => {
    let interval;

    // console.log('nextToken', nextToken);
    if (nextToken && !isTDB) {
      // console.log('next:', nextToken);
      interval = setInterval(() => {
        // console.log('This will run every 2 second!');

        if (fetchMoreConsultations) {
          fetchMoreConsultations();
        }
      }, 500);
    }
    // console.log(consultationS);
    return () => clearInterval(interval);
  }, [consultationSvar]);

  // console.log(consultationS);
  if (consultationS) {
    teleconsEnCours = DateUtils.verificationDateTelecons('enCours', consultationS);
    teleconsultationProchaines = DateUtils.verificationDateTelecons('aVenirs', consultationS);

    teleconsDemande = consultationS?.filter((item) => {
      if (item?.dateTeleCons === null && item?.teleconsultation && !item?._deleted) {
        return item;
      }
      return false;
    });
  }

  const [openDemandeTelecon, setOpenDemandeTelecon] = useState(false);
  const faireDemandeTelecons = async () => {
    const now = moment(new Date()).format('YYYY-MM-DD');
    await createConsultation.createConsultation({
      variables: {
        input: {
          breedingId: breeding.id,
          teleconsultation: true,
          teleconsAskAt: now,
        },
      },
    });

    setOpenDemandeTelecon(false);
  };

  // console.log('teleconsultationProchaines', teleconsultationProchaines);

  return (
    <>

      {hide === 'tdbShow' && (
        <>
          <Text category="h2" appearance="hint" style={{ marginTop: 8 }}>Dernières consultations créées</Text>
          {consultationS && consultationS.length > 0
            ? (consultationS?.map((eachConsultation) => (<TeleconsultationCard key={eachConsultation?.id} consultationData={eachConsultation} consultation="consultationCreated" detailConsultationPage={false} back="tableau-de-bord" />)))
            : <Text category="h4">Vous n'avez pas de consultation</Text>}

          {consultationS && consultationS.length >= 3
            ? (
              <CompButton
                size="medium"
                status="primary"
                // leftIcon="plus-outline"
                appearance="outline"
                onPress={() => { linkTo('/consultation'); }}
              >
                Voir toutes les consultations
              </CompButton>
            )
            : (<></>)}

          <Text category="h1" style={{ marginTop: 40, marginBottom: 7 }}>Auto-examen</Text>

          <Tutorial
            videoId="Tc59C2nmzxA"
          />

          <InfoCard
            title="alertInfo"
            description={'L’auto-examen se présente sous la forme d’un questionnaire guidé à choix unique ◉ ou multiple ☑ divisé en 4 grandes parties : historique, localisation et ciblage, examen général, examen par région anatomique.\n'}
            description2="Le déroulé du questionnaire s’adapte en fonction de vos réponses que vous pouvez ignorer en cas de doute."
            imagePosition="right"
            imageUri={require('../assets/eleveur.png')}
            buttonText="Réaliser un auto-examen"
            buttonOnPress={() => {
              if (breeding.locked === LockedStatus.unlocked) {
                linkTo(`/consultation/realiser-auto-exam/${breeding.id}?back=tableau-de-bord`);
              } else {
                setOpenModalUnsubscrib(true);
              }
            }}
            infoStyle={{ padding: 20 }}
          />

          <Text category="h1" style={{ marginTop: 40, marginBottom: 7 }}>Téléconsultation</Text>
          <Tutorial
            videoId="arQnAd6eWGI"
          />
          <InfoCard
            title="alertInfo"
            description="Cette fonctionnalité vous permet de demander à votre vétérinaire un rendez-vous pour une téléconsultation."
            imagePosition="left"
            imageUri={require('../assets/veto_ordi.png')}
            buttonText="Faire une demande de téléconsultation"
            buttonOnPress={() => {
              if (breeding.locked === LockedStatus.unlocked) {
                linkTo(`/tableau-de-bord/faire-demande-teleconsultation/${breeding.id}?back=tableau-de-bord`);
              } else {
                setOpenModalUnsubscrib(true);
              }
              // setOpenDemandeTelecon(true);
            }}
            infoStyle={{ padding: 20, borderColor: '#0A3A51' }}
          />
        </>
      )}

      <Text category="h2" appearance="hint" style={{ marginTop: 20 }}>Téléconsultations en cours</Text>
      {teleconsEnCours && teleconsEnCours.length > 0
        ? (teleconsEnCours.map((item) => <TeleconsultationCard detailConsultationPage={false} key={item?.id} consultationData={item} consultation="RDV" back="tableau-de-bord" />))
        : (
          <InfoCard
            title="alertInfo"
            iconShow="alert-circle-outline"
                  // iconCloseShow="close-outline"
            infoStyle={{ backgroundColor: theme['color-primary-100'] }}
            description={"Vous n'avez pas de téléconsultation en cours."}
            alertInfo="alertInfo"
          />
        )}

      {hide === 'tdbShow' && (
      <>
        <Text category="h2" appearance="hint" style={{ marginTop: 20 }}>Vos demandes de téléconsultations</Text>
        {teleconsDemande && teleconsDemande.length > 0
          ? (teleconsDemande.map((item) => <TeleconsultationCard detailConsultationPage={false} key={item?.id} consultationData={item} consultation="RDV" back="tableau-de-bord" />))
          : (
            <InfoCard
              title="alertInfo"
              iconShow="alert-circle-outline"
                        // iconCloseShow="close-outline"
              infoStyle={{ backgroundColor: theme['color-primary-100'] }}
              description={"Vous n'avez aucune demande de téléconsultation."}
              alertInfo="alertInfo"
            />
          )}

        <Text category="h2" appearance="hint" style={{ marginTop: 20 }}>Prochaines téléconsultations</Text>
        {teleconsultationProchaines && teleconsultationProchaines.length > 0
          ? (teleconsultationProchaines.map((item) => <TeleconsultationCard detailConsultationPage={false} key={item?.id} consultationData={item} consultation="RDV" back="tableau-de-bord" />))
          : (
            <InfoCard
              title="alertInfo"
              iconShow="alert-circle-outline"
                        // iconCloseShow="close-outline"
              infoStyle={{ backgroundColor: theme['color-primary-100'] }}
              description={"Vous n'avez pas de téléconsultations programmées."}
              alertInfo="alertInfo"
            />
          )}
      </>
      )}

      {hide === 'consultationScreenShow' && (
      <>
        <Text category="h2" appearance="hint" style={{ marginTop: 20, marginBottom: 10 }}>Dernières consultations</Text>

        <TouchableOpacity style={{ flexDirection: 'row', justifyContent: 'space-between', marginVertical: 10 }} onPress={() => setOpened(!opened)}>
          <View style={{ flexDirection: 'row' }}>
            <IconUIKitten
              name="options-outline"
              fill="#000000"
              style={{
                height: 20, width: 20, marginRight: 5,
              }}
            />
            <Text>Filtrer</Text>
          </View>

          <RotatingIcon name="arrow-ios-downward-outline" uikitten state={opened} width={20} height={20} fill="#000" />
        </TouchableOpacity>

        <SearchComponent
          state="consultations"
          opened={opened}
          searchButton // hide button and automatic charging of data
          data={consultationS}
          // fetchMore={fetchMore}
          currentNextToken={currentNextToken}
          keyExtractor={(item) => item.id}
          placeholder={ElevageScreenBL.placeholderTitle('consultations')}
          renderItem={(item) => {
            if (item.item) {
              return (<TeleconsultationCard key={item?.item.id} consultationData={item?.item} consultation="consultationCreated" />);
            }
            return (
              <InfoCard
                title="alertInfo"
                iconShow="alert-circle-outline"
                          // iconCloseShow="close-outline"
                infoStyle={{ backgroundColor: theme['color-primary-100'] }}
                description={"Vous n'avez pas de téléconsultation."}
                alertInfo="alertInfo"
              />
            );
          }}
        />
      </>
      )}
      <CustomModal
        title={'Vous êtes sur le point de faire une demande de téléconsultation à votre vétérinaire.'
          + '\n'}
        subtitle="Souhaitez-vous continuer ?"
        before={<></>}
        noSafeArea
        visible={openDemandeTelecon}
        scrollable={false}
        onClose={() => setOpenDemandeTelecon(false)}
        insideModalStyle={{ textAlign: 'center', padding: 20 }}
        buttonText="Continuer"
        secondButtonText="Annuler"
        heightPercentage={0.46}
        buttonPress={() => { faireDemandeTelecons(); }}
      />
      <CustomModal
        title="Votre abonnement est terminé. Abonnez vous pour accéder à cette fonctionalité"
        before={<></>}
        noSafeArea
        visible={openModalUnsubscrib}
        scrollable={false}
        onClose={() => setOpenModalUnsubscrib(false)}
        insideModalStyle={{ textAlign: 'center', padding: 20 }}
        buttonText="Continuer"
        heightPercentage={0.46}
        buttonPress={() => {
          setOpenModalUnsubscrib(false);
          linkTo('/mon-compte/mode-paiement');
        }}
      />

    </>
  );
};

export default ListTeleconsultationCards;
