import React, {useEffect, useState} from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { Text, useTheme } from '@ui-kitten/components';
import { useLinkTo, useNavigation, useRoute } from '@react-navigation/native';
import { useForm } from 'react-hook-form';
import { useDimensions } from '@react-native-community/hooks';
import Auth from '@aws-amplify/auth';

import { RouteProp } from '@react-navigation/core/lib/typescript/src/types';
import TextInput from '../../components/Form/TextInput';
import MaxWidthContainer from '../../components/MaxWidthContainer';
import Form from '../../components/Form/Form';

import { AvailableValidationRules } from '../../components/Form/validation';
import { useUser } from '../../src/API/UserContext';
import Button from '../../components/Button';

import { AuthStyles } from '../../components/Auth/styles';
import useCreateBreedingMutation, {
  useBreedingList,
  useUpdateBreedingMutation,
} from '../../src/API/Breeding';
import {
  useCreateVetOfficeMutation,
  useGetVetOffice,
  useUpdateVetOfficeMutation,
} from '../../src/API/VetOffice';
import { LockedStatus, VetOffice } from '../../src/API';
import { TabMonCompteParamList, VetoTabMonCabinetParamList } from '../../types';

type InscriptionEtape2Form = {
  companyName: string
  phone?: string
  address?: {
    address: string;
    additionalAddress?: string | null;
    city: string;
    postalCode: string;
  } | null;
  vetProfile?: {
    emailOffice: string
    nOrdreCabinet: string
    nOrdreCabinetPerso: string
    nSIRET: string
    maxPriceTeleConsultation: number
    maxPriceConsultation: number
    phoneVetOffice: string
  }
};

const InscriptionEtape2Screen = () => {
  const inscriptionEtape2Form = useForm<InscriptionEtape2Form>();
  const navigation = useNavigation();
  const linkTo = useLinkTo();

  const route = useRoute<
  RouteProp<TabMonCompteParamList, 'inscription-etape-2'>
  |
  RouteProp<VetoTabMonCabinetParamList, 'veto-modify-info-perso'>
  >();
  let boolSignUp: boolean = true;
  if (route && route.params && route.params?.signUp === 'false') {
    boolSignUp = false;
  }

  const { createUser, user, updateUser, cognitoUser } = useUser();
  const { createBreeding } = useCreateBreedingMutation();
  const { createNewVetOffice } = useCreateVetOfficeMutation();
  const authUser = cognitoUser?.attributes['custom:groupe'] || 'eleveur';

  const [loading, setLoading] = useState(false);
  const { window } = useDimensions();
  let defaultUser: InscriptionEtape2Form | undefined;

  const { vetOfficeData: vetData } = useGetVetOffice();
  const vetOfficeData = vetData as (VetOffice | undefined | null);

  const { breeding } = useBreedingList(authUser);
  const unBreeding = breeding;

  if (authUser === 'veto' && vetOfficeData) {
    defaultUser = {
      companyName: vetOfficeData.companyName,
      address: {
        address: vetOfficeData.address.address,
        additionalAddress: vetOfficeData.address.additionalAddress,
        city: vetOfficeData.address.city,
        postalCode: vetOfficeData.address.postalCode,
      },
      vetProfile: {
        emailOffice: vetOfficeData.emailOffice,
        nOrdreCabinet: vetOfficeData?.nOrdreCabinet,
        nOrdreCabinetPerso: user?.inscriptionNumberOrdreVeto,
        nSIRET: vetOfficeData.nSIRET,
        maxPriceTeleConsultation: vetOfficeData.maxPriceTeleConsultation,
        maxPriceConsultation: vetOfficeData.maxPriceConsultation,
        phoneVetOffice: vetOfficeData.phoneVetOffice,
      },
    };
  }

  if (authUser === 'eleveur' && unBreeding && unBreeding.address) {
    defaultUser = {
      companyName: unBreeding.companyName,
      phone: unBreeding.phone,
      address: {
        address: unBreeding.address.address,
        additionalAddress: unBreeding.address.additionalAddress,
        city: unBreeding.address?.city,
        postalCode: unBreeding.address?.postalCode,
      },
    };
  }

  /**  CRUD FUNCTIONS   */
  const onPress = (data: InscriptionEtape2Form) => {
    if (defaultUser === undefined) {
      createUserFunction(data);
    } else {
      updateVetOfficeFunction(data);
    }
  };

  const createUserFunction = async (data: InscriptionEtape2Form) => {
    if (route.params && route.params.signUp) {
      if (createUser) {
      // console.log('createUser, updateUser, user', createUser, updateUser, user);
        let createdUser = user;
        if (!user) {
          const { data: newUser } = await createUser({});
          createdUser = newUser.createUser;
        }
        // console.log('new user : ', newUser);
        /**     CREATE VETO      */
        // console.log('test :', newUser);
        if (authUser === 'veto' && data && data.vetProfile && createdUser) {
        // console.log('créer un vetOffice');
          const nameVetSanitaire = `${createdUser.firstname} ${createdUser.lastname}`;
          const { data: vetOffice } = await createNewVetOffice({
            variables: {
              input: {
                companyName: data.companyName,
                emailOffice: data.vetProfile.emailOffice,
                nOrdreCabinet: data.vetProfile.nOrdreCabinet,
                nSIRET: data.vetProfile.nSIRET,
                maxPriceTeleConsultation: data.vetProfile.maxPriceTeleConsultation,
                maxPriceConsultation: data.vetProfile.maxPriceConsultation,
                address: data.address,
                nameVetSanitaire,
                admins: [createdUser.id],
                phoneVetOffice: data.vetProfile.phoneVetOffice,
                locked: LockedStatus.initialControlNeed,
              },
            },
          });

          if (updateUser) {
            await updateUser({
              inscriptionNumberOrdreVeto: data.vetProfile.nOrdreCabinetPerso,
            }, createdUser);
          }

          navigation.navigate('veto-inscription-etape-3', {
            signUp: true,
          // userGroup: authUser,
          });
          /* if (updateUser && vetOffice?.createVetOffice) {
            await updateUser({
              vetOfficeId: vetOffice?.createVetOffice?.id,
              userGroup: 'veto',
            }, newUser.createUser);
          } */

        /**     CREATE ELEVEUR      */
        } else if (
          authUser === 'eleveur' && createdUser && createdUser.email && data.phone
        ) {
          // console.log('créer un vetOffice');
          await createBreeding({
            variables: {
              input: {
                nBreeding: '',
                nSIRET: '',
                address: data.address,
                companyName: data.companyName,
                dairyFarm: false,
                sucklerCowHusbandry: false,
                fatteningFarm: false,
                nbCow: 0,
                nbHeifer: 0,
                nbMale: 0,
                nbYoung: 0,
                admins: [createdUser.id],
                phone: data.phone,
                emailEleveur: createdUser.email,
                locked: LockedStatus.initialControlNeed,
              },
            },
          });

          // console.log('passage');
          navigation.navigate('inscription-etape-3', {
            signUp: true,
          });
        }
      }
    }
  };

  /**     Done on another screen: ModifyInfoPerso, ModifyEmailScreen    */
  // const updateUserFunction = () => {};

  /**  if modify Veto infor cabinet   */
  const { updateVeterinaryOffice } = useUpdateVetOfficeMutation();
  const { modifyBreeding } = useUpdateBreedingMutation();

  const updateVetOfficeFunction = async (data: InscriptionEtape2Form) => {
    if (vetOfficeData && authUser === 'veto' && data.vetProfile) {
      // await updateUser(data);
      await updateVeterinaryOffice({
        variables: {
          input: {
            id: vetOfficeData.id,
            nOrdreCabinet: data.vetProfile.nOrdreCabinet,
            nSIRET: data.vetProfile.nSIRET,
            companyName: data.companyName,
            address: data.address,
            emailOffice: data.vetProfile.emailOffice,
            phoneVetOffice: data.vetProfile.phoneVetOffice,
            // maxPriceTeleConsultation: data.vetProfile.maxPriceTeleConsultation,
            // maxPriceConsultation: data.vetProfile.maxPriceConsultation,
            // eslint-disable-next-line no-underscore-dangle
            _version: vetOfficeData._version,
          },
        },
      });

      if (updateUser && boolSignUp && user) {
        await updateUser({
          inscriptionNumberOrdreVeto: data.vetProfile.nOrdreCabinetPerso,
        });
      }
      if (!boolSignUp) {
        linkTo('/veto-mon-cabinet');
      } else {
        navigation.navigate('veto-inscription-etape-3', {
          signUp: true,
          // userGroup: authUser,
        });
      }
    } else if (unBreeding && authUser === 'eleveur') {
      await modifyBreeding({
        variables: {
          input: {
            id: unBreeding.id,
            address: data.address,
            companyName: data.companyName,
            phone: data.phone,
            _version: unBreeding._version,
          },
        },
      });

      navigation.navigate('inscription-etape-3', {
        signUp: true,
        // userGroup: authUser,
      });
    }
  };

  return (
    <MaxWidthContainer
      withScrollView="keyboardAware"
      innerViewProps={{
        style: {
          paddingHorizontal: 15,
          paddingVertical: 34,
        },
      }}
    >
      <Form<InscriptionEtape2Form>
        {...inscriptionEtape2Form}
      >
        <>

          {boolSignUp
            ? (
              <>
                <View style={{
                  alignItems: 'center',
                }}
                >
                  <Text category="h2" style={[AuthStyles.header, { textAlign: 'center' }]}>{`Je m'inscris en tant ${authUser === 'eleveur' ? "qu'éleveur" : 'que vétérinaire'}`}</Text>
                  <Text category="p1" appearance="hint" style={{ marginVertical: 20 }}>{authUser === 'eleveur' ? 'Etape 2/4' : 'Etape 2/3'}</Text>
                </View>
              </>
            )
            : (<></>)}

          {authUser === 'veto' && (
            <View style={Platform.OS === 'web' && window.width > 560 && ({ flexDirection: 'row' })}>
              <TextInput
                title={"N° d'ordre du cabinet"}
                style={[Platform.OS === 'web' && window.width > 560 && ({ marginRight: 20 }), { marginBottom: 20 }]}
                name="vetProfile.nOrdreCabinet"
                placeholder="Saisissez le numéro d'ordre du cabinet"
                validators={[
                  AvailableValidationRules.required,
                ]}
              />
              <TextInput
                title="N° de SIREN du cabinet"
                name="vetProfile.nSIRET"
                placeholder="Saisissez le n°SIREN du cabinet"
                style={{ marginBottom: 20 }}
                validators={[
                  AvailableValidationRules.required,
                ]}
              />
            </View>
          )}

          <View style={Platform.OS === 'web' && window.width > 560 && ({ flexDirection: 'row' })}>
            <TextInput
              title={authUser === 'veto' ? 'Raison sociale' : 'Nom de l\'exploitation'}
              name="companyName"
              placeholder={authUser === 'veto' ? 'Saisissez la raison sociale' : 'Saisissez le nom de l\'exploitation'}
              style={[Platform.OS === 'web' && window.width > 560 && ({ marginRight: 20 }), { marginBottom: 20 }]}
              validators={[
                AvailableValidationRules.required,
              ]}
            />
            <TextInput
              title="Adresse"
              name="address.address"
              placeholder={`Saisissez l'adresse ${authUser === 'veto' ? 'du cabinet' : 'de l\'élevage'}`}
              style={{ marginBottom: 20 }}
              validators={[
                AvailableValidationRules.required,
              ]}
            />
          </View>

          <View style={Platform.OS === 'web' && window.width > 560 && ({ flexDirection: 'row' })}>
            <TextInput
              title={"Saisissez le complément d'adresse"}
              name="address.additionalAddress"
              placeholder="Saisissez le complément d'adresse"
              style={[Platform.OS === 'web' && window.width > 560 && ({ marginRight: 20 }), { marginBottom: 20 }]}
            />
            <TextInput
              title="Code Postal"
              name="address.postalCode"
              placeholder="Saisissez le code postal"
              style={{ marginBottom: 20 }}
              maxLength={5}
              validators={[
                AvailableValidationRules.required,
              ]}
            />
          </View>
          <View style={Platform.OS === 'web' && window.width > 560 && ({ flexDirection: 'row' })}>
            <TextInput
              title="Ville"
              name="address.city"
              placeholder="Saisissez votre ville"
              style={[Platform.OS === 'web' && window.width > 560 && ({ marginRight: 20 }), { marginBottom: 20 }]}
              validators={[
                AvailableValidationRules.required,
              ]}
            />
            {authUser !== 'veto' && (
              <TextInput
                title="Numéro de téléphone de l'élevage"
                name="phone"
                keyboardType="numeric"
                maxLength={10}
                placeholder="Saisissez le numéro de téléphone"
                style={{ marginBottom: 20 }}
                validators={[
                  AvailableValidationRules.required,
                ]}
              />
            )}

          </View>
          <View style={Platform.OS === 'web' && window.width > 560 && ({ flexDirection: 'row' })}>
            {authUser === 'veto' && (
              <TextInput
                title="E-mail du cabinet"
                name="vetProfile.emailOffice"
                placeholder="Saisissez l'e-mail du cabinet"
                style={[{ marginBottom: 20 }]}
                validators={[
                  AvailableValidationRules.required,
                ]}
              />
            )}
          </View>

          {authUser === 'veto' && (
            <View style={Platform.OS === 'web' && window.width > 560 && ({ flexDirection: 'row' })}>
              <TextInput
                title="Numéro de téléphone du cabinet"
                name="vetProfile.phoneVetOffice"
                keyboardType="numeric"
                maxLength={10}
                placeholder="Saisissez le téléphone du cabinet"
                style={[{ marginBottom: 20 }]}
              />
              {/**
               <TextInput
               title="Numéro de téléphone du vétérinaire"
               name="phoneVet"
               placeholder="Saisissez le téléphone du vétérinaire"
               style={{ marginBottom: 20 }}
               /> */}
            </View>
          )}

          {authUser === 'veto' && route && route.params && boolSignUp && (
            <>
              <View style={Platform.OS === 'web' && window.width > 560 && ({ flexDirection: 'row' })}>
                <TextInput
                  title="Tarif de la téléconsultation"
                  name="vetProfile.maxPriceTeleConsultation"
                  placeholder="Saisissez le montant"
                  containerStyle={[Platform.OS === 'web' && window.width > 560 && ({ marginRight: 20 }), { marginBottom: 20 }]}
                  validators={[
                    AvailableValidationRules.required,
                  ]}
                />
                <TextInput
                  title="Tarif de la prestation de conseil"
                  name="vetProfile.maxPriceConsultation"
                  placeholder="Saisissez le montant"
                  containerStyle={{ marginBottom: 20 }}
                  validators={[
                    AvailableValidationRules.required,
                  ]}
                />
                <TextInput
                  title="Votre N° d'ordre personnel"
                  containerStyle={[Platform.OS === 'web' && window.width > 560 && ({ marginLeft: 20 }), { marginBottom: 20 }]}
                  name="vetProfile.nOrdreCabinetPerso"
                  placeholder="Saisissez votre numéro d'ordre personnel"
                  validators={[
                    AvailableValidationRules.required,
                  ]}
                />
              </View>
            </>
          )}

          <View style={styles.buttonRight}>
            <Button loading={loading} loadingText="Chargement" onPress={inscriptionEtape2Form.handleSubmit((data) => { setLoading(true); onPress(data); })} size="large">
              {boolSignUp ? 'Suivant' : 'Valider'}
            </Button>
          </View>
          <Text category="c1" appearance="hint">
            * champs obligatoires
          </Text>
        </>
      </Form>

    </MaxWidthContainer>

  );
};

export default InscriptionEtape2Screen;

const styles = StyleSheet.create({
  buttonRight: { alignItems: 'flex-end', marginTop: 34 },
  title: {
    marginTop: 12,
    marginBottom: 39,
  },
});
