/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://fiaxwxumenhkrjni7igppv6jtu.appsync-api.eu-west-3.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-3",
    "aws_cloud_logic_custom": [
        {
            "endpoint": "https://vh58m1fd67.execute-api.eu-west-3.amazonaws.com/prod",
            "name": "scanflockrest",
            "region": "eu-west-3"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-3:570a5f3a-a073-43c8-b3fd-56a07254d4b7",
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyCharacters": [],
        "passwordPolicyMinLength": 8
    },
    "aws_cognito_region": "eu-west-3",
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "eu-west-3",
    "aws_dynamodb_table_schemas": [
        {
            "region": "eu-west-3",
            "tableName": "Counters-prod"
        }
    ],
    "aws_project_region": "eu-west-3",
    "aws_user_files_s3_bucket": "scanflockbucket155611-prod",
    "aws_user_files_s3_bucket_region": "eu-west-3",
    "aws_user_pools_id": "eu-west-3_FrHBwR0XL",
    "aws_user_pools_web_client_id": "6i4pkubknojf33ds06034fsh3o",
    "oauth": {}
};


export default awsmobile;
