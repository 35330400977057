import React, { useState } from 'react';
import { useLinkTo, useRoute } from '@react-navigation/native';
import { Spinner, Text, useTheme } from '@ui-kitten/components';

import { View } from 'react-native';
import moment from 'moment';
import Storage from '@aws-amplify/storage';
import { useApolloClient } from 'react-apollo';
import Button from '../../../components/Button';
import MaxWidthContainer from '../../../components/MaxWidthContainer';

import InfoCard from '../../../screenComponents/InfoCard';
import { useGetBse } from '../../../src/API/BSE';
import PSCard from '../../../screenComponents/VetScreens/BSEComps/PSCard';
import BseScreenBL from '../../../screenBusinessLogic/BseScreenBL/BseScreenBL';
import { templatePDF } from './templatePDF';
import bseQuestionConditionData from '../../../src/mockData/bseQuestionConditionData';
import pdfGenerator from '../../../utils/pdfGenerator/pdfGenerator';
import { Upload } from '../../../utils/S3FileStorage';
import { useUser } from '../../../src/API/UserContext';
import FileHelper from '../../../utils/FileHelper';
import CrudBL from '../../../screenBusinessLogic/ConsultationScreen/CrudBL';
import VetoBseScreenBL
  from '../../../screenBusinessLogic/vetoScreensBL/VetoBseScreenBL/VetoBseScreenBL';
import { StatusBse } from '../../../src/API';
import { getProtocolSoinSaveByBseIdAsync } from '../../../src/API/ProtocolSoin';
import client from '../../../src/Apollo';
import { getUserAsync } from '../../../src/API/User';

const VetoChoixPS = () => {
  const route = useRoute();
  const linkTo = useLinkTo();
  const apolloClient = useApolloClient();

  const { editBse } = CrudBL.useCrudBSE();
  const theme = useTheme();

  const { bse } = useGetBse(route.params.id);
  const { user } = useUser();
  const [generating, setGenerating] = useState(false);

  const getBgColor = (currentQuestionnaireData:any, questionId:string, answerId:string, value: string) => {
    let thisAnswer = currentQuestionnaireData[questionId]?.answers[answerId];
    if (thisAnswer.minVert.indexOf('${') > -1) {
      const possibleAnwsers = Object.values(currentQuestionnaireData[thisAnswer.minVert.replace('${', '').replace('}', '')].answers);
      thisAnswer = possibleAnwsers.find(({ checked }) => checked);
    }
    if (!thisAnswer) {
      return false;
    }

    const minVert = parseFloat(thisAnswer.minVert ?? '0');
    const maxVert = parseFloat(thisAnswer.maxVert ?? '0');
    const minOrange = parseFloat(thisAnswer.minOrange ?? '0');
    const maxOrange = parseFloat(thisAnswer.maxOrange ?? '0');
    const minRouge = parseFloat(thisAnswer.minRouge ?? '0');
    const maxRouge = parseFloat(thisAnswer.maxRouge ?? '0');

    const answerValue = parseFloat(value ?? '0');

    if (minVert <= answerValue
        && answerValue <= maxVert) {
      return (theme['color-success-400']);
    }
    if (minOrange <= answerValue
        && answerValue <= maxOrange) {
      return (theme['color-warning-500']);
    }
    if (minRouge <= answerValue && answerValue <= maxRouge) {
      return (theme['color-danger-600']);
    }
    return false;
  };

  return (
    <MaxWidthContainer
      withScrollView="keyboardAware"
      outerViewProps={{
        style: {
          padding: 24,
        },
      }}
    >
      <InfoCard
        title="alertInfo"
        iconShow="alert-circle-outline"
        description={`Dans la liste suivante des maladies détectées par l'application comme présentant une importance estimée moyenne ou forte, vous pouvez sélectionner celles que vous souhaitez inscrire dans le Protocole de Soins.
Cliquez sur la maladie choisie, puis en bas de notre proposition de protocole de soins, cliquez d'abord sur "Editer le protocole de soins" afin de pouvoir éventuellement le modifier, 
Puis validez le protocole de soins correspondant à la maladie concernée.
Procédez de la même façon pour tous les protocoles de soins que vous souhaitez retenir.
Vous pouvez enfin valider le BSE.`}
        alertInfo="alertInfo"
      />
      <Text category="h3" appearance="hint" style={{ marginVertical: 10 }}>Maladies détectées par l'application</Text>

      {bse && (
        bse.maladieRecurente?.map((maladieRecu) => (
          <PSCard key={maladieRecu} idMaladiePS={maladieRecu} bse={bse} />
        ))
      )}
      <View style={{ flex: 1 }}>
        <Button
          onPress={async () => {
            setGenerating(true);
            const {
              currentQuestionnaireData,
            } = BseScreenBL.initQuestionnaire(bseQuestionConditionData, bse, true, true);
            // console.log(currentQuestionnaireData);

            let html = '';

            let countPerQuestion = 0;
            let currentHtml = '';
            let waitForNext = false;

            const values = Object.values(currentQuestionnaireData);
            for (let i = 0; i < values.length; i++) {
              const currentValue = values[i];
              if (currentValue.showQuestion && currentValue.name !== 'Reposer la question?') {
                if (!waitForNext) {
                  currentHtml = '';
                } else {
                  waitForNext = false;
                }
                if (currentValue.hideRapport === undefined || !currentValue.hideRapport) {
                  countPerQuestion += 1;
                  if (currentValue.titre1Rapport || currentValue.titre1) {
                    html += `<p class="titre1">${currentValue.titre1Rapport || currentValue.titre1}</p>`;
                  }
                  if (currentValue.titre2Rapport || currentValue.titre2) {
                    html += `<p class="titre2">${currentValue.titre2Rapport || currentValue.titre2}</p>`;
                  }
                  if (currentValue.titre3Rapport || currentValue.titre3) {
                    html += `<p class="titre3">${currentValue.titre3Rapport || currentValue.titre3}</p>`;
                  }
                  if (currentValue.titre4Rapport || currentValue.titre4) {
                    html += `<p class="titre4">${currentValue.titre4Rapport || currentValue.titre4}</p>`;
                  }
                  if (currentValue.name || currentValue.nameRapport) {
                    currentHtml += `<p class="question">${currentValue.nameRapport || currentValue.name}</p>`;
                  }
                }
                let hasAnswers = false;
                // on met les réponses
                if (currentValue.answers) {
                  const answers = Object.values(currentValue.answers);
                  const answerIds = Object.keys(currentValue.answers);
                  let hasCats = false;
                  const cats = {};
                  for (let j = 0; j < answers.length; j++) {
                    const currentAnswer = answers[j];
                    if ((currentValue.hideQuestionRapport === false) && !currentAnswer.interdire && ((currentAnswer.checked && (currentAnswer.name || currentAnswer.answerText)))) {
                      waitForNext = true;
                    }
                    if ((currentAnswer.hideRapport === undefined || !currentAnswer.hideRapport) && !currentAnswer.interdire && ((currentAnswer.checked && (currentAnswer.name || currentAnswer.answerText)))) {
                      hasAnswers = true;
                      if (currentAnswer.catRapport !== undefined) {
                        hasCats = true;
                        if (cats[currentAnswer.catRapport] !== undefined) {
                          cats[currentAnswer.catRapport].push({ id: answerIds[j], ...currentAnswer });
                        } else {
                          cats[currentAnswer.catRapport] = [{ id: answerIds[j], ...currentAnswer }];
                        }
                      } else if (currentAnswer.answerType === 'F') {
                        const bgColor = getBgColor(currentQuestionnaireData, currentValue.id, answerIds[j], currentAnswer.answerText);
                        currentHtml += `<p class="calcul" ${bgColor ? `style="background-color:${bgColor}"` : ''}>
${parseFloat(currentAnswer.answerText).toFixed(currentAnswer.round ?? 0).replace('.', ',')}${currentAnswer.suffix ?? ''}
</p>`;
                      } else {
                        currentHtml += `<p class="reponse">${currentAnswer.nameRapport || currentAnswer.name || currentAnswer.answerText}</p>`;
                      }
                    }
                  }
                  if (hasCats) {
                    const catsKeys = Object.keys(cats);
                    const catsValues = Object.values(cats);
                    for (let j = 0; j < catsValues.length; j++) {
                      currentHtml += `<p class="cats">${catsKeys[j]}</p>`;

                      for (let k = 0; k < catsValues[j].length; k++) {
                        const currentAnswer = catsValues[j][k];
                        if ((currentAnswer.hideRapport === undefined || !currentAnswer.hideRapport) && !currentAnswer.interdire && ((currentAnswer.checked && (currentAnswer.name || currentAnswer.answerText)))) {
                          if (currentAnswer.answerType === 'F') {
                            const bgColor = getBgColor(currentQuestionnaireData, currentValue.id, currentAnswer.id, currentAnswer.answerText);
                            currentHtml += `<p class="calcul cats" ${bgColor ? `style="background-color:${bgColor}"` : ''}>
${parseFloat(currentAnswer.answerText).toFixed(currentAnswer.round ?? 0).replace('.', ',')}${currentAnswer.suffix ?? ''}
</p>`;
                          } else {
                            currentHtml += `<p class="reponse cats">${currentAnswer.nameRapport || currentAnswer.name || currentAnswer.answerText}</p>`;
                          }
                        }
                      }
                    }
                  }
                }
                if (hasAnswers || currentValue.autopass) {
                  html += `${currentHtml}`;
                }
              }
            }

            // signature du vétérinaire
            html += `<p>Signé par le vétérinaire ${user.firstname} ${user.lastname} sur l'application ScanFlock le ${moment().format('DD/MM/YYYY HH:mm')}.</p>`;

            // signature de l'éleveur
            const userEleveurData = await getUserAsync(apolloClient, bse.idEleveurUser);
            if (userEleveurData) {
              const userEleveur = userEleveurData.data.getUser;
              if (userEleveur) {
                html += `<p>Signé par l'éleveur ${userEleveur.firstname} ${userEleveur.lastname} sur l'application ScanFlock le ${moment().format('DD/MM/YYYY HH:mm')} pour confirmer la présence du vétérinaire sur l'exploitation lors de la réalisation du BSE.</p>
               `;
              }
            }

            html += '<p>Ce Bilan sanitaire d’Elevage et son Protocole de Soins ont été réalisés à partir de l’application ScanFlock accessible exclusivement aux éleveurs et aux vétérinaires en exercice conformément aux CGU et CGV de l’application, acceptées par ses utilisateurs et consultables sur le site scanflock.com.</p>';

            const protocolesSoins = await getProtocolSoinSaveByBseIdAsync(apolloClient, bse.id);
            let htmlPS = '';
            const items = protocolesSoins.data.protocolSoinSaveByBseId?.items;
            if (items) {
              for (let i = 0; i < items.length; i++) {
                if (items[i]) {
                  htmlPS += `<p class="titre1">${items[i].name}</p><p>${items[i]?.Protocol?.replace(/\n/g, '<br/>').replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;')}</p><br/><br/>`;
                }
              }
            }

            console.log(templatePDF.replace('{{content}}', html).replace('{{contentPS}}', htmlPS));

            const result = await pdfGenerator(templatePDF.replace('{{content}}', html).replace('{{contentPS}}', htmlPS));
            // console.log('result', result);
            if (result !== false) {
              const name = `Compte_rendu_BSE_${moment().format('YYYY-MM-DD')}.pdf`;
              const s3file = await Upload(result, `elevages/${bse?.breedingId}/bse/${bse.id}/`, name);
              // console.log('s3file', s3file);

              if (s3file !== false) {
                // on enregistre la key dans le BSE
                await editBse({
                  ...bse,
                  StatusBse: StatusBse.BSEFinish,
                  crS3Key: s3file.key,
                });
              }
            }
            setGenerating(false);
            VetoBseScreenBL.linkToSwitch({
              state: 'details-bse',
              linkTo,
              id: bse.id,
            });
          }}
          disabled={generating}
        >
          {generating
            ? (
              <>
                <Spinner />
                <Text style={{ marginLeft: 10 }}>Génération du compte rendu en cours</Text>
              </>
            )
            : <Text>{'Valider le Bilan Sanitaire d\'élevage'}</Text>}
        </Button>
      </View>
    </MaxWidthContainer>
  );
};

export default VetoChoixPS;
