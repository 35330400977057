import { LinkProps } from '../../../types';

const linkToSwitch = ({
  state, linkTo, id, back,
}: LinkProps) => {
  switch (state) {
    case 'veto-bse':
      linkTo(`/veto-bse${back ? `?back=${back}` : ''}`);
      break;
    case 'realiser-bse':
      linkTo(`/veto-bse/veto-realiser-bse${back ? `?back=${back}` : ''}`);
      break;
    case 'details-bse':
      linkTo(`/veto-bse/veto-details-bse/${id}${back ? `?back=${back}` : ''}`);
      break;
    case 'prebilan':
      linkTo(`/veto-bse/veto-details-bse/veto-prebilan${back ? `?back=${back}` : ''}`);
      break;

    case 'ajout-elevage':
      linkTo(`/veto-bse/veto-realiser-bse/veto-ajout-elevage${back ? `?back=${back}` : ''}`);
      break;
    default:
      break;
  }
};

export default {
  linkToSwitch,
};
