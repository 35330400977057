import { useMutation, useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { DocumentNode } from 'apollo-link';
import { WatchQueryFetchPolicy } from 'apollo-client/core/watchQueryOptions';
import * as mutations from '../graphql/mutations';
import {
  CreateLotsMutation, CreateLotsMutationVariables, DeleteLotsMutation, DeleteLotsMutationVariables,
  GetLotsQuery, GetLotsQueryVariables, ListLotsByBreedingQuery, ListLotsByBreedingQueryVariables,
  ListLotsQueryVariables,
  Lots as Lot,
  UpdateLotsMutation, UpdateLotsMutationVariables,
} from '../API';
import {
  getLots, listLotsByBreeding,
} from '../graphql/queries';
import { deleteLots } from '../graphql/mutations';

export function useCreateLotsMutation() {
  const [createLots] = useMutation<
  CreateLotsMutation, CreateLotsMutationVariables
  >(gql(mutations.createLots));
  return { createLots };
}

export function useGetLot(id: string, fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network') {
  if (!id) {
    return { loading: false, lot: undefined };
  }

  const lotQuery = <DocumentNode>gql(`
  query GetLots($id: ID!) {
    getLots(id: $id) {
      id
      numberLot
      name
      buildingId
      breedingId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      breeding {
        id
        nBreeding
        nSIRET
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        emailVeto
        admins
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      animals {
        items {
          id
          numberID
          name
          gender
          birthDate
          breedingId
          lotsId
          zoneId
          buildingId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      building {
        id
        nameBuilding
        address {
          address
          additionalAddress
          postalCode
        }
        breedingId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        
        zone {
          nextToken
          startedAt
        }
      }
      consultations(sortDirection: DESC, limit: 10) {
        items {
          id
          breedingId
          animalId
          lotId
          shared
          autoExam
          diseaseIsVerified
          teleconsultation
          priceConsultation
          factureId
          isDone
          
          dateTeleCons
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          
          lot {
            id
            numberLot
            name
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          
          animal {
            id
            numberID
            name
            gender
            birthDate
            breedingId
            lotsId
            lots {
              id
              numberLot
              name
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            zoneId
            buildingId
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          
          priceConsultation
          sharedFacture
          sharedFactureAt
          factureId
          facture {
            id
            breedingId
            name
            key
            s3file
            createdAt
            _version
            _deleted
            _lastChangedAt
            updatedAt
          }
        }
      }
    }
  }
`);

  const { loading, data, refetch } = useQuery<
  GetLotsQuery, GetLotsQueryVariables
  >(lotQuery, {
    fetchPolicy,
    variables: {
      id,
    },
  });
  // console.log('useGetLot:', data);
  return {
    loading, lot: <Lot>data?.getLots, refetch,
  };
}

const lotsQuery = <DocumentNode>gql(`
  query ListLots {
    listBreedings {
      items {
        lots (limit: 1000) {
          items {
            id
            numberLot
            name
            buildingId
            breedingId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
        }
      }
    }
  }
`);

export type ListLotsQuery = {
  listBreedings?: {
    items: Array< {
      lots?: {
        __typename: 'ModelLotsConnection',
        items: Array< {
          __typename: 'Lots',
          id: string,
          numberLot: string,
          name?: string | null,
          buildingId?: string | null,
          breedingId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
      } | null,
    }>,
  }
};

export function useListLots(fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network') {
  const {
    loading, data, fetchMore, refetch,
  } = useQuery<ListLotsQuery, ListLotsQueryVariables>(lotsQuery, {
    fetchPolicy,
  });

  // console.log('Lots data: api', data);
  return {
    loading, lots: data?.listBreedings?.items[0].lots?.items, fetchMore, refetch,
  };
}

export function useUpdateLotMutation() {
  const [updateLot, { loading: mutationLotLoading }] = useMutation<
  UpdateLotsMutation, UpdateLotsMutationVariables
  >(gql(mutations.updateLots),
    {
      update: (cache, { data: mutationData }) => {
        if (mutationData) {
          const { updateLots: newData } = mutationData;
          if (newData) {
            // Read query from cache
            const cacheData = cache.readQuery<GetLotsQuery, GetLotsQueryVariables>({
              query: gql(getLots),
              variables: {
                id: newData.id,
              },
            });

            // Add newly created item to the cache copy
            if (cacheData && cacheData.getLots) {
              cacheData.getLots = newData;

              // Overwrite the cache with the new results
              cache.writeQuery<GetLotsQuery, GetLotsQueryVariables>({
                query: gql(getLots),
                variables: {
                  id: newData.id,
                },
                data: cacheData,
              });
            }
          }
        }
      },
    });
  return { updateLot, mutationLotLoading };
}

export function useDeleteLotMutation() {
  const [deleteLot] = useMutation<
  DeleteLotsMutation, DeleteLotsMutationVariables
  >(gql(deleteLots));
  return deleteLot;
}

export function useListLotsByBreeding(
  id: string, limit: number, fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network',
) {
  const listLotsByBreedingQuery = <DocumentNode>gql(listLotsByBreeding);

  const {
    loading, data, fetchMore, refetch,
  } = useQuery<
  ListLotsByBreedingQuery, ListLotsByBreedingQueryVariables
  >(listLotsByBreedingQuery, {
    variables: {
      limit,
      breedingId: id,
    },
    fetchPolicy,
  });

  if (data && data.listLotsByBreeding && data.listLotsByBreeding.items) {
    return {
      loadingLots: loading,
      lotS: data.listLotsByBreeding.items,
      nextToken: data.listLotsByBreeding.nextToken,
      startedAt: data.listLotsByBreeding.startedAt,

      fetchMore,

      refetch,
    };
  }

  return { loadingLots: loading, lotS: null };
}
