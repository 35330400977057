/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      lastname
      firstname
      avatarUri
      userGroup
      optIn
      inscriptionNumberOrdreVeto
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      vetOfficeId
      vetOffice {
        id
        locked
        companyName
        emailOffice
        admins
        personnel
        phoneVetOffice
        nameVetSanitaire
        nOrdreCabinet
        nSIRET
        maxPriceTeleConsultation
        maxPriceConsultation
        mpUserId
        isFree
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      email
      expoToken
      notificationLastSeenAt
      messages {
        nextToken
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        lastname
        firstname
        avatarUri
        userGroup
        optIn
        inscriptionNumberOrdreVeto
        breedingId
        vetOfficeId
        email
        expoToken
        notificationLastSeenAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        lastname
        firstname
        avatarUri
        userGroup
        optIn
        inscriptionNumberOrdreVeto
        breedingId
        vetOfficeId
        email
        expoToken
        notificationLastSeenAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: AWSEmail!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lastname
        firstname
        avatarUri
        userGroup
        optIn
        inscriptionNumberOrdreVeto
        breedingId
        vetOfficeId
        email
        expoToken
        notificationLastSeenAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getVetOffice = /* GraphQL */ `
  query GetVetOffice($id: ID!) {
    getVetOffice(id: $id) {
      id
      locked
      companyName
      emailOffice
      admins
      personnel
      phoneVetOffice
      address {
        address
        additionalAddress
        postalCode
        city
      }
      nameVetSanitaire
      nOrdreCabinet
      nSIRET
      maxPriceTeleConsultation
      maxPriceConsultation
      users {
        nextToken
        startedAt
      }
      breedings {
        nextToken
        startedAt
      }
      billingHistories {
        nextToken
      }
      mpUserId
      isFree
      mpWalletId
      mpBankId
      mpMandateId
      mpMandateConfirmed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listVetOffices = /* GraphQL */ `
  query ListVetOffices(
    $filter: ModelVetOfficeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVetOffices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        locked
        companyName
        emailOffice
        admins
        personnel
        phoneVetOffice
        nameVetSanitaire
        nOrdreCabinet
        nSIRET
        maxPriceTeleConsultation
        maxPriceConsultation
        mpUserId
        isFree
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncVetOffices = /* GraphQL */ `
  query SyncVetOffices(
    $filter: ModelVetOfficeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVetOffices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        locked
        companyName
        emailOffice
        admins
        personnel
        phoneVetOffice
        nameVetSanitaire
        nOrdreCabinet
        nSIRET
        maxPriceTeleConsultation
        maxPriceConsultation
        mpUserId
        isFree
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listVetOfficeByLockedByCreationDate = /* GraphQL */ `
  query ListVetOfficeByLockedByCreationDate(
    $locked: LockedStatus!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVetOfficeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVetOfficeByLockedByCreationDate(
      locked: $locked
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        locked
        companyName
        emailOffice
        admins
        personnel
        phoneVetOffice
        nameVetSanitaire
        nOrdreCabinet
        nSIRET
        maxPriceTeleConsultation
        maxPriceConsultation
        mpUserId
        isFree
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const vetOfficeByEmailOffice = /* GraphQL */ `
  query VetOfficeByEmailOffice(
    $emailOffice: AWSEmail!
    $sortDirection: ModelSortDirection
    $filter: ModelVetOfficeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vetOfficeByEmailOffice(
      emailOffice: $emailOffice
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        locked
        companyName
        emailOffice
        admins
        personnel
        phoneVetOffice
        nameVetSanitaire
        nOrdreCabinet
        nSIRET
        maxPriceTeleConsultation
        maxPriceConsultation
        mpUserId
        isFree
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listVetOfficeMandateId = /* GraphQL */ `
  query ListVetOfficeMandateId(
    $mpMandateId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelVetOfficeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVetOfficeMandateId(
      mpMandateId: $mpMandateId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        locked
        companyName
        emailOffice
        admins
        personnel
        phoneVetOffice
        nameVetSanitaire
        nOrdreCabinet
        nSIRET
        maxPriceTeleConsultation
        maxPriceConsultation
        mpUserId
        isFree
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getBreeding = /* GraphQL */ `
  query GetBreeding($id: ID!) {
    getBreeding(id: $id) {
      id
      locked
      nBreeding
      nSIRET
      phone
      emailEleveur
      address {
        address
        additionalAddress
        postalCode
        city
      }
      companyName
      dairyFarm
      sucklerCowHusbandry
      fatteningFarm
      nbCow
      nbHeifer
      nbMale
      nbYoung
      idVeto
      emailVeto
      admins
      vetOffice {
        id
        locked
        companyName
        emailOffice
        admins
        personnel
        phoneVetOffice
        nameVetSanitaire
        nOrdreCabinet
        nSIRET
        maxPriceTeleConsultation
        maxPriceConsultation
        mpUserId
        isFree
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      users {
        nextToken
        startedAt
      }
      documents {
        nextToken
      }
      animals {
        nextToken
        startedAt
      }
      lots {
        nextToken
        startedAt
      }
      buildings {
        nextToken
        startedAt
      }
      bse {
        nextToken
        startedAt
      }
      pendingInvitations {
        nextToken
        startedAt
      }
      consultations {
        nextToken
        startedAt
      }
      billingHistories {
        nextToken
      }
      mpUserId
      mpWalletId
      mpBankId
      mpMandateId
      mpMandateConfirmed
      createdAt
      dateFirstCons
      nextSubscriptionDate
      subscriptionAmount
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listBreedings = /* GraphQL */ `
  query ListBreedings(
    $filter: ModelBreedingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBreedings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBreedings = /* GraphQL */ `
  query SyncBreedings(
    $filter: ModelBreedingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBreedings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listBreedingByLocked = /* GraphQL */ `
  query ListBreedingByLocked(
    $locked: LockedStatus!
    $nextSubscriptionDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBreedingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBreedingByLocked(
      locked: $locked
      nextSubscriptionDate: $nextSubscriptionDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listBreedingByLockedByCreationDate = /* GraphQL */ `
  query ListBreedingByLockedByCreationDate(
    $locked: LockedStatus!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBreedingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBreedingByLockedByCreationDate(
      locked: $locked
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listBreedingMandateId = /* GraphQL */ `
  query ListBreedingMandateId(
    $mpMandateId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelBreedingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBreedingMandateId(
      mpMandateId: $mpMandateId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listBillingHistoriesByVetOffice = /* GraphQL */ `
  query ListBillingHistoriesByVetOffice(
    $vetOfficeId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBillingHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBillingHistoriesByVetOffice(
      vetOfficeId: $vetOfficeId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        vetOfficeId
        breedingId
        createdAt
        type
        invoiceNumber
        amount
        label
        paid
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
    }
  }
`;
export const listBillingHistoriesByBreeding = /* GraphQL */ `
  query ListBillingHistoriesByBreeding(
    $breedingId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBillingHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBillingHistoriesByBreeding(
      breedingId: $breedingId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        vetOfficeId
        breedingId
        createdAt
        type
        invoiceNumber
        amount
        label
        paid
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
    }
  }
`;
export const listBillingHistoriesByType = /* GraphQL */ `
  query ListBillingHistoriesByType(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBillingHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBillingHistoriesByType(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        vetOfficeId
        breedingId
        createdAt
        type
        invoiceNumber
        amount
        label
        paid
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
    }
  }
`;
export const getAnimals = /* GraphQL */ `
  query GetAnimals($id: ID!) {
    getAnimals(id: $id) {
      id
      numberID
      name
      gender
      birthDate
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      lotsId
      lots {
        id
        numberLot
        name
        buildingId
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      consultations {
        nextToken
        startedAt
      }
      zoneId
      zone {
        id
        name
        numberZone
        buildingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      buildingId
      building {
        id
        nameBuilding
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAnimals = /* GraphQL */ `
  query ListAnimals(
    $filter: ModelAnimalsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnimals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        numberID
        name
        gender
        birthDate
        breedingId
        lotsId
        zoneId
        buildingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAnimals = /* GraphQL */ `
  query SyncAnimals(
    $filter: ModelAnimalsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAnimals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        numberID
        name
        gender
        birthDate
        breedingId
        lotsId
        zoneId
        buildingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const animalsByNumberId = /* GraphQL */ `
  query AnimalsByNumberId(
    $numberID: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAnimalsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    animalsByNumberId(
      numberID: $numberID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        numberID
        name
        gender
        birthDate
        breedingId
        lotsId
        zoneId
        buildingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const animalsByName = /* GraphQL */ `
  query AnimalsByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAnimalsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    animalsByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        numberID
        name
        gender
        birthDate
        breedingId
        lotsId
        zoneId
        buildingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listAnimalsByBreeding = /* GraphQL */ `
  query ListAnimalsByBreeding(
    $breedingId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAnimalsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnimalsByBreeding(
      breedingId: $breedingId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        numberID
        name
        gender
        birthDate
        breedingId
        lotsId
        zoneId
        buildingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getLots = /* GraphQL */ `
  query GetLots($id: ID!) {
    getLots(id: $id) {
      id
      numberLot
      name
      buildingId
      building {
        id
        nameBuilding
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      animals {
        nextToken
        startedAt
      }
      consultations {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listLots = /* GraphQL */ `
  query ListLots(
    $filter: ModelLotsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLots(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        numberLot
        name
        buildingId
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLots = /* GraphQL */ `
  query SyncLots(
    $filter: ModelLotsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLots(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        numberLot
        name
        buildingId
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const lotsBynumberLot = /* GraphQL */ `
  query LotsBynumberLot(
    $numberLot: String!
    $sortDirection: ModelSortDirection
    $filter: ModelLotsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    lotsBynumberLot(
      numberLot: $numberLot
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        numberLot
        name
        buildingId
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listLotsByBreeding = /* GraphQL */ `
  query ListLotsByBreeding(
    $breedingId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLotsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLotsByBreeding(
      breedingId: $breedingId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        numberLot
        name
        buildingId
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getBuildings = /* GraphQL */ `
  query GetBuildings($id: ID!) {
    getBuildings(id: $id) {
      id
      nameBuilding
      address {
        address
        additionalAddress
        postalCode
        city
      }
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      zone {
        nextToken
        startedAt
      }
      animals {
        nextToken
        startedAt
      }
      lots {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listBuildings = /* GraphQL */ `
  query ListBuildings(
    $filter: ModelBuildingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBuildings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        nameBuilding
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBuildings = /* GraphQL */ `
  query SyncBuildings(
    $filter: ModelBuildingsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBuildings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        nameBuilding
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const buildingsByNameBuilding = /* GraphQL */ `
  query BuildingsByNameBuilding(
    $nameBuilding: String!
    $sortDirection: ModelSortDirection
    $filter: ModelBuildingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    buildingsByNameBuilding(
      nameBuilding: $nameBuilding
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        nameBuilding
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listBuildingsByBreeding = /* GraphQL */ `
  query ListBuildingsByBreeding(
    $breedingId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBuildingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBuildingsByBreeding(
      breedingId: $breedingId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        nameBuilding
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getZones = /* GraphQL */ `
  query GetZones($id: ID!) {
    getZones(id: $id) {
      id
      name
      numberZone
      animals {
        nextToken
        startedAt
      }
      buildingId
      building {
        id
        nameBuilding
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listZones = /* GraphQL */ `
  query ListZones(
    $filter: ModelZonesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listZones(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        numberZone
        buildingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncZones = /* GraphQL */ `
  query SyncZones(
    $filter: ModelZonesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncZones(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        numberZone
        buildingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const zonesByBuilding = /* GraphQL */ `
  query ZonesByBuilding(
    $buildingId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelZonesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    zonesByBuilding(
      buildingId: $buildingId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        numberZone
        buildingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getBSE = /* GraphQL */ `
  query GetBSE($id: ID!) {
    getBSE(id: $id) {
      id
      breedingId
      StatusBse
      dateBSE
      crS3Key
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      questionResponse
      lasIdQuestion
      idEleveurUser
      lastQuestionVeto
      questionResponseOrder
      maladieRecurente
      protocolesDeSoins
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listBSES = /* GraphQL */ `
  query ListBSES(
    $filter: ModelBSEFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBSES(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        breedingId
        StatusBse
        dateBSE
        crS3Key
        questionResponse
        lasIdQuestion
        idEleveurUser
        lastQuestionVeto
        questionResponseOrder
        maladieRecurente
        protocolesDeSoins
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBSES = /* GraphQL */ `
  query SyncBSES(
    $filter: ModelBSEFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBSES(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        breedingId
        StatusBse
        dateBSE
        crS3Key
        questionResponse
        lasIdQuestion
        idEleveurUser
        lastQuestionVeto
        questionResponseOrder
        maladieRecurente
        protocolesDeSoins
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listBseByBreeding = /* GraphQL */ `
  query ListBseByBreeding(
    $breedingId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBSEFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBseByBreeding(
      breedingId: $breedingId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        breedingId
        StatusBse
        dateBSE
        crS3Key
        questionResponse
        lasIdQuestion
        idEleveurUser
        lastQuestionVeto
        questionResponseOrder
        maladieRecurente
        protocolesDeSoins
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const documentsByConsultation = /* GraphQL */ `
  query DocumentsByConsultation(
    $consultationId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    documentsByConsultation(
      consultationId: $consultationId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        breedingId
        consultationId
        name
        key
        s3file
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
    }
  }
`;
export const documentsByKey = /* GraphQL */ `
  query DocumentsByKey(
    $key: String!
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    documentsByKey(
      key: $key
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        breedingId
        consultationId
        name
        key
        s3file
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
    }
  }
`;
export const messagesByConsultation = /* GraphQL */ `
  query MessagesByConsultation(
    $consultationId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByConsultation(
      consultationId: $consultationId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        consultationId
        message
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
    }
  }
`;
export const messagesByUser = /* GraphQL */ `
  query MessagesByUser(
    $userId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByUser(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        consultationId
        message
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
    }
  }
`;
export const getPendingInvitation = /* GraphQL */ `
  query GetPendingInvitation($id: ID!) {
    getPendingInvitation(id: $id) {
      id
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      emailCurrentUser
      email
      createdAt
      initial
      refusedAt
      acceptedAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPendingInvitations = /* GraphQL */ `
  query ListPendingInvitations(
    $filter: ModelPendingInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPendingInvitations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        breedingId
        emailCurrentUser
        email
        createdAt
        initial
        refusedAt
        acceptedAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPendingInvitations = /* GraphQL */ `
  query SyncPendingInvitations(
    $filter: ModelPendingInvitationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPendingInvitations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        breedingId
        emailCurrentUser
        email
        createdAt
        initial
        refusedAt
        acceptedAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const pendingInvitationsByEmail = /* GraphQL */ `
  query PendingInvitationsByEmail(
    $email: AWSEmail!
    $sortDirection: ModelSortDirection
    $filter: ModelPendingInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pendingInvitationsByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        breedingId
        emailCurrentUser
        email
        createdAt
        initial
        refusedAt
        acceptedAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getConsultation = /* GraphQL */ `
  query GetConsultation($id: ID!) {
    getConsultation(id: $id) {
      id
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      animalId
      animal {
        id
        numberID
        name
        gender
        birthDate
        breedingId
        lotsId
        zoneId
        buildingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      lotId
      lot {
        id
        numberLot
        name
        buildingId
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      diseaseId
      disease {
        id
        codeIdentification
        name
        forme
        description
        synonymes
        enjeux
        causes
        circonstancesApparition
        diagnosticDiff
        diagnosticLab
        traitementCuratif
        pronostic
        prevention
        url
        image
        ponderationDisease
        ponderationCount
        familleMaladie
        familleMaladieId
        MRLC
        maladieUrgente
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      shared
      sharedAt
      autoExam
      autoExamRes {
        reponse1
      }
      teleconsultation
      priceConsultation
      diseaseIsVerified
      isDone
      factureId
      facture {
        id
        breedingId
        consultationId
        name
        key
        s3file
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      documents {
        nextToken
      }
      messages {
        nextToken
      }
      sharedFacture
      sharedFactureAt
      teleconsAskAt
      dateTeleCons
      questionResponse
      note
      questionResponseOrder
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listConsultations = /* GraphQL */ `
  query ListConsultations(
    $filter: ModelConsultationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConsultations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        breedingId
        animalId
        lotId
        diseaseId
        shared
        sharedAt
        autoExam
        teleconsultation
        priceConsultation
        diseaseIsVerified
        isDone
        factureId
        sharedFacture
        sharedFactureAt
        teleconsAskAt
        dateTeleCons
        questionResponse
        note
        questionResponseOrder
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncConsultations = /* GraphQL */ `
  query SyncConsultations(
    $filter: ModelConsultationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncConsultations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        breedingId
        animalId
        lotId
        diseaseId
        shared
        sharedAt
        autoExam
        teleconsultation
        priceConsultation
        diseaseIsVerified
        isDone
        factureId
        sharedFacture
        sharedFactureAt
        teleconsAskAt
        dateTeleCons
        questionResponse
        note
        questionResponseOrder
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listConsultationsByBreeding = /* GraphQL */ `
  query ListConsultationsByBreeding(
    $breedingId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConsultationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConsultationsByBreeding(
      breedingId: $breedingId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        breedingId
        animalId
        lotId
        diseaseId
        shared
        sharedAt
        autoExam
        teleconsultation
        priceConsultation
        diseaseIsVerified
        isDone
        factureId
        sharedFacture
        sharedFactureAt
        teleconsAskAt
        dateTeleCons
        questionResponse
        note
        questionResponseOrder
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDisease = /* GraphQL */ `
  query GetDisease($id: ID!) {
    getDisease(id: $id) {
      id
      codeIdentification
      name
      forme
      description
      synonymes
      enjeux
      causes
      circonstancesApparition
      diagnosticDiff
      diagnosticLab
      traitementCuratif
      pronostic
      prevention
      url
      consultations {
        nextToken
        startedAt
      }
      image
      ponderationDisease
      ponderationCount
      familleMaladie
      familleMaladieId
      MRLC
      maladieUrgente
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDiseases = /* GraphQL */ `
  query ListDiseases(
    $filter: ModelDiseaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDiseases(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        codeIdentification
        name
        forme
        description
        synonymes
        enjeux
        causes
        circonstancesApparition
        diagnosticDiff
        diagnosticLab
        traitementCuratif
        pronostic
        prevention
        url
        image
        ponderationDisease
        ponderationCount
        familleMaladie
        familleMaladieId
        MRLC
        maladieUrgente
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDiseases = /* GraphQL */ `
  query SyncDiseases(
    $filter: ModelDiseaseFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDiseases(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        codeIdentification
        name
        forme
        description
        synonymes
        enjeux
        causes
        circonstancesApparition
        diagnosticDiff
        diagnosticLab
        traitementCuratif
        pronostic
        prevention
        url
        image
        ponderationDisease
        ponderationCount
        familleMaladie
        familleMaladieId
        MRLC
        maladieUrgente
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const diseaseByCodeIdentification = /* GraphQL */ `
  query DiseaseByCodeIdentification(
    $codeIdentification: String!
    $sortDirection: ModelSortDirection
    $filter: ModeldiseaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    diseaseByCodeIdentification(
      codeIdentification: $codeIdentification
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        codeIdentification
        name
        forme
        description
        synonymes
        enjeux
        causes
        circonstancesApparition
        diagnosticDiff
        diagnosticLab
        traitementCuratif
        pronostic
        prevention
        url
        image
        ponderationDisease
        ponderationCount
        familleMaladie
        familleMaladieId
        MRLC
        maladieUrgente
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
    }
  }
`;
export const getProtocolSoin = /* GraphQL */ `
  query GetProtocolSoin($id: ID!) {
    getProtocolSoin(id: $id) {
      id
      idDiseaseFamily
      name
      Protocol
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listProtocolSoins = /* GraphQL */ `
  query ListProtocolSoins(
    $filter: ModelProtocolSoinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProtocolSoins(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        idDiseaseFamily
        name
        Protocol
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncProtocolSoins = /* GraphQL */ `
  query SyncProtocolSoins(
    $filter: ModelProtocolSoinFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProtocolSoins(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        idDiseaseFamily
        name
        Protocol
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const protocolSoinByIdDiseaseFamily = /* GraphQL */ `
  query ProtocolSoinByIdDiseaseFamily(
    $idDiseaseFamily: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProtocolSoinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    protocolSoinByIdDiseaseFamily(
      idDiseaseFamily: $idDiseaseFamily
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        idDiseaseFamily
        name
        Protocol
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getProtocolSoinSave = /* GraphQL */ `
  query GetProtocolSoinSave($id: ID!) {
    getProtocolSoinSave(id: $id) {
      id
      breedingId
      bseId
      idDiseaseFamily
      new
      name
      Protocol
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listProtocolSoinSaves = /* GraphQL */ `
  query ListProtocolSoinSaves(
    $filter: ModelProtocolSoinSaveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProtocolSoinSaves(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        breedingId
        bseId
        idDiseaseFamily
        new
        name
        Protocol
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncProtocolSoinSaves = /* GraphQL */ `
  query SyncProtocolSoinSaves(
    $filter: ModelProtocolSoinSaveFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProtocolSoinSaves(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        breedingId
        bseId
        idDiseaseFamily
        new
        name
        Protocol
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const protocolSoinSaveByIdBreedingId = /* GraphQL */ `
  query ProtocolSoinSaveByIdBreedingId(
    $breedingId: String!
    $idDiseaseFamily: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProtocolSoinSaveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    protocolSoinSaveByIdBreedingId(
      breedingId: $breedingId
      idDiseaseFamily: $idDiseaseFamily
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        breedingId
        bseId
        idDiseaseFamily
        new
        name
        Protocol
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const protocolSoinSaveByBseId = /* GraphQL */ `
  query ProtocolSoinSaveByBseId(
    $bseId: String!
    $idDiseaseFamily: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProtocolSoinSaveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    protocolSoinSaveByBseId(
      bseId: $bseId
      idDiseaseFamily: $idDiseaseFamily
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        breedingId
        bseId
        idDiseaseFamily
        new
        name
        Protocol
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const protocolSoinSaveByIdDiseaseFamily = /* GraphQL */ `
  query ProtocolSoinSaveByIdDiseaseFamily(
    $idDiseaseFamily: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProtocolSoinSaveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    protocolSoinSaveByIdDiseaseFamily(
      idDiseaseFamily: $idDiseaseFamily
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        breedingId
        bseId
        idDiseaseFamily
        new
        name
        Protocol
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!, $createdAt: AWSDateTime!) {
    getNotification(id: $id, createdAt: $createdAt) {
      id
      userId
      user {
        id
        lastname
        firstname
        avatarUri
        userGroup
        optIn
        inscriptionNumberOrdreVeto
        breedingId
        vetOfficeId
        email
        expoToken
        notificationLastSeenAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      type
      title
      body
      data
      clicked
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $id: ID
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNotifications(
      id: $id
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userId
        type
        title
        body
        data
        clicked
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncNotifications = /* GraphQL */ `
  query SyncNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        type
        title
        body
        data
        clicked
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getNotificationById = /* GraphQL */ `
  query GetNotificationById(
    $id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getNotificationById(
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        type
        title
        body
        data
        clicked
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listNotificationsByUser = /* GraphQL */ `
  query ListNotificationsByUser(
    $userId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotificationsByUser(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        type
        title
        body
        data
        clicked
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getNotificationTickets = /* GraphQL */ `
  query GetNotificationTickets($id: ID!) {
    getNotificationTickets(id: $id) {
      id
      expoTokens {
        userId
        token
      }
      ticketIds
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listNotificationTickets = /* GraphQL */ `
  query ListNotificationTickets(
    $filter: ModelNotificationTicketsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotificationTickets(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ticketIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncNotificationTickets = /* GraphQL */ `
  query SyncNotificationTickets(
    $filter: ModelNotificationTicketsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNotificationTickets(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        ticketIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
