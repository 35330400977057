import React, { useContext, useState } from 'react';
import { useLinkTo, useRoute } from '@react-navigation/native';
import { Text, useTheme } from '@ui-kitten/components';

import { View } from 'react-native';
import Button from '../../components/Button';
import MaxWidthContainer from '../../components/MaxWidthContainer';

import InfoCard from '../../screenComponents/InfoCard';
import DiseaseCard from '../../screenComponents/ConsultationScreenComps/DiseaseCard';
import { useListDiseases } from '../../src/API/Disease';
import { findDisease } from '../../screenBusinessLogic/ConsultationScreen/ConsulationFicheMaladie';
import { useGetConsultation } from '../../src/API/Consultation';
import { GlobalStateContext } from '../../utils/GlobalStateContext';
import { disease } from '../../src/API';
import CustomModal from '../../components/Modals/CustomModal';

const ListDiseaseScreen = () => {
  const { diseases } = useListDiseases('cache-and-network', {
    limit: 1000,
  });
  const linkTo = useLinkTo();
  const route = useRoute();
  const theme = useTheme();
  // get cons by id from route
  // console.log('route:', route.params);
  const { consultation } = useGetConsultation(route?.params?.id);
  const parsedQuesAnsCons = JSON.parse(consultation?.questionResponse);
  const ListDiseasePossible = findDisease(parsedQuesAnsCons, diseases?.filter(({ _deleted }) => !_deleted));

  ListDiseasePossible.sort((a, b) => b.ponderationCount - a.ponderationCount);
  const [modifyA, setModifyA] = useState(false);

  return (
    <MaxWidthContainer
      withScrollView="keyboardAware"
      outerViewProps={{
        style: {
          padding: 24,
        },
      }}
    >
      <InfoCard
        title="alertInfo"
        iconShow="alert-circle-outline"
        description="Il ne s’agit pas d’un diagnostic médical mais d’une suspicion de maladie sur la base des réponses que vous avez fournies.
La démarche à suivre varie selon l’indication accolée au titre de la maladie sélectionnée (Voir tutoriel sur l’auto-examen – résultat de l’auto-examen) :
1) « Urgence » ou « MRLC » : Appelez votre véto dans les plus brefs délais.
2) « Maladie non récurrente » : Vous devez partager cet auto-examen avec votre véto qui vous proposera une prestation de conseil, une téléconsultation, ou une intervention sur place.
3) « Maladie récurrente » ou « Maladie du protocole de soins » : Nous vous conseillons de partager cet auto-examen avec votre véto qui vous conseillera sur la conduite à suivre : agir seul ou préciser le résultat via une prestation de conseil ou une téléconsultation."
        alertInfo="alertInfo"
      />
      <Text category="h3" appearance="hint" style={{ marginVertical: 10 }}>Maladies détectées par l'application</Text>
      {ListDiseasePossible?.map(
        (diseasePossible) => (
          <DiseaseCard
            key={diseasePossible?.id}
            currentDisease={diseasePossible}
            consultation={consultation}
          />
        ),
      )}

      <View style={{ flexDirection: 'column', justifyContent: 'space-between' }}>
        <Button
          leftIcon="edit-outline"
          appearance="outline"
          leftIconFill={theme['color-primary-600']}
          style={{ marginBottom: 25 }}
          onPress={() => { linkTo(`/consultation/questionnaire/${consultation?.id}?enCours=true`); }}
        >
          Modifier le questionnaire
        </Button>

        <InfoCard
          title="alertInfo"
          iconShow="alert-circle-outline"
            // iconCloseShow="close-outline"
          description="
        Pour modifier la localisation et le ciblage des principaux symptômes ou lésions observés , cliquez sur le bouton ci-dessous"
          alertInfo="alertInfo"
        />

        <Button style={{ marginBottom: 8 }} onPress={() => { setModifyA(true); }}>Modifier la localisation et le ciblage</Button>

      </View>
      <CustomModal
        title={'Vous pouvez toujours modifier vos réponses concernant la localisation et le ciblage des principaux symptômes ou lésions observées afin d\'être plus exhaustif et accéder ainsi des à des résultats plus précis. Les réponses apportées dans cette partie de l’auto-examen sont fondamentales car elles déterminent la suite du contenu de l’auto-examen. Une attention toute particulière vous est donc demandée pour cette étape.\n'
          + 'Etes-vous sûr de vouloir continuer ?'}
        before={<></>}
        noSafeArea
        visible={modifyA}
        scrollable={false}
        onClose={() => { setModifyA(false); }}
        insideModalStyle={{ padding: 20 }}
        buttonText="Modifier la localisation et le ciblage"
        buttonPress={() => {
          setModifyA(false);
          linkTo(`/consultation/questionnaire/${consultation?.id}?enCours=true&zoom=A)3)a)`);
        }}
        secondButtonText="Annuler"
        secondButtonPress={() => {
          setModifyA(false);
        }}
      />
    </MaxWidthContainer>
  );
};

export default ListDiseaseScreen;
