import * as React from 'react';
import { Spinner, Text, useTheme } from '@ui-kitten/components';
import { TouchableOpacity, View } from 'react-native';
import { Icon as IconUIKitten } from '@ui-kitten/components/ui/icon/icon.component';
import Chat from '../Chat/Chat';

export enum AdditionalInfosState {
  infos = 'infos',
  chat = 'chat',
  documents = 'documents',
}

export type AdditionalInfosProps = {
  state?: AdditionalInfosState
  onClose: ()=> void
  channel: string
};

const renderInnerComponent = (state: AdditionalInfosState, channel: string) => {
  const theme = useTheme();
  switch (state) {
    case AdditionalInfosState.infos:
      return <></>;
    case AdditionalInfosState.chat:
      return <Chat channel={channel} backgroundColor={theme['background-color']} />;
    case AdditionalInfosState.documents:
      return <></>;
    default:
      return <></>;
  }
};

export function AdditionalInfos(props:AdditionalInfosProps) {
  const { state, onClose, channel } = props;

  if (!state) {
    return <></>;
  }

  let title = '';
  switch (state) {
    case AdditionalInfosState.infos:
      title = 'Informations';
      break;
    case AdditionalInfosState.chat:
      title = 'Messagerie';
      break;
    case AdditionalInfosState.documents:
      title = 'Documents';
      break;
    default:
      title = '';
      break;
  }

  return (
    <View style={{ padding: 17, flex: 1 }}>
      <Text category="h1" style={{ fontSize: 35, textAlign: 'center' }}>{title}</Text>
      <TouchableOpacity
        onPress={() => { onClose(); }}
        style={{
          position: 'absolute', left: 17, top: 17,
        }}
      >
        <IconUIKitten name="close" style={{ width: 30, height: 30 }} fill="black" />
      </TouchableOpacity>
      {renderInnerComponent(state, channel)}
    </View>
  );
}
