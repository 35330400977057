import React from 'react';

import { Text } from '@ui-kitten/components';
import { useLinkTo } from '@react-navigation/native';
import MaxWidthContainer from '../../../components/MaxWidthContainer';
import InfoCard from '../../../screenComponents/InfoCard';
import GoCustomCard from '../../../components/GoCustomCard';
import VetoMonCabinetScreenBL
  from '../../../screenBusinessLogic/vetoScreensBL/MonCabinetScreenBL/VetoMonCabinetScreenBL';

const VetoMonCabinetScreen = () => {
  const linkTo = useLinkTo();
  return (
    <MaxWidthContainer
      withScrollView="keyboardAware"
      outerViewProps={{
        style: {
          padding: 20,
          marginBottom: 20,
        },
      }}
    >
      <InfoCard
        title="Bienvenue sur votre espace Cabinet."
        iconShow="alert-circle-outline"
        // iconCloseShow="close-outline"
        description="Vous pouvez paramétrer vos informations personnelles de votre cabinet et gérer vos utilisateurs."
        alertInfo="alertInfo"
      />

      <GoCustomCard
        title1="Informations du cabinet"
        linkToCustom={() => { VetoMonCabinetScreenBL.allerMonCompteScreen({ state: 'information cabinet', linkTo, signUp: false }); }}
        minusSquareOutline="arrow-ios-forward"
      />
      <Text category="s2">Modifiez les informations de votre cabinet.</Text>

      <GoCustomCard
        title1="Mon compte"
        customStyle={{ marginTop: 32 }}
        linkToCustom={() => { VetoMonCabinetScreenBL.allerMonCompteScreen({ state: 'mon compte', linkTo }); }}
        minusSquareOutline="arrow-ios-forward"
      />
      <Text category="s2">Modifiez vos informations personnelles.</Text>

      <GoCustomCard
        title1="Utilisateurs"
        customStyle={{ marginTop: 32 }}
        linkToCustom={() => { VetoMonCabinetScreenBL.allerMonCompteScreen({ state: 'utilisateurs', linkTo }); }}
        minusSquareOutline="arrow-ios-forward"
      />
      <Text category="s2">Ajoutez des utilisateurs et donnez leur accès à votre espace ScanFlock.</Text>

      <GoCustomCard
        title1="Vos tarifications"
        linkToCustom={() => { VetoMonCabinetScreenBL.allerMonCompteScreen({ state: 'tarif-consultation', linkTo }); }}
        customStyle={{ marginTop: 32 }}
        minusSquareOutline="arrow-ios-forward"
      />
      <Text category="s2">Paramétrez le montant plafond de vos téléconsultations et de vos prestations de conseil suite au partage des auto-examens par l’éleveur.</Text>

      <GoCustomCard
        title1="Informations bancaires"
        linkToCustom={() => { VetoMonCabinetScreenBL.allerMonCompteScreen({ state: 'compte-bancaire', linkTo }); }}
        customStyle={{ marginTop: 32 }}
        minusSquareOutline="arrow-ios-forward"
      />
      <Text category="s2">Ajoutez ou modifiez vos informations bancaires.</Text>

      <GoCustomCard
        title1="Factures"
        linkToCustom={() => { VetoMonCabinetScreenBL.allerMonCompteScreen({ state: 'facture', linkTo }); }}
        customStyle={{ marginTop: 32 }}
        minusSquareOutline="arrow-ios-forward"
      />
      <Text category="s2">Vous pouvez consulter et télécharger les factures liées à vos consultations.</Text>

    </MaxWidthContainer>
  );
};

export default VetoMonCabinetScreen;
