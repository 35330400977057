import React, { useEffect, useState } from 'react';
import { SignIn as AmplifySignIn } from 'aws-amplify-react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Layout, Text } from '@ui-kitten/components';
import { useForm } from 'react-hook-form';
import { Platform, View } from 'react-native';
import { Hub } from 'aws-amplify';
import { useDimensions } from '@react-native-community/hooks';
import Form from '../Form/Form';
import TextInputComp from '../Form/TextInput';

import { AvailableValidationRules } from '../Form/validation';
import { ErrorMessage } from './components/ErrorMessage';
import MaxWidthContainer from '../MaxWidthContainer';
import Button from '../Button';

import LogoSvgIcon from '../../assets/LOGOS_SCANFLOCK/LOGO_COULEUR/LOGO_LONG/SVG/logoScan.svg';
import WebView from '../WebView';
import BaseModal from '../Modals/BaseModal';

interface SignInProps {
  signUp: () => void
  forgotPassword: () => void
  signIn: (email: string, password: string) => void
  error?: string
}

type LoginForm = {
  email: string;
  password: string;
  stayConnected: boolean;
};

const MySigIn = ({
  forgotPassword, signIn, signUp, error,
}: SignInProps) => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [lastUser, setLastUser] = useState<string>('');
  const [modalUrl, setModalUrl] = useState<string | false>(false);
  const { window } = useDimensions();

  const loginForm = useForm<LoginForm>();

  useEffect(() => {
    setErrorMessage(error);
  }, [error]);

  const [isConnecting, setIsConnecting] = useState(false);

  useEffect(() => {
    AsyncStorage.getItem('lastFirstname', (err?: Error, result?: string) => {
      if (result) {
        setLastUser(result);
      }
    });
  }, []);

  const login = (data: LoginForm) => {
    setIsConnecting(true);
    Hub.listen('auth', (res) => {
      setIsConnecting(false);
    });
    signIn(data.email, data.password);
  };

  // const LogoImage = () => <Image source={require('../../assets/LOGOS_SCANFLOCK/LOGO_COULEUR/LOGO_LONG/PNG/logo.png')} style={{ marginTop: 34, height: 150, width: 150 }} />;
  const LogoSvg = () => <LogoSvgIcon height={74} width={230} fill="#1C6B79" style={{ marginBottom: 30, marginTop: 150 }} />;

  return (
    <MaxWidthContainer
      withScrollView="keyboardAware"
      outerViewProps={{
        showsVerticalScrollIndicator: false,
      }}
      innerViewProps={{ style: { flex: 1 } }}
      maxWidth={450}
    >

      <Form<LoginForm> {...loginForm}>
        <Layout style={{
          marginHorizontal: 25,
          backgroundColor: 'transparent',
        }}
        >
          {/* eslint-disable-next-line global-require */}

          <Layout style={{ backgroundColor: 'transparent', justifyContent: 'center', alignItems: 'center' }}>
            <LogoSvg />
          </Layout>

          <ErrorMessage message={errorMessage} />
          <TextInputComp
            name="email"
            placeholder="Votre e-mail"
            validators={[
              AvailableValidationRules.required,
              AvailableValidationRules.email,
            ]}
          />
          <TextInputComp
            name="password"
            placeholder="Votre mot de passe"
            secureTextEntry
            withEyeToggle
            validators={[
              AvailableValidationRules.required,
              AvailableValidationRules.password,
            ]}
            icon="eye-off-outline"
          />

          {/**
            <Radio
              name="stayConnected"
              label="Souhaitez-vous rester connecté ?"
              labelPosition="before"
              status="info"
            />
          */}

          <Button
            size="medium"
            onPress={loginForm.handleSubmit((data) => {
              AsyncStorage.setItem('stayConnected', data.stayConnected ? 'true' : 'false');
              login(data);
            })}
            disabled={isConnecting}
            loading={isConnecting}
          >
            Se connecter
          </Button>
          {/**
          <Button
            size="medium"
            style={{
              width: 180,
              shadowColor: 'rgba(190, 190, 190, 0.5)',
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowRadius: 2,
              shadowOpacity: 1,
              elevation: 2,
            }}
            onPress={signUp}
          >
            S'inscrire
          </Button>
      */}

        </Layout>
      </Form>

      <Button appearance="ghost" style={{ justifyContent: 'flex-start' }} onPress={forgotPassword}>Mot de passe oublié ?</Button>
      {true || Platform.OS !== 'ios' ? <Button appearance="ghost" onPress={signUp}>Vous n'avez pas de compte ? S'inscrire</Button> : null}
      <View style={{
        flex: 1, alignItems: 'flex-start', marginHorizontal: 25,
      }}
      >
        <Text
          category="p2"
          style={{ fontSize: 11, marginTop: 15 }}
        >
          La société VETELSUD SAS collecte votre identifiant et votre mot de passe afin de vous permettre
          d’accéder et d’utiliser les services de Scanflock.
        </Text>
        <Text
          category="p2"
          style={{ fontSize: 11 }}
        >
          Ces informations sont obligatoires pour vous permettre d’accéder et d’utiliser les services.
        </Text>
        <Text
          category="p2"
          style={{ fontSize: 11 }}
        >
          <Text
            category="p2"
            style={{ fontSize: 11 }}
          >
            Pour en savoir plus sur l’utilisation de vos données personnelles par VETELSUD et pour exercer vos
            droits notamment vos droits d’accès, rectification et suppression, veuillez consulter la
          </Text>
          <Text
            category="p2"
            style={{ fontSize: 11 }}
          >
            {' '}
          </Text>
          <Text
            category="p2"
            style={{ fontSize: 11, textDecorationLine: 'underline' }}
            onPress={() => setModalUrl('https://scanflock.com/politique-de-confidentialite/?simple=1')}
          >
            Politique de confidentialité
          </Text>
          <Text
            category="p2"
            style={{ fontSize: 11 }}
          >
            .
          </Text>
        </Text>
        <BaseModal
          visible={modalUrl !== false}
          onClose={() => setModalUrl(false)}
        >
          <View
            style={{
              backgroundColor: 'white',
              borderRadius: 10,
              height: window.height * 0.9,
              maxHeight: window.height * 0.9,
              width: window.width * 0.9,
              flex: 1,
              justifyContent: 'space-between',
              overflow: 'hidden',
              padding: 7,
            }}
          >
            <WebView src={modalUrl || ''} />
            <Button onPress={() => setModalUrl(false)}>Fermer</Button>
          </View>
        </BaseModal>
      </View>
    </MaxWidthContainer>
  );
};

export default class SignIn extends AmplifySignIn {
  showComponent() {
    return (
      <MySigIn
        // @ts-expect-error : AWS does not expose Types
        signUp={() => this.changeState('signUp')}
        // @ts-expect-error : AWS does not expose Types
        forgotPassword={() => this.changeState('forgotPassword')}
        signIn={(email: string, password: string) => {
          this.setState({
            password, email, phone_number: email, error: null,
          // @ts-expect-error : AWS does not expose Types
          }, this.signIn);
        }}
        error={this.state.error}
      />
    );
  }
}
