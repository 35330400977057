import React from 'react';
import { FlatList } from 'react-native';
import MaxWidthContainer from '../../components/MaxWidthContainer';

import { useGetVetOffice } from '../../src/API/VetOffice';
import FactureComponent from '../../components/FactureComponent';

const VetoFacturesScreen = () => {
  const { vetOfficeData } = useGetVetOffice();

  return (
    <MaxWidthContainer
      outerViewProps={{
        style: {
          padding: 20,
          marginBottom: 20,
        },
      }}
    >
      <FlatList
        data={vetOfficeData?.billingHistories?.items}
        renderItem={({ item }) => (
          <FactureComponent
            billingHistory={item}
          />
        )}
      />
    </MaxWidthContainer>
  );
};

export default VetoFacturesScreen;
