import { RouteProp } from '@react-navigation/core/lib/typescript/src/types';

import {
  useCreateBuildingMutation,
  useUpdateBuildingMutation,
} from '../../src/API/Buildings';
import {
  useCreateZonesMutation,
  useDeleteZoneMutation,
  useUpdateZoneMutation,
} from '../../src/API/Zone';
import { useUser } from '../../src/API/UserContext';
import { useBreedingList } from '../../src/API/Breeding';
import {
  useCreateLotsMutation, useUpdateLotMutation,
} from '../../src/API/Lots';
import {
  AjoutAnimalForm, AjoutBatimentForm, AjoutLotForm, TabElevageParamList,
} from '../../types';
import { Animals, Buildings, Lots } from '../../src/API';
import { useCreateAnimalMutation, useUpdateAnimalMutation } from '../../src/API/Animals';

/**    CRUD    building       */
const useAjoutBatiment = () => {
  const { user } = useUser();
  /**           Create building            */
  const createBuilding = useCreateBuildingMutation();
  const createZone = useCreateZonesMutation();
  const deleteZone = useDeleteZoneMutation();
  const { breeding } = useBreedingList(user?.userGroup);

  /**           Update building            */
  const updateBuilding = useUpdateBuildingMutation();
  const updateZone = useUpdateZoneMutation();

  const updateBatiment = async (
    route: RouteProp<TabElevageParamList, 'modify-batiment'>,
    data: AjoutBatimentForm,
    currentBuilding: Buildings | undefined,
  ) => {
    const {
      zone, nameBuilding, address, zonesToDelete,
    } = data;
    if (user && breeding) {
      if (route?.params) {
        /**    MODIFY BUILDING AND ZONE      */
        // console.log('currentBuilding:', currentBuilding);
        const { data: thisBuilding } = await updateBuilding.updateBuilding({
          variables: {
            input: {
              id: route?.params?.id,
              breedingId: breeding?.id,
              nameBuilding,
              address,
              // eslint-disable-next-line no-underscore-dangle
              _version: currentBuilding?._version,
            },
          },
        });
        // console.log('currentBuilding?.zone?.items :', currentBuilding?.zone?.items);
        if (zone.items) {
          await Promise.all(Object.keys(zone.items).map(async (zoneId) => {
            // console.log('thisZone for update :', thisZone);
            const thisZone = zone.items[zoneId];
            if (zoneId.indexOf('new') <= -1) {
              const currentZone = currentBuilding?.zone?.items[zoneId];
              await updateZone.updateZone({
                variables: {
                  input: {
                    id: zoneId,
                    buildingId: thisBuilding?.updateBuildings?.id,
                    ...thisZone,
                    // eslint-disable-next-line no-underscore-dangle
                    _version: currentZone._version,
                  },
                },
              });
            } else {
              await createZone.createZones({
                variables: {
                  input: {
                    buildingId: thisBuilding?.updateBuildings?.id,
                    ...thisZone,
                  },
                },
              });
            }
          }));
        }
        if (zonesToDelete.length) {
          await Promise.all(zonesToDelete.map(async (thisZone) => {
            const currentZone = currentBuilding?.zone?.items[thisZone.id];
            await deleteZone.deleteZone({
              variables: {
                input: {
                  id: thisZone.id,
                  _version: currentZone._version,
                },
              },
            });
          }));
        }
      }
    }
  };

  const addBuilding = async (data: AjoutBatimentForm) => {
    const { zone, nameBuilding, address } = data;
    if (user && breeding) {
      /**    CREATE BUILDING AND ZONE      */
      const { data: currentBulding } = await createBuilding.createBuilding({
        variables: {
          input: {
            breedingId: breeding?.id,
            nameBuilding,
            address,
          },
        },
      });
      // console.log('les zones : ', zones);
      await Promise.all(Object.keys(zone.items).map(async (zoneId) => {
        // console.log('thisZone for update :', thisZone);
        const thisZone = zone.items[zoneId];
        // console.log('la zone dans le map :', zone);
        await createZone.createZones({
          variables: {
            input: {
              buildingId: currentBulding?.createBuildings?.id,
              ...thisZone,
            },
          },
        });
      }));
      return currentBulding;
      // console.log('curren bulding:', currentBulding);
    }
    return () => {};
  };
  return { updateBatiment, addBuilding };
};

/**         Create and Update LOT            */
const UseAjoutLot = () => {
  const { user } = useUser();

  /**    Modify lot & Create Lot    */
  const createLot = useCreateLotsMutation();
  const updateLots = useUpdateLotMutation();
  const { breeding } = useBreedingList(user.userGroup);

  const updateLotFunc = async (
    route: RouteProp<TabElevageParamList, 'modify-lot'>,
    data: AjoutLotForm,
    currentLot: Lots | undefined,
  ) => {
    if (user && breeding) {
      if (route.params && currentLot) {
        // console.log('2 current lot:', currentLot);
        /**      MODIFY LOT              */
        await updateLots.updateLot({
          variables: {
            input: {
              id: route?.params?.id,
              ...data,
              // eslint-disable-next-line no-underscore-dangle
              _version: currentLot._version,
            },
          },
        });
      }
    }
  };

  const addLot = async (data: AjoutLotForm) => {
    if (user && breeding) {
      /**   IF LOT DOESN'T EXIST CREATE LOT              */
      const { data: currentLot } = await createLot.createLots({
        variables: {
          input: {
            breedingId: breeding?.id,
            ...data,
          },
        },
      });
      return currentLot;
    }
    return () => {};
  };

  return { updateLotFunc, addLot };
};

/**    CRUD  ANIMAL     */

const UseAjoutAnimal = () => {
  const { user } = useUser();
  const createAnimal = useCreateAnimalMutation();
  const updateAnimal = useUpdateAnimalMutation();

  const { breeding } = useBreedingList(user.userGroup);
  // console.log('route animal modify:', route);
  const updateAnimaux = async (
    route: RouteProp<TabElevageParamList, 'modify-animal'>,
    data: AjoutAnimalForm,
    currentAnimal: Animals | undefined,
  ) => {
    if (user && breeding) {
      if (route.params && currentAnimal) {
        /**      MODIFY ANIMAL              */
        const { data: updatedAnimal } = await updateAnimal.updateAnimal({
          variables: {
            input: {
              id: route?.params?.id,
              ...data,
              // eslint-disable-next-line no-underscore-dangle
              _version: currentAnimal._version,
            },
          },
        });
        return updatedAnimal; // not used yet
      }
    }
    return () => {};
  };
  const addAnimal = async (data: AjoutAnimalForm) => {
    /**   IF ANIMAL DOESN'T EXIST THEN CREATE IT              */
    const { data: newAnimal } = await createAnimal.createAnimal({
      variables: {
        input: {
          breedingId: breeding?.id,
          ...data,
        },
      },
    });
    return newAnimal;
  };
  return { updateAnimaux, addAnimal };
};

export default {
  useAjoutBatiment,
  UseAjoutLot,
  UseAjoutAnimal,
};
