import { useLinkTo } from '@react-navigation/native';
import React from 'react';
import MaxWidthContainer from '../../components/MaxWidthContainer';

import FaireDemandeBseCard from '../../screenComponents/BilanSanitaireScreenComps/FaireDemandeBseCard';

const DemandeBseScreen = () => {
  const linkTo = useLinkTo();

  return (

    <MaxWidthContainer
      withScrollView="keyboardAware"
      outerViewProps={{
        style: {
          padding: 24,
        },
      }}
    >
      <FaireDemandeBseCard />

      {/* <Text category="h2" appearance="hint" style={{ marginTop: 40 }}>Précédents Bilans Sanitaires d'Élevage</Text>
      <Text category="s2" style={{ marginBottom: 8 }}>Consultez les précédents BSE réalisés avec l'application.</Text>
      <GoCustomCard title1="Historique" linkToCustom={() => { BseScreenBL.linkToSwitch({ state: 'historique-bse', linkTo }); }} minusSquareOutline="arrow-ios-forward" />

      <Text category="h1" style={{ marginTop: 40, marginBottom: 7 }}>Une question sur le BSE ?</Text>
      <InfoCard
        description={"Consultez notre FAQ afin d'obtenir une réponse sur les questions les plus "
                + "fréquemment posées à propos de l'application."}
        // imagePosition="right"
        imageUri=""
        buttonText="Accéder à la FAQ"
        buttonOnPress={() => { linkTo('/faq'); }}
      /> */}
    </MaxWidthContainer>

  );
};

export default DemandeBseScreen;
