import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { StyleSheet } from 'react-native';
import Animated from 'react-native-reanimated';
import { TabNotificationParamList } from '../../types';

import NotificationScreen from '../../screens/NotificationsScreen/NotificationScreen';
import NotificationsParamsScreen from '../../screens/NotificationsScreen/NotificationsParamsScreen';

/** Notification Section */
const Stack = createStackNavigator<TabNotificationParamList>();

export default ({ style }) => (
  <Animated.View style={StyleSheet.flatten([{ flex: 1 }, style])}>
    <Stack.Navigator
      initialRouteName="notifications"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="notifications"
        component={NotificationScreen}
      />
      <Stack.Screen
        name="notifications-params"
        component={NotificationsParamsScreen}
      />
    </Stack.Navigator>
  </Animated.View>
);
