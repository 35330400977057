import moment from 'moment';
import 'moment/locale/fr';
import 'expo-dev-client';
import 'react-native-get-random-values';
import { StatusBar } from 'expo-status-bar';
import React, {
  FC, useEffect, useRef,
// useRef
} from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import AppLoading from 'expo-app-loading';
import {
  ApplicationProvider, IconRegistry, Layout,
} from '@ui-kitten/components';
import * as eva from '@eva-design/eva';
import { EvaIconsPack } from '@ui-kitten/eva-icons';

import {
  Alert, Platform, TextInput, Text, AppState, AppStateStatus,
} from 'react-native';
import * as Sentry from 'sentry-expo';
import { Authenticator } from 'aws-amplify-react-native';
import { ApolloProvider } from 'react-apollo';
import { Amplify } from 'aws-amplify';
import Auth from '@aws-amplify/auth';
import { I18n } from '@aws-amplify/core';
import * as Updates from 'expo-updates';
import { UpdateEventType } from 'expo-updates';
import useColorScheme from './hooks/useColorScheme';
import Navigation from './navigation';
import useAssetLoader from './hooks/useAssetLoader';

import scanflockTheme from './style-ui-kitten/custom-theme';
import mapping from './style-ui-kitten/mapping.json';
import vetoMapping from './style-ui-kitten/veto/mapping.json';
import vetoScanflockTheme from './style-ui-kitten/veto/custom-theme';

import ActivityIndicator from './components/ActivityIndicator';
import client, { Rehydration } from './src/Apollo';
import { UserContext, UserProvider } from './src/API/UserContext';
import {
  ForgotPassword, SignIn, SignUp, ConfirmSignUp,
} from './components/Auth';
import ErrorMap from './components/Auth/ErrorMap';

import awsExports from './src/aws-exports';
import { GlobalStateProvider } from './utils/GlobalStateContext';
import useUpdatesChecker from './hooks/useUpdatesChecker';
import { TutorialStateContextProvider } from './utils/TutorialStateContext';

TextInput.defaultProps = TextInput.defaultProps || {}; TextInput.defaultProps.allowFontScaling = false;
Text.defaultProps = Text.defaultProps || {}; Text.defaultProps.allowFontScaling = false;

moment.locale('fr');

Sentry.init({
  dsn: 'https://9bccb617ba1348509eb8db53e7f01951@o239428.ingest.sentry.io/6035506',
  enableInExpoDevelopment: false,
  debug: false, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
});

Amplify.configure({
  ...awsExports,
  Analytics: {
    disabled: true,
  },
});

Auth.configure(awsExports);

const fonts = {
  // eslint-disable-next-line global-require
  Icons: require('./components/Icon/icomoon.ttf'),

  // eslint-disable-next-line global-require
  sourceSansPro_Regular: require('./assets/fonts/SourceSansPro-Regular.ttf'),
  // eslint-disable-next-line global-require
  sourceSansPro_SemiBold: require('./assets/fonts/SourceSansPro-SemiBold.ttf'),
  // eslint-disable-next-line global-require
  sourceSansPro_Bold: require('./assets/fonts/SourceSansPro-Bold.ttf'),
};

I18n.setLanguage('fr');
I18n.putVocabularies({
  fr: {
    'An account with the given email already exists.': 'Un compte avec une adresse e-mail identique existe déjà.',
    'User is disabled.': 'Cet utilisateur est désactivé.',
    'User is not confirmed.': 'Cet utilisateur n\'est pas vérifié.',
  },
});

if (Platform.OS !== 'web') {
  Updates.addListener((event) => {
    if (event.type === UpdateEventType.UPDATE_AVAILABLE) {
      Alert.alert(
        'Mise à jour',
        'Une mise à jour est disponible. L\'application va être relancée.\n'
          + 'Si vous annulez cette action, pensez à relancer l\'application vous même.',
        [
          { text: 'OK', onPress: () => Updates.reloadAsync() },
          { text: 'Annuler', style: 'cancel', onPress: () => {} },
        ],
        { cancelable: true },
      );
    }
  });
}

const App = () => {
  const colorScheme = useColorScheme();
  const tmpPasswd = useRef<string>();
  const setTmpPasswd = (passwd: string) => { tmpPasswd.current = passwd; };
  const getTmpPasswd = () => tmpPasswd.current;

  const assetLoader = useAssetLoader({ fonts });

  useUpdatesChecker();

  if (!assetLoader.isReady) {
    if (Platform.OS !== 'web') {
      return (
        <>
          <StatusBar hidden />
          <AppLoading {...assetLoader.getAppLoadingProps()} />
        </>
      );
    }
    (async () => {
      const appLoadingProps = assetLoader.getAppLoadingProps();
      if (appLoadingProps.startAsync && appLoadingProps.onFinish) {
        await appLoadingProps.startAsync();
        appLoadingProps.onFinish();
      }
    })();
    return (
      <ApplicationProvider
        {...eva}
        // @ts-ignore
        customMapping={mapping}
        theme={{ ...eva.light, ...scanflockTheme }}
      >
        <Layout style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <ActivityIndicator />
        </Layout>
      </ApplicationProvider>
    );
  }

  // eslint-disable-next-line react/prop-types
  const AuthContainer: FC<{}> = ({ children }) => <Layout style={{ flex: 1 }}>{children}</Layout>;

  // @ts-ignore
  return (
    <ApolloProvider client={client}>
      <IconRegistry icons={[EvaIconsPack]} />
      <UserProvider>
        <TutorialStateContextProvider>
          <UserContext.Consumer>
            {
              ({ cognitoUser, isVeto, user }) => (
                <SafeAreaProvider>
                  <ApplicationProvider
                    {...eva}
                    theme={isVeto ? { ...eva.light, ...vetoScanflockTheme } : { ...eva.light, ...scanflockTheme }}
          // @ts-ignore
                    customMapping={isVeto ? vetoMapping : mapping}
                  >

                    <Rehydration>
                      <GlobalStateProvider>
                        <Layout style={{ flex: 1 }}>
                          {
                          // eslint-disable-next-line no-nested-ternary
                          cognitoUser
                            ? (<Navigation colorScheme={colorScheme} />)
                            : (
                              <Authenticator
                                hideDefault
                                usernameAttributes="email"
                                container={AuthContainer}
                                onStateChange={(e) => console.log('Authenticator onStateChange :', e)}
                                errorMessage={ErrorMap}
                                authState={Platform.OS === 'web' && window.location.hash.indexOf('inscription') > -1 ? 'signUp' : undefined}
                              >
                                <SignIn />
                                <ForgotPassword />
                                {/* @ts-expect-error : Cannot change AWS prop types */}
                                <SignUp setTmpPasswd={setTmpPasswd} />
                                {/* @ts-expect-error : Cannot change AWS prop types */}
                                <ConfirmSignUp getTmpPasswd={getTmpPasswd} />
                              </Authenticator>
                            )
                  }
                        </Layout>
                      </GlobalStateProvider>
                    </Rehydration>
                  </ApplicationProvider>
                </SafeAreaProvider>
              )
            }
          </UserContext.Consumer>
        </TutorialStateContextProvider>
      </UserProvider>
    </ApolloProvider>

  );
};

export default App;
