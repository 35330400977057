import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { TableauDeBordParamList } from '../../types';
import RealiserAutoExamScreen from '../../screens/ConsultationScreen/RealiserAutoExamScreen';
import TableauDeBordScreen from '../../screens/TableauDeBordScreen/TableauDeBordScreen';
import FacturesScreen from '../../screens/FacturesScreen';

const Stack = createStackNavigator<TableauDeBordParamList>();

export default () => (
  <Stack.Navigator
    initialRouteName="tableau-de-bord"
    screenOptions={{
      headerShown: false,
    }}
  >
    <Stack.Screen
      name="tableau-de-bord"
      component={TableauDeBordScreen}
    />
    <Stack.Screen
      name="faire-demande-teleconsultation"
      component={RealiserAutoExamScreen}
    />
    <Stack.Screen
      name="factures"
      component={FacturesScreen}
    />
  </Stack.Navigator>
);
