import React from 'react';

import { Text } from '@ui-kitten/components';

import Constants from 'expo-constants';
import MaxWidthContainer from '../../../components/MaxWidthContainer';
import AdminTableauDeBordHeaderCard from '../../../screenComponents/TableauDeBordScreenComps/AdminTableauDeBordHeaderCard';

export default function AdminTableauDeBordScreen() {
  return (
    <MaxWidthContainer
      withScrollView="keyboardAware"
      outerViewProps={{
        style: {
          paddingHorizontal: 20,
        },
      }}
    >
      <AdminTableauDeBordHeaderCard />

      <Text category="p1">
        v
        {Constants.manifest.version}
      </Text>
    </MaxWidthContainer>
  );
}
