import React, { useEffect, useState } from 'react';
import { FlatList, ListRenderItem, View } from 'react-native';

import { Text } from '@ui-kitten/components';
import { useLinkTo } from '@react-navigation/native';
import FTextInput from '../components/Form/TextInput';
import Button from '../components/Button';
import DatePicker from '../components/Form/DatePicker';

import SearchCompBL from '../screenBusinessLogic/SearchCompBL';
import ElevageScreenBL from '../screenBusinessLogic/ElevageScreen/ElevageScreenBL';
import { useUser } from '../src/API/UserContext';

type SearchComponentProps<Item> = {
  title?: string
  state: string | undefined; // | (() => void);
  keyExtractor: <T extends Item>(item: T, index: number) => string;
  data: Item[] | undefined | null;
  placeholder: string;
  renderItem: ListRenderItem<Item>
  opened: boolean;
  searchButton?: boolean;
  fetchMore?: string;
  currentNextToken?: string;
};

const SearchComponent = <T extends object>(props: SearchComponentProps<T>) => {
  const {
    state, data, placeholder, renderItem, keyExtractor, title, opened, searchButton,
    fetchMore, currentNextToken,
  } = props;

  const user = useUser();
  const linkTo = useLinkTo();

  const [searchedText, setSearchedText] = useState<undefined | string>('');
  const [searchedDate, setSearchedDate] = useState('');

  useEffect(() => {
    setSearchedDate('');
    setSearchedText('');
    return () => {

    };
  }, [state]);

  let searchedItems = data;
  if (searchedText || searchedDate) {
    searchedItems = SearchCompBL.searchItems({
      data, searchedText, searchedDate,
    });
  }

  return (
    <>
      {/* console.log('RENDER') */}
      {title && <Text>{title}</Text>}
      {opened && (
      <View style={{ height: 70 }}>
        <FTextInput
          name={state}
          placeholder={placeholder}
          value={searchedText}
          style={{ marginBottom: 30, marginHorizontal: 5 }}
          // eslint-disable-next-line no-underscore-dangle
          onChangeValue={(enteredValue) => {
            setSearchedText(enteredValue as string);
          }}
          icon="search-outline"
        />
      </View>

      )}

      {state === 'consultations'
        ? (opened && <DatePicker name="date" onChangeValue={(l) => setSearchedDate(l)} icon="calendar-outline" placeholder="jj/mm/aaaa" style={{ marginHorizontal: 5 }} />)
        : (<></>)}

      <FlatList<T>
        data={searchedItems}
        keyExtractor={keyExtractor}
        ListHeaderComponent={() => (opened
          ? (<Text category="h4" style={{ textAlign: 'center', marginTop: 10 }}>Résultat(s) de la recherche</Text>)
          : (<></>)
        )}
        renderItem={renderItem}
        ListFooterComponent={() => (
          <>
            {searchButton
              ? (<></>)
              : (state !== 'realiseAutoExamConsultation' && user.user?.userGroup !== 'veto' && (
              <>
                {currentNextToken && (
                <Button
                              // leftIcon="plus-outline"
                  onPress={async () => {
                    // console.log('goVetoVoirPlusScreenSwitch', user);
                    if (user.user && user.user.userGroup === 'veto') {
                      // console.log('goVetoVoirPlusScreenSwitch');
                      ElevageScreenBL.goVetoVoirPlusScreenSwitch({ state, linkTo });
                    } else if (user.user && user.user.userGroup === 'eleveur') {
                      // console.log('goVoirPlusScreenSwitch');

                      ElevageScreenBL.goVoirPlusScreenSwitch({ state, linkTo });
                    }
                  }}
                              // leftIconFill="#1C6B79"
                  appearance="outline"
                  style={{ marginTop: 20 }}
                >
                  {`Voir plus ${state === 'animaux' ? "d'animaux" : `de ${state}`}`}
                </Button>
                )}
              </>

              )
              )}
          </>
        )}
      />
    </>

  );
};

export default SearchComponent;
