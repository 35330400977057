import { useRoute } from '@react-navigation/native';
import { Text } from '@ui-kitten/components';

import React, { useState } from 'react';
import { RouteProp } from '@react-navigation/core/lib/typescript/src/types';

import { View } from 'react-native';
import MaxWidthContainer from '../../components/MaxWidthContainer';

import SearchComponent from '../../screenComponents/SearchComponent';
import AnimalCard from '../../screenComponents/AnimalCard';
import Button from '../../components/Button';
import { TabElevageParamList } from '../../types';
import { useGetLot, useUpdateLotMutation } from '../../src/API/Lots';
import CustomModal from '../../components/Modals/CustomModal';
import ActionSheet from '../../components/ActionSheet/ActionSheet';

import { useGetBuilding } from '../../src/API/Buildings';
import { useBreedingList } from '../../src/API/Breeding';
import { useUpdateAnimalMutation } from '../../src/API/Animals';
import ActivityIndicator from '../../components/ActivityIndicator';
import GoCustomCard from '../../components/GoCustomCard';
import { useUser } from '../../src/API/UserContext';

const ManageAffectedAnimalsScreen = () => {
  const route = useRoute<RouteProp<TabElevageParamList, 'manage-affected-animals'>>();
  const { user } = useUser();
  const {
    id, whichPage, manage, listTitle,
  } = route.params;
  // console.log('route ManageAffectedAnimalsScreen: ', id);
  // console.log('whichPage ManageAffectedAnimalsScreen: ', whichPage);

  const { breeding, loading } = useBreedingList(user?.userGroup);
  // console.log('breeding :', breeding?.animals?.items);
  // console.log('la route :', route.params);

  let currentData;
  let numberLot: string | undefined = '';
  let notAffectedItems;
  const { lot, loading: loadingLot, refetch: refetchLot } = useGetLot(id);
  const { building, loading: loadingBuilding, refetch: refetchBuilding } = useGetBuilding(id);
  // console.log('lot', lot);
  // console.log('building', building);

  if (whichPage === 'lot') {
    // eslint-disable-next-line no-underscore-dangle
    currentData = lot?.animals?.items?.filter((ani) => ani && !ani._deleted);
    numberLot = lot?.numberLot;
    // eslint-disable-next-line no-underscore-dangle
    notAffectedItems = breeding?.animals?.items?.filter(
      (each) => each && !each._deleted && each.lotsId !== route.params.id,
    );

    // console.log('notAffectedItems', notAffectedItems);
  } else if (whichPage === 'batiment') {
    if (listTitle === 'Animaux') {
      // eslint-disable-next-line no-underscore-dangle
      currentData = building?.animals?.items?.filter(((ani) => ani && !ani._deleted));

      notAffectedItems = breeding?.animals?.items?.filter(
        // eslint-disable-next-line no-underscore-dangle
        (each) => each && !each._deleted && each.buildingId !== route.params.id,
      );
    } else if (listTitle === 'Lots') {
      // eslint-disable-next-line no-underscore-dangle
      currentData = building?.lots?.items?.filter((lo) => lo && !lo._deleted);
      notAffectedItems = breeding?.lots?.items?.filter(
        // eslint-disable-next-line no-underscore-dangle
        (each) => each && !each._deleted && each.buildingId !== route.params.id,
      );

      // console.log(notAffectedItems);
    }
  }

  // console.log('building ManageAffectedAnimalsScreen: ', building);

  const affectedAnimalsToLotQuantity = currentData?.length;

  const [openDelete, setOpenDelete] = useState<{ title : string, id: string, _version: number }>();
  const [openAffectation, setOpenAffectation] = useState();

  const updateAnimal = useUpdateAnimalMutation();
  const updateLot = useUpdateLotMutation();

  const affecter = async (id: string, _version: number) => {
    // console.log('id affecter animal:', id);
    // console.log('_version affecter animal:', _version);
    // console.log('route affecter animal:', route.params.id);
    // console.log('currentData ManageAffectedAnimalsScreen: ', notAffectedItems);

    if (id) {
      if (whichPage === 'lot') {
        await updateAnimal.updateAnimal({
          variables: {
            input: {
              id,
              lotsId: route.params.id,
              // eslint-disable-next-line no-underscore-dangle
              _version,
            },
          },
        });
      } else if (whichPage === 'batiment') {
        if (listTitle === 'Animaux') {
          await updateAnimal.updateAnimal({
            variables: {
              input: {
                id,
                buildingId: route.params.id,
                // eslint-disable-next-line no-underscore-dangle
                _version,
              },
            },
          });
        } else if (listTitle === 'Lots') {
          await updateLot.updateLot({
            variables: {
              input: {
                id,
                buildingId: route.params.id,
                _version,
              },
            },
          });
        }
      }
    }
    setOpenDelete(undefined);
    if (refetchLot) {
      await refetchLot();
    }
    if (refetchBuilding) {
      await refetchBuilding();
    }
  };

  const supprimer = async (id: string, _version: number) => {
    // console.log('id supprimer animal:', id);
    // console.log('_version affecter animal:', _version);
    // console.log('route affecter animal:', route.params.id);
    if (id) {
      if (whichPage === 'lot') {
        await updateAnimal.updateAnimal({
          variables: {
            input: {
              id,
              lotsId: null,
              // eslint-disable-next-line no-underscore-dangle
              _version,
            },
          },
        });
      } else if (whichPage === 'batiment') {
        if (listTitle === 'Animaux') {
          await updateAnimal.updateAnimal({
            variables: {
              input: {
                id,
                buildingId: null,
                zoneId: null,
                // eslint-disable-next-line no-underscore-dangle
                _version,
              },
            },
          });
        } else if (listTitle === 'Lots') {
          await updateLot.updateLot({
            variables: {
              input: {
                id,
                buildingId: null,
                _version,
              },
            },
          });
        }
      }
    }

    setOpenDelete(undefined);
    if (refetchLot) {
      await refetchLot();
    }
    if (refetchBuilding) {
      await refetchBuilding();
    }
  };

  const confirmButton = () => {
    /**
     * cet animal à ce lot
     */
    if (openDelete?.title.includes('affecter')) {
      // console.log('Confimer affectation', openDelete.id);
      // eslint-disable-next-line no-underscore-dangle
      return affecter(openDelete.id, openDelete._version);
    } if (openDelete?.title.includes('supprimer')) {
      // console.log('Confimer suppression', openDelete.id);
      // eslint-disable-next-line no-underscore-dangle
      return supprimer(openDelete.id, openDelete._version);
    }
    return () => {};
  };

  const handleChosenCard = (item, add : boolean) => {
    let text = '';
    if (whichPage === 'lot') {
      if (add) {
        text = "'affecter cet animal à ce lot";
      } else {
        text = 'e supprimer cet animal de ce lot';
      }
    } if (whichPage === 'batiment') {
      // if animal
      if (listTitle === 'Animaux') {
        if (add) {
          text = "'affecter cet animal à ce bâtiment";
        } else {
          text = 'e supprimer cet animal de ce bâtiment';
        }
      } else if (listTitle === 'Lots') {
        if (add) {
          text = "'affecter ce lot à ce bâtiment";
        } else {
          text = 'e supprimer ce lot de ce bâtiment';
        }
      }
    }
    setOpenDelete({
      // eslint-disable-next-line no-underscore-dangle
      title: text, id: item.item.id, _version: item.item._version,
    });
  };

  const renderAffectedCards = (item) => {
    // eslint-disable-next-line no-underscore-dangle
    // console.log('deleted', lot);
    // eslint-disable-next-line no-underscore-dangle
    if (lot && lot._deleted !== true) {
      return (
        <AnimalCard
          name={item.item.name}
          numberId={item.item.numberID}
          numberLot={item.item.lots ? (item.item.lots.numberLot) : (numberLot)}
          minusSquareOutline="trash-2-outline"
          onPress={() => { handleChosenCard(item, false); }}
        />
      );
      // eslint-disable-next-line no-underscore-dangle
    } if (building && building._deleted !== true) {
      if (listTitle === 'Animaux') {
        return (
          <AnimalCard
            name={item.item.name}
            numberId={item.item.numberID}
            numberLot={item.item.lots ? (item.item.lots.numberLot) : (numberLot)}
            minusSquareOutline="trash-2-outline"
            onPress={() => {
              handleChosenCard(item, false);
            }}
          />
        );
      }
      return (
        <GoCustomCard
          minusSquareOutline="trash-2-outline"
          key={item.item.id}
          title1={item.item.name}
          title2={`Numéro de lot: ${item.item.numberLot}`}
          linkToCustom={() => {
            // console.log('Lot card renderAffectedCards');
            handleChosenCard(item, false);
          }}
          customStyle={{ marginHorizontal: 5 }}
        />
      );
    }
    return () => {};
  };

  const renderNotAffectedCards = (item) => {
    // console.log('animal renderNotAffectedCards:', item);
    // console.log('lot renderNotAffectedCards:', lot);
    // eslint-disable-next-line no-underscore-dangle
    if (lot && lot._deleted !== true) {
      return (
        <AnimalCard
          key={item.item.id}
          name={item.item.name}
          numberId={item.item.numberID}
          numberLot={item.item.lots && item.item.lots.numberLot}
          minusSquareOutline="plus-square-outline"
          onPress={() => {
            handleChosenCard(item, true);
          }}
        />
      );
      // eslint-disable-next-line no-underscore-dangle
    } if (building && building._deleted !== true) {
      if (listTitle === 'Animaux') {
        return (
          <AnimalCard
            key={item.item.id}
            name={item.item.name}
            numberId={item.item.numberID}
            numberLot={item.item.lots ? (item.item.lots.numberLot) : (numberLot)}
            minusSquareOutline="plus-square-outline"
                //* eslint-disable-next-line no-underscore-dangle
            onPress={() => {
              handleChosenCard(item, true);
            }}
          />
        );
      }
      return (
        <GoCustomCard
          minusSquareOutline="plus-square-outline"
          key={item.item.id}
          title1={item.item.name}
          title2={`Numéro de lot: ${item.item.numberLot ? (item.item.numberLot) : ('L\'animal n\'est affecté à aucun lot')}`}
          linkToCustom={() => {
            // console.log('Lot card renderNotAffectedCards');
            handleChosenCard(item, true);
          }}
          customStyle={{ marginHorizontal: 5 }}
        />
      );
    }
    return () => {};
  };

  const renderTitle = () => {
    if (listTitle === 'Animaux') {
      return 'animal';
    } if (listTitle === 'Lots') {
      return 'lot';
    }
    return () => {};
  };

  const renderSearchPlacehodlerTitle = () => {
    if (listTitle === 'Animaux') {
      return 'animal';
    } if (listTitle === 'Lots') {
      return 'lot';
    }
    return () => {};
  };

  const renderAffectationTitle = () => {
    switch (listTitle) {
      case 'Animaux':
        return 'des animaux';
        break;
      case 'Lots':
        return 'des lots';
        break;
      default:
        break;
    }
    return () => {};
  };

  return (
    <>
      <MaxWidthContainer
        outerViewProps={{
          style: {
            padding: 24,
          },
        }}
      >
        <Text category="h5" style={{ marginBottom: 10 }}>
          {`Sélectionnez ${manage} qui composeront votre ${whichPage === 'batiment' ? 'bâtiment' : whichPage}`}
        </Text>

        <Button
          leftIcon="plus-outline"
          leftIconFill="#fff"
          onPress={() => { setOpenAffectation('open Affectation sheet'); }}
        >
          {`Affecter un ${renderTitle()} à ce ${whichPage === 'batiment' ? 'bâtiment' : whichPage}`}
        </Button>

        {affectedAnimalsToLotQuantity > 0 && (
        <>
          <Text category="p1" appearance="hint" style={{ marginTop: 20 }}>{`${listTitle} affectés (${affectedAnimalsToLotQuantity || '0'})`}</Text>
          <SearchComponent
            state="animal"
            data={currentData}
            keyExtractor={(item) => item.id}
            placeholder={`Rechercher un ${renderSearchPlacehodlerTitle()}`}
            opened
            renderItem={(item) => (
              <>
                {loading
                  ? <ActivityIndicator center margin={10} />
                  : (renderAffectedCards(item))}
              </>
            )}
          />
        </>
        )}

      </MaxWidthContainer>

      <ActionSheet
        title={`Affecter ${renderAffectationTitle()} à ce ${whichPage === 'batiment' ? 'bâtiment' : whichPage}`}
        before={<></>}
        noSafeArea
        scrollable={false}
        visible={openAffectation !== undefined}
        onClose={() => setOpenAffectation(undefined)}
      >
        <View style={{ padding: 20, paddingBottom: 0, flex: 1 }}>
          <SearchComponent
            title={`Rechercher un ${renderSearchPlacehodlerTitle()}`}
            state={renderSearchPlacehodlerTitle()}
            opened
            onChangeSearchText={() => {}}
            keyExtractor={(item) => item.id}
            data={notAffectedItems}
            searchButton
            placeholder={`Rechercher un ${renderSearchPlacehodlerTitle()}`}
            renderItem={(item) => renderNotAffectedCards(item)}
          />
        </View>

      </ActionSheet>

      <CustomModal
        title={`Vous êtes sur le point d${openDelete?.title}.`
          + '\n'
          + 'Etes-vous sûr de vouloir continuer ?'}
        before={<></>}
        noSafeArea
        visible={openDelete !== undefined}
        scrollable={false}
        onClose={() => { setOpenDelete(undefined); }}
        insideModalStyle={{ padding: 20 }}
        buttonText="Confirmer"
        heightPercentage={0.36}
        buttonPress={() => confirmButton()}
      />
    </>
  );
};

export default ManageAffectedAnimalsScreen;
