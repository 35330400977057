import { Text, useTheme } from '@ui-kitten/components';

import React, { useState } from 'react';

import moment from 'moment';
import { useLinkTo } from '@react-navigation/native';
import TeleconsultationCard from '../../../components/TeleconsultationCard';
import Button from '../../../components/Button';
import InfoCard from '../../InfoCard';
import CustomModal from '../../../components/Modals/CustomModal';
import { Consultation } from '../../../src/API';
import DateUtils from '../../../utils/DateUtils';

const PartageDemandeConsultCards = (
  { modifyConsultation, consultationData }: { consultationData: Consultation },
) => {
  const theme = useTheme();
  // console.log('PartageDemandeConsultCards', consultationData.consultationData);
  const now = moment(new Date()).format('YYYY-MM-DD');
  const linkTo = useLinkTo();

  // for modal
  const [openDelete, setOpenDelete] = useState<{ title : string, description?: string }>();

  const openShareModal = () => {
    setOpenDelete({ title: 'partager cet auto-examen', description: `Attention, le vétérinaire est suspectible de facturer le conseil apporté sur cette consultation (montant maximum : ${consultationData.breeding?.vetOffice?.maxPriceConsultation} €).` });
  };
  const openModalFaireDemandeTelecons = () => {
    setOpenDelete({ title: 'faire une demande de téléconsultation' });
  };

  const shareConsultation = async () => {
    if (consultationData) {
      await modifyConsultation({
        variables: {
          input: {
            id: consultationData?.id,
            shared: true,
            sharedAt: now,
            // eslint-disable-next-line no-underscore-dangle
            _version: consultationData?._version,
          },
        },
      });
    }
    setOpenDelete(undefined);
  };

  const faireDemandeTelecons = async () => {
    if (consultationData) {
      await modifyConsultation({
        variables: {
          input: {
            id: consultationData?.id,
            teleconsultation: true,
            teleconsAskAt: now,
            // eslint-disable-next-line no-underscore-dangle
            _version: consultationData?._version,
          },
        },
      });
    }
    setOpenDelete(undefined);
  };

  return (
    <>
      {consultationData?.shared
        ? (
          <InfoCard
            title="alertInfo"
            iconShow="alert-circle-outline"
            // iconCloseShow="close-outline"
            description={`Cette consultation a été partagé à votre vétérinaire ${consultationData?.sharedAt ? `le ${moment(consultationData?.sharedAt).format('L')}` : ''}.\n`
                + '\n'
                  + "Vous avez désormais accès à une messagerie afin de pouvoir échanger avec votre vétérinaire. Il pourra également consulter et partager des documents dans l'espace dédié."}
            alertInfo="alertInfo"
          />
        )
        : (
          <>
            <Text category="h2" appearance="hint" style={{ marginTop: 40 }}>Partage de l'auto-examen</Text>
            <Text category="s2" style={{ marginVertical: 10 }}>Partagez cet auto-examen à votre vétérinaire.</Text>
            <Button
              onPress={() => { openShareModal(); }}
              status="control"
              leftIcon="share-outline"
              style={{ backgroundColor: theme['color-primary-300'], borderColor: theme['color-primary-300'] }}
            >
              Partager l'auto-examen
            </Button>
          </>
        )}

      {/**      */}
      <Text category="h2" appearance="hint" style={{ marginTop: 40, marginBottom: 10 }}>Téléconsultation</Text>

      {consultationData?.teleconsultation
        ? (
          <>
            {!consultationData?.dateTeleCons && (
            <>
              <InfoCard
                title="alertInfo"
                iconShow="alert-circle-outline"
              // iconCloseShow="close-outline"
                description={consultationData?.teleconsAskAt ? `Une demande de téléconsultation a été demandée le ${DateUtils.convertDate(consultationData?.teleconsAskAt)} La date et l'heure du rendez-vous seront fixées lors de la confirmation de votre vétérinaire.` : ''}
                alertInfo="alertInfo"
              />

            </>
            )}
            <TeleconsultationCard
              consultationData={consultationData && consultationData}
              consultation="RDV"
              detailConsultationPage
            />
          </>
        ) : (consultationData?.breeding?.vetOffice ? (
          <>
            <Button
              style={{ marginVertical: 10 }}
              onPress={() => { openModalFaireDemandeTelecons(); }}
            >
              Faire une demande de téléconsultation
            </Button>

            <InfoCard
              title="alertInfo"
              iconShow="alert-circle-outline"
                    // iconCloseShow="close-outline"
              description={`La date et l'heure du rendez-vous seront fixées lors de la confirmation de votre vétérinaire. Le montant maximal pouvant vous être prélevé par votre vétérinaire pour une téléconsultation est de ${consultationData.breeding.vetOffice.maxPriceTeleConsultation} €.`}
              alertInfo="alertInfo"
            />
          </>
        ) : null)}

      <CustomModal
        title={`Vous êtes sur le point de ${openDelete?.title} à votre vétérinaire.`
            + '\n'}
        description={openDelete?.description ? openDelete?.description : ''}
        subtitle="Souhaitez-vous continuer ?"
        before={<></>}
        noSafeArea
        visible={openDelete !== undefined}
        scrollable={false}
        onClose={() => setOpenDelete(undefined)}
        insideModalStyle={{ textAlign: 'center', padding: 20 }}
        buttonText="Continuer"
        secondButtonText="Annuler"
        heightPercentage={0.46}
        buttonPress={() => { openDelete?.title === 'partager cet auto-examen' ? shareConsultation() : faireDemandeTelecons(); }}
      />

    </>
  );
};

export default PartageDemandeConsultCards;
