import { useEffect, useRef } from 'react';
import {
  Alert, AppState, AppStateStatus, Platform,
} from 'react-native';
import * as Updates from 'expo-updates';

const useUpdatesChecker = () => {
  const appState = useRef(AppState.currentState);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      const listener = AppState.addEventListener('change', handleAppStateChange);

      return () => {
        listener.remove();
      };
    }
    return () => {};
  }, []);

  const handleAppStateChange = (nextAppState: AppStateStatus) => {
    if (
      appState.current.match(/inactive|background/)
            && nextAppState === 'active'
    ) {
      Updates.checkForUpdateAsync().then(
        (res) => {
          if (res.isAvailable) {
            Updates.fetchUpdateAsync().then(
              (resFetch) => {
                if (resFetch.isNew) {
                  Alert.alert(
                    'Mise à jour',
                    'Une mise à jour est disponible. L\'application va être relancée.\n'
                                        + 'Si vous annulez cette action, pensez à relancer l\'application vous même.',
                    [
                      { text: 'OK', onPress: () => Updates.reloadAsync() },
                      { text: 'Annuler', style: 'cancel', onPress: () => {} },
                    ],
                    { cancelable: true },
                  );
                }
              },
            );
          }
        },
      );
      Updates.fetchUpdateAsync();
    }

    appState.current = nextAppState;
  };
};

export default useUpdatesChecker;
