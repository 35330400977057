import { LinkProps } from '../../../types';

const allerModifierMonCompteScreen = ({ state, linkTo }: LinkProps) => {
  switch (state) {
    case 'modifier-mon-compte':
      linkTo('/veto-mon-cabinet/veto-mon-compte/veto-modifier-mon-compte');
      break;
    default:
      break;
  }
};

export default {
  allerModifierMonCompteScreen,
};
