import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { VetoTabTeleconsultationParamList } from '../../types';

import VetoTeleconsultationScreen from '../../screens/VetScreens/TeleconsultationScreen/VetoTeleconsultationScreen';

const Stack = createStackNavigator<VetoTabTeleconsultationParamList>();

export default () => (
  <Stack.Navigator
    initialRouteName="veto-teleconsultation"
    screenOptions={{
      headerShown: false,
    }}
  >
    <Stack.Screen
      name="veto-teleconsultation"
      component={VetoTeleconsultationScreen}
    />

  </Stack.Navigator>
);
