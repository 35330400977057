/**
 *
 * @author:
 */

import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import Auth from '@aws-amplify/auth';
import moment from 'moment';
import { AppState, AppStateStatus } from 'react-native';
import { useApolloClient } from 'react-apollo';
import * as Notifications from 'expo-notifications';
import { RootStackParamList } from '../types';
import MainDrawerNavigator from './MainDrawerNavigator';
import NotFoundScreen from '../screens/NotFoundScreen';
import VetMainDrawerNavigator from './VetMainDrawerNavigator';
import { useUser } from '../src/API/UserContext';
import { useNotificationHandler } from '../components/CustomCards/NotificationCard';
import { getNotificationByIdQuery, useNotificationsList } from '../src/API/Notification';
import { ModelSortDirection } from '../src/API';
import AdminMainDrawerNavigator from './AdminMainDrawerNavigator';

const Stack = createStackNavigator<RootStackParamList>();

function InitialNavigator() {
  /** NOTIFICATIONS */
  const notificationHandler = useNotificationHandler();
  const { user, isVeto, isAdmin } = useUser();
  const { refetch } = useNotificationsList({
    userId: user?.id,
    sortDirection: ModelSortDirection.DESC,
    createdAt: {
      ge: moment().add(-30, 'days').format('YYYY-MM-DDT00:00:00'),
    },
  }, 'cache-and-network');

  const appState = useRef(AppState.currentState);

  useEffect(() => {
    const listener = AppState.addEventListener('change', handleAppStateChange);

    return () => {
      listener.remove();
    };
  }, []);

  const handleAppStateChange = (nextAppState: AppStateStatus) => {
    if (
      appState.current.match(/inactive|background/)
            && nextAppState === 'active'
    ) {
      refetch({
        userId: user?.id,
        sortDirection: ModelSortDirection.DESC,
        createdAt: {
          ge: moment().add(-30, 'days').format('YYYY-MM-DDT00:00:00'),
        },
      });
      /**
            refetchLastBillingHistory({
                userId: user?.id,
                sortDirection: ModelSortDirection.DESC,
                limit: 1,
            });
             */
    }

    appState.current = nextAppState;
  };

  const apolloClient = useApolloClient();
  useEffect(() => {
    const subscription = Notifications.addNotificationResponseReceivedListener(
      async (notification) => {
        console.log(notification);
        if (notification.actionIdentifier === 'expo.modules.notifications.actions.DEFAULT') {
          if (notification.notification.request.content.data.notificationId) {
            const notificationResult = await getNotificationByIdQuery(apolloClient,
              { id: notification.notification.request.content.data.notificationId as string });
            console.log(notificationResult);
            if (notificationResult.data.getNotificationById?.items
                            && notificationResult.data.getNotificationById?.items?.length > 0
                            && notificationResult.data.getNotificationById?.items[0]) {
              await notificationHandler(notificationResult.data.getNotificationById?.items[0]);
              refetch({
                userId: user?.id,
                sortDirection: ModelSortDirection.DESC,
                createdAt: {
                  ge: moment().add(-30, 'days').format('YYYY-MM-DDT00:00:00'),
                },
              });
            }
          }
        }
      },
    );
    return () => subscription.remove();
  }, []);

  // console.log('route InitialNavigator:', authUser);

  let component = MainDrawerNavigator;
  if (isVeto) {
    component = VetMainDrawerNavigator;
  } else if (isAdmin) {
    component = AdminMainDrawerNavigator;
  }

  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name="Root"
        component={
            component
        }
      />
      <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Oops!' }} />
    </Stack.Navigator>
  );
}

export default InitialNavigator;
