import * as Linking from 'expo-linking';

export default {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      Root: {
        screens: {
          'bottom-tab-nav': {
            path: '',
            screens: {
              'tableau-de-bord-nav': {
                initialRouteName: 'tableau-de-bord',
                path: 'tableau-de-bord',
                screens: {
                  'tableau-de-bord': '/',
                  'faire-demande-teleconsultation': '/faire-demande-teleconsultation/:id',
                  factures: '/factures/:id',
                },
              },
              'elevage-nav': {
                path: 'elevage',
                initialRouteName: 'elevage',
                screens: {
                  elevage: '/',
                  'ajout-animal': '/ajout-animal',
                  'ajout-lot': '/ajout-lot',
                  'ajout-batiment': '/ajout-batiment',
                  'detail-animal': '/detail-animal/:id',
                  'detail-lot': '/detail-lot/:id',
                  'detail-batiment': '/detail-batiment/:id',

                  'voir-plus-animal': '/voir-plus-animal',
                  'voir-plus-lot': '/voir-plus-lot',
                  'voir-plus-batiment': '/voir-plus-batiment',

                  'modify-lot': '/detail-lot/:id/modify-lot',
                  'modify-animal': '/detail-animal/:id/modify-animal',
                  'modify-batiment': '/detail-batiment/:id/modify-batiment',

                  'manage-affected-animals': '/manage-affected-animals/:whichPage',

                  'modify-elevage': '/modify-elevage/:id',
                },
              },
              'consultation-nav': {
                path: 'consultation',
                initialRouteName: 'consultation',
                screens: {
                  consultation: '/',
                  'ajout-animal': '/ajout-animal',
                  'ajout-lot': '/ajout-lot',
                  'realiser-auto-exam': '/realiser-auto-exam/:id',
                  document: '/detail-consultation/:id/document',
                  'detail-consultation': '/detail-consultation/:id',
                  questionnaire: '/questionnaire/:id',
                  'list-disease': '/questionnaire/list-disease/:id',
                  'search-consultation': '/search-consultation',
                  'saisir-disease-result': '/questionnaire/list-disease/saisir-disease-result/:id/:codeIdentification',
                  messagerie: '/detail-consultation/:id/messagerie',
                },
              },
              photo: {
                path: 'photo',
                screens: {
                  photo: '/',
                },
              },
            },
          },
          'mon-compte-nav': {
            initialRouteName: 'mon-compte',
            path: 'mon-compte',
            screens: {
              'mon-compte': '/',
              'modifier-compte-info': '/modifier-compte',

              'modify-info-perso': '/modify-info-perso',
              'modify-password': '/modify-password',
              'modify-email': '/modify-email',

              utilisateurs: '/utilisateurs',
              'ajout-utilisateur': 'utilisateurs/ajout-utilisateur',
              'modify-utilisateur': 'utilisateurs/modify-utilisateur/:id',
              'mode-paiement': '/mode-paiement',
              factures: 'factures',
            },
          },
          'bilan-sanitaire-nav': {
            initialRouteName: 'bilan-sanitaire',
            path: 'bilan-sanitaire',
            screens: {
              'bilan-sanitaire': '/',
              prebilan: '/prebilan',
              'prebilan-questionnaire': '/prebilan-questionnaire/:id',
              'demande-bse': '/demande-bse/:id',
              'bse-historique': '/bse-historique',
              'prebilan-historique': '/prebilan-historique',
              photo: 'photo',
            },
          },
          'notifications-nav': {
            initialRouteName: 'notifications',
            path: 'notifications',
            screens: {
              notifications: '/',
              'notifications-params': '/notifications-params',
            },
          },

          faq: 'faq',
        },
      },
      NotFound: '*',
    },
  },
};
