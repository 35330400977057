import { useLinkTo, useRoute } from '@react-navigation/native';
import React, { useState } from 'react';
import { Platform, View } from 'react-native';
import { Text } from '@ui-kitten/components';
import * as DocumentPicker from 'expo-document-picker';
import { Upload } from '../../utils/S3FileStorage';
import MaxWidthContainer from '../../components/MaxWidthContainer';
import Button from '../../components/Button';
import DocumentComponent from '../../components/Document/DocumentComponent';
import { useCreateDocumentMutation, useGetDocumentByConsultationId } from '../../src/API/Document';
import CustomModal from '../../components/Modals/CustomModal';

const DocumentsConsultationsScreen = () => {
  const linkTo = useLinkTo();
  const route = useRoute();
  const { documents, refetch } = useGetDocumentByConsultationId(route.params.id);
  const { createDocument, loadingDocument } = useCreateDocumentMutation();
  const [uploading, setUploading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [doc, setDoc] = useState<DocumentPicker.DocumentResult | undefined>();
  return (
    <MaxWidthContainer
      withScrollView="keyboardAware"
      outerViewProps={{
        style: {
          padding: 24,
        },
      }}
    >
      <View>
        <Button
          onPress={async () => {
            const document = await DocumentPicker.getDocumentAsync({
              copyToCacheDirectory: Platform.OS !== 'android',
            });
            setDoc(document);
            setOpenConfirm(true);
          }}
          size="large"
          style={{ width: '100%', marginBottom: 25, height: '10%' }}
          loading={uploading}
        >
          + Ajouter un Document
        </Button>

        <Text category="h1" appearance="hint"> Tous les documents </Text>
        {documents && documents.map((documentCons) => (
          <DocumentComponent
            key={documentCons.id}
            document={documentCons}
          />
        ))}
      </View>
      <CustomModal
        title={`Vous êtes sur le point d'envoyer le document ${doc !== undefined && doc.type === 'success' ? doc.name : ''} .`
            + '\n'
            + 'Souhaitez vous continuer ?'}
        before={<></>}
        noSafeArea
        visible={openConfirm && doc !== undefined && doc.type === 'success'}
        scrollable={false}
        onClose={() => { setOpenConfirm(true); setDoc(undefined); }}
        insideModalStyle={{ padding: 20 }}
        buttonText="Envoyer"
        secondButtonText="Annuler"
        heightPercentage={0.36}
        buttonPress={async () => {
          setOpenConfirm(false);
          //console.log('doc ajouter: ', doc);
          const keyName = route.params.id;
          if (doc) {
            const name = doc.type === 'success' ? doc.name : '';
            const key = `Document_consultation_${keyName}`;
            if (doc.type === 'success') {
              setUploading(true);
              // upload chosen document from gallery to S3
              const s3file = await Upload(doc, `documents/consultation/imagesDoc/${route.params.id}/`);
              if (s3file !== false && route.params.id) {
                //console.log('s3file ajouter:', s3file);
                await createDocument({
                  variables: {
                    input: {
                      s3file: s3file.key,
                      consultationId: route.params.id,
                      key,
                      name,
                    },
                  },
                });
              }
            }
          }
          setUploading(false);
          setDoc(undefined);
        }}
      />

    </MaxWidthContainer>
  );
};

export default DocumentsConsultationsScreen;
