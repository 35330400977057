import React, { useState } from 'react';
import { useLinkTo, useNavigation, useRoute } from '@react-navigation/native';
import { Text, useTheme } from '@ui-kitten/components';
import { RouteProp } from '@react-navigation/core/lib/typescript/src/types';
import { Platform } from 'react-native';
import MaxWidthContainer from '../../components/MaxWidthContainer';

import InfoCard from '../../screenComponents/InfoCard';
import CompButton from '../../components/Button';

import GoCustomCard from '../../components/GoCustomCard';
import DetailsScreensHeaderCard from '../../screenComponents/DetailsScreensHeaderCard';
import { TabElevageParamList } from '../../types';
import { useDeleteBuildingMutation, useGetBuilding } from '../../src/API/Buildings';
import AnimalCard from '../../screenComponents/AnimalCard';
import ElevageScreenBL from '../../screenBusinessLogic/ElevageScreen/ElevageScreenBL';
import CustomModal from '../../components/Modals/CustomModal';

import { useUpdateAnimalMutation } from '../../src/API/Animals';
import { useUpdateLotMutation } from '../../src/API/Lots';
import { useUpdateZoneMutation } from '../../src/API/Zone';
import { useBreedingList } from '../../src/API/Breeding';
import { useUser } from '../../src/API/UserContext';

const DetailBatimentScreen = () => {
  const linkTo = useLinkTo();
  const navigation = useNavigation();
  const theme = useTheme();

  const user = useUser();
  const { refetch } = useBreedingList(user?.userGroup);
  // for modal
  const [openDelete, setOpenDelete] = useState<{ title : string, id: string, data }>();

  const route = useRoute<
  RouteProp<TabElevageParamList, 'detail-batiment'>
  >();
  // console.log('DetailBatimentScreen route: ', route);
  const { building, refetch: refetchBuilding } = useGetBuilding(route?.params?.id);
  // console.log('DetailBatimentScreen data: ', building);

  /** DELETE BATIMENT */
  const onDeleteBatiment = () => {
    setOpenDelete('delete building');
    return () => {};
  };

  const { updateAnimal } = useUpdateAnimalMutation();
  const { updateLot } = useUpdateLotMutation();
  const { updateZone } = useUpdateZoneMutation();
  const deleteBuilding = useDeleteBuildingMutation();

  const deleteBatiment = async () => {
    if (building) {
      /** DELETE ANIMALS FROM BUILDING */
      if (building?.animals?.items && building?.animals?.items.length > 0) {
        building?.animals?.items?.reduce(async (promise, animal) => {
          await promise;
          if (animal) {
            await updateAnimal({
              variables: {
                input: {
                  id: animal.id,
                  // eslint-disable-next-line no-underscore-dangle
                  _version: animal._version,
                  buildingId: null,
                },
              },
            });
          }
        }, Promise.resolve());
      }
      /** DELETE LOTS FROM BUILDING */
      if (building?.lots?.items && building?.lots?.items.length > 0) {
        building?.lots?.items.reduce(async (promise, lot) => {
          await promise;
          if (lot) {
            await updateLot({
              variables: {
                input: {
                  id: lot.id,
                  // eslint-disable-next-line no-underscore-dangle
                  _version: lot._version,
                  buildingId: null,
                },
              },
            });
          }
        }, Promise.resolve());
      }
      /** DELETE ZONES FROM BUILDING */
      if (building.zone?.items && building.zone?.items.length > 0) {
        building.zone?.items.reduce(async (promise, zone) => {
          await promise;
          if (zone) {
            await updateZone({
              variables: {
                input: {
                  id: zone.id,
                  // eslint-disable-next-line no-underscore-dangle
                  _version: zone._version,
                  buildingId: null,
                },
              },
            });
          }
        }, Promise.resolve());
      }
      /** DELETE BUILDING after unaffecting from everything */
      await deleteBuilding({
        variables: {
          input: {
            id: route.params.id,
            // eslint-disable-next-line no-underscore-dangle
            _version: building._version,
          },
        },
      });
    }
    if (refetchBuilding) {
      await refetchBuilding;
    }
    if (refetch) {
      await refetch();
    }
    linkTo('/elevage');
    setOpenDelete(undefined);
  };

  return (
    <>
      <MaxWidthContainer
        outerViewProps={{
          style: {
            padding: 24,
          },
        }}
      >
        <DetailsScreensHeaderCard
          title1="Nom du bâtiment"
          titleInfo1={building?.nameBuilding}
          title2="Adresse"
          titleInfo2={building?.address?.address}
          title3="Complément d'adresse"
          titleInfo3={building?.address?.additionalAddress}
          title4="Code Postal"
          titleInfo4={building?.address?.postalCode}
          title5="Ville"
          titleInfo5={building?.address?.city}
        />
        <CompButton
          leftIcon="edit-outline"
          leftIconFill={theme['color-primary-500']}
          appearance="outline"
          onPress={() => { linkTo(`/elevage/detail-batiment/${building?.id}/modify-batiment`); }}
        >
          Modifier les informations de ce bâtiment
        </CompButton>

        <Text category="p1" appearance="hint" style={{ marginTop: 20, marginBottom: 10 }}>
          Animaux affectés à ce bâtiment
        </Text>
        <InfoCard
          title="alertInfo"
          iconShow="alert-circle-outline"
        // iconCloseShow="close-outline"
          description="Appuyez sur le bouton ci-dessous pour accéder à votre espace de gestion des animaux affectés à ce bâtiment. Vous pouvez ajouter ou supprimer les animaux de votre choix."
          alertInfo="alertInfo"
          infoStyle={{ backgroundColor: theme['color-primary-100'] }}
        />

        <CompButton
          leftIcon="edit-outline"
          leftIconFill={theme['color-primary-500']}
          appearance="outline"
          style={{ marginBottom: 10 }}
        // onPress={() => { linkTo('/elevage/manage-affected-animals'); }}
          onPress={() => {
            navigation.navigate('manage-affected-animals', {
              listTitle: 'Animaux', manage: 'les animaux', whichPage: 'batiment', id: route.params.id,
            });
          }}
        >
          Gérer les animaux affectés à ce bâtiment
        </CompButton>

        {building && building.animals && building.animals.items?.map(
          (animalInThisBuilding) => (
            <AnimalCard
              key={animalInThisBuilding?.id}
              name={animalInThisBuilding?.name}
              numberId={animalInThisBuilding?.numberID}
              numberLot={animalInThisBuilding?.lots?.numberLot}
              onPress={() => {
                ElevageScreenBL.allerDetailScreen(
                  { state: 'animaux', linkTo, id: animalInThisBuilding?.id },
                );
              }}
            />
          ),
        )}

        <Text category="p1" appearance="hint" style={{ marginTop: 40, marginBottom: 10 }}>
          Lots affectés à ce bâtiment
        </Text>
        <InfoCard
          title="alertInfo"
          iconShow="alert-circle-outline"
        // iconCloseShow="close-outline"
          description="Appuyez sur le bouton ci-dessous pour accéder à votre espace de gestion des lots affectés à ce bâtiment. Vous pouvez ajouter ou supprimer les lots de votre choix."
          alertInfo="alertInfo"
          infoStyle={{ backgroundColor: theme['color-primary-100'] }}
        />

        <CompButton
          leftIcon="edit-outline"
          leftIconFill={theme['color-primary-500']}
          appearance="outline"
          style={{ marginBottom: 10 }}
          onPress={() => {
            navigation.navigate('manage-affected-animals', {
              listTitle: 'Lots', manage: 'les lots', whichPage: 'batiment', id: route.params.id,
            });
          }}
        >
          Gérer les lots affectés à ce bâtiment
        </CompButton>

        {building && building.lots && building.lots.items?.map(
          (lotInBuilding) => (
            <GoCustomCard
              key={lotInBuilding?.id}
              title1={lotInBuilding?.name}
              title2={`Numéro de lot: ${lotInBuilding?.numberLot}`}
              linkToCustom={() => {
                ElevageScreenBL.allerDetailScreen(
                  { state: 'lots', linkTo, id: lotInBuilding?.id },
                );
              }}
              minusSquareOutline="arrow-ios-forward"
            />
          ),
        )}

        <CompButton onPress={() => { onDeleteBatiment(); }} style={{ marginTop: 40, backgroundColor: '#fff' }} status="danger" appearance="outline">Supprimer le bâtiment</CompButton>

      </MaxWidthContainer>
      <CustomModal
        title={'Vous êtes sur le point de supprimer le bâtiment.'
      + '\n'
      + 'Êtes-vous sûr de vouloir continuer ?'}
        before={<></>}
        noSafeArea
        visible={openDelete !== undefined}
        scrollable={false}
        onClose={() => setOpenDelete(undefined)}
        insideModalStyle={{ textAlign: 'center', padding: 20 }}
        buttonText="Confirmer"
        secondButtonText="Annuler"
        heightPercentage={Platform.OS === 'web' ? 0.36 : 0.6}
        buttonPress={() => { deleteBatiment(); }}
      />
    </>
  );
};

export default DetailBatimentScreen;
