import React, {
  useEffect,
  useReducer, useState,
} from 'react';

import API from '@aws-amplify/api';
import Constants from 'expo-constants';
import { ClientConfig, createClient, createMicrophoneAndCameraTracks } from 'agora-rtc-react';
import {
  RtcProvider,
  UidInterface,
  UidStateInterface,
  DispatchType,
  ActionInterface,
  ActionType, CallbacksInterface, RtcPropsInterface,
} from './RtcContext';
import { useUser } from '../../src/API/UserContext';
import { UidProvider } from './UidContext';

const config: ClientConfig = { mode: 'rtc', codec: 'vp8' };

const useClient = createClient(config);
const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();

/**
 * The RtcConfigre component handles the logic for the video experience.
 * It's a collection of providers to wrap your components that need
 * access to user data or engine dispatch
 */
const RtcConfigure: React.FC<RtcPropsInterface> = (props) => {
  const {
    enableAudio, enableVideo, channel,
    appId, mode: modeProps, children,
  } = props;
  const { user: currentUser } = useUser();

  const [joined, setJoined] = useState(false);

  const client = useClient();
  let { ready, tracks } = { ready: false, tracks: null };
  try {
    const result = useMicrophoneAndCameraTracks();
    ready = result.ready;
    tracks = result.tracks;
  } catch (e) {
    console.log(e);
  }

  const initialState: UidStateInterface = {
    uids: [
      {
        uid: 'local',
        hasAudio: enableAudio !== false && tracks && tracks[0],
        hasVideo: enableVideo !== false && tracks && tracks[1],
      },
    ],
  };

  const leave = async () => {
    if (tracks) {
      tracks[0].close();
      tracks[1].close();
    }
    await client.leave();
  };

  const reducer = (
    state: UidStateInterface,
    action: ActionInterface<keyof CallbacksInterface, CallbacksInterface>,
  ) => {
    let stateUpdate = {};
    const uids = state.uids.map((u: UidInterface) => u.uid);

    console.log(action);

    switch (action.type) {
      case 'UserJoined':
        const index = uids.indexOf((action as ActionType<'UserJoined'>).value[0].uid);
        if (index === -1) {
          // If new user has joined
          const uidsUpdate = [
            ...state.uids,
            (action as ActionType<'UserJoined'>).value[0],
          ];

          stateUpdate = {
            uids: uidsUpdate,
          };
          console.log('new user joined!\n', action.value[0].uid);
        } else {
          const UserJoined = (user: UidInterface) => {
            if (user.uid === (action as ActionType<'UserJoined'>).value[0].uid) {
              user = (action as ActionType<'UserJoined'>).value[0];
            }
            return user;
          };
          stateUpdate = {
            uids: state.uids.map(UserJoined),
          };
          console.log('old changed joined!\n', action.value[0].uid);
        }
        break;
      case 'UserOffline':
        stateUpdate = {
          uids: state.uids.filter(
            (user) => user.uid !== (action as ActionType<'UserOffline'>).value[0].uid,
          ),
        };
        break;
      case 'TokenPrivilegeWillExpire':

        break;
      case 'LocalMuteAudio':
        if (!(action as ActionType<'LocalMuteAudio'>).value[0]) {
          client.publish([tracks[0]]);
        } else {
          client.unpublish([tracks[0]]);
        }
        const LocalAudioMute = (user: UidInterface) => {
          if (user.uid === 'local') {
            user.hasAudio = !(action as ActionType<'LocalMuteAudio'>).value[0];
            user.audioTrack = user.hasAudio ? tracks[0] : null;
          }
          return user;
        };
        stateUpdate = {
          uids: state.uids.map(LocalAudioMute),
        };
        break;
      case 'LocalMuteVideo':
        if (!(action as ActionType<'LocalMuteVideo'>).value[0]) {
          client.publish([tracks[1]]);
        } else {
          client.unpublish([tracks[1]]);
        }
        const LocalVideoMute = (user: UidInterface) => {
          if (user.uid === 'local') {
            user.hasVideo = !(action as ActionType<'LocalMuteVideo'>).value[0];
            user.videoTrack = user.hasVideo ? tracks[1] : null;
          }
          return user;
        };
        stateUpdate = {
          uids: state.uids.map(LocalVideoMute),
        };
        break;
      case 'UserMuteAudio':
        const UserMuteAudio = (user: UidInterface) => {
          if (user.uid === (action as ActionType<'UserMuteAudio'>).value[0].uid) {
            user = (action as ActionType<'UserMuteAudio'>).value[0];
          }
          return user;
        };
        stateUpdate = {
          uids: state.uids.map(UserMuteAudio),
        };
        break;
      case 'UserMuteVideo':
        const UserMuteVideo = (user: UidInterface) => {
          if (user.uid === (action as ActionType<'UserMuteVideo'>).value[0].uid) {
            user = (action as ActionType<'UserMuteAudio'>).value[0];
          }
          return user;
        };
        stateUpdate = {
          uids: state.uids.map(UserMuteVideo),
        };
        break;
      case 'LeaveChannel':
        stateUpdate = {
          uids: [
            {
              uid: 'local',
              hasAudio: enableAudio !== false,
              hasVideo: enableVideo !== false,
            },
          ],
        };
    }

    return {
      ...state,
      ...stateUpdate,
    };
  };

  const [uidState, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const init = async () => {
      client.on('user-joined', async (user) => {
        (dispatch as DispatchType<'UserJoined'>)({
          type: 'UserJoined',
          value: [user],
        });
      });

      client.on('user-published', async (user, type) => {
        if (type === 'audio') {
          await client.subscribe(user, 'audio');
          await user.audioTrack?.play();
          (dispatch as DispatchType<'UserMuteAudio'>)({
            type: 'UserMuteAudio',
            value: [user, false],
          });
        }
        if (type === 'video') {
          await client.subscribe(user, 'video');
          (dispatch as DispatchType<'UserMuteVideo'>)({
            type: 'UserMuteVideo',
            value: [user, false],
          });
        }
      });

      client.on('user-unpublished', async (user, type) => {
        console.log('unpublished', user, type);
        if (type === 'audio') {
          await user.audioTrack?.stop();
          await client.unsubscribe(user, 'audio');
          (dispatch as DispatchType<'UserMuteAudio'>)({
            type: 'UserMuteAudio',
            value: [user, true],
          });
        }
        if (type === 'video') {
          await client.unsubscribe(user, 'video');
          (dispatch as DispatchType<'UserMuteVideo'>)({
            type: 'UserMuteVideo',
            value: [user, true],
          });
        }
      });

      client.on('user-left', (...args) => {
        (dispatch as DispatchType<'UserOffline'>)({
          type: 'UserOffline',
          value: args,
        });
      });

      client.on('token-privilege-will-expire', async () => {
        const response = await API.get('scanflockrest', `/agora/generate-token?channel=${channel}&sessionId=${Constants.sessionId}`, {});

        if (response.success) {
          await client.renewToken(response.token);
        }
      });

      client.on('exception', (args: any) => {
        console.log('error', args);
      });
    };

    init();
    return () => {};
  }, [client]);

  // Dynamically switches channel when channel prop changes
  useEffect(() => {
    async function join() {
      if (client && currentUser && !joined) {
        /* Token URL */
        const response = await API.get('scanflockrest', `/agora/generate-token?channel=${channel}&sessionId=${Constants.sessionId}`, {});

        const UID = currentUser.id + Constants.sessionId;
        console.log('UID', UID, Constants.sessionId, response);
        if (response.success) {
          await client.join(appId, channel, response.token, UID);
          setJoined(true);
        }
      } else {
        console.error('trying to join before RTC Engine was initialized');
      }
    }
    if (currentUser) {
      join();
      console.log('Attempted join: ', channel);
    } else {
      console.log('In precall - waiting to join');
    }
    return () => {
    };
  }, [
    appId,
    channel,
    modeProps,
    client,
    currentUser,
  ]);

  useEffect(() => {
    if (joined && tracks && ready) {
      /* enableVideo */
      if (enableVideo !== false) {
        (dispatch as DispatchType<'LocalMuteVideo'>)({
          type: 'LocalMuteVideo',
          value: [false],
        });
      }
      /* enableAudio */

      if (enableAudio !== false) {
        (dispatch as DispatchType<'LocalMuteAudio'>)({
          type: 'LocalMuteAudio',
          value: [false],
        });
      }
    }
  }, [joined, tracks, ready, enableAudio, enableVideo]);

  console.log(uidState.uids);

  return (
    <RtcProvider value={{ dispatch, leave, isTorchAvailable: false }}>
      <UidProvider value={uidState.uids}>
        {
            // Render children once RTCEngine has been initialized
            ready ? children : <></>
          }
      </UidProvider>
    </RtcProvider>
  );
};

export default RtcConfigure;
