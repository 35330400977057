import { View } from 'react-native';
import { Icon as IconUIKitten, Text, useTheme } from '@ui-kitten/components';
import React from 'react';
import moment from 'moment';
import { useLinkTo } from '@react-navigation/native';
// import MaisonVert from '../assets/Omedom_Icons_svg/Logement/maison_verte.svg';
import Card from '../Card';
import { Notification } from '../../src/API';
import { useUpdateNotification } from '../../src/API/Notification';

type NotificationCardProps = {
  notification: Notification;
};

export const useNotificationHandler = () => {
  const linkTo = useLinkTo();
  const { updateNotification } = useUpdateNotification();

  return {
    notificationHandler: async (notification: Notification) => {
      await updateNotification({
        variables: {
          input: {
            id: notification.id,
            clicked: true,
            createdAt: notification.createdAt,
            // eslint-disable-next-line no-underscore-dangle
            _version: notification._version,
          },
        },
      });
      if (notification.data) {
        const data = JSON.parse(notification.data);
        switch (data.event) {
          case 'startBse':
            linkTo(`/bilan-sanitaire/demande-bse/${data.bseId}`);
            break;
          default:
            break;
        }
      }
    },
    hasNotificationHandler: (notification: Notification) => {
      if (notification.data) {
        const data = JSON.parse(notification.data);
        switch (data.event) {
          case 'startBse':
            return true;
          default:
            break;
        }
      }
      return false;
    },
  };
};

const NotificationCard = (props: NotificationCardProps) => {
  const { notification } = props;
  const theme = useTheme();
  const { notificationHandler, hasNotificationHandler } = useNotificationHandler();
  const onPress = async () => {
    await notificationHandler(notification);
  };

  const hasHandler = hasNotificationHandler(notification);

  return (
    <Card
      onPress={hasHandler ? onPress : undefined}
      style={{
        marginTop: 27,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: notification.clicked || !hasHandler ? '#FFFFFF' : theme['color-primary-400'],
      }}
    >
      <View style={{ flex: 1, flexDirection: 'column', marginRight: 10 }}>
        <Text
          category="h5"
          status={notification.clicked ? 'basic' : 'control'}
          style={{ flex: 1, flexWrap: 'wrap' }}
        >
          {notification.title}
        </Text>
        <Text
          category="p2"
          status={notification.clicked ? 'basic' : 'control'}
          style={{ flex: 1, flexWrap: 'wrap' }}
        >
          {notification.body}
        </Text>
        <Text
          category="c1"
          appearance={notification.clicked ? 'hint' : 'default'}
          style={{ flex: 1, flexWrap: 'wrap' }}
        >
          {`${moment(notification.createdAt).format('DD/MM/YYYY à HH:mm')}`}
        </Text>
      </View>
      {hasHandler ? (
        <IconUIKitten
          name="arrow-ios-forward"
          fill={notification.clicked ? 'black' : 'white'}
          style={{
            height: 20, width: 20, marginRight: 5, alignItems: 'center',
          }}
        />
      ) : null}
    </Card>
  );
};

export default NotificationCard;
