import { LinkProps } from '../../../types';

const linkToSwitch = ({
  state, linkTo, id, back,
}: LinkProps) => {
  switch (state) {
    case 'acceder-a-elevage':
      linkTo(`/veto-elevage/veto-details-elevage/${id}${back ? `?back=${back}` : ''}`);
      break;
    case 'consultation-document':
      linkTo(`/veto-examen/veto-details-consultation/${id}/document${back ? `?back=${back}` : ''}`);
      break;
    case 'consultation-messagerie':
      linkTo(`/veto-examen/veto-details-consultation/${id}/messagerie${back ? `?back=${back}` : ''}`);
      break;
    case 'consultation-questionnaire':
      linkTo(`/veto-examen/veto-details-consultation/${id}/questionnaire?update=false${back ? `&back=${back}` : ''}`);
      break;
    case 'details-teleconsultation':
      linkTo(`/veto-examen/veto-details-consultation/veto-details-teleconsultation/${id}${back ? `?back=${back}` : ''}`);
      break;
    case 'details-consultation':
      linkTo(`/veto-examen/veto-details-consultation/${id}${back ? `?back=${back}` : ''}`);
      break;
    case 'facture-consultation':
      linkTo(`/veto-facture/${id}${back ? `?back=${back}` : ''}`);
      break;
    default:
      break;
  }
};

export default {
  linkToSwitch,
};
