import React from 'react';

import { useLinkTo } from '@react-navigation/native';
import MaxWidthContainer from '../../../components/MaxWidthContainer';
import InfoCard from '../../../screenComponents/InfoCard';
import GoCustomCard from '../../../components/GoCustomCard';
import Button from '../../../components/Button';
import VetoUtilisateursScreenBL
  from '../../../screenBusinessLogic/vetoScreensBL/MonCabinetScreenBL/VetoUtilisateursScreenBL';
import { useUser } from '../../../src/API/UserContext';
import { useGetVetOffice } from '../../../src/API/VetOffice';

const VetoMonCabinetScreen = () => {
  const linkTo = useLinkTo();
  const { user } = useUser();

  const { vetOfficeData } = useGetVetOffice();

  let vetoList;
  let personnelList;
  if (user && vetOfficeData && vetOfficeData.users && vetOfficeData.users.items) {
    const vetoListId = vetOfficeData.admins.filter((admin) => admin !== user.id);
    if (vetOfficeData.personnel) {
      const personnelListId = vetOfficeData.personnel.filter((admin) => admin !== user.id);
      personnelList = vetOfficeData.users.items
        .filter((thisUser) => personnelListId.includes(thisUser.id));
    }

    vetoList = vetOfficeData.users.items.filter((thisUser) => vetoListId.includes(thisUser.id));
  }

  // console.log('personnelList :', personnelList);
  // console.log('vetoList :', vetoList);

  return (
    <MaxWidthContainer
      withScrollView="keyboardAware"
      outerViewProps={{
        style: {
          padding: 20,
          marginBottom: 20,
        },
      }}
    >
      <InfoCard
        title="alertInfo"
        iconShow="alert-circle-outline"
        // iconCloseShow="close-outline"
        description="Gérez depuis cet espace les utilisateurs de votre cabinet. Les utilisateurs de type personnel ne peuvent pas réaliser le Bilan Sanitaire d'Élevage, seuls les utilisateurs de type vétérinaire en sont habilités."
        alertInfo="alertInfo"
      />
      {vetoList && vetoList.map((veto) => (
        <GoCustomCard
          key={veto?.id}
          userCard="userCard"
          userAvatar={veto?.avatarUri}
          title1="Nom"
          title2={`${veto?.firstname} ${veto?.lastname}`}
          titleEmail={veto?.email}
          titleFonction="Vétérinaire"
          minusSquareOutline="arrow-ios-forward"
          linkToCustom={() => { VetoUtilisateursScreenBL.allerUtilisateursScreen({ state: 'modify-utilisateurs', linkTo, id: veto?.id }); }}
        />
      ))}
      {personnelList && personnelList.map((veto) => (
        <GoCustomCard
          key={veto?.id}
          userCard="userCard"
          userAvatar={veto?.avatarUri}
          title1="Nom"
          title2={`${veto?.firstname} ${veto?.lastname}`}
          titleEmail={veto?.email}
          titleFonction="Personnel"
          minusSquareOutline="arrow-ios-forward"
          linkToCustom={() => { VetoUtilisateursScreenBL.allerUtilisateursScreen({ state: 'modify-utilisateurs', linkTo, id: veto?.id }); }}
        />
      ))}
      <Button
        leftIcon="plus-outline"
        leftIconFill="#fff"
        style={{ marginTop: 40 }}
        onPress={() => { VetoUtilisateursScreenBL.allerUtilisateursScreen({ state: 'ajout-utilisateurs', linkTo }); }}
      >
        Ajouter un nouvel utilisateur
      </Button>

    </MaxWidthContainer>
  );
};

export default VetoMonCabinetScreen;
