import React from 'react';
import { FlatList } from 'react-native';
import MaxWidthContainer from '../../components/MaxWidthContainer';

import FactureComponent from '../../components/FactureComponent';
import { useListInvoices } from '../../src/API/Invoices';
import Button from '../../components/Button';

const AdminFacturesScreen = () => {
  const {
    fetchMore, invoices, loading, nextToken,
  } = useListInvoices();

  return (
    <MaxWidthContainer
      outerViewProps={{
        style: {
          padding: 20,
          marginBottom: 20,
        },
      }}
    >

      <FlatList
        data={invoices}
        keyExtractor={(item) => item?.id || 'null'}
        renderItem={({ item }) => (item ? (
          <FactureComponent
            billingHistory={item}
          />
        ) : null
        )}
        ListFooterComponent={nextToken ? (
          <Button
            disabled={loading}
            loading={loading}
            onPress={() => {
              fetchMore({
                variables: { nextToken },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev;
                  return {
                    ...prev,
                    listBillingHistoriesByType: {
                      ...prev.listBillingHistoriesByType,
                      ...fetchMoreResult.listBillingHistoriesByType,
                      items: [
                        ...prev.listBillingHistoriesByType?.items,
                        ...fetchMoreResult.listBillingHistoriesByType?.items,
                      ],
                    },
                  };
                },
              });
            }}
          >
            Charger plus de factures
          </Button>
        ) : null}
      />

    </MaxWidthContainer>
  );
};

export default AdminFacturesScreen;
