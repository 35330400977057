import { ButtonGroupProps, useTheme } from '@ui-kitten/components';
import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import Button from './Button';

type ButtonGroupItem<K extends string> = { key: K, name: string };

type ButtonGroupPropsCustom<K extends string> = Partial<ButtonGroupProps> & {
  onChange?: (selectedItem: ButtonGroupItem<K>) => void;
  items: ButtonGroupItem<K>[];
  initialSelectedItem: K;
};

const ButtonGroup = <K extends string>(props: ButtonGroupPropsCustom<K>): JSX.Element => {
  const {
    onChange,
    // filter,
    items,
    initialSelectedItem,
    style,
    ...property
  } = props;
  const theme = useTheme();

  const [selectedItem, setSelectedItem] = useState(initialSelectedItem);

  return (
    <>
      <View
        style={[styles.common, { borderColor: theme['color-primary-300'] }, style]}
        {...property}
      >
        {
          items.map((i) => (
            <Button
              status="basic"
              // appearance="default"
              key={i.key}
              style={[(selectedItem === i.key) ? [styles.buttonActive, { backgroundColor: theme['color-primary-300'] }] : styles.buttons]}
              onPress={() => {
                setSelectedItem(i.key);
                if (onChange) onChange(i);
              }}
            >
              {i.name}
            </Button>
          ))
        }
      </View>
    </>
  );
};

export default ButtonGroup;

const styles = StyleSheet.create({
  common: {
    marginTop: 20,
    borderRadius: 15,
    // borderColor: '#66CBBF',
    borderWidth: 3,
    backgroundColor: 'white',
    flexDirection: 'row',
    height: 56,
  },
  buttonActive: {
    flex: 1,
    paddingHorizontal: 0,
    borderRadius: 12,
    // alignItems: 'center',
    // justifyContent: 'center',
    borderWidth: 0,
  },
  buttons: {
    flex: 1,
    paddingHorizontal: 5,
    borderRadius: 15,
    borderWidth: 0,
    backgroundColor: 'transparent',
    color: 'black',
  },

});
