import { Text, useTheme } from '@ui-kitten/components';
import React, { useEffect, useState } from 'react';
import { useLinkTo, useRoute } from '@react-navigation/native';
import {
  FlatList, Platform,
  View,
} from 'react-native';
import { useForm } from 'react-hook-form';
import { useApolloClient } from 'react-apollo';
import MaxWidthContainer from '../../../components/MaxWidthContainer';
import Form from '../../../components/Form/Form';
import FTextInput from '../../../components/Form/TextInput';
import { AvailableValidationRules } from '../../../components/Form/validation';
import Button from '../../../components/Button';
import {
  getDiseaseAsync,
  useCreateDiseaseMutation,
  useDeleteMaladieMutation,
  useUpdateDiseaseMutation,
} from '../../../src/API/Disease';
import RichTextInput from '../../../components/Form/RichTextInput';
import questionnaireData from '../../../src/mockData/questionnaireData';
import Select from '../../../components/Form/Select';
import Checkbox from '../../../components/Form/Checkbox';
import CustomModal from '../../../components/Modals/CustomModal';

const listeFamille = [
  { label: 'Morsures, foudre et traumatismes du trayon', key: 'Morsures, foudre et traumatismes du trayon' },
  { label: 'Maladies de la peau ou des muqueuses', key: 'Maladies de la peau ou des muqueuses' },
  { label: 'Maladies des ganglions ou affections néoplasiques  (tumeurs)', key: 'Maladies des ganglions ou affections néoplasiques  (tumeurs)' },
  { label: 'Maladies des yeux', key: 'Maladies des yeux' },
  { label: 'Maladies de la bouche', key: 'Maladies de la bouche' },
  { label: "Maladies de l'appareil digestif, du foie et de la défécation", key: "Maladies de l'appareil digestif, du foie et de la défécation" },
  { label: 'Maladies métaboliques, nutritionnelles et intoxications', key: 'Maladies métaboliques, nutritionnelles et intoxications' },
  { label: "Maladies de l'appareil respiratoire, circulatoire et du cœur", key: "Maladies de l'appareil respiratoire, circulatoire et du cœur" },
  { label: "Maladies de l'appareil génital et troubles de la reproduction", key: "Maladies de l'appareil génital et troubles de la reproduction" },
  { label: 'Affections de la mamelle et troubles de la production lactée', key: 'Affections de la mamelle et troubles de la production lactée' },
  { label: "Maladies de l'appareil urinaire et de la miction", key: "Maladies de l'appareil urinaire et de la miction" },
  { label: 'Maladies du nombril', key: 'Maladies du nombril' },
  { label: "Maladies de l'appareil locomoteur", key: "Maladies de l'appareil locomoteur" },
  { label: 'Maladies du système nerveux et du comportement', key: 'Maladies du système nerveux et du comportement' },
  { label: 'Maladies parasitaires et exotiques', key: 'Maladies parasitaires et exotiques' },
  { label: 'Maladies virales et bactériennes générales', key: 'Maladies virales et bactériennes générales' },
];

type ChangeDisease = {
  name: string,
  codeIdentification: string,
  description: string,
  synonymes: string,
  enjeux: string,
  causes: string,
  circonstancesApparition: string,
  diagnosticDiff: string,
  diagnosticLab: string,
  traitementCuratif: string,
  pronostic: string,
  prevention: string,
  familleMaladie?: string | null,
  familleMaladieId?: number | null,
  MRLC?: boolean | null,
  maladieUrgente?: boolean | null,
};

const AdminDetailsDiseaseScreen = () => {
  const linkTo = useLinkTo();
  const route = useRoute();
  const theme = useTheme();
  const apolloClient = useApolloClient();

  const { id } = route.params;
  const isAddMode = (id === 'new');

  const { modifyDisease } = useUpdateDiseaseMutation();
  const { createDisease } = useCreateDiseaseMutation();
  const supprimerMaladie = useDeleteMaladieMutation();

  const [processing, setProcessing] = useState(false);
  const [ficheMaladie, setFicheMaladie] = useState({});
  const [disease, setDisease] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [fmFilter, setFMFilter] = useState(null);
  const changeDisease = useForm<ChangeDisease>();

  const getDisease = async () => {
    if (!isAddMode) {
      const data = await getDiseaseAsync(apolloClient, id);
      setDisease(data?.data?.getDisease);
    }
  };

  useEffect(() => {
    (async () => {
      await getDisease();
    })();
    return () => {};
  }, [id]);

  useEffect(() => {
    if (disease?.ponderationDisease) {
      setFicheMaladie(JSON.parse(disease?.ponderationDisease));
    } else {
      setFicheMaladie({});
    }
    return () => {
    };
  }, [disease]);

  let data = Object.values(questionnaireData).filter((v) => (v && v.id));
  if (fmFilter && fmFilter != '') {
    data = data.filter((v) => (v.id.indexOf(fmFilter) > -1
        || (v.name && v.name.indexOf(fmFilter) > -1)
        || (v.titre1 && v.titre1.indexOf(fmFilter) > -1)
        || (v.titre2 && v.titre2.indexOf(fmFilter) > -1)
        || (v.titre3 && v.titre3.indexOf(fmFilter) > -1)
        || (v.titre4 && v.titre4.indexOf(fmFilter) > -1)
        || (v.idt1 && v.idt1.indexOf(fmFilter) > -1)
        || (v.idt2 && v.idt2.indexOf(fmFilter) > -1)
        || (v.idt3 && v.idt3.indexOf(fmFilter) > -1)
        || (v.idt4 && v.idt4.indexOf(fmFilter) > -1)
    ));
  }

  const deleteMaladie = async () => {
    if (disease) {
      await supprimerMaladie({
        variables: {
          input: {
            id,
            // eslint-disable-next-line no-underscore-dangle
            _version: disease._version,
          },
        },
      });
      return true;
    }
    return false;
  };

  return (
    <MaxWidthContainer
      withScrollView="keyboardAware"
      outerViewProps={{
        style: {
          padding: 20,
        },
      }}
    >
      {disease || isAddMode ? (
        <>
          <Form<ChangeDisease>
            {...changeDisease}
            defaultValues={disease}
          >
            <>
              <Text category="h4" style={{ marginTop: 40 }}>Monographie</Text>
              <View>
                <Text category="h6" appearance="hint">Nom *</Text>
                <FTextInput
                  name="name"
                  placeholder="Saisissez le nom"
                  validators={[
                    AvailableValidationRules.required,
                  ]}
                />
                <Text category="h6" appearance="hint">Code d'identification *</Text>
                <FTextInput
                  name="codeIdentification"
                  placeholder="Saisissez le code d'identification"
                  validators={[
                    AvailableValidationRules.required,
                  ]}
                />
                <Text category="h6" appearance="hint">Famille de maladie *</Text>
                <Select
                  name="familleMaladie"
                  placeholder="Choisissez la famille"
                  validators={[
                    AvailableValidationRules.required,
                  ]}
                  data={listeFamille}
                />
                <Text category="h6" appearance="hint">Protocole de soin</Text>
                <FTextInput
                  name="familleMaladieId"
                  placeholder="Saisissez le numéro du protocole de soin"
                />
                <Text category="h6" appearance="hint">MRLC</Text>
                <Checkbox name="MRLC" />
                <Text category="h6" appearance="hint">Maladie Urgente</Text>
                <Checkbox name="maladieUrgente" />
                <Text category="h6" appearance="hint">1.1. Définition</Text>
                <RichTextInput
                  name="description"
                />
                <Text category="h6" appearance="hint">1.2. Synonymes</Text>
                <RichTextInput
                  name="synonymes"
                />
                <Text category="h6" appearance="hint">1.3. Enjeux</Text>
                <RichTextInput
                  name="enjeux"
                />
                <Text category="h6" appearance="hint">1.4. Causes</Text>
                <RichTextInput
                  name="causes"
                />
                <Text category="h6" appearance="hint">2.1. Circonstances d’apparition et symptômes</Text>
                <RichTextInput
                  name="circonstancesApparition"
                />
                <Text category="h6" appearance="hint">2.2. Différentiel</Text>
                <RichTextInput
                  name="diagnosticDiff"
                />
                <Text category="h6" appearance="hint">2.3. Examens complémentaires</Text>
                <RichTextInput
                  name="diagnosticLab"
                />
                <Text category="h6" appearance="hint">3.1 Traitement curatif</Text>
                <RichTextInput
                  name="traitementCuratif"
                />
                <Text category="h6" appearance="hint">3.2 Pronostic</Text>
                <RichTextInput
                  name="pronostic"
                />
                <Text category="h6" appearance="hint">3.3 Prévention</Text>
                <RichTextInput
                  name="prevention"
                />
              </View>

              <Text category="h4" style={{ marginTop: 40 }}>Fiche maladie</Text>
              <FTextInput
                name="fmFilter"
                placeholder="Filtrer la liste"
                value={fmFilter}
                onChangeValue={(text) => {
                  setFMFilter(text);
                }}
                style={{ marginBottom: 15 }}
              />
              <FlatList
                data={data}
                style={{ height: 300 }}
                keyExtractor={(v) => v.id}
                renderItem={({ item: v }) => {
                  if (v && v.id) {
                    let answers = [];
                    let keys = [];
                    if (v.answers) {
                      answers = Object.values(v.answers);
                      keys = Object.keys(v.answers);
                    }
                    return (
                      <View key={v.id}>
                        {(v.titre1) ? (
                          <Text category="h1" appearance="hint">
                            {v.idt1}
                            {' '}
                            {v.titre1}
                          </Text>
                        ) : null}
                        {(v.titre2) ? (
                          <Text category="h2" appearance="hint">
                            {v.idt2}
                            {' '}
                            {v.titre2}
                          </Text>
                        ) : null}
                        {(v.titre3) ? (
                          <Text category="h3" appearance="hint">
                            {v.idt3}
                            {' '}
                            {v.titre3}
                          </Text>
                        ) : null}
                        {(v.titre4) ? (
                          <Text category="h4" appearance="hint">
                            {v.idt4}
                            {' '}
                            {v.titre4}
                          </Text>
                        ) : null}
                        {(v.name && v.answers) ? (
                          <>
                            <Text category="h4" appearance="hint">
                              {v.id}
                              {' '}
                              {v.name}
                            </Text>
                            {answers.map((va, i) => {
                              const currentID = keys[i];
                              if (va.name) {
                                return (
                                  <View
                                    key={currentID}
                                    style={{
                                      flexDirection: 'row', marginLeft: 20, justifyContent: 'space-between', alignItems: 'center', marginBottom: 15,
                                    }}
                                  >
                                    <Text category="h6" appearance="hint">
                                      {currentID}
                                      {' '}
                                      {va.name}
                                    </Text>
                                    <FTextInput
                                      name={currentID}
                                      placeholder="Poids"
                                      value={ficheMaladie && ficheMaladie[currentID]}
                                      onChangeValue={(text) => {
                                        if (text == '') {
                                          const fiche = { ...ficheMaladie };
                                          delete fiche[currentID];
                                          setFicheMaladie(fiche);
                                        } else {
                                          setFicheMaladie({ ...ficheMaladie, [currentID]: text });
                                        }
                                      }}
                                      style={{ width: 100, maxWidth: 100 }}
                                      containerStyle={{ marginBottom: 0, width: 100, maxWidth: 100 }}
                                    />
                                  </View>
                                );
                              }
                              return <></>;
                            })}
                          </>
                        ) : null}
                      </View>
                    );
                  }
                  return <></>;
                }}
              />
            </>
          </Form>

          <Button
            loading={processing}
            onPress={async () => {
              await changeDisease.handleSubmit(async (data) => {
                setProcessing(true);

                if (isAddMode) {
                  const res = await createDisease({
                    variables: {
                      input: {
                        name: data.name,
                        familleMaladieId: data.familleMaladieId,
                        codeIdentification: data.codeIdentification,
                        familleMaladie: data.familleMaladie,
                        MRLC: data.MRLC || false,
                        maladieUrgente: data.maladieUrgente || false,
                        description: data.description || '',
                        synonymes: data.synonymes,
                        enjeux: data.enjeux,
                        causes: data.causes,
                        circonstancesApparition: data.circonstancesApparition,
                        diagnosticDiff: data.diagnosticDiff,
                        diagnosticLab: data.diagnosticLab,
                        traitementCuratif: data.traitementCuratif,
                        pronostic: data.pronostic,
                        prevention: data.prevention,
                        ponderationDisease: JSON.stringify(ficheMaladie),
                      },
                    },
                  });
                  if (res.data?.createDisease.id) {
                    setProcessing(false);
                    linkTo(`/admin-diseases/details/${res.data?.createDisease.id}`);
                    return;
                  }
                } else {
                  await modifyDisease({
                    variables: {
                      input: {
                        id: disease.id,
                        name: data.name,
                        familleMaladieId: data.familleMaladieId,
                        codeIdentification: data.codeIdentification,
                        familleMaladie: data.familleMaladie,
                        MRLC: data.MRLC || false,
                        maladieUrgente: data.maladieUrgente || false,
                        description: data.description,
                        synonymes: data.synonymes,
                        enjeux: data.enjeux,
                        causes: data.causes,
                        circonstancesApparition: data.circonstancesApparition,
                        diagnosticDiff: data.diagnosticDiff,
                        diagnosticLab: data.diagnosticLab,
                        traitementCuratif: data.traitementCuratif,
                        pronostic: data.pronostic,
                        prevention: data.prevention,
                        ponderationDisease: JSON.stringify(ficheMaladie),
                        _version: disease._version,
                      },
                    },
                  });

                  await getDisease();
                }

                setProcessing(false);
              })();
            }}
            leftIcon="edit-outline"
            leftIconFill={theme['color-primary-500']}
            appearance="outline"
            style={{ marginTop: 10 }}
          >
            Enregistrer
          </Button>
          {!isAddMode ? (
            <>
              <Button
                onPress={() => {
                  setOpenModal(true);
                }}
                leftIcon="trash-2-outline"
                leftIconFill="white"
                appearance="filled"
                status="danger"
                style={{ marginTop: 10 }}
              >
                Supprimer la maladie
              </Button>
              <CustomModal
                title={'Vous êtes sur le point de supprimer cette maladie.'
                      + '\n'
                      + 'Êtes-vous sur de vouloir continuer ?'}
                before={<></>}
                noSafeArea
                visible={openModal}
                scrollable={false}
                onClose={() => setOpenModal(false)}
                insideModalStyle={{ textAlign: 'center', padding: 20 }}
                buttonText="Confirmer"
                secondButtonText="Annuler"
                heightPercentage={Platform.OS === 'web' ? 0.36 : 0.6}
                buttonPress={async () => {
                  const deleted = await deleteMaladie();
                  if (deleted) {
                    setOpenModal(false);
                    linkTo('/admin-diseases/');
                  }
                }}
              />
            </>
          ) : null}
        </>
      ) : null}
    </MaxWidthContainer>
  );
};

export default AdminDetailsDiseaseScreen;
