import { ViewStyle } from 'react-native';
import { useIsTablet } from '../../utils/CustomHooks';

export const useBtnStyle = (): ViewStyle => {
  const isTabletFormat = useIsTablet();
  return {
    backgroundColor: 'white',
    height: isTabletFormat ? 80 : 54,
    width: isTabletFormat ? 80 : 54,
    marginHorizontal: isTabletFormat ? 20 : 13,
    borderRadius: isTabletFormat ? 40 : 27,
    justifyContent: 'center',
    alignItems: 'center',
  };
};

export const useBtnInnerStyle = (): ViewStyle => {
  const isTabletFormat = useIsTablet();
  return {
    height: isTabletFormat ? 80 : 100,
    marginHorizontal: 20,
    justifyContent: 'center',
    alignItems: 'center',
  };
};

export const useIconStyle = (): ViewStyle => {
  const isTabletFormat = useIsTablet();
  return {
    width: isTabletFormat ? 35 : 20,
    height: isTabletFormat ? 35 : 20,
  };
};

export const useInnerIconStyle = (): ViewStyle => ({
  width: 35,
  height: 35,
});
