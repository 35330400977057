import React, { useState } from 'react';

import { Text } from '@ui-kitten/components';
import { useLinkTo } from '@react-navigation/native';
import { FlatList, View } from 'react-native';
import MaxWidthContainer from '../../../components/MaxWidthContainer';

import Button from '../../../components/Button';
import FCard from '../../../components/Card';
import ButtonGroup from '../../../components/ButtonGroup';
import { LockedStatus } from '../../../src/API';
import { useVetOfficeListAdmin } from '../../../src/API/VetOffice';
import FTextInput from '../../../components/Form/TextInput';

export default function AdminVetoScreen() {
  const linkTo = useLinkTo();

  const [locked, setLocked] = useState<LockedStatus>(LockedStatus.initialControlNeed);
  const [searchedText, setSearchedText] = useState<undefined | string>('');
  const {
    vetOffices, nextToken, fetchMore, loading,
  } = useVetOfficeListAdmin(
    locked,
    searchedText ? {
      or: [
        { companyName: { contains: searchedText } },
        { nSIRET: { contains: searchedText } },
        { nOrdreCabinet: { contains: searchedText } },
      ],
    } : undefined,
  );

  return (
    <MaxWidthContainer
      withScrollView="keyboardAware"
      outerViewProps={{
        style: {
          padding: 15,
        },
      }}
    >
      <FlatList
        data={vetOffices}
        keyExtractor={(item) => item?.id || 'z'}
        renderItem={
            ({ item }) => (item
              ? (
                <FCard
                  onPress={() => { linkTo(`/admin-vetos/details/${item.id}`); }}
                  style={{
                    marginVertical: 5, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginHorizontal: 5,
                  }}
                >
                  <View style={{ flexDirection: 'column', justifyContent: 'flex-start' }}>
                    <Text category="h4">{item.companyName}</Text>
                    <Text category="h6" appearance="hint">{`Numéro d'ordre : ${item.nOrdreCabinet}`}</Text>
                    <Text category="h6" appearance="hint">{`Numéro de SIRET : ${item.nSIRET}`}</Text>
                  </View>
                </FCard>
              ) : null
            )
          }
        ListHeaderComponent={(
          <>
            <ButtonGroup<LockedStatus>
              onChange={(selectedItem) => setLocked(selectedItem.key)}
              style={{ marginHorizontal: 5 }}
              items={[
                { key: LockedStatus.initialControlNeed, name: 'En attente de contrôle initial' },
                { key: LockedStatus.unlocked, name: 'Actifs' },
                { key: LockedStatus.otherLockReason, name: 'Autres bloqués' },
              ]}
              initialSelectedItem={locked}
            />
            <FTextInput
              name="search"
              placeholder="Rechercher un vétérinaire"
              value={searchedText}
              style={{ marginVertical: 15, marginHorizontal: 5, marginBottom: 10 }}
                // eslint-disable-next-line no-underscore-dangle
              onChangeValue={(enteredValue) => {
                setSearchedText(enteredValue as string);
              }}
              icon="search-outline"
            />
          </>
          )}
        ListFooterComponent={nextToken ? (
          <Button
            disabled={loading}
            loading={loading}
            onPress={() => {
              fetchMore({
                variables: { nextToken },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev;
                  return {
                    ...prev,
                    listVetOfficeByLockedByCreationDate: {
                      ...prev.listVetOfficeByLockedByCreationDate,
                      ...fetchMoreResult.listVetOfficeByLockedByCreationDate,
                      items: [
                        ...prev.listVetOfficeByLockedByCreationDate?.items,
                        ...fetchMoreResult.listVetOfficeByLockedByCreationDate?.items,
                      ],
                    },
                  };
                },
              });
            }}
          >
            Charger plus d'élevages
          </Button>
        ) : null}
      />

    </MaxWidthContainer>
  );
}
