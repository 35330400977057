import { Icon, Text, useTheme } from '@ui-kitten/components';
import React, { useEffect, useState } from 'react';
import { useRoute } from '@react-navigation/native';
import {
  FlatList, TouchableOpacity, View,
} from 'react-native';
import { API } from 'aws-amplify';
import { useForm } from 'react-hook-form';
import MaxWidthContainer from '../../../components/MaxWidthContainer';
import DetailsScreensHeaderCard from '../../../screenComponents/DetailsScreensHeaderCard';
import ActivityIndicator from '../../../components/ActivityIndicator';
import { getLockedStatusLabel, LockedStatusLabels } from '../../../screenBusinessLogic/LockedStatus';
import FCard from '../../../components/Card';
import Form from '../../../components/Form/Form';
import { AvailableValidationRules } from '../../../components/Form/validation';
import Button from '../../../components/Button';
import { LockedStatus } from '../../../src/API';
import Select from '../../../components/Form/Select';
import { useGetVetOfficeAdmin, useUpdateVetOfficeMutation } from '../../../src/API/VetOffice';

type ChangeSubscription = {
  locked: LockedStatus,
  isFree: 'true' | 'false'
};

const fraisItems = [
  { key: 'true', label: 'Oui' },
  { key: 'false', label: 'Non' },
];

const AdminDetailsElevageScreen = () => {
  const route = useRoute();
  const theme = useTheme();

  const [usersEnabled, setUsersEnabled] = useState<{ [key:string]: boolean }>({});

  const { vetOffice, refetch } = useGetVetOfficeAdmin(route.params.id);

  const { updateVeterinaryOffice } = useUpdateVetOfficeMutation();

  const [processing, setProcessing] = useState(false);
  const changeSubscription = useForm<ChangeSubscription>();
  const [changingSubscription, setChangingSubscription] = useState(false);

  useEffect(() => {
    let isLoaded = true;

    if (vetOffice?.users?.items) {
      vetOffice?.users?.items.forEach((item) => {
        if (item) {
          API.get('scanflockrest', `/user-admin/user-status?userId=${item.id}`, {}).then((res) => {
            if (res.success && isLoaded) {
              setUsersEnabled((prevState) => ({
                ...prevState,
                [item.id]: res.enabled,
              }));
            }
          });
        }
      });
    }
    return () => { isLoaded = false; };
  }, [vetOffice?.users?.items?.length, vetOffice?.id]);

  const renderUserState = (item: { id: string }) => {
    switch (usersEnabled[item.id]) {
      case true:
        return <Icon name="checkmark-circle-outline" fill={theme['color-success-500']} style={{ height: 20, width: 20 }} />;
      case false:
        return <Icon name="close-circle-outline" fill={theme['color-danger-500']} style={{ height: 20, width: 20 }} />;
      default:
        return <ActivityIndicator style={{ width: 20, height: 20 }} />;
    }
  };

  return (
    <MaxWidthContainer
      withScrollView="keyboardAware"
      outerViewProps={{
        style: {
          padding: 20,
        },
      }}
    >
      <Text category="h4" style={{ marginTop: 40 }}>Informations du cabinet</Text>
      {vetOffice
        ? (
          <DetailsScreensHeaderCard
            title1="Raison sociale"
            titleInfo1={vetOffice.companyName}
            title2="Adresse"
            titleInfo2={vetOffice.address?.address}
            title3="Complèment d'adresse"
            titleInfo3={vetOffice.address?.additionalAddress}
            title4="Code Postal"
            titleInfo4={vetOffice.address?.postalCode}
            title5="Ville"
            titleInfo5={vetOffice.address?.city}
            title6="Téléphone"
            titleInfo6={vetOffice.phoneVetOffice}
            title7="N° d'ordre du cabinet"
            titleInfo7={vetOffice.nOrdreCabinet}
            title8="E-mail"
            titleInfo8={vetOffice.emailOffice}
          />
        )
        : (<ActivityIndicator center margin={10} />)}

      <Text category="h4" style={{ marginTop: 40 }}>Statut du cabinet</Text>
      {vetOffice && (
        <>
          <DetailsScreensHeaderCard
            title1="Statut"
            titleInfo1={getLockedStatusLabel(vetOffice.locked)}
            title2="Frais offerts"
            titleInfo2={vetOffice.isFree ? 'Oui' : 'Non'}
          />
          {changingSubscription ? (

            <Form<ChangeSubscription>
              {...changeSubscription}
              defaultValues={{ ...vetOffice, isFree: vetOffice.isFree ? 'true' : 'false' }}
            >
              <>
                <View>
                  <Text category="h6" appearance="hint">Statut *</Text>
                  <Select
                    name="locked"
                    placeholder="Choisissez le statut"
                    validators={[
                      AvailableValidationRules.required,
                    ]}
                    data={LockedStatusLabels}
                  />
                  <Text category="h6" appearance="hint">Frais offerts *</Text>
                  <Select
                    name="isFree"
                    placeholder="Frais offerts ?"
                    validators={[
                      AvailableValidationRules.required,
                    ]}
                    data={fraisItems}
                  />
                </View>
              </>
            </Form>
          ) : null}
          <Button
            loading={processing}
            onPress={async () => {
              if (changingSubscription && refetch) {
                await changeSubscription.handleSubmit(async (data) => {
                  setProcessing(true);

                  await updateVeterinaryOffice({
                    variables: {
                      input: {
                        id: vetOffice.id,
                        locked: data.locked,
                        isFree: data.isFree === 'true',
                        _version: vetOffice._version,
                      },
                    },
                  });

                  await refetch();

                  setChangingSubscription(false);
                  setProcessing(false);
                })();
              } else {
                setChangingSubscription(true);
              }
            }}
            leftIcon="edit-outline"
            leftIconFill={theme['color-primary-500']}
            appearance="outline"
            style={{ marginTop: 10 }}
          >
            Modifier le statut
          </Button>
        </>
      )}
      <Text category="h4" style={{ marginTop: 40 }}>Utilisateurs</Text>
      <FlatList
        data={vetOffice?.users?.items}
        keyExtractor={(item) => item?.id || 'z'}
        renderItem={
              ({ item }) => (item
                ? (
                  <FCard
                    style={{
                      marginVertical: 5, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginHorizontal: 5,
                    }}
                  >
                    <View style={{ flexDirection: 'column', justifyContent: 'flex-start' }}>
                      <Text category="h4">
                        {item.firstname}
                        {' '}
                        {item.lastname}
                      </Text>
                      <Text category="h6" appearance="hint">{item.email}</Text>
                      {item.inscriptionNumberOrdreVeto ? (
                        <Text category="h6" appearance="hint">
                          N° ordre vétérinaire :
                          {' '}
                          {item.inscriptionNumberOrdreVeto}
                        </Text>
                      ) : null}
                      {vetOffice.admins.indexOf(item.id) > -1 ? (<Text category="h6" appearance="hint">Vétérinaire</Text>) : (<Text category="h6" appearance="hint">Personnel</Text>)}
                    </View>
                    <TouchableOpacity onPress={() => {
                      if (usersEnabled[item.id] !== undefined) {
                        API.post('scanflockrest', '/user-admin/user-status', {
                          body: {
                            userId: item.id,
                            enabled: !usersEnabled[item.id],
                          },
                        }).then((res) => {
                          if (res.success) {
                            setUsersEnabled((prevState) => ({
                              ...prevState,
                              [item.id]: res.enabled,
                            }));
                          }
                        });
                      }
                    }}
                    >
                      {renderUserState(item)}
                    </TouchableOpacity>
                  </FCard>
                ) : null
              )
            }
      />
    </MaxWidthContainer>
  );
};

export default AdminDetailsElevageScreen;
