import { useLinkTo } from '@react-navigation/native';

import React from 'react';

import MaxWidthContainer from '../../components/MaxWidthContainer';

import { useBseListEleveur } from '../../src/API/BSE';
import ActivityIndicator from '../../components/ActivityIndicator';
import { StatusBse } from '../../src/API';
import BseCard from '../../components/BseCard';

const BseHistoriqueScreen = () => {
  const linkTo = useLinkTo();
  const { loading, bse } = useBseListEleveur();
  return (
    <MaxWidthContainer
      withScrollView="keyboardAware"
      outerViewProps={{
        style: {
          padding: 24,
        },
      }}
    >
      {loading ? (<ActivityIndicator center margin={10} />) : (
        bse && bse.map((item) => {
          if (item.StatusBse === StatusBse.BSEFinish) {
            return (
              <BseCard
                BSEId={item.id}
                goTo={() => {
                  linkTo(`/bilan-sanitaire/demande-bse/${item.id}`);
                }}
              />
            );
          }
        })
      )}
    </MaxWidthContainer>
  );
};

export default BseHistoriqueScreen;
