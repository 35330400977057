import { useMutation, useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { WatchQueryFetchPolicy } from '@apollo/client';
import { useEffect } from 'react';
import {
  documentsByConsultation,
} from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import {
  CreateDocumentMutation,
  CreateDocumentMutationVariables,
  DeleteDocumentMutation,
  DeleteDocumentMutationVariables,
  DocumentsByConsultationQuery,
  DocumentsByConsultationQueryVariables,
  OnCreateDocumentByConsultationIdSubscriptionVariables,
  OnDeleteDocumentByConsultationIdSubscriptionVariables,
  UpdateDocumentMutation,
  UpdateDocumentMutationVariables,
} from '../API';

export type DocumentItem = {
  __typename: 'Document',
  id?: string | null,
  breedingId?: string | null,
  name: string,
  key?: string | null,
  s3file: string,
  createdAt?: string | null,
  updatedAt: string,
  _version?: number | null,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export const createDocumentQuery = /* GraphQL */ `
    mutation CreateDocument(
        $input: CreateDocumentInput!
        $condition: ModelDocumentConditionInput
    ) {
        createDocument(input: $input, condition: $condition) {
            id
            breedingId
            consultationId
            name
            key
            s3file
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
        }
    }
`;

export function useCreateDocumentMutation() {
  const [createDocument, { loading: loadingDocument }] = useMutation<
  CreateDocumentMutation,
  CreateDocumentMutationVariables>(gql(createDocumentQuery));

  return { createDocument, loadingDocument };
}

export function useDeleteDocumentMutation() {
  const [deleteDocument] = useMutation<
  DeleteDocumentMutation, DeleteDocumentMutationVariables
  >(gql(mutations.deleteDocument));
  return deleteDocument;
}

export function useUpdateDocumentMutation() {
  const [updateDocument, { loading: mutationDocumentLoading }] = useMutation<
  UpdateDocumentMutation, UpdateDocumentMutationVariables
  >(gql(mutations.updateBuildings));
  return { updateDocument, mutationDocumentLoading };
}

const onDeleteDocumentByConsultationId = /* GraphQL */ `
  subscription OnDeleteDocumentByConsultationId($consultationId: ID!) {
    onDeleteDocumentByConsultationId(consultationId: $consultationId) {
      id
    }
  }
`;

const onCreateDocumentByConsultationId = /* GraphQL */ `
  subscription OnCreateDocumentByConsultationId($consultationId: ID!) {
    onCreateDocumentByConsultationId(consultationId: $consultationId) {
      id
      breedingId
      consultationId
      name
      key
      s3file
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

type OnCreateDocumentByConsultationIdSubscription = {
  onCreateDocumentByConsultationId?: {
    __typename: 'Document',
    id: string,
    breedingId?: string | null,
    consultationId?: string | null,
    name: string,
    key?: string | null,
    s3file: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

type OnDeleteDocumentByConsultationIdSubscription = {
  onDeleteDocumentByConsultationId?: {
    __typename: 'Document',
    id: string,
  } | null,
};

export function useGetDocumentByConsultationId(consultationId: string, fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network') {
  const { data, refetch, subscribeToMore } = useQuery<DocumentsByConsultationQuery, DocumentsByConsultationQueryVariables>(gql(documentsByConsultation), {
    fetchPolicy,
    variables: {
      consultationId,
      limit: 1000,
    },
  });

  useEffect(() => {
    let unsubscribeCreate = () => {};
    let unsubscribeDelete = () => {};
    if (subscribeToMore) {
      unsubscribeCreate = subscribeToMore<
      OnCreateDocumentByConsultationIdSubscription,
      OnCreateDocumentByConsultationIdSubscriptionVariables
      >({
        document: gql(onCreateDocumentByConsultationId),
        variables: { consultationId },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          const newDocument = subscriptionData.data.onCreateDocumentByConsultationId;

          if (newDocument) {
            if (prev.documentsByConsultation) {
              if (prev.documentsByConsultation.items
                  && prev.documentsByConsultation.items[0]
                  && prev.documentsByConsultation.items[0].id === newDocument.id) {
                return prev;
              }
              return {
                documentsByConsultation: {
                  ...prev.documentsByConsultation,
                  items: [
                    newDocument,
                    ...prev.documentsByConsultation.items,
                  ],
                },
              };
            }
            return {
              documentsByConsultation: {
                __typename: 'ModelDocumentConnection',
                items: [
                  newDocument,
                ],
              },
            };
          }
          return prev;
        },
      });
      unsubscribeDelete = subscribeToMore<
      OnDeleteDocumentByConsultationIdSubscription,
      OnDeleteDocumentByConsultationIdSubscriptionVariables
      >({
        document: gql(onDeleteDocumentByConsultationId),
        variables: { consultationId },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          const deletedDocument = subscriptionData.data.onDeleteDocumentByConsultationId;

          if (deletedDocument) {
            if (prev.documentsByConsultation && prev.documentsByConsultation.items) {
              // on recherche cet ID dans la liste
              const newItems = prev.documentsByConsultation.items
                .filter((item) => item && item.id !== deletedDocument.id);
              return {
                documentsByConsultation: {
                  ...prev.documentsByConsultation,
                  items: newItems,
                },
              };
            }
          }
          return prev;
        },
      });
    }
    return () => {
      unsubscribeCreate();
      unsubscribeDelete();
    };
  }, [subscribeToMore, consultationId]);

  if (data) {
    const documents = data.documentsByConsultation?.items?.filter((docu) => !docu._deleted);
    return { documents, refetch };
  }
  return { documents: null };
}
