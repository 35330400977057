export const templateFacture = `
<html><head>
<style type="text/css">
    * {
        font-size: 14px;
        font-family: "SourceSansPro-Regular";
    }
    p {
    margin: 0;
}
    p.bigTitle {
        font-size: 26px;
        text-align: center;
        margin-bottom: 30px;
        font-weight: bold;
    }
    
    p.titre1 {
        font-size: 22px;
    margin-top: 20px;
    }
    
    p.question {
    margin-top: 16px;
    }
    
    p.titre2 {
        font-size: 18px;
    margin-top: 20px;
    }
    
    p.question, p.reponse, p.calcul {
        margin-left: 60px;
    }
    
    p.cats {
    margin-left: 70px;
    font-weight: bold;
}
    
    p.reponse.cats, p.calcul.cats {
    font-weight: normal;
    }
    
    p.titre3 {
        font-size: 16px;
        margin-left: 40px;
    margin-top: 20px;
    }
    
    p.titre4 {
        font-size: 14px;
        margin-left: 50px;
    margin-top: 10px;
    }
    @page {
    margin: 50px;
  }
  .container {
    width: auto;
    padding: 30px;
  }
  .rowBailleur {
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 80px;
  }
  .bailleur {
    padding: 15px;
  }
  .bailleur2 {
      padding: 15px;
      text-align: right;
      width: 100%;
  }
  .bailleur p {
    margin-top: 0;
    margin-bottom: 5px;
  }
  table {
    margin-top: 80px;
    border-collapse: collapse;
    width: 100%;
  }
  tbody {
    display: table;
    width: 100%;
  }
  td {
    border: 1px solid black;
    padding: 5px 0 5px 10px;
  }
  td p,
   .bailleur2 p {
    margin-top: 5px;
    margin-bottom: 0;
  }
  .column {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  td div {
    display: flex;
    flex: 1;
    justify-content: center;
  }
  h2 {
    display: flex;
    flex: 1;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 0;
  }
  h3 {
    margin: 0;
  }
  .bailleurText {
    margin-top: 20px;
    margin-bottom: 20px;
  }
</style></head><body class="pdfContainer">
<div  class="pdfContainer">
<div class="container">
    <table width="99%" border="0" cellspacing="0" cellpadding="3">
    <tr>
    <td><p>VetelSud</p>
        <p>39 av François Monsarrat</p>
        <p>81110 VERDALLE</p>
        <p>RCS Castres 892 743 691</p>
      </td>
    <td style="text-align: right;"></td>
    </tr><tr>
    <td></td>
    <td style="text-align: right;">
        <p>{{societe}}</p>
        <p>{{address}} </p>
        <p>{{address2}} </p>
        <p>{{postalCode}} {{city}}</p>
      </td>
    </tr>
    <tr><td colspan="2"><br>
        <br>
    <p style="font-weight: bold;font-family: 'SourceSansPro-Bold';font-size: 25px">Facture n°{{nFacture}} - {{date}}</p>
        <br>
        <br></td></tr>
      <table width="100%" border="1" cellspacing="0" cellpadding="3">
        <tbody>
        <tr >
          <td >
            Désignation
          </td>
          <td>
            P.U. HT
          </td>
          <td>
            Quantité
          </td>
          <td>
            Total HT
          </td>
          <td>
            Total TTC
          </td>
        </tr>
        <tr >
          <td >
            <p><strong>{{label}}</strong></p>
          </td>
          <td style="text-align: right;">
            {{totalHT}}
          </td>
          <td style="text-align: right;">
            1
          </td>
          <td style="text-align: right;">
            {{totalHT}}
          </td>
          <td style="text-align: right;">
            {{totalTTC}}
          </td>
        </tr>
        <tr>
          <td colspan="5"><br/><br/></td>
        </tr>
        <tr>
          <td colspan="4">Total HT</td>
          <td style="text-align: right;">{{totalHT}}</td>
        </tr>
        <tr>
          <td colspan="4">TVA</td>
          <td style="text-align: right;">{{totalTaxes}}</td>
        </tr>
        <tr>
          <td colspan="4"><p style="font-weight: bold;font-family: 'SourceSansPro-Bold'">Total TTC</p></td>
          <td style="text-align: right;"><p style="font-weight: bold;font-family: 'SourceSansPro-Bold'">{{totalTTC}}</p></td>
        </tr>
        </tbody>
      </table>
  </div>
</div>
</body></html>
`;
