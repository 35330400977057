import { View, ViewStyle } from 'react-native';
import { Icon as IconUIKitten } from '@ui-kitten/components/ui/icon/icon.component';
import React from 'react';
import { Text } from '@ui-kitten/components';
import FCard from '../components/Card';
import Radio from '../components/Form/Radio';

export type AnimalCardProps = {
  onPress?: () => void,
  name: string | undefined | null,
  numberId?: number | string,
  numberLot?: number | string,
  minusSquareOutline?: string,
  animals?: [{
    birthDate?: string
    breedingId: string
    gender?: string
    id: string
    lots?: null
    name: string
    numberID: number
    __typename: string
  }]

  teleCard?: string;
  // animal?: Animals | undefined
  radio?: string,
  style?: ViewStyle
};

const AnimalCard = (props: AnimalCardProps) => {
  const {
    onPress, numberId, numberLot, name, teleCard, minusSquareOutline, radio, style,
  } = props;
  // console.log('animal card : ', numberId, numberLot, name);
  // console.log('animal card : ', minusSquareOutline);
  const renderIcon = () => {
    let iconLocal;
    if (teleCard !== 'telecard') {
      iconLocal = 'arrow-ios-forward';
    } if (minusSquareOutline === 'trash-2-outline') {
      iconLocal = 'trash-2-outline';
    } else if (minusSquareOutline === 'plus-square-outline') {
      iconLocal = 'plus-square-outline';
    } else if (minusSquareOutline === 'arrow-right-outline') {
      iconLocal = 'arrow-right-outline';
    } else if (radio) {
      // not used yet. Potentially for RealiserExam Screen
      return (<Radio name="" />);
    }
    return (<IconUIKitten name={iconLocal} fill="#000000" style={{ height: 24, width: 24 }} />);
  };
  return (
    <>
      {teleCard !== 'telecard' && (
        <FCard
          onPress={onPress}
          style={{
            marginVertical: 5, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginHorizontal: 5,
          }}
        >
          <View style={{ flexDirection: 'column', justifyContent: 'flex-start' }}>
            <Text category="h6" appearance="hint">{name || "L'animal n'a pas de nom"}</Text>
            <Text category="h4">{`Numéro d'identification: ${numberId}`}</Text>
            <Text category="h4">{numberLot ? (`Numéro de lot: ${numberLot}`) : ("L'animal n'est affecté à aucun lot")}</Text>
          </View>
          {renderIcon()}
        </FCard>
      )}
      {teleCard === 'telecard' && (
      <View style={[{
        flex: 1, marginLeft: 20, flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
      }, style]}
      >
        <Text category="h6" appearance="hint">Animal</Text>
        {name && (<Text category="h4">{name || ''}</Text>)}
        <Text category="h4">
          {numberId
            ? (`${numberId}`)
            : ("L'animal n'est affecté à aucun lot")}
        </Text>
      </View>
      )}
    </>
  );
};

export default AnimalCard;
