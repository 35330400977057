export default {
  'D)1)1.': {
    id: 'D)1)1.',
    titre1: 'Zoom sur l\'élevage :',
    name: 'Le type de production :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    readOnly: true,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)1)1.1/': {
        name: 'Allaitant',
        description: '',
        bseOnly: false,
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)1)1.2/': {
        name: 'Laitier',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)1)1.3/': {
        name: 'Veaux de boucherie et (ou) engraissement',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)1)2.': {
    id: 'D)1)2.',
    name: 'Le niveau de production :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)1)2.1/': {
        name: 'Conduite économe',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)1)2.2/': {
        name: 'élevé',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)1)3.': {
    id: 'D)1)3.',
    name: 'L\'organisation du travail :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)1)3.1/': {
        name: 'Main d’œuvre disponible',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        bseColor: 'green',
      },
      'D)1)3.2/': {
        name: 'Travaux en concurrence avec le troupeau à certaines périodes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        bseColor: 'orange',
      },
    },
  },
  'D)1)4.': {
    id: 'D)1)4.',
    name: 'Le régime alimentaire :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)1)4.1/': {
        name: 'Aliment',
        description: 'Fermier ou commerce',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)1)4.2/': {
        name: 'Herbacé',
        description: 'Herbe-foin',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)1)4.3/': {
        name: 'Ensilage d’herbe',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)1)4.4/': {
        name: 'Ensilage de mais',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)1)4.5/': {
        name: 'Enrubannage',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)1)4.6/': {
        name: 'Mixte',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)1)4.7/': {
        name: 'Lacté',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)1)5.': {
    id: 'D)1)5.',
    name: 'Les transitions alimentaires :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)1)5.1/': {
        name: 'Absence',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        bseColor: 'red',
      },
      'D)1)5.2/': {
        name: 'Inférieures à 2 semaines',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        bseColor: 'orange',
      },
    },
  },
  'D)1)6.': {
    id: 'D)1)6.',
    name: 'Le logement :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)1)6.1/': {
        name: 'Abri individuel',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)1)6.2/': {
        name: 'Bâtiment traditionnel',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)1)6.3/': {
        name: 'étable entravée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)1)6.4/': {
        name: 'Hangar ou stabulation',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)1)6.5/': {
        name: 'Tunnel',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)1)6.6/': {
        name: 'Aire paillée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)1)6.7/': {
        name: 'Aire raclée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)1)6.8/': {
        name: 'Logettes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)1)7.': {
    id: 'D)1)7.',
    name: 'Les avortements :',
    description: 'Veau vivant né avant le terme ou veau mort ou qui meurt dans les 48h00 après sa naissance',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)1)7.1/': {
        name: '<4% de l\'effectif',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        bseColor: 'green',
      },
      'D)1)7.2/': {
        name: '> 4 % de l’effectif',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        bseColor: 'red',
      },
      'D)1)7.3/': {
        name: 'Pas d\'avortement ou exceptionnel',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)1)8.': {
    id: 'D)1)8.',
    titre3: 'L\'effectif :',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)1)8.1)': {
    id: 'D)1)8.1)',
    name: '< 1 mois',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)8.1)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)1)8.2)': {
    id: 'D)1)8.2)',
    name: '1 mois < < 6 mois',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)8.2)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)1)8.12)': {
    id: 'D)1)8.12)',
    name: '6 mois < < 1 an',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)8.12)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)1)8.13)': {
    id: 'D)1)8.13)',
    name: '1 an < < 2 ans',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)8.13)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)1)8.4)': {
    id: 'D)1)8.4)',
    name: 'Génisses de 2 ans et plus',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)8.4)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)1)8.3)': {
    id: 'D)1)8.3)',
    name: 'Mâles de 2 ans et plus',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)8.3)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)1)8.5)': {
    id: 'D)1)8.5)',
    name: 'Vaches',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)8.5)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)1)8.7)': {
    id: 'D)1)8.7)',
    name: 'Effectif total',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)8.7)': {
        answerType: 'F',
        formule: '${D)1)8.1)}+${D)1)8.2)}+${D)1)8.12)}+${D)1)8.13)}+${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)1)8.8)': {
    id: 'D)1)8.8)',
    name: 'SAU en ha',
    description: 'Superficie agricole utilisée',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)8.8)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)1)8.9)': {
    id: 'D)1)8.9)',
    name: 'Nombre d\'UGB moyen',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)8.9)': {
        answerType: 'F',
        formule: '(${D)1)8.1)}+${D)1)8.2)}+${D)1)8.12)})0.4+${D)1)8.13)}*0.7+${D)1)8.4)}*0.8+${D)1)8.5)}+${D)1)8.3)}',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)1)8.10)': {
    id: 'D)1)8.10)',
    name: 'Nombre d\'UGB / ha',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)8.10)': {
        answerType: 'F',
        formule: '${D)1)8.9)}/${D)1)8.8)}',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)1)9.': {
    id: 'D)1)9.',
    titre3: 'La reproduction :',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)1)9.1)': {
    id: 'D)1)9.1)',
    name: 'Nombre total de vaches mises à la reproduction',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)9.1)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)1)9.2)': {
    id: 'D)1)9.2)',
    name: 'Nombre total de vaches gestantes',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)9.2)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)1)9.3)': {
    id: 'D)1)9.3)',
    name: 'Taux de gestation',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)9.3)': {
        answerType: 'F',
        formule: '${D)1)9.2)}/${D)1)9.1)}*100',
        suffix: ' %',
        round: 2,
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)1)9.4)': {
    id: 'D)1)9.4)',
    name: 'Nombre de naissances',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)9.4)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)1)9.5)': {
    id: 'D)1)9.5)',
    name: 'Nombre de veaux sevrés',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)9.5)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)1)9.6)': {
    id: 'D)1)9.6)',
    name: 'Taux de veaux sevrés',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)9.6)': {
        answerType: 'F',
        formule: '${D)1)9.5)}/${D)1)9.4)}*100',
        suffix: ' %',
        round: 2,
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)1)10.': {
    id: 'D)1)10.',
    titre2: 'Les réformes :',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)1)10.a)': {
    id: 'D)1)10.a)',
    titre4: 'Nombre et motif des réformes pour causes zootechniques',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)1)10.a)1)': {
    id: 'D)1)10.a)1)',
    name: 'âge',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)10.a)1)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)1)10.a)2)': {
    id: 'D)1)10.a)2)',
    name: 'Aplombs',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)10.a)2)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)1)10.a)3)': {
    id: 'D)1)10.a)3)',
    name: 'Production',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)10.a)3)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)1)10.a)4)': {
    id: 'D)1)10.a)4)',
    name: 'Qualité maternelle',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)10.a)4)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)1)10.a)5)': {
    id: 'D)1)10.a)5)',
    name: 'Comportement',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)10.a)5)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)1)10.a)6)': {
    id: 'D)1)10.a)6)',
    name: 'Autres',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)10.a)6)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)1)10.a)7)': {
    id: 'D)1)10.a)7)',
    name: 'Taux âge',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)10.a)7)': {
        answerType: 'F',
        formule: '${D)1)10.a)1)}/${D)1)8.5)}*100',
        suffix: ' %',
        round: 2,
        minVert: '20',
        maxVert: '40',
        minOrange: '0',
        maxOrange: '20',
        minRouge: '40',
        maxRouge: '1000000',
      },
    },
  },
  'D)1)10.a)8)': {
    id: 'D)1)10.a)8)',
    name: 'Taux aplombs',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)10.a)8)': {
        answerType: 'F',
        formule: '${D)1)10.a)2)}/${D)1)8.5)}*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '20',
        minOrange: '20',
        maxOrange: '40',
        minRouge: '40',
        maxRouge: '1000000',
      },
    },
  },
  'D)1)10.a)9)': {
    id: 'D)1)10.a)9)',
    name: 'Taux production',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)10.a)9)': {
        answerType: 'F',
        formule: '${D)1)10.a)3)}/${D)1)8.5)}*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '20',
        minOrange: '20',
        maxOrange: '40',
        minRouge: '40',
        maxRouge: '1000000',
      },
    },
  },
  'D)1)10.a)10)': {
    id: 'D)1)10.a)10)',
    name: 'Taux qualité maternelle',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)10.a)10)': {
        answerType: 'F',
        formule: '${D)1)10.a)4)}/${D)1)8.5)}*100',
        suffix: ' %',
        round: 2,
          minVert: '0',
          maxVert: '20',
          minOrange: '20',
          maxOrange: '40',
        minRouge: '40',
        maxRouge: '1000000',
      },
    },
  },
  'D)1)10.a)11)': {
    id: 'D)1)10.a)11)',
    name: 'Taux comportement',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)10.a)11)': {
        answerType: 'F',
        formule: '${D)1)10.a)5)}/${D)1)8.5)}*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '20',
        minOrange: '20',
        maxOrange: '40',
        minRouge: '40',
        maxRouge: '1000000',
      },
    },
  },
  'D)1)10.a)12)': {
    id: 'D)1)10.a)12)',
    name: 'Taux autres',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)10.a)12)': {
        answerType: 'F',
        formule: '${D)1)10.a)6)}/${D)1)8.5)}*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '20',
        minOrange: '20',
        maxOrange: '40',
        minRouge: '40',
        maxRouge: '1000000',
      },
    },
  },
  'D)1)10.a)13)': {
    id: 'D)1)10.a)13)',
    name: 'Taux moyen',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)10.a)13)': {
        answerType: 'F',
        formule: '(${D)1)10.a)7)}+${D)1)10.a)8)}+${D)1)10.a)9)}+${D)1)10.a)10)}+${D)1)10.a)11)}+${D)1)10.a)12)})/6',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '20',
        minOrange: '20',
        maxOrange: '40',
        minRouge: '40',
        maxRouge: '1000000',
      },
    },
  },
  'D)1)10.b)': {
    id: 'D)1)10.b)',
    titre4: 'Nombre et motif des réformes pour causes sanitaires :',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)1)10.b)1)': {
    id: 'D)1)10.b)1)',
    name: 'Boiterie',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)10.b)1)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)1)10.b)2)': {
    id: 'D)1)10.b)2)',
    name: 'Mammite',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)10.b)2)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)1)10.b)3)': {
    id: 'D)1)10.b)3)',
    name: 'Cellules',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)10.b)3)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)1)10.b)4)': {
    id: 'D)1)10.b)4)',
    name: 'Métrites',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)10.b)4)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)1)10.b)5)': {
    id: 'D)1)10.b)5)',
    name: 'Fécondité',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)10.b)5)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)1)10.b)6)': {
    id: 'D)1)10.b)6)',
    name: 'Autres',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)10.b)6)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)1)10.b)7)': {
    id: 'D)1)10.b)7)',
    name: 'Taux boiterie',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)10.b)7)': {
        answerType: 'F',
        formule: '${D)1)10.b)1)}/${D)1)8.5)}*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '1000000',
      },
    },
  },
  'D)1)10.b)8)': {
    id: 'D)1)10.b)8)',
    name: 'Taux mammite',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)10.b)8)': {
        answerType: 'F',
        formule: '${D)1)10.b)2)}/${D)1)8.5)}*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '30',
        minOrange: '30',
        maxOrange: '50',
        minRouge: '50',
        maxRouge: '1000000',
      },
    },
  },
  'D)1)10.b)9)': {
    id: 'D)1)10.b)9)',
    name: 'Taux cellules',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)10.b)9)': {
        answerType: 'F',
        formule: '${D)1)10.b)3)}/${D)1)8.5)}*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '30',
        minOrange: '30',
        maxOrange: '50',
        minRouge: '50',
        maxRouge: '1000000',
      },
    },
  },
  'D)1)10.b)10)': {
    id: 'D)1)10.b)10)',
    name: 'Taux métrites',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)10.b)10)': {
        answerType: 'F',
        formule: '${D)1)10.b)4)}/${D)1)8.5)}*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '1000000',
      },
    },
  },
  'D)1)10.b)11)': {
    id: 'D)1)10.b)11)',
    name: 'Taux fécondité',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)10.b)11)': {
        answerType: 'F',
        formule: '${D)1)10.b)5)}/${D)1)8.5)}*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '10',
        minOrange: '10',
        maxOrange: '20',
        minRouge: '20',
        maxRouge: '1000000',
      },
    },
  },
  'D)1)10.b)12)': {
    id: 'D)1)10.b)12)',
    name: 'Taux autres',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)10.b)12)': {
        answerType: 'F',
        formule: '${D)1)10.b)6)}/${D)1)8.5)}*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '3',
        minOrange: '3',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '1000000',
      },
    },
  },
  'D)1)av': {
    id: 'D)1)av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)1)av': {
        answerType: 'TI',
      },
    },
  },
  'D)2)': {
    id: 'D)2)',
    titre2: 'Zoom sur l\'élevage :',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)2)2.': {
    id: 'D)2)2.',
    titre3: 'L\'effectif humain :',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)2)2.a)': {
    id: 'D)2)2.a)',
    name: 'UMO atelier veau :',
    description: 'Unité Main d’œuvre',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)2)2.a)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)2)2.b)': {
    id: 'D)2)2.b)',
    name: 'UMO totale exploitation :',
    description: 'Unité Main d’œuvre',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)2)2.b)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)2)3.': {
    id: 'D)2)3.',
    name: 'Le nombre de places :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)2)3.': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)2)4.': {
    id: 'D)2)4.',
    name: 'Le type racial des lots :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)2)4.1/': {
        name: 'Holstein',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)2)4.2/': {
        name: 'Croisés lourds',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)2)4.3/': {
        name: 'Croisés légers',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)2)4.4/': {
        name: 'Mixtes mélange',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)2)5.': {
    id: 'D)2)5.',
    titre3: 'L’organisation de la production :',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)2)5.a)': {
    id: 'D)2)5.a)',
    name: 'Intégration :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)2)5.a)1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)2)5.a)2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)2)5.b)': {
    id: 'D)2)5.b)',
    name: 'Coordonnées de l’intégrateur :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)2)5.b)': {
        answerType: 'TI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)2)5.c)': {
    id: 'D)2)5.c)',
    name: 'Coordonnées du technicien :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)2)5.c)': {
        answerType: 'TI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)2)6.': {
    id: 'D)2)6.',
    name: 'L\'origine des animaux :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)2)6.': {
        answerType: 'TI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)2)7.': {
    id: 'D)2)7.',
    name: 'Le nombre de veaux par bande :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)2)7.': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)2)16.': {
    id: 'D)2)16.',
    name: 'Nombre de veaux de moins de 2 mois :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)2)16.': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)2)17.': {
    id: 'D)2)17.',
    name: 'Nombre de veaux entre 2 et 3 mois :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)2)17.': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)2)18.': {
    id: 'D)2)18.',
    name: 'Nombre de veaux de plus de 3 mois :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)2)18.': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)2)8.': {
    id: 'D)2)8.',
    name: 'Conduite en bande unique sur l’élevage :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)2)8.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)2)8.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)2)9.': {
    id: 'D)2)9.',
    name: 'Contrôle anémie :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)2)9.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)2)9.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)2)10.': {
    id: 'D)2)10.',
    name: 'Hématocrite :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)2)10.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)2)10.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)2)11.': {
    id: 'D)2)11.',
    name: 'Dosage hémoglobine :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)2)11.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)2)11.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)2)12.': {
    id: 'D)2)12.',
    name: 'Le bâtiment :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)2)12.1/': {
        name: 'Bâtiments en dur',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)2)12.2/': {
        name: 'Bâtiments tunnels',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)2)13.': {
    id: 'D)2)13.',
    name: 'Les façades des parcs :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)2)13.1/': {
        name: 'Cornadis',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)2)13.2/': {
        name: 'Barres au garot',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)2)13.3/': {
        name: 'Cornadis basculants',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)2)14.': {
    id: 'D)2)14.',
    titre3: 'L’organisation des bâtiments :',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)2)14.a)': {
    id: 'D)2)14.a)',
    name: 'Nombre de bâtiments :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)2)14.a)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)2)14.b)': {
    id: 'D)2)14.b)',
    name: 'Nombre moyen de veaux par bâtiment :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)2)14.b)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)2)14.c)': {
    id: 'D)2)14.c)',
    name: 'Infirmerie :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)2)14.c)1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)2)14.c)2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)2)14.d)': {
    id: 'D)2)14.d)',
    name: 'Nombre de places infirmerie :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)2)14.d)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)2)14.f)': {
    id: 'D)2)14.f)',
    name: 'Cohabitation plusieurs bandes :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)2)14.f)1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)2)14.f)2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)2)15.': {
    id: 'D)2)15.',
    name: 'Nature du sol :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)2)15.1/': {
        name: 'Caillebotis',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)2)15.2/': {
        name: 'Paille',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)2)av': {
    id: 'D)2)av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)2)av': {
        answerType: 'TI',
      },
    },
  },
  'D)3)': {
    id: 'D)3)',
    titre2: 'L’hygiène et l’ambiance des bâtiments :',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)3)a)': {
    id: 'D)3)a)',
    name: 'Le circuit des déjections :',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)3)a)1/': {
        name: 'Absence d’entretien des aires de couchage',
        nameRapport: 'les aires de couchage nécessitent un meilleur entretien',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)a)2/': {
        name: 'Absence d’entretien des aires de promenade',
        nameRapport: 'les aires de promenade nécessitent un meilleur entretien',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)a)3/': {
        name: 'Contacts des déjections des adultes vers les jeunes',
        nameRapport: 'éviter les contacts des déjections des adultes vers les jeunes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)a)4/': {
        name: 'Contact des déjections des animaux malades vers les animaux sains',
        nameRapport: 'éviter les contacts des déjections des animaux malades vers les animaux sains',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)a)5/': {
        name: 'Croisement entre les zones «souillées» et les zones «propres»',
        nameRapport: 'éviter les croisements entre les zones « souillées » et les zones « propres »',
        description: ' - zones «souillées» : raclage vers la fumière ou la fosse, curage… \\n - zones «propres» : stockage, distribution, bloc traite, accès du camion de collecte…',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)3)a)av': {
    id: 'D)3)a)av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)3)a)av': {
        answerType: 'TI',
      },
    },
  },
  'D)3)b)': {
    id: 'D)3)b)',
    name: 'La propreté des animaux :',
    description: 'Méthode d’évaluation proposée par l’Institut de l’Elevage dans le cadre de la Charte des Bonnes Pratiques d’Elevage :',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)3)b)1/': {
        name: 'Propre',
        nameRapport: 'l’état de propreté de vos animaux est tout à fait correcte',
        bseColor: 'green',
        description: 'Absence de salissure sur l’animal ou salissures à l’état de traces',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)b)2/': {
        name: 'Peu sale',
        nameRapport: 'l’état de propreté de vos animaux est correcte',
        bseColor: 'yellow',
        description: 'Zones de salissures s’étendant sur la moitié inférieure de la cuisse et sur le bas du ventre jusqu’à la poitrine',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)b)3/': {
        name: 'Sale',
        description: 'Zones de salissures s’étendant du haut de la cuisse jusqu’à la poitrine',
        nameRapport: 'l’état de propreté de vos animaux doit être amélioré',
        bseColor: 'orange',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)b)4/': {
        name: 'Très sale',
        nameRapport: 'l’état de propreté de vos animaux doit être amélioré de toute urgence',
        bseColor: 'red',
        description: 'Zone de salissures s’étendant de la hanche jusqu’à la pointe de l’épaule. Les salissures remontent sur le côté jusqu’en haut du flanc et forment une croûte épaisse',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)3)b)av': {
    id: 'D)3)b)av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)3)b)av': {
        answerType: 'TI',
      },
    },
  },
  'D)3)c)': {
    id: 'D)3)c)',
    name: 'Le paillage et le raclage :',
    description: 'En litière accumulée, l’optimum se situe autour de 1 à 1,2 kg de paille stockée au sec par m2 et par jour. Après curage, l’utilisation de produit asséchant est conseillée, et l’optimum se situe alors autour de 2 à 2,5 kg de paille stockée au sec par m2: ',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)3)c)1/': {
        name: 'Absence de contrôle de la température de la litière',
        description: 'Norme: 40°c en moyenne à 10 cm de profondeur',
        nameRapport: 'contrôler régulièrement la température de la litière',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)c)2/': {
        name: 'Distribution d’aliment ou de foin sur l’aire paillée',
        nameRapport: 'éviter la distribution d’aliment ou de foin sur l’aire paillée',
        description: 'Râteliers',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)c)3/': {
        name: 'Non respect des normes de paillage après curage',
        nameRapport: 'respecter les normes de paillage après curage',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)c)4/': {
        name: 'Paillage abusif',
        nameRapport: 'éviter de trop pailler',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)c)5/': {
        name: 'Paillage insuffisant',
        nameRapport: 'vous devriez pailler davantage',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)c)6/': {
        name: 'Présence d’abreuvoir sur l’aire paillée',
        nameRapport: 'éviter la présence d’abreuvoir sur l’aire paillée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)c)7/': {
        name: 'Raclage irrégulier des aires d’exercice',
        nameRapport: 'racler plus régulièrement les aires d’exercice',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)c)8/': {
        name: 'Sortie de la salle de traite sur l’aire paillée',
        nameRapport: 'veiller à ce que vos vaches ne se couchent pas dans l’heure qui suit la traite',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)c)9/': {
        name: 'Sous sol de l’aire paillée insuffisamment drainant',
        nameRapport: 'améliorer le drainage du sous-sol de l’aire paillée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)3)c)av': {
    id: 'D)3)c)av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)3)c)av': {
        answerType: 'TI',
      },
    },
  },
  'D)3)d)': {
    id: 'D)3)d)',
    titre3: 'La densité animale :',
    description: 'La surcharge en effectif est une cause importante de dérèglement du statut sanitaire d’un troupeau.',
    bseOnly: true,
    interdire: false,
    autopass: true,
    function: 'R3',
  },
  'D)3)d)1)': {
    id: 'D)3)d)1)',
    name: 'Type de bâtiment',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)3)d)1)1/': {
        name: 'Aire paillée intégrale',
        description: '',
        image: '',
        interdire: false,
        mandatory: true,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)d)1)2/': {
        name: 'Aire paillée avec stalle d’auge',
        description: '',
        image: '',
        interdire: false,
        mandatory: true,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)d)1)3/': {
        name: 'Aire paillée avec aire d\'exercice',
        description: '',
        image: '',
        interdire: false,
        mandatory: true,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)d)1)4/': {
        name: 'Pente paillée - logettes - avec couloir raclé',
        description: '',
        image: '',
        interdire: false,
        mandatory: true,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)d)1)5/': {
        name: 'Pente paillée - logettes - sans couloir raclé',
        description: '',
        image: '',
        interdire: false,
        mandatory: true,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)d)1)6/': {
        name: 'Caillebotis intégral',
        description: '',
        image: '',
        interdire: false,
        mandatory: true,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)3)d)2)': {
    id: 'D)3)d)2)',
    name: 'Superficie (en m2)',
    hideRapport: true,
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)3)d)2)': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)3)d)3)': {
    id: 'D)3)d)3)',
    name: 'Nb bovins > 700 kg',
    hideRapport: true,
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)3)d)3)': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)3)d)7)': {
    id: 'D)3)d)7)',
    name: 'Nb bovins > 700 kg',
    hideRapport: true,
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)3)d)7)1/': {
        name: 'Non recommandé',
        hideRapport: true,
        answerType: 'I',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)3)d)4)': {
    id: 'D)3)d)4)',
    name: 'Nb bovins 500 < > 600kg',
    hideRapport: true,
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)3)d)4)': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)3)d)5)': {
    id: 'D)3)d)5)',
    name: 'Nb bovins 400kg',
    hideRapport: true,
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)3)d)5)': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)3)d)6)': {
    id: 'D)3)d)6)',
    name: 'Nb bovins 300kg',
    hideRapport: true,
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)3)d)6)': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)3)d)8)': {
    id: 'D)3)d)8)',
    name: 'Densité animale',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)3)d)8)': {
        answerType: 'F',
        formule: '${D)3)d)2)}/(${D)3)d)3)}*11+${D)3)d)4)}*5+${D)3)d)5)}*4+${D)3)d)6)}*3.5)',
        round: 2,
        minVert: '1.3',
        maxVert: '1000000',
        minOrange: '1',
        maxOrange: '1.3',
        minRouge: '0',
        maxRouge: '1',
      },
    },
  },
  'D)3)d)9)': {
    id: 'D)3)d)9)',
    name: 'Densité animale',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)3)d)9)': {
        answerType: 'F',
        formule: '${D)3)d)2)}/(${D)3)d)3)}*10+${D)3)d)4)}*5+${D)3)d)5)}*4+${D)3)d)6)}*3)',
        round: 2,
        minVert: '1.3',
        maxVert: '1000000',
        minOrange: '1',
        maxOrange: '1.3',
        minRouge: '0',
        maxRouge: '1',
      },
    },
  },
  'D)3)d)10)': {
    id: 'D)3)d)10)',
    name: 'Densité animale',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)3)d)10)': {
        answerType: 'F',
        formule: '${D)3)d)2)}/(${D)3)d)3)}*7+${D)3)d)4)}*3.5+${D)3)d)5)}*2.5+${D)3)d)6)}*2.5)',
        round: 2,
        minVert: '1.3',
        maxVert: '1000000',
        minOrange: '1',
        maxOrange: '1.3',
        minRouge: '0',
        maxRouge: '1',
      },
    },
  },
  'D)3)d)11)': {
    id: 'D)3)d)11)',
    name: 'Densité animale',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)3)d)11)': {
        answerType: 'F',
        formule: '${D)3)d)2)}/(${D)3)d)3)}*6+${D)3)d)4)}*3+${D)3)d)5)}*2.5+${D)3)d)6)}*2)',
        round: 2,
        minVert: '1.3',
        maxVert: '1000000',
        minOrange: '1',
        maxOrange: '1.3',
        minRouge: '0',
        maxRouge: '1',
      },
    },
  },
  'D)3)d)12)': {
    id: 'D)3)d)12)',
    name: 'Densité animale',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)3)d)12)': {
        answerType: 'F',
        formule: '${D)3)d)2)}/(${D)3)d)3)}*8+${D)3)d)4)}*4+${D)3)d)5)}*3+${D)3)d)6)}*2.5)',
        round: 2,
        minVert: '1.3',
        maxVert: '1000000',
        minOrange: '1',
        maxOrange: '1.3',
        minRouge: '0',
        maxRouge: '1',
      },
    },
  },
  'D)3)d)13)': {
    id: 'D)3)d)13)',
    name: 'Densité animale',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)3)d)13)': {
        answerType: 'F',
        formule: '${D)3)d)2)}/(${D)3)d)4)}*3+${D)3)d)5)}*2.5+${D)3)d)6)}*2)',
        round: 2,
        minVert: '1.3',
        maxVert: '1000000',
        minOrange: '1',
        maxOrange: '1.3',
        minRouge: '0',
        maxRouge: '1',
      },
    },
  },
  'D)3)d)av': {
    id: 'D)3)d)av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)3)d)av': {
        answerType: 'TI',
      },
    },
  },
  'D)3)e)': {
    id: 'D)3)e)',
    name: 'Les logettes :',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)3)e)1/': {
        name: 'Absence de barre au garrot',
        nameRapport: 'Equiper vos logette de barres au garrot',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)e)2/': {
        name: 'Barre au garrot trop basse ou trop haute',
        nameRapport: 'la hauteur recommandée de la barre au garrot est de 105 à 115 cm ',
        description: '105 à 115 cm',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)e)3/': {
        name: 'Couchage inconfortable',
        nameRapport: 'améliorer le confort du couchage',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)e)4/': {
        name: 'Déjections importantes à l’arrière de la logette',
        nameRapport: 'l’arrière de la logette doit être nettoyé 2 fois par jour',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)e)5/': {
        name: 'Entretien de l’arrière des logettes irrégulier',
        nameRapport: 'l’arrière de la logette doit être nettoyé 2 fois par jour',
        description: 'Norme: 1 à 2 fois par jour',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)e)6/': {
        name: 'Logettes trop hautes',
        nameRapport: 'la hauteur des logettes ne doit pas être supérieur à 15 cm',
        description: '> 15 cm',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)e)7/': {
        name: 'Mouvements de lever-coucher difficiles',
        nameRapport: 'logettes inadaptées : mouvements de lever-coucher difficiles',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)e)8/': {
        name: 'Station debout difficile',
        description: 'Les pieds arrières ne reposent pas sur le seuil de la logette',
        nameRapport: 'les logettes sont trop courtes',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)3)e)av': {
    id: 'D)3)e)av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)3)e)av': {
        answerType: 'TI',
      },
    },
  },
  'D)3)f)': {
    id: 'D)3)f)',
    name: 'La désinfection :',
    description: 'La désinfection des locaux contribue à réduire la pression d’infection dans l’environnement des animaux d’élevage :',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)3)f)1/': {
        name: 'Pas de désinfection',
        nameRapport: 'nous vous conseillons fortement de désinfecter vos bâtiments',
        catRapport: 'Recommandations générales',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)f)2/': {
        name: 'Pas de désinfection générale et régulière des bâtiments',
        nameRapport: 'vous devriez effectuer une désinfection générale et régulière des bâtiments au moins une fois par an',
        catRapport: 'Recommandations générales',
        description: 'Norme: au moins une fois par an',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)f)3/': {
        name: 'Pratique incorrecte de la désinfection',
        nameRapport: 'votre pratique de la désinfection est incorrecte : commencer par le plafond, puis les murs et, en dernier, sol',
        catRapport: 'Recommandations générales',
        description: 'Norme: de haut en bas: plafond, murs et, en dernier, sol',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)f)4/': {
        name: 'Pas de désinfection du box de vêlage',
        nameRapport: 'le box de vêlage',
        catRapport: 'Lieux  et matériel à désinfecter tout particulièrement :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)f)5/': {
        name: 'Pas de désinfection de l’infirmerie',
        nameRapport: 'l’infirmerie',
        catRapport: 'Lieux  et matériel à désinfecter tout particulièrement :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)f)6/': {
        name: 'Pas de désinfection des installations de stockage',
        nameRapport: 'les installations de stockage',
        catRapport: 'Lieux  et matériel à désinfecter tout particulièrement :',
        description: 'Silos…',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)f)7/': {
        name: 'Pas de désinfection du local de quarantaine',
        nameRapport: 'le local de quarantaine',
        catRapport: 'Lieux  et matériel à désinfecter tout particulièrement :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)f)8/': {
        name: 'Pas de désinfection du matériel d’élevage',
        nameRapport: 'le matériel d’élevage ',
        catRapport: 'Lieux  et matériel à désinfecter tout particulièrement :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)f)9/': {
        name: 'Pas de désinfection du matériel de transport',
        nameRapport: 'le matériel de transport',
        catRapport: 'Lieux  et matériel à désinfecter tout particulièrement :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)f)10/': {
        name: 'Pas de désinfection de la salle de traite',
        nameRapport: 'la salle de traite',
        catRapport: 'Lieux  et matériel à désinfecter tout particulièrement :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)f)11/': {
        name: 'Pas d’imbibition préalable par l’eau à basse pression pendant 3 à 5 heures',
        nameRapport: 'l’imbibition préalable par l’eau à basse pression pendant 3 à 5 heures',
        catRapport: 'Les étapes recommandées pour une bonne désinfection :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)f)12/': {
        name: 'Pas de décapage',
        nameRapport: 'le décapage à haute pression',
        catRapport: 'Les étapes recommandées pour une bonne désinfection :',
        description: 'Nettoyeur haute pression',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)f)13/': {
        name: 'Pas de lavage préalable à l’aide d’un détergent',
        nameRapport: 'un lavage préalable à l’aide d’un détergent',
        catRapport: 'Les étapes recommandées pour une bonne désinfection :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)f)14/': {
        name: 'Pas de rinçage à l’eau claire',
        nameRapport: 'un rinçage à l’eau claire ',
        catRapport: 'Les étapes recommandées pour une bonne désinfection :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)f)15/': {
        name: 'Pas de vide sanitaire après désinfection',
        nameRapport: 'un vide sanitaire de 2 à 3 semaines après',
        catRapport: 'Les étapes recommandées pour une bonne désinfection :',
        description: 'Norme:2 à 3 semaines',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)f)16/': {
        name: 'Pas de vide sanitaire vrai',
        nameRapport: 'sans désinfection, un vide sanitaire vrai de 3 mois au moins sans animaux après curage complet',
        catRapport: 'Les étapes recommandées pour une bonne désinfection :',
        description: 'Norme: 3 mois au moins sans animaux après curage complet mais sans désinfection',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)f)17/': {
        name: 'Non respect des dosages indiqués',
        nameRapport: 'respecter les dosages indiqués',
        catRapport: 'Les étapes recommandées pour une bonne désinfection :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)f)18/': {
        name: 'Utilisation d’un désinfectant homologué ciblé',
        nameRapport: 'un désinfectant homologué ciblé, en rapport avec le microbisme ambiant',
        catRapport: 'Les désinfectants utilisés dans votre élevage :',
        description: 'En rapport avec le microbisme ambiant',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)f)19/': {
        name: 'Utilisation d’un désinfectant homologué large spectre',
        nameRapport: 'un désinfectant homologué large spectre',
        catRapport: 'Les désinfectants utilisés dans votre élevage :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)f)20/': {
        name: 'Emploi d’ammoniums quaternaires',
        nameRapport: 'des ammoniums quaternaires',
        catRapport: 'Les désinfectants utilisés dans votre élevage :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)f)21/': {
        name: 'Emploi d’amphotères',
        nameRapport: 'des amphotères ',
        catRapport: 'Les désinfectants utilisés dans votre élevage :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)f)22/': {
        name: 'Emploi de chaux ou de soude caustique',
        nameRapport: 'de la chaux (ou soude caustique)',
        catRapport: 'Les désinfectants utilisés dans votre élevage :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)f)23/': {
        name: 'Emploi de produits chlorés',
        nameRapport: 'des produits chlorés (eau de javel)',
        catRapport: 'Les désinfectants utilisés dans votre élevage :',
        description: 'Eau de javel',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)f)24/': {
        name: 'Emploi de chlorhexidine ou de biguanides',
        nameRapport: 'de la chlorhexidine (ou des biguanides)',
        catRapport: 'Les désinfectants utilisés dans votre élevage :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)f)25/': {
        name: 'Emploi de glutaraldéhyde',
        nameRapport: 'du glutaraldéhyde',
        catRapport: 'Les désinfectants utilisés dans votre élevage :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)f)26/': {
        name: 'Emploi de produits iodés',
        nameRapport: 'des produits iodés',
        catRapport: 'Les désinfectants utilisés dans votre élevage :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)f)27/': {
        name: 'Emploi de peroxydes ou d’acide paracétique',
        nameRapport: 'des peroxydes ou de l’acide peracétique',
        catRapport: 'Les désinfectants utilisés dans votre élevage :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)f)28/': {
        name: 'Emploi de phénols de synthèse',
        nameRapport: 'des phénols de synthèse ',
        catRapport: 'Les désinfectants utilisés dans votre élevage :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)f)29/': {
        name: 'Pas de pédiluve',
        nameRapport: 'vous devriez vous équiper d’un pédiluve',
        catRapport: 'Recommandations générales',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)3)f)av': {
    id: 'D)3)f)av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)3)f)av': {
        answerType: 'TI',
      },
    },
  },
  'D)3)g)': {
    id: 'D)3)g)',
    name: 'La désinsectisation, la dératisation et la lutte contre les limaces :',
    description: 'La désinsectisation et la dératisation des locaux contribuent à détruire les vecteurs potentiels d’agents infectieux',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)3)g)1/': {
        name: 'Absence de dératisation',
        nameRapport: 'nous vous conseillons fortement de dératiser vos bâtiments',
        catRapport: 'Recommandations générales :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)g)2/': {
        name: 'Dératisation sans protocole défini',
        nameRapport: 'pour la dératisation, utiliser une protocole défini :ciblage des périodes à risque, choix de l’emplacement des appâts, maintien d’une pression minimale',
        catRapport: 'Recommandations générales :',
        description: 'Ciblage des périodes à risque, choix de l’emplacement des appâts, maintien d’une pression minimale',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)g)3/': {
        name: 'Absence de désinsectisation',
        nameRapport: 'nous vous conseillons fortement de désinsectiser vos bâtiments',
        catRapport: 'Recommandations générales :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)g)4/': {
        name: 'Désinsectisation sans protocole défini',
        nameRapport: 'pour la désinsectisation, utiliser un protocole défini : ciblage des périodes à risque, association de larvicides (produit actif sur les stades larvaires de l’insecte) et d’adulticides (produit actif sur l’insecte adulte) , maintien d’une pression minimale',
        catRapport: 'Recommandations générales :',
        description: 'Ciblage des périodes à risque, association de larvicides (produit actif sur les stades larvaires de l’insecte) et d’adulticides (produit actif sur l’insecte adulte) , maintien d’une pression minimale',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)g)5/': {
        name: 'Désinsectisation biologique',
        catRapport: 'Type de désinsectisation pratiquée et produits utilisés dans votre élevage :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)g)6/': {
        name: 'Désinsectisation chimique',
        catRapport: 'Type de désinsectisation pratiquée et produits utilisés dans votre élevage :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)g)7/': {
        name: 'Pas d’utilisation de larvicides',
        nameRapport: 'utiliser des produits larvicides (produit actif sur les stades larvaires de l’insecte)',
        catRapport: 'Recommandations générales :',
        description: 'Produit actif sur les stades larvaires de l’insecte',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)g)8/': {
        name: 'Pas d’utilisation d’adulticides',
        nameRapport: 'utiliser des produits adulticides (produit actif sur l’insecte adulte)',
        catRapport: 'Recommandations générales :',
        description: 'Produit actif sur l’insecte adulte',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)g)9/': {
        name: 'Emploi de carbamates',
        nameRapport: 'carbamates',
        catRapport: 'Type de désinsectisation pratiquée et produits utilisés dans votre élevage :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)g)10/': {
        name: 'Emploi de métaldéhyde',
        nameRapport: 'métaldéhyde',
        catRapport: 'Type de désinsectisation pratiquée et produits utilisés dans votre élevage :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)g)11/': {
        name: 'Emploi d’organophosphorés',
        nameRapport: 'organophosphorés',
        catRapport: 'Type de désinsectisation pratiquée et produits utilisés dans votre élevage :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)g)12/': {
        name: 'Emploi de pyréthrinoides',
        nameRapport: 'pyréthrinoïdes',
        catRapport: 'Type de désinsectisation pratiquée et produits utilisés dans votre élevage :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)3)g)av': {
    id: 'D)3)g)av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)3)g)av': {
        answerType: 'TI',
      },
    },
  },
  'D)3)h)': {
    id: 'D)3)h)',
    name: 'L’ambiance des bâtiments :',
    description: 'Une bonne ventilation naturelle, voire mécanique si besoin, permet d’éliminer l’humidité, la chaleur et autres substances favorables au développement microbien et nocives pour les animaux :',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)3)h)1/': {
        name: 'Absence de brise vent',
        nameRapport: 'brise vent',
        catRapport: 'Equipements recommandés :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)h)2/': {
        name: 'Absence de «décalages de toiture» en cas de bâtiment trop large',
        nameRapport: 'décalages de toiture en cas de bâtiment trop large',
        catRapport: 'Corrections techniques recommandées :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)h)3/': {
        name: 'Absence d’effet «cheminée»',
        nameRapport: 'effet « cheminée » : normalement l’air se réchauffe et s’élève vers le faîtage du toit',
        catRapport: 'Equipements recommandés :',
        description: 'Normalement l’air se réchauffe et s’élève vers le faîtage du toit',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)h)4/': {
        name: 'Absence ou mauvaise répartition des ouvertures sur les pans et les pignons',
        nameRapport: 'meilleure répartition des ouvertures sur les pans et les pignons',
        catRapport: 'Corrections techniques recommandées :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)h)5/': {
        name: 'Absence de «plafonds filtrants» en cas de volume excessif',
        nameRapport: 'plafonds filtrants en cas de volume excessif ',
        catRapport: 'Equipements recommandés :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)h)6/': {
        name: 'Base des entrées d’air trop basses',
        nameRapport: 'surélever la base des d’air : normalement au moins à 2 m du niveau où sont les animaux, en tenant compte des accumulations de litière',
        catRapport: 'Corrections techniques recommandées :',
        description: 'Normalement au moins à 2 m du niveau où sont les animaux, en tenant compte des accumulations de litière',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)h)7/': {
        name: 'Bâtiment trop large',
        nameRapport: 'bâtiment trop large (> 20 m) ',
        catRapport: 'Erreurs de construction observées :',
        description: '> 20 m',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)h)8/': {
        name: 'Courants d’air',
        catRapport: 'Critères d’ambiance nécessitant une correction :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)h)9/': {
        name: 'Faîtage trop haut',
        nameRapport: 'faîtage trop haut (> 7 m) ',
        catRapport: 'Erreurs de construction observées :',
        description: '> 7 m',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)h)10/': {
        name: 'Forte chaleur',
        catRapport: 'Critères d’ambiance nécessitant une correction :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)h)11/': {
        name: 'Forte humidité',
        catRapport: 'Critères d’ambiance nécessitant une correction :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)h)12/': {
        name: 'Forte odeur d’ammoniac',
        catRapport: 'Critères d’ambiance nécessitant une correction :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)h)13/': {
        name: 'Logement des veaux inadapté',
        nameRapport: 'logement des veaux inadapté (absence d’isolation thermique, humidité, courants d’air, mauvaise litière, froid)',
        catRapport: 'Erreurs de construction observées :',
        description: 'Absence d’isolation thermique, humidité, courants d’air, mauvaise litière, froid',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)h)14/': {
        name: 'Recours à des extracteurs thermiques',
        nameRapport: 'extracteurs thermiques',
        catRapport: 'Critères d’ambiance nécessitant une correction :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)h)15/': {
        name: 'Mauvais réglage des extracteurs thermiques',
        catRapport: 'Critères d’ambiance nécessitant une correction :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)h)16/': {
        name: 'Surface de bardage importante',
        catRapport: 'Erreurs de construction observées :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)h)17/': {
        name: 'Surface de toiture importante',
        catRapport: 'Erreurs de construction observées :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)h)18/': {
        name: 'Ventilation non modifiée en période estivale',
        catRapport: 'Critères d’ambiance nécessitant une correction :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)h)19/': {
        name: 'Volume excessif',
        catRapport: 'Erreurs de construction observées :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)3)h)av': {
    id: 'D)3)h)av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)3)h)av': {
        answerType: 'TI',
      },
    },
  },
  'D)3)i)': {
    id: 'D)3)i)',
    titre3: 'La protection sanitaire mise en œuvre :',
    nameRapport: 'Recommandations',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)3)i)1.': {
    id: 'D)3)i)1.',
    name: 'Pédiluve :',
    hideRapport: true,
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)3)i)1.1/': {
        name: 'Oui',
        hideRapport: true,
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)i)1.2/': {
        name: 'Non',
        nameRapport: 'l’installation d’un pédiluve est fortement recommandée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)3)i)2.': {
    id: 'D)3)i)2.',
    name: 'Bac d’équarrissage :',
    hideRapport: true,
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)3)i)2.1/': {
        name: 'Oui',
        hideRapport: true,
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)i)2.2/': {
        name: 'Non',
        nameRapport: 'l’installation d’un bac d’équarrissage est fortement recommandée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)3)i)3.': {
    id: 'D)3)i)3.',
    name: 'Nettoyage du bâtiment :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)3)i)3.1/': {
        name: 'éleveur',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)i)3.2/': {
        name: 'Entreprise',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)3)i)4.': {
    id: 'D)3)i)4.',
    name: 'Désinfection :',
    hideRapport: true,
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)3)i)4.1/': {
        name: 'Oui',
        description: '',
        hideRapport: true,
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)i)4.2/': {
        name: 'Non',
        nameRapport: 'nous vous conseillons de mettre en place un protocole de désinfection',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)3)i)5.': {
    id: 'D)3)i)5.',
    name: 'Désinsectisation :',
    hideRapport: true,
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)3)i)5.1/': {
        name: 'Oui',
        description: '',
        hideRapport: true,
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)i)5.2/': {
        name: 'Non',
        nameRapport: 'nous vous conseillons de mettre en place un protocole de désinsectisation',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)3)i)6.': {
    id: 'D)3)i)6.',
    name: 'Dératisation :',
    hideRapport: true,
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)3)i)6.1/': {
        name: 'Oui',
        hideRapport: true,
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)i)6.2/': {
        name: 'Non',
        nameRapport: 'nous vous conseillons de mettre en place un protocole de dératisation',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)3)i)7.': {
    id: 'D)3)i)7.',
    name: 'Nettoyage du matériel de préparation et distribution des aliments :',
    hideRapport: true,
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)3)i)7.1/': {
        name: 'Oui',
        hideRapport: true,
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)i)7.2/': {
        name: 'Non',
        nameRapport: 'le matériel de préparation et distribution des aliments doit être régulièrement nettoyé',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)3)i)8.': {
    id: 'D)3)i)8.',
    name: 'Produits utilisés :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)3)i)8.1/': {
        name: 'Utilisation d’un désinfectant homologué ciblé',
        nameRapport: 'désinfectant homologué ciblé (en rapport avec le microbisme ambiant)',
        description: 'En rapport avec le microbisme ambiant',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)i)8.2/': {
        name: 'Utilisation d’un désinfectant homologué large spectre',
        nameRapport: 'désinfectant homologué large spectre',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)i)8.3/': {
        name: 'Emploi d’ammoniums quaternaires',
        nameRapport: 'ammoniums quaternaires ',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)i)8.4/': {
        name: 'Emploi d’amphotères',
        nameRapport: 'amphotères',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)i)8.5/': {
        name: 'Emploi de chaux ou de soude caustique',
        nameRapport: 'chaux ou soude caustique',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)i)8.6/': {
        name: 'Emploi de produits chlorés',
        nameRapport: 'produits chlorés (eau de javel)',
        description: 'Eau de javel',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)i)8.7/': {
        name: 'Emploi de chlorhexidine ou de biguanides',
        nameRapport: 'chlorhexidine ou biguanides',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)i)8.8/': {
        name: 'Emploi de glutaraldéhyde',
        nameRapport: 'glutaraldéhyde',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)i)8.9/': {
        name: 'Emploi de produits iodés',
        nameRapport: 'produits iodés',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)i)8.10/': {
        name: 'Emploi de peroxydes ou d’acide paracétique',
        nameRapport: 'peroxydes ou acide peracétique',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)i)8.11/': {
        name: 'Emploi de phénols de synthèse',
        nameRapport: 'phénols de synthèse',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)i)8.12/': {
        name: 'Pas de pédiluve',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)3)i)9.': {
    id: 'D)3)i)9.',
    name: 'Fréquence du nettoyage :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)3)i)9.1/': {
        name: 'Plusieurs fois en cours de bande',
        hideRapport: true,
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)i)9.2/': {
        name: 'Entre chaque bande uniquement',
        nameRapport: 'le nettoyage peut s’avérer utile en cours de bande si nécessaire',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)3)i)9.3/': {
        name: 'Irrégulier',
        nameRapport: 'le nettoyage doit être régulier, entre chaque bande, et si nécessaire en cours de bande',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)3)i)9.av': {
    id: 'D)3)i)9.av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)3)i)9.av': {
        answerType: 'TI',
      },
    },
  },
  'D)3)av#': {
    id: 'D)3)av#',
    name: 'L\'avis du véto (l’hygiène et l’ambiance des bâtiments)',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)3)av#': {
        answerType: 'TI',
      },
    },
  },
  'D)4)': {
    id: 'D)4)',
    titre2: 'Gestion du pâturage et parasitisme :',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)4)d)': {
    id: 'D)4)d)',
    name: 'Le mode d\'élevage :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)4)d)1/': {
        name: 'Zéro pâturage',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)d)2/': {
        name: 'Semi plein air',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)d)3/': {
        name: 'Plein air',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)d)4/': {
        name: 'Transhumance',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)d)5/': {
        name: 'Itinérant',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)4)a)': {
    id: 'D)4)a)',
    name: 'La gestion du pâturage :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)4)a)1/': {
        name: 'Absence de transition alimentaire',
        nameRapport: 'à chaque changement de régime alimentaire, une transition de 2 à 3 semaines est obligatoire',
        description: 'à chaque changement de régime alimentaire, une transition de 2 à 3 semaines est obligatoire',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)a)2/': {
        name: 'Absence de pâturage d’automne',
        nameRapport: 'il peut être intéressant d’envisager un pâturage d’automne',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)a)3/': {
        name: 'Hauteur d’herbe en entrée en pâture > 13 cm',
        nameRapport: 'l’herbe en entrée en pâture doit être inférieur à 13 cm',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)a)4/': {
        name: 'Hauteur d’herbe à la sortie de pâture < 5 cm',
        nameRapport: 'l’herbe à la sortie de pâture doit être supérieur à 5 cm',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)a)5/': {
        name: 'Mise à l’herbe brutale',
        nameRapport: 'la mise à l’herbe ne doit pas être brutale',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)a)6/': {
        name: 'Non distribution de magnésium quelques jours avant la mise à l’herbe',
        nameRapport: 'nous vous conseillons de distribuer du magnésium quelques jours avant la mise à l’herbe : minéral riche en magnésium (8 %) ou 50 gr de chlorure de magnésium par jour et par animal',
        description: 'Minéral riche en magnésium (8 %) ou 50 gr de chlorure de magnésium par jour et par animal',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)a)7/': {
        name: 'Non prise en compte de la période de la journée pour la sortie des vaches',
        nameRapport: 'sorter vos vaches plutôt l’après-midi en début de pâture, plutôt le matin après la traite en automne',
        description: 'Plutôt l’après midi en début de pâture, plutôt le matin après la traite en automne',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)a)8/': {
        name: 'Non respect d’un repos minimum de la prairie en automne',
        nameRapport: 'respecter un repos minimum de la prairie de un à deux mois en automne',
        description: 'Un à deux mois',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)a)9/': {
        name: 'Pâturage libre avec certaines zones sur pâturées, d’autres délaissées',
        nameRapport: 'attention au déséquilibre entre les zones sur pâturées et les zones délaissées',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)a)10/': {
        name: 'Pâturage au fil et main d’œuvre limitée',
        nameRapport: 'le pâturage au fil nécessite une main d’œuvre suffisante',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)a)11/': {
        name: 'Séjour par parcelle supérieur à 5 jours',
        nameRapport: 'éviter des séjours par parcelle supérieurs à 5 jours',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)a)12/': {
        name: 'Retour sur parcelle inférieur à 20 jours',
        nameRapport: 'éviter des retour sur parcelle inférieurs à 20 jours',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)a)13/': {
        name: 'Retour sur parcelle supérieur à 25 jours',
        nameRapport: 'le retour sur parcelle devrait être inférieur à 25 jours',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)a)14/': {
        name: 'Taille des parcelles inadaptée par rapport au climat et au sol',
        nameRapport: 'la taille des parcelles est inadaptée par rapport au climat et au sol',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)a)15/': {
        name: 'Taille des parcelles inadaptée par rapport à la charge animale',
        nameRapport: 'la taille des parcelles est inadaptée par rapport à la charge animale (20 ares environ par UGB au printemps, 40 en été)',
        description: '20 ares environ par UGB au printemps, 40 en été',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)4)a)av': {
    id: 'D)4)a)av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)4)a)av': {
        answerType: 'TI',
      },
    },
  },
  'D)4)b)': {
    id: 'D)4)b)',
    titre3: 'Les herbicides et les engrais :',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)4)b)1.': {
    id: 'D)4)b)1.',
    name: 'Les herbicides :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)4)b)1.1/': {
        name: 'Emploi de glyphosate',
        nameRapport: 'glyphosate',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)b)1.2/': {
        name: 'Emploi de Paraquat',
        nameRapport: 'Paraquat',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)b)1.3/': {
        name: 'Emploi de Trichlopyr',
        nameRapport: 'Trichlopyr',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)4)b)2.': {
    id: 'D)4)b)2.',
    name: 'Les engrais :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)4)b)2.1/': {
        name: 'Emploi de nitrates',
        nameRapport: 'nitrates   (> 250g / bovin de 500 kg) ',
        description: '> 250g / bovin de 500 kg',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)b)2.2/': {
        name: 'Emploi d’urée',
        nameRapport: 'urée (> 500-750g / bovin de 500 kg)',
        description: '> 500-750g / bovin de 500 kg',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)4)b)av': {
    id: 'D)4)b)av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)4)b)av': {
        answerType: 'TI',
      },
    },
  },
  'D)4)c)': {
    id: 'D)4)c)',
    name: 'Le parasitisme :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)4)c)1/': {
        name: 'Absence d’analyses coprologiques',
        nameRapport: 'nous vous conseillons de réaliser une analyse coprologique avant chaque traitement',
        catRapport: 'Les recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)c)2/': {
        name: 'Absence de changement systématique de pâturage après déparasitage',
        nameRapport: 'vous devriez changer systématiquement de pâturage après le déparasitage',
        catRapport: 'Les recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)c)3/': {
        name: 'Absence de déparasitage du troupeau',
        nameRapport: 'nous vous conseillons de déparasiter votre troupeau après analyse coprologique positive',
        catRapport: 'Les recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)c)4/': {
        name: 'Absence de déparasitage des veaux début septembre en troupeau allaitant',
        nameRapport: 'nous vous conseillons de vermifuger vos veaux à l\'entrée de l\'automne ',
        catRapport: 'Les recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)c)5/': {
        name: 'Absence d’un protocole de lutte contre le parasitisme',
        nameRapport: 'la lutte contre le parasitisme doit s’appuyer sur un protocole adapté',
        catRapport: 'Les recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)c)6/': {
        name: 'Choix des antiparasitaires aléatoire',
        nameRapport: 'le choix des antiparasitaires ne doit pas être aléatoire',
        catRapport: 'Les recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)c)7/': {
        name: 'Cohabitation d’animaux traités et non traités',
        nameRapport: 'éviter la cohabitation d’animaux traités et non traités',
        catRapport: 'Les recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)c)8/': {
        name: 'Cohabitation en troupeau laitier des jeunes et des adultes sur la même pâture',
        nameRapport: 'éviter la cohabitation en troupeau laitier des jeunes et des adultes sur la même pâture',
        catRapport: 'Les recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)c)9/': {
        name: 'Cohabitation en troupeau laitier des jeunes de l’année et des jeunes de 1 à 3 ans',
        nameRapport: 'éviter la cohabitation en troupeau laitier des jeunes de l’année et des jeunes de 1 à 3 ans',
        catRapport: 'Les recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)c)10/': {
        name: 'La priorité en troupeau laitier n’est pas donnée aux jeunes de l’année pour les pâtures saines',
        nameRapport: 'la priorité en troupeau laitier doit être donnée aux jeunes de l’année pour les pâtures saines',
        catRapport: 'Les recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)c)11/': {
        name: 'Les bovins d’une même parcelle ne sont pas tous traités en même temps',
        nameRapport: 'les bovins d’une même parcelle doivent tous être traités en même temps',
        catRapport: 'Les recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)c)12/': {
        name: 'Déparasitage irrégulier, pas tous les ans',
        nameRapport: 'les analyses coprologiques doivent être réalisées au moins tous les ans',
        catRapport: 'Les recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)c)13/': {
        name: 'Déparasitage qu’une seule fois par an',
        nameRapport: 'les analyses coprologiques doivent être réalisées avant chaque période à risque',
        catRapport: 'Les recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)c)14/': {
        name: 'Déparasitage systématique',
        nameRapport: 'le déparasitage ne doit pas être systématique, mais fonction des résultats des analyses coprologiques',
        catRapport: 'Les recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)c)15/': {
        name: 'Les bâtiments ne sont jamais déparasités',
        nameRapport: 'les bâtiments devraient être régulièrement déparasités',
        catRapport: 'Les recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)c)16/': {
        name: 'Non respect stricte des dosages des antiparasitaires',
        nameRapport: 'vous devez respecter strictement le dosage des antiparasitaires',
        catRapport: 'Les recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)c)17/': {
        name: 'Pulvérisation ou bains',
        catRapport: 'Vos pratiques :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)c)18/': {
        name: 'Recherche coprologique de la grande douve entre avril et septembre',
        nameRapport: 'recherche coprologique de la grande douve entre avril et septembre',
        catRapport: 'Vos pratiques :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)c)19/': {
        name: 'Utilisation d’antiparasitaires à action immédiate',
        nameRapport: 'utilisation d’antiparasitaires à action immédiate (durée d’action du produit: 40 à 72 heures, réinfestation après traitement si maintien sur pâturages contaminés)',
        catRapport: 'Vos pratiques :',
        description: 'Durée d’action du produit: 40 à 72 heures, réinfestation après traitement si maintien sur pâturages contaminés',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)c)20/': {
        name: 'Utilisation d’antiparasitaires à action rémanente',
        nameRapport: 'utilisation d’antiparasitaires à action rémanente (durée d’action prolongée dans le temps)',
        catRapport: 'Vos pratiques :',
        description: 'Durée d’action prolongée dans le temps',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)c)21/': {
        name: 'Utilisation d’antiparasitaires longue action',
        nameRapport: 'utilisation d’antiparasitaires longue action (types bolus ou injection dans le coussinet de l’oreille, durée d’action supérieure à 3 mois)',
        catRapport: 'Vos pratiques :',
        description: 'Types bolus ou injection dans le coussinet de l’oreille, durée d’action supérieure à 3 mois',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)4)c)22/': {
        name: 'Utilisation d’antiparasitaires pour on',
        nameRapport: 'utilisation d’antiparasitaires pour on (application externe sur la ligne du dos)',
        catRapport: 'Vos pratiques :',
        description: 'Application externe sur la ligne du dos',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)4)c)av': {
    id: 'D)4)c)av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)4)c)av': {
        answerType: 'TI',
      },
    },
  },
  'D)4)av#': {
    id: 'D)4)av#',
    name: 'L\'avis du véto (gestion du pâturage et parasitisme)',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)4)av#': {
        answerType: 'TI',
      },
    },
  },
  'D)5)': {
    id: 'D)5)',
    titre2: 'L’alimentation du troupeau :',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)5)a)': {
    id: 'D)5)a)',
    titre3: 'Le troupeau adulte :',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)5)a)1.': {
    id: 'D)5)a)1.',
    name: 'Valeur des aliments et conservation :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)5)a)1.1/': {
        name: 'Absence d’analyses de fourrage',
        nameRapport: 'effectuer régulièrement une analyse du fourrage',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)1.2/': {
        name: 'Absence d’information technique sur la valeur nutritive estimée d’un aliment du commerce',
        nameRapport: 'la valeur nutritive estimée d’un aliment du commerce doit être connue ',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)1.3/': {
        name: 'Absence de recours à des références de valeurs alimentaires',
        nameRapport: 'pour une meilleure gestion des rations, n’hésitez pas à recourir à des références de valeurs alimentaires (valeurs régionales, tables INRA',
        catRapport: 'Recommandations :',
        description: 'Valeurs régionales, tables INRA',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)1.4/': {
        name: 'Analyses de fourrage non systématiques',
        nameRapport: 'effectuer régulièrement une analyse du fourrage',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)1.5/': {
        name: 'Méconnaissance de la valeur nutritive des concentrés',
        nameRapport: 'la valeur nutritive des concentrés doit être connue',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)1.6/': {
        name: 'Méconnaissance de la valeur nutritive des fourrages',
        nameRapport: 'la valeur nutritive des fourrages doit être connue',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)1.7/': {
        name: 'Sur fourrage humide à moins de 40% de MS, pH > 4,2 ou odeur persistante sur la main au delà de 3',
        nameRapport: 'sur fourrage humide à moins de 40% de MS, pH > 4,2 ou odeur persistante sur la main au-delà de 3 minutes : le fourrage humide est mal conservé',
        catRapport: 'Mises en garde :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)1.8/': {
        name: 'Sur un fourrage sec, échauffement, ou moisissure, ou piqué, ou mauvaise odeur',
        nameRapport: 'sur un fourrage sec, échauffement, ou moisissure, ou piqué, ou mauvaise odeur : le fourrage sec est mal conservé',
        catRapport: 'Mises en garde :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)1.9/': {
        name: 'échauffement, ou moisissure, ou piqué, ou mauvaise odeur de l\'aliment',
        nameRapport: 'sur l\'aliment : échauffement, ou moisissure, ou mauvaise odeur : l\'aliment est mal conservé',
        catRapport: 'Mises en garde :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)5)a)1.av': {
    id: 'D)5)a)1.av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)5)a)1.av': {
        answerType: 'TI',
      },
    },
  },
  'D)5)a)2.': {
    id: 'D)5)a)2.',
    name: 'équilibre de la ration :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)5)a)2.1/': {
        name: 'Absence d’un plan d’alimentation',
        nameRapport: 'vous devriez vous appuyer sur un plan d’alimentation précis',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)2.2/': {
        name: 'Absence de révision du plan d’alimentation en situation d’urgence',
        nameRapport: 'en situation d’urgence (ex : pénurie de fourrage) le plan d’alimentation doit être révisé',
        catRapport: 'Recommandations :',
        description: 'Ex: pénurie de fourrage',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)2.3/': {
        name: 'Ration effectuée par l’éleveur',
        catRapport: 'Vos pratiques :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)2.4/': {
        name: 'Ration effectuée par un technicien',
        catRapport: 'Vos pratiques :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)2.5/': {
        name: 'Ration non adaptée pour chaque stade physiologique',
        catRapport: 'Les erreurs de la ration :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)2.6/': {
        name: 'Excès d’énergie',
        catRapport: 'Les erreurs de la ration :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)2.7/': {
        name: 'Excès de protéines',
        catRapport: 'Les erreurs de la ration :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)2.8/': {
        name: 'Excès en minéraux ou vitamines',
        catRapport: 'Les erreurs de la ration :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)2.9/': {
        name: 'Carence en énergie',
        catRapport: 'Les erreurs de la ration :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)2.10/': {
        name: 'Carence en protéines',
        catRapport: 'Les erreurs de la ration :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)2.11/': {
        name: 'Carence en minéraux ou vitamines',
        catRapport: 'Les erreurs de la ration :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)5)a)2.av': {
    id: 'D)5)a)2.av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)5)a)2.av': {
        answerType: 'TI',
      },
    },
  },
  'D)5)a)3.': {
    id: 'D)5)a)3.',
    name: 'Les fibres :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)5)a)3.1/': {
        name: 'Fourrage trop finement coupés',
        nameRapport: 'le fourrage devrait être coupé plus grossièrement',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)3.2/': {
        name: 'Fourrage moulus et laminés',
        nameRapport: 'votre fourrage moulus et laminés ne répond pas aux besoins en fibres du troupeau',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)3.3/': {
        name: 'Faible consommation de fibres',
        nameRapport: 'vous devez impérativement favoriser la consommation de fibres',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)3.4/': {
        name: 'Trop peu de fourrage dans la ration',
        nameRapport: 'augmenter le fourrage dans la ration',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)5)a)3.av': {
    id: 'D)5)a)3.av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)5)a)3.av': {
        answerType: 'TI',
      },
    },
  },
  'D)5)a)4.': {
    id: 'D)5)a)4.',
    name: 'L’utilisation de tampons (bicarbonates, levures, etc.):',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)5)a)4.1/': {
        name: 'En continu',
        nameRapport: 'les tampons ne devraient pas être utilisés en continu, mais plutôt sur des périodes ciblées',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)4.2/': {
        name: 'Jamais',
        nameRapport: 'l’utilisation de tampons est une assurance contre les risques d’acidose, vous devriez les utiliser sur des périodes ciblées',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)4.3/': {
        name: 'Périodes ciblées',
        nameRapport: 'vous utilisez correctement les tampons sur des périodes ciblées',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)4.4/': {
        name: 'Parfois, aléatoire',
        nameRapport: 'l’utilisation de tampons est une assurance contre les risques d’acidose, vous devriez les utiliser plus régulièrement sur des périodes ciblées',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)5)a)4.av': {
    id: 'D)5)a)4.av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)5)a)4.av': {
        answerType: 'TI',
      },
    },
  },
  'D)5)a)5.': {
    id: 'D)5)a)5.',
    titre4: 'Le régime nutritionnel :',
    description: 'Pour chaque catégorie précisez le niveau des apports. Les apports recommandés proposés sont exprimés en g/kg de MSI de la ration totale pour les minéraux et les oligo-éléments, en UI/kg de MS de la ration totale pour les vitamines): ',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)5)a)5.a)': {
    id: 'D)5)a)5.a)',
    name: 'Minéraux (Ca/P):',
    description: '(laitière gestante: Ca = 4, P = 2,5, laitière en lactation: Ca = 7, P = 4, allaitante: Ca = 7, P = 4,5)',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)5)a)5.a)1/': {
        name: 'Faible ou absent',
        nameRapport: 'carence',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)5.a)2/': {
        name: 'Important',
        nameRapport: 'excès',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)5)a)5.b)': {
    id: 'D)5)a)5.b)',
    name: 'Sel :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)5)a)5.b)1/': {
        name: 'Faible ou absent',
        nameRapport: 'carence',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)5.b)2/': {
        name: 'Important',
        nameRapport: 'excès',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)5)a)5.c)': {
    id: 'D)5)a)5.c)',
    name: 'Oligo-éléments :',
    description: '(7< Cu < 30, 0,07 < Co < 10, 0,15 < I < 8, 45 < Mn < 1000, 45 < Zn < 250, 0,1 < Se < 0,5, Mo < 3)',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)5)a)5.c)1/': {
        name: 'Faible ou absent',
        nameRapport: 'carence',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)5.c)2/': {
        name: 'Important',
        nameRapport: 'excès',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)5)a)5.d)': {
    id: 'D)5)a)5.d)',
    name: 'Vitamines :',
    description: '(adulte: Vit A = 4000, Vit D = 1000, Vit E = 15, veau allaitement: Vit A = 3800, Vit D = 600, Vit E = 40, veau concentré: Vit A = 2200, Vit D = 300, Vit E = 25)',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)5)a)5.d)1/': {
        name: 'Faible ou absent',
        nameRapport: 'carence',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)5.d)2/': {
        name: 'Important',
        nameRapport: 'excès',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)5)a)5.av': {
    id: 'D)5)a)5.av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)5)a)5.av': {
        answerType: 'TI',
      },
    },
  },
  'D)5)a)6.': {
    id: 'D)5)a)6.',
    name: 'La distribution :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)5)a)6.1/': {
        name: 'L’auge peut rester vide plusieurs heures',
        nameRapport: 'veiller à ce que l’auge contienne toujours de l’aliment',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)6.2/': {
        name: 'Concentré en proportion élevée',
        nameRapport: 'éviter de donner trop de concentré',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)6.3/': {
        name: 'Une seule distribution par jour',
        nameRapport: 'préférer une distribution matin et soir, après chaque traite',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)6.4/': {
        name: 'Les animaux ne peuvent pas tous s’alimenter en même temps',
        nameRapport: 'les animaux doivent pouvoir tous s’alimenter en même temps',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)6.5/': {
        name: 'La température de la ration augmente sensiblement en cours de journée',
        nameRapport: 'une température de la ration qui augmente en cours de journée est un signe de mauvaise conservation',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)6.6/': {
        name: 'Odeur piquante, désagréable de la ration quelques heures après distribution',
        nameRapport: 'une odeur piquante, désagréable de la ration quelques heures après distribution est un signe de mauvaise conservation',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)5)a)6.av': {
    id: 'D)5)a)6.av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)5)a)6.av': {
        answerType: 'TI',
      },
    },
  },
  'D)5)a)7.': {
    id: 'D)5)a)7.',
    name: 'L’état d’engraissement :',
    description: 'Le suivi de l’évolution de l’état d’engraissement permet d’ajuster le niveau d’alimentation aux besoins du troupeau.',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)5)a)7.1/': {
        name: 'Engraissement important au tarissement',
        nameRapport: 'vaches taries trop grasses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)7.2/': {
        name: 'Amaigrissement au tarissement',
        nameRapport: 'vaches taries trop maigres',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)7.3/': {
        name: 'Vache en état au vêlage',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)7.4/': {
        name: 'Vache grasse au vêlage',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)7.5/': {
        name: 'Vache maigre en milieu ou fin de lactation',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)5)a)7.av': {
    id: 'D)5)a)7.av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)5)a)7.av': {
        answerType: 'TI',
      },
    },
  },
  'D)5)a)8.': {
    id: 'D)5)a)8.',
    titre4: 'Les erreurs d’alimentation :',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)5)a)8.a)': {
    id: 'D)5)a)8.a)',
    name: 'Ensilage de mais :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)5)a)8.a)1/': {
        name: 'Ensilage trop fin au silo ou après désilage',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)8.a)2/': {
        name: 'Ensilage trop grossier',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)8.a)3/': {
        name: 'Ensilage laminé, moulu, défibré par le mélange',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)8.a)4/': {
        name: 'Ration riche en concentré (ou en pulpe de betterave)',
        description: '> 30 % de la MS',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)5)a)8.b)': {
    id: 'D)5)a)8.b)',
    name: 'Ensilage d’herbe - foins :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)5)a)8.b)1/': {
        name: 'Ensilage, enrubannage, foins précoces et regains',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)8.b)2/': {
        name: 'Ration herbagère avec plus de 2/3 de regain',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)8.b)3/': {
        name: 'Ration riche en concentrés',
        description: '> 35 % de la MS',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)5)a)8.c)': {
    id: 'D)5)a)8.c)',
    name: 'Toutes rations :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)5)a)8.c)1/': {
        name: 'Absence de transition fourragère',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)8.c)2/': {
        name: 'Céréales ou protéagineux broyés, aplatis, laminés trop finement',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)8.c)3/': {
        name: 'Distribution de concentré à plus de 3,5 kg par repas',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)8.c)4/': {
        name: 'Ration avec plus de 5 kg de betteraves',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)8.c)5/': {
        name: 'Ration avec plus de 6 kg de blé, orge ou triticale',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)8.c)6/': {
        name: 'Ration à teneur en amidon > 30 % de la MS',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)8.c)7/': {
        name: 'Ration à teneur en cellulose brute > 17 % de la MS',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)8.c)8/': {
        name: 'Ration fourragère distribuée de façon restreinte (< 80 % de la satiété)',
        description: 'état d’un animal complètement rassasié',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)5)a)8.av': {
    id: 'D)5)a)8.av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)5)a)8.av': {
        answerType: 'TI',
      },
    },
  },
  'D)5)a)9.': {
    id: 'D)5)a)9.',
    titre4: 'Les principales intoxications :',
    description: 'Vous devez cocher les produits proposés lorsqu’une interaction semble plausible ou a été plausible à un certain moment entre le produit cité et l’animal ou précisé par une recherche de laboratoire.',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)5)a)9.a)': {
    id: 'D)5)a)9.a)',
    name: 'Le plomb :',
    nameRapport: 'Eviter toute interactions entre votre troupeau et les produits suivants :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)5)a)9.a)1/': {
        name: 'Batterie',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)9.a)2/': {
        name: 'Peinture antirouille',
        description: 'Minium',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)9.a)3/': {
        name: 'Peinture au plomb',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)9.a)4/': {
        name: 'Plomb solubilisé dans un aliment acide',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)5)a)9.b)': {
    id: 'D)5)a)9.b)',
    name: 'Les hydrocarbures :',
    hideRapport: true,
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)5)a)9.b)1/': {
        name: 'Huile de vidange',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)9.b)2/': {
        name: 'Gasoil',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)9.b)3/': {
        name: 'Mazout',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)5)a)9.c)': {
    id: 'D)5)a)9.c)',
    name: 'Les médicaments :',
    nameRapport: 'Veiller à bien utiliser les médicaments suivants :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)5)a)9.c)1/': {
        name: 'Calcium',
        nameRapport: 'éviter les doses trop rapprochées de calcium',
        description: 'Doses trop rapprochées',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)9.c)2/': {
        name: 'Endectocides',
        nameRapport: 'les endectocides ne doivent pas être surdosés sur des animaux trop jeunes',
        description: 'Surdosage sur des animaux trop jeunes',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)9.c)3/': {
        name: 'Lévamisole',
        nameRapport: 'attention au surdosage du lévamisole ',
        description: '> double dose',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)9.c)4/': {
        name: 'Pénicilline',
        nameRapport: 'éviter les pénicillines en cas d’allergie',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)9.c)5/': {
        name: 'Sélénium',
        nameRapport: 'ne pas surdoser le sélénium',
        description: 'Surdosage',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)9.c)6/': {
        name: 'Vitamine E',
        nameRapport: 'la vitamine E doit être utilisée aux doses prescrites',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)5)a)9.d)': {
    id: 'D)5)a)9.d)',
    titre4: 'Les mycotoxines :',
    titre4Rapport: 'Contamination par les mycotoxines :',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)5)a)9.d)1.': {
    id: 'D)5)a)9.d)1.',
    name: 'Aliments susceptibles d’être contaminés :',
    nameRapport: 'Surveiller tout particulièrement les aliments suivants :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)5)a)9.d)1.1/': {
        name: 'Avoine',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)9.d)1.2/': {
        name: 'Blé',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)9.d)1.3/': {
        name: 'Coton',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)9.d)1.4/': {
        name: 'Fétuques',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)9.d)1.5/': {
        name: 'Graminées prairiales',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)9.d)1.6/': {
        name: 'Mais',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)9.d)1.7/': {
        name: 'Orge',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)9.d)1.8/': {
        name: 'Ray-grass anglais',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)9.d)1.9/': {
        name: 'Seigle',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)9.d)1.10/': {
        name: 'Soja',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)9.d)1.11/': {
        name: 'Sorgho',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)9.d)1.12/': {
        name: 'Tourteau d’arachide',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)9.d)1.13/': {
        name: 'Triticales',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)5)a)9.d)2.': {
    id: 'D)5)a)9.d)2.',
    name: 'Mycotoxines suspectées :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)5)a)9.d)2.1/': {
        name: 'Aflatoxine',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)9.d)2.2/': {
        name: 'Ergot',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)9.d)2.3/': {
        name: 'Néotyphodium',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)9.d)2.4/': {
        name: 'Zéaralénome',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)5)a)9.e)': {
    id: 'D)5)a)9.e)',
    name: 'Les intoxications végétales au pré:',
    nameRapport: 'Au pré, vos animaux peuvent s’intoxiquer en ingérant les plantes suivantes :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)5)a)9.e)1/': {
        name: 'Cerise',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)9.e)2/': {
        name: 'Colchique',
        description: 'Souvent au mois de juin',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)9.e)3/': {
        name: 'Fougère aigle',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)9.e)4/': {
        name: 'Glands',
        description: 'Ingestion répétée en période de disette',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)9.e)5/': {
        name: 'If',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)9.e)6/': {
        name: 'Laurier',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)9.e)7/': {
        name: 'Laurier rose',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)9.e)8/': {
        name: 'Mercuriale',
        description: 'Pâture mal entretenue',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)9.e)9/': {
        name: 'Oenanthe',
        description: 'Ingestion de la partie souterraine après curage des fossés ou terrassement',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)9.e)10/': {
        name: 'Thuya',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)5)a)9.f)': {
    id: 'D)5)a)9.f)',
    name: 'Les intoxications végétales par les fourrages :',
    nameRapport: 'Eviter la présence de plantes toxiques dans le fourrage ou l’ensilage :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)5)a)9.f)1/': {
        name: 'Amarante',
        description: 'Dans le foin ou l’ensilage de mais',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)9.f)2/': {
        name: 'Colchique',
        description: 'Dans le foin',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)9.f)3/': {
        name: 'Datura',
        description: 'Dans le foin ou l’ensilage de mais',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)9.f)4/': {
        name: 'Galéga',
        description: 'Dans le foin',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)9.f)5/': {
        name: 'Mercuriale',
        description: 'Dans le foin ou l’ensilage de mais',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)a)9.f)6/': {
        name: 'Morelle noire',
        description: 'Dans le foin ou l’ensilage de mais',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)5)a)9.av': {
    id: 'D)5)a)9.av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)5)a)9.av': {
        answerType: 'TI',
      },
    },
  },
  'D)5)b)': {
    id: 'D)5)b)',
    titre3: 'L’alimentation des veaux :',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)5)b)1.': {
    id: 'D)5)b)1.',
    name: 'Le colostrum :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)5)b)1.1/': {
        name: 'Absence de colostrum',
        nameRapport: 'veiller à ce que le veau prenne impérativement du colostrum dans les heures qui suivent sa naissance',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)1.2/': {
        name: 'Absence de prise du colostrum dans les 3 premières heures de vie',
        nameRapport: 'le colostrum doit être ingéré dans les 3 premières heures de vie',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)1.3/': {
        name: 'Consommation nettement inférieure à 2 l de colostrum dans les 3 premières heures de vie',
        nameRapport: '2 l de colostrum au moins doivent être ingérés dans les 3 premières heures de vie ',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)1.4/': {
        name: 'Consommation nettement inférieure à 4 à 5 l de colostrum dans les 24 premières heures de vie',
        nameRapport: '4 à 5 l de colostrum au moins doivent être ingérés dans les 24 premières heures de vie',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)1.5/': {
        name: 'Utilisation d’un pèse-colostrum',
        nameRapport: 'nous vous conseillons d’utiliser un pèse-colostrum',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)1.6/': {
        name: 'Colostrum de mauvaise qualité',
        nameRapport: 'la qualité du colostrum se mesure à l’aide d’un pèse-colostrum',
        catRapport: 'Recommandations :',
        description: 'La qualité du colostrum se mesure à l’aide d’un pèse-colostrum',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)1.7/': {
        name: 'Recours au colostrum congelé',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)1.8/': {
        name: 'Mauvaise décongélation du colostrum',
        nameRapport: 'la décongélation du colostrum doit être réalisée au bain-marie à 40°c',
        catRapport: 'Recommandations :',
        description: 'Elle doit être réalisée au bain-marie à 40°c',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)1.9/': {
        name: 'Recours au colostrum artificiel du commerce',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)5)b)1.av': {
    id: 'D)5)b)1.av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)5)b)1.av': {
        answerType: 'TI',
      },
    },
  },
  'D)5)b)2.': {
    id: 'D)5)b)2.',
    name: 'Le lait :',
    description: 'Il n’existe pas de méthode universelle, par contre chaque pratique possède son mode d’emploi qui doit être respecté scrupuleusement. Les veaux sont en effet très sensibles aux conditions d’alimentation et à leurs variations :',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)5)b)2.1/': {
        name: 'Absence de corrélation entre le rythme de distribution et le volume distribué',
        nameRapport: 'le rythme de distribution doit être corrélé au volume distribué',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)2.2/': {
        name: 'Absence de prise en compte de la teneur en matières grasses pour le volume de lait entier distribué',
        nameRapport: 'la teneur en matières grasses détermine le volume de lait entier distribué',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)2.3/': {
        name: 'Dilution du lait entier',
        nameRapport: 'la dilution du lait entier peut proposer un produit déséquilibré',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)2.4/': {
        name: 'MG de l’aliment d’allaitement < 17% ou > à 18 %',
        nameRapport: 'la MG de l’aliment d’allaitement doit représenter entre  17%  et 18 %',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)2.5/': {
        name: 'Protéines de l’aliment d’allaitement < 20% ou > à 21 %',
        nameRapport: 'les protéines de l’aliment d’allaitement doivent représenter entre 20% et 21 %',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)2.6/': {
        name: 'Quantité de poudre des aliments d’allaitement imprécise',
        nameRapport: 'la quantité de poudre des aliments d’allaitement doit être mesurée avec précision',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)2.7/': {
        name: 'Variation de la température du lait d’un repas à l’autre',
        nameRapport: 'la température du lait d’un repas à l’autre doit être constante',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)2.8/': {
        name: 'Variation des pratiques de distribution d’un jour sur l’autre',
        nameRapport: 'les pratiques de distribution d’un jour sur l’autre doivent rester identiques',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)2.9/': {
        name: 'Volumes distribués aléatoires, peu précis',
        nameRapport: 'les volumes distribués ne peuvent être aléatoires, mais relativement précis',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)5)b)2.av': {
    id: 'D)5)b)2.av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)5)b)2.av': {
        answerType: 'TI',
      },
    },
  },
  'D)5)b)5.': {
    id: 'D)5)b)5.',
    name: 'La distribution du lait :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)5)b)5.1/': {
        name: 'Auge',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)5.2/': {
        name: 'Seau',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)5.3/': {
        name: 'DAL',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)5.4/': {
        name: 'Volucompteur ou pistolet wifi',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)5.5/': {
        name: 'Automatique',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)5.6/': {
        name: 'Chariot automoteur',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)5.7/': {
        name: 'Vanne quart de tour',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)5)b)5.av': {
    id: 'D)5)b)5.av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)5)b)5.av': {
        answerType: 'TI',
      },
    },
  },
  'D)5)b)3.': {
    id: 'D)5)b)3.',
    name: 'Les aliments solides et l’eau :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)5)b)3.1/': {
        name: 'Absence de mise à disposition dés la 2ème semaine d’aliments solides et d’eau',
        nameRapport: 'mise à disposition dès la 2ème semaine d’aliments solides et d’eau',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)3.2/': {
        name: 'Absence de fourrage fibreux',
        nameRapport: 'le  fourrage fibreux est un composant essentiel de la ration du veau',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)3.3/': {
        name: 'Concentré et (ou) fourrage ne sont pas distribués à volonté',
        nameRapport: 'le concentré et le fourrage doivent être  distribués à volonté',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)3.4/': {
        name: 'Consommation de concentré < 500g / jour après 4-5 semaines d’âge',
        nameRapport: 'après 4-5 semaines d’âge, la consommation de concentré doit être > 500g / jour',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)3.5/': {
        name: 'Consommation de concentré < 2 kg / jour à la veille du sevrage',
        nameRapport: 'à la veille du sevrage, la consommation de concentré doit être > 2 kg / jour',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)3.6/': {
        name: 'L’eau de boisson n’est pas distribuée à volonté',
        nameRapport: 'l’eau de boisson doit être distribuée à volonté ',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)3.7/': {
        name: 'Mauvaise accessibilité du concentré ou des fourrages',
        nameRapport: 'veiller à une bonne accessibilité du concentré ou des fourrages',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)3.8/': {
        name: 'Mauvaise accessibilité à l’eau de boisson',
        nameRapport: 'veiller à une bonne accessibilité à l’eau de boisson',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)5)b)3.av': {
    id: 'D)5)b)3.av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)5)b)3.av': {
        answerType: 'TI',
      },
    },
  },
  'D)5)b)7.': {
    id: 'D)5)b)7.',
    name: 'La distribution des aliments solides :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)5)b)7.1/': {
        name: 'Auge',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)7.2/': {
        name: 'Nourrisseur',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)7.3/': {
        name: 'Seau',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)7.4/': {
        name: 'Autre',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)7.5/': {
        name: 'Manuel (brouette)',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)7.6/': {
        name: 'Chaine à pastille ou vis à spires',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)7.7/': {
        name: 'Chariot automoteur',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)7.8/': {
        name: 'Robot',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)5)b)7.av': {
    id: 'D)5)b)7.av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)5)b)7.av': {
        answerType: 'TI',
      },
    },
  },
  'D)5)b)4.': {
    id: 'D)5)b)4.',
    name: 'Logement et hygiène :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)5)b)4.1/': {
        name: 'Absence de logement individuel pendant l’allaitement',
        nameRapport: 'les logements individuels pendant l’allaitement sont fortement conseillés',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)4.2/': {
        name: 'Différence d’âge importante dans un même lot',
        nameRapport: 'chaque lot doit être constitué d’animaux du même âge',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)4.3/': {
        name: 'Concentré mal conservé',
        nameRapport: 'le concentré doit être bien conservé',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)4.4/': {
        name: 'Fourrages mal conservés',
        nameRapport: 'les fourrages doivent être bien conservés',
        catRapport: 'Recommandations :',
        description: 'Moisissures, terre, odeur…',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)4.5/': {
        name: 'Non retrait systématique des refus',
        nameRapport: 'les refus doivent être systématiquement retirés',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)4.6/': {
        name: 'Non rinçage à l’eau propre du matériel de distribution après chaque utilisation',
        nameRapport: 'le matériel de distribution doit être rincé à l’eau propre après chaque utilisation',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)4.7/': {
        name: 'Observation insuffisante du comportement alimentaire des veaux',
        nameRapport: 'le  comportement alimentaire des veaux doit faire l’objet d’une surveillance accrue',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)b)4.8/': {
        name: 'Propreté suspecte de l’eau de boisson',
        nameRapport: 'veiller à la propreté de l’eau de boisson',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)5)b)4.av': {
    id: 'D)5)b)4.av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)5)b)4.av': {
        answerType: 'TI',
      },
    },
  },
  'D)5)c)': {
    id: 'D)5)c)',
    name: 'Origine et qualité de l’eau d’abreuvement :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)5)c)1/': {
        name: 'Absence d’analyse d’eau',
        nameRapport: 'effectuer régulièrement une analyse d’eau',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)c)2/': {
        name: 'Moins d’une analyse d’eau par an',
        nameRapport: 'effectuer une analyse d’eau annuellement',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)c)3/': {
        name: 'Absence de distribution à volonté de l’eau',
        nameRapport: 'l’eau doit être distribuée à volonté',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)c)4/': {
        name: 'Absence de nettoyage régulier des abreuvoirs et points d’eau',
        nameRapport: 'les abreuvoirs et points d’eau doivent être régulièrement nettoyés',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)c)5/': {
        name: 'Absence de périmètre de protection autour du captage',
        nameRapport: 'un périmètre de protection doit être installé autour du captage',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)c)6/': {
        name: 'Captage à proximité de sources potentielles de contamination',
        nameRapport: 'le captage à proximité de sources potentielles de contamination (fumières, passages réguliers d’animaux, assainissement individuel d’habitations…) est à proscrire',
        catRapport: 'Recommandations :',
        description: 'Fumières, passages réguliers d’animaux, assainissement individuel d’habitations…',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)c)7/': {
        name: 'Captage en aval des zones à risques',
        nameRapport: 'captage ne doit pas se faire en aval des zones à risques',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)c)8/': {
        name: 'Mauvaise étanchéité des installations de captage',
        nameRapport: 'veiller à une bonne étanchéité des installations de captage',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)c)9/': {
        name: 'L\'eau provient du réseau',
        nameRapport: 'rapprochez vous du fournisseur d\'eau pour connaître ses caractéristiques',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)5)c)10/': {
        name: 'L\'eau provient d\'un puits ou d\'un forage',
        nameRapport: 'nous vous conseillons d\'analyser régulièrement l\'eau de votre puits ou de votre forage',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)5)c)av': {
    id: 'D)5)c)av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)5)c)av': {
        answerType: 'TI',
      },
    },
  },
  'D)5)av#': {
    id: 'D)5)av#',
    name: 'L\'avis du véto (l’alimentation du troupeau)',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)5)av#': {
        answerType: 'TI',
      },
    },
  },
  'D)6)': {
    id: 'D)6)',
    titre2: 'Les performances techniques sur la période des 12 mois concernés :',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)6)d)': {
    id: 'D)6)d)',
    name: 'Type principal',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)6)d)1/': {
        name: 'Holstein',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)d)2/': {
        name: 'Croisés lourds',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)d)3/': {
        name: 'Croisés légers',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)d)4/': {
        name: 'Mixtes mélange',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)6)a)': {
    id: 'D)6)a)',
    titre3: 'Sanitaire',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)6)a)1.': {
    id: 'D)6)a)1.',
    titre4: 'Taux de mortalité total (%)',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)6)a)1.a)': {
    id: 'D)6)a)1.a)',
    name: 'Moyenne élevage',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)6)a)1.a)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)6)a)1.b)': {
    id: 'D)6)a)1.b)',
    name: 'Moyenne nationale',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)6)a)1.b)1/': {
        name: '4,3',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)a)1.b)2/': {
        name: '2,1',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)a)1.b)3/': {
        name: '3,2',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)a)1.b)4/': {
        name: '4',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)6)a)1.c)': {
    id: 'D)6)a)1.c)',
    name: 'Ratio élevage',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)6)a)1.c)': {
        answerType: 'F',
        formule: '${D)6)a)1.a)}/${D)6)a)1.b)}',
        minVert: '0',
        maxVert: '1',
        minOrange: '',
        maxOrange: '',
        minRouge: '1',
        maxRouge: '100000',
      },
    },
  },
  'D)6)b)': {
    id: 'D)6)b)',
    titre3: 'Performances d’engraissement',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)6)b)1.': {
    id: 'D)6)b)1.',
    titre4: 'Durée d’engraissement (jours)',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)6)b)1.a)': {
    id: 'D)6)b)1.a)',
    name: 'Moyenne élevage',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)6)b)1.a)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)6)b)1.b)': {
    id: 'D)6)b)1.b)',
    name: 'Moyenne nationale',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)6)b)1.b)1/': {
        name: '166',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)b)1.b)2/': {
        name: '153',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)b)1.b)3/': {
        name: '165',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)b)1.b)4/': {
        name: '164',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)6)b)1.c)': {
    id: 'D)6)b)1.c)',
    name: 'Ratio élevage',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)6)b)1.c)': {
        answerType: 'F',
        formule: '${D)6)b)1.a)}/${D)6)b)1.b)}',
        minVert: '0',
        maxVert: '1',
        minOrange: '',
        maxOrange: '',
        minRouge: '1',
        maxRouge: '100000',
      },
    },
  },
  'D)6)b)2.': {
    id: 'D)6)b)2.',
    titre4: 'Durée du vide sanitaire précédent (jours)',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)6)b)2.a)': {
    id: 'D)6)b)2.a)',
    name: 'Moyenne élevage',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)6)b)2.a)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)6)b)2.b)': {
    id: 'D)6)b)2.b)',
    name: 'Moyenne nationale',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)6)b)2.b)1/': {
        name: '32',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)b)2.b)2/': {
        name: '36',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)b)2.b)3/': {
        name: '33',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)b)2.b)4/': {
        name: '37',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)6)b)2.c)': {
    id: 'D)6)b)2.c)',
    name: 'Ratio élevage',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)6)b)2.c)': {
        answerType: 'F',
        formule: '${D)6)b)2.a)}/${D)6)b)2.b)}',
        minVert: '1',
        maxVert: '100000',
        minOrange: '',
        maxOrange: '',
        minRouge: '0',
        maxRouge: '1',
      },
    },
  },
  'D)6)b)3.': {
    id: 'D)6)b)3.',
    titre4: 'Poids des veaux nourrissons (kg)',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)6)b)3.a)': {
    id: 'D)6)b)3.a)',
    name: 'Moyenne élevage',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)6)b)3.a)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)6)b)3.b)': {
    id: 'D)6)b)3.b)',
    name: 'Moyenne nationale',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)6)b)3.b)1/': {
        name: '49',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)b)3.b)2/': {
        name: '70',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)b)3.b)3/': {
        name: '54',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)b)3.b)4/': {
        name: '51',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)6)b)3.c)': {
    id: 'D)6)b)3.c)',
    name: 'Ratio élevage',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)6)b)3.c)': {
        answerType: 'F',
        formule: '${D)6)b)3.a)}/${D)6)b)3.b)}',
        minVert: '1',
        maxVert: '100000',
        minOrange: '',
        maxOrange: '',
        minRouge: '0',
        maxRouge: '1',
      },
    },
  },
  'D)6)b)4.': {
    id: 'D)6)b)4.',
    titre4: 'Poids de carcasse (kg)',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)6)b)4.a)': {
    id: 'D)6)b)4.a)',
    name: 'Moyenne élevage',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)6)b)4.a)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)6)b)4.b)': {
    id: 'D)6)b)4.b)',
    name: 'Moyenne nationale',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)6)b)4.b)1/': {
        name: '140',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)b)4.b)2/': {
        name: '159',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)b)4.b)3/': {
        name: '149',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)b)4.b)4/': {
        name: '145',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)6)b)4.c)': {
    id: 'D)6)b)4.c)',
    name: 'Ratio élevage',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)6)b)4.c)': {
        answerType: 'F',
        formule: '${D)6)b)4.a)}/${D)6)b)4.b)}',
        minVert: '1',
        maxVert: '100000',
        minOrange: '',
        maxOrange: '',
        minRouge: '0',
        maxRouge: '1',
      },
    },
  },
  'D)6)b)5.': {
    id: 'D)6)b)5.',
    titre4: 'Poids carcasse par jour d’engraissement (g/jour)',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)6)b)5.a)': {
    id: 'D)6)b)5.a)',
    name: 'Moyenne élevage',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)6)b)5.a)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)6)b)5.b)': {
    id: 'D)6)b)5.b)',
    name: 'Moyenne nationale',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)6)b)5.b)1/': {
        name: '842',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)b)5.b)2/': {
        name: '1035',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)b)5.b)3/': {
        name: '906',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)b)5.b)4/': {
        name: '890',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)6)b)5.c)': {
    id: 'D)6)b)5.c)',
    name: 'Ratio élevage',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)6)b)5.c)': {
        answerType: 'F',
        formule: '${D)6)b)5.a)}/${D)6)b)5.b)}',
        minVert: '1',
        maxVert: '100000',
        minOrange: '',
        maxOrange: '',
        minRouge: '0',
        maxRouge: '1',
      },
    },
  },
  'D)6)b)6.': {
    id: 'D)6)b)6.',
    titre4: 'Taux de veaux de moins de 100 kg carcasse (%)',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)6)b)6.a)': {
    id: 'D)6)b)6.a)',
    name: 'Moyenne élevage',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)6)b)6.a)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)6)b)6.b)': {
    id: 'D)6)b)6.b)',
    name: 'Moyenne nationale',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)6)b)6.b)1/': {
        name: '2.8',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)b)6.b)2/': {
        name: '1.1',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)b)6.b)3/': {
        name: '1.7',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)b)6.b)4/': {
        name: '2.3',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)6)b)6.c)': {
    id: 'D)6)b)6.c)',
    name: 'Ratio élevage',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)6)b)6.c)': {
        answerType: 'F',
        formule: '${D)6)b)6.a)}/${D)6)b)6.b)}',
        minVert: '1',
        maxVert: '100000',
        minOrange: '',
        maxOrange: '',
        minRouge: '0',
        maxRouge: '1',
      },
    },
  },
  'D)6)b)7.': {
    id: 'D)6)b)7.',
    titre4: 'Taux de veaux classés conformation P (%)',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)6)b)7.a)': {
    id: 'D)6)b)7.a)',
    name: 'Moyenne élevage',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)6)b)7.a)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)6)b)7.b)': {
    id: 'D)6)b)7.b)',
    name: 'Moyenne nationale',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)6)b)7.b)1/': {
        name: '22.5',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)b)7.b)2/': {
        name: '0.3',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)b)7.b)3/': {
        name: '1.6',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)b)7.b)4/': {
        name: '13.2',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)6)b)7.c)': {
    id: 'D)6)b)7.c)',
    name: 'Ratio élevage',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)6)b)7.c)': {
        answerType: 'F',
        formule: '${D)6)b)7.a)}/${D)6)b)7.b)}',
        minVert: '1',
        maxVert: '100000',
        minOrange: '',
        maxOrange: '',
        minRouge: '0',
        maxRouge: '1',
      },
    },
  },
  'D)6)b)8.': {
    id: 'D)6)b)8.',
    titre4: 'Taux de veaux classés couleur 1 et 2 (%)',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)6)b)8.a)': {
    id: 'D)6)b)8.a)',
    name: 'Moyenne élevage',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)6)b)8.a)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)6)b)8.b)': {
    id: 'D)6)b)8.b)',
    name: 'Moyenne nationale',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)6)b)8.b)1/': {
        name: '80.1',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)b)8.b)2/': {
        name: '88.4',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)b)8.b)3/': {
        name: '82.8',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)b)8.b)4/': {
        name: '81.4',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)6)b)8.c)': {
    id: 'D)6)b)8.c)',
    name: 'Ratio élevage',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)6)b)8.c)': {
        answerType: 'F',
        formule: '${D)6)b)8.a)}/${D)6)b)8.b)}',
        minVert: '1',
        maxVert: '100000',
        minOrange: '',
        maxOrange: '',
        minRouge: '0',
        maxRouge: '1',
      },
    },
  },
  'D)6)c)': {
    id: 'D)6)c)',
    titre3: 'Alimentation',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)6)c)1.': {
    id: 'D)6)c)1.',
    titre4: 'Quantité d’aliments d’allaitement consommée (kg/veau sorti)',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)6)c)1.a)': {
    id: 'D)6)c)1.a)',
    name: 'Moyenne élevage',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)6)c)1.a)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)6)c)1.b)': {
    id: 'D)6)c)1.b)',
    name: 'Moyenne nationale',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)6)c)1.b)1/': {
        name: '267',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)c)1.b)2/': {
        name: '288',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)c)1.b)3/': {
        name: '290',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)c)1.b)4/': {
        name: '275',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)6)c)1.c)': {
    id: 'D)6)c)1.c)',
    name: 'Ratio élevage',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)6)c)1.c)': {
        answerType: 'F',
        formule: '${D)6)c)1.a)}/${D)6)c)1.b)}',
        minVert: '1',
        maxVert: '100000',
        minOrange: '',
        maxOrange: '',
        minRouge: '0',
        maxRouge: '1',
      },
    },
  },
  'D)6)c)2.': {
    id: 'D)6)c)2.',
    titre4: 'Quantité d’aliments solides consommée (kg/veau sorti)',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)6)c)2.a)': {
    id: 'D)6)c)2.a)',
    name: 'Moyenne élevage',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)6)c)2.a)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)6)c)2.b)': {
    id: 'D)6)c)2.b)',
    name: 'Moyenne nationale',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)6)c)2.b)1/': {
        name: '184',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)c)2.b)2/': {
        name: '93',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)c)2.b)3/': {
        name: '143',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)c)2.b)4/': {
        name: '202',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)6)c)2.c)': {
    id: 'D)6)c)2.c)',
    name: 'Ratio élevage',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)6)c)2.c)': {
        answerType: 'F',
        formule: '${D)6)c)2.a)}/${D)6)c)2.b)}',
        minVert: '1',
        maxVert: '100000',
        minOrange: '',
        maxOrange: '',
        minRouge: '0',
        maxRouge: '1',
      },
    },
  },
  'D)6)c)3.': {
    id: 'D)6)c)3.',
    titre4: 'Part de paille dans la ration solide (%)',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)6)c)3.a)': {
    id: 'D)6)c)3.a)',
    name: 'Moyenne élevage',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)6)c)3.a)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)6)c)3.b)': {
    id: 'D)6)c)3.b)',
    name: 'Moyenne nationale',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)6)c)3.b)1/': {
        name: '4.9',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)c)3.b)2/': {
        name: '4.5',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)c)3.b)3/': {
        name: '6.1',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)c)3.b)4/': {
        name: '7.5',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)6)c)3.c)': {
    id: 'D)6)c)3.c)',
    name: 'Ratio élevage',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)6)c)3.c)': {
        answerType: 'F',
        formule: '${D)6)c)3.a)}/${D)6)c)3.b)}',
        minVert: '1',
        maxVert: '100000',
        minOrange: '',
        maxOrange: '',
        minRouge: '0',
        maxRouge: '1',
      },
    },
  },
  'D)6)c)4.': {
    id: 'D)6)c)4.',
    titre4: 'Coefficient d’efficacité alimentaire lait + aliment solide',
    description: 'Le coefficient d’efficacité alimentaire lait + aliment solide remplace l’indice de consommation habituel. Il correspond à la formule suivante : [quantité d’aliments d’allaitement + quantité d’aliments solides/2 + quantité de paille/6] / Poids de carcasse',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)6)c)4.a)': {
    id: 'D)6)c)4.a)',
    name: 'Moyenne élevage',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)6)c)4.a)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)6)c)4.b)': {
    id: 'D)6)c)4.b)',
    name: 'Moyenne nationale',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)6)c)4.b)1/': {
        name: '2.69',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)c)4.b)2/': {
        name: '2.11',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)c)4.b)3/': {
        name: '2.41',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)6)c)4.b)4/': {
        name: '2.49',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)6)c)4.c)': {
    id: 'D)6)c)4.c)',
    name: 'Ratio élevage',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)6)c)4.c)': {
        answerType: 'F',
        formule: '${D)6)c)4.a)}/${D)6)c)4.b)}',
        minVert: '1',
        maxVert: '100000',
        minOrange: '',
        maxOrange: '',
        minRouge: '0',
        maxRouge: '1',
      },
    },
  },
  'D)6)av#': {
    id: 'D)6)av#',
    name: 'L\'avis du véto (Performances techniques)',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)6)av#': {
        answerType: 'TI',
      },
    },
  },
  'D)7)': {
    id: 'D)7)',
    titre2: 'La reproduction :',
    description: 'Pour pouvoir répondre à ce questionnaire indispensable pour la détection d’un certain nombre de troubles de la reproduction, vous devez impérativement noter régulièrement tous les événements liés à la reproduction (dates de vêlages, de chaleurs, d’inséminations, etc.) à l’aide éventuellement d’un planning de fécondité circulaire ou de moyens informatiques et utiliser les informations issues des bilans régulièrement fournis par les différents organismes.  ',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)7)a)': {
    id: 'D)7)a)',
    titre3: 'Généralités :',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)7)a)1.': {
    id: 'D)7)a)1.',
    name: 'Synchronisation des chaleurs :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)7)a)1.1/': {
        name: 'Non',
        nameRapport: 'pas de synchronisation',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)a)1.2/': {
        name: 'Sur certains lots seulement',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)a)1.3/': {
        name: 'Sur tous les lots',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)7)a)2.': {
    id: 'D)7)a)2.',
    name: 'Flushing :',
    description: 'Apport alimentaire en énergie supérieur aux besoins pendant une période limitée au moment de la mise à la reproduction, dans le but d’améliorer la fertilité (aptitude physiologique à se reproduire)',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)7)a)2.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)a)2.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)7)a)3.': {
    id: 'D)7)a)3.',
    titre4: 'Chaleurs :',
    description: 'La durée normale de l’intervalle entre les chaleurs successives chez la vache est de 18 à 25 jours, avec une moyenne de 21 jours.',
    autopass: true,
  },
  'D)7)a)3.1)': {
    id: 'D)7)a)3.1)',
    name: 'Nombre d\'anoestrus',
    description: 'Absence de chaleurs',
    hideRapport: true,
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)7)a)3.1)': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)7)a)3.2)': {
    id: 'D)7)a)3.2)',
    name: 'Nombre de chaleurs discrètes',
    hideRapport: true,
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)7)a)3.2)': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)7)a)3.3)': {
    id: 'D)7)a)3.3)',
    name: 'Nombre de détections difficiles',
    hideRapport: true,
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)7)a)3.3)': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)7)a)3.4)': {
    id: 'D)7)a)3.4)',
    name: 'Nombre de vaches non cyclées',
    hideRapport: true,
    description: 'Cycles anormaux',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)7)a)3.4)': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)7)a)3.5)': {
    id: 'D)7)a)3.5)',
    name: 'Nombre d\'interoestrus court',
    hideRapport: true,
    description: 'Durée entre deux chaleurs inférieure à 18 jours',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)7)a)3.5)': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)7)a)3.6)': {
    id: 'D)7)a)3.6)',
    name: 'Nombre d\'interoestrus long',
    hideRapport: true,
    description: 'Durée entre deux chaleurs supérieure à 25 jours',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)7)a)3.6)': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)7)a)3.7)': {
    id: 'D)7)a)3.7)',
    name: 'Nombre de nymphomanie',
    description: 'Chaleurs exacerbées',
    hideRapport: true,
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)7)a)3.7)': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)7)a)3.8)': {
    id: 'D)7)a)3.8)',
    name: 'Nombre de repeat breeding',
    hideRapport: true,
    description: 'Retours en chaleurs réguliers',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)7)a)3.8)': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)7)a)3.a)': {
    id: 'D)7)a)3.a)',
    titre4: 'Calcul du taux :',
    titre4Rapport: 'Importance estimée : ',
    description: '',
    bseOnly: true,
    interdire: false,
    autopass: true,
  },
  'D)7)a)3.a)1)': {
    id: 'D)7)a)3.a)1)',
    name: 'Taux anoestrus',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)7)a)3.a)1)': {
        answerType: 'F',
        formule: '${D)7)a)3.1)}/${D)1)8.5)}*100',
        suffix: ' %',
        round: 2,
        minVert: '1',
        maxVert: '15',
        minOrange: '15',
        maxOrange: '25',
        minRouge: '25',
        maxRouge: '1000000',
      },
    },
  },
  'D)7)a)3.a)2)': {
    id: 'D)7)a)3.a)2)',
    name: 'Taux chaleurs discrètes',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)7)a)3.a)2)': {
        answerType: 'F',
        formule: '${D)7)a)3.2)}/${D)1)8.5)}*100',
        suffix: ' %',
        round: 2,
        minVert: '1',
        maxVert: '15',
        minOrange: '15',
        maxOrange: '25',
        minRouge: '25',
        maxRouge: '1000000',
      },
    },
  },
  'D)7)a)3.a)3)': {
    id: 'D)7)a)3.a)3)',
    name: 'Taux détections difficiles',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)7)a)3.a)3)': {
        answerType: 'F',
        formule: '${D)7)a)3.3)}/${D)1)8.5)}*100',
        suffix: ' %',
        round: 2,
        minVert: '1',
        maxVert: '15',
        minOrange: '15',
        maxOrange: '25',
        minRouge: '25',
        maxRouge: '1000000',
      },
    },
  },
  'D)7)a)3.a)4)': {
    id: 'D)7)a)3.a)4)',
    name: 'Taux non cyclées',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)7)a)3.a)4)': {
        answerType: 'F',
        formule: '${D)7)a)3.4)}/${D)1)8.5)}*100',
        suffix: ' %',
        round: 2,
        minVert: '1',
        maxVert: '15',
        minOrange: '15',
        maxOrange: '25',
        minRouge: '25',
        maxRouge: '1000000',
      },
    },
  },
  'D)7)a)3.a)5)': {
    id: 'D)7)a)3.a)5)',
    name: 'Taux interoestrus court',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)7)a)3.a)5)': {
        answerType: 'F',
        formule: '${D)7)a)3.5)}/${D)1)8.5)}*100',
        suffix: ' %',
        round: 2,
        minVert: '1',
        maxVert: '15',
        minOrange: '15',
        maxOrange: '25',
        minRouge: '25',
        maxRouge: '1000000',
      },
    },
  },
  'D)7)a)3.a)6)': {
    id: 'D)7)a)3.a)6)',
    name: 'Taux interoestrus long',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)7)a)3.a)6)': {
        answerType: 'F',
        formule: '${D)7)a)3.6)}/${D)1)8.5)}*100',
        suffix: ' %',
        round: 2,
        minVert: '1',
        maxVert: '15',
        minOrange: '15',
        maxOrange: '25',
        minRouge: '25',
        maxRouge: '1000000',
      },
    },
  },
  'D)7)a)3.a)7)': {
    id: 'D)7)a)3.a)7)',
    name: 'Taux nymphomanie',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)7)a)3.a)7)': {
        answerType: 'F',
        formule: '${D)7)a)3.7)}/${D)1)8.5)}*100',
        suffix: ' %',
        round: 2,
        minVert: '1',
        maxVert: '15',
        minOrange: '15',
        maxOrange: '25',
        minRouge: '25',
        maxRouge: '1000000',
      },
    },
  },
  'D)7)a)3.a)8)': {
    id: 'D)7)a)3.a)8)',
    name: 'Taux repeat breeding',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)7)a)3.a)8)': {
        answerType: 'F',
        formule: '${D)7)a)3.8)}/${D)1)8.5)}*100',
        suffix: ' %',
        round: 2,
        minVert: '1',
        maxVert: '15',
        minOrange: '15',
        maxOrange: '25',
        minRouge: '25',
        maxRouge: '1000000',
      },
    },
  },
  'D)7)a)3.av': {
    id: 'D)7)a)3.av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)7)a)3.av': {
        answerType: 'TI',
      },
    },
  },
  'D)7)a)4.': {
    id: 'D)7)a)4.',
    titre4: 'Vêlage :',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)7)a)4.1)': {
    id: 'D)7)a)4.1)',
    name: 'Nombre de vaches avec un intervalle entre vêlages anormalement long',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)7)a)4.1)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)7)a)4.a)': {
    id: 'D)7)a)4.a)',
    titre4: 'Calcul du taux :',
    description: '',
    bseOnly: true,
    interdire: false,
    autopass: true,
  },
  'D)7)a)4.a)1)': {
    id: 'D)7)a)4.a)1)',
    name: 'Taux de vaches avec un intervalle entre vêlages anormalement long',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)7)a)4.a)1)': {
        answerType: 'F',
        formule: '${D)7)a)4.1)}/${D)1)8.5)}*100',
        suffix: ' %',
        round: 2,
        minVert: '1',
        maxVert: '15',
        minOrange: '15',
        maxOrange: '25',
        minRouge: '25',
        maxRouge: '1000000',
      },
    },
  },
  'D)7)a)4.av': {
    id: 'D)7)a)4.av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)7)a)4.av': {
        answerType: 'TI',
      },
    },
  },
  'D)7)b)': {
    id: 'D)7)b)',
    titre3: 'Indicateurs de fertilité et de fécondité du troupeau allaitant :',
    description: ' - fertilité : aptitude physiologique à se reproduire\\n - fécondité : aptitude physiologique à se reproduire dans un délai donné',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)7)b)1.': {
    id: 'D)7)b)1.',
    name: 'Intervalle moyen entre vêlages :',
    description: 'Somme des intervalles entre vêlages des vaches ayant vêlé deux campagnes consécutives divisé par le nombre de vaches pleines présentes deux années consécutives',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)7)b)1.1/': {
        name: '> 370 jours',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)7)b)2.': {
    id: 'D)7)b)2.',
    name: 'Nombre de vêlage survenus plus de 2 mois après la date médiane :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)7)b)2.': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)7)b)4.': {
    id: 'D)7)b)4.',
    name: 'Pourcentage de vêlages tardifs :',
    description: 'Nombre de vêlages survenus plus de deux mois après la date médiane divisé par le nombre total de vêlage',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)7)b)4.': {
        answerType: 'F',
        formule: '${D)7)b)2.}/${D)1)9.4)}*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '3',
        minOrange: '3',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
    },
  },
  'D)7)b)av': {
    id: 'D)7)b)av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)7)b)av': {
        answerType: 'TI',
      },
    },
  },
  'D)7)c)': {
    id: 'D)7)c)',
    titre3: 'Indicateurs de fertilité et de fécondité en élevage laitier :',
    description: ' - fertilité : aptitude d’une femelle à être fécondée, donc à devenir gestante\\n - fécondité : aptitude d’une femelle à devenir gestante dans un délai donné',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)7)c)1.': {
    id: 'D)7)c)1.',
    name: 'V - IF :',
    description: 'Intervalle vêlage - insémination fécondante',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)7)c)1.1/': {
        name: '50 < V-IF < 80',
        bseColor: 'green',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)c)1.2/': {
        name: '80 < V-IF < 90',
        bseColor: 'green',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)c)1.3/': {
        name: '90 < V-IF < 100',
        bseColor: 'yellow',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)c)1.4/': {
        name: 'V-IF > 100',
        bseColor: 'red',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)7)c)2.': {
    id: 'D)7)c)2.',
    name: 'V - IA1 :',
    description: 'Intervalle vêlage - 1ère insémination',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)7)c)2.1/': {
        name: '50 < V-IA1 < 80',
        bseColor: 'green',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)c)2.2/': {
        name: '80 < V-IA1 < 100',
        bseColor: 'green',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)c)2.3/': {
        name: 'V-IA1 > 100',
        bseColor: 'yellow',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)c)2.4/': {
        name: 'Variable selon la période d’IA',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)7)c)3.': {
    id: 'D)7)c)3.',
    name: 'Taux de réussite en IA1 :',
    description: 'Première insémination',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)7)c)3.1/': {
        name: '45 %',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)c)3.2/': {
        name: '> 50%',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)c)3.3/': {
        name: '> 55 %',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)7)c)4.': {
    id: 'D)7)c)4.',
    name: 'Taux de gestation :',
    description: 'Nombre de femelles pleines divisé par le nombre de femelles mises à la reproduction',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)7)c)4.1/': {
        name: '< 80%',
        bseColor: 'yellow',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)c)4.2/': {
        name: '80 % < < 85%',
        bseColor: 'yellow',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)c)4.3/': {
        name: '85 % < < 90%',
        bseColor: 'green',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)c)4.4/': {
        name: '> 90 %',
        bseColor: 'green',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)7)c).av': {
    id: 'D)7)c).av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)7)c).av': {
        answerType: 'TI',
      },
    },
  },
  'D)7)d)': {
    id: 'D)7)d)',
    titre3: 'L’anoestrus :',
    description: 'Absence d’oestrus ou de chaleurs',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)7)d)1.': {
    id: 'D)7)d)1.',
    titre4: 'Indicateurs d’anoestrus de troupeau :',
    description: 'Absence d’oestrus ou de chaleurs',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)7)d)1.a)': {
    id: 'D)7)d)1.a)',
    name: 'Nombre de vaches non venues en chaleurs 60 jours après le vêlage :',
    nameRapport: 'Vaches non venues en chaleurs 60 jours après le vêlage :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)7)d)1.a)': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)7)d)1.a)1.': {
    id: 'D)7)d)1.a)1.',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)7)d)1.a)1.': {
        answerType: 'F',
        formule: '${D)7)d)1.a)}/${D)1)9.4)}*100',
        suffix: ' %',
        round: 2,
        minVert: '1',
        maxVert: '15',
        minOrange: '15',
        maxOrange: '25',
        minRouge: '25',
        maxRouge: '1000000',
      },
    },
  },
  'D)7)d)1.b)': {
    id: 'D)7)d)1.b)',
    name: 'Nombre de vaches non inséminées 90 jours après le vêlage :',
    nameRapport: 'Vaches non inséminées 90 jours après le vêlage :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)7)d)1.b)': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)7)d)1.b)1.': {
    id: 'D)7)d)1.b)1.',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)7)d)1.b)1.': {
        answerType: 'F',
        formule: '${D)7)d)1.b)}/${D)1)9.4)}*100',
        suffix: ' %',
        round: 2,
        minVert: '1',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '1000000',
      },
    },
  },
  'D)7)d)1.c)': {
    id: 'D)7)d)1.c)',
    name: 'Nombre de vaches non pleines 110 jours après le vêlage :',
    nameRapport: 'Vaches non pleines 110 jours après le vêlage :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)7)d)1.c)': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)7)d)1.c)1.': {
    id: 'D)7)d)1.c)1.',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)7)d)1.c)1.': {
        answerType: 'F',
        formule: '${D)7)d)1.c)}/${D)1)9.4)}*100',
        suffix: ' %',
        round: 2,
        minVert: '1',
        maxVert: '15',
        minOrange: '15',
        maxOrange: '25',
        minRouge: '25',
        maxRouge: '1000000',
      },
    },
  },
  'D)7)d)1.d)': {
    id: 'D)7)d)1.d)',
    name: 'Nombre de vaches non pleines 4 mois après le vêlage (> 121jours):',
    nameRapport: 'Vaches non pleines 4 mois après le vêlage (> 121jours):',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)7)d)1.d)': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)7)d)1.d)1.': {
    id: 'D)7)d)1.d)1.',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)7)d)1.d)1.': {
        answerType: 'F',
        formule: '${D)7)d)1.d)}/${D)1)9.4)}*100',
        suffix: ' %',
        round: 2,
        minVert: '1',
        maxVert: '15',
        minOrange: '15',
        maxOrange: '20',
        minRouge: '20',
        maxRouge: '1000000',
      },
    },
  },
  'D)7)d)2.': {
    id: 'D)7)d)2.',
    name: 'Caractéristiques des vaches en anoestrus :',
    description: 'Absence d’oestrus ou de chaleurs',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)7)d)2.1/': {
        name: 'Primipares',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)d)2.2/': {
        name: 'Vaches fortes productrices',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)d)2.3/': {
        name: 'Vaches ayant vêlé à une période précise',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)d)2.4/': {
        name: 'Vaches présentant une maladie autour du vêlage',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)d)2.5/': {
        name: 'Rien de caractéristique',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)7)d)av': {
    id: 'D)7)d)av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)7)d)av': {
        answerType: 'TI',
      },
    },
  },
  'D)7)e)': {
    id: 'D)7)e)',
    titre3: 'Les avortements :',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)7)e)1.': {
    id: 'D)7)e)1.',
    name: 'Stade de gestation :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)7)e)1.1/': {
        name: '1er mois',
        description: '1 cm de long',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)e)1.2/': {
        name: '2ème mois',
        description: '1 à 7 cm de long',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)e)1.3/': {
        name: '3ème mois',
        description: '7 à 12 cm de long',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)e)1.4/': {
        name: '4ème mois',
        description: '15 à 20 cm de long, 2 kg',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)e)1.5/': {
        name: '5ème mois (20 à 40 cm, 3 kg, premiers poils tactiles aux lèvres et au menton)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)e)1.6/': {
        name: 'Entre le 2ème et le 5ème mois',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)e)1.7/': {
        name: '6ème mois',
        description: '40 à 60 cm, 4 kg, poils aux yeux',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)e)1.8/': {
        name: '7ème mois',
        description: 'Environ 75 cm, 6 kg, poils sur la tête à la naissance des cornes et au bout de la queue',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)e)1.9/': {
        name: 'Aux 6ème et 7ème mois',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)e)1.10/': {
        name: 'Surtout le 7ème mois',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)e)1.11/': {
        name: '8ème mois',
        description: 'Environ 80 cm, 12 kg, poils sur le dos',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)e)1.12/': {
        name: '9ème mois',
        description: 'Jusqu’à 85 cm, 25 kg, poils partout',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)e)1.13/': {
        name: 'Aux 8ème et 9ème mois',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)e)1.14/': {
        name: '10ème mois',
        description: 'Environ 100 cm, 40 kg, prêt pour naître',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)e)1.15/': {
        name: 'Précoce',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)e)1.16/': {
        name: 'Tardif',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)e)1.17/': {
        name: 'Tous stades',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)e)1.18/': {
        name: 'Surtout tardif',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)e)1.19/': {
        name: 'Surtout mi-gestation',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)7)e)2.': {
    id: 'D)7)e)2.',
    name: 'Contexte épidémiologique :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)7)e)2.1/': {
        name: 'Enzootique',
        description: 'Se dit d’une maladie infectieuse sévissant dans une zone donnée sans tendance à l’extension',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)e)2.2/': {
        name: 'Furtifs',
        description: 'Sur une brève période',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)e)2.3/': {
        name: 'Plutôt sporadique',
        description: 'Se dit d’une maladie qui touche quelques individus isolément',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)e)2.4/': {
        name: 'Sporadique',
        description: 'Se dit d’une maladie qui touche quelques individus isolément',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)7)e)3.': {
    id: 'D)7)e)3.',
    name: 'Lots ou types d’animaux concernés :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)7)e)3.1/': {
        name: 'Primipares uniquement',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)e)3.2/': {
        name: 'Animaux en 1er ou 2ème gestation',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)e)3.3/': {
        name: 'Toutes catégories d’âge',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)7)e)4.': {
    id: 'D)7)e)4.',
    name: 'Aspect de l’avorton et du placenta :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)7)e)4.1/': {
        name: 'Naissance de veaux morts',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)e)4.2/': {
        name: 'Naissance de veaux vivants chétifs mourant rapidement',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)e)4.3/': {
        name: 'Lésions cutanées',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)e)4.4/': {
        name: 'Lésions de myosite',
        description: 'Inflammation du tissu musculaire',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)e)4.5/': {
        name: 'Momie',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)e)4.6/': {
        name: 'Rétentions placentaires',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)7)e)av': {
    id: 'D)7)e)av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)7)e)av': {
        answerType: 'TI',
      },
    },
  },
  'D)7)f)': {
    id: 'D)7)f)',
    name: 'Infertilité du taureau :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)7)f)1/': {
        name: 'Erreur d’allotement',
        description: '15 femelles maximum pour un jeune taureau, et 30 maximum pour un adulte',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)f)2/': {
        name: 'Incapacité à effectuer le saut',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)f)3/': {
        name: 'Lésions du pénis et du fourreau',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)f)4/': {
        name: 'Manque de libido',
        description: 'Instinct sexuel',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)7)f)5/': {
        name: 'Mauvaise qualité du sperme',
        description: 'Faible quantité, clair, ou mauvais résultats d’analyses',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)7)f)av': {
    id: 'D)7)f)av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)7)f)av': {
        answerType: 'TI',
      },
    },
  },
  'D)7)av#': {
    id: 'D)7)av#',
    name: 'L\'avis du véto (la reproduction)',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)7)av#': {
        answerType: 'TI',
      },
    },
  },
  'D)8)': {
    id: 'D)8)',
    titre2: 'Le vêlage :',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)8)a)': {
    id: 'D)8)a)',
    name: 'La préparation :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)8)a)1/': {
        name: 'Absence d’appareils de monitoring individuels',
        nameRapport: 'les appareils de monitoring individuels sont une aide précieuse pour un bon déroulement du vêlage',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)8)a)2/': {
        name: 'Absence d’appareils de télésurveillance',
        nameRapport: 'les appareils de télésurveillance facilitent sérieusement l’observation au moment du vêlage',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)8)a)3/': {
        name: 'Absence d’observations',
        nameRapport: 'la préparation au vêlage nécessiterait une observation plus soutenue',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)8)a)4/': {
        name: 'Absence de prise de température 3 à 6 jours avant',
        nameRapport: 'la prise de température 3 à 6 jours avant le vêlage est fortement conseillée',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)8)a)5/': {
        name: 'Absence de visualisation de l’écoulement translucide de mucus',
        nameRapport: 'il est important de chercher à observer l’écoulement translucide de mucus annonçant l’imminence du vêlage',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)8)a)av': {
    id: 'D)8)a)av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)8)a)av': {
        answerType: 'TI',
      },
    },
  },
  'D)8)b)': {
    id: 'D)8)b)',
    name: 'Le lieu du vêlage :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)8)b)1/': {
        name: 'Dehors',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)8)b)2/': {
        name: 'En aire paillée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)8)b)3/': {
        name: 'En box',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)8)b)4/': {
        name: 'En étable entravée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)8)b)5/': {
        name: 'N’importe où',
        nameRapport: 'en intérieur, il est recommandé de faire vêler les vaches dans un endroit adapté',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)8)b)6/': {
        name: 'Espace insuffisant',
        nameRapport: 'le vêlage doit avoir lieu dans un espace suffisant',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)8)b)7/': {
        name: 'Présence de courant d’air',
        nameRapport: 'éviter la présence de courant d’air dans le lieu où se déroulent les vêlages',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)8)b)8/': {
        name: 'Propreté insuffisante',
        nameRapport: 'le lieu de vêlage doit être propre, désinfecté, et bien paillé',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)8)b)av': {
    id: 'D)8)b)av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)8)b)av': {
        answerType: 'TI',
      },
    },
  },
  'D)8)c)': {
    id: 'D)8)c)',
    name: 'Le box de vêlage :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)8)c)1/': {
        name: 'Absence de désinfection à chaque vêlage',
        nameRapport: 'en intérieur, il est recommandé de faire vêler les vaches dans un endroit adapté',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)8)c)2/': {
        name: 'Absence de paillage à chaque vêlage',
        nameRapport: 'le box de vêlage doit être paillé avant chaque vêlage',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)8)c)3/': {
        name: 'Espace insuffisant',
        nameRapport: '8 à 10 m2 sont le minimum pour un box de vêlage',
        catRapport: 'Recommandations :',
        description: 'Au moins de 8 à 10 m2',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)8)c)4/': {
        name: 'Introduction trop tardive de la vache',
        nameRapport: 'la vache doit être introduite dans le box au moins 1 à 2 jours avant le vêlage',
        catRapport: 'Recommandations :',
        description: 'Au moins 1 à 2 jours avant',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)8)c)5/': {
        name: 'Nombre insuffisant',
        nameRapport: 'il faut prévoir 1 box de vêlage pour 50 vaches au maximum',
        catRapport: 'Recommandations :',
        description: '1 box pour 50 vaches',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)8)c)6/': {
        name: 'Présence de courant d’air',
        nameRapport: 'éviter la présence de courant d’air sur le box de vêlage',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)8)c)av': {
    id: 'D)8)c)av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)8)c)av': {
        answerType: 'TI',
      },
    },
  },
  'D)8)d)': {
    id: 'D)8)d)',
    titre3: 'Le déroulement :',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)8)d)1)': {
    id: 'D)8)d)1)',
    name: 'Nombre de vaches présentant une absence de dilatation',
    nameRapport: 'Vaches présentant une absence de dilatation',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)8)d)1)': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)8)d)1)1.': {
    id: 'D)8)d)1)1.',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)8)d)1)1.': {
        answerType: 'F',
        formule: '${D)8)d)1)}/${D)1)9.4)}*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '20',
        minOrange: '20',
        maxOrange: '30',
        minRouge: '30',
        maxRouge: '1000000',
      },
    },
  },
  'D)8)d)2)': {
    id: 'D)8)d)2)',
    name: 'Nombre de vêlages assistés',
    nameRapport: 'Vêlages assistés',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)8)d)2)': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)8)d)2)1.': {
    id: 'D)8)d)2)1.',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)8)d)2)1.': {
        answerType: 'F',
        formule: '${D)8)d)2)}/${D)1)9.4)}*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '20',
        minOrange: '20',
        maxOrange: '30',
        minRouge: '30',
        maxRouge: '1000000',
      },
    },
  },
  'D)8)d)3)': {
    id: 'D)8)d)3)',
    name: 'Nombre de vêlages avec vêleuse ou treuil',
    nameRapport: 'Vêlages avec vêleuse ou treuil',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)8)d)3)': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)8)d)3)1.': {
    id: 'D)8)d)3)1.',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)8)d)3)1.': {
        answerType: 'F',
        formule: '${D)8)d)3)}/${D)1)9.4)}*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '20',
        minOrange: '20',
        maxOrange: '30',
        minRouge: '30',
        maxRouge: '1000000',
      },
    },
  },
  'D)8)d)4)': {
    id: 'D)8)d)4)',
    name: 'Nombre de césariennes',
    nameRapport: 'Césariennes',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)8)d)4)': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)8)d)4)1.': {
    id: 'D)8)d)4)1.',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)8)d)4)1.': {
        answerType: 'F',
        formule: '${D)8)d)4)}/${D)1)9.4)}*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '20',
        minOrange: '20',
        maxOrange: '30',
        minRouge: '30',
        maxRouge: '1000000',
      },
    },
  },
  'D)8)d)5)': {
    id: 'D)8)d)5)',
    name: 'Nombre de vêlage avec une durée de la phase préliminaire trop longue',
    nameRapport: 'Vêlage avec une durée de la phase préliminaire trop longue',
    description: 'Environ 4h pour une vache, 8h pour une génisse',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)8)d)5)': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)8)d)5)1.': {
    id: 'D)8)d)5)1.',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)8)d)5)1.': {
        answerType: 'F',
        formule: '${D)8)d)5)}/${D)1)9.4)}*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '20',
        minOrange: '20',
        maxOrange: '30',
        minRouge: '30',
        maxRouge: '1000000',
      },
    },
  },
  'D)8)d)6)': {
    id: 'D)8)d)6)',
    name: 'Nombre de vêlages avec une expulsion du veau trop longue',
    nameRapport: 'Vêlages avec une expulsion du veau trop longue',
    description: 'Entre 2 et 10h, 3h pour une vache adulte',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)8)d)6)': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)8)d)6)1.': {
    id: 'D)8)d)6)1.',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)8)d)6)1.': {
        answerType: 'F',
        formule: '${D)8)d)6)}/${D)1)9.4)}*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '20',
        minOrange: '20',
        maxOrange: '30',
        minRouge: '30',
        maxRouge: '1000000',
      },
    },
  },
  'D)8)d)7)': {
    id: 'D)8)d)7)',
    name: 'Nombre de vêlages avec évacuation de liquide noir',
    nameRapport: 'Vêlages avec évacuation de liquide noir',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)8)d)7)': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)8)d)7)1.': {
    id: 'D)8)d)7)1.',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)8)d)7)1.': {
        answerType: 'F',
        formule: '${D)8)d)7)}/${D)1)9.4)}*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '20',
        minOrange: '20',
        maxOrange: '30',
        minRouge: '30',
        maxRouge: '1000000',
      },
    },
  },
  'D)8)d)8)': {
    id: 'D)8)d)8)',
    name: 'Nombre de vêlages avec mauvaise présentation',
    nameRapport: 'Vêlages avec mauvaise présentation',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)8)d)8)': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)8)d)8)1.': {
    id: 'D)8)d)8)1.',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)8)d)8)1.': {
        answerType: 'F',
        formule: '${D)8)d)8)}/${D)1)9.4)}*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '20',
        minOrange: '20',
        maxOrange: '30',
        minRouge: '30',
        maxRouge: '1000000',
      },
    },
  },
  'D)8)d)9)': {
    id: 'D)8)d)9)',
    name: 'Nombre de veaux avec une rupture du cordon ombilical trop court',
    nameRapport: 'Veaux avec une rupture du cordon ombilical trop court',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)8)d)9)': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)8)d)9)1.': {
    id: 'D)8)d)9)1.',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)8)d)9)1.': {
        answerType: 'F',
        formule: '${D)8)d)9)}/${D)1)9.4)}*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '20',
        minOrange: '20',
        maxOrange: '30',
        minRouge: '30',
        maxRouge: '1000000',
      },
    },
  },
  'D)8)d)av': {
    id: 'D)8)d)av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)8)d)av': {
        answerType: 'TI',
      },
    },
  },
  'D)8)e)': {
    id: 'D)8)e)',
    titre3: 'Après le vêlage :',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)8)e)1)': {
    id: 'D)8)e)1)',
    name: 'Nombre de non délivrances',
    nameRapport: 'Non délivrances',
    description: 'Le placenta doit être expulsé dans les 12 heures suivant le vêlage',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)8)e)1)': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)8)e)1)1.': {
    id: 'D)8)e)1)1.',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)8)e)1)1.': {
        answerType: 'F',
        formule: '${D)8)e)1)}/${D)1)9.4)}*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '20',
        minOrange: '20',
        maxOrange: '30',
        minRouge: '30',
        maxRouge: '1000000',
      },
    },
  },
  'D)8)e)2)': {
    id: 'D)8)e)2)',
    name: 'Nombre de vaches présentant des déchirures sur la paroi génitale après vérification par fouille',
    nameRapport: 'Vaches présentant des déchirures sur la paroi génitale après vérification par fouille',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)8)e)2)': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)8)e)2)1.': {
    id: 'D)8)e)2)1.',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)8)e)2)1.': {
        answerType: 'F',
        formule: '${D)8)e)2)}/${D)1)9.4)}*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '20',
        minOrange: '20',
        maxOrange: '30',
        minRouge: '30',
        maxRouge: '1000000',
      },
    },
  },
  'D)8)e)3)': {
    id: 'D)8)e)3)',
    name: 'Nombre de renversements de la matrice',
    nameRapport: 'Renversements de la matrice',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)8)e)3)': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)8)e)3)1.': {
    id: 'D)8)e)3)1.',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)8)e)3)1.': {
        answerType: 'F',
        formule: '${D)8)e)3)}/${D)1)9.4)}*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '20',
        minOrange: '20',
        maxOrange: '30',
        minRouge: '30',
        maxRouge: '1000000',
      },
    },
  },
  'D)8)e)4)': {
    id: 'D)8)e)4)',
    name: 'Nombre de vaches ne se relevant pas',
    nameRapport: 'Vaches ne se relevant pas',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)8)e)4)': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)8)e)4)1.': {
    id: 'D)8)e)4)1.',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)8)e)4)1.': {
        answerType: 'F',
        formule: '${D)8)e)4)}/${D)1)9.4)}*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '20',
        minOrange: '20',
        maxOrange: '30',
        minRouge: '30',
        maxRouge: '1000000',
      },
    },
  },
  'D)8)e)5)': {
    id: 'D)8)e)5)',
    name: 'Nombre de nouveaux nés avec des muqueuses violacées',
    nameRapport: 'Nouveaux nés avec des muqueuses violacées',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)8)e)5)': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)8)e)5)1.': {
    id: 'D)8)e)5)1.',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)8)e)5)1.': {
        answerType: 'F',
        formule: '${D)8)e)5)}/${D)1)9.4)}*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '20',
        minOrange: '20',
        maxOrange: '30',
        minRouge: '30',
        maxRouge: '1000000',
      },
    },
  },
  'D)8)e)av': {
    id: 'D)8)e)av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)8)e)av': {
        answerType: 'TI',
      },
    },
  },
  'D)8)f)': {
    id: 'D)8)f)',
    name: 'Les soins du veau :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)8)f)1/': {
        name: 'Absence de prise en charge du veau',
        nameRapport: 'chaque nouveau-né doit être pris en charge :dégagement des voies respiratoires, suspension par les pieds, séchage par friction énergique avec de la paille',
        catRapport: 'Recommandations :',
        description: 'Dégagement des voies respiratoires, suspension par les pieds, séchage par friction énergique avec de la paille',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)8)f)2/': {
        name: 'Absence de désinfection du cordon',
        nameRapport: 'la désinfection du cordon doit être systématique',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)8)f)3/': {
        name: 'Recours aux analeptiques respiratoires',
        nameRapport: 'recours aux analeptiques respiratoires (médicaments qui stimulent les fonctions physiologiques de la respiration)',
        catRapport: 'Recommandations :',
        description: 'Médicaments qui stimulent les fonctions physiologiques de la respiration',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)8)f)4/': {
        name: 'Présence de veaux «voleurs de colostrum»',
        nameRapport: 'le nouveau-né et sa mère doivent être isolés les premières heures pour éviter la présence de veaux « voleurs de colostrum »',
        catRapport: 'Recommandations :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)8)f)av': {
    id: 'D)8)f)av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)8)f)av': {
        answerType: 'TI',
      },
    },
  },
  'D)8)av#': {
    id: 'D)8)av#',
    name: 'L\'avis du véto (le vêlage)',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)8)av#': {
        answerType: 'TI',
      },
    },
  },
  'D)9)': {
    id: 'D)9)',
    titre2: 'La traite :',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)9)a)': {
    id: 'D)9)a)',
    name: 'Type de traite :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)9)a)1/': {
        name: 'Manuelle',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)a)2/': {
        name: 'Machine à traire fixe',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)a)3/': {
        name: 'Machine à traire ambulante',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)a)4/': {
        name: 'Monotraite',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)a)5/': {
        name: 'Robot',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)9)b)': {
    id: 'D)9)b)',
    name: 'Mesures d’hygiène avant la traite :',
    description: '',
    titre2Rapport: 'Les recommandations',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)9)b)1/': {
        name: 'Absence de lavage des mains du trayeur entre chaque traite',
        nameRapport: 'entre chaque traite, le trayeur doit se laver les mains',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)b)2/': {
        name: 'Absence de désinfection des mains du trayeur entre chaque traite',
        nameRapport: 'entre chaque traite, le trayeur doit se désinfecter les mains',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)b)3/': {
        name: 'Absence de lavage des trayons à l’eau tiède',
        nameRapport: 'les trayons doivent être lavés à l’eau tiède avant la traite',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)b)4/': {
        name: 'Absence d’essuyage des trayons',
        nameRapport: 'les trayons doivent être essuyés après lavage',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)b)5/': {
        name: 'Pas de lavettes à usage unique ou de système douchette serviette papier',
        nameRapport: 'vous devez utiliser des lavettes à usage unique ou un système « douchette - serviette papier »',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)b)6/': {
        name: 'Pas d’examen des premiers jets',
        nameRapport: 'vous devez examiner systématiquement les premiers jets',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)b)7/': {
        name: 'Pas de pré trempage',
        nameRapport: 'pré trempage est fortement recommandé',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)9)b)av': {
    id: 'D)9)b)av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)9)b)av': {
        answerType: 'TI',
      },
    },
  },
  'D)9)c)': {
    id: 'D)9)c)',
    name: 'Pratiques en cours de traite :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)9)c)1/': {
        name: 'Absence de décrochage automatique',
        nameRapport: 'entre chaque traite, le trayeur doit se laver les mains',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)c)2/': {
        name: 'Fuites d’air au niveau des gobelets trayeurs',
        nameRapport: 'vérifier régulièrement la présence de fuites d’air au niveau des gobelets trayeurs',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)c)3/': {
        name: 'Surtraite',
        nameRapport: 'la surtraite est source de mammites',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)9)c)av': {
    id: 'D)9)c)av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)9)c)av': {
        answerType: 'TI',
      },
    },
  },
  'D)9)d)': {
    id: 'D)9)d)',
    name: 'Post trempage :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)9)d)1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)d)2/': {
        name: 'Non',
        nameRapport: 'vous devriez réaliser un post trempage systématique',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)9)d)av': {
    id: 'D)9)d)av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)9)d)av': {
        answerType: 'TI',
      },
    },
  },
  'D)9)e)': {
    id: 'D)9)e)',
    name: 'Traite des vaches infectées :',
    description: 'Vaches atteintes de mammites cliniques ou à concentrations cellulaires élevées',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)9)e)1/': {
        name: 'Absence d’utilisation d’un faisceau trayeur supplémentaire',
        nameRapport: 'un faisceau trayeur supplémentaire doit être utilisé pour les vaches infectées',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)e)2/': {
        name: 'Non désinfection après chaque utilisation du faisceau trayeur supplémentaire',
        nameRapport: 'le faisceau trayeur supplémentaire doit être désinfecté après chaque utilisation',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)e)3/': {
        name: 'Vaches infectées non traites à la fin',
        nameRapport: 'les vaches infectées doivent être traites à la fin',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)e)4/': {
        name: 'Non identification des animaux par bracelet Velcro ou procédé équivalent',
        nameRapport: 'les animaux infectés doivent être identifiés par bracelet Velcro ou procédé équivalent',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)e)5/': {
        name: 'Non séparation du lait issu de vaches sous traitement',
        nameRapport: 'le lait issu de vaches sous traitement ne peut pas aller au tank',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)e)6/': {
        name: 'Non séparation du lait des 14 traites suivant le vêlage',
        nameRapport: 'le lait des 14 traites suivant le vêlage ne peut pas aller au tank',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)9)e)av': {
    id: 'D)9)e)av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)9)e)av': {
        answerType: 'TI',
      },
    },
  },
  'D)9)f)': {
    id: 'D)9)f)',
    name: 'Nettoyage de la machine à traire :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)9)f)1/': {
        name: 'Absence de nettoyage biquotidien',
        nameRapport: 'la machine à traire doit être nettoyée après chaque traite',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)f)2/': {
        name: 'Absence de prélavage à l’eau tiède',
        nameRapport: 'le prélavage à l’eau tiède est indispensable',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)f)3/': {
        name: 'Absence de nettoyage avec détergent + désinfectant',
        nameRapport: 'le nettoyage se fait à l’aide de détergent + désinfectant (alcalin chloré) en alternance avec un détartrant',
        description: 'Alcalin chloré',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)f)4/': {
        name: 'Absence de nettoyage avec détartrant',
        nameRapport: 'vous devez utiliser un détartrant (acide) lors du nettoyage de la machine à traire en alternance avec le détergent et le désinfectant',
        description: 'Acide',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)f)5/': {
        name: 'Absence d’alternance sur la journée de ces deux types de nettoyage',
        nameRapport: 'vous devez alterne sur la journée les deux types de nettoyage : détergent + désinfectant - détartrant',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)f)6/': {
        name: 'Absence de rinçage final à l’eau froide',
        nameRapport: 'le nettoyage de la machine à traire se termine par un rinçage final à l’eau froide',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)f)7/': {
        name: 'Absence de lavage et de désinfection des faisceaux trayeurs après la traite de vache infectée',
        nameRapport: 'après la traite de vache infectée, vous devez procéder au lavage et à la désinfection des faisceaux trayeurs',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)f)8/': {
        name: 'Concentration inappropriée de la solution de lavage',
        nameRapport: 'la concentration de la solution de lavage doit respecter la norme : entre 0,5 et 1 % ',
        description: 'Norme: entre 0,5 et 1 %',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)f)9/': {
        name: 'Durée inappropriée de la phase de lavage',
        nameRapport: 'la phase de lavage doit durer entre 8 et 10 minutes',
        description: 'Norme: entre 8 et 10 minutes',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)f)10/': {
        name: 'Température inappropriée de la solution de lavage',
        nameRapport: 'la température inappropriée de la solution de lavage doit se situer entre 50 et 70°c au début et au moins à 35°c à la fin',
        description: 'Normes: entre 50 et 70°c au début et au moins 35°c à la fin',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)9)f)av': {
    id: 'D)9)f)av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)9)f)av': {
        answerType: 'TI',
      },
    },
  },
  'D)9)g)': {
    id: 'D)9)g)',
    name: 'Nettoyage des locaux de traite :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)9)g)1/': {
        name: 'Absence de raclage des bouses après chaque traite',
        nameRapport: 'les bouses sont raclées après chaque traite',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)g)2/': {
        name: 'Absence de lavage de la salle de traite',
        nameRapport: 'vous devez impérativement laver la salle de traite deux fois par jour',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)g)3/': {
        name: 'Absence de lavage après chaque traite',
        nameRapport: 'vous devez laver la salle de traite après chaque traite',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)g)4/': {
        name: 'Absence de lavage de l’aire d’attente',
        nameRapport: 'vous devez laver l’aire d’attente après chaque traite',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)g)5/': {
        name: 'Absence de lavage des murs',
        nameRapport: 'vous devez laver régulièrement les murs de la salle de traite ',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)g)6/': {
        name: 'Absence de lavage des sols',
        nameRapport: 'vous devez laver les sols des locaux de traite',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)g)7/': {
        name: 'Absence de lavage des tubulures',
        nameRapport: 'vous devez laver régulièrement les tubulures',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)g)8/': {
        name: 'Non respect du sens du lavage',
        nameRapport: 'vous devez respecter un sens du lavage : de la salle de traite vers l’aire d’attente',
        description: 'Norme: de la salle de traite vers l’aire d’attente',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)9)g)av': {
    id: 'D)9)g)av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)9)g)av': {
        answerType: 'TI',
      },
    },
  },
  'D)9)h)': {
    id: 'D)9)h)',
    name: 'Maintenance de la machine à traire :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)9)h)1/': {
        name: 'Absence de contrôle annuel de l’installation par un agent agréé',
        nameRapport: 'l’installation doit être contrôlée annuellement par un agent agréé',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)h)2/': {
        name: 'Absence de contrôles et entretien réguliers par l’éleveur',
        nameRapport: '/l’éleveur doit contrôler et entretenir régulièrement la machine à traire',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)9)h)av': {
    id: 'D)9)h)av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)9)h)av': {
        answerType: 'TI',
      },
    },
  },
  'D)9)i)': {
    id: 'D)9)i)',
    name: 'Comportement des animaux après la traite :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)9)i)1/': {
        name: 'Absence de distribution systématique d’une ration après la traite',
        nameRapport: 'une ration doit être systématiquement distribuée après la traite',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)i)2/': {
        name: 'La conduite d’élevage ne prévoit pas d’empêcher les vaches de pouvoir se coucher juste après la traite',
        nameRapport: 'les vaches ne doivent pas pouvoir se coucher juste après la traite',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)i)3/': {
        name: 'En cas d’une longue traite et d’impossibilité pour les vaches de se coucher, la conduite d’élevage ne prévoit pas de libérer les premières traitestout en contenant les dernières',
        nameRapport: 'en cas d’une longue traite (plus d’une heure) et d’impossibilité pour les vaches de se coucher, les premières traites doivent pouvoir être libérées tout en contenant les dernières',
        description: 'Plus d’une heure',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)9)i)av': {
    id: 'D)9)i)av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)9)i)av': {
        answerType: 'TI',
      },
    },
  },
  'D)9)j)': {
    id: 'D)9)j)',
    name: 'Lutte contre les infections de la mamelle :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'D)9)j)1/': {
        name: 'Absence de traitement immédiat des mammites cliniques',
        description: ' - mammite : inflammation et infection d’une ou de plusieurs glandes mammaires\\n - clinique : qui est décelé par examen direct du sujet',
        nameRapport: 'les mammites (inflammation et infection d’une ou de plusieurs glandes mammaires) cliniques (qui est décelé par examen direct du sujet) doivent être traitées immédiatement',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)j)2/': {
        name: 'Traitement incomplet des mammites cliniques',
        nameRapport: 'chaque mammites (inflammation et infection d’une ou de plusieurs glandes mammaires) cliniques (qui est décelé par examen direct du sujet) doit être traitée complétement : antibiotiques par voie générale et intra mammaire associé à un anti-inflammatoire',
        description: ' - traitement complet: antibiotiques par voie générale et intramammaire associé à un anti-inflammatoire\\n - mammite : inflammation et infection d’une ou de plusieurs glandes mammaires\\n - clinique : qui est décelé par examen direct du sujet',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)j)3/': {
        name: 'Non respect de la durée du traitement indiqué sur la prescription du vétérinaire',
        nameRapport: 'la durée du traitement indiqué sur la prescription du vétérinaire doit être respectée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)j)4/': {
        name: 'Absence de traitement des mammites subcliniques (cellules) au tarissement',
        nameRapport: 'les mammites subcliniques (cellules) (à peine décelable à l’examen clinique) doivent être traitées au tarissement',
        description: 'à peine décelable à l’examen clinique',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)j)5/': {
        name: 'Non respect des précautions d’hygiène lors des traitements intramammaire',
        nameRapport: 'des précautions d’hygiène lors des traitements intra mammaire doivent être respectées : nettoyer soigneusement et désinfecter l’extrémité du trayon à traiter, introduire l’injecteur délicatement dans le canal du trayon pour ne pas le léser',
        description: 'Nettoyer soigneusement et désinfecter l’extrémité du trayon à traiter, introduire l’injecteur délicatement dans le canal du trayon pour ne pas le léser',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)9)j)6/': {
        name: 'Pas de réforme systématique des vaches incurables',
        nameRapport: 'les vaches incurables (vaches présentant des mammites cliniques à répétition, vaches infectées subcliniques, cellules), non guéries par le traitement hors lactation doivent être systématiquement réformées',
        description: 'Vaches présentant des mammites cliniques à répétition, vaches infectées subcliniques (cellules), non guéries par le traitement hors lactation',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)9)j)av': {
    id: 'D)9)j)av',
    name: 'L\'avis du véto',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)9)j)av': {
        answerType: 'TI',
      },
    },
  },
  'D)9)av#': {
    id: 'D)9)av#',
    name: 'L\'avis du véto (la traite)',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)9)av#': {
        answerType: 'TI',
      },
    },
  },
  'D)10)': {
    id: 'D)10)',
    titre2: 'La production laitière :',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)10)a)': {
    id: 'D)10)a)',
    name: 'Production moyenne / vache :',
    description: 'en litres par jour sur une durée de 10 mois',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)10)a)': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)10)b)': {
    id: 'D)10)b)',
    titre3: 'Numérations cellulaires (moyenne géométrique sur les 6 derniers mois et (ou) moyenne annuelle en cell./ml):',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)10)b)1.': {
    id: 'D)10)b)1.',
    name: 'M-6',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)10)b)1.': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)10)b)2.': {
    id: 'D)10)b)2.',
    name: 'M-5',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)10)b)2.': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)10)b)3.': {
    id: 'D)10)b)3.',
    name: 'M-4',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)10)b)3.': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)10)b)4.': {
    id: 'D)10)b)4.',
    name: 'M-3',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)10)b)4.': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)10)b)5.': {
    id: 'D)10)b)5.',
    name: 'M-2',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)10)b)5.': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)10)b)6.': {
    id: 'D)10)b)6.',
    name: 'M-1',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)10)b)6.': {
        answerType: 'NI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)10)b)7.': {
    id: 'D)10)b)7.',
    name: 'Moyenne annuelle',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)10)b)7.': {
        answerType: 'NI',
        formule: '',
        minVert: '1',
        maxVert: '250000',
        minOrange: '250000',
        maxOrange: '800000',
        minRouge: '800000',
        maxRouge: '100000000000',
      },
    },
  },
  'D)10)c)': {
    id: 'D)10)c)',
    titre3: 'Statut sanitaire :',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)10)c)1.': {
    id: 'D)10)c)1.',
    name: 'Fièvre Q :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)10)c)1.1/': {
        name: 'Positif',
        bseColor: 'red',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)10)c)1.2/': {
        name: 'Négatif',
        bseColor: 'green',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)10)c)1.3/': {
        name: 'Non déterminé',
        bseColor: 'yellow',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)10)c)2.': {
    id: 'D)10)c)2.',
    name: 'Salmonellose :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)10)c)2.1/': {
        name: 'Positif',
        bseColor: 'red',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)10)c)2.2/': {
        name: 'Négatif',
        bseColor: 'green',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)10)c)2.3/': {
        name: 'Non déterminé',
        bseColor: 'yellow',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)10)c)3.': {
    id: 'D)10)c)3.',
    name: 'Listériose :',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)10)c)3.1/': {
        name: 'Positif',
        bseColor: 'red',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)10)c)3.2/': {
        name: 'Négatif',
        bseColor: 'green',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)10)c)3.3/': {
        name: 'Non déterminé',
        bseColor: 'yellow',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)10)av#': {
    id: 'D)10)av#',
    name: 'L\'avis du véto (la production laitière)',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)10)av#': {
        answerType: 'TI',
      },
    },
  },
  'D)11)': {
    id: 'D)11)',
    titre2: 'La mortalité par classe d\'âge dans l\'élevage laitier :',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)11)a)': {
    id: 'D)11)a)',
    titre3: '0 à 1 mois',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
    function: 'R3',
  },
  'D)11)a)1.': {
    id: 'D)11)a)1.',
    name: 'Motif',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)11)a)1.10/': {
        name: 'Affections de l\'appareil urinaire',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)11)a)1.27/': {
        name: 'Diarrhées néonatales',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)11)a)1.35/': {
        name: 'Affections respiratoires',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)11)a)1.36/': {
        name: 'VRSB',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)11)a)1.39/': {
        name: 'Maladies du nombril',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)11)a)1.41/': {
        name: 'Affections articulaires',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)11)a)1.49/': {
        name: 'Autres',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)11)a)2.': {
    id: 'D)11)a)2.',
    name: 'Nombre',
    hideRapport: true,
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)11)a)2.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)11)a)3.': {
    id: 'D)11)a)3.',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)11)a)3.': {
        answerType: 'F',
        formule: '${D)11)a)2.}/${D)1)9.4)}*100',
        suffix: ' %',
        round: 2,
        minVert: '${D)11)a)1.}',
        maxVert: '${D)11)a)1.}',
        minOrange: '${D)11)a)1.}',
        maxOrange: '${D)11)a)1.}',
        minRouge: '${D)11)a)1.}',
        maxRouge: '${D)11)a)1.}',
      },
    },
  },
  'D)11)b)': {
    id: 'D)11)b)',
    titre4: '1 à 6 mois',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
    function: 'R3',
  },
  'D)11)b)1.': {
    id: 'D)11)b)1.',
    name: 'Motif',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)11)b)1.10/': {
        name: 'Affections de l\'appareil urinaire',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)11)b)1.27/': {
        name: 'Diarrhées néonatales',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)11)b)1.28/': {
        name: 'Météorisation gazeuses chroniques du veau',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)11)b)1.35/': {
        name: 'Affections respiratoires',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)11)b)1.36/': {
        name: 'VRSB',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)11)b)1.39/': {
        name: 'Maladies du nombril',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)11)b)1.41/': {
        name: 'Affections articulaires',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)11)b)1.49/': {
        name: 'Autres (TI)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)11)b)2.': {
    id: 'D)11)b)2.',
    name: 'Nombre',
    hideRapport: true,
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)11)b)2.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)11)b)3.': {
    id: 'D)11)b)3.',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)11)b)3.': {
        answerType: 'F',
        formule: '${D)11)b)2.}/${D)1)9.4))}*100',
        suffix: ' %',
        round: 2,
        minVert: '${D)11)b)1.}',
        maxVert: '${D)11)b)1.}',
        minOrange: '${D)11)b)1.}',
        maxOrange: '${D)11)b)1.}',
        minRouge: '${D)11)b)1.}',
        maxRouge: '${D)11)b)1.}',
      },
    },
  },
  'D)11)c)': {
    id: 'D)11)c)',
    titre4: '7 à 24 mois',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
    function: 'R3',
  },
  'D)11)c)1.': {
    id: 'D)11)c)1.',
    name: 'Motif',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)11)c)1.10/': {
        name: 'Affections de l\'appareil urinaire',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)11)c)1.22/': {
        name: 'Météorisations',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)11)c)1.23/': {
        name: 'Réticulite traumatique (corps étranger)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)11)c)1.26/': {
        name: 'Diarrhées du jeune bovin et de l\'adulte',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)11)c)1.28/': {
        name: 'Météorisation gazeuses chroniques du veau',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)11)c)1.31/': {
        name: 'Acidoses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)11)c)1.35/': {
        name: 'Affections respiratoires',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)11)c)1.36/': {
        name: 'VRSB',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)11)c)1.37/': {
        name: 'BPIE',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)11)c)1.38/': {
        name: 'Pasteurelloses et mycoplasmoses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)11)c)1.39/': {
        name: 'Maladies du nombril',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)11)c)1.41/': {
        name: 'Affections articulaires',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)11)c)1.42/': {
        name: 'Fractures',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)11)c)1.43/': {
        name: 'Maladies du système nerveux et du comportement',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)11)c)1.44/': {
        name: 'Parasitisme',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '10',
        minOrange: '10',
        maxOrange: '20',
        minRouge: '20',
        maxRouge: '100000',
      },
      'D)11)c)1.45/': {
        name: 'BVD',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)11)c)1.46/': {
        name: 'IBR',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)11)c)1.47/': {
        name: 'Salmonelloses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)11)c)1.48/': {
        name: 'Toxi-infections',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)11)c)2.': {
    id: 'D)11)c)2.',
    name: 'Nombre',
    hideRapport: true,
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)11)c)2.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)11)c)3.': {
    id: 'D)11)c)3.',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)11)c)3.': {
        answerType: 'F',
        formule: '${D)11)c)2.}/(${D)1)8.12)}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '${D)11)c)1.}',
        maxVert: '${D)11)c)1.}',
        minOrange: '${D)11)c)1.}',
        maxOrange: '${D)11)c)1.}',
        minRouge: '${D)11)c)1.}',
        maxRouge: '${D)11)c)1.}',
      },
    },
  },
  'D)11)d)': {
    id: 'D)11)d)',
    titre4: '> 24 mois',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
    function: 'R3',
  },
  'D)11)d)1.': {
    id: 'D)11)d)1.',
    name: 'Motif',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)11)d)1.1/': {
        name: 'Traumatismes du trayon',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)11)d)1.3/': {
        name: 'Mammites avec signes généraux',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '30',
        minOrange: '30',
        maxOrange: '50',
        minRouge: '50',
        maxRouge: '100000',
      },
      'D)11)d)1.7/': {
        name: 'Prolapsus et non délivrance',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '8',
        minRouge: '8',
        maxRouge: '100000',
      },
      'D)11)d)1.8/': {
        name: 'Métrites',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)11)d)1.9/': {
        name: 'Avortements',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)11)d)1.10/': {
        name: 'Affections de l\'appareil urinaire',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)11)d)1.22/': {
        name: 'Météorisations',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)11)d)1.23/': {
        name: 'Réticulite traumatique (corps étranger)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)11)d)1.24/': {
        name: 'Déplacements et volvulus de la caillette',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)11)d)1.25/': {
        name: 'Dysenterie d\'hiver',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)11)d)1.26/': {
        name: 'Diarrhées du jeune bovin et de l\'adulte',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)11)d)1.29/': {
        name: 'Fièvre de lait',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)11)d)1.30/': {
        name: 'Tétanie d\'herbage',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)11)d)1.31/': {
        name: 'Acidoses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)11)d)1.32/': {
        name: 'Acétonémie',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '4',
        minOrange: '4',
        maxOrange: '8',
        minRouge: '8',
        maxRouge: '100000',
      },
      'D)11)d)1.35/': {
        name: 'Affections respiratoires',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)11)d)1.36/': {
        name: 'VRSB',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)11)d)1.37/': {
        name: 'BPIE',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)11)d)1.38/': {
        name: 'Pasteurelloses et mycoplasmoses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)11)d)1.40/': {
        name: 'Affections du pied',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)11)d)1.41/': {
        name: 'Affections articulaires',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)11)d)1.42/': {
        name: 'Fractures',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)11)d)1.43/': {
        name: 'Maladies du système nerveux et du comportement',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)11)d)1.44/': {
        name: 'Parasitisme',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '10',
        minOrange: '10',
        maxOrange: '20',
        minRouge: '20',
        maxRouge: '100000',
      },
      'D)11)d)1.45/': {
        name: 'BVD',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)11)d)1.46/': {
        name: 'IBR',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)11)d)1.47/': {
        name: 'Salmonelloses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)11)d)1.48/': {
        name: 'Toxi-infections',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)11)d)2.': {
    id: 'D)11)d)2.',
    name: 'Nombre',
    hideRapport: true,
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)11)d)2.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)11)d)3.': {
    id: 'D)11)d)3.',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)11)d)3.': {
        answerType: 'F',
        formule: '${D)11)d)2.}/(${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)})*100',
        suffix: ' %',
        round: 2,
        minVert: '${D)11)d)1.}',
        maxVert: '${D)11)d)1.}',
        minOrange: '${D)11)d)1.}',
        maxOrange: '${D)11)d)1.}',
        minRouge: '${D)11)d)1.}',
        maxRouge: '${D)11)d)1.}',
      },
    },
  },
  'D)11)av#': {
    id: 'D)11)av#',
    name: 'L\'avis du véto (la mortalité par classe d’âge)',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)11)av#': {
        answerType: 'TI',
      },
    },
  },
  'D)12)': {
    id: 'D)12)',
    titre2: 'La mortalité par classe d\'âge dans l\'élevage allaitant : ',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)12)a)': {
    id: 'D)12)a)',
    titre3: 'De la naissance au sevrage',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
    function: 'R3',
  },
  'D)12)a)1.': {
    id: 'D)12)a)1.',
    name: 'Motif',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)12)a)1.10/': {
        name: 'Affections de l\'appareil urinaire',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)12)a)1.27/': {
        name: 'Diarrhées néonatales',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)12)a)1.28/': {
        name: 'Météorisation gazeuses chroniques du veau',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)12)a)1.35/': {
        name: 'Affections respiratoires',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)12)a)1.36/': {
        name: 'VRSB',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)12)a)1.38/': {
        name: 'Pasteurelloses et mycoplasmoses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)12)a)1.39/': {
        name: 'Maladies du nombril',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)12)a)1.41/': {
        name: 'Affections articulaires',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)12)a)1.42/': {
        name: 'Fractures',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)12)a)1.43/': {
        name: 'Maladies du système nerveux et du comportement',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)12)a)1.45/': {
        name: 'BVD',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)12)a)1.47/': {
        name: 'Salmonelloses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)12)a)1.48/': {
        name: 'Toxi-infections',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)12)a)2.': {
    id: 'D)12)a)2.',
    name: 'Nombre',
    hideRapport: true,
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)12)a)2.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)12)a)3.': {
    id: 'D)12)a)3.',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)12)a)3.': {
        answerType: 'F',
        formule: '${D)12)a)2.}/(${D)1)9.4)})*100',
        suffix: ' %',
        round: 2,
        minVert: '${D)12)a)1.}',
        maxVert: '${D)12)a)1.}',
        minOrange: '${D)12)a)1.}',
        maxOrange: '${D)12)a)1.}',
        minRouge: '${D)12)a)1.}',
        maxRouge: '${D)12)a)1.}',
      },
    },
  },
  'D)12)b)': {
    id: 'D)12)b)',
    titre4: 'Du sevrage à la mise à la reproduction',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
    function: 'R3',
  },
  'D)12)b)1.': {
    id: 'D)12)b)1.',
    name: 'Motif',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)12)b)1.10/': {
        name: 'Affections de l\'appareil urinaire',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)12)b)1.22/': {
        name: 'Météorisations',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)12)b)1.23/': {
        name: 'Réticulite traumatique (corps étranger)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)12)b)1.26/': {
        name: 'Diarrhées du jeune bovin et de l\'adulte',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)12)b)1.31/': {
        name: 'Acidoses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)12)b)1.35/': {
        name: 'Affections respiratoires',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)12)b)1.36/': {
        name: 'VRSB',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)12)b)1.37/': {
        name: 'BPIE',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)12)b)1.38/': {
        name: 'Pasteurelloses et mycoplasmoses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)12)b)1.39/': {
        name: 'Maladies du nombril',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)12)b)1.40/': {
        name: 'Affections du pied',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)12)b)1.41/': {
        name: 'Affections articulaires',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)12)b)1.42/': {
        name: 'Fractures',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)12)b)1.43/': {
        name: 'Maladies du système nerveux et du comportement',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)12)b)1.44/': {
        name: 'Parasitisme',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '10',
        minOrange: '10',
        maxOrange: '20',
        minRouge: '20',
        maxRouge: '100000',
      },
      'D)12)b)1.45/': {
        name: 'BVD',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)12)b)1.46/': {
        name: 'IBR',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)12)b)1.47/': {
        name: 'Salmonelloses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)12)b)1.48/': {
        name: 'Toxi-infections',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)12)b)2.': {
    id: 'D)12)b)2.',
    name: 'Nombre',
    hideRapport: true,
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)12)b)2.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)12)b)3.': {
    id: 'D)12)b)3.',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)12)b)3.': {
        answerType: 'F',
        formule: '${D)12)b)2.}/(${D)1)8.12)}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '${D)12)b)1.}',
        maxVert: '${D)12)b)1.}',
        minOrange: '${D)12)b)1.}',
        maxOrange: '${D)12)b)1.}',
        minRouge: '${D)12)b)1.}',
        maxRouge: '${D)12)b)1.}',
      },
    },
  },
  'D)12)c)': {
    id: 'D)12)c)',
    titre4: 'Génisses de 2 ans et plus',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
    function: 'R3',
  },
  'D)12)c)1.': {
    id: 'D)12)c)1.',
    name: 'Motif',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)12)c)1.6/': {
        name: 'Affections du vagins',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)12)c)1.7/': {
        name: 'Prolapsus et non délivrance',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '8',
        minRouge: '8',
        maxRouge: '100000',
      },
      'D)12)c)1.9/': {
        name: 'Avortements',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)12)c)1.10/': {
        name: 'Affections de l\'appareil urinaire',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)12)c)1.22/': {
        name: 'Météorisations',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)12)c)1.23/': {
        name: 'Réticulite traumatique (corps étranger)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)12)c)1.25/': {
        name: 'Dysenterie d\'hiver',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)12)c)1.26/': {
        name: 'Diarrhées du jeune bovin et de l\'adulte',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)12)c)1.30/': {
        name: 'Tétanie d\'herbage',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)12)c)1.31/': {
        name: 'Acidoses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)12)c)1.35/': {
        name: 'Affections respiratoires',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)12)c)1.36/': {
        name: 'VRSB',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)12)c)1.37/': {
        name: 'BPIE',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)12)c)1.38/': {
        name: 'Pasteurelloses et mycoplasmoses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)12)c)1.40/': {
        name: 'Affections du pied',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)12)c)1.41/': {
        name: 'Affections articulaires',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)12)c)1.42/': {
        name: 'Fractures',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)12)c)1.43/': {
        name: 'Maladies du système nerveux et du comportement',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)12)c)1.44/': {
        name: 'Parasitisme',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '10',
        minOrange: '10',
        maxOrange: '20',
        minRouge: '20',
        maxRouge: '100000',
      },
      'D)12)c)1.45/': {
        name: 'BVD',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)12)c)1.46/': {
        name: 'IBR',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)12)c)1.47/': {
        name: 'Salmonelloses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)12)c)1.48/': {
        name: 'Toxi-infections',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)12)c)2.': {
    id: 'D)12)c)2.',
    name: 'Nombre',
    hideRapport: true,
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)12)c)2.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)12)c)3.': {
    id: 'D)12)c)3.',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)12)c)3.': {
        answerType: 'F',
        formule: '${D)12)c)2.}/(${D)1)8.4)})*100',
        suffix: ' %',
        round: 2,
        minVert: '${D)12)c)1.}',
        maxVert: '${D)12)c)1.}',
        minOrange: '${D)12)c)1.}',
        maxOrange: '${D)12)c)1.}',
        minRouge: '${D)12)c)1.}',
        maxRouge: '${D)12)c)1.}',
      },
    },
  },
  'D)12)d)': {
    id: 'D)12)d)',
    titre4: 'Vaches',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
    function: 'R3',
  },
  'D)12)d)1.': {
    id: 'D)12)d)1.',
    name: 'Motif',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)12)d)1.6/': {
        name: 'Affections du vagins',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)12)d)1.7/': {
        name: 'Prolapsus et non délivrance',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '8',
        minRouge: '8',
        maxRouge: '100000',
      },
      'D)12)d)1.8/': {
        name: 'Métrites',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)12)d)1.9/': {
        name: 'Avortements',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)12)d)1.10/': {
        name: 'Affections de l\'appareil urinaire',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)12)d)1.22/': {
        name: 'Météorisations',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)12)d)1.23/': {
        name: 'Réticulite traumatique (corps étranger)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)12)d)1.24/': {
        name: 'Déplacements et volvulus de la caillette',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)12)d)1.25/': {
        name: 'Dysenterie d\'hiver',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)12)d)1.26/': {
        name: 'Diarrhées du jeune bovin et de l\'adulte',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)12)d)1.30/': {
        name: 'Tétanie d\'herbage',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)12)d)1.31/': {
        name: 'Acidoses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)12)d)1.35/': {
        name: 'Affections respiratoires',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)12)d)1.36/': {
        name: 'VRSB',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)12)d)1.37/': {
        name: 'BPIE',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)12)d)1.38/': {
        name: 'Pasteurelloses et mycoplasmoses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)12)d)1.40/': {
        name: 'Affections du pied',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)12)d)1.41/': {
        name: 'Affections articulaires',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)12)d)1.42/': {
        name: 'Fractures',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)12)d)1.44/': {
        name: 'Parasitisme',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '10',
        minOrange: '10',
        maxOrange: '20',
        minRouge: '20',
        maxRouge: '100000',
      },
      'D)12)d)1.45/': {
        name: 'BVD',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)12)d)1.46/': {
        name: 'IBR',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)12)d)1.47/': {
        name: 'Salmonelloses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)12)d)1.48/': {
        name: 'Toxi-infections',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)12)d)2.': {
    id: 'D)12)d)2.',
    name: 'Nombre',
    hideRapport: true,
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)12)d)2.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)12)d)3.': {
    id: 'D)12)d)3.',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)12)d)3.': {
        answerType: 'F',
        formule: '${D)12)d)2.}/(${D)1)8.5)})*100',
        suffix: ' %',
        round: 2,
        minVert: '${D)12)d)1.}',
        maxVert: '${D)12)d)1.}',
        minOrange: '${D)12)d)1.}',
        maxOrange: '${D)12)d)1.}',
        minRouge: '${D)12)d)1.}',
        maxRouge: '${D)12)d)1.}',
      },
    },
  },
  'D)12)av#': {
    id: 'D)12)av#',
    name: 'L\'avis du véto (la mortalité par classe d’âge)',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)12)av#': {
        answerType: 'TI',
      },
    },
  },
  'D)13)': {
    id: 'D)13)',
    titre2: 'La mortalité par classe d\'âge à l\'engraissement :',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)13)1.': {
    id: 'D)13)1.',
    titre3: 'Bande précédente :',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)13)1.a)': {
    id: 'D)13)1.a)',
    titre4: 'Avant 2 mois',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
    function: 'R3',
  },
  'D)13)1.a)1.': {
    id: 'D)13)1.a)1.',
    name: 'Motif',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)13)1.a)1.10/': {
        name: 'Affections de l\'appareil urinaire',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)13)1.a)1.22/': {
        name: 'Météorisations',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)13)1.a)1.26/': {
        name: 'Diarrhées du jeune bovin et de l\'adulte',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)13)1.a)1.28/': {
        name: 'Météorisation gazeuses chroniques du veau',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)13)1.a)1.31/': {
        name: 'Acidoses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)13)1.a)1.35/': {
        name: 'Affections respiratoires',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)1.a)1.36/': {
        name: 'VRSB',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)1.a)1.37/': {
        name: 'BPIE',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)1.a)1.38/': {
        name: 'Pasteurelloses et mycoplasmoses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)1.a)1.39/': {
        name: 'Maladies du nombril',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)1.a)1.40/': {
        name: 'Affections du pied',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)1.a)1.41/': {
        name: 'Affections articulaires',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)1.a)1.42/': {
        name: 'Fractures',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)1.a)1.43/': {
        name: 'Maladies du système nerveux et du comportement',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)13)1.a)1.44/': {
        name: 'Parasitisme',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '10',
        minOrange: '10',
        maxOrange: '20',
        minRouge: '20',
        maxRouge: '100000',
      },
      'D)13)1.a)1.45/': {
        name: 'BVD',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)13)1.a)1.46/': {
        name: 'IBR',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)13)1.a)1.47/': {
        name: 'Salmonelloses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)13)1.a)1.48/': {
        name: 'Toxi-infections',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)13)1.a)2.': {
    id: 'D)13)1.a)2.',
    name: 'Nombre',
    hideRapport: true,
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)13)1.a)2.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)13)1.a)3.': {
    id: 'D)13)1.a)3.',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)13)1.a)3.': {
        answerType: 'F',
        formule: '${D)13)1.a)2.}/(${D)2)16.})*100',
        suffix: ' %',
        round: 2,
        minVert: '${D)13)1.a)1.}',
        maxVert: '${D)13)1.a)1.}',
        minOrange: '${D)13)1.a)1.}',
        maxOrange: '${D)13)1.a)1.}',
        minRouge: '${D)13)1.a)1.}',
        maxRouge: '${D)13)1.a)1.}',
      },
    },
  },
  'D)13)1.b)': {
    id: 'D)13)1.b)',
    titre4: '2 à 3 mois',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
    function: 'R3',
  },
  'D)13)1.b)1.': {
    id: 'D)13)1.b)1.',
    name: 'Motif',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)13)1.b)1.10/': {
        name: 'Affections de l\'appareil urinaire',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)13)1.b)1.22/': {
        name: 'Météorisations',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)13)1.b)1.26/': {
        name: 'Diarrhées du jeune bovin et de l\'adulte',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)13)1.b)1.28/': {
        name: 'Météorisation gazeuses chroniques du veau',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)13)1.b)1.31/': {
        name: 'Acidoses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)13)1.b)1.35/': {
        name: 'Affections respiratoires',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)1.b)1.36/': {
        name: 'VRSB',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)1.b)1.37/': {
        name: 'BPIE',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)1.b)1.38/': {
        name: 'Pasteurelloses et mycoplasmoses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)1.b)1.39/': {
        name: 'Maladies du nombril',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)1.b)1.40/': {
        name: 'Affections du pied',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)1.b)1.41/': {
        name: 'Affections articulaires',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)1.b)1.42/': {
        name: 'Fractures',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)1.b)1.43/': {
        name: 'Maladies du système nerveux et du comportement',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)13)1.b)1.44/': {
        name: 'Parasitisme',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '10',
        minOrange: '10',
        maxOrange: '20',
        minRouge: '20',
        maxRouge: '100000',
      },
      'D)13)1.b)1.45/': {
        name: 'BVD',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)13)1.b)1.46/': {
        name: 'IBR',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)13)1.b)1.47/': {
        name: 'Salmonelloses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)13)1.b)1.48/': {
        name: 'Toxi-infections',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)13)1.b)2.': {
    id: 'D)13)1.b)2.',
    name: 'Nombre',
    hideRapport: true,
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)13)1.b)2.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)13)1.b)3.': {
    id: 'D)13)1.b)3.',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)13)1.b)3.': {
        answerType: 'F',
        formule: '${D)13)1.b)2.}/(${D)2)17.})*100',
        suffix: ' %',
        round: 2,
        minVert: '${D)13)1.b)1.}',
        maxVert: '${D)13)1.b)1.}',
        minOrange: '${D)13)1.b)1.}',
        maxOrange: '${D)13)1.b)1.}',
        minRouge: '${D)13)1.b)1.}',
        maxRouge: '${D)13)1.b)1.}',
      },
    },
  },
  'D)13)1.c)': {
    id: 'D)13)1.c)',
    titre4: '> 3 mois',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
    function: 'R3',
  },
  'D)13)1.c)1.': {
    id: 'D)13)1.c)1.',
    name: 'Motif',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)13)1.c)1.10/': {
        name: 'Affections de l\'appareil urinaire',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)13)1.c)1.22/': {
        name: 'Météorisations',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)13)1.c)1.26/': {
        name: 'Diarrhées du jeune bovin et de l\'adulte',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)13)1.c)1.28/': {
        name: 'Météorisation gazeuses chroniques du veau',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)13)1.c)1.31/': {
        name: 'Acidoses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)13)1.c)1.35/': {
        name: 'Affections respiratoires',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)1.c)1.36/': {
        name: 'VRSB',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)1.c)1.37/': {
        name: 'BPIE',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)1.c)1.38/': {
        name: 'Pasteurelloses et mycoplasmoses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)1.c)1.39/': {
        name: 'Maladies du nombril',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)1.c)1.40/': {
        name: 'Affections du pied',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)1.c)1.41/': {
        name: 'Affections articulaires',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)1.c)1.42/': {
        name: 'Fractures',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)1.c)1.43/': {
        name: 'Maladies du système nerveux et du comportement',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)13)1.c)1.44/': {
        name: 'Parasitisme',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '10',
        minOrange: '10',
        maxOrange: '20',
        minRouge: '20',
        maxRouge: '100000',
      },
      'D)13)1.c)1.45/': {
        name: 'BVD',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)13)1.c)1.46/': {
        name: 'IBR',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)13)1.c)1.47/': {
        name: 'Salmonelloses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)13)1.c)1.48/': {
        name: 'Toxi-infections',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)13)1.c)2.': {
    id: 'D)13)1.c)2.',
    name: 'Nombre',
    hideRapport: true,
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)13)1.c)2.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)13)1.c)3.': {
    id: 'D)13)1.c)3.',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)13)1.c)3.': {
        answerType: 'F',
        formule: '${D)13)1.c)2.}/(${D)2)18.})*100',
        suffix: ' %',
        round: 2,
        minVert: '${D)13)1.c)1.}',
        maxVert: '${D)13)1.c)1.}',
        minOrange: '${D)13)1.c)1.}',
        maxOrange: '${D)13)1.c)1.}',
        minRouge: '${D)13)1.c)1.}',
        maxRouge: '${D)13)1.c)1.}',
      },
    },
  },
  'D)13)2.2.': {
    id: 'D)13)2.2.',
    titre4: 'Bande présente au moment du bilan :',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)13)2.a)': {
    id: 'D)13)2.a)',
    titre4: 'Avant 2 mois',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
    function: 'R3',
  },
  'D)13)2.a)1.': {
    id: 'D)13)2.a)1.',
    titre4: 'Motif',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)13)2.a)1.10/': {
        name: 'Affections de l\'appareil urinaire',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)13)2.a)1.22/': {
        name: 'Météorisations',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)13)2.a)1.26/': {
        name: 'Diarrhées du jeune bovin et de l\'adulte',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)13)2.a)1.28/': {
        name: 'Météorisation gazeuses chroniques du veau',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)13)2.a)1.31/': {
        name: 'Acidoses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)13)2.a)1.35/': {
        name: 'Affections respiratoires',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)2.a)1.36/': {
        name: 'VRSB',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)2.a)1.37/': {
        name: 'BPIE',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)2.a)1.38/': {
        name: 'Pasteurelloses et mycoplasmoses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)2.a)1.39/': {
        name: 'Maladies du nombril',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)2.a)1.40/': {
        name: 'Affections du pied',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)2.a)1.41/': {
        name: 'Affections articulaires',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)2.a)1.42/': {
        name: 'Fractures',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)2.a)1.43/': {
        name: 'Maladies du système nerveux et du comportement',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)13)2.a)1.44/': {
        name: 'Parasitisme',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '10',
        minOrange: '10',
        maxOrange: '20',
        minRouge: '20',
        maxRouge: '100000',
      },
      'D)13)2.a)1.45/': {
        name: 'BVD',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)13)2.a)1.46/': {
        name: 'IBR',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)13)2.a)1.47/': {
        name: 'Salmonelloses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)13)2.a)1.48/': {
        name: 'Toxi-infections',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)13)2.a)2.': {
    id: 'D)13)2.a)2.',
    name: 'Nombre',
    hideRapport: true,
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)13)2.a)2.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)13)2.a)3.': {
    id: 'D)13)2.a)3.',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)13)2.a)3.': {
        answerType: 'F',
        formule: '${D)13)2.a)2.}/(${D)2)16.})*100',
        suffix: ' %',
        round: 2,
        minVert: '${D)13)2.a)1.}',
        maxVert: '${D)13)2.a)1.}',
        minOrange: '${D)13)2.a)1.}',
        maxOrange: '${D)13)2.a)1.}',
        minRouge: '${D)13)2.a)1.}',
        maxRouge: '${D)13)2.a)1.}',
      },
    },
  },
  'D)13)2.b)': {
    id: 'D)13)2.b)',
    titre4: '2 à 3 mois',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
    function: 'R3',
  },
  'D)13)2.b)1.': {
    id: 'D)13)2.b)1.',
    name: 'Motif',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)13)2.b)1.10/': {
        name: 'Affections de l\'appareil urinaire',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)13)2.b)1.22/': {
        name: 'Météorisations',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)13)2.b)1.26/': {
        name: 'Diarrhées du jeune bovin et de l\'adulte',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)13)2.b)1.28/': {
        name: 'Météorisation gazeuses chroniques du veau',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)13)2.b)1.31/': {
        name: 'Acidoses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)13)2.b)1.35/': {
        name: 'Affections respiratoires',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)2.b)1.36/': {
        name: 'VRSB',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)2.b)1.37/': {
        name: 'BPIE',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)2.b)1.38/': {
        name: 'Pasteurelloses et mycoplasmoses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)2.b)1.39/': {
        name: 'Maladies du nombril',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)2.b)1.40/': {
        name: 'Affections du pied',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)2.b)1.41/': {
        name: 'Affections articulaires',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)2.b)1.42/': {
        name: 'Fractures',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)2.b)1.43/': {
        name: 'Maladies du système nerveux et du comportement',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)13)2.b)1.44/': {
        name: 'Parasitisme',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '10',
        minOrange: '10',
        maxOrange: '20',
        minRouge: '20',
        maxRouge: '100000',
      },
      'D)13)2.b)1.45/': {
        name: 'BVD',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)13)2.b)1.46/': {
        name: 'IBR',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)13)2.b)1.47/': {
        name: 'Salmonelloses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)13)2.b)1.48/': {
        name: 'Toxi-infections',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)13)2.b)2.': {
    id: 'D)13)2.b)2.',
    name: 'Nombre',
    hideRapport: true,
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)13)2.b)2.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)13)2.b)3.': {
    id: 'D)13)2.b)3.',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)13)2.b)3.': {
        answerType: 'F',
        formule: '${D)13)2.b)2.}/(${D)2)17.})*100',
        suffix: ' %',
        round: 2,
        minVert: '${D)13)2.b)1.}',
        maxVert: '${D)13)2.b)1.}',
        minOrange: '${D)13)2.b)1.}',
        maxOrange: '${D)13)2.b)1.}',
        minRouge: '${D)13)2.b)1.}',
        maxRouge: '${D)13)2.b)1.}',
      },
    },
  },
  'D)13)2.c)': {
    id: 'D)13)2.c)',
    titre4: '> 3 mois',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
    function: 'R3',
  },
  'D)13)2.c)1.': {
    id: 'D)13)2.c)1.',
    name: 'Motif',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)13)2.c)1.10/': {
        name: 'Affections de l\'appareil urinaire',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)13)2.c)1.22/': {
        name: 'Météorisations',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)13)2.c)1.26/': {
        name: 'Diarrhées du jeune bovin et de l\'adulte',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)13)2.c)1.28/': {
        name: 'Météorisation gazeuses chroniques du veau',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)13)2.c)1.31/': {
        name: 'Acidoses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)13)2.c)1.35/': {
        name: 'Affections respiratoires',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)2.c)1.36/': {
        name: 'VRSB',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)2.c)1.37/': {
        name: 'BPIE',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)2.c)1.38/': {
        name: 'Pasteurelloses et mycoplasmoses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)2.c)1.39/': {
        name: 'Maladies du nombril',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)2.c)1.40/': {
        name: 'Affections du pied',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)2.c)1.41/': {
        name: 'Affections articulaires',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)2.c)1.42/': {
        name: 'Fractures',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
      'D)13)2.c)1.43/': {
        name: 'Maladies du système nerveux et du comportement',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
      'D)13)2.c)1.44/': {
        name: 'Parasitisme',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '10',
        minOrange: '10',
        maxOrange: '20',
        minRouge: '20',
        maxRouge: '100000',
      },
      'D)13)2.c)1.45/': {
        name: 'BVD',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)13)2.c)1.46/': {
        name: 'IBR',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)13)2.c)1.47/': {
        name: 'Salmonelloses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
      'D)13)2.c)1.48/': {
        name: 'Toxi-infections',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)13)2.c)2.': {
    id: 'D)13)2.c)2.',
    name: 'Nombre',
    hideRapport: true,
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)13)2.c)2.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)13)2.c)3.': {
    id: 'D)13)2.c)3.',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)13)2.c)3.': {
        answerType: 'F',
        formule: '${D)13)2.c)2.}/(${D)2)18.})*100',
        suffix: ' %',
        round: 2,
        minVert: '${D)13)2.c)1.}',
        maxVert: '${D)13)2.c)1.}',
        minOrange: '${D)13)2.c)1.}',
        maxOrange: '${D)13)2.c)1.}',
        minRouge: '${D)13)2.c)1.}',
        maxRouge: '${D)13)2.c)1.}',
      },
    },
  },
  'D)13)av#': {
    id: 'D)13)av#',
    name: 'L\'avis du véto (la mortalité par classe d’âge)',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)13)av#': {
        answerType: 'TI',
      },
    },
  },
  'D)14)': {
    id: 'D)14)',
    titre2: 'Traitements préventifs actuellement mis en œuvre dans l\'élevage : ',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)14)a)': {
    id: 'D)14)a)',
    titre3: 'Traitements antiparasitaires :',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
    function: 'R3',
  },
  'D)14)a)1.': {
    id: 'D)14)a)1.',
    name: 'Affection(s) visée(s)',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)14)a)1.': {
        answerType: 'TI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)14)a)2.': {
    id: 'D)14)a)2.',
    name: 'Catégorie(s) d’animaux traités',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)14)a)2.': {
        answerType: 'TI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)14)a)3.': {
    id: 'D)14)a)3.',
    name: 'Traitement(s) administré(s)',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)14)a)3.': {
        answerType: 'TI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)14)a)4.': {
    id: 'D)14)a)4.',
    name: 'Protocole utilisé',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)14)a)4.': {
        answerType: 'TI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)14)b)': {
    id: 'D)14)b)',
    titre3: 'Vaccins',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
    function: 'R3',
  },
  'D)14)b)1.': {
    id: 'D)14)b)1.',
    name: 'Affection(s) visée(s)',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)14)b)1.': {
        answerType: 'TI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)14)b)2.': {
    id: 'D)14)b)2.',
    name: 'Catégorie(s) d’animaux traités',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)14)b)2.': {
        answerType: 'TI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)14)b)3.': {
    id: 'D)14)b)3.',
    name: 'Traitement(s) administré(s)',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)14)b)3.': {
        answerType: 'TI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)14)b)4.': {
    id: 'D)14)b)4.',
    name: 'Protocole utilisé',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)14)b)4.': {
        answerType: 'TI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)14)c)': {
    id: 'D)14)c)',
    titre3: 'Autres traitements (chimio prévention cordon, correction de carences oligoéléments…)',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
    function: 'R3',
  },
  'D)14)c)1.': {
    id: 'D)14)c)1.',
    name: 'Affection(s) visée(s)',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)14)c)1.': {
        answerType: 'TI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)14)c)2.': {
    id: 'D)14)c)2.',
    name: 'Catégorie(s) d’animaux traités',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)14)c)2.': {
        answerType: 'TI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)14)c)3.': {
    id: 'D)14)c)3.',
    name: 'Traitement(s) administré(s)',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)14)c)3.': {
        answerType: 'TI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)14)c)4.': {
    id: 'D)14)c)4.',
    name: 'Protocole utilisé',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)14)c)4.': {
        answerType: 'TI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)14)av#': {
    id: 'D)14)av#',
    name: 'L\'avis du véto (traitements préventifs actuellement mis en œuvre dans l’élevage)',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)14)av#': {
        answerType: 'TI',
      },
    },
  },
  'D)15)': {
    id: 'D)15)',
    titre2: 'Traitements préventifs actuellement mis en œuvre dans l\'élevage allaitant : ',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)15)a)': {
    id: 'D)15)a)',
    titre3: 'Traitements antiparasitaires :',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
    function: 'R3',
  },
  'D)15)a)1.': {
    id: 'D)15)a)1.',
    name: 'Affection(s) visée(s)',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)15)a)1.': {
        answerType: 'TI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)15)a)2.': {
    id: 'D)15)a)2.',
    name: 'Catégorie(s) d’animaux traités',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)15)a)2.1/': {
        name: 'Veaux sous la mère',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)15)a)2.2/': {
        name: 'Broutards mâles',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)15)a)2.3/': {
        name: 'Broutards femelles',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)15)a)2.4/': {
        name: '18 mois',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)15)a)2.5/': {
        name: '30 mois et adultes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)15)a)3.': {
    id: 'D)15)a)3.',
    name: 'Traitement(s) administré(s)',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)15)a)3.': {
        answerType: 'TI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)15)a)4.': {
    id: 'D)15)a)4.',
    name: 'Protocole utilisé',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)15)a)4.': {
        answerType: 'TI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)15)b)': {
    id: 'D)15)b)',
    titre3: 'Vaccins',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
    function: 'R3',
  },
  'D)15)b)1.': {
    id: 'D)15)b)1.',
    name: 'Affection(s) visée(s)',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)15)b)1.': {
        answerType: 'TI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)15)b)2.': {
    id: 'D)15)b)2.',
    name: 'Catégorie(s) d’animaux traités',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'D)15)b)2.1/': {
        name: 'Cheptel reproducteur',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)15)b)2.2/': {
        name: 'Veaux sous la mère',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)15)b)2.3/': {
        name: 'Broutards',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
      'D)15)b)2.4/': {
        name: 'Autres (TI)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
      },
    },
  },
  'D)15)b)3.': {
    id: 'D)15)b)3.',
    name: 'Traitement(s) administré(s)',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)15)b)3.': {
        answerType: 'TI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)15)b)4.': {
    id: 'D)15)b)4.',
    name: 'Protocole utilisé',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)15)b)4.': {
        answerType: 'TI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)15)c)': {
    id: 'D)15)c)',
    titre3: 'Autres traitements (chimio prévention cordon, correction de carences oligoéléments…)',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
    function: 'R3',
  },
  'D)15)c)1.': {
    id: 'D)15)c)1.',
    name: 'Affection(s) visée(s)',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)15)c)1.': {
        answerType: 'TI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)15)c)2.': {
    id: 'D)15)c)2.',
    name: 'Catégorie(s) d’animaux traités',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)15)c)2.': {
        answerType: 'TI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)15)c)3.': {
    id: 'D)15)c)3.',
    name: 'Traitement(s) administré(s)',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)15)c)3.': {
        answerType: 'TI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)15)c)4.': {
    id: 'D)15)c)4.',
    name: 'Protocole utilisé',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)15)c)4.': {
        answerType: 'TI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)15)av#': {
    id: 'D)15)av#',
    name: 'L\'avis du véto (traitements préventifs actuellement mis en œuvre dans l’élevage)',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)15)av#': {
        answerType: 'TI',
      },
    },
  },
  'D)16)': {
    id: 'D)16)',
    titre2: 'Troubles de santé observés et / ou enregistrés sur le troupeau sur la période des 12 mois concernés :',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,

  },
  'D)16)#': {
    id: 'D)16)#',
    titre3: 'Pour chaque maladie proposée dans la liste ci-dessous, vous devez indiquer le nombre de cas observés dans votre troupeau au cours de l’année écoulée. Cliquez directement sur '
        + '« envoyer » sans ne rien saisir si vous n’avez pas observé de cas pour la maladie proposée',
    titre3Rapport: '',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,

  },
  'D)16)a)': {
    id: 'D)16)a)',
    titre3: 'La mamelle et les trayons',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)16)a)1.': {
    id: 'D)16)a)1.',
    name: 'Traumatismes du trayon',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)a)1.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)a)1.1)': {
    id: 'D)16)a)1.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)a)1.1)': {
        answerType: 'F',
        formule: '${D)16)a)1.}/(${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)16)a)2.': {
    id: 'D)16)a)2.',
    name: 'Oedèmes mammaires',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)a)2.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)a)2.1)': {
    id: 'D)16)a)2.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)a)2.1)': {
        answerType: 'F',
        formule: '${D)16)a)2.}/(${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '30',
        minOrange: '30',
        maxOrange: '50',
        minRouge: '50',
        maxRouge: '100000',
      },
    },
  },
  'D)16)a)3.': {
    id: 'D)16)a)3.',
    name: 'Mammites cliniques',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)a)3.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)a)3.1)': {
    id: 'D)16)a)3.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)a)3.1)': {
        answerType: 'F',
        formule: '${D)16)a)3.}/(${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '30',
        minOrange: '30',
        maxOrange: '50',
        minRouge: '50',
        maxRouge: '100000',
      },
    },
  },
  'D)16)b)': {
    id: 'D)16)b)',
    titre3: 'La reproduction',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)16)b)4.': {
    id: 'D)16)b)4.',
    name: 'Anoestrus',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)b)4.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)b)4.1)': {
    id: 'D)16)b)4.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)b)4.1)': {
        answerType: 'F',
        formule: '${D)16)b)4.}/(${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '10',
        minOrange: '10',
        maxOrange: '20',
        minRouge: '20',
        maxRouge: '100000',
      },
    },
  },
  'D)16)b)5.': {
    id: 'D)16)b)5.',
    name: 'Infertilité',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)b)5.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)b)5.1)': {
    id: 'D)16)b)5.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)b)5.1)': {
        answerType: 'F',
        formule: '${D)16)b)5.}/(${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '10',
        minOrange: '10',
        maxOrange: '20',
        minRouge: '20',
        maxRouge: '100000',
      },
    },
  },
  'D)16)b)6.': {
    id: 'D)16)b)6.',
    name: 'Affections du vagins',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)b)6.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)b)6.1)': {
    id: 'D)16)b)6.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)b)6.1)': {
        answerType: 'F',
        formule: '${D)16)b)6.}/(${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
    },
  },
  'D)16)b)7.': {
    id: 'D)16)b)7.',
    name: 'Prolapsus et non délivrance',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)b)7.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)b)7.1)': {
    id: 'D)16)b)7.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)b)7.1)': {
        answerType: 'F',
        formule: '${D)16)b)7.}/(${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '8',
        minRouge: '8',
        maxRouge: '100000',
      },
    },
  },
  'D)16)b)8.': {
    id: 'D)16)b)8.',
    name: 'Métrites',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)b)8.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)b)8.1)': {
    id: 'D)16)b)8.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)b)8.1)': {
        answerType: 'F',
        formule: '${D)16)b)8.}/(${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
    },
  },
  'D)16)b)9.': {
    id: 'D)16)b)9.',
    name: 'Avortements',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)b)9.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)b)9.1)': {
    id: 'D)16)b)9.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)b)9.1)': {
        answerType: 'F',
        formule: '${D)16)b)9.}/(${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
    },
  },
  'D)16)c)': {
    id: 'D)16)c)',
    titre3: 'L\'appareil urinaire',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)16)c)10.': {
    id: 'D)16)c)10.',
    name: 'Affections de l\'appareil urinaire',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)c)10.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)c)10.1)': {
    id: 'D)16)c)10.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)c)10.1)': {
        answerType: 'F',
        formule: '${D)16)c)10.}/(${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
    },
  },
  'D)16)c)10.2)': {
    id: 'D)16)c)10.2)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)c)10.2)': {
        answerType: 'F',
        formule: '${D)16)c)10.}/(${D)2)7.})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
    },
  },
  'D)16)d)': {
    id: 'D)16)d)',
    titre3: 'La peau et les muqueuses',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)16)d)11.': {
    id: 'D)16)d)11.',
    name: 'Staphylococcies cutanées',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)d)11.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)d)11.1)': {
    id: 'D)16)d)11.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)d)11.1)': {
        answerType: 'F',
        formule: '${D)16)d)11.}/(${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '10',
        minOrange: '10',
        maxOrange: '20',
        minRouge: '20',
        maxRouge: '100000',
      },
    },
  },
  'D)16)d)11.2)': {
    id: 'D)16)d)11.2)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)d)11.2)': {
        answerType: 'F',
        formule: '${D)16)d)11.}/(${D)2)7.})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '10',
        minOrange: '10',
        maxOrange: '20',
        minRouge: '20',
        maxRouge: '100000',
      },
    },
  },
  'D)16)d)12.': {
    id: 'D)16)d)12.',
    name: 'Gales',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)d)12.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)d)12.1)': {
    id: 'D)16)d)12.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)d)12.1)': {
        answerType: 'F',
        formule: '${D)16)d)12.}/(${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '10',
        minOrange: '10',
        maxOrange: '20',
        minRouge: '20',
        maxRouge: '100000',
      },
    },
  },
  'D)16)d)12.2)': {
    id: 'D)16)d)12.2)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)d)12.2)': {
        answerType: 'F',
        formule: '${D)16)d)12.}/(${D)2)7.})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '10',
        minOrange: '10',
        maxOrange: '20',
        minRouge: '20',
        maxRouge: '100000',
      },
    },
  },
  'D)16)d)13.': {
    id: 'D)16)d)13.',
    name: 'Teigne',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)d)13.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)d)13.1)': {
    id: 'D)16)d)13.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)d)13.1)': {
        answerType: 'F',
        formule: '${D)16)d)13.}/(${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '10',
        minOrange: '10',
        maxOrange: '20',
        minRouge: '20',
        maxRouge: '100000',
      },
    },
  },
  'D)16)d)13.2)': {
    id: 'D)16)d)13.2)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)d)13.2)': {
        answerType: 'F',
        formule: '${D)16)d)13.}/(${D)2)7.})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '10',
        minOrange: '10',
        maxOrange: '20',
        minRouge: '20',
        maxRouge: '100000',
      },
    },
  },
  'D)16)d)14.': {
    id: 'D)16)d)14.',
    name: 'Infestation par les poux',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)d)14.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)d)14.1)': {
    id: 'D)16)d)14.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)d)14.1)': {
        answerType: 'F',
        formule: '${D)16)d)14.}/(${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '10',
        minOrange: '10',
        maxOrange: '20',
        minRouge: '20',
        maxRouge: '100000',
      },
    },
  },
  'D)16)d)14.2)': {
    id: 'D)16)d)14.2)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)d)14.2)': {
        answerType: 'F',
        formule: '${D)16)d)14.}/(${D)2)7.})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '10',
        minOrange: '10',
        maxOrange: '20',
        minRouge: '20',
        maxRouge: '100000',
      },
    },
  },
  'D)16)d)15.': {
    id: 'D)16)d)15.',
    name: 'Infestation par les tiques',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)d)15.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)d)15.1)': {
    id: 'D)16)d)15.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)d)15.1)': {
        answerType: 'F',
        formule: '${D)16)d)15.}/(${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '10',
        minOrange: '10',
        maxOrange: '20',
        minRouge: '20',
        maxRouge: '100000',
      },
    },
  },'D)16)d)15.2)': {
    id: 'D)16)d)15.2)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)d)15.2)': {
        answerType: 'F',
        formule: '${D)16)d)15.}/(${D)2)7.})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '10',
        minOrange: '10',
        maxOrange: '20',
        minRouge: '20',
        maxRouge: '100000',
      },
    },
  },
  'D)16)d)16.': {
    id: 'D)16)d)16.',
    name: 'Dermatophilose',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)d)16.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)d)16.1)': {
    id: 'D)16)d)16.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)d)16.1)': {
        answerType: 'F',
        formule: '${D)16)d)16.}/(${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '10',
        minOrange: '10',
        maxOrange: '20',
        minRouge: '20',
        maxRouge: '100000',
      },
    },
  },
  'D)16)d)16.2)': {
    id: 'D)16)d)16.2)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)d)16.2)': {
        answerType: 'F',
        formule: '${D)16)d)16.}/(${D)2)7.})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '10',
        minOrange: '10',
        maxOrange: '20',
        minRouge: '20',
        maxRouge: '100000',
      },
    },
  },
  'D)16)d)17.': {
    id: 'D)16)d)17.',
    name: 'Myiases',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)d)17.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)d)17.1)': {
    id: 'D)16)d)17.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)d)17.1)': {
        answerType: 'F',
        formule: '${D)16)d)17.}/(${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '10',
        minOrange: '10',
        maxOrange: '20',
        minRouge: '20',
        maxRouge: '100000',
      },
    },
  },
  'D)16)d)17.2)': {
    id: 'D)16)d)17.2)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)d)17.2)': {
        answerType: 'F',
        formule: '${D)16)d)17.}/(${D)2)7.})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '10',
        minOrange: '10',
        maxOrange: '20',
        minRouge: '20',
        maxRouge: '100000',
      },
    },
  },
  'D)16)d)18.': {
    id: 'D)16)d)18.',
    name: 'Photosensibilisations',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)d)18.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)d)18.1)': {
    id: 'D)16)d)18.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)d)18.1)': {
        answerType: 'F',
        formule: '${D)16)d)18.}/(${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '10',
        minOrange: '10',
        maxOrange: '20',
        minRouge: '20',
        maxRouge: '100000',
      },
    },
  },
  'D)16)d)19.': {
    id: 'D)16)d)19.',
    name: 'Allergies',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)d)19.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)d)19.1)': {
    id: 'D)16)d)19.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)d)19.1)': {
        answerType: 'F',
        formule: '${D)16)d)19.}/(${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '10',
        minOrange: '10',
        maxOrange: '20',
        minRouge: '20',
        maxRouge: '100000',
      },
    },
  },
  'D)16)d)19.2)': {
    id: 'D)16)d)19.2)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)d)19.2)': {
        answerType: 'F',
        formule: '${D)16)d)19.}/(${D)2)7.})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '10',
        minOrange: '10',
        maxOrange: '20',
        minRouge: '20',
        maxRouge: '100000',
      },
    },
  },
  'D)16)d)52.': {
    id: 'D)16)d)52.',
    name: 'Allergies',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)d)52.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)d)52.1)': {
    id: 'D)16)d)52.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)d)52.1)': {
        answerType: 'F',
        formule: '${D)16)d)52.}/(${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '10',
        minOrange: '10',
        maxOrange: '20',
        minRouge: '20',
        maxRouge: '100000',
      },
    },
  },
  'D)16)d)52.2)': {
    id: 'D)16)d)52.2)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)d)52.2)': {
        answerType: 'F',
        formule: '${D)16)d)52.}/(${D)2)7.})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '10',
        minOrange: '10',
        maxOrange: '20',
        minRouge: '20',
        maxRouge: '100000',
      },
    },
  },
  'D)16)e)': {
    id: 'D)16)e)',
    titre3: 'Les yeux',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)16)e)20.': {
    id: 'D)16)e)20.',
    name: 'Kérato-conjonctivite',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)e)20.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)e)20.1)': {
    id: 'D)16)e)20.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)e)20.1)': {
        answerType: 'F',
        formule: '${D)16)e)20.}/(${D)1)8.1)}+${D)1)8.2)}+${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.12}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)16)e)20.2)': {
    id: 'D)16)e)20.2)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)e)20.2)': {
        answerType: 'F',
        formule: '${D)16)e)20.}/(${D)2)7.})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)16)f)': {
    id: 'D)16)f)',
    titre3: 'L\'appareil digestif',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)16)f)21.': {
    id: 'D)16)f)21.',
    name: 'Actinobacillose',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)f)21.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)f)21.1)': {
    id: 'D)16)f)21.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)f)21.1)': {
        answerType: 'F',
        formule: '${D)16)f)21.}/(${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)16)f)21.2)': {
    id: 'D)16)f)21.2)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)f)21.2)': {
        answerType: 'F',
        formule: '${D)16)f)21.}/(${D)2)7.})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)16)f)22.': {
    id: 'D)16)f)22.',
    name: 'Météorisations',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)f)22.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)f)22.1)': {
    id: 'D)16)f)22.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)f)22.1)': {
        answerType: 'F',
        formule: '${D)16)f)22.}/(${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)16)f)22.2)': {
    id: 'D)16)f)22.2)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)f)22.2)': {
        answerType: 'F',
        formule: '${D)16)f)22.}/(${D)2)7.})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)16)f)23.': {
    id: 'D)16)f)23.',
    name: 'Réticulite traumatique (corps étranger)',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)f)23.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)f)23.1)': {
    id: 'D)16)f)23.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)f)23.1)': {
        answerType: 'F',
        formule: '${D)16)f)23.}/(${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)16)f)23.2)': {
    id: 'D)16)f)23.2)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)f)23.2)': {
        answerType: 'F',
        formule: '${D)16)f)23.}/(${D)2)7.})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)16)f)24.': {
    id: 'D)16)f)24.',
    name: 'Déplacements et volvulus de la caillette',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)f)24.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)f)24.1)': {
    id: 'D)16)f)24.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)f)24.1)': {
        answerType: 'F',
        formule: '${D)16)f)24.}/(${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)16)f)25.': {
    id: 'D)16)f)25.',
    name: 'Dysenterie d\'hiver',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)f)25.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)f)25.1)': {
    id: 'D)16)f)25.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)f)25.1)': {
        answerType: 'F',
        formule: '${D)16)f)25.}/(${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)16)f)26.': {
    id: 'D)16)f)26.',
    name: 'Diarrhées du jeune bovin et de l\'adulte',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)f)26.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)f)26.1)': {
    id: 'D)16)f)26.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)f)26.1)': {
        answerType: 'F',
        formule: '${D)16)f)26.}/(${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)16)f)26.2)': {
    id: 'D)16)f)26.2)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)f)26.2)': {
        answerType: 'F',
        formule: '${D)16)f)26.}/(${D)2)7.})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)16)f)27.': {
    id: 'D)16)f)27.',
    name: 'Diarrhées néonatales',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)f)27.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)f)27.1)': {
    id: 'D)16)f)27.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)f)27.1)': {
        answerType: 'F',
        formule: '${D)16)f)27.}/(${D)1)9.4)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
    },
  },
  'D)16)f)28.': {
    id: 'D)16)f)28.',
    name: 'Météorisation gazeuses chroniques du veau',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)f)28.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)f)28.1)': {
    id: 'D)16)f)28.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)f)28.1)': {
        answerType: 'F',
        formule: '${D)16)f)28.}/(${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)16)f)28.2)': {
    id: 'D)16)f)28.2)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)f)28.2)': {
        answerType: 'F',
        formule: '${D)16)f)28.}/(${D)2)7.})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)16)g)': {
    id: 'D)16)g)',
    titre3: 'Les maladies nutritionnelles et les intoxications',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)16)g)29.': {
    id: 'D)16)g)29.',
    name: 'Fièvre de lait',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)g)29.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)g)29.1)': {
    id: 'D)16)g)29.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)g)29.1)': {
        answerType: 'F',
        formule: '${D)16)g)29.}/(${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
    },
  },
  'D)16)g)30.': {
    id: 'D)16)g)30.',
    name: 'Tétanie d\'herbage',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)g)30.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)g)30.1)': {
    id: 'D)16)g)30.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)g)30.1)': {
        answerType: 'F',
        formule: '${D)16)g)30.}/(${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
    },
  },
  'D)16)g)31.': {
    id: 'D)16)g)31.',
    name: 'Acidoses',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)g)31.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)g)31.1)': {
    id: 'D)16)g)31.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)g)31.1)': {
        answerType: 'F',
        formule: '${D)16)g)31.}/(${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
    },
  },
  'D)16)g)31.2)': {
    id: 'D)16)g)31.2)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)g)31.2)': {
        answerType: 'F',
        formule: '${D)16)g)31.}/(${D)2)7.})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
    },
  },
  'D)16)g)53.': {
    id: 'D)16)g)53.',
    name: 'Alcaloses',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)g)53.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)g)53.1)': {
    id: 'D)16)g)53.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)g)53.1)': {
        answerType: 'F',
        formule: '${D)16)g)53.}/(${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
    },
  },
  'D)16)g)53.2)': {
    id: 'D)16)g)53.2)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)g)53.2)': {
        answerType: 'F',
        formule: '${D)16)g)53.}/(${D)2)7.})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
    },
  },
  'D)16)g)32.': {
    id: 'D)16)g)32.',
    name: 'Acétonémie',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)g)32.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)g)32.1)': {
    id: 'D)16)g)32.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)g)32.1)': {
        answerType: 'F',
        formule: '${D)16)g)32.}/(${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '4',
        minOrange: '4',
        maxOrange: '8',
        minRouge: '8',
        maxRouge: '100000',
      },
    },
  },
  'D)16)g)33.': {
    id: 'D)16)g)33.',
    name: 'Carences en minéraux et en oligo-éléments',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)g)33.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)g)33.1)': {
    id: 'D)16)g)33.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)g)33.1)': {
        answerType: 'F',
        formule: '${D)16)g)33.}/(${D)1)8.1)}+${D)1)8.2)}+${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.12}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
    },
  },
  'D)16)g)33.2)': {
    id: 'D)16)g)33.2)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)g)33.2)': {
        answerType: 'F',
        formule: '${D)16)g)33.}/(${D)2)7.})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
    },
  },
  'D)16)g)34.': {
    id: 'D)16)g)34.',
    name: 'Carences en vitamines',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)g)34.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)g)34.1)': {
    id: 'D)16)g)34.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)g)34.1)': {
        answerType: 'F',
        formule: '${D)16)g)34.}/(${D)1)8.1)}+${D)1)8.2)}+${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.12}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
    },
  },
  'D)16)g)34.2)': {
    id: 'D)16)g)34.2)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)g)34.2)': {
        answerType: 'F',
        formule: '${D)16)g)34.}/(${D)2)7.})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
    },
  },
  'D)16)g)59.': {
    id: 'D)16)g)59.',
    name: 'Intoxications',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)g)59.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)g)59.1)': {
    id: 'D)16)g)59.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)g)59.1)': {
        answerType: 'F',
        formule: '${D)16)g)59.}/(${D)1)8.1)}+${D)1)8.2)}+${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.12}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
    },
  },
  'D)16)h)': {
    id: 'D)16)h)',
    titre3: 'L\'appareil respiratoire et circulatoire',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)16)h)35.': {
    id: 'D)16)h)35.',
    name: 'Affections respiratoires',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)h)35.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)h)35.1)': {
    id: 'D)16)h)35.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)h)35.1)': {
        answerType: 'F',
        formule: '${D)16)h)35.}/(${D)1)8.2)}+${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
    },
  },
  'D)16)h)35.2)': {
    id: 'D)16)h)35.2)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)h)35.2)': {
        answerType: 'F',
        formule: '${D)16)h)35.}/(${D)2)7.})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
    },
  },
  'D)16)h)36.': {
    id: 'D)16)h)36.',
    name: 'VRSB',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)h)36.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)h)36.1)': {
    id: 'D)16)h)36.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)h)36.1)': {
        answerType: 'F',
        formule: '${D)16)h)36.}/(${D)1)8.2)}+${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
    },
  },
  'D)16)h)36.2)': {
    id: 'D)16)h)36.2)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)h)36.2)': {
        answerType: 'F',
        formule: '${D)16)h)36.}/(${D)2)7.})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
    },
  },
  'D)16)h)37.': {
    id: 'D)16)h)37.',
    name: 'BPIE',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)h)37.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)h)37.1)': {
    id: 'D)16)h)37.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)h)37.1)': {
        answerType: 'F',
        formule: '${D)16)h)37.}/(${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
    },
  },
  'D)16)h)37.2)': {
    id: 'D)16)h)37.2)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)h)37.2)': {
        answerType: 'F',
        formule: '${D)16)h)37.}/(${D)2)7.})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
    },
  },
  'D)16)h)38.': {
    id: 'D)16)h)38.',
    name: 'Pasteurelloses et mycoplasmoses',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)h)38.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)h)38.1)': {
    id: 'D)16)h)38.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)h)38.1)': {
        answerType: 'F',
        formule: '${D)16)h)38.}/(${D)1)8.2)}+${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
    },
  },
  'D)16)h)38.2)': {
    id: 'D)16)h)38.2)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)h)38.2)': {
        answerType: 'F',
        formule: '${D)16)h)38.}/(${D)2)7.})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
    },
  },
  'D)16)h)60.': {
    id: 'D)16)h)60.',
    name: 'Maladies du cœur et de l\'appareil circulatoire',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)h)60.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)h)60.1)': {
    id: 'D)16)h)60.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)h)60.1)': {
        answerType: 'F',
        formule: '${D)16)h)60.}/(${D)1)8.2)}+${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
    },
  },
  'D)16)h)60.2)': {
    id: 'D)16)h)60.2)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)h)60.2)': {
        answerType: 'F',
        formule: '${D)16)h)60.}/(${D)2)7.})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
    },
  },
  'D)16)i)': {
    id: 'D)16)i)',
    titre3: 'Le nombril',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)16)i)39.': {
    id: 'D)16)i)39.',
    name: 'Maladies du nombril',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)i)39.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)i)39.1)': {
    id: 'D)16)i)39.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)i)39.1)': {
        answerType: 'F',
        formule: '${D)16)i)39.}/(${D)1)9.4)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
    },
  },
  'D)16)j)': {
    id: 'D)16)j)',
    titre3: 'L\'appareil locomoteur',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)16)j)40.': {
    id: 'D)16)j)40.',
    name: 'Affections du pied - Boiteries',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)j)40.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)j)40.1)': {
    id: 'D)16)j)40.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)j)40.1)': {
        answerType: 'F',
        formule: '${D)16)j)40.}/(${D)1)8.2)}+${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
    },
  },
  'D)16)j)40.2)': {
    id: 'D)16)j)40.2)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)j)40.2)': {
        answerType: 'F',
        formule: '${D)16)j)40.}/(${D)2)7.})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
    },
  },
  'D)16)j)54.': {
    id: 'D)16)j)54.',
    name: 'Mortellaro',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)j)54.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)j)54.1)': {
    id: 'D)16)j)54.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)j)54.1)': {
        answerType: 'F',
        formule: '${D)16)j)54.}/(${D)1)8.2)}+${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
    },
  },
  'D)16)j)54.2)': {
    id: 'D)16)j)54.2)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)j)54.2)': {
        answerType: 'F',
        formule: '${D)16)j)54.}/(${D)2)7.})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
    },
  },
  'D)16)j)41.': {
    id: 'D)16)j)41.',
    name: 'Affections articulaires',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)j)41.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)j)41.1)': {
    id: 'D)16)j)41.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)j)41.1)': {
        answerType: 'F',
        formule: '${D)16)j)41.}/(${D)1)8.1)}+${D)1)8.2)}+${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.12}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
    },
  },
  'D)16)j)41.2)': {
    id: 'D)16)j)41.2)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)j)41.2)': {
        answerType: 'F',
        formule: '${D)16)j)41.}/(${D)2)7.})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
    },
  },
  'D)16)j)42.': {
    id: 'D)16)j)42.',
    name: 'Fractures',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)j)42.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)j)42.1)': {
    id: 'D)16)j)42.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)j)42.1)': {
        answerType: 'F',
        formule: '${D)16)j)42.}/(${D)1)8.1)}+${D)1)8.2)}+${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.12}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
    },
  },
  'D)16)j)42.2)': {
    id: 'D)16)j)42.2)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)j)42.2)': {
        answerType: 'F',
        formule: '${D)16)j)42.}/(${D)2)7.})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '5',
        minOrange: '5',
        maxOrange: '10',
        minRouge: '10',
        maxRouge: '100000',
      },
    },
  },
  'D)16)k)': {
    id: 'D)16)k)',
    titre3: 'Le système nerveux et le comportement',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)16)k)43.': {
    id: 'D)16)k)43.',
    name: 'Maladies du système nerveux et du comportement',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)k)43.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)k)43.1)': {
    id: 'D)16)k)43.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)k)43.1)': {
        answerType: 'F',
        formule: '${D)16)k)43.}/(${D)1)8.1)}+${D)1)8.2)}+${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.12}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
    },
  },
  'D)16)k)43.2)': {
    id: 'D)16)k)43.2)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)k)43.2)': {
        answerType: 'F',
        formule: '${D)16)k)43.}/(${D)2)7.})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '5',
        minRouge: '5',
        maxRouge: '100000',
      },
    },
  },
  'D)16)l)': {
    id: 'D)16)l)',
    titre3: 'Le parasitisme',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)16)l)44.': {
    id: 'D)16)l)44.',
    name: 'Parasitisme',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)l)44.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)l)44.1)': {
    id: 'D)16)l)44.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)l)44.1)': {
        answerType: 'F',
        formule: '${D)16)l)44.}/(${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '10',
        minOrange: '10',
        maxOrange: '20',
        minRouge: '20',
        maxRouge: '100000',
      },
    },
  },
  'D)16)l)55.': {
    id: 'D)16)l)55.',
    name: 'Coccidiose',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)l)55.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)l)55.1)': {
    id: 'D)16)l)55.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)l)55.1)': {
        answerType: 'F',
        formule: '${D)16)l)55.}/(${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '10',
        minOrange: '10',
        maxOrange: '20',
        minRouge: '20',
        maxRouge: '100000',
      },
    },
  },
  'D)16)l)56.': {
    id: 'D)16)l)56.',
    name: 'Cryptosporidiose',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)l)56.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)l)56.1)': {
    id: 'D)16)l)56.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)l)56.1)': {
        answerType: 'F',
        formule: '${D)16)l)56.}/(${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '10',
        minOrange: '10',
        maxOrange: '20',
        minRouge: '20',
        maxRouge: '100000',
      },
    },
  },
  'D)16)l)57.': {
    id: 'D)16)l)57.',
    name: 'Paramphistomose',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)l)57.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)l)57.1)': {
    id: 'D)16)l)57.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)l)57.1)': {
        answerType: 'F',
        formule: '${D)16)l)57.}/(${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '10',
        minOrange: '10',
        maxOrange: '20',
        minRouge: '20',
        maxRouge: '100000',
      },
    },
  },
  'D)16)m)': {
    id: 'D)16)m)',
    titre3: 'Les maladies virales et bactériennes générales',
    description: '',
    bseOnly: false,
    interdire: false,
    autopass: true,
  },
  'D)16)m)45.': {
    id: 'D)16)m)45.',
    name: 'BVD',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)m)45.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)m)45.1)': {
    id: 'D)16)m)45.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)m)45.1)': {
        answerType: 'F',
        formule: '${D)16)m)45.}/(${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)16)m)45.2)': {
    id: 'D)16)m)45.2)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)m)45.2)': {
        answerType: 'F',
        formule: '${D)16)m)45.}/(${D)2)7.})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)16)m)46.': {
    id: 'D)16)m)46.',
    name: 'IBR',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)m)46.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)m)46.1)': {
    id: 'D)16)m)46.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)m)46.1)': {
        answerType: 'F',
        formule: '${D)16)m)46.}/(${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)16)m)46.2)': {
    id: 'D)16)m)46.2)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)m)46.2)': {
        answerType: 'F',
        formule: '${D)16)m)46.}/(${D)2)7.})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)16)m)47.': {
    id: 'D)16)m)47.',
    name: 'Salmonelloses',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)m)47.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)m)47.1)': {
    id: 'D)16)m)47.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)m)47.1)': {
        answerType: 'F',
        formule: '${D)16)m)47.}/(${D)1)8.1)}+${D)1)8.2)}+${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.12}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)16)m)47.2)': {
    id: 'D)16)m)47.2)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)m)47.2)': {
        answerType: 'F',
        formule: '${D)16)m)47.}/(${D)2)7.})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)16)m)48.': {
    id: 'D)16)m)48.',
    name: 'Toxi-infections',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)m)48.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)m)48.1)': {
    id: 'D)16)m)48.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)m)48.1)': {
        answerType: 'F',
        formule: '${D)16)m)48.}/(${D)1)8.2)}+${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)16)m)48.2)': {
    id: 'D)16)m)48.2)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)m)48.2)': {
        answerType: 'F',
        formule: '${D)16)m)48.}/(${D)2)7.})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)16)m)58.': {
    id: 'D)16)m)58.',
    name: 'Fièvre Q',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)m)58.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)m)58.1)': {
    id: 'D)16)m)58.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)m)58.1)': {
        answerType: 'F',
        formule: '${D)16)m)58.}/(${D)1)8.2)}+${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)16)m)61.': {
    id: 'D)16)m)61.',
    name: 'Leucose bovine enzootique',
    description: '',
    bseOnly: false,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    hideQuestionRapport: false,
    answers: {
      'D)16)m)61.': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)m)61.1)': {
    id: 'D)16)m)61.1)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)m)61.1)': {
        answerType: 'F',
        formule: '${D)16)m)61.}/(${D)1)8.2)}+${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)16)n)': {
    id: 'D)16)n)',
    titre3: 'Autres troubles de santé observés',
    description: '',
    bseOnly: true,
    interdire: false,
    autopass: true,
    function: 'R3',
  },
  'D)16)n)49.': {
    id: 'D)16)n)49.',
    name: 'Autres',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)n)49.': {
        answerType: 'TI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)n)49.1)': {
    id: 'D)16)n)49.1)',
    name: 'Description',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)n)49.1)': {
        answerType: 'TI',
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)n)49.2)': {
    id: 'D)16)n)49.2)',
    name: 'Nombre',
    hideRapport: true,
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)n)49.2)': {
        answerType: 'NI',
        hideRapport: true,
        formule: '',
        minVert: '',
        maxVert: '',
        minOrange: '',
        maxOrange: '',
        minRouge: '',
        maxRouge: '',
      },
    },
  },
  'D)16)n)49.3)': {
    id: 'D)16)n)49.3)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)n)49.3)': {
        answerType: 'F',
        formule: '${D)16)n)49.2)}/(${D)1)8.1)}+${D)1)8.2)}+${D)1)8.4)}+${D)1)8.3)}+${D)1)8.5)}+${D)1)8.12}+${D)1)8.13)})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)16)n)49.4)': {
    id: 'D)16)n)49.4)',
    name: 'Taux',
    nameRapport: 'Importance estimée',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)n)49.4)': {
        answerType: 'F',
        formule: '${D)16)n)49.2)}/(${D)2)7.})*100',
        suffix: ' %',
        round: 2,
        minVert: '0',
        maxVert: '2',
        minOrange: '2',
        maxOrange: '3',
        minRouge: '3',
        maxRouge: '100000',
      },
    },
  },
  'D)16)av#': {
    id: 'D)16)av#',
    name: 'L\'avis du véto (troubles de santé observés et (ou) enregistrés sur la période des 12 moins concernés)',
    description: '',
    bseOnly: true,
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    answers: {
      'D)16)av#': {
        answerType: 'TI',
      },
    },
  },
};
