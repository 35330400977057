import { Icon as IconUIKitten } from '@ui-kitten/components/ui/icon/icon.component';
import { TouchableOpacity } from 'react-native';
import * as React from 'react';
import { useContext } from 'react';
import { useBtnStyle, useIconStyle } from '../useStyles';
import RtcContext from '../RtcContext';

export default function EndCall(props: { onPress: () => void }) {
  const btnStyle = useBtnStyle();
  const iconStyle = useIconStyle();
  const { onPress } = props;
  const { leave } = useContext(RtcContext);

  return (
    <TouchableOpacity
      style={{ ...btnStyle, backgroundColor: '#DB3B0F' }}
      onPress={async () => {
        await leave();
        onPress();
      }}
    >
      <IconUIKitten
        name="phone-off-outline"
        style={iconStyle}
        fill="white"
      />
    </TouchableOpacity>
  );
}
