import * as React from 'react';
import {
  StyleSheet, TouchableOpacity, KeyboardAvoidingView, View, ViewStyle, ScrollView,
  Modal,
} from 'react-native';

import { useEffect, useState } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { AnimatePresence, MotiView } from 'moti';
import { useDimensions } from '@react-native-community/hooks';
import { Spinner, Text } from '@ui-kitten/components';

import Button from '../Button';

export type CustomModalProps = {
  title: string,
  subtitle?: string,

  buttonText?: string,
  buttonLoadingText?: string,
  description?: string,
  secondButtonText?: string,
  thirdButtonText?: string,

  insideModalStyle?: ViewStyle,
  children?: React.ReactNode,
  before?: React.ReactNode,
  noSafeArea: boolean,
  visible: boolean,
  scrollable: boolean,
  onClose?: () => void

  buttonPress?: () => void,
  secondButtonPress?: () => void,
  thirdButtonPress?: () => void,

  heightPercentage?: number,
  rightAction?: {
    label: string,
    onPress: () => any
  }
};

const CustomModal = (props: CustomModalProps) => {
  const {
    scrollable = false,
    buttonText,
    buttonLoadingText,
    secondButtonText,
    thirdButtonPress,
    thirdButtonText,
    description,
    // noSafeArea = false,
    subtitle,
    // rightAction = undefined,
    buttonPress,
    heightPercentage = 0.7,
    title, children,
    // before,
    visible, onClose, insideModalStyle,
  } = props;
  const { window } = useDimensions();
  const [visibleState, setVisible] = useState(false);
  const [visibleModalState, setVisibleModal] = useState(false);
  const [isDoingButtonAction, setIsDoingButtonAction] = useState(false);

  useEffect(() => {
    if (visible !== visibleState) {
      if (visible) {
        setVisible(visible);
        setVisibleModal(visible);
      } else {
        setVisible(visible);
      }
    }
  }, [visible]);

  const insets = useSafeAreaInsets();

  const onRequestClose = () => {
    if (onClose) {
      onClose();
    } else {
      setVisible(false);
    }
  };

  const computedStyles = styles.modal;
  const computedContentStyle = {};
  // noSafeArea === false ? noSafeArea : (<SafeAreaView>{noSafeArea}</SafeAreaView>);

  return (
    <Modal
      onRequestClose={() => onRequestClose()}
      animationType="none"
      transparent
      statusBarTranslucent
      visible={visibleModalState}
      style={{
        margin: 0,
        ...StyleSheet.absoluteFillObject,
        height: window.height,
        width: window.width,
        paddingTop: insets.top,
      }}
    >
      {/**
         before
      */}
      <AnimatePresence onExitComplete={() => setVisibleModal(false)}>
        {visibleState && (
          <View style={[computedStyles, {
            paddingTop: insets.top,
          }]}
          >
            <MotiView
              style={{
                ...StyleSheet.absoluteFillObject,
                backgroundColor: '#000000',
                height: window.height + insets.top,
                width: window.width,
                flex: 1,
              }}
              from={{ opacity: 0 }}
              animate={{ opacity: 0.5 }}
              exit={{ opacity: 0 }}
              transition={{
                type: 'timing',
                duration,
              }}
            >
              <TouchableOpacity style={styles.exit} onPress={() => onRequestClose()} />
            </MotiView>
            <MotiView
              style={{
                maxWidth: 780,
                width: '100%',
                flex: 1,
                maxHeight: window.height * heightPercentage,
                height: window.height * heightPercentage,
              }}
              from={{ opacity: 0, translateY: window.height }}
              animate={{ opacity: 1, translateY: 0 }}
              exit={{ opacity: 0, translateY: window.height }}
              transition={{
                type: 'timing',
                duration,
              }}
            >
              <KeyboardAvoidingView behavior="height" style={{ flex: 1 }}>
                <View style={[styles.content, computedContentStyle, insideModalStyle]}>
                  <Text category="h4" style={{ textAlign: 'center', marginTop: 20 }}>{title}</Text>
                  {description ? <Text category="h5" style={{ textAlign: 'center', marginTop: 10 }}>{description}</Text> : (<></>)}
                  {subtitle ? <Text category="h4" style={{ textAlign: 'center', marginTop: 10 }}>{subtitle}</Text> : <></>}
                  {/** still to finish buttonPress */}
                  {buttonText && (
                  <Button
                    size="large"
                    onPress={async () => {
                      if (buttonPress) {
                        setIsDoingButtonAction(true);
                        await buttonPress();
                        setIsDoingButtonAction(false);
                      }
                    }}
                    disabled={isDoingButtonAction}
                    style={[{ marginTop: 20, alignSelf: 'center' }]}
                  >
                    {isDoingButtonAction
                      ? (
                        <>
                          <Spinner />
                          <Text style={{ marginLeft: 10 }}>{buttonLoadingText || 'En attente'}</Text>
                        </>
                      )
                      : <Text>{buttonText}</Text>}
                  </Button>
                  )}

                  {thirdButtonText && (
                  <Button
                    size="large"
                    disabled={isDoingButtonAction}
                    onPress={thirdButtonPress}
                    style={[{ marginTop: 20, alignSelf: 'center' }]}
                  >
                    {thirdButtonText}
                  </Button>
                  )}

                  {children
                    ? ((scrollable
                      ? (<ScrollView bounces={false}>{children}</ScrollView>)
                      : children))
                    : (<></>)}
                  {secondButtonText && (
                  <Button
                    size="large"
                    appearance="ghost"
                    disabled={isDoingButtonAction}
                    onPress={() => onRequestClose()}
                    style={{ marginTop: 20, textAlign: 'center', justifyContent: 'center' }}
                  >
                    {secondButtonText || 'Annuler'}
                  </Button>
                  )}

                </View>
              </KeyboardAvoidingView>
            </MotiView>
          </View>
        )}
      </AnimatePresence>
    </Modal>
  );
};

export default CustomModal;

const duration = 350;
const styles = StyleSheet.create({
  modal: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 30,
  },
  exit: {
    flex: 1,
  },
  content: {
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderRadius: 15,
    flex: 1,
  },

});
