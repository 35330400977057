import React, { useState } from 'react';
import { useLinkTo, useRoute } from '@react-navigation/native';
import { Text } from '@ui-kitten/components';

import { Platform, View } from 'react-native';

import moment from 'moment';
import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';
import { Storage } from 'aws-amplify';
import UIKittenIconButton from '../../components/Icon/UIKittenIconButton';
import FCard from '../../components/Card';
import InfoCard from '../InfoCard';
import Button from '../../components/Button';
import CustomModal from '../../components/Modals/CustomModal';
import { useGetBse, useUpdateBseMutation } from '../../src/API/BSE';
import { useUser } from '../../src/API/UserContext';
import GoCustomCard from '../../components/GoCustomCard';
import { StatusBse } from '../../src/API';
import {
  useGetProtocolSoinSaveByBseId,
} from '../../src/API/ProtocolSoin';
import PSCard from '../VetScreens/BSEComps/PSCard';
import { waitingDirectory } from '../../utils/S3FileStorage';
import DownloadFile from '../../utils/DownloadFile';

const FaireDemandeBseCard = () => {
  const linkTo = useLinkTo();

  const route = useRoute();
  /**     for modal     */
  const [openDelete, setOpenDelete] = useState<{ title : string, description?: string }>();
  const openDemandeBseModal = () => {
    // console.log('openDemandeBseModal', openDelete?.title);
    setOpenDelete({ title: 'faire une demande de Bilan Sanitaire d\'Élevage' });
  };

  const { user } = useUser();
  const { bse } = useGetBse(route.params.id);
  const { psSave } = useGetProtocolSoinSaveByBseId(route.params.id || undefined);
  const FaireDemandeBse = async () => {
    if (bse && bse.id) {
      await updateBse({
        variables: {
          input: {
            id: bse.id,
            StatusBse: StatusBse.BSEAsk,
            // eslint-disable-next-line no-underscore-dangle
            _version: bse?._version,
          },
        },
      });
    }
    setOpenDelete(undefined);
  };
  const openConfirmerDemarrageModal = () => {
    setOpenDelete({ title: 'confirmer le démarrage du BSE' });
  };

  const { updateBse } = useUpdateBseMutation();
  const ConfirmerDemarrage = async () => {
    // console.log('confimer demarrage', openDelete?.title);

    if (user) {
      if (bse && bse.id) {
        await updateBse({
          variables: {
            input: {
              id: bse.id,
              idEleveurUser: user.id,
              StatusBse: StatusBse.BSEStarted,
              // eslint-disable-next-line no-underscore-dangle
              _version: bse?._version,
            },
          },
        });
      }
    }
    /** put it when ready: linkTo('/bilan-sanitaire/photo') */
    setOpenDelete(undefined);
  };
  // console.log('BSE', BSE);

  return (

    <>
      {bse
        ? (
          <>
            {bse.StatusBse === StatusBse.BSEFinish
              ? (
                <>
                  {bse.crS3Key ? (
                    <>
                      <Text category="h2">Compte rendu du BSE</Text>
                      <GoCustomCard
                        title1="Compte rendu du BSE"
                        title2={new Date(bse?.dateBSE).getFullYear() || 'Année du BSE'}
                        linkToCustom={async () => {
                          if (bse.crS3Key) {
                            try {
                              const url = await Storage.get(bse.crS3Key);
                              if (Platform.OS === 'web') {
                                window.open(url as string);
                              } else {
                                const directory = `${FileSystem.cacheDirectory + waitingDirectory}temp/`;

                                try {
                                  await FileSystem.makeDirectoryAsync(
                                    directory,
                                    { intermediates: true },
                                  );
                                } catch (e) {
                                  console.log('Document Component error : ', e);
                                }

                                const name = `Compte_rendu_BSE_${moment(bse.dateBSE).format('YYYY-MM-DD')}.pdf`;

                                const res = await FileSystem.downloadAsync(
                                  url as string,
                                  FileSystem.documentDirectory + encodeURIComponent(name.replace(/\//g, '_')),
                                );
                                console.log(res);

                                if (Platform.OS === 'ios') {
                                  await Sharing.shareAsync(res.uri);
                                  await FileSystem.deleteAsync(res.uri);
                                } else {
                                  const folder = 'scanflock';
                                  const test = await DownloadFile.downloadFilesAsync([{ url: res.uri, name: name.replace(/\//g, '_'), mimeType: res.headers['Content-Type'] }], folder, () => {});
                                }
                              }
                            } catch (e) {
                              console.log('Document Component error : ', e);
                            }
                          }
                        }}
                        minusSquareOutline="download-outline"
                      />
                    </>
                  ) : null}
                  {/* <Text category="h2" style={{ marginTop: 40 }}>Consulter le Bilan Sanitaire d'Élevage</Text>
                  <GoCustomCard title1="BSE réalisé par le vétérinaire" linkToCustom={() => { linkTo(`/bilan-sanitaire/prebilan-questionnaire/${bse.id}?allCheck=${true}`); }} minusSquareOutline="arrow-ios-forward" />
*/}
                  <Text category="h2" style={{ marginTop: 40 }}>Protocoles de soins</Text>
                  <Text category="h4">
                    Consultez les protocoles de soins sélectionnées par votre vétérinaire lors du Bilan Sanitaire d'Élevage.
                  </Text>
                  {psSave?.map((ps) => (
                    <PSCard bse={bse} idMaladiePS={ps.idDiseaseFamily} key={ps.idDiseaseFamily} notEdit />
                  ))}
                </>
              )
              : (
                <>
                  {bse.StatusBse === StatusBse.BSEAsk || bse.StatusBse === StatusBse.BSEAskStart
                    ? (
                      bse.dateBSE ? (
                        bse.StatusBse === StatusBse.BSEAskStart ? (
                          <InfoCard
                            title="alertInfo"
                            iconShow="alert-circle-outline"
                            description="Votre vétérinaire souhaite débuter le Bilan Sanitaire d'Élevage.
                Appuyez sur le bouton dans les détails du rendez-vous pour confirmer
                le démarrage du BSE."
                            alertInfo="alertInfo"
                          />
                        ) : (
                          <>
                            <InfoCard
                              title="alertInfo"
                              iconShow="alert-circle-outline"
                              description="Votre vétérinaire a planifié le rendez-vous pour le Bilan Sanitaire d'Élevage."
                              alertInfo="alertInfo"
                            />
                          </>
                        )
                      ) : (
                        <>
                          <InfoCard
                            title="alertInfo"
                            iconShow="alert-circle-outline"
                            description="La demande de BSE a été transmise à votre vétérinaire. Vous serez
                notifié de la planification du rendez-vous."
                            alertInfo="alertInfo"
                          />
                        </>
                      )
                    )
                    : (
                      <>
                        {bse.StatusBse === StatusBse.BSEStarted || bse.StatusBse === StatusBse.BSEProtocolSoins
                          ? (
                            <InfoCard
                              title="alertInfo"
                              iconShow="alert-circle-outline"
                              description="Votre BSE est en cours de réalisation par votre vétérinaire."
                              alertInfo="alertInfo"
                            />
                          ) : (
                            <InfoCard
                              title="alertInfo"
                              iconShow="alert-circle-outline"
                              description="Faite votre demande de BSE ici."
                              alertInfo="alertInfo"
                            />
                          )}
                      </>
                    )}
                  {bse.StatusBse === StatusBse.BSEAsk || bse.StatusBse === StatusBse.BSEAskStart ? (
                    <>
                      <FCard
                        style={{ flexDirection: 'row', justifyContent: 'space-between', backgroundColor: '#182E6B' }}
                      >
                        <View style={{ flexDirection: 'column' }}>
                          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <UIKittenIconButton fill="#fff" name="calendar-outline" width={24} height={24} style={{ marginRight: 2 }} />
                            <Text category="h5" status="control">
                              {bse.dateBSE ? moment(new Date(bse.dateBSE)).format('L') : 'En attente'}
                            </Text>
                          </View>
                          {bse.dateBSE ? (
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                              <UIKittenIconButton fill="#fff" name="clock-outline" width={24} height={24} style={{ marginRight: 2 }} />
                              <Text category="h5" status="control">
                                {moment(new Date(bse.dateBSE)).format('LT')}
                              </Text>
                            </View>
                          ) : null}
                        </View>
                        {bse.StatusBse === StatusBse.BSEAskStart
                          ? (
                            <>
                              <Button
                                status="control"
                                style={{ backgroundColor: '#fff', marginHorizontal: 5, flexShrink: 1 }}
                                onPress={() => openConfirmerDemarrageModal()}
                              >
                                Confirmer le démarrage
                              </Button>
                            </>
                          ) : (
                            <></>
                          )}
                      </FCard>
                    </>
                  )
                    : (
                      <>
                        {bse.StatusBse !== StatusBse.BSEStarted && bse.StatusBse !== StatusBse.BSEProtocolSoins ? (<Button onPress={() => openDemandeBseModal()}>Faire une demande de BSE</Button>) : (<></>)}
                      </>
                    )}
                </>
              )}
          </>
        )
        : (
          <>
          </>
        )}
      <CustomModal
        title={`${`Vous êtes sur le point de ${openDelete?.title}.`
          + '\n'}${
          openDelete?.title === 'faire une demande de Bilan Sanitaire d\'Élevage'
            ? ''
            : 'En confirmant le démarrage du BSE, vous confirmez également la présence du vétérinaire sur l\'exploitation pour la réalisation de celui-ci.'}`}
        subtitle="Souhaitez-vous continuer ?"
        before={<></>}
        noSafeArea
        visible={openDelete !== undefined}
        scrollable={false}
        onClose={() => setOpenDelete(undefined)}
        insideModalStyle={{ textAlign: 'center', padding: 20 }}
        buttonText="Continuer"
        secondButtonText="Annuler"
        heightPercentage={0.36}
        buttonPress={() => {
          openDelete?.title === 'faire une demande de Bilan Sanitaire d\'Élevage'
            ? FaireDemandeBse()
            : ConfirmerDemarrage();
        }}
      />
    </>

  );
};

export default FaireDemandeBseCard;
