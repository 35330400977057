import React, { useEffect, useRef } from 'react';

import { Text } from '@ui-kitten/components';

import { useFocusEffect } from '@react-navigation/native';
import { AppState, AppStateStatus } from 'react-native';
import moment from 'moment/moment';
import MaxWidthContainer from '../components/MaxWidthContainer';
import { useUser } from '../src/API/UserContext';
import { ModelSortDirection } from '../src/API';

export default function LockedScreen() {
  const { user, refetch } = useUser();
  useEffect(() => {
    refetch({ variables: { id: user.id } });
    return () => {
    };
  }, [user, refetch]);

  const appState = useRef(AppState.currentState);

  useEffect(() => {
    const listener = AppState.addEventListener('change', handleAppStateChange);

    return () => {
      listener.remove();
    };
  }, []);

  const handleAppStateChange = (nextAppState: AppStateStatus) => {
    if (
      appState.current.match(/inactive|background/)
      && nextAppState === 'active'
    ) {
      refetch({ variables: { id: user.id } });
    }

    appState.current = nextAppState;
  };

  return (
    <>
      <MaxWidthContainer
        outerViewProps={{
          style: {
            paddingHorizontal: 20,
          },
        }}
      >
        <Text category="h1" style={{ marginTop: 40 }}>Compte en attente de validation</Text>
        <Text category="p1">
          Merci de vous être inscrit sur l'application ScanFlock.
          Avant de pouvoir avoir accès à la plateforme,
          notre équipe doit encore valider votre inscription.
        </Text>
      </MaxWidthContainer>
    </>
  );
}
