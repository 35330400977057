import React from 'react';
import { useTheme } from '@ui-kitten/components';
import { useLinkTo } from '@react-navigation/native';

import { useUser } from '../src/API/UserContext';
import { Breeding, Consultation } from '../src/API';
import InfoCard from './InfoCard';
import CompButton from '../components/Button';
import DocumentComponent from '../components/Document/DocumentComponent';
import { useListConsultationsByBreedinG } from '../src/API/Consultation';

type FactureProps = {
  breeding: Breeding;
};

const FactureSectionComp = ({
  breeding,
}: FactureProps) => {
  const theme = useTheme();
  const linkTo = useLinkTo();
  const { user } = useUser();
  // console.log('breeding:', breeding?.id);
  // console.log('user:', user);

  // Factures partagées par véto à éleveur qu'il faut afficher dans cette page
  let fetchMore;
  const { consultationS, fetchMoreConsultations } = useListConsultationsByBreedinG(20, { sharedFacture: { eq: true } }, 'network-only', breeding);
  fetchMore = fetchMoreConsultations;
  // console.log('cccccc', consultationS);
  const consultationSvar = consultationS;

  const consultations: Consultation[] | null = [];

  /** ELEVAGE PART OF APP */

  if (user?.userGroup === 'eleveur' && consultationSvar) {
    consultationSvar.map((item) => {
      if (item && item.factureId
            // eslint-disable-next-line no-underscore-dangle
            && item.facture && !item.facture._deleted) {
        // console.log('llll', consultations.length);
        if (consultations.length < 10) {
          consultations.push(item);
        }
      }
      // console.log('item:', item);
      return () => {};
    });
  }

  // console.log('consultations:', consultations);
  return (
    <>
      {consultations.length > 0
        ? (
          <>
            {React.Children.toArray(consultations.map(
              // eslint-disable-next-line no-underscore-dangle
              (item) => (item && item.facture && !item.facture._deleted
                  && item.facture.key && item.facture.key.includes('Facture')
                  && (
                  <DocumentComponent
                    facture
                    facturePrice={item.priceConsultation}
                    key={item.factureId}
                    document={item.facture}
                  />
                  )),
            ))}
            <CompButton onPress={() => linkTo(`/tableau-de-bord/factures/${breeding.id}`)} status="primary" appearance="outline" style={{ justifyContent: 'center' }}>
              Voir toutes les factures
            </CompButton>
          </>
        )
        : (
          <InfoCard
            title="alertInfo"
            iconShow="alert-circle-outline"
                  // iconCloseShow="close-outline"
            infoStyle={{ backgroundColor: theme['color-primary-100'] }}
            description="Vos factures liées à des consultations (téléconsultations, prestations de conseil, BSE) sont stockées ici. Vous n’avez pas de facture pour le moment."
            alertInfo="alertInfo"
          />
        )}
    </>
  );
};

export default FactureSectionComp;
