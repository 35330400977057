import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { TabElevageParamList } from '../../types';

import ElevageScreen from '../../screens/ElevageScreen/ElevageScreen';
import AjoutAnimalScreen from '../../screens/ElevageScreen/AjoutAnimalScreen';
import DetailAnimalScreen from '../../screens/ElevageScreen/DetailAnimalScreen';
import AjoutLotScreen from '../../screens/ElevageScreen/AjoutLotScreen';
import AjoutBatimentScreen from '../../screens/ElevageScreen/AjoutBatimentScreen';
import DetailLotScreen from '../../screens/ElevageScreen/DetailLotScreen';
import DetailBatimentScreen from '../../screens/ElevageScreen/DetailBatimentScreen';
import ManageAffectedAnimalsScreen from '../../screens/ElevageScreen/ManageAffectedAnimalsScreen';
import VoirPlusScreen from '../../screens/ElevageScreen/VoirPlusScreen';

import ModifyElevageScreen from '../../screens/ElevageScreen/ModifyElevageScreen';

const Stack = createStackNavigator<TabElevageParamList>();

export default () => (
  <Stack.Navigator
    initialRouteName="elevage"
    screenOptions={{
      headerShown: false,
    }}
  >
    <Stack.Screen
      name="elevage"
      component={ElevageScreen}
      options={({ route }) => ({
        headerBackTitle: route.name, title: 'Elevages',
      })}
    />
    <Stack.Screen
      name="detail-animal"
      component={DetailAnimalScreen}
    />
    <Stack.Screen
      name="detail-lot"
      component={DetailLotScreen}
    />
    <Stack.Screen
      name="detail-batiment"
      component={DetailBatimentScreen}
    />

    <Stack.Screen
      name="voir-plus-animal"
      component={VoirPlusScreen}
    />
    <Stack.Screen
      name="voir-plus-lot"
      component={VoirPlusScreen}
    />
    <Stack.Screen
      name="voir-plus-batiment"
      component={VoirPlusScreen}
    />

    <Stack.Screen
      name="manage-affected-animals"
      component={ManageAffectedAnimalsScreen}
    />
    <Stack.Screen
      name="modify-elevage"
      component={ModifyElevageScreen}
    />
    <Stack.Screen
      name="ajout-animal"
      component={AjoutAnimalScreen}
    />
    <Stack.Screen
      name="ajout-lot"
      component={AjoutLotScreen}
    />
    <Stack.Screen
      name="ajout-batiment"
      component={AjoutBatimentScreen}
    />
    <Stack.Screen
      name="modify-animal"
      component={AjoutAnimalScreen}
    />
    <Stack.Screen
      name="modify-lot"
      component={AjoutLotScreen}
    />
    <Stack.Screen
      name="modify-batiment"
      component={AjoutBatimentScreen}
    />
  </Stack.Navigator>
);
