import React, { Dispatch } from 'react';
import RtcEngine, { StreamFallbackOptions } from 'react-native-agora';
import { RtcEngineEvents } from 'react-native-agora/src/common/RtcEvents';

export interface UidInterface {
  uid: number | string;
  hasAudio: boolean;
  hasVideo: boolean;
}

export interface UidStateInterface {
  uids: Array<UidInterface>;
}

export interface CallbacksInterface
  extends RtcEngineEvents,
  CustomCallbacksInterface {}

export interface CustomCallbacksInterface {
  /**
   * Callback for EndCall
   */
  EndCall(): void;
  /**
   * Callback for SwitchCamera
   */
  SwitchCamera(): void;
  /**
   * Callback for FlashLight
   */
  FlashLight(on: boolean): void;
  /**
   * Callback for when a user mutes their audio
   */
  LocalMuteAudio(muted: boolean): void;
  /**
   * Callback for when a user mutes their video
   */
  LocalMuteVideo(muted: boolean): void;
}

export interface ActionInterface<
  T extends keyof CallbacksInterface,
  K extends CallbacksInterface,
> {
  type: T;
  value: Parameters<K[T]>;
}

export enum VideoMode {
  ELEVEUR,
  VETERINAIRE,
}

export type DispatchType<T extends keyof CallbacksInterface> = Dispatch<
ActionInterface<T, CallbacksInterface>
>;

export type ActionType<T extends keyof CallbacksInterface> = ActionInterface<
T,
CallbacksInterface
>;

export interface RtcContextInterface {
  dispatch: DispatchType<keyof CallbacksInterface>;
  isTorchAvailable: boolean;
  leave: () => Promise<void>;
}

const RtcContext = React.createContext<RtcContextInterface>(
  {} as RtcContextInterface,
);

export const RtcProvider = RtcContext.Provider;
export const RtcConsumer = RtcContext.Consumer;
export default RtcContext;

/**
 * Select a pre built layout
 */
export enum layout {
  /**
   * 0: Grid layout: each user occupies a cell in the grid
   */
  grid = 0,
  /**
   * 2: Pinned layout: MaxUser occupies the main view, the other users are in a floating view on top
   */
  pin = 1,
}

/**
 * User role for live streaming mode
 */
export enum role {
  /**
   * 1: A host can both send and receive streams.
   */
  Broadcaster = 1,
  /**
   * 2: The default role. An audience can only receive streams.
   */
  Audience = 2,
}

/**
 * Mode for RTC (Live or Broadcast)
 */
export enum mode {
  /**
   * 0: (Default) The Communication profile.
   * Use this profile in one-on-one calls or group calls, where all users can talk freely.
   */
  Communication = 0,
  /**
   * 1: The Live-Broadcast profile.
   * Users in a live-broadcast channel have a role as either host or audience. A host can both send and receive streams; an audience can only receive streams.
   */
  LiveBroadcasting = 1,
}

export interface RtcPropsInterface {
  /**
   * Agora App ID - used to authenticate the request
   */
  appId: string;
  /**
   * Channel name to join - users in the same channel can communicate with each other
   */
  channel: string;
  /**
   * (optional) UID for local user to join the channel (default: 0)
   */
  uid?: number;
  /**
   * Once set to true, the UI Kit attempts to join the channel. Can be set to false to initialise the SDK and wait before joining the channel. (default: true)
   */
  callActive?: boolean;
  // enableDualStream?: boolean;
  // /**
  //  * Enables dual stream mode. (default: false)
  //  */
  /**
   * Enable dual stream mode with selected fallback option. (default: disabled)
   */
  dualStreamMode?: StreamFallbackOptions;
  /**
   * Set local user's role between audience and host. Use with mode set to livestreaming. (default: host)
   */
  role?: role;
  /**
   * Select between livestreaming and communication mode for the SDK. (default: communication)
   */
  mode?: mode;
  /**
   * Enable the mic before joining the call. (default: true)
   */
  enableAudio?: boolean;
  /**
   * Enable the camera before joining the call. Only use for initiak(default: true)
   */
  enableVideo?: boolean;
}
