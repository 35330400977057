export default {
  'A)1)b)1.1/': [{
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)b)2.3/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)b)3.3/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)b)3.4/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)b)3.5/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)1.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)10.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)2.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)5.4/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)20.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)13.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)2)a)21.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)2)a)15.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)c)1/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)c)2/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)c)17/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)c)19/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)e)1.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)i)2/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)i)9/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)4)a)33/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)4)a)34/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)4)a)35/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)a)6.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)a)1.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)a)4.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)e)5.16/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)f)10.3/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)f)10.4/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)f)10.5/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)f)10.6/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)f)10.7/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)9)c)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)9)d)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)11)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)15)b)1.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)16)a)2/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)16)a)3/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)16)c)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)16)d)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)12.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)20.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)26.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)27.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)28.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)29.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)30.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)32.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)33.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)34.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)35.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)36.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)37.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)38.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)39.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)52.',
  },
  ],
  'A)1)b)1.2/': [{
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)b)2.3/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)b)3.3/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)b)3.4/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)b)3.5/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)1.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)10.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)2.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.2bis/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.2ter/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.4/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.7/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.15/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.18/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.20/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.21/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.22/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.35/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.37/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)18.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)11.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)21.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)c)17/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)e)1.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)j)4/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)j)11/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)4)a)34/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)4)a)35/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)a)6.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)a)1.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)a)4.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)e)5.16/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)14)b)5.3/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)14)b)5.5/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)15)a)6.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)15)a)3.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)15)a)4.a)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)15)b)1.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)16)a)3/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)16)d)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)a)7/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)12.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)20/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)27.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)28.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)29.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)30.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)32.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)33.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)34.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)35.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)36.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)37.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)38.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)39.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)40.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)52.',
  },
  ],
  'A)1)b)1.3/': [{
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)b)2.3/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)b)3.3/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)b)3.4/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)b)3.5/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)10.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)2.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.r)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.b)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.s)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.c)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.t)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.d)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.u)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.e)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.k)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.w)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.g)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.z)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.j)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)5.4/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.2/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.2bis/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.2ter/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.4/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.7/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.15/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.18/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.20/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.21/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.22/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.35/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.37/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.41/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)2.3/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)2.4/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)18.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)11.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)21.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)c)17/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)e)1.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)j)4/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)j)11/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)4)a)34/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)4)a)35/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)a)6.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)a)1.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)a)4.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)e)5.16/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)f)8.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)f)10.3/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)f)10.4/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)f)10.5/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)f)10.6/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)f)10.7/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)9)c)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)9)d)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)11)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)12)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)14)b)5.3/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)14)b)5.5/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)15)a)6.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)15)a)3.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)15)a)4.a)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)15)b)1.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)16)a)3/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)16)d)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)a)7/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)12.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)27.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)28.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)29.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)30.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)32.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)33.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)34.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)35.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)36.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)37.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)38.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)39.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)40.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)52.',
  },
  ],
  'A)1)b)1.4/': [{
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)b)2.3/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)b)3.3/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)b)3.4/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)b)3.5/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.u)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.e)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.k)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.w)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.g)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.z)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.j)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)5.4/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.2/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.2bis/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.2ter/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.4/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.7/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.15/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.18/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.20/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.21/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.22/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.35/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.37/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.41/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)2.3/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)2.4/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)18.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)11.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)21.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)c)17/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)e)1.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)j)4/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)j)11/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)4)a)34/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)4)a)35/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)a)6.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)a)1.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)a)4.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)f)8.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)f)10.3/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)f)10.4/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)f)10.5/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)f)10.6/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)f)10.7/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)9)c)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)9)d)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)11)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)12)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)14)b)5.3/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)14)b)5.5/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)15)a)6.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)15)a)3.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)15)b)1.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)16)a)3/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)16)d)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)a)7/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)12.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)27.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)28.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)29.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)30.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)32.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)33.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)34.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)35.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)36.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)37.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)38.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)39.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)40.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)52.',
  },
  ],
  'A)1)b)1.5/': [{
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)b)3.5/',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.t)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.d)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.u)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.e)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.k)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.2/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.2bis/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.2ter/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.3/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.15/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)18.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)11.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)2)a)21.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)2)a)14.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)2)a)15.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)e)2.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)11)d)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)12)A)a)5/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)16)d)16.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)27.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)32.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)33.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)34.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)39.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)40.',
  },
  ],
  'A)1)b)1.6/': [{
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)b)3.1/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)b)3.2/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)b)3.3/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)b)3.4/',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)1)b)3.5/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.r)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.d)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)2.4/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)21.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)14.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)15.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)e)2.',
  },
  ],
  'A)1)b)1.7/': [{
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)b)3.1/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)b)3.2/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)b)3.3/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)b)3.4/',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)1)b)3.5/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.r)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.b)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)21.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)14.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)15.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)2.4/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)e)2.',
  },
  ],
  'A)1)b)2.1/': [{
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)2.1/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.s)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.c)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.t)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.d)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.u)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.e)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.k)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.2/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.2bis/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.2ter/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.15/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.20/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.21/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.22/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.41/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)18.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)11.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)j)4/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)4)a)33/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)4)a)34/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)4)a)35/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)4)a)46/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)11)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)12)A)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)16)a)3/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)16)d)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)a)7/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)27.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)28.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)32.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)33.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)34.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)35.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)36.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)37.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)39.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)40.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)41.',
  },
  ],
  'A)1)b)2.2/': [{
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.18/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.37/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.38/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)4)a)36/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)4)a)37/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)12)B)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)42.',
  },
  ],
  'A)1)b)2.3/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)1)c)2.1/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.s)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.c)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.t)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.d)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.u)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.e)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.k)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.2/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.2bis/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.2ter/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.15/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.20/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.21/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.22/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.37/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.38/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)9.41/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)18.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)11.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)21.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)15.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)c)17/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)j)4/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)4)a)33/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)4)a)35/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)46/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)12)A)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)12)B)b)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)12)B)e)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)12)B)f)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)12)B)g)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)a)7/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)27.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)28.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)32.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)33.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)34.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)35.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)36.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)37.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)38.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)39.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)40.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)41.',
  },
  ],
  'A)1)c)10.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)1)c)2.',
  },
  ],
  'A)1)c)10.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)2.',
  },
  ],
  'A)1)c)11.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)1)c)9.',
  },
  ],
  'A)1)c)11.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)9.',
  },
  ],
  'A)1)c)2.1/': [{
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.u)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.e)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)1)c)3.k)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)2)a)18.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)2)a)11.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)2)a)21.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)2)a)15.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)2)c)17/',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)11)d)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)12)B)b)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)12)B)g)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)27.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)28.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)32.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)33.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)34.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)35.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)36.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)37.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)38.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)39.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)40.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)41.',
  },
  ],
  'A)1)c)3.b)1/': [{
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)12.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)a)6.2/',
  },
  ],
  'A)1)c)3.c)1/': [{
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)30.',
  },
  ],
  'A)1)c)3.c)2/': [{
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)28.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)30.',
  },
  ],
  'A)1)c)3.c)4/': [{
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)28.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)32.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)34.',
  },
  ],
  'A)1)c)3.d)1/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.e)2/',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.e)3/',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.e)5/',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.e)6/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)28.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)32.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)34.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)36.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)37.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)38.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)42.',
  },
  ],
  'A)1)c)3.d)2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.e)2/',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.e)3/',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.e)5/',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.e)6/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)28.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)32.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)34.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)36.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)37.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)38.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)42.',
  },
  ],
  'A)1)c)3.d)3/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.e)2/',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.e)3/',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.e)5/',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.e)6/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)28.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)32.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)34.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)36.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)37.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)38.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)42.',
  },
  ],
  'A)1)c)3.d)4/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.e)2/',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.e)3/',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.e)5/',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.e)6/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)27.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)28.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)37.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)38.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)39.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)40.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)42.',
  },
  ],
  'A)1)c)3.d)5/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.e)2/',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.e)3/',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.e)5/',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.e)6/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)27.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)39.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)40.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)42.',
  },
  ],
  'A)1)c)3.e.1/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)1.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)2.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)3.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)4.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)5.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)6.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)7.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)8.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)28.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)36.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)37.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)38.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)39.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)42.',
  },
  ],
  'A)1)c)3.e)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)2)a)18.',
  },
  ],
  'A)1)c)3.e)2/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)2)a)18.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)1.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)2.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)3.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)4.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)5.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)6.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)7.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)8.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)27.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)40.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)42.',
  },
  ],
  'A)1)c)3.e)3/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)2)a)18.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)1.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)2.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)3.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)4.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)5.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)6.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)7.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)8.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)27.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)28.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)30.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)40.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)42.',
  },
  ],
  'A)1)c)3.e)4/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)2)a)18.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)42.',
  },
  ],
  'A)1)c)3.e)5/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)2)a)11.3/',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)2.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)3.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)4.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)5.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)6.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)8.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)27.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)28.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)30.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)32.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)34.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)42.',
  },
  ],
  'A)1)c)3.e)6/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)2)a)11.3/',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)1.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)2.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)3.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)4.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)5.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)6.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)7.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)8.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)27.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)28.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)30.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)32.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)34.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)37.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)38.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)40.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)51.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)52.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)42.',
  },
  ],
  'A)1)c)3.g)1/ ': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)9.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)10.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)11.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)12.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)13.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)14.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)15.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)16.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)17.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)18.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)19.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)26.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)29.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)31.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)39.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)43.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)44.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)45.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)46.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)50.',
  },
  ],
  'A)1)c)3.g)2/ ': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)9.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)10.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)11.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)12.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)13.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)14.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)15.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)16.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)17.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)18.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)19.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)26.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)29.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)31.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)39.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)43.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)44.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)45.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)46.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)50.',
  },
  ],
  'A)1)c)3.g)3/ ': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)9.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)10.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)11.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)12.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)13.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)14.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)15.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)16.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)17.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)18.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)19.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)26.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)28.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)29.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)31.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)39.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)43.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)44.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)45.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)46.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)50.',
  },
  ],
  'A)1)c)3.g)4/ ': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)9.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)10.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)11.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)12.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)13.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)14.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)15.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)16.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)17.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)18.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)19.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)26.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)29.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)31.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)39.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)43.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)44.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)45.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)46.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)50.',
  },
  ],
  'A)1)c)3.g)5/ ': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)9.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)10.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)11.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)12.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)13.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)14.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)15.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)16.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)17.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)18.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)19.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)26.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)29.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)31.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)39.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)43.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)44.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)45.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)46.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)50.',
  },
  ],
  'A)1)c)3.g)6/ ': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)9.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)10.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)11.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)12.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)13.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)14.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)15.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)16.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)17.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)18.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)19.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)26.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)29.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)31.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)39.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)43.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)44.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)45.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)46.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)50.',
  },
  ],
  'A)1)c)3.g)7/ ': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)9.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)10.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)11.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)12.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)13.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)14.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)15.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)16.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)17.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)18.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)19.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)26.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)29.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)31.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)39.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)43.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)44.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)45.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)46.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)50.',
  },
  ],
  'A)1)c)3.g)8/ ': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)9.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)10.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)11.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)12.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)13.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)14.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)15.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)16.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)17.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)18.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)19.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)26.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)29.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)31.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)39.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)43.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)44.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)45.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)46.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)50.',
  },
  ],
  'A)1)c)3.g)9/ ': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)9.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)10.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)11.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)12.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)13.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)14.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)15.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)16.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)17.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)18.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)19.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)26.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)29.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)31.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)39.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)43.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)44.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)45.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)46.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)50.',
  },
  ],
  'A)1)c)3.h)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)8.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)9.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)31.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)3.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)26.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)27.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)28.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)29.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)30.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)36.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)37.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)38.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)40.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)51.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)52.',
  },
  ],
  'A)1)c)3.h)2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)3.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)4.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)26.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)27.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)28.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)29.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)B)30.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)31.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)34.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)35.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)36.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)37.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)38.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)40.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)52.',
  },
  ],
  'A)1)c)3.h)3/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)31.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)3.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)26.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)27.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)28.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)29.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)30.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)52.',
  },
  ],
  'A)1)c)3.h)4/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)26.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)27.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)28.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)29.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)30.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)33.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)34.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)35.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)36.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)37.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)38.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)40.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)51.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)52.',
  },
  ],
  'A)1)c)3.h)5/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)7.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)10.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)11.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)12.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)13.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)14.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)15.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)16.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)17.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)18.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)19.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)26.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)27.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)28.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)29.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)30.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)31.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)36.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)37.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)38.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)46.',
  },
  ],
  'A)1)c)3.h)6/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)3.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)4.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)20.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)21.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)22.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)23.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)24.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)26.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)27.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)28.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)29.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)30.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)31.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)35.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)40.',
  },
  ],
  'A)1)c)3.h)7/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)1.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)2.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)5.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)6.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)8.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)13.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)23.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)35.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)41.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)42.',
  },
  {
    veto: false, poser: true, imposer: false, interdire: false, cible: 'A)3)b)52.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)53.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)16)c)',
  },
  ],
  'A)1)c)3.k)7/': [{
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)42.',
  },
  ],
  'A)1)c)3.r)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)1)c)3.b)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.s)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.c)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.t)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.d)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.u)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.e)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.k)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)27.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)28.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)29.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)30.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)32.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)33.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)34.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)36.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)37.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)38.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)39.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)40.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)c)17/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)e)1.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)11)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)12)',
  },
  ],
  'A)1)c)3.r)2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.b)',
  },
  ],
  'A)1)c)3.s)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)1)c)3.c)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.t)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.d)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.u)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.e)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.k)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)27.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)36.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)37.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)38.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)40.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)3)b)42.',
  },
  ],
  'A)1)c)3.s)2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.c)',
  },
  ],
  'A)1)c)3.t)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)1)c)3.d)',
  },
  ],
  'A)1)c)3.t)2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.d)',
  },
  ],
  'A)1)c)3.u)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)1)c)3.e)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)1)c)3.k)',
  },
  ],
  'A)1)c)3.u)2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.e)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.k)',
  },
  ],
  'A)1)c)3.v)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)1)c)3.f)',
  },
  ],
  'A)1)c)3.v)2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.f)',
  },
  ],
  'A)1)c)3.w)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)1)c)3.g)',
  },
  ],
  'A)1)c)3.w)2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.g)',
  },
  ],
  'A)1)c)3.x)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)1)c)3.h)',
  },
  ],
  'A)1)c)3.x)2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.h)',
  },
  ],
  'A)1)c)3.y)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)1)c)3.i)',
  },
  ],
  'A)1)c)3.y)2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.i)',
  },
  ],
  'A)1)c)3.z)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)1)c)3.j)',
  },
  ],
  'A)1)c)3.z)2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)1)c)3.j)',
  },
  ],
  'A)1)c)36quater/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)',
  },
  ],
  'A)1)c)4.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)52.',
  },
  ],
  'A)1)c)7.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)52.',
  },
  ],
  'A)1)c)8.3/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)36.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)37.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)38.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)3)b)52.',
  },
  ],
  'A)1)c)9.1/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)b)1.a)',
  },
  ],
  'A)1)c)9.10/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)a)4.',
  },
  ],
  'A)1)c)9.11/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)a)5.',
  },
  ],
  'A)1)c)9.12/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)a)6.',
  },
  ],
  'A)1)c)9.13/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)a)7.',
  },
  ],
  'A)1)c)9.14/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)b)1.c)',
  },
  ],
  'A)1)c)9.15/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)1)',
  },
  ],
  'A)1)c)9.16/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)a)8.',
  },
  ],
  'A)1)c)9.17/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)b)2.',
  },
  ],
  'A)1)c)9.17bis/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)b)2.',
  },
  ],
  'A)1)c)9.18/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)7)c)',
  },
  ],
  'A)1)c)9.19/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)7)d)',
  },
  ],
  'A)1)c)9.2/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)c)',
  },
  ],
  'A)1)c)9.20/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)a)9.',
  },
  ],
  'A)1)c)9.21/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)7)e)',
  },
  ],
  'A)1)c)9.22/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)7)g)',
  },
  ],
  'A)1)c)9.23/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)7)f)',
  },
  ],
  'A)1)c)9.24/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)b)3.',
  },
  ],
  'A)1)c)9.25/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)b)4.',
  },
  ],
  'A)1)c)9.26/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)a)10.',
  },
  ],
  'A)1)c)9.27/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)a)11.',
  },
  ],
  'A)1)c)9.28/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)a)12.',
  },
  ],
  'A)1)c)9.29/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)b)1.b)',
  },
  ],
  'A)1)c)9.2bis/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)c)',
  },
  ],
  'A)1)c)9.2ter/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)c)',
  },
  ],
  'A)1)c)9.3/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)5)a)',
  },
  ],
  'A)1)c)9.30/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)b)5.',
  },
  ],
  'A)1)c)9.31/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)a)13.',
  },
  ],
  'A)1)c)9.32/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)a)14.',
  },
  ],
  'A)1)c)9.33/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)a)15.',
  },
  ],
  'A)1)c)9.34/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)a)16.',
  },
  ],
  'A)1)c)9.35/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)a)17.',
  },
  ],
  'A)1)c)9.36/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)',
  },
  ],
  'A)1)c)9.36bis/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)',
  },
  ],
  'A)1)c)9.36ter/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)',
  },
  ],
  'A)1)c)9.37/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)6)',
  },
  ],
  'A)1)c)9.38/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)a)18.',
  },
  ],
  'A)1)c)9.39/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)b)6.',
  },
  ],
  'A)1)c)9.4/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)5)b)',
  },
  ],
  'A)1)c)9.40/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)4)',
  },
  ],
  'A)1)c)9.41/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)a)19.',
  },
  ],
  'A)1)c)9.42/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)b)6.',
  },
  ],
  'A)1)c)9.44/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)a)12.',
  },
  {
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)b)4.',
  },
  ],
  'A)1)c)9.47/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)b)8.',
  },
  ],
  'A)1)c)9.5/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)a)1.',
  },
  ],
  'A)1)c)9.6/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)a)2.',
  },
  ],
  'A)1)c)9.7/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)7)a)',
  },
  ],
  'A)1)c)9.8/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)a)3.',
  },
  ],
  'A)10)a)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)10)b)',
  },
  ],
  'A)10)a)2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)10)b)',
  },
  ],
  'A)10)b)3/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)b)6.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)g)',
  },
  ],
  'A)10)b)7/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)g)',
  },
  ],
  'A)11)a)3.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)b)6.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)g)',
  },
  ],
  'A)11)a)4.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)11)a)1.',
  },
  ],
  'A)11)a)4.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)11)a)1.',
  },
  ],
  'A)11)a)5.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)11)a)2.',
  },
  ],
  'A)11)a)5.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)11)a)2.',
  },
  ],
  'A)11)a)6.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)11)a)3.',
  },
  ],
  'A)11)a)6.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)11)a)3.',
  },
  ],
  'A)11)c)10/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)1)',
  },
  ],
  'A)11)c)2/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)1)',
  },
  ],
  'A)11)c)3/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)1)',
  },
  ],
  'A)11)c)4/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)1)',
  },
  ],
  'A)11)c)5/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)1)',
  },
  ],
  'A)11)c)6/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)1)',
  },
  ],
  'A)11)c)7/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)1)',
  },
  ],
  'A)11)c)8/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)1)',
  },
  ],
  'A)11)c)9/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)1)',
  },
  ],
  'A)11)d)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)11)c)',
  },
  ],
  'A)11)d)2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)11)c)',
  },
  ],
  'A)12)A)a)3/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)g)',
  },
  ],
  'A)12)A)a)7/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)b)6.',
  },
  ],
  'A)12)A)a)8/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)g)',
  },
  ],
  'A)12)A)b)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)g)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)b)6.',
  },
  ],
  'A)12)A)b)4/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)g)',
  },
  ],
  'A)12)A)c)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)12)A)a)',
  },
  ],
  'A)12)A)c)2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)12)A)a)',
  },
  ],
  'A)12)A)d)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)12)A)b)',
  },
  ],
  'A)12)A)d)2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)12)A)b)',
  },
  ],
  'A)12)B)': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)6)',
  },
  ],
  'A)12)B)d)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)7)c)',
  },
  ],
  'A)12)B)d)10/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)7)c)',
  },
  ],
  'A)12)B)d)11/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)7)c)',
  },
  ],
  'A)12)B)d)12/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)7)c)',
  },
  ],
  'A)12)B)d)13/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)7)c)',
  },
  ],
  'A)12)B)d)2/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)7)c)',
  },
  ],
  'A)12)B)d)3/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)7)c)',
  },
  ],
  'A)12)B)d)4/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)7)c)',
  },
  ],
  'A)12)B)d)5/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)7)c)',
  },
  ],
  'A)12)B)d)6/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)7)c)',
  },
  ],
  'A)12)B)d)7/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)7)c)',
  },
  ],
  'A)12)B)d)8/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)7)c)',
  },
  ],
  'A)12)B)d)9/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)7)c)',
  },
  ],
  'A)12)B)e)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)12)B)a)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)12)B)f)',
  },
  ],
  'A)12)B)e)2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)12)B)a)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)12)B)f)',
  },
  ],
  'A)12)B)g)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)12)B)c)',
  },
  ],
  'A)12)B)g)2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)12)B)c)',
  },
  ],
  'A)12)B)h)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)12)B)d)',
  },
  ],
  'A)12)B)h)2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)12)B)d)',
  },
  ],
  'A)13)': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)4)',
  },
  ],
  'A)13)b)3.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)13)b)1.',
  },
  ],
  'A)13)b)3.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)13)b)1.',
  },
  ],
  'A)13)b)4.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)13)b)2.',
  },
  ],
  'A)13)b)4.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)13)b)2.',
  },
  ],
  'A)13)c)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)13)a)',
  },
  ],
  'A)13)c)2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)13)a)',
  },
  ],
  'A)14)': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)5)',
  },
  ],
  'A)14)b)6.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)14)b)5.',
  },
  ],
  'A)14)b)6.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)14)b)5.',
  },
  ],
  'A)14)c)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)14)a)',
  },
  ],
  'A)14)c)2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)14)a)',
  },
  ],
  'A)15)a)5.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)a)1.',
  },
  ],
  'A)15)a)5.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)15)a)1.',
  },
  ],
  'A)15)a)6.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)a)3.',
  },
  ],
  'A)15)a)6.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)15)a)3.',
  },
  ],
  'A)15)b)1.c)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)b)1.a)',
  },
  ],
  'A)15)b)1.c)2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)15)b)1.a)',
  },
  ],
  'A)15)b)1.d)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)b)1.b)',
  },
  ],
  'A)15)b)1.d)2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)15)b)1.b)',
  },
  ],
  'A)15)c)3.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)c)1.',
  },
  ],
  'A)15)c)3.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)15)c)1.',
  },
  ],
  'A)15)c)4.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)c)2.',
  },
  ],
  'A)15)c)4.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)15)c)2.',
  },
  ],
  'A)15)e)3.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)e)1.',
  },
  ],
  'A)15)e)3.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)15)e)1.',
  },
  ],
  'A)15)f)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)d)',
  },
  ],
  'A)15)f)2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)15)d)',
  },
  ],
  'A)16)3/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)16)d)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)16)b)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)16)c)',
  },
  ],
  'A)16)a)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)16)b)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)16)c)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)16)d)',
  },
  ],
  'A)16)a)2/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)16)c)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)16)b)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)16)d)',
  },
  ],
  'A)16)d)10.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)16)d)2.',
  },
  ],
  'A)16)d)10.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)16)d)2.',
  },
  ],
  'A)16)d)11.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)16)d)3.',
  },
  ],
  'A)16)d)11.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)16)d)3.',
  },
  ],
  'A)16)d)12.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)16)d)4.',
  },
  ],
  'A)16)d)12.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)16)d)4.',
  },
  ],
  'A)16)d)13.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)16)d)5.',
  },
  ],
  'A)16)d)13.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)16)d)5.',
  },
  ],
  'A)16)d)14.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)16)d)6.',
  },
  ],
  'A)16)d)14.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)16)d)6.',
  },
  ],
  'A)16)d)15.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)16)d)7.',
  },
  ],
  'A)16)d)15.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)16)d)7.',
  },
  ],
  'A)16)d)16.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)16)d)8.',
  },
  ],
  'A)16)d)16.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)16)d)8.',
  },
  ],
  'A)16)d)9.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)16)d)1.',
  },
  ],
  'A)16)d)9.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)16)d)1.',
  },
  ],
  'A)2)a)10.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)13)',
  },
  ],
  'A)2)a)10.2/': [{
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)13)',
  },
  ],
  'A)2)a)12.3/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)a)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)',
  },
  ],
  'A)2)a)12.4/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)a)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)',
  },
  ],
  'A)2)a)14.4/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)1)f)',
  },
  ],
  'A)2)a)16.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)2)a)2.',
  },
  ],
  'A)2)a)16.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)2)a)2.',
  },
  ],
  'A)2)a)17.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)2)a)3.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)a)4.',
  },
  ],
  'A)2)a)17.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)2)a)3.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)15)',
  },
  ],
  'A)2)a)18.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)2)a)11.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)11)d)',
  },
  ],
  'A)2)a)18.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)2)a)11.',
  },
  ],
  'A)2)a)19.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)2)a)12.',
  },
  ],
  'A)2)a)19.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)2)a)12.',
  },
  ],
  'A)2)a)20.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)2)a)13.',
  },
  ],
  'A)2)a)20.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)2)a)13.',
  },
  ],
  'A)2)a)21.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)2)a)14.',
  },
  ],
  'A)2)a)21.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)2)a)14.',
  },
  ],
  'A)2)a)8.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)h)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)e)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)16.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)8)',
  },
  ],
  'A)2)a)9.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)14)',
  },
  ],
  'A)2)a)9.2/': [{
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)14)',
  },
  ],
  'A)2)b)10/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)9)',
  },
  ],
  'A)2)b)12/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)9)',
  },
  ],
  'A)2)b)13/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)9)',
  },
  ],
  'A)2)b)14/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)9)',
  },
  ],
  'A)2)b)15/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)9)',
  },
  ],
  'A)2)b)21/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)a)6.',
  },
  ],
  'A)2)b)22/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)7)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)a)6.',
  },
  ],
  'A)2)b)23/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)a)6.',
  },
  ],
  'A)2)b)24/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)a)5.',
  },
  ],
  'A)2)b)25/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)a)5.',
  },
  ],
  'A)2)g)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)c)10.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)13.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)14.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)15.',
  },
  ],
  'A)2)g)2/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)c)10.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)13.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)14.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)15.',
  },
  ],
  'A)2)g)3/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)c)10.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)13.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)14.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)15.',
  },
  ],
  'A)2)g)4/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)c)10.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)13.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)14.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)15.',
  },
  ],
  'A)2)i)2/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)4)',
  },
  ],
  'A)2)j)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)a)',
  },
  ],
  'A)2)j)14/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)b)8.',
  },
  ],
  'A)2)j)2/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)h)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)e)',
  },
  ],
  'A)2)j)3/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)g)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)c)',
  },
  ],
  'A)2)j)4/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)11)a)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)11)b)',
  },
  ],
  'A)2)j)6/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)f)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)e)',
  },
  ],
  'A)2)j)7/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)b)',
  },
  ],
  'A)2)j)8/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)f)',
  },
  ],
  'A)2)j)9/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)10)',
  },
  ],
  'A)2)k)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)2)b)',
  },
  ],
  'A)2)k)2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)2)b)',
  },
  ],
  'A)2)l)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)2)c)',
  },
  ],
  'A)2)l)2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)2)c)',
  },
  ],
  'A)2)m)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)2)f)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)8)',
  },
  ],
  'A)2)m)2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)2)f)',
  },
  ],
  'A)2)n)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)2)g)',
  },
  ],
  'A)2)n)2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)2)g)',
  },
  ],
  'A)2)o)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)2)h)',
  },
  ],
  'A)2)o)2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)2)h)',
  },
  ],
  'A)2)p)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)2)i)',
  },
  ],
  'A)2)p)2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)2)i)',
  },
  ],
  'A)2)q)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)2)j)',
  },
  ],
  'A)2)q)2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)2)j)',
  },
  ],
  'A)3)a)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)4)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)5)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)6)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)7)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)8)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)9)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)10)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)11)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)12)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)15)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)16)',
  },
  ],
  'A)3)a)2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)4)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)5)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)5)b)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)a)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)7)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)8)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)9)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)10)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)11)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)12)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)15)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)16)',
  },
  ],
  'A)3)a)3/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)4)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)5)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)5)c)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)6)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)9)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)10)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)11)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)12',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)15)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)16)',
  },
  ],
  'A)3)a)4/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)5)d)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)4)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)5)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)6)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)7)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)8)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)11)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)12)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)15)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)16)',
  },
  ],
  'A)3)a)5/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)4)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)5)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)5)d)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)6)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)7)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)8)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)9)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)10)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)11)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)12)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)16)',
  },
  ],
  'A)3)a)6/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)4)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)5)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)5)d)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)6)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)7)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)8)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)9)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)10)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)11)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)15)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)16)',
  },
  ],
  'A)3)a)7/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)4)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)5)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)5)d)2.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)6)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)7)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)8)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)9)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)10)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)11)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)12)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)15)',
  },
  ],
  'A)3)a)8/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)4)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)5)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)6)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)7)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)8)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)9)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)10)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)11)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)12)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)16)',
  },
  ],
  'A)3)b)1.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)4)',
  },
  ],
  'A)3)b)1.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)4)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)p)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)i)',
  },
  ],
  'A)3)b)10.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)a)6.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)7)b)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)9)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)13)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)14)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)28.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)29.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)30.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)31.',
  },
  ],
  'A)3)b)10.2/': [{
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)12.2/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)12.3/',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)9)',
  },
  ],
  'A)3)b)11.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)a)6.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)8.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)7)b)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)9)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)13)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)14)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)28.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)29.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)30.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)31.',
  },
  ],
  'A)3)b)11.2/': [{
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)13.2/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)13.3/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)13.4/',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)9)',
  },
  ],
  'A)3)b)12.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)a)6.1/',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)9)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)13)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)14)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)28.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)29.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)30.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)31.',
  },
  ],
  'A)3)b)12.2/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)a)6.2/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)a)6.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)a)1.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)9)',
  },
  ],
  'A)3)b)13.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)9)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)13)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)14)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)30.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)31.',
  },
  ],
  'A)3)b)13.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)9)',
  },
  ],
  'A)3)b)14.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)9)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)13)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)14)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)31.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)54.',
  },
  ],
  'A)3)b)14.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)9)',
  },
  ],
  'A)3)b)15.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)2)a)9.1/',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)9)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)13)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)31.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)14)',
  },
  ],
  'A)3)b)15.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)9)',
  },
  ],
  'A)3)b)16.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)9)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)13)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)14)',
  },
  ],
  'A)3)b)16.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)9)',
  },
  ],
  'A)3)b)17.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)2)a)9.1/',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)9)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)13)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)14)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)b)1.d)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)b)2.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)31.',
  },
  ],
  'A)3)b)17.2/': [{
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)14)b)1.',
  },
  ],
  'A)3)b)18.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)2)a)9.1/',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)9)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)13)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)14)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)b)1.d)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)b)2.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)31.',
  },
  ],
  'A)3)b)18.2/': [{
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)14)b)4.',
  },
  ],
  'A)3)b)19.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)2)a)9.1/',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)9)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)13)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)14)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)b)1.d)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)b)2.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)29.',
  },
  ],
  'A)3)b)19.2/': [{
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)14)b)6.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)14)b)5.',
  },
  ],
  'A)3)b)2.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)4)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)2)n)',
  },
  ],
  'A)3)b)2.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)4)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)n)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)g)',
  },
  ],
  'A)3)b)20.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)9)f)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)11)b)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)11)d)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)12)A)c)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)12)A)d)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)a)5.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)b)2.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)c)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)f)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)e)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)f)12.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)f)19.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)f)17.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)f)8.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)f)22.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)4)g)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)d)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)40.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)47.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)22.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)23.',
  },
  ],
  'A)3)b)20.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'C)III)2)f)12.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'C)III)2)f)19.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'C)III)2)f)17.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'C)III)2)f)8.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'C)III)2)f)22.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'C)III)4)g)',
  },
  ],
  'A)3)b)21.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)13)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)14)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)31.',
  },
  ],
  'A)3)b)22.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)a)8.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)7)a)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)a)5.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)c)4.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)f)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)e)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)20.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)47.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)48.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)49.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)50.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)51.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)13)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)14)',
  },
  ],
  'A)3)b)23.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)a)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)20.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)25.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)48.',
  },
  ],
  'A)3)b)24.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)a)7.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)a)8.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)4.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)a)4.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)a)9.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)7)a)',
  },
  ],
  'A)3)b)25.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)c)7.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)1.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)16.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)26.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)31.',
  },
  ],
  'A)3)b)26.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)13)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)14)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)b)1.c)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)f)5.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)f)14.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)f)16.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)f)28.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)f)30.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)f)31.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)f)17.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)f)19.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)f)20.',
  },
  ],
  'A)3)b)27.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)f)12.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)f)15.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)f)19.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)f)24.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)11)b)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)11)d)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)13)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)14)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)a)5.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)28.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)30.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)33.',
  },
  ],
  'A)3)b)28.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)f)19.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)f)12.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)a)5.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)13)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)14)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)27.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)30.',
  },
  ],
  'A)3)b)29.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)f)23.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)4)d)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)1)a)1.c)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)9)f)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)13)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)14)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)b)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)e)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)32.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)34.',
  },
  ],
  'A)3)b)3.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)5',
  },
  {
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)b)8.',
  },
  ],
  'A)3)b)3.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)4)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)5)',
  },
  ],
  'A)3)b)30.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)f)8.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)f)15.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)4)d)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)4)g)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)9)f)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)9)d)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)a)5.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)27.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)28.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)e)8.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)13)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)14)',
  },
  ],
  'A)3)b)31.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)f)5.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)f)14.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)f)16.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)f)28.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)a)5.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)a)16.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)17.',
  },
  ],
  'A)3)b)32.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)11)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)2)a)18.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)1)a)',
  },
  ],
  'A)3)b)32.2/': [{
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)18.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)11.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)11)c)1/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)11)c)2/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)16)d)16.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)16)d)8.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)11)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)16)d)',
  },
  ],
  'A)3)b)33.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)11)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)1)a)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)1)b)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)1)c)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)34.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)35.',
  },
  ],
  'A)3)b)33.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)11)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)16)d)',
  },
  ],
  'A)3)b)34.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)11)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)2)a)18.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)1)a)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)35.',
  },
  ],
  'A)3)b)34.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)11)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)16)d)',
  },
  ],
  'A)3)b)35.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)16)d)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)11)',
  },
  ],
  'A)3)b)35.2/': [{
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)16)d)',
  },
  ],
  'A)3)b)36.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)12)A)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)13)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)38.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)39.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)40.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)41.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)43.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)44.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)45.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)46.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)7)e)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)7)g)',
  },
  ],
  'A)3)b)36.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)12)A)',
  },
  ],
  'A)3)b)37.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)12)A)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)13)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)38.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)39.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)40.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)41.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)43.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)44.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)45.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)46.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)7)e)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)7)g)',
  },
  ],
  'A)3)b)37.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)12)A)',
  },
  ],
  'A)3)b)38.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)12)A)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)13)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)39.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)40.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)41.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)43.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)44.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)45.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)46.',
  },
  ],
  'A)3)b)38.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)12)A)',
  },
  ],
  'A)3)b)39.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)41.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)53.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)54.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)12)A)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)13)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)b)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)c)',
  },
  ],
  'A)3)b)39.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)12)A)',
  },
  ],
  'A)3)b)4.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)10)',
  },
  {
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)b)2.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)49.',
  },
  ],
  'A)3)b)4.2/': [{
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)10)',
  },
  ],
  'A)3)b)40.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)41.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)53.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)12)A)',
  },
  ],
  'A)3)b)40.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)12)A)',
  },
  ],
  'A)3)b)41.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)43.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)44.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)45.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)46.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)53.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)12)A)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)13)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)7)e)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)7)g)',
  },
  ],
  'A)3)b)41.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)12)A)',
  },
  ],
  'A)3)b)42.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)43.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)44.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)45.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)46.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)12)B)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)13)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)6)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)7)c)',
  },
  ],
  'A)3)b)42.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)12)B)',
  },
  ],
  'A)3)b)43.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)2)a)10.1/',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)45.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)46.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)47.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)53.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)54.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)12)A)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)12)B)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)13)c)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)4)',
  },
  ],
  'A)3)b)43.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)12)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)13)c)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)13)a)',
  },
  ],
  'A)3)b)44.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)2)a)10.1/',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)47.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)12)A)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)12)B)g)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)12)B)h)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)13)c)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)4)',
  },
  ],
  'A)3)b)44.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)12)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)13)',
  },
  ],
  'A)3)b)45.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)2)a)10.1/',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)53.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)12)A)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)12)B)g)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)12)B)h)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)13)b)3.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)4)',
  },
  ],
  'A)3)b)45.2/': [{
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)13)b)3.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)13)b)1.',
  },
  ],
  'A)3)b)46.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)2)a)10.1/',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)41.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)42.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)12)A)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)12)B)g)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)12)B)h)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)13)b)4.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)4)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)30.',
  },
  ],
  'A)3)b)46.2/': [{
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)13)b)4.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)13)b)2.',
  },
  ],
  'A)3)b)47.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)48.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)54.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)13)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)14)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)a)',
  },
  ],
  'A)3)b)47.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)15)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)2)a)17.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)2)a)3.',
  },
  ],
  'A)3)b)48.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)2)a)3.3/',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)49.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)50.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)54.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)',
  },
  ],
  'A)3)b)48.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)15)',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)2)a)17.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)2)a)3.',
  },
  ],
  'A)3)b)49.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)54.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)a)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)c)',
  },
  {
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)b)2.',
  },
  ],
  'A)3)b)49.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)2)a)17.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)2)a)3.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)15)c)',
  },
  ],
  'A)3)b)5.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)g)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)c)8.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)c)9.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)c)10.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)c)11.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)c)12.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)c)6.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)c)7.a)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)c)7.b)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)c)7.c)',
  },
  ],
  'A)3)b)5.2/': [{
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)g)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)b)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)c)8.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)c)1.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)c)9.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)c)2.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)c)10.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)c)3.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)c)11.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)c)4.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)c)12.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)c)5.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)c)6.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)c)7.a)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)c)7.b)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)c)7.c)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)j)3/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)4)a)7/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)4)a)8/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)4)a)9/',
  },
  ],
  'A)3)b)50.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)26.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)51.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)54.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)a)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)b)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)f)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)e)',
  },
  ],
  'A)3)b)50.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)2)a)17.',
  },
  {
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)2)a)3.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)15)b)',
  },
  ],
  'A)3)b)51.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)53.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)54.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)16)b)',
  },
  ],
  'A)3)b)51.2/': [{
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)16)b)',
  },
  ],
  'A)3)b)52.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)16)c)',
  },
  ],
  'A)3)b)52.2/': [{
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)16)c)',
  },
  ],
  'A)3)b)53.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)54.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)e)9.',
  },
  ],
  'A)3)b)54.2/': [{
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)7.',
  },
  ],
  'A)3)b)6.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)h)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)e)8.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)e)3.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)e)4.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)e)9.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)e)6.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)e)10.',
  },
  ],
  'A)3)b)6.2/': [{
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)h)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)d)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)e)8.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)e)1.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)e)3.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)e)4.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)e)9.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)e)5.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)e)6.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)e)10.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)e)7.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)j)2/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)4)a)10/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)4)a)11/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)4)a)12/',
  },
  ],
  'A)3)b)7.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)8)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)2)a)8.1/',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)2)m)',
  },
  ],
  'A)3)b)7.2/': [{
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)8)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)a)8.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)m)',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)f)',
  },
  ],
  'A)3)b)8.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)1.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)12.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)13.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)14.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)15.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)7.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)8.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)16.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)17.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)18.',
  },
  ],
  'A)3)b)8.2/': [{
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)f)1.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)f)12.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)f)2.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)f)13.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)f)4.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)f)14.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)f)5.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)f)15.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)f)6.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)f)7.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)f)8.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)f)16.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)f)9.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)f)17.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)f)10.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)f)18.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)f)11.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)2)j)1/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)4)a)13/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)4)a)14/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)4)a)15/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)4)a)16/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)4)a)17/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)4)a)18/',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)4)a)19/',
  },
  ],
  'A)3)b)9.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)12.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)7)b)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)9)f)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)9)c)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)31.',
  },
  ],
  'A)3)b)9.2/': [{
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)f)12.',
  },
  {
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)6)f)2.',
  },
  ],
  'A)3)c)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)3)b)',
  },
  ],
  'A)4)a)11/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)h)',
  },
  ],
  'A)4)a)12/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)e)9.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)e)6.',
  },
  ],
  'A)4)a)13/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)12.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)13.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)14.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)15.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)17.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)18.',
  },
  ],
  'A)4)a)15/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)13.',
  },
  ],
  'A)4)a)16/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)18.',
  },
  ],
  'A)4)a)17/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)15.',
  },
  ],
  'A)4)a)18/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)a)',
  },
  ],
  'A)4)a)19/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)17.',
  },
  ],
  'A)4)a)31/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)12)A)c)',
  },
  ],
  'A)4)a)32/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)12)A)c)',
  },
  ],
  'A)4)a)33/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)12)A)d)',
  },
  ],
  'A)4)a)34/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)11)a)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)11)b)',
  },
  ],
  'A)4)a)35/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)16)d)',
  },
  ],
  'A)4)a)36/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)12)B)h)',
  },
  ],
  'A)4)a)37/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)12)B)e)',
  },
  ],
  'A)4)a)43/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)15)b)',
  },
  ],
  'A)4)a)7/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)c)9.',
  },
  ],
  'A)4)a)8/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)c)10.',
  },
  ],
  'A)4)a)9/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)g)',
  },
  ],
  'A)4)c)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)b)6.',
  },
  ],
  'A)4)c)10/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)b)5.',
  },
  ],
  'A)4)c)19/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)f)12.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)f)14.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)f)26.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)g)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)b)7.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)4)c)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)4)e)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)5)a)1.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)5)b)1.',
  },
  ],
  'A)4)c)2/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)b)6.',
  },
  ],
  'A)4)c)20/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)b)5.',
  },
  ],
  'A)4)c)21/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)b)5.',
  },
  ],
  'A)4)c)22/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)b)5.',
  },
  ],
  'A)4)c)23/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)b)5.',
  },
  ],
  'A)4)c)25/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)b)1.c)',
  },
  ],
  'A)4)c)27/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)g)',
  },
  ],
  'A)4)c)29/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)b)5.',
  },
  ],
  'A)4)c)3/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)b)6.',
  },
  ],
  'A)4)c)30/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)b)5.',
  },
  ],
  'A)4)c)31/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)b)5.',
  },
  ],
  'A)4)c)32/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)b)5.',
  },
  ],
  'A)4)c)33/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)b)5.',
  },
  ],
  'A)4)c)34/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)b)5.',
  },
  ],
  'A)4)c)35/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)b)5.',
  },
  ],
  'A)4)c)36/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)b)5.',
  },
  ],
  'A)4)c)38/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)b)5.',
  },
  ],
  'A)4)c)39/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)b)5.',
  },
  ],
  'A)4)c)4/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)b)5.',
  },
  ],
  'A)4)c)40/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)b)5.',
  },
  ],
  'A)4)c)41/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)g)',
  },
  ],
  'A)4)c)46/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)b)5.',
  },
  ],
  'A)4)c)5/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)b)1.a)',
  },
  ],
  'A)4)c)9/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)b)5.',
  },
  ],
  'A)4)i)3/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)b)6.',
  },
  ],
  'A)4)k)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)4)i)',
  },
  ],
  'A)4)k)2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)4)i)',
  },
  ],
  'A)5)a)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)g)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)a)6.',
  },
  ],
  'A)5)b)1.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)g)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)a)6.',
  },
  ],
  'A)5)b)2.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)g)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)a)6.',
  },
  ],
  'A)5)b)3.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)g)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)a)6.',
  },
  ],
  'A)5)b)4.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)5)b)1.',
  },
  ],
  'A)5)b)4.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)5)b)1.',
  },
  ],
  'A)5)b)5.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)5)b)2.',
  },
  ],
  'A)5)b)5.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)5)b)2.',
  },
  ],
  'A)5)b)6.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)5)b)3.',
  },
  ],
  'A)5)b)6.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)5)b)3.',
  },
  ],
  'A)5)c)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)g)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)a)6.',
  },
  ],
  'A)5)d)1.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)g)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)a)6.',
  },
  ],
  'A)5)d)2.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)g)',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)a)6.',
  },
  ],
  'A)5)d)3.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)5)d)1.',
  },
  ],
  'A)5)d)3.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)5)d)1.',
  },
  ],
  'A)5)d)4.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)5)d)2.',
  },
  ],
  'A)5)d)4.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)5)d)2.',
  },
  ],
  'A)5)e)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)5)c)',
  },
  ],
  'A)5)e)2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)5)c)',
  },
  ],
  'A)6)a)6.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)a)1.',
  },
  ],
  'A)6)a)6.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)6)a)1.',
  },
  ],
  'A)6)a)7.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)a)2.',
  },
  ],
  'A)6)a)7.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)6)a)2.',
  },
  ],
  'A)6)a)8.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)a)3.',
  },
  ],
  'A)6)a)8.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)6)a)3.',
  },
  ],
  'A)6)a)9.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)a)5.',
  },
  ],
  'A)6)a)9.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)6)a)5.',
  },
  ],
  'A)6)c)1.5/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)a)4.',
  },
  ],
  'A)6)c)10.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)c)3.',
  },
  ],
  'A)6)c)10.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)6)c)3.',
  },
  ],
  'A)6)c)11.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)c)4.',
  },
  ],
  'A)6)c)11.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)6)c)4.',
  },
  ],
  'A)6)c)12.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)c)5.',
  },
  ],
  'A)6)c)12.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)6)c)5.',
  },
  ],
  'A)6)c)8.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)c)1.',
  },
  ],
  'A)6)c)8.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)6)c)1.',
  },
  ],
  'A)6)c)9.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)c)2.',
  },
  ],
  'A)6)c)9.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)6)c)2.',
  },
  ],
  'A)6)e)10.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)e)7.',
  },
  ],
  'A)6)e)10.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)6)e)7.',
  },
  ],
  'A)6)e)8.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)e)1.',
  },
  ],
  'A)6)e)8.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)6)e)1.',
  },
  ],
  'A)6)e)9.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)e)5.',
  },
  ],
  'A)6)e)9.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)6)e)5.',
  },
  ],
  'A)6)f)10.2/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)b)6.',
  },
  ],
  'A)6)f)12.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)2.',
  },
  ],
  'A)6)f)12.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)6)f)2.',
  },
  ],
  'A)6)f)13.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)4.',
  },
  ],
  'A)6)f)13.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)6)f)4.',
  },
  ],
  'A)6)f)14.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)5.',
  },
  ],
  'A)6)f)14.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)6)f)5.',
  },
  ],
  'A)6)f)15.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)6.',
  },
  ],
  'A)6)f)15.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)6)f)6.',
  },
  ],
  'A)6)f)16.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)9.',
  },
  ],
  'A)6)f)16.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)6)f)9.',
  },
  ],
  'A)6)f)17.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)10.',
  },
  ],
  'A)6)f)17.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)6)f)10.',
  },
  ],
  'A)6)f)18.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)f)11.',
  },
  ],
  'A)6)f)18.2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)6)f)11.',
  },
  ],
  'A)6)f)7.1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)2)f)8.',
  },
  {
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)4)g)',
  },
  ],
  'A)6)g)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)b)',
  },
  ],
  'A)6)g)2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)6)b)',
  },
  ],
  'A)6)h)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)6)d)',
  },
  ],
  'A)6)h)2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)6)d)',
  },
  ],
  'A)7)c)2/': [{
    veto: true, poser: false, imposer: false, interdire: false, cible: 'A)7)d)',
  },
  ],
  'A)8)g)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)8)e)',
  },
  ],
  'A)8)g)2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)8)e)',
  },
  ],
  'A)9)a)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'C)III)3)b)6.',
  },
  ],
  'A)9)f)1/': [{
    veto: false, poser: false, imposer: true, interdire: false, cible: 'A)9)a)',
  },
  ],
  'A)9)f)2/': [{
    veto: false, poser: false, imposer: false, interdire: true, cible: 'A)9)a)',
  },
  ],
  'C)I)1/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)b)1.a)',
  },
  ],
  'C)I)10/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)a)4.',
  },
  ],
  'C)I)11/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)a)5.',
  },
  ],
  'C)I)12/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)a)6.',
  },
  ],
  'C)I)13/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)a)7.',
  },
  ],
  'C)I)14/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)b)1.c)',
  },
  ],
  'C)I)15/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)II)3)',
  },
  ],
  'C)I)16/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)a)8.',
  },
  ],
  'C)I)17/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)b)2.',
  },
  ],
  'C)I)17bis/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)b)2.',
  },
  ],
  'C)I)18/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)7)c)',
  },
  ],
  'C)I)19/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)7)d)',
  },
  ],
  'C)I)2/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)c)',
  },
  ],
  'C)I)20/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)a)9.',
  },
  ],
  'C)I)21/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)7)e)',
  },
  ],
  'C)I)22/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)7)g)',
  },
  ],
  'C)I)23/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)7)f)',
  },
  ],
  'C)I)24/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)b)3.',
  },
  ],
  'C)I)25/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)b)4.',
  },
  ],
  'C)I)26/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)a)10.',
  },
  ],
  'C)I)27/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)a)11.',
  },
  ],
  'C)I)28/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)a)12.',
  },
  ],
  'C)I)29/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)b)1.b)',
  },
  ],
  'C)I)2bis/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)c)',
  },
  ],
  'C)I)2ter/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)c)',
  },
  ],
  'C)I)3/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)II)1)',
  },
  ],
  'C)I)30/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)b)5.',
  },
  ],
  'C)I)31/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)a)13.',
  },
  ],
  'C)I)32/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)a)14.',
  },
  ],
  'C)I)33/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)a)15.',
  },
  ],
  'C)I)34/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)a)16.',
  },
  ],
  'C)I)35/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)a)17.',
  },
  ],
  'C)I)36/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)II)4)',
  },
  ],
  'C)I)36bis/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)II)4)',
  },
  ],
  'C)I)36quater/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)II)4)',
  },
  ],
  'C)I)36ter/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)II)4)',
  },
  ],
  'C)I)37/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)II)5)',
  },
  ],
  'C)I)38/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)a)18.',
  },
  ],
  'C)I)39/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)b)6.',
  },
  ],
  'C)I)4/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)II)2)',
  },
  ],
  'C)I)40/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)II)6)',
  },
  ],
  'C)I)41/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)a)19.',
  },
  ],
  'C)I)42/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)b)6.',
  },
  ],
  'C)I)44/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)a)12.',
  },
  {
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)b)4.',
  },
  ],
  'C)I)47/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)b)8.',
  },
  ],
  'C)I)5/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)a)1.',
  },
  ],
  'C)I)6/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)a)2.',
  },
  ],
  'C)I)7/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)7)a)',
  },
  ],
  'C)I)8/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)3)a)3.',
  },
  ],
  'C)II)1)1/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)5)a)1.',
  },
  ],
  'C)II)1)2/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)5)a)2.',
  },
  ],
  'C)II)1)3/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)5)a)3.',
  },
  ],
  'C)II)2)1/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)5)b)1.',
  },
  ],
  'C)II)2)2/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)5)b)2.',
  },
  ],
  'C)II)2)3/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)5)b)3.',
  },
  ],
  'C)II)3)1/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)1)a)1.',
  },
  ],
  'C)II)3)2/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)1)a)2.',
  },
  ],
  'C)II)3)3/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)1)b)',
  },
  ],
  'C)II)3)6/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)1)e)1.',
  },
  ],
  'C)II)3)7/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)1)e)2.',
  },
  ],
  'C)II)3)8/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)1)e)3.',
  },
  ],
  'C)II)3)9/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)1)f)1.',
  },
  ],
  'C)II)3)9bis/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)1)f)2.',
  },
  ],
  'C)II)3)9quater': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)1)f)3.',
  },
  ],
  'C)II)3)9ter/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)1)f)2.',
  },
  ],
  'C)II)4)1/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)a)1.',
  },
  ],
  'C)II)4)13/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)f)5.',
  },
  ],
  'C)II)4)14/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)f)6.',
  },
  ],
  'C)II)4)14bis/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)f)32.',
  },
  ],
  'C)II)4)14ter/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)f)33.',
  },
  ],
  'C)II)4)15/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)f)7.',
  },
  ],
  'C)II)4)16/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)f)8.',
  },
  ],
  'C)II)4)17/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)f)9.',
  },
  ],
  'C)II)4)19/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)f)11.',
  },
  ],
  'C)II)4)2/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)a)2.',
  },
  ],
  'C)II)4)20/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)f)12.',
  },
  ],
  'C)II)4)20bis/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)f)12.',
  },
  ],
  'C)II)4)21/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)f)13.',
  },
  ],
  'C)II)4)22/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)f)14.',
  },
  ],
  'C)II)4)23/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)f)15.',
  },
  ],
  'C)II)4)24/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)f)16.',
  },
  ],
  'C)II)4)25/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)f)17.',
  },
  ],
  'C)II)4)26/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)f)18.',
  },
  ],
  'C)II)4)27/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)f)19.',
  },
  ],
  'C)II)4)28/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)f)20.',
  },
  ],
  'C)II)4)3/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)b)',
  },
  ],
  'C)II)4)30/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)f)22.',
  },
  ],
  'C)II)4)31/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)f)23.',
  },
  ],
  'C)II)4)32/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)f)24.',
  },
  ],
  'C)II)4)33/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)f)25.',
  },
  ],
  'C)II)4)34/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)f)26.',
  },
  ],
  'C)II)4)36/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)f)28.',
  },
  ],
  'C)II)4)37/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)f)29.',
  },
  ],
  'C)II)4)37bis/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)f)34.',
  },
  ],
  'C)II)4)38/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)f)30.',
  },
  ],
  'C)II)4)39/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)f)31.',
  },
  ],
  'C)II)4)4/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)a)2.',
  },
  {
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)c)',
  },
  {
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)d)',
  },
  {
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)e)',
  },
  ],
  'C)II)4)40/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)g)',
  },
  ],
  'C)II)4)41/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)f)5bis.',
  },
  ],
  'C)II)4)4bis/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)a)2.',
  },
  {
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)c)',
  },
  {
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)d)',
  },
  {
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)e)',
  },
  ],
  'C)II)4)4ter/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)a)2.',
  },
  {
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)c)',
  },
  {
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)d)',
  },
  {
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)e)',
  },
  ],
  'C)II)4)5/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)d)',
  },
  ],
  'C)II)4)6/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)e)',
  },
  ],
  'C)II)4)6bis/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)2)c)',
  },
  ],
  'C)II)5)1/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)6)a)',
  },
  ],
  'C)II)5)2/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)6)b)',
  },
  ],
  'C)II)5)3/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)6)c)',
  },
  ],
  'C)II)6)1/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)4)a)',
  },
  ],
  'C)II)6)11/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)4)j)2.',
  },
  ],
  'C)II)6)12/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)4)j)',
  },
  ],
  'C)II)6)2/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)4)b)',
  },
  ],
  'C)II)6)3/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)4)c)',
  },
  ],
  'C)II)6)4/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)4)d)',
  },
  ],
  'C)II)6)5/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)4)e)',
  },
  ],
  'C)II)6)6/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)4)f)',
  },
  ],
  'C)II)6)7/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)4)g)',
  },
  ],
  'C)II)6)8/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)4)h)',
  },
  ],
  'C)II)6)9/': [{
    veto: false, poser: true, imposer: false, interdire: false, cible: 'C)III)4)i)',
  }],
};
