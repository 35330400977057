import { Text, useTheme } from '@ui-kitten/components';
import React, { useState } from 'react';
import { useRoute } from '@react-navigation/native';
import {
  View,
} from 'react-native';
import { useForm } from 'react-hook-form';
import MaxWidthContainer from '../../../components/MaxWidthContainer';
import Form from '../../../components/Form/Form';
import FTextInput from '../../../components/Form/TextInput';
import { AvailableValidationRules } from '../../../components/Form/validation';
import Button from '../../../components/Button';
import { useGetProtocolSoin, useUpdateProtocolSoinMutation } from '../../../src/API/ProtocolSoin';

type ChangeProtocolSoin = {
  name: string,
  Protocol: string,
};

const AdminDetailsProtocolSoinScreen = () => {
  const route = useRoute();
  const theme = useTheme();

  const { ps, refetch } = useGetProtocolSoin(route.params.id);

  const { modifyProtocolSoin } = useUpdateProtocolSoinMutation();

  const [processing, setProcessing] = useState(false);
  const changeProtocolSoin = useForm<ChangeProtocolSoin>();

  return (
    <MaxWidthContainer
      withScrollView="keyboardAware"
      outerViewProps={{
        style: {
          padding: 20,
        },
      }}
    >
      {ps ? (
        <>
          <Text category="h4" style={{ marginTop: 40 }}>Protocole de soin</Text>
          <Form<ChangeProtocolSoin>
            {...changeProtocolSoin}
            defaultValues={ps}
          >
            <>
              <View>
                <Text category="h6" appearance="hint">Nom *</Text>
                <FTextInput
                  name="name"
                  placeholder="Saisissez le nom"
                  validators={[
                    AvailableValidationRules.required,
                  ]}
                />
                <Text category="h6" appearance="hint">Protocole *</Text>
                <FTextInput
                  name="Protocol"
                  placeholder="Saisissez le code d'identification"
                  multiline
                  validators={[
                    AvailableValidationRules.required,
                  ]}
                  textStyle={{ minHeight: 400 }}
                  containerStyle={{
                    flexShrink: 1,
                    flexBasis: 'auto',
                    flexGrow: 0,
                  }}
                />
              </View>
            </>
          </Form>

          <Button
            loading={processing}
            onPress={async () => {
              await changeProtocolSoin.handleSubmit(async (data) => {
                setProcessing(true);

                await modifyProtocolSoin({
                  variables: {
                    input: {
                      id: ps.id,
                      name: data.name,
                      Protocol: data.Protocol,
                      _version: ps._version,
                    },
                  },
                });

                await refetch();

                setProcessing(false);
              })();
            }}
            leftIcon="edit-outline"
            leftIconFill={theme['color-primary-500']}
            appearance="outline"
            style={{ marginTop: 10 }}
          >
            Enregistrer
          </Button>
        </>
      ) : null}
    </MaxWidthContainer>
  );
};

export default AdminDetailsProtocolSoinScreen;
