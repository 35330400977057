import { disease } from '../../src/API';

const findDisease = (questionReponse : {
  [key : string] : string[]
}, diseases: disease[]) => {
  const responses = Object.values(questionReponse);
  const diseasPossible:disease[] = [];
  diseases?.forEach((currentDisease) => {
    /* if (currentDisease.codeIdentification === 'bddb8ac8-01ed-4ecf-8fbd-465d7cf686ad') {
      diseasPossible.push(currentDisease);
      diseasPossible = JSON.parse(JSON.stringify(diseasPossible));
    } */
    if (currentDisease && currentDisease.ponderationDisease) {
      const ponderation = JSON.parse(currentDisease.ponderationDisease);
      diseasPossible.push(currentDisease);
      const ponderationKeys = Object.keys(ponderation);
      currentDisease.ponderationCount = 0;
      for (let i = 0; i < ponderationKeys.length; i++) {
        const ponderationKey = ponderationKeys[i];
        if (responses.find(((value) => value.find((value1) => value1 === ponderationKey)))) {
          // console.log(ponderation[ponderationKey]);
          // console.log(currentDisease.name);
          if (ponderation[ponderationKey] && ponderation[ponderationKey] !== 'O') {
            currentDisease.ponderationCount += parseInt(ponderation[ponderationKey], 10);
          } else if (ponderation[ponderationKey] && ponderation[ponderationKey] === 'O') {
            currentDisease.ponderationCount += 20;
          }
        } else if (ponderation[ponderationKey] === 'O') {
          diseasPossible.pop();
          break;
        }
      }
    }
  });
  return diseasPossible;
};

const verifyDiseas = (questionReponse : {
  [key : string] : string[]
}, Disease: disease) => {
  const responses = Object.values(questionReponse);
  // console.log(responses);
  let prob = 'peu élevée';
  let isOver = false;
  if (Disease && Disease.ponderationDisease) {
    const ponderation = JSON.parse(Disease.ponderationDisease);
    const ponderationKeys = Object.keys(ponderation).filter((key) => key.startsWith('C)'));
    ponderationKeys.map((ponderationKey) => {
      if (responses.find(((value) => value.find((value1) => value1 === ponderationKey))) && !isOver) {
        // console.log(ponderation[ponderationKey]);
        // console.log(currentDisease.name);
        if (ponderation[ponderationKey] === '500') {
          prob = 'trés élevé';
          isOver = true;
        } else {
          prob = 'élevé';
        }
      }
    });
  }

  return prob;
};

export { findDisease, verifyDiseas };
