const scanflockTheme = {
  'color-primary-100': '#D9FAEE',
  'color-primary-200': '#B5F6E3',
  'color-primary-300': '#8AE5D2',
  'color-primary-400': '#66CBBF',
  'color-primary-500': '#39A9A6',
  'color-primary-600': '#298A91',
  'color-primary-700': '#1C6B79',
  'color-primary-800': '#124F62',
  'color-primary-900': '#0A3A51',
  'color-success-100': '#ECFBD6',
  'color-success-200': '#D5F8AE',
  'color-success-300': '#B2EA81',
  'color-success-400': '#8ED55E',
  'color-success-500': '#5EBA30',
  'color-success-600': '#449F23',
  'color-success-700': '#2E8518',
  'color-success-800': '#1C6B0F',
  'color-success-900': '#0F5909',
  'color-info-100': '#DDEDFB',
  'color-info-200': '#BDDAF8',
  'color-info-300': '#97BDEA',
  'color-info-400': '#769ED5',
  'color-info-500': '#4c76ba',
  'color-info-600': '#375B9F',
  'color-info-700': '#264385',
  'color-info-800': '#182E6B',
  'color-info-900': '#0E1F59',
  'color-warning-100': '#FFF3CC',
  'color-warning-200': '#FFE399',
  'color-warning-300': '#FFD066',
  'color-warning-400': '#FFBC3F',
  'color-warning-500': '#FF9D00',
  'color-warning-600': '#DB7E00',
  'color-warning-700': '#B76200',
  'color-warning-800': '#934900',
  'color-warning-900': '#7A3800',
  'color-danger-100': '#FFECDF',
  'color-danger-200': '#FFD4BF',
  'color-danger-300': '#FFB79F',
  'color-danger-400': '#FF9B87',
  'color-danger-500': '#FF6E60',
  'color-danger-600': '#DB4746',
  'color-danger-700': '#B7303A',
  'color-danger-800': '#931E31',
  'color-danger-900': '#7A122B',

  'light-grey': '#6E6E6E',
  'xlight-grey': '#d4d4d4',
  'xtralight-grey': '#E6E6E6',
  'background-color': '#FAFCFD',

  'text-primary-focused-color': 'color-primary-500',
  'realised-color': '#349B2E',
};

export default scanflockTheme;
