import { useMutation, useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { DocumentNode } from 'apollo-link';
import { WatchQueryFetchPolicy } from 'apollo-client/core/watchQueryOptions';
import { useEffect } from 'react';
import { createBSE, updateBSE } from '../graphql/mutations';
import {
  Breeding,
  BSE,
  CreateBSEMutation,
  CreateBSEMutationVariables,
  GetBSEQuery,
  GetBSEQueryVariables,
  ListBseByBreedingQuery,
  ListBseByBreedingQueryVariables,
  ListBSESQuery,
  ListBSESQueryVariables,
  ModelSortDirection,
  OnAskBSEStartSubscriptionVariables,
  StatusBse,
  UpdateBSEMutation,
  UpdateBSEMutationVariables,
} from '../API';
import { getBSE, listBseByBreeding, listBSES } from '../graphql/queries';

export function useCreateBseMutation() {
  const [createBse] = useMutation<
  CreateBSEMutation, CreateBSEMutationVariables
  >(gql(createBSE));
  return { createBse };
}

export function useGetBse(id: string) {
  const bseQuery = <DocumentNode>gql(getBSE);

  if (!id) {
    return { loading: false, bse: undefined };
  }

  const { loading, data, subscribeToMore } = useQuery<
  GetBSEQuery, GetBSEQueryVariables
  >(bseQuery, {
    variables: {
      id,
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    let unsubscribeUpdate = () => {};
    if (subscribeToMore) {
      unsubscribeUpdate = subscribeToMore<
      OnAskBSEStartSubscription,
      OnAskBSEStartSubscriptionVariables
      >({
        document: gql(onAskBSEStart),
        variables: { id },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          const bse = subscriptionData.data.onAskBSEStart;

          if (bse) {
            if (prev.getBSE) {
              if (prev.getBSE.id === bse.id) {
                return prev;
              }
              return {
                getBSE: {
                  bse,
                },
              };
            }
            return {
              getBSE: {
                bse,
              },
            };
          }
          return prev;
        },
      });
    }
    return () => {
      unsubscribeUpdate();
    };
  }, [subscribeToMore]);

  return {
    loading, bse: <BSE>data?.getBSE,
  };
}

// not finished
export function useBseList() {
  const listBseQuery = <DocumentNode>gql(listBSES);

  const { loading, data } = useQuery<
  ListBSESQuery, ListBSESQueryVariables
  >(listBseQuery, {
    fetchPolicy: 'cache-and-network',
  });
  // console.log('listBseQuery', data);

  if (data) {
    return { loading, bseS: data?.listBSES };
  }
  return { loading, bseS: null };
}

export function useUpdateBseMutation() {
  const [updateBse, { loading: mutationBseLoading }] = useMutation<
  UpdateBSEMutation, UpdateBSEMutationVariables
  >(gql(updateBSE));
  return { updateBse, mutationBseLoading };
}

type OnAskBSEStartSubscription = {
  onAskBSEStart?: {
    __typename: 'BSE',
    id: string,
    breedingId: string,
    StatusBse?: StatusBse | null,
    dateBSE?: string | null,
    lasIdQuestion?: string | null,
    questionResponse?: string | null,
    questionResponseOrder?: Array< string | null > | null,
    maladieRecurente?: Array< string | null > | null,
    protocolesDeSoins?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};
const onAskBSEStart = /* GraphQL */ `
  subscription OnAskBSEStart($id: ID!) {
    onAskBSEStart(id: $id) {
      id
      breedingId
      StatusBse
      dateBSE
      questionResponse
      lasIdQuestion
      questionResponseOrder
      maladieRecurente
      protocolesDeSoins
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export function useBseListEleveur(getLast: boolean = false, getLastFinished: boolean = false, fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network') {
  const listBseQuery = <DocumentNode>gql(`
  query ListBseEleveur {
    listBreedings {
      items {
        bse(sortDirection: DESC, limit: 1000) {
          items {
            id
            breedingId
            StatusBse
            dateBSE
            questionResponse
            lasIdQuestion
            maladieRecurente
            protocolesDeSoins
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`);

  type ListBseByBreedingQuery = {
    listBreedings?: {
      __typename: '"ModelBreedingConnection"',
      items: Array< {
        __typename: 'Breeding',
        bse :{
          items: Array< {
            __typename: '"ModelBSEConnection"',
            id: string,
            breedingId: string,
            dateBSE?: string | null,
            lasIdQuestion?: string | null,
            StatusBse?: StatusBse | null,
            questionResponse?: string | null,
            maladieRecurente?: string[] | null,
            protocolesDeSoins?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >
          extToken?: string | null,
          startedAt?: number | null,
        }

      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  };

  const { loading, data, subscribeToMore } = useQuery<
  ListBseByBreedingQuery, ListBseByBreedingQueryVariables
  >(listBseQuery, { fetchPolicy });

  if (data) {
    if (getLastFinished) {
      return { loading, bse: data?.listBreedings?.items[0].bse.items.find((item) => item.StatusBse === StatusBse.BSEFinish) };
    } if (getLast) {
      return { loading, bse: data?.listBreedings?.items[0].bse.items[0] };
    }
    return { loading, bse: data?.listBreedings?.items[0].bse.items };
  }
  return { loading, bse: null };
}

export function useBseListByBreedingId(breedingId: string, getLast: boolean = false, getLastFinished: boolean = false, fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network') {
  const listBseQuery = <DocumentNode>gql(listBseByBreeding);

  const { loading, data, subscribeToMore } = useQuery<
  ListBseByBreedingQuery, ListBseByBreedingQueryVariables
  >(listBseQuery, {
    variables: {
      breedingId,
      sortDirection: ModelSortDirection.DESC,
    },
    fetchPolicy,
  });

  if (data) {
    if (getLastFinished) {
      return { loading, bse: data?.listBseByBreeding?.items.find((item) => item.StatusBse === StatusBse.BSEFinish) };
    }
    if (getLast) {
      return { loading, bse: data?.listBseByBreeding?.items.find((item) => item.StatusBse === StatusBse.BSEFinish) };
    }
    return { loading, bses: data?.listBseByBreeding?.items };
  }
  return { loading, bse: null, bses: null };
}
