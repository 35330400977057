import { useMutation, useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { DocumentNode } from 'apollo-link';
import { WatchQueryFetchPolicy } from 'apollo-client/core/watchQueryOptions';
import * as mutations from '../graphql/mutations';

import {
  CreateZonesMutation,
  CreateZonesMutationVariables, DeleteZonesMutation, DeleteZonesMutationVariables,
  GetZonesQuery,
  GetZonesQueryVariables,
  UpdateZonesMutation,
  UpdateZonesMutationVariables, Zones, ZonesByBuildingQuery, ZonesByBuildingQueryVariables,
} from '../API';
import { getZones, zonesByBuilding } from '../graphql/queries';

export function useCreateZonesMutation() {
  const [createZones] = useMutation<
  CreateZonesMutation, CreateZonesMutationVariables
  >(gql(mutations.createZones));
  return { createZones };
}

const getZoneQuery = <DocumentNode>gql(getZones);
export function useGetZone(id: string) {
  if (!id) {
    return { loading: false, zone: undefined };
  }
  const {
    loading, data, fetchMore, refetch,
  } = useQuery<GetZonesQuery, GetZonesQueryVariables>(getZoneQuery, {
    variables: {
      id,
    },
  });
  return {
    loading, zone: <Zones>data?.getZones, fetchMore, refetch,
  };
}

const listZonesByBuildingQuery = <DocumentNode>gql(zonesByBuilding);
export function useListZonesByBuilding(buildingId?: string | null, fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network') {
  const {
    loading, data, fetchMore, refetch,
  } = useQuery<
  ZonesByBuildingQuery, ZonesByBuildingQueryVariables
  >(listZonesByBuildingQuery, {
    fetchPolicy,
    variables: {
      buildingId,
    },
  });

  return {
    loading, dataZonesByBuilding: data?.zonesByBuilding?.items, fetchMore, refetch,
  };
}

export function useUpdateZoneMutation() {
  const [updateZone, { loading: mutationZoneLoading }] = useMutation<
  UpdateZonesMutation, UpdateZonesMutationVariables
  >(gql(mutations.updateZones));
  return { updateZone, mutationZoneLoading };
}

export function useDeleteZoneMutation() {
  const [deleteZone, { loading: deleteZoneLoading }] = useMutation<
  DeleteZonesMutation, DeleteZonesMutationVariables
  >(gql(mutations.deleteZones));
  return { deleteZone, deleteZoneLoading };
}
