import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';

import { useFocusEffect } from '@react-navigation/native';
import { VetoTabExamenParamList } from '../../types';

import VetoExamenScreen from '../../screens/VetScreens/Examen/VetoExamenScreen';
import VetoDetailsConsultationScreen from '../../screens/VetScreens/Examen/VetoDetailsConsultationScreen';
import VetoDetailsElevageScreen from '../../screens/VetScreens/VetoDetailsElevageScreen';
import MessagerieScreen from '../../screens/ConsultationScreen/MessagerieScreen';
import VetoDetailsTeleconsultationScreen from '../../screens/VetScreens/TeleconsultationScreen/VetoDetailsTeleconsultationScreen';
import QuestionnaireScreen from '../../screens/ConsultationScreen/QuestionnaireScreen';
import DocumentsConsultationsScreen
  from '../../screens/ConsultationScreen/DocumentsConsultationsScreen';

const Stack = createNativeStackNavigator<VetoTabExamenParamList>();

const VetoExamenStackNavigator = () => (
  <Stack.Navigator
    initialRouteName="veto-examen"
    screenOptions={{ headerShown: false }}
  >
    <Stack.Screen
      name="veto-examen"
      component={VetoExamenScreen}
    />
    <Stack.Screen
      name="veto-details-consultation"
      component={VetoDetailsConsultationScreen}
    />
    <Stack.Screen
      name="veto-details-teleconsultation"
      component={VetoDetailsTeleconsultationScreen}
    />
    <Stack.Screen
      name="veto-details-elevage"
      component={VetoDetailsElevageScreen}
    />
    <Stack.Screen
      name="veto-consultation-document"
      component={DocumentsConsultationsScreen}
    />
    <Stack.Screen
      name="veto-consultation-messagerie"
      component={MessagerieScreen}
    />
    <Stack.Screen
      name="veto-consultation-questionnaire"
      component={QuestionnaireScreen}
    />

  </Stack.Navigator>
);

export default VetoExamenStackNavigator;
