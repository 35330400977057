import { Text } from '@ui-kitten/components';
import React from 'react';
import { useLinkTo, useRoute } from '@react-navigation/native';
import { RouteProp } from '@react-navigation/core/lib/typescript/src/types';
import InfoCard from '../../screenComponents/InfoCard';
import MaxWidthContainer from '../../components/MaxWidthContainer';
import DetailsScreensHeaderCard from '../../screenComponents/DetailsScreensHeaderCard';
import SearchComponent from '../../screenComponents/SearchComponent';
import TeleconsultationCard from '../../components/TeleconsultationCard';
import GoCustomCard from '../../components/GoCustomCard';
import VetoElevageScreenBL from '../../screenBusinessLogic/vetoScreensBL/VetoElevageScreens/VetoElevageScreenBL';
import { useGetBreeding } from '../../src/API/Breeding';
import { VetoTabExamenParamList } from '../../types';
import { useConsultationListByBreedingId } from '../../src/API/Consultation';
import { BSE, Consultation, StatusBse } from '../../src/API';
import ActivityIndicator from '../../components/ActivityIndicator';
import ElevageScreenBL from '../../screenBusinessLogic/ElevageScreen/ElevageScreenBL';
import DateUtils from '../../utils/DateUtils';
import { useBseListByBreedingId } from '../../src/API/BSE';
import Tutorial from '../../components/Tutorial';

const VetoDetailsElevageScreen = () => {
  const linkTo = useLinkTo();
  const route = useRoute<RouteProp<VetoTabExamenParamList, 'veto-details-elevage'>>();

  const { oneBreeding } = useGetBreeding(route.params.id);
  // console.log('iiiiiiid: ', route.params.id);

  const { consultationS } = useConsultationListByBreedingId('cache-and-network', route.params.id);
  let consult = consultationS;

  const ede = oneBreeding.documents?.items?.find(
    // eslint-disable-next-line no-underscore-dangle
    (item) => (item && !item._deleted && item.key && item.key.includes('Document_EDE')),
  );

  const user = oneBreeding.users?.items[0];

  // console.log('consultations', consultationS);
  // console.log('oneBreeding', oneBreeding);

  if (consult) {
    consult = consult.filter((consultation) => consultation && consultation.shared);
  }

  const { bse: lastBse } = useBseListByBreedingId(oneBreeding?.id, true);

  const bse: BSE[] = [];
  oneBreeding?.bse?.items.map((item) => {
    if (item.StatusBse === StatusBse.BSEAsk
          || item.StatusBse === StatusBse.BSEStarted
          || item.StatusBse === StatusBse.BSEProtocolSoins
          || item.StatusBse === StatusBse.BSEAskStart) bse.push(item);
  });

  const bseEncours = DateUtils.verificationDateBSE('enCours', bse);
  const bseAVenirs = DateUtils.verificationDateBSE('aVenirs', bse);
  const bseAPlanifiers = DateUtils.verificationDateBSE('aPlanifiers', bse);

  return (
    <MaxWidthContainer
      withScrollView="keyboardAware"
      outerViewProps={{
        style: {
          padding: 20,
        },
      }}
    >

      <Tutorial
        videoId="CHkaxyT6AKI"
      />
      <Text category="h4">Informations de l'éleveur</Text>
      {oneBreeding
        ? (
          <DetailsScreensHeaderCard
            title1="Nom de l'éleveur"
            titleInfo1={`${user && user.firstname} ${user && user.lastname}`}
            title2="E-mail"
            titleInfo2={oneBreeding.emailEleveur}
            title3="N° de téléphone"
            titleInfo3={oneBreeding.phone}
          />
        )
        : (<ActivityIndicator center margin={10} />)}

      <Text category="h4" style={{ marginTop: 40 }}>Informations de l'élevage</Text>
      {oneBreeding
        ? (
          <DetailsScreensHeaderCard
            title1="Raison sociale"
            titleInfo1={oneBreeding.companyName}
            title2="Adresse"
            titleInfo2={oneBreeding.address?.address}
            title3="Complèment d'adresse"
            titleInfo3={oneBreeding.address?.additionalAddress}
            title4="Code Postal"
            titleInfo4={oneBreeding.address?.postalCode}
            title5="Ville"
            titleInfo5={oneBreeding.address?.city}
            title6={"Nom de l'éleveur"}
            titleInfo6={user && `${user.firstname} ${user.lastname}`}
            title7="Type de production"
            titleInfo7={ElevageScreenBL.getTypeProductionAsString(oneBreeding)}
            title8="Document EDE"
            titleInfo8={ede || 'Pas de document EDE ajouté'}
            title9={"Statut du Bilan Sanitaire d'Élevage"}
            titleInfo9={lastBse ? `Réalisé le ${DateUtils.convertDate(lastBse.dateBSE)}` : 'Non réalisé'}
          />
        )
        : (<ActivityIndicator center margin={10} />)}

      <Text category="h4" style={{ marginTop: 40 }}>Effectif de l'élevage</Text>
      {oneBreeding && (
      <DetailsScreensHeaderCard
        title1="Vaches"
        titleInfo1={oneBreeding.nbCow}
        title2="Génisses"
        titleInfo2={oneBreeding.nbHeifer}
        title3="Mâles"
        titleInfo3={oneBreeding.nbMale}
        title4="Moins de 12 mois"
        titleInfo4={oneBreeding.nbYoung}
        buttonText={"Accéder à l'effectif de l'élevage"}
        customLinkTo={() => {
          VetoElevageScreenBL.switchTo({ state: 'effectif elevage', linkTo, id: oneBreeding.id });
        }}
      />
      )}

      <InfoCard
        title="alertInfo"
        iconShow="alert-circle-outline"
        description={"Vous pouvez visualiser les derniers auto-examens effectués sur l'élevage et les dernières téléconsultations réalisées avec l'éleveur. "
            + 'Vous pouvez également réaliser et gérer le BSE pour cet élevage.'}
        alertInfo="alertInfo"
      />

      <Text category="h4" style={{ marginTop: 40 }}>Derniers auto-examens</Text>
      <SearchComponent<Consultation>
        state="consultation"
        // onChangeSearchText={() => {}}
        data={consult}
        opened
        searchButton
        keyExtractor={(item) => item.id}
        placeholder="Rechercher un auto-examen"
        renderItem={(item) => (
          <TeleconsultationCard
            detailConsultationPage={false}
            key={item.index}
            consultationData={item.item}
            back={`veto-elevage/veto-details-elevage/${oneBreeding?.id}`}
          />
        )}
      />

      <Text category="h4" style={{ marginTop: 40 }}>Bilan sanitaire d'élevage</Text>
      {bseEncours?.length || bseAVenirs?.length || bseAPlanifiers?.length ? (
        <>
          <InfoCard
            title="alertInfo"
            iconShow="alert-circle-outline"
                  // iconCloseShow="close-outline"
            description={"L'élevage vous a transmis une demande de Bilan sanitaire d'élevage. Vous pouvez confirmer et planifier le rendez-vous en accédant aux détails BSE."}
            alertInfo="alertInfo"
          />
          <TeleconsultationCard consultation="RDV" bseData={(bseEncours && bseEncours[0]) || (bseAVenirs && bseAVenirs[0]) || (bseAPlanifiers && bseAPlanifiers[0])} />
        </>
      ) : null}

      <Text style={{ marginTop: 10 }}>Consultez les derniers BSE réalisés sur l'application</Text>
      <GoCustomCard
        title1="Historique des Bilans Sanitaire d'Élevage"
        minusSquareOutline="arrow-ios-forward"
        linkToCustom={() => { VetoElevageScreenBL.goAjoutScreen({ state: 'historique-bse', linkTo, id: oneBreeding?.id }); }}
      />
    </MaxWidthContainer>
  );
};

export default VetoDetailsElevageScreen;
