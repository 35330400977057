import { DocumentNode } from 'apollo-link';
import gql from 'graphql-tag';
import { useMutation, useQuery } from 'react-apollo';
import { ApolloClient } from '@apollo/client';
import {
  getProtocolSoin,
  listProtocolSoins,
  protocolSoinByIdDiseaseFamily,
  protocolSoinSaveByBseId,
  protocolSoinSaveByIdBreedingId,
} from '../graphql/queries';
import {
  CreateProtocolSoinSaveMutation,
  CreateProtocolSoinSaveMutationVariables,
  DeleteProtocolSoinSaveMutation,
  DeleteProtocolSoinSaveMutationVariables,
  GetProtocolSoinQuery,
  GetProtocolSoinQueryVariables,
  ListProtocolSoinsQuery,
  ListProtocolSoinsQueryVariables,
  ProtocolSoinByIdDiseaseFamilyQuery,
  ProtocolSoinByIdDiseaseFamilyQueryVariables,
  ProtocolSoinSaveByBseIdQuery,
  ProtocolSoinSaveByBseIdQueryVariables,
  ProtocolSoinSaveByIdBreedingIdQuery,
  ProtocolSoinSaveByIdBreedingIdQueryVariables,
  UpdateProtocolSoinMutation,
  UpdateProtocolSoinMutationVariables,
  UpdateProtocolSoinSaveMutation,
} from '../API';
import { updateProtocolSoin, updateProtocolSoinSave } from '../graphql/mutations';
import * as mutations from '../graphql/mutations';

export function useListProtocolSoinByIdDiseaseFamily(filter: {}) {
  const listProto = <DocumentNode>gql(listProtocolSoins);

  const { loading, data } = useQuery<
  ListProtocolSoinsQuery, ListProtocolSoinsQueryVariables>(listProto, {
    variables: {
      filter,
    },
    fetchPolicy: 'cache-and-network',
  });
  if (data) {
    return { protocolSoins: data, loading };
  }
  return { disease: null, loading };
}

export function useGetProtocolSoinByIdDiseaseFamily(idDiseaseFamily: string) {
  const psQuery = <DocumentNode>gql(protocolSoinByIdDiseaseFamily);

  const { loading, data } = useQuery<
  ProtocolSoinByIdDiseaseFamilyQuery, ProtocolSoinByIdDiseaseFamilyQueryVariables>(psQuery, {
    variables: {
      idDiseaseFamily,
    },
    fetchPolicy: 'cache-and-network',
  });
  if (data && data.protocolSoinByIdDiseaseFamily && data.protocolSoinByIdDiseaseFamily.items) {
    return { psDefault: data.protocolSoinByIdDiseaseFamily.items[0], loading };
  }
  return { psDefault: null, loading };
}

export function useGetProtocolSoin(id: string) {
  const psQuery = <DocumentNode>gql(getProtocolSoin);

  const { loading, data, refetch } = useQuery<
  GetProtocolSoinQuery, GetProtocolSoinQueryVariables>(psQuery, {
    variables: {
      id,
    },
    fetchPolicy: 'cache-and-network',
  });
  if (data && data.getProtocolSoin && data.getProtocolSoin) {
    return { ps: data.getProtocolSoin, loading, refetch };
  }
  return { ps: null, loading, refetch: () => {} };
}

export function useGetProtocolSoinSaveByIdDiseaseFamilyAndBseId(idDiseaseFamily?: string, bseId?: string) {
  const psQuery = <DocumentNode>gql(protocolSoinSaveByBseId);

  const { loading, data, refetch } = useQuery<
  ProtocolSoinSaveByBseIdQuery,
  ProtocolSoinSaveByBseIdQueryVariables
  >(psQuery, {
    variables: {
      bseId: bseId || '',
      idDiseaseFamily: { eq: idDiseaseFamily || '' },
    },
    fetchPolicy: 'cache-and-network',
    skip: !(idDiseaseFamily && bseId),
  });
  if (data && data.protocolSoinSaveByBseId && data.protocolSoinSaveByBseId.items) {
    return {
      psSave: data.protocolSoinSaveByBseId.items
        .filter((item) => item && !item._deleted)[0],
      loading,
      refetch,
    };
  }
  return { psSave: null, loading, refetch };
}

export function getProtocolSoinSaveByBseIdAsync<T>(client: ApolloClient<T>, bseId: string) {
  const psQuery = <DocumentNode>gql(protocolSoinSaveByBseId);

  return client.query<ProtocolSoinSaveByBseIdQuery,
  ProtocolSoinSaveByBseIdQueryVariables>({
    query: psQuery,
    variables: {
      bseId,
    },
    fetchPolicy: 'network-only',
  });
}

export function useGetProtocolSoinSaveByBseId(bseId?: string) {
  const psQuery = <DocumentNode>gql(protocolSoinSaveByBseId);
  if (bseId) {
    const {
      loading,
      data,
    } = useQuery<ProtocolSoinSaveByBseIdQuery,
    ProtocolSoinSaveByBseIdQueryVariables>(psQuery, {
      variables: {
        bseId,
      },
      fetchPolicy: 'cache-and-network',
    });
    if (data && data.protocolSoinSaveByBseId && data.protocolSoinSaveByBseId.items) {
      return {
        psSave: data.protocolSoinSaveByBseId.items
          .filter((item) => item && !item._deleted),
        loading,
      };
    }
  }
  return { psSave: null, loading: true };
}

export function useListProtocolSoin(variables: ListProtocolSoinsQueryVariables) {
  const psQuery = <DocumentNode>gql(listProtocolSoins);
  const {
    loading,
    data,
    fetchMore,
    refetch,
  } = useQuery<ListProtocolSoinsQuery,
  ListProtocolSoinsQueryVariables>(psQuery, {
    variables,
    fetchPolicy: 'cache-and-network',
  });
  if (data && data.listProtocolSoins && data.listProtocolSoins.items) {
    return {
      protocolSoins: data.listProtocolSoins.items,
      loading,
      fetchMore,
      refetch,
      nextToken: data?.listProtocolSoins?.nextToken,
    };
  }

  return {
    protocolSoins: null,
    loading: false,
    fetchMore: () => {},
    refetch: () => {},
    nextToken: null,
  };
}

export function useGetProtocolSoinSaveByBreedingId(breedingId?: string) {
  const psQuery = <DocumentNode>gql(protocolSoinSaveByIdBreedingId);
  if (breedingId) {
    const {
      loading,
      data,
    } = useQuery<ProtocolSoinSaveByIdBreedingIdQuery,
    ProtocolSoinSaveByIdBreedingIdQueryVariables>(psQuery, {
      variables: {
        breedingId,
      },
      fetchPolicy: 'cache-and-network',
    });
    if (data && data.protocolSoinSaveByIdBreedingId && data.protocolSoinSaveByIdBreedingId.items) {
      return {
        psSave: data.protocolSoinSaveByIdBreedingId.items
          .filter((item) => item && !item._deleted),
        loading,
      };
    }
  }
  return { psSave: null, loading: true };
}

export function useUpdateProtocolSoinSave() {
  const [modifyProtocolSoinSave, { loading: mutationProtocolSoinSaveLoading }] = useMutation<
  UpdateProtocolSoinSaveMutation, UpdateProtocolSoinMutationVariables
  >(gql(updateProtocolSoinSave));
  return { modifyProtocolSoinSave, mutationProtocolSoinSaveLoading };
}

export function useCreateProtocolSoinSaveMutation() {
  const [createProtocolSoinSave] = useMutation<
  CreateProtocolSoinSaveMutation, CreateProtocolSoinSaveMutationVariables
  >(gql(mutations.createProtocolSoinSave));
  return { createProtocolSoinSave };
}

export function useDeleteProtocolSoinSave() {
  const [deleteProtocolSoinSave] = useMutation<
  DeleteProtocolSoinSaveMutation, DeleteProtocolSoinSaveMutationVariables
  >(gql(mutations.deleteProtocolSoinSave));
  return { deleteProtocolSoinSave };
}

export function useUpdateProtocolSoinMutation() {
  const [modifyProtocolSoin, { loading: mutationProtocolSoinLoading }] = useMutation<
  UpdateProtocolSoinMutation, UpdateProtocolSoinMutationVariables
  >(gql(updateProtocolSoin));
  return { modifyProtocolSoin, mutationProtocolSoinLoading };
}
