import * as React from 'react';
import {
  StyleSheet, TouchableOpacity, KeyboardAvoidingView, View, Modal,
} from 'react-native';

import { useEffect, useState } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { AnimatePresence, MotiView } from 'moti';
import { useDimensions } from '@react-native-community/hooks';
import Sheet from './Sheet';

export type ActionSheetProps = {
  title?: string,
  subtitle?: string,
  children: React.ReactNode,
  before: React.ReactNode,
  noSafeArea: boolean,
  visible: boolean,
  scrollable: boolean,
  onClose?: () => void
  heightPercentage?: number,
  rightAction?: {
    label: string,
    onPress: () => any
  }
};

const ActionSheet = (props: ActionSheetProps) => {
  const {
    scrollable = false,
    noSafeArea = false,
    subtitle = undefined,
    rightAction = undefined,
    heightPercentage = 0.8,
    title, children, before, visible, onClose,
  } = props;
  const { window } = useDimensions();
  const [visibleState, setVisible] = useState(false);
  const [visibleModalState, setVisibleModal] = useState(false);

  useEffect(() => {
    if (visible !== visibleState) {
      if (visible) {
        setVisible(visible);
        setVisibleModal(visible);
      } else {
        setVisible(visible);
      }
    }
  }, [visible]);

  const insets = useSafeAreaInsets();

  const onRequestClose = () => {
    if (onClose) {
      onClose();
    } else {
      setVisible(false);
    }
  };

  const computedStyles = styles.modal;

  return (
    <Modal
      animationType="none"
      onRequestClose={onRequestClose}
      transparent
      statusBarTranslucent
      visible={visibleModalState}
      style={{
        margin: 0,
        ...StyleSheet.absoluteFillObject,
        height: window.height,
      }}
    >
      {
          before
        }
      <AnimatePresence onExitComplete={() => setVisibleModal(false)}>
        {visibleState && (
          <View style={computedStyles}>
            <MotiView
              style={{
                ...StyleSheet.absoluteFillObject,
                backgroundColor: '#000000',
                flex: 1,
              }}
              from={{ opacity: 0 }}
              animate={{ opacity: 0.5 }}
              exit={{ opacity: 0 }}
              transition={{
                type: 'timing',
                duration,
              }}
            >
              <TouchableOpacity style={styles.exit} onPress={onRequestClose} />

            </MotiView>
            <MotiView
              style={{
                paddingBottom: insets.bottom,
                marginBottom: -insets.bottom,
                width: '100%',
                maxWidth: 780,
                flex: 1,
                height: window.height * heightPercentage,
                maxHeight: window.height * heightPercentage,
                justifyContent: 'flex-end',
              }}
              from={{ opacity: 0, translateY: window.height, translateX: 0 }}
              animate={{ opacity: 1, translateY: 0, translateX: 0 }}
              exit={{ opacity: 0, translateY: window.height, translateX: 0 }}
              transition={{
                type: 'timing',
                duration,
              }}
            >
              <View style={{
                maxHeight: window.height * heightPercentage, flex: 1,
              }}
              >
                <KeyboardAvoidingView behavior="height" style={{ flex: 1 }}>
                  <Sheet
                    {...{
                      toggle: onRequestClose, title, subtitle, rightAction, noSafeArea, scrollable,
                    }}
                  >
                    {children}
                  </Sheet>
                </KeyboardAvoidingView>
              </View>
            </MotiView>
          </View>
        )}
      </AnimatePresence>
    </Modal>
  );
};

export default ActionSheet;

const duration = 350;
const styles = StyleSheet.create({
  modal: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  exit: {
    flex: 1,
  },
});
