import React from 'react';

import { Text } from '@ui-kitten/components';
import { useLinkTo } from '@react-navigation/native';
import MaxWidthContainer from '../../../components/MaxWidthContainer';

import InfoCard from '../../../screenComponents/InfoCard';
import Button from '../../../components/Button';
import SearchComponent from '../../../screenComponents/SearchComponent';
import VetoElevageScreenBL from '../../../screenBusinessLogic/vetoScreensBL/VetoElevageScreens/VetoElevageScreenBL';
import ElevageCard from '../../../components/ElevageCard';
import VetoBseScreenBL from '../../../screenBusinessLogic/vetoScreensBL/VetoBseScreenBL/VetoBseScreenBL';
import { useBreedingList } from '../../../src/API/Breeding';
import { useUser } from '../../../src/API/UserContext';
import Tutorial from '../../../components/Tutorial';

export default function VetoElevageScreen() {
  const linkTo = useLinkTo();

  const { user } = useUser();
  const { breedings } = useBreedingList(user?.userGroup);
  // console.log(breedings);
  // console.log(user?.userGroup);
  return (
    <MaxWidthContainer
      withScrollView="keyboardAware"
      outerViewProps={{
        style: {
          padding: 24,
        },
      }}
    >
      <Tutorial
        videoId="CHkaxyT6AKI"
      />
      <InfoCard
        title="Bienvenue sur votre espace dédié à vos élevages"
        iconShow="alert-circle-outline"
        // iconCloseShow="close-outline"
        description="Vous pouvez accéder à tous les élevages suivis par votre cabinet et en ajouter de nouveaux. Depuis chaque élevage, vous pouvez consulter les détails de celui-ci et le statut de réalisation du Bilan Sanitaire d'élevage. Vous pouvez planifier, modifier et commencer un BSE depuis cet écran."
        alertInfo="alertInfo"
      />

      {/* <Button leftIcon="plus-outline" leftIconFill="white" onPress={() => { VetoElevageScreenBL.goAjoutScreen({ state: 'ajout-elevage', linkTo }); }}>Ajouter un nouvel élevage</Button> */}

      <Text category="h2" style={{ marginTop: 40 }} appearance="hint">Tous les élevages suivis</Text>
      <SearchComponent
        state="elevage"
        opened
        keyExtractor={(item) => item.id}
        data={breedings}
        placeholder="Rechercher un élevage"
        renderItem={(item) => (
          <ElevageCard
            linkTo={() => { VetoElevageScreenBL.goAjoutScreen({ state: 'details-elevage', linkTo, id: item.item.id }); }}
            // linkTo={() => {
              // ElevageScreenBL.allerDetailScreen({ state: 'lots', linkTo, id: item.item.id });
              // })}
            breeding={item.item}
          />
        )}
      />

      <Text category="h1" style={{ marginTop: 40 }}>Réaliser le Bilan Sanitaire d'Élevage</Text>
      <Tutorial
        videoId="H2L-7YOALUM"
      />
      <InfoCard
        title="alertInfo"
        description={"Vous pouvez réaliser un nouveau Bilan Sanitaire d'Élevage et voir les élevages pour lesquels il n'a pas été réalisé."}
        buttonText="Accéder à mon espace BSE"
        buttonOnPress={() => { VetoBseScreenBL.linkToSwitch({ state: 'veto-bse', linkTo }); }}
        invertButtonStyle
      />

    </MaxWidthContainer>
  );
}
