import { useLinkTo } from '@react-navigation/native';
import { Text, useTheme } from '@ui-kitten/components';
import React from 'react';
import MaxWidthContainer from '../../components/MaxWidthContainer';

import InfoCard from '../../screenComponents/InfoCard';
import Button from '../../components/Button';
import { useCreateBseMutation, useBseListEleveur } from '../../src/API/BSE';
import { useBreedingList } from '../../src/API/Breeding';
import { useUser } from '../../src/API/UserContext';
import { StatusBse } from '../../src/API';

const PrebilanScreen = () => {
  const linkTo = useLinkTo();
  const theme = useTheme();
  const { user } = useUser();
  const { breeding } = useBreedingList(user?.userGroup);
  const { createBse } = useCreateBseMutation();
  const { bse } = useBseListEleveur(true);
  let modifyPrereview = false;
  if (bse && [StatusBse.BSEStarted, StatusBse.BSEAskStart, StatusBse.BSEFinish, StatusBse.BSEProtocolSoins].indexOf(bse.StatusBse) <= -1) {
    modifyPrereview = true;
  }

  const realisePrebilan = async () => {
    // console.log('realisePrebilan');
    if (breeding) {
      await createBse({
        variables: {
          input: {
            breedingId: breeding?.id,
          },
        },
      }).then((prebilan) => { linkTo(`/bilan-sanitaire/prebilan-questionnaire/${prebilan.data?.createBSE?.id}`); });
    }
  };

  const modifyPrebilan = () => {
    linkTo(`/bilan-sanitaire/prebilan-questionnaire/${bse.id}`);
  };

  return (
    <MaxWidthContainer
      withScrollView="keyboardAware"
      outerViewProps={{
        style: {
          padding: 24,
        },
      }}
    >

      {modifyPrereview ? (
        <>
          <InfoCard
            title="alertInfo"
            iconShow="alert-circle-outline"
            description="Vous pouvez encore modifier le pré-bilan avant de faire une demande de BSE."
            alertInfo="alertInfo"
          />
          <Button
            leftIcon="edit-outline"
            leftIconFill={theme['color-primary-500']}
            appearance="outline"
            onPress={() => {
              modifyPrebilan();
            }}
          >
            Modifier le Pré-bilan
          </Button>
        </>

      ) : (bse && bse.StatusBse === StatusBse.BSEFinish || !bse
        ? (
          <>
            <InfoCard
              title="alertInfo"
              iconShow="alert-circle-outline"
              description="Vous devez répondre aux questions du pré-bilan avant de
            pouvoir faire une demande de BSE."
              alertInfo="alertInfo"
            />
            <Button
              onPress={() => { realisePrebilan(); }}
            >
              Réaliser le pré-bilan
            </Button>
          </>
        ) : (
          <InfoCard
            title="alertInfo"
            iconShow="alert-circle-outline"
            description="Vous avez déjà une demande de BSE en cours."
            alertInfo="alertInfo"
          />
        )
      )}

      {/* <Text category="h2" appearance="hint" style={{ marginTop: 40, marginBottom: 10 }}>
        Précédents Pré - Bilans
      </Text>
      <Text category="s2" style={{ marginBottom: 10 }}>Consultez les précédents Pré-bilans réalisés avec l'application.</Text>

      <GoCustomCard title1="Historique" linkToCustom={() => { BseScreenBL.linkToSwitch({ state: 'historique-prebilan', linkTo }); }} minusSquareOutline="arrow-ios-forward" />
*/}
      <Text category="h1" style={{ marginTop: 40, marginBottom: 7 }}>Une question sur le Pré-bilan ?</Text>
      <InfoCard
        description={"Consultez notre FAQ afin d'obtenir une réponse sur les questions les plus "
          + "fréquemment posées à propos de l'application."}
        // imagePosition="right"
        imageUri=""
        buttonText="Accéder à la FAQ"
        buttonOnPress={() => { linkTo('/faq'); }}
      />
    </MaxWidthContainer>
  );
};

export default PrebilanScreen;
