import React, { useState } from 'react';
import { useLinkTo } from '@react-navigation/native';
import ListTeleconsultationCards from '../../screenComponents/ListTeleconsultationCards';
import { useUser } from '../../src/API/UserContext';
import { useBreedingList } from '../../src/API/Breeding';
import Button from '../../components/Button';
import InfoCard from '../../screenComponents/InfoCard';
import MaxWidthContainer from '../../components/MaxWidthContainer';
import { LockedStatus } from '../../src/API';
import CustomModal from '../../components/Modals/CustomModal';
import Tutorial from '../../components/Tutorial';

const ConsultationScreen = () => {
  const linkTo = useLinkTo();
  const { user } = useUser();
  const { breeding } = useBreedingList(user?.userGroup);
  const [openModalUnsubscrib, setOpenModalUnsubscrib] = useState(false);

  return (
    <MaxWidthContainer
      outerViewProps={{
        style: {
          padding: 24,
        },
      }}
    >
      <Tutorial
        videoId="Tc59C2nmzxA"
      />
      {breeding && (
        <Button
          style={{ marginBottom: 10 }}
          onPress={() => {
            if (breeding && breeding.locked === LockedStatus.unlocked) {
              linkTo(`/consultation/realiser-auto-exam/${breeding.id}`);
            } else {
              setOpenModalUnsubscrib(true);
            }
          }}
        >
          Réaliser un nouvel auto-examen
        </Button>
      )}
      <InfoCard
        title="alertInfo"
        iconShow="alert-circle-outline"
        description="L’auto-examen se présente sous la forme d’un questionnaire guidé à choix unique ◉ ou multiple ☑
divisé en 4 grandes parties : historique, localisation et ciblage, examen général, examen par région
anatomique.
Le déroulé du questionnaire s’adapte en fonction de vos réponses que vous pouvez ignorer en cas de
doute."
        alertInfo="alertInfo"
      />
      {breeding && (
        <ListTeleconsultationCards
          breeding={breeding}
          isTDB={false}
          hide="consultationScreenShow"
          limit={1000}
        />
      )}
      <CustomModal
        title="Votre abonnement est terminé. Abonnez vous pour accéder à cette fonctionalité"
        before={<></>}
        noSafeArea
        visible={openModalUnsubscrib}
        scrollable={false}
        onClose={() => setOpenModalUnsubscrib(false)}
        insideModalStyle={{ textAlign: 'center', padding: 20 }}
        buttonText="Continuer"
        heightPercentage={0.46}
        buttonPress={() => {
          setOpenModalUnsubscrib(false);
          linkTo('/mon-compte/mode-paiement');
        }}
      />
    </MaxWidthContainer>
  );
};

export default ConsultationScreen;
