import React, { useState } from 'react';

import { useLinkTo } from '@react-navigation/native';
import { FlatList, View } from 'react-native';
import { Text } from '@ui-kitten/components';
import MaxWidthContainer from '../../../components/MaxWidthContainer';

import Button from '../../../components/Button';
import FCard from '../../../components/Card';
import FTextInput from '../../../components/Form/TextInput';
import { useListProtocolSoin } from '../../../src/API/ProtocolSoin';

export default function AdminProtocolSoinsScreen() {
  const linkTo = useLinkTo();

  const [searchedText, setSearchedText] = useState<undefined | string>('');
  const {
    protocolSoins, nextToken, fetchMore, loading,
  } = useListProtocolSoin(
    {
      filter: searchedText ? {
        name: { contains: searchedText },
      } : undefined,
      limit: 100,
    },
  );

  return (
    <MaxWidthContainer
      withScrollView="keyboardAware"
      outerViewProps={{
        style: {
          padding: 15,
        },
      }}
    >
      <FlatList
        data={protocolSoins}
        keyExtractor={(item) => item?.id || 'z'}
        renderItem={
          ({ item }) => (item
            ? (
              <FCard
                onPress={() => { linkTo(`/admin-ps/details/${item.id}`); }}
                style={{
                  marginVertical: 5, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginHorizontal: 5,
                }}
              >
                <View style={{ flexDirection: 'column', justifyContent: 'flex-start' }}>
                  <Text category="h4">{item.name}</Text>
                </View>
              </FCard>
            ) : null
          )
        }
        ListHeaderComponent={(
          <>
            <FTextInput
              name="search"
              placeholder="Rechercher un protocole de soins"
              value={searchedText}
              style={{ marginVertical: 15, marginHorizontal: 5, marginBottom: 10 }}
                    // eslint-disable-next-line no-underscore-dangle
              onChangeValue={(enteredValue) => {
                setSearchedText(enteredValue as string);
              }}
              icon="search-outline"
            />
          </>
        )}
        ListFooterComponent={nextToken ? (
          <Button
            disabled={loading}
            loading={loading}
            onPress={() => {
              fetchMore({
                variables: { nextToken },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev;
                  return {
                    ...prev,
                    listProtocolSoins: {
                      ...prev.listProtocolSoins,
                      ...fetchMoreResult.listProtocolSoins,
                      items: [
                        ...prev.listProtocolSoins?.items,
                        ...fetchMoreResult.listProtocolSoins?.items,
                      ],
                    },
                  };
                },
              });
            }}
          >
            Charger plus de monographies
          </Button>
        ) : null}
      />
    </MaxWidthContainer>
  );
}
