import { Text, useTheme } from '@ui-kitten/components';
import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { Alert, Platform, View } from 'react-native';
import { useForm } from 'react-hook-form';
import { useDimensions } from '@react-native-community/hooks';
import { useSubscription } from 'react-apollo';
import gql from 'graphql-tag';
import DetailsScreensHeaderCard from '../../../screenComponents/DetailsScreensHeaderCard';
import MaxWidthContainer from '../../../components/MaxWidthContainer';
import CustomModal from '../../../components/Modals/CustomModal';
import {
  onConfirmVetOfficeMandate,
  OnConfirmVetOfficeMandateSubscription,
  useGetVetOffice,
} from '../../../src/API/VetOffice';
import Formatter from '../../../utils/Formatter';
import FTextInput from '../../../components/Form/TextInput';
import { AvailableValidationRules } from '../../../components/Form/validation';
import Button from '../../../components/Button';
import Form from '../../../components/Form/Form';
import Radio from '../../../components/Form/Radio';
import BaseModal from '../../../components/Modals/BaseModal';
import WebView from '../../../components/WebView';
import Select from '../../../components/Form/Select';
import { ALL_countries, EU_countries } from '../../../src/mockData/selectData';
import DatepickerComp from '../../../components/Form/DatePicker';
import { IBANData } from '../../../types/mangopay';
import InfoCard from '../../../screenComponents/InfoCard';
import DateUtils from '../../../utils/DateUtils';
import { BicCodes } from '../../../src/mockData/bicCodes';
import * as StringHelper from '../../../utils/StringHelper';
import { KYCInfos } from '../../../screenComponents/VetScreens/KYCInfos';
import ActionSheet from '../../../components/ActionSheet/ActionSheet';
import ActivityIndicator from '../../../components/ActivityIndicator';
import { OnConfirmVetOfficeMandateSubscriptionVariables } from '../../../src/API';

type VetoIBAN = {
  iban: string,
  bic: string,
  nationality: string,
  birthday: string,
  nom:string,
  prenom:string,
  email:string,
  address1: string,
  address2: string,
  postalCode: string,
  city: string,
};

const INJECTEDJAVASCRIPT = 'const meta = document.createElement(\'meta\'); meta.setAttribute(\'content\', \'width=device-width, initial-scale=1, maximum-scale=0.99, user-scalable=0\'); meta.setAttribute(\'name\', \'viewport\'); document.getElementsByTagName(\'head\')[0].appendChild(meta); ';

const VetoCompteBancaire = () => {
  const theme = useTheme();
  const vetoIBAN = useForm<VetoIBAN>();
  const vetoIBANChange = useForm<VetoIBAN>();
  const [openDelete, setOpenDelete] = useState();
  const { window: rnWindow } = useDimensions();

  const [newMandateLink, setNewMandateLink] = useState<string | undefined>();
  const [addingMandate, setAddingMandate] = useState(false);

  const { vetOfficeData, refetchVetOffice } = useGetVetOffice();
  const [walletAmount, setWalletAmount] = useState<number | false>(false);
  const [bankInfos, setBankInfos] = useState<IBANData | false>(false);
  const [userInfos, setUserInfos] = useState<{ } | false>(false);

  const [processing, setProcessing] = useState(false);
  const [changingIban, setChangingIban] = useState(false);
  const [modalUrl, setModalUrl] = useState<string | false>(false);
  const [hasPayout, setHasPayout] = useState<boolean>(false);

  useEffect(() => {
    let isLoaded = true;

    if (vetOfficeData?.mpWalletId) {
      API.get('scanflockrest', '/mangopay/get-wallet-amount', {}).then((res) => {
        if (isLoaded) {
          if (res.success) {
            setWalletAmount(res.amount);
          } else {
            setWalletAmount(false);
          }
        }
      });
    }
    return () => { isLoaded = false; };
  }, [vetOfficeData?.mpWalletId, hasPayout]);

  useEffect(() => {
    let isLoaded = true;

    if (vetOfficeData?.mpBankId) {
      API.get('scanflockrest', '/mangopay/get-bank-infos', {}).then((res) => {
        if (isLoaded) {
          if (res.success) {
            setBankInfos(res.bankInfos);
          } else {
            setBankInfos(false);
          }
        }
      });
    }
    return () => { isLoaded = false; };
  }, [vetOfficeData?.mpBankId]);

  useEffect(() => {
    let isLoaded = true;

    if (vetOfficeData?.mpUserId) {
      API.get('scanflockrest', '/mangopay/get-user', {}).then((res) => {
        if (isLoaded) {
          if (res.success) {
            setUserInfos(res.userInfo);
          } else {
            setUserInfos(false);
          }
        }
      });
    }
    return () => { isLoaded = false; };
  }, [vetOfficeData?.mpUserId]);

  useSubscription<
  OnConfirmVetOfficeMandateSubscription,
  OnConfirmVetOfficeMandateSubscriptionVariables
  >(gql(onConfirmVetOfficeMandate),
    {
      variables: { id: vetOfficeData?.id || 'z' },
      onSubscriptionData: (options) => {
        if (options.subscriptionData.data?.onConfirmVetOfficeMandate?.mpMandateConfirmed) {
          if (refetchVetOffice) {
            refetchVetOffice();
            setAddingMandate(false);
            setNewMandateLink(undefined);
            setChangingIban(false);
          }
        }
      },
    });

  const openUrl = (url: string) => {
    if (Platform.OS === 'web') {
      window.open(url);
    } else {
      setModalUrl(url);
    }
  };

  if (vetOfficeData && vetOfficeData.mpWalletId) {
    return (
      <MaxWidthContainer
        withScrollView="keyboardAware"
        outerViewProps={{
          style: {
            padding: 20,
            marginBottom: 20,
          },
        }}
      >
        <Text category="h2" appearance="hint" style={{ marginTop: 30 }}>Compte bancaire enregisté</Text>
        {bankInfos ? (
          <DetailsScreensHeaderCard
            title1="Nom du détenteur"
            titleInfo1={bankInfos.OwnerName}
            title2="Banque"
            titleInfo2={BicCodes[bankInfos.BIC] || 'Banque inconnue'}
            title3="IBAN"
            titleInfo3={StringHelper.formatIban(bankInfos.IBAN)}
          />
        ) : null}

        {walletAmount !== false ? (
          <>
            <Text style={{ textAlign: 'center' }}>
              Montant disponible
            </Text>
            <Text category="h1" style={{ marginTop: 10, textAlign: 'center' }}>{Formatter.currencyFormatter.format(walletAmount / 100)}</Text>
            <CustomModal
              title={`Vous allez transférer le montant de ${Formatter.currencyFormatter.format(walletAmount / 100)} sur le compte bancaire enregistré ci-dessus.`}
              subtitle="Souhaitez-vous continuer ?"
              before={<></>}
              noSafeArea
              visible={openDelete !== undefined}
              scrollable={false}
              onClose={() => { setOpenDelete(undefined); }}
              insideModalStyle={{ padding: 20 }}
              buttonText="Confirmer"
              secondButtonText="Annuler"
              heightPercentage={0.45}
              buttonPress={async () => {
                const resp = await API.post('scanflockrest', '/mangopay/do-payout', {});
                console.log(resp);
                setHasPayout(true);
                setOpenDelete(undefined);
              }}
            />
          </>
        ) : null}

        {userInfos && walletAmount !== false ? (
          userInfos.KYCLevel === 'LIGHT'
            ? (<KYCInfos />)
            : (
              <Button
                size="medium"
                status="primary"
            // appearance="outline"
                onPress={() => { setOpenDelete('open'); }}
                style={{ marginTop: 30, backgroundColor: theme['color-primary-700'] }}
              >
                Transférer vers un compte bancaire
              </Button>
            )) : null}

        <Text style={{ marginTop: 30 }}>
          Vous souhaitez modifier vos informations bancaires ?
        </Text>
        {changingIban ? (
          <>
            <Form<VetoIBAN>
              {...vetoIBANChange}
            >
              <>
                <View>
                  <Text category="h6" appearance="hint">IBAN *</Text>
                  <FTextInput
                    name="iban"
                    placeholder="Saisissez votre IBAN"
                    maskOptions={{
                      type: 'custom',
                      options: { mask: 'FR99 9999 9999 99SS SSSS SSSS S99' },
                    }}
                    validators={[
                      AvailableValidationRules.iban,
                      AvailableValidationRules.required,
                    ]}
                  />
                  <Text category="h6" appearance="hint">BIC *</Text>
                  <FTextInput
                    name="bic"
                    placeholder="Saisissez votre BIC"
                    validators={[
                      AvailableValidationRules.required,
                    ]}
                  />
                </View>
              </>
            </Form>
            <Text style={{ flex: 1 }}>
              <Text>Les mandats de prélèvements sont gérés grâce à nos partenaires Mangopay et Gocardless. Vous pouvez consulter les politiques de confidentialité de Gocardless </Text>
              <Text onPress={() => openUrl('https://gocardless.com/fr-fr/legal/politique-de-confidentialite/')} style={{ textDecorationLine: 'underline' }}>ici</Text>
              <Text>.</Text>
            </Text>
          </>
        ) : null}
        <Button
          loading={processing}
          onPress={async () => {
            if (changingIban) {
              await vetoIBANChange.handleSubmit(async (data) => {
                setProcessing(true);
                let w = null;
                if (Platform.OS === 'web') {
                  w = window.open('');
                  if (w) {
                    w.document.write("<html><head></head><body>Merci d'attendre, nous chargeons votre mandat électronique de prélèvement.</body></html>");
                  }
                }
                const resp = await API.post('scanflockrest', '/mangopay/update-bank', { body: data });
                if (resp.success) {
                  if (w) {
                    w.location.href = resp.returnUrl;
                  }
                  setNewMandateLink(resp.returnUrl);
                }
                setProcessing(false);
                setChangingIban(false);
              }, (a) => {
                console.log('error');
                console.log(a);
              })();
            } else {
              setChangingIban(true);
            }
          }}
          leftIcon="edit-outline"
          leftIconFill={theme['color-primary-500']}
          appearance="outline"
          style={{ marginTop: 10 }}
        >
          Modifier les informations bancaires
        </Button>

        {Platform.OS === 'web' ? (
          <CustomModal
            title="Signature du mandat électronique en cours. Merci de ne pas fermer cette fenêtre."
            before={<></>}
            noSafeArea
            visible={newMandateLink !== undefined || addingMandate}
            scrollable={false}
            onClose={() => {}}
            heightPercentage={Platform.OS === 'web' ? 0.36 : 0.6}
          />
        ) : (
          <>
            <ActionSheet
              title="Confirmation du mandat"
              before={<></>}
              noSafeArea
              scrollable={false}
              visible={newMandateLink !== undefined || addingMandate}
              onClose={() => {
                Alert.alert('Mandat de prélèvement',
                  'Etes-vous sur de vouloir quitter sans confirmer votre mandat de prélèvement ?.',
                  [{
                    text: 'Annuler',
                    style: 'cancel',
                  },
                  {
                    text: 'Valider',
                    onPress: async () => {
                      setNewMandateLink(undefined);
                      setAddingMandate(false);
                    },
                  }]);
              }}
            >
              {newMandateLink && (
                <WebView
                  src={newMandateLink}
                  injectedJavaScript={INJECTEDJAVASCRIPT}
                />
              )}
              {addingMandate && (
                <View style={{
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                >
                  <ActivityIndicator />
                </View>
              )}
            </ActionSheet>
            <BaseModal
              visible={modalUrl !== false}
              onClose={() => setModalUrl(false)}
            >
              <View
                style={{
                  backgroundColor: 'white',
                  borderRadius: 10,
                  height: rnWindow.height * 0.9,
                  maxHeight: rnWindow.height * 0.9,
                  width: rnWindow.width * 0.9,
                  flex: 1,
                  justifyContent: 'space-between',
                  overflow: 'hidden',
                  padding: 7,
                }}
              >
                <WebView src={modalUrl || ''} />
                <Button onPress={() => setModalUrl(false)}>Fermer</Button>
              </View>
            </BaseModal>
          </>
        )}
      </MaxWidthContainer>
    );
  }

  return (
    <MaxWidthContainer
      withScrollView="keyboardAware"
      outerViewProps={{
        style: {
          padding: 20,
          marginBottom: 20,
        },
      }}
    >
      <InfoCard
        title="alertInfo"
        iconShow="alert-circle-outline"
        // iconCloseShow="close-outline"
        description="Dans le cadre de ses obligations pour la lutte contre le blanchiment et le financement du terrorisme, notre prestataire de paiement collecte un certain nombre d'informations pour être en conformité avec ses obligations légales. Ces informations ne sont pas stockées par Scanflock."
        alertInfo="alertInfo"
        infoStyle={{ marginVertical: 10 }}
      />
      <Form<VetoIBAN>
        {...vetoIBAN}
      >
        <>
          <View>
            <Text category="h6" appearance="hint">IBAN *</Text>
            <FTextInput
              name="iban"
              placeholder="Saisissez votre IBAN"
              maskOptions={{
                type: 'custom',
                options: { mask: 'FR99 9999 9999 99SS SSSS SSSS S99' },
              }}
              validators={[
                AvailableValidationRules.iban,
                AvailableValidationRules.required,
              ]}
            />
            <Text category="h6" appearance="hint">BIC *</Text>
            <FTextInput
              name="bic"
              placeholder="Saisissez votre BIC"
              validators={[
                AvailableValidationRules.required,
              ]}
            />
            <Text category="h6" appearance="hint">Informations sur le représentant légal du cabinet vétérinaire</Text>
            <Text category="h6" appearance="hint">Nom *</Text>
            <FTextInput
              name="nom"
              placeholder="Saisissez le nom du représentant légal"
              validators={[
                AvailableValidationRules.required,
              ]}
            />
            <Text category="h6" appearance="hint">Prénom *</Text>
            <FTextInput
              name="prenom"
              placeholder="Saisissez le prénom du représentant légal"
              validators={[
                AvailableValidationRules.required,
              ]}
            />
            <Text category="h6" appearance="hint">E-mail *</Text>
            <FTextInput
              name="email"
              placeholder="Saisissez l'e-mail du représentant légal"
              validators={[
                AvailableValidationRules.required,
                AvailableValidationRules.email,
              ]}
            />
            <Text category="h6" appearance="hint">Adresse *</Text>
            <FTextInput
              name="address1"
              placeholder="Saisissez l'adresse du représentant légal"
              validators={[
                AvailableValidationRules.required,
              ]}
            />
            <Text category="h6" appearance="hint">Adresse (complément)</Text>
            <FTextInput
              name="address2"
              placeholder="Saisissez le complément d'adresse"
            />
            <Text category="h6" appearance="hint">Code postal *</Text>
            <FTextInput
              name="postalCode"
              placeholder="Saisissez le code postal"
              validators={[
                AvailableValidationRules.required,
              ]}
            />
            <Text category="h6" appearance="hint">Ville *</Text>
            <FTextInput
              name="city"
              placeholder="Saisissez la ville"
              validators={[
                AvailableValidationRules.required,
              ]}
            />
            <Text category="h6" appearance="hint">Nationalité *</Text>
            <Select
              name="nationality"
              placeholder="Choisissez la nationalité"
              validators={[
                AvailableValidationRules.required,
              ]}
              data={EU_countries}
            />
            <Text category="h6" appearance="hint">Date de naissance *</Text>
            <DatepickerComp
              name="birthday"
              placeholder="Saisissez la date de naissance"
              validators={[
                AvailableValidationRules.required,
              ]}
            />
            <Text category="h6" appearance="hint">Lieu de naissance *</Text>
            <FTextInput
              name="lieuNaissance"
              placeholder="Saisissez le lieu de naissance"
              validators={[
                AvailableValidationRules.required,
              ]}
            />
            <Text category="h6" appearance="hint">Pays de naissance *</Text>
            <Select
              name="paysNaissance"
              placeholder="Choisissez le pays de naissance"
              validators={[
                AvailableValidationRules.required,
              ]}
              data={ALL_countries}
            />
          </View>
          <Radio
            name="acceptCGVMangoPay"
            label={(props) => (
              <Text style={{ flex: 1 }}>
                <Text {...props}>J'accepte </Text>
                <Text {...props} onPress={() => openUrl('https://scanflock.com/conditions-generales-dutilisation/?simple=1')} style={{ textDecorationLine: 'underline' }}>les CGU</Text>
                <Text {...props}> de notre partenaire de paiement Mangopay</Text>
              </Text>
            )}
            labelPosition="after"
            validators={[
              {
                rule: AvailableValidationRules.required,
                errorMessage: 'Vous devez accepter les CGU de notre partenaire de paiement Mangopay',
              },
            ]}
          />
          <Button
            style={{ marginTop: 40 }}
            loading={processing}
            loadingText={"En cours d'enregistrement"}
            onPress={
              vetoIBAN.handleSubmit(async (data) => {
                setProcessing(true);
                const birthday = DateUtils.parseToDateObj(data.birthday);
                birthday.setHours(12);
                const realData = {
                  ...data,
                  birthday: Math.round(birthday.getTime() / 1000),
                };
                let w = null;
                if (Platform.OS === 'web') {
                  w = window.open('');
                  if (w) {
                    w.document.write("<html><head></head><body>Merci d'attendre, nous chargeons votre mandat électronique de prélèvement.</body></html>");
                  }
                }
                const resp = await API.post('scanflockrest', '/mangopay/create-user', { body: realData });
                if (resp.success && resp.returnUrl) {
                  if (w) {
                    w.location.href = resp.returnUrl;
                  }
                  setNewMandateLink(resp.returnUrl);
                }
                setProcessing(false);
              })
            }
          >
            Ajouter mes coordonnées bancaires
          </Button>
          <Text style={{ flex: 1 }}>
            <Text>Les mandats de prélèvements sont gérés grâce à nos partenaires Mangopay et Gocardless. Vous pouvez consulter les politiques de confidentialité de Gocardless </Text>
            <Text onPress={() => openUrl('https://gocardless.com/fr-fr/legal/politique-de-confidentialite/')} style={{ textDecorationLine: 'underline' }}>ici</Text>
            <Text>.</Text>
          </Text>
        </>
      </Form>
      {Platform.OS === 'web' ? (
        <CustomModal
          title="Signature du mandat électronique en cours. Merci de ne pas fermer cette fenêtre."
          before={<></>}
          noSafeArea
          visible={newMandateLink !== undefined || addingMandate}
          scrollable={false}
          onClose={() => {}}
          heightPercentage={Platform.OS === 'web' ? 0.36 : 0.6}
        />
      ) : (
        <>
          <ActionSheet
            title="Confirmation du mandat"
            before={<></>}
            noSafeArea
            scrollable={false}
            visible={newMandateLink !== undefined || addingMandate}
            onClose={() => {
              Alert.alert('Mandat de prélèvement',
                'Etes-vous sur de vouloir quitter sans confirmer votre mandat de prélèvement ?.',
                [{
                  text: 'Annuler',
                  style: 'cancel',
                },
                {
                  text: 'Valider',
                  onPress: async () => {
                    setNewMandateLink(undefined);
                    setAddingMandate(false);
                  },
                }]);
            }}
          >
            {newMandateLink && (
              <WebView
                src={newMandateLink}
                injectedJavaScript={INJECTEDJAVASCRIPT}
              />
            )}
            {addingMandate && (
              <View style={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
              }}
              >
                <ActivityIndicator />
              </View>
            )}
          </ActionSheet>
          <BaseModal
            visible={modalUrl !== false}
            onClose={() => setModalUrl(false)}
          >
            <View
              style={{
                backgroundColor: 'white',
                borderRadius: 10,
                height: rnWindow.height * 0.9,
                maxHeight: rnWindow.height * 0.9,
                width: rnWindow.width * 0.9,
                flex: 1,
                justifyContent: 'space-between',
                overflow: 'hidden',
                padding: 7,
              }}
            >
              <WebView src={modalUrl || ''} />
              <Button onPress={() => setModalUrl(false)}>Fermer</Button>
            </View>
          </BaseModal>
        </>
      )}
    </MaxWidthContainer>
  );
};

export default VetoCompteBancaire;
