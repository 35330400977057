import * as DocumentPicker from 'expo-document-picker';
import { Platform } from 'react-native';
import { ApolloQueryResult } from 'apollo-client';
import { Upload } from '../../utils/S3FileStorage';
import { useCreateDocumentMutation } from '../../src/API/Document';
import { useUpdateConsultationMutation } from '../../src/API/Consultation';

import {
  Breeding,
  Consultation, GetUserQuery, GetUserQueryVariables,
} from '../../src/API';
import { useUser } from '../../src/API/UserContext';
import { useUpdateUser } from '../../src/API/User';

const UseDocument = () => {
  const { createDocument } = useCreateDocumentMutation();
  const { modifyConsultation } = useUpdateConsultationMutation();
  const { modifyUser } = useUpdateUser();
  const { user } = useUser();
  const uploadCreateDocument = async (
    documentType: 'facture' | 'EDE',
    refetch? :(
      variables?: GetUserQueryVariables | undefined
    ) => Promise<ApolloQueryResult<GetUserQuery>>,
    data?: Consultation | Breeding,
  ) => {
    let breedingId: string | null = '';
    const doc = await DocumentPicker.getDocumentAsync({
      copyToCacheDirectory: Platform.OS !== 'android',
    });
    // console.log('doc ajouter: ', doc);
    let key : string;
    let path : string;
    let oneConsultation: Consultation;
    let oneBreeding: Breeding;

    /** FACTURE */
    if (documentType === 'facture' && data) {
      // @ts-ignore can be only consultation
      oneConsultation = data;
      key = `Facture_consultation_${oneConsultation?.id}`;
      path = `documents/consultation/facture/${oneConsultation.id}/`;
    }
    /** EDE */
    if (documentType === 'EDE' && data) {
      // @ts-ignore can be only breeding
      oneBreeding = data;
      key = `Document_EDE_${oneBreeding.nSIRET}`;
      path = `documents/ede/${oneBreeding.id}/`;
      breedingId = oneBreeding.id;
    }

    /** COMMON */
    const name = doc.type === 'success' ? doc.name : '';
    if (doc.type === 'success') {
      // upload chosen document from gallery to S3
      const s3file = await Upload(doc, path);
      if (s3file !== false) {
        let docu: any;
        // console.log('s3file ajouter:', s3file);
        if (documentType === 'EDE' && breedingId) {
          docu = await createDocument({
            variables: {
              input: {
                s3file: s3file.key,
                breedingId,
                key,
                name,
              },
            },
          });
        } else {
          docu = await createDocument({
            variables: {
              input: {
                s3file: s3file.key,
                key,
                name,
              },
            },
          });
        }

        // console.log('facture:', facture);
        if (documentType === 'facture' && oneConsultation) {
          await modifyConsultation({
            variables: {
              input: {
                id: oneConsultation.id,
                // eslint-disable-next-line no-underscore-dangle
                _version: oneConsultation._version,
                factureId: docu?.data.createDocument.id,
              },
            },
          });
        }
      }
      if (refetch) {
        await refetch();
      }
    }
  };

  return { uploadCreateDocument };
};

const useDocumentDelete = () => {

};

export default {
  UseDocument,
  useDocumentDelete,
};
