import { Icon, Text, useTheme } from '@ui-kitten/components';
import React, { useEffect, useState } from 'react';
import { useRoute } from '@react-navigation/native';
import { RouteProp } from '@react-navigation/core/lib/typescript/src/types';
import {
  FlatList, TouchableOpacity, View,
} from 'react-native';
import { API } from 'aws-amplify';
import { useForm } from 'react-hook-form';
import MaxWidthContainer from '../../../components/MaxWidthContainer';
import DetailsScreensHeaderCard from '../../../screenComponents/DetailsScreensHeaderCard';
import { useGetBreeding, useUpdateBreedingMutation } from '../../../src/API/Breeding';
import { VetoTabExamenParamList } from '../../../types';
import ActivityIndicator from '../../../components/ActivityIndicator';
import ElevageScreenBL from '../../../screenBusinessLogic/ElevageScreen/ElevageScreenBL';
import DateUtils from '../../../utils/DateUtils';
import { useBseListByBreedingId } from '../../../src/API/BSE';
import Formatter from '../../../utils/Formatter';
import { getLockedStatusLabel, LockedStatusLabels } from '../../../screenBusinessLogic/LockedStatus';
import FCard from '../../../components/Card';
import Form from '../../../components/Form/Form';
import FTextInput from '../../../components/Form/TextInput';
import { AvailableValidationRules } from '../../../components/Form/validation';
import Button from '../../../components/Button';
import { LockedStatus } from '../../../src/API';
import Select from '../../../components/Form/Select';

type ChangeSubscription = {
  locked: LockedStatus,
  subscriptionAmount: number,
};

const AdminDetailsElevageScreen = () => {
  const route = useRoute();
  const theme = useTheme();

  const [usersEnabled, setUsersEnabled] = useState<{ [key:string]: boolean }>({});

  const { oneBreeding, refetch } = useGetBreeding(route.params.id);
  const { bse: lastBse } = useBseListByBreedingId(oneBreeding?.id, true);

  const { modifyBreeding } = useUpdateBreedingMutation();

  const [processing, setProcessing] = useState(false);
  const changeSubscription = useForm<ChangeSubscription>();
  const [changingSubscription, setChangingSubscription] = useState(false);

  const ede = oneBreeding?.documents?.items?.find(
    // eslint-disable-next-line no-underscore-dangle
    (item) => (item && !item._deleted && item.key && item.key.includes('Document_EDE')),
  );

  useEffect(() => {
    let isLoaded = true;

    if (oneBreeding?.users?.items) {
      oneBreeding?.users?.items.forEach((item) => {
        if (item) {
          API.get('scanflockrest', `/user-admin/user-status?userId=${item.id}`, {}).then((res) => {
            if (res.success && isLoaded) {
              setUsersEnabled((prevState) => ({
                ...prevState,
                [item.id]: res.enabled,
              }));
            }
          });
        }
      });
    }
    return () => { isLoaded = false; };
  }, [oneBreeding?.users?.items?.length, oneBreeding?.id]);

  const renderUserState = (item: { id: string }) => {
    switch (usersEnabled[item.id]) {
      case true:
        return <Icon name="checkmark-circle-outline" fill={theme['color-success-500']} style={{ height: 20, width: 20 }} />;
      case false:
        return <Icon name="close-circle-outline" fill={theme['color-danger-500']} style={{ height: 20, width: 20 }} />;
      default:
        return <ActivityIndicator style={{ width: 20, height: 20 }} />;
    }
  };

  return (
    <MaxWidthContainer
      withScrollView="keyboardAware"
      outerViewProps={{
        style: {
          padding: 20,
        },
      }}
    >
      <Text category="h4" style={{ marginTop: 40 }}>Informations de l'élevage</Text>
      {oneBreeding
        ? (
          <DetailsScreensHeaderCard
            title1="Raison sociale"
            titleInfo1={oneBreeding.companyName}
            title2="Adresse"
            titleInfo2={oneBreeding.address?.address}
            title3="Complèment d'adresse"
            titleInfo3={oneBreeding.address?.additionalAddress}
            title4="Code Postal"
            titleInfo4={oneBreeding.address?.postalCode}
            title5="Ville"
            titleInfo5={oneBreeding.address?.city}
            title6="Téléphone"
            titleInfo6={oneBreeding.phone}
            title7="E-mail"
            titleInfo7={oneBreeding.emailEleveur}
            title8="Type de production"
            titleInfo8={ElevageScreenBL.getTypeProductionAsString(oneBreeding)}
            title9="Document EDE"
            titleInfo9={ede || 'Pas de document EDE ajouté'}
            title10={"Statut du Bilan Sanitaire d'Élevage"}
            titleInfo10={lastBse ? `Réalisé le ${DateUtils.convertDate(lastBse.dateBSE)}` : 'Non réalisé'}

          />
        )
        : (<ActivityIndicator center margin={10} />)}

      <Text category="h4" style={{ marginTop: 40 }}>Effectif de l'élevage</Text>
      {oneBreeding && (
      <DetailsScreensHeaderCard
        title1="Vaches"
        titleInfo1={oneBreeding.nbCow}
        title2="Génisses"
        titleInfo2={oneBreeding.nbHeifer}
        title3="Mâles"
        titleInfo3={oneBreeding.nbMale}
        title4="Moins de 12 mois"
        titleInfo4={oneBreeding.nbYoung}
      />
      )}

      <Text category="h4" style={{ marginTop: 40 }}>Statut de l'élevage</Text>
      {oneBreeding && (
      <>
        <DetailsScreensHeaderCard
          title1="Montant annuel TTC de l'abonnement"
          titleInfo1={Formatter.currencyFormatter.format(oneBreeding.subscriptionAmount || 0)}
          title2="Statut"
          titleInfo2={getLockedStatusLabel(oneBreeding.locked)}
          title3="Prochain renouvellement"
          titleInfo3={oneBreeding.nextSubscriptionDate ? DateUtils.convertDate(oneBreeding.nextSubscriptionDate) : "Période d'essai non commencée"}
        />
        {changingSubscription ? (

          <Form<ChangeSubscription>
            {...changeSubscription}
            defaultValues={oneBreeding}
          >
            <>
              <View>
                <Text category="h6" appearance="hint">Montant annuel TTC de l'abonnement *</Text>
                <FTextInput
                  name="subscriptionAmount"
                  placeholder="Saisissez le montant annuel de l'abonnement"
                  maskOptions={{
                    type: 'money',
                    options: {
                      precision: 2,
                      separator: ',',
                      delimiter: ' ',
                      unit: '',
                      suffixUnit: ' €',
                    },
                  }}
                  moneySign
                  validators={[
                    AvailableValidationRules.float,
                    AvailableValidationRules.required,
                  ]}
                />
                <Text category="h6" appearance="hint">Statut *</Text>
                <Select
                  name="locked"
                  placeholder="Choisissez le statut"
                  validators={[
                    AvailableValidationRules.required,
                  ]}
                  data={LockedStatusLabels}
                />
              </View>
            </>
          </Form>
        ) : null}
        <Button
          loading={processing}
          onPress={async () => {
            if (changingSubscription) {
              await changeSubscription.handleSubmit(async (data) => {
                setProcessing(true);

                await modifyBreeding({
                  variables: {
                    input: {
                      id: oneBreeding.id,
                      subscriptionAmount: data.subscriptionAmount,
                      locked: data.locked,
                      _version: oneBreeding._version,
                    },
                  },
                });

                await refetch();

                setChangingSubscription(false);
                setProcessing(false);
              })();
            } else {
              setChangingSubscription(true);
            }
          }}
          leftIcon="edit-outline"
          leftIconFill={theme['color-primary-500']}
          appearance="outline"
          style={{ marginTop: 10 }}
        >
          Modifier le statut et le montant de l'abonnement
        </Button>
      </>
      )}
      <Text category="h4" style={{ marginTop: 40 }}>Utilisateurs</Text>
      <FlatList
        data={oneBreeding?.users?.items}
        keyExtractor={(item) => item?.id || 'z'}
        renderItem={
            ({ item }) => (item
              ? (
                <FCard
                  style={{
                    marginVertical: 5, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginHorizontal: 5,
                  }}
                >
                  <View style={{ flexDirection: 'column', justifyContent: 'flex-start' }}>
                    <Text category="h4">
                      {item.firstname}
                      {' '}
                      {item.lastname}
                    </Text>
                    <Text category="h6" appearance="hint">{item.email}</Text>
                  </View>
                  <TouchableOpacity onPress={() => {
                    if (usersEnabled[item.id] !== undefined) {
                      API.post('scanflockrest', '/user-admin/user-status', {
                        body: {
                          userId: item.id,
                          enabled: !usersEnabled[item.id],
                        },
                      }).then((res) => {
                        if (res.success) {
                          setUsersEnabled((prevState) => ({
                            ...prevState,
                            [item.id]: res.enabled,
                          }));
                        }
                      });
                    }
                  }}
                  >
                    {renderUserState(item)}
                  </TouchableOpacity>
                </FCard>
              ) : null
            )
          }
      />
    </MaxWidthContainer>
  );
};

export default AdminDetailsElevageScreen;
