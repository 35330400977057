import { useMutation, useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { DocumentNode } from 'apollo-link';
import { WatchQueryFetchPolicy } from 'apollo-client/core/watchQueryOptions';
import {
  CreateMessageInput,
  MessagesByConsultationQueryVariables,
  ModelSortDirection,
} from '../API';

export type CreateMessageMutationVariables = {
  input: CreateMessageInput,
};

export type CreateMessageMutation = {
  createMessage?: {
    __typename: 'Message',
    id: string,
    consultationId: string,
    message: string,
    userId: string,
    user?: {
      __typename: 'User',
      id: string,
      lastname?: string | null,
      firstname?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export const onCreateMessageByConsultationIdSubscription = /* GraphQL */ `
  subscription OnCreateMessageByConsultationId($consultationId: ID!) {
    onCreateMessageByConsultationId(consultationId: $consultationId) {
      id
      consultationId
      message
      userId
      user {
        id
        lastname
        firstname
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export type OnCreateMessageByConsultationIdSubscription = {
  onCreateMessageByConsultationId?: {
    __typename: 'Message',
    id: string,
    consultationId: string,
    message: string,
    userId: string,
    user?: {
      __typename: 'User',
      id: string,
      lastname?: string | null,
      firstname?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export const createMessageQuery = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
  ) {
    createMessage(input: $input) {
      id
      consultationId
      message
      userId
      user {
        id
        lastname
        firstname
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export function useCreateMessageMutation() {
  const [createMessage] = useMutation<
  CreateMessageMutation, CreateMessageMutationVariables
  >(gql(createMessageQuery));
  return createMessage;
}

export type MessagesByConsultationQuery = {
  messagesByConsultation?: {
    __typename: 'ModelMessageConnection',
    items: Array< {
      __typename: 'Message',
      id: string,
      consultationId: string,
      message: string,
      userId: string,
      user?: {
        __typename: 'User',
        id: string,
        lastname?: string | null,
        firstname?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export function useGetMessagesByConsultation(id: string, fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network') {
  const messagesByConsultationQuery = <DocumentNode>gql(`
  query MessagesByConsultation(
    $consultationId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByConsultation(
      consultationId: $consultationId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        consultationId
        message
        userId
        user {
          id
          lastname
          firstname
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
    }
  }
`);

  if (!id) {
    return { loading: false, messages: null };
  }
  const {
    loading, data, refetch, subscribeToMore, fetchMore,
  } = useQuery<
  MessagesByConsultationQuery, MessagesByConsultationQueryVariables
  >(messagesByConsultationQuery, {
    fetchPolicy,
    variables: {
      consultationId: id,
      sortDirection: ModelSortDirection.DESC,
      limit: 20,
    },
  });
  return {
    loading, messages: data?.messagesByConsultation, refetch, subscribeToMore, fetchMore,
  };
}
