import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { StyleSheet } from 'react-native';
import Animated from 'react-native-reanimated';
import { TabBilanSanitaireParamList } from '../../types';
import BilanSanitaireScreen from '../../screens/BilanSanitaireScreen/BilanSanitaireScreen';
import PrebilanScreen from '../../screens/BilanSanitaireScreen/PrebilanScreen';
import PrebilanQuestionnaireScreen from '../../screens/BilanSanitaireScreen/PrebilanQuestionnaireScreen';
import BseHistoriqueScreen from '../../screens/BilanSanitaireScreen/BseHistoriqueScreen';
import DemandeBseScreen from '../../screens/BilanSanitaireScreen/DemandeBseScreen';

import Camera from '../../components/Camera';

const Stack = createStackNavigator<TabBilanSanitaireParamList>();

export default ({ style }) => (
  <Animated.View style={StyleSheet.flatten([{ flex: 1 }, style])}>
    <Stack.Navigator
      initialRouteName="bilan-sanitaire"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="bilan-sanitaire"
        component={BilanSanitaireScreen}
      />
      <Stack.Screen
        name="prebilan"
        component={PrebilanScreen}
      />
      <Stack.Screen
        name="prebilan-questionnaire"
        component={PrebilanQuestionnaireScreen}
      />
      <Stack.Screen
        name="demande-bse"
        component={DemandeBseScreen}
      />
      <Stack.Screen
        name="bse-historique"
        component={BseHistoriqueScreen}
      />
      <Stack.Screen
        name="prebilan-historique"
        component={BseHistoriqueScreen}
      />
      <Stack.Screen
        name="photos"
        component={Camera}
      />
    </Stack.Navigator>
  </Animated.View>
);
