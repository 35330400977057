import { useMutation, useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { DocumentNode } from 'apollo-link';
import { WatchQueryFetchPolicy } from 'apollo-client/core/watchQueryOptions';
import * as mutations from '../graphql/mutations';
import {
  Buildings,
  CreateBuildingsMutation,
  CreateBuildingsMutationVariables,
  DeleteBuildingsMutation,
  DeleteBuildingsMutationVariables,
  GenderType,
  GetBuildingsQueryVariables,
  ListBuildingsQueryVariables,
  UpdateBuildingsMutation,
  UpdateBuildingsMutationVariables,
  Zones,
} from '../API';
import { deleteBuildings } from '../graphql/mutations';

export function useCreateBuildingMutation() {
  const [createBuilding] = useMutation<
  CreateBuildingsMutation, CreateBuildingsMutationVariables
  >(gql(mutations.createBuildings));
  return { createBuilding };
}

const buildingQuery = <DocumentNode>gql(`
  query ListBuildings {
    listBreedings {
      items {
        buildings (limit: 1000) {
          items {
            breedingId
            address {
              address
              postalCode
              additionalAddress
            }
            nameBuilding
            _deleted
            _lastChangedAt
            _version
            createdAt
            id
            updatedAt
            zone {
              nextToken
              startedAt
              items {
                _deleted
                _lastChangedAt
                _version
                buildingId
                createdAt
                id
                updatedAt
                name
                numberZone
              }
            }
          }
        }
      }
    }
  }
`);

export type ListBuildingsQuery = {
  listBreedings?: {
    items: Array< {
      buildings?: {
        __typename: 'ModelBuildingsConnection',
        items?: Array<{
          __typename: 'Buildings',
          id: string,
          nameBuilding: string,
          address?: {
            __typename: 'Address',
            address: string,
            additionalAddress?: string | null,
            postalCode: string,
          } | null,
          breedingId: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          createdAt: string,
          updatedAt: string,
          zone?: {
            __typename: 'ModelZonesConnection',
            items?: Array<Zones | null > | null,
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
        } | null> | null,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    }>,
  }
};

/** useGet Building */
export type GetBuildingsQuery = {
  getBuildings?: {
    __typename: 'Buildings',
    id: string,
    nameBuilding: string,
    address?: {
      __typename: 'Address',
      address: string,
      additionalAddress?: string | null,
      postalCode: string,
    } | null,
    breedingId: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    breeding?: {
      __typename: 'Breeding',
      id: string,
      nBreeding: string,
      nSIRET: string,
      dairyFarm: boolean,
      sucklerCowHusbandry: boolean,
      fatteningFarm: boolean,
      nbCow: number,
      nbHeifer: number,
      nbMale: number,
      nbYoung: number,
      emailVeto?: string | null,
      admins: Array< string >,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      animals?: {
        __typename: 'ModelAnimalsConnection',
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      lots?: {
        __typename: 'ModelLotsConnection',
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?: {
        __typename: 'ModelBuildingsConnection',
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bse?: {
        __typename: 'ModelBSEConnection',
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      documents?: {
        __typename: 'ModelDocumentConnection',
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      pendingInvitations?: {
        __typename: 'ModelPendingInvitationConnection',
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exams?: {
        __typename: 'ModelExamConnection',
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    animals?: {
      __typename: 'ModelAnimalsConnection',
      items?: Array< {
        __typename: 'Animals',
        id: string,
        numberID: number,
        name?: string | null,
        gender?: GenderType | null,
        birthDate?: string | null,
        breedingId: string,
        lotsId?: string | null,
        lots?: {
          __typename: 'Lots',
          id: string,
          numberLot: number,
        }
        zoneId?: string | null,
        buildingId?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    lots?: {
      __typename: 'ModelLotsConnection',
      items?: Array< {
        __typename: 'Lots',
        id: string,
        numberLot: number,
        name?: string | null,
        buildingId?: string | null,
        breedingId: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    zone?: {
      __typename: 'ModelZonesConnection',
      items?: Array< {
        __typename: 'Zones',
        id: string,
        name?: string | null,
        numberZone?: number | null,
        buildingId?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export function useGetBuilding(id: string, fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network') {
  const getBuildingQuery = gql(`
  query GetBuildings($id: ID!) {
    getBuildings(id: $id) {
      id
      nameBuilding
      address {
        address
        additionalAddress
        postalCode
        city
      }
      breedingId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      animals {
        items {
          id
          numberID
          name
          lots {
            id
            numberLot
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
      }
      lots {
        items {
          id
          numberLot
          name
          buildingId
          breedingId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
      }
      zone {
        items {
          id
          name
          numberZone
          buildingId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`);
  if (!id) {
    return { loading: false, building: undefined };
  }
  const { loading, data, refetch } = useQuery<
  GetBuildingsQuery, GetBuildingsQueryVariables
  >(getBuildingQuery, {
    fetchPolicy,
    variables: {
      id,
    },
  });
  // console.log('data useGetBuild API:', data);
  return {
    loading, building: <Buildings>data?.getBuildings, refetch,
  };
}

/** useGet Building List by BREEDING that is   can see in buildingQuery  */
export function useBuildingList(fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network') {
  const { loading, data } = useQuery<
  ListBuildingsQuery, ListBuildingsQueryVariables
  >(buildingQuery, { fetchPolicy });

  // console.log('useBuildingList data:', data);
  if (data) {
    return { loadingBuilding: loading, buildingS: data?.listBreedings?.items[0]?.buildings?.items };
  }
  return { loadingBuilding: loading, buildingS: null };
}

export function useUpdateBuildingMutation() {
  const [updateBuilding, { loading: mutationBuildingLoading }] = useMutation<
  UpdateBuildingsMutation, UpdateBuildingsMutationVariables
  >(gql(mutations.updateBuildings));
  return { updateBuilding, mutationBuildingLoading };
}

export function useDeleteBuildingMutation() {
  const [deleteBuilding] = useMutation<
  DeleteBuildingsMutation, DeleteBuildingsMutationVariables
  >(gql(deleteBuildings));
  return deleteBuilding;
}
