import { LinkProps } from '../../types';

const linkToSwitch = ({
  state, linkTo, id, back,
}: LinkProps) => {
  switch (state) {
    case 'documents':
      linkTo(`/consultation/detail-consultation/${id}/document${back ? `?back=${back}` : ''}`);
      break;
    case 'messagerie':
      linkTo(`/consultation/detail-consultation/${id}/messagerie${back ? `?back=${back}` : ''}`);
      break;
    case 'questionnaire':
      linkTo(`/consultation/detail-consultation/questionnaire${back ? `?back=${back}` : ''}`);
      break;
    case 'details-consultation':
      linkTo(`/consultation/detail-consultation/${id}${back ? `?back=${back}` : ''}`);
      // (Platform.OS === 'web') && (linkTo('/consultation/detail-consultation'));
      break;
    case 'list-disease':
      linkTo('/consultation/questionnaire/list-disease${back ? `?back=${back}` : \'\'}');
      break;

    default:
      break;
  }
};

export default {
  linkToSwitch,
};
