import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Button, Dimensions, InputAccessoryView,
  Platform, StyleSheet, TouchableOpacity, View, Keyboard,
} from 'react-native';
import { Icon, IconProps, Text } from '@ui-kitten/components';
import { TextInputMaskProps } from 'react-native-masked-text';
import { Input } from '../UIKittenRewrite/Input';
import { TextInputFormProps } from './types';
import { AvailableValidationRules } from './validation';
import Formatter from '../../utils/Formatter';

const FTextInput = React.forwardRef<Input, TextInputFormProps>(
  (props: TextInputFormProps, ref): React.ReactElement => {
    const {
      title,
      titleStyle,
      label,
      icon,
      moneySign,
      labelStyle,
      error,
      onChangeValue,
      onChangeText,
      style,
      defaultValue,
      containerStyle,
      withEyeToggle,
      secureTextEntry,
      keyboardType,
      maskOptions,
      validators,
      placeholder,
      showAsterix = true,
      inputAccessoryViewID = 'Terminer',
      appendTitle = (<></>),
      clearStateFunc,
      ...inputProps
    } = props;

    let realDefaultValue: string | number | undefined = defaultValue || inputProps.value;

    if (maskOptions && maskOptions.type === 'money') {
      realDefaultValue = defaultValue
        ? Formatter.baseNumberFormatter.format(parseFloat(defaultValue))
        : defaultValue;
    }

    const [inputValue, setInputValue] = useState<string | number | undefined>(realDefaultValue);
    const [passwdShown, setPasswdShown] = useState(!secureTextEntry);

    if (clearStateFunc) {
      clearStateFunc(() => {
        setInputValue('');
      });
    }

    useEffect(() => {
      setInputValue(realDefaultValue);
    }, [defaultValue, inputProps.value]);

    const renderIcon = (iconProps: IconProps) => (
      <Icon {...iconProps} name={icon} fill="#000" />
    );
    const renderEyeIcon = (iconProps: IconProps) => (
      <TouchableOpacity accessible={false} onPress={() => setPasswdShown(!passwdShown)}><Icon {...iconProps} name={passwdShown ? 'eye-off-outline' : 'eye-outline'} /></TouchableOpacity>
    );

    let finalIcon;

    if (withEyeToggle && secureTextEntry) {
      finalIcon = renderEyeIcon;
    } else if (icon) {
      finalIcon = renderIcon;
    }

    let additionalProps: {
      withMask?: true,
      includeRawValueInChangeText?: true,
      maskOptions?: TextInputMaskProps,
    } = {};
    if (maskOptions) {
      additionalProps = {
        withMask: true,
        includeRawValueInChangeText: true,
        maskOptions: {
          ...maskOptions,
          onChangeText: (text, rawText) => {
            setInputValue(text);
            if (onChangeValue) {
              onChangeValue((maskOptions.type !== 'datetime') ? (maskOptions.options?.unit === '- ' && rawText ? -rawText : rawText) : text);
            }
          },
        },
      };
    }

    const required = ((showAsterix && validators && validators.indexOf(AvailableValidationRules.required) > -1) ? ' *' : '');

    return (
      <View style={[styles.container, containerStyle, title ? ({ flexDirection: 'column' }) : ({ flexDirection: 'row' })]}>
        {title && (
        <View style={{ flexDirection: 'row' }}>
          <Text category="h5" style={[{ marginBottom: 5 }, titleStyle]}>{title + required}</Text>
          {appendTitle}
        </View>
        )}
        <View style={{ flex: 1, flexDirection: 'row' }}>
          <Input
            autoCapitalize="none"
            ref={ref}
            label={label ? label + required : label}
            keyboardType={keyboardType || 'default'}
            accessoryRight={finalIcon}
            inputAccessoryViewID={inputAccessoryViewID}
            placeholder={(placeholder || '') + (!label ? required : '')}
            style={[styles.input, style, ({ flex: 1 })]}
            caption={error && error.message}
            status={error && error.message ? 'danger' : 'default'}
            {...inputProps}
            secureTextEntry={!passwdShown}
            size="medium"
            onChangeText={(text) => {
              let finalText = text;
              if (keyboardType === 'numeric') {
                finalText = text.replace(',', '.');
              }
              setInputValue(finalText);
              if (onChangeValue) {
                onChangeValue(finalText);
              }
            }}
            {...additionalProps}
            value={inputValue?.toString()}
          />
          {moneySign && (
            <Text category="h2" style={{ alignSelf: 'center', marginLeft: 10 }}>€</Text>
          )}
        </View>
        {Platform.OS === 'ios' && keyboardType === 'numeric' && (
          <InputAccessoryView nativeID={inputAccessoryViewID}>
            <View style={styles.accessory}>
              <Button
                onPress={Keyboard.dismiss}
                title="Terminer"
              />
            </View>
          </InputAccessoryView>
        )}
      </View>
    );
  },
);

FTextInput.displayName = 'TextInput';

export default FTextInput;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // marginVertical: 8,
    flexDirection: 'row',
    marginBottom: 15,
  },
  input: {
    flex: 1,
  },
  accessory: {
    width: Dimensions.get('window').width,
    height: 48,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: '#F8F8F8',
    paddingHorizontal: 8,
  },
});
