import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';

import { StyleSheet } from 'react-native';
import Animated from 'react-native-reanimated';

import AccMonCompteScreen from '../../screens/MonCompteScreen/AccMonCompteScreen';
import { TabMonCompteParamList } from '../../types';
import ModifierInfoScreen from '../../screens/MonCompteScreen/ModifierInfoScreen';
import UtilisateursScreen from '../../screens/MonCompteScreen/UtilisateursScreen';
import ModePaiementScreen from '../../screens/MonCompteScreen/ModePaiementScreen';
import FacturesScreen from '../../screens/FacturesScreen';
import AjoutUtilisateur from '../../screens/MonCompteScreen/AjoutUtilisateurScreen';
import ModifyPasswordScreen from '../../screens/AccountScreens/ModifyPasswordScreen';
import ModifyEmailScreen from '../../screens/AccountScreens/ModifyEmailScreen';
import Verification from '../../screens/AccountScreens/Verification';
import ModifyInfoPerso from '../../screens/AccountScreens/ModifyInfoPerso';

const Stack = createStackNavigator<TabMonCompteParamList>();

type AnimatedStyleProps = {

};

const MonCompteStackNavigator = ({ style }: AnimatedStyleProps) => (
  <Animated.View style={StyleSheet.flatten([{ flex: 1 }, style])}>
    <Stack.Navigator
      initialRouteName="mon-compte"
      screenOptions={{ headerShown: false }}
    >
      <Stack.Screen
        name="mon-compte"
        component={AccMonCompteScreen}
      />

      <Stack.Screen
        name="modify-info-perso"
        component={ModifyInfoPerso}
      />
      <Stack.Screen
        name="modify-password"
        component={ModifyPasswordScreen}
      />
      <Stack.Screen
        name="modify-email"
        component={ModifyEmailScreen}
      />
      <Stack.Screen
        name="verification"
        component={Verification}
      />
      <Stack.Screen
        name="modifier-compte-info"
        component={ModifierInfoScreen}
      />
      <Stack.Screen
        name="utilisateurs"
        component={UtilisateursScreen}
      />
      <Stack.Screen
        name="ajout-utilisateur"
        component={AjoutUtilisateur}
      />
      <Stack.Screen
        name="modify-utilisateur"
        component={AjoutUtilisateur}
      />
      <Stack.Screen
        name="mode-paiement"
        component={ModePaiementScreen}
      />
      <Stack.Screen
        name="factures"
        component={FacturesScreen}
      />

    </Stack.Navigator>
  </Animated.View>
);

export default MonCompteStackNavigator;

// const styles = StyleSheet.create({ });
