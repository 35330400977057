import { Platform, View } from 'react-native';
import React from 'react';

import { Text, useTheme } from '@ui-kitten/components';
import moment from 'moment';
import { useDimensions } from '@react-native-community/hooks';
import RejoindreButton from '../../components/RejoindreButton';
import FCard from '../../components/Card';

import ScreenIcon from '../../assets/svg_icones_ScanFlock/WHITE/whiteScreenIcon.svg';
import PaperPenIcon from '../../assets/svg_icones_ScanFlock/WHITE/whitePaperIcon.svg';
import ThinSeparator from '../../components/ThinSeparator';

import Doctor from '../../assets/svg_icones_ScanFlock/WHITE/greenDoctorIcon.svg';
import { useUser } from '../../src/API/UserContext';
// import { useConsultationList } from '../../src/API/Consultation';
import DateUtils from '../../utils/DateUtils';
import { useConsultationList, useConsultationListVeto } from '../../src/API/Consultation';

const DoctorIcon = () => <Doctor height={40} width={40} fill="white" />;
const TeleIcon = () => <ScreenIcon height={40} width={40} fill="white" />;
const BseIcon = () => <PaperPenIcon height={40} width={40} fill="white" />;

type TdbHeaderProps = {
  veto?: boolean;
  elevagePage?: string;
  linkTo?: () => void
};

moment.locale('fr');

const TableauDeBordHeaderCard = ({ veto, elevagePage, linkTo }: TdbHeaderProps) => {
  const theme = useTheme();
  const { user } = useUser();
  const { window } = useDimensions();
  const { consultationS } = veto ? useConsultationListVeto() : useConsultationList();

  // console.log('consultationS', consultationS);

  // data.firstname.substr(0, 1).toUpperCase() + data.firstname.substr(1)
  // {DateUtils.today.substr(0, 1).toUpperCase() + DateUtils.today.substr(1)}

  const teleconsEnCours = DateUtils.verificationDateTelecons('enCours', consultationS);

  const teleconsultationDemande = DateUtils.verificationDateTelecons('aPlanifiers', consultationS);

  const teleconsultationProchaines = DateUtils.verificationDateTelecons('aVenirs', consultationS);

  const consultationShared = consultationS?.filter((consultation) => {
    if (consultation?.shared === true) {
      return true;
    }
    return false;
  });
  // console.log('tele:', teleconsultationProchaines, teleconsultationDemande, teleconsEnCours);
  // console.log('consultationS:', consultationShared?.length);
  return (
    <>

      <FCard style={{
        paddingHorizontal: 0,
        marginBottom: 30,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 25,
        borderBottomRightRadius: 25,
        paddingBottom: 0,
      }}
      >
        <View style={{
          paddingHorizontal: 20, paddingTop: 25, borderBottomWidth: 1, borderBottomColor: '#b5b5b5', paddingBottom: 10,
        }}
        >
          <Text category="s1" appearance="hint">Bonjour !</Text>
          <Text style={{ fontSize: 39, fontFamily: 'sourceSansPro_Bold' }}>{`${user?.firstname || undefined} ${user?.lastname || undefined}`}</Text>
          <Text category="h2" appearance="hint" style={{ marginVertical: 20 }}>Votre programme</Text>
          <Text category="h3">{DateUtils.today}</Text>
        </View>

        <View style={window.width > 690 && elevagePage !== 'elevagePage' && ({ flexDirection: 'row', alignItems: 'center' })}>
          <View style={window.width > 690 && elevagePage !== 'elevagePage' && ({ flex: 1, flexShrink:1, flexGrow:1, borderRightWidth: 1, borderRightColor: '#b5b5b5' })}>
            {(elevagePage !== 'elevagePage') && (
              <>
                <View style={{
                  flexDirection: 'row', alignItems: 'center', paddingHorizontal: 20, paddingTop: 20,
                }}
                >
                  <View style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 115,
                    height: 115,
                    backgroundColor: theme['color-primary-800'],
                    borderRadius: 15,
                    padding: 10,
                  }}
                  >
                    <DoctorIcon />
                    <Text category="c1" style={{ textAlign: 'center' }} appearance="alternative">Consultations</Text>
                  </View>
                  <Text
                    category="h5"
                    style={{
                      justifyContent: 'center', alignItems: 'center', marginLeft: 20, width: 200, flex:1
                    }}
                  >
                    {consultationShared?.length > 0 ? consultationShared?.length : '0'}
                    {' '}
                    partagée(s) avec votre cabinet
                  </Text>
                </View>

                <ThinSeparator />
              </>
            )}

            <View
              style={[
                { flexDirection: 'row', alignItems: 'center', paddingHorizontal: 20 },
                elevagePage !== 'elevagePage' && window.width > 690 && ({ paddingBottom: 20 }),
                elevagePage === 'elevagePage' && ({ marginTop: 20 }),
              ]}
            >
              <View style={{
                justifyContent: 'center',
                alignItems: 'center',
                width: 115,
                height: 115,
                backgroundColor: theme['color-primary-800'],
                borderRadius: 15,
                padding: 10,
              }}
              >
                <BseIcon />
                <Text category="c1" style={{ textAlign: 'center' }} appearance="alternative">BSE</Text>
              </View>
              <View style={{ flex: 1, width: 180 }}>
                <Text
                  category="h5"
                  style={{
                    justifyContent: 'center', alignItems: 'center', marginLeft: 10, marginBottom: 20,
                  }}
                >
                  0 demande effectuée
                </Text>
                <Text
                  category="h5"
                  style={{
                    justifyContent: 'center', alignItems: 'center', marginLeft: 10,
                  }}
                >
                  0 prévu aujourd'hui
                </Text>
              </View>

            </View>
            {elevagePage === 'elevagePage' && (<ThinSeparator />)}
            {elevagePage !== 'elevagePage' && window.width < 691 && (<ThinSeparator />)}

          </View>
          <View style={{
            flexDirection: 'row', alignItems: 'center', paddingHorizontal: 20, paddingBottom: 20, flex:1, flexGrow:1
          }}
          >
            <View style={{
              justifyContent: 'center',
              alignItems: 'center',
              width: 115,
              height: 115,
              backgroundColor: theme['color-primary-800'],
              borderRadius: 15,
              padding: 5,
            }}
            >
              <TeleIcon />
              <Text category="c1" appearance="alternative" style={{ textAlign: 'center' }}>Téléconsultation</Text>
            </View>
            <View style={{ flex: 1, marginLeft: 15 }}>

              {teleconsEnCours && teleconsEnCours.length > 0
                ? (
                  <>
                    <Text
                      category="h5"
                      style={{
                        marginRight: 5, marginBottom: 20, flexWrap: 'nowrap',
                      }}
                    >
                      {`${teleconsEnCours.length || '0'} en cours`}
                    </Text>
                    <RejoindreButton veto={veto} consultation={teleconsEnCours[0]} />
                  </>
                )
                : (<></>)}

              <Text category="h5" style={{ marginBottom: 20, }}>
                {`${teleconsultationProchaines?.length || 0} prévue(s)`}
              </Text>

              <Text category="h5" >
                {veto ? `${(teleconsultationDemande && teleconsultationDemande.length > 0)
                  ? teleconsultationDemande.length : '0'} demande(s) à planifier` : `${(teleconsultationDemande && teleconsultationDemande.length > 0)
                  ? teleconsultationDemande.length : '0'} demande(s) en attente de planification`}
              </Text>

            </View>
          </View>

        </View>

      </FCard>
    </>
  );
};

export default TableauDeBordHeaderCard;
