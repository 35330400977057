/**
 * Custom drawer navigation
 *
 * @author: David BUCH, Shynggys UMBETOV
 */
import React from 'react';
import {
  Drawer, DrawerItem, IndexPath, Layout, Text,
  Icon as IconUIKitten,
} from '@ui-kitten/components';

import {
  ImageProps, Platform, TouchableOpacity, View,
} from 'react-native';
import {
  InitialState,
} from '@react-navigation/native';
// import Auth from '@aws-amplify_old/auth';
import { DrawerContentComponentProps } from '@react-navigation/drawer/src/types';
import { RenderProp } from '@ui-kitten/components/devsupport';
// import { DrawerContentScrollView } from '@react-navigation/drawer';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
// import { useDimensions } from '@react-native-community/hooks';
// import * as WebBrowser from 'expo-web-browser';
import Auth from '@aws-amplify/auth';

import Icon, { IconName } from '../components/Icon/Icon';
import Cow from '../assets/svg_icones_ScanFlock/GREEN/greenCowIcon.svg';
import AcceuilIcon from '../assets/svg_icones_ScanFlock/GREEN/greenSquareIcon.svg';
import Bell from '../assets/svg_icones_ScanFlock/notifications.svg';
import Person from '../assets/svg_icones_ScanFlock/moncompte.svg';
import Faq from '../assets/svg_icones_ScanFlock/faq.svg';
import Doctor from '../assets/svg_icones_ScanFlock/GREEN/greenDoctorIcon.svg';
import CameraSvg from '../assets/svg_icones_ScanFlock/GREEN/greenCameraIcon.svg';
import PaperPenIcon from '../assets/svg_icones_ScanFlock/GREEN/greenPaperPenIcon.svg';
import { useUser } from '../src/API/UserContext';
import AutoAvatar from '../components/AutoAvatar';
import { useResetLinkTo } from './Utils';
import LinkingConfiguration from './LinkingConfiguration';

/**
 * 2. Icons
 */
// eslint-disable-next-line max-len
type IconProps = {
  name: IconName, uikitten?: false
} | {
  name: string, uikitten: true
};

// eslint-disable-next-line max-len
const IconGenerator = ({ name, uikitten }: IconProps): RenderProp<Partial<ImageProps>> => (props?: Partial<ImageProps>) => {
  let width;
  let color;
  if (props) {
    const { style } = props;
    if (style) {
      // @ts-ignore : Dans le cas de UI KItten ce sera une ImageProps
      width = style.width;
      // @ts-ignore : Dans le cas de UI KItten ce sera une ImageProps
      color = style.tintColor;
    }
  }
  if (uikitten) {
    return (
      <IconUIKitten
        name={name}
        fill={color}
              // @ts-ignore : Dans le cas de UI KItten ce sera une ImageProps
        style={{ width, height: props?.style?.height }}
      />
    );
  }

  /* Dans le cas de Icomoon on à uikitten === false ou undefined (cf. type)
        @ts-expect-error */
  return <Icon name={name} size={width} color={color} />;
};

const HomeIcon = () => <AcceuilIcon height={20} width={20} fill="#1C6B79" />;
const BellIcon = () => <Bell height={20} width={20} fill="#1C6B79" />;
const PersonIcon = () => <Person height={20} width={20} fill="#1C6B79" />;
const FaqIcon = () => <Faq height={20} width={20} fill="#1C6B79" />;
const CowIcon = () => <Cow height={20} width={20} fill="#1C6B79" />;
const DoctorIcon = () => <Doctor height={20} width={20} fill="#1C6B79" />;
const CameraIcon = () => <CameraSvg height={20} width={20} fill="#1C6B79" />;
const BseIcon = () => <PaperPenIcon height={20} width={20} fill="#1C6B79" />;

// const EmailIcon = IconGenerator({ name: 'email-outline', uikitten: true });
// const CadenaIcon = IconGenerator({ name: 'lock-outline', uikitten: true });

function findIndexByRouteName(name?: string) {
  const toAdd = Platform.OS !== 'web' ? 0 : 1;
  switch (name) {
    case 'tableau-de-bord-nav':
      return 0;
    case 'mon-compte-nav':
      return 1;
    case 'elevage-nav':
      return 2;
    case 'consultation-nav':
      return 3;
    case 'photo':
      return 4 - toAdd;
    case 'bilan-sanitaire-nav':
      return 5 - toAdd;
    case 'notifications-nav':
      return 6 - toAdd;
    case 'faq':
      return 7 - toAdd;
    default:
      return null;
  }
}

function findFocusedDrawerItem(state: InitialState) {
  let current: InitialState | undefined = state;

  while (current?.routes[current.index ?? 0].state != null) {
    const drawerIndex = findIndexByRouteName(current?.routes[current.index ?? 0].name);
    if (drawerIndex !== null) {
      return drawerIndex;
    }
    current = current.routes[current.index ?? 0].state;
  }

  const drawerIndex = findIndexByRouteName(current?.routes[current.index ?? 0].name);
  if (drawerIndex !== null) {
    return drawerIndex;
  }

  return 0;
}

/**
 * 3. Custom Drawer itself
 */
const CustomDrawer = (props: DrawerContentComponentProps) => {
  const { state } = props;
  // console.log('custom drawer state:', state);
  const { user } = useUser();
  // const theme = useTheme();
  // const countNotification = useCountUnseenNotification();
  const inset = useSafeAreaInsets();
  const linkTo = useResetLinkTo(LinkingConfiguration.config);
  // const { window } = useDimensions();

  // const navigation = useNavigation();

  /**
  const closeDrawer = React.useCallback(
    debounce(() => {
      navigation.dispatch(DrawerActions.closeDrawer());
    }, 50),
    [navigation],
  );
  */
  return (
    <View style={{ flex: 1, marginTop: inset.top, marginBottom: inset.bottom }}>
      <Layout level="4" style={{ flex: 1, justifyContent: 'space-between' }}>

        <Layout
          level="4"
          style={{
            margin: 24,
            marginHorizontal: 21,
            flexDirection: 'row',
          }}
        >
          <AutoAvatar
            // source={require('../assets/test.png')}
            style={{
              height: 41,
              width: 41,
              borderRadius: 25,
              overflow: 'hidden',
              marginRight: 2,
              // marginLeft: Platform.OS === 'android' ? 0 : 8.5,
              marginLeft: 0,
            }}
            turnOff={false}
            avatarInfo={user?.avatarUri || 'default::ManAvatar'}
          />
          <Text category="h6" appearance="hint" style={{ marginLeft: 5, marginTop: 11 }}>
            {user?.firstname || undefined}
          </Text>
        </Layout>
        <Drawer
          selectedIndex={new IndexPath(findFocusedDrawerItem(state))}
          onSelect={(index) => {
            // console.log(index);
            const toAdd = Platform.OS !== 'web' ? 0 : 1;
            // eslint-disable-next-line default-case
            switch (index.row) {
              case 0:
                linkTo('/tableau-de-bord');
                break;
              case 1:
                linkTo('/mon-compte');
                break;
              case 2:
                linkTo('/elevage');
                break;
              case 3:
                linkTo('/consultation');
                break;
              case 4 - toAdd:
                linkTo('/photo');
                break;
              case 5 - toAdd:
                linkTo('/bilan-sanitaire');
                break;
              case 6 - toAdd:
                linkTo('/notifications');
                break;
              case 7 - toAdd:
                linkTo('/faq');
                break;
            }
          }}
        >
          <DrawerItem
            title="Tableau de bord"
            accessoryLeft={HomeIcon}
          />
          <DrawerItem
            title="Mon Compte"
            accessoryLeft={PersonIcon}
          />
          <DrawerItem
            title="Elevage"
            accessoryLeft={CowIcon}
          />
          <DrawerItem
            title="Consultations"
            accessoryLeft={DoctorIcon}
          />
          {Platform.OS !== 'web' && (
            <DrawerItem
              title="Photos"
              accessoryLeft={CameraIcon}
            />
          )}
          <DrawerItem
            title="Bilan Sanitaire d'Elevage"
            accessoryLeft={BseIcon}
          />
          <DrawerItem
            title="Notifications"
            accessoryLeft={BellIcon}
          />
          <DrawerItem
            title="Foire aux questions"
            accessoryLeft={FaqIcon}
          />

        </Drawer>
      </Layout>

      <TouchableOpacity
        onPress={() => { Auth.signOut(); }}
        style={{
          paddingHorizontal: 26, marginBottom: 18 + inset.bottom,
        }}
      >
        <Text category="h5" style={{ textDecorationLine: 'underline' }}>Déconnexion</Text>
      </TouchableOpacity>

    </View>
  );
};

export default CustomDrawer;
