import React from 'react';

import { Text, useTheme } from '@ui-kitten/components';
import { useLinkTo } from '@react-navigation/native';
import { Platform, View } from 'react-native';
import MaxWidthContainer from '../../../components/MaxWidthContainer';

import Card from '../../../components/Card';
import Button from '../../../components/Button';
import VetoMonCompteScreenBL from '../../../screenBusinessLogic/vetoScreensBL/MonCabinetScreenBL/VetoMonCompteScreenBL';
import { useUser } from '../../../src/API/UserContext';
import AutoAvatar from '../../../components/AutoAvatar';
import { useGetVetOffice } from '../../../src/API/VetOffice';

const VetoMonCompteScreen = () => {
  const linkTo = useLinkTo();
  const theme = useTheme();
  const { user } = useUser();
  const { vetOfficeData } = useGetVetOffice();

  // console.log('user VetoMonCompteScreen:', user);
  // console.log('vetOfficeData VetoMonCompteScreen:', vetOfficeData);

  return (
    <MaxWidthContainer
      withScrollView="keyboardAware"
      outerViewProps={{
        style: {
          padding: 20,
          marginBottom: 20,
        },
      }}
    >

      <View style={{ alignItems: 'center' }}>
        <AutoAvatar
            // source={require('../assets/test.png')}
          style={{
            height: 94,
            width: 94,
            marginBottom: 10,
            borderRadius: 50,
            overflow: 'hidden',
            marginRight: 12,
            marginLeft: 0,
          }}
          avatarInfo={user?.avatarUri || 'default::ManAvatar'}
        />
      </View>

      <Card style={{ marginTop: 20 }}>

        <View style={Platform.OS === 'web' && ({ flexDirection: 'row' })}>
          <View style={{ flex: 1, paddingBottom: 10 }}>
            <Text category="h6" appearance="hint">Nom</Text>
            <Text category="h4">{`${user?.firstname || undefined} ${user?.lastname || undefined}`}</Text>
          </View>
          <View style={[
            { flex: 1, paddingVertical: 11 },
            Platform.OS !== 'web' && ({ borderTopWidth: 1, borderTopColor: '#b5b5b5' }),
          ]}
          >
            <Text category="h6" appearance="hint">E-mail</Text>
            <Text category="h4">{`${user?.email || undefined}`}</Text>
          </View>
        </View>

        <View style={{ paddingTop: 11, borderTopWidth: 1, borderTopColor: '#b5b5b5' }}>
          <Text category="h6" appearance="hint">Fonction</Text>
          <Text category="h4">
            {
              `${vetOfficeData && vetOfficeData.admins && vetOfficeData.admins.includes(user?.id) ? 'Vétérinaire' : 'Personnel'}`
          }
          </Text>
        </View>
      </Card>

      <Button
        leftIcon="edit-outline"
        leftIconFill={theme['color-info-500']}
        appearance="outline"
        style={{ marginTop: 20 }}
        onPress={() => VetoMonCompteScreenBL.allerModifierMonCompteScreen({ state: 'modifier-mon-compte', linkTo })}
      >
        Modifier les informations
      </Button>

      <Button status="danger" appearance="outline" style={{ marginTop: 40, backgroundColor: 'white' }}>Supprimer mon compte</Button>

    </MaxWidthContainer>
  );
};

export default VetoMonCompteScreen;
