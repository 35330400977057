import React, { useState } from 'react';
import { ImagePickerResult } from 'expo-image-picker';
import Camera from '../../components/Camera';
import Button from '../../components/Button';
import InfoCard from '../../screenComponents/InfoCard';
import MaxWidthContainer from '../../components/MaxWidthContainer';
import { useUser } from '../../src/API/UserContext';
import { Upload } from '../../utils/S3FileStorage';
import { CameraOutput } from '../../components/Camera/Camera';
import { useBreedingList } from '../../src/API/Breeding';
import CustomModal from '../../components/Modals/CustomModal';
import BaseModal from '../../components/Modals/BaseModal';
import Tutorial from '../../components/Tutorial';

const PhotoScreen = () => {
  const { user } = useUser();

  const [camera, setCamera] = useState(false);
  const [modal, setModal] = useState<'share' | 'confirm' | undefined>(undefined);
  const [image, setImage] = useState<ImagePickerResult | CameraOutput | undefined>(undefined);
  const { breeding } = useBreedingList(user?.userGroup);

  const saveImage = async (result: ImagePickerResult | CameraOutput | undefined) => {
    if (result) {
      const upload = await Upload(result, `photoCollect/${breeding?.id}/`);
      if (upload !== false) {
        setModal('confirm');
      }
    }
  };
  return (
    <>
      <MaxWidthContainer
            // withScrollView={false}
        outerViewProps={{
          style: {
            // flex: 1,
            padding: 24,
          },
        }}
      >
        <Tutorial
          videoId="mZAJbdC9lDM"
        />
        <InfoCard
          title="Bienvenue sur l'espace Photos"
          iconShow="alert-circle-outline"
                // iconCloseShow="close-outline"
          description="Vous pouvez faire parvenir des photos à notre application afin de participer à notre évolution pour votre confort. Notre objectif est de constituer la base de données, préalable à la fonctionnalité de reconnaissance d’images.
Photographiez tant que possible symptômes et lésions !
Vous pouvez également partager les photos prises en cours d’auto-examen avec votre véto."
          alertInfo="alertInfo"
        />

        <Button onPress={() => { setCamera(true); }}>Accéder à mon appareil photo</Button>

      </MaxWidthContainer>
      <BaseModal
        visible={camera}
        onClose={() => setCamera(false)}
        animationType="none"
        style={{ overflow: 'hidden', alignItems: 'center', backgroundColor: 'black' }}
      >
        <Camera
          onClose={() => { setCamera(false); }}
          onChoose={(result) => {
            setCamera(false);
            setImage(result);
            setModal('share');
          }}
          withPreview
          ratio={[1, 1]}
          maxWidth={1200}
        />
      </BaseModal>
      <CustomModal
        title="Vous êtes sur le point de faire parvenir une photo à notre application."
        description="Pour en savoir plus sur l'utilité de cette fonctionalité, consultez notre FAQ."
        subtitle="Souhaitez-vous continuer ?"
        before={<></>}
        noSafeArea
        visible={modal === 'share'}
        scrollable={false}
        onClose={() => setModal(undefined)}
        insideModalStyle={{ textAlign: 'center', padding: 20 }}
        buttonText="Confirmer l'envoi de la photo"
        secondButtonText="Annuler"
        heightPercentage={0.45}
        buttonPress={() => {
          saveImage(image);
        }}
      />
      <CustomModal
        title="Votre photo a été partagée avec succès !"
        description="Merci de votre contribution."
        before={<></>}
        noSafeArea
        visible={modal === 'confirm'}
        scrollable={false}
        onClose={() => setModal(undefined)}
        insideModalStyle={{ textAlign: 'center', padding: 20 }}
        buttonText="Continuer"
        heightPercentage={0.45}
        buttonPress={() => {
          setModal(undefined);
        }}
      />
    </>
  );
};

export default PhotoScreen;
