import { StyleSheet } from 'react-native';

export const AuthStyles = StyleSheet.create({
  input: {
    marginTop: 16,
  },
  button: {
    marginTop: 16,
    marginHorizontal: 8,
  },
  header: {
    marginTop: 29,
  },
});

export default AuthStyles;
