import React, { useState } from 'react';

import { Text } from '@ui-kitten/components';
import { TouchableOpacity, View } from 'react-native';
import { Icon as IconUIKitten } from '@ui-kitten/components/ui/icon/icon.component';
import MaxWidthContainer from '../../../components/MaxWidthContainer';
import InfoCard from '../../../screenComponents/InfoCard';
import SearchComponent from '../../../screenComponents/SearchComponent';
import TeleconsultationCard from '../../../components/TeleconsultationCard';
import { useBreedingList } from '../../../src/API/Breeding';
import { Consultation } from '../../../src/API';
import { useUser } from '../../../src/API/UserContext';
import RotatingIcon from '../../../components/Icon/RotatingIcon';
import Tutorial from '../../../components/Tutorial';

const VetoExamenScreen = () => {
  const { user } = useUser();
  const { breedings } = useBreedingList(user?.userGroup);
  const [opened, setOpened] = useState(false);
  const consultations:[Consultation][] = [];
  // console.log('br:', breedings);

  if (breedings) {
    breedings.map(
      (item) => item && item.consultations
          && item.consultations.items && item.consultations.items.map(
        (item2) => {
          if (item2 && item2.shared) consultations.push(item2);
          return () => {};
        },
      ),
    );
  }
  return (
    <MaxWidthContainer
      withScrollView="keyboardAware"
      outerViewProps={{
        style: {
          padding: 20,
        },
      }}
    >
      <Tutorial
        videoId="CHkaxyT6AKI"
      />
      <InfoCard
        title="Bienvenue sur votre espace dédié aux Auto-examens"
        iconShow="alert-circle-outline"
        // iconCloseShow="close-outline"
        description={'Vous pouvez accéder aux détails des derniers auto-examens partagés avec votre cabinet. Une messagerie mettant en relation les utilisateurs du cabinet et de l\'élevage est présente pour chaque auto-examen. Vous serez notifié à chaque nouveau message. Les auto-examens pour lesquels une téléconsultation a été demandée ou planifiée sont assortis de la mention « Téléconsultation ». Lorsqu\'une téléconsultation est en cours, vous pouvez la rejoindre depuis cet espace.'}
        alertInfo="alertInfo"
      />

      <Text category="h2" appearance="hint" style={{ marginTop: 20, marginBottom: 10 }}>Derniers auto-examens</Text>
      <TouchableOpacity style={{ flexDirection: 'row', justifyContent: 'space-between', marginVertical: 10 }} onPress={() => setOpened(!opened)}>
        <View style={{ flexDirection: 'row' }}>
          <IconUIKitten
            name="options-outline"
            fill="#000000"
            style={{
              height: 20, width: 20, marginRight: 5,
            }}
          />
          <Text>Filtrer</Text>
        </View>

        <RotatingIcon name="arrow-ios-downward-outline" uikitten state={opened} width={20} height={20} fill="#000" />
      </TouchableOpacity>
      <SearchComponent
        state="consultations"
        opened={opened}
        searchButton
        data={consultations}
        keyExtractor={(item) => item.id}
        placeholder="Rechercher un auto-examen"
        renderItem={(item) => (
          <TeleconsultationCard
            consultation="consultationCreated"
            consultationData={item.item}
            detailConsultationPage={false}
          />
        )}
      />

    </MaxWidthContainer>
  );
};

export default VetoExamenScreen;
