/**
 * Custom drawer navigation
 *
 * @author: David BUCH, Shynggys UMBETOV
 */

import React from 'react';
import {
  Drawer, DrawerItem, IndexPath, Layout, Text,
} from '@ui-kitten/components';
import {
  TouchableOpacity, View,
} from 'react-native';
import {
  InitialState,
} from '@react-navigation/native';

import { DrawerContentComponentProps } from '@react-navigation/drawer/src/types';

import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Auth from '@aws-amplify/auth';
import Cow from '../assets/svg_icones_ScanFlock/BLUE/cow.svg';
import AcceuilIcon from '../assets/svg_icones_ScanFlock/BLUE/square.svg';
import Doctor from '../assets/svg_icones_ScanFlock/BLUE/doctor.svg';
import VetoPerson from '../assets/svg_icones_ScanFlock/BLUE/vetoPerson.svg';
import PaperPenIcon from '../assets/svg_icones_ScanFlock/GREEN/greenPaperPenIcon.svg';
import { useUser } from '../src/API/UserContext';
import AutoAvatar from '../components/AutoAvatar';
import { useResetLinkTo } from './Utils';
import AdminLinkingConfiguration from './AdminLinkingConfiguration';

/**
 * 2. Icons
 */
const HomeIcon = () => (<AcceuilIcon height={20} width={20} fill="#264385" />);
const CowIcon = () => (<Cow height={20} width={20} fill="#264385" />);
const ScopeIcon = () => (<Doctor height={20} width={20} fill="#264385" />);
const DoctorIcon = () => (<VetoPerson height={20} width={20} fill="#264385" />);
const PaperPen = () => (<PaperPenIcon height={20} width={20} fill="#264385" />);

function findIndexByRouteName(name?: string) {
  switch (name) {
    case 'admin-tableau-de-bord':
      return 0;
    case 'admin-elevages':
      return 1;
    case 'admin-vetos':
      return 2;
    case 'admin-diseases':
      return 3;
    case 'admin-ps':
      return 4;
    case 'admin-factures':
      return 5;
    default:
      return null;
  }
}

function findFocusedDrawerItem(state: InitialState) {
  let current: InitialState | undefined = state;

  while (current?.routes[current.index ?? 0].state != null) {
    const drawerIndex = findIndexByRouteName(current?.routes[current.index ?? 0].name);
    if (drawerIndex !== null) {
      return drawerIndex;
    }
    current = current.routes[current.index ?? 0].state;
  }

  const drawerIndex = findIndexByRouteName(current?.routes[current.index ?? 0].name);
  if (drawerIndex !== null) {
    return drawerIndex;
  }

  return 0;
}

/**
 * 3. Custom Drawer itself
 */
const AdminCustomDrawer = (props: DrawerContentComponentProps) => {
  const { state } = props;
  const { user } = useUser();
  const inset = useSafeAreaInsets();
  const linkTo = useResetLinkTo(AdminLinkingConfiguration.config);

  return (
    <View style={{ flex: 1, marginTop: inset.top, marginBottom: inset.bottom }}>
      <Layout level="4" style={{ flex: 1, justifyContent: 'space-between' }}>

        <Layout
          level="4"
          style={{
            margin: 24,
            marginBottom: 10,
            marginHorizontal: 21,
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <AutoAvatar
            style={{
              height: 41,
              width: 41,
              borderRadius: 21,
              overflow: 'hidden',
              marginRight: 18,
              marginLeft: 0,
            }}
            avatarInfo={user?.avatarUri || 'default::ManAvatar'}
          />
          <Text category="h6" appearance="hint" style={{ marginLeft: 10 }}>
            {user?.firstname || 'will appear soon'}
          </Text>
        </Layout>

        <Drawer
          selectedIndex={new IndexPath(findFocusedDrawerItem(state))}
          onSelect={(index) => {
            // console.log(index);
            // const toAdd = window.width > 780 ? 0 : 1;
            // eslint-disable-next-line default-case
            switch (index.row) {
              case 0:
                linkTo('/admin-tableau-de-bord');
                break;
              case 1:
                linkTo('/admin-elevages');
                break;
              case 2:
                linkTo('/admin-vetos');
                break;
              case 3:
                linkTo('/admin-diseases');
                break;
              case 4:
                linkTo('/admin-ps');
                break;
              case 5:
                linkTo('/admin-factures');
                break;
            }
          }}
        >
          <DrawerItem
            title="Tableau de bord"
            accessoryLeft={HomeIcon}
          />
          <DrawerItem
            title="Elevages"
            accessoryLeft={CowIcon}
          />
          <DrawerItem
            title="Vétérinaires"
            accessoryLeft={DoctorIcon}
          />
          <DrawerItem
            title="Monographies"
            accessoryLeft={ScopeIcon}
          />
          <DrawerItem
            title="Protocoles de soins"
            accessoryLeft={ScopeIcon}
          />
          <DrawerItem
            title="Factures"
            accessoryLeft={PaperPen}
          />
        </Drawer>
      </Layout>

      <TouchableOpacity
        onPress={() => { Auth.signOut(); }}
        style={{
          paddingHorizontal: 26, marginBottom: 18 + inset.bottom,
        }}
      >
        <Text category="h5" status="danger" style={{ textDecorationLine: 'underline' }}>Déconnexion</Text>
      </TouchableOpacity>

    </View>
  );
};

export default AdminCustomDrawer;
