import { Text, useTheme } from '@ui-kitten/components';
import { View } from 'react-native';

import React from 'react';
import moment from 'moment';
import PaperPenIcon from '../assets/svg_icones_ScanFlock/BLACK/blackPaperPenIcon.svg';
import Button from './Button';
import FCard from './Card';
import { Breeding, BSE, StatusBse } from '../src/API';
import { useBseListByBreedingId } from '../src/API/BSE';

export type ElevageCardProps = {
  linkTo?: () => void;
  breeding: Breeding
};

const renderBse = (bse?: BSE | null) => {
  if (bse) {
    const diff = moment().diff(moment(bse.dateBSE), 'days');
    if (bse.StatusBse === StatusBse.BSEFinish) {
      if (diff > 365) {
        return (
          <Button
            disabled
            leftIcon="alert-circle"
            leftIconFill="#DB4746"
            appearance="ghost"
            style={{
              flex: 1,
              padding: 0,
            }}
          >
            Bilan Sanitaire d'Élevage expiré
          </Button>
        );
      } if (diff > 365 - 30) {
        return (
          <Button
            disabled
            leftIcon="checkmark-circle-2"
            leftIconFill="#FF9D00"
            appearance="ghost"
            style={{
              flex: 1,
              padding: 0,
            }}
          >
            Bilan Sanitaire d'Élevage bientôt expiré
          </Button>
        );
      }
      return (
        <Button
          disabled
          leftIcon="checkmark-circle-2"
          leftIconFill="#349B2E"
          appearance="ghost"
          style={{
            flex: 1,
            padding: 0,
          }}
        >
          Bilan Sanitaire d'Élevage
        </Button>
      );
    }
  }
  return (
    <Button
      disabled
      leftIcon="alert-circle"
      leftIconFill="#FF9D00"
      appearance="ghost"
      style={{
        flex: 1, padding: 0,
      }}
    >
      En attente de réalisation
    </Button>
  );
};

const ElevageCard = ({ linkTo, breeding }: ElevageCardProps) => {
  const theme = useTheme();

  const { bse } = useBseListByBreedingId(breeding.id, true);

  return (
    <FCard
      onPress={linkTo}
      style={{
        padding: 0, marginVertical: 10, paddingHorizontal: -5, marginHorizontal: 5,
      }}
    >
      <View style={{
        flexDirection: 'row', alignItems: 'center', paddingHorizontal: 15, paddingTop: 15,
      }}
      >
        <PaperPenIcon height={24} width={24} fill="black" />
        {breeding && (
          <View style={{ flex: 2, marginLeft: 10 }}>
            <Text category="h6" appearance="hint">Élevage</Text>
            <Text category="h6">{breeding.companyName}</Text>
            <Text category="h6">{breeding.nBreeding}</Text>
          </View>
        )}

        <View style={{ flex: 1, marginLeft: 10 }}>
          <Text category="h6" appearance="hint">{breeding.nextSubscriptionDate ? 'Élevage abonné' : 'Élevage non abonné'}</Text>
        </View>
        <Button rightIcon="arrow-ios-forward-outline" appearance="ghost" rightIconFill={theme['color-info-500']} onPress={linkTo}>Détails</Button>
      </View>

      <View
        style={{
          flex: 1,
          borderTopWidth: 1,
          borderTopColor: '#b5b5b5',
          // paddingTop: 10,
          marginTop: 10,
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {renderBse(bse)}
      </View>

    </FCard>
  );
};

export default ElevageCard;
