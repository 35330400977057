import {
  Radio, Text, useTheme,
} from '@ui-kitten/components';
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';

import { useLinkTo, useNavigation, useRoute } from '@react-navigation/native';

import {
  BackHandler, FlatList, TouchableOpacity, View,
} from 'react-native';
import _ from 'lodash';
import { Icon as IconUIKitten } from '@ui-kitten/components/ui/icon/icon.component';
import MaxWidthContainer from '../../components/MaxWidthContainer';
import questionnaireData from '../../src/mockData/questionnaireData';
import questionConditionData from '../../src/mockData/questionConditionData';

import QuestionCard from '../../components/CustomCards/QuestionCard';
import AnswerCard from '../../components/CustomCards/AnswerCard';

import Button from '../../components/Button';
import CustomModal from '../../components/Modals/CustomModal';
import CustomRatingBar from '../../components/Modals/CustomRatingBar';
import ConsultationScreenBL from '../../screenBusinessLogic/ConsultationScreen/ConsultationScreenBL';
import Card from '../../components/Card';
import { useGetConsultation, useUpdateConsultationMutation } from '../../src/API/Consultation';
import { useCreateDocumentMutation } from '../../src/API/Document';
import Camera, { CameraOutput } from '../../components/Camera/Camera';
import { Upload } from '../../utils/S3FileStorage';
import { GlobalStateContext } from '../../utils/GlobalStateContext';
import CrudBL from '../../screenBusinessLogic/ConsultationScreen/CrudBL';
import { useFunction } from '../../utils/CustomHooks';
import ListAutoExamenItem from '../ListAutoExamenItem';

export type QuestionaireDataItem = {
  id: string;
  titre1? :string;
  titre2? :string;
  titre3? :string;
  titre4? :string;
  autopass?: boolean;
  // quand on a valider la réponse de cette question
  validate?: boolean;
  // les questions a afficher dans la flatlist
  showQuestion?: boolean;
  lastChecked?: number;
  name: string;
  description: string;
  interdire: boolean;
  veto: boolean;
  imposer: boolean;
  poserMaintenant: boolean,
  hideQuestion: boolean;
  multipleAnswer: boolean;
  answers: {
    [key: string]: {
      name: string;
      description: string;
      image: string;
      interdire: boolean;
      veto: boolean;
      imposer: boolean;
      checked: boolean;
      showNextQuestion: boolean,
    }
  }
};

export type QuestionnaireData = {
  [key: string]: QuestionaireDataItem
};

const SaisirDiseaseResultScreen = () => {
  const linkTo = useLinkTo();
  const route = useRoute();
  const scrollViewRef = useRef(null);
  const theme = useTheme();
  /**  screen height logic */
  const navigation = useNavigation();

  const [currentQuestionnaireData, setCurrentQuestionnaireDataB] = useState<QuestionnaireData>(_.cloneDeep(questionnaireData));

  const setCurrentQuestionnaireData = (data) => {
    setCurrentQuestionnaireDataB(data);
  };

  const [flatListLayout, setFlatListLayout] = useState({
    layoutHeight: 0,
    contentHeight: 0,
    contentOffsetY: 0,
  });

  const isCloseToBottom = () => {
    const paddingToBottom = 20;
    const {
      layoutHeight,
      contentHeight,
      contentOffsetY,
    } = flatListLayout;
    return layoutHeight + contentOffsetY
        >= contentHeight - paddingToBottom;
  };

  useEffect(() => {
    if (isCloseToBottom()) {
      setIsBottom(false);
    } else {
      setIsBottom(true);
    }
  }, [flatListLayout.layoutHeight, flatListLayout.contentHeight, flatListLayout.contentOffsetY]);

  const [isBottom, setIsBottom] = useState(false);

  const { state, setState } = useContext(GlobalStateContext);

  const { consultation, refetch } = useGetConsultation(route?.params?.id);

  const [answerChecked, setAnswerChecked] = useState<string[]>([]);

  const [currentQuestion, setCurrentQuestion] = useState<string>();

  const [showNext, setShowNext] = useState(false);
  const [ignoreQuestion, setIgnoreQuestion] = useState(false);

  useEffect(() => {
    const newStateTemp = _.cloneDeep(currentQuestionnaireData);
    const newState: QuestionnaireData = {};
    const newStateKeys = Object.keys(newStateTemp).filter((item) => item.startsWith('C)'));

    newStateKeys.forEach((newStateKey) => {
      newState[newStateKey] = newStateTemp[newStateKey];
    });
    const questionResponseTemp = JSON.parse(consultation?.questionResponse);
    const keyQuestionReponseOrder = consultation?.questionResponseOrder;
    newState['C)I)'].showQuestion = true;
    setCurrentQuestion('C)I)');
    /**
    for (let i = 0; i < keyQuestionReponseOrder.length; i++) {
      if (newState[keyQuestionReponseOrder[i]].answers) {
        Object.keys(newState[keyQuestionReponseOrder[i]].answers).forEach((key) => {
          if (questionResponseTemp[keyQuestionReponseOrder[i]].indexOf(key) > -1) {
            newState[keyQuestionReponseOrder[i]].answers[key].checked = true;
          }
        });
      }
      validAnswer(questionResponseTemp[keyQuestionReponseOrder[i]], newState, keyQuestionReponseOrder[i], i === keyQuestionReponseOrder.length - 1);
    } */
    setCurrentQuestionnaireData(newState);

    return () => {};
  }, [consultation]);

  useEffect(() => {
    scrollToBottom(Object.values(currentQuestionnaireData).filter((item) => item.showQuestion).length - 1);
  }, [currentQuestion]);

  useEffect(() => {
    const backAction = () => {
      setState(true);
      return true;
    };

    const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction);

    return () => backHandler.remove();
  }, []);

  const { editConsultation, deleteConsultation } = CrudBL.useCrudConsultation();
  const validAnswer = async (argAnswerCheck? : string[], newState?: any, argIdCurrenQuestion?: string, isLast?:boolean) => {
    let currentAnswerCheck = answerChecked;
    if (ignoreQuestion) {
      currentAnswerCheck = [];
    }
    if (argAnswerCheck) {
      currentAnswerCheck = argAnswerCheck;
    }
    let id = currentQuestion;
    if (argIdCurrenQuestion) {
      id = argIdCurrenQuestion;
    }
    let keys = Object.keys(currentQuestionnaireData);
    let newListKey = keys.slice(keys.indexOf(id));
    let nextState = {};
    if (newState) {
      keys = Object.keys(newState);
      newListKey = keys.slice(keys.indexOf(id));
      nextState = newState;
    } else {
      nextState = _.cloneDeep(currentQuestionnaireData);
    }

    // all disease that possible exactlh for these Q&A chosen

    //
    currentAnswerCheck.map((answer) => {
      if (questionConditionData[answer]) {
        questionConditionData[answer].map((item) => {
          // console.log(item);
          if (item.cible.substr(-1) === '/') {
            const regex = /([A-Za-z0-9).]+?)([0-9]+\/)/gm;
            let questionId = '';
            let m;
            while ((m = regex.exec(item.cible)) !== null) {
              // This is necessary to avoid infinite loops with zero-width matches
              if (m.index === regex.lastIndex) {
                regex.lastIndex++;
              }

              // The result can be accessed through the `m`-variable.
              m.forEach((match, groupIndex) => {
                if (groupIndex === 1) {
                  questionId = match;
                }
              });
            }
            if (nextState[questionId]) {
              if (item.interdire || item.veto) {
                nextState[questionId].answers[item.cible].interdire = true;
                nextState[questionId].answers[item.cible].imposer = false;
                nextState[questionId].answers[item.cible].checked = false;
                if (item.veto) {
                  nextState[questionId].answers[item.cible].veto = true;
                }
              }
              if (item.imposer && !item.veto) {
                nextState[questionId].answers[item.cible].imposer = true;
                nextState[questionId].answers[item.cible].interdire = false;
                nextState[questionId].answers[item.cible].checked = true;
                if (nextState[questionId] && !nextState[questionId].multipleAnswer) {
                  nextState[questionId].validate = true;
                  nextState[questionId].interdire = true;
                }
              }
            }
          } else {
            if (item.veto) {
              keys.filter((key) => {
                if (key.startsWith(item.cible)) {
                  nextState[key].veto = true;
                  nextState[key].interdire = true;
                  nextState[key].imposer = false;
                }
                return () => {};
              });
            }
            if (item.poser) {
              keys.filter((key) => {
                if (key.startsWith(item.cible)) {
                  nextState[key].poserMaintenant = true;
                  nextState[key].interdire = false;
                }
                return () => {};
              });
            }
            if (item.interdire) {
              keys.filter((key) => {
                if (key.startsWith(item.cible) && !nextState[key].imposer) {
                  nextState[key].interdire = true;
                }
                return () => {};
              });
            }
            if (item.imposer) {
              keys.filter((key) => {
                if (key.startsWith(item.cible) && !nextState[key].veto) {
                  nextState[key].imposer = true;
                  nextState[key].interdire = false;
                }
                return () => {};
              });
            }
          }

          return () => {};
        });
      }
      return () => {};
    });
    const showNow = false;

    nextState[id].validate = true;

    goNextQuestion(nextState, showNow, newListKey, argIdCurrenQuestion, isLast);
  };

  const goNextQuestion = (nextState: any, showNow: boolean, newListKey: string[], argIdCurrenQuestion?: string, isLast?:boolean) => {
    let id = currentQuestion;

    if (argIdCurrenQuestion) {
      id = argIdCurrenQuestion;
    }
    if (nextState[newListKey[0]] && newListKey.length <= 1) {
      if (!argIdCurrenQuestion) {
        setFinQuestionaire(nextState);
      }
      return;
    }
    for (let i = 0; i < newListKey.length; i++) {
      if (nextState[newListKey[i]] && nextState[newListKey[i]].poserMaintenant && !nextState[newListKey[i]].interdire && !nextState[newListKey[i]].hideQuestion) {
        showNow = true;
        newListKey.splice(1, 0, newListKey[i]);
        newListKey.splice(i + 1, 1);
        break;
      }
    }
    if (showNow) {
      let currentQuestionnaireDataTemp = {};
      const keys = Object.keys(currentQuestionnaireData);
      const keyBefore = keys.slice(0, keys.indexOf(id));
      const valueQuestionnaireInit = Object.values(currentQuestionnaireData);
      for (let j = 0; j < keyBefore.length; j++) {
        currentQuestionnaireDataTemp = Object.assign(currentQuestionnaireDataTemp, { [keyBefore[j]]: valueQuestionnaireInit[j] });
      }
      for (let k = 0; k < newListKey.length; k++) {
        currentQuestionnaireDataTemp = Object.assign(currentQuestionnaireDataTemp, { [newListKey[k]]: nextState[newListKey[k]] });
      }
      nextState = currentQuestionnaireDataTemp;
    }
    if (nextState[newListKey[1]].autopass && !showNow) {
      nextState[newListKey[1]].showQuestion = true;
      const updateListKey = newListKey.slice(1);
      goNextQuestion(nextState, showNow, updateListKey, argIdCurrenQuestion, isLast);
      return;
    } if (!showNow && nextState[newListKey[1]]) {
      if (nextState[newListKey[1]].interdire) {
        newListKey = newListKey.slice(1);
        // console.log('List key avant while interdire', newListKey);
        while (newListKey[1] && ((nextState[newListKey[1]] && nextState[newListKey[1]].interdire) || nextState[newListKey[1]].hideQuestion)) {
          newListKey = newListKey.slice(1);
        }
        if (newListKey.length === 1 && nextState[newListKey[0]].interdire && !argIdCurrenQuestion) {
          setFinQuestionaire(nextState);
          return;
        }
        // newListKey = newListKey.filter((item) => item !== newListKey[0]);
        goNextQuestion(nextState, showNow, newListKey, argIdCurrenQuestion, isLast);
        return;
      }
      if (nextState[newListKey[1]].hideQuestion && !nextState[newListKey[1]].interdire
      ) {
        // console.log(currentQuestionnaireData[newListKey[0]]);
        nextState[newListKey[1]].validate = true;
        newListKey = newListKey.filter((item) => (item !== newListKey[0]));

        goNextQuestion(nextState, showNow, newListKey, argIdCurrenQuestion, isLast);
        return;
      }
    }
    nextState[newListKey[1]].showQuestion = true;
    if (isLast) {
      setCurrentQuestion(newListKey[1]);
    }
    if (!argIdCurrenQuestion) {
      setCurrentQuestion(newListKey[1]);
      setCurrentQuestionnaireData(nextState);
      setAnswerChecked([]);
      setIgnoreQuestion(false);
    }
  };

  const questionCondtion = (nextState, conditionData, idQuestion) => {
    const allKeys = Object.keys(nextState);
    const keys = allKeys.slice(allKeys.indexOf(idQuestion));
    if (conditionData && keys[1] === conditionData.cible) {
      if (conditionData.imposer) {
        if (nextState[conditionData.cible].hideQuestion) {
          nextState[keys[1]].showQuestion = true;
        }
      }
      if (conditionData.interdire) {
        nextState[keys[1]].showQuestion = false;
        // console.log('questionaire data : ', currentQuestionnaireData[conditionData.cible]);
      }
    }
  };

  const scrollToBottom = (index, viewPosition? = 0, viewOffset? = 0) => {
    try {
      scrollViewRef?.current?.scrollToIndex({
        index, animated: true, viewOffset, viewPosition,
      });
    } catch (e) {
      // console.log('pas grave on devrait scroll à la prochaine');
    }
  };

  const modifyQuestionnaire = useFunction((idQuestion: string, answerId?: string, currentChecked?: boolean) => {
    const keys = Object.keys(currentQuestionnaireData);
    const keyUpdate = keys.slice(keys.indexOf(idQuestion) + 1);
    const keyBefore = keys.slice(0, keys.indexOf(idQuestion) + 1);
    let initialKeys = Object.keys(questionnaireData);
    initialKeys = initialKeys.slice(initialKeys.indexOf(idQuestion) + 1);
    const orderedKeyUpdate = [];
    for (let i = 0; i < initialKeys.length; i++) {
      if (keyUpdate.indexOf(initialKeys[i]) > -1) {
        orderedKeyUpdate.push(initialKeys[i]);
      }
    }

    const newQuestionnaireData = _.cloneDeep(currentQuestionnaireData);

    for (let j = 0; j < orderedKeyUpdate.length; j++) {
      newQuestionnaireData[orderedKeyUpdate[j]] = { ...currentQuestionnaireData[orderedKeyUpdate[j]] };
      newQuestionnaireData[orderedKeyUpdate[j]].validate = false;
      newQuestionnaireData[orderedKeyUpdate[j]].showQuestion = false;
      newQuestionnaireData[orderedKeyUpdate[j]].imposer = false;
      newQuestionnaireData[orderedKeyUpdate[j]].interdire = !!orderedKeyUpdate[j].startsWith('C)');
      newQuestionnaireData[orderedKeyUpdate[j]].poserMaintenant = false;
      newQuestionnaireData[orderedKeyUpdate[j]].veto = false;
      if (newQuestionnaireData[orderedKeyUpdate[j]].answers) {
        const answersKeys = Object.keys(newQuestionnaireData[orderedKeyUpdate[j]].answers);
        for (let k = 0; k < answersKeys.length; k++) {
          newQuestionnaireData[orderedKeyUpdate[j]].answers[answersKeys[k]].interdire = false;
          newQuestionnaireData[orderedKeyUpdate[j]].answers[answersKeys[k]].veto = false;
          newQuestionnaireData[orderedKeyUpdate[j]].answers[answersKeys[k]].imposer = false;
        }
      }
    }

    for (let k = 0; k < keys.indexOf(idQuestion); k++) {
      if (newQuestionnaireData[keys[k]].answers) {
        Object.keys(newQuestionnaireData[keys[k]].answers).map((answerKey) => {
          const answer = newQuestionnaireData[keys[k]].answers[answerKey];
          if (answer.checked && questionConditionData[answerKey]) {
            questionConditionData[answerKey].map((item) => {
              // console.log(item);
              if (item.cible.substr(-1) === '/') {
                const questionId = item.cible.substr(0, item.cible.length - 2);
                if (newQuestionnaireData[questionId]) {
                  if (item.interdire) {
                    newQuestionnaireData[questionId].answers[item.cible].interdire = true;
                    newQuestionnaireData[questionId].answers[item.cible].imposer = false;
                    newQuestionnaireData[questionId].answers[item.cible].checked = false;
                  }
                  if (item.imposer) {
                    newQuestionnaireData[questionId].answers[item.cible].imposer = true;
                    newQuestionnaireData[questionId].answers[item.cible].interdire = false;
                    newQuestionnaireData[questionId].answers[item.cible].checked = true;
                  }
                }
              } else {
                if (item.veto) {
                  keys.filter((key) => {
                    if (key.startsWith(item.cible)) {
                      newQuestionnaireData[key].veto = true;
                      newQuestionnaireData[key].interdire = true;
                      newQuestionnaireData[key].imposer = false;
                    }
                    return () => {
                    };
                  });
                }
                if (item.poser) {
                  keys.filter((key) => {
                    if (key.startsWith(item.cible)) {
                      newQuestionnaireData[key].poserMaintenant = true;
                      newQuestionnaireData[key].interdire = false;
                    }
                    return () => {
                    };
                  });
                }
                if (item.interdire) {
                  keys.filter((key) => {
                    if (key.startsWith(item.cible) && !newQuestionnaireData[key].imposer) {
                      newQuestionnaireData[key].interdire = true;
                    }
                    return () => {
                    };
                  });
                }
                if (item.imposer) {
                  keys.filter((key) => {
                    if (key.startsWith(item.cible) && !newQuestionnaireData[key].veto) {
                      newQuestionnaireData[key].imposer = true;
                      newQuestionnaireData[key].interdire = false;
                    }
                    return () => {
                    };
                  });
                }
              }

              return () => {
              };
            });
          }
        });
      }
    }

    newQuestionnaireData[idQuestion].validate = false;
    if (answerId) {
      onChecked(idQuestion, answerId, currentChecked, true, newQuestionnaireData);
    } else {
      setCurrentQuestionnaireData(newQuestionnaireData);
    }
    setCurrentQuestion(idQuestion);
  });
  const [finQuestionaire, setFinQuestionaire] = useState(undefined);
  const finaliserQuestionnaire = (nextState: any, isDone: boolean) => {
    const questionReponse : { [key: string]: string[] } = JSON.parse(consultation?.questionResponse);
    const questionResponseOrder : string[] = consultation?.questionResponseOrder;
    const listKey = Object.keys(nextState);
    listKey.forEach((key) => {
      if (nextState[key].validate) {
        const answers: string[] = [];
        Object.keys(nextState[key].answers).forEach((answerKey) => {
          if (nextState[key].answers[answerKey].checked) {
            answers.push(answerKey);
          }
        });
        questionResponseOrder.push(key);
        questionReponse[key] = answers;
      }
    });
    let diseaseIsVerified = [...(consultation?.diseaseIsVerified || [])];

    diseaseIsVerified = [...(diseaseIsVerified), route.params.codeIdentification];

    editConsultation({
      id: route?.params?.id,
      questionResponse: JSON.stringify(questionReponse),
      diseaseIsVerified,
      questionResponseOrder,
      isDone,
      _version: consultation._version,
    }).then(navigation.goBack, setState(false));
  };
  const onPressSaveAndQuit = () => {
    finaliserQuestionnaire(currentQuestionnaireData, false);
  };
  const onChecked = useFunction((
    idQuestion: string, checkedItem: string, currentChecked: boolean, trueCheck = true, baseState?: QuestionnaireData,
    // showNextQuestion, showingNextQuestion,
  ) => {
    // next lines of code permit to stock or remove checked answer
    // by comparing
    // current item from CheckedAnswersState  with checkedItem that was just checked

    const nextState = _.cloneDeep(currentQuestionnaireData);
    const newCurrentQuestion = nextState[idQuestion];

    const newChecked = newCurrentQuestion.multipleAnswer ? answerChecked.filter((current) => current !== checkedItem) : []; // console.log('current checked:', currentChecked);
    if (currentChecked) { newChecked.push(checkedItem); }

    // fix pushing and filter of newChecked when using checkbox
    // it causes bad appearance of "Suivant" button
    // console.log('newChecked', newChecked);
    /**  switch between 2 different questions that have same name
     * (e.g. 'A)1)c)2.') thanks to hideQuestion attribute */

    if (newCurrentQuestion.multipleAnswer) {
      newCurrentQuestion.answers[checkedItem].checked = currentChecked;
    } else {
      Object.keys(newCurrentQuestion.answers).map((item) => (newCurrentQuestion.answers[item].checked = false));
      newCurrentQuestion.answers[checkedItem].checked = currentChecked;
    }
    if (trueCheck) {
      newCurrentQuestion.lastChecked = Date.now();
    }

    setAnswerChecked(newChecked);
    if (newChecked.length > 0) {
      setIgnoreQuestion(false);
      setShowNext(true);
    } else {
      setShowNext(false);
    }
    setCurrentQuestionnaireData(nextState);
  });

  return (
    <MaxWidthContainer
      withScrollView={false}
      outerViewProps={{
        style: {
          padding: 0, margin: 0, marginBottom: -25, backgroundColor: 'white',
        },
      }}
    >
      <FlatList<QuestionaireDataItem>
        style={{ backgroundColor: '#EFF8F8', paddingHorizontal: 10 }}
        data={Object.values(currentQuestionnaireData).filter((item) => item.showQuestion)}
        ref={scrollViewRef}
        onScrollToIndexFailed={(info) => {
          const wait = new Promise((resolve) => setTimeout(resolve, 500));
          wait.then(() => {
            scrollViewRef.current?.scrollToIndex({ index: info.index, animated: true });
          });
        }}
        onScroll={({ nativeEvent }) => {
          setFlatListLayout({
            layoutHeight: nativeEvent.layoutMeasurement.height,
            contentHeight: nativeEvent.contentSize.height,
            contentOffsetY: nativeEvent.contentOffset.y,
          });
        }}
        onLayout={({ nativeEvent }) => {
          setFlatListLayout((currentState) => ({
            ...currentState,
            layoutHeight: nativeEvent.layout.height,
          }));
        }}
        onContentSizeChange={(w, h) => {
          setFlatListLayout((currentState) => ({
            ...currentState,
            contentHeight: h,
          }));
        }}
        renderItem={({ item }) => (
          <ListAutoExamenItem item={item} modifyQuestionnaire={modifyQuestionnaire} onChecked={onChecked} />
        )}
        ListFooterComponent={(
          <>
            <View style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
              <View style={{
                display: 'flex', flex: 3, alignSelf: 'flex-end', flexDirection: 'column',
              }}
              >
                <Text category="h4" style={{ marginBottom: 10, textAlign: 'right' }}>Vous ne savez pas ou vous n'êtes pas sûr ?</Text>
                <>
                  {route?.params?.update === 'false' ? (
                    <Button
                      rightIcon="arrow-ios-forward"
                      rightIconFill="#fff"
                      style={{ alignSelf: 'flex-end', width: 210, marginBottom: 10 }}
                      onPress={() => { linkTo(`/consultation/detail-consultation/${route?.params?.id}`); }}
                    >
                      Revenir à l'auto-examen
                    </Button>
                  ) : (
                    <>
                      <Card
                        onPress={() => setIgnoreQuestion(!ignoreQuestion)}
                        style={{
                          flexDirection: 'row', alignSelf: 'flex-end', marginLeft: 55, marginBottom: 10,
                        }}
                      >
                        <Radio
                          onChange={(nextChecked) => {
                            setIgnoreQuestion(nextChecked);
                          }}
                          checked={ignoreQuestion}
                          style={{ marginRight: 5 }}
                        />
                        <Text category="h4">Ignorer cette question</Text>
                      </Card>
                    </>
                  )}
                </>
              </View>
            </View>
          </>
            )}
        keyExtractor={(item) => item.id}
      />
      {isBottom ? (
        <TouchableOpacity
          onPress={() => scrollToBottom(Object.values(currentQuestionnaireData).filter((item) => item.showQuestion).length - 1, 1, -100)}
          style={{
            position: 'absolute', bottom: 100, marginHorizontal: 50, alignSelf: 'center', width: 300, backgroundColor: theme['color-primary-300'], borderRadius: 20, flexDirection: 'row', justifyContent: 'center',
          }}
        >
          <IconUIKitten
            name="arrow-downward-outline"
            style={{
              height: 20, width: 20, paddingRight: 5, paddingTop: 2,
            }}
            fill="#000000"
          />
          <Text>
            Avez-vous vu toutes les réponses ?
          </Text>
        </TouchableOpacity>
      ) : null}

      <View style={{
        backgroundColor: 'white', justifyContent: 'flex-end', flexDirection: 'row', minHeight: 50,
      }}
      >
        {(showNext || ignoreQuestion)
          ? (
            <Button
              rightIcon="arrow-ios-forward"
              rightIconFill="#fff"
              style={{ width: 100, height: '80%', marginTop: 10 }}
              onPress={() => {
                validAnswer();
                setShowNext(false);
              }}
            >
              Suivant
            </Button>
          ) : null}
      </View>
      <CustomModal
        title="Vous êtes sur le point de quitter le questionnaire."
        before={<></>}
        noSafeArea
        visible={state}
        scrollable={false}
        onClose={() => { setState(false); }}
        insideModalStyle={{ padding: 20 }}
        heightPercentage={0.5}
      >
        <View>
          <Button
            size="large"
            appearance="filled"
            onPress={onPressSaveAndQuit}
            style={{ marginTop: 20, justifyContent: 'center' }}
          >
            Sauvegarder le questionnaire et quitter
          </Button>
          <Button
            size="large"
            appearance="outline"
            status="danger"
            onPress={() => {
              deleteConsultation({
                id: consultation?.id,
                // eslint-disable-next-line no-underscore-dangle
                _version: consultation?._version,
              }).then(navigation.goBack);

              setState(false);
            }}
            style={{ marginTop: 20, justifyContent: 'center' }}
          >
            <Text style={{ textAlign: 'center' }}>
              Quitter sans sauvegarder le questionnaire

            </Text>
          </Button>
          <Button size="large" appearance="ghost" onPress={() => setState(false)} style={{ marginTop: 20, justifyContent: 'center' }}>
            Annuler
          </Button>
        </View>
      </CustomModal>
      <CustomModal
        title={'Vous êtes sur le point de valider votre questionnaire.\n'
            + 'Etes-vous sûr de vouloir continuer ?'}
        before={<></>}
        noSafeArea
        visible={finQuestionaire !== undefined}
        scrollable={false}
        onClose={() => { setFinQuestionaire(undefined); }}
        insideModalStyle={{ padding: 20 }}
        buttonText="Valider le questionnaire"
        buttonPress={() => {
          setState(undefined);
          finaliserQuestionnaire(finQuestionaire, true);
        }}
        secondButtonText="Modifier le questionnaire"
        secondButtonPress={() => {
          setState(undefined);
        }}
      />
    </MaxWidthContainer>
  );
};
export default SaisirDiseaseResultScreen;
