/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateMessageByConsultationId = /* GraphQL */ `
  subscription OnCreateMessageByConsultationId($consultationId: ID!) {
    onCreateMessageByConsultationId(consultationId: $consultationId) {
      id
      consultationId
      consultation {
        id
        breedingId
        animalId
        lotId
        diseaseId
        shared
        sharedAt
        autoExam
        teleconsultation
        priceConsultation
        diseaseIsVerified
        isDone
        factureId
        sharedFacture
        sharedFactureAt
        teleconsAskAt
        dateTeleCons
        questionResponse
        note
        questionResponseOrder
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      message
      userId
      user {
        id
        lastname
        firstname
        avatarUri
        userGroup
        optIn
        inscriptionNumberOrdreVeto
        breedingId
        vetOfficeId
        email
        expoToken
        notificationLastSeenAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateDocumentByConsultationId = /* GraphQL */ `
  subscription OnCreateDocumentByConsultationId($consultationId: ID!) {
    onCreateDocumentByConsultationId(consultationId: $consultationId) {
      id
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      consultationId
      consultation {
        id
        breedingId
        animalId
        lotId
        diseaseId
        shared
        sharedAt
        autoExam
        teleconsultation
        priceConsultation
        diseaseIsVerified
        isDone
        factureId
        sharedFacture
        sharedFactureAt
        teleconsAskAt
        dateTeleCons
        questionResponse
        note
        questionResponseOrder
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      name
      key
      s3file
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteDocumentByConsultationId = /* GraphQL */ `
  subscription OnDeleteDocumentByConsultationId($consultationId: ID!) {
    onDeleteDocumentByConsultationId(consultationId: $consultationId) {
      id
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      consultationId
      consultation {
        id
        breedingId
        animalId
        lotId
        diseaseId
        shared
        sharedAt
        autoExam
        teleconsultation
        priceConsultation
        diseaseIsVerified
        isDone
        factureId
        sharedFacture
        sharedFactureAt
        teleconsAskAt
        dateTeleCons
        questionResponse
        note
        questionResponseOrder
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      name
      key
      s3file
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onAskBSEStart = /* GraphQL */ `
  subscription OnAskBSEStart($id: ID!) {
    onAskBSEStart(id: $id) {
      id
      breedingId
      StatusBse
      dateBSE
      crS3Key
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      questionResponse
      lasIdQuestion
      idEleveurUser
      lastQuestionVeto
      questionResponseOrder
      maladieRecurente
      protocolesDeSoins
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onConfirmBreedingMandate = /* GraphQL */ `
  subscription OnConfirmBreedingMandate($id: ID!) {
    onConfirmBreedingMandate(id: $id) {
      id
      locked
      nBreeding
      nSIRET
      phone
      emailEleveur
      address {
        address
        additionalAddress
        postalCode
        city
      }
      companyName
      dairyFarm
      sucklerCowHusbandry
      fatteningFarm
      nbCow
      nbHeifer
      nbMale
      nbYoung
      idVeto
      emailVeto
      admins
      vetOffice {
        id
        locked
        companyName
        emailOffice
        admins
        personnel
        phoneVetOffice
        nameVetSanitaire
        nOrdreCabinet
        nSIRET
        maxPriceTeleConsultation
        maxPriceConsultation
        mpUserId
        isFree
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      users {
        nextToken
        startedAt
      }
      documents {
        nextToken
      }
      animals {
        nextToken
        startedAt
      }
      lots {
        nextToken
        startedAt
      }
      buildings {
        nextToken
        startedAt
      }
      bse {
        nextToken
        startedAt
      }
      pendingInvitations {
        nextToken
        startedAt
      }
      consultations {
        nextToken
        startedAt
      }
      billingHistories {
        nextToken
      }
      mpUserId
      mpWalletId
      mpBankId
      mpMandateId
      mpMandateConfirmed
      createdAt
      dateFirstCons
      nextSubscriptionDate
      subscriptionAmount
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onConfirmVetOfficeMandate = /* GraphQL */ `
  subscription OnConfirmVetOfficeMandate($id: ID!) {
    onConfirmVetOfficeMandate(id: $id) {
      id
      locked
      companyName
      emailOffice
      admins
      personnel
      phoneVetOffice
      address {
        address
        additionalAddress
        postalCode
        city
      }
      nameVetSanitaire
      nOrdreCabinet
      nSIRET
      maxPriceTeleConsultation
      maxPriceConsultation
      users {
        nextToken
        startedAt
      }
      breedings {
        nextToken
        startedAt
      }
      billingHistories {
        nextToken
      }
      mpUserId
      isFree
      mpWalletId
      mpBankId
      mpMandateId
      mpMandateConfirmed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser(
    $filter: ModelSubscriptionUserFilterInput
    $id: String
  ) {
    onCreateUser(filter: $filter, id: $id) {
      id
      lastname
      firstname
      avatarUri
      userGroup
      optIn
      inscriptionNumberOrdreVeto
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      vetOfficeId
      vetOffice {
        id
        locked
        companyName
        emailOffice
        admins
        personnel
        phoneVetOffice
        nameVetSanitaire
        nOrdreCabinet
        nSIRET
        maxPriceTeleConsultation
        maxPriceConsultation
        mpUserId
        isFree
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      email
      expoToken
      notificationLastSeenAt
      messages {
        nextToken
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser(
    $filter: ModelSubscriptionUserFilterInput
    $id: String
  ) {
    onUpdateUser(filter: $filter, id: $id) {
      id
      lastname
      firstname
      avatarUri
      userGroup
      optIn
      inscriptionNumberOrdreVeto
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      vetOfficeId
      vetOffice {
        id
        locked
        companyName
        emailOffice
        admins
        personnel
        phoneVetOffice
        nameVetSanitaire
        nOrdreCabinet
        nSIRET
        maxPriceTeleConsultation
        maxPriceConsultation
        mpUserId
        isFree
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      email
      expoToken
      notificationLastSeenAt
      messages {
        nextToken
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser(
    $filter: ModelSubscriptionUserFilterInput
    $id: String
  ) {
    onDeleteUser(filter: $filter, id: $id) {
      id
      lastname
      firstname
      avatarUri
      userGroup
      optIn
      inscriptionNumberOrdreVeto
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      vetOfficeId
      vetOffice {
        id
        locked
        companyName
        emailOffice
        admins
        personnel
        phoneVetOffice
        nameVetSanitaire
        nOrdreCabinet
        nSIRET
        maxPriceTeleConsultation
        maxPriceConsultation
        mpUserId
        isFree
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      email
      expoToken
      notificationLastSeenAt
      messages {
        nextToken
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateVetOffice = /* GraphQL */ `
  subscription OnCreateVetOffice(
    $filter: ModelSubscriptionVetOfficeFilterInput
  ) {
    onCreateVetOffice(filter: $filter) {
      id
      locked
      companyName
      emailOffice
      admins
      personnel
      phoneVetOffice
      address {
        address
        additionalAddress
        postalCode
        city
      }
      nameVetSanitaire
      nOrdreCabinet
      nSIRET
      maxPriceTeleConsultation
      maxPriceConsultation
      users {
        nextToken
        startedAt
      }
      breedings {
        nextToken
        startedAt
      }
      billingHistories {
        nextToken
      }
      mpUserId
      isFree
      mpWalletId
      mpBankId
      mpMandateId
      mpMandateConfirmed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateVetOffice = /* GraphQL */ `
  subscription OnUpdateVetOffice(
    $filter: ModelSubscriptionVetOfficeFilterInput
  ) {
    onUpdateVetOffice(filter: $filter) {
      id
      locked
      companyName
      emailOffice
      admins
      personnel
      phoneVetOffice
      address {
        address
        additionalAddress
        postalCode
        city
      }
      nameVetSanitaire
      nOrdreCabinet
      nSIRET
      maxPriceTeleConsultation
      maxPriceConsultation
      users {
        nextToken
        startedAt
      }
      breedings {
        nextToken
        startedAt
      }
      billingHistories {
        nextToken
      }
      mpUserId
      isFree
      mpWalletId
      mpBankId
      mpMandateId
      mpMandateConfirmed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteVetOffice = /* GraphQL */ `
  subscription OnDeleteVetOffice(
    $filter: ModelSubscriptionVetOfficeFilterInput
  ) {
    onDeleteVetOffice(filter: $filter) {
      id
      locked
      companyName
      emailOffice
      admins
      personnel
      phoneVetOffice
      address {
        address
        additionalAddress
        postalCode
        city
      }
      nameVetSanitaire
      nOrdreCabinet
      nSIRET
      maxPriceTeleConsultation
      maxPriceConsultation
      users {
        nextToken
        startedAt
      }
      breedings {
        nextToken
        startedAt
      }
      billingHistories {
        nextToken
      }
      mpUserId
      isFree
      mpWalletId
      mpBankId
      mpMandateId
      mpMandateConfirmed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateBreeding = /* GraphQL */ `
  subscription OnCreateBreeding($filter: ModelSubscriptionBreedingFilterInput) {
    onCreateBreeding(filter: $filter) {
      id
      locked
      nBreeding
      nSIRET
      phone
      emailEleveur
      address {
        address
        additionalAddress
        postalCode
        city
      }
      companyName
      dairyFarm
      sucklerCowHusbandry
      fatteningFarm
      nbCow
      nbHeifer
      nbMale
      nbYoung
      idVeto
      emailVeto
      admins
      vetOffice {
        id
        locked
        companyName
        emailOffice
        admins
        personnel
        phoneVetOffice
        nameVetSanitaire
        nOrdreCabinet
        nSIRET
        maxPriceTeleConsultation
        maxPriceConsultation
        mpUserId
        isFree
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      users {
        nextToken
        startedAt
      }
      documents {
        nextToken
      }
      animals {
        nextToken
        startedAt
      }
      lots {
        nextToken
        startedAt
      }
      buildings {
        nextToken
        startedAt
      }
      bse {
        nextToken
        startedAt
      }
      pendingInvitations {
        nextToken
        startedAt
      }
      consultations {
        nextToken
        startedAt
      }
      billingHistories {
        nextToken
      }
      mpUserId
      mpWalletId
      mpBankId
      mpMandateId
      mpMandateConfirmed
      createdAt
      dateFirstCons
      nextSubscriptionDate
      subscriptionAmount
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateBreeding = /* GraphQL */ `
  subscription OnUpdateBreeding($filter: ModelSubscriptionBreedingFilterInput) {
    onUpdateBreeding(filter: $filter) {
      id
      locked
      nBreeding
      nSIRET
      phone
      emailEleveur
      address {
        address
        additionalAddress
        postalCode
        city
      }
      companyName
      dairyFarm
      sucklerCowHusbandry
      fatteningFarm
      nbCow
      nbHeifer
      nbMale
      nbYoung
      idVeto
      emailVeto
      admins
      vetOffice {
        id
        locked
        companyName
        emailOffice
        admins
        personnel
        phoneVetOffice
        nameVetSanitaire
        nOrdreCabinet
        nSIRET
        maxPriceTeleConsultation
        maxPriceConsultation
        mpUserId
        isFree
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      users {
        nextToken
        startedAt
      }
      documents {
        nextToken
      }
      animals {
        nextToken
        startedAt
      }
      lots {
        nextToken
        startedAt
      }
      buildings {
        nextToken
        startedAt
      }
      bse {
        nextToken
        startedAt
      }
      pendingInvitations {
        nextToken
        startedAt
      }
      consultations {
        nextToken
        startedAt
      }
      billingHistories {
        nextToken
      }
      mpUserId
      mpWalletId
      mpBankId
      mpMandateId
      mpMandateConfirmed
      createdAt
      dateFirstCons
      nextSubscriptionDate
      subscriptionAmount
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteBreeding = /* GraphQL */ `
  subscription OnDeleteBreeding($filter: ModelSubscriptionBreedingFilterInput) {
    onDeleteBreeding(filter: $filter) {
      id
      locked
      nBreeding
      nSIRET
      phone
      emailEleveur
      address {
        address
        additionalAddress
        postalCode
        city
      }
      companyName
      dairyFarm
      sucklerCowHusbandry
      fatteningFarm
      nbCow
      nbHeifer
      nbMale
      nbYoung
      idVeto
      emailVeto
      admins
      vetOffice {
        id
        locked
        companyName
        emailOffice
        admins
        personnel
        phoneVetOffice
        nameVetSanitaire
        nOrdreCabinet
        nSIRET
        maxPriceTeleConsultation
        maxPriceConsultation
        mpUserId
        isFree
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      users {
        nextToken
        startedAt
      }
      documents {
        nextToken
      }
      animals {
        nextToken
        startedAt
      }
      lots {
        nextToken
        startedAt
      }
      buildings {
        nextToken
        startedAt
      }
      bse {
        nextToken
        startedAt
      }
      pendingInvitations {
        nextToken
        startedAt
      }
      consultations {
        nextToken
        startedAt
      }
      billingHistories {
        nextToken
      }
      mpUserId
      mpWalletId
      mpBankId
      mpMandateId
      mpMandateConfirmed
      createdAt
      dateFirstCons
      nextSubscriptionDate
      subscriptionAmount
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateAnimals = /* GraphQL */ `
  subscription OnCreateAnimals($filter: ModelSubscriptionAnimalsFilterInput) {
    onCreateAnimals(filter: $filter) {
      id
      numberID
      name
      gender
      birthDate
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      lotsId
      lots {
        id
        numberLot
        name
        buildingId
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      consultations {
        nextToken
        startedAt
      }
      zoneId
      zone {
        id
        name
        numberZone
        buildingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      buildingId
      building {
        id
        nameBuilding
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateAnimals = /* GraphQL */ `
  subscription OnUpdateAnimals($filter: ModelSubscriptionAnimalsFilterInput) {
    onUpdateAnimals(filter: $filter) {
      id
      numberID
      name
      gender
      birthDate
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      lotsId
      lots {
        id
        numberLot
        name
        buildingId
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      consultations {
        nextToken
        startedAt
      }
      zoneId
      zone {
        id
        name
        numberZone
        buildingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      buildingId
      building {
        id
        nameBuilding
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteAnimals = /* GraphQL */ `
  subscription OnDeleteAnimals($filter: ModelSubscriptionAnimalsFilterInput) {
    onDeleteAnimals(filter: $filter) {
      id
      numberID
      name
      gender
      birthDate
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      lotsId
      lots {
        id
        numberLot
        name
        buildingId
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      consultations {
        nextToken
        startedAt
      }
      zoneId
      zone {
        id
        name
        numberZone
        buildingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      buildingId
      building {
        id
        nameBuilding
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateLots = /* GraphQL */ `
  subscription OnCreateLots($filter: ModelSubscriptionLotsFilterInput) {
    onCreateLots(filter: $filter) {
      id
      numberLot
      name
      buildingId
      building {
        id
        nameBuilding
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      animals {
        nextToken
        startedAt
      }
      consultations {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateLots = /* GraphQL */ `
  subscription OnUpdateLots($filter: ModelSubscriptionLotsFilterInput) {
    onUpdateLots(filter: $filter) {
      id
      numberLot
      name
      buildingId
      building {
        id
        nameBuilding
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      animals {
        nextToken
        startedAt
      }
      consultations {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteLots = /* GraphQL */ `
  subscription OnDeleteLots($filter: ModelSubscriptionLotsFilterInput) {
    onDeleteLots(filter: $filter) {
      id
      numberLot
      name
      buildingId
      building {
        id
        nameBuilding
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      animals {
        nextToken
        startedAt
      }
      consultations {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateBuildings = /* GraphQL */ `
  subscription OnCreateBuildings(
    $filter: ModelSubscriptionBuildingsFilterInput
  ) {
    onCreateBuildings(filter: $filter) {
      id
      nameBuilding
      address {
        address
        additionalAddress
        postalCode
        city
      }
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      zone {
        nextToken
        startedAt
      }
      animals {
        nextToken
        startedAt
      }
      lots {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateBuildings = /* GraphQL */ `
  subscription OnUpdateBuildings(
    $filter: ModelSubscriptionBuildingsFilterInput
  ) {
    onUpdateBuildings(filter: $filter) {
      id
      nameBuilding
      address {
        address
        additionalAddress
        postalCode
        city
      }
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      zone {
        nextToken
        startedAt
      }
      animals {
        nextToken
        startedAt
      }
      lots {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteBuildings = /* GraphQL */ `
  subscription OnDeleteBuildings(
    $filter: ModelSubscriptionBuildingsFilterInput
  ) {
    onDeleteBuildings(filter: $filter) {
      id
      nameBuilding
      address {
        address
        additionalAddress
        postalCode
        city
      }
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      zone {
        nextToken
        startedAt
      }
      animals {
        nextToken
        startedAt
      }
      lots {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateZones = /* GraphQL */ `
  subscription OnCreateZones($filter: ModelSubscriptionZonesFilterInput) {
    onCreateZones(filter: $filter) {
      id
      name
      numberZone
      animals {
        nextToken
        startedAt
      }
      buildingId
      building {
        id
        nameBuilding
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateZones = /* GraphQL */ `
  subscription OnUpdateZones($filter: ModelSubscriptionZonesFilterInput) {
    onUpdateZones(filter: $filter) {
      id
      name
      numberZone
      animals {
        nextToken
        startedAt
      }
      buildingId
      building {
        id
        nameBuilding
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteZones = /* GraphQL */ `
  subscription OnDeleteZones($filter: ModelSubscriptionZonesFilterInput) {
    onDeleteZones(filter: $filter) {
      id
      name
      numberZone
      animals {
        nextToken
        startedAt
      }
      buildingId
      building {
        id
        nameBuilding
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateBSE = /* GraphQL */ `
  subscription OnCreateBSE($filter: ModelSubscriptionBSEFilterInput) {
    onCreateBSE(filter: $filter) {
      id
      breedingId
      StatusBse
      dateBSE
      crS3Key
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      questionResponse
      lasIdQuestion
      idEleveurUser
      lastQuestionVeto
      questionResponseOrder
      maladieRecurente
      protocolesDeSoins
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateBSE = /* GraphQL */ `
  subscription OnUpdateBSE($filter: ModelSubscriptionBSEFilterInput) {
    onUpdateBSE(filter: $filter) {
      id
      breedingId
      StatusBse
      dateBSE
      crS3Key
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      questionResponse
      lasIdQuestion
      idEleveurUser
      lastQuestionVeto
      questionResponseOrder
      maladieRecurente
      protocolesDeSoins
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteBSE = /* GraphQL */ `
  subscription OnDeleteBSE($filter: ModelSubscriptionBSEFilterInput) {
    onDeleteBSE(filter: $filter) {
      id
      breedingId
      StatusBse
      dateBSE
      crS3Key
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      questionResponse
      lasIdQuestion
      idEleveurUser
      lastQuestionVeto
      questionResponseOrder
      maladieRecurente
      protocolesDeSoins
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreatePendingInvitation = /* GraphQL */ `
  subscription OnCreatePendingInvitation(
    $filter: ModelSubscriptionPendingInvitationFilterInput
  ) {
    onCreatePendingInvitation(filter: $filter) {
      id
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      emailCurrentUser
      email
      createdAt
      initial
      refusedAt
      acceptedAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdatePendingInvitation = /* GraphQL */ `
  subscription OnUpdatePendingInvitation(
    $filter: ModelSubscriptionPendingInvitationFilterInput
  ) {
    onUpdatePendingInvitation(filter: $filter) {
      id
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      emailCurrentUser
      email
      createdAt
      initial
      refusedAt
      acceptedAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeletePendingInvitation = /* GraphQL */ `
  subscription OnDeletePendingInvitation(
    $filter: ModelSubscriptionPendingInvitationFilterInput
  ) {
    onDeletePendingInvitation(filter: $filter) {
      id
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      emailCurrentUser
      email
      createdAt
      initial
      refusedAt
      acceptedAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateConsultation = /* GraphQL */ `
  subscription OnCreateConsultation(
    $filter: ModelSubscriptionConsultationFilterInput
  ) {
    onCreateConsultation(filter: $filter) {
      id
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      animalId
      animal {
        id
        numberID
        name
        gender
        birthDate
        breedingId
        lotsId
        zoneId
        buildingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      lotId
      lot {
        id
        numberLot
        name
        buildingId
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      diseaseId
      disease {
        id
        codeIdentification
        name
        forme
        description
        synonymes
        enjeux
        causes
        circonstancesApparition
        diagnosticDiff
        diagnosticLab
        traitementCuratif
        pronostic
        prevention
        url
        image
        ponderationDisease
        ponderationCount
        familleMaladie
        familleMaladieId
        MRLC
        maladieUrgente
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      shared
      sharedAt
      autoExam
      autoExamRes {
        reponse1
      }
      teleconsultation
      priceConsultation
      diseaseIsVerified
      isDone
      factureId
      facture {
        id
        breedingId
        consultationId
        name
        key
        s3file
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      documents {
        nextToken
      }
      messages {
        nextToken
      }
      sharedFacture
      sharedFactureAt
      teleconsAskAt
      dateTeleCons
      questionResponse
      note
      questionResponseOrder
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateConsultation = /* GraphQL */ `
  subscription OnUpdateConsultation(
    $filter: ModelSubscriptionConsultationFilterInput
  ) {
    onUpdateConsultation(filter: $filter) {
      id
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      animalId
      animal {
        id
        numberID
        name
        gender
        birthDate
        breedingId
        lotsId
        zoneId
        buildingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      lotId
      lot {
        id
        numberLot
        name
        buildingId
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      diseaseId
      disease {
        id
        codeIdentification
        name
        forme
        description
        synonymes
        enjeux
        causes
        circonstancesApparition
        diagnosticDiff
        diagnosticLab
        traitementCuratif
        pronostic
        prevention
        url
        image
        ponderationDisease
        ponderationCount
        familleMaladie
        familleMaladieId
        MRLC
        maladieUrgente
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      shared
      sharedAt
      autoExam
      autoExamRes {
        reponse1
      }
      teleconsultation
      priceConsultation
      diseaseIsVerified
      isDone
      factureId
      facture {
        id
        breedingId
        consultationId
        name
        key
        s3file
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      documents {
        nextToken
      }
      messages {
        nextToken
      }
      sharedFacture
      sharedFactureAt
      teleconsAskAt
      dateTeleCons
      questionResponse
      note
      questionResponseOrder
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteConsultation = /* GraphQL */ `
  subscription OnDeleteConsultation(
    $filter: ModelSubscriptionConsultationFilterInput
  ) {
    onDeleteConsultation(filter: $filter) {
      id
      breedingId
      breeding {
        id
        locked
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        mpUserId
        mpWalletId
        mpBankId
        mpMandateId
        mpMandateConfirmed
        createdAt
        dateFirstCons
        nextSubscriptionDate
        subscriptionAmount
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      animalId
      animal {
        id
        numberID
        name
        gender
        birthDate
        breedingId
        lotsId
        zoneId
        buildingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      lotId
      lot {
        id
        numberLot
        name
        buildingId
        breedingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      diseaseId
      disease {
        id
        codeIdentification
        name
        forme
        description
        synonymes
        enjeux
        causes
        circonstancesApparition
        diagnosticDiff
        diagnosticLab
        traitementCuratif
        pronostic
        prevention
        url
        image
        ponderationDisease
        ponderationCount
        familleMaladie
        familleMaladieId
        MRLC
        maladieUrgente
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      shared
      sharedAt
      autoExam
      autoExamRes {
        reponse1
      }
      teleconsultation
      priceConsultation
      diseaseIsVerified
      isDone
      factureId
      facture {
        id
        breedingId
        consultationId
        name
        key
        s3file
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      documents {
        nextToken
      }
      messages {
        nextToken
      }
      sharedFacture
      sharedFactureAt
      teleconsAskAt
      dateTeleCons
      questionResponse
      note
      questionResponseOrder
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateDisease = /* GraphQL */ `
  subscription OnCreateDisease($filter: ModelSubscriptionDiseaseFilterInput) {
    onCreateDisease(filter: $filter) {
      id
      codeIdentification
      name
      forme
      description
      synonymes
      enjeux
      causes
      circonstancesApparition
      diagnosticDiff
      diagnosticLab
      traitementCuratif
      pronostic
      prevention
      url
      consultations {
        nextToken
        startedAt
      }
      image
      ponderationDisease
      ponderationCount
      familleMaladie
      familleMaladieId
      MRLC
      maladieUrgente
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateDisease = /* GraphQL */ `
  subscription OnUpdateDisease($filter: ModelSubscriptionDiseaseFilterInput) {
    onUpdateDisease(filter: $filter) {
      id
      codeIdentification
      name
      forme
      description
      synonymes
      enjeux
      causes
      circonstancesApparition
      diagnosticDiff
      diagnosticLab
      traitementCuratif
      pronostic
      prevention
      url
      consultations {
        nextToken
        startedAt
      }
      image
      ponderationDisease
      ponderationCount
      familleMaladie
      familleMaladieId
      MRLC
      maladieUrgente
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteDisease = /* GraphQL */ `
  subscription OnDeleteDisease($filter: ModelSubscriptionDiseaseFilterInput) {
    onDeleteDisease(filter: $filter) {
      id
      codeIdentification
      name
      forme
      description
      synonymes
      enjeux
      causes
      circonstancesApparition
      diagnosticDiff
      diagnosticLab
      traitementCuratif
      pronostic
      prevention
      url
      consultations {
        nextToken
        startedAt
      }
      image
      ponderationDisease
      ponderationCount
      familleMaladie
      familleMaladieId
      MRLC
      maladieUrgente
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateProtocolSoin = /* GraphQL */ `
  subscription OnCreateProtocolSoin(
    $filter: ModelSubscriptionProtocolSoinFilterInput
  ) {
    onCreateProtocolSoin(filter: $filter) {
      id
      idDiseaseFamily
      name
      Protocol
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateProtocolSoin = /* GraphQL */ `
  subscription OnUpdateProtocolSoin(
    $filter: ModelSubscriptionProtocolSoinFilterInput
  ) {
    onUpdateProtocolSoin(filter: $filter) {
      id
      idDiseaseFamily
      name
      Protocol
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteProtocolSoin = /* GraphQL */ `
  subscription OnDeleteProtocolSoin(
    $filter: ModelSubscriptionProtocolSoinFilterInput
  ) {
    onDeleteProtocolSoin(filter: $filter) {
      id
      idDiseaseFamily
      name
      Protocol
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateProtocolSoinSave = /* GraphQL */ `
  subscription OnCreateProtocolSoinSave(
    $filter: ModelSubscriptionProtocolSoinSaveFilterInput
  ) {
    onCreateProtocolSoinSave(filter: $filter) {
      id
      breedingId
      bseId
      idDiseaseFamily
      new
      name
      Protocol
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateProtocolSoinSave = /* GraphQL */ `
  subscription OnUpdateProtocolSoinSave(
    $filter: ModelSubscriptionProtocolSoinSaveFilterInput
  ) {
    onUpdateProtocolSoinSave(filter: $filter) {
      id
      breedingId
      bseId
      idDiseaseFamily
      new
      name
      Protocol
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteProtocolSoinSave = /* GraphQL */ `
  subscription OnDeleteProtocolSoinSave(
    $filter: ModelSubscriptionProtocolSoinSaveFilterInput
  ) {
    onDeleteProtocolSoinSave(filter: $filter) {
      id
      breedingId
      bseId
      idDiseaseFamily
      new
      name
      Protocol
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification(
    $filter: ModelSubscriptionNotificationFilterInput
    $userId: String
  ) {
    onCreateNotification(filter: $filter, userId: $userId) {
      id
      userId
      user {
        id
        lastname
        firstname
        avatarUri
        userGroup
        optIn
        inscriptionNumberOrdreVeto
        breedingId
        vetOfficeId
        email
        expoToken
        notificationLastSeenAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      type
      title
      body
      data
      clicked
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification(
    $filter: ModelSubscriptionNotificationFilterInput
    $userId: String
  ) {
    onUpdateNotification(filter: $filter, userId: $userId) {
      id
      userId
      user {
        id
        lastname
        firstname
        avatarUri
        userGroup
        optIn
        inscriptionNumberOrdreVeto
        breedingId
        vetOfficeId
        email
        expoToken
        notificationLastSeenAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      type
      title
      body
      data
      clicked
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification(
    $filter: ModelSubscriptionNotificationFilterInput
    $userId: String
  ) {
    onDeleteNotification(filter: $filter, userId: $userId) {
      id
      userId
      user {
        id
        lastname
        firstname
        avatarUri
        userGroup
        optIn
        inscriptionNumberOrdreVeto
        breedingId
        vetOfficeId
        email
        expoToken
        notificationLastSeenAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      type
      title
      body
      data
      clicked
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateNotificationTickets = /* GraphQL */ `
  subscription OnCreateNotificationTickets(
    $filter: ModelSubscriptionNotificationTicketsFilterInput
  ) {
    onCreateNotificationTickets(filter: $filter) {
      id
      expoTokens {
        userId
        token
      }
      ticketIds
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateNotificationTickets = /* GraphQL */ `
  subscription OnUpdateNotificationTickets(
    $filter: ModelSubscriptionNotificationTicketsFilterInput
  ) {
    onUpdateNotificationTickets(filter: $filter) {
      id
      expoTokens {
        userId
        token
      }
      ticketIds
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteNotificationTickets = /* GraphQL */ `
  subscription OnDeleteNotificationTickets(
    $filter: ModelSubscriptionNotificationTicketsFilterInput
  ) {
    onDeleteNotificationTickets(filter: $filter) {
      id
      expoTokens {
        userId
        token
      }
      ticketIds
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
