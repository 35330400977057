import React from 'react';

import { Text } from '@ui-kitten/components';

import { useLinkTo } from '@react-navigation/native';
import MaxWidthContainer from '../../../components/MaxWidthContainer';
import InfoCard from '../../../screenComponents/InfoCard';
import TeleconsultationCard from '../../../components/TeleconsultationCard';
import Button from '../../../components/Button';
import ActivityIndicator from '../../../components/ActivityIndicator';
import VetoBseScreenBL from '../../../screenBusinessLogic/vetoScreensBL/VetoBseScreenBL/VetoBseScreenBL';
import { useUser } from '../../../src/API/UserContext';
import { useBreedingList } from '../../../src/API/Breeding';
import { BSE, StatusBse } from '../../../src/API';
import DateUtils from '../../../utils/DateUtils';
import Tutorial from '../../../components/Tutorial';

export default function VetoBilanSanitaireScreen() {
  const linkTo = useLinkTo();
  const { user } = useUser();
  const { breedings, loading } = useBreedingList(user?.userGroup);
  const bse: BSE[] = [];

  if (breedings) {
    breedings.map((breeding) => breeding.bse.items.map((item) => {
      if (item.StatusBse === StatusBse.BSEAsk
        || item.StatusBse === StatusBse.BSEStarted
        || item.StatusBse === StatusBse.BSEProtocolSoins
        || item.StatusBse === StatusBse.BSEAskStart) bse.push(item);
    }));
  }

  const bseEncours = DateUtils.verificationDateBSE('enCours', bse);
  const bseAVenirs = DateUtils.verificationDateBSE('aVenirs', bse);
  const bseAPlanifiers = DateUtils.verificationDateBSE('aPlanifiers', bse);

  return (
    <MaxWidthContainer
      withScrollView="keyboardAware"
      outerViewProps={{
        style: {
          padding: 24,
        },
      }}
    >
      <Tutorial
        videoId="H2L-7YOALUM"
      />
      <InfoCard
        title="alertInfo"
        iconShow="alert-circle-outline"
        description="Bienvenue sur votre espace dédié au Bilan Sanitaire d'Élevage. Vous pouvez consulter vos prochains BSE à réaliser, les demandes effectuées par les éléveurs et démarrer un BSE."
        alertInfo="alertInfo"
      />
      {/* <Button onPress={() => VetoBseScreenBL.linkToSwitch({ state: 'realiser-bse', linkTo })}>Réaliser un nouveau Bilan Sanitaire d'Élevage</Button> */}
      {loading ? <ActivityIndicator margin={10} center /> : (
        <>
          <Text category="h2" appearance="hint" style={{ marginTop: 40 }}>Bilan Sanitaire d'Élevage en cours</Text>
          {bseEncours && bseEncours.map((bseEncour) => <TeleconsultationCard key={bseEncour.id} consultation="RDV" bseData={bseEncour} />)}

          <Text category="h2" appearance="hint" style={{ marginTop: 40 }}>Demandes de BSE</Text>
          {bseAVenirs && bseAVenirs.map((bseAVenir) => <TeleconsultationCard key={bseAVenir.id} consultation="RDV" bseData={bseAVenir} />)}

          <Text category="h2" appearance="hint" style={{ marginTop: 40 }}>Bilan Sanitaire d'Élevage à planifier</Text>
          {bseAPlanifiers && bseAPlanifiers.map((bseAPlanifier) => <TeleconsultationCard key={bseAPlanifier.id} consultation="RDV" bseData={bseAPlanifier} />)}
        </>
      )}

    </MaxWidthContainer>
  );
}
