import React, { useContext } from 'react';
import { TouchableOpacity } from 'react-native';
import { Icon as IconUIKitten } from '@ui-kitten/components/ui/icon/icon.component';
import RtcContext, { DispatchType } from '../RtcContext';
import { LocalContext } from '../LocalUserContext';
import { useBtnStyle, useIconStyle } from '../useStyles';

function LocalAudioMute() {
  const { dispatch } = useContext(RtcContext);
  const local = useContext(LocalContext);
  const btnStyle = useBtnStyle();
  const iconStyle = useIconStyle();

  return (
    <TouchableOpacity
      style={{ ...btnStyle, ...{ backgroundColor: local.hasAudio ? 'white' : 'orange' } }}
      onPress={() => {
        (dispatch as DispatchType<'LocalMuteAudio'>)({
          type: 'LocalMuteAudio',
          value: [local.hasAudio],
        });
      }}
    >
      <IconUIKitten
        name={local.hasAudio ? 'mic-outline' : 'mic-off-outline'}
        style={iconStyle}
        fill="black"
      />
    </TouchableOpacity>
  );
}

export default LocalAudioMute;
