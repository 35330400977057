import React from 'react';
import { useLinkTo, useRoute } from '@react-navigation/native';
import { Text } from '@ui-kitten/components';
import { RouteProp } from '@react-navigation/core/lib/typescript/src/types';
import MaxWidthContainer from '../../../components/MaxWidthContainer';

import GoCustomCard from '../../../components/GoCustomCard';
import DetailsScreensHeaderCard from '../../../screenComponents/DetailsScreensHeaderCard';
import { TabElevageParamList } from '../../../types';
import { useGetBuilding } from '../../../src/API/Buildings';
import AnimalCard from '../../../screenComponents/AnimalCard';
import ElevageScreenBL from '../../../screenBusinessLogic/ElevageScreen/ElevageScreenBL';

const VetoDetailBatimentScreen = () => {
  const linkTo = useLinkTo();
  const route = useRoute<
  RouteProp<TabElevageParamList, 'detail-batiment'>
  >();
  // console.log('DetailBatimentScreen route: ', route);
  const { building } = useGetBuilding(route?.params?.id);
  // console.log('DetailBatimentScreen data: ', building);

  return (
    <MaxWidthContainer
      outerViewProps={{
        style: {
          padding: 24,
        },
      }}
    >
      <DetailsScreensHeaderCard
        title1="Nom du bâtiment"
        titleInfo1={building?.nameBuilding}
        title2="Adresse"
        titleInfo2={building?.address?.address}
        title3="Complément d'adresse"
        titleInfo3={building?.address?.additionalAddress}
        title4="Code Postal"
        titleInfo4={building?.address?.postalCode}
        title5="Ville"
        titleInfo5={building?.address?.city}
      />

      <Text category="p1" appearance="hint" style={{ marginTop: 20, marginBottom: 10 }}>
        Animaux affectés à ce bâtiment
      </Text>

      {building && building.animals && building.animals.items?.map(
        (animalInThisBuilding) => (
          <AnimalCard
            key={animalInThisBuilding?.id}
            name={animalInThisBuilding?.name}
            numberId={animalInThisBuilding?.numberID}
            numberLot={animalInThisBuilding?.lots?.numberLot}
            onPress={() => {
              ElevageScreenBL.allerDetailScreen(
                { state: 'animaux', linkTo, id: animalInThisBuilding?.id },
              );
            }}
          />
        ),
      )}

      <Text category="p1" appearance="hint" style={{ marginTop: 40, marginBottom: 10 }}>
        Lots affectés à ce bâtiment
      </Text>

      {building && building.lots && building.lots.items?.map(
        (lotInBuilding) => (
          <GoCustomCard
            key={lotInBuilding?.id}
            title1={lotInBuilding?.name}
            title2={`Numéro de lot: ${lotInBuilding?.numberLot}`}
            linkToCustom={() => {
              ElevageScreenBL.allerDetailScreen(
                { state: 'lots', linkTo, id: lotInBuilding?.id },
              );
            }}
            minusSquareOutline="arrow-ios-forward"
          />
        ),
      )}

    </MaxWidthContainer>

  );
};

export default VetoDetailBatimentScreen;
