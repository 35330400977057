import AsyncStorage from '@react-native-async-storage/async-storage';
import * as FileSystem from 'expo-file-system';
import * as MediaLibrary from 'expo-media-library';
import * as IntentLauncher from 'expo-intent-launcher';
import { Alert, Platform } from 'react-native';

export default class DownloadFile {
  static settings = async (newSettings) => new Promise(async (resolve, reject) => {
    try {
      let settings = (await AsyncStorage.getItem('settings').then((result) => JSON.parse(result))) || {};
      if (newSettings) {
        settings = Object.assign(settings, newSettings);
        await AsyncStorage.setItem('settings', JSON.stringify(settings));
      }
      return resolve(settings);
    } catch (e) {
      console.log('Error in settings', e);
      return resolve({});
    }
  });

  static getMediaLibraryPermission = () => MediaLibrary.requestPermissionsAsync();

  static getDirectoryPermissions = async (folder, onDirectoryChange) => new Promise(async (resolve, reject) => {
    try {
      onDirectoryChange({ isSelecting: true }); // For handle appStateChange and loading
      Alert.alert(
        'Choix du dossier de téléchargement',
        'Merci de choisir un dossier pour télécharger votre compte rendu.',
        [
          {
            text: 'Je choisis le dossier',
            onPress: async () => {
              try {
                const permissions = await FileSystem.StorageAccessFramework.requestDirectoryPermissionsAsync();
                this.settings({ downloadsFolder: permissions.granted ? permissions.directoryUri : null });
                // Unfortunately, StorageAccessFramework has no way to read a previously specified folder without popping up a selector.
                // Save the address to avoid asking for the download folder every time
                onDirectoryChange({
                  downloadsFolder: permissions.granted ? permissions.directoryUri : null,
                  isSelecting: false,
                });
                return resolve(permissions.granted ? permissions.directoryUri : null);
              } catch (e) {
                console.log('Error in getDirectoryPermissions', e);
                onDirectoryChange({ downloadsFolder: null });
                return resolve(null);
              }
            },
          }],
      );
    } catch (e) {
      console.log('Error in getDirectoryPermissions', e);
      onDirectoryChange({ downloadsFolder: null });
      return resolve(null);
    }
  });

  static downloadFilesAsync = async (files, folder, onDirectoryChange) =>
  // files = [{url: "url", fileName: "new file name" + "extension", mimeType: is_video ? "video/mp4" : "image/jpg"}]
  // onDirectoryChange = () => {cb_something_like_setState()}
    new Promise(async (resolve, reject) => {
      try {
        const mediaLibraryPermission = await this.getMediaLibraryPermission();
        if (mediaLibraryPermission.status !== 'granted') {
          return resolve({ status: 'error' });
        }
        const settings = await this.settings();
        // Unfortunately, StorageAccessFramework has no way to read a previously specified folder without popping up a selector.
        // Save the address to avoid asking for the download folder every time
        const androidSDK = Platform.constants.Version;
        console.log(androidSDK);
        if (Platform.OS === 'android' && androidSDK >= 30 && !settings.downloadsFolder) {
          // Except for Android 11, using the media library works stably
          console.log('should alert');
          settings.downloadsFolder = await this.getDirectoryPermissions(folder, onDirectoryChange);
          console.log('settings', settings.downloadsFolder);
        }
        const results = await Promise.all(
          files.map(async (file) => {
            try {
              if (file.url.includes('https://')) {
                // Remote file
                const {
                  uri, status, headers, md5,
                } = await FileSystem.downloadAsync(
                  file.url,
                  FileSystem.cacheDirectory + file.name,
                );
                file.url = uri; // local file(file:///data/~~~/content.jpg)
                // The document says to exclude the extension, but without the extension, the saved file cannot be viewed in the Gallery app.
              }
              if (Platform.OS === 'android' && settings.downloadsFolder) {
                // Creating files using SAF
                // I think this code should be in the documentation as an example
                const fileString = await FileSystem.readAsStringAsync(file.url, { encoding: FileSystem.EncodingType.Base64 });
                const newFile = await FileSystem.StorageAccessFramework.createFileAsync(
                  settings.downloadsFolder,
                  file.name,
                  file.mimeType,
                );
                await FileSystem.writeAsStringAsync(newFile, fileString, { encoding: FileSystem.EncodingType.Base64 });
                Alert.alert(
                  'Le fichier a été téléchargé',
                  'Voulez-vous l\'afficher ?',
                  [{
                    text: 'Non',
                    style: 'cancel',
                  },
                  {
                    text: 'Oui',
                    onPress: async () => {
                      try {
                        await IntentLauncher.startActivityAsync('android.intent.action.VIEW', { data: newFile, type: file.mimeType, flags: 1 });
                      } catch (error) {}
                    },
                  }],
                );
              } else {
                // Creating files using MediaLibrary
                const asset = await MediaLibrary.createAssetAsync(file.url);
                let album = await MediaLibrary.getAlbumAsync(folder);
                if (album == null) {
                  album = await MediaLibrary.createAlbumAsync(folder, asset, false);
                } else {
                  await MediaLibrary.addAssetsToAlbumAsync([asset], album, false);
                }
                Alert.alert(
                  'Le fichier a été téléchargé',
                  'Voulez-vous l\'afficher ?',
                  [{
                    text: 'Non',
                    style: 'cancel',
                  },
                  {
                    text: 'Oui',
                    onPress: async () => {
                      console.log(album);
                      const assets = await MediaLibrary.getAssetsAsync({
                        album, first: 1, sortBy: ['creationTime'], mediaType: ['unknown'],
                      });
                      if (assets) {
                        console.log(assets);
                        const assetInfo = await MediaLibrary.getAssetInfoAsync(assets.assets[0]);
                        try {
                          await IntentLauncher.startActivityAsync('android.intent.action.VIEW', {
                            data: assetInfo.localUri,
                            type: file.mimeType,
                            flags: 1,
                          });
                        } catch (error) {
                          console.error(error);
                        }
                      }
                    },
                  }],
                );
              }
              return await Promise.resolve({ status: 'ok' });
            } catch (e) {
              console.log(e);
              return Promise.resolve({ status: 'error' });
            }
          }),
        );
        return resolve({ status: results.every((result) => result.status === 'ok') ? 'ok' : 'error' });
      } catch (e) {
        console.log('Error in downloadFilesAsync', e);
        return resolve({ status: 'error' });
      }
    })
  ;
}
