import React, {
  createContext,
  useContext,
  useEffect,
  useMemo, useRef,
  useState,
} from 'react';

import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { HubCapsule } from 'aws-amplify-react-native/types';
import PropTypes from 'prop-types';
import { useApolloClient, useLazyQuery } from 'react-apollo';
import { gql } from 'graphql-tag';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { FetchResult } from 'apollo-link';
import _ from 'lodash';
import { CognitoAccessToken } from 'amazon-cognito-identity-js';
import {
  CreateUserInput,
  CreateUserMutation,
  CreateUserMutationVariables, GetUserQueryVariables,
  GetUserQuery,
  UpdateUserInput,
  UpdateUserMutation,
  UpdateUserMutationVariables, LockedStatus,
} from '../API';
import * as mutations from '../graphql/mutations';
import { removeKey, removeNull } from '../../utils/ObjectHelper';
import { getUser } from '../graphql/queries';
import { onUpdateUser } from '../graphql/subscriptions';

type SimpleCreateUserInput = Omit<CreateUserInput, 'lastname' | 'firstname'>;
type SimpleUpdateUserInput = Omit<UpdateUserInput, 'id'>;

export type UserItem = {
  __typename: 'User',
  id: string,
  lastname?: string | null,
  firstname?: string | null,
  avatarUri?: string | null,
  userGroup?: string | null,
  optIn?: boolean | null,
  inscriptionNumberOrdreVeto?: string | null,
  breedingId?: string | null,
  breeding?: {
    __typename: 'Breeding',
    id: string,
    locked: LockedStatus,
    nBreeding: string,
    nSIRET: string,
    phone: string,
    emailEleveur: string,
    companyName: string,
    dairyFarm: boolean,
    sucklerCowHusbandry: boolean,
    fatteningFarm: boolean,
    nbCow: number,
    nbHeifer: number,
    nbMale: number,
    nbYoung: number,
    idVeto?: string | null,
    emailVeto?: string | null,
    admins: Array< string >,
    mpUserId?: string | null,
    mpWalletId?: string | null,
    mpBankId?: string | null,
    createdAt: string,
    dateFirstCons?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
  vetOfficeId?: string | null,
  vetOffice?: {
    __typename: 'VetOffice',
    id: string,
    locked: LockedStatus,
    companyName: string,
    emailOffice: string,
    admins: Array< string >,
    personnel?: Array< string > | null,
    phoneVetOffice?: string | null,
    nameVetSanitaire?: string | null,
    nOrdreCabinet: string,
    nSIRET: string,
    maxPriceTeleConsultation: number,
    maxPriceConsultation: number,
    mpUserId?: string | null,
    mpWalletId?: string | null,
    mpBankId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
  email?: string | null,
  expoToken?: Array< string > | null,
  notificationLastSeenAt?: string | null,
  messages?: {
    __typename: 'ModelMessageConnection',
    nextToken?: string | null,
  } | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export interface CognitoUserInterface {
  Session?: string | null;
  authenticationFlowType?: string;
  client?: {
    endpoint?: string;
    userAgent?: string;
  };
  keyPrefix?: string;
  pool?: {
    advancedSecurityDataCollectionFlag?: boolean;
    clientId?: string;
    userPoolId?: string;
  };
  username?: string;
  userConfirmed?: boolean;
  userSub?: string;
  challengeName: string;
  challengeParam: { [key: string]: any };
  unverified?: {
    email?: string;
    phone_number?: string;
  };
  [attributes: string]: any;
}

type UserContextProps = {
  user: UserItem;
  cognitoUser: CognitoUserInterface;
  jwt: string;
  isVeto: boolean;
  isAdmin: boolean;
  userIsLoading: boolean;
  userIsCreating: boolean;
  updateUser: (inputVars: SimpleUpdateUserInput, argUser?: UserItem) =>
  Promise<FetchResult<UpdateUserMutation>>;
  createUser: (inputVars: SimpleCreateUserInput) =>
  Promise<FetchResult<CreateUserMutation>>;
};

const UserContext = createContext<Partial<UserContextProps>>({});

const getUserAuthenticatedAST = gql(getUser);

const UserProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<UserItem | undefined>();
  const unsubscribe = useRef(() => {});
  const [getUserFromDataBase, { subscribeToMore, refetch }] = useLazyQuery<
  GetUserQuery,
  GetUserQueryVariables
  >(
    getUserAuthenticatedAST,
    {
      errorPolicy: 'all',
      onCompleted: (data) => {
        console.log(`data${data}`);
        setUser(data?.getUser || undefined);
        setIsVeto(data?.getUser?.userGroup === 'veto');
        setIsAdmin(data?.getUser?.userGroup === 'admin');
        setUpdateMemo(Math.random().toString(36).substring(2, 15));
        if (loading) {
          // on vient de se reconnecter, donc il faut relancer la souscription
          setRefresh(!refresh);
        }
        setLoading(false);
      },
      onError: (error) => {
        console.error(error);
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    },
  );
  const [cognitoUser, setCognitoUser] = useState<CognitoUserInterface | undefined>();
  const [jwt, setJwt] = useState<string | undefined>();
  const [loading, setLoading] = useState(true);
  const [isVeto, setIsVeto] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [updateMemo, setUpdateMemo] = useState('');
  const [userIsCreating, setUserIsCreating] = useState(false);
  const client = useApolloClient();

  const currentUser = async () => {
    console.log('loading current user');
    try {
      setLoading(true);
      const authUser = await Auth.currentAuthenticatedUser();
      const authSession = await Auth.currentSession();
      setJwt(authSession.getAccessToken().getJwtToken());
      setCognitoUser(authUser);
      console.log('start getting from DB');
      getUserFromDataBase({ variables: { id: authUser.attributes.sub } });
      setRefresh(!refresh);
      if (authUser?.attributes.given_name) {
        AsyncStorage.setItem('lastFirstname', authUser?.attributes.given_name);
      }
      authUser.getCachedDeviceKeyAndPassword();
      const stayConnected = await AsyncStorage.getItem('stayConnected');
      if (stayConnected === 'true') {
        authUser.setDeviceStatusRemembered({
          onSuccess: () => {},
          onFailure: () => {},
        });
      } else {
        authUser.setDeviceStatusNotRemembered({
          onSuccess: () => {},
          onFailure: () => {},
        });
      }
    } catch (error) {
      // console.log(error);
      setLoading(false);
      setIsVeto(false);
      setIsAdmin(false);
      setUser(undefined);
      setUpdateMemo(Math.random().toString(36).substring(2, 15));
      setCognitoUser(undefined);
      setJwt(undefined);
    }
  };

  const authListener = ({ payload: { event } }: HubCapsule) => {
    switch (event) {
      case 'signIn':
        currentUser();
        break;
      case 'signOut':
        setLoading(true);
        setIsVeto(false);
        setIsAdmin(false);
        setUser(undefined);
        setUpdateMemo(Math.random().toString(36).substring(2, 15));
        setCognitoUser(undefined);
        setJwt(undefined);
        unsubscribe.current();
        client.cache.reset();
        /* if (Platform.OS === 'web') {
          // hack pour couper le websocket car je n'ai rien trouvé d'autre
          document.location.reload();
        } */
        setLoading(false);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    currentUser();
    const listener = Hub.listen('auth', authListener);
    return () => listener();
  }, []);

  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    let unsubscribeLocaly = () => {};
    if (user?.id) {
      setIsVeto(user.userGroup === 'veto');
      setIsAdmin(user.userGroup === 'admin');
      unsubscribeLocaly = subscribeToMore({
        document: gql(onUpdateUser),
        variables: { id: user.id },
        updateQuery: (prev) => {
          // on doit refetch, car les champs ne sont pas tous accessibles
          // console.log(prev);
          getUserFromDataBase({ variables: { id: cognitoUser?.attributes.sub } });
          return prev;
        },
        onError: (e) => {
          // console.log(e);
          setRefresh(!refresh);
        },
      });
      unsubscribe.current = unsubscribeLocaly;
    }

    return () => {
      if (unsubscribeLocaly) {
        unsubscribeLocaly();
      }
    };
  }, [refresh]);

  const values = useMemo(() => {
    const createUser = (inputVars: SimpleCreateUserInput) => {
      const finalInputVars = {
        id: cognitoUser?.attributes.sub,
        lastname: cognitoUser?.attributes.family_name,
        firstname: cognitoUser?.attributes.given_name,
        email: cognitoUser?.attributes.email,
        userGroup: cognitoUser?.attributes['custom:groupe'],
        optIn: cognitoUser?.attributes['custom:optIn'] === 'true',
      };
      _.merge(finalInputVars, inputVars);

      setUserIsCreating(true);
      return client.mutate<
      CreateUserMutation,
      CreateUserMutationVariables
      >({
        mutation: gql(mutations.createUser),
        // @ts-ignore
        optimisticResponse: (vars) => ({
          createUser: {
            __typename: 'User',
            id: cognitoUser?.attributes.sub,
            ...user,
            ...vars.input,
            // eslint-disable-next-line no-underscore-dangle
            _version: (vars.input?._version ?? 0) + 1,
          },
        }),
        variables: {
          input: finalInputVars,
        },
        update: () => {
          getUserFromDataBase({ variables: { id: cognitoUser?.attributes.sub } });
        },
      });
    };

    const updateUser = (inputVars: SimpleUpdateUserInput, argUser?: UserItem) => {
      if (user || argUser) {
        // console.log(removeKey(_.merge(argUser || user,
        //  inputVars), ['_deleted', '_lastChangedAt', 'createdAt', 'updatedAt', '__typename', '_version']));
        return client.mutate<
        UpdateUserMutation,
        UpdateUserMutationVariables
        >({
          mutation: gql(mutations.updateUser),
          // @ts-ignore
          optimisticResponse: (vars) => ({
            updateUser: _.merge(argUser || user, {
              ...vars.input,
              // eslint-disable-next-line no-underscore-dangle
              _version: (vars.input?._version ?? 0) + 1,
            }),
          }),
          variables: {
            input: {
              ...(removeKey(_.merge(argUser || user,
                inputVars), ['_deleted', '_lastChangedAt', 'createdAt', 'updatedAt', '__typename', '_version', 'breeding', 'vetOffice', 'messages', 'signatureVeto'])),
              // eslint-disable-next-line no-underscore-dangle
              _version: (argUser || user)._version,
            },
          },
          update: (cache, { data: mutationData }) => {
            // console.log(mutationData);
            if (mutationData) {
              const { updateUser: mutationUpdateUser } = mutationData;
              if (mutationUpdateUser) {
                // Read query from cache
                const cacheData = cache.readQuery<GetUserQuery,
                GetUserQueryVariables>({
                  query: getUserAuthenticatedAST,
                  variables: {
                    id: cognitoUser?.attributes.sub,
                  },
                });

                // Add newly created item to the cache copy
                if (cacheData && cacheData.getUser) {
                  const merged = _.merge(argUser || user, {
                    // eslint-disable-next-line no-underscore-dangle
                    _version: mutationUpdateUser._version + 1,
                    ...inputVars,
                    ...(removeNull(mutationUpdateUser) as UpdateUserMutation),
                  });
                  cacheData.getUser = merged;

                  setUser(merged);
                  setUpdateMemo(Math.random().toString(36).substring(2, 15));

                  // Overwrite the cache with the new results
                  cache.writeQuery({
                    query: getUserAuthenticatedAST,
                    variables: {
                      id: cognitoUser?.attributes.sub,
                    },
                    data: cacheData,
                  });
                }
              }
            }
            setUserIsCreating(false);
          },
        });
      }
      throw new Error("User doesn't exist, cannot be updated");
    };

    return {
      isVeto,
      isAdmin,
      user,
      cognitoUser,
      jwt,
      userIsLoading: loading,
      updateUser,
      createUser,
      refetch,
      userIsCreating,
    };
  }, [user, jwt, loading, cognitoUser, userIsCreating, updateMemo, isVeto, isAdmin]);

  return (
    <UserContext.Provider
      value={values}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node,
};

const useUser = () => useContext(UserContext);

export { useUser, UserContext, UserProvider };
