import React, { useState } from 'react';
import { useLinkTo, useRoute } from '@react-navigation/native';

import { Text, useTheme } from '@ui-kitten/components';

import { RouteProp } from '@react-navigation/core/lib/typescript/src/types';
import { Platform } from 'react-native';
import MaxWidthContainer from '../../components/MaxWidthContainer';

import PartageDemandeConsultCards
  from '../../screenComponents/ConsultationScreenComps/DetailConsultationScreen/PartageDemandeConsultCards';
import MesEchangesAvecVetCards
  from '../../screenComponents/ConsultationScreenComps/DetailConsultationScreen/MesEchangesAvecVetCards';

import Button from '../../components/Button';
import InfoCard from '../../screenComponents/InfoCard';
import GoCustomCard from '../../components/GoCustomCard';
import DetailsScreensHeaderCard from '../../screenComponents/DetailsScreensHeaderCard';
import { TabConsultationParamList } from '../../types';
import {
  useCreateConsultationMutation,
  useDeleteConsultationMutation,
  useGetConsultation,
  useUpdateConsultationMutation,
} from '../../src/API/Consultation';
import ActivityIndicator from '../../components/ActivityIndicator';
import DateUtils from '../../utils/DateUtils';
import CustomModal from '../../components/Modals/CustomModal';
import DiseaseCard from '../../screenComponents/ConsultationScreenComps/DiseaseCard';
import { typeGenderCustom } from '../../src/selectFormData/ajoutAnimalData';

const DetailConsultationScreen = () => {
  const linkTo = useLinkTo();
  const theme = useTheme();

  const route = useRoute<RouteProp<TabConsultationParamList, 'detail-consultation'>>();
  // console.log('route detail exam:', route.params);

  const [openDelete, setOpenDelete] = useState(true);
  const [openDeleteConsultation, setOpenDeleteConsultation] = useState(false);
  const [openModifyConsultation, setOpenModifyConsultation] = useState(false);

  const { consultation, loading } = useGetConsultation(route.params?.id);
  const { modifyConsultation } = useUpdateConsultationMutation();
  const { supprimerConsultation } = useDeleteConsultationMutation();
  const { createConsultation } = useCreateConsultationMutation();
  // console.log('Detail consultation:', consultation.disease.name);

  /**
  useEffect(() => {
    (async () => {
      const hasAskedCreate = await AsyncStorage.getItem('hasAskedCreate');
      // check if details of consultation exist
      if (hasAskedCreate !== 'true') {
        // console.log('hasAskedCreate');
      }
    })();
    return () => {
    };
  }, []);

   */
  const deleteConsultation = async () => {
    await supprimerConsultation({
      variables: {
        input: {
          id: route.params.id,
          // eslint-disable-next-line no-underscore-dangle
          _version: consultation._version,
        },
      },
    });
    linkTo('/consultation');
    setOpenDeleteConsultation(false);
  };
  const onModifyConsultation = async () => {
    if (consultation) {
      await createConsultation({
        variables: {
          input: {
            breedingId: consultation.breedingId,
            animalId: consultation.animalId ? consultation.animalId : undefined,
            autoExam: true,
            lotId: consultation.lotId ? consultation.lotId : undefined,
            questionResponseOrder: consultation.questionResponseOrder,
            questionResponse: consultation.questionResponse,
          },
        },
      }).then((value) => linkTo(`/consultation/questionnaire/${value?.data?.createConsultation?.id}`));
      setOpenModifyConsultation(false);
    }
  };
  return (
    <>
      <MaxWidthContainer
        outerViewProps={{
          style: {
            padding: 24,
          },
        }}
      >
        {loading
          ? (<ActivityIndicator center margin={10} />)
          : (
            <>
              {consultation && (
              <DetailsScreensHeaderCard
                title1={"Date de l'auto-examen"}
                titleInfo1={DateUtils.convertDate(consultation.createdAt)}
                title2={`N° d'identification de ${(consultation.animal?.numberID && "l'animal") || (consultation.lot?.numberLot && 'lot')}`}
                titleInfo2={consultation?.animal?.numberID || consultation.lot?.numberLot}
                title3={(consultation?.animal?.name && "Nom de l'animal") || (consultation?.lot?.name && 'Nom de lot')}
                titleInfo3={consultation?.animal?.name || consultation?.lot?.name}
                title4={consultation?.animal?.gender ? 'Sexe' : undefined}
                titleInfo4={consultation?.animal?.gender ? typeGenderCustom[consultation?.animal?.gender].label : undefined}
                title5={consultation?.animal ? 'Age' : undefined}
                titleInfo5={DateUtils.getAge(consultation?.animal)}
                title6="Maladie sélectionnée"
                titleInfo6={consultation?.disease && consultation?.disease?.name}
              />
              )}
            </>

          )}
        {(consultation) && (
          <>
            <PartageDemandeConsultCards
              consultationData={consultation}
              modifyConsultation={modifyConsultation}
            />

            <MesEchangesAvecVetCards />
              {(consultation.autoExam) && (
                (consultation.isDone) ? (
                  <>
                    <Text category="h2" appearance="hint" style={{ marginTop: 40, marginBottom: 5 }}>Accéder au questionnaire</Text>
                    <GoCustomCard title1="Questionnaire" linkToCustom={() => { linkTo(`/consultation/questionnaire/${consultation?.id}?update=${false}`); }} minusSquareOutline="arrow-ios-forward" />
                    <InfoCard
                      title="alertInfo"
                      iconShow="alert-circle-outline"
                      // iconCloseShow="close-outline"
                      description="Vous pouvez consulter le questionnaire mais vous ne pouvez pas modifier vos réponses ici."
                      alertInfo="alertInfo"
                      infoStyle={{ marginTop: 20 }}
                    />

                    <Text category="h2" appearance="hint" style={{ marginTop: 40, marginBottom: 5 }}>Maladie sélectionnée</Text>
                    {consultation && consultation.disease && (
                    <DiseaseCard
                      key={consultation.disease.id}
                      currentDisease={consultation.disease}
                      consultation={consultation}
                    />
                    )}

                    <GoCustomCard title1="Les maladies possibles" linkToCustom={() => linkTo(`/consultation/questionnaire/list-disease/${route?.params?.id}`)} minusSquareOutline="arrow-ios-forward" />
                    <Button
                      leftIcon="edit-outline"
                      appearance="outline"
                      leftIconFill={theme['color-primary-600']}
                      style={{ marginTop: 20 }}
                      onPress={() => { setOpenModifyConsultation(true); }}
                    >
                      Modifier l'auto-examen
                    </Button>
                    <InfoCard
                      title="alertInfo"
                      iconShow="alert-circle-outline"
                      // iconCloseShow="close-outline"
                      description="Une nouvelle version de l'auto-examen sera créée en cas de modification."
                      alertInfo="alertInfo"
                      infoStyle={{ marginTop: 20 }}
                    />

                  </>
                ) : (
                  <>
                    <Text category="h6" style={{ textAlign: 'center', marginTop: 20 }}>L'auto-examen n'a pas été terminé.</Text>
                    <Text category="s2" style={{ textAlign: 'center', marginBottom: 20 }}>
                      Vous pouvez reprendre le questionnaire à l'endroit où vous l'avez
                      quitté en cliquant sur le bouton ci-dessous.
                    </Text>
                    <Button
                      leftIcon="refresh-outline"
                      leftIconFill="#fff"
                      onPress={() => { linkTo(`/consultation/questionnaire/${consultation?.id}?enCours=true`); }}
                    >
                      Reprendre l'auto-examen
                    </Button>
                  </>
                )
              )}
          </>
        )}

        <Button status="danger" appearance="outline" onPress={() => setOpenDeleteConsultation(true)} style={{ marginTop: 40, backgroundColor: 'white' }}>Supprimer la consultation</Button>

      </MaxWidthContainer>

      <CustomModal
        title={'Vous êtes sur le point de supprimer cette consultation.'
          + '\n'
          + 'Êtes-vous sûr de vouloir continuer ?'}
        before={<></>}
        noSafeArea
        visible={openDeleteConsultation}
        scrollable={false}
        onClose={() => setOpenDeleteConsultation(false)}
        insideModalStyle={{ textAlign: 'center', padding: 20 }}
        buttonText="Confirmer"
        secondButtonText="Annuler"
        heightPercentage={Platform.OS === 'web' ? 0.3 : 0.6}
        buttonPress={() => { deleteConsultation(); }}
      />

      <CustomModal
        title={'Vous êtes sur le point de modifier votre auto-examen.'
            + '\n'
            + 'Cette modification crééra une copie de celui-ci'}
        before={<></>}
        subtitle="Êtes-vous sûr de vouloir continuer ?"
        noSafeArea
        visible={openModifyConsultation}
        scrollable={false}
        onClose={() => setOpenModifyConsultation(false)}
        insideModalStyle={{ textAlign: 'center', padding: 20 }}
        buttonText="Confirmer"
        secondButtonText="Annuler"
        secondButtonPress={() => setOpenModifyConsultation(false)}
        heightPercentage={Platform.OS === 'web' ? 0.3 : 0.6}
        buttonPress={() => { onModifyConsultation(); }}
      />

    </>
  );
};
export default DetailConsultationScreen;
