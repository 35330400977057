import {
  CheckBox, Icon, Text, useTheme,
} from '@ui-kitten/components';
import React, { useEffect, useState } from 'react';
import * as Sharing from 'expo-sharing';
import {
  Platform, TouchableOpacity, View, ViewStyle,
} from 'react-native';
import { Storage } from 'aws-amplify';
import * as FileSystem from 'expo-file-system';
import * as MediaLibrary from 'expo-media-library';
import Card from '../Card';
import { waitingDirectory } from '../../utils/S3FileStorage';
import ActivityIndicator from '../ActivityIndicator';
import Button from '../Button';
import { DocumentItem, useDeleteDocumentMutation } from '../../src/API/Document';
import CustomModal from '../Modals/CustomModal';
import DownloadFile from '../../utils/DownloadFile';

type DocumentProps = {
  supprimer?: boolean,
  telecharger?: boolean,
  facture?: boolean,
  facturePrice?: number | null | undefined,
  document: DocumentItem
  onCheck?: (checked: boolean) => void,
  checked?: boolean,
  // deleteDoc?: () => void
  customTelechargerSTYLE?: ViewStyle
  data?: {
    factureId: string,
  }
  refetch: () => {}
};

const DocumentComponent = (props: DocumentProps) => {
  const {
    document, telecharger, supprimer = false,
    facture, facturePrice,
    checked = false, onCheck, customTelechargerSTYLE,
    modifyConsultation, data, refetch, shareFactureToConsultation,
  } = props;
  // console.log('props', document);
  const theme = useTheme();
  const [sharing, setSharing] = useState(false);

  async function getMediaLibraryPermissions() {
    await MediaLibrary.requestPermissionsAsync();
  }

  useEffect(() => {
    getMediaLibraryPermissions();
  });

  const shareDoc = async () => {
    if (document) {
      setSharing(true);
      try {
        const url = await Storage.get(document.s3file);
        if (Platform.OS === 'web') {
          window.open(url as string);
        } else {
          const directory = `${FileSystem.cacheDirectory + waitingDirectory}temp/`;

          try {
            await FileSystem.makeDirectoryAsync(
              directory,
              { intermediates: true },
            );
          } catch (e) {
            // eslint-disable-next-line no-console
            console.log('Document Component error 1 : ', e);
          }

          const res = await FileSystem.downloadAsync(
            url as string,
            directory + encodeURIComponent(document.name.replace(/\//g, '_')),
          );
          console.log(res);
          if (res.uri) {
            if (Platform.OS === 'ios') {
              await Sharing.shareAsync(res.uri);
              await FileSystem.deleteAsync(res.uri);
            } else {
              const folder = 'scanflock';
              console.log(res.uri);
              const test = await DownloadFile.downloadFilesAsync(
                [
                  {
                    url: res.uri,
                    name: document.name.replace(/\//g, '_'),
                    mimeType: res.headers['Content-Type'],
                  },
                ], folder, () => {},
              );/*
              const asset = await MediaLibrary.createAssetAsync(res.uri);
              console.log('asset', asset);
              const album = await MediaLibrary.getAlbumAsync(folder);
              if (album == null) {
                await MediaLibrary.createAlbumAsync(folder, asset, false);
              } else {
                const res2 = await MediaLibrary.addAssetsToAlbumAsync([asset], album, false);
                console.log('album', res2);
              }
              */
            }
          }
        }
        setSharing(false);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Document Component error : ', e);
      }
    }
  };
  // console.log('document component', document);

  const [openDelete, setOpenDelete] = useState();

  const deleteDoc = useDeleteDocumentMutation();
  // console.log('EDE elevage:', document);
  // console.log('data:', data);
  const supprimerDocument = async () => {
    // console.log('facture consultation:', data);
    console.log('EDE elevage:', document);

    /** DELETE EDE doc (établissement départemental d'élevage)   */
    if (document) {
      await deleteDoc({
        variables: {
          input: {
            id: document.id,
            // eslint-disable-next-line no-underscore-dangle
            _version: document._version,
          },
        },
      });
    }
    if (refetch) {
      await refetch();
    }

    /** DELETE Signature    */
    if (document.key?.includes('Signature')) {
      // console.log('includes');

      await deleteDoc({
        variables: {
          input: {
            id: document.id,
            // eslint-disable-next-line no-underscore-dangle
            _version: document._version,
          },
        },
      });
    }

    /** DELETE Facture   */
    if (data && data.factureId && data.facture) {
      await deleteDoc({
        variables: {
          input: {
            id: data.factureId,
            // eslint-disable-next-line no-underscore-dangle
            _version: data.facture._version,
          },
        },
      });

      await modifyConsultation({
        variables: {
          input: {
            id: data.id,
            // eslint-disable-next-line no-underscore-dangle
            _version: data._version,
            factureId: null,
            sharedFacture: false,
          },
        },
      });
    }
    setOpenDelete(undefined);
  };

  return (document ? (
    <>
      {telecharger ? (
        <>
          <Button
            onPress={() => { shareDoc(); }}
            status="primary"
            leftIcon="download-outline"
            leftIconFill={theme['color-primary-500']}
            appearance="ghost"
            style={[customTelechargerSTYLE, { justifyContent: 'flex-start' }]}
          >
            Télécharger
          </Button>
        </>
      ) : (
        <Card
          onPress={() => { shareDoc(); }}
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 15,
            borderWidth: checked ? (1) : (0),
            borderColor: 'red',
          }}
        >
          {supprimer && (
          <View style={{ justifyContent: 'center', paddingHorizontal: 14, width: 50 }}>
            <CheckBox
              checked={checked}
              status="danger"
              onChange={(nextChecked) => {
                if (onCheck) {
                  onCheck(nextChecked);
                }
              }}
            />
          </View>
          )}
          <View style={[{ flex: 1 }, facture && { flexDirection: 'column' }]}>
            <Text category="h3">
              {document.name}
            </Text>
            {facture && (<Text category="h6" appearance="hint">{`${facturePrice || ''} €`}</Text>)}
          </View>

          {sharing ? <ActivityIndicator style={{ height: 24, width: 24 }} />
            : <Icon name="download-outline" fill="#000" style={{ height: 24, width: 24 }} />}
          {!facture && (
          <TouchableOpacity
            onPress={() => { setOpenDelete('delete document'); }}
            style={{ marginHorizontal: 15 }}
          >
            <Icon name="trash-outline" fill="#FF0000" style={{ height: 24, width: 24 }} />
          </TouchableOpacity>
          )}

        </Card>
      )}
      <CustomModal
        title={`Vous êtes sur le point de ${openDelete !== 'share facture' ? `supprimer le document ${document.name}.` : 'partager une facture à cet élevage.'} `
          + '\n'
          + 'Souhaitez vous continuer ?'}
        before={<></>}
        noSafeArea
        visible={openDelete !== undefined}
        scrollable={false}
        onClose={() => { setOpenDelete(undefined); }}
        insideModalStyle={{ padding: 20 }}
        buttonText={openDelete !== 'share facture' ? 'Supprimer' : 'Partager'}
        secondButtonText="Annuler"
        heightPercentage={0.36}
        buttonPress={() => { openDelete !== 'share facture' ? supprimerDocument() : shareFactureToConsultation(); }}
      />
    </>

  ) : (<></>)
  );
};

export default DocumentComponent;
