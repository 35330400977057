import moment from 'moment';
import DateUtils from '../utils/DateUtils';

type SearchItemsProps = {
  data: Array<object> | undefined | null,
  searchedText: string | number | boolean | undefined,
  searchedDate: string | undefined
};

/** Seulement si le texte recherché n'est pas vide */
// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle
const searchItems = ({
  data, searchedText, searchedDate,
}: SearchItemsProps) => {
  // console.log('data _searchItems:', data);

  let lowerSearchedText: string | number | boolean | undefined;
  if (searchedText && typeof searchedText !== 'number') {
    lowerSearchedText = searchedText.toLowerCase();
  }

  let filtered = data;
  if (filtered && (lowerSearchedText || searchedDate)) {
    if (searchedText && lowerSearchedText && lowerSearchedText.length > 0) {
      filtered = filtered.filter((item) => {
        // console.log('item:', item);

        // search Animal by name
        if (item && item.name && lowerSearchedText && lowerSearchedText.length > 0) {
          if (item.name.toLowerCase().indexOf(lowerSearchedText) > -1) {
            return true;
          }
        }
        // search Animal by numberID
        if (item.numberID && lowerSearchedText.length > 0) {
          const numberIdAnimal = item.numberID.toString();
          if (numberIdAnimal.indexOf(lowerSearchedText) > -1) {
            return true;
          }
        }
        // search Animal by numberLot Elevage part
        if (item.numberLot && lowerSearchedText.length > 0) {
          const numberIdAnimal = item.numberLot.toString();
          if (numberIdAnimal.indexOf(lowerSearchedText) > -1) {
            return true;
          }
        }
        // search lot in Animal by numberLot Veto part
        if (item.lots && item.lots.numberLot && lowerSearchedText.length > 0) {
          const numberLotInAnimal = item.lots.numberLot.toString();
          if (numberLotInAnimal.indexOf(lowerSearchedText) > -1) {
            return true;
          }
        }
        // search Building by name
        if (item.nameBuilding && lowerSearchedText.length > 0) {
          if (item.nameBuilding.indexOf(lowerSearchedText) > -1) {
            return true;
          }
        }
        // search consultation by name of animal
        if (item && item.animalId && item.animal && lowerSearchedText.length > 0) {
          // const { animal } = useGetAnimal(item.animalId);
          if (item?.animal.name.toLowerCase().indexOf(lowerSearchedText) > -1) {
            return true;
          }
        }
        // search consultation by animal numberID
        if (item.animalId && item.animal && lowerSearchedText.length > 0) {
          // console.log('numberID', item?.animal.numberID);
          const numberIdAnimal = item?.animal.numberID.toString();
          if (numberIdAnimal.indexOf(lowerSearchedText) > -1) {
            return true;
          }
        }

        // search companyName(nom de l'elevage) breeding inside consultation
        if (item && item.breeding && lowerSearchedText.length > 0) {
          const numberBreeding = item.breeding.nBreeding.toString();
          if (numberBreeding.indexOf(lowerSearchedText) > -1) {
            return true;
          }
        }

        // search nBreeding breeding inside consultation
        if (item && item.breeding && lowerSearchedText.length > 0) {
          if (item.breeding.companyName.toLowerCase().indexOf(lowerSearchedText) > -1) {
            return true;
          }
        }

        // search consultation created by animal
        // search consultation by number lots in animal
        // search consultation by name lots in animal
        if (item.animal && item.animal.lotsId && lowerSearchedText.length > 0) {
          const numberLot = item?.animal.lots.numberLot.toString();
          const nameLot = item?.animal.lots.name.toString();
          // console.log('lotsIdLot', item);
          // lot by numberLot
          if (numberLot.toLowerCase().indexOf(lowerSearchedText) > -1) {
            return true;
          }
          // lot by name Lot
          if (nameLot.toLowerCase().indexOf(lowerSearchedText) > -1) {
            return true;
          }
        }

        // console.log('lot', item);
        // search consultation created by lot
        if (item.lot && lowerSearchedText.length > 0) {
          const nameLot = item?.lot.name.toString();
          const numberLot = item?.lot.numberLot.toString();

          // lot by numberLot
          if (numberLot.toLowerCase().indexOf(lowerSearchedText) > -1) {
            return true;
          }
          // lot by name Lot
          if (nameLot.toLowerCase().indexOf(lowerSearchedText) > -1) {
            return true;
          }
        }
        // console.log('item:', item);
        if (item.companyName && item.companyName.toLowerCase().indexOf(lowerSearchedText) > -1) {
          return true;
        }
        if (item.nBreeding && item.nBreeding.toLowerCase().indexOf(lowerSearchedText) > -1) {
          return true;
        }
        return false;
      });
    }
    if (searchedDate) {
      filtered = filtered.filter((item) => {
        // search consultation by date
        if (item.createdAt) {
          const y = moment(searchedDate).format('L');
          const o = moment(DateUtils.parseToDateObj(item.createdAt)).format('L');
          // console.log('createdAt', y, o);
          if (y === o) {
            return true;
          }
        }

        return false;
      });
    }
  }
  return filtered;
};

export default {
  searchItems,
};
