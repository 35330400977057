import { HTMLFontFace, jsPDF } from 'jspdf';

const pdfGenerator = async (html: string) => {
  const pdf = new jsPDF({
    unit: 'px',
    hotfixes: ['px_scaling'],
  });

  const fonts: HTMLFontFace[] = [
    {
      family: 'SourceSansPro-Regular',
      src: [
        {
          url: '/fonts/SourceSansPro-Regular.ttf',
          format: 'truetype',
        },
      ],
      style: 'normal',
      weight: 'normal',
    }, {
      family: 'SourceSansPro-Bold',
      src: [
        {
          url: '/fonts/SourceSansPro-Bold.ttf',
          format: 'truetype',
        },
      ],
      style: 'normal',
      weight: 'bold',
    },
  ];

  await pdf.html(html, {
    fontFaces: fonts,
    width: 695,
    windowWidth: 695,
    autoPaging: 'text',
    margin: 50,
    html2canvas: {

    },
  });
  // console.log('pdf generator : ', pdfFile);
  return { uri: pdf.output('bloburi') };
};

export default pdfGenerator;
