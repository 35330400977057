/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import * as Linking from 'expo-linking';

export default {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      Root: {
        screens: {
          'admin-tableau-de-bord': '/admin-tableau-de-bord',
          'admin-elevages-nav': {
            path: 'admin-elevages',
            screens: {
              'admin-elevages': '/',
              'admin-details-elevage': '/details/:id',
            },
          },
          'admin-vetos-nav': {
            path: 'admin-vetos',
            screens: {
              'admin-vetos': '/',
              'admin-details-veto': '/details/:id',
            },
          },
          'admin-diseases-nav': {
            path: 'admin-diseases',
            screens: {
              'admin-diseases': '/',
              'admin-details-disease': '/details/:id',
            },
          },
          'admin-ps-nav': {
            path: 'admin-ps',
            screens: {
              'admin-ps': '/',
              'admin-details-ps': '/details/:id',
            },
          },
          'admin-factures': '/admin-factures',
        },
      },
      NotFound: '*',
    },
  },
};
