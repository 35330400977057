import React from 'react';
import { Text } from '@ui-kitten/components';
import MaxWidthContainer from '../../../components/MaxWidthContainer';

const VetoPrebilanScreen = () => (
  <MaxWidthContainer
    withScrollView="keyboardAware"
    outerViewProps={{
      style: {
        padding: 20,
      },
    }}
  >
    <Text>Prébilan screen</Text>
  </MaxWidthContainer>
);

export default VetoPrebilanScreen;
