import React, { useState } from 'react';

import { useLinkTo, useRoute } from '@react-navigation/native';
import moment from 'moment';
import { RouteProp } from '@react-navigation/core/lib/typescript/src/types';
import { Text } from '@ui-kitten/components';
import MaxWidthContainer from '../../../components/MaxWidthContainer';

import Button from '../../../components/Button';
import DetailsScreensHeaderCard from '../../../screenComponents/DetailsScreensHeaderCard';

import VetoDetailsConsultationScreenBL
  from '../../../screenBusinessLogic/vetoScreensBL/VetoConsultationScreenBL/VetoDetailsConsultationScreenBL';
import { useGetConsultation } from '../../../src/API/Consultation';

import PlanifierTeleconsultationModal
  from '../../../components/Modals/PlanifierTeleconsultationModal';
import { Breeding, Consultation } from '../../../src/API';
import { VetoTabExamenParamList } from '../../../types';
import InfoCard from '../../../screenComponents/InfoCard';
import { useVetOfficeList } from '../../../src/API/VetOffice';

const VetoDetailsTeleconsultationScreen = () => {
  const linkTo = useLinkTo();
  const [telecons, setTelecons] = useState();
  const route = useRoute<RouteProp<VetoTabExamenParamList, 'veto-details-teleconsultation'>>();

  const planifierTelecons = () => {
    if (route && route.params) {
      setTelecons(route.params.id);
    }
  };
  let consultation: Consultation | undefined;
  let oneBreeding: Breeding;

  if (route.params) {
    consultation = useGetConsultation(route.params?.id).consultation;
  }

  const { vetOffice } = useVetOfficeList();

  if (consultation && consultation.breeding) {
    oneBreeding = consultation.breeding;
  }

  // console.log('Veto detail telecons:', consultation);
  return (
    <MaxWidthContainer
      withScrollView="keyboardAware"
      outerViewProps={{
        style: {
          padding: 20,
        },
      }}
    >
      {consultation && (
      <DetailsScreensHeaderCard
        title1="Date de la téléconsultation"
        titleInfo1={consultation?.dateTeleCons ? moment(consultation?.dateTeleCons).format('L à HH:mm') : 'À définir'}
        title2="Nom de l'élevage"
        titleInfo2={oneBreeding.companyName ? oneBreeding.companyName : ''}
        title3={"N° d'identification de l'élevage"}
        titleInfo3={oneBreeding.nBreeding ? oneBreeding.nBreeding : ''}
        title4="Nom de l'animal"
        titleInfo4={consultation && consultation.animal && consultation.animal.name
          ? consultation?.animal?.name : "Cette consultation n'est affecté qu'à un lot."}
        title5={consultation?.animal?.numberID && "N° d'identification de l'animal"}
        titleInfo5={consultation?.animal?.numberID
          ? consultation?.animal?.numberID : "Cette consultation n'est affecté qu'à un lot."}
        title7={
                (consultation?.animal?.lots?.numberLot && "N° d'identification du lot")
                || (consultation && consultation.lot && consultation.lot.numberLot && "N° d'identification du lot")
              }
        titleInfo7={consultation?.animal?.lots?.numberLot
        || (consultation && consultation.lot && consultation.lot.numberLot)}
        title8={
          (consultation?.animal?.lots?.name && 'Nom du lot')
          || (consultation && consultation.lot && consultation.lot.name && 'Nom du lot')
        }
        titleInfo8={
          (consultation?.animal?.lots?.name && 'Nom du lot')
          || (consultation && consultation.lot
              && consultation.lot.name && consultation.lot.name)
        }
        buttonText="Accéder à l'élevage"
        secondButtonText="Accéder à l'auto-examen"
        customLinkTo={() => {
          VetoDetailsConsultationScreenBL.linkToSwitch({ state: 'acceder-a-elevage', linkTo, id: oneBreeding?.id });
        }}
        secondLinkTo={() => {
          VetoDetailsConsultationScreenBL.linkToSwitch({ state: 'details-consultation', linkTo, id: consultation?.id });
        }}
      />
      ) }
      {consultation && !consultation.facture ? (
        consultation && consultation.breeding
              && consultation.breeding.mpMandateId
              && consultation.breeding.mpMandateConfirmed
              && vetOffice && vetOffice.mpBankId ? (
                <>
                  <Button
                    leftIcon="calendar-outline"
                    leftIconFill="white"
                    style={{ alignSelf: 'center', alignItems: 'center' }}
                    onPress={() => planifierTelecons()}
                  >
                    Planifier la téléconsultation
                  </Button>
                  <PlanifierTeleconsultationModal
                    title="Planifier la téléconsultation"
                    before={<></>}
                        /* eslint-disable-next-line no-underscore-dangle */
                    data={{
                      id: route.params?.id,
                      _version: consultation?._version,
                      date: consultation?.dateTeleCons,
                      type: 'teleconsultation',
                    }}
                    noSafeArea
                    visible={telecons !== undefined}
                    scrollable={false}
                    onClose={() => { setTelecons(undefined); }}
                    insideModalStyle={{ padding: 20 }}
                    buttonText="Confirmer"
                  />
                </>
          ) : (
            vetOffice && vetOffice.mpBankId ? (
              <InfoCard
                title="alertInfo"
                iconShow="alert-triangle-outline"
                              // iconCloseShow="close-outline"
                description={"L'éleveur n'a pas encore fournis ses coordonnées bancaires, vous ne pouvez pas planifier de téléconsultation pour l'instant."}
                alertInfo="alertInfo"
              />
            ) : (
              <>
                <InfoCard
                  title="alertInfo"
                  iconShow="alert-triangle-outline"
                                // iconCloseShow="close-outline"
                  description={"Vous n'avez pas encore fournis vos coordonnées bancaires pour recevoir vos paiements, vous ne pouvez pas planifier de téléconsultation pour l'instant."}
                  alertInfo="alertInfo"
                />

                <Button
                  leftIconFill="#FFF"
                  onPress={() => { linkTo('/veto-mon-compte/infos-bancaires'); }}
                >
                  Renseigner ses coordonnées bancaires
                </Button>
              </>
            )
          )
      ) : null}
    </MaxWidthContainer>
  );
};

export default VetoDetailsTeleconsultationScreen;
