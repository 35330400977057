import React, { useState } from 'react';

import { Text } from '@ui-kitten/components';
// import { useNavigation } from '@react-navigation/native';
import { useForm } from 'react-hook-form';
import { useLinkTo } from '@react-navigation/native';
import MaxWidthContainer from '../../../components/MaxWidthContainer';
import FTextInput from '../../../components/Form/TextInput';
import Button from '../../../components/Button';
import Form from '../../../components/Form/Form';
import { useUser } from '../../../src/API/UserContext';

import { useGetVetOffice, useUpdateVetOfficeMutation } from '../../../src/API/VetOffice';
import { VetOffice } from '../../../src/API';
import VetoMonCabinetScreenBL
  from '../../../screenBusinessLogic/vetoScreensBL/MonCabinetScreenBL/VetoMonCabinetScreenBL';

type ModifyTarificationForm = {
  maxPriceConsultation: number
  maxPriceTeleConsultation: number
};

const VetoTarifConsultationScreen = () => {
  // const navigation = useNavigation();
  const linkTo = useLinkTo();
  const [loading, setLoading] = useState(false);
  // console.log('user tarif', user);

  const modifyTarificationForm = useForm<ModifyTarificationForm>();
  const { updateVeterinaryOffice } = useUpdateVetOfficeMutation();
  const { vetOfficeData } = useGetVetOffice();

  // console.log('vetOfficeData:', vetOfficeData);
  const onSaveTarification = async (data: ModifyTarificationForm) => {
    // console.log('data onSaveTarification:', data);
    // console.log('vetOfficeData inside:', vetOfficeData);
    if (vetOfficeData) {
      await updateVeterinaryOffice({
        variables: {
          input: {
            id: vetOfficeData.id,
            // eslint-disable-next-line no-underscore-dangle
            _version: vetOfficeData._version,
            maxPriceTeleConsultation: data.maxPriceTeleConsultation,
            maxPriceConsultation: data.maxPriceConsultation,
          },
        },
      });
    }
    VetoMonCabinetScreenBL.allerMonCompteScreen({ state: 'mon cabinet', linkTo });
  };

  return (
    <MaxWidthContainer
      withScrollView="keyboardAware"
      outerViewProps={{
        style: {
          padding: 20,
          marginBottom: 20,
        },
      }}
    >
      <Form<ModifyTarificationForm> {...modifyTarificationForm} defaultValues={vetOfficeData}>
        <>

          <Text category="h5" style={{ marginBottom: 20 }}>Paramétrez le montant plafond de vos téléconsultations et de vos prestations de conseil suite au partage des auto-examens par l’éleveur. Vous ne pourrez pas le dépasser lorsque vous fixerez le tarif lors d'un rendez-vous.</Text>
          <FTextInput
            name="maxPriceTeleConsultation"
            title="Téléconsultation"
            placeholder="Saisissez le montant"
            keyboardType="numeric"
            moneySign
          />
          <FTextInput
            name="maxPriceConsultation"
            title="Prestation de conseil"
            placeholder="Saisissez le montant"
            keyboardType="numeric"
            moneySign
          />

          <Button
            loading={loading}
            loadingText="Chargement"
            style={{ marginTop: 40 }}
            onPress={modifyTarificationForm.handleSubmit(
              (data) => {
                setLoading(true);
                onSaveTarification(data);
              },
            )}
          >
            Enregistrer
          </Button>
        </>
      </Form>
    </MaxWidthContainer>
  );
};

export default VetoTarifConsultationScreen;
