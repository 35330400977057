import {
  createBottomTabNavigator,
} from '@react-navigation/bottom-tabs';
import * as React from 'react';
import { SafeAreaView, StyleSheet } from 'react-native';

import { useNavigation } from '@react-navigation/native';
import { useDimensions } from '@react-native-community/hooks';
import { BottomNavigationTab, useTheme } from '@ui-kitten/components';
import Animated from 'react-native-reanimated';
import { BottomTabParamList } from '../types';

import { BottomNavigation } from '../components/UIKittenRewrite/BottomNavigation';

import { getStackInfos, useResetLinkTo } from './Utils';

import ElevageStackNavigator from './Navigators/ElevageStackNavigator';
import TableauDeBordStackNavigator from './Navigators/TableauDeBordStackNavigator';
import ConsultationStackNavigator from './Navigators/ConsultationStackNavigator';

import AcceuilIcon from '../assets/svg_icones_ScanFlock/GREEN/greenSquareIcon.svg';
import Cow from '../assets/svg_icones_ScanFlock/GREEN/greenCowIcon.svg';
import Doctor from '../assets/svg_icones_ScanFlock/GREEN/greenDoctorIcon.svg';
import CameraSvg from '../assets/svg_icones_ScanFlock/GREEN/greenCameraIcon.svg';

import { useBreedingList } from '../src/API/Breeding';
import PhotoStackNavigator from './Navigators/PhotoStackNavigator';
import LinkingConfiguration from './LinkingConfiguration';

const HomeIcon = (props) => {
  const { style } = props;
  return <AcceuilIcon height={20} width={20} fill={style.tintColor} />;
};
const CowIcon = (props) => {
  const { style } = props;
  return <Cow height={20} width={20} fill={style.tintColor} />;
};
const DoctorIcon = (props) => {
  const { style } = props;
  return <Doctor height={20} width={20} fill={style.tintColor} />;
};
const CameraIcon = (props) => {
  const { style } = props;
  return <CameraSvg height={20} width={20} fill={style.tintColor} />;
};

// create type Props for props. Do Not leave any props

const BottomTabBar = ({ navigation, state, visible }: any) => {
  const theme = useTheme();
  const linkTo = useResetLinkTo(LinkingConfiguration.config);

  return (
    <SafeAreaView style={{ backgroundColor: theme['color-basic-100'] }}>
      <BottomNavigation
        style={{ marginHorizontal: 2 }}
        selectedIndex={state.index}
        visible={visible}
        onSelect={(index) => {
          switch (index) {
            case 0:
              linkTo('/tableau-de-bord');
              break;
            case 1:
              linkTo('/elevage');
              break;
            case 2:
              linkTo('/consultation');
              break;
            case 3:
              linkTo('/photo');
              break;
          }
        }}
      >
        {/* eslint-disable-next-line @typescript-eslint/no-shadow */}
        <BottomNavigationTab title="Accueil" icon={HomeIcon} />
        <BottomNavigationTab title="Elevage" icon={CowIcon} />
        <BottomNavigationTab title="Consultations" icon={DoctorIcon} />
        <BottomNavigationTab title="Photos" icon={CameraIcon} />
      </BottomNavigation>
    </SafeAreaView>
  );
};

const BottomTab = createBottomTabNavigator<BottomTabParamList>();

export default function BottomTabNavigator({ style }) {
  const navigation = useNavigation();
  const { window } = useDimensions();
  const { breeding } = useBreedingList();
  return (
    <Animated.View style={StyleSheet.flatten([{ flex: 1 }, style])}>
      <BottomTab.Navigator
        initialRouteName="tableau-de-bord-nav"
        tabBar={
                (props) => {
                  const { showBack, currentRouteName } = getStackInfos(navigation.getState());
                  return <BottomTabBar {...props} visible={!((showBack && currentRouteName !== 'realiser-auto-exam') || window.width > 780)} style={{ margin: 100 }} />;
                }
            }
        screenOptions={{ headerShown: false }}
      >
        <BottomTab.Screen
          name="tableau-de-bord-nav"
          component={TableauDeBordStackNavigator}
        />

        <BottomTab.Screen
          name="elevage-nav"
          component={ElevageStackNavigator}
          initialParams={{
            id: breeding?.id,
          }}
        />
        <BottomTab.Screen
          name="consultation-nav"
          component={ConsultationStackNavigator}
        />

        <BottomTab.Screen
          name="photo"
          // component={Camera}
          component={PhotoStackNavigator}
        />
      </BottomTab.Navigator>
    </Animated.View>
  );
}
