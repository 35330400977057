import React from 'react';

import { useRoute } from '@react-navigation/native';
import { Text } from '@ui-kitten/components';
import { RouteProp } from '@react-navigation/core/lib/typescript/src/types';
import MaxWidthContainer from '../../../components/MaxWidthContainer';

import TeleconsultationCard from '../../../components/TeleconsultationCard';
import DetailsScreensHeaderCard from '../../../screenComponents/DetailsScreensHeaderCard';
import { TabElevageParamList } from '../../../types';
import { useGetAnimal } from '../../../src/API/Animals';
import DateUtils from '../../../utils/DateUtils';
import { typeGenderCustom } from '../../../src/selectFormData/ajoutAnimalData';

const VetoDetailAnimalScreen = () => {
  const route = useRoute<RouteProp<TabElevageParamList, 'detail-animal'>>();
  // console.log('DetailAnimalScreen route: ', route);
  const { animal } = useGetAnimal(route.params.id);

  return (
    <MaxWidthContainer
      outerViewProps={{
        style: {
          padding: 24,
        },
      }}
    >
      {animal
        && (
        <>
          <DetailsScreensHeaderCard
            title1={"N° d'identification"}
            titleInfo1={animal?.numberID}
            title2="Nom"
            titleInfo2={animal?.name}
            title3="Sexe"
            titleInfo3={animal?.gender ? typeGenderCustom[animal?.gender].label : null}
            title4="Age"
            titleInfo4={DateUtils.getAge(animal?.birthDate)}
            title5="Numéro de lot"
            titleInfo5={animal?.lots?.numberLot ? animal?.lots?.numberLot : "L'animal n'est affecté à aucun lot"}
            title6="Bâtiment"
            titleInfo6={animal?.building?.nameBuilding ? animal?.building?.nameBuilding : "L'animal n'est affecté à aucun bâtiment"}
            title7="Zone"
            titleInfo7={animal?.zone?.name ? animal?.zone?.name : "L'animal n'est affecté à aucune zone"}
            animals={animal}
          />
        </>
        )}

      <Text
        category="p1"
        appearance="hint"
        style={{ marginTop: 20 }}
      >
        Dernières consultations créées
      </Text>
      {
          animal?.consultations?.items && animal?.consultations?.items?.length > 0
            ? (animal?.consultations?.items.map(
              (item) => <TeleconsultationCard key={item?.id} consultationData={item} back={`veto-elevage/veto-detail-animal/${animal?.id}`} />,
            ))
            : <Text category="h4">Vous n'avez pas de consultations</Text>
        }

    </MaxWidthContainer>

  );
};

export default VetoDetailAnimalScreen;
