import { TouchableOpacity, View, ViewStyle } from 'react-native';
import React, { useState } from 'react';
import { Icon, Text } from '@ui-kitten/components';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useDimensions } from '@react-native-community/hooks';
import YoutubePlayer from 'react-native-youtube-iframe';
import Card from './Card';
import CustomModal from './Modals/CustomModal';
import BaseModal from './Modals/BaseModal';
import { useTutorialState } from '../utils/TutorialStateContext';

type TutorialProps = {
  videoId: string,
  style?: ViewStyle,
};

const Tutorial = (props: TutorialProps) => {
  const { videoId, style } = props;
  const [modal, setModal] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const { window } = useDimensions();

  const { state, setHidden } = useTutorialState();

  if (state[`HIDE_TUTO_${videoId}`] === 'true') {
    return null;
  }

  return (
    <>
      <Card onPress={() => setModal(true)} style={[{ alignItems: 'center', marginBottom: 10 }, style]}>
        <View style={{
          position: 'absolute',
          right: 5,
          top: 5,
          width: 20,
          height: 20,
        }}
        >
          <TouchableOpacity
            onPress={() => {
              setCloseModal(true);
            }}
          >
            <Icon name="close-outline" width={20} height={20} fill="black" />
          </TouchableOpacity>
        </View>
        <Text>Besoin d'aide ?</Text>
        <Text>Visionnez notre tutoriel</Text>
      </Card>
      <CustomModal
        title="Voulez-vous vraiment cacher ce tutoriel ? Il sera toujours accessible dans la FAQ."
        before={<></>}
        noSafeArea
        visible={closeModal}
        scrollable={false}
        onClose={() => {
          setCloseModal(false);
        }}
        insideModalStyle={{ padding: 20 }}
        buttonText="Cacher ce tutoriel"
        buttonPress={async () => {
          setHidden(videoId);
        }}
        secondButtonPress={() => setCloseModal(false)}
        secondButtonText="Annuler"
        heightPercentage={0.3}
      />
      <BaseModal
        visible={modal}
        onClose={() => setModal(false)}
      >
        <View
          style={{
            backgroundColor: 'white',
            borderRadius: 10,
            height: window.width * 0.8 * 9 / 16 + 30,
            maxHeight: window.width * 0.8 * 9 / 16 + 30,
            width: window.width * 0.8,
            flex: 1,
            justifyContent: 'space-between',
            overflow: 'hidden',
            padding: 7,
          }}
        >
          <View style={{
            alignItems: 'flex-end',
          }}
          >
            <TouchableOpacity
              onPress={() => {
                setModal(false);
              }}
            >
              <Icon name="close-outline" width={20} height={20} fill="black" />
            </TouchableOpacity>
          </View>
          <YoutubePlayer
            height={window.width * 0.8 * 9 / 16}
            play
            videoId={videoId}
          />
        </View>
      </BaseModal>
    </>
  );
};

export default Tutorial;
