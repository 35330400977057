import React, { useState } from 'react';
import { TouchableOpacity } from 'react-native';
import { Icon as IconUIKitten } from '@ui-kitten/components/ui/icon/icon.component';
import { useBtnStyle, useIconStyle } from '../useStyles';
import MoreButtons from './MoreButtons';
import { AdditionalInfosState } from '../AdditionalInfos';

type ShowMoreButtonProps = { setState: (state?: AdditionalInfosState) => void };

function ShowMoreButton(props: ShowMoreButtonProps) {
  const btnStyle = useBtnStyle();
  const iconStyle = useIconStyle();
  const { setState } = props;
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      {showMore && (
      <MoreButtons
        style={{
          position: 'absolute',
          top: -150,
          right: 0,
          width: 322,
          height: 100,
          borderWidth: 1,
          borderColor: 'black',
          justifyContent: 'center',
            zIndex: 100000
        }}
        setState={(state) => {
          setState(state);
          setShowMore(false);
        }}
      />
      )}
      <TouchableOpacity
        style={btnStyle}
        onPress={() => {
          setShowMore(!showMore);
        }}
      >
        <IconUIKitten
          name="more-vertical"
          style={iconStyle}
          fill="black"
        />
      </TouchableOpacity>
    </>
  );
}

export default ShowMoreButton;
