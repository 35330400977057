import React from 'react';

import MaxWidthContainer from '../../components/MaxWidthContainer';
import ListAutoExam from '../../screenComponents/ListAutoExam';
import Tutorial from '../../components/Tutorial';

const QuestionnaireScreen = () => (
  <MaxWidthContainer
    withScrollView={false}
    outerViewProps={{
      style: {
        padding: 0, margin: 0, marginBottom: -25, backgroundColor: 'white',
      },
    }}
  >
    <ListAutoExam />
  </MaxWidthContainer>
);

export default QuestionnaireScreen;
