import React from 'react';
import { useRoute } from '@react-navigation/native';
import { RouteProp } from '@react-navigation/core/lib/typescript/src/types';
import Chat from '../../components/Chat/Chat';
import { TabConsultationParamList } from '../../types';

const MessagerieScreen = () => {
  const route = useRoute<RouteProp<TabConsultationParamList, 'messagerie'>>();
  return (
    <Chat channel={route?.params?.id} />
  );
};

export default MessagerieScreen;
