import {
  StackActions, useLinkTo, useNavigation, useRoute,
} from '@react-navigation/native';
import React, { useEffect, useState } from 'react';

import { Text } from '@ui-kitten/components';

import Button from '../../components/Button';
import SearchComponent from '../SearchComponent';
import AnimalCard from '../AnimalCard';
import GoCustomCard from '../../components/GoCustomCard';
import CustomModal from '../../components/Modals/CustomModal';

import { useUser } from '../../src/API/UserContext';
import { useBreedingList, useUpdateBreedingMutation } from '../../src/API/Breeding';
import { Animals, Buildings, Lots } from '../../src/API';

import ElevageScreenBL from '../../screenBusinessLogic/ElevageScreen/ElevageScreenBL';
import CrudBL from '../../screenBusinessLogic/ConsultationScreen/CrudBL';

const RealiserAutoExamScreenHeaderCard = ({ state }: { state: string }) => {
  const linkTo = useLinkTo();
  // console.log('state', state);
  const { user } = useUser();
  const { breeding, refetch } = useBreedingList(user?.userGroup);
  const { modifyBreeding } = useUpdateBreedingMutation();
  const route = useRoute();
  // console.log('breeding', breeding);
  let animalsElevage: Animals | null | undefined;
  let nextTokenAnimals;
  let fetchMoreAnimals;

  const {
    animalS, fetchMoreAnimalS, nextToken,
  } = ElevageScreenBL.useListAnimalByBreedingBL(route.params.id);
  animalsElevage = animalS;
  nextTokenAnimals = nextToken;
  // refetch = referchAnimalS;
  fetchMoreAnimals = fetchMoreAnimalS;

  // console.log('route RealiserAutoExamScreenHeaderCard:', route);
  // console.log('breeding consultation:', breeding);
  const [elevageList, setElevageList] = useState();
  let animals: Animals;
  let buildings: Buildings;
  let lots: Lots;
  const navigation = useNavigation();
  useEffect(() => {
    if (state) {
      ElevageScreenBL.changeElevageListData({
        breeding, animals, lots, buildings, setElevageList, state, refetch, animalsElevage,
      });
    }
  }, [state]);

  // console.log('Elevage list:', elevageList);

  const [openDelete, setOpenDelete] = useState<{ title : string, id: string }>();

  const { addConsultationByAnimal, addConsultationByLot } = CrudBL.useAjoutConsultation();
  const date = new Date().toISOString();
  // console.log(date);
  /** Create consultation by animal and by lot  */
  const createConsultFunction = async () => {
    if (breeding && (!breeding.dateFirstCons || !breeding.nextSubscriptionDate)) {
      const date2Mois = new Date();
      const date = new Date();
      date2Mois.setMonth(date2Mois.getMonth() + 2);
      date2Mois.setHours(0, 0, 0, 0);

      await modifyBreeding({
        variables: {
          input: {
            id: breeding.id,
            _version: breeding._version,
            dateFirstCons: date.toISOString(),
            nextSubscriptionDate: date2Mois.toISOString(),
          },
        },
      });
    }

    if (user && state === 'animal') {
      // clicked animal id
      // console.log('id for creation consultation:', openDelete?.id);
      addConsultationByAnimal(openDelete?.id).then((newCreateConsByAnimal) => {
        if (route?.name === 'faire-demande-teleconsultation') {
          navigation.dispatch({
            ...StackActions.pop(),
            source: route.key,
          });
        } else {
          linkTo(`/consultation/questionnaire/${newCreateConsByAnimal?.createConsultation?.id}`);
        }
        // close modal
        setOpenDelete(undefined);
      });
    } else if (user && state === 'lots') {
      addConsultationByLot(openDelete?.id).then((newCreateConsByLot) => {
        // console.log('addConsultationByLot:', newCreateConsByLot);
        if (route?.name === 'faire-demande-teleconsultation') {
          navigation.dispatch({
            ...StackActions.pop(),
            source: route.key,
          });
        } else {
          linkTo(`/consultation/questionnaire/${newCreateConsByLot?.createConsultation?.id}`);
        } // close modal
        setOpenDelete(undefined);
      });
    }
  };

  return (
    <>
      <Text category="h4" style={{ marginTop: 15 }}>
        Rechercher un
        {' '}
        {state || 'animal ou un lot'}
      </Text>

      <SearchComponent
        state="realiseAutoExamConsultation"
        // onChangeSearchText={}
        opened
        data={elevageList}
        fetchMore={fetchMoreAnimals}
        currentNextToken={nextTokenAnimals}
        keyExtractor={(item) => item.id}
        placeholder={"Nom ou le n°d'identification"}
        renderItem={(item) => {
          // console.log('item:', item);
          switch (state) {
            case 'animal':
              return (
                <AnimalCard
                  // animal={item}
                  name={item.item.name}
                  numberId={item.item.numberID}
                  numberLot={item.item.lots && item.item.lots.numberLot}
                  key={item.index}
                  minusSquareOutline="arrow-right-outline"
                  // radio="radio"
                  onPress={() => {
                    // console.log('id animal:', item.item.id);
                    setOpenDelete({
                      title: item.item.name, id: item.item.id,
                    });
                  }}
                />
              );
            case 'lot':
              return (
                <GoCustomCard
                  title1={item.item.name}
                  title2={item.item.numberLot}
                  linkToCustom={() => {
                    // console.log('id animal:', item.item);
                    setOpenDelete({
                      title: item.item.name, id: item.item.id,
                    });
                  }}
                  minusSquareOutline="arrow-right-outline"
                  customStyle={{ marginHorizontal: 5 }}
                />
              );

            default:
              break;
          }
          return '';
        }}
      />

      <CustomModal
        title={(route.name === 'realiser-auto-exam' && `Vous êtes sur le point de débuter un auto-examen avec ${state === 'animal' ? "l'animal:" : 'le lot:'} ${openDelete?.title} .`
            + '\n'
            + 'Etes-vous sûr de vouloir continuer ?') || (route.name === 'faire-demande-teleconsultation' && `Vous êtes sur le point de faire une demande de téléconsultation à votre vétérinaire avec  ${state === 'animal' ? "l'animal:" : 'le lot:'} ${openDelete?.title} .`
            + '\n'
            + 'Etes-vous sûr de vouloir continuer ?')}
        before={<></>}
        noSafeArea
        visible={openDelete !== undefined}
        scrollable={false}
        onClose={() => { setOpenDelete(undefined); }}
        insideModalStyle={{ padding: 20 }}
        buttonText={(route.name === 'faire-demande-teleconsultation' && 'Faire demande de téléconsultation') || (route.name === 'realiser-auto-exam' && "Débuter l'auto-examen")}
        secondButtonText="Annuler"
        heightPercentage={0.36}
        buttonPress={() => { createConsultFunction(); }}
      />

    </>
  );
};

export default RealiserAutoExamScreenHeaderCard;
