import {
  Radio, Text, useTheme,
} from '@ui-kitten/components';
import React, {
  useCallback,
  useContext, useEffect, useRef, useState,
} from 'react';

import {
  StackActions, useLinkTo, useNavigation, useRoute,
} from '@react-navigation/native';
import {
  BackHandler,
  FlatList, Platform, TouchableOpacity, View,
} from 'react-native';
import { Icon as IconUIKitten } from '@ui-kitten/components/ui/icon/icon.component';
import _ from 'lodash';
import questionnaireData from '../src/mockData/questionnaireData';

import Card from '../components/Card';
import Button from '../components/Button';

import questionConditionData from '../src/mockData/questionConditionData';
import { useGetConsultation } from '../src/API/Consultation';
import CrudBL from '../screenBusinessLogic/ConsultationScreen/CrudBL';
import CustomModal from '../components/Modals/CustomModal';
import { GlobalStateContext } from '../utils/GlobalStateContext';
import Camera from '../components/Camera';
import { CameraOutput } from '../components/Camera/Camera';
import { Upload } from '../utils/S3FileStorage';
import { useCreateDocumentMutation } from '../src/API/Document';
import ListAutoExamenItem from './ListAutoExamenItem';
import { useFunction } from '../utils/CustomHooks';
import BaseModal from '../components/Modals/BaseModal';
import { useUser } from '../src/API/UserContext';
import Tutorial from '../components/Tutorial';

export type QuestionaireDataItem = {
  id: string;
  titre1? :string;
  titre2? :string;
  titre3? :string;
  titre4? :string;
  autopass?: boolean;
  // quand on a valider la réponse de cette question
  validate?: boolean;
  // les questions a afficher dans la flatlist
  showQuestion?: boolean;
  lastChecked?: number;
  name: string;
  description: string;
  interdire: boolean;
  veto: boolean;
  imposer: boolean;
  poserMaintenant: boolean,
  hideQuestion: boolean;
  multipleAnswer: boolean;
  answers: {
    [key: string]: {
      name: string;
      description: string;
      image: string;
      interdire: boolean;
      veto: boolean;
      imposer: boolean;
      checked: boolean;
      showNextQuestion: boolean,
    }
  }
};

export type QuestionnaireData = {
  [key: string]: QuestionaireDataItem
};

const ListAutoExam = () => {
  const linkTo = useLinkTo();
  const route = useRoute();
  const scrollViewRef = useRef<FlatList | null>(null);
  const theme = useTheme();
  const { isVeto } = useUser();
  /**  screen height logic */
  const navigation = useNavigation();

  const [currentQuestionnaireData, setCurrentQuestionnaireDataB] = useState<QuestionnaireData>(_.cloneDeep(questionnaireData));
  const [hasToZoom, setHasToZoom] = useState(route?.params?.zoom !== undefined);

  const setCurrentQuestionnaireData = (data) => {
    setCurrentQuestionnaireDataB(data);
  };

  const [flatListLayout, setFlatListLayout] = useState({
    layoutHeight: 0,
    contentHeight: 0,
    contentOffsetY: 0,
  });

  const isCloseToBottom = () => {
    const paddingToBottom = 20;
    const {
      layoutHeight,
      contentHeight,
      contentOffsetY,
    } = flatListLayout;
    return layoutHeight + contentOffsetY
        >= contentHeight - paddingToBottom;
  };

  useEffect(() => {
    if (isCloseToBottom()) {
      setIsBottom(false);
    } else {
      setIsBottom(true);
    }
  }, [flatListLayout.layoutHeight, flatListLayout.contentHeight, flatListLayout.contentOffsetY]);

  const [isBottom, setIsBottom] = useState(false);

  const [camera, setCamera] = useState(false);

  const { createDocument, loadingDocument } = useCreateDocumentMutation();
  const onSaveImage = async (image?: CameraOutput) => {
    if (image) {
      const upload = await Upload(image, `documents/consultation/imagesDoc/${route.params.id}/`);
      if (upload !== false) {
        await createDocument({
          variables: {
            input: {
              s3file: upload.key,
              consultationId: route.params.id,
              name: upload.name,
            },
          },
        });
      }
    }
    // }
  };

  const { state, setState } = useContext(GlobalStateContext);
  console.log('route', route?.params?.id);
  const { consultation, refetch, loading } = useGetConsultation(route?.params?.id);
  console.log('consultation', consultation, loading);

  const [answerChecked, setAnswerChecked] = useState<string[]>([]);

  const [currentQuestion, setCurrentQuestion] = useState<string>();

  const [showNext, setShowNext] = useState(false);
  const [ignoreQuestion, setIgnoreQuestion] = useState(false);

  useEffect(() => {
    if (consultation && ((consultation?.questionResponse && consultation?.questionResponseOrder && consultation?.questionResponseOrder.length > 0) || route?.params?.update === 'false')) {
      let newState = _.cloneDeep(currentQuestionnaireData);
      const questionResponseTemp = JSON.parse(consultation?.questionResponse);
      const keyQuestionReponse = Object.keys(questionResponseTemp);
      const keyQuestionReponseOrder = consultation?.questionResponseOrder;
      newState['A)1)b)1.'].showQuestion = true;
      for (let i = 0; i < keyQuestionReponseOrder.length; i++) {
        if (newState[keyQuestionReponseOrder[i]].answers) {
          Object.keys(newState[keyQuestionReponseOrder[i]].answers).forEach((key) => {
            if (questionResponseTemp[keyQuestionReponseOrder[i]].indexOf(key) > -1) {
              newState[keyQuestionReponseOrder[i]].answers[key].checked = true;
            }
          });
        }
        newState = validAnswer(questionResponseTemp[keyQuestionReponseOrder[i]], newState, keyQuestionReponseOrder[i], i === keyQuestionReponseOrder.length - 1);
        if (newState['A)3)b)52.'].poserMaintenant) {
          debugger;
        }
      }
      setCurrentQuestionnaireData(newState);
    } else {
      const nextState = _.cloneDeep(currentQuestionnaireData);
      Object.values(nextState)[0].showQuestion = true;
      setCurrentQuestionnaireData(nextState);
      setCurrentQuestion(Object.keys(currentQuestionnaireData)[0]);
    }

    return () => {};
  }, [consultation]);

  useEffect(() => {
    if (currentQuestion) {
      const data = Object.values(currentQuestionnaireData).filter((item) => item.showQuestion);
      if (!isVeto) {
        if (!hasToZoom) {
          let indexToGo = data.length - 1;
          console.log(data);
          // on verifie si juste avant on a pas un autopass
          while (indexToGo > 0 && data[indexToGo - 1].autopass) {
            indexToGo -= 1;
          }
          scrollToBottom(indexToGo);
        } else if (hasToZoom) {
          scrollToBottom(data.map((item) => item.id).indexOf('A)3)a)') + 1);
        }
      }
      setHasToZoom(false);
    }
  }, [currentQuestion]);

  useEffect(() => {
    const backAction = () => {
      setState(true);
      return true;
    };

    const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction);

    return () => backHandler.remove();
  }, []);

  const { editConsultation, deleteConsultation } = CrudBL.useCrudConsultation();
  const validAnswer = (argAnswerCheck? : string[], newState?: any, argIdCurrenQuestion?: string, isLast?:boolean): any => {
    let currentAnswerCheck = answerChecked;
    if (ignoreQuestion) {
      currentAnswerCheck = [];
    }
    if (argAnswerCheck) {
      currentAnswerCheck = argAnswerCheck;
    }
    let id = currentQuestion;
    if (argIdCurrenQuestion) {
      id = argIdCurrenQuestion;
    }
    let keys = Object.keys(currentQuestionnaireData);
    let newListKey = keys.slice(keys.indexOf(id));
    let nextState = {};
    if (newState) {
      keys = Object.keys(newState);
      newListKey = keys.slice(keys.indexOf(id));
      nextState = newState;
    } else {
      nextState = _.cloneDeep(currentQuestionnaireData);
    }

    // all disease that possible exactlh for these Q&A chosen

    //
    currentAnswerCheck.map((answer) => {
      if (questionConditionData[answer]) {
        questionConditionData[answer].map((item) => {
          // console.log(item);
          if (item.cible.substr(-1) === '/') {
            const regex = /([A-Za-z0-9).]+?)([0-9]+\/)/gm;
            let questionId = '';
            let m;
            while ((m = regex.exec(item.cible)) !== null) {
              // This is necessary to avoid infinite loops with zero-width matches
              if (m.index === regex.lastIndex) {
                regex.lastIndex++;
              }

              // The result can be accessed through the `m`-variable.
              m.forEach((match, groupIndex) => {
                if (groupIndex === 1) {
                  questionId = match;
                }
              });
            }
            if (nextState[questionId]) {
              if (item.interdire || item.veto) {
                nextState[questionId].answers[item.cible].interdire = true;
                nextState[questionId].answers[item.cible].imposer = false;
                nextState[questionId].answers[item.cible].checked = false;
                if (item.veto) {
                  nextState[questionId].answers[item.cible].veto = true;
                }
              }
              if (item.imposer && !item.veto) {
                nextState[questionId].answers[item.cible].imposer = true;
                nextState[questionId].answers[item.cible].interdire = false;
                nextState[questionId].answers[item.cible].checked = true;
                if (nextState[questionId] && !nextState[questionId].multipleAnswer) {
                  // si la question a une réponse imposée et qu'il n'y a qu'une réponse autorisée, on "interdit" la question pour ne pas l'afficher inutillement.
                  nextState[questionId].validate = true;
                  nextState[questionId].interdire = true;
                }
              }
            }
          } else {
            if (item.veto) {
              keys.filter((key) => {
                if (key.startsWith(item.cible)) {
                  nextState[key].veto = true;
                  nextState[key].interdire = true;
                  nextState[key].imposer = false;
                }
                return () => {};
              });
            }
            if (item.poser) {
              keys.filter((key) => {
                if (key.startsWith(item.cible)) {
                  nextState[key].poserMaintenant = true;
                  nextState[key].interdire = false;
                }
                return () => {};
              });
            }
            if (item.interdire) {
              keys.filter((key) => {
                if (key.startsWith(item.cible) && !nextState[key].imposer && !nextState[key].poserMaintenant) {
                  nextState[key].interdire = true;
                }
                return () => {};
              });
            }
            if (item.imposer) {
              keys.filter((key) => {
                if (key.startsWith(item.cible) && !nextState[key].veto) {
                  nextState[key].imposer = true;
                  nextState[key].interdire = false;
                }
                return () => {};
              });
            }
          }

          return () => {};
        });
      }
      return () => {};
    });
    const showNow = false;

    nextState[id].validate = true;
    nextState[id].showQuestion = true;

    nextState = goNextQuestion(nextState, showNow, newListKey, argIdCurrenQuestion, isLast);

    console.log(Object.entries(nextState).map((k) => ({ [k[0]]: k[1] })));

    return nextState;
  };

  const goNextQuestion = (nextState: any, showNow: boolean, newListKey: string[], argIdCurrenQuestion?: string, isLast?:boolean): any => {
    let id = currentQuestion;

    if (argIdCurrenQuestion) {
      id = argIdCurrenQuestion;
    }
    if (nextState[newListKey[0]] && newListKey.length <= 1) {
      if (!argIdCurrenQuestion) {
        setFinQuestionaire(nextState);
      } else {
        setCurrentQuestion(argIdCurrenQuestion);
      }
      return nextState;
    }
    for (let i = 0; i < newListKey.length; i++) {
      if (nextState[newListKey[i]] && nextState[newListKey[i]].poserMaintenant && !nextState[newListKey[i]].interdire && !nextState[newListKey[i]].hideQuestion) {
        showNow = true;
        if (nextState[newListKey[1]].hideQuestion) {
          if (nextState[newListKey[1]].validate) {
            nextState[newListKey[1]].showQuestion = true;
          }
          newListKey.splice(2, 0, newListKey[i]);
        } else {
          newListKey.splice(1, 0, newListKey[i]);
        }
        newListKey.splice(i + 1, 1);
        break;
      }
    }
    if (showNow) {
      let currentQuestionnaireDataTemp = {};
      const keys = Object.keys(nextState);
      const keyBefore = keys.slice(0, keys.indexOf(id));
      const valueQuestionnaireInit = Object.values(nextState);
      for (let j = 0; j < keyBefore.length; j++) {
        currentQuestionnaireDataTemp = Object.assign(currentQuestionnaireDataTemp, { [keyBefore[j]]: valueQuestionnaireInit[j] });
      }
      for (let k = 0; k < newListKey.length; k++) {
        currentQuestionnaireDataTemp = Object.assign(currentQuestionnaireDataTemp, { [newListKey[k]]: { ...nextState[newListKey[k]], poserMaintenant: false } });
      }
      nextState = currentQuestionnaireDataTemp;

      console.log(Object.entries(nextState).map((k) => ({ [k[0]]: k[1] })));
    }
    if (nextState[newListKey[1]].autopass && !showNow) {
      nextState[newListKey[1]].showQuestion = true;
      const updateListKey = newListKey.slice(1);
      const state = goNextQuestion(nextState, showNow, updateListKey, argIdCurrenQuestion, isLast);
      return state;
    } if (!showNow && nextState[newListKey[1]]) {
      if (nextState[newListKey[1]].interdire) {
        newListKey = newListKey.slice(1);
        // console.log('List key avant while interdire', newListKey);
        while (newListKey[1] && ((nextState[newListKey[1]] && nextState[newListKey[1]].interdire) || newListKey[1].startsWith('C)III)') || nextState[newListKey[1]].hideQuestion)) {
          newListKey = newListKey.slice(1);
        }
        if (newListKey.length === 1 && nextState[newListKey[0]].interdire && !argIdCurrenQuestion) {
          setFinQuestionaire(nextState);
          return nextState;
        }
        // newListKey = newListKey.filter((item) => item !== newListKey[0]);
        const state = goNextQuestion(nextState, showNow, newListKey, argIdCurrenQuestion, isLast);
        return state;
      }
      if (nextState[newListKey[1]].hideQuestion && !nextState[newListKey[1]].interdire
      ) {
        // console.log(currentQuestionnaireData[newListKey[0]]);
        nextState[newListKey[1]].validate = true;
        newListKey = newListKey.filter((item) => (item !== newListKey[0]));

        const state = goNextQuestion(nextState, showNow, newListKey, argIdCurrenQuestion, isLast);
        return state;
      }
    }
    nextState[newListKey[1]].showQuestion = true;
    if (showNow && nextState[newListKey[1]].hideQuestion) {
      nextState[newListKey[1]].validate = true;
      nextState[newListKey[2]].showQuestion = true;
    }
    if (isLast) {
      setCurrentQuestion(newListKey[1]);
    }
    if (!argIdCurrenQuestion) {
      if (showNow && nextState[newListKey[1]].hideQuestion) {
        setCurrentQuestion(newListKey[2]);
      } else {
        setCurrentQuestion(newListKey[1]);
      }
      setCurrentQuestionnaireData(nextState);
      setAnswerChecked([]);
      setIgnoreQuestion(false);
    }
    return nextState;
  };

  const questionCondtion = (nextState, conditionData, idQuestion) => {
    const allKeys = Object.keys(nextState);
    const keys = allKeys.slice(allKeys.indexOf(idQuestion));
    if (conditionData && keys[1] === conditionData.cible) {
      if (conditionData.imposer) {
        if (nextState[conditionData.cible].hideQuestion) {
          nextState[keys[1]].showQuestion = true;
        }
      }
      if (conditionData.interdire) {
        nextState[keys[1]].showQuestion = false;
        // console.log('questionaire data : ', currentQuestionnaireData[conditionData.cible]);
      }
    }
  };

  const scrollToBottom = (index, viewPosition? = 0, viewOffset? = 0) => {
    try {
      const dataLength = Object.values(currentQuestionnaireData).filter((item) => item.showQuestion).length;
      if (dataLength > 0) {
        setTimeout(() => {
          try {
            scrollViewRef?.current?.scrollToIndex({
              index, animated: true, viewOffset, viewPosition,
            });
          } catch (e) {
            // console.log('pas grave on devrait scroll à la prochaine');
          }
        }, 300);
      }
    } catch (e) {
      // console.log('pas grave on devrait scroll à la prochaine');
    }
  };

  const modifyQuestionnaire = useFunction((idQuestion: string, answerId?: string, currentChecked?: boolean) => {
    const keys = Object.keys(currentQuestionnaireData);
    const keyUpdate = keys.slice(keys.indexOf(idQuestion) + 1);
    const keyBefore = keys.slice(0, keys.indexOf(idQuestion) + 1);
    let initialKeys = Object.keys(questionnaireData);
    initialKeys = initialKeys.slice(initialKeys.indexOf(idQuestion) + 1);
    const orderedKeyUpdate = [];
    for (let i = 0; i < initialKeys.length; i++) {
      if (keyUpdate.indexOf(initialKeys[i]) > -1) {
        orderedKeyUpdate.push(initialKeys[i]);
      }
    }

    const newQuestionnaireData = {};
    for (let j = 0; j < keyBefore.length; j++) {
      newQuestionnaireData[keyBefore[j]] = { ...currentQuestionnaireData[keyBefore[j]] };
    }


    for (let j = 0; j < orderedKeyUpdate.length; j++) {
      newQuestionnaireData[orderedKeyUpdate[j]] = { ...currentQuestionnaireData[orderedKeyUpdate[j]] };
      newQuestionnaireData[orderedKeyUpdate[j]].validate = false;
      newQuestionnaireData[orderedKeyUpdate[j]].showQuestion = false;
      newQuestionnaireData[orderedKeyUpdate[j]].imposer = false;
      newQuestionnaireData[orderedKeyUpdate[j]].interdire = !!orderedKeyUpdate[j].startsWith('C)');
      newQuestionnaireData[orderedKeyUpdate[j]].poserMaintenant = false;
      newQuestionnaireData[orderedKeyUpdate[j]].veto = false;
      if (newQuestionnaireData[orderedKeyUpdate[j]].answers) {
        const answersKeys = Object.keys(newQuestionnaireData[orderedKeyUpdate[j]].answers);
        for (let k = 0; k < answersKeys.length; k++) {
          newQuestionnaireData[orderedKeyUpdate[j]].answers[answersKeys[k]].interdire = false;
          newQuestionnaireData[orderedKeyUpdate[j]].answers[answersKeys[k]].veto = false;
          newQuestionnaireData[orderedKeyUpdate[j]].answers[answersKeys[k]].imposer = false;
        }
      }
    }

    for (let k = 0; k < keys.indexOf(idQuestion); k++) {
      if (newQuestionnaireData[keys[k]].answers) {
        Object.keys(newQuestionnaireData[keys[k]].answers).map((answerKey) => {
          const answer = newQuestionnaireData[keys[k]].answers[answerKey];
          if (answer.checked && questionConditionData[answerKey]) {
            questionConditionData[answerKey].map((item) => {
              // console.log(item);
              if (item.cible.substr(-1) === '/') {
                const questionId = item.cible.substr(0, item.cible.length - 2);
                if (newQuestionnaireData[questionId]) {
                  if (item.interdire) {
                    newQuestionnaireData[questionId].answers[item.cible].interdire = true;
                    newQuestionnaireData[questionId].answers[item.cible].imposer = false;
                    newQuestionnaireData[questionId].answers[item.cible].checked = false;
                  }
                  if (item.imposer) {
                    newQuestionnaireData[questionId].answers[item.cible].imposer = true;
                    newQuestionnaireData[questionId].answers[item.cible].interdire = false;
                    newQuestionnaireData[questionId].answers[item.cible].checked = true;
                  }
                }
              } else {
                if (item.veto) {
                  keys.filter((key) => {
                    if (key.startsWith(item.cible)) {
                      newQuestionnaireData[key].veto = true;
                      newQuestionnaireData[key].interdire = true;
                      newQuestionnaireData[key].imposer = false;
                    }
                    return () => {
                    };
                  });
                }
                if (item.poser) {
                  keys.filter((key) => {
                    if (key.startsWith(item.cible)) {
                      newQuestionnaireData[key].poserMaintenant = true;
                      newQuestionnaireData[key].interdire = false;
                    }
                    return () => {
                    };
                  });
                }
                if (item.interdire) {
                  keys.filter((key) => {
                    if (key.startsWith(item.cible) && !newQuestionnaireData[key].imposer) {
                      newQuestionnaireData[key].interdire = true;
                    }
                    return () => {
                    };
                  });
                }
                if (item.imposer) {
                  keys.filter((key) => {
                    if (key.startsWith(item.cible) && !newQuestionnaireData[key].veto) {
                      newQuestionnaireData[key].imposer = true;
                      newQuestionnaireData[key].interdire = false;
                    }
                    return () => {
                    };
                  });
                }
              }

              return () => {
              };
            });
          }
        });
      }
    }

    newQuestionnaireData[idQuestion].validate = false;
    if (answerId) {
      onChecked(idQuestion, answerId, currentChecked, true, newQuestionnaireData);
    } else {
      setCurrentQuestionnaireData(newQuestionnaireData);
    }
    setCurrentQuestion(idQuestion);
  });
  const scrollHandler = useCallback(_.throttle((event) => {
    if (event.nativeEvent) {
      setFlatListLayout({
        layoutHeight: event.nativeEvent.layoutMeasurement.height,
        contentHeight: event.nativeEvent.contentSize.height,
        contentOffsetY: event.nativeEvent.contentOffset.y,
      });
    }
  }, 200));
  const layoutHandler = useCallback(_.throttle((event) => {
    if (event.nativeEvent) {
      setFlatListLayout((currentState) => ({
        ...currentState,
        layoutHeight: event.nativeEvent.layout.height,
      }));
    }
  }, 200));
  const [finQuestionaire, setFinQuestionaire] = useState(undefined);
  const finaliserQuestionnaire = async (nextState: any, isDone: boolean) => {
    const questionReponse : { [key: string]: string[] } = {};
    const questionResponseOrder : string[] = [];
    const listKey = Object.keys(nextState);
    listKey.forEach((key) => {
      if (nextState[key].validate) {
        const answers: string[] = [];
        Object.keys(nextState[key].answers).forEach((answerKey) => {
          if (nextState[key].answers[answerKey].checked) {
            answers.push(answerKey);
          }
        });
        questionResponseOrder.push(key);
        questionReponse[key] = answers;
      }
    });
    await editConsultation({
      id: route?.params?.id,
      questionResponse: JSON.stringify(questionReponse),
      questionResponseOrder,
      isDone,
      _version: consultation._version,
    });
    setFinQuestionaire(undefined);
    if (route?.params?.enCours === 'true' && !isDone) {
      navigation.dispatch(StackActions.pop());
    } else if (isDone) {
      linkTo(`/consultation/questionnaire/list-disease/${route?.params?.id}`);
    } else {
      setState(false);
      navigation.reset({
        index: 0,
        routes: [{ name: 'consultation' }],
      });
    }
  };
  const onPressSaveAndQuit = () => {
    finaliserQuestionnaire(currentQuestionnaireData, false);
  };
  const onChecked = useFunction((
    idQuestion: string, checkedItem: string, currentChecked: boolean, trueCheck = true, baseState?: QuestionnaireData,
    // showNextQuestion, showingNextQuestion,
  ) => {
    // next lines of code permit to stock or remove checked answer
    // by comparing
    // current item from CheckedAnswersState  with checkedItem that was just checked
    const nextState = baseState ?? _.cloneDeep(currentQuestionnaireData);
    const newCurrentQuestion = nextState[idQuestion];
    const newChecked = newCurrentQuestion.multipleAnswer ? answerChecked.filter((current) => current !== checkedItem) : []; // console.log('current checked:', currentChecked);
    if (currentChecked) { newChecked.push(checkedItem); }

    // fix pushing and filter of newChecked when using checkbox
    // it causes bad appearance of "Suivant" button
    // console.log('newChecked', newChecked);
    /**  switch between 2 different questions that have same name
     * (e.g. 'A)1)c)2.') thanks to hideQuestion attribute */

    if (newCurrentQuestion.multipleAnswer) {
      newCurrentQuestion.answers[checkedItem].checked = currentChecked;
    } else {
      Object.keys(newCurrentQuestion.answers).map((item) => {
        if (!newCurrentQuestion.answers[item].imposer) {
          newCurrentQuestion.answers[item].checked = false;
        }
      });
      newCurrentQuestion.answers[checkedItem].checked = currentChecked;
    }
    if (trueCheck) {
      newCurrentQuestion.lastChecked = Date.now();
    }
    if (questionConditionData[checkedItem]) {
      questionConditionData[checkedItem].map(
        (conditionData) => (questionCondtion(nextState, conditionData, idQuestion)),
      );
    }

    setAnswerChecked(newChecked);
    if (newChecked.length > 0 && route?.params?.update !== 'false') {
      setIgnoreQuestion(false);
      setShowNext(true);
    } else {
      setShowNext(false);
    }
    setCurrentQuestionnaireData(nextState);
  });

  const data = Object.values(currentQuestionnaireData).filter((item) => item.showQuestion);
  console.log('render');

  return (data.length > 0
    ? (
      <>
        <FlatList<QuestionaireDataItem>
          style={{ backgroundColor: '#EFF8F8', paddingHorizontal: 10 }}
          data={data}
          ref={scrollViewRef}
          onScrollToIndexFailed={(info) => {
            if (info.averageItemLength > 0) {
              const offset = info.averageItemLength * info.index;
              scrollViewRef?.current?.scrollToOffset({ offset, animated: true });
              setTimeout(() => {
                try {
                  scrollViewRef?.current?.scrollToIndex({
                    index: info.index, animated: true, viewOffset: 0, viewPosition: 0,
                  });
                } catch (e) {
                  try {
                    scrollViewRef?.current?.scrollToIndex({
                      index: info.index-1, animated: true, viewOffset: 0, viewPosition: 0,
                    });
                  } catch (e) {
                    try {
                      scrollViewRef?.current?.scrollToIndex({
                        index: info.index-2, animated: true, viewOffset: 0, viewPosition: 0,
                      });
                    } catch (e) {
                      // console.log('pas grave on devrait scroll à la prochaine');
                    }
                  }
                }
              }, 100);
            }
          }}
          onScroll={(event) => {
            if (event.persist) {
              event.persist();
            }
            scrollHandler(event);
          }}
          onLayout={(event) => {
            if (event.persist) {
              event.persist();
            }
            layoutHandler(event);
          }}
          onContentSizeChange={_.throttle((w, h) => {
            setFlatListLayout((currentState) => ({
              ...currentState,
              contentHeight: h,
            }));
          }, 200)}
          renderItem={({ item }) => (
            <ListAutoExamenItem
              item={item}
              modifyQuestionnaire={modifyQuestionnaire}
              onChecked={onChecked}
            />
          )}
          ListHeaderComponent={(
            <Tutorial
              style={{ marginTop: 10 }}
              videoId="Tc59C2nmzxA"
            />
)}
          ListFooterComponent={(
            <View style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
              <View style={{
                display: 'flex', flex: 3, alignSelf: 'flex-end', flexDirection: 'column',
              }}
              >
                {route?.params?.update === 'false' ? (
                  <Button
                    rightIcon="arrow-ios-forward"
                    rightIconFill="#fff"
                    style={{ alignSelf: 'flex-end', width: 210, marginBottom: 10 }}
                    onPress={() => {
                      if (isVeto) {
                        linkTo(`/veto-examen/veto-details-consultation/${route?.params?.id}`);
                      } else {
                        linkTo(`/consultation/detail-consultation/${route?.params?.id}`);
                      }
                    }}
                  >
                    Revenir à
                    {' '}
                    {isVeto ? 'la consultation' : 'l\'auto-examen'}
                  </Button>
                ) : (
                  <>
                    <Text category="h4" style={{ marginBottom: 10, textAlign: 'right' }}>Vous ne savez pas ou vous n'êtes pas sûr ?</Text>
                    <Card
                      onPress={() => {
                        setIgnoreQuestion(!ignoreQuestion);
                        if (!ignoreQuestion) {
                          const nextState = _.cloneDeep(currentQuestionnaireData);
                          const newCurrentQuestion = nextState[currentQuestion];

                          Object.keys(newCurrentQuestion.answers).map((item) => {
                            if (!newCurrentQuestion.answers[item].imposer) {
                              newCurrentQuestion.answers[item].checked = false;
                            }
                          });
                          newCurrentQuestion.lastChecked = Date.now();

                          setAnswerChecked([]);
                          setCurrentQuestionnaireData(nextState);
                        }
                      }}
                      style={{
                        flexDirection: 'row', alignSelf: 'flex-end', marginLeft: 55, marginBottom: 10,
                      }}
                    >
                      <Radio
                        onChange={(nextChecked) => {
                          setIgnoreQuestion(nextChecked);
                          if (nextChecked) {
                            const nextState = _.cloneDeep(currentQuestionnaireData);
                            const newCurrentQuestion = nextState[currentQuestion];

                            Object.keys(newCurrentQuestion.answers).map((item) => {
                              if (!newCurrentQuestion.answers[item].imposer) {
                                newCurrentQuestion.answers[item].checked = false;
                              }
                            });
                            newCurrentQuestion.lastChecked = Date.now();

                            setAnswerChecked([]);
                            setCurrentQuestionnaireData(nextState);
                          }
                        }}
                        checked={ignoreQuestion}
                        style={{ marginRight: 5 }}
                      />
                      <Text category="h4">Ignorer cette question</Text>
                    </Card>
                  </>
                )}
              </View>
            </View>
      )}
          keyExtractor={(item) => item.id}
        />
        {isBottom ? (
          <TouchableOpacity
            onPress={() => scrollToBottom(Object.values(currentQuestionnaireData).filter((item) => item.showQuestion).length - 1, 1, -100)}
            style={{
              position: 'absolute', bottom: 110, marginHorizontal: 50, alignSelf: 'center', width: 300, backgroundColor: theme['color-primary-300'], borderRadius: 20, flexDirection: 'row', justifyContent: 'center',
            }}
          >
            <IconUIKitten
              name="arrow-downward-outline"
              style={{
                height: 20, width: 20, paddingRight: 5, paddingTop: 2,
              }}
              fill="#000000"
            />
            <Text>
              Avez-vous vu toutes les réponses ?
            </Text>
          </TouchableOpacity>
        ) : null}

        {!isVeto ? (
          <View style={{
            backgroundColor: 'white', justifyContent: 'space-between', flexDirection: 'row', marginHorizontal: 10, marginBottom: Platform.OS === 'web' ? 0 : 10,
          }}
          >
            {Platform.OS !== 'web' ? (
              <Button size="large" leftIconFill={theme['color-primary-600']} leftIcon="file-add-outline" appearance="ghost" onPress={() => setCamera(true)}>
                Ajouter une photo
              </Button>
            ) : <View />}
            {(showNext || ignoreQuestion)
              ? (
                <Button
                  rightIcon="arrow-ios-forward"
                  rightIconFill="#fff"
                  style={{
                    width: 100, height: 45, marginTop: 10,
                  }}
                  onPress={() => {
                    /** linkTo(`/consultation-screen/questionnaire-screen
                   list-disease-screen/${route.params.id}`); */
                    validAnswer();
                    setShowNext(false);
                  }}
                >
                  Suivant
                </Button>
              ) : null}
          </View>
        ) : null}
        <CustomModal
          title="Vous êtes sur le point de quitter le questionnaire."
          before={<></>}
          noSafeArea
          visible={state}
          scrollable={false}
          onClose={() => { setState(false); }}
          insideModalStyle={{ padding: 20 }}
          heightPercentage={0.5}
        >
          <View>
            <Button
              size="large"
              appearance="filled"
              onPress={onPressSaveAndQuit}
              style={{ marginTop: 20, justifyContent: 'center' }}
            >
              Sauvegarder le questionnaire et quitter
            </Button>
            <Button
              size="large"
              appearance="outline"
              status="danger"
              onPress={() => {
                if (route?.params?.enCours === 'true') {
                  navigation.dispatch(StackActions.pop());
                } else {
                  deleteConsultation({
                    id: consultation?.id,
                    // eslint-disable-next-line no-underscore-dangle
                    _version: consultation?._version,
                  }).then(navigation.reset({
                    index: 0,
                    routes: [{ name: 'consultation' }],
                  }));
                }

                setState(false);
              }}
              style={{ marginTop: 20, justifyContent: 'center' }}
            >
              <Text style={{ textAlign: 'center' }}>
                Quitter sans sauvegarder le questionnaire

              </Text>
            </Button>
            <Button size="large" appearance="ghost" onPress={() => setState(false)} style={{ marginTop: 20, justifyContent: 'center' }}>
              Annuler
            </Button>
          </View>
        </CustomModal>
        {Platform.OS !== 'web' ? (
          <BaseModal
            visible={camera}
            style={{ overflow: 'hidden', alignItems: 'center', backgroundColor: 'black' }}
          >
            <Camera
              onClose={() => { setCamera(false); }}
              onChoose={(result) => {
                onSaveImage(result);
                setCamera(false);
              }}
              withPreview
              ratio={[1, 1]}
              maxWidth={1200}
            />
          </BaseModal>
        ) : null}
        <CustomModal
          title={'Vous êtes sur le point de valider votre questionnaire.\n'
          + 'Etes-vous sûr de vouloir continuer ?'}
          before={<></>}
          noSafeArea
          visible={finQuestionaire !== undefined}
          scrollable={false}
          onClose={() => { setFinQuestionaire(undefined); }}
          insideModalStyle={{ padding: 20 }}
          buttonText="Valider le questionnaire"
          buttonLoadingText="Calcul du résultat en cours"
          buttonPress={async () => {
            setState(false);
            await finaliserQuestionnaire(finQuestionaire, true);
          }}
          secondButtonText="Modifier le questionnaire"
          secondButtonPress={() => {
            setState(false);
            setFinQuestionaire(undefined);
          }}
        />
      </>
    ) : null
  );
};

export default ListAutoExam;
