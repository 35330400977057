/**
 * Custom drawer navigation
 *
 * @author: David BUCH, Shynggys UMBETOV
 */

import React from 'react';
import {
  Drawer, DrawerItem, IndexPath, Layout, Text,
} from '@ui-kitten/components';
import {
  TouchableOpacity, View,
} from 'react-native';
import {
  InitialState, useLinkTo,
} from '@react-navigation/native';

import { DrawerContentComponentProps } from '@react-navigation/drawer/src/types';

import { useSafeAreaInsets } from 'react-native-safe-area-context';
// import comptesData from '../mockData/comptesData';
// import * as WebBrowser from 'expo-web-browser';
import Auth from '@aws-amplify/auth';
import { IconName } from '../components/Icon/Icon';
import Cow from '../assets/svg_icones_ScanFlock/BLUE/cow.svg';
import AcceuilIcon from '../assets/svg_icones_ScanFlock/BLUE/square.svg';
import Doctor from '../assets/svg_icones_ScanFlock/BLUE/doctor.svg';
// import CameraSvg from '../assets/svg_icones_ScanFlock/GREEN/greenCameraIcon.svg';
import PaperPenIcon from '../assets/svg_icones_ScanFlock/GREEN/greenPaperPenIcon.svg';
import Question from '../assets/svg_icones_ScanFlock/BLUE/faq2.svg';
import Screen from '../assets/svg_icones_ScanFlock/BLUE/screen.svg';
import PersonDoct from '../assets/svg_icones_ScanFlock/BLUE/vetoPerson.svg';
import Bell from '../assets/svg_icones_ScanFlock/BLUE/bell.svg';
// import AutoAvatar from '../components/AutoAvatar';
import { useUser } from '../src/API/UserContext';
import AutoAvatar from '../components/AutoAvatar';
import { useResetLinkTo } from './Utils';
import VetoLinkingConfiguration from './VetoLinkingConfiguration';

/**
 * 2. Icons
 */
const HomeIcon = () => (<AcceuilIcon height={20} width={20} fill="#264385" />);
const CowIcon = () => (<Cow height={20} width={20} fill="#264385" />);
const DoctorIcon = () => (<Doctor height={20} width={20} fill="#264385" />);
// const CameraIcon = () => (<CameraSvg height={20} width={20} />);
const PaperPen = () => (<PaperPenIcon height={20} width={20} fill="#264385" />);
const ScreenIcon = () => (<Screen height={20} width={20} fill="#264385" />);
const QuestionIcon = () => (<Question height={20} width={20} fill="#264385" />);
const PersonIcon = () => (<PersonDoct height={20} width={20} fill="#264385" />);
const BellIcon = () => (<Bell height={20} width={20} fill="#264385" />);

function findIndexByRouteName(name?: string) {
  switch (name) {
    case 'veto-tableau-de-bord':
      return 0;
    case 'veto-mon-cabinet-nav':
      return 1;
    case 'veto-examen-nav':
      return 2;
    case 'veto-teleconsultation-nav':
      return 3;
    case 'veto-bse-nav':
      return 4;
    case 'veto-elevage-nav':
      return 5;
    case 'veto-notifications-nav':
      return 6;
    case 'faq':
      return 7;

    default:
      return null;
  }
}

function findFocusedDrawerItem(state: InitialState) {
  let current: InitialState | undefined = state;

  while (current?.routes[current.index ?? 0].state != null) {
    const drawerIndex = findIndexByRouteName(current?.routes[current.index ?? 0].name);
    if (drawerIndex !== null) {
      return drawerIndex;
    }
    current = current.routes[current.index ?? 0].state;
  }

  const drawerIndex = findIndexByRouteName(current?.routes[current.index ?? 0].name);
  if (drawerIndex !== null) {
    return drawerIndex;
  }

  return 0;
}

/**
 * 3. Custom Drawer itself
 */
const VetoCustomDrawer = (props: DrawerContentComponentProps) => {
  const { state } = props;
  const { user } = useUser();
  const inset = useSafeAreaInsets();
  const linkTo = useResetLinkTo(VetoLinkingConfiguration.config);

  // const navigation = useNavigation();

  // const closeDrawer = React.useCallback(
  //   debounce(() => {
  //     navigation.dispatch(DrawerActions.closeDrawer());
  //   }, 50),
  //   [navigation],
  // );

  return (
    <View style={{ flex: 1, marginTop: inset.top, marginBottom: inset.bottom }}>
      <Layout level="4" style={{ flex: 1, justifyContent: 'space-between' }}>

        <Layout
          level="4"
          style={{
            margin: 24,
            marginBottom: 10,
            marginHorizontal: 21,
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <AutoAvatar
              // source={require('../assets/test.png')}
            style={{
              height: 41,
              width: 41,
              borderRadius: 21,
              overflow: 'hidden',
              marginRight: 18,
              marginLeft: 0,
            }}
            turnOff={false}
            avatarInfo={user?.avatarUri || 'default::ManAvatar'}
          />
          <Text category="h6" appearance="hint" style={{ marginLeft: 10 }}>
            {user?.firstname || 'will appear soon'}
          </Text>
        </Layout>

        <Drawer
          selectedIndex={new IndexPath(findFocusedDrawerItem(state))}
          onSelect={(index) => {
            // console.log(index);
            // const toAdd = window.width > 780 ? 0 : 1;
            // eslint-disable-next-line default-case
            switch (index.row) {
              case 0:
                linkTo('/veto-tableau-de-bord');
                break;
              case 1:
                linkTo('/veto-mon-cabinet');
                break;
              case 2:
                linkTo('/veto-examen');
                break;
              case 3:
                linkTo('/veto-teleconsultation');
                break;
              case 4:
                linkTo('/veto-bse');
                break;
              case 5:
                linkTo('/veto-elevage');
                break;
              case 6:
                linkTo('/veto-notifications');
                break;
              case 7:
                linkTo('/faq');
                break;
                /**
              case 8:
                linkTo('/veto-parametre');
                break;
                 */
            }
          }}
        >

          <DrawerItem
            title="Tableau de bord"
            accessoryLeft={HomeIcon}
          />
          <DrawerItem
            title="Mon cabinet"
            accessoryLeft={PersonIcon}
          />
          <DrawerItem
            title="Consultations"
            accessoryLeft={DoctorIcon}
          />
          <DrawerItem
            title="Téléconsultations"
            accessoryLeft={ScreenIcon}
          />
          <DrawerItem
            title="BSE"
            accessoryLeft={PaperPen}
          />
          <DrawerItem
            title="Elevages"
            accessoryLeft={CowIcon}
          />
          <DrawerItem
            title="Notifications"
            accessoryLeft={BellIcon}
          />
          <DrawerItem
            title="FAQ"
            accessoryLeft={QuestionIcon}
          />
          {/**
           <DrawerItem
           title="Paramètres"
           accessoryLeft={ChargeIcon}
           />
           */}

        </Drawer>
      </Layout>

      <TouchableOpacity
        onPress={() => { Auth.signOut(); }}
        style={{
          paddingHorizontal: 26, marginBottom: 18 + inset.bottom,
        }}
      >
        <Text category="h5" status="danger" style={{ textDecorationLine: 'underline' }}>Déconnexion</Text>
      </TouchableOpacity>

    </View>
  );
};

export default VetoCustomDrawer;
