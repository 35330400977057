import React from 'react';
import { FlatList } from 'react-native';
import MaxWidthContainer from '../components/MaxWidthContainer';

import FactureComponent from '../components/FactureComponent';
import { useUser } from '../src/API/UserContext';
import { useBreedingList } from '../src/API/Breeding';

const FacturesScreen = () => {
  const { user } = useUser();
  const { breeding } = useBreedingList(user?.userGroup);

  return (
    <MaxWidthContainer
      outerViewProps={{
        style: {
          padding: 20,
          marginBottom: 20,
        },
      }}
    >
      <FlatList
        data={breeding?.billingHistories?.items}
        renderItem={({ item }) => (
          <FactureComponent
            billingHistory={item}
          />
        )}
      />
    </MaxWidthContainer>
  );
};

export default FacturesScreen;
