import { useMutation, useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { DocumentNode } from 'apollo-link';
import { DeleteUserMessage } from 'aws-sdk/clients/elasticache';
import { ApolloClient } from '@apollo/client';
import {
  CreateUserMutation,
  CreateUserMutationVariables, DeleteUserMutationVariables,
  GetUserQuery,
  GetUserQueryVariables,
  ListUsersQuery,
  ListUsersQueryVariables, ProtocolSoinSaveByBseIdQuery, ProtocolSoinSaveByBseIdQueryVariables,
  // ModelUserFilterInput,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  User,
  UserByEmailQuery,
  UserByEmailQueryVariables,
} from '../API';
import {
  getUser,
  listUsers, protocolSoinSaveByBseId, userByEmail,
} from '../graphql/queries';
import { createUser, deleteUser, updateUser } from '../graphql/mutations';

const listUsersQuery = <DocumentNode>gql(listUsers);

export function useGetUserByIDList(userList: string[]) {
  // let listUser : ModelUserFilterInput;
  // const getPendingInvitation = useGetPendingInvitation(idRealEstate);
  // console.log('userList', userList);
  const {
    // loading,
    data,
    // fetchMore, refetch,
  } = useQuery<ListUsersQuery, ListUsersQueryVariables>(listUsersQuery, {
    variables: {
      filter: {
        or: userList.map((userId) => ({
          id: {
            eq: userId,
          },
        })),
      },
    },
  });
  // console.log('data user list : ', data);
  return { userList: data };
}

export function useGetUserByEmail(email: string) {
  const getUserQuery = <DocumentNode>gql(userByEmail);
  // console.log('email user.ts:', email, typeof email);
  const {
    loading, data, fetchMore, refetch,
  } = useQuery<UserByEmailQuery, UserByEmailQueryVariables>(getUserQuery, {
    variables: {
      email,
    },
    fetchPolicy: 'cache-and-network',
  });
  // console.log('useGetUserByEmail', data);
  return {
    loading, user: <User>data?.userByEmail?.items[0], fetchMore, refetch,
  };
}

export function useGetUser(id?: string) {
  if (!id) {
    return {
      loading: false, user: null, fetchMore: () => {}, refetch: () => {},
    };
  }
  const getUserQuery = <DocumentNode>gql(`
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      lastname
      firstname
      avatarUri
      userGroup
      optIn
      inscriptionNumberOrdreVeto
      breedingId
      vetOfficeId
      email
      expoToken
      notificationLastSeenAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      vetOffice {
        id
        companyName
        emailOffice
        admins
        personnel
        phoneVetOffice
        nameVetSanitaire
        nOrdreCabinet
        nSIRET
        maxPriceTeleConsultation
        maxPriceConsultation
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      breeding {
        id
        nBreeding
        nSIRET
        phone
        emailEleveur
        companyName
        dairyFarm
        sucklerCowHusbandry
        fatteningFarm
        nbCow
        nbHeifer
        nbMale
        nbYoung
        idVeto
        emailVeto
        admins
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`);
  const {
    loading, data, fetchMore, refetch,
  } = useQuery<GetUserQuery, GetUserQueryVariables>(getUserQuery, {
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  });
  // console.log('data useGetUser:', data);
  return {
    loading, user: <User>data?.getUser, fetchMore, refetch,
  };
}

export function useCreateUser() {
  const addUserQuery = <DocumentNode>gql(createUser);
  const [createNewUser] = useMutation<
  CreateUserMutation, CreateUserMutationVariables
  >(addUserQuery);
  return { createNewUser };
}

export function useUpdateUser() {
  const updateUserQuery = <DocumentNode>gql(updateUser);
  const [modifyUser] = useMutation<
  UpdateUserMutation, UpdateUserMutationVariables
  >(updateUserQuery);
  return { modifyUser };
}

export function getUserAsync<T>(client: ApolloClient<T>, id: string) {
  const psQuery = <DocumentNode>gql(getUser);

  return client.query<GetUserQuery,
  GetUserQueryVariables>({
    query: psQuery,
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  });
}

export function useDeleteUser() {
  const [supprimerUser] = useMutation<
  DeleteUserMessage, DeleteUserMutationVariables
  >(gql(deleteUser));
  return supprimerUser;
}
