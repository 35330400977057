const blobToBase64 = (blob: Blob): Promise<string> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onerror = reject;
  reader.onload = () => {
    const data = reader.result.slice(reader.result.indexOf('base64,') + 7);
    resolve(data);
  };
  reader.readAsDataURL(blob);
});

export default {
  blobToBase64,
};
