import React from 'react';

import MaxWidthContainer from '../../../components/MaxWidthContainer';
import InfoCard from '../../../screenComponents/InfoCard';
import VetoListTeleconsultationCards
  from '../../../screenComponents/VetScreens/VetoListTeleconsultationCards';
import { useUser } from '../../../src/API/UserContext';
import { useBreedingList } from '../../../src/API/Breeding';
import { Consultation } from '../../../src/API';
import Tutorial from '../../../components/Tutorial';

export default function VetoTeleconsultationScreen() {
  const { user } = useUser();
  const { breedings } = useBreedingList(user?.userGroup);
  const teleCons:Consultation[] = [];

  if (breedings) {
    breedings.map(
      (item) => item.consultations && item.consultations.items && item.consultations.items.map(
        (item2) => { if (item2 && item2.teleconsultation) teleCons.push(item2); return () => {}; },
      ),
    );
  }
  return (
    <MaxWidthContainer
      withScrollView="keyboardAware"
      outerViewProps={{
        style: {
          padding: 24,
        },
      }}
    >
      <Tutorial
        videoId="arQnAd6eWGI"
      />
      <InfoCard
        title="Bienvenue sur votre espace dédié aux Téléconsultations"
        iconShow="alert-circle-outline"
        // iconCloseShow="close-outline"
        description="Vous pouvez consulter les téléconsultations demandées par les éleveurs et à venir, et rejoindre un rendez-vous en cours. Les téléconsultations peuvent être liées à un auto-examen partagé. Vous pouvez accéder à l’auto-examen partagé en question depuis les détails d’une téléconsultation."
        alertInfo="alertInfo"
      />
      <VetoListTeleconsultationCards tdb={false} teleconsultationData={teleCons} />

    </MaxWidthContainer>
  );
}
