import {useLinkTo, useRoute} from '@react-navigation/native';
import React, {useEffect, useState} from 'react';
import {Text} from '@ui-kitten/components';
import {useForm} from 'react-hook-form';
import {RouteProp} from '@react-navigation/core/lib/typescript/src/types';

import {Platform} from 'react-native';
import MaxWidthContainer from '../../components/MaxWidthContainer';
import FTextInput from '../../components/Form/TextInput';
import FDatepicker from '../../components/Form/DatePicker';
import FSelect from '../../components/Form/Select';
import {typeGenderCustom} from '../../src/selectFormData/ajoutAnimalData';

import Form from '../../components/Form/Form';
import Button from '../../components/Button';

import {useUser} from '../../src/API/UserContext';
import {useBreedingList, useUpdateBreedingMutation} from '../../src/API/Breeding';
import {useGetAnimal} from '../../src/API/Animals';
import {useListLots} from '../../src/API/Lots';
import {useBuildingList, useGetBuilding} from '../../src/API/Buildings';
import {Animals, Buildings, LockedStatus} from '../../src/API';

import {AjoutAnimalForm, TabElevageParamList} from '../../types';
import CrudBL from '../../screenBusinessLogic/ElevageScreen/CrudBL';
import {useAjoutConsultation} from '../../screenBusinessLogic/ConsultationScreen/CrudBL';
import CustomModal from '../../components/Modals/CustomModal';
import {AvailableValidationRules} from "../../components/Form/validation";

const typeGenderArray = Object.values(typeGenderCustom);

const AjoutAnimalScreen = () => {
  const { user } = useUser();
  const linkTo = useLinkTo();
  const { breeding } = useBreedingList(user?.userGroup);

  const route = useRoute<RouteProp<TabElevageParamList, 'modify-animal'>>();
  // console.log('route animal modify:', route);

  /**  SELECT LOTS     */
  const [lotsList, setLotsList] = useState<
  Array<{ label: string | undefined, key: string | undefined }> | undefined
  >([]);
  const [processing, setProcessing] = useState(false);
  const [openDelete, setOpenDelete] = useState<boolean | undefined>(undefined);
  const { lots } = useListLots();

  useEffect(() => {
    /**   Refactored data structure for further providing to select component  */
    const selectLot = lots?.filter((lot) => !!lot && !lot._deleted).map(
      (lot) => ({ label: lot?.numberLot, key: lot?.id }),
    );
    setLotsList(selectLot);

    return () => {};
  }, []);

  /**  SELECT BUILDINGS    */
  const [buildingList, setBuildingList] = useState<
  Array<{ label: string | undefined, key: string | undefined }> | undefined
  >([]);
  const {
    buildingS,
    // loadingBuilding
  } = useBuildingList();
  // console.log('buildingS animal ajout:', buildingS);

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    const selectBuilding = buildingS?.filter((building) => building && !building._deleted).map(
      (building) => ({ label: building?.nameBuilding, key: building?.id }),
    );
    setBuildingList(selectBuilding);

    return () => {};
  }, [buildingS]);

  const [showZone, setShowZone] = useState(false);

  /**  SELECT ZONE    */
  const [zoneList, setZoneList] = useState<
  Array<{ label: string | undefined | null, key: string | undefined }> | undefined
  >([]);

  const { updateAnimaux, addAnimal } = CrudBL.UseAjoutAnimal();
  const { modifyBreeding } = useUpdateBreedingMutation();

  /**  MODIFY ANIMAL    */
  let currentAnimal: Animals | undefined;
  let currentBuilding: Buildings | undefined;
  if (route.params) {
    const { animal } = useGetAnimal(route?.params?.id);
    if (animal && animal.buildingId) {
      const { building } = useGetBuilding(animal?.buildingId);
      currentBuilding = building;
    }
    currentAnimal = animal;
    useEffect(() => {
      if (animal && currentBuilding) {
        setShowZone(true);
        // eslint-disable-next-line no-underscore-dangle
        const zoneListLocal = currentBuilding?.zone?.items?.filter((zone) => zone && zone._deleted)
          .map(
            (zon) => ({ label: zon?.name, key: zon?.id }),
          );
        // console.log('zoneListLocal', zoneListLocal);
        setZoneList(zoneListLocal);
        // console.log('zoneList : ', animal.building);
      }
      return () => {};
    }, []);
    // console.log('selectedBuilding', showZone);
  }

  const { addConsultationByAnimal } = useAjoutConsultation();

  /**        FORM to CRUD               */
  const ajoutAnimalForm = useForm<AjoutAnimalForm>();
  const onAjoutAnimal = async (data: AjoutAnimalForm, newAutoExam = false) => {
    // console.log('data onAjoutAnimal:', data);
    setProcessing(true);

    // on regarde si un animal avec le même numéro d'identification existe déjà.
    let found = false;
    const elem = breeding.animals.items.find((item) => !item._deleted && item.numberID == data.numberID);
    if (elem) {
      found = true;
    }
    if (currentAnimal && elem && elem.id === currentAnimal.id) {
      found = false;
    }

    if (!found) {
      if (user && breeding) {
        if (breeding && (!breeding.dateFirstCons || !breeding.nextSubscriptionDate)) {
          const date2Mois = new Date();
          const date = new Date();
          date2Mois.setMonth(date2Mois.getMonth() + 2);
          date2Mois.setHours(0, 0, 0, 0);

          await modifyBreeding({
            variables: {
              input: {
                id: breeding.id,
                _version: breeding._version,
                dateFirstCons: date.toISOString(),
                nextSubscriptionDate: date2Mois.toISOString(),
              },
            },
          });
        }

        if (route.params && currentAnimal) {
          updateAnimaux(route, data, currentAnimal).then(() => {
            setProcessing(false);
            if (newAutoExam) {
              addConsultationByAnimal(currentAnimal?.id).then((newCreateConsByAnimal) => {
                linkTo(`/consultation/questionnaire/${newCreateConsByAnimal?.createConsultation?.id}`);
              });
            } else {
              linkTo('/elevage');
            }
          });
        } else {
          addAnimal(data).then((newCreatedAnial) => {
            setProcessing(false);
            if (newAutoExam) {
              addConsultationByAnimal(newCreatedAnial?.createAnimals?.id).then((newCreateConsByAnimal) => {
                linkTo(`/consultation/questionnaire/${newCreateConsByAnimal?.createConsultation?.id}`);
              });
            } else {
              linkTo('/elevage');
            }
          });
        }
      }
    } else {
      setProcessing(false);
      setOpenDelete(true);
    }
  };
  return (
    <MaxWidthContainer
      withScrollView="keyboardAware"
      outerViewProps={{ style: { padding: 24, marginBottom: 10 } }}
    >
      <Form {...ajoutAnimalForm} defaultValues={currentAnimal}>
        <>
          <FTextInput title="N° d'identification*" name="numberID" keyboardType="numeric" placeholder="Saisissez le n° d'identification"
            validators={[AvailableValidationRules.required]}
          />
          <FSelect data={typeGenderArray} title="Sexe*" name="gender" placeholder="Sélectionnez le sexe" containerStyle={{ marginTop: 10 }}
                   validators={[AvailableValidationRules.required]}/>

          <FDatepicker name="birthDate" title="Date de naissance*" placeholder="jj/mm/aaaa" icon="calendar-outline"
                       validators={[AvailableValidationRules.required]} />

          <FTextInput title="Nom (optionnel)" name="name" placeholder="Saisissez le nom de l'animal" />
          <FSelect name="lotsId" data={lotsList} title="Lot (optionnel)" placeholder="Affecter l'animal à un lot" size="large" appearance="default" status="primary" />
          <FSelect
            name="buildingId"
            data={buildingList}
            onChangeValue={(selectedKey) => {
              if (selectedKey) {
                currentBuilding = buildingS?.find(
                  (item) => item?.id === selectedKey,
                );
                setShowZone(true);
                // console.log('currentBuilding:', currentBuilding);
                const zoneListLocal = currentBuilding?.zone?.items?.map(
                  (zon) => ({ label: zon?.name, key: zon?.id }),
                );
                // console.log('zoneListLocal', zoneListLocal);
                setZoneList(zoneListLocal);
              }
            }}
            title="Bâtiment(s)"
            placeholder="Affecter l'animal à un bâtiment"
            size="large"
            appearance="default"
            status="primary"
            containerStyle={{ marginTop: 10 }}
          />
          {showZone && (
          <FSelect name="zoneId" data={zoneList} title="Zone(s)" placeholder="Affecter l'animal à un zone" size="large" appearance="default" status="primary" containerStyle={{ marginTop: 10 }} />
          )}

          <Button
            loading={processing}
            style={{ marginTop: 20 }}
            onPress={ajoutAnimalForm.handleSubmit((data) => onAjoutAnimal(data))}
          >
            Enregistrer
          </Button>
          {breeding && breeding.locked === LockedStatus.unlocked && (
          <>
            <Text category="s2" style={{ marginVertical: 20 }}>Réalisez un nouvel auto-examen avec cet animal</Text>
            <Button
              loading={processing}
              onPress={
                ajoutAnimalForm.handleSubmit(
                  (data) => onAjoutAnimal(data, true),
                )
              // linkTo('/consultation/realiser-auto-exam')
              }
            >
              Enregistrer et réaliser un nouvel auto-examen
            </Button>
          </>
          )}
        </>
      </Form>

      <Text category="c1" appearance="hint" style={{ marginBottom: 30 }}>* champs obligatoires</Text>
      <CustomModal
        title={'Un animal avec le même numéro d\'identification existe déjà !'
          + '\n'
          + 'Merci de changer votre numéro d\'identification.'}
        before={<></>}
        noSafeArea
        visible={openDelete !== undefined}
        scrollable={false}
        onClose={() => setOpenDelete(undefined)}
        insideModalStyle={{ textAlign: 'center', padding: 20 }}
        heightPercentage={Platform.OS === 'web' ? 0.50 : 0.6}
        buttonText="Fermer"
        buttonPress={() => setOpenDelete(undefined)}
      />
    </MaxWidthContainer>
  );
};

export default AjoutAnimalScreen;
