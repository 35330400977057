import { useLinkTo, useNavigation, useRoute } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { Text, useTheme } from '@ui-kitten/components';
import { useForm } from 'react-hook-form';

import { RouteProp } from '@react-navigation/core/lib/typescript/src/types';
import { AjoutAnimalForm, AjoutLotForm, TabElevageParamList } from '../../types';

import MaxWidthContainer from '../../components/MaxWidthContainer';
import Button from '../../components/Button';

import CompTextInput from '../../components/Form/TextInput';
import Form from '../../components/Form/Form';
import FSelect from '../../components/Form/Select';

import { useUser } from '../../src/API/UserContext';
import { useGetLot } from '../../src/API/Lots';
import { useBuildingList } from '../../src/API/Buildings';
import { LockedStatus, Lots } from '../../src/API';

import CrudBL from '../../screenBusinessLogic/ElevageScreen/CrudBL';
import CustomModal from '../../components/Modals/CustomModal';
import InfoCard from '../../screenComponents/InfoCard';
import { AvailableValidationRules } from '../../components/Form/validation';
import { useBreedingList } from '../../src/API/Breeding';

const AjoutLotScreen = () => {
  const { user } = useUser();
  const linkTo = useLinkTo();
  const theme = useTheme();
  const navigation = useNavigation();
  const { breeding } = useBreedingList(user?.userGroup);
  const [processing, setProcessing] = useState(false);
  // for modal
  const [openDelete, setOpenDelete] = useState<{ title : string, id: string, data }>();

  /** Update Lot */
  const route = useRoute<RouteProp<TabElevageParamList, 'modify-lot'>>();
  // console.log('route modify lot:', route);
  const { updateLotFunc, addLot } = CrudBL.UseAjoutLot();

  let currentLot: Lots | undefined;
  if (route.params) {
    const { lot } = useGetLot(route?.params?.id);
    currentLot = lot;
    // console.log('current lot:', currentLot);
  }

  /**    Modify lot & Create Lot    */
  const ajoutLotForm = useForm<AjoutLotForm>();
  const onAjoutLot = (data: AjoutLotForm) => {
    setProcessing(true);
    if (user) {
      if (route.params) {
        updateLotFunc(route, data, currentLot).then((newCurrentLot) => {
          setProcessing(false);
          setOpenDelete({ id: currentLot?.id });
        });
      } else {
        addLot(data).then((newCurrentLot) => {
          setProcessing(false);
          setOpenDelete({ id: newCurrentLot?.createLots?.id });
        });
      }
    }
  };

  // console.log('openDelete onAjoutLot:', openDelete);
  const confirmButtonThenAffect = () => {
    setOpenDelete(undefined);
    navigation.navigate('manage-affected-animals', {
      listTitle: 'Animaux', manage: 'les animaux', whichPage: 'lot', id: openDelete?.id,
    });
  };

  const confirmGoDetail = () => {
    setOpenDelete(undefined);
    linkTo(`/elevage/detail-lot/${openDelete?.id}`);
  };

  /**  SELECT BUILDINGS    */
  const [buildingList, setBuildingList] = useState<
  Array<{ label: string | undefined, key: string | undefined }> | undefined
  >([]);
  // console.log('buildingList:', buildingList);
  const {
    buildingS,
    // loadingBuilding
  } = useBuildingList();
  // const [selectedBuilding, setSelectedBuilding] = useState(null);
  useEffect(() => {
    /**   Refactored data structure for further providing to select component  */
    const selectBuilding = buildingS?.filter(
      // eslint-disable-next-line no-underscore-dangle
      (building) => !!building && !building._deleted,
    ).map((building) => ({ label: building?.nameBuilding, key: building?.id }));
    // console.log('selectBuilding:', selectBuilding);
    setBuildingList(selectBuilding);

    return () => {};
  }, []);

  return (
    <>
      <MaxWidthContainer
        withScrollView="keyboardAware"
        outerViewProps={{ style: { padding: 24, paddingBottom: 40 } }}
      >
        <Form {...ajoutLotForm} defaultValues={currentLot}>
          <>
            <CompTextInput title="N° de lot" name="numberLot" placeholder="Saisissez le numéro de lot" validators={[AvailableValidationRules.required]} />
            <CompTextInput title="Nom du lot" name="name" placeholder="Saisissez le nom du lot" containerStyle={{ marginTop: 10 }} validators={[AvailableValidationRules.required]} />
            <FSelect name="buildingId" data={buildingList} title="Bâtiment(s)" placeholder="Affecter le lot à un bâtiment" size="large" appearance="default" status="primary" containerStyle={{ marginTop: 10 }} />

            <Button
              loading={processing}
              onPress={ajoutLotForm.handleSubmit((data) => onAjoutLot(data))}
              style={{ marginTop: 20 }}
            >
              Enregistrer le lot
            </Button>
          </>
        </Form>
        <Text category="c1" appearance="hint">* champs obligatoires</Text>
        <InfoCard
          title="alertInfo"
          iconShow="alert-circle-outline"
            // iconCloseShow="close-outline"
          infoStyle={{ marginTop: 20, backgroundColor: theme['color-primary-100'] }}
          description="En appuyant sur le bouton ci-dessus, le lot sera créé. Vous pourrez gérer les animaux qui composeront votre lot depuis la prochaine étape et à tout moment par la suite."
          alertInfo="alertInfo"
        />

      </MaxWidthContainer>
      <CustomModal
        title={'Vous venez de créer un lot d\'animaux.'
      + '\n'
      + 'Vous pouvez désormais affecter à ce lot des animaux enregistrés dans votre espace Élevage.'}
        before={<></>}
        noSafeArea
        visible={openDelete !== undefined}
        scrollable={false}
        onClose={() => confirmGoDetail()}
        insideModalStyle={{ textAlign: 'center', padding: 20 }}
        buttonText="Continuer"
        secondButtonText={"Je n'ai pas d'animal à affecter pour l'instant"}
        heightPercentage={0.36}
        buttonPress={() => confirmButtonThenAffect(openDelete)}
      />
    </>
  );
};

export default AjoutLotScreen;
