import * as React from 'react';
import {
  StyleSheet, TouchableOpacity, View, Modal, ViewStyle, ModalProps,
} from 'react-native';

export type ActionSheetProps = {
  children: React.ReactNode,
  animationType?: ModalProps['animationType']
  visible: boolean,
  onClose?: () => void
  backDropStyle?: ViewStyle
  style?:ViewStyle
};

const BaseModal = (props: ActionSheetProps) => {
  const {
    children, visible, onClose, backDropStyle, style, animationType = 'fade',
  } = props;

  return (
    <Modal
      animationType={animationType}
      onRequestClose={onClose}
      transparent
      statusBarTranslucent
      visible={visible}
      style={[{
        margin: 0,
        ...StyleSheet.absoluteFillObject,
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }, style]}
    >
      <View style={[{
        margin: 0,
        ...StyleSheet.absoluteFillObject,
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }, style]}
      >
        <View
          style={[{
            ...StyleSheet.absoluteFillObject,
            backgroundColor: 'rgba(0,0,0,0.7)',
            flex: 1,
          }, backDropStyle]}
        >
          <TouchableOpacity style={styles.exit} onPress={onClose} />
        </View>
        {children}
      </View>
    </Modal>
  );
};

export default BaseModal;

const styles = StyleSheet.create({
  modal: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  exit: {
    flex: 1,
  },
});
