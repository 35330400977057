import * as React from 'react';

export const getChildrenNames = (innerChildren: React.ReactNode): string[] => {
  const array = (Array.isArray(innerChildren) ? [...innerChildren] : [innerChildren]).reduce(
    (arr: string[], child: React.ReactElement) => {
      if (child?.props?.name && child?.props?.name !== '') {
        arr.push(child?.props?.name);
        if (child?.props?.accessoryElement) {
          return arr.concat(getChildrenNames(child?.props?.accessoryElement));
        }
        return arr;
      }
      if (child?.props?.children) {
        return arr.concat(getChildrenNames(child?.props?.children));
      }
      if (Array.isArray(child)) {
        return arr.concat(getChildrenNames(child));
      }
      return arr;
    },
    []
  );
  return array;
};
