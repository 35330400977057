import { View } from 'react-native';
import { Layout, Text } from '@ui-kitten/components';
import React, { useState } from 'react';
import Card from '../Card';
import InfoModal from '../InfoModal';

type QuestionProps = {
  question: string;
  message?: string;
  titre1? :string;
  titre2? :string;
  titre3? :string;
  titre4? :string;
  // content?: string,
  description: string,
  hideQuestion : boolean;
  createdAt?: string,
  user?: {
    id: string;
    name: string;
  }
};

const QuestionCard = (props: QuestionProps) => {
  const {
    question, message, description, createdAt, user, hideQuestion, titre1, titre2, titre3, titre4, // user, content,
  } = props;
  // console.log('props question:', props);
  const [openInfoModal, setOpenInfoModal] = useState('');

  return (
    <>
      {titre1 && (
        <View style={{ alignItems: 'center', paddingBottom: 20, paddingTop: 35 }}>
          <Text category="h1">{titre1}</Text>
        </View>
      )}
      {titre2 && (
        <View style={{ paddingLeft: 15, paddingBottom: 20, paddingTop: 35 }}>
          <Text category="h2" status="primary">{titre2}</Text>
        </View>
      )}
      {titre3 && (
        <View style={{ paddingLeft: 45, paddingTop: 35, paddingBottom: 20 }}>
          <Text category="h3" status="primary">{titre3}</Text>
        </View>
      )}
      {titre4 && (
        <View style={{ paddingLeft: 65, paddingTop: 35, paddingBottom: 20 }}>
          <Text category="h4" status="primary">{titre4}</Text>
        </View>
      )}
      {question && !hideQuestion && (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Layout style={{
            height: 60, width: 60, borderRadius: 60, marginRight: 10, alignItems: 'center', justifyContent: 'center',
          }}
          >
            <Text>SF</Text>
          </Layout>
          <Card
            style={{
              flexDirection: 'row', borderRadius: 20, marginVertical: 20, width: '70%',
            }}
          >
            <Text category="h3" style={{ flex: 11 }}>
              {question}
            </Text>

            {description ? (
              <InfoModal infoText={description} infoTitle="Aide pour la question" />
            ) : null}
          </Card>
        </View>
      )}

      {message && (
      <View>
        <Layout style={{
          height: 60, width: 60, borderRadius: 60, marginRight: 10, alignItems: 'center', justifyContent: 'center',
        }}
        >
          <Text>AD</Text>
        </Layout>
        <Card
          style={{ borderRadius: 20, marginVertical: 20, width: '70%' }}
        >
          <Text category="h5">
            {message}
          </Text>
        </Card>
      </View>
      )}
    </>
  );
};

export default QuestionCard;
