import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import { createPendingInvitation } from '../graphql/mutations';
import { CreatePendingInvitationMutation, CreatePendingInvitationMutationVariables } from '../API';

export function useCreatePendingInvitationMutation() {
  const [createPendingInvitationVeto, { loading: invitationLoading }] = useMutation<
  CreatePendingInvitationMutation, CreatePendingInvitationMutationVariables
  >(gql(createPendingInvitation));

  return { createPendingInvitationVeto, invitationLoading };
}
