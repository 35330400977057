import _ from 'lodash';
import { LinkProps } from '../../types';
import questionnaireData from '../../src/mockData/bseQuestionData';
import { BSE } from '../../src/API';

const linkToSwitch = ({
  state, linkTo,
}: LinkProps) => {
  switch (state) {
    case 'demande-bse':
      linkTo('/bilan-sanitaire/demande-bse');
      break;

    case 'prebilan':
      linkTo('/bilan-sanitaire/prebilan');
      break;
    case 'prebilan-questionnaire':
      linkTo('/bilan-sanitaire/prebilan-questionnaire');
      break;

    case 'historique-bse':
      linkTo('/bilan-sanitaire/bse-historique');
      break;
    case 'historique-prebilan':
      linkTo('/bilan-sanitaire/prebilan-historique');
      break;
    default:
      break;
  }
};

const initQuestionnaire = (
  questionConditionData: any,
  bse?: BSE,
  isVeto?: boolean,
  isAllCheck?: boolean,
) => {
  let currentQuestion;
  let showNext;
  let diseaseSave = [];
  let currentQuestionnaireDataTemp = {};
  let questionConditionDataTemp = { ...questionConditionData };
  let repeatPrefix = '';
  const keyQuestionnaireInit = Object.keys(questionnaireData);
  const valueQuestionnaireInit = Object.values(questionnaireData);
  // ici commence la boucle pour le R3 David
  for (let i = 0; i <= keyQuestionnaireInit.length - 1; i++) {
    if (valueQuestionnaireInit[i].function && valueQuestionnaireInit[i].function.substr(0, 1) === 'R') {
      repeatPrefix = valueQuestionnaireInit[i].id;
      currentQuestionnaireDataTemp = Object.assign(
        currentQuestionnaireDataTemp, { [keyQuestionnaireInit[i]]: valueQuestionnaireInit[i] },
      );
      const nbRepeat = valueQuestionnaireInit[i].function.substr(1, 1);
      // on trouve le groupe de question
      const questionGroup = {};
      let isBseOnly = true;
      for (let j = i + 1; j < keyQuestionnaireInit.length; j++) {
        if (valueQuestionnaireInit[j].id.indexOf(repeatPrefix) > -1) {
          Object.assign(
            questionGroup, { [keyQuestionnaireInit[j]]: { ...valueQuestionnaireInit[j] } },
          );
          if (!valueQuestionnaireInit[j].bseOnly) {
            isBseOnly = false;
          }
          currentQuestionnaireDataTemp = Object.assign(
            currentQuestionnaireDataTemp, { [keyQuestionnaireInit[j]]: valueQuestionnaireInit[j] },
          );
          i++;
        } else {
          break;
        }
      }

      // console.log(questionGroup);

      // on repette le groupe de questions X fois
      for (let k = 2; k <= nbRepeat; k++) {
        const newGroup = {
          [`${repeatPrefix}#suite${k}`]: {
            id: `${repeatPrefix}#suite${k}`,
            name: 'Reposer la question?',
            description: '',
            bseOnly: isBseOnly,
            interdire: k > 2,
            veto: false,
            imposer: false,
            poserMaintenant: false,
            hideQuestion: false,
            answers: {
              [`suite${k}#${repeatPrefix}1/`]: {
                name: 'oui',
                description: '',
              },
              [`suite${k}#${repeatPrefix}2/`]: {
                name: 'non',
                description: '',
              },
            },
          },
        };
        const conditionTemp1 = [];
        const conditionTemp2 = [];
        const questionGroupKeys = Object.keys(questionGroup);
        for (let l = 0; l < questionGroupKeys.length; l++) {
          newGroup[`${k}_${questionGroupKeys[l]}`] = {
            ...questionGroup[questionGroupKeys[l]],
            interdire: true,
            answers: Object.fromEntries(
              Object.keys(questionGroup[questionGroupKeys[l]].answers).map(
                (key) => [`${k}_${key}`, { ...questionGroup[questionGroupKeys[l]].answers[key] }],
              ),
            ),
            id: `${k}_${questionGroup[questionGroupKeys[l]].id}`,
          };

          conditionTemp1.push({
            poser: false, imposer: false, interdire: false, resetInterdire: true, cible: `${k}_${questionGroupKeys[l]}`,
          }, {
            poser: false, imposer: false, interdire: false, resetInterdire: true, cible: `${repeatPrefix}#suite${k + 1}`,
          });
          conditionTemp2.push({
            poser: false, imposer: false, interdire: true, cible: `${k}_${questionGroupKeys[l]}`,
          }, {
            poser: false, imposer: false, interdire: true, cible: `${repeatPrefix}#suite${k + 1}`,
          });

          if (questionConditionDataTemp[questionGroupKeys[l]] && k < nbRepeat) {
            if (k === 2) {
              questionConditionDataTemp = {
                ...questionConditionDataTemp,
                [questionGroupKeys[l]]: questionConditionDataTemp[questionGroupKeys[l]].concat([
                  {
                    poser: false, imposer: false, interdire: true, cible: `${repeatPrefix}#suite${k}`,
                  },
                ]),
              };
            }
            questionConditionDataTemp = {
              ...questionConditionDataTemp,
              [`${k}_${questionGroupKeys[l]}`]: [
                {
                  poser: false, imposer: false, interdire: true, cible: `${repeatPrefix}#suite${k + 1}`,
                },
              ],
            };
          }
        }
        questionConditionDataTemp = {
          ...questionConditionDataTemp,
          [`suite${k}#${repeatPrefix}1/`]: conditionTemp1,
          [`suite${k}#${repeatPrefix}2/`]: conditionTemp2,
        };
        currentQuestionnaireDataTemp = { ...currentQuestionnaireDataTemp, ...newGroup };
      }
    } else {
      currentQuestionnaireDataTemp = Object.assign(
        currentQuestionnaireDataTemp, { [keyQuestionnaireInit[i]]: valueQuestionnaireInit[i] },
      );
    }
  }

  const objectValues = Object.values(currentQuestionnaireDataTemp);
  let lastValidAnswerRest;
  objectValues[0].showQuestion = true;
  const newState = _.cloneDeep(currentQuestionnaireDataTemp);
  const checked = [];
  if (bse) {
    newState['D)1)1.'].answers['D)1)1.1/'].imposer = true;
    newState['D)1)1.'].answers['D)1)1.2/'].imposer = true;
    newState['D)1)1.'].answers['D)1)1.3/'].imposer = true;
    if (bse.breeding.fatteningFarm) {
      checked.push('D)1)1.3/');
      newState['D)1)1.'].answers['D)1)1.3/'].checked = true;
    }
    if (bse.breeding.dairyFarm) {
      checked.push('D)1)1.2/');
      newState['D)1)1.'].answers['D)1)1.2/'].checked = true;
    }
    if (bse.breeding.sucklerCowHusbandry) {
      checked.push('D)1)1.1/');
      newState['D)1)1.'].answers['D)1)1.1/'].checked = true;
    }
    newState['D)1)1.'].lastChecked = Date.now();
  }
  const { diseaseSave: diseaseSaveTmp, ...lastValidAnswerRestTemp } = validAnswer(
    checked,
    questionConditionDataTemp,
    currentQuestionnaireDataTemp,
    'D)1)1.',
    diseaseSave,
    true,
    newState,
    false,
    false, isVeto, isAllCheck,
  );
  diseaseSave = diseaseSaveTmp;
  lastValidAnswerRest = lastValidAnswerRestTemp;
  if (bse && bse.questionResponse) {
    const questionResponseTemp = JSON.parse(bse?.questionResponse);
    const keyQuestionReponseOrder = bse.questionResponseOrder;
    const isText = false;

    const currentVetoQuestion = isVeto && bse.lastQuestionVeto ? keyQuestionReponseOrder.indexOf(bse.lastQuestionVeto) : -1;

    let hasValid = false;
    for (let r = 0; r < keyQuestionReponseOrder.length; r++) {
      if (newState[keyQuestionReponseOrder[r]]?.answers) {
        Object.keys(newState[keyQuestionReponseOrder[r]].answers).forEach((key) => {
          if (questionResponseTemp[keyQuestionReponseOrder[r]].indexOf(key) > -1) {
            newState[keyQuestionReponseOrder[r]].answers[key].checked = true;
          } else if (newState[keyQuestionReponseOrder[r]].answers[keyQuestionReponseOrder[r]]) {
            newState[keyQuestionReponseOrder[r]].answers[keyQuestionReponseOrder[r]].answerText = questionResponseTemp[keyQuestionReponseOrder[r]][0];
            newState[keyQuestionReponseOrder[r]].answers[keyQuestionReponseOrder[r]].checked = true;
          }
        });
      }
      if (!isVeto || isAllCheck || r <= currentVetoQuestion) {
        hasValid = true;
        const { diseaseSave: diseaseSaveTmp, ...lastValidAnswerRestTemp } = validAnswer(
          questionResponseTemp[keyQuestionReponseOrder[r]],
          questionConditionDataTemp,
          currentQuestionnaireDataTemp,
          keyQuestionReponseOrder[r],
          diseaseSave,
          true,
          newState,
          isText,
          isVeto && bse.lastQuestionVeto ? r === currentVetoQuestion : r === (keyQuestionReponseOrder.length - 1),
          isVeto,
          isAllCheck,
        );

        diseaseSave = diseaseSaveTmp;
        lastValidAnswerRest = lastValidAnswerRestTemp;
      }
    }
    if (!hasValid) {
      currentQuestion = Object.keys(newState)[1];
      showNext = true;
    }
  } else {
    currentQuestion = Object.keys(newState)[1];
  }

  return _.mergeWith(lastValidAnswerRest, {
    currentQuestion,
    showNext,
    currentQuestionnaireData: newState,
    questionConditionData: questionConditionDataTemp,
    diseaseSave: diseaseSaveTmp,
  }, (o, s) => (s !== undefined ? s : o));
};

const validAnswer = (
  currentAnswerCheck: string[],
  questionConditionDataTemp: any,
  currentQuestionnaireData: any,
  id: string,
  diseaseSave: any,
  isInit?: boolean,
  newState?: any,
  isTextInput?:boolean,
  isLast?:boolean,
  isVeto?:boolean,
  isAllCheck?:boolean,
) => {
  let keys = Object.keys(currentQuestionnaireData);
  let newListKey = keys.slice(keys.indexOf(id));
  let nextState = {};
  if (newState) {
    keys = Object.keys(newState);
    newListKey = keys.slice(keys.indexOf(id));
    nextState = newState;
  } else {
    nextState = _.cloneDeep(currentQuestionnaireData)
  }

  // all disease that possible exactlh for these Q&A chosen

  //
  if (!isTextInput) {
    currentAnswerCheck.map((answer) => {
      let nbRepeat = '';
      if (typeof answer === 'string' && answer.includes('_')) {
        const answerSplit = answer.split('_');
        nbRepeat = `${answerSplit[0]}_`;
        answer = answerSplit[1];
      }
      if (questionConditionDataTemp[answer]) {
        questionConditionDataTemp[answer].map((item) => {
          if (item.cible.substr(-1) === '/') {
            const regex = /([A-Za-z0-9).]+?)([0-9]+\/)/gm;
            let questionId = '';
            let reponseId = '';
            let m;
            while ((m = regex.exec(item.cible)) !== null) {
              // This is necessary to avoid infinite loops with zero-width matches
              if (m.index === regex.lastIndex) {
                regex.lastIndex++;
              }

              // The result can be accessed through the `m`-variable.
              m.forEach((match, groupIndex) => {
                if (groupIndex === 1) {
                  questionId = match;
                }
                if (groupIndex === 2) {
                  reponseId = match;
                }
              });
            }
            if (questionId.startsWith('D)16)') && reponseId === '4/') {
              //console.log(answer);
              const regex2 = /([A-Za-z0-9).]+?)([0-9]+\.)/gm;
              let famillediseasId = '';
              let n;
              while ((n = regex2.exec(item.cible)) !== null) {
                // This is necessary to avoid infinite loops with zero-width matches
                if (n.index === regex2.lastIndex) {
                  regex2.lastIndex++;
                }

                // The result can be accessed through the `m`-variable.
                n.forEach((match, groupIndex) => {
                  if (groupIndex === 2) {
                    famillediseasId = match.slice(0, -1);
                  }
                });
              }
              if (famillediseasId) {
                diseaseSave.push(famillediseasId);
              }
            } else if (nextState[questionId]) {
              if (item.interdire) {
                nextState[questionId].answers[item.cible].interdire = true;
                nextState[questionId].answers[item.cible].imposer = false;
                nextState[questionId].answers[item.cible].checked = false;
              }
              if (item.imposer) {
                nextState[questionId].answers[item.cible].imposer = true;
                nextState[questionId].answers[item.cible].interdire = false;
                nextState[questionId].answers[item.cible].checked = true;
                if (nextState[questionId] && !nextState[questionId].multipleAnswer) {
                  nextState[questionId].validate = true;
                  nextState[questionId].interdire = true;
                }
              }
            }
          } else {
            if (item.veto) {
              keys.filter((key) => {
                if (key.startsWith(item.cible)) {
                  nextState[nbRepeat + key].veto = true;
                  nextState[nbRepeat + key].interdire = true;
                  nextState[nbRepeat + key].imposer = false;
                }
                return () => {
                };
              });
            }
            if (item.poser) {
              keys.filter((key) => {
                if (key.startsWith(item.cible)) {
                  nextState[nbRepeat + key].poserMaintenant = true;
                }
                return () => {
                };
              });
            }
            if (item.interdire) {
              keys.filter((key) => {
                if (key.startsWith(item.cible) && !nextState[nbRepeat + key].imposer) {
                  nextState[nbRepeat + key].interdire = true;
                }
                return () => {
                };
              });
            }
            if (item.imposer) {
              keys.filter((key) => {
                if (key.startsWith(item.cible) && !nextState[nbRepeat + key].veto) {
                  nextState[nbRepeat + key].imposer = true;
                  nextState[nbRepeat + key].interdire = false;
                }
                return () => {
                };
              });
            }
            if (item.resetInterdire) {
              keys.filter((key) => {
                if (key.startsWith(item.cible)) {
                  nextState[nbRepeat + key].interdire = item.interdire;
                }
                return () => {
                };
              });
            }
          }

          return () => {
          };
        });
      }
      return () => {
      };
    });
    if (currentAnswerCheck.length <= 0) {
      // cas ou on ignore, alors on vérifie s'il n'y a pas des liens interactif dans le cas ignoré
      // (on cherche alors par l'ID de la question et plus par l'ID de la réponse)
      let nbRepeat = '';
      const ids = [id];
      if (id.includes('_')) {
        const answerSplit = id.split('_');
        nbRepeat = `${answerSplit[0]}_`;
        const baseId = answerSplit[1];
        ids.push(baseId);
      }
      ids.forEach((id) => {
        if (id && questionConditionDataTemp[id]) {
          questionConditionDataTemp[id].map((item) => {
            if (item.cible.substr(-1) === '/') {
              const regex = /([A-Za-z0-9).]+?)([0-9]+\/)/gm;
              let questionId = '';
              let reponseId = '';
              let m;
              while ((m = regex.exec(item.cible)) !== null) {
                // This is necessary to avoid infinite loops with zero-width matches
                if (m.index === regex.lastIndex) {
                  regex.lastIndex++;
                }

                // The result can be accessed through the `m`-variable.
                m.forEach((match, groupIndex) => {
                  if (groupIndex === 1) {
                    questionId = match;
                  }
                  if (groupIndex === 2) {
                    reponseId = match;
                  }
                });
              }
              if (questionId.startsWith('D)16)') && reponseId === '4/') {
                const regex2 = /([A-Za-z0-9).]+?)([0-9]+\.)/gm;
                let famillediseasId = '';
                let n;
                while ((n = regex2.exec(item.cible)) !== null) {
                  // This is necessary to avoid infinite loops with zero-width matches
                  if (n.index === regex2.lastIndex) {
                    regex2.lastIndex++;
                  }

                  // The result can be accessed through the `m`-variable.
                  n.forEach((match, groupIndex) => {
                    if (groupIndex === 2) {
                      famillediseasId = match.slice(0, -1);
                    }
                  });
                }
                if (famillediseasId) {
                  diseaseSave.push(famillediseasId);
                }
              } else if (nextState[questionId]) {
                if (item.interdire) {
                  nextState[questionId].answers[item.cible].interdire = true;
                  nextState[questionId].answers[item.cible].imposer = false;
                  nextState[questionId].answers[item.cible].checked = false;
                }
                if (item.imposer) {
                  nextState[questionId].answers[item.cible].imposer = true;
                  nextState[questionId].answers[item.cible].interdire = false;
                  nextState[questionId].answers[item.cible].checked = true;
                  if (nextState[questionId] && !nextState[questionId].multipleAnswer) {
                    nextState[questionId].validate = true;
                    nextState[questionId].interdire = true;
                  }
                }
              }
            } else {
              if (item.veto) {
                keys.filter((key) => {
                  const finalKey = nextState[nbRepeat + key] ? nbRepeat + key : key;
                  if (key.startsWith(item.cible)) {
                    nextState[finalKey].veto = true;
                    nextState[finalKey].interdire = true;
                    nextState[finalKey].imposer = false;
                  }
                  return () => {
                  };
                });
              }
              if (item.poser) {
                keys.filter((key) => {
                  const finalKey = nextState[nbRepeat + key] ? nbRepeat + key : key;
                  if (key.startsWith(item.cible)) {
                    nextState[finalKey].poserMaintenant = true;
                  }
                  return () => {
                  };
                });
              }
              if (item.interdire) {
                keys.filter((key) => {
                  const finalKey = nextState[nbRepeat + key] ? nbRepeat + key : key;
                  if (key.startsWith(item.cible) && !nextState[finalKey].imposer) {
                    nextState[finalKey].interdire = true;
                  }
                  return () => {
                  };
                });
              }
              if (item.imposer) {
                keys.filter((key) => {
                  const finalKey = nextState[nbRepeat + key] ? nbRepeat + key : key;
                  if (key.startsWith(item.cible) && !nextState[finalKey].veto) {
                    nextState[finalKey].imposer = true;
                    nextState[finalKey].interdire = false;
                  }
                  return () => {
                  };
                });
              }
              if (item.resetInterdire) {
                keys.filter((key) => {
                  const finalKey = nextState[nbRepeat + key] ? nbRepeat + key : key;
                  if (key.startsWith(item.cible)) {
                    nextState[finalKey].interdire = item.interdire;
                  }
                  return () => {
                  };
                });
              }
            }

            return () => {
            };
          });
        }
      });
    }
  }
  const showNow = false;

  if (!isLast) {
    nextState[id].validate = true;
  }
  return {
    diseaseSave,
    ...goNextQuestion(
      currentQuestionnaireData,
      nextState,
      showNow,
      newListKey,
      id,
      isInit,
      isLast,
      isVeto,
      isAllCheck,
    ),
  };
};

const goNextQuestion = (
  currentQuestionnaireData: any,
  nextState: any,
  showNow: boolean,
  newListKey: string[],
  id: string,
  isInit?: boolean,
  isLast?:boolean,
  isVeto?: boolean,
  isAllCheck?: boolean,
): {
  finQuestionnaire?: string,
  showIgnore?: boolean,
  valueInputState?: string,
  showInputState?: 'NI' | 'TI' | false,
  currentQuestion?: string,
  currentQuestionnaireData?: any,
  showNext?: boolean,
} => {
  let finQuestionnaire;
  let currentQuestion;
  const baseReturnObj = {
    finQuestionnaire: undefined,
    currentQuestion: undefined,
    showIgnore: undefined,
    valueInputState: undefined,
    showInputState: undefined,
    currentQuestionnaireData: undefined,
    showNext: undefined,
  };
  if (nextState[newListKey[0]] && newListKey.length <= 1) {
    if (!isInit) {
      finQuestionnaire = nextState;
    }
    currentQuestion = newListKey[0];

    let showNextCurrent;
    let showInputState;
    let newInputState = '';
    if (nextState[currentQuestion].answers[currentQuestion] && (nextState[currentQuestion].answers[currentQuestion].answerType === 'NI' || nextState[currentQuestion].answers[currentQuestion].answerType === 'TI')) {
      showInputState = (nextState[currentQuestion].answers[currentQuestion].answerType);
      newInputState = (nextState[currentQuestion].answers[currentQuestion].answerText);
      showNextCurrent = false;
    } else {
      showInputState = false;
      showNextCurrent = true;
    }
    return {
      ...baseReturnObj,
      finQuestionnaire,
      currentQuestion,
      valueInputState: newInputState,
      showInputState,
      showNext: showNextCurrent,
    };
  }
  for (let i = 0; i < newListKey.length; i++) {
    if (nextState[newListKey[i]] && nextState[newListKey[i]].poserMaintenant && !nextState[newListKey[i]].interdire && !nextState[newListKey[i]].hideQuestion) {
      showNow = true;
      newListKey.splice(1, 0, newListKey[i]);
      newListKey.splice(i + 1, 1);
      break;
    }
  }
  if (showNow) {
    let currentQuestionnaireDataTemp = {};
    const keys = Object.keys(currentQuestionnaireData);
    const keyBefore = keys.slice(0, keys.indexOf(id));
    const valueQuestionnaireInit = Object.values(currentQuestionnaireData);
    for (let j = 0; j < keyBefore.length; j++) {
      currentQuestionnaireDataTemp = Object.assign(
        currentQuestionnaireDataTemp, { [keyBefore[j]]: valueQuestionnaireInit[j] },
      );
    }
    for (let k = 0; k < newListKey.length; k++) {
      currentQuestionnaireDataTemp = Object.assign(
        currentQuestionnaireDataTemp, { [newListKey[k]]: nextState[newListKey[k]] },
      );
    }
    nextState = currentQuestionnaireDataTemp;
  }
  if (!isLast) {
    if (!showNow && nextState[newListKey[1]]) {
      if (nextState[newListKey[1]].interdire || (nextState[newListKey[1]].bseOnly && !isVeto && !isAllCheck)) {
        newListKey = newListKey.slice(1);
        // console.log('List key avant while interdire', newListKey);
        while (newListKey[1] && (nextState[newListKey[1]] && (nextState[newListKey[1]].interdire || (nextState[newListKey[1]].bseOnly && !isVeto && !isAllCheck)))) {
          newListKey = newListKey.slice(1);
        }
        if (newListKey.length === 1 && (nextState[newListKey[0]].interdire || (nextState[newListKey[0]].bseOnly && !isVeto && !isAllCheck))) {
          if (!isInit) {
            finQuestionnaire = nextState;
          }
          currentQuestion = newListKey[0];
          return {
            ...baseReturnObj,
            finQuestionnaire,
            currentQuestion,
          };
        }
        // newListKey = newListKey.filter((item) => item !== newListKey[0]);
        return goNextQuestion(
          currentQuestionnaireData,
          nextState,
          showNow,
          newListKey,
          id,
          isInit,
          isLast,
          isVeto,
          isAllCheck,
        );
      }
    }
    if (nextState[newListKey[1]].autopass && !showNow) {
      nextState[newListKey[1]].showQuestion = true;
      const updateListKey = newListKey.slice(1);
      return goNextQuestion(currentQuestionnaireData,
        nextState,
        showNow,
        updateListKey,
        id,
        isInit,
        isLast,
        isVeto,
        isAllCheck);
    }
    nextState[newListKey[1]].showQuestion = true;
  }
  let showNextCurrent;
  let showInputState;
  let newInputState = '';
  if (isLast) {
    // si on arrive à la fin du questionnaire on réaffiche la dernière à laquelle on a répondu
    currentQuestion = id;
    if (nextState[currentQuestion].answers[currentQuestion] && (nextState[currentQuestion].answers[currentQuestion].answerType === 'NI' || nextState[currentQuestion].answers[currentQuestion].answerType === 'TI')) {
      showInputState = (nextState[currentQuestion].answers[currentQuestion].answerType);
      newInputState = (nextState[currentQuestion].answers[currentQuestion].answerText);
      showNextCurrent = false;
    } else {
      showInputState = false;
      showNextCurrent = true;
    }
  }
  let showIgnoreTemp = true;
  if (!isInit) {
    if (nextState[newListKey[1]].answers[newListKey[1]] && (nextState[newListKey[1]].answers[newListKey[1]].answerType === 'NI' || nextState[newListKey[1]].answers[newListKey[1]].answerType === 'TI')) {
      showNextCurrent = false;
      showInputState = (nextState[newListKey[1]].answers[newListKey[1]].answerType);
      if (nextState[newListKey[1]].answers[newListKey[1]].answerText) {
        newInputState = nextState[newListKey[1]].answers[newListKey[1]].answerText;
      }
    } else {
      showInputState = false;
      if (
        nextState[newListKey[1]] && nextState[newListKey[1]].answers
      ) {
        showNextCurrent = false;
        Object.values(nextState[newListKey[1]].answers).forEach((answer) => {
          if (answer.checked || answer.answerType === 'F') {
            showNextCurrent = true;
          }
          if (answer.answerType === 'F' || answer.mandatory) {
            showIgnoreTemp = false;
          }
        });
      }
    }

    return {
      ...baseReturnObj,
      showIgnore: showIgnoreTemp,
      valueInputState: newInputState,
      showInputState,
      currentQuestion: newListKey[1],
      currentQuestionnaireData: nextState,
      showNext: showNextCurrent,
    };
  }
  return {
    ...baseReturnObj,
    valueInputState: newInputState,
    showNext: showNextCurrent,
    currentQuestion,
    showInputState,
  };
};

export default {
  linkToSwitch,
  initQuestionnaire,
  validAnswer,
  goNextQuestion,
};
