import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Text } from '@ui-kitten/components';
import Auth from '@aws-amplify/auth';
import { useUser } from '../../src/API/UserContext';
import MaxWidthContainer from '../../components/MaxWidthContainer';
import Form from '../../components/Form/Form';
import TextInput from '../../components/Form/TextInput';
import { AvailableValidationRules } from '../../components/Form/validation';
import Button from '../../components/Button';
import { removeNull } from '../../utils/ObjectHelper';

type ModifyEmailForm = {
  email: string;
};

const ModifyEmailScreen = () => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const { updateUser, user, cognitoUser } = useUser();

  const onPress = async (data: ModifyEmailForm) => {
    if (user && updateUser && cognitoUser) {
      const { ...otherProps } = data;
      let nextScreen = 'mon-compte/modifier-compte';
      setEmailError(false);

      const newCognitoAttr = removeNull({
        email: otherProps.email,
      });
      if (Object.keys(newCognitoAttr).length > 0) {
        await Auth.updateUserAttributes(cognitoUser, newCognitoAttr);
        const currentAttr = await Auth.currentUserInfo();
        if (!currentAttr.attributes.email_verified) {
          // console.log('verif');
          nextScreen = 'verification';
        }
      }

      // await updateUser(otherProps);
      setLoading(false);
      navigation.navigate(nextScreen);
    }
  };

  const modifyEmailForm = useForm<ModifyEmailForm>();

  return (
    <MaxWidthContainer
      withScrollView="keyboardAware"
      outerViewProps={{
        showsVerticalScrollIndicator: false,
        style: { padding: 20 },
      }}
    >
      <Form<ModifyEmailForm> {...modifyEmailForm} defaultValues={user}>
        <>
          <TextInput
            name="email"
            placeholder="Votre e-mail"
            validators={[
              AvailableValidationRules.email,
            ]}
          />

          <Button
            loading={loading}
            loadingText="Chargement"
            onPress={modifyEmailForm.handleSubmit(
              (data) => {
                setLoading(true);
                onPress(data);
              },
            )}
            style={{ marginTop: 40 }}
          >
            Valider
          </Button>
          {emailError && <Text status="danger">Une erreur à eu lieu lors du changement de mail merci de vérifier votre mot de passe.</Text>}
        </>
      </Form>
    </MaxWidthContainer>
  );
};

export default ModifyEmailScreen;
