import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';

import { AdminVetoParamList } from '../../types';
import AdminDetailsVetoScreen from '../../screens/AdminScreens/VetosScreen/AdminDetailsVetoScreen';
import AdminVetoScreen from '../../screens/AdminScreens/VetosScreen/AdminVetoScreen';

const Stack = createNativeStackNavigator<AdminVetoParamList>();

const AdminVetoStackNavigator = () => (
  <Stack.Navigator
    initialRouteName="veto-mon-cabinet"
    screenOptions={{ headerShown: false }}
  >
    <Stack.Screen
      name="admin-vetos"
      component={AdminVetoScreen}
    />
    <Stack.Screen
      name="admin-details-veto"
      component={AdminDetailsVetoScreen}
    />
  </Stack.Navigator>
);

export default AdminVetoStackNavigator;
