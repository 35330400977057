import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';
import { VetoTabBilanSanitaireParamList } from '../../types';

import VetoBilanSanitaireScreen from '../../screens/VetScreens/BseScreen/VetoBilanSanitaireScreen';
import VetoRealiserBseScreen from '../../screens/VetScreens/BseScreen/VetoRealiserBseScreen';
import VetoAjoutElevageScreen from '../../screens/VetScreens/VetoAjoutElevageScreen';
import VetoDetailsBseScreen from '../../screens/VetScreens/VetoDetailsBseScreen';
import VetoPrebilanScreen from '../../screens/VetScreens/BseScreen/VetoPrebilanScreen';
import PrebilanQuestionnaireScreen
  from '../../screens/BilanSanitaireScreen/PrebilanQuestionnaireScreen';
import VetoChoixPS from '../../screens/VetScreens/BseScreen/VetoChoixPS';

const Stack = createNativeStackNavigator<VetoTabBilanSanitaireParamList>();

export default () => (
  <Stack.Navigator
    initialRouteName="veto-bse"
    screenOptions={{
      headerShown: false,
    }}
  >
    <Stack.Screen
      name="veto-bse"
      component={VetoBilanSanitaireScreen}
    />
    <Stack.Screen
      name="veto-details-bse"
      component={VetoDetailsBseScreen}
    />
    <Stack.Screen
      name="veto-realiser-bse"
      component={VetoRealiserBseScreen}
    />
    <Stack.Screen
      name="veto-ajout-elevage"
      component={VetoAjoutElevageScreen}
    />
    <Stack.Screen
      name="bse-questionnaire"
      component={PrebilanQuestionnaireScreen}
    />
    <Stack.Screen
      name="veto-prebilan"
      component={VetoPrebilanScreen}
    />
    <Stack.Screen
      name="veto-protocol-soins"
      component={VetoChoixPS}
    />

  </Stack.Navigator>
);
