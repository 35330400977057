export default {
  'A)1)b)1.': {
    id: 'A)1)b)1.',
    idt1: 'A)',
    titre1: 'EXAMEN DES ANIMAUX',
    idt2: 'A)1)',
    titre2: 'Caractéristiques de l’animal, de son histoire (antécédents, évolution de la maladie) et des circonstances d’apparition de la maladie dans l’élevage :',
    idt3: 'A)1)b)',
    titre3: 'Les caractéristiques de l’animal ou du lot d’animaux :',
    name: 'Age :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)1)b)1.1/': {
        name: 'De 0 à 2 jours',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)b)1.2/': {
        name: 'De 3 à 10 jours',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)b)1.3/': {
        name: '10 jours à 3 semaines',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)b)1.4/': {
        name: 'De 3 à 6 semaines',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)b)1.5/': {
        name: 'Plus de 6 semaines',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)b)1.6/': {
        name: 'Adulte',
        description: 'Femelle ayant mis bas au moins une fois, ou mâle entier ou castré de plus de 18 mois',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)b)1.7/': {
        name: 'âgé',
        description: 'Plus de 8-10 ans',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)1)b)2.': {
    id: 'A)1)b)2.',
    name: 'Sexe :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)1)b)2.1/': {
        name: 'Mâle',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)b)2.2/': {
        name: 'Femelle',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)b)2.3/': {
        name: 'Castré',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)1)b)3.': {
    id: 'A)1)b)3.',
    name: 'Quel est le stade d’élevage / contexte d’élevage ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)1)b)3.1/': {
        name: 'Veau avant sevrage',
        description: 'Alimentation partiellement ou totalement à base de lait',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)b)3.2/': {
        name: 'Veau de lait',
        description: 'Animal destiné à la production de viande blanche avec alimentation quasi exclusivement constituée de lait',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)b)3.3/': {
        name: 'Jeune bovin de renouvellement',
        description: 'Animal destiné à la reproduction et après sevrage = alimentation totalement sans lait ; si femelle, avant 1ere mise bas',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)b)3.4/': {
        name: 'Jeune bovin à l’engraissement',
        description: 'Animal destiné à la production de viande rouge, après sevrage = alimentation totalement sans lait, et avec une ration d’engraissement',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)b)3.5/': {
        name: 'Adulte',
        description: 'Femelle ayant mis bas au moins une fois, ou mâle entier ou castré de plus de 18 mois',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)1)c)1.': {
    id: 'A)1)c)1.',
    idt3: 'A)1)c)',
    titre3: 'L’histoire de l’apparition de la maladie :',
    name: 'L’animal ou le troupeau ont-ils été vaccinés contre des maladies pouvant présenter des similitudes avec les symptômes observés ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)1)c)1.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)1.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)1)c)10.': {
    id: 'A)1)c)10.',
    name: 'Y a-t-il des antécédents chirurgicaux ou interventions diverses ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)1)c)10.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)10.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)1)c)2.': {
    id: 'A)1)c)2.',
    name: 'Y a-t-il des antécédents chirurgicaux ou interventions diverses ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)1)c)2.1/': {
        name: 'Castration',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)2.2/': {
        name: 'écornage',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)2.3/': {
        name: 'Laparotomie',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)2.4/': {
        name: 'Autres',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)1)c)5.': {
    id: 'A)1)c)5.',
    name: 'Qualifiez la durée d’évolution de la maladie, les modalités d’apparition et d’évolution :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'A)1)c)5.1/': {
        name: 'Aiguë',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)5.2/': {
        name: 'Sub aiguë',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)5.3/': {
        name: 'Sur aiguë',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)5.4/': {
        name: 'Chronique',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)5.8/': {
        name: 'Apparition brutale',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)5.9/': {
        name: 'Apparition progressive',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)5.10/': {
        name: 'évolution intermittente ou récurrente',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)5.11/': {
        name: 'évolution par crises',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'A)1)c)3.': {
    id: 'A)1)c)3.',
    idt3: 'A)1)c)',
    titre3: 'Les données épidémiologiques :',
    idt4: 'A)1)c)3.',
    titre4: 'Quelles sont les circonstances d’apparition des symptômes ?',
    autopass: true,
  },
  'A)1)c)3.r)': {
    id: 'A)1)c)3.r)',
    name: 'Autour du sevrage :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)1)c)3.r)1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: true,
      },
      'A)1)c)3.r)2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)1)c)3.b)': {
    id: 'A)1)c)3.b)',
    name: 'Autour du sevrage :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: false,
    answers: {
      'A)1)c)3.b)1/': {
        name: 'Avant le sevrage',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.b)2/': {
        name: 'Au sevrage',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.b)3/': {
        name: 'Dans les jours ou semaines après le sevrage',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)1)c)3.s)': {
    id: 'A)1)c)3.s)',
    name: 'En cours de gestation ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)1)c)3.s)1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: true,
      },
      'A)1)c)3.s)2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)1)c)3.c)': {
    id: 'A)1)c)3.c)',
    name: 'En cours de gestation :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: false,
    answers: {
      'A)1)c)3.c)1/': {
        name: 'En début de gestation',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.c)2/': {
        name: 'En milieu de gestation',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.c)4/': {
        name: 'En fin de gestation',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)1)c)3.t)': {
    id: 'A)1)c)3.t)',
    name: 'Autour du vêlage :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)1)c)3.t)1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.t)2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)1)c)3.d)': {
    id: 'A)1)c)3.d)',
    name: 'Autour du vêlage :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: false,
    answers: {
      'A)1)c)3.d)1/': {
        name: 'Pendant le vêlage',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.d)2/': {
        name: 'Dans les minutes / heures après le vêlage',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.d)3/': {
        name: 'Le jour ou le lendemain du vêlage',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.d)4/': {
        name: 'Dans les trois semaines qui suivent le vêlage',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.d)5/': {
        name: 'Plus de trois semaines après le vêlage',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)1)c)3.u)': {
    id: 'A)1)c)3.u)',
    name: 'Par rapport au rang (numéro) et au stade de lactation ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)1)c)3.u)1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.u)2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)1)c)3.e)': {
    id: 'A)1)c)3.e)',
    name: 'Par rapport au stade de lactation :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)1)c)3.e)1/': {
        name: 'Au cours des 10 premières semaines',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.e)2/': {
        name: 'Au pic',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.e)3/': {
        name: 'En milieu et fin de lactation',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.e)5/': {
        name: 'En période sèche',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.e)6/': {
        name: 'Dans les 8 -10 jours après l’arrêt de la traite ou de la tétée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)1)c)3.k)': {
    id: 'A)1)c)3.k)',
    name: 'Par rapport au rang de lactation :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)1)c)3.k)7/': {
        name: 'L1',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.k)8/': {
        name: 'L2',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.k)9/': {
        name: 'L3 et plus',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)1)c)3.v)': {
    id: 'A)1)c)3.v)',
    name: 'Par rapport à la saisonnalité ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)1)c)3.v)1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: true,
      },
      'A)1)c)3.v)2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)1)c)3.f)': {
    id: 'A)1)c)3.f)',
    name: 'Saisonnalité :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)1)c)3.f)1/': {
        name: 'Printemps',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.f)2/': {
        name: 'été',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.f)3/': {
        name: 'Automne',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.f)4/': {
        name: 'Hiver',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)1)c)3.w)': {
    id: 'A)1)c)3.w)',
    name: 'Est-ce suite à un changement d’alimentation ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)1)c)3.w)1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.w)2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)1)c)3.g)': {
    id: 'A)1)c)3.g)',
    name: 'Est-ce suite à un changement d’alimentation ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)1)c)3.g)1/': {
        name: 'En début d’engraissement',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.g)2/': {
        name: 'En cours d’engraissement',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.g)10/': {
        name: 'En fin d’engraissement',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.g)3/': {
        name: 'à la mise à l’herbe',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.g)4/': {
        name: 'Après le début d’hivernage en bâtiment',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.g)5/': {
        name: 'Dans les 3 semaines après un changement de pâture',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.g)6/': {
        name: 'Dans les jours après un changement de ration',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.g)11/': {
        name: 'Dans les jours qui suivent l’apport d’un nouvel aliment dans la ration',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.g)7/': {
        name: 'à la fin des silos',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.g)8/': {
        name: 'Dans les 2 à 3 semaines après mise à disposition d’un aliment mal conservé',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.g)9/': {
        name: 'Dans les 3 à 4 semaines après l’ouverture d’un silo souillé',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)1)c)3.x)': {
    id: 'A)1)c)3.x)',
    name: 'Est-ce lié à un facteur extérieur ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)1)c)3.x)1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.x)2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)1)c)3.h)': {
    id: 'A)1)c)3.h)',
    name: 'Est-ce liée à un facteur extérieur :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)1)c)3.h)1/': {
        name: 'Une ingestion suspecte',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.h)2/': {
        name: 'Une introduction, un contact suspect',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.h)3/': {
        name: 'Un phénomène toxique ou infectieux',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.h)4/': {
        name: 'Après un traitement médicamenteux, une injection',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.h)5/': {
        name: 'Un traumatisme',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.h)6/': {
        name: 'Un stress',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.h)7/': {
        name: 'Orage dans les heures précédant la découverte du cadavre',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)1)c)3.y)': {
    id: 'A)1)c)3.y)',
    name: 'Par rapport aux conditions climatiques ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)1)c)3.y)1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.y)2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)1)c)3.i)': {
    id: 'A)1)c)3.i)',
    name: 'Par rapport aux conditions climatiques :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)1)c)3.i)1/': {
        name: 'Pluie',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.i)2/': {
        name: 'Humidité',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.i)3/': {
        name: 'Sécheresse',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.i)4/': {
        name: 'Chaleur',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.i)5/': {
        name: 'Froid',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)1)c)3.z)': {
    id: 'A)1)c)3.z)',
    name: 'L’apparition des symptômes est-elle totalement aléatoire ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)1)c)3.z)1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)3.z)2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)1)c)3.j)': {
    id: 'A)1)c)3.j)',
    name: 'L’apparition des symptômes est-elle totalement aléatoire ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: false,
    answers: {
      'A)1)c)3.j)1/': {
        name: 'Non liée à une période de vie précise',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)1)c)4.': {
    id: 'A)1)c)4.',
    name: 'Où l’animal vit-il ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'A)1)c)4.1/': {
        name: 'Extérieur',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)4.2/': {
        name: 'Intérieur',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)4.3/': {
        name: 'Au cours de la journée de 24h, une partie du temps en bâtiment et l’autre partie à l’extérieur',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)1)c)7.a)': {
    id: 'A)1)c)7.a)',
    idt4: 'A)1)c)7.',
    titre4: 'Quel est le nombre d’animaux malades avec des symptômes identiques ou proches ?',
    name: 'Sur une période de 5 à 7 jours :',
    description: '',
    image: '',
    interdire: false,
    veto: false,
    imposer: false,
    checked: false,
    showNextQuestion: false,
    answers: {
      'A)1)c)7.a)1/': {
        name: 'Un seul animal atteint',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)7.a)2/': {
        name: 'Quelques animaux atteints (moins de 5)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)7.a)3/': {
        name: 'Nombreux animaux atteints (plus de 5 )',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)1)c)7.b)': {
    id: 'A)1)c)7.b)',
    name: 'Sur une période de 1 mois :',
    description: '',
    image: '',
    interdire: false,
    veto: false,
    imposer: false,
    checked: false,
    showNextQuestion: false,
    answers: {
      'A)1)c)7.b)1/': {
        name: 'Un seul animal atteint',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)7.b)2/': {
        name: 'Quelques animaux atteints (moins de 5)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)7.b)3/': {
        name: 'Nombreux animaux atteints (plus de 5 )',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)1)c)7.c)': {
    id: 'A)1)c)7.c)',
    name: 'Sur une période de 6 mois :',
    description: '',
    image: '',
    interdire: false,
    veto: false,
    imposer: false,
    checked: false,
    showNextQuestion: false,
    answers: {
      'A)1)c)7.c)1/': {
        name: 'Un seul animal atteint',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)7.c)2/': {
        name: 'Quelques animaux atteints (moins de 5)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)7.c)3/': {
        name: 'Nombreux animaux atteints (plus de 5 )',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)1)c)7.d)': {
    id: 'A)1)c)7.d)',
    name: 'Sur une période d’1 an :',
    description: '',
    image: '',
    interdire: false,
    veto: false,
    imposer: false,
    checked: false,
    showNextQuestion: false,
    answers: {
      'A)1)c)7.d)1/': {
        name: 'Un seul animal atteint',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)7.d)2/': {
        name: 'Quelques animaux atteints (moins de 5)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)7.d)3/': {
        name: 'Nombreux animaux atteints (plus de 5 )',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)1)c)8.a)': {
    id: 'A)1)c)8.a)',
    idt4: 'A)1)c)8.',
    titre4: 'Quel est le nombre d’animaux morts avec des symptômes identiques ou proches ?',
    name: 'Sur une période de 5 à 7 jours :',
    description: '',
    image: '',
    interdire: false,
    veto: false,
    imposer: false,
    checked: false,
    showNextQuestion: false,
    answers: {
      'A)1)c)8.a)1/': {
        name: 'Un seul animal mort',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)8.a)2/': {
        name: 'Quelques animaux morts (moins de 5)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)8.a)3/': {
        name: 'Nombreux animaux morts (plus de 5 )',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)1)c)8.b)': {
    id: 'A)1)c)8.b)',
    name: 'Sur une période de 1 mois :',
    description: '',
    image: '',
    interdire: false,
    veto: false,
    imposer: false,
    checked: false,
    showNextQuestion: false,
    answers: {
      'A)1)c)8.b)1/': {
        name: 'Un seul animal mort',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)8.b)2/': {
        name: 'Quelques animaux morts (moins de 5)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)8.b)3/': {
        name: 'Nombreux animaux morts (plus de 5 )',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)1)c)8.c)': {
    id: 'A)1)c)8.c)',
    name: 'Sur une période de 6 mois :',
    description: '',
    image: '',
    interdire: false,
    veto: false,
    imposer: false,
    checked: false,
    showNextQuestion: false,
    answers: {
      'A)1)c)8.c)1/': {
        name: 'Un seul animal mort',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)8.c)2/': {
        name: 'Quelques animaux morts (moins de 5)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)8.c)3/': {
        name: 'Nombreux animaux morts (plus de 5 )',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)1)c)8.d)': {
    id: 'A)1)c)8.d)',
    name: 'Sur une période d’1 an :',
    description: '',
    image: '',
    interdire: false,
    veto: false,
    imposer: false,
    checked: false,
    showNextQuestion: false,
    answers: {
      'A)1)c)8.d)1/': {
        name: 'Un seul animal mort',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)8.d)2/': {
        name: 'Quelques animaux morts (moins de 5)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)8.d)3/': {
        name: 'Nombreux animaux morts (plus de 5 )',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)1)c)11.': {
    id: 'A)1)c)11.',
    name: 'Une autopsie ou des prélèvements ont-ils déjà été effectués et si oui de quel(s) type(s) de prélèvement(s) s\'agit il ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)1)c)11.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)11.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)1)c)9.': {
    id: 'A)1)c)9.',
    name: ' Une autopsie ou des prélèvements ont-ils déjà été effectués et si oui de quel(s) type(s) de prélèvement(s) s\'agit il ?:',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)1)c)9.1/': {
        name: 'Aphtes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.2/': {
        name: 'Avorton',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.2bis/': {
        name: 'Placenta',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.2ter/': {
        name: 'écouvillon vaginal après avortement',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.3/': {
        name: 'Bouses du nouveau né',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.4/': {
        name: 'Bouses de jeune bovin ou d\'adulte',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.5/': {
        name: 'Caillette',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.6/': {
        name: 'Cœur',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.7/': {
        name: 'Contenu du rumen',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.8/': {
        name: 'Diaphragme',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.10/': {
        name: 'Encéphale',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.11/': {
        name: 'Foie',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.12/': {
        name: 'Ganglion',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.13/': {
        name: 'Intestin',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.14/': {
        name: 'Kystes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.15/': {
        name: 'Lait',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.16/': {
        name: 'Langue',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.17/': {
        name: 'Liquide articulaire',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.17bis/': {
        name: 'Pus ombilical',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.18/': {
        name: 'Liquide de lavage du fourreau',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.19/': {
        name: 'Liquide trachéal',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.20/': {
        name: 'Mamelle',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.21/': {
        name: 'Mucus ou liquide utérin',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.22/': {
        name: 'Exsudat ou mucus vulvo-vaginal',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.23/': {
        name: 'Mucus nasal',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.24/': {
        name: 'Muqueuse',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.25/': {
        name: 'Muscle',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.26/': {
        name: 'œil',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.27/': {
        name: 'œsophage',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.28/': {
        name: 'Os',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.29/': {
        name: 'Papules',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.30/': {
        name: 'Peau',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.31/': {
        name: 'Péritoine',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.32/': {
        name: 'Poumon',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.33/': {
        name: 'Rate',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.34/': {
        name: 'Rein',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.35/': {
        name: 'Paroi du rumen',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.36/': {
        name: 'Sang',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.36bis/': {
        name: 'Sang total',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.36ter/': {
        name: 'Sérum',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.36quater/': {
        name: 'Plasma',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.37/': {
        name: 'Sperme',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.38/': {
        name: 'Testicule',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.39/': {
        name: 'Tissus conjonctif',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.40/': {
        name: 'Urine',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.41/': {
        name: 'Utérus',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.42/': {
        name: 'Pus',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.44/': {
        name: 'Muscle de la carcasse',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)1)c)9.47/': {
        name: 'Tumeur',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'A)3)a)': {
    id: 'A)3)a)',
    idt2: 'A)3)',
    titre2: 'Localisation et ciblage des principaux symptômes ou lésions observés :',
    name: 'Localisation des principaux symptômes ou lésions :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'A)3)a)1/': {
        name: 'La peau, les muqueuses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)a)2/': {
        name: 'La tête',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)a)3/': {
        name: 'Le cou, le poitrail, les côtes',
        description: 'En avant du corps, en bas de l’encolure et en avant des membres antérieurs',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)a)4/': {
        name: 'La ligne du dos, les flancs, le ventre, le nombril',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)a)5/': {
        name: 'Le bassin, les cuisses, la région périanale, la queue',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)a)6/': {
        name: 'La vulve, le vagin, le pénis, le scrotum',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)a)7/': {
        name: 'La mamelle',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)a)8/': {
        name: 'Les membres, les pieds',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)a)9/': {
        name: 'Pratiquement tout le corps de l\'animal',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)a)10/': {
        name: 'Localisation indéterminée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'A)3)b)1.': {
    id: 'A)3)b)1.',
    idt3: 'A)3)b)',
    titre3: 'Ciblage des principaux symptômes ou lésions : ',
    name: 'Lésions de la peau, du pelage ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)1.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)1.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)2.': {
    id: 'A)3)b)2.',
    name: 'Lésions d\'une muqueuse ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)2.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)2.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)3.': {
    id: 'A)3)b)3.',
    name: 'Tumeur, cancer, grosseur, verrue ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)3.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)3.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)4.': {
    id: 'A)3)b)4.',
    name: 'Symptômes ou lésions du nombril ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)4.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)4.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)5.': {
    id: 'A)3)b)5.',
    name: 'Symptômes ou lésions des yeux, sécrétions oculaires ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)5.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)5.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)6.': {
    id: 'A)3)b)6.',
    name: 'Symptômes ou lésions du mufle (nez), sécrétions nasales ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)6.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)6.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)7.': {
    id: 'A)3)b)7.',
    name: 'Symptômes respiratoires, toux, essoufflement ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)7.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)7.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)8.': {
    id: 'A)3)b)8.',
    name: 'Symptômes ou lésions de la bouche, de la langue, des dents ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)8.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)8.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)9.': {
    id: 'A)3)b)9.',
    name: 'Salivation ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)9.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)9.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)10.': {
    id: 'A)3)b)10.',
    name: 'Diminution de l\'appétit ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)10.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)10.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)11.': {
    id: 'A)3)b)11.',
    name: 'Diminution de l\'abreuvement ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)11.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)11.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)12.': {
    id: 'A)3)b)12.',
    name: 'Diminution de la rumination ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)12.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)12.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)13.': {
    id: 'A)3)b)13.',
    name: 'Ballonnement ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)13.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)13.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)14.': {
    id: 'A)3)b)14.',
    name: 'Colique ?',
    description: 'Selon la gravité de la douleur, le plus souvent trépigne, piétine, se couche et se lève sans arrêt, lève et bouge la queue sans cesse, et dans les cas extrêmes se couche et sur le côté « pédale » puis se relève et recommence',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)14.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)14.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)15.': {
    id: 'A)3)b)15.',
    name: 'Diarrhée ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)15.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)15.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)16.': {
    id: 'A)3)b)16.',
    name: 'Constipation ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)16.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)16.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)17.': {
    id: 'A)3)b)17.',
    name: 'Coloration des bouses ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)17.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)17.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)18.': {
    id: 'A)3)b)18.',
    name: 'Odeur anormale des bouses ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)18.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)18.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)19.': {
    id: 'A)3)b)19.',
    name: 'Présence d’éléments anormaux dans les bouses? ',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)19.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)19.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)20.': {
    id: 'A)3)b)20.',
    name: 'Vache couchée ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)20.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)20.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)21.': {
    id: 'A)3)b)21.',
    name: 'Convulsions, tremblements ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)21.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)21.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)22.': {
    id: 'A)3)b)22.',
    name: 'Paralysie ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)22.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)22.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)23.': {
    id: 'A)3)b)23.',
    name: 'Position anormale ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)23.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)23.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)24.': {
    id: 'A)3)b)24.',
    name: 'Port de la tête anormal ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)24.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)24.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)25.': {
    id: 'A)3)b)25.',
    name: 'Troubles du comportement ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)25.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)25.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)26.': {
    id: 'A)3)b)26.',
    name: 'Suspicion de carences ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)26.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)26.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)27.': {
    id: 'A)3)b)27.',
    name: 'Suspicion de fièvre de lait ?',
    description: 'Vache couchée juste avant ou dans les jours après le vêlage, parfois dans le coma',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)27.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)27.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)28.': {
    id: 'A)3)b)28.',
    name: 'Suspicion de tétanie d\'herbage ?',
    description: 'Le plus souvent au printemps, au pré, avec une herbe jeune',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)28.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)28.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)29.': {
    id: 'A)3)b)29.',
    name: 'Suspicion d’acidose du rumen ? ',
    description: 'Rumen = panse, estomac qui permet la fermentation microbienne des aliments, ration riche en glucides fermentescibles et pauvre en fibres',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)29.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)29.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)30.': {
    id: 'A)3)b)30.',
    name: 'Suspicion d’acétonémie ou cétose ?',
    description: 'Déficit énergétique en début de lactation ou en fin de gestation',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)30.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)30.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)31.': {
    id: 'A)3)b)31.',
    name: 'Suspicion d\'intoxication ?',
    description: 'Plusieurs animaux atteints simultanément ou sur une courte période avec salivation ou diarrhée ou troubles nerveux (tremblements ou convulsions…)',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)31.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)31.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)32.': {
    id: 'A)3)b)32.',
    name: 'Diminution de la production de lait ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)32.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)32.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)33.': {
    id: 'A)3)b)33.',
    name: 'Mammite ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)33.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)33.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)34.': {
    id: 'A)3)b)34.',
    name: 'Augmentation des cellules dans le lait ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)34.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)34.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)35.': {
    id: 'A)3)b)35.',
    name: 'Lésions du trayon ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)35.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)35.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)36.': {
    id: 'A)3)b)36.',
    name: 'Retour en chaleurs ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)36.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)36.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)37.': {
    id: 'A)3)b)37.',
    name: 'Absence de chaleurs ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)37.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)37.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)38.': {
    id: 'A)3)b)38.',
    name: 'Infertilité ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)38.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)38.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)39.': {
    id: 'A)3)b)39.',
    name: 'Avortement ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)39.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)39.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)40.': {
    id: 'A)3)b)40.',
    name: 'Problème autour du vêlage ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)40.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)40.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)41.': {
    id: 'A)3)b)41.',
    name: 'Lésions de la vulve ou du vagin ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)41.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)41.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)42.': {
    id: 'A)3)b)42.',
    name: 'Lésions du pénis ou du scrotum ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)42.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)42.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)43.': {
    id: 'A)3)b)43.',
    name: 'Difficulté à uriner ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)43.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)43.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)44.': {
    id: 'A)3)b)44.',
    name: 'Absence d\'urine ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)44.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)44.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)45.': {
    id: 'A)3)b)45.',
    name: 'Coloration anormale de l\'urine ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)45.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)45.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)46.': {
    id: 'A)3)b)46.',
    name: 'Odeur anormale de l\'urine ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)46.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)46.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)47.': {
    id: 'A)3)b)47.',
    name: 'Animal raide ou bloqué ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)47.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)47.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)48.': {
    id: 'A)3)b)48.',
    name: 'Boiterie ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)48.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)48.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)49.': {
    id: 'A)3)b)49.',
    name: 'Lésions des articulations ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)49.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)49.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)50.': {
    id: 'A)3)b)50.',
    name: 'Lésions des pieds ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)50.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)50.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)51.': {
    id: 'A)3)b)51.',
    name: 'Suspicion de plaie de morsure ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)51.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)51.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)52.': {
    id: 'A)3)b)52.',
    name: 'Suspicion de foudroiement ?',
    description: 'Orage précédant la découverte du cadavre',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)52.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)52.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)53.': {
    id: 'A)3)b)53.',
    name: 'Saignements, hémorragie ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)53.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)53.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)b)54.': {
    id: 'A)3)b)54.',
    name: 'Plaintes ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)b)54.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)3)b)54.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)3)c)': {
    id: 'A)3)c)',
    name: 'Proposer plus de symptômes ou lésions :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)3)c)1/': {
        name: 'Plus de propositions',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'A)2)a)1.': {
    id: 'A)2)a)1.',
    idt2: 'A)2)',
    titre2: 'L’examen général :',
    idt3: 'A)2)a)',
    titre3: 'L’examen à distance :',
    name: 'Observez l’aspect général du troupeau ou du lot, est-il ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)2)a)1.1/': {
        name: 'Homogène',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)a)1.2/': {
        name: 'Hétérogène',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)2)a)16.': {
    id: 'A)2)a)16.',
    name: 'L’animal est-il en retard de croissance ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)2)a)16.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: true,
      },
      'A)2)a)16.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)2)a)2.': {
    id: 'A)2)a)2.',
    name: 'L’animal est-il en retard de croissance ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: false,
    answers: {
      'A)2)a)2.1/': {
        name: 'Mauvaise',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)a)2.2/': {
        name: 'Retard',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)a)2.3/': {
        name: 'Retard en fin de pâturage',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)a)2.4/': {
        name: 'Retard au sevrage',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)2)a)17.': {
    id: 'A)2)a)17.',
    name: 'Observez-vous une anomalie de la démarche ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)2)a)17.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: true,
      },
      'A)2)a)17.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)2)a)3.': {
    id: 'A)2)a)3.',
    name: 'Observez-vous une anomalie de la démarche ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)2)a)3.1/': {
        name: 'Lente',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)a)3.2/': {
        name: 'Rapide',
        description: 'Piétinements',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)a)3.3/': {
        name: 'Boiteries',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)a)3.4/': {
        name: 'Ataxie',
        description: 'Incoordination des mouvements avec conservation de la force musculaire',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)a)3.5/': {
        name: 'Incoordination motrice avec perte de la force musculaire',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)a)3.6/': {
        name: 'En cercle',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)a)3.7/': {
        name: 'Titubante',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)2)a)4.': {
    id: 'A)2)a)4.',
    name: 'Quel est l’état de propreté de l’animal ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)2)a)4.1/': {
        name: 'Peu sale',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)a)4.2/': {
        name: 'Très sale',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)a)4.3/': {
        name: 'Propre',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)2)a)5.': {
    id: 'A)2)a)5.',
    name: 'L’animal transpire-t-il de façon anormale ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)2)a)5.1/': {
        name: 'Abondante',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)2)a)6.': {
    id: 'A)2)a)6.',
    name: 'L’odeur de l’animal est-elle modifiée ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)2)a)6.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)a)6.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)2)a)7.': {
    id: 'A)2)a)7.',
    name: 'Si l’animal se plaint, comment percevez-vous ces plaintes ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)2)a)7.1/': {
        name: 'Faibles',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)a)7.2/': {
        name: 'Fortes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)a)7.3/': {
        name: 'Absence de plainte',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)2)a)8.': {
    id: 'A)2)a)8.',
    name: 'L’animal est-il atteint de symptômes respiratoires ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)2)a)8.1/': {
        name: 'Symptômes respiratoires',
        description: 'Toux, éternuements, écoulements nasaux, essoufflement, mouvements respiratoires accélérés etc.',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)a)8.2/': {
        name: 'Pas de symptômes visibles',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)2)a)9.': {
    id: 'A)2)a)9.',
    name: 'Les bouses et la défécation sont-elles anormales ?',
    description: 'émission des fèces, qualifiées de bouses chez les bovins adultes',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)2)a)9.1/': {
        name: 'Anomalies des bouses ou de la défécation',
        description: 'Trop sèches, absence ou très forte réduction de la quantité de bouses, diarrhée, modification de l’aspect ou de l’odeur des bouses, efforts de défécation sans émission de bouses',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)a)9.2/': {
        name: 'Pas d’anomalies',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)2)a)10.': {
    id: 'A)2)a)10.',
    name: 'L’urine ou la miction sont-elles anormales ?',
    description: 'Miction = émission de l’urine',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)2)a)10.1/': {
        name: 'Anomalies de l’urine ou de la miction',
        description: 'Modification de l’aspect, de l’odeur ou des caractères de l’urine, émission d’urine par petits jets successifs, efforts infructueux pour uriner',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)a)10.2/': {
        name: 'Pas d’anomalie',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)2)a)18.': {
    id: 'A)2)a)18.',
    name: 'La quantité de lait est-elle modifiée ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)2)a)18.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)a)18.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)2)a)11.': {
    id: 'A)2)a)11.',
    name: 'La quantité de lait est-elle modifiée ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: false,
    answers: {
      'A)2)a)11.1/': {
        name: 'Modérément diminuée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)a)11.2/': {
        name: 'Fortement diminuée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)a)11.3/': {
        name: 'Tarie',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)2)a)19.': {
    id: 'A)2)a)19.',
    name: 'L’ingestion d’aliment est-elle modifiée ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)2)a)19.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)a)19.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)2)a)12.': {
    id: 'A)2)a)12.',
    name: 'L’ingestion d’aliment est-elle modifiée ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)2)a)12.1/': {
        name: 'Augmentée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)a)12.2/': {
        name: 'Diminuée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)a)12.3/': {
        name: 'Anorexie',
        description: 'Ne mange plus',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)a)12.4/': {
        name: 'Des aliments tombent de la bouche ou restent dans la bouche et ne sont pas avalés',
        description: 'Régurgitation = suit la prise alimentaire ou la rumination ; vomissements = en dehors de la prise alimentaire ou de la rumination',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)2)a)20.': {
    id: 'A)2)a)20.',
    name: 'L’abreuvement est-il modifié ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)2)a)20.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)a)20.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)2)a)13.': {
    id: 'A)2)a)13.',
    name: 'L’abreuvement est-il modifié ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: false,
    answers: {
      'A)2)a)13.1/': {
        name: 'Augmentation',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)a)13.2/': {
        name: 'Diminution',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)a)13.3/': {
        name: 'Absence',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)a)13.4/': {
        name: 'Refus de boire',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)2)a)21.': {
    id: 'A)2)a)21.',
    name: 'L’ingestion du colostrum ( =1ere tétée ou 1ere buvée) est-elle retardée /différée ?',
    description: 'Elle doit être réalisée dans les 2 à 6 premières heures de la vie',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)2)a)21.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: true,
      },
      'A)2)a)21.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)2)a)14.': {
    id: 'A)2)a)14.',
    name: 'Estimez-vous que le colostrum ingéré a été suffisant (quantité et qualité) pour assurer un transfert satisfaisant d’immunité de la mère vers le veau ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)2)a)14.1/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)a)14.2/': {
        name: 'Je ne sais pas',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)a)14.4/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)2)a)15.': {
    id: 'A)2)a)15.',
    name: 'Le réflexe de succion est-il diminué ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)2)a)15.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)a)15.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)2)k)': {
    id: 'A)2)k)',
    name: 'L’attitude, la posture et les profils de l’animal sont-ils anormaux ?',
    description: 'En se plaçant successivement sur le côté droit et gauche et dans l’axe de l’animal, il convient d’observer le port de la tête, des oreilles, de l’encolure, la position des membres, de la queue, la ligne du dos et de vérifier les profils du ventre (profils abdominaux). Observez-vous une modification de l’attitude, de la posture des profils abdominaux ?',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)2)k)1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)k)2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)2)b)': {
    id: 'A)2)b)',
    name: 'Observez-vous une modification de l’attitude, de la posture des profils abdominaux ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)2)b)1/': {
        name: 'Tête basse',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)b)2/': {
        name: 'Opisthotonos',
        description: 'Tête portée haute ou presque renversée en arrière',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)b)3/': {
        name: 'Tête déviée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)b)4/': {
        name: 'Rotation de la tête',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)b)5/': {
        name: 'Tête et encolure portées relevées',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)b)6/': {
        name: 'Oreilles basses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)b)7/': {
        name: 'Oreilles dressées',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)b)8/': {
        name: 'Oreilles en arrière',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)b)9/': {
        name: 'Une seule oreille pendante',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)b)10/': {
        name: 'Dos voussé',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)b)11/': {
        name: 'Queue relevée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)b)12/': {
        name: 'L’animal observe son flanc',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)b)13/': {
        name: 'Paroi abdominale tendue',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)b)14/': {
        name: 'Abdomen levretté',
        description: 'Qui a le ventre relevé comme celui d’un lévrier',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)b)15/': {
        name: 'Météorisation',
        description: 'Bombement de la paroi abdominale par des gaz contenus dans l’estomac ou l’intestin',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)b)16/': {
        name: 'Raideur',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)b)17/': {
        name: 'Paralysie rigide',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)b)18/': {
        name: 'Paralysie flasque',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)b)19/': {
        name: 'Parésie',
        description: 'Paralysie partielle ou incomplète, parfois transitoire, d’un ou plusieurs muscles, généralement des membres',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)b)20/': {
        name: 'Paraplégie',
        description: 'Paralysie des deux membres postérieurs',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)b)21/': {
        name: 'écartement des membres antérieurs',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)b)22/': {
        name: 'Cou en extension horizontale et appui sur les antérieurs',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)b)23/': {
        name: 'Membres raides, légèrement écartés du corps, bien campés',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)b)24/': {
        name: 'Couché latéral',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)b)25/': {
        name: 'Couché sur le ventre',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)2)l)': {
    id: 'A)2)l)',
    name: 'Le comportement de l’animal est-il modifié ?',
    description: 'Soit sans intervention soit après sollicitation auditive (bruit strident ou claquement des mains), tactile (application d’une pointe de stylo, ou pression forte avec la main ou le genou) ou visuelle (lors de lumière vive), les réactions sont accrues (en hyper) ou amoindries (en hypo) ?',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)2)l)1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)l)2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)2)c)': {
    id: 'A)2)c)',
    name: 'Le comportement de l’animal est-il modifié ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)2)c)1/': {
        name: 'Modification discrète et évolutive du comportement',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)c)2/': {
        name: 'Isolement',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)c)3/': {
        name: 'Anxiété',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)c)4/': {
        name: 'énervement',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)c)5/': {
        name: 'Agressivité',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)c)6/': {
        name: 'Hyperréactivité',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)c)7/': {
        name: 'Automutilation',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)c)8/': {
        name: 'Somnolence',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)c)9/': {
        name: 'Apathie',
        description: 'Diminution de la vigilance ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)c)11/': {
        name: 'Coma',
        description: 'Absence complète de réponse aux différentes sollicitations tactiles auditives',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)c)12/': {
        name: 'Contractions musculaires',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)c)13/': {
        name: 'Convulsions',
        description: 'Contractions involontaires d’un ou plusieurs muscles',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)c)14/': {
        name: 'Tremblements de la peau ou des muscles',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)c)15/': {
        name: 'Mouvements incessants des oreilles',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)c)16/': {
        name: 'Léchage du corps',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)c)23/': {
        name: 'Léchage sans raison de l’environnement',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)c)17/': {
        name: 'Excitation sexuelle sans lien immédiat avec saillie',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)c)18/': {
        name: 'Cécité',
        description: 'Aveugle, diminution de l’acuité visuelle, heurte des obstacles etc.',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)c)19/': {
        name: '«poussée au mur»',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)c)20/': {
        name: 'Perte d’équilibre',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)c)21/': {
        name: 'Pédalage',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)c)22/': {
        name: 'Impossibilité de se lever',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)2)d)': {
    id: 'A)2)d)',
    name: 'Quel est l’état d’engraissement de votre animal ?',
    description: 'L’observation et la palpation de la base de la queue et du flanc permettent une appréciation rapide de l’état corporel. Qu’observez-vous ?',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)2)d)1/': {
        name: 'Maigreur extrême',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)d)2/': {
        name: 'Maigreur modérée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)d)3/': {
        name: 'Maigreur marquée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)d)4/': {
        name: 'Gras à très gras',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)d)5/': {
        name: 'état d’engraissement normal par rapport au stade d’élevage et de production',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'A)2)e)': {
    id: 'A)2)e)',
    idt3: 'A)2)e)',
    titre3: 'Evaluer la température corporelle :',
    description: 'Avant de prendre la température corporelle, il faut s’assurer que la colonne de mercure est bien descendue, ou, avec un thermomètre digital, que l’écran indique bien « L°c ».\n'
      + 'L’extrémité du thermomètre doit être appliquée bien au contact de la paroi du rectum et assez profondément, et ne pas être au milieu des matières fécales\n'
      + 'Avec un thermomètre à mercure, celui-ci doit rester dans le rectum pendant 2 minutes.\n'
      + 'Indiquez la température de l’animal en cliquant sur l’une des plages proposées',
    autopass: true,
  },
  'A)2)e)1.': {
    id: 'A)2)e)1.',
    name: 'Animal adulte :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)2)e)1.1/': {
        name: 'Hypothermie(T<37,5°C)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)e)1.2/': {
        name: 'Température normale(38°C<T<39°C)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)e)1.3/': {
        name: 'Hyperthermie modérée(39°C<T<40°C)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)e)1.4/': {
        name: 'Hyperthermie sévère(T>40°C)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)2)e)2.': {
    id: 'A)2)e)2.',
    name: 'Veau :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)2)e)2.1/': {
        name: 'Hypothermie(T<37,5°C)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)e)2.2/': {
        name: 'Température normale(38°C<T<39°5C)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)e)2.3/': {
        name: 'Hyperthermie(T>40°C)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)2)m)': {
    id: 'A)2)m)',
    name: 'Les mouvements respiratoires sont-ils anormaux ?',
    description: 'Les mouvements respiratoires sont évalués en regardant l’animal de ¾ arrière, en observant les mouvements du creux du flanc (abdomen)  et des dernières côtes (thorax). \n Chez un bovin adulte, la fréquence respiratoire normale est de 15 à 35 mouvements par minute, mais peut doubler lors de forte chaleur et de forte humidité ambiantes; tenez en compte dans vos réponses.\n La respiration normale est qualifiée de costo-abdominale avec une durée approximativement égale de l’inspiration et de l’expiration, et une augmentation simultanée du volume du thorax et de l’abdomen.',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)2)m)1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)m)2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)2)f)': {
    id: 'A)2)f)',
    name: 'Les mouvements respiratoires sont-ils anormaux ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: false,
    answers: {
      'A)2)f)1/': {
        name: 'Tachypnée ou polypnée',
        description: 'Augmentation de la fréquence respiratoire',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)f)2/': {
        name: 'Apnée',
        description: 'Arrêt temporaire des mouvements respiratoires',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)f)3/': {
        name: 'Dyspnée',
        description: 'Mouvements costaux et abdominaux non simultanés, ou durée de l’inspiration - expiration  modifiée au cours d’un cycle respiratoire',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)2)n)': {
    id: 'A)2)n)',
    name: 'La couleur des muqueuses est-elle modifiée ?',
    description: 'Les muqueuses oculaire, buccale, vulvaire d’un bovin en bonne santé sont brillantes, humides, de couleur rose pâle.',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)2)n)1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)n)2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)2)g)': {
    id: 'A)2)g)',
    name: 'La couleur des muqueuses :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: false,
    answers: {
      'A)2)g)1/': {
        name: 'Blanches ou pâles',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)g)2/': {
        name: 'Jaunes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)g)3/': {
        name: 'Rouges',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)g)4/': {
        name: 'Bleues violacées',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)2)o)': {
    id: 'A)2)o)',
    name: 'L’animal vous semble-t-il déshydraté ?',
    description: 'L’enfoncement des yeux ou un pli de peau persistant lorsqu’on pince la peau de l’encolure dans la main témoignent d’une déshydratation,  plus ou moins marquée selon l’intensité de ces signes.',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)2)o)1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)o)2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)2)h)': {
    id: 'A)2)h)',
    name: 'L’état de déshydratation et son rythme :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)2)h)1/': {
        name: 'Déshydratation modérée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)h)2/': {
        name: 'Déshydratation sévère',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)h)3/': {
        name: 'Déshydratation lente',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)h)4/': {
        name: 'Déshydratation rapide',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)2)p)': {
    id: 'A)2)p)',
    name: 'Observez-vous des anomalies de la peau et/ou des poils ? ',
    description: 'Les modifications de la peau et des poils peuvent être attribuées à une maladie de la peau (voir examen spécial ci-dessus)  ou à une maladie générale quelle qu’en soit la cause.',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)2)p)1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)p)2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)2)i)': {
    id: 'A)2)i)',
    name: 'La peau et les poils :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)2)i)1/': {
        name: 'Diminution de la sensibilité cutanée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)i)2/': {
        name: 'Parasites visibles de la peau (poux, lentes, mouches, larves de mouches "asticots")',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)i)3/': {
        name: 'Plaies, blessures',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)i)4/': {
        name: 'Poil terne',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)i)5/': {
        name: 'Poil sec, cassant',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)i)6/': {
        name: 'Poil hirsute',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)i)7/': {
        name: 'Poils longs',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)i)8/': {
        name: 'Poils agglutinés suite au léchage',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)i)9/': {
        name: 'Verrues',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)i)10/': {
        name: 'Zones de décoloration',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)i)11/': {
        name: 'Zones de dépilations',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)i)12/': {
        name: 'Lésions diverses ( couleur rouge de la peau, croûtes, squames, vésicules, papules, pus, sang… )',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)2)q)': {
    id: 'A)2)q)',
    name: 'Observez-vous des anomalies physiques ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)2)q)1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: true,
      },
      'A)2)q)2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)2)j)': {
    id: 'A)2)j)',
    name: 'Observez-vous des anomalies physiques ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)2)j)1/': {
        name: 'Anomalie de la bouche',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)j)2/': {
        name: 'Anomalie des naseaux',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)j)3/': {
        name: 'Anomalie des yeux',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)j)4/': {
        name: 'Anomalie de la mamelle ou des trayons',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)j)5/': {
        name: 'Bosses sur le dos',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)j)6/': {
        name: 'Déformation d’un membre',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)j)7/': {
        name: 'Déformation des onglons',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)j)8/': {
        name: 'Fracture',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)j)9/': {
        name: 'Gros nombril',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)j)10/': {
        name: 'Malformation congénitale',
        description: 'Qui existe à la naissance',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)j)11/': {
        name: 'Malformation évolutive',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)j)12/': {
        name: 'Mutilation',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)j)13/': {
        name: 'œdème',
        description: 'Infiltration séreuse de tissus qui se traduit par un gonflement',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)2)j)14/': {
        name: 'Tuméfaction ou tumeur',
        description: 'Augmentation pathologique du volume d’un organe ou d’un tissu due à un processus inflammatoire ou à une infiltration oedémateuse ou gonflement pathologique constitué par une néoformation tissulaire résultant d’une activité anormale des cellules ayant tendance à persister ou à augmenter en nombre',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'A)4)a)': {
    id: 'A)4)a)',
    idt2: 'A)4)',
    titre2: 'L’examen de la peau et des muqueuses :',
    name: 'La localisation des lésions :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'A)4)a)1/': {
        name: 'Uniquement la peau',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)2/': {
        name: 'Uniquement certaines muqueuses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)3/': {
        name: 'La peau et certaines muqueuses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)4/': {
        name: 'La totalité du corps',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)5/': {
        name: 'La tête',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)6/': {
        name: 'Les oreilles',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)7/': {
        name: 'Les yeux',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)8/': {
        name: 'La muqueuse oculaire',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)9/': {
        name: 'Les paupières',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)10/': {
        name: 'Le chanfrein',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)11/': {
        name: 'Les naseaux / mufle',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)12/': {
        name: 'La muqueuse nasale',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)13/': {
        name: 'La bouche',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)14/': {
        name: 'Les lèvres',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)15/': {
        name: 'La muqueuse buccale',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)16/': {
        name: 'La langue',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)17/': {
        name: 'Le palais',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)18/': {
        name: 'Les mâchoires',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)19/': {
        name: 'Les dents',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)20/': {
        name: 'Le garrot',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)21/': {
        name: 'Le cou',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)22/': {
        name: 'Les épaules',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)23/': {
        name: 'Le poitrail',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)24/': {
        name: 'Les flancs',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)25/': {
        name: 'La ligne du dos',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)26/': {
        name: 'Le bassin',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)27/': {
        name: 'La base de la queue',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)28/': {
        name: 'La queue',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)29/': {
        name: 'La croupe',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)30/': {
        name: 'La région ventrale de l’abdomen',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)31/': {
        name: 'Le périnée',
        description: 'Région du corps comprise entre l’anus et les parties génitales',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)32/': {
        name: 'La région autour de l’anus',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)46/': {
        name: 'La vulve (lèvres vulvaires)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)33/': {
        name: 'La muqueuse vulvaire',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)34/': {
        name: 'Les mamelles',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)35/': {
        name: 'Les trayons',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)36/': {
        name: 'Le pénis',
        description: 'Verge',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)37/': {
        name: 'Le scrotum',
        description: 'Enveloppe cutanée des testicules',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)38/': {
        name: 'Le nombril',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)39/': {
        name: 'La face interne des cuisses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)40/': {
        name: 'La face externe des cuisses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)41/': {
        name: 'Le pâturon',
        description: 'Entre boulet et onglon',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)42/': {
        name: 'Les membres dans leur totalité',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)45/': {
        name: 'Les articulations',
        description: 'Carpe = genou, tarse =jarret, boulet antérieur, postérieur',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)43/': {
        name: 'Les pieds',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)a)44/': {
        name: 'La paroi costale',
        description: 'Côtes',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)4)b)': {
    id: 'A)4)b)',
    name: 'L’étendue des lésions :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)4)b)1/': {
        name: 'Diffuse',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)b)2/': {
        name: 'Localisée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)4)c)': {
    id: 'A)4)c)',
    name: 'Le type de lésions :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'A)4)c)1/': {
        name: 'Abcès',
        description: 'Collection de pus dans une partie du corps ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)2/': {
        name: 'Abcès chaud',
        description: 'Abcès avec peau anormalement chaude – abcès en cours de constitution et d’évolution   ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)3/': {
        name: 'Abcès froid',
        description: 'Abcès avec température  normale de la peau -  abcès qui n’évolue plus  ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)4/': {
        name: 'Alopécie',
        description: 'Chute des poils ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)5/': {
        name: 'Aphte',
        description: '= vésicule = collection de liquide translucide de la muqueuse buccale, linguale, qui évolue en se rompant vers un ulcère superficiel ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)6/': {
        name: 'Bouclure',
        description: 'Modification du poil qui est bouclé ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)8/': {
        name: 'Crevasse',
        description: 'Gerçure de la peau ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)9/': {
        name: 'Croûte',
        description: 'Pus desséché  plus ou moins adhérent à la peau sous-jacente',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)10/': {
        name: 'Dépilation',
        description: '= alopécie = absence des poils, localisée ou généralisée ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)11/': {
        name: 'Eczéma',
        description: 'Lésion érythémateuse et suintante ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)12/': {
        name: 'érythème',
        description: 'Rougeur = congestion disparaissant à la pression ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)13/': {
        name: 'Escarre',
        description: 'Partie superficielle de la peau noirâtre car morte = nécrose, dont la chute peut s’effectuer spontanément ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)14/': {
        name: 'Exanthème',
        description: 'Rougeur cutanée plus ou moins vive sans papule ni vésicule',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)15/': {
        name: 'Excoriation',
        description: 'écorchure superficielle de la peau ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)16/': {
        name: 'Exulcération',
        description: 'Ulcération légère et superficielle ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)17/': {
        name: 'Gerçure',
        description: 'Plaie linéaire, fendillement de la peau ou des muqueuses due au froid ou à certaines maladies ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)18/': {
        name: 'Gingivite',
        description: 'Inflammation de la muqueuse des gencives ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)19/': {
        name: 'Hématidrose',
        description: 'Sueur de sang  ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)20/': {
        name: 'Hyperkératose',
        description: 'épaississement  de la couche cornée de la peau ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)21/': {
        name: 'Hyperplasie',
        description: 'Prolifération excessive des cellules d’un tissu, mais non tumorale  ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)22/': {
        name: 'Hypotrichose',
        description: 'Arrêt localisé du développement des poils ou s’étendant à toutes les zones pileuses ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)23/': {
        name: 'Induration',
        description: 'Durcissement et épaississement des tissus ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)25/': {
        name: 'Kyste',
        description: 'Collection de liquide = sac contenant habituellement un liquide translucide  ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)26/': {
        name: 'Nécrose',
        description: 'Mort de cellule ou de  tissu dans un organisme vivant et provoquant une réaction inflammatoire périphérique  ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)27/': {
        name: 'Nodule',
        description: 'Boule inflammatoire autour d’un élément infectieux, parasitaire, ou un corps étranger ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)28/': {
        name: 'œdème',
        description: 'Infiltration d’un tissu par du liquide se traduisant par un gonflement et gardant l’empreinte du doigt ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)29/': {
        name: 'Pachydermie',
        description: 'épaississement très marqué de la peau  ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)30/': {
        name: 'Papule',
        description: 'Petite saillie de quelques mm de diamètre, blanchâtre,  rose ou rouge, sans liquide à l’intérieur ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)31/': {
        name: 'Parakératose',
        description: 'Prolifération des certaines couches de l’épiderme ( couche épineuse  se traduisant par une épaississement de la peau  ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)32/': {
        name: 'Pellicules',
        description: 'Petits fragments secs qui se détachent de l’épiderme ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)33/': {
        name: 'Pétéchie',
        description: 'Foyer hémorragique de 1 à 2 mm de diamètre ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)34/': {
        name: 'Phlyctène',
        description: 'Vésicule sous- ou intra- cutanée ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)35/': {
        name: 'Placards cuirassés',
        description: 'Durcissement et épaississement de plusieurs cm à dizaine de cm  ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)36/': {
        name: 'Placards cuirassés fendillés',
        description: 'Plaques cuirassées qui se craquellent par endroit ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)37/': {
        name: 'Polype',
        description: 'Prolifération  anormale à partir d’une muqueuse ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)38/': {
        name: 'Pustule',
        description: 'Collection = sac contenant du pus ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)39/': {
        name: 'Sclérodermie',
        description: 'Induration sévère de la peau ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)40/': {
        name: 'Squame',
        description: 'Lambeau corné qui se détache de la peau ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)41/': {
        name: 'Stomatite',
        description: 'Inflammation de la muqueuse buccale, souvent d’origine infectieuse ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)42/': {
        name: 'Tuméfaction',
        description: 'Augmentation anormale du volume d’un organe ou d’un tissu dont le mécanisme initiateur est inflammatoire  ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)43/': {
        name: 'Tumeur',
        description: 'Augmentation anormale du volume d’un organe ou d’un tissu, localisée ou généralisée, en relation avec la prolifération incontrôlée de cellules  ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)44/': {
        name: 'Ulcère',
        description: 'Perte de substance de la peau ou d’une muqueuse, sous forme d’une plaie ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)45/': {
        name: 'Verrue',
        description: 'Forme particulière de tumeur à partir de la peau ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)c)46/': {
        name: 'Vésicule',
        description: 'Petite saillie en surface de la peau ou d’une muqueuse, en forme de sac, et contenant un liquide translucide',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)4)d)': {
    id: 'A)4)d)',
    name: 'La taille des lésions :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)4)d)1/': {
        name: 'Petite',
        description: 'Quelques millimètres',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)d)2/': {
        name: 'Moyenne',
        description: '1 à 5 cm',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)d)3/': {
        name: 'Grande',
        description: '> 5 cm',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)4)e)': {
    id: 'A)4)e)',
    name: 'La forme des lésions :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'A)4)e)1/': {
        name: 'Bourgeonnant',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)e)3/': {
        name: 'Circulaire bords diffus',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)e)4/': {
        name: 'Circulaire bords nets',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)e)5/': {
        name: 'En anneau',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)e)6/': {
        name: 'En choux fleur',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)e)7/': {
        name: 'En fer à cheval',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)e)8/': {
        name: 'En grain de riz',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)e)13/': {
        name: 'Lésions satellites',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)e)14/': {
        name: 'Pédiculée',
        description: 'Relatif à un organe ou une tumeur dont la base est un pied réduit par rapport à l’ensemble',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)e)15/': {
        name: 'Punctiforme',
        description: 'En forme de point',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)4)l)': {
    id: 'A)4)l)',
    name: 'L’aspect des lésions :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'A)4)l)2/': {
        name: 'Caséeux',
        description: 'Qui a l’apparence de fromage',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)l)9/': {
        name: 'épaissie',
        description: 'Peau de rhinocéros',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)l)10/': {
        name: 'Fendillée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)l)11/': {
        name: 'Ferme',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)l)12/': {
        name: 'Granuleux',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)l)16/': {
        name: 'Revêtement blanchâtre',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)l)17/': {
        name: 'Suintant',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)4)f)': {
    id: 'A)4)f)',
    name: 'La couleur des lésions :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)4)f)1/': {
        name: 'Blanchâtre',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)f)2/': {
        name: 'Jaunâtre',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)f)3/': {
        name: 'Orangé',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)f)4/': {
        name: 'Noir',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)f)5/': {
        name: 'Rougeâtre',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)f)6/': {
        name: 'Rosé',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)4)g)': {
    id: 'A)4)g)',
    name: 'La sensibilité des lésions :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)4)g)1/': {
        name: 'Indolore',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)g)2/': {
        name: 'Douloureux',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)g)3/': {
        name: 'Très douloureux',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)4)h)': {
    id: 'A)4)h)',
    name: 'Y a-t-il du prurit ?',
    description: 'Démangeaison provoquée par une lésion locale ou une maladie',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)4)h)1/': {
        name: 'Léger',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)h)2/': {
        name: 'Intense',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)h)3/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)4)k)': {
    id: 'A)4)k)',
    name: 'Observez-vous des sécrétions, et si oui, essayez de les identifier :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)4)k)1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)k)2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)4)i)': {
    id: 'A)4)i)',
    name: 'Observez-vous des sécrétions, et si oui, essayez de les identifier :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)4)i)1/': {
        name: 'Exsudat',
        description: 'Liquide organique qui suinte sur une surface enflammée',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)i)2/': {
        name: 'Mucus',
        description: 'Substance visqueuse sécrétée par les muqueuses',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)i)3/': {
        name: 'Pus',
        description: 'Liquide pathologique, plus ou moins visqueux, qui est formé d’un exsudat chargé de leucocytes, de bactéries ainsi que de cellules du tissu atteint',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)i)4/': {
        name: 'Séborrhée',
        description: 'Augmentation de la sécrétion des glandes sébacées donnant une peau grasse',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)i)5/': {
        name: 'Sérosité',
        description: 'Liquide remplissant les cavités des séreuses ou épanchements des oedèmes',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)i)6/': {
        name: 'Suintement',
        description: 'écoulement très lent d’un liquide',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)4)j)': {
    id: 'A)4)j)',
    name: 'Sentez-vous une odeur particulière ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)4)j)1/': {
        name: 'Inodore',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)j)2/': {
        name: 'Désagréable',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)4)j)3/': {
        name: 'Fétide',
        description: 'Odeur forte et répugnante',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'A)5)a)': {
    id: 'A)5)a)',
    idt2: 'A)5)',
    titre2: 'L’examen des ganglions (nœuds lymphatiques) :',
    name: 'Vue d’ensemble :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)5)a)1/': {
        name: 'Hypertrophie généralisée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)5)a)2/': {
        name: 'Rien de suspect',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'A)5)b)1.': {
    id: 'A)5)b)1.',
    idt3: 'A)5)b)',
    titre3: 'Ganglions de la tête :',
    name: 'Observez-vous des modifications du volume des ganglions mandibulaires?',
    description: 'De la taille d’une petite noix (2 cm de diamètre), les ganglions mandibulaires sont situés dans la région de l’auge contre la face interne de mandibule et à l’extrémité caudale de la glande mandibulaire.',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)5)b)1.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)5)b)1.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)5)b)2.': {
    id: 'A)5)b)2.',
    name: 'Observez-vous des modifications du volume des ganglions parotidiens?',
    description: 'De la taille d’une grosse noisette (1 à 1,5 cm de diamètre), les ganglions parotidiens sont situés à la base de l’oreille, légèrement au dessus de l’articulation de la mâchoire.',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)5)b)2.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)5)b)2.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)5)b)3.': {
    id: 'A)5)b)3.',
    name: 'Observez-vous des modifications du volume des ganglions pharyngiens?',
    description: 'Très difficilement palpables à l’état normal, les ganglions pharyngiens sont  adjacents au pharynx, localisés entre la branche montante de la mandibule, sous l’aile de l’atlas ( = 1ere vertèbre cervicale).',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)5)b)3.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)5)b)3.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)5)c)': {
    id: 'A)5)c)',
    name: 'Observez-vous des modifications du volume des ganglions de l’encolure et du thorax (ganglions préscapulaires) ?',
    description: 'En forme de cigare (6-8 cm x 2 cm), les ganglions préscapulaires  sont situés en avant de l’omoplate et au-dessusde l\'articulation de l’épaule.',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)5)c)1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)5)c)2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'A)5)d)1.': {
    id: 'A)5)d)1.',
    idt3: 'A)5)d)',
    titre3: 'Ganglions du flanc et du membre postérieur :',
    name: 'Observez-vous des modifications du volume des ganglions précruraux?',
    description: 'En forme de cigare (6-8 cm x 1- 2 cm), les ganglions précruraux sont situés sur une ligne verticale à partir de la pointe de l’ilium à un travers de main au-dessus du pli du  grasset.',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)5)d)1.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)5)d)1.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)5)d)2.': {
    id: 'A)5)d)2.',
    name: 'Observez-vous des modifications du volume des ganglions rétromammaires (inguinaux chez le mâle) ?',
    description: 'En forme de disque épais (4 à 5 cm de diamètre x 2 3 cm d’épaisseur)les ganglions rétromammaires sont situés de chaque côté du ligament suspenseur des mamelles, au niveau de l\'attache de la mamelle.',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)5)d)2.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)5)d)2.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'A)6)a)6.': {
    id: 'A)6)a)6.',
    idt2: 'A)6)',
    titre2: 'L’examen de la tête :',
    idt3: 'A)6)a)',
    titre3: 'Aspect général :',
    name: 'Y a-t-il une anomalie de la rumination ?',
    description: 'Durée totale d’environ 6 à 8 h par jour et avec environ 60 mastications par bol de rumination',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)6)a)6.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)a)6.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)a)1.': {
    id: 'A)6)a)1.',
    name: 'Y a-t-il une anomalie de la rumination ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: false,
    answers: {
      'A)6)a)1.1/': {
        name: 'Rumination à vide',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)a)1.2/': {
        name: 'Rumination absente',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)a)1.3/': {
        name: 'Rumination diminuée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)a)7.': {
    id: 'A)6)a)7.',
    name: 'La symétrie de la tête est-elle modifiée ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)6)a)7.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)a)7.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)a)2.': {
    id: 'A)6)a)2.',
    name: 'La symétrie de la tête est-elle modifiée ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)6)a)2.1/': {
        name: 'Bombement du front',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)a)2.2/': {
        name: 'Hypertrophie d’une mandibule',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)a)2.3/': {
        name: 'Mandibule pendante',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)a)8.': {
    id: 'A)6)a)8.',
    name: 'La symétrie des structures molles est-elle modifiée ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)6)a)8.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)a)8.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)a)3.': {
    id: 'A)6)a)3.',
    name: 'La symétrie des structures molles est-elle modifiée ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)6)a)3.1/': {
        name: 'Tuméfaction de la mandibule',
        description: 'Joues',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)a)3.3/': {
        name: 'Hémiplégie faciale',
        description: 'Paralysie unilatérale d’une oreille, d’une paupière, d’un naseau',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)a)4.': {
    id: 'A)6)a)4.',
    name: 'Observez-vous le signe de la bouteille ?',
    description: 'œdème de l’auge = espace inter mandibulaire',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)6)a)4.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)a)4.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)a)9.': {
    id: 'A)6)a)9.',
    name: 'Observez-vous des lésions de la tête ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)6)a)9.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)a)9.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)a)5.': {
    id: 'A)6)a)5.',
    name: 'Observez-vous des lésions de la tête ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)6)a)5.1/': {
        name: 'Blessures',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)a)5.2/': {
        name: 'Malformations',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)g)': {
    id: 'A)6)g)',
    name: 'Observez-vous des anomalies des paupières ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)6)g)1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)g)2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)b)': {
    id: 'A)6)b)',
    name: 'Observez-vous des anomalies des paupières ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)6)b)1/': {
        name: 'Clignements',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)b)2/': {
        name: 'Fermées',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)b)3/': {
        name: 'Gonflées',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)b)4/': {
        name: 'Ouvertes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)b)5/': {
        name: 'Prolapsus de la 3ème paupière',
        description: 'Extériorisation de la paupière interne recouvrant l’œil en partie',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'A)6)c)8.': {
    id: 'A)6)c)8.',
    idt3: 'A)6)c)',
    titre3: 'Anomalies des yeux',
    name: 'Observez-vous une anomalie des yeux ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)6)c)8.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)c)8.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)c)1.': {
    id: 'A)6)c)1.',
    name: 'Observez-vous une anomalie des yeux ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)6)c)1.1/': {
        name: 'Enfoncement',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)c)1.2/': {
        name: 'Exophtalmie',
        description: 'Position saillante du globe oculaire dans l’orbite',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)c)1.3/': {
        name: 'Nystagmus',
        description: 'Suite involontaire de mouvements saccadés et rapides des globes oculaires',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)c)1.4/': {
        name: 'Un œil regarde vers le bas, l’autre vers le haut',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)c)1.5/': {
        name: 'Regard fixe et sournois',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)c)1.6/': {
        name: 'Strabisme dorso-médial',
        description: 'Globes oculaires déviés vers le haut et vers la ligne médiane',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)c)9.': {
    id: 'A)6)c)9.',
    name: 'écoulements oculaires, et si oui de quelle nature ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)6)c)9.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)c)9.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)c)2.': {
    id: 'A)6)c)2.',
    name: 'écoulements oculaires, et si oui de quelle nature ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)6)c)2.1/': {
        name: 'Aqueux',
        description: 'Comparable à de l’eau',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)c)2.2/': {
        name: 'Purulent',
        description: 'Présence de pus',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)c)2.3/': {
        name: 'Séreux',
        description: 'Légèrement opaque, blanchâtre',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)c)2.4/': {
        name: 'Bilatéral',
        description: 'Des 2 côtés',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)c)2.5/': {
        name: 'Unilatéral',
        description: 'D’un seul côté',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)c)10.': {
    id: 'A)6)c)10.',
    name: 'Observez-vous des modifications sur l’aspect de la conjonctive ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)6)c)10.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)c)10.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)c)3.': {
    id: 'A)6)c)3.',
    name: 'L’aspect de la conjonctive :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)6)c)3.1/': {
        name: 'Blanche ou à peine rosée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)c)3.2/': {
        name: 'Jaune',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)c)3.3/': {
        name: 'Très rouge',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)c)3.4/': {
        name: 'Violacée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)c)3.5/': {
        name: 'œdème',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)c)3.6/': {
        name: 'Sèche',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)c)3.7/': {
        name: 'Hémorragies punctiformes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)c)11.': {
    id: 'A)6)c)11.',
    name: 'Anomalies de la cornée ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)6)c)11.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)c)11.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)c)4.': {
    id: 'A)6)c)4.',
    name: 'Anomalies de la cornée ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)6)c)4.2/': {
        name: 'Kératite',
        description: 'Inflammation de la cornée « œil blanc »',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)c)4.4/': {
        name: 'Lésions (blessures, ulcères…)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)c)12.': {
    id: 'A)6)c)12.',
    name: 'Les pupilles sont-elles dilatées ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)6)c)12.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)c)12.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)c)5.': {
    id: 'A)6)c)5.',
    name: 'L’aspect des pupilles : observez-vous une dilatation ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: false,
    answers: {
      'A)6)c)5.1/': {
        name: 'Dilatation bilatérale',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)c)5.2/': {
        name: 'Dilatation unilatérale',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)c)6.': {
    id: 'A)6)c)6.',
    name: 'Quel est l’aspect du cristallin ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)6)c)6.1/': {
        name: 'Opacité = cristallin blanc',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)c)6.2/': {
        name: 'Normal',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)c)7.a)': {
    id: 'A)6)c)7.a)',
    idt4: 'A)6)c)7.',
    titre4: 'Tests de vision',
    name: 'Réflexe du clignement à la menace : ',
    description: 'Pointer un doigt très près de l’œil sans le toucher et en veillant à ne pas ébranler l’air près de l’œil :',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)6)c)7.a)1/': {
        name: 'Pas de fermeture réflexe des paupières',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)c)7.a)4/': {
        name: 'Fermeture reflexe des paupières',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)c)7.b)': {
    id: 'A)6)c)7.b)',
    name: 'L’épreuve des obstacles : ',
    description: 'Apprécier la faculté de l’animal à éviter les obstacles dans un environnement étranger',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)6)c)7.b)2/': {
        name: 'Difficulté à éviter les obstacles',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)c)7.b)5/': {
        name: 'Aucune difficulté au passage de l’obstacle',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)c)7.c)': {
    id: 'A)6)c)7.c)',
    name: 'L’épreuve des obstacles en lumière atténuée (crépuscule ou clair de lune )',
    description: 'Permet de déceler une cécité nocturne ',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)6)c)7.c)3/': {
        name: 'Difficulté à éviter les obstacles',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)c)7.c)6/': {
        name: 'Aucune difficulté au passage de l’obstacle',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)h)': {
    id: 'A)6)h)',
    name: 'Anomalies du mufle ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)6)h)1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)h)2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)d)': {
    id: 'A)6)d)',
    name: 'Anomalies du mufle ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)6)d)1/': {
        name: 'Anormalement chaud',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)d)2/': {
        name: 'Anormalement froid',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)d)3/': {
        name: 'Humide',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)d)4/': {
        name: 'Sec',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)d)5/': {
        name: 'érosions',
        description: 'Pertes de substance, localisées, très superficielles',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)d)6/': {
        name: 'Enduit nécro-fibrineux ou croûte',
        description: 'Fausse membrane, constituée d’exsudat inflammatoire et de tissu mort, plus ou moins adhérente',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)d)7/': {
        name: 'Rougeurs',
        description: 'Pour les races à mufle naturellement clair non pigmenté',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)d)9/': {
        name: 'Ulcères',
        description: 'Perte de substance localisées plus ou moins profondes, à fond plus ou moins rouge ou avec enduit croûteux',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)d)10/': {
        name: 'Papule',
        description: 'Surélévation légère, circulaire, de couleur rougeâtre à brunâtre dans les races à muqueuse claire, parfois en cocarde –liséré rouge périphérique',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'A)6)e)8.': {
    id: 'A)6)e)8.',
    idt3: 'A)6)e)',
    titre3: 'Anomalies des narines',
    name: 'Odeur particulière du souffle nasal ?',
    description: 'À contrôler pendant l’expiration',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)6)e)8.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)e)8.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)e)1.': {
    id: 'A)6)e)1.',
    name: 'Odeur particulière du souffle nasal ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: false,
    answers: {
      'A)6)e)1.1/': {
        name: 'Aigre',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)e)1.2/': {
        name: 'Douce et fade',
        description: 'Odeur de pomme reinette',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)e)1.3/': {
        name: 'Piquante',
        description: 'Odeur d’ammoniaque',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)e)1.4/': {
        name: 'Putride',
        description: 'Odeur de pourri',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)e)3.': {
    id: 'A)6)e)3.',
    name: 'Force du souffle nasal ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)6)e)3.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)e)3.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)e)4.': {
    id: 'A)6)e)4.',
    name: 'Les naseaux sont-ils dilatés lors de l’inspiration ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)6)e)4.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)e)4.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)e)9.': {
    id: 'A)6)e)9.',
    name: 'Des écoulements sont-ils observables , et si oui tentez de les qualifier :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)6)e)9.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)e)9.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)e)5.': {
    id: 'A)6)e)5.',
    name: 'écoulements nasaux, si oui tentez de les qualifier :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)6)e)5.1/': {
        name: 'Bilatéral',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)e)5.2/': {
        name: 'Unilatéral',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)e)5.3/': {
        name: 'Séreux',
        description: 'Comparable à de l’eau',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)e)5.4/': {
        name: 'Blanc',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)e)5.5/': {
        name: 'Jaune',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)e)5.6/': {
        name: 'Mucopurulent',
        description: 'Visqueux et totalement opaque / trouble',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)e)5.7/': {
        name: 'Orange',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)e)5.8/': {
        name: 'Rouille, prune',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)e)5.9/': {
        name: 'Muqueux',
        description: 'Légèrement opaque, blanchâtre',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)e)5.10/': {
        name: 'épistaxis bilatérale',
        description: 'Sang qui s’écoule des orifices nasaux',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)e)5.11/': {
        name: 'épistaxis unilatérale',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)e)5.13/': {
        name: 'Présence de caillots sanguins',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)e)5.14/': {
        name: 'écoulement nasal mousseux',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)e)5.16/': {
        name: 'Présence de particules de fourrage',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)e)6.': {
    id: 'A)6)e)6.',
    name: 'Quel est l’aspect de la muqueuse nasale ?',
    description: 'Ecarter l’aile du nez avec l’index pour bien voir l’intérieur de la cavité nasale. Un bon éclairage est nécessaire.',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'A)6)e)6.1/': {
        name: 'Inflammatoire',
        description: 'Rouge',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)e)6.2/': {
        name: 'Croûtes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)e)6.3/': {
        name: 'Nécrose diffuse',
        description: 'Dégénérescence aboutissant à l’arrêt de la vie dans une cellule ou un tissu',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)e)6.4/': {
        name: 'Papules',
        description: 'Petite saillie cutanée, rose ou rouge, sans liquide à l’intérieur',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)e)6.5/': {
        name: 'Pétéchies',
        description: 'Taches hémorragiques en forme de point',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)e)6.6/': {
        name: 'Ulcérations profondes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)e)6.7/': {
        name: 'Ulcères',
        description: 'Perte de substance de la peau sous forme d’une plaie rebelle à toute cicatrisation et souvent suppurante',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)e)10.': {
    id: 'A)6)e)10.',
    name: 'Des bruits anormaux ( = cornage) sont-ils perceptibles lors de la respiration ? si oui tentez de les décrire ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)6)e)10.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)e)10.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)e)7.': {
    id: 'A)6)e)7.',
    name: 'Percevez-vous des bruits, et si oui tentez de les décrire ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: false,
    answers: {
      'A)6)e)7.1/': {
        name: 'Reniflement',
        description: 'Bruit en provenance des cavités nasales',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)e)7.3/': {
        name: 'Plainte',
        description: 'Bruit analogue au « han » du bucheron,  lié à une douleur d’origine abdominale ou thoracique',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)e)7.4/': {
        name: 'Râle ou sifflement',
        description: 'Bruit en provenance du larynx',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)e)7.6/': {
        name: 'Bourdonnement ou grésillement',
        description: 'Bruit en provenance de la trachée',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)e)7.8/': {
        name: 'Ronflement',
        description: 'Bruits en provenance du pharynx',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'A)6)f)1.': {
    id: 'A)6)f)1.',
    idt3: 'A)6)f)',
    titre3: 'Examen détaillé de la bouche',
    name: 'Observez-vous des bâillements ?',
    description: 'La bouche peut être maintenue ouverte en extériorisant la langue fermement maintenue sur le côté à l’aide d’une serviette. ',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)6)f)1.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)1.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)f)12.': {
    id: 'A)6)f)12.',
    name: 'Observez-vous des anomalies de la salive ? Si oui, tentez de les qualifier.',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)6)f)12.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)12.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)f)2.': {
    id: 'A)6)f)2.',
    name: 'Anomalies de la salive ? si oui tentez de qualifier ces anomalies :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)6)f)2.1/': {
        name: 'écume',
        description: 'Salivation mousseuse',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)2.2/': {
        name: 'Ptyalisme',
        description: 'Excès de salive s’écoulant de la bouche, soit consécutive à un excès de production soit à un défaut de déglutition',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)2.3/': {
        name: 'Sécheresse excessive de la bouche',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)f)13.': {
    id: 'A)6)f)13.',
    name: 'Observez-vous des anomalies de la muqueuse buccale ? ',
    description: 'La muqueuse buccale doit être rose pâle et sans lésion apparente.',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)6)f)13.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)13.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)f)4.': {
    id: 'A)6)f)4.',
    name: 'Anomalies de la muqueuse buccale ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)6)f)4.1/': {
        name: 'Hémorragique',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)4.2/': {
        name: 'Jaune',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)4.3/': {
        name: 'Pâle',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)4.4/': {
        name: 'Violacée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)4.6/': {
        name: 'Ulcères',
        description: 'Perte de substance, localisée, plus ou moins profonde, à fond plus ou moins rouge ou parfois  recouvert de fausse membrane',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)4.7/': {
        name: 'Vésicules',
        description: 'Petite saillie de la muqueuse en forme de sac avec contenu translucide',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)4.8/': {
        name: 'Fausse membrane',
        description: 'Enduit nécro-fibrineux ou purulent, plus ou moins adhérent',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)4.9/': {
        name: 'Papules',
        description: 'Surélévation légère, circulaire, de couleur rougeâtre à brunâtre dans les races à muqueuse claire, parfois en cocarde =liséré rouge périphérique',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)f)14.': {
    id: 'A)6)f)14.',
    name: 'Observez-vous des anomalies des gencives ? ',
    description: 'Les gencives doivent être rose pâle et sans lésion apparente.',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)6)f)14.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)14.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)f)5.': {
    id: 'A)6)f)5.',
    name: 'Anomalies des gencives ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)6)f)5.1/': {
        name: 'Hémorragique',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)5.2/': {
        name: 'Jaune',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)5.3/': {
        name: 'Pâle',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)5.4/': {
        name: 'Violacée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)5.6/': {
        name: 'Ulcères',
        description: 'Perte de substance, localisée, plus ou moins profonde, à fond plus ou moins rouge ou parfois  recouvert de fausse membrane',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)5.7/': {
        name: 'Vésicules',
        description: 'Petite saillie de la muqueuse en forme de sac avec contenu translucide',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)5.8/': {
        name: 'Stomatite',
        description: 'Inflammation de la muqueuse buccale, souvent d’origine infectieuse',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)5.9/': {
        name: 'Papules',
        description: 'Surélévation légère, circulaire, de couleur rougeâtre à brunâtre dans les races à muqueuse claire, parfois en cocarde =liséré rouge périphérique',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)f)15.': {
    id: 'A)6)f)15.',
    name: 'Observez-vous des anomalies du palais ?',
    description: 'Le palais doit être rose pâle et sans lésion apparente.',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)6)f)15.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)15.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)f)6.': {
    id: 'A)6)f)6.',
    name: 'Anomalies du palais ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)6)f)6.1/': {
        name: 'Hémorragique',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)6.2/': {
        name: 'Jaune',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)6.3/': {
        name: 'Pâle',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)6.4/': {
        name: 'Violacée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)6.7/': {
        name: 'Ulcères',
        description: 'Perte de substance, localisée, plus ou moins profonde, à fond plus ou moins rouge ou parfois  recouvert de fausse membrane',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)6.8/': {
        name: 'Vésicules',
        description: 'Petite saillie de la muqueuse en forme de sac avec contenu translucide',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)6.9/': {
        name: 'Fente du palais',
        description: '= palatoschisis, observée chez le nouveau-né, gênant la tétée, d’origine génétique ou  infectieuse ou toxique',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)6.10/': {
        name: 'Papules',
        description: 'Surélévation légère, circulaire, de couleur rougeâtre à brunâtre dans les races à muqueuse claire, parfois en cocarde =liséré rouge périphérique',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)f)7.': {
    id: 'A)6)f)7.',
    name: 'Quelle est l’odeur du souffle buccal ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)6)f)7.1/': {
        name: 'Aigre douce et fade',
        description: 'Odeur de pomme reinette',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)7.2/': {
        name: 'Piquante',
        description: 'Odeur d’ammoniaque',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)7.3/': {
        name: 'Putride',
        description: 'Odeur de pourri',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)7.4/': {
        name: 'Pas d’odeur particulière',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)f)8.': {
    id: 'A)6)f)8.',
    name: 'Qu’observez-vous dans la cavité buccale ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)6)f)8.1/': {
        name: 'Présence d’aliments non déglutis ou d’un corps étranger',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)8.3/': {
        name: 'Rien de suspect',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)f)16.': {
    id: 'A)6)f)16.',
    name: 'L’animal émet-il des bruits, et si oui, tentez de les qualifier ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)6)f)16.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)16.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)f)9.': {
    id: 'A)6)f)9.',
    name: 'L’animal émet-il des bruits, et si oui, tentez de les qualifier :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)6)f)9.1/': {
        name: 'éternuements',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)9.2/': {
        name: 'Meuglements rauques',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)9.3/': {
        name: 'Toux forte',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)9.4/': {
        name: 'Toux humide',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)9.5/': {
        name: 'Toux sèche',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)f)17.': {
    id: 'A)6)f)17.',
    name: 'Anomalies des dents ? si oui, tentez de les décrire ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)6)f)17.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)17.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)f)10.': {
    id: 'A)6)f)10.',
    name: 'Anomalies des dents ? si oui, tentez de les décrire ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)6)f)10.1/': {
        name: 'Dent absente',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)10.2/': {
        name: 'Abcès dentaire',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)10.3/': {
        name: 'Dépôt de tartre noire',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)10.4/': {
        name: 'éruption tardive',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)10.5/': {
        name: 'Grincement des dents',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)10.6/': {
        name: 'Usure excessive',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)10.7/': {
        name: 'Usure inégale',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)f)18.': {
    id: 'A)6)f)18.',
    name: 'Observez-vous des anomalies de la langue ? ',
    description: 'La langue ne doit pas présenter de lésion apparente.',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)6)f)18.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)18.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)6)f)11.': {
    id: 'A)6)f)11.',
    name: 'Anomalies de la langue ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)6)f)11.1/': {
        name: 'Lésions de même nature localisées à la langue et à d’autres organes – maladie générale',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)11.2/': {
        name: 'Lésions localisées exclusivement à la langue et la bouche',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)11.3/': {
        name: 'Macroglossie',
        description: 'Grosse langue du nouveau-né  qui l’empêche de fermer complètement la bouche avec la langue qui sort',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)11.4/': {
        name: 'Extériorisation permanente ou intermittente de la langue',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)11.5/': {
        name: 'Dure',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)11.6/': {
        name: 'Flasque, molle',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)11.7/': {
        name: 'Gonflée (œdème)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)11.8/': {
        name: 'Plissée ou atrophiée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)11.9/': {
        name: 'Lésions de grande taille',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)11.10/': {
        name: 'Lésions de petite taille',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)11.11/': {
        name: 'Lésions de taille variable',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)11.12/': {
        name: 'Lésions diffuses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)11.13/': {
        name: 'Lésions multiples',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)11.14/': {
        name: 'Lésion unique',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)11.15/': {
        name: 'Lésions «en carte de géographie»',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)11.16/': {
        name: 'Lésions «en coups d’ongle»',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)11.17/': {
        name: 'Présence de fausses membranes, nauséabondes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)11.18/': {
        name: 'Présence de nodule qui déforme la langue',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)11.19/': {
        name: 'Présence de papules',
        description: 'Petite saillie, rose ou rouge, sans liquide à l’intérieur',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)11.20/': {
        name: 'Présence d’ulcères superficiels',
        description: 'Perte de substance, localisée, à fond plus ou moins rouge ou parfois  recouvert de fausse membrane ',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)11.21/': {
        name: 'Présence d’ulcères profonds',
        description: 'Perte de substance, localisée, à fond plus ou moins rouge ou parfois  recouvert de fausse membrane',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)6)f)11.23/': {
        name: '« chancre »',
        description: 'Foyer souvent unique plus ou moins saillant avec fausse membrane ou pus en surface',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'A)7)a)': {
    id: 'A)7)a)',
    idt2: 'A)7)',
    titre2: 'L’examen du cou = encolure :',
    name: 'Qu’observez-vous ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'A)7)a)1/': {
        name: 'Blessures',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)7)a)2/': {
        name: 'Hypertrophie',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)7)a)3/': {
        name: 'Tuméfaction de la base du cou',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)7)a)4/': {
        name: 'Pas d’anomalie',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)7)b)': {
    id: 'A)7)b)',
    name: 'Anomalies de l’œsophage ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'A)7)b)1/': {
        name: 'Augmentation de volume à la palpation',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)7)b)2/': {
        name: 'Douleur à la palpation',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)7)b)3/': {
        name: 'Efforts de déglutition',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)7)b)4/': {
        name: 'Pas d’anomalie',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)7)c)': {
    id: 'A)7)c)',
    name: 'Palpation de la gouttière jugulaire ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)7)c)1/': {
        name: 'Gonflement',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)7)c)2/': {
        name: 'Pas d’anomalie',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)7)d)': {
    id: 'A)7)d)',
    name: 'Pratiquez maintenant un test de compression veineuse, pour ce faire, comprimez avec le pouce la veine jugulaire à mi-hauteur de l’encolure. Qu’observez-vous ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)7)d)1/': {
        name: 'Le segment situé au dessus se distend, alors que la partie située en dessous diminue de volume',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)7)d)2/': {
        name: 'La distension persiste en dessous du point de compression',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)7)e)': {
    id: 'A)7)e)',
    name: 'Qu’observez-vous au niveau de la gorge ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)7)e)1/': {
        name: 'Tuméfaction',
        description: 'Gonflement et peau tendue',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)7)e)3/': {
        name: 'Pas d’anomalie',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'A)8)c)': {
    id: 'A)8)c)',
    idt2: 'A)8)',
    titre2: 'L’examen du thorax :',
    name: 'Le type de respiration : essayez de le décrire à partir des indications suivantes :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)8)c)1/': {
        name: 'Abdominal',
        description: 'Les mouvements respiratoires sont observés principalement sur les flancs avec contraction de la paroi des flancs en fin d’expiration ; la paroi thoracique semble ne pas bouger',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)8)c)2/': {
        name: 'Thoracique',
        description: 'Les mouvements respiratoires sont observés principalement sur la paroi du thorax ; la paroi des flancs semble ne pas bouger',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)8)c)3/': {
        name: 'Costo-abdominal',
        description: 'Normal = expansion synchrone et de même amplitude du thorax et de des flancs lors d’un cycle respiratoire',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)8)d)': {
    id: 'A)8)d)',
    name: 'Palpez le thorax en appuyant fortement à différents endroits. Qu’observez-vous ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'A)8)d)1/': {
        name: 'Douleur',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)8)d)2/': {
        name: 'Tuméfaction',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)8)d)3/': {
        name: 'Aucune anomalie',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)8)g)': {
    id: 'A)8)g)',
    name: 'Palpez la paroi costale contre le cœur, observez-vous des anomalies ?',
    description: 'Main droite sur le garrot, on place la main gauche à plat entre la pointe du coude gauche et la paroi du thorax de l\'animal, afin de détecter le «choc précordial», qui correspond à la contraction du ventricule gauche.  Normalement  ce choc est légèrement perceptible. Observez-vous une anomalie ?',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)8)g)1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)8)g)2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)8)e)': {
    id: 'A)8)e)',
    name: 'Après palpation de la paroi costale contre le cœur, quel type d’anomalie observez-vous ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'A)8)e)1/': {
        name: 'Choc très perceptible',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)8)e)2/': {
        name: 'Choc non perceptible',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)8)e)7/': {
        name: 'Impression de trémulation lors du choc précordial',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'A)9)f)': {
    id: 'A)9)f)',
    idt2: 'A)9)',
    titre2: 'L’examen des flancs :',
    name: 'Aspect général, observe-vous des anomalies ?',
    description: 'L\'examen se fait de 3/4 arrière ou de 3/4 avant et de façon symétrique',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)9)f)1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: true,
      },
      'A)9)f)2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)9)a)': {
    id: 'A)9)a)',
    name: 'Aspect général :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)9)a)1/': {
        name: 'Abcès en bas du sternum',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)9)a)2/': {
        name: 'Bombement du flanc côté gauche',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)9)a)3/': {
        name: 'Bombement du fuyant du flanc côté droit',
        description: 'Partie arrière supérieure',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)9)a)4/': {
        name: 'Bombement en arrière de la dernière côte à droite',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)9)a)5/': {
        name: 'Creux du flanc côté droit',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)9)a)6/': {
        name: 'Météorisation uniformément répartie',
        description: 'Bombonnement de la paroie abdominale par des gaz contenus dans l’estomac ou l’intestin',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)9)a)7/': {
        name: 'Météorisation plutôt en position déclive',
        description: 'La partie inférieure',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)9)a)8/': {
        name: 'œdème ventral',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)9)b)': {
    id: 'A)9)b)',
    name: 'Force et régularité des contractions :',
    description: '8 à 10 contractions en 10 minutes pendant le repas',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)9)b)1/': {
        name: 'Absence',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)9)b)2/': {
        name: 'Accélération du rythme',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)9)b)3/': {
        name: 'Ralentissement du rythme',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'A)9)c)': {
    id: 'A)9)c)',
    idt3: 'A)9)c)',
    titre3: 'Recherche d’un point douloureux :',
    description: 'Différentes techniques permettent de déceler une douleur, et donc de suspecter entre autres la présence d\'un corps étranger. L\'utilisation successive de ces différentes techniques augmente la précision de l\'examen',
    autopass: true,
  },
  'A)9)c)1.': {
    id: 'A)9)c)1.',
    name: 'épreuve du bâton :',
    description: 'Un solide bâton est placé sous l\'animal, juste en arrière de la pointe du coude. Il faut soulever le bâton lentement, une personne de chaque côté, puis le relacher brusquement, et répéter la manœuvre sur toute la zone allant jusqu\'à l\'ombilic, qu\'observez-vous ?',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)9)c)1.1/': {
        name: 'Plainte',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)9)c)2.': {
    id: 'A)9)c)2.',
    name: 'épreuve du garrot :',
    description: 'En pinçant l\'épine dorsale au niveau du garrot avec les deux mains, normalement l\'animal incurve (se courber de dehors en dedans) son dos, qu\'observez-vous ?',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)9)c)2.1/': {
        name: 'Refus de s’incurver',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)9)c)3.': {
    id: 'A)9)c)3.',
    name: 'épreuve de locomotion :',
    description: 'Faire descendre l\'animal sur un plan incliné pour provoquer l\'appui des viscères sur le réseau, qu\'observez-vous ?',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)9)c)3.1/': {
        name: 'Douleur',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)9)c)4.': {
    id: 'A)9)c)4.',
    name: 'épreuve du coup de poing :',
    description: 'Pratiquer un bon coup de poing sur la dernière côte droite, qu\'observez-vous ?',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)9)c)4.1/': {
        name: 'Douleur',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'A)9)d)': {
    id: 'A)9)d)',
    idt3: 'A)9)d)',
    titre3: 'Palpation – pression du rumen :',
    description: 'Elle doit être réalisée côté gauche, avec la main à plat, l\'extrémité des doigts, le poing ou le genou, qu\'observez-vous ?',
    autopass: true,
  },
  'A)9)d)1.': {
    id: 'A)9)d)1.',
    name: 'Le contenu :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)9)d)1.1/': {
        name: 'Réplétion',
        description: 'état d\'un organe plein',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)9)d)1.2/': {
        name: 'Vacuité',
        description: 'état d\'un organe vide',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)9)d)2.': {
    id: 'A)9)d)2.',
    name: 'Consistance du contenu :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'A)9)d)2.1/': {
        name: 'élastique dorsalement',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)9)d)2.2/': {
        name: 'Fluctuant ventralement',
        description: 'Déplacement alternatif dans la masse d\'un liquide',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)9)d)2.3/': {
        name: 'Pâteux au milieu',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)9)d)3.': {
    id: 'A)9)d)3.',
    name: 'Sensibilité:',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)9)d)3.1/': {
        name: 'Douleur',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)9)e)': {
    id: 'A)9)e)',
    name: 'Palpation des intestins :',
    description: 'Elle s\'effectue de 3/4 arrière. Les intestins occupent les 2/3 postérieurs de la moitié droite de l\'abdomen, l\'intestin grêle se trouve en région ventrale et le gros intestin en région dorsale. Qu\'observez-vous ?',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'A)9)e)1/': {
        name: 'Sensibilité dorsale',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)9)e)2/': {
        name: 'Sensibilité ventrale',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)9)e)3/': {
        name: 'Tension dorsale',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)9)e)4/': {
        name: 'Tension ventrale',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'A)10)a)': {
    id: 'A)10)a)',
    idt2: 'A)10)',
    titre2: 'L\'examen du nombril :',
    name: 'Observez-vous des anomalies ?',
    description: 'Il est normal d’observer dés les premiers jours de vie une infection minimale locale, à savoir une rougeur de l’ombilic et une légère douleur à la palpation. Celle-ci régresse normalement rapidement.',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)10)a)1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: true,
      },
      'A)10)a)2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)10)b)': {
    id: 'A)10)b)',
    name: 'Observez-vous des anomalies ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)10)b)1/': {
        name: 'Douleur',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)10)b)2/': {
        name: 'Chaleur',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)10)b)3/': {
        name: 'écoulement de pus',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)10)b)4/': {
        name: 'écoulement d’urine par l’ombilic',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)10)b)5/': {
        name: 'éventration',
        description: 'Extériorisation des intestins de l’abdomen',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)10)b)6/': {
        name: 'Hématome',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)10)b)7/': {
        name: 'Hémorragie',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)10)b)8/': {
        name: 'Inflammation',
        description: 'Réaction locale de l’organisme à une action irritante caractérisée essentiellement par des phénomènes vasculaires',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)10)b)9/': {
        name: 'Induration',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)10)b)10/': {
        name: 'Masse sphérique externe',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)10)b)11/': {
        name: 'Masse pouvant être repoussée par pression douce dans l’abdomen du veau',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)10)b)12/': {
        name: 'Par palpation, des structures tubulaires',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)10)b)13/': {
        name: 'Structure cylindrique ferme, indolore, froide interne',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'A)11)': {
    id: 'A)11)',
    idt2: 'A)11)',
    titre2: 'L\'examen de la mamelle :',
    description: '',
    autopass: true,
  },
  'A)11)a)': {
    id: 'A)11)a)',
    idt3: 'A)11)a)',
    titre3: 'Inspection :',
    description: 'Observez la mamelle de l’arrière pour inspecter les quartiers postérieurs et de 3/4 avant pour inspecter les quartiers antérieurs. Tant que possible, l’inspection doit se faire avant et après la traite.',
    autopass: true,
  },
  'A)11)a)4.': {
    id: 'A)11)a)4.',
    name: ' Observez-vous des anomalies au niveau de l\'aspect général ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)11)a)4.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: true,
      },
      'A)11)a)4.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)11)a)1.': {
    id: 'A)11)a)1.',
    name: 'Aspect général :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)11)a)1.1/': {
        name: 'Mauvaise attache',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)a)1.2/': {
        name: 'Mauvaise conformation',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)a)1.3/': {
        name: 'Dissymétrie',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)a)1.4/': {
        name: 'Hauteur des trayons au sol diminuée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)a)1.5/': {
        name: 'œdème',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)a)1.6/': {
        name: 'Quartiers surnuméraires',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)a)1.7/': {
        name: 'Trayons surnuméraires',
        description: 'En surnombre',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)a)1.8/': {
        name: 'Trayons de longueur anormale',
        description: '8-10 cm pour une longueur normale',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)a)1.9/': {
        name: 'Trayons cylindriques',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)a)1.10/': {
        name: 'Trayons en poire',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)a)1.11/': {
        name: 'Veine mammaire peu visible',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)11)a)5.': {
    id: 'A)11)a)5.',
    name: 'Observez-vous une anomalie par rapport à la couleur de la peau ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)11)a)5.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: true,
      },
      'A)11)a)5.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)11)a)2.': {
    id: 'A)11)a)2.',
    name: 'Couleur de la peau :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: false,
    answers: {
      'A)11)a)2.1/': {
        name: 'Anémie',
        description: 'Blancheur des muqueuses consécutives à une diminution notable des globules rouges ou en hémoglobine dans le sang',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)a)2.2/': {
        name: 'Congestion',
        description: 'Excès de sang dans les vaisseaux d’un organe ou d’une partie d’organe',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)a)2.3/': {
        name: 'Cyanose',
        description: 'Coloration bleue violacé des muqueuses ou de la peau consécutive à un trouble d’oxygénation général ou local',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)a)2.4/': {
        name: 'Ictère',
        description: 'Coloration jaune de la peau et des muqueuses',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)11)a)6.': {
    id: 'A)11)a)6.',
    name: 'Présence de lésions : observez-vous des anomalies ?',
    description: 'La peau de la mamelle ne doit pas présenter de lésions apparentes, observez-vous des anomalies ?',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)11)a)6.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: true,
      },
      'A)11)a)6.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)11)a)3.': {
    id: 'A)11)a)3.',
    name: 'Présence de lésions :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)11)a)3.1/': {
        name: 'Abcès',
        description: 'Amas de pus dans une partie du corps',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)a)3.2/': {
        name: 'Crevasse',
        description: 'Gerçure de la peau',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)a)3.3/': {
        name: 'éversion de la muqueuse du canal',
        description: 'Renversement du pourtour d’un orifice',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)a)3.4/': {
        name: 'Excoriations',
        description: 'écorchure superficielle de la peau',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)a)3.5/': {
        name: 'Fistule',
        description: 'Voie anormale suivie par un liquide physiologique ou pathologique',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)a)3.6/': {
        name: 'Gangrène',
        description: 'Mortification locale qui aboutit à la nécrose des tissus, avec tendance à l’extension',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)a)3.7/': {
        name: 'Gerçures',
        description: 'Plaie linéaire, fendillement de la peau ou des muqueuses due au froid ou à certains états morbides',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)a)3.8/': {
        name: 'Microhémorragies',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)a)3.9/': {
        name: 'Nécrose',
        description: 'Dégénérescence aboutissant à l’arrêt de la vie dans une cellule ou un tissu',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)a)3.10/': {
        name: 'Papules',
        description: 'Petite saillie cutanée, rose ou rouge, sans liquide à l’intérieur',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)a)3.11/': {
        name: 'Pétéchies',
        description: 'Tache hémorragique à la surface d’un organe due à la rupture d’un micro vaisseau',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)a)3.12/': {
        name: 'Plaies',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)a)3.13/': {
        name: 'Ulcères',
        description: 'Perte de substance de la peau sous forme d’une plaie rebelle à toute cicatrisation et souvent suppurante',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)a)3.14/': {
        name: 'Verrues',
        description: 'Développement anormal et anarchique d’un tissu cellulaire à partir de la peau',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)11)b)': {
    id: 'A)11)b)',
    name: 'Palpation :',
    description: 'A réaliser de préférence après la traite. Palpez les quartiers à deux mains, un par un, du haut vers le bas, en finissant par le trayon. Pour le trayon, le faire rouler entre le pouce et l’index tout en l’étirant avec l’autre main.',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'A)11)b)1/': {
        name: 'Blessures',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)b)2/': {
        name: 'Douleur',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)b)3/': {
        name: 'Sensibilité',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)b)4/': {
        name: 'Indurations',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)b)5/': {
        name: 'Manque d’élasticité',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)b)6/': {
        name: 'Quartier très chaud',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)b)7/': {
        name: 'Quartier très froid',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)b)8/': {
        name: 'Quartier très dur',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)b)9/': {
        name: 'Sur un quartier antérieur',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)b)10/': {
        name: 'Sur les deux quartiers antérieurs',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)b)11/': {
        name: 'Sur un quartier postérieur',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)b)12/': {
        name: 'Sur les deux quartiers postérieurs',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)b)13/': {
        name: 'Sur les deux quartiers droits ou gauches',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)b)14/': {
        name: 'Sur les deux quartiers en diagonale',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)b)15/': {
        name: 'Sur la totalité de la mamelle',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)b)16/': {
        name: 'Uniquement sur les trayons',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)b)17/': {
        name: 'Problème d’imperméabilité',
        description: 'Pour tester la perméabilité du trayon, expulsez quelques jets sur un récipient à fond noir.',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)11)d)': {
    id: 'A)11)d)',
    name: 'Le lait : observez-vous des anomalies ?',
    description: 'Un lait sain est couleur crème, inodore, homogène. Par contre le colostrum peut être plus ou moins jaune à orangé, épais, collant. On examine les premiers jets sur un bol à fond noir.',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)11)d)1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: true,
      },
      'A)11)d)2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)11)c)': {
    id: 'A)11)c)',
    name: 'Le lait :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)11)c)1/': {
        name: 'Arrêt de la production',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)c)2/': {
        name: 'Diminution de la production',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)c)3/': {
        name: 'Dépôt jaunâtre et surnageant incolore',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)c)4/': {
        name: 'Modification de la couleur',
        description: 'Couleur bière',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)c)5/': {
        name: 'Modification de l’odeur',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)c)6/': {
        name: 'Présence de caillots',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)c)7/': {
        name: 'Présence de grumeaux',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)c)8/': {
        name: 'Présence de pus',
        description: 'Liquide pathologique, plus ou moins visqueux, qui est formé d’un exsudat chargé de leucocytes, de bactéries ainsi que de cellules du tissu atteint',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)c)9/': {
        name: 'Présence de pus abondant et nauséabond',
        description: 'Liquide pathologique, plus ou moins visqueux, qui est formé d’un exsudat chargé de leucocytes, de bactéries ainsi que de cellules du tissu atteint',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)11)c)10/': {
        name: 'Présence de sang',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'A)12)': {
    id: 'A)12)',
    idt2: 'A)12)',
    titre2: 'L’examen de l’appareil génital :',
    description: '',
    autopass: true,
  },
  'A)12)A)': {
    id: 'A)12)A)',
    idt3: 'A)12)A)',
    titre3: 'L’appareil génital femelle :',
    description: 'Observez l’animal de l’arrière, puis soulevez la queue pour examiner plus précisément la zone péri anale et la face interne de la queue.',
    autopass: true,
  },
  'A)12)A)c)': {
    id: 'A)12)A)c)',
    name: 'Examen de la zone péri génitale',
    description: 'La zone doit être symétrique et propre. Observez-vous des anomalies ?',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)12)A)c)1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: true,
      },
      'A)12)A)c)2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)12)A)a)': {
    id: 'A)12)A)a)',
    name: 'Examen de la zone péri génitale :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)12)A)a)1/': {
        name: 'Dissymétrie du bassin',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)A)a)2/': {
        name: 'Relâchement des ligaments sacro iliaques',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)A)a)3/': {
        name: 'Caillots',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)A)a)4/': {
        name: 'Glaires',
        description: 'Liquide incolore, filant, sécrété par les muqueuses',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)A)a)5/': {
        name: 'Non délivrance',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)A)a)6/': {
        name: 'Prolapsus',
        description: 'Glissement d’un organe vers le bas ou à l’extérieur des cavités naturelles',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)A)a)7/': {
        name: 'Pus',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)A)a)8/': {
        name: 'Sang',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)12)A)d)': {
    id: 'A)12)A)d)',
    name: 'Examen de la vulve et du vagin : observez-vous des signes suspects ?',
    description: 'La vulve et le vagin ne doivent pas présenter de lésion apparente, de sécrétions ni d\'odeur suspecte. Observez-vous des signes suspects ?',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)12)A)d)1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: true,
      },
      'A)12)A)d)2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)12)A)b)': {
    id: 'A)12)A)b)',
    name: 'Examen de la vulve et du vagin :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)12)A)b)1/': {
        name: 'Abcès',
        description: 'Amas de pus dans une partie du corps',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)A)b)2/': {
        name: 'Crépitements au toucher',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)A)b)3/': {
        name: 'Déchirures',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)A)b)4/': {
        name: 'écoulements hémorragiques',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)A)b)5/': {
        name: 'Fistule recto-vaginale',
        description: 'Voie anormale suivie par un liquide physiologique ou pathologique',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)A)b)6/': {
        name: 'Glaires blanchâtres',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)A)b)7/': {
        name: 'Glaires cassantes et troubles',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)A)b)8/': {
        name: 'Glaires filantes et translucides',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)A)b)9/': {
        name: 'Glaires marrons',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)A)b)10/': {
        name: 'Glaires violettes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)A)b)11/': {
        name: 'Hypertrophie du clitoris',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)A)b)12/': {
        name: 'Muqueuse bosselée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)A)b)13/': {
        name: 'Muqueuse chaude',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)A)b)14/': {
        name: 'Muqueuse froide',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)A)b)15/': {
        name: 'Muqueuse blanche',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)A)b)16/': {
        name: 'Muqueuse hémorragique',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)A)b)17/': {
        name: 'Muqueuse jaune',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)A)b)18/': {
        name: 'Muqueuse violacée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)A)b)19/': {
        name: 'Muqueuse «en papier de verre»',
        description: 'Granulations rouges, en têtes d’épingle, réparties par plages',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)A)b)20/': {
        name: 'Odeur nauséabonde',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)A)b)21/': {
        name: 'Odeur de nécrose',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)A)b)22/': {
        name: 'Oedèmes',
        description: 'Infiltration séreuse de tissus qui se traduit par un gonflement',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)A)b)23/': {
        name: 'Papules',
        description: 'Petite saillie cutanée, rose ou rouge, sans liquide à l’intérieur',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)A)b)24/': {
        name: 'Pneumovagin',
        description: 'Cavité vaginale remplie d’air',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)A)b)25/': {
        name: 'Présence d’un mélange de mucus et de pus',
        description: 'Mucus : substance visqueuse sécrétée par les muqueuses - pus : liquide pathologique, plus ou moins visqueux, qui est formé d’un exsudat chargé de leucocytes, de bactéries ainsi que de cellules du tissu atteint',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)A)b)26/': {
        name: 'Sensibilité accrue',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)A)b)27/': {
        name: 'Taches bleuâtres',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)A)b)28/': {
        name: 'Ulcères',
        description: 'Perte de substance de la peau sous forme d’une plaie rebelle à toute cicatrisation et souvent suppurante',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)A)b)29/': {
        name: 'Urovagin',
        description: 'Accumulation d’urine sur le plancher affaissé du vagin',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'A)12)B)e)': {
    id: 'A)12)B)e)',
    idt3: 'A)12)B)',
    titre3: 'L’appareil génital du mâle :',
    name: 'Les bourses : observez-vous des anomalies ?',
    description: 'Placez vous à l’arrière de l’animal, inspecter puis palper le scrotum et son contenu. ',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)12)B)e)1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: true,
      },
      'A)12)B)e)2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)12)B)a)': {
    id: 'A)12)B)a)',
    name: 'Les bourses :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)12)B)a)1/': {
        name: 'Asymétrie',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)B)a)2/': {
        name: 'Blessures',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)B)a)3/': {
        name: 'Chaleur',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)B)a)4/': {
        name: 'Lésions cutanées',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)B)a)5/': {
        name: 'Limitation du mouvement des différentes couches les unes par rapport aux autres',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)B)a)6/': {
        name: 'Modifications du pelage',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)B)a)7/': {
        name: 'Modification de la couleur',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)B)a)8/': {
        name: 'Néoformations',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)B)a)9/': {
        name: 'Odeur',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)B)a)10/': {
        name: 'œdème',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)12)B)f)': {
    id: 'A)12)B)f)',
    name: 'La circonférence des bourses :',
    description: 'La circonférence est un bon indicateur de la capacité de production des spermatozoïdes. La circonférence scrotale minimale est de 30 cm entre 12 et 24 mois, 33 cm entre 24 et 30 mois, 34 cm au-delà de 30 mois.',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)12)B)f)11/': {
        name: 'Circonférence scrotale augmentée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)B)f)12/': {
        name: 'Circonférence scrotale diminuée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'A)12)B)b)': {
    id: 'A)12)B)b)',
    idt4: 'A)12)B)b)',
    titre4: 'Les testicules :',
    description: 'Le testicule du taureau est ovale, allongé longitudinalement avec une courbure latérale marquée, il est positionné verticalement dans le scrotum. Il doit être ferme et élastique. On examine les deux testicules par inspection puis palpation. On les bloque dans les bourses en les entraînant vers le bas jusqu’à ce que la peau du scrotum soit tendue et sans plis.',
    autopass: true,
  },
  'A)12)B)b)1.': {
    id: 'A)12)B)b)1.',

    name: 'La taille :',
    description: 'La taille dépend de l’âge du taureau ; à maturité sexuelle (2 ans), le testicule doit mesurer environ 9 cm sur 5 cm, à la fin de la croissance (5 ans), il doit mesurer environ 10 à 12 cm sur 6 à 8 cm.',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)12)B)b)1.1/': {
        name: 'Augmentation',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)B)b)1.2/': {
        name: 'Diminution',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)12)B)b)2.': {
    id: 'A)12)B)b)2.',
    name: 'La forme :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)12)B)b)2.1/': {
        name: 'Courbure peu marquée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)B)b)2.2/': {
        name: 'Courbure très marquée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)12)B)b)3.': {
    id: 'A)12)B)b)3.',
    name: 'Le positionnement : observez-vous une anomalie ?',
    description: 'Le corps de l’épididyme a une position médiane et la plus forte courbure du testicule est latérale.',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)12)B)b)3.1/': {
        name: 'Une torsion autour de l’axe longitudinal',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)12)B)b)4.': {
    id: 'A)12)B)b)4.',
    name: 'Le nombre : combien comptez-vous de testicules ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)12)B)b)4.1/': {
        name: 'Pas de testicule',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)B)b)4.2/': {
        name: 'Un seul testicule',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)12)B)b)5.': {
    id: 'A)12)B)b)5.',
    name: 'La consistance :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)12)B)b)5.1/': {
        name: 'Ferme',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)B)b)5.2/': {
        name: 'Mou',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)12)B)b)6.': {
    id: 'A)12)B)b)6.',
    name: 'La sensibilité:',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)12)B)b)6.1/': {
        name: 'Douleur',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)12)B)b)7.': {
    id: 'A)12)B)b)7.',
    name: 'La température :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)12)B)b)7.1/': {
        name: 'Chaud',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)B)b)7.2/': {
        name: 'Très froid',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)12)B)g)': {
    id: 'A)12)B)g)',
    name: 'L\'épididyme et les conduits déférents : observez-vous des anomalies ?',
    description: 'L’inspection et la palpation de l’épididyme sont réalisées en pinçant le scrotum au dessus du pôle supérieur du testicule. On peut ainsi sentir la tête de l’épididyme. La queue de l\'épididyme se palpe plus facilement au pôle inférieur du testicule. Elle mesure de 1 à 5 cm selon l’âge de l’animal. On peut ensuite comparer les deux cordons testiculaires.',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)12)B)g)1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: true,
      },
      'A)12)B)g)2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)12)B)c)': {
    id: 'A)12)B)c)',
    name: 'Observez-vous des modifications sur l’épididyme et les conduits déférents ? ',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)12)B)c)1/': {
        name: 'Augmentation de volume',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)B)c)2/': {
        name: 'Asymétrie',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)B)c)3/': {
        name: 'Chaleur',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)B)c)4/': {
        name: 'Diminution de consistance',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)B)c)5/': {
        name: 'Douleur',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)B)c)6/': {
        name: 'Mobilité difficile',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)12)B)h)': {
    id: 'A)12)B)h)',
    name: 'Le foureau et le pénis : observez-vous des anomalies ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)12)B)h)1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: true,
      },
      'A)12)B)h)2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)12)B)d)': {
    id: 'A)12)B)d)',
    name: 'Observez-vous des modifications sur le fourreau et le pénis ? ',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)12)B)d)1/': {
        name: 'Abcès du fourreau',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)B)d)2/': {
        name: 'Déformation de la verge',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)B)d)3/': {
        name: 'Fourreau lacéré',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)B)d)4/': {
        name: 'Hématomes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)B)d)5/': {
        name: 'Lésions du pénis',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)B)d)6/': {
        name: 'Odeur nauséabonde',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)B)d)7/': {
        name: 'œdème du prépuce',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)B)d)8/': {
        name: 'Phimosis',
        description: 'Impossibilité d’extériorisation du pénis',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)B)d)9/': {
        name: 'Prépuce chaud',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)B)d)10/': {
        name: 'Prépuce douloureux',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)B)d)11/': {
        name: 'Poils humides',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)B)d)12/': {
        name: 'Sécrétion hémorragique',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)B)d)13/': {
        name: 'Sécrétion purulente',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)12)B)d)14/': {
        name: 'Verrues du pénis',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'A)13)c)': {
    id: 'A)13)c)',
    name: 'Observation des mictions : observez-vous des anomalies ?',
    idt2: 'A)13)',
    titre2: 'L’examen de l’appareil urinaire :',
    description: 'La fréquence normale des mictions est de 6 à 8 fois par jour pour 10 à 20 litres d’urine émise.',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)13)c)1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: true,
      },
      'A)13)c)2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)13)a)': {
    id: 'A)13)a)',
    name: 'Observation des mictions :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)13)a)1/': {
        name: 'Anurie',
        description: 'Absence de miction',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)13)a)2/': {
        name: 'Dysurie',
        description: 'Difficultés à uriner',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)13)a)3/': {
        name: 'Manifestations de douleur',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)13)a)4/': {
        name: 'Oligurie',
        description: 'Diminution de la quantité d’urine émise',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)13)a)5/': {
        name: 'Polyurie',
        description: 'Augmentation de la quantité d’urine émise',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)13)a)6/': {
        name: 'Pollakiurie',
        description: 'Augmentation de la fréquence des mictions ou incontinence',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'A)13)b)': {
    id: 'A)13)b)',
    idt3: 'A)13)b)',
    titre3: 'Observation des urines :',
    description: 'A observer lors de miction spontanée.',
    autopass: true,
  },
  'A)13)b)3.': {
    id: 'A)13)b)3.',
    name: 'La couleur est-elle modifiée ?',
    description: 'Les urines sont normalement jaunes, plus ou moins claires.',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)13)b)3.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: true,
      },
      'A)13)b)3.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)13)b)1.': {
    id: 'A)13)b)1.',
    name: 'La couleur :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: false,
    answers: {
      'A)13)b)1.1/': {
        name: 'Clair',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)13)b)1.2/': {
        name: 'Foncé',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)13)b)1.3/': {
        name: 'Marron',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)13)b)1.4/': {
        name: 'Rouge',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)13)b)4.': {
    id: 'A)13)b)4.',
    name: 'L\'odeur des urines est-elle modifiée ?',
    description: 'Elle est normalement peu forte et peu désagréable.',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)13)b)4.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: true,
      },
      'A)13)b)4.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)13)b)2.': {
    id: 'A)13)b)2.',
    name: 'L’odeur :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: false,
    answers: {
      'A)13)b)2.1/': {
        name: 'Désagréable',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)13)b)2.2/': {
        name: 'Très forte',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)13)b)2.3/': {
        name: 'Odeur de pomme reinette',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'A)14)': {
    id: 'A)14)',
    idt2: 'A)14)',
    titre2: 'L’examen de la défécation et des bouses :',
    description: 'Défécation : émission de bouses',
    autopass: true,
  },
  'A)14)c)': {
    id: 'A)14)c)',
    name: 'Observez-vous des anomalies concernant l\'émission de bouses ?',
    description: 'Un bovin adulte en bonne santé émet entre 30 et 50 kg de bouses sur un rythme de 10 à 20 défécations par jour, sans efforts expulsifs et sans douleur.',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)14)c)1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: true,
      },
      'A)14)c)2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)14)a)': {
    id: 'A)14)a)',
    name: 'La défécation',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)14)a)1/': {
        name: 'Absence de matières fécales',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)14)a)2/': {
        name: 'Anus «en trou de serrure»',
        description: 'Anus resserré lors de la défécation',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)14)a)3/': {
        name: 'Diarrhée intermittente',
        description: 'Qui s’arrête et reprend par intervalles, discontinue, irrégulière',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)14)a)4/': {
        name: 'Douleur à la défécation',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)14)a)5/': {
        name: 'Efforts expulsifs',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)14)a)6/': {
        name: 'Forte augmentation des bouses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)14)a)7/': {
        name: 'Forte diminution des bouses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)14)a)8/': {
        name: 'Nette augmentation du rythme d’émission',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)14)a)9/': {
        name: 'Ténesmes',
        description: 'Efforts infructueux et répétés de défécation',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'A)14)b)1.': {
    id: 'A)14)b)1.',
    idt3: 'A)14)b)',
    titre3: 'Les bouses :',
    name: 'La couleur :',
    description: 'La couleur des bouses dépend beaucoup du régime alimentaire, vert foncé en prairie, brun olive en étable, brun jaunâtre avec l’ensilage de mais, …',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)14)b)1.1/': {
        name: 'Modification anormale de la couleur par rapport au régime alimentaire',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)14)b)1.2/': {
        name: 'Blanches',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)14)b)1.3/': {
        name: 'Jaunâtres',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)14)b)1.4/': {
        name: 'Noires',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)14)b)1.5/': {
        name: 'Rouges',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)14)b)1.6/': {
        name: 'Verdâtres',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)14)b)2.': {
    id: 'A)14)b)2.',
    name: 'La consistance :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'A)14)b)2.1/': {
        name: 'Aqueuse',
        description: 'Même nature que l’eau',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)14)b)2.2/': {
        name: 'Collantes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)14)b)2.3/': {
        name: 'Crémeuse',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)14)b)2.4/': {
        name: 'Glaireuses',
        description: 'Présence de glaires, liquides incolores, filants, sécrétés par les muqueuses',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)14)b)2.5/': {
        name: 'Liquide',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)14)b)2.6/': {
        name: 'Semi liquide',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)14)b)2.7/': {
        name: 'Sèche solide',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)14)b)2.8/': {
        name: 'Pâteuse',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)14)b)2.9/': {
        name: 'Plâtreuse',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)14)b)3.': {
    id: 'A)14)b)3.',
    name: 'L’aspect :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'A)14)b)3.1/': {
        name: 'Aspect cidre',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)14)b)3.2/': {
        name: 'Galettes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)14)b)3.3/': {
        name: 'Présence de bulles',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)14)b)4.': {
    id: 'A)14)b)4.',
    name: 'L’odeur :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)14)b)4.1/': {
        name: 'Aigrelette',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)14)b)4.2/': {
        name: 'Nauséabonde',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)14)b)4.3/': {
        name: 'Odeur de beurre rance',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)14)b)6.': {
    id: 'A)14)b)6.',
    name: 'Observez-vous des éléments anormaux dans les bouses ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)14)b)6.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: true,
      },
      'A)14)b)6.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)14)b)5.': {
    id: 'A)14)b)5.',
    name: 'Présence d’éléments anormaux :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)14)b)5.1/': {
        name: 'Caillots',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)14)b)5.2/': {
        name: 'Fausses membranes nécro fibrineuses',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)14)b)5.3/': {
        name: 'Fragments végétaux incomplètement digérés (>2cm)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)14)b)5.4/': {
        name: 'Mucus',
        description: 'Matières fécales glaireuses',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)14)b)5.5/': {
        name: 'Nombreux grains de maïs non digérés',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)14)b)5.6/': {
        name: 'Sang',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'A)15)': {
    id: 'A)15)',
    idt2: 'A)15)',
    titre2: 'L’examen de l’appareil \n locomoteur :',
    description: 'Nécessite un lever du pied, donc une bonne contention et un savoir faire, un parage fonctionnel du pied après nettoyage soigneux',
    autopass: true,
  },
  'A)15)a)5.': {
    id: 'A)15)a)5.',
    idt3: 'A)15)a)',
    titre3: 'Inspection :',
    name: 'Animal couché : observez-vous une position anormale ?',
    description: 'Les bovins se couchent normalement en décubitus (position de l’animal couché) sterno-abdominal avec les membres antérieurs repliés de chaque côté et les postérieurs repliés de chaque côté.',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)15)a)5.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: true,
      },
      'A)15)a)5.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)15)a)1.': {
    id: 'A)15)a)1.',
    name: 'Animal couché:',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: false,
    answers: {
      'A)15)a)1.1/': {
        name: 'Décubitus latéral',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)15)a)1.2/': {
        name: 'Membres antérieurs allongés vers l’avant',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)15)a)1.3/': {
        name: 'Position «en grenouille»',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)15)a)2.': {
    id: 'A)15)a)2.',
    name: 'Animal au relever :',
    description: 'Normalement, un bovin se relève rapidement, le train arrière d\'abord puis le train avant en repliant la tête et l’encolure pour se donner de l\'élan.',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)15)a)2.1/': {
        name: 'à la manière d\'un cheval (antérieurs d\'abord)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)15)a)2.2/': {
        name: 'Avec un long agenouillement sur les faces antérieures des carpes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)15)a)6.': {
    id: 'A)15)a)6.',
    name: 'L\'animal debout : constatez-vous un problème d\'aplombs ?',
    description: 'Observez les aplombs de face et de profil',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)15)a)6.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: true,
      },
      'A)15)a)6.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)15)a)3.': {
    id: 'A)15)a)3.',
    name: 'Animal debout :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: false,
    answers: {
      'A)15)a)3.1/': {
        name: '« sous lui des antérieurs »',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)15)a)3.2/': {
        name: '« sous lui des postérieurs »',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)15)a)3.3/': {
        name: 'Membres antérieurs écartés',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)15)a)3.4/': {
        name: 'Membres postérieurs écartés',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)15)a)4.': {
    id: 'A)15)a)4.',
    idt4: 'A)15)a)4.',
    titre4: 'La démarche :',
    description: 'Faites marcher la vache sur un sol régulier et non glissant, et recherchez les modifications de l’allure ou de la démarche en faisant marcher l’animal en ligne droite, en cercle et en huit.',
    autopass: true,
  },
  'A)15)a)4.a)': {
    id: 'A)15)a)4.a)',
    name: 'Phases d’appui et de suspension :',
    description: 'Observez successivement les phases d\'appui et de suspension, et indiquez si vous notez un racourcissement de l\'une des 2 phases ou des 2',
    image: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)15)a)4.a)1/': {
        name: 'Raccourcissement de la phase d\'appui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)15)a)4.a)2/': {
        name: 'Raccourcissement de la phase de suspension',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)15)a)4.a)3/': {
        name: 'Raccourcissement des 2 phases',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)15)a)4.b)': {
    id: 'A)15)a)4.b)',
    name: 'L\'apparition de la boiterie est-elle ?',
    description: '',
    image: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)15)a)4.b)1/': {
        name: 'Brusque',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)15)a)4.b)2/': {
        name: 'Progressive',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)15)b)': {
    id: 'A)15)b)',
    idt3: 'A)15)b)',
    titre3: 'Examen des onglons :',
    description: '80% des boiteries ont pour origine les onglons. Avant l\'examen, bien nettoyer les onglons en grattant avec le dos d\'une rénette et avec une brosse.',
    autopass: true,
  },
  'A)15)b)1.': {
    id: 'A)15)b)1.',
    idt4: 'A)15)b)1.',
    titre4: 'Inspection :',
    description: 'Un onglon normal fait un angle de 50° environ avec le sol, sa sole est deux fois plus longue que sa muraille et sa corne est lisse et unie.',
    autopass: true,
  },
  'A)15)b)1.c)': {
    id: 'A)15)b)1.c)',
    name: 'Observez-vous des anomalies au niveau des onglons ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)15)b)1.c)1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: true,
      },
      'A)15)b)1.c)2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)15)b)1.a)': {
    id: 'A)15)b)1.a)',
    name: 'Anomalies des onglons :',
    description: '',
    image: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)15)b)1.a)1/': {
        name: 'Cassures de la corne',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)15)b)1.a)2/': {
        name: 'Cerises',
        description: 'Tissus de bourgeonnement tentant de combler l’ulcère de la sole',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)15)b)1.a)3/': {
        name: '«en sabot»',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)15)b)1.a)4/': {
        name: '«en ciseaux»',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)15)b)1.a)5/': {
        name: '«en tire-bouchon»',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)15)b)1.a)6/': {
        name: 'Fissures de la corne',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)15)b)1.a)7/': {
        name: 'Seime',
        description: 'Fissure longitudinale de la muraille',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)15)b)1.a)8/': {
        name: 'Ulcères de la sole',
        description: '« trou » dans la sole à l’endroit « typique » causé par un arrêt localisé de production de corne',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)15)b)1.d)': {
    id: 'A)15)b)1.d)',
    name: 'Observez-vous des anomalies de l\'espace interdigité ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)15)b)1.d)1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: true,
      },
      'A)15)b)1.d)2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)15)b)1.b)': {
    id: 'A)15)b)1.b)',
    name: 'Anomalies de l’espace interdigité :',
    description: '',
    image: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)15)b)1.b)1/': {
        name: 'Dermite',
        description: 'Inflammation de la peau',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)15)b)1.b)2/': {
        name: 'Formation hyperplasique',
        description: 'Prolifération anormale des cellules d’un tissu',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)15)b)1.b)3/': {
        name: 'Nécrose superficielle',
        description: 'Dégénérescence aboutissant à l’arrêt de la vie dans une cellule ou un tissu',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)15)b)1.b)4/': {
        name: 'Nécrose profonde',
        description: 'Dégénérescence aboutissant à l’arrêt de la vie dans une cellule ou un tissu',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)15)b)1.b)5/': {
        name: 'Phlegmon',
        description: 'Infiltration purulente aigue du tissu sous-cutané ou conjonctif',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)15)b)2.': {
    id: 'A)15)b)2.',
    name: 'Palpation – pression :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'A)15)b)2.1/': {
        name: 'Onglons chauds',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)15)b)2.2/': {
        name: 'Onglons sensibles',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)15)c)': {
    id: 'A)15)c)',
    idt3: 'A)15)c)',
    titre3: 'Examen des articulations :',
    description: 'A réaliser lorsque l’affection n’est pas localisée aux onglons, et plus spécialement chez les veaux présentant des affections du nombril, très sujets aux arthrites.',
    autopass: true,
  },
  'A)15)c)3.': {
    id: 'A)15)c)3.',
    name: 'Observez-vous des anomalies à l\'inspection des articulations ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)15)c)3.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: true,
      },
      'A)15)c)3.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)15)c)1.': {
    id: 'A)15)c)1.',
    name: 'Inspection :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)15)c)1.1/': {
        name: 'Blessure avec épanchement de synovie',
        description: 'Accumulation pathologique de la synovie (liquide incolore et visqueux lubrifiant les articulations) dans la cavité articulaire',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)15)c)1.2/': {
        name: 'Déviation de l’axe de l’articulation',
        description: 'Luxation',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)15)c)1.3/': {
        name: 'Rougeur de la peau',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)15)c)1.4/': {
        name: 'Tuméfactions',
        description: 'Augmentation pathologique du volume d’un organe ou d’un tissu due à un processus inflammatoire ou à une infiltration oedémateuse ; partie tuméfiée',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)15)c)4.': {
    id: 'A)15)c)4.',
    name: 'Observez-vous des anomalies à la palpation des articulations ?',
    description: 'Cet examen se réalise avec l’extrémité des doigts.',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)15)c)4.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: true,
      },
      'A)15)c)4.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)15)c)2.': {
    id: 'A)15)c)2.',
    name: 'Palpation :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)15)c)2.1/': {
        name: 'Fluctuation',
        description: 'Déplacement dans la masse d’un liquide',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)15)c)2.2/': {
        name: 'Chaleur',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)15)c)2.3/': {
        name: 'Sensibilité à la palpation',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)15)c)2.4/': {
        name: 'Sensibilité à la mobilisation',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)15)c)2.5/': {
        name: 'Crépitation à la mobilisation',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)15)c)2.6/': {
        name: 'Mobilité diminuée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)15)c)2.7/': {
        name: 'Mobilité impossible',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)15)c)2.8/': {
        name: 'Consistance modifiée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)15)f)': {
    id: 'A)15)f)',
    name: 'Examen des os : observez-vous des anomalies ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)15)f)1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: true,
      },
      'A)15)f)2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)15)d)': {
    id: 'A)15)d)',
    name: 'Examen des os :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)15)d)1/': {
        name: 'Augmentation de volume',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)15)d)2/': {
        name: 'Crépitations',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)15)d)3/': {
        name: 'Discontinuité des membres',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)15)d)4/': {
        name: 'Douleur',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'A)15)e)3.': {
    id: 'A)15)e)3.',
    idt3: 'A)15)e)',
    titre3: 'Examen des muscles et tendons :',
    name: 'Lors de l\'inspection, observez-vous des anomalies ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)15)e)3.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: true,
      },
      'A)15)e)3.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)15)e)1.': {
    id: 'A)15)e)1.',
    name: 'Inspection :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)15)e)1.1/': {
        name: 'Amyotrophie unilatérale',
        description: 'Diminution anormale du volume du muscle',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)15)e)1.2/': {
        name: 'Amyotrophie bilatérale',
        description: 'Diminution anormale du volume du muscle',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)15)e)1.3/': {
        name: 'Gonflement',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)15)e)1.4/': {
        name: 'Malformation',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)15)e)2.': {
    id: 'A)15)e)2.',
    name: 'Palpation :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'A)15)e)2.1/': {
        name: 'Chaleur',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)15)e)2.2/': {
        name: 'Douleur',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'A)16)a)': {
    id: 'A)16)a)',
    idt2: 'A)16)',
    titre2: 'Morsures, foudre, blessures et traumatismes du trayon :',
    name: 'Indiquer le type de lésions auquel se rapproche ce que vous observez sur votre animal :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)16)a)1/': {
        name: 'Morsures',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)16)a)2/': {
        name: 'Lésions consécutives à une fulguration',
        description: 'Coup de foudre',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)16)a)3/': {
        name: 'Blessures ou traumatismes du trayon',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)16)b)': {
    id: 'A)16)b)',
    name: 'Morsures :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'A)16)b)1/': {
        name: 'Mort subite',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)16)b)2/': {
        name: 'Plaies aux contours irréguliers, dilacérés',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)16)b)3/': {
        name: 'Traces de dents, de crocs',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)16)c)': {
    id: 'A)16)c)',
    name: 'Foudre :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'A)16)c)1/': {
        name: 'Mort subite',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)16)c)2/': {
        name: 'Un orage a eu lieu dans les heures précédant la découverte du cadavre',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)16)c)3/': {
        name: 'Cornes du cadavre en terre',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)16)c)4/': {
        name: 'Le cadavre se trouve près d’une haie, d’un arbre…',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)16)c)5/': {
        name: 'Brûlures en formes de «zébrures»',
        description: 'Lésions des tissus provoquées par la chaleur, des produits caustiques, l’électricité ou par des rayonnements',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)16)c)6/': {
        name: 'Lésions des muqueuses (oculaires, rectales, vulvaires)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)16)d)': {
    id: 'A)16)d)',
    idt2: 'A)16)d)',
    titre2: 'Blessures et traumatismes du trayon :',
    description: 'Parmi les différents types de traumatismes proposés dans la liste suivante, choisissez celui ou ceux auxquels se rapproche le plus le type de lésion que vous observez, et essayez de décrire plus précisément la lésion à partir des descriptions proposées',
    autopass: true,
  },
  'A)16)d)9.': {
    id: 'A)16)d)9.',
    name: 'Brûlures solaires ?',
    description: 'Lésions des tissus provoquées par la chaleur, des produits caustiques, l’électricité ou par des rayonnements',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)16)d)9.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: true,
      },
      'A)16)d)9.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)16)d)1.': {
    id: 'A)16)d)1.',
    name: 'Brûlures solaires :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)16)d)1.1/': {
        name: 'Rougeur de la peau de la mamelle et des trayons sur la totalité de leur face externe',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)16)d)1.2/': {
        name: 'Présence de lésions identiques sur le mufle',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)16)d)1.3/': {
        name: 'Douleur au toucher',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)16)d)1.4/': {
        name: 'Dessèchement de la peau',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)16)d)1.5/': {
        name: 'Desquamation',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)16)d)1.6/': {
        name: 'Ulcères superficiels',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)16)d)1.7/': {
        name: 'Absence de lésions sur les même zones abritées du soleil',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)16)d)10.': {
    id: 'A)16)d)10.',
    name: 'Crevasses et gerçures ?',
    description: 'Crevasses (gerçures de la peau) et gerçures (plaie linéaire, fendillement de la peau ou des muqueuses due au froid ou à certains états morbides)',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)16)d)10.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: true,
      },
      'A)16)d)10.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)16)d)2.': {
    id: 'A)16)d)2.',
    name: 'Crevasses et gerçures :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)16)d)2.1/': {
        name: 'Fissures obliques',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)16)d)2.2/': {
        name: 'Fissures circulaires horizontales à la base du trayon',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)16)d)11.': {
    id: 'A)16)d)11.',
    name: 'Déchirures ?',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)16)d)11.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: true,
      },
      'A)16)d)11.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)16)d)3.': {
    id: 'A)16)d)3.',
    name: 'Déchirures :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)16)d)3.1/': {
        name: 'Lésions superficielle',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)16)d)3.2/': {
        name: 'Lésions profondes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)16)d)3.3/': {
        name: 'Ulcération',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)16)d)12.': {
    id: 'A)16)d)12.',
    name: 'écrasement ?',
    description: 'état de ce qui est écrasé, aplati, broyé, brisé ou meurtri par une compression, un choc',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)16)d)12.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: true,
      },
      'A)16)d)12.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)16)d)4.': {
    id: 'A)16)d)4.',
    name: 'écrasement :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)16)d)4.1/': {
        name: 'écrasement du trayon',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)16)d)4.2/': {
        name: 'écrasement de la partie latéro-externe de la mamelle',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)16)d)13.': {
    id: 'A)16)d)13.',
    name: 'Fistules ?',
    description: 'Voie anormale suivie par un liquide physiologique ou pathologique',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)16)d)13.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: true,
      },
      'A)16)d)13.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)16)d)5.': {
    id: 'A)16)d)5.',
    name: 'Fistules :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: false,
    answers: {
      'A)16)d)5.1/': {
        name: 'Fistule vraie',
        description: 'L’injection de bleu de méthylène dans l’orifice de la fistule ressort au niveau du canal du trayon',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)16)d)5.2/': {
        name: 'Fistule fausse',
        description: 'L’injection de bleu de méthylène dans l’orifice de la fistule aboutit dans un cul de sac',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)16)d)14.': {
    id: 'A)16)d)14.',
    name: 'Gelures ?',
    description: 'Lésions résultant de l’action du froid sur un organe, une partie du corps',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)16)d)14.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: true,
      },
      'A)16)d)14.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)16)d)6.': {
    id: 'A)16)d)6.',
    name: 'Gelures :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)16)d)6.1/': {
        name: 'Trayon tuméfié, rouge',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)16)d)6.2/': {
        name: 'Trayon pâle',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)16)d)6.3/': {
        name: 'Coloration rouge orange',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)16)d)6.4/': {
        name: 'Indolore au toucher',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)16)d)15.': {
    id: 'A)16)d)15.',
    name: 'Hématomes ?',
    description: 'épanchement de sang dans une cavité naturelle ou sous la peau, consécutif à une rupture des vaisseaux',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)16)d)15.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: true,
      },
      'A)16)d)15.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)16)d)7.': {
    id: 'A)16)d)7.',
    name: 'Hématomes :',
    description: '',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)16)d)7.1/': {
        name: 'Hémorragies sous cutanées',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)16)d)7.2/': {
        name: 'Plutôt à l’extrémité distale du trayon',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)16)d)16.': {
    id: 'A)16)d)16.',
    name: 'Sténose et occlusion de l\'orifice du trayon ?',
    description: 'Sténose (rétrécissement pathologique d’un orifice naturel ou de la lumière d’un conduit excréteur) et occlusion (fermeture pathologique d’un conduit, d’un orifice de l’organisme) de l’orifice du trayon',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'A)16)d)16.1/': {
        name: 'Oui',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: true,
      },
      'A)16)d)16.2/': {
        name: 'Non',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'A)16)d)8.': {
    id: 'A)16)d)8.',
    name: 'Sténose et occlusion de l’orifice du trayon :',
    description: 'Excès de sang dans les vaisseaux d’un organe ou d’une partie d’organe',
    interdire: false,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: true,
    multipleAnswer: true,
    answers: {
      'A)16)d)8.1/': {
        name: 'Congestion de l’orifice',
        description: 'Excès de sang dans les vaisseaux d’un organe ou d’une partie d’organe',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)16)d)8.2/': {
        name: 'Saignements',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)16)d)8.3/': {
        name: 'écoulement du lait contrarié',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)16)d)8.4/': {
        name: 'Plusieurs trayons atteints',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)16)d)8.5/': {
        name: 'Plutôt les trayons antérieurs',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'A)16)d)8.6/': {
        name: 'Plutôt les longs trayons',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'C)I)': {
    id: 'C)I)',
    idt1: 'C)',
    titre1: 'EXAMENS COMPLEMENTAIRES',
    name: 'Indiquer tout d’abord la nature du prélèvement analysé :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'C)I)1/': {
        name: 'Aphtes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)2/': {
        name: 'Avorton',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)2bis/': {
        name: 'Placenta',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)2ter/': {
        name: 'écouvillon vaginal après avortement',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)3/': {
        name: 'Bouses du nouveau né',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)4/': {
        name: 'Bouses de jeune bovin ou d\'adulte',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)5/': {
        name: 'Caillette',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)6/': {
        name: 'Cœur',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)7/': {
        name: 'Contenu du rumen',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)8/': {
        name: 'Diaphragme',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)10/': {
        name: 'Encéphale',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)11/': {
        name: 'Foie',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)12/': {
        name: 'Ganglion',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)13/': {
        name: 'Intestin',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)14/': {
        name: 'Kystes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)15/': {
        name: 'Lait',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)16/': {
        name: 'Langue',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)17/': {
        name: 'Liquide articulaire',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)17bis/': {
        name: 'Pus ombilical',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)18/': {
        name: 'Liquide de lavage du fourreau',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)19/': {
        name: 'Liquide trachéal',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)20/': {
        name: 'Mamelle',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)21/': {
        name: 'Mucus ou liquide utérin',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)22/': {
        name: 'Exsudat ou mucus vulvo-vaginal',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)23/': {
        name: 'Mucus nasal',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)24/': {
        name: 'Muqueuse',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)25/': {
        name: 'Muscle',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)26/': {
        name: 'œil',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)27/': {
        name: 'œsophage',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)28/': {
        name: 'Os',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)29/': {
        name: 'Papules',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)30/': {
        name: 'Peau',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)31/': {
        name: 'Péritoine',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)32/': {
        name: 'Poumon',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)33/': {
        name: 'Rate',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)34/': {
        name: 'Rein',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)35/': {
        name: 'Paroiu du rumen',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)36/': {
        name: 'Sang',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)36bis/': {
        name: 'Sang total',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)36ter/': {
        name: 'Sérum',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)36quater/': {
        name: 'Plasma',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)37/': {
        name: 'Sperme',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)38/': {
        name: 'Testicule',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)39/': {
        name: 'Tissus conjonctif',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)40/': {
        name: 'Urine',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)41/': {
        name: 'Utérus',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)42/': {
        name: 'Pus',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)44/': {
        name: 'Muscle de la carcasse',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)I)47/': {
        name: 'Tumeur',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'C)II)1)': {
    id: 'C)II)1)',
    idt2: 'C)II)',
    titre2: 'Indiquer maintenant le type d’analyse réalisée :',
    name: 'Les bouses du nouveau né :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)II)1)1/': {
        name: 'Parasitologie',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)1)2/': {
        name: 'Bactériologie',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)1)3/': {
        name: 'Virologie',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)II)2)': {
    id: 'C)II)2)',
    name: 'Les bouses du jeune bovin ou de l\'adulte :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)II)2)1/': {
        name: 'Parasitologie',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)2)2/': {
        name: 'Bactériologie',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)2)3/': {
        name: 'Virologie',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)II)3)': {
    id: 'C)II)3)',
    name: 'Le lait :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)II)3)1/': {
        name: 'Résultats du contrôle laitier ou de la coopérative',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)3)2/': {
        name: 'CMT',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)3)3/': {
        name: 'Bactériologie',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)3)6/': {
        name: 'Dosage de l’I (iode)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)3)7/': {
        name: 'Dosage de la progestérone',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)3)8/': {
        name: 'Dosage des bPAG (PSBP ou PAG)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)3)9/': {
        name: 'évaluation des anticorps (immunoglobulines) du colostrum avec colostromètre',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)3)9bis/': {
        name: 'évaluation des anticorps (immunoglobulines) du colostrum avec Brix',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)3)9ter/': {
        name: 'évaluation des anticorps (immunoglobulines) du colostrum avec réfractomètre Brix',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)3)9quater/': {
        name: 'évaluation des anticorps (immunoglobulines) du colostrum par dosage des IgG1',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)II)4)': {
    id: 'C)II)4)',
    name: 'Le sang :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)II)4)1/': {
        name: 'Aspect visuel',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)2/': {
        name: 'Cytologie (examen des cellules au microscope) d’un étalement (frottis) sanguin',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)3/': {
        name: 'Sérologie (évaluation des anticorps témoins d’une infection)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)4/': {
        name: 'Virologie (recherche directe de virus)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)4bis/': {
        name: 'Bactériologie ( recherche directe de bactéries)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)4ter/': {
        name: 'Parasitologie ( recherche directe de parasites)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)5/': {
        name: 'Technique de PCR',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)6/': {
        name: 'Technique de culture (isolement et identification)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)6bis/': {
        name: 'Technique de détection d’antigène',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)7/': {
        name: 'Biochime',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)8/': {
        name: 'Hématologie (numération formule (NF))',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)13/': {
        name: 'Dosage de l’activité de la glutathion peroxydase (Se)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)14/': {
        name: 'Dosage de l’albumine',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)14bis/': {
        name: 'Dosage du fibrinogène',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)14ter/': {
        name: 'Dosage de l’haptoglobine',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)15/': {
        name: 'Dosage de l’ammoniaque',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)16/': {
        name: 'Dosage du béta-hydroxybutyrate (BHB) (corps cétonique)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)17/': {
        name: 'Dosage de la bilirubine totale',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)19/': {
        name: 'Dosage des bPAG (PSBP ou PAG)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)20/': {
        name: 'Dosage du Ca (calcium) total',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)20bis/': {
        name: 'Dosage du Ca (calcium) ionisé',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)21/': {
        name: 'Dosage du Cl (chlore)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)22/': {
        name: 'Dosage du Cu (cuivre)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)23/': {
        name: 'Dosage du glucose (glycémie)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)24/': {
        name: 'Dosage de l’I (iode)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)25/': {
        name: 'Dosage du K (potassium)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)26/': {
        name: 'Dosage du Lactate',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)27/': {
        name: 'Dosage du Mg (magnésium)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)28/': {
        name: 'Dosage du Na (sodium)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)29/': {
        name: 'Dosage du P (phosphates)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)30/': {
        name: 'Dosage du pepsinogène plasmatique',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)31/': {
        name: 'PH sanguin',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)32/': {
        name: 'Dosage du Phosphate',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)33/': {
        name: 'Dosage de la progestérone',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)34/': {
        name: 'Dosage des protéines totales',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)36/': {
        name: 'Dosage de la vitamineB12 sérique',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)37/': {
        name: 'Dosage de l’urée',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)37bis/': {
        name: 'Dosage de la créatinine',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)38/': {
        name: 'Dosage du Se (sélénium)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)39/': {
        name: 'Dosage du Zn (zinc)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)40/': {
        name: 'Dosage des bicarbonates ( HCO3- ou CO2 total)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)4)41/': {
        name: 'Dosage de l’activité enzymatique (à décliner selon enzyme ASAT, GGT, CK)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)II)5)': {
    id: 'C)II)5)',
    name: 'Le sperme :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)II)5)1/': {
        name: 'Observation microscopique',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)5)2/': {
        name: 'Bactériologie',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)5)3/': {
        name: 'Test de Schalm',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)II)6)': {
    id: 'C)II)6)',
    name: 'Les urines :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)II)6)1/': {
        name: 'Leucocytes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)6)2/': {
        name: 'Nitrites',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)6)3/': {
        name: 'Protéines',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)6)4/': {
        name: 'PH',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)6)5/': {
        name: 'Sang',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)6)6/': {
        name: 'Densité',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)6)7/': {
        name: 'Corps cétoniques',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)6)8/': {
        name: 'Glucose',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)6)9/': {
        name: 'Bactéries',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)6)11/': {
        name: 'I (iode)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)II)6)12/': {
        name: 'Minéraux (à décliner selon minéral Na, K, Cl, Ca, P, Mg)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)': {
    id: 'C)III)',
    idt2: 'C)III)',
    titre2: 'Indiquer, si vous le connaissez, le résultat de l’analyse demandée',
    autopass: true,
    interdire: true,
  },
  'C)III)1)a)': {
    id: 'C)III)1)a)',
    idt3: 'C)III)1)',
    titre3: 'Le lait :',
    idt4: 'C)III)1)a)',
    titre4: 'Contrôle laitier et CMT',
    autopass: true,
    interdire: true,
  },
  'C)III)1)a)1.a)': {
    id: 'C)III)1)a)1.a)',
    idt4: 'C)III)1)a)1.',
    titre4: 'Résultats du contrôle laitier ou de la coopérative :',
    name: 'Cellules :',
    description: '',
    image: '',
    interdire: true,
    veto: false,
    imposer: false,
    checked: false,
    showNextQuestion: false,
    answers: {
      'C)III)1)a)1.a)1/': {
        name: '0 < > 200.000',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)a)1.a)2/': {
        name: '150.000 < > 500.000',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)a)1.a)3/': {
        name: '400.000 < > 1.500.000',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)a)1.a)4/': {
        name: '800.000 < > 5.000.000',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)a)1.a)5/': {
        name: '> 5.000.000',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)1)a)1.b)': {
    id: 'C)III)1)a)1.b)',
    name: 'PH:',
    description: '',
    image: '',
    interdire: true,
    veto: false,
    imposer: false,
    checked: false,
    showNextQuestion: false,
    answers: {
      'C)III)1)a)1.b)1/': {
        name: '< 5,2',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)a)1.b)2/': {
        name: '> 7',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)1)a)1.c)': {
    id: 'C)III)1)a)1.c)',
    name: 'Matières grasses et matières protéiques :',
    description: '',
    image: '',
    interdire: true,
    veto: false,
    imposer: false,
    checked: false,
    showNextQuestion: false,
    answers: {
      'C)III)1)a)1.c)1/': {
        name: 'MP > MG',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)1)a)1.d)': {
    id: 'C)III)1)a)1.d)',
    name: 'Urée :',
    description: '',
    image: '',
    interdire: true,
    veto: false,
    imposer: false,
    checked: false,
    showNextQuestion: false,
    answers: {
      'C)III)1)a)1.d)1/': {
        name: '< 0,20 g/L',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)a)1.d)2/': {
        name: '0,20 g/L < > 0,35 g/L',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)a)1.d)3/': {
        name: '0,35 g/L < > 0,50 g/L',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)a)1.d)4/': {
        name: '> 0,50 g/L',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)1)a)2.a)': {
    id: 'C)III)1)a)2.a)',
    idt4: 'C)III)1)a)2.',
    titre4: 'CMT',
    name: 'Avant droit :',
    description: '',
    image: '',
    interdire: true,
    veto: false,
    imposer: false,
    checked: false,
    showNextQuestion: false,
    answers: {
      'C)III)1)a)2.a)1/': {
        name: 'N',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)a)2.a)2/': {
        name: 'T',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)a)2.a)3/': {
        name: '1',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)a)2.a)4/': {
        name: '2',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)a)2.a)5/': {
        name: '3',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)a)2.a)6/': {
        name: '+',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)a)2.a)7/': {
        name: '- A',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)1)a)2.b)': {
    id: 'C)III)1)a)2.b)',
    name: 'Avant gauche :',
    description: '',
    image: '',
    interdire: true,
    veto: false,
    imposer: false,
    checked: false,
    showNextQuestion: false,
    answers: {
      'C)III)1)a)2.b)1/': {
        name: 'N',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)a)2.b)2/': {
        name: 'T',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)a)2.b)3/': {
        name: '1',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)a)2.b)4/': {
        name: '2',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)a)2.b)5/': {
        name: '3',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)a)2.b)6/': {
        name: '+',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)a)2.b)7/': {
        name: '- A',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)1)a)2.c)': {
    id: 'C)III)1)a)2.c)',
    name: 'Arrière droit :',
    description: '',
    image: '',
    interdire: true,
    veto: false,
    imposer: false,
    checked: false,
    showNextQuestion: false,
    answers: {
      'C)III)1)a)2.c)1/': {
        name: 'N',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)a)2.c)2/': {
        name: 'T',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)a)2.c)3/': {
        name: '1',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)a)2.c)4/': {
        name: '2',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)a)2.c)5/': {
        name: '3',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)a)2.c)6/': {
        name: '+',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)a)2.c)7/': {
        name: '- A',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)1)a)2.d)': {
    id: 'C)III)1)a)2.d)',
    name: 'Arrière gauche :',
    description: '',
    image: '',
    interdire: true,
    veto: false,
    imposer: false,
    checked: false,
    showNextQuestion: false,
    answers: {
      'C)III)1)a)2.d)1/': {
        name: 'N',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)a)2.d)2/': {
        name: 'T',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)a)2.d)3/': {
        name: '1',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)a)2.d)4/': {
        name: '2',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)a)2.d)5/': {
        name: '3',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)a)2.d)6/': {
        name: '+',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)a)2.d)7/': {
        name: '- A',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)1)b)': {
    id: 'C)III)1)b)',
    name: 'Analyses bactériologiques de lait de mammite :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)1)b)1/': {
        name: 'Trueperella pyogenes (T. pyogene)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)b)2/': {
        name: 'Bacillus cereus (B. cereus)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)b)4/': {
        name: 'Escheérichia coli (E.coli)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)b)5/': {
        name: 'Klebsiella spp',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)b)6/': {
        name: 'Nocardia astéroïdes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)b)7/': {
        name: 'Pseudomonas spp',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)b)8/': {
        name: 'Staphylococcus aureus',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)b)8bis/': {
        name: 'Staphylococcus coagulase positif',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)b)8ter/': {
        name: 'Staphylococcus spp coagulase négatif',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)b)9/': {
        name: 'Streptococcus agalactiae',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)b)10/': {
        name: 'Streptocococcus dysgalactiae',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)b)11/': {
        name: 'Streptococcus ubéris',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)b)12/': {
        name: 'Aspergillus spp',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)b)13/': {
        name: 'Proteus spp',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)b)14/': {
        name: 'Candida spp',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)b)15/': {
        name: 'Serratia spp',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)b)16/': {
        name: 'Listeria monocytogenes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)b)17/': {
        name: 'Salmonella spp',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)b)18/': {
        name: 'E. coli producteur de shiga toxine (STEC)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)b)19/': {
        name: 'Prototheca spp',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)1)c)': {
    id: 'C)III)1)c)',
    name: 'Agents infectieux :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)1)c)1/': {
        name: 'BVD (PCR)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)c)1bis/': {
        name: 'BVD (Anticorps)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)c)2/': {
        name: 'IBR (Anticorps)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)c)3/': {
        name: 'LBE (leucose bovine enzootique) (anticorps)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)1)d)': {
    id: 'C)III)1)d)',
    name: 'Parasitologie :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)1)d)1/': {
        name: 'Fasciola hepatica (grande douve du foie) (anticorps)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'C)III)1)e)1.': {
    id: 'C)III)1)e)1.',
    idt4: 'C)III)1)e)',
    titre4: 'Biochimie :',
    name: 'Dosage de l’I (iode)(en µg/l):',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)1)e)1.1/': {
        name: '< 30 (carence)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)e)1.2/': {
        name: '> 300 (excès)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)1)e)2.': {
    id: 'C)III)1)e)2.',
    name: 'Dosage de la progestérone(en ng/ml):',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)1)e)2.1/': {
        name: '< 2',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)e)2.2/': {
        name: '> 3,5',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)1)e)3.': {
    id: 'C)III)1)e)3.',
    name: 'Dosage des bPAG (PSBP ou PAG) :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)1)e)3.1/': {
        name: 'Gestante',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)e)3.2/': {
        name: 'Non gestante',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'C)III)1)f)1.': {
    id: 'C)III)1)f)1.',
    idt4: 'C)III)1)f)',
    titre4: 'Evaluation de la qualité  du colostrum :',
    name: 'Avec pèse colostrum :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)1)f)1.1/': {
        name: 'Zone rouge (colostrum médiocre)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)f)1.2/': {
        name: 'Zone jaune (colostrum moyen)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)f)1.3/': {
        name: 'Zone verte (colostrum de bonne qualité)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)1)f)2.': {
    id: 'C)III)1)f)2.',
    name: 'Avec réfractomètre Brix :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)1)f)2.1/': {
        name: '% < 20: qualité médiocre',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)f)2.2/': {
        name: '% > 23 bonne qualité',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)1)f)3.': {
    id: 'C)III)1)f)3.',
    name: 'Dosage des IgG1 :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)1)f)3.1/': {
        name: '< 50 g/L qualité médiocre',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)1)f)3.2/': {
        name: '> 70 g./L bonne qualité',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'C)III)2)a)1.': {
    id: 'C)III)2)a)1.',
    idt3: 'C)III)2)',
    titre3: 'Le sang :',
    idt4: 'C)III)2)a)',
    titre4: 'Examen direct :',
    name: 'Analyse visuelle :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'C)III)2)a)1.1/': {
        name: 'Incoagulable',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)a)1.2/': {
        name: 'Noir',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)a)1.3/': {
        name: 'Poisseux',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)a)2.': {
    id: 'C)III)2)a)2.',
    name: 'étalement / frottis sanguin :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'C)III)2)a)2.1/': {
        name: 'Anaplasma phagocytophilum (ehrlichiose ou anaplasmose granulocytaire)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)a)2.2/': {
        name: 'Anaplasma marginale ( anaplasmose éythrocytaire)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)a)2.3/': {
        name: 'Babesia spp',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)a)2.6/': {
        name: 'Theileria spp ( piroplasmose)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)a)2.8/': {
        name: 'Trypanosoma',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)a)2.9/': {
        name: 'Mycoplasma wenyonii ( éperythzoonose)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)b)': {
    id: 'C)III)2)b)',
    name: 'Sérologie (recherche d’antigènes ou d’anticorps = témoins d’une infection) :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)b)1/': {
        name: 'Anaplasmose granulocytaire (ehrlichiose)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)b)2/': {
        name: 'Babesiose',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)b)3/': {
        name: 'Besnoitiose',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)b)4/': {
        name: 'Borreliose ( Maladie de Lyme)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)b)5/': {
        name: 'Brucellose',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)b)6/': {
        name: 'BVD',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)b)7/': {
        name: 'Chlamydiose',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)b)8/': {
        name: 'Fasciolose',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)b)9/': {
        name: 'FCO',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)b)10/': {
        name: 'Fièvre Q',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)b)11/': {
        name: 'IBR',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)b)12/': {
        name: 'LBE',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)b)14/': {
        name: 'Paratuberculose (Mycobacteérium avium subsp. Paratuberculosis) (Map)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)b)18/': {
        name: 'Infection par le VRSB',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)b)19/': {
        name: 'Infection par le virus PI3 ( Parainfluenza 3)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)b)20/': {
        name: 'Infection par le virus Adenovirus 3',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)b)21/': {
        name: 'Infection par le BoHV4',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)b)22/': {
        name: 'Infection par Mycoplasma bovis',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)b)23/': {
        name: 'Leptospirose',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)b)24/': {
        name: 'Neosporose',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)c)': {
    id: 'C)III)2)c)',
    name: 'Méthode de mise en évidence de protéines ou antigènes viraux :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)c)1/': {
        name: 'BVD',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)d)': {
    id: 'C)III)2)d)',
    name: 'PCR :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)d)1/': {
        name: 'Anaplasma phagocytophilum',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)d)2/': {
        name: 'Borrelia',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)d)3/': {
        name: 'BVD',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)d)4/': {
        name: 'Coryza gangréneux',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)d)5/': {
        name: 'FCO',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)d)10/': {
        name: 'Babesia spp',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)d)11/': {
        name: 'Theileria spp',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)d)12/': {
        name: 'Mycoplasma wenyonii',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)d)13/': {
        name: 'Leptospira spp',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)e)': {
    id: 'C)III)2)e)',
    name: 'Mise en culture :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)e)1/': {
        name: 'Borrelia',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'C)III)2)f)4.': {
    id: 'C)III)2)f)4.',
    idt4: 'C)III)2)f)',
    titre4: 'Biochimie :',
    name: 'Dosage de l’activité acétylcholinestérase (en µmol de substrat hydrolysé par minute / ml d’échantillon):',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)f)4.a)': {
        name: 'Sang total :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)4.a)1/': {
        name: '< 2,64 ou > 3,32',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)4.b)': {
        name: 'Globules rouges :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)4.b)1/': {
        name: '< 4,94 ou > 6,84',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)4.c)': {
        name: 'Plasma :',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)4.c)1/': {
        name: '< 0,21 ou > 0,37',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)f)5.': {
    id: 'C)III)2)f)5.',
    name: 'Dosage de l’activité de la glutathion peroxydase (Se) (en U/g Hb) :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)f)5.1/': {
        name: '<75 (carence)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)5.2/': {
        name: '75 < < 150 (marginal)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)5.3/': {
        name: '> 1500 (excès)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)f)5bis.a)': {
    id: 'C)III)2)f)5bis.a)',
    name: 'ASAT (Aspartate Amino Transtérase) en (U/L) :',
    idt4: 'C)III)2)f)5bis.',
    titre4: 'Dosage de l\'activité enzymatique (en (U/L)',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)f)5bis.a)1/': {
        name: '< 30',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)5bis.a)2/': {
        name: '> 170',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)f)5bis.b)': {
    id: 'C)III)2)f)5bis.b)',
    name: 'GGT (Gamma Glutamyl Transférase) en (U/L) :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)f)5bis.b)1/': {
        name: '< 10',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)5bis.b)2/': {
        name: '> 45',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)f)5bis.c)': {
    id: 'C)III)2)f)5bis.c)',
    name: 'CK (Créatinine Kinase) en (U/L) :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)f)5bis.c)1/': {
        name: '< 350',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)f)6.': {
    id: 'C)III)2)f)6.',
    name: 'Dosage de l’albumine (en g/l):',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)f)6.1/': {
        name: '< 27',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)6.2/': {
        name: '>33',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)f)7.': {
    id: 'C)III)2)f)7.',
    name: 'Dosage de l’ammoniaque (en mmol/l):',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)f)7.1/': {
        name: '> 70',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)f)8.': {
    id: 'C)III)2)f)8.',
    name: 'Dosage du béta-hydroxybutyrate (corps cétoniques) (en mg/l):',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)f)8.1/': {
        name: '< 0.8',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)8.2/': {
        name: '0.8 < < 1.2',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)8.3/': {
        name: '1.2< < 2.5',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)8.4/': {
        name: '>2.5',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)f)9.': {
    id: 'C)III)2)f)9.',
    name: 'Dosage de la bilirubine totale (en mg/l):',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)f)9.1/': {
        name: '> 15',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)f)11.': {
    id: 'C)III)2)f)11.',
    name: 'Dosage des bPAG (PSBP ou PAG) :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)f)11.1/': {
        name: 'Gestante',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)11.2/': {
        name: 'Non gestante',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)f)12.a)': {
    id: 'C)III)2)f)12.a)',
    name: '(en mmol/l):',
    idt4: 'C)III)2)f)12.',
    titre4: 'Dosage du Ca (calcium)',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)f)12.a)1/': {
        name: '< 1,65',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)12.a)2/': {
        name: '1,65 < <1,90',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)12.a)3/': {
        name: '1.90 < <2.20',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)12.a)4/': {
        name: '1.90 < < 2.80',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)f)12.b)': {
    id: 'C)III)2)f)12.b)',
    name: '(en mg/l):',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)f)12.b)1/': {
        name: '< 65',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)12.b)2/': {
        name: '65 < < 75',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)12.b)3/': {
        name: '75 < < 90',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)12.b)4/': {
        name: '90 < < 115',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)f)13.': {
    id: 'C)III)2)f)13.',
    name: 'Dosage du Cl (chlore) (en mmol/l):',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)f)13.1/': {
        name: '< 90',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)13.2/': {
        name: '> 110',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)f)14.a)': {
    id: 'C)III)2)f)14.a)',
    name: 'Cu plasmatique (en µmol/l):',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)f)14.a)1/': {
        name: '<8 (carence)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)14.a)2/': {
        name: '8< <15 (marginal)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)14.a)3/': {
        name: '> 20 (excès)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)f)14.b)': {
    name: 'Cu sérique (en µg/l):',

    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)f)14.b)1/': {
        name: '< 450',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)14.b)2/': {
        name: '450< < 600 (marginal)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)14.b)3/': {
        name: '> 1500 (excès)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)f)15.': {
    id: 'C)III)2)f)15.',
    name: 'Glycémie (en g/l) :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)f)15.1/': {
        name: '< 0,4',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)15.2/': {
        name: '0,4 < < 0,6',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)15.3/': {
        name: '> 0,6',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)f)16.': {
    id: 'C)III)2)f)16.',
    name: 'Dosage de l’iode(en µg/l):',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)f)16.1/': {
        name: '< 50 (carence)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)16.2/': {
        name: '50 < < 100 (marginal)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)16.3/': {
        name: '> 500 (excès)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)f)17.': {
    id: 'C)III)2)f)17.',
    name: 'Dosage du K (potassium) (en mmol/l):',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)f)17.1/': {
        name: '< 3,8',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)17.2/': {
        name: '> 5,2',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)f)18.': {
    id: 'C)III)2)f)18.',
    name: 'Dosage du Lactate (en mmol/l):',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)f)18.1/': {
        name: '> 3,5',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)f)19.a)': {
    id: 'C)III)2)f)19.a)',
    name: '(en mmol/l):',
    idt4: 'C)III)2)f)19.',
    titre4: 'Dosage du Mg (magnésium) :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)f)19.a)1/': {
        name: '< 0,7',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)19.a)2/': {
        name: '> 1',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)f)19.b)': {
    id: 'C)III)2)f)19.b)',
    name: '(en mg/l):',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)f)19.b)1/': {
        name: '< 17',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)19.b)2/': {
        name: '> 24',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)f)20.': {
    id: 'C)III)2)f)20.',
    name: 'Dosage du Na (sodium) (en mmol/l):',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)f)20.1/': {
        name: '< 145',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)20.2/': {
        name: '> 160',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)f)22.': {
    id: 'C)III)2)f)22.',
    name: 'Dosage du pepsinogène plasmatique (en mU):',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)f)22.1/': {
        name: '> 600',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)f)23.': {
    id: 'C)III)2)f)23.',
    name: 'PH:',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)f)23.1/': {
        name: '< 7,35',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)23.2/': {
        name: '> 7,45',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)f)24.a)': {
    id: 'C)III)2)f)24.a)',
    name: '(en mmol/l):',
    idt4: 'C)III)2)f)24.',
    titre4: 'Dosage des Phosphates :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)f)24.a)1/': {
        name: '< 0,3',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)24.a)2/': {
        name: '0,3 < < 1,3',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)24.a)3/': {
        name: '1,3 < < 2',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)24.a)4/': {
        name: '> 2,25',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)f)24.b)': {
    id: 'C)III)2)f)24.b)',
    name: '(en mg/l):',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)f)24.b)1/': {
        name: '< 10',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)24.b)2/': {
        name: '10 < < 40',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)24.b)3/': {
        name: '40 < < 65',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)24.b)4/': {
        name: '> 75',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)f)25.': {
    id: 'C)III)2)f)25.',
    name: 'Dosage de la progestérone(en ng/ml):',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)f)25.1/': {
        name: '< 1',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)f)26.': {
    id: 'C)III)2)f)26.',
    name: 'Dosage des protéines totales (en g/l):',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)f)26.1/': {
        name: '< 60',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)26.2/': {
        name: '> 80',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)f)28.': {
    id: 'C)III)2)f)28.',
    name: 'Dosage de la vitamine B12 sérique (en ng/l) (indicateur d’une carence en Co (cobalt)):',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)f)28.1/': {
        name: '< 100 (carence)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)28.2/': {
        name: '100 < < 200 (marginal)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)f)29.': {
    id: 'C)III)2)f)29.',
    name: 'Dosage de l’urée (en g/l):',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)f)29.1/': {
        name: '< 0,25',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)29.2/': {
        name: '0.25< < 0.45',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)29.3/': {
        name: '0.45 < < 0.80',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)29.4/': {
        name: '> 0.80',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)f)30.': {
    id: 'C)III)2)f)30.',
    name: 'Dosage du Se (sélénium) (en µg/l):',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)f)30.1/': {
        name: '< 50 (carence)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)30.2/': {
        name: '50 < < 200 (marginal)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)f)31.': {
    id: 'C)III)2)f)31.',
    name: 'Dosage du Zn (zinc) sérique (en µg/l):',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)f)31.1/': {
        name: '< 400 (carence)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)31.2/': {
        name: '400 < < 600 (marginal)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)f)32.': {
    id: 'C)III)2)f)32.',
    name: 'Dosage du fibrinogène :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)f)32.1/': {
        name: '<<< 1 g/L',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)32.2/': {
        name: '>>> 7 g/L',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)f)33.': {
    id: 'C)III)2)f)33.',
    name: 'Dosage de l\'haptoglobine :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)f)33.1/': {
        name: '>>> 0,1 g/L',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)f)34.': {
    id: 'C)III)2)f)34.',
    name: 'Dosage de la créatinine :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)f)34.1/': {
        name: '< 12 mg/L',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)f)34.2/': {
        name: '> 12 mg/L',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'C)III)2)g)1.1.': {
    id: 'C)III)2)g)1.1.',
    idt4: 'C)III)2)g)1.',
    titre4: 'Formule :',
    name: 'Hemoglobine ( g/L) :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)g)1.1.1/': {
        name: '< 50',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)g)1.1.2/': {
        name: '50< < 80',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)g)1.1.3/': {
        name: '80< < 120',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)g)1.1.4/': {
        name: '> 120',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)g)2.': {
    id: 'C)III)2)g)2.',
    name: 'Globules blancs :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)g)2.1/': {
        name: '< 5000 / mm3',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)g)2.2/': {
        name: '> 12000 / mm3',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)g)3.': {
    id: 'C)III)2)g)3.',
    name: 'Lymphocytes :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)g)3.1/': {
        name: '< 3000 / mm3',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)g)3.2/': {
        name: '> 7500 / mm3',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)g)4.': {
    id: 'C)III)2)g)4.',
    name: 'Leucocytes neutrophiles :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)g)4.1/': {
        name: '< 1500 / mm3',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)g)4.2/': {
        name: '> 3000 / mm3',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)g)5.': {
    id: 'C)III)2)g)5.',
    name: 'Leucocytes éosinophiles :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)g)5.1/': {
        name: '< 350 / mm3',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)g)5.2/': {
        name: '> 500 / mm3',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)g)6.': {
    id: 'C)III)2)g)6.',
    name: 'Monocytes :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)g)6.1/': {
        name: '< 350 / mm3',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)g)6.2/': {
        name: '> 500 / mm3',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)2)g)7.': {
    id: 'C)III)2)g)7.',
    name: 'Leucocytes basophiles :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)2)g)7.1/': {
        name: '< 50 / mm3',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)2)g)7.2/': {
        name: '> 100 / mm3',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'C)III)3)': {
    id: 'C)III)3)',
    idt3: 'C)III)3)',
    titre3: 'Prélèvements d’organes, de tissus ou liquides physiologiques, d’avortons ou placenta :',
    autopass: true,
    interdire: true,
  },
  'C)III)3)a)1.': {
    id: 'C)III)3)a)1.',
    idt4: 'C)III)3)a)',
    titre4: 'Prélèvements d’organes :',
    name: 'Caillette :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'C)III)3)a)1.1/': {
        name: 'Clostridium septicum',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)3)a)2.': {
    id: 'C)III)3)a)2.',
    name: 'Cœur :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'C)III)3)a)2.1/': {
        name: 'Mycobacteérium bovis',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)2.2/': {
        name: 'Cysticerque',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)2.3/': {
        name: 'Kyste hydatique (echinococcus granulosus, hydatidose)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)2.4/': {
        name: 'Histophilus somni',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)3)a)3.': {
    id: 'C)III)3)a)3.',
    name: 'Diaphragme :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)3)a)3.1/': {
        name: 'Cysticerque (taenia saginata, cysticercose)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)3)a)4.': {
    id: 'C)III)3)a)4.',
    name: 'Encéphale :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'C)III)3)a)4.2/': {
        name: 'Kyste hydatique',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)4.3/': {
        name: 'Listeria monocytogenes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)4.4/': {
        name: 'Virus de la rage',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)4.5/': {
        name: 'ESB',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)4.6/': {
        name: 'Histophilus somni',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)3)a)5.': {
    id: 'C)III)3)a)5.',
    name: 'Foie :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'C)III)3)a)5.1/': {
        name: 'Arsenic',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)5.1bis/': {
        name: 'Plomb',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)5.2/': {
        name: 'Cuivre',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)5.3/': {
        name: 'Vit B12 (indicateur d’une carence en Co (cobalt)) (carence si < 0,30 µg/mg)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)5.4/': {
        name: 'Clostridium perfringens',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)5.5/': {
        name: 'Clostridium sordellii',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)5.6/': {
        name: 'Clostridium septicum',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)5.7/': {
        name: 'Clostridium chauvoei',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)5.8/': {
        name: 'Clostridium novyi',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)5.9/': {
        name: 'Clostridium haemolyticum',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)5.10/': {
        name: 'Mycobactérium bovis',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)5.11/': {
        name: 'Cysticerque',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)5.12/': {
        name: 'Kyste hydatique',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)5.13/': {
        name: 'Salmonella',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)5.14/': {
        name: 'Listeria',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)5.15/': {
        name: 'Bacillus anthracis',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)5.16/': {
        name: 'E. coli',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)5.17/': {
        name: 'Trueperella pyogenes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)3)a)6.': {
    id: 'C)III)3)a)6.',
    name: 'Ganglions :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'C)III)3)a)6.1/': {
        name: 'Mycobactérium avium subsp. Paratuberculosis (Map)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)6.2/': {
        name: 'Mycobactérium bovis',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)3)a)7.': {
    id: 'C)III)3)a)7.',
    name: 'Intestin :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'C)III)3)a)7.2/': {
        name: 'Clostridium perfringens',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)7.3/': {
        name: 'Clostridium septicum',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)7.4/': {
        name: 'Clostridium sordellii',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)7.5/': {
        name: 'Mycobactérium avium subsp. Paratuberculosis (Map)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)7.6/': {
        name: 'Mycobactérium bovis',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)7.7/': {
        name: 'Salmonella',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)7.8/': {
        name: 'Listeria',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)7.9/': {
        name: 'E. coli',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)3)a)8.': {
    id: 'C)III)3)a)8.',
    name: 'Langue :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'C)III)3)a)8.1/': {
        name: 'Cysticerque',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)8.2/': {
        name: 'Parapoxivirus ( stomatite papuleuse)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)3)a)9.': {
    id: 'C)III)3)a)9.',
    name: 'Mamelle :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'C)III)3)a)9.1/': {
        name: 'Brucella',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)9.2/': {
        name: 'Mycobactérium bovis',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)9.3/': {
        name: 'Trueperella pyogenes (T. pyogene)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)9.4/': {
        name: 'Bacillus cereus (B. cereus)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)9.5/': {
        name: 'Escheérichia coli (E.coli)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)9.6/': {
        name: 'Klebsiella spp',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)9.7/': {
        name: 'Nocardia astéroïdes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)9.8/': {
        name: 'Pseudomonas spp',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)9.9/': {
        name: 'Staphylococcus aureus',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)9.10/': {
        name: 'Staphylococcus coagulase positif',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)9.11/': {
        name: 'Staphylococcus spp coagulase négatif',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)9.12/': {
        name: 'Streptococcus agalactiae',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)9.13/': {
        name: 'Streptocococcus dysgalactiae',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)9.14/': {
        name: 'Streptococcus ubéris',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)9.15/': {
        name: 'Aspergillus spp',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)9.16/': {
        name: 'Proteus spp',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)9.17/': {
        name: 'Candida spp',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)9.18/': {
        name: 'Serratia spp',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)9.19/': {
        name: 'Listeria monocytogenes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)9.20/': {
        name: 'Salmonella spp',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)9.21/': {
        name: 'E. coli producteur de shiga toxine (STEC)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)9.22/': {
        name: 'Prototheca spp',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)3)a)10.': {
    id: 'C)III)3)a)10.',
    name: 'œil :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)3)a)10.1/': {
        name: 'Moraxella bovis',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)3)a)11.': {
    id: 'C)III)3)a)11.',
    name: 'œsophage :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)3)a)11.1/': {
        name: 'Cysticerque',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)3)a)12.': {
    id: 'C)III)3)a)12.',
    name: 'Os :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)3)a)12.1/': {
        name: 'Kyste hydatique (echinococcus granulosus, hydatidose) (observation d’abattoir)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)3)a)13.': {
    id: 'C)III)3)a)13.',
    name: 'Péritoine :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)3)a)13.1/': {
        name: 'Cysticerque',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)3)a)14.': {
    id: 'C)III)3)a)14.',
    name: 'Poumon :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'C)III)3)a)14.1/': {
        name: 'Kyste hydatique',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)14.2/': {
        name: 'Mycobactérium bovis',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)14.3/': {
        name: 'Salmonella',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)14.4/': {
        name: 'Mannheimia haemolytica',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)14.5/': {
        name: 'Pasteurella multocida',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)14.6/': {
        name: 'Histophilus somni',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)14.7/': {
        name: 'Mycoplasma bovis',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)14.8/': {
        name: 'Mycoplasma spp',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)14.9/': {
        name: 'Trueperella pyogenes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)14.10/': {
        name: 'VRSB',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)14.11/': {
        name: 'PI3',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)14.12/': {
        name: 'Coronavirus',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)14.13/': {
        name: 'Influenza D',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)14.14/': {
        name: 'BVD',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)14.15/': {
        name: 'IBR',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)14.16/': {
        name: 'BoHV4',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)3)a)15.': {
    id: 'C)III)3)a)15.',
    name: 'Rate :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'C)III)3)a)15.1/': {
        name: 'Clostridium chauvoei',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)15.2/': {
        name: 'Kyste hydatique',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)15.3/': {
        name: 'Mycobactérium bovis',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)15.4/': {
        name: 'Bacillus anthracis',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)3)a)16.': {
    id: 'C)III)3)a)16.',
    name: 'Rein :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'C)III)3)a)16.1/': {
        name: 'Arsenic',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)16.2/': {
        name: 'Kyste hydatique',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)16.3/': {
        name: 'Mycobactérium bovis',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)16.4/': {
        name: 'Leptospira',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)a)16.5/': {
        name: 'Plomb',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)3)a)17.': {
    id: 'C)III)3)a)17.',
    name: 'Rumen :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'C)III)3)a)17.2/': {
        name: 'Paramphistomum',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)3)a)18.': {
    id: 'C)III)3)a)18.',
    name: 'Testicule :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)3)a)18.1/': {
        name: 'Brucella',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)3)a)19.': {
    id: 'C)III)3)a)19.',
    name: 'Utérus :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)3)a)19.1/': {
        name: 'Brucella',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)3)b)1.a)': {
    id: 'C)III)3)b)1.a)',
    name: 'Aphtes :',
    idt2: 'C)III)3)b)',
    titre2: 'Prélèvements de tissus et liquides :',
    idt4: 'C)III)3)b)1.',
    titre4: 'Lésions :',
    description: '',
    image: '',
    interdire: true,
    veto: false,
    imposer: false,
    checked: false,
    showNextQuestion: false,
    answers: {
      'C)III)3)b)1.a)1/': {
        name: 'Fièvre aphteuse',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)3)b)1.b)': {
    name: 'Papules :',
    id: 'C)III)3)b)1.b)',
    description: '',
    image: '',
    interdire: true,
    veto: false,
    imposer: false,
    checked: false,
    showNextQuestion: false,
    answers: {
      'C)III)3)b)1.b)1/': {
        name: 'Parapoxivirus',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)3)b)1.c)': {
    name: 'Peau :',
    id: 'C)III)3)b)1.c)',
    description: '',
    image: '',
    interdire: true,
    veto: false,
    imposer: false,
    checked: false,
    showNextQuestion: false,
    answers: {
      'C)III)3)b)1.c)1/': {
        name: 'Besnoitia besnoiti',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)3)b)2.': {
    id: 'C)III)3)b)2.',
    name: 'Liquide articulaire ou pus ombilical :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)3)b)2.1/': {
        name: 'Brucella',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)2.2/': {
        name: 'Trueperella pyogenes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)2.3/': {
        name: 'E. coli',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)2.4/': {
        name: 'Salmonella sp',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)2.5/': {
        name: 'Pasteurella sp',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)2.6/': {
        name: 'Streptococcus sp',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)2.7/': {
        name: 'Histophilus somni',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)2.8/': {
        name: 'Mannheimia',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)2.9/': {
        name: 'Chlamydia',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)2.10/': {
        name: 'Borrelia',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)3)b)3.': {
    id: 'C)III)3)b)3.',
    name: 'Muqueuse :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)3)b)3.1/': {
        name: 'Fièvre aphteuse',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)3)b)4.': {
    id: 'C)III)3)b)4.',
    name: 'Muscles :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'C)III)3)b)4.1/': {
        name: 'Clostridium chauvoei',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)4.2/': {
        name: 'Cysticerque',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)4.3/': {
        name: 'Kyste hydatique',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)4.4/': {
        name: 'Sarcocystis ( sarcosporidiose ) (myosite eosinophilique )',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)3)b)5.': {
    id: 'C)III)3)b)5.',
    name: 'Peau :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)3)b)5.1/': {
        name: 'Acariens',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)5.2/': {
        name: 'Champignons',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)5.3/': {
        name: 'Tuberculination positive',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)5.4/': {
        name: 'Staphylocoques',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)5.6/': {
        name: 'Dermatophilus congolensis',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)5.7/': {
        name: 'Parafilaria bovicola',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)5.8/': {
        name: 'Poux piqueurs',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)5.9/': {
        name: 'Poux broyeurs',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)3)b)6.': {
    id: 'C)III)3)b)6.',
    name: 'Pus :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)3)b)6.1/': {
        name: 'Trueperella pyogenes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)6.2/': {
        name: 'Eschérichia coli',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)6.3/': {
        name: 'Pasteurella spp',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)6.4/': {
        name: 'Salmonella spp',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)6.5/': {
        name: 'Staphylococcus spp',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)6.6/': {
        name: 'Streptococcus spp',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)6.7/': {
        name: 'Pseudomonas spp',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)3)b)7.': {
    id: 'C)III)3)b)7.',
    name: 'Le tissu conjonctif :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)3)b)7.1/': {
        name: 'Parafilaria bovicola',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)3)b)8.': {
    id: 'C)III)3)b)8.',
    name: 'Tumeur :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)3)b)8.1/': {
        name: 'Carcinomes et adénocarcinomes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)8.2/': {
        name: 'Adénomes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)8.3/': {
        name: 'Papillomes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)8.4/': {
        name: 'Sarcomes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)8.5/': {
        name: 'Fibromes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)8.6/': {
        name: 'Myxomes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)8.7/': {
        name: 'Lipomes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)8.8/': {
        name: 'Ostéomes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)8.9/': {
        name: 'Hémangiomes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)8.10/': {
        name: 'Mélanomes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)8.11/': {
        name: 'Leiomyomes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)8.12/': {
        name: 'Gliomes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)8.13/': {
        name: 'Neurinomes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)8.14/': {
        name: 'Adamantinomes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)8.15/': {
        name: 'Thymomes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)8.16/': {
        name: 'Myélomes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)8.17/': {
        name: 'Néphroblastomes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)8.18/': {
        name: 'Tératomes para-rénal',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)8.19/': {
        name: 'Phéochromocytome',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)8.20/': {
        name: 'Tumeurs de la granulosa',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)8.21/': {
        name: 'Tumeurs des cellules de Sertolli',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)8.22/': {
        name: 'Méningiomes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)8.23/': {
        name: 'épendymomes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)8.24/': {
        name: 'Schwannomes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)8.25/': {
        name: 'Mésothéliomes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)8.26/': {
        name: 'Mastocytomes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)b)8.27/': {
        name: 'Histiocytomes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)3)c)': {
    id: 'C)III)3)c)',
    name: 'Les avortons et le placenta :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)3)c)1/': {
        name: 'Trueperella pyogenes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)c)2/': {
        name: 'Aspergillus fumigatus',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)c)3/': {
        name: 'Brucella abortus',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)c)4/': {
        name: 'BVD',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)c)5/': {
        name: 'Campylobacter fœtus intestinalis',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)c)6/': {
        name: 'Campylobacter fœtus venerealis',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)c)7/': {
        name: 'Candida albicans',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)c)8/': {
        name: 'Chlamydia psittaci',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)c)9/': {
        name: 'Coxiella burnetii ( fievre Q)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)c)10/': {
        name: 'Eschérichia coli',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)c)11/': {
        name: 'Bo HV1 (herpesvirus 1 de l’IBR)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)c)12/': {
        name: 'BoHV4',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)c)13/': {
        name: 'Leptospira',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)c)14/': {
        name: 'Listeria monocytogenes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)c)15/': {
        name: 'Neospora caninum',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)c)16/': {
        name: 'Pestivirus',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)c)16bis/': {
        name: 'BVD',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)c)17/': {
        name: 'Salmonella',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)c)18/': {
        name: 'Trichomonas fœtus',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)3)c)19/': {
        name: 'Listeria monocytogenes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'C)III)4)a)': {
    id: 'C)III)4)a)',
    idt3: 'C)III)4)',
    titre3: 'Les urines :',
    name: 'Leucocytes(/µl):',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)4)a)1/': {
        name: 'Négatif',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)4)a)2/': {
        name: '15',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)4)a)3/': {
        name: '70',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)4)a)4/': {
        name: '125',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)4)a)5/': {
        name: '500',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)4)b)': {
    id: 'C)III)4)b)',
    name: 'Nitrites :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)4)b)1/': {
        name: 'Négatif',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)4)b)2/': {
        name: 'Positif',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)4)c)': {
    id: 'C)III)4)c)',
    name: 'Protéines(g/l):',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)4)c)1/': {
        name: 'Négatif',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)4)c)2/': {
        name: 'Trace',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)4)c)3/': {
        name: '0,30',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)4)c)4/': {
        name: '1',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)4)c)5/': {
        name: '3',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)4)c)6/': {
        name: '> 20',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)4)d)': {
    id: 'C)III)4)d)',
    name: 'PH:',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)4)d)1/': {
        name: '< 7',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)4)d)2/': {
        name: '> 8,5',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)4)e)': {
    id: 'C)III)4)e)',
    name: 'Sang ( activité peroxydasique) (/µl):',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)4)e)1/': {
        name: 'Négatif',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)4)e)2/': {
        name: '+',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)4)e)3/': {
        name: '++',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)4)e)4/': {
        name: '+++',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)4)f)': {
    id: 'C)III)4)f)',
    name: 'Densité:',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)4)f)1/': {
        name: '< 1,005',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)4)f)2/': {
        name: '> 1,045',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)4)g)': {
    id: 'C)III)4)g)',
    name: 'Corps cétoniques(g/l):',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)4)g)1/': {
        name: 'Négatif',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)4)g)2/': {
        name: '0,05',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)4)g)3/': {
        name: '0,15',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)4)g)4/': {
        name: '0,4',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)4)g)5/': {
        name: '0,8',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)4)g)6/': {
        name: '1,6',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)4)h)': {
    id: 'C)III)4)h)',
    name: 'Glucose(g/l):',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)4)h)1/': {
        name: 'Négatif',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)4)h)2/': {
        name: '1',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)4)h)3/': {
        name: '2,5',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)4)h)4/': {
        name: '5',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)4)h)5/': {
        name: '10',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)4)h)6/': {
        name: '> 20',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)4)i)': {
    id: 'C)III)4)i)',
    name: 'Mise en culture, agents infectieux :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)4)i)1/': {
        name: 'Borrelia',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)4)i)2/': {
        name: 'Corynebacterium renale',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)4)i)3/': {
        name: 'Dénombrement de bactéries > 100 000 / mL',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'C)III)4)j)2.': {
    id: 'C)III)4)j)2.',
    idt4: 'C)III)4)j)',
    titre4: 'Biochimie :',
    name: 'Iode (en µg/l):',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)4)j)2.1/': {
        name: '< 50 (carence)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)4)j)2.2/': {
        name: '50 < < 100 (marginal)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)4)j)3.': {
    id: 'C)III)4)j)3.',
    name: 'Ca (en mmol/L) :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)4)j)3.1/': {
        name: '< 0,3',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)4)j)3.2/': {
        name: '> 3,2',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)4)j)4.': {
    id: 'C)III)4)j)4.',
    name: 'P (en mmol/L) :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)4)j)4.1/': {
        name: '> 3',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)4)j)5.': {
    id: 'C)III)4)j)5.',
    name: 'Mg (en mmol/L) :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)4)j)5.1/': {
        name: '< 9',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)4)j)5.2/': {
        name: '> 26',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)4)j)6.': {
    id: 'C)III)4)j)6.',
    name: 'K (en mmol/L) :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)4)j)6.1/': {
        name: '< 220',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)4)j)7.': {
    id: 'C)III)4)j)7.',
    name: 'Na (en mmol/L) :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)4)j)7.1/': {
        name: '< 12',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)4)j)8.': {
    id: 'C)III)4)j)8.',
    name: 'Cl (en mmol/L) :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)4)j)8.1/': {
        name: '< 30',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'C)III)5)a)1.': {
    id: 'C)III)5)a)1.',
    idt3: 'C)III)5)',
    titre3: 'Les bouses :',
    idt4: 'C)III)5)a)',
    titre4: 'Diarrhées néonatales :',
    name: 'Parasitologie :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'C)III)5)a)1.1/': {
        name: 'Coccidies',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)a)1.2/': {
        name: 'Cryptosporidium',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)a)1.3/': {
        name: 'E. bovis',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)a)1.3bis/': {
        name: 'E. zuernii',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)a)1.3ter/': {
        name: 'E alabamensis',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)a)1.4/': {
        name: 'Giardia',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)a)1.5/': {
        name: 'Buxtonella',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)5)a)2.': {
    id: 'C)III)5)a)2.',
    name: 'Bactériologie :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'C)III)5)a)2.1/': {
        name: 'Clostridium perfringens',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)a)2.2/': {
        name: 'Clostridium septicum',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)a)2.3/': {
        name: 'Clostridium sordellii',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)a)2.4/': {
        name: 'Campylobacter sp',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)a)2.5/': {
        name: 'Eschérichia coli',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)a)2.6/': {
        name: 'E.coli F5 ( = K99)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)a)2.6bis/': {
        name: 'E. coli CS31A',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)a)2.6ter/': {
        name: 'E . coli F17',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)a)2.6quater/': {
        name: 'E.coli producteur de shiga toxine (STEC)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)a)2.7/': {
        name: 'Salmonella spp',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)5)a)3.': {
    id: 'C)III)5)a)3.',
    name: 'Virologie :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'C)III)5)a)3.1/': {
        name: 'BVD',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)a)3.2/': {
        name: 'Calicivirus',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)a)3.3/': {
        name: 'Coronavirus',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)a)3.5/': {
        name: 'Rotavirus',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)a)3.6/': {
        name: 'Torovirus',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'C)III)5)b)1.': {
    id: 'C)III)5)b)1.',
    idt4: 'C)III)5)b)',
    titre4: 'Diarrhées du jeune bovin et de l’adulte :',
    name: 'Parasitologie :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'C)III)5)b)1.1/': {
        name: 'Toxocara (ascaris)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)b)1.1bis/': {
        name: 'Stongyloïdes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)b)1.2/': {
        name: 'Coccidies',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)b)1.4/': {
        name: 'Dicrocoelium',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)b)1.5/': {
        name: 'Dictyocaulus viviparus ( bronchite vermineuse)-',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)b)1.6/': {
        name: 'E. bovis',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)b)1.6bis/': {
        name: 'E. zuernii',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)b)1.6ter/': {
        name: 'E alabamensis',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)b)1.7/': {
        name: 'Fasciola hepatica',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)b)1.9/': {
        name: 'Nématodirus',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)b)1.12/': {
        name: 'Paramphistomum',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)b)1.13/': {
        name: 'Strongles gastro-intestinaux ( Ostertagia, Cooperia , …)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)b)1.15/': {
        name: 'Moniezia ( tenia)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)b)1.17/': {
        name: 'Trichuris ( trichures )',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)5)b)2.': {
    id: 'C)III)5)b)2.',
    name: 'Bactériologie :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: true,
    answers: {
      'C)III)5)b)2.1/': {
        name: 'Clostridium chauvoei',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)b)2.2/': {
        name: 'Clostridium bifermentans',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)b)2.3/': {
        name: 'Clostridium perfringens',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)b)2.4/': {
        name: 'Clostridium septicum',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)b)2.5/': {
        name: 'Clostridium sordellii',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)b)2.6/': {
        name: 'Clostridium sporogenes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)b)2.7/': {
        name: 'Mycobacteérium avium subsp. paratuberculosis (Map)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)b)2.9/': {
        name: 'Salmonella spp',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)b)2.10/': {
        name: 'Listeria monocytogenes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)b)2.11/': {
        name: 'Yersinia',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)5)b)2.12/': {
        name: 'Campylobacter',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)5)b)3.': {
    id: 'C)III)5)b)3.',
    name: 'Virologie :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)5)b)3.1/': {
        name: 'Coronavirus (dysenterie d’hiver)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'C)III)6)a)': {
    id: 'C)III)6)a)',
    idt3: 'C)III)6)',
    titre3: 'Le sperme :',
    idt4: 'C)III)6)a)',
    titre4: 'Observation microscopique :',
    autopass: true,
    interdire: true,
  },

  'C)III)6)a)1.': {
    id: 'C)III)6)a)1.',
    name: 'Motilité massale :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)6)a)1.1/': {
        name: '3 < < 5',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)6)a)1.2/': {
        name: '1 < < 2',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)6)a)1.3/': {
        name: '0 < < 0,5',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)6)a)2.': {
    id: 'C)III)6)a)2.',
    name: 'Motilité individuelle :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)6)a)2.1/': {
        name: '40% < < 100%',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)6)a)2.2/': {
        name: '10% < < 40%',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)6)a)2.3/': {
        name: '< 10%',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)6)a)3.': {
    id: 'C)III)6)a)3.',
    name: 'Numération (nb. de spz. par mm3):',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)6)a)3.1/': {
        name: '> 300.000',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)6)a)3.2/': {
        name: '10.000 < < 300.000',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)6)a)3.3/': {
        name: '< 10.000',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)6)a)4.': {
    id: 'C)III)6)a)4.',
    name: 'Anomalies :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)6)a)4.1/': {
        name: '< 25%',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)6)a)4.2/': {
        name: '25% < < 40%',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)6)a)4.3/': {
        name: '> 40%',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)6)a)5.': {
    id: 'C)III)6)a)5.',
    name: 'Morts :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)6)a)5.1/': {
        name: '< 40%',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)6)b)': {
    id: 'C)III)6)b)',
    name: 'Bactériologie :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)6)b)1/': {
        name: 'Brucella',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)6)b)2/': {
        name: 'Campylobacter fœtus',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)6)b)3/': {
        name: 'Chlamydia',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)6)b)4/': {
        name: 'Trichomonas foetus',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)6)c)': {
    id: 'C)III)6)c)',
    name: 'Test de Schalm :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)6)c)1/': {
        name: '0 (négatif : le réactif est fluide, de couleur violette)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)6)c)2/': {
        name: '1 (Schalm 1+ : présence de grumeaux fugaces, mélange fluide)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)6)c)3/': {
        name: '2 (Schalm 2+ : présence de grumeaux nets persistants, mélange encore fluide)',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)6)c)4/': {
        name: '3 (Schalm 3+ : présence de gros grumeaux, mélange visqueux, consistance du blanc d’œuf )',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)6)c)5/': {
        name: '4 (Schalm 4+ : prise en masse du mélange )',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },

    },
  },
  'C)III)7)a)': {
    id: 'C)III)7)a)',
    idt3: 'C)III)7)',
    titre3: 'Les sécrétions :',
    name: 'Contenu ruminal :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)7)a)1/': {
        name: 'PH < 5.5',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)a)2/': {
        name: 'PH > 7',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)a)3/': {
        name: 'Buis',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)a)4/': {
        name: 'Glands',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)a)5/': {
        name: 'Morelle',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)a)6/': {
        name: 'Renoncule',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)a)7/': {
        name: 'Verâtre',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)a)8/': {
        name: 'Mercuriale',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)a)9/': {
        name: 'Amaranthe',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)7)c)': {
    id: 'C)III)7)c)',
    name: 'Liquide de lavage du fourreau :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)7)c)1/': {
        name: 'Campylobacter foetus',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)c)2/': {
        name: 'Chlamydia',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)c)3/': {
        name: 'Trichomonas foetus',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)c)4/': {
        name: 'Coxiella',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)7)d)': {
    id: 'C)III)7)d)',
    name: 'Liquide d’aspiration trachéale ou broncho-alvéolaire :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)7)d)1/': {
        name: 'Kyste hydatique',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)d)2/': {
        name: 'Mycobactérium bovis',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)d)3/': {
        name: 'Salmonella',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)d)4/': {
        name: 'Mannheimia haemolytica',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)d)5/': {
        name: 'Pasteurella multocida',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)d)6/': {
        name: 'Histophilus somni',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)d)7/': {
        name: 'Mycoplasma bovis',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)d)8/': {
        name: 'Mycoplasma spp',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)d)9/': {
        name: 'Trueperella pyogenes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)d)10/': {
        name: 'VRSB',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)d)11/': {
        name: 'PI3',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)d)12/': {
        name: 'Coronavirus',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)d)13/': {
        name: 'Influenza D',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)d)14/': {
        name: 'BVD',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)d)15/': {
        name: 'IBR',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)d)16/': {
        name: 'BoHV4',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)7)e)': {
    id: 'C)III)7)e)',
    name: 'Mucus ou liquide utérin :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)7)e)1/': {
        name: 'Trueperella pyogenes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)e)2/': {
        name: 'Bacillus sp.',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)e)3/': {
        name: 'Campylobacter foetus',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)e)5/': {
        name: 'Coxiella burnetii',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)e)6/': {
        name: 'Eschérichia coli',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)e)7/': {
        name: 'Fusobacterium necrophorum',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)e)8/': {
        name: 'Trichomonas fœtus',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)e)9/': {
        name: 'Histophilus somni',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)7)f)': {
    id: 'C)III)7)f)',
    name: 'Mucus nasal :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)7)f)1/': {
        name: 'Kyste hydatique',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)f)2/': {
        name: 'Mycobactérium bovis',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)f)3/': {
        name: 'Salmonella',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)f)4/': {
        name: 'Mannheimia haemolytica',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)f)5/': {
        name: 'Pasteurella multocida',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)f)6/': {
        name: 'Histophilus somni',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)f)7/': {
        name: 'Mycoplasma bovis',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)f)8/': {
        name: 'Mycoplasma spp',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)f)9/': {
        name: 'Trueperella pyogenes',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)f)10/': {
        name: 'VRSB',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)f)11/': {
        name: 'PI3',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)f)12/': {
        name: 'Coronavirus',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)f)13/': {
        name: 'Influenza D',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)f)14/': {
        name: 'BVD',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)f)15/': {
        name: 'IBR',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)f)16/': {
        name: 'BoHV4',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
  'C)III)7)g)': {
    id: 'C)III)7)g)',
    name: 'Mucus vaginal :',
    description: '',
    interdire: true,
    veto: false,
    imposer: false,
    poserMaintenant: false,
    hideQuestion: false,
    multipleAnswer: false,
    answers: {
      'C)III)7)g)1/': {
        name: 'Brucella',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)g)2/': {
        name: 'Campylobacter fœtus',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
      'C)III)7)g)3/': {
        name: 'Trichomonas foetus',
        description: '',
        image: '',
        interdire: false,
        veto: false,
        imposer: false,
        checked: false,
        showNextQuestion: false,
      },
    },
  },
};
