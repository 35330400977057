import { View } from 'react-native';
import { Text, useTheme } from '@ui-kitten/components';
import { Icon as IconUIKitten } from '@ui-kitten/components/ui/icon/icon.component';
import React from 'react';

import FCard from '../Card';
import { StatusBse } from '../../src/API';

type PrebilanCardProps = {
  linkTo?: () => void
  prebilanStatus?: StatusBse
  prebilanYear?: boolean
};

const PrebilanCard = ({ linkTo, prebilanStatus, prebilanYear }: PrebilanCardProps) => {
  const theme = useTheme();
  return (
    <FCard
      onPress={linkTo}
      style={{
        marginTop: 10, padding: 0,
      }}
    >
      <View style={{
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        borderBottomWidth: 1,
        borderBottomColor: '#b5b5b5',
        padding: 15,
      }}
      >
        <View style={{ flex: 1 }}>
          <Text category="h4">Pré - Bilan</Text>
          <Text category="h6" appearance="hint">{prebilanYear || ''}</Text>
        </View>
        <IconUIKitten
          name="arrow-ios-forward-outline"
          fill="#000000"
          style={{
            height: 24, width: 24,
          }}
        />
      </View>

      {prebilanStatus !== StatusBse.preReviewStarted ? (
        <View style={{
          flex: 1, marginVertical: 10, flexDirection: 'row', justifyContent: 'center',
        }}
        >
          <IconUIKitten
            name="checkmark-circle-2"
            fill={theme['realised-color']}
            style={{ height: 20, width: 20, marginRight: 5 }}
          />
          <Text category="h6" status="primary">Réalisé</Text>
        </View>
      )
        : (
          <View style={{
            flex: 1, marginVertical: 10, flexDirection: 'row', justifyContent: 'center',
          }}
          >
            <IconUIKitten
              name="alert-circle"
              fill="orange"
              style={{ height: 20, width: 20, marginRight: 5 }}
            />
            <Text category="h6" status="primary">En attente de réalisation</Text>
          </View>
        )}

    </FCard>
  );
};

export default PrebilanCard;
