// @flow
import * as React from 'react';
import {
  Dimensions,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View, ViewStyle,
} from 'react-native';

// import {StyleGuide, type StyleProps} from "./theme/index";
import { Icon, Text } from '@ui-kitten/components';
// import Text from './Text';
// import Icon from "./Icon/index";
// import {withBreakpoints} from "../Helpers/Breakpoints";

export type SheetProps = {
  title?: string,
  subtitle?: string,
  children: React.ReactNode,
  customStyle?: ViewStyle,
  noSafeArea: boolean,
  scrollable: boolean,
  rightAction?: {
    label: string,
    onPress: () => void
  },
  // toggle: () => mixed
};

const Sheet = (props: SheetProps) => {
  const {
    toggle, title,
    //  subtitle, rightAction,
    noSafeArea = false, scrollable = false, customStyle,
    children,
  } = props;
  const finalChildren = noSafeArea
    ? children
    : <SafeAreaView>{children}</SafeAreaView>;

  const computedContentStyle = {};

  return (
    <View style={[styles.content, computedContentStyle, customStyle]}>
      <TouchableWithoutFeedback onPress={toggle} style={{ flexShrink: 0, flex: 0 }}>
        <View style={styles.header}>
          <TouchableOpacity style={styles.left} onPress={toggle}>
            <Icon name="arrow-ios-downward-outline" fill="black" style={{ width: 36, height: 36 }} />
            {!title && <Text category="p1" style={{ marginTop: 6 }}> Retour à la page précédente</Text>}
          </TouchableOpacity>
          {title && (<View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}><Text category="h3">{title}</Text></View>)}
        </View>
      </TouchableWithoutFeedback>
      {scrollable ? (<ScrollView bounces={false}>{finalChildren}</ScrollView>) : finalChildren}
    </View>
  );
};

export default Sheet;

const { height } = Dimensions.get('window');
const styles = StyleSheet.create({
  content: {
    backgroundColor: '#fff',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    flex: 1,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    // paddingHorizontal: StyleGuide.spacing.small,
    // paddingVertical: StyleGuide.spacing.tiny,
    borderBottomWidth: 1,
    borderColor: '#f5f5f5',
    height: 40,
  },
  left: {
    flexDirection: 'row',
    position: 'absolute',
  },
  center: {
    flex: 1,
  },
  title: {
    textAlign: 'center',
  },
  right: {
    minWidth: 36,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});
