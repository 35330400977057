import { Platform, View } from 'react-native';
import { Text, useTheme } from '@ui-kitten/components';

import React from 'react';
import { useDimensions } from '@react-native-community/hooks';

import FCard from '../components/Card';
import Button from '../components/Button';
import ThinSeparator from '../components/ThinSeparator';
import { Animals } from '../src/API';
import DateUtils from '../utils/DateUtils';
import DocumentComponent from '../components/Document/DocumentComponent';
import { DocumentItem } from '../src/API/Document';

type DetailsScreenHeaderCardProps = {
  title1: string, titleInfo1?: string | number | null,
  title2?: string | undefined, titleInfo2?: string | number | string[] | null,
  title3?: string | undefined | null, titleInfo3?: string | string[] | number | null | undefined | DocumentItem,
  title4?: string | undefined | null, titleInfo4?: string | number | string[] | null,
  title5?: string | null | undefined, titleInfo5?: string | number | null | undefined,
  title6?: string | null | undefined, titleInfo6?: string | null | undefined,
  title7?: string | null | undefined, titleInfo7?: string | null | undefined | boolean,
  title8?: string | null | undefined, titleInfo8?: string | null | undefined | DocumentItem,
  title9?: string, titleInfo9?: string | number | null | undefined,
  title10?: string, titleInfo10?: string,
  buttonText?: string,
  secondButtonText?: string,
  customLinkTo?: () => void,
  secondLinkTo?: () => void
  animals?: Animals | undefined | null,
};

const DetailsScreensHeaderCard = (props: DetailsScreenHeaderCardProps) => {
  const {
    animals,
    title1, titleInfo1,
    title2, titleInfo2,
    title3, titleInfo3,
    title4, titleInfo4,
    title5, titleInfo5,
    title6, titleInfo6,
    title7, titleInfo7,
    title8, titleInfo8,
    title9, titleInfo9,
    title10, titleInfo10,
    buttonText,
    secondButtonText,
    customLinkTo,
    secondLinkTo,
  } = props;
  const theme = useTheme();
  const { window } = useDimensions();

  return (
    <FCard style={{
      marginVertical: 20,
    }}
    >
      <View style={Platform.OS === 'web' && window.width > 780 && ({ flexDirection: 'row' })}>
        <View style={{ flex: 1, flexDirection: 'column' }}>
          <Text category="h6" appearance="hint">{title1}</Text>
          <Text category="h4">{titleInfo1}</Text>
        </View>

        {title2 && (
        <View style={[
          Platform.OS !== 'web' && ({
            marginTop: 20, paddingTop: 20, borderTopWidth: 1, borderTopColor: '#b5b5b5',
          }),
          Platform.OS === 'web' && ({ flex: 1 }),
          Platform.OS === 'web' && window.width < 780 && ({
            flex: 1, marginTop: 20, paddingTop: 20, borderTopWidth: 1, borderTopColor: '#b5b5b5',
          }),
        ]}
        >
          <Text category="h6" appearance="hint">{title2}</Text>
          <Text category="h4">{titleInfo2}</Text>
        </View>
        )}

      </View>

      {/** */}
      {title3 && (
      <View style={[
        Platform.OS === 'web' && window.width > 780 && ({ flexDirection: 'row' }),
        {
          marginTop: 20, paddingTop: 20, borderTopWidth: 1, borderTopColor: '#b5b5b5',
        },
      ]}
      >
        <View style={{
          flex: 1, flexDirection: 'column',
        }}
        >
          <Text category="h6" appearance="hint">{title3}</Text>
          {titleInfo3 && typeof titleInfo3 === 'object' ? (
            <DocumentComponent
              key={titleInfo3.id}
              document={titleInfo3}
              telecharger
              customTelechargerSTYLE={{
                marginLeft: -5,
                marginTop: -10,
                paddingBottom: -15,
                paddingLeft: -5,
              }}
            />
          )
            : <Text category="h4">{titleInfo3}</Text>}
        </View>
        {title4 && (
        <View style={[
          Platform.OS !== 'web' && ({
            marginTop: 20, paddingTop: 20, borderTopWidth: 1, borderTopColor: '#b5b5b5',
          }),
          Platform.OS === 'web' && ({ flex: 1 }),
          Platform.OS === 'web' && window.width < 780 && ({
            flex: 1, marginTop: 20, paddingTop: 20, borderTopWidth: 1, borderTopColor: '#b5b5b5',
          }),
        ]}
        >
          <Text category="h6" appearance="hint">{title4}</Text>
          {(titleInfo4 || animals) && (
          <Text category="h4">{`${(animals !== undefined ? DateUtils.getAge(animals) : '') || titleInfo4}`}</Text>
          )}

        </View>
        )}

      </View>
      )}

      {/** */}
      {title5 && (
      <View style={[
        Platform.OS === 'web' && window.width > 780 && ({ flexDirection: 'row' }),
        {
          marginTop: 20, paddingTop: 20, borderTopWidth: 1, borderTopColor: '#b5b5b5',
        },
      ]}
      >
        <View style={{
          flex: 1, flexDirection: 'column',
        }}
        >
          <Text category="h6" appearance="hint">{title5}</Text>
          <Text category="h4">{titleInfo5}</Text>
        </View>

        {title6 && (
        <View style={[
          Platform.OS !== 'web' && ({
            marginTop: 20, paddingTop: 20, borderTopWidth: 1, borderTopColor: '#b5b5b5',
          }),
          Platform.OS === 'web' && ({ flex: 1 }),
          Platform.OS === 'web' && window.width < 780 && ({
            flex: 1, marginTop: 20, paddingTop: 20, borderTopWidth: 1, borderTopColor: '#b5b5b5',
          }),
        ]}
        >
          <Text category="h6" appearance="hint">{title6}</Text>
          <Text category="h4">{titleInfo6 && titleInfo6}</Text>
        </View>
        )}

      </View>
      )}

      {/** */}
      {(title7 || title8) && (
      <View style={[
        Platform.OS === 'web' && window.width > 780 && ({ flexDirection: 'row' }),
        {
          marginTop: 20, paddingTop: 20, borderTopWidth: 1, borderTopColor: '#b5b5b5',
        },
      ]}
      >
        {title7 && (
        <View style={{
          flex: 1, flexDirection: 'column',
        }}
        >
          <Text category="h6" appearance="hint">{title7}</Text>
          <Text category="h4">{titleInfo7}</Text>
        </View>
        )}

        {title8 && (
        <View style={[
          Platform.OS !== 'web' && ({
            marginTop: 20, paddingTop: 20, borderTopWidth: 1, borderTopColor: '#b5b5b5',
          }),
          Platform.OS === 'web' && ({ flex: 1 }),
          Platform.OS === 'web' && window.width < 780 && ({
            flex: 1, marginTop: 20, paddingTop: 20, borderTopWidth: 1, borderTopColor: '#b5b5b5',
          }),
        ]}
        >
          <Text category="h6" appearance="hint">
            {title8}
          </Text>
          {titleInfo8 && typeof titleInfo8 === 'object' ? (
            <DocumentComponent
              key={titleInfo8.id}
              document={titleInfo8}
              telecharger
              customTelechargerSTYLE={{
                marginLeft: -5,
                marginTop: -10,
                paddingBottom: -15,
                paddingLeft: -5,
              }}
            />
          )
            : <Text category="h4">{titleInfo8}</Text>}
        </View>
        )}

      </View>
      )}

      {/** */}
      {title9 && (
      <View style={[
        Platform.OS === 'web' && window.width > 780 && ({ flexDirection: 'row' }),
        {
          marginTop: 20, paddingTop: 20, borderTopWidth: 1, borderTopColor: '#b5b5b5',
        },
      ]}
      >
        <View style={{
          flex: 1, flexDirection: 'column',
        }}
        >
          <Text category="h6" appearance="hint">{title9}</Text>
          {titleInfo9 === 'Télécharger'
            ? (<Button status="primary" leftIcon="download-outline" leftIconFill={theme['color-primary-500']} appearance="ghost">Télécharger</Button>)
            : (<Text category="h4">{titleInfo9}</Text>)}

        </View>

        {title10 && (
        <View style={[
          Platform.OS !== 'web' && ({
            marginTop: 20, paddingTop: 20, borderTopWidth: 1, borderTopColor: '#b5b5b5',
          }),
          Platform.OS === 'web' && ({ flex: 1 }),
          Platform.OS === 'web' && window.width < 780 && ({
            flex: 1, marginTop: 20, paddingTop: 20, borderTopWidth: 1, borderTopColor: '#b5b5b5',
          }),
        ]}
        >
          <Text category="h6" appearance="hint">{title10}</Text>
          <Text category="h4">{titleInfo10}</Text>
        </View>
        )}

      </View>
      )}
      {/**

      {animals && (
      <View style={{
        marginTop: 20, paddingTop: 20, borderTopWidth: 1, borderTopColor: '#b5b5b5',
      }}
      >
        map it since there can be more than 1
        <Text category="h6" appearance="hint">Bâtiment(s) et zone(s)</Text>
        <Text category="h4">{`${animals?.building?.nameBuilding} `}</Text>
        <Text category="h5">{`Numéro de zone: ${animals?.zone?.numberZone}`}</Text>

        <Text category="h4" style={{ marginTop: 10 }}>{`${animals?.building?.nameBuilding} `}</Text>
        <Text category="h5">{`Numéro de zone: ${animals?.zone?.numberZone}`}</Text>
      </View>
      )}

      */}

      {buttonText && (
      <>
        <ThinSeparator />
        <View style={Platform.OS === 'web' && { flexDirection: 'row', justifyContent: 'center' }}>
          <Button
            rightIcon="arrow-ios-forward-outline"
            rightIconFill={theme['color-info-500']}
            appearance="outline"
            style={{ alignSelf: 'center' }}
            onPress={customLinkTo}
          >
            {buttonText}
          </Button>
          {secondButtonText && (
          <Button
            rightIcon="arrow-ios-forward-outline"
            rightIconFill={theme['color-info-500']}
            appearance="outline"
            style={[
              { alignSelf: 'center' },
              Platform.OS !== 'web' ? ({ marginTop: 10 }) : ({ marginLeft: 20 }),
            ]}
            onPress={secondLinkTo}
          >
            {secondButtonText}
          </Button>
          )}
        </View>

      </>

      )}

    </FCard>
  );
};

export default DetailsScreensHeaderCard;
