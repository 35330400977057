import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Text } from '@ui-kitten/components';
import { Platform, View } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import { ImagePickerResult } from 'expo-image-picker';
import { useUser } from '../../src/API/UserContext';
import MaxWidthContainer from '../../components/MaxWidthContainer';
import Form from '../../components/Form/Form';
import { AvailableValidationRules } from '../../components/Form/validation';
import Button from '../../components/Button';
import TextInputComp from '../../components/Form/TextInput';
import AutoAvatar from '../../components/AutoAvatar';
import { CameraOutput } from '../../components/Camera/Camera';
import { Delete, Upload } from '../../utils/S3FileStorage';

type ModifyInfoPersoForm = {
  firstname: string;
  lastname: string;
};

const ModifyEmailScreen = () => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);

  const { user, updateUser } = useUser();

  /** AVATAR PHOTO MANAGEMENT   */
  const [selectedNewImage, setSelectedNewImage] = useState<
  ImagePickerResult |
  CameraOutput |
  undefined
  >();

  const [image, setImage] = useState('default::ManAvatar');

  /** AVATAR PHOTO MANAGEMENT   */

  const pickImage = async () => {
    try {
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
      });
      if (!result.cancelled) {
        setImage(result.uri);
        setSelectedNewImage(result);
      }
    } catch (e) {
      // console.log('pickImage error: ', e);
    }
  };
  useEffect(() => {
    setImage(user?.avatarUri || 'default::ManAvatar');
  }, [user]);

  /** UPDATE USER */
  const onPress = async (data: ModifyInfoPersoForm) => {
    if (user) {
      let iconUri = image;
      if (image !== user.avatarUri) {
        // console.log('passage 1');
        const toDelete = user.avatarUri && user.avatarUri.indexOf('default::') > -1
          ? undefined
          : user.avatarUri;
        // console.log('passage : ', toDelete);
        if (toDelete) {
          // console.log('passage 2');

          await Delete(toDelete);
        }
        if (selectedNewImage) {
          const upload = await Upload(selectedNewImage, `avatarUser/${user.id}/`);
          // console.log('upload 1', upload);

          if (upload !== false) {
            iconUri = upload.key;
          }
        }
      }

      if (data && updateUser) {
        await updateUser({
          avatarUri: iconUri,
          // eslint-disable-next-line no-underscore-dangle
          _version: user._version,
          firstname: data.firstname,
          lastname: data.lastname,
          email: user.email,
        });
      }

      navigation.navigate('mon-compte');
    }
  };

  const modifyInfoPersoForm = useForm<ModifyInfoPersoForm>();

  return (
    <MaxWidthContainer
      withScrollView="keyboardAware"
      outerViewProps={{
        showsVerticalScrollIndicator: false,
        style: { padding: 20 },
      }}
    >
      <Form<ModifyInfoPersoForm> {...modifyInfoPersoForm} defaultValues={user}>
        <>
          <View style={{
            alignItems: 'center',
            marginTop: 10,
            marginBottom: 34,
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
          >
            <AutoAvatar
              avatarInfo={image}
              // avatarInfo={currentUser?.avatarUri || 'default::ManAvatar'}
              style={{
                height: 146, width: 146, borderRadius: 73, overflow: 'hidden', marginLeft: Platform.OS === 'web' ? 0 : 0, marginRight: 0,
              }}
            />
            <View style={{ flexDirection: 'column', justifyContent: 'space-between', marginVertical: 7 }}>

              <Button
                status="primary"
                leftIcon="plus-outline"
                leftIconFill="#1F6C79"
                appearance="ghost"
                onPress={() => { pickImage(); }}
              >
                Ajouter une photo
              </Button>

              {image.indexOf('default::') <= -1 && (
              <Button
                status="primary"
                leftIcon="close-outline"
                leftIconFill="#1F6C79"
                appearance="ghost"
                onPress={() => {
                  setImage('default::ManAvatar');
                  // setSelectedNewImage(undefined);
                }}
              >
                Supprimer la photo
              </Button>

              )}
            </View>
          </View>
          <View style={Platform.OS === 'web' && ({ flexDirection: 'row' })}>
            <TextInputComp
              name="firstname"
              label="Prénom"
              placeholder="Saisissez votre prénom"
              style={[Platform.OS === 'web' && ({ marginRight: 20, marginBottom: 20 })]}
              validators={[
                AvailableValidationRules.required,
              ]}
            />
            <TextInputComp
              name="lastname"
              label="Nom"
              placeholder="Saisissez votre nom"
              validators={[
                AvailableValidationRules.required,
              ]}
            />
          </View>

          <Button
            loading={loading}
            loadingText="Chargement"
            onPress={modifyInfoPersoForm.handleSubmit(
              (data) => {
                setLoading(true);
                onPress(data);
              },
            )}
            disabled={user === null}
            style={{ marginTop: 40 }}
          >
            Valider
          </Button>
          <Text category="c1" appearance="hint">* champs obligatoire</Text>
        </>
      </Form>
    </MaxWidthContainer>
  );
};

export default ModifyEmailScreen;
