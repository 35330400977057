import React, {
  useContext, createContext, useState, useEffect,
} from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

const TutorialStateContext = createContext({});

const useTutorialState = () => useContext(TutorialStateContext);

const TutorialStateContextProvider = ({ children }) => {
  const [state, setState] = useState({});

  useEffect(() => {
    let isLoaded = true;
    (async () => {
      const keys = await AsyncStorage.getAllKeys();
      const result = await AsyncStorage.multiGet(keys.filter((key) => key.startsWith('HIDE_TUTO_')));
      if (isLoaded) {
        setState(result);
      }
    })();
    return () => {
      isLoaded = false;
    };
  }, []);

  const setHidden = async (videoId: string) => {
    const newState = { ...state, [`HIDE_TUTO_${videoId}`]: 'true' };
    setState(newState);
    await AsyncStorage.setItem(`HIDE_TUTO_${videoId}`, 'true');
  };

  return (
    <TutorialStateContext.Provider value={{ state, setHidden }}>
      {children}
    </TutorialStateContext.Provider>
  );
};

export { useTutorialState, TutorialStateContextProvider };
