/**
 * Création d'une celulle d'une ligne
 *
 * @author: David-Julian Buch
 */
import * as React from 'react';
import { FieldValues, FormProvider, Path, PathValue } from 'react-hook-form';
import _ from 'lodash';
import isDeepEqual from 'fast-deep-equal/react';
import { composeValidationRules, ValidationRuleConfig } from './validation';
import { useUpdateEffect } from '../../utils/CustomHooks';
import { PossibleFields } from './types';
import { getPaths } from '../../utils/ObjectHelper';
import { getChildrenNames } from './FormUtils';
import { UseFormReturn } from 'react-hook-form/dist/types';
import { Nullable } from '../../utils/typeHelpers';

type Props<T extends FieldValues> = {
  children: React.ReactNode;
  defaultValues?: Nullable<T> | null;
  defaultSection?: number;
} & UseFormReturn<T>;

export default function Form<T extends FieldValues>(
  props: Props<T> & { resetFunc?: (func: () => void) => void }
): JSX.Element {
  const { children, defaultValues, defaultSection, ...formProps } = props;
  const { register, unregister, formState, setValue, getValues, resetFunc, trigger } = formProps;
  const Inputs = React.useRef<PossibleFields[]>([]);
  const defaultValuesRef = React.useRef(defaultValues);
  const listRegisteredNamesForCurrentChildren: string[] = [];
  const resetFuncs: { (): void }[] = [];

  if (!isDeepEqual(defaultValuesRef.current, defaultValues)) {
    defaultValuesRef.current = defaultValues;
  }

  if (resetFunc) {
    resetFunc(() => {
      resetFuncs.map((func) => func());
    });
  }

  const registerMyInput = (
    name: Path<T>,
    label: string,
    validators: ValidationRuleConfig,
    setInitialValue = false,
    onChangeValue?: (value: FieldValues) => void
  ) => {
    if (name) {
      register(name, validators ? composeValidationRules(validators, getValues, label) : undefined);
      listRegisteredNamesForCurrentChildren.push(name);
      if (setInitialValue) {
        const initialValue = _.get(defaultValues, name);
        const currentValue = getValues(name);
        if (initialValue && !currentValue) {
          setValue(name, initialValue);
          if (onChangeValue) {
            onChangeValue(initialValue);
          }
        }
      }
    }
  };
  /**
   * Function registering all children in a recursive manner, allowing Form to have as many nested
   * components as required by the desired layout.
   *
   * All children having a name property are considered being inputs and so are registered by hook
   * form
   */
  const registerChildren = (innerChildren: React.ReactNode, setValues = false) => {
    (Array.isArray(innerChildren) ? [...innerChildren] : [innerChildren]).forEach(
      (child: React.ReactElement) => {
        if (child?.props?.name && child?.props?.name !== '') {
          registerMyInput(
            child.props.name,
            child.props.label ? child.props.label : child.props.placeholder,
            child.props.validators,
            setValues,
            child.props.onChangeValue
          );
          if (child?.props?.accessoryElement) {
            registerChildren(child.props.accessoryElement, setValues);
          }
        } else if (child?.props?.children) {
          registerChildren(child.props.children, setValues);
        } else if (Array.isArray(child)) {
          registerChildren(child, setValues);
        }
      }
    );
  };

  useUpdateEffect(() => {
    registerChildren(children);
  }, [register]);

  const currentChildrenNames = getChildrenNames(children);
  React.useEffect(() => {
    // lors du premier render on met les valeurs, sinon elles manquent
    registerChildren(children, true);
    // on verifie si on a pas des vieux champs qu'il faudrait supprimer
    const currentFields = getPaths(getValues()) as Path<T>[];
    const anciens = currentFields.filter((x) => !listRegisteredNamesForCurrentChildren.includes(x));
    unregister(anciens);
  }, [defaultValuesRef.current, JSON.stringify(currentChildrenNames)]);
  let index = -1;
  let keys = 1;

  /**
   * Function rendering all children in a recursive manner, allowing Form to have as many nested
   * components as required by the desired layout.
   *
   * All children having a name property are considered being inputs and so are registered by hook
   * form
   */
  const renderInput = (child: JSX.Element): React.ReactNode => {
    keys += 1;
    if (child?.props?.name && child?.props?.name !== '') {
      index += 1;
      const i = index;
      return React.createElement(child.type !== undefined ? child.type : React.Fragment, {
        ...child.props,
        ref: (e: PossibleFields) => {
          Inputs.current[i] = e;
          return e;
        },
        key: child.props.name,
        defaultValue: _.get(defaultValues, child.props.name),
        value: getValues(child.props.name),
        clearStateFunc: (func: () => void) => {
          resetFuncs.push(func);
        },
        onChangeValue: (v: PathValue<T, any>) => {
          setValue(child.props.name, v, {
            shouldValidate: true,
          });
          if (child.props.onChangeValue) {
            child.props.onChangeValue(v);
          }
        },
        onSubmitEditing: () => {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          Inputs.current[i + 1]
            ? Inputs.current[i + 1].focus()
            : Inputs.current[i] && Inputs.current[i].blur();
          if (child.props.onSubmitEditing) {
            child.props.onSubmitEditing();
          }
        },
        accessoryElement: child.props?.accessoryElement
          ? renderInput(child.props?.accessoryElement)
          : undefined,
        // bluronsubmit: false,
        error: _.get(formState.errors, child.props.name),
      });
    }
    if (child?.props?.children && !(typeof child?.props?.children === 'string')) {
      return React.createElement(child.type !== undefined ? child.type : React.Fragment, {
        ...child.props,
        key: keys,
        children: renderInputs(child.props.children),
      });
    }
    /* if (child?.props?.children?.props?.name && child?.props?.children?.props?.name !== ''
    ) {
      const myUniqueChild = child.props.children;
      return React.createElement(child.type !== undefined ? child.type : React.Fragment, {
        ...{
          ...child.props,
          key: keys,
          children: renderInput(myUniqueChild),
        },
      });
    } */
    if (Array.isArray(child)) {
      return React.createElement(child.type !== undefined ? child.type : React.Fragment, {
        ...child.props,
        key: keys,
        children: renderInputs(child),
      });
    }
    // @ts-ignore
    if (child) {
      return React.createElement(child.type !== undefined ? child.type : React.Fragment, {
        ...child.props,
        key: keys,
      });
    }
  };
  // eslint-disable-next-line max-len,@typescript-eslint/no-shadow
  const renderInputs = (children: React.ReactNode) =>
    (Array.isArray(children) ? [...children] : [children]).map((child) => renderInput(child));

  return <React.Fragment key="root-form">{renderInputs(children)}</React.Fragment>;
}
