import React, { useEffect } from 'react';

import { useLinkTo } from '@react-navigation/native';
import { Text } from '@ui-kitten/components';
import { Platform } from 'react-native';
import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';

import MaxWidthContainer from '../../components/MaxWidthContainer';

import TableauDeBordHeaderCard
  from '../../screenComponents/TableauDeBordScreenComps/TableauDeBordHeaderCard';
import ListTeleconsultationCards from '../../screenComponents/ListTeleconsultationCards';
import MonCabinetInfo from '../../screenComponents/TableauDeBordScreenComps/MonCabinetInfo';
import InfoCard from '../../screenComponents/InfoCard';
import { useUser } from '../../src/API/UserContext';
import { useBreedingList } from '../../src/API/Breeding';

import { useUpdateUser } from '../../src/API/User';
import FactureSectionComp from '../../screenComponents/FactureSectionComp';
import Tutorial from '../../components/Tutorial';

export default function TableauDeBordScreen() {
  const linkTo = useLinkTo();
  const { user } = useUser();
  const { modifyUser } = useUpdateUser();
  const { breeding } = useBreedingList(user?.userGroup);

  /** NOTIFICATIONS */
  useEffect(() => {
    (async () => {
      if (Platform.OS !== 'web' && Constants.isDevice && user) {
        const { status: existingStatus } = await Notifications.getPermissionsAsync();
        // console.log('existingStatus', existingStatus);
        let finalStatus = existingStatus;
        if (existingStatus !== 'granted') {
          const { status } = await Notifications.requestPermissionsAsync();
          finalStatus = status;
        }
        if (finalStatus === 'granted') {
          const token = (await Notifications.getExpoPushTokenAsync({ experienceId: '@djbuch/scanflock' })).data;
          // console.log('token', token);
          const newTokens = user?.expoToken || [];
          // console.log('newTokens', newTokens);
          if (newTokens.indexOf(token) <= -1) {
            newTokens.push(token);
            // const justUpdatedUser =
            await modifyUser({
              variables: {
                input: {
                  id: user.id,
                  // eslint-disable-next-line no-underscore-dangle
                  _version: user._version,
                  expoToken: newTokens,

                },
              },
            });
            // console.log(userUpdate);
          }
        }
        if (finalStatus !== 'granted') {
          // console.log('Failed to get push token for push notification!');
        }
        // console.log('finalStatus', finalStatus);
      }
    })();

    return () => {};
  }, [user]);

  return (
    <>
      <MaxWidthContainer
        withScrollView={"keyboardAware"}
        outerViewProps={{
          style: {
            paddingHorizontal: 20,
          },
        }}
      >

        <TableauDeBordHeaderCard elevagePage="elevagePage" />

        <Text category="h1" style={{ marginTop: 40 }}>Consultations</Text>

        {breeding && (
        <>
          <ListTeleconsultationCards limit={20} breeding={breeding} isTDB hide="tdbShow" />
          <MonCabinetInfo breeding={breeding} />
        </>
        )}

        <Text category="h1" style={{ marginTop: 40, marginBottom: 7 }}>Factures</Text>
        <Text category="h2" appearance="hint" style={{ marginVertical: 10 }}>Dernières factures</Text>
        {breeding && (
        <FactureSectionComp breeding={breeding} />
        )}
        <Text category="h1" style={{ marginTop: 40, marginBottom: 7 }}>Le Pré-bilan et le Bilan Sanitaire d’Elevage</Text>
        <Tutorial
          videoId="TXLkBKh2WGk"
        />
        <InfoCard
          title="alertInfo"
          description="La réalisation du BSE et du Protocole de Soins par votre véto à l’aide de l’application ScanFlock améliore sensiblement la qualité des résultats de vos auto-examens."
          imagePosition="left"
          imageUri={require('../../assets/veto_eleveur.png')}
          buttonText="Accéder au Pré-bilan et au BSE"
          buttonOnPress={() => { linkTo('/bilan-sanitaire'); }}
          infoStyle={{ padding: 20 }}
        />
        {Platform.OS !== 'web' && (

        <>
          <Text category="h1" style={{ marginTop: 40, marginBottom: 7 }}>Photo</Text>
          <InfoCard
            title="alertInfo"
            description="Vous pouvez faire parvenir des photos à notre application afin de participer à notre évolution pour votre confort. Notre objectif est de constituer la base de données, préalable à la fonctionnalité de reconnaissance d’images."
            description2="Photographiez tant que possible symptômes et lésions !
Vous pouvez également partager les photos prises en cours d’auto-examen avec votre véto."
            imagePosition="right"
            imageUri={require('../../assets/main_photo.png')}
            buttonText="Prendre une photo"
            buttonOnPress={() => { linkTo('/photo'); }}
            infoStyle={{ padding: 20 }}
          />
        </>
        )}

        <Text category="h1" style={{ marginTop: 40, marginBottom: 7 }}>FAQ</Text>
        <InfoCard
          title="alertInfo"
          description="Consultez notre FAQ afin d’obtenir une réponse aux questions les plus fréquemment posées à propos de l’application.
N’hésitez pas à nous contacter via le formulaire de contact de notre site scanflock.com"
          imagePosition="left"
          imageUri={require('../../assets/eleveur_faq.png')}
          buttonText="Consulter notre FAQ"
          buttonOnPress={() => { linkTo('/faq'); }}
          infoStyle={{ padding: 20 }}
        />

        <Text category="p1">
          v
          {Constants.expoConfig?.version || ''}
        </Text>
      </MaxWidthContainer>
    </>
  );
}
