export const templatePDF = `
<html><head>
<style type="text/css">
    * {
        font-size: 14px;
        
    }
    p {
    margin: 0;
}
    p.bigTitle {
        font-size: 26px;
        text-align: center;
        margin-bottom: 30px;
        font-weight: bold;
    }
    
    p.titre1 {
        font-size: 22px;
    margin-top: 20px;
    }
    
    p.question {
    margin-top: 16px;
    }
    
    p.titre2 {
        font-size: 18px;
    margin-top: 20px;
    }
    
    p.question, p.reponse, p.calcul {
        margin-left: 60px;
    }
    
    p.cats {
    margin-left: 70px;
    font-weight: bold;
}
    
    p.reponse.cats, p.calcul.cats {
    font-weight: normal;
    }
    
    p.titre3 {
        font-size: 16px;
        margin-left: 40px;
    margin-top: 20px;
    }
    
    p.titre4 {
        font-size: 14px;
        margin-left: 50px;
    margin-top: 10px;
    }
    @page {
    margin: 50px;
  }
</style></head><body class="pdfContainer">
<div  class="pdfContainer">
<div class="container">
  <p class="bigTitle">BSE    ET    PROTOCOLE    DE    SOINS<br/>COMPTE    RENDU</p>
  {{content}}
  <br/>
  <br/>
  <br/>
  <br/>
  <p class="bigTitle">PROTOCOLES    DE    SOINS<br/>SELECTIONNES</p>
  {{contentPS}}
</div>
</div>
</body></html>
`;
