import {
  Platform, TouchableOpacity, View,
} from 'react-native';
import { Icon as IconUIKitten } from '@ui-kitten/components/ui/icon/icon.component';
import { Modal, Text } from '@ui-kitten/components';
import React, { useState } from 'react';
import Button from './Button';
import BaseModal from './Modals/BaseModal';

export type InfosModalProps = {
  infoText: string
  infoTitle?: string
};

const InfoModal = (props: InfosModalProps) => {
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const { infoText, infoTitle = 'Informations sur la partie' } = props;

  return (
    <>
      <TouchableOpacity
        onPress={() => setOpenInfoModal(true)}
        style={{ marginLeft: 5, width: 20 }}
      >
        <IconUIKitten name="info-outline" fill="#2b4f9e" style={{ height: 20, width: 20 }} />
      </TouchableOpacity>
      <BaseModal
        visible={openInfoModal}
        onClose={() => setOpenInfoModal(false)}
      >
        <View
          style={{
            backgroundColor: 'white',
            borderRadius: 10,
            width: (Platform.OS !== 'web' ? ('75%') : ('42%')),
            justifyContent: 'space-between',
            overflow: 'hidden',
            padding: 20,
            alignItems: 'center',
          }}
        >
          <Text category="h2" style={{ textAlign: 'center' }}>{infoTitle}</Text>
          <Text style={{ marginVertical: 20 }}>
            {infoText}
          </Text>
          <Button style={{ width: 180 }} size="medium" onPress={() => setOpenInfoModal(false)}>Fermer</Button>
        </View>
      </BaseModal>
    </>
  );
};

export default InfoModal;
