import { LinkProps } from '../../../types';

const allerMonCompteScreen = ({ state, linkTo, signUp }: LinkProps) => {
  switch (state) {
    case 'mon cabinet':
      linkTo('/veto-mon-cabinet');
      break;
    case 'information cabinet':
      linkTo(`/veto-mon-cabinet/veto-modify-info-perso/${signUp}`);
      break;
    case 'mon compte':
      linkTo('/veto-mon-cabinet/veto-mon-compte');
      break;
    case 'utilisateurs':
      linkTo('/veto-mon-cabinet/veto-utilisateurs');
      break;
    case 'tarif-consultation':
      linkTo('/veto-mon-cabinet/veto-tarif-consultation');
      break;
    case 'facture':
      linkTo('/veto-mon-cabinet/veto-facture');
      break;
    case 'compte-bancaire':
      linkTo('/veto-mon-cabinet/infos-bancaires');
      break;

    default:
      break;
  }
};

export default {
  allerMonCompteScreen,
};
