/**
 * If you are not familiar with React Navigation, check out the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';

import * as React from 'react';
import { ColorSchemeName } from 'react-native';

import { Layout, Text } from '@ui-kitten/components';
import { StatusBar } from 'expo-status-bar';
import { useState } from 'react';
import Auth from '@aws-amplify/auth';
import ActivityIndicator from '../components/ActivityIndicator';
import InitialNavigator from './InitialNavigator';
import { useUser } from '../src/API/UserContext';
import LinkingConfiguration from './LinkingConfiguration';
import VetoLinkingConfiguration from './VetoLinkingConfiguration';
import FinalSignUpStackNavigator from './FinalSignUpStackNavigator';
import { LockedStatus } from '../src/API';
import LockedScreen from '../screens/LockedScreen';
import { getTitleFromName } from './Utils';
import AdminLinkingConfiguration from './AdminLinkingConfiguration';

export default function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }) {
  const {
    user, userIsLoading, userIsCreating, isVeto, isAdmin,
  } = useUser();
  // useAutoFileStorage();

  // console.log('authUser Navigation:', authUser);

  const Theme = colorScheme === 'dark' ? DarkTheme : DefaultTheme;
  // Theme.colors.background = '#fbfbfb';
  let linking = LinkingConfiguration;
  if (isVeto) {
    linking = VetoLinkingConfiguration;
  }
  if (isAdmin) {
    linking = AdminLinkingConfiguration;
  }

  return (
    userIsLoading
      ? (
        <Layout style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <Text category="h4" status="primary">Chargement des données</Text>
          <ActivityIndicator />
        </Layout>
      )
      : (
        <NavigationContainer
          linking={linking}
          theme={Theme}
          documentTitle={{
            formatter: (options, route) => `Scanflock | ${getTitleFromName(route?.name)}`,
          }}
          fallback={(
            <Layout style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
              <ActivityIndicator />
            </Layout>
          )}
        >
          <StatusBar />
          { user && !userIsCreating && (user.vetOffice || user.breeding || user.userGroup === 'admin')
            ? ((((user?.breeding && user.breeding.locked === LockedStatus.initialControlNeed)
                  || (user?.vetOffice && user.vetOffice.locked === LockedStatus.initialControlNeed))
              ? (<LockedScreen />)
              : (<InitialNavigator />))) : <FinalSignUpStackNavigator /> }
        </NavigationContainer>
      )

  );
}
