import React from 'react';
import MaxWidthContainer from '../../components/MaxWidthContainer';
import WebView from '../../components/WebView';

export default function FaqScreen() {
  return (
    <MaxWidthContainer
      outerViewProps={{
        style: {
          padding: 20,
          marginBottom: 20,
        },
      }}
      withScrollView={false}
    >
      <WebView
        src="https://scanflock.com/foire-aux-questions?rnapp=1"

      />
    </MaxWidthContainer>
  );
}
