import { View } from 'react-native';
import React from 'react';

import { Text } from '@ui-kitten/components';
import moment from 'moment';
import FCard from '../../components/Card';

import { useUser } from '../../src/API/UserContext';
import DateUtils from '../../utils/DateUtils';

moment.locale('fr');

const AdminTableauDeBordHeaderCard = () => {
  const { user } = useUser();

  return (
    <>

      <FCard style={{
        paddingHorizontal: 0,
        marginBottom: 30,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 25,
        borderBottomRightRadius: 25,
        paddingBottom: 0,
      }}
      >
        <View style={{
          paddingHorizontal: 20, paddingTop: 25, paddingBottom: 10,
        }}
        >
          <Text category="s1" appearance="hint">Bonjour !</Text>
          <Text style={{ fontSize: 39, fontFamily: 'sourceSansPro_Bold' }}>{`${user?.firstname || undefined} ${user?.lastname || undefined}`}</Text>
          <Text category="h2" appearance="hint" style={{ marginVertical: 20 }}>Votre programme</Text>
          <Text category="h3">{DateUtils.today}</Text>
        </View>
      </FCard>
    </>
  );
};

export default AdminTableauDeBordHeaderCard;
