import { View } from 'react-native';
import React from 'react';
import QuestionCard from '../components/CustomCards/QuestionCard';
import AnswerCard, { AnswerProps } from '../components/CustomCards/AnswerCard';
import ItemTitle from './ItemTitle';
import { QuestionaireDataItem } from './BilanSanitaireScreenComps/ListPrebilanBilan';
import { useUser } from '../src/API/UserContext';

type ListAutoExamenItemProps = {
  item: QuestionaireDataItem,
  modifyQuestionnaire: (questionId:string, answerId:string, currentChecked?: boolean) => void,
  onChecked: AnswerProps['onChecked'],
  setFunctionResult?: (result: string, idQuestion: string) => void;
  calculateResult?: (functionData: string) => void;
  getBgColor?: (questionId:string, answerId:string, value: string) => string | false;
};

const ListAutoExamenItem = (props: ListAutoExamenItemProps) => {
  const { isVeto } = useUser();
  const {
    item, modifyQuestionnaire, onChecked, setFunctionResult, calculateResult, getBgColor,
  } = props;

  if (item.showQuestion) {
    return (
      <>
        {item.autopass ? (
          <>
            {item.titre1 ? (
              <ItemTitle title={item.titre1} description={item.description} titleType="1" />
            ) : null}
            {item.titre2 ? (
              <ItemTitle title={item.titre2} description={item.description} titleType="2" />
            ) : null}
            {item.titre3 ? (
              <ItemTitle title={item.titre3} description={item.description} titleType="3" />
            ) : null}
            {item.titre4 ? (
              <ItemTitle title={item.titre4} description={item.description} titleType="4" />
            ) : null}
          </>
        ) : (
          <View>
            <QuestionCard
              question={item.name}
              hideQuestion={item.hideQuestion}
              description={item.description}
              titre1={item.titre1 ? item.titre1 : undefined}
              titre2={item.titre2 ? item.titre2 : undefined}
              titre3={item.titre3 ? item.titre3 : undefined}
              titre4={item.titre4 ? item.titre4 : undefined}
            />
            <AnswerCard
              answer={item.answers}
              multipleAnswer={item.multipleAnswer}
              locked={item.validate}
              readonly={item.readOnly === true}
              idQuestion={item.id}
              lastChecked={item.lastChecked}
              onChecked={onChecked}
              onModify={modifyQuestionnaire}
              setFunctionResult={setFunctionResult}
              calculateResult={calculateResult}
              getBgColor={getBgColor}
            />
          </View>
        )}

      </>
    );
  }
  return null;
};

const areEqual = (prevProps: ListAutoExamenItemProps, nextProps: ListAutoExamenItemProps) => {
  if (prevProps.item.validate === nextProps.item.validate
      && prevProps.item.lastChecked === nextProps.item.lastChecked
      && prevProps.item.showQuestion === nextProps.item.showQuestion
  ) {
    return true; // donot re-render
  }
  return false; // will re-render
};

export default React.memo(ListAutoExamenItem, areEqual);
