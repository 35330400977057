import React, { useState } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import {
  Text,
} from '@ui-kitten/components';
import { useLinkTo, useNavigation, useRoute } from '@react-navigation/native';
import { RouteProp } from '@react-navigation/core/lib/typescript/src/types';
import { useForm } from 'react-hook-form';
import API from '@aws-amplify/api';
import _ from 'lodash';
import { useDimensions } from '@react-native-community/hooks';
import TextInput from '../../components/Form/TextInput';
import MaxWidthContainer from '../../components/MaxWidthContainer';
import { TabMonCompteParamList } from '../../types';
import Form from '../../components/Form/Form';

import { AvailableValidationRules } from '../../components/Form/validation';
import { useUser } from '../../src/API/UserContext';
import Button from '../../components/Button';

type InscriptionEtape3Form = {
  privateProfile: {
    address?: {
      address: string;
      additionalAddress?: string | null;
      postalCode: string;
      city: string;
      country: string;
    } | null;
    birthDate?: string | null;
  }
};

{ /**

 component use while registration and as ajout élevage of veto BSE navigation !!!!!!!

 */ }
const VetoAjoutElevageScreen = () => {
  const navigation = useNavigation();
  const linkTo = useLinkTo();
  const { createUser, updateUser, user } = useUser();
  const [loading, setLoading] = useState(false);

  const { window } = useDimensions();

  const inscriptionEtape3Form = useForm<InscriptionEtape3Form>();
  const onPress = async (data: InscriptionEtape3Form) => {
    /**
         if (route.params?.signUp) {
      if (createUser) {
        const response = await API.post('omedomrest', '/budgetinsight/create-user', {});

        await createUser({
          privateProfile: {
            ...data.privateProfile,
            notificationParams: {
              soldeNegatif: {
                push: true,
                email: true,
              },
              creditBancaire: {
                push: true,
                email: true,
              },
              debitBancaire: {
                push: true,
                email: true,
              },
              loyer: {
                push: true,
                email: true,
              },
              retardLoyer: {
                push: true,
                email: true,
              },
              autre: {
                push: true,
                email: true,
              },
              echeanceFacture: {
                push: true,
                email: true,
              },
              mauvaiseRenta: {
                push: true,
                email: true,
              },
            },
          },
          biUser: response.id_user,
          biToken: response.auth_token,
        });

        navigation.navigate('modifier-info-3', {
          signUp: true,
        });
      }
    } else if (updateUser) {
      if (!user?.biUser) {
        const response = await API.post('omedomrest', '/budgetinsight/create-user', {});
        _.merge(data, {
          biUser: response.id_user,
          biToken: response.auth_token,
        });
      }

      await updateUser(data);
         */

    // linkTo('/veto-details-elevage');
  };

  return (
    <MaxWidthContainer
      withScrollView="keyboardAware"
      outerViewProps={{
        showsVerticalScrollIndicator: false,
      }}
      innerViewProps={{
        style: {
          paddingHorizontal: 24,
          paddingVertical: 34,
        },
      }}
    >
      <Form<InscriptionEtape3Form>
        {...inscriptionEtape3Form}
        defaultValues={user}
      >
        <>

          {/**        Nom d'élevage   , Adresse         */}
          <View style={Platform.OS === 'web' && ({ flexDirection: 'row' })}>
            <View style={{ flex: 1 }}>
              <TextInput
                title={"N° d'élevage"}
                name="nBreeding"
                placeholder="Saisissez votre numéro d'élevage"
                validators={[
                  AvailableValidationRules.required,
                ]}
              />
            </View>

            <View style={[{ flex: 1 },
              Platform.OS === 'web' && ({ marginLeft: 20 }),
              Platform.OS !== 'web' && ({ marginTop: 20 }),
            ]}
            >
              <Text>Adresse*</Text>
              <TextInput
                name="numberSiret"
                placeholder="Adresse"
                validators={[
                  AvailableValidationRules.required,
                ]}
              />
            </View>
          </View>

          {/**        Complèment d'adresse , Code Postal      */}
          <View style={[{ marginTop: 20 }, Platform.OS === 'web' && ({ flexDirection: 'row' })]}>
            <View style={{ flex: 1 }}>
              <Text>Complèment d'adresse</Text>
              <TextInput
                name="numberBreeding"
                placeholder="Saisissez le complèment d'adresse"
              />
            </View>

            <View style={[{ flex: 1 },
              Platform.OS === 'web' && ({ marginLeft: 20 }),
              Platform.OS !== 'web' && ({ marginTop: 20 }),
            ]}
            >
              <Text>Code Postal*</Text>
              <TextInput
                name="numberSiret"
                placeholder="Saisissez le code Postal"
                validators={[
                  AvailableValidationRules.required,
                ]}
              />
            </View>
          </View>

          {/**     Ville , N° d'élevage          */}
          <View style={[{ marginTop: 20 }, Platform.OS === 'web' && ({ flexDirection: 'row' })]}>
            <View style={{ flex: 1 }}>
              <Text>Ville*</Text>
              <TextInput
                name="city"
                placeholder="Saisissez la ville"
              />
            </View>

            <View style={[{ flex: 1 },
              Platform.OS === 'web' && ({ marginLeft: 20 }),
              Platform.OS !== 'web' && ({ marginTop: 20 }),
            ]}
            >
              <Text>N° d'élevage*</Text>
              <TextInput
                name="numberSiret"
                placeholder="Saisissez le numéro d'élevage"
                validators={[
                  AvailableValidationRules.required,
                ]}
              />
            </View>
          </View>

          {/**     Ville , N° d'élevage          */}
          <View style={[{ marginTop: 20 }, Platform.OS === 'web' && ({ flexDirection: 'row' })]}>
            <View style={{ flex: 1 }}>
              <Text>N° SIREN de l'exploitation*</Text>
              <TextInput
                name="city"
                placeholder="Saisissez le numéro SIREN"
              />
            </View>

          </View>

          <Text category="h5" style={{ marginTop: 40 }}>Effectif détaillé du cheptel*</Text>
          <Text category="h5" appearance="hint" style={{ marginVertical: 10 }}>Saisissez le nombre de bovins</Text>
          <View style={Platform.OS === 'web' && window.width > 780 && ({ flexDirection: 'row' })}>

            <TextInput
              name="cows"
              placeholder="Vaches"
              title="Vaches"
            />

            <TextInput
              name="privateProfile.address.postalCode"
              placeholder="Génisses"
              keyboardType="numeric"
              maxLength={5}
              title="Génisses"
              titleStyle={[Platform.OS === 'web' && window.width > 780 ? ({ marginLeft: 20 }) : ({ marginTop: 10 })]}
              style={Platform.OS === 'web' && window.width > 780 && ({ marginLeft: 20 })}
            />
            <TextInput
              name="sex"
              placeholder="Mâles"
              title="Mâles"
              titleStyle={[Platform.OS === 'web' && window.width > 780 ? ({ marginLeft: 20 }) : ({ marginTop: 10 })]}
              style={Platform.OS === 'web' && window.width > 780 && ({ marginLeft: 20 })}
            />
            <TextInput
              name="less12"
              placeholder="Moins de 12 mois"
              title="Moins de 12 mois"
              titleStyle={[Platform.OS === 'web' && window.width > 780 ? ({ marginLeft: 20 }) : ({ marginTop: 10 })]}
              style={Platform.OS === 'web' && window.width > 780 && ({ marginLeft: 20, minWidth: 200 })}
            />
          </View>

          <Button style={{ marginTop: 34 }} loading={loading} loadingText="Chargement" onPress={inscriptionEtape3Form.handleSubmit((data) => { setLoading(true); onPress(data); })}>
            Enregistrer l'élevage
          </Button>

          <Text category="c1" appearance="hint">
            * champs obligatoires
          </Text>
        </>
      </Form>

    </MaxWidthContainer>

  );
};

const styles = StyleSheet.create({
  title: {
    marginTop: 12,
    marginBottom: 20,
  },
});

export default VetoAjoutElevageScreen;
