export const typeGenderCustom = {
  Woman: {
    label: 'Femelle',
    key: 'Woman',
  },
  Man: {
    label: 'Mâle',
    key: 'Man',
  },
  Castrated: {
    label: 'Castré',
    key: 'Castrated',
  },
};
