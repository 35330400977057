import { AgoraVideoPlayer } from 'agora-rtc-react';
import * as React from 'react';
import { useContext } from 'react';
import { Icon as IconUIKitten } from '@ui-kitten/components/ui/icon/icon.component';
import { View } from 'react-native';
import { Text, Spinner } from '@ui-kitten/components';
import UidContext from './UidContext';
import { UidInterface, VideoMode } from './RtcContext';
import { useIsTablet } from '../../utils/CustomHooks';

export type VetoViewProps = {
  mode: VideoMode
};

export function VetoView(props:VetoViewProps) {
  const { mode } = props;

  const uids = useContext(UidContext);

  const isTabletFormat = useIsTablet();

  if (mode === VideoMode.VETERINAIRE) {
    const localUser: UidInterface = uids.filter(({ uid }) => uid === 'local').pop();
    if (localUser && localUser.hasVideo && localUser.videoTrack) {
      console.log('localUser', localUser);
      return (
        <AgoraVideoPlayer
          style={{
            width: '100%',
            height: '100%',
          }}
          config={{ fit: 'contain' }}
          videoTrack={localUser.videoTrack}
        />
      );
    }
    return (
      <View style={{
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
      }}
      >
        <IconUIKitten
          name="video-off-outline"
          style={{ width: 90, height: 90 }}
          fill="black"
        />
      </View>
    );
  }

  const remoteUser: UidInterface = uids.filter(({ uid }) => uid !== 'local').pop();
  if (remoteUser) {
    let MutedAudioIndicator = <></>;
    if (!remoteUser.hasAudio) {
      MutedAudioIndicator = (
        <View style={{
          position: 'absolute',
          top: 10,
          left: 10,
        }}
        >
          <IconUIKitten
            name="mic-off-outline"
            style={{ width: 20, height: 20 }}
            fill="red"
          />
        </View>
      );
    }
    if (remoteUser.hasVideo && remoteUser.videoTrack) {
      return (
        <>
          <AgoraVideoPlayer
            style={{
              width: '100%',
              height: '100%',
            }}
            config={{ fit: 'contain' }}
            videoTrack={remoteUser.videoTrack}
          />
          {MutedAudioIndicator}
        </>
      );
    }
    return (
      <>
        {MutedAudioIndicator}
        <View style={{
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
        }}
        >
          <IconUIKitten
            name="video-off-outline"
            style={{ width: 90, height: 90 }}
            fill="black"
          />
        </View>
      </>
    );
  }
  return (
    <>
      <Spinner status="primary" />
      <Text
        category={isTabletFormat ? 'h4' : 'h6'}
        style={{ textAlign: 'center', paddingTop: isTabletFormat ? 15 : 5, paddingHorizontal: 20 }}
      >
        En attente de l'arrivée du vétérinaire
      </Text>
    </>
  );
}
