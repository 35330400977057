import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';

import NotificationScreen from '../../screens/NotificationsScreen/NotificationScreen';
import { VetoTabNotificationsParamList } from '../../types';
import NotificationsParamsScreen from '../../screens/NotificationsScreen/NotificationsParamsScreen';

const Stack = createNativeStackNavigator<VetoTabNotificationsParamList>();

const VetoNotificationStackNavigator = () => (
  <Stack.Navigator
    initialRouteName="veto-notifications"
    screenOptions={{
      headerShown: false,
    }}
  >
    <Stack.Screen
      name="veto-notifications"
      component={NotificationScreen}
    />
    {/**         Declaration impots screens      */}
    <Stack.Screen
      name="veto-notifications-params"
      component={NotificationsParamsScreen}
    />

  </Stack.Navigator>
);

export default VetoNotificationStackNavigator;
