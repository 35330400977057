import { Text } from '@ui-kitten/components';
import React from 'react';
import { View } from 'react-native';
import { useForm } from 'react-hook-form';
import { useGetVetOffice } from '../../src/API/VetOffice';
import DetailsScreensHeaderCard from '../DetailsScreensHeaderCard';
import { useUser } from '../../src/API/UserContext';
import { useGetBreeding } from '../../src/API/Breeding';
import InfoCard from '../InfoCard';
import FTextInput from '../../components/Form/TextInput';
import { AvailableValidationRules } from '../../components/Form/validation';
import Button from '../../components/Button';
import Form from '../../components/Form/Form';
import { useCreatePendingInvitationMutation } from '../../src/API/PendingInvitation';

type InvitationVeto = {
  email: string,
};

const MonCabinetInfo = () => {
  const { vetOfficeData } = useGetVetOffice();
  const { user } = useUser();
  const invitationVeto = useForm<InvitationVeto>();
  const { oneBreeding: breeding, refetch } = useGetBreeding(user?.breedingId);

  // on verifie si la dernière invitation à essuyé un refus
  const derniereInvitationRefus = breeding?.pendingInvitations?.items
      && breeding.pendingInvitations.items[0]
      && breeding.pendingInvitations.items[0].refusedAt !== null;

  const { createPendingInvitationVeto } = useCreatePendingInvitationMutation();
  const sendInvitationEmail = async (emailVeto: string) => {
    if (user && user.email && breeding) {
      await createPendingInvitationVeto({
        variables: {
          input: {
            breedingId: breeding.id,
            emailCurrentUser: user.email,
            initial: false,
            email: emailVeto.toLowerCase(),
          },
        },
      });
      await refetch();
    }
  };

  if (!breeding) {
    return null;
  }

  return (
    <>
      <Text category="h1" style={{ marginBottom: 20, marginTop: 40 }}>Mon cabinet vétérinaire</Text>
      {vetOfficeData ? (
        <DetailsScreensHeaderCard
          title1="Nom du cabinet"
          titleInfo1={`${vetOfficeData.companyName}`}
          title2="Adresse"
          titleInfo2={`${vetOfficeData.address?.address} ${vetOfficeData.address?.additionalAddress || ''} ${vetOfficeData.address?.postalCode} ${vetOfficeData.address?.city}`}
          title3="Numéro d'inscription ordre des vétérinaires"
          titleInfo3={`${vetOfficeData.nOrdreCabinet}`}
          title4="Numéro de téléphone"
          titleInfo4={`${vetOfficeData.phoneVetOffice || ''}`}
          title5="Mail"
          titleInfo5={`${vetOfficeData.emailOffice}`}
        />
      ) : (
        derniereInvitationRefus
          ? (
            <>
              <InfoCard
                title="alertInfo"
                iconShow="alert-circle-outline"
                description="Le vétérinaire que vous aviez invité à refusé votre invitation. Vous pouvez dès à présent inviter un autre vétérinaire"
                alertInfo="alertInfo"
              />
              <Form<InvitationVeto>
                {...invitationVeto}
              >
                <>
                  <View style={[
                    { marginTop: 10 },
                  ]}
                  >
                    <Text category="h6" appearance="hint" style={{ paddingBottom: 7 }}>Adresse e-mail du nouveau cabinet que vous souhaitez inviter *</Text>
                    <FTextInput
                      name="email"
                      placeholder="Saisissez l'adresse e-mail"
                      validators={[
                        AvailableValidationRules.email,
                        AvailableValidationRules.required,
                      ]}
                    />
                  </View>

                  <Button
                    style={{ marginBottom: 40 }}
                    onPress={
                        invitationVeto.handleSubmit((data) => {
                          // setLoading(true);
                          // console.log('handleSubmit data:', data);
                          sendInvitationEmail(data.email);
                        })
                      }
                  >
                    Inviter
                  </Button>
                </>
              </Form>
            </>
          )
          : (
            <InfoCard
              title="alertInfo"
              iconShow="alert-circle-outline"
              description={"L'invitation est en cours d'analyse par votre vétérinaire. Sans réponse de sa part sous 15 jours, vous pourrez inviter un autre vétérinaire à participer à l'aventure ScanFlock avec vous."}
              alertInfo="alertInfo"
            />
          )
      ) }

      <Text category="h1" style={{ marginVertical: 10, marginBottom: 7 }}>Mon vétérinaire sanitaire</Text>
      <DetailsScreensHeaderCard
        title1="Mail"
        titleInfo1={`${breeding.emailVeto}`}
      />
    </>
  );
};

export default MonCabinetInfo;
