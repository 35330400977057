import React from 'react';
import { useLinkTo, useRoute } from '@react-navigation/native';
import { Text } from '@ui-kitten/components';
import { RouteProp } from '@react-navigation/core/lib/typescript/src/types';

import MaxWidthContainer from '../../../components/MaxWidthContainer';

import TeleconsultationCard from '../../../components/TeleconsultationCard';
import DetailsScreensHeaderCard from '../../../screenComponents/DetailsScreensHeaderCard';
import AnimalCard from '../../../screenComponents/AnimalCard';

import { TabElevageParamList } from '../../../types';

import { useGetLot } from '../../../src/API/Lots';
import ActivityIndicator from '../../../components/ActivityIndicator';
import ElevageScreenBL from '../../../screenBusinessLogic/ElevageScreen/ElevageScreenBL';

const VetoDetailLotScreen = () => {
  const linkTo = useLinkTo();

  const route = useRoute<RouteProp<TabElevageParamList, 'detail-lot'>>();
  // console.log('DetailLotScreen route: ', route);
  // console.log('DetailLotScreen data: ', breeding);

  const { lot, loading } = useGetLot(route.params.id);
  // console.log('lot detail lot:', lot);
  // console.log('lot detail route.params.id:', route.params.id);

  const affectedAnimalsToLotQuatity = lot?.animals?.items?.length;

  return (
    <MaxWidthContainer
      outerViewProps={{
        style: {
          padding: 24,
        },
      }}
    >
      {lot
    && (
      <DetailsScreensHeaderCard
        title1="Numéro de lot"
        titleInfo1={lot?.numberLot}
        title2="Nom du lot"
        titleInfo2={lot?.name}
        title3="Effectif"
        titleInfo3={affectedAnimalsToLotQuatity} // quatité des animaux affecté à ce lot
        title4="Bâtiment(s)"
        titleInfo4={lot?.building?.nameBuilding || 'Pas de bâtiment affecté à ce lot'}
      />
    )}

      <Text category="p1" appearance="hint" style={{ marginTop: 20, marginBottom: 10 }}>
        Animaux affectés à ce lot
      </Text>

      {loading
        ? (<ActivityIndicator center margin={10} />)
        : (
          lot && lot.animals && lot.animals.items
            && lot.animals.items.map(
              (animalInLot) => (
                <AnimalCard
                  key={animalInLot?.id}
                  name={animalInLot?.name}
                  numberLot={lot.numberLot}
                  numberId={animalInLot?.numberID}
                  onPress={() => { ElevageScreenBL.allerDetailScreen({ state: 'animaux', linkTo, id: animalInLot?.id }); }}
                />
              ),
            )
        )}

      <Text category="p1" appearance="hint" style={{ marginTop: 20 }}>Derniers auto-examens</Text>

      {
          lot?.consultations?.items && lot?.consultations?.items.length > 0
            ? (lot?.consultations?.items.map(
              (item) => <TeleconsultationCard key={item?.id} consultationData={item} back={`veto-elevage/veto-detail-lot/${lot?.id}`} />,
            ))
            : <Text category="h4">Vous n'avez pas de consultations</Text>
        }
    </MaxWidthContainer>
  );
};

export default VetoDetailLotScreen;
