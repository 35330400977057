/**
 *
 *
 * @author: David-Julian Buch
 */

import * as React from 'react';
import { Layout, LayoutProps } from '@ui-kitten/components';
import {
  Pressable,
  StyleSheet,
  TouchableOpacity,
  TouchableOpacityProps,
} from 'react-native';

export type CardProps = LayoutProps & Pick<TouchableOpacityProps, 'onPress'> & {
  withOpacity?: boolean
};

function CompCard(props: CardProps): JSX.Element {
  const {
    level = '3',
    style,
    onPress,
    withOpacity = true,
    ...otherViewProps
  } = props;

  if (onPress !== undefined) {
    if (withOpacity) {
      return (
        <TouchableOpacity
          onPress={onPress}
          style={[
            baseStyle.component,
            { backgroundColor: '#FFFFFF' },
            style]}
          {...otherViewProps}
        />
      );
    }
    return (
      <Pressable
        onPress={onPress}
        style={[
          baseStyle.component,
          { backgroundColor: '#FFFFFF' },
          style]}
        {...otherViewProps}
      />
    );
  }

  return (
    <Layout
      level={level}
      style={[
        baseStyle.component,
        style,
      ]}
      {...otherViewProps}
    />
  );
}

CompCard.displayName = 'Card';

export default CompCard;

const baseStyle = StyleSheet.create({
  component: {
    flexDirection: 'column',
    padding: 10,
    borderRadius: 10,
    shadowColor: 'rgba(197, 197, 197, 0.5)',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 8,
    shadowOpacity: 1,
    elevation: 2,
  },
});
