import { WatchQueryFetchPolicy } from 'apollo-client/core/watchQueryOptions';
import { DocumentNode } from 'apollo-link';
import gql from 'graphql-tag';
import { useMutation, useQuery } from 'react-apollo';
import { ApolloClient } from '@apollo/client';
import {
  listDiseases, getDisease, diseaseByCodeIdentification,
} from '../graphql/queries';
import {
  CreateDiseaseMutation,
  CreateDiseaseMutationVariables,
  DeleteDiseaseMutation, DeleteDiseaseMutationVariables,
  disease,
  DiseaseByCodeIdentificationQuery,
  DiseaseByCodeIdentificationQueryVariables,
  GetDiseaseQuery,
  GetDiseaseQueryVariables,
  ListDiseasesQuery,
  ListDiseasesQueryVariables,
  UpdateDiseaseMutation,
  UpdateDiseaseMutationVariables,
} from '../API';
import {
  updateDisease, createDisease as createDiseaseQuery, deleteDisease,
} from '../graphql/mutations';

export function useListDiseases(fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network', variables?: ListDiseasesQueryVariables) {
  const listDiseasesQuery = <DocumentNode>gql(listDiseases);

  const {
    loading, data, fetchMore, refetch,
  } = useQuery<ListDiseasesQuery, ListDiseasesQueryVariables>(listDiseasesQuery, {
    fetchPolicy,
    variables,
  });

  // console.log('Lots data: api', data);
  return {
    loading,
    diseases: data?.listDiseases?.items,
    fetchMore,
    refetch,
    nextToken: data?.listDiseases?.nextToken,
  };
}

export function useGetDisease(id: string) {
  const diseaseQuery = <DocumentNode>gql(getDisease);

  if (!id) {
    return { loading: false, bse: undefined, refetch: () => {} };
  }

  const { loading, data, refetch } = useQuery<
  GetDiseaseQuery, GetDiseaseQueryVariables
  >(diseaseQuery, {
    variables: {
      id,
    },
  });

  return {
    loading, disease: <disease>data?.getDisease, refetch,
  };
}

export function getDiseaseAsync<T>(client: ApolloClient<T>, id: string) {
  const psQuery = <DocumentNode>gql(getDisease);

  return client.query<GetDiseaseQuery, GetDiseaseQueryVariables>({
    query: psQuery,
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  });
}

export function useGetDiseaseByCodeIdentification(codeIdentification: string) {
  const diseaseQuery = <DocumentNode>gql(diseaseByCodeIdentification);

  const { loading, data } = useQuery<
  DiseaseByCodeIdentificationQuery, DiseaseByCodeIdentificationQueryVariables>(diseaseQuery, {
    variables: {
      codeIdentification,
    },
  });
  if (data && data.diseaseByCodeIdentification && data.diseaseByCodeIdentification.items) {
    return { disease: data.diseaseByCodeIdentification.items[0], loading };
  }
  return { disease: null, loading };
}

export function useUpdateDiseaseMutation() {
  const [modifyDisease, { loading: mutationDiseaseLoading }] = useMutation<
  UpdateDiseaseMutation, UpdateDiseaseMutationVariables
  >(gql(updateDisease));
  return { modifyDisease, mutationDiseaseLoading };
}

export function useCreateDiseaseMutation() {
  const [createDisease, { loading: mutationDiseaseLoading }] = useMutation<
  CreateDiseaseMutation, CreateDiseaseMutationVariables
  >(gql(createDiseaseQuery));
  return { createDisease, mutationDiseaseLoading };
}

export function useDeleteMaladieMutation() {
  const [supprimerMaladie] = useMutation<
  DeleteDiseaseMutation, DeleteDiseaseMutationVariables
  >(gql(deleteDisease));
  return supprimerMaladie;
}
